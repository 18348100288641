import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';
import { Text } from '@asteria/component-core/typography';

import Feedback from '@asteria/component-feedback';
import IntegrationList, {
	IntegrationLanding,
	Splash,
} from '@asteria/component-integrations-v2';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import './styles.scss';

const OnboardingViewFeedback = (props) => {
	const { onAction } = props;

	const [isOpen, setOpen] = React.useState(false);

	const onFeedbackAction = React.useCallback(
		(action, data) => {
			if (data.dismissed) {
				setOpen(false);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const onFeedbackSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	const handleClose = React.useCallback(() => setOpen(false), []);

	React.useEffect(() => {
		const selector = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--swedbank-feedback-selector');

		let node = false;

		if (selector) {
			node = document.querySelector(selector);
		}

		setOpen(!node);
	}, []);

	if (!isOpen) {
		return null;
	}

	return (
		<FeatureFlag feature="onboarding-feedback">
			<Feedback
				description={TranslationService.get([
					'feedback.text',
					'feedback.onboarding.text',
				])}
				feedbackKey="add-erp"
				// feedbackKey="onboarding"

				maxText={TranslationService.get([
					'feedback.rating.max',
					'feedback.onboarding.rating.max',
				])}
				minText={TranslationService.get([
					'feedback.rating.min',
					'feedback.onboarding.rating.min',
				])}
				values={[1, 2, 3, 4, 5]}
				size="lg"
				onAction={onFeedbackAction}
				onSupportRequest={onFeedbackSupportRequest}
				onEnd={handleClose}
				validate
				type="onboarding"
			/>
		</FeatureFlag>
	);
};

OnboardingViewFeedback.propTypes = {
	onAction: PropTypes.func,
};

const OnboardingView = (props) => {
	const { onAction, onFetch } = props;

	const hasBankFeature = useFeature('bank-integrations');
	const hasIntegrationLanding = useFeature('integration-landing-page');

	const hasIntegrations = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: hasBankFeature ? undefined : 'erp',
			}).length,
	);

	useTourShowAction();

	return (
		<div className="asteria-view__onboarding">
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get(
							'view.integrations.title',
							// eslint-disable-next-line spellcheck/spell-checker
							'Bokföringssystem',
						)}
					/>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-onboarding">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>

			{hasIntegrations || !hasIntegrationLanding ? (
				<>
					<OnboardingViewFeedback onAction={onAction} />

					<div className="asteria-view__onboarding__content">
						<FeatureFlag feature="onboarding-splash">
							<Splash />
						</FeatureFlag>
						<IntegrationList
							onAction={onAction}
							onSubmit={onFetch}
						/>
					</div>

					<Text
						className="asteria-component__text--disclaimer"
						size="sm"
					>
						{TranslationService.get(
							'view.integrations.disclaimer',
							// eslint-disable-next-line spellcheck/spell-checker
							'Företagskollen är en kostnadsfri tjänst som underlättar bokslutsarbetet och gör det enklare att planera för framtiden. Anslut ditt bokföringssystem för att få en djupare analys, fler insikter och en skarpare prognos av ditt kassaflöde. Resultatet visualiseras i enkla grafer och en prognos på företagets framtida kassaflöde presenteras. Tjänsten är framtagen tillsammans med vår samarbetspartner Asteria AB.',
						)}
					</Text>
				</>
			) : (
				<IntegrationLanding />
			)}
		</div>
	);
};

OnboardingView.displayName = 'OnboardingView';

OnboardingView.propTypes = {
	onAction: PropTypes.func,
	onFetch: PropTypes.func,
};

export default OnboardingView;
