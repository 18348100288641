import React from 'react';

import SettingsView from '@asteria/view-settings';

import { LayoutContext } from '../../context';

import './styles.scss';

const SettingsPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<SettingsView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

SettingsPage.displayName = 'SettingsPage';

SettingsPage.propTypes = {};

export default SettingsPage;
