import { addMonths, format, startOfMonth } from 'date-fns';

import { TranslationService } from '@asteria/language';

/**
 *
 * @param {{ type: 'STRAIGHT' | 'ANNUITY', amount: number, term: number, rate: number, timestamp: Date }} options
 * @returns { { remaining: number, amortization: number, interest: number, total: number, date: Date, end: number }[] }
 */
export function getData({ type, amount, term, rate, timestamp }) {
	if (type === 'STRAIGHT') {
		const amortization = amount / (term * 12);

		const { data } = Array.from({ length: term * 12 }).reduce(
			(acc, value, index) => {
				const remaining = acc.last;
				const interest = remaining * (rate / 100 / 12);
				const total = amortization + interest;
				const end = Math.max(remaining - total, 0);

				const date = addMonths(startOfMonth(timestamp), index);

				acc.data.push({
					remaining,
					amortization,
					interest,
					total,
					date,
					end,
				});

				acc.last = remaining - amortization;

				return acc;
			},
			{ data: [], last: amount },
		);

		return data;
	}

	if (type === 'ANNUITY') {
		const mRate = rate / 100 / 12;
		const nMonth = term * 12;
		const delta = (1 + mRate) ** nMonth;
		const total = (amount * mRate * delta) / (delta - 1);

		const { data } = Array.from({ length: term * 12 }).reduce(
			(acc, value, index) => {
				const remaining = acc.last;
				const interest = acc.last * mRate;
				const amortization = total - interest;
				const end = Math.max(remaining - total, 0);

				const date = addMonths(startOfMonth(timestamp), index);

				acc.data.push({
					remaining,
					amortization,
					interest,
					total,
					date,
					end,
				});

				acc.last = remaining - amortization;

				return acc;
			},
			{ data: [], last: amount },
		);

		return data;
	}

	return [];
}

/**
 *
 * @param { { remaining: number, amortization: number, interest: number, total: number, date: Date, end: number }[] } $data
 */
export function getCSVContent($data) {
	const data = $data.reduce((acc, object) => {
		const year = format(object.date, 'yyyy');

		if (!acc[year]) {
			acc[year] = [];
		}

		acc[year].push(object);

		return acc;
	}, {});

	let content = '';

	for (const year in data) {
		content += `${year};\n\n`;

		content +=
			['date', 'remaining', 'amortization', 'interest', 'total', 'end']
				.map((column) =>
					TranslationService.get([
						'financial.page.loan.section.transactions.table.monthly.header.cell',
						`financial.page.loan.section.transactions.table.monthly.header.cell.${column}`,
					]),
				)
				.join(';') + '\n';

		for (const object of data[year]) {
			content +=
				TranslationService.get(
					[
						'financial.page.loan.section.transactions.table.monthly.cell',
						'financial.page.loan.section.transactions.table.monthly.cell.date',
					],
					'{{ date | date:"MMM" | capitalize }}',
					object,
				) + ';';

			content +=
				TranslationService.get(
					[
						'financial.page.loan.section.transactions.table.monthly.cell',
						'financial.page.loan.section.transactions.table.monthly.cell.remaining',
					],
					'{{ remaining | number:false:false:true:false }}',
					object,
				).replace(/\s/g, '') + ';';

			content +=
				TranslationService.get(
					[
						'financial.page.loan.section.transactions.table.monthly.cell',
						'financial.page.loan.section.transactions.table.monthly.cell.amortization',
					],
					'{{ amortization | number:false:false:true:false }}',
					object,
				).replace(/\s/g, '') + ';';

			content +=
				TranslationService.get(
					[
						'financial.page.loan.section.transactions.table.monthly.cell',
						'financial.page.loan.section.transactions.table.monthly.cell.interest',
					],
					'{{ interest | number:false:false:true:false }}',
					object,
				).replace(/\s/g, '') + ';';

			content +=
				TranslationService.get(
					[
						'financial.page.loan.section.transactions.table.monthly.cell',
						'financial.page.loan.section.transactions.table.monthly.cell.total',
					],
					'{{ total | number:false:false:true:false }}',
					object,
				).replace(/\s/g, '') + ';';

			content +=
				TranslationService.get(
					[
						'financial.page.loan.section.transactions.table.monthly.cell',
						'financial.page.loan.section.transactions.table.monthly.cell.end',
					],
					'{{ end | number:false:false:true:false }}',
					object,
				).replace(/\s/g, '') + ';';

			content += '\n';
		}

		content += '\n';
	}

	return content;
}
