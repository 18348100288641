export const initialState = {
	details: null,
	adminUsers: { loading: true, data: [] },
	users: { loading: true, data: [] },
	companies: {
		loading: true,
		data: [],
		filters: [],
		pagination: {
			direction: 'ASC',

			hasNextPage: false,
			hasPreviousPage: false,
			currentCursor: null,
			startCursor: null,
			endCursor: null,
			history: [],
		},
		count: 0,
	},
};
