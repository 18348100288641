import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';
import Steps, { Step as GuideStep } from '@asteria/component-steps';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const IntegrationGuide = (props) => {
	const { onClose, steps, className, integration } = props;

	if (!steps?.length) {
		return null;
	}

	return (
		<Modal
			{...props}
			className={cn('asteria-component__onboarding-guide', className)}
			size="sm"
		>
			<Steps
				onEnd={onClose}
				title={TranslationService.get([
					`integration.guide.title`,
					`integration.guide.${integration?.type}.title`,
					`integration.guide.${integration?.key}.title`,
					`integration.guide.${integration?.type}.${integration?.key}.title`,
				])}
			>
				{steps?.map((step) => (
					<Contenter
						key={step.id}
						as={GuideStep}
						content={step.content}
					/>
				))}
			</Steps>
		</Modal>
	);
};

IntegrationGuide.displayName = 'IntegrationGuide';

IntegrationGuide.propTypes = {
	onClose: PropTypes.func,
	className: PropTypes.string,
	steps: PropTypes.arrayOf(PropTypes.object),
	integration: PropTypes.shape({
		type: PropTypes.string,
		key: PropTypes.string,
	}),
};

export default IntegrationGuide;
