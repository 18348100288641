import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import SearchSelector, { SearchSelectorItem } from './base';

import './styles.scss';

const CurrencySelectorItem = React.memo((props) => {
	const { onAction, object } = props;

	const ID = object?._id ?? object?.id;

	const onClick = React.useCallback(() => {
		Analytics.event('search.selector.toggle', { type: 'currency', id: ID });

		return onAction?.('toggleCurrency', {
			type: 'currency',
			id: ID,
			item: object,
		});
	}, [ID, object, onAction]);

	const selected = useSelector(
		(store) => AppStore.selectors.filters(store, { id: ID }).length > 0,
	);

	return (
		<SearchSelectorItem active={selected} onClick={onClick}>
			<Chip
				label={TranslationService.get(
					`search.selector.currency.${object?.code}`,
					object?.code,
					{ currency: object },
				)}
				flag={object?.code}
				variant="simple"
			/>
		</SearchSelectorItem>
	);
});

CurrencySelectorItem.displayName = 'CurrencySelectorItem';
CurrencySelectorItem.propTypes = {
	onAction: PropTypes.func,
	object: PropTypes.shape({
		code: PropTypes.string,
		id: PropTypes.string,
		_id: PropTypes.string,
	}),
};

const CurrencySelector = React.memo((props) => {
	const { className, onAction } = props;

	const count = useSelector(
		(store) =>
			AppStore.selectors.filters(store, { type: 'currency' }).length,
	);

	const objects = useSelector(AppStore.selectors.currencies);

	if (objects.length < 2) {
		return null;
	}

	return (
		<SearchSelector
			className={cn('asteria--variant-currency', className)}
			label={TranslationService.get(`selector.currencies`)}
			onAction={onAction}
			type="currency"
			count={count}
		>
			{objects.map((object) => (
				<CurrencySelectorItem
					key={object?._id ?? object?.id}
					object={object}
					onAction={onAction}
				/>
			))}
		</SearchSelector>
	);
});

CurrencySelector.displayName = 'CurrencySelector';
CurrencySelector.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default CurrencySelector;
