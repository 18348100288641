import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

import * as AccountStore from '@asteria/datalayer/stores/accounts';

/* START: Graph Selectors */

export const graph = createSelector(
	(store) => store?.graph?.data,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const range = createSelector(
	(store) => store?.graph?.data?.range ?? [],
	(_, options) => options,
	(range, options) => {
		let response = range;

		if (options?.id) {
			response = response.filter((object) => object?.id === options?.id);
		}

		if (options?.formatEach) {
			response = response.map(options?.formatEach);
		}

		if (options?.format) {
			response = options?.format(response);
		}

		return response;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const activeGroups = createSelector(
	(store) => store?.graph?.data?.activeGroups ?? {},
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const activeBars = createSelector(
	(store) => store?.graph?.data?.activeBars ?? [],
	(_, ID) => ID,
	(value, ID) => {
		let response = value;

		if (ID) {
			response = response.filter((value) => value === ID);
		}

		return response;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const hoverBars = createSelector(
	(store) => store?.graph?.data?.hoverBars,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const hoverGroups = createSelector(
	(store) => store?.graph?.data?.hoverGroups,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const steps = createSelector(
	(store) => store?.graph?.data?.steps,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const barsSteps = createSelector(
	(store) => store?.graph?.data?.barsSteps,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const linesSteps = createSelector(
	(store) => store?.graph?.data?.linesSteps,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const id = createSelector(
	(store) => store?.graph?.data?.id,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const graphGroupWidth = createSelector(
	(store) => store?.graph?.data?.graphGroupWidth,
	(value) => value,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const barGroups = createSelector(
	(store) => store?.graph?.data?.barGroups ?? {},
	(_, options) => options,
	(value, options) => {
		let response = value;

		if (options?.id) {
			response = response?.[options?.id];
		}

		if (options?.format) {
			response = options?.format(response);
		}

		return response;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const hasUnpaid = createSelector(
	(store) => store?.graph?.hasUnpaid,
	(value) => value,
);

export const hasOverdue = createSelector(
	(store) => store?.graph?.hasOverdue,
	(value) => value,
);

export const hasForecast = createSelector(
	(store) => store?.graph?.hasForecast,
	(value) => value,
);

export const hasCredit = createSelector(
	(store) => store?.graph?.hasCredit,
	(store, options) => AccountStore.selectors.dynamicCredit(store, options),
	(hasCredit, credit) => hasCredit && credit,
);

export const options = createSelector(
	(store) => store?.graph?.options,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const visibleCategories = createSelector(
	(store) => store?.graph?.data?.visibleCategories,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const hasVisibleCategories = createSelector(
	visibleCategories,
	(value) => value.length > 0,
);

export const hasActive = createSelector(
	activeGroups,
	activeBars,
	(groups, bars) => groups.length || bars.length,
);

export const yAxisSteps = createSelector(
	steps,
	barsSteps,
	linesSteps,
	(_, parts = []) => parts,
	(steps, barsSteps, linesSteps, parts) => {
		if (parts.length === 1) {
			return parts.includes('bars') ? barsSteps : linesSteps;
		}

		return steps;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const active = createSelector(
	activeGroups,
	(_, ID) => ID,
	(groups, ID) => groups.includes(ID),
);

export const hover = createSelector(
	hoverGroups,
	(_, ID) => ID,
	(groups, ID) => groups.includes(ID),
);

export const xAxis = {
	noChildrenActive: createSelector(
		activeGroups,
		activeBars,
		(_, ID) => ID,
		(groups, bars, ID) => groups.includes(ID) && bars.length === 0,
	),

	noChildrenHover: createSelector(
		hoverGroups,
		hoverBars,
		(_, ID) => ID,
		(groups, bars, ID) => groups.includes(ID) && bars.length === 0,
	),
	value: createSelector(
		barGroups,
		(_, ID) => ID,
		(groups, ID) => groups?.[ID]?.lines?.[0]?.value,
	),
};

export const graphMaxValue = createSelector(
	(store) => store?.graph?.data?.graphMaxValue,
	(value) => value ?? 0,
);

export const graphMinValue = createSelector(
	(store) => store?.graph?.data?.graphMinValue,
	(value) => value ?? 0,
);

export const barsMaxValue = createSelector(
	(store) => store?.graph?.data?.barsMaxValue,
	(value) => value ?? 0,
);

export const barsMinValue = createSelector(
	(store) => store?.graph?.data?.barsMinValue,
	(value) => value ?? 0,
);

export const linesMaxValue = createSelector(
	(store) => store?.graph?.data?.linesMaxValue,
	(value) => value ?? 0,
);

export const linesMinValue = createSelector(
	(store) => store?.graph?.data?.linesMinValue,
	(value) => value ?? 0,
);

export const minValue = createSelector(
	graphMinValue,
	barsMinValue,
	linesMinValue,
	(_, parts = []) => parts,
	(graph, bars, lines, parts) => {
		if (parts.length === 1) {
			if (parts.includes('bars')) {
				return bars;
			}

			return lines;
		}

		return graph;
	},
);

export const maxValue = createSelector(
	graphMaxValue,
	barsMaxValue,
	linesMaxValue,
	(_, parts = []) => parts,
	(graph, bars, lines, parts) => {
		if (parts.length === 1) {
			if (parts.includes('bars')) {
				return bars;
			}

			return lines;
		}

		return graph;
	},
);

/* END: Graph Selectors */
