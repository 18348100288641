import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { { postfix: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useIntegrationError() {
	const available = useSelector(
		(store) => {
			const erp = IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				filters: [{ status: 'ERROR' }],
			});

			const bank = IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				filters: [{ status: 'ERROR' }],
			});

			return [].concat(erp).concat(bank);
		},
		(a, b) => isEqual(a, b),
	);

	return available;
}

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & { integrations: unknown[], type: 'erp' | 'bank' }> } */
const OnboardingErrorTitle = React.memo(function OnboardingErrorTitle({
	integrations,
	type,
	translationOptions,
	onAction,
}) {
	const handleClick = React.useCallback(() => {
		onAction?.('card:action', {
			type: 'onboarding-error',
			integrations: integrations,
		});
	}, [integrations, onAction]);

	if (!integrations?.length) {
		return null;
	}

	if (integrations?.length === 1) {
		const integration = integrations?.[0];

		const integrationName = TranslationService.get([
			'integration.title',
			`integration.title.${integration?.key}`,
			`integration.title.${integration?.type}.${integration?.key}`,
			`integration.${integration?.key}.title`,
			`integration.${integration?.type}.${integration?.key}.title`,
		]);

		return (
			<Text className="asteria-component__onboarding-error-line">
				{TranslationService.getV2(
					[
						'card.content.title',
						'card.content.title.onboarding-error',
						'card.content.title.onboarding-error.single',
					],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-key': integration?.key,
							'integration-type': integration?.type,
						},
						data: {
							...translationOptions?.data,
							integration: integration,
							integrationName: integrationName,
						},
					},
				)}
				<Button
					onClick={handleClick}
					size="md"
					label={TranslationService.getV2(
						['card.content.onboarding-error.action'],
						{
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								'integration-key': integration?.key,
								'integration-type': integration?.type,
							},
							data: {
								...translationOptions?.data,
								integration: integration,
								integrationName: integrationName,
							},
						},
					)}
					variant="link"
				/>
				{TranslationService.getV2(
					[
						'card.content.title.last.onboarding-error',
						'card.content.title.last.onboarding-error.single',
					],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-key': integration?.key,
							'integration-type': integration?.type,
						},
						data: {
							...translationOptions?.data,
							integration: integration,
							integrationName: integrationName,
						},
					},
				)}
			</Text>
		);
	}

	return (
		<Text className="asteria-component__onboarding-error-line">
			{TranslationService.getV2(
				[
					'card.content.title',
					'card.content.title.onboarding-error',
					'card.content.title.onboarding-error.multiple',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integrations: integrations,
					},
				},
			)}
			<Button
				onClick={handleClick}
				size="md"
				label={TranslationService.getV2(
					['card.content.onboarding-error.action'],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-type': type,
						},
						data: {
							...translationOptions?.data,
							integrations: integrations,
						},
					},
				)}
				variant="link"
			/>
			{TranslationService.getV2(
				[
					'card.content.title.last.onboarding-error',
					'card.content.title.last.onboarding-error.multiple',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integrations: integrations,
					},
				},
			)}
		</Text>
	);
});

OnboardingErrorTitle.propTypes = {
	translationOptions: PropTypes.object,
	type: PropTypes.oneOf(['erp', 'bank']),
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & { integrations: unknown[] }> } */
const OnboardingLoadingTitle = React.memo(function OnboardingLoadingTitle({
	integrations,
	translationOptions,
	onAction,
}) {
	const erpErrors = integrations.filter(({ type }) => type === 'erp');
	const bankErrors = integrations.filter(({ type }) => type === 'bank');

	return [
		<OnboardingErrorTitle
			key="onboarding-erp-error"
			type="erp"
			integrations={erpErrors}
			translationOptions={translationOptions}
			onAction={onAction}
		/>,
		<OnboardingErrorTitle
			key="onboarding-bank-error"
			type="bank"
			integrations={bankErrors}
			translationOptions={translationOptions}
			onAction={onAction}
		/>,
	];
});

OnboardingLoadingTitle.propTypes = {
	translationOptions: PropTypes.object,
	integrations: PropTypes.arrayOf(PropTypes.object),
};

export default OnboardingLoadingTitle;
export { useIntegrationError };
