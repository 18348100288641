import React from 'react';

import Group from '@asteria/component-core/group';

import { Bar } from '@asteria/component-forecaster/components/bar';

import './styles.scss';

const GraphLoader = () => {
	return (
		<Group
			className="asteria-view__welcome__loader__content"
			direction="horizontal"
			verticalAlign="bottom"
		>
			<Bar loading />
			<Bar loading />
			<Bar loading />
			<Bar loading />
			<Bar loading />
		</Group>
	);
};

GraphLoader.displayName = 'GraphLoader';

export default React.memo(GraphLoader);
