import { useContext, useMemo } from 'react';

import WrapperContext from './context';

/**
 * @param { boolean } loading
 * @returns { boolean }
 */
export function useLoading(loading) {
	const ctx = useContext(WrapperContext);

	return useMemo(() => loading ?? ctx?.loading, [ctx?.loading, loading]);
}

/**
 * @param { boolean } scrollable
 * @returns { boolean }
 */
export function useScrollable(scrollable) {
	const ctx = useContext(WrapperContext);

	return useMemo(
		() => scrollable ?? ctx?.scrollable,
		[ctx?.scrollable, scrollable],
	);
}
