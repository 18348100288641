import React from 'react';

import { format, parseISO } from 'date-fns';

export function useGroupMessages(messages) {
	return React.useMemo(() => {
		return messages.reduce((acc, object) => {
			const created = object?.createdAt
				? parseISO(object?.createdAt)
				: new Date();

			const date = format(created, 'yyyy-MM-dd');

			if (!acc[date]) {
				acc[date] = [];
			}

			acc[date].push(object);

			return acc;
		}, {});
	}, [messages]);
}

export function useAutoScroll(ref, messages) {
	const [lastHeight, setLastHeight] = React.useState(0);

	const scrollToBottom = React.useCallback(
		(options) => {
			if (ref?.current?.scrollHeight) {
				ref?.current?.scrollTo({
					top: ref?.current?.scrollHeight,
					...options,
				});
			}
		},
		[ref],
	);

	React.useEffect(() => {
		scrollToBottom();
	}, [scrollToBottom]);

	React.useEffect(() => {
		const lastMessage = messages?.[messages?.length - 1];

		if (
			lastHeight ===
				ref?.current?.scrollTop + ref?.current?.offsetHeight ||
			lastMessage?.createdBy?.type === 'UserToken'
		) {
			scrollToBottom({ behavior: 'smooth' });
		}

		setLastHeight(ref?.current?.scrollHeight);
	}, [messages, lastHeight, scrollToBottom, ref]);
}
