import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Title, { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';
import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import '../styles.scss';

const RemoveScenario = (props) => {
	const { onClose, onAction, data } = props;

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);
	const isUpdating = useSelector(ScenarioStore.selectors.isUpdating);

	const config = useConfig('modals.scenario.remove', { deep: true });

	const handleRemove = useCallback(() => {
		Analytics.event('forecaster.scenario.remove.confirm', data);

		onAction?.('scenario:delete', data);
	}, [data, onAction]);

	return (
		<Wrapper>
			<Header onClose={onClose}>
				{TranslationService.get('forecaster.modal.remove.title')}
			</Header>
			<Content>
				<FormWrapper>
					<Content>
						{config?.contenter ? (
							<Contenter
								content={config?.contenter}
								data={data}
							/>
						) : (
							<TextGroup>
								<Title type="title" size="xs">
									{TranslationService.get(
										'forecaster.modal.remove.body.label',
									)}
								</Title>
								<Text>
									{TranslationService.get(
										'forecaster.modal.remove.body',
										undefined,
										data,
									)}
								</Text>
							</TextGroup>
						)}
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection>
					{hasHistory ? (
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.back',
								'forecaster.modal.remove.button.back',
							])}
							onClick={onClose}
							analyticsKey="forecaster.modal.remove.back"
						/>
					) : (
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.cancel',
								'forecaster.modal.remove.button.cancel',
							])}
							onClick={onClose}
							analyticsKey="forecaster.modal.remove.cancel"
						/>
					)}
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.remove',
							'forecaster.modal.remove.button.remove',
						])}
						onClick={handleRemove}
						analyticsKey="forecaster.modal.remove.remove"
						loading={isUpdating}
						disabled={isUpdating}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

RemoveScenario.displayName = 'RemoveScenario';

RemoveScenario.propTypes = {
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
};

const RemoveScenarioModal = (props) => {
	const { open, onClose } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const handleClose = useCallback(
		(event) => {
			Analytics.event('forecaster.scenario.remove.cancel', {});

			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			size="sm"
			className={cn('asteria--type-scenario', 'asteria--action-remove')}
		>
			<RemoveScenario {...props} onClose={handleClose} />
		</Modal>
	);
};

RemoveScenarioModal.displayName = 'RemoveScenarioModal';

RemoveScenarioModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
};

export default RemoveScenarioModal;
export { RemoveScenario };
