import React, { cloneElement } from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { stateClasses } from '../utils';

const TabsContent = (props) => {
	const { className, children, tab, setTab } = props;

	return (
		<div
			className={cn(
				className,
				'asteria-component__tabs__content__wrapper',
			)}
		>
			{React.Children.map(children, (child, index) => {
				const current = child?.props?.tab;
				const className = child?.props?.className;

				const isActive = tab?.includes?.(current);

				return cloneElement(child, {
					className: cn(
						className,
						'asteria-component__tabs__content',
						`asteria-component__tabs__content--name-${current}`,
						stateClasses({ active: isActive }),
					),
					tab: tab,
					setTab: setTab,
					key: ['content', current, index].filter(Boolean).join('-'),
					isTabActive: isActive,
				});
			})}
		</div>
	);
};

TabsContent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	tab: PropTypes.string,
	setTab: PropTypes.func,
};

TabsContent.displayName = 'TabsContent';

export default TabsContent;
