import React from 'react';

import PropTypes from 'prop-types';

import BarGraphColumn from '@asteria/component-forecaster/components/graph/bars/column';

import LoanBarGraphBarGroup from './bar-group';

const LoanBarGraphColumn = React.memo((props) => {
	const { date, type } = props;

	return (
		<BarGraphColumn
			type={type}
			date={date}
			render={({ date, type }) => (
				<LoanBarGraphBarGroup date={date} type={type} />
			)}
		/>
	);
});

LoanBarGraphColumn.displayName = 'LoanBarGraphColumn';
LoanBarGraphColumn.propTypes = {
	date: PropTypes.string,
	type: PropTypes.string,
};

export default LoanBarGraphColumn;
