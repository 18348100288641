import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import {
	ListCell,
	ListGroup,
	ListHeader,
	ListItem,
} from '@asteria/component-list';

import { TranslationService } from '@asteria/language';

import Account from './account';
import * as selectors from './selectors';

/** @type { React.FC<{ currency: string }> } */
const AccountsGroup = React.memo(function AccountsGroup({ currency }) {
	const accounts = useSelector(
		(store) => selectors.accounts(store, { currency }),
		(a, b) => isEqual(a, b),
	);

	return (
		<ListGroup>
			<ListHeader>
				<ListItem className="flex-1">
					<ListCell className="flex-1">
						<Text weight="medium">
							{TranslationService.get(
								['accounts.list.group.title'],
								undefined,
								{ currency: currency, accounts: accounts },
							)}
						</Text>
					</ListCell>
				</ListItem>
			</ListHeader>
			{accounts.map((account) => {
				const ID = account?._id ?? account?.id;

				return <Account key={ID} id={ID} />;
			})}
		</ListGroup>
	);
});

AccountsGroup.propTypes = { currency: PropTypes.string };

export default AccountsGroup;
