import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import useContenter from '@asteria/component-core/utils/useContenter';
import { FooterSection } from '@asteria/component-core/wrapper';

import { CompleteScreen } from '@asteria/component-alert';
import List, { ListGroup, ListItem } from '@asteria/component-list';
import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Step, { Content, Footer, Header } from '../../components/Step';
import { useFlow } from '../../hooks';

import './styles.scss';

const IntegrationErrorDetails = (props) => {
	const { integration, error, type } = props;

	const code = error?.code ?? 500;
	const message = error?.message;

	return (
		<ListItem>
			<Text size="sm" weight="bold">
				{TranslationService.get(
					[
						`integration.error.code`,
						`integration.error.${code}.code`,
						`integration.error.${type}.code`,
						`integration.error.${code}.${type}.code`,
						`integration.error.${integration?.key}.code`,
						`integration.error.${code}.${integration?.key}.code`,
						`integration.error.${type}.${integration?.key}.code`,
						`integration.error.${code}.${type}.${integration?.key}.code`,
					],
					code,
					{ error: error },
				)}
			</Text>
			<Text size="sm">
				{TranslationService.get(
					[
						`integration.error.message`,
						`integration.error.${code}.message`,
						`integration.error.${type}.message`,
						`integration.error.${code}.${type}.message`,
						`integration.error.${integration?.key}.message`,
						`integration.error.${code}.${integration?.key}.message`,
						`integration.error.${type}.${integration?.key}.message`,
						`integration.error.${code}.${type}.${integration?.key}.message`,
						message,
					],
					message,
					{ error: error },
				)}
			</Text>
		</ListItem>
	);
};

IntegrationErrorDetails.displayName = 'IntegrationErrorDetails';

IntegrationErrorDetails.propTypes = {
	type: PropTypes.string,
	integration: PropTypes.object,
	error: PropTypes.shape({
		code: PropTypes.number,
		message: PropTypes.string,
	}),
};

function useAnalytics(integration) {
	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.error', {
			integration: {
				_id: integration?._id,
				key: integration?.key,
				type: integration?.type,
				lastSync: integration?.lastSync,
				disabled: integration?.disabled,
				status: integration?.status,
				config: {
					connected: integration?.config?.connected,
					errors: integration?.config?.errors,
				},
				actions: integration?.actions,
			},
		});

		return () => {
			Analytics.endFlow('integrations.error');
		};
	}, []);

	return 'integrations.error';
}

const IntegrationError = (props) => {
	const { className, onSubmit, onClose, type } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, _id),
	);

	const analyticsFlow = useAnalytics(integration);

	const content = useContenter(`integrations.${integration.key}.error`);

	const $type = type ?? integration?.type;

	const step = `${$type}.error`;

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, onClose],
	);

	const abort = React.useCallback(
		(event) => {
			const _id = IntegrationStore.selectors.navigation._id(
				store.getState(),
			);
			const integration = IntegrationStore.selectors.integration(
				store.getState(),
				_id,
			);

			onSubmit?.('integrations:delete', integration);

			Analytics.endFlow(analyticsFlow);
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[analyticsFlow, dispatch, onClose, onSubmit, store],
	);

	const back = React.useCallback(() => {
		const _id = IntegrationStore.selectors.navigation._id(store.getState());
		const integration = IntegrationStore.selectors.integration(
			store.getState(),
			_id,
		);

		onSubmit?.('integrations:delete', integration);

		Analytics.endFlow(analyticsFlow);

		dispatch(
			IntegrationStore.navigation.back(
				JSON.parse(JSON.stringify(integration)),
			),
		);
	}, [store, onSubmit, analyticsFlow, dispatch]);

	const flow = useFlow(integration?.type, integration?.key);

	const integrationName = TranslationService.get(
		[
			'integration.title',
			`integration.title.${integration?.key}`,
			`integration.title.${integration?.type}.${integration?.key}`,
			`integration.${integration?.key}.title`,
			`integration.${integration?.type}.${integration?.key}.title`,
		],
		flow?.name,
	);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-generic__error',
				{
					[`asteria-component__onboarding-generic__error--type-${$type}`]:
						$type,
				},
				className,
			)}
			step={step}
		>
			<Header onBack={back} onClose={close}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${integration?.type}.add.title`,
						`integrations.${integration?.key}.add.title`,
						`integrations.${integration?.type}.${integration?.key}.add.title`,
						`integrations.error.title`,
						`integrations.${integration?.type}.error.title`,
						`integrations.${integration?.key}.error.title`,
						`integrations.${integration?.type}.${integration?.key}.error.title`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Header>
			<Content step={step}>
				<CompleteScreen
					level="warning"
					icon="warning"
					iconPosition="first"
					title={TranslationService.get(
						[
							`integrations.error.title`,
							`integrations.${$type}.error.title`,
							`integrations.${$type}.${integration.key}.error.title`,
							`integrations.error.alert.title`,
							`integrations.${$type}.error.alert.title`,
							`integrations.${$type}.${integration.key}.error.title`,
						],
						undefined,
						{ integration: integration, name: integrationName },
					)}
				>
					<Title type="title" size="xs" align="center">
						{TranslationService.get(
							[
								`integrations.error.subtitle`,
								`integrations.${$type}.error.subtitle`,
								`integrations.${$type}.${integration.key}.error.subtitle`,
								`integrations.error.alert.subtitle`,
								`integrations.${$type}.error.alert.subtitle`,
								`integrations.${$type}.${integration.key}.error.alert.subtitle`,
							],
							undefined,
							{ integration: integration, name: integrationName },
						)}
					</Title>
					<Text
						weight="semibold"
						size="lg"
						className="asteria-component__alert--highlight"
						align="center"
					>
						{TranslationService.get(
							[
								`integrations.error.integration`,
								`integrations.${$type}.error.integration`,
								`integrations.${$type}.${integration.key}.error.integration`,
								`integrations.error.alert.integration`,
								`integrations.${$type}.error.alert.integration`,
								`integrations.${$type}.${integration.key}.error.alert.integration`,
							],
							'{{ integration.name | cap }}',
							{ integration: integration, name: integrationName },
						)}
					</Text>
					<Text align="center" size="sm">
						{TranslationService.get(
							[
								`integrations.error.content`,
								`integrations.${$type}.error.content`,
								`integrations.${$type}.${integration.key}.error.content`,
								`integrations.error.alert.content`,
								`integrations.${$type}.error.alert.content`,
								`integrations.${$type}.${integration.key}.error.alert.content`,
							],
							undefined,
							{ integration: integration, name: integrationName },
						)}
					</Text>
					{integration?.config?.errors?.length ? (
						<List
							className="asteria-component__onboarding-errors"
							size="lg"
							applyTypographySizes={false}
						>
							<ListGroup>
								{integration?.config?.errors.map((error) => (
									<IntegrationErrorDetails
										key={[error.code, error.message].join(
											'-',
										)}
										error={error}
										integration={integration}
										type={$type}
									/>
								))}
							</ListGroup>
						</List>
					) : null}

					<Contenter content={content} />
				</CompleteScreen>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${integration.key}.abort`}
						variant="tertiary"
						label={TranslationService.get(
							[
								'action.abort',
								'button.abort',
								'onboarding.modal.action',
								'onboarding.modal.action.abort',
								'onboarding.modal.flow-error.action',
								'onboarding.modal.flow-error.action.abort',
								`onboarding.modal.${integration?.type}.action`,
								`onboarding.modal.${integration?.type}.action.abort`,
								`onboarding.modal.${integration?.type}.flow-error.action`,
								`onboarding.modal.${integration?.type}.flow-error.action.abort`,
								`onboarding.modal.${integration?.key}.action`,
								`onboarding.modal.${integration?.key}.action.abort`,
								`onboarding.modal.${integration?.key}.flow-error.action`,
								`onboarding.modal.${integration?.key}.flow-error.action.abort`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action.abort`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-error.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-error.action.abort`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={abort}
					/>
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationError.displayName = 'IntegrationError';

IntegrationError.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default IntegrationError;
export { useAnalytics };
