import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import LoanLineGraph from '../../../components/loan/graph/lines';

const SectionLineGraph = React.memo(() => {
	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-line-graph',
			)}
		>
			<LoanLineGraph />
		</div>
	);
});

SectionLineGraph.displayName = 'SectionLineGraph';
SectionLineGraph.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionLineGraph;
