import React from 'react';
import { createPortal } from 'react-dom';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { sizeClasses } from '@asteria/component-core/utils';

import animationListener from '@asteria/utils-funcs/animationListener';
import { cn } from '@asteria/utils-funcs/classes';
import useWrapper from '@asteria/utils-hooks/useWrapper';

import Context from './context';

import './index.scss';

const Content = (props) => {
	const {
		size,
		closeOnClickOutside,
		closeOnEscape,
		onClose,
		className,
		children,
	} = props;

	const ref = React.useRef(null);
	const clickRef = React.useRef(null);

	React.useEffect(() => {
		if (!closeOnEscape) {
			return;
		}

		const close = (e) => {
			if (e.keyCode === 27) {
				onClose?.(e);
			}
		};
		document.addEventListener('keydown', close);

		return () => {
			document.removeEventListener('keydown', close);
		};
	}, [onClose, closeOnEscape]);

	const onMouseDown = React.useCallback(
		(event) => {
			if (closeOnClickOutside) {
				clickRef.current = null;

				if (event?.target?.isSameNode?.(ref.current)) {
					clickRef.current = event.target;
				}
			}
		},
		[closeOnClickOutside],
	);

	const onMouseUp = React.useCallback(
		(event) => {
			if (closeOnClickOutside) {
				if (
					event?.target?.isSameNode?.(ref.current) &&
					clickRef?.current
				) {
					return onClose?.(event);
				}
			}
		},
		[closeOnClickOutside, onClose],
	);

	return (
		<div
			className={cn(
				'asteria-component__backdrop',
				'asteria-component__backdrop--variant-modal',
				sizeClasses({ size: size }),
			)}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			tabIndex="-1"
			ref={ref}
		>
			<div
				className={cn(
					'asteria-component__modal',
					sizeClasses({ size: size }),
					className,
				)}
			>
				{children}
			</div>
			<div className={cn('asteria-component__background', className)} />
		</div>
	);
};

Content.displayName = 'Content';

Content.propTypes = {
	size: SizeProp(),
	closeOnClickOutside: PropTypes.bool,
	closeOnEscape: PropTypes.bool,
	onClose: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.node,
};

const Modal = (props) => {
	const { onClose, open } = props;

	const wrapper = useWrapper('asteria-modals-container');

	const context = React.useMemo(() => {
		return {
			closeModal: onClose,
		};
	}, [onClose]);

	const Component = (
		<Context.Provider value={context}>
			<CSSTransition
				in={open}
				appear
				unmountOnExit
				classNames="asteria-modal"
				addEndListener={animationListener}
			>
				<Content {...props} />
			</CSSTransition>
		</Context.Provider>
	);

	return <>{createPortal(Component, wrapper)}</>;
};

Modal.displayName = 'Modal';

Modal.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,

	size: SizeProp('modal'),
	closeOnClickOutside: PropTypes.bool,
	closeOnEscape: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
};

Modal.defaultProps = {
	open: true,
	size: 'md',
	closeOnClickOutside: true,
	closeOnEscape: true,
};

export { Content };
export default Modal;
