import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Progress from '@asteria/component-core/progress';
import { Text } from '@asteria/component-core/typography';
import useContenter from '@asteria/component-core/utils/useContenter';
import { FooterSection } from '@asteria/component-core/wrapper';

import Input from '@asteria/component-form/input';
import Contenter from '@asteria/component-tools/contenter';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Step, {
	Content,
	Footer,
	Header,
	StepInner,
} from '../../components/Step';
import { useFlow } from '../../hooks';
import IntegrationError from '../Error';
import IntegrationSuccess from '../Success';

import Actions from './Actions';

import './styles.scss';

function getRedirectURI(integration) {
	return integration?.config?.server?.urls?.REDIRECT?.[0]?.formattedHref;
}

function useActions(integration) {
	const [isOpen, setOpen] = React.useState(false);
	const [actions, setActions] = React.useState(integration?.actions ?? []);

	React.useEffect(() => {
		const URI = getRedirectURI(integration);

		const mode = integration?.config?.client?.mode;

		if (!URI || isOpen || mode == 'sandbox') {
			return;
		}

		setOpen(true);

		const win = window.open('', '_blank');

		if (!win) {
			setActions([{ action: 'browser.enable.popup', status: 'PENDING' }]);
			return;
		}

		win.location = URI;
		win.focus();
	}, [getRedirectURI(integration)]);

	React.useEffect(() => {
		if (integration?.actions?.length) {
			setActions(integration?.actions);
		}
	}, [integration?.actions]);

	return [actions, setActions];
}

function useAnalytics(integration) {
	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.connecting', {
			integration: {
				_id: integration?._id,
				key: integration?.key,
				type: integration?.type,
				lastSync: integration?.lastSync,
				disabled: integration?.disabled,
				status: integration?.status,
				config: {
					connected: integration?.config?.connected,
					errors: integration?.config?.errors,
				},
				actions: integration?.actions,
			},
		});

		return () => {
			Analytics.endFlow('integrations.connecting');
		};
	}, []);

	return 'integrations.connecting';
}

const CustomIntegration = React.memo((props) => {
	const { integration } = props;

	const dispatch = useDispatch();

	const [hasCopied, setCopied] = React.useState(false);

	const flow = useFlow(integration?.type, integration?.key);

	const copyToken = useCallback(() => {
		navigator.clipboard.writeText(
			integration?.config?.server?.integrationAccessToken,
		);

		SnackbarStore.show(dispatch, {
			title: 'snackbar.integration.token.copy.title',
			content: 'snackbar.integration.token.copy.content',
			type: 'integration-token-copy',
			variant: 'success',
			icon: 'clipboard',
			hideActions: true,
		});

		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 4000);
	}, [dispatch, integration?.config?.server?.integrationAccessToken]);

	if (flow?.flow !== 'custom') {
		return null;
	}

	return (
		<>
			<Input
				direction="vertical"
				value={integration?.config?.server?.integrationAccessToken}
				icon="clipboard"
				iconPosition="last"
				iconTooltip={TranslationService.get(
					'integrations.connecting.token.tooltip',
				)}
				onIconClick={copyToken}
				readonly
				uncontrolled
			/>
			<Text className="asteria-component__onboarding-connecting__token">
				{TranslationService.get(
					hasCopied
						? 'integrations.connecting.token.copied'
						: 'integrations.connecting.token.copy',
				)}
			</Text>
		</>
	);
});

CustomIntegration.displayName = 'CustomIntegration';

CustomIntegration.propTypes = {
	integration: PropTypes.object,
	Wrapper: PropTypes.node,
};

const IntegrationConnecting = (props) => {
	const { className, onAction, onSubmit, onClose, type } = props;

	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, _id),
	);

	const [actions, setActions] = useActions(integration);

	const analyticsFlow = useAnalytics(integration);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, onClose],
	);

	const dispatch = useDispatch();
	const store = useStore();

	const content = useContenter(`integrations.${integration?.key}.connecting`);
	const step = `${type}.oauth.info`;

	const handleAction = React.useCallback(
		(action) => {
			const { action: type } = action;

			if (type === 'browser.enable.popup') {
				const URI = getRedirectURI(integration);

				const win = window.open('', '_blank');

				if (!win) {
					return;
				}

				win.location = URI;
				win.focus();

				setActions(integration?.actions);

				return;
			}

			return onAction?.('integrations:action', action);
		},
		[integration, onAction, setActions],
	);

	const abort = React.useCallback(
		(event) => {
			const _id = IntegrationStore.selectors.navigation._id(
				store.getState(),
			);
			const integration = IntegrationStore.selectors.integration(
				store.getState(),
				_id,
			);

			onSubmit?.('integrations:delete', integration);

			Analytics.endFlow(analyticsFlow);
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[store, onSubmit, analyticsFlow, dispatch, onClose],
	);

	const back = React.useCallback(() => {
		const _id = IntegrationStore.selectors.navigation._id(store.getState());
		const integration = IntegrationStore.selectors.integration(
			store.getState(),
			_id,
		);

		onSubmit?.('integrations:delete', integration);

		Analytics.endFlow(analyticsFlow);

		dispatch(
			IntegrationStore.navigation.back(
				JSON.parse(JSON.stringify(integration)),
			),
		);
	}, [store, onSubmit, analyticsFlow, dispatch]);

	const flow = useFlow(integration?.type, integration?.key);

	const integrationName = TranslationService.get(
		[
			'integration.title',
			`integration.title.${integration?.key}`,
			`integration.title.${integration?.type}.${integration?.key}`,
			`integration.${integration?.key}.title`,
			`integration.${integration?.type}.${integration?.key}.title`,
		],
		flow?.name,
	);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-generic__connecting',
				{
					[`asteria-component__onboarding-generic__connecting--type-${type}`]:
						type,
				},
				className,
			)}
			step={step}
		>
			<Header onBack={back} onClose={close}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${integration?.type}.add.title`,
						`integrations.${integration?.key}.add.title`,
						`integrations.${integration?.type}.${integration?.key}.add.title`,
						`integrations.connecting.title`,
						`integrations.${integration?.type}.connecting.title`,
						`integrations.${integration?.key}.connecting.title`,
						`integrations.${integration?.type}.${integration?.key}.connecting.title`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Header>
			<Content step={step}>
				<StepInner>
					<div
						className={cn(
							'asteria-component__onboarding-connecting__wrapper',
							{
								'asteria-component__onboarding-connecting__wrapper--hasActions':
									actions?.length,
							},
						)}
					>
						<div className="asteria-component__onboarding-connecting">
							{!actions?.length ? (
								<>
									<Text>
										{TranslationService.get(
											[
												`integrations.connecting`,
												`integrations.${type}.connecting`,
												`integrations.${type}.${integration?.key}.connecting`,
											],
											undefined,
											{
												integration: integration,
												name: integrationName,
											},
										)}
									</Text>
									<Progress progress={-1} />
									<CustomIntegration
										integration={integration}
									/>
								</>
							) : null}
							<Contenter
								content={content}
								data={{ integration: integration }}
							/>
							<Actions
								integration={integration}
								onAction={handleAction}
								actions={actions}
							/>
						</div>
					</div>
				</StepInner>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${integration?.key}.abort`}
						variant="tertiary"
						label={TranslationService.get(
							[
								'action.abort',
								'button.abort',
								'onboarding.modal.action',
								'onboarding.modal.action.abort',
								'onboarding.modal.flow-connecting.action',
								'onboarding.modal.flow-connecting.action.abort',
								`onboarding.modal.${integration?.type}.action`,
								`onboarding.modal.${integration?.type}.action.abort`,
								`onboarding.modal.${integration?.type}.flow-connecting.action`,
								`onboarding.modal.${integration?.type}.flow-connecting.action.abort`,
								`onboarding.modal.${integration?.key}.action`,
								`onboarding.modal.${integration?.key}.action.abort`,
								`onboarding.modal.${integration?.key}.flow-connecting.action`,
								`onboarding.modal.${integration?.key}.flow-connecting.action.abort`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action.abort`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-connecting.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-connecting.action.abort`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={abort}
					/>
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationConnecting.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

IntegrationConnecting.defaultProps = {};

const IntegrationGateway = (props) => {
	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, _id),
	);

	const isConnected = integration?.config?.connected ?? false;
	const errors = integration?.config?.errors ?? [];

	if (integration?.status?.state !== 'ERROR' && isConnected) {
		return <IntegrationSuccess {...props} />;
	}

	if (integration?.status?.state === 'ERROR' || errors?.length) {
		return <IntegrationError {...props} />;
	}

	return <IntegrationConnecting {...props} />;
};

IntegrationGateway.displayName = 'IntegrationGateway';
IntegrationGateway.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default IntegrationGateway;
export { Actions, useActions, getRedirectURI, CustomIntegration, useAnalytics };
