import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { getViewport } from '@asteria/component-core/utils';

import ForecasterFeedback from '@asteria/component-forecaster/components/feedback';
import Switch from '@asteria/component-form/switch';
import { IntegrationsTeaser } from '@asteria/component-integrations-v2';
import Resizer from '@asteria/component-resizer';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import Navigation from '@asteria/layout/navigation';
import Overview from '@asteria/layout/overview';
import { cn } from '@asteria/utils-funcs/classes';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import ActionbarLogic from './components/actionbar';
import FeedbackLogic from './components/feedback';
import GraphLogic from './components/graph';
import NavigationActions from './components/navigationActions';
import SearchLogic from './components/search';
import TransactionLogic from './components/transactions';
import Updater from './components/updater';

import './styles.scss';

const selectors = {
	isTransactionListShown: createSelector(
		(store) => store?.transactions?.state ?? null,
		(value) => value !== null,
	),
	isForecastCompleted: createSelector(
		(store) =>
			store?.app?.user?.settings?.flags?.isForecastCompleted ?? null,
		(value) => value ?? false,
	),
	size: createSelector(
		(store) => store?.app?.user?.settings?.layout?.size,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const CashflowSearchNavigation = React.memo((props) => {
	const { onAction, onFetch } = props;

	const [searchFocus, setSearchFocus] = React.useState(false);

	const handleFocusChange = React.useCallback(
		(focus) => {
			if (focus) {
				onFetch?.('search');
			}

			setSearchFocus(focus);
		},
		[onFetch],
	);

	const asteriaSearchSwedbank = useFeature('asteria-search-swedbank');
	const asteriaSearchNavigation = useFeature('asteria-search-navigation');

	const handleOpenForecaster = React.useCallback(
		() => onAction?.('forecaster:open'),
		[onAction],
	);

	return (
		<>
			<div className="asteria-search">
				<SearchLogic
					onAction={onAction}
					onFocusChange={handleFocusChange}
				/>
			</div>
			{asteriaSearchNavigation ||
			!asteriaSearchSwedbank ||
			!searchFocus ? (
				<div className="asteria-navigation">
					<Navigation onAction={onAction} />
					<NavigationActions onAction={onAction} />
					<FeatureFlag feature="header-action-forecaster">
						<Switch
							className="asteria-component__switch"
							name="forecaster"
							icon="pencil"
							onChange={handleOpenForecaster}
							tooltip={TranslationService.get(
								'header.action.forecaster.tooltip',
								'Manage your forecast and future',
							)}
							analyticsKey="forecaster.switch.open"
						/>
					</FeatureFlag>
				</div>
			) : null}
		</>
	);
});

CashflowSearchNavigation.displayName = 'CashflowSearchNavigation';
CashflowSearchNavigation.propTypes = {
	onAction: PropTypes.func,
	onFetch: PropTypes.func,
};

const CashflowFeedback = (props) => {
	const { onAction } = props;

	const [showFeedback, setShowFeedback] = React.useState(false);
	const isForecastCompleted = useSelector(selectors.isForecastCompleted);

	const handleFeedbackAction = useCallback(
		(action, data) => {
			if (action === 'forecaster:feedback:submit') {
				return onAction?.(...Object.values(data ?? {}));
			}

			if (action === 'forecaster:feedback:support') {
				return onAction?.('go', '/support');
			}
		},
		[onAction],
	);

	React.useEffect(() => {
		const selector = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--swedbank-feedback-selector');

		let node = false;

		if (selector) {
			node = document.querySelector(selector);
		}

		setShowFeedback(!node);
	}, []);

	if (!(showFeedback && isForecastCompleted)) {
		return null;
	}

	return (
		<ForecasterFeedback
			className="cashflow-forecast-feedback"
			onAction={handleFeedbackAction}
		/>
	);
};

CashflowFeedback.propTypes = {
	onAction: PropTypes.func,
};

const CashflowView = React.memo((props) => {
	const {
		onAction,
		onFetch,
		transactions,
		feedback,
		wrapperRef,
		accessToken,
	} = props;

	const [renderError, setRenderError] = React.useState(false);

	const [, viewportHeight] = getViewport();
	const layoutSize = useSelector(selectors.size);

	const hasCashflowResizer = useFeature('cashflow-resizer');
	const hasLargeBreakpoint = useFeature('cashflow-graph-breakpoint-large');
	const hasMediumBreakpoint = useFeature('cashflow-graph-breakpoint-medium');

	const cashflowLayoutSize = useMemo(() => {
		if (hasLargeBreakpoint) {
			return 'large';
		}

		if (hasMediumBreakpoint) {
			return 'medium';
		}

		if (viewportHeight < 1000) {
			return 'medium';
		}

		return 'large';
	}, [hasLargeBreakpoint, hasMediumBreakpoint, viewportHeight]);

	let height = layoutSize?.[viewportHeight]?.height;

	if (Number.parseFloat(height)) {
		height = Number.parseFloat(height);
	} else {
		height = null;
	}

	const handleResize = React.useCallback(
		(size) => onAction?.('layout:resize', size),
		[onAction],
	);

	const isTransactionListShown = useSelector(
		selectors.isTransactionListShown,
	);

	const [overviewNode, setOverviewRef] = React.useState(null);

	const ResizerMinValue = React.useMemo(() => ({ first: 300 }), []);

	const searchNode = wrapperRef?.querySelector?.('.asteria-search');

	const maxHeight = wrapperRef?.offsetHeight
		? wrapperRef?.offsetHeight -
		  (overviewNode?.offsetHeight ?? 0) -
		  (searchNode?.offsetHeight ?? 0) -
		  16 * 2 -
		  20
		: null;

	const ResizerMaxValue = React.useMemo(
		() => ({ first: maxHeight }),
		[maxHeight],
	);

	const ResizerInitialValue = React.useMemo(
		() => ({
			first: height ? Math.max(Math.min(height, maxHeight), 300) : 300,
		}),
		[height, maxHeight],
	);

	const ResizerTooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get([
				'layout.resizable.handle',
				'cashflow.layout.resizable.handle',
			]),
			once: 'cashflow:resizer:handle',
		}),
		[],
	);

	useTourShowAction();

	return (
		<>
			<Updater onSubmit={onFetch} accessToken={accessToken} />

			<FeatureFlag feature="test-error-view">
				<Button
					className="asteria-test-error"
					onClick={() => {
						setRenderError(true);
					}}
					label="Throw error"
				/>

				{
					// eslint-disable-next-line react/jsx-no-undef
					renderError && <RenderError />
				}
			</FeatureFlag>
			<FeatureFlag feature="feedback-dialog">
				{feedback?.state && (
					<FeedbackLogic
						{...feedback?.state}
						onAction={onAction}
						onClose={feedback?.reset}
					/>
				)}
			</FeatureFlag>

			<div className="asteria-company-overview">
				<CashflowFeedback onAction={onAction} />
				<Overview onAction={onAction} ref={setOverviewRef} />
			</div>

			<CashflowSearchNavigation onAction={onAction} onFetch={onFetch} />

			{/* {args.settings && (
				<div className="asteria-settings">
					<Icon icon="edit-dots" /> Settings
				</div>
			)} */}

			<div
				className={cn('asteria-component__cashflow', {
					'asteria--feature-resizer': hasCashflowResizer,
					[`asteria--size-${cashflowLayoutSize}`]:
						!hasCashflowResizer,
				})}
				style={{ '--viewport-height': `${maxHeight - 106}px` }}
			>
				<Resizer
					active={isTransactionListShown && maxHeight !== null}
					min={ResizerMinValue}
					max={ResizerMaxValue}
					initial={ResizerInitialValue}
					analyticsKey="cashflow.graph.resizer"
					tooltip={ResizerTooltip}
					hidden={!hasCashflowResizer}
				>
					<div className="asteria-graphs">
						<GraphLogic
							onAction={onAction}
							onFetch={onFetch}
							onResize={handleResize}
						/>
					</div>

					<div className="asteria-cashflow-details">
						<IntegrationsTeaser
							onAction={onAction}
							onSubmit={onFetch}
						/>
						<TransactionLogic
							showAddTransactions={transactions?.state}
							onAbort={transactions?.reset}
							onAction={onAction}
							onFetch={onFetch}
						/>
					</div>
				</Resizer>
			</div>

			<ActionbarLogic onAction={onAction} />
		</>
	);
});

CashflowView.displayName = 'CashflowView';

CashflowView.propTypes = {
	onAction: PropTypes.func,
	onFetch: PropTypes.func,

	integrations: PropTypes.shape({
		state: PropTypes.any,
		set: PropTypes.func,
		reset: PropTypes.func,
	}),

	statement: PropTypes.shape({
		state: PropTypes.any,
		set: PropTypes.func,
		reset: PropTypes.func,
	}),

	transactions: PropTypes.shape({
		state: PropTypes.any,
		set: PropTypes.func,
		reset: PropTypes.func,
	}),

	feedback: PropTypes.shape({
		state: PropTypes.any,
		set: PropTypes.func,
		reset: PropTypes.func,
	}),

	wrapperRef: PropTypes.node,
	accessToken: PropTypes.string,
};

export default React.memo(CashflowView);
