import React from 'react';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import ElementAnchor from '@asteria/component-core/utils/elementAnchor';

import { SatisfactionSelectorOpen } from '@asteria/component-forecaster/components/selector/satisfaction';

import animationListener from '@asteria/utils-funcs/animationListener';
import { useToggleState } from '@asteria/utils-funcs/state';

/**
 * @typedef Props
 * @property { string } size
 * @property { number } value
 * @property { (value: number) => void } onChange
 */

/** @type { React.FC<Pick<Props, 'value' | 'onChange'>> } */
const SatisfactionSelector = React.memo(function SatisfactionSelector({
	value,
	onChange,
}) {
	return (
		<div className="bg-white border border-solid border-border-normal rounded p-4">
			<SatisfactionSelectorOpen active={value} onSelect={onChange} />
		</div>
	);
});

SatisfactionSelector.propTypes = {
	value: PropTypes.number,
	onChange: PropTypes.func,
};

/**
 * @param { Pick<Props, 'value'> } options
 */
function getIconByValue({ value }) {
	if (value <= 0.2) {
		return 'face-1';
	}

	if (value > 0.2 && value <= 0.4) {
		return 'face-2';
	}

	if (value > 0.4 && value <= 0.6) {
		return 'face-3';
	}

	if (value > 0.6 && value <= 0.8) {
		return 'face-4';
	}

	return 'face-5';
}

/** @type { React.FC<Props> } */
const Satisfaction = React.memo(function Satisfaction(props) {
	const { size, value, onChange } = props;

	const ref = React.useRef(null);
	const [open, actions] = useToggleState();

	const handleChange = React.useCallback(
		(value) => {
			actions.close();

			return onChange?.(value);
		},
		[actions, onChange],
	);

	return (
		<>
			<Button
				icon={getIconByValue({ value })}
				size={size}
				onClick={actions.toggle}
				ref={ref}
			/>
			<CSSTransition
				in={open}
				appear
				unmountOnExit
				classNames="my-node"
				addEndListener={animationListener}
			>
				<ElementAnchor element={ref} placement="right">
					<SatisfactionSelector
						value={value}
						onChange={handleChange}
					/>
				</ElementAnchor>
			</CSSTransition>
		</>
	);
});

Satisfaction.displayName = 'Satisfaction';

Satisfaction.propTypes = {
	size: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};

export default Satisfaction;
