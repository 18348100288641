import store from '@asteria/datalayer';

import Analytics from '@asteria/utils-analytics';

import * as FeedbackAPI from '../api/feedback';

/**
 * @param { { dispatch: unknown, accessToken: string, event: { type?: 'feedback:save', data?: unknown } } } options
 */
export const onAction = (options) => {
	const { event, dispatch, accessToken } = options;

	if (event?.type === 'feedback:save') {
		const user = store.getState()?.app?.user;
		const userId = user?.id ?? null;

		Analytics.event('feedback.change', {
			user: user,
			userId: userId,
			data: event?.data,
		});

		return FeedbackAPI.create({
			accessToken: accessToken,
			id: userId,
			feedback: event?.data,
			dispatch: dispatch,
		});
	} else if (event?.type === 'feedback:reset') {
		return FeedbackAPI.reset({
			accessToken: accessToken,
			dispatch: dispatch,
		});
	}
};
