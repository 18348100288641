export const TAB_KEYCODE = 9;

//  selector adapted from https://gist.github.com/r3lk3r/0030bab99347a2326334e00b23188cab#file-focusloopingutil-js
export const focusableSelector =
	'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex]:not([tabindex^="-"]):not([disabled]), [contenteditable]';

export let CardinalOrientation = {
	EAST: 'east',
	SOUTH: 'south',
	WEST: 'west',
	NORTH: 'north',
	CENTER: 'center',
	EASTNORTH: 'east-north',
	EASTSOUTH: 'east-south',
	SOUTHEAST: 'south-east',
	SOUTHWEST: 'south-west',
	WESTSOUTH: 'west-south',
	WESTNORTH: 'west-north',
	NORTHWEST: 'north-west',
	NORTHEAST: 'north-east',
};

export function isValidCoords(coords) {
	if (!coords) {
		return false;
	} else if ((!coords.x && coords.x !== 0) || (!coords.y && coords.y !== 0)) {
		return false;
	} else {
		return true;
	}
}
export function isValidDims(dims) {
	if (!dims) {
		return false;
	} else if (
		(!dims.height && dims.height !== 0) ||
		(!dims.width && dims.height !== 0)
	) {
		return false;
	} else if (dims.height < 0 || dims.width < 0) {
		return false;
	} else {
		return true;
	}
}
export function dist(a, b) {
	if (!isValidCoords(a) || !isValidCoords(b)) {
		return;
	}
	return Math.sqrt(
		Math.pow(Math.abs(a.x - b.x), 2) + Math.pow(Math.abs(a.y - b.y), 2),
	);
}
export function areaDiff(a, b) {
	if (!isValidDims(a) || !isValidDims(b)) {
		return;
	}

	return Math.abs(a.height * a.width - b.height * b.width);
}
export function getElementCoords(element) {
	if (!element) {
		return;
	}

	const elementData = element.getBoundingClientRect();
	return {
		x: elementData.left,
		y: elementData.top,
	};
}
export function getElementDims(element) {
	if (!element) {
		return;
	}

	const { width, height } = element.getBoundingClientRect();
	return { width, height };
}

export function getNearestScrollAncestor(element) {
	const regex = /(auto|scroll)/;

	const style = (el, prop) =>
		getComputedStyle(el, null).getPropertyValue(prop);

	const scroll = (el) =>
		regex.test(
			style(el, 'overflow') +
				style(el, 'overflow-y') +
				style(el, 'overflow-x'),
		);

	if (!element || isDefaultScrollingElement(element)) {
		return getDefaultScrollingElement();
	} else {
		if (scroll(element)) {
			return element;
		} else {
			return getNearestScrollAncestor(element.parentElement);
		}
	}
} //https://github.com/GreenGremlin/scroll-doc/blob/master/index.js

export function getDefaultScrollingElement() {
	const windowStart = window.pageYOffset; //slightly better support than scrollY

	document.documentElement.scrollTop = windowStart + 1;

	if (window.pageXOffset > windowStart) {
		document.documentElement.scrollTop = windowStart; //reset

		return document.documentElement;
	} else {
		return document.scrollingElement || document.body;
	}
}
export function isDefaultScrollingElement(root) {
	return (
		root.isSameNode(document.body) ||
		root.isSameNode(document.scrollingElement) ||
		root.isSameNode(document.documentElement)
	);
} //if we're not putting the portal in a custom container, it needs to be at the body level

export function getValidPortalRoot(root) {
	// check for the potential default scrolling elements that might be returned from the above function
	return isDefaultScrollingElement(root) ? document.body : root;
}

export function getCombinedData(aCoords, aDims, bCoords, bDims) {
	// generates similar data as getBoundingClientRect but using hypothetical positions
	const generateBounds = (coords, dims) => {
		return {
			left: coords.x,
			right: coords.x + dims.width,
			top: coords.y,
			bottom: coords.y + dims.height,
		};
	};

	const mostExtreme = (a, b, largest) => {
		return a > b ? (largest ? a : b) : largest ? b : a;
	};

	const aBounds = generateBounds(aCoords, aDims);
	const bBounds = generateBounds(bCoords, bDims);
	const left = mostExtreme(aBounds.left, bBounds.left, false);
	const right = mostExtreme(aBounds.right, bBounds.right, true);
	const top = mostExtreme(aBounds.top, bBounds.top, false);
	const bottom = mostExtreme(aBounds.bottom, bBounds.bottom, true);
	return {
		coords: {
			x: left,
			y: top,
		},
		dims: {
			height: bottom - top,
			width: right - left,
		},
	};
} // determines if a can fit within b

export function fitsWithin(aDims, bDims) {
	if (!isValidDims(aDims) || !isValidDims(bDims)) {
		return false;
	}

	return aDims.height <= bDims.height && aDims.width <= bDims.width;
} // determines if a does fit within b at the given coords

export function isWithinAt(aDims, bDims, aCoords, bCoords) {
	if (!isValidDims(aDims) || !isValidDims(bDims)) {
		return false;
	}

	const validCoordsA = isValidCoords(aCoords)
		? aCoords
		: {
				x: 0,
				y: 0,
		  };
	const validCoordsB = isValidCoords(bCoords)
		? bCoords
		: {
				x: 0,
				y: 0,
		  };
	const fitsDims = fitsWithin(aDims, bDims);
	const fitsHorizontally =
		validCoordsA.x >= validCoordsB.x &&
		validCoordsA.x + aDims.width <= validCoordsB.x + bDims.width;
	const fitsVertically =
		validCoordsA.y >= validCoordsB.y &&
		validCoordsA.y + aDims.height <= validCoordsB.y + bDims.height;
	return fitsDims && fitsHorizontally && fitsVertically;
}
export function getFocusableElements(root, includeSelf) {
	const focusableChildren = root.querySelectorAll(focusableSelector);
	let array = [];

	if (includeSelf && root.matches(focusableSelector)) {
		array.push(root);
	}

	if (focusableChildren.length > 0) {
		focusableChildren.forEach((el) => array.push(el));
	}

	return array;
	// eslint-disable-next-line spellcheck/spell-checker
} // helper function to get first/last focusable elements if possible

export function getEdgeFocusables(defaultElement, container, includeSelf) {
	if (container) {
		const containerFocusables = getFocusableElements(
			container,
			includeSelf,
		);

		if (containerFocusables.length > 0) {
			return {
				start: containerFocusables[0],
				end: containerFocusables[containerFocusables.length - 1],
			};
		}
	}

	return {
		start: defaultElement,
		end: defaultElement,
	};
}
export function getTargetInfo(root, target) {
	if (!root || !target) {
		return;
	}

	const dims = getElementDims(target);
	const coords = getTargetPosition(root, target);
	return {
		coords,
		dims,
	};
}
export function isForeignTarget(root, selector) {
	return !root.querySelector(selector);
}

export function getCurrentScrollOffset(root) {
	return {
		x: root.scrollLeft,
		y: root.scrollTop,
	};
}
export function addScrollOffset(root, coords) {
	const curOffset = getCurrentScrollOffset(root);
	return {
		x: coords.x + curOffset.x,
		y: coords.y + curOffset.y,
	};
}
export function addAppropriateOffset(root, coords) {
	if (!coords || !root) {
		return;
	} // if there's a custom root, then we need to offset by that root's positioning relative to the window
	// before adjusting for its scroll values

	if (!isDefaultScrollingElement(root)) {
		const rootCoords = getElementCoords(root);
		return addScrollOffset(root, {
			x: coords.x - rootCoords.x,
			y: coords.y - rootCoords.y,
		});
	} else {
		return addScrollOffset(root, coords);
	}
}
// eslint-disable-next-line spellcheck/spell-checker
/* apply a common offset calculation where b is centered relative to a. If b is larger than a, the result is that a will be centered within b.
	b is the moveable element: the returned value will specify where to place b to achieve centering. */

export function applyCenterOffset(aCoords, aDims, b) {
	return {
		x: aCoords.x + aDims.width / 2 - b.width / 2,
		y: aCoords.y + aDims.height / 2 - b.height / 2,
	};
}
export function centerViewportAroundElements(root, a, b, aPosition, bPosition) {
	if (!root || !a || !b) {
		return;
	}

	const aCoords = aPosition || getElementCoords(a);
	const bCoords = bPosition || getElementCoords(b);
	const aDims = getElementDims(a);
	const bDims = getElementDims(b);
	const { coords, dims } = getCombinedData(aCoords, aDims, bCoords, bDims);
	return centerViewportAround(root, coords, dims);
}
export function centerViewportAround(root, coords, dims) {
	return applyCenterOffset(coords, dims, getViewportDims(root));
} // get the coordinates the viewport would need to be placed for the element to be centered

export function centerViewportAroundElement(root, element) {
	const elementDims = getElementDims(element);
	const elementCoords = getElementCoords(element);
	return centerViewportAround(root, elementCoords, elementDims);
	// eslint-disable-next-line spellcheck/spell-checker
} // get the center coord of the viewport. If element is provided, the return value is the origin
// which would align that element's center with the viewport center. If atViewportPosition is provided,
// gets the viewport's center at that position

export function getViewportCenter(root, element, atViewportPosition) {
	if (!root) {
		return;
	}

	const startCoords = atViewportPosition || getViewportStart(root);
	const viewportDims = getViewportDims(root);
	const elementDims = element
		? getElementDims(element)
		: {
				height: 0,
				width: 0,
		  };
	return applyCenterOffset(startCoords, viewportDims, elementDims);
}

export function scrollToElement(root, element, disableSmoothScrolling) {
	if (!root || !element) {
		return;
	}

	const coords = addAppropriateOffset(
		root,
		centerViewportAroundElement(root, element),
	);
	scrollToDestination(root, coords, disableSmoothScrolling);
}
export function scrollToDestination(root, destination, disableSmoothScrolling) {
	if (!root || !destination) {
		return;
	} // check if the 'scrollBehavior' property is supported. Support for this property is consistent
	// with support for scrollToOptions, and if it's supported we can scroll smoothly

	const smoothScrollingIsSupported =
		'scrollBehavior' in document.documentElement.style;

	if (smoothScrollingIsSupported && !disableSmoothScrolling) {
		const scrollOptions = {
			top: destination.y,
			left: destination.x,
			behavior: 'smooth',
		};
		root.scrollTo(scrollOptions);
	} else {
		root.scrollTop = destination.y;
		root.scrollLeft = destination.x;
	}
}

export function getIdString(base, identifier) {
	return `${base}${identifier ? `-${identifier}` : ``}`;
}
export function setTargetWatcher(callback, interval) {
	const intervalId = window.setInterval(callback, interval);
	return () => window.clearInterval(intervalId);
}
export function setTourUpdateListener(args) {
	const { update, customSetListener, customRemoveListener, event } = {
		event: 'resize',
		...args,
	};

	if (customSetListener && customRemoveListener) {
		customSetListener(update);
		return () => customRemoveListener(update);
	} else {
		window.addEventListener(event, update);
		return () => window.removeEventListener(event, update);
	}
}

// helper function to create a keyboard focus trap, potentially including multiple elements
function getFocusTrapHandler(args) {
	const { start, end, beforeStart, afterEnd, lightningRod } = args;
	return (e) => {
		if (e.keyCode === TAB_KEYCODE) {
			if (e.shiftKey && e.target === start) {
				e.preventDefault();
				beforeStart ? beforeStart.focus() : end.focus();
			} else if (!e.shiftKey && e.target === end) {
				e.preventDefault();
				afterEnd ? afterEnd.focus() : start.focus();
			} else if (e.target === lightningRod) {
				e.preventDefault();
				start.focus();
			}
		}
	};
}

export const setFocusTrap = (
	tooltipContainer,
	target,
	disableMaskInteraction,
) => {
	if (!tooltipContainer) {
		return;
	}

	const { start: tooltipFirst, end: tooltipLast } = getEdgeFocusables(
		tooltipContainer,
		tooltipContainer,
	);
	const { start: targetFirst, end: targetLast } = getEdgeFocusables(
		undefined,
		target,
		true,
	);
	let tooltipBeforeStart;
	let tooltipAfterEnd;
	let targetTrapHandler;

	if (target && !disableMaskInteraction && targetFirst && targetLast) {
		tooltipAfterEnd = targetFirst;
		tooltipBeforeStart = targetLast;
		targetTrapHandler = getFocusTrapHandler({
			start: targetFirst,
			end: targetLast,
			beforeStart: tooltipLast,
			afterEnd: tooltipFirst,
		});
		target.addEventListener('keydown', targetTrapHandler);
	}

	const tooltipTrapHandler = getFocusTrapHandler({
		start: tooltipFirst,
		end: tooltipLast,
		beforeStart: tooltipBeforeStart,
		afterEnd: tooltipAfterEnd,
		lightningRod: tooltipContainer,
	});
	tooltipContainer.addEventListener('keydown', tooltipTrapHandler);
	return () => {
		if (target) {
			target.removeEventListener('keydown', targetTrapHandler);
		}

		tooltipContainer.removeEventListener('keydown', tooltipTrapHandler);
	};
};

function naiveShouldScroll(args) {
	const { root, tooltip, tooltipPosition, target } = args;

	if (!isElementInView(root, tooltip, tooltipPosition)) {
		return true;
	}

	if (!isElementInView(root, target)) {
		return fitsWithin(getElementDims(target), getViewportDims(root));
	}

	return false;
}

export function shouldScroll(args) {
	const {
		root,
		tooltip,
		target,
		disableAutoScroll,
		allowForeignTarget,
		selector: targetSelector,
	} = args;

	if (!root || !tooltip || !target) {
		return false;
	}

	if (disableAutoScroll) {
		return false;
	}

	if (allowForeignTarget && targetSelector) {
		return !isForeignTarget(root, targetSelector);
	}

	return naiveShouldScroll({ ...args });
}
export function targetChanged(args) {
	const { root, target, targetCoords, targetDims, rerenderTolerance } = args;

	if (!target && !targetCoords && !targetDims) {
		return false;
		// eslint-disable-next-line spellcheck/spell-checker
	} // when the target / target data are out of sync. usually due to a movingTarget, i.e. the target arg is more up to date than the pos/dims args

	if (
		(!target && targetCoords && targetDims) ||
		(target && !targetCoords && !targetDims)
	) {
		return true;
	}

	const currentTargetSize = getElementDims(target);
	const currentTargetPosition = getTargetPosition(root, target);
	const sizeChanged =
		areaDiff(currentTargetSize, targetDims) > rerenderTolerance;
	const positionChanged =
		dist(currentTargetPosition, targetCoords) > rerenderTolerance;
	return sizeChanged || positionChanged;
}
// eslint-disable-next-line spellcheck/spell-checker
/*	if there's no target, we need to ensure that the tooltip is centered, even if the window/container/scroll changes
		if a target exists, there's not a tooltip desync in this context; there are two other functions
		to determine if the tooltip/target are out of sync - this is solely for non-target cases */
export function tooltipDesync(args) {
	const { target, root, tooltip, tooltipPosition: currentPosition } = args;

	if (target || !root || !tooltip) {
		return false;
	}

	const newPosition = getTooltipPosition({ ...args }); // if there's a difference between the newly calculated position and the current position, we need to update

	return dist(newPosition.coords, currentPosition) !== 0;
}
export function shouldUpdate(args) {
	const { root, tooltip } = args;

	if (!root || !tooltip) {
		return false;
	}

	return (
		targetChanged({ ...args }) ||
		shouldScroll({ ...args }) ||
		tooltipDesync({ ...args })
	);
}
export const takeActionIfValid = async (action, actionValidator) => {
	if (actionValidator) {
		const valid = await actionValidator();

		if (valid) {
			action();
		}
	} else {
		action();
	}
};
export const setNextOnTargetClick = (target, next, validateNext) => {
	if (!target) {
		return;
	}
	const clickHandler = () => {
		const actionWithCleanup = () => {
			next(true);
			target.removeEventListener('click', clickHandler);
		};

		takeActionIfValid(actionWithCleanup, validateNext);
	};

	target.addEventListener('click', clickHandler);
	return () => target.removeEventListener('click', clickHandler);
};

export function getViewportDims(root) {
	return {
		width: root.clientWidth,
		height: root.clientHeight,
	};
}
export function getViewportScrollDims(root) {
	return {
		width: root.scrollWidth,
		height: root.scrollHeight,
	};
}
export function getViewportStart(root) {
	if (isDefaultScrollingElement(root)) {
		return {
			x: 0,
			y: 0,
		};
	} else {
		return getElementCoords(root);
	}
}
export function getViewportEnd(root) {
	const startCoords = getViewportStart(root);
	return {
		x: startCoords.x + root.clientWidth,
		y: startCoords.y + root.clientHeight,
	};
}
export function getViewportScrollStart(root) {
	const curScrollOffset = getCurrentScrollOffset(root);
	const start = getViewportStart(root);
	return {
		x: start.x - curScrollOffset.x,
		y: start.y - curScrollOffset.y,
	};
}
export function getViewportScrollEnd(root) {
	const startCoords = getViewportScrollStart(root);
	const { width, height } = getViewportScrollDims(root);
	return {
		x: startCoords.x + width,
		y: startCoords.y + height,
	};
}
export function isElementInView(root, element, atPosition, needsAdjusting) {
	if (!root || !element) {
		return false;
	}

	const explicitPosition =
		atPosition &&
		(needsAdjusting ? addAppropriateOffset(root, atPosition) : atPosition);
	const position =
		explicitPosition ||
		addAppropriateOffset(root, getElementCoords(element));
	const elementDims = getElementDims(element);
	const startCoords = addAppropriateOffset(root, getViewportStart(root));
	const viewportDims = getViewportDims(root);
	return isWithinAt(elementDims, viewportDims, position, startCoords);
}

export function getScrolledViewportPosition(root, scrollDestination) {
	const dims = getViewportDims(root);
	const startCoords = getViewportScrollStart(root);
	const endCoords = getViewportScrollEnd(root);
	const rightmost = endCoords.x - dims.width;
	const bottommost = endCoords.y - dims.height;
	let coords = scrollDestination;

	if (scrollDestination.x < startCoords.x) {
		coords.x = startCoords.x;
	} else if (scrollDestination.x > rightmost) {
		coords.x = rightmost;
	} else {
		coords.x = scrollDestination.x;
	}

	if (scrollDestination.y < startCoords.y) {
		coords.y = startCoords.y;
	} else if (scrollDestination.y > bottommost) {
		coords.y = bottommost;
	} else {
		coords.y = scrollDestination.y;
	}

	return coords;
}

function getTooltipPositionCandidates(
	target,
	tooltip,
	padding,
	tooltipDistance,
	includeAllPositions,
) {
	if (!target || !tooltip) {
		return;
	}

	const tooltipDims = getElementDims(tooltip);
	const targetCoords = getElementCoords(target);
	const targetDims = getElementDims(target);
	const centerX = targetCoords.x - (tooltipDims.width - targetDims.width) / 2;
	const centerY =
		targetCoords.y - (tooltipDims.height - targetDims.height) / 2;
	const eastOffset =
		targetCoords.x + targetDims.width + padding + tooltipDistance;
	const southOffset =
		targetCoords.y + targetDims.height + padding + tooltipDistance;
	const westOffset =
		targetCoords.x - tooltipDims.width - padding - tooltipDistance;
	const northOffset =
		targetCoords.y - tooltipDims.height - padding - tooltipDistance;
	const east = {
		x: eastOffset,
		y: centerY,
	};
	const south = {
		x: centerX,
		y: southOffset,
	};
	const west = {
		x: westOffset,
		y: centerY,
	};
	const north = {
		x: centerX,
		y: northOffset,
	};
	const center = applyCenterOffset(targetCoords, targetDims, tooltipDims);
	const standardPositions = [
		{
			orientation: CardinalOrientation.EAST,
			coords: east,
		},
		{
			orientation: CardinalOrientation.SOUTH,
			coords: south,
		},
		{
			orientation: CardinalOrientation.WEST,
			coords: west,
		},
		{
			orientation: CardinalOrientation.NORTH,
			coords: north,
		},
	];
	let additionalPositions;

	if (includeAllPositions) {
		const eastAlign =
			targetCoords.x - (tooltipDims.width - targetDims.width) + padding;
		const southAlign =
			targetCoords.y - (tooltipDims.height - targetDims.height) + padding;
		const westAlign = targetCoords.x - padding;
		const northAlign = targetCoords.y - padding;
		const eastNorth = {
			x: eastOffset,
			y: northAlign,
		};
		const eastSouth = {
			x: eastOffset,
			y: southAlign,
		};
		const southEast = {
			x: eastAlign,
			y: southOffset,
		};
		const southWest = {
			x: westAlign,
			y: southOffset,
		};
		const westSouth = {
			x: westOffset,
			y: southAlign,
		};
		const westNorth = {
			x: westOffset,
			y: northAlign,
		};
		const northWest = {
			x: westAlign,
			y: northOffset,
		};
		const northEast = {
			x: eastAlign,
			y: northOffset,
		};
		additionalPositions = [
			{
				orientation: CardinalOrientation.EASTNORTH,
				coords: eastNorth,
			},
			{
				orientation: CardinalOrientation.EASTSOUTH,
				coords: eastSouth,
			},
			{
				orientation: CardinalOrientation.SOUTHEAST,
				coords: southEast,
			},
			{
				orientation: CardinalOrientation.SOUTHWEST,
				coords: southWest,
			},
			{
				orientation: CardinalOrientation.WESTSOUTH,
				coords: westSouth,
			},
			{
				orientation: CardinalOrientation.WESTNORTH,
				coords: westNorth,
			},
			{
				orientation: CardinalOrientation.NORTHWEST,
				coords: northWest,
			},
			{
				orientation: CardinalOrientation.NORTHEAST,
				coords: northEast,
			},
		];
	}

	return [
		...standardPositions,
		...additionalPositions,
		{
			orientation: CardinalOrientation.CENTER,
			coords: center,
		},
	];
} // simple reducer who selects for coordinates closest to the current center of the viewport

function getCenterReducer(root, tooltip, target, predictViewport) {
	const currentCenter = getViewportCenter(root, tooltip); // store the center of the predicted viewport location with the tooltip at acc
	// to have a meaningful distance comparison

	let accCenter = currentCenter;

	const getCenter = (coords) => {
		if (
			predictViewport &&
			(!isElementInView(root, target) ||
				!isElementInView(root, tooltip, coords, true))
		) {
			return getViewportCenter(
				root,
				tooltip,
				getScrolledViewportPosition(
					root,
					centerViewportAroundElements(root, tooltip, target, coords),
				),
			);
		} else {
			return currentCenter;
		}
	};

	return (acc, cur, ind, arr) => {
		if (cur.orientation === CardinalOrientation.CENTER) {
			//ignore centered coords since those will always be closest to the center
			if (ind === arr.length - 1 && acc === undefined) {
				//unless  we're at the end and we still haven't picked a coord
				return cur;
			} else {
				return acc;
			}
		} else if (acc === undefined) {
			accCenter = getCenter(cur.coords);
			return cur;
		} else {
			const center = getCenter(cur.coords);

			if (dist(center, cur.coords) > dist(accCenter, acc.coords)) {
				return acc;
			} else {
				accCenter = center;
				return cur;
			}
		}
	};
} // complex candidate reducer function that tries to place the tooltip as close to the center of the
// screen as possible, even after the screen has scrolled to a particular location.

function chooseBestTooltipPosition(
	preferredCandidates,
	root,
	tooltip,
	target,
	scrollDisabled,
) {
	if (preferredCandidates.length === 1) {
		//if there's only a single pref candidate, use that
		return preferredCandidates[0];
	} else if (scrollDisabled) {
		// if scrolling is disabled, there's not much we can do except use the naive center reducer
		return preferredCandidates.reduce(
			getCenterReducer(root, tooltip, target, false),
			undefined,
		);
	} else {
		// scrolling is allowed, which means we have to figure out:
		// 1. what candidates are valid positions (not out of the scrolling root's bounds)
		// 2. which positions are absolutely compatible (allow both target & tooltip to fit within the viewport at the same time)
		// 3. which positions are currently compatible (allow both target & tooltip to fit with the CURRENT viewport)
		// 4. which of those positions is *best* - use same closest-to-center heuristic.
		// priority is 3 > 2 > 1 for the pool of positions from which 4 is chosen
		const viewportDims = getViewportDims(root);
		const viewportScrollStart = getViewportScrollStart(root);
		const viewportCurrentStart = getViewportStart(root);
		const viewportScrollEnd = getViewportScrollEnd(root);
		const tooltipDims = getElementDims(tooltip);
		const targetDims = getElementDims(target);
		const targetCoords = getElementCoords(target);

		const curriedGetCombinedData = (coords) =>
			getCombinedData(coords, tooltipDims, targetCoords, targetDims);

		const validPositions = preferredCandidates.filter(
			getInBoundsFilter(
				tooltipDims,
				viewportScrollStart,
				viewportScrollEnd,
			),
		);
		const absoluteCompatiblePositions = validPositions.filter(
			getAbsoluteCompatibleArrangementFilter(
				curriedGetCombinedData,
				viewportDims,
			),
		);
		const currentCompatiblePositions = absoluteCompatiblePositions.filter(
			getCurrentInViewFilter(
				curriedGetCombinedData,
				viewportDims,
				viewportCurrentStart,
			),
		); // // if possible, use only those positions which don't force a scroll. Default back to those which can fit in the viewport, even if that means scrolling

		const compatiblePositions =
			currentCompatiblePositions.length > 0
				? currentCompatiblePositions
				: absoluteCompatiblePositions;
		// eslint-disable-next-line spellcheck/spell-checker
		// if there are NO compatible positions, the viewport is too small to accomodate both the target/tooltip, in any arrangement.
		// we default to our valid positions, even if that means placing the elements slightly off screen.

		const filteredList =
			compatiblePositions.length > 0
				? compatiblePositions
				: validPositions;
		return filteredList.reduce(
			getCenterReducer(root, tooltip, target, true),
			undefined,
		);
	}
} // filter out any positions which would have the tooltip be out of the bounds of the root container
// (i.e. in a position that the viewport can't "reach"/scroll to)

function getInBoundsFilter(
	tooltipDims,
	viewportScrollStart,
	viewportScrollEnd,
) {
	return (oc) => {
		const coords = oc.coords;
		return !(
			coords.x < viewportScrollStart.x ||
			coords.y < viewportScrollStart.y ||
			coords.x + tooltipDims.width > viewportScrollEnd.x ||
			coords.y + tooltipDims.height > viewportScrollEnd.y
		);
	};
} // filters out any positions which would cause the target/tooltip to not fit within the viewport

function getAbsoluteCompatibleArrangementFilter(
	curriedGetCombinedData,
	viewportDims,
) {
	return (oc) => {
		const coords = oc.coords; // we only care about the resultant dims but the input coords are critical here

		const { dims: combinedDims } = curriedGetCombinedData(coords);
		return fitsWithin(combinedDims, viewportDims);
	};
}

function getCurrentInViewFilter(
	curriedGetCombinedData,
	viewportDims,
	viewportCurrentStart,
) {
	return (oc) => {
		const coords = oc.coords;
		const { dims: combinedDims, coords: combinedCoords } =
			curriedGetCombinedData(coords);
		return isWithinAt(
			combinedDims,
			viewportDims,
			combinedCoords,
			viewportCurrentStart,
		);
	};
}

function getPreferredCandidates(candidates, orientationPreferences) {
	if (!orientationPreferences || orientationPreferences.length === 0) {
		return candidates;
	} else if (orientationPreferences.length === 1) {
		const specifiedCandidate = candidates.find(
			(oc) => oc.orientation === orientationPreferences[0],
		);

		if (specifiedCandidate) {
			return [specifiedCandidate];
		} else {
			return candidates; // if the specified orientation isn't available for whatever reason, default to standard behavior
		}
	} else {
		const preferenceFilter = (cc) =>
			orientationPreferences.indexOf(cc.orientation) !== -1;

		return candidates.filter(preferenceFilter);
	}
}

function restrictToCurrentViewport(root, coords, dims, padding) {
	if (!root) {
		return coords;
	}

	const viewportStart = getCurrentScrollOffset(root);
	const viewportDims = getViewportDims(root);
	const viewportEnd = {
		x: viewportStart.x + viewportDims.width,
		y: viewportStart.y + viewportDims.height,
	};
	const sx = viewportStart.x + padding;
	const sy = viewportStart.y + padding;
	const ex = viewportEnd.x - dims.width - padding;
	const ey = viewportEnd.y - dims.height - padding;
	let x = coords.x;
	let y = coords.y;

	if (coords.x < sx) {
		x = sx;
	} else if (coords.x + dims.width > ex) {
		x = ex;
	}

	if (coords.y < sy) {
		y = sy;
	} else if (coords.y + dims.height > ey) {
		y = ey;
	}

	return {
		x,
		y,
	};
}

export function getTooltipPosition(args) {
	const {
		target,
		tooltip,
		padding,
		tooltipSeparation,
		orientationPreferences,
		getPositionFromCandidates,
		root: tourRoot,
		disableAutoScroll: scrollDisabled,
		allowForeignTarget,
		selector,
	} = args;
	const center = target
		? getViewportCenter(
				tourRoot,
				tooltip,
				getScrolledViewportPosition(
					tourRoot,
					centerViewportAroundElement(tourRoot, target),
				),
		  )
		: getViewportCenter(tourRoot, tooltip);
	const defaultPosition = addAppropriateOffset(tourRoot, center);

	if (!tooltip || !tourRoot) {
		return;
	}

	if (!target) {
		return {
			orientation: null,
			coords: defaultPosition,
		};
	}

	const foreignTarget =
		allowForeignTarget && isForeignTarget(tourRoot, selector);
	const noScroll = scrollDisabled || foreignTarget;
	const candidates = getTooltipPositionCandidates(
		target,
		tooltip,
		padding,
		tooltipSeparation,
		true,
	);

	const choosePosition =
		getPositionFromCandidates ||
		((cans) =>
			chooseBestTooltipPosition(
				cans,
				tourRoot,
				tooltip,
				target,
				noScroll,
			));

	const rawPosition = choosePosition(
		getPreferredCandidates(candidates, orientationPreferences),
	); //position relative to current viewport

	if (!rawPosition) {
		return {
			orientation: CardinalOrientation.CENTER,
			coords: defaultPosition,
		};
	}

	const adjustedPosition = {
		orientation: rawPosition.orientation,
		coords: addAppropriateOffset(tourRoot, rawPosition.coords),
	};

	if (foreignTarget) {
		return {
			orientation: adjustedPosition.orientation,
			coords: restrictToCurrentViewport(
				tourRoot,
				adjustedPosition.coords,
				getElementDims(tooltip),
				padding + tooltipSeparation,
			),
		};
	}

	return adjustedPosition;
}
export function getTargetPosition(root, target) {
	return addAppropriateOffset(root, getElementCoords(target));
}
