import PropTypes from 'prop-types';

export const Notification = PropTypes.shape({
	type: PropTypes.oneOf(['prefixes', 'text']),
	data: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({ colors: PropTypes.arrayOf(PropTypes.string) }),
		),
		PropTypes.shape({
			key: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.arrayOf(PropTypes.string),
			]),
			default: PropTypes.string,
			lookup: PropTypes.object,
			data: PropTypes.object,
		}),
	]),
});
