import React, { useCallback, useContext, useMemo, useRef } from 'react';

import posed from 'react-pose';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isSameMonth, parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';

import Button from '@asteria/component-core/button';
import Dot from '@asteria/component-core/dot';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { toggleFilter } from '@asteria/datalayer/stores/app';
import * as GraphStore from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useColors from '@asteria/utils-hooks/useColors';

import { GraphActionsContext } from '../../context';
import { useGraphMode } from '../../hooks';
import * as GraphSettingsSelectors from '../../selectors/settings';

import './bar.scss';

const selectors = {
	filters: createSelector(
		(store) => store?.app?.filters,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	shownDots: createSelector(
		(store) => store?.app?.user?.settings?.flags,
		(_, type) => type,
		(flags, type) =>
			type && (flags?.[['bar:dot', type, 'isShown'].join(':')] ?? false),
	),
	mode: createSelector(
		(state) => state?.app?.mode,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Badge = ({ className, badge, bar, group }) => {
	const ref = useRef(null);
	const { types, count } = badge;

	const { clickAction, mouseEnterAction, mouseLeaveAction } =
		useContext(GraphActionsContext);

	const clickHandler = useCallback(
		(e) => {
			e.stopPropagation();
			clickAction && clickAction({ bar, group, badge, source: 'badge' });
		},
		[clickAction, bar, group, badge],
	);

	return (
		<div
			ref={ref}
			className={cn(
				className,
				'asteria-graph-bar-badge',
				types.map((type) => `asteria-graph-bar-badge-${type}`),
			)}
			onClick={clickHandler}
			onKeyPress={clickHandler}
			role="button"
			tabIndex="-1"
			onMouseEnter={() =>
				mouseEnterAction &&
				mouseEnterAction({
					bar,
					group,
					badge,
					target: ref,
				})
			}
			onMouseLeave={() =>
				mouseLeaveAction &&
				mouseLeaveAction({
					bar,
					group,
					badge,
					target: ref,
				})
			}
		>
			<Text size="label">{count > 9 ? '+9' : count}</Text>
		</div>
	);
};

function getVisibleState(object, { layout, type, status, id }) {
	if (!object) {
		return;
	}

	if (object?.part !== 'cashflow') {
		return layout !== 'stacked';
	}

	if (['deposit', 'withdraw'].includes(object?.id)) {
		return object?.id === type;
	}

	if (['signed'].includes(object?.id)) {
		return status === 'unpaid' || status === 'signed';
	}

	if (['overdue', 'unpaid', 'paid', 'forecast'].includes(object?.id)) {
		return object?.id === status;
	}

	return object?.id === id;
}

function useStateHidden({ layout, type, status, id }) {
	const selected = useSelector(
		GraphStore.selectors.legends.selected,
		(a, b) => isEqual(a, b),
	);

	const exists = !!selected.length;
	const result = selected.some((object) =>
		getVisibleState(object, { layout, type, status, id }),
	);

	return React.useMemo(() => {
		if (!exists) {
			return;
		}

		return !result;
	}, [exists, result]);
}

function useStateInvisible({ layout, type, status, id }) {
	const highlight = useSelector(
		GraphStore.selectors.legends.highlight,
		(a, b) => isEqual(a, b),
	);

	const exists = !!highlight;
	const result = getVisibleState(highlight, { layout, type, status, id });

	return React.useMemo(() => {
		if (!exists) {
			return;
		}

		return !result;
	}, [exists, result]);
}

const PartBar = ({ className, height, data = {}, mode }) => {
	const { value = 0, parts = [], types = [], colors } = data;

	const status = data?.data?.status?.toLowerCase?.();
	const type = data?.data?.type?.toLowerCase?.();
	const lastType = types.slice(-1)[0];

	const showUniqueTooltip = useFeature('graph-tooltip-unique-show');
	const handlePartBarClick = useFeature('graph-bar-part-clickable');

	const store = useStore();
	const layout = useSelector(
		(store) => GraphSettingsSelectors.graphLayout(store, { mode }),
		(a, b) => isEqual(a, b),
	);

	const { custom: customColors } = useColors(colors);

	const style = useMemo(() => {
		if (customColors?.length) {
			return {
				height: `${height * 100}%`,
				'--color': customColors?.[0],
			};
		}

		return { height: `${height * 100}%` };
	}, [customColors, height]);

	const dispatch = useDispatch();
	const ref = useRef(null);

	const { mouseEnterAction, mouseLeaveAction } =
		useContext(GraphActionsContext);

	const handleClick = useCallback(() => {
		const status = data?.data?.status;

		const hasFilters = selectors
			.filters(store.getState())
			.some(
				(object) => object?.type === 'status' && object?.id === status,
			);

		if (handlePartBarClick) {
			if (!hasFilters) {
				dispatch(
					toggleFilter({
						id: status,
						item: { code: status },
						type: 'status',
					}),
				);
			}
		}
	}, [data?.data?.status, dispatch, handlePartBarClick, store]);

	const handleMouseEnter = useCallback(() => {
		if (showUniqueTooltip) {
			mouseEnterAction?.({
				bar: data,
				group: {},
				target: ref,
			});
		}
	}, [data, mouseEnterAction, showUniqueTooltip]);

	const handleMouseLeave = useCallback(() => {
		if (showUniqueTooltip) {
			mouseLeaveAction?.({
				bar: data,
				group: {},
			});
		}
	}, [data, mouseLeaveAction, showUniqueTooltip]);

	const hidden = useStateHidden({ layout, type, status, id: lastType });
	const invisible = useStateInvisible({ layout, type, status, id: lastType });

	return (
		<div
			ref={ref}
			className={cn(
				className,
				'asteria-component__graph-part-bar',
				{
					'asteria--state-hidden': hidden && (invisible ?? true),
					'asteria--state-invisible': invisible,
				},
				types.map((type) => `asteria-color__${type}`),
			)}
			style={style}
			onClick={handleClick}
			role="button"
			tabIndex="-1"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{parts.map(({ value: partValue = 0, types: partTypes = [] }) => {
				return (
					<PartBar
						height={partValue / value}
						key={partTypes.join('-')}
						className={cn(
							'asteria-graph-bar-part',
							partTypes.map(
								(partType) => `asteria-graph-part-${partType}`,
							),
							partTypes.map(
								(partType) =>
									`asteria-graph-bar-part-${partType}`,
							),
						)}
						types={partTypes}
						mode={mode}
					/>
				);
			})}
		</div>
	);
};

PartBar.displayName = 'GraphPartBar';

const AnimatedBar = posed.div({
	open: {
		height: (props) => {
			let { height } = props;
			if (height > 1) {
				height = 0;
			}
			return `${height * 100}%`;
		},
		transition: { duration: 1000 },
		delay: ({ index }) => index * 100,
	},
	enter: { height: '0%', transition: { duration: 0 } },
});
AnimatedBar.displayName = 'AnimatedBar';

const BaseBarComponent = React.forwardRef(
	(
		{
			className,
			type,
			onClick,
			onMouseEnter,
			onMouseLeave,
			height,
			index,
			badges,
			children,
		},
		ref,
	) => (
		<AnimatedBar
			className={cn(className, { [`asteria-${type}`]: type })}
			onClick={onClick}
			onKeyPress={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			role="button"
			tabIndex="-1"
			key="bar"
			height={height}
			pose={(height > 0 && height <= 1) || badges ? 'open' : 'closed'}
			poseKey={height}
			index={index}
			ref={ref}
		>
			{children}
		</AnimatedBar>
	),
);

BaseBarComponent.displayName = 'BaseBar';

const AdjustBar = ({ onClick, className }) => (
	<div
		onClick={onClick}
		onKeyPress={onClick}
		role="button"
		tabIndex="-1"
		className={cn(className, 'asteria-graph-bar-actions')}
	>
		<div
			className={cn('asteria-graph-bar-action asteria-adjust-add-event')}
		>
			<Button
				icon="edit"
				size="md"
				variant="link"
				title={TranslationService.get('list.adjustable.total.label')}
				tooltip={TranslationService.get(
					'list.adjustable.getstarted.total.label',
				)}
				className={cn(`asteria-adjustable`)}
			/>
		</div>
	</div>
);
AdjustBar.displayName = 'AdjustBar';

const Bar = React.memo(
	({
		className,
		bar = {},
		group = {},
		height = 0,
		index = 0,
		active = false,
		pointer = false,
		beacon = false,
		rangeType = [],
	}) => {
		const {
			types = [],
			value = 0,
			parts = [],
			data = {},
			badges = [],
		} = bar;

		const ref = useRef(null);
		const indicatorRef = useRef(null);
		const { clickAction, mouseEnterAction, mouseLeaveAction } =
			useContext(GraphActionsContext);

		const isCurrentMonth = isSameMonth(
			group?.id
				? group?.id instanceof Date
					? group?.id
					: parseISO(group?.id)
				: new Date(),
			new Date(),
		);
		const type = data.type;

		const withdrawDotIsShown = useSelector(
			(store) => selectors.shownDots(store, 'withdraw'),
			(a, b) => isEqual(a, b),
		);

		const depositDotIsShown = useSelector(
			(store) => selectors.shownDots(store, 'deposit'),
			(a, b) => isEqual(a, b),
		);

		const mode = useGraphMode();

		const hasIndicatorClickFeature = useFeature(
			'graph-bar-indicator-click',
		);
		const hasBarDotFeature = useFeature('graph-bar-dot');
		const hasIndicatorTooltipFeature = useFeature(
			'graph-bar-indicator-tooltip',
		);

		const hasAdjustBar = useFeature('cashflow.graph.edit.forecaster');
		const hasIndicatorTooltip = useFeature(
			'cashflow-graph-indicator-tooltip',
		);

		const showDot = useMemo(() => {
			if (!hasBarDotFeature || hasAdjustBar) {
				return false;
			}

			if (mode === 'credit' || !isCurrentMonth) {
				return false;
			}

			const showDeposit = type === 'deposit' && !depositDotIsShown;
			const showWithdraw =
				type === 'withdraw' && !withdrawDotIsShown && depositDotIsShown;

			return showDeposit || showWithdraw;
		}, [
			depositDotIsShown,
			hasAdjustBar,
			hasBarDotFeature,
			isCurrentMonth,
			mode,
			type,
			withdrawDotIsShown,
		]);

		const handleDotClick = useCallback(() => {
			Analytics.event(`graph.bar-graph.dot.click`, { group, bar });
			return clickAction?.({ source: 'dot', bar: bar, group: group });
		}, [bar, clickAction, group]);

		const handleClick = useCallback(
			(options) => {
				return (source = 'bar') => {
					if (options?.analyticsKey) {
						Analytics.event(options?.analyticsKey, {
							group,
							bar,
							source,
						});
					}

					return clickAction?.({
						bar,
						group,
						source,
						beacon: beacon ? types : false,
					});
				};
			},
			[clickAction, bar, group, types, beacon],
		);

		const handleMouseEnter = useCallback(
			() =>
				mouseEnterAction?.({
					bar,
					group,
					target: ref?.current ? ref : indicatorRef,
				}),
			[bar, group, mouseEnterAction],
		);

		const handleMouseLeave = useCallback(
			() =>
				mouseLeaveAction?.({
					bar,
					group,
					target: ref?.current ? ref : indicatorRef,
				}),
			[bar, group, mouseLeaveAction],
		);

		return (
			<div
				className={cn(
					className,
					'asteria-component__old__graph__bar',
					types.map((type) => `asteria-${type}`),
					{ 'asteria-state-active': active },
				)}
				role="button"
				tabIndex="-1"
			>
				{value !== 0 || badges.length !== 0 ? (
					<BaseBarComponent
						height={height}
						index={index}
						pointer={pointer}
						ref={ref}
						badges={badges && badges.length > 0}
						className={cn(
							'asteria-graph-bar-main',
							types.map((type) => `asteria-graph-bar-${type}`),
							{ 'asteria-graph-bar-zero': value === 0 },
						)}
						onClick={
							mode !== 'credit'
								? handleClick({
										analyticsKey:
											'graph.bar-graph.bar.click',
								  })
								: undefined
						}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						mode={mode}
					>
						{badges.map((badge) => (
							<Badge
								key={`badge_${badge.types.join('_')}`}
								badge={badge}
								group={group}
								bar={bar}
							/>
						))}
						{parts.map((data, i) => {
							const {
								value: partValue = 0,
								types: partTypes = [],
								parts: subParts = [],
							} = data;

							return (
								<PartBar
									data={data}
									height={partValue / value}
									key={[
										...partTypes,
										...subParts.map((p) => p.types),
										i, // TODO: remove when we get correct data
									].join('-')}
									mode={mode}
								/>
							);
						})}
					</BaseBarComponent>
				) : null}
				{showDot ? (
					<div className="dot-wrapper">
						<Dot
							tooltip={TranslationService.get([
								'graph.dot.tooltip',
								`graph.dot.${type}.tooltip`,
							])}
							animate
							onClick={handleDotClick}
						/>
					</div>
				) : null}
				{(rangeType.includes('forecast') ||
					rangeType.includes('today')) &&
				!types.includes('background') &&
				mode !== 'credit' ? (
					<FeatureFlag feature="cashflow.graph.edit.forecaster">
						<AdjustBar
							types={types}
							onClick={() =>
								handleClick({
									analyticsKey: 'graph.bar-graph.pen.click',
								})('edit')
							}
						/>
					</FeatureFlag>
				) : null}
				<TooltipWrapper
					tooltip={
						hasIndicatorTooltip
							? TranslationService.get(
									[
										'cashflow.graph.indicator.tooltip',
										`cashflow.graph.${data?.type}.indicator.tooltip`,
									],
									undefined,
									{ date: group?.id },
							  )
							: undefined
					}
					variant="alt"
				>
					<span
						className={cn('asteria-indicator__wrapper', {
							'asteria--state-clickable':
								hasIndicatorClickFeature && mode !== 'credit',
						})}
						onClick={
							hasIndicatorClickFeature && mode !== 'credit'
								? handleClick({
										analyticsKey:
											'graph.bar-graph.indicator.click',
								  })
								: null
						}
						onMouseEnter={
							hasIndicatorTooltipFeature ? handleMouseEnter : null
						}
						onMouseLeave={
							hasIndicatorTooltipFeature ? handleMouseLeave : null
						}
						ref={indicatorRef}
					>
						<span className="asteria-indicator asteria-bar-indicator" />
					</span>
				</TooltipWrapper>
			</div>
		);
	},
);

Bar.displayName = 'GraphBar';

export { Bar };
