import React from 'react';

import PropTypes from 'prop-types';

import { ListHeader, ListItem } from '@asteria/component-list';
import { useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';
import { useMemoizeHook } from '@asteria/utils-hooks/useDeep';
import useHasScrolledPassed from '@asteria/utils-hooks/useHasScrolledPassed';
import useIsOnScreen from '@asteria/utils-hooks/useIsOnScreen';

import { Context } from '../../../context';

import './styles.scss';

const TransactionGroupHeader = React.memo((props) => {
	const { group, parts } = props;

	const showEditButton = useFeature('cashflow-transactions-list-header-edit');

	const [ref, isVisible] = useIsOnScreen({
		root: null,
		rootMargin: '0px',
		threshold: 1.0,
	});

	const hasScrolledPassed = useHasScrolledPassed(
		ref,
		0,
		document.getElementsByClassName(
			'asteria-component__transactions__list',
		),
	);

	return (
		<ListHeader className="asteria-component__transactions__group__header">
			<ListItem
				data-date={group?.id}
				ref={ref}
				className={cn({
					'asteria--state-visible': isVisible,
					'asteria--state-scroll-passed': hasScrolledPassed,
					'asteria--state-has-edit':
						showEditButton && group?.status === 'FORECAST',
				})}
			>
				{parts ?? []}
			</ListItem>
		</ListHeader>
	);
});

TransactionGroupHeader.displayName = 'TransactionGroupHeader';

TransactionGroupHeader.propTypes = {
	group: PropTypes.shape({
		id: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.string),
		status: PropTypes.string,
	}),
	parts: PropTypes.node,
};

const TransactionGroupHeaderProvider = (props) => {
	const { group, onAction } = props;

	const { useHeaderParts } = React.useContext(Context);

	const parts = useMemoizeHook(useHeaderParts, {
		group: group,
		onAction: onAction,
	});

	return <TransactionGroupHeader {...props} parts={parts} />;
};

TransactionGroupHeaderProvider.displayName = 'TransactionGroupHeaderProvider';

TransactionGroupHeaderProvider.propTypes = {
	group: PropTypes.shape({
		id: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.string),
	}),
	onAction: PropTypes.func,
};

export default TransactionGroupHeaderProvider;
