import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { ViewportList } from 'react-viewport-list';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';
import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import Chip from '@asteria/component-chip';
import List, { ListCell, ListFilter, ListItem } from '@asteria/component-list';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import {
	findParentByClassname,
	findScrollingParent,
} from '@asteria/utils-funcs/node';

import { useClients, useStatistics } from './hooks';
import * as selectors from './selectors';
import ClientSorting from './sorting';
import ClientSplash from './splash';

import './styles.scss';

const ClientListItem = React.memo((props) => {
	const { object, onSelect, loading } = props;

	const ID = object?._id ?? object?.id;

	const onClick = React.useCallback(() => onSelect?.(ID), [ID, onSelect]);

	return (
		<ListItem>
			<ListCell className="asteria--type-name">
				<Text size="sm">
					{TranslationService.get(
						[
							'clients.list.item.label',
							'clients.list.item.name.label',
						],
						'{{{ name }}}',
						object,
					)}
				</Text>
			</ListCell>
			<ListCell className="asteria--type-revenue">
				{loading ? (
					<span className="asteria-component__skeleton" />
				) : (
					<Text size="sm">
						{TranslationService.get(
							[
								'clients.list.item.label',
								'clients.list.item.revenue.label',
							],
							'{{ revenue|number:false:false:true:true:false }}',
							object,
						)}
					</Text>
				)}
			</ListCell>
			<ListCell className="asteria--type-action">
				<Button size="sm" icon="chevron-right" onClick={onClick} />
			</ListCell>
		</ListItem>
	);
});

ClientListItem.displayName = 'ClientListItem';
ClientListItem.propTypes = {
	object: PropTypes.object,
	onSelect: PropTypes.func,
	loading: PropTypes.bool,
};

const ClientList = (props) => {
	const { className, onAction, onSubmit } = props;

	const store = useStore();
	const dispatch = useDispatch();

	const ref = React.useRef(null);
	const viewportRef = React.useRef(null);

	const type = useSelector(selectors.type, (a, b) => isEqual(a, b));

	const count = useSelector(
		(store) => AppStore.selectors.clients(store).length,
	);

	const [loading, statistics] = useStatistics({ onSubmit });

	const clients = useClients({ statistics });

	const hasErp = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, { type: 'erp' })
				.length,
	);

	const onChangeSettings = React.useCallback(
		(object) => () => {
			const settings = selectors.settings(store.getState());

			dispatch(
				AppStore.setSettings({ clients: { ...settings, ...object } }),
			);
		},
		[dispatch, store],
	);

	const onSelect = React.useCallback(
		(ID) => onAction?.('clients:select', ID),
		[onAction],
	);

	const renderSpacer = React.useCallback(
		({ ref, style }) => (
			<div
				className="asteria-component__clients-list-spacer"
				ref={ref}
				style={style}
			/>
		),
		[],
	);

	React.useLayoutEffect(() => {
		let node = findScrollingParent(ref.current);

		if (!node) {
			node = findParentByClassname(
				ref.current,
				'asteria-component__modal',
			);

			if (node) {
				node = node.querySelector(
					'.asteria-component__wrapper-content',
				);
			}
		}

		if (!node) {
			node = findParentByClassname(ref.current, 'asteria-content-view');
		}

		viewportRef.current = node ?? false;
	}, []);

	if (!clients?.length || !hasErp) {
		return <ClientSplash onAction={onAction} />;
	}

	return (
		<Group className="asteria-component__clients-list" ref={ref}>
			{count ? (
				<Group
					direction="horizontal"
					horizontalAlign="space-between"
					className="asteria-component__clients-list__actions"
				>
					<Group
						direction="horizontal"
						horizontalAlign="left"
						className={cn(
							'asteria-component__clients-list__action',
							'asteria--type-selector',
						)}
					>
						<Chip
							size="sm"
							label={TranslationService.get([
								'clients.list.selector.label',
								'clients.list.selector.customers.label',
							])}
							onClick={onChangeSettings({ type: 'CUSTOMER' })}
							active={type === 'CUSTOMER'}
						/>
						<Chip
							size="sm"
							label={TranslationService.get([
								'clients.list.selector.label',
								'clients.list.selector.suppliers.label',
							])}
							onClick={onChangeSettings({ type: 'SUPPLIER' })}
							active={type === 'SUPPLIER'}
						/>
					</Group>
					<Group
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
						className={cn(
							'asteria-component__clients-list__action',
							'asteria--type-sorting',
						)}
					>
						<ClientSorting onChange={onChangeSettings} />
					</Group>
				</Group>
			) : null}
			<List
				className={cn(
					'asteria-component__clients-list__table',
					className,
				)}
				applyTypographySizes={false}
				size="lg"
			>
				<ListFilter>
					<ListItem>
						<ListCell className="asteria--type-name">
							<Text size="sm">
								{TranslationService.get([
									'clients.list.header.label',
									'clients.list.header.name.label',
								])}
							</Text>
						</ListCell>
						<ListCell className="asteria--type-revenue">
							<Text size="sm">
								{TranslationService.get([
									'clients.list.header.label',
									'clients.list.header.revenue.label',
								])}
							</Text>
						</ListCell>
						<ListCell className="asteria--type-action" />
					</ListItem>
				</ListFilter>

				{viewportRef.current !== false ? (
					<ViewportList
						items={clients}
						viewportRef={viewportRef}
						itemSize={40}
						overscan={10}
						scrollThreshold={40}
						getItemBoundingClientRect={getItemBoundingClientRect}
						renderSpacer={renderSpacer}
					>
						{(object) => (
							<ClientListItem
								key={object?._id ?? object?.id}
								object={object}
								onSelect={onSelect}
								loading={loading}
							/>
						)}
					</ViewportList>
				) : (
					clients.map((object) => (
						<ClientListItem
							key={object?._id ?? object?.id}
							object={object}
							onSelect={onSelect}
							loading={loading}
						/>
					))
				)}
			</List>
		</Group>
	);
};

ClientList.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

ClientList.defaultProps = {};

export default ClientList;
