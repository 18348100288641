import React from 'react';

import PropTypes from 'prop-types';

const Group = (props) => {
	const { children } = props;

	return <div className="asteria-component__group">{children}</div>;
};

Group.displayName = 'Group';

Group.propTypes = { children: PropTypes.node };

export default Group;
