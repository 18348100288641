import AsteriaCore from '@asteria/core';

import { decode } from '@asteria/utils-funcs/jwt';

let API_URL = 'https://prod.api.asteria.ai/api/v3/';
let WIDGET_URL = 'https://demo.asteria.ai/';

function apply() {
	if (location.href.includes('dev.') || location.href.includes('dev-')) {
		API_URL = 'https://dev-api.asteria.ai/api/v3/';
		WIDGET_URL = 'https://dev-demo.asteria.ai/';
	} else if (
		location.href.includes('stage.') ||
		location.href.includes('stage-')
	) {
		API_URL = 'https://stage-api.asteria.ai/api/v3/';
		WIDGET_URL = 'https://stage-demo.asteria.ai/';
	} else if (location.href.includes('localhost')) {
		API_URL = 'https://dev-api.asteria.ai/api/v3/';
		WIDGET_URL = 'https://dev-demo.asteria.ai/';
	} else if (location.href.includes('local.')) {
		API_URL = 'https://dev-api.asteria.ai/api/v3/';
		WIDGET_URL = 'https://dev-demo.asteria.ai/';
	} else if (location.href.includes('http://209.97.158.104:3000/')) {
		API_URL = 'https://dev-api.asteria.ai/api/v3/';
		WIDGET_URL = 'https://dev-demo.asteria.ai/';
	}

	// API_URL = 'http://localhost:4004';

	const getValueAsync = (options) => {
		const { key } = options;

		if (/services\..+?\.api/.test(key)) {
			return API_URL.replace('v3/', '');
		}

		if (/services\..+?\.graphqlUri/.test(key)) {
			return API_URL;
		}

		if (key === 'widgetUrl') {
			return WIDGET_URL;
		}

		return null;
	};

	AsteriaCore.Configuration.getValueAsync = getValueAsync;
}

const setURLByToken = (token) => {
	const baseUrl = decode(token)?.baseUrl ?? null;

	if (baseUrl) {
		API_URL = `${baseUrl}api/v3/`;
	}

	return API_URL;
};

function set(url) {
	API_URL = url;
}

apply();

export default AsteriaCore.Configuration;
export { apply, set, setURLByToken };
