export function deepEqual(object1, object2) {
	if (typeof object1 !== typeof object2) {
		return false;
	}

	if (!isObject(object1) || !isObject(object2)) {
		return object1 === object2;
	}

	if (object1.id && object2.id) {
		return object1.id === object2.id;
	}

	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (!deepEqual(object1[key], object2[key])) {
			return false;
		}
	}
	return true;
}

export function isObject(object) {
	return (
		object && typeof object === 'object' && object.constructor === Object
	);
}
