import React from 'react';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';
import ElementAnchor from '@asteria/component-core/utils/elementAnchor';

import { Translation } from '@asteria/language';
import animationListener from '@asteria/utils-funcs/animationListener';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import Popup, { useDateRange } from '../popup';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Input = React.memo(function Input(props) {
	const {
		className,

		date: initialDate = new Date().toISOString(),

		onAction,
		onSubmit,
	} = props;

	const [date, setDate] = React.useState(initialDate);
	const dateRange = useDateRange(date);

	const ref = React.useRef(null);

	const [open, actions] = useToggleState();

	React.useEffect(() => {
		setDate(initialDate);
	}, [initialDate]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				setDate(data?.date);

				if (typeof data?.date === 'string') {
					actions.close();
				}
			}

			return onAction?.(action, data);
		},
		[actions, onAction],
	);

	const extra = React.useMemo(
		() => ({
			postfix: { 'date-range': dateRange },
			data: { date },
		}),
		[date, dateRange],
	);

	return (
		<>
			<div
				className={cn(
					'flex items-center p-2 border border-solid rounded gap-2 justify-between cursor-pointer',
					'bg-time-selector-input-normal-background border-time-selector-input-normal-border',
					'hover:bg-time-selector-input-hover-background hover:border-time-selector-input-hover-border',
					{
						'bg-time-selector-input-focus-background border-time-selector-input-focus-border hover:bg-time-selector-input-focus-background hover:border-time-selector-input-focus-border':
							open,
					},
					'asteria-component__time-selector-input',
					stateClasses({ active: open }),
					className,
				)}
				ref={ref}
				onClick={actions.toggle}
			>
				<TextGroup>
					<Translation
						translationKey="time.selector.input.label"
						translationOptions={extra}
						Component={Text}
					/>
					<Translation
						translationKey="time.selector.input.value"
						translationOptions={extra}
						Component={Title}
					/>
				</TextGroup>
				<Button icon="date" className="pointer-events-none" />
			</div>
			<CSSTransition
				in={open}
				appear
				unmountOnExit
				classNames="my-node"
				addEndListener={animationListener}
			>
				<ElementAnchor
					className="z-50"
					element={ref}
					placement="bottom"
					minWidth
				>
					<Popup
						date={date}
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
				</ElementAnchor>
			</CSSTransition>
		</>
	);
});

Input.displayName = 'Input';

Input.propTypes = {
	className: PropTypes.string,
	date: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Input;
