import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Radio } from '@asteria/component-form';
import List, { ListItem } from '@asteria/component-list';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const AuthProfileChangeContent = React.memo((props) => {
	const { className, onClose, loading } = props;

	const companies = useSelector(
		(store) => AppStore.selectors.user(store)?.companies ?? [],
		(a, b) => isEqual(a, b),
	);

	return (
		<Wrapper className={className}>
			<Header onClose={onClose}>
				{TranslationService.get([
					'auth.title',
					'auth.modal.title',
					'auth.profile.change.title',
					'auth.modal.profile.change.title',
				])}
			</Header>
			<Content>
				<List size="lg">
					{companies.map((object) => (
						<ListItem key={object?._id ?? object?.id}>
							<Radio
								label={object?.name}
								name="companyId"
								value={object?._id ?? object?.id}
							/>
						</ListItem>
					))}
				</List>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.abort',
							'action.abort',
							'auth.action.abort',
							'auth.modal.action.abort',
							'auth.profile.change.action.abort',
							'auth.modal.profile.change.action.abort',
						])}
						onClick={onClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.submit',
							'action.submit',
							'auth.action.submit',
							'auth.modal.action.submit',
							'auth.profile.change.action.submit',
							'auth.modal.profile.change.action.submit',
						])}
						type="submit"
						loading={loading}
						disabled={loading}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

AuthProfileChangeContent.displayName = 'AuthProfileChangeContent';

AuthProfileChangeContent.propTypes = {
	className: PropTypes.string,

	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	accessToken: PropTypes.string,
	loading: PropTypes.bool,
};

export default AuthProfileChangeContent;
