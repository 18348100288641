import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../../../PropTypes';
import Button from '../../../button';
import { useLoading } from '../../hooks';

import '../styles.scss';

/** @type { React.FC<Partial<{ icon: string, onClose: () => void, size: string, loading: boolean }>> } */
const CloseButton = React.memo((props) => {
	const {
		icon = 'close',
		onClose,
		size = 'sm',
		loading: $loading,
		...rest
	} = props;

	const loading = useLoading($loading);

	return (
		<Button
			className={cn(
				'asteria-component__wrapper__button-close',
				'asteria-component__wrapper-header-action',
				'asteria--variant-close',
				{ 'asteria--state-loading': loading },
			)}
			icon={icon}
			variant="close"
			onClick={onClose}
			size={size}
			analyticsKey="wrapper.header.close"
			{...rest}
		/>
	);
});

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
	icon: PropTypes.string,
	onClose: PropTypes.func,
	size: SizeProp(),
	loading: PropTypes.bool,
};

export default CloseButton;
