import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import Select, { Option } from '@asteria/component-form/select/';
import ComponentService from '@asteria/component-tools/contenter/service';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { TooltipWrapper } from '../tooltip';
import { Text } from '../typography';
import { positionClasses, sizeClasses } from '../utils';
import { getProbabilityLevel, normalizeProbability } from '../utils/normalize';

import './index.scss';

export const BarLine = (props) => {
	const { className, probability } = props;
	return (
		<div
			className={cn(
				'asteria-component__probability__bar-line',
				`asteria-component__probability__bar-line--${probability}`,
				className,
			)}
		></div>
	);
};

BarLine.propTypes = {
	className: PropTypes.string,
	probability: PropTypes.oneOf(['low', 'medium', 'high']),
};

const ProbabilityBarSelect = (props) => {
	const {
		onChange,
		flat,
		icon = false,
		iconActive = icon,
		value: $value,
		uncontrolled,
	} = props;

	const formProbability = useWatch({ name: props.name });

	const value = $value !== undefined ? $value : formProbability;

	const probability = useMemo(
		() =>
			value === undefined
				? null
				: normalizeProbability(value, props.values),
		[value, props.values],
	);

	return (
		<Select
			className={cn('asteria-component__probability-select', {
				'asteria-component__select--no-probability-label': !props.label,
				'asteria-component__select--flat': flat,
			})}
			name={props.name}
			onChange={onChange}
			icon={icon}
			iconActive={iconActive}
			tooltip={TranslationService.get(
				'forecaster.probability.select',
				// eslint-disable-next-line spellcheck/spell-checker
				'Klicka för att välja sannolikhet',
			)}
			indeterminate
			uncontrolled={uncontrolled}
			value={value}
			variant="probability"
		>
			<Option hidden active={probability === null}>
				<ProbabilityBar {...props} probability={null} label={true} />
			</Option>
			{props.values
				.slice(0)
				.reverse()
				.map((value, index) => (
					<Option
						value={value}
						key={index}
						active={probability === value}
					>
						<ProbabilityBar
							{...props}
							probability={value}
							label={true}
						/>
					</Option>
				))}
		</Select>
	);
};

ProbabilityBarSelect.propTypes = {
	onChange: PropTypes.func,
	probability: PropTypes.number,
	values: PropTypes.arrayOf(PropTypes.number),
	label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	name: PropTypes.string,
	flat: PropTypes.bool,

	icon: PropTypes.string,
	iconActive: PropTypes.string,

	value: PropTypes.number,
	uncontrolled: PropTypes.bool,
};

const Probability = (props) => {
	const {
		className,
		type,
		tooltip,
		label,
		labelPosition = 'last',
		probability,
		values = [],
		hidden,
	} = props;

	const level = getProbabilityLevel({ value: probability, variants: values });

	const probability_relative =
		level <= values.length * 0.4
			? 'low'
			: level <= values.length * 0.6
			? 'medium'
			: 'high';

	const ProbabilityTooltip = React.useMemo(() => {
		if (!tooltip) {
			return null;
		}

		if (typeof tooltip === 'boolean') {
			return TranslationService.get([
				`probability.level.${level}`,
				`probability.level.${type}.${level}`,
			]);
		}

		return tooltip;
	}, [level, tooltip, type]);

	const probabilityElement = (
		<TooltipWrapper
			tooltip={
				typeof ProbabilityTooltip === 'string' ? (
					<Text size="sm">{ProbabilityTooltip}</Text>
				) : (
					ProbabilityTooltip
				)
			}
		>
			<div
				className={cn(
					className,
					'asteria-component__probability',
					// `asteria-component__probability--step-${level}`,
					{ 'asteria-component__probability--state-hidden': hidden },
					sizeClasses(props),
				)}
			>
				{values.map((value, step) => (
					<BarLine
						key={`step_${step}`}
						probability={probability_relative}
						className={cn({
							'asteria-state-active': level >= step + 1,
						})}
					/>
				))}
			</div>
		</TooltipWrapper>
	);

	if (hidden || !label) {
		return (
			<div className="asteria-component__probability__wrapper">
				{probabilityElement}
			</div>
		);
	}

	const labelText =
		label === true
			? TranslationService.get([
					`probability.level.${level}`,
					`probability.level.${type}.${level}`,
			  ])
			: label;

	return (
		<div className="asteria-component__probability__wrapper">
			<Text
				className={cn(
					'asteria-component__probability__label',
					positionClasses({ position: labelPosition }),
				)}
			>
				{labelText}
			</Text>
			{probabilityElement}
		</div>
	);
};

Probability.displayName = 'Probability';

Probability.propTypes = {
	onChange: PropTypes.func,
	probability: PropTypes.number,
	values: PropTypes.arrayOf(PropTypes.number),
	label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	name: PropTypes.string,

	className: PropTypes.string,
	type: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	labelPosition: PropTypes.oneOf(['first', 'last']),
	hidden: PropTypes.bool,
	flat: PropTypes.bool,

	icon: PropTypes.string,
	iconActive: PropTypes.string,

	value: PropTypes.number,
	uncontrolled: PropTypes.bool,
};

const ProbabilityBar = (props) => {
	const { edit } = props;

	if (edit) {
		return <ProbabilityBarSelect {...props} edit={false} tooltip={false} />;
	}

	return <Probability {...props} />;
};

ProbabilityBar.propTypes = {
	...Probability.propTypes,
	edit: PropTypes.bool,
};

ProbabilityBar.defaultProps = {
	edit: false,
	hidden: false,
	values: [0.2, 0.4, 0.6, 0.8, 1],
	probability: null,
	labelPosition: 'last',
	label: false,
	name: 'probability',
};

ProbabilityBar.displayName = 'ProbabilityBar';

ComponentService.register('Probability', ProbabilityBar, {
	onChange: { type: 'function' },
	probability: { type: 'number' },
	values: { type: 'array', of: { type: 'number' } },
	label: { type: 'string' },
	name: { type: 'string' },

	className: { type: 'string' },
	type: { type: 'string' },
	tooltip: { type: 'string' },
	labelPosition: { type: 'enum', options: ['first', 'last'] },
	hidden: { type: 'boolean' },
	flat: { type: 'boolean' },

	icon: { type: 'string' },
	iconActive: { type: 'string' },

	value: { type: 'number' },
	uncontrolled: { type: 'boolean' },
	edit: { type: 'boolean' },
});

export default ProbabilityBar;
