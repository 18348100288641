import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Content from './Content';
import Footer, { FooterSection } from './Footer';
import Header from './Header';

const Wrapper = (props) => {
	const { children, border, divider, className } = props;

	return (
		<div
			className={cn(
				'asteria-component__wrapper',
				'asteria-component__wrapper--variant-vertical',
				{
					'asteria-component__wrapper--border': border,
					'asteria-component__wrapper--divider': divider,
				},
				className,
			)}
		>
			{children}
		</div>
	);
};

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = {
	children: PropTypes.node,
	border: PropTypes.bool,
	divider: PropTypes.bool,

	className: PropTypes.string,
};

const FormWrapper = (props) => {
	return (
		<Wrapper
			{...props}
			className={cn('asteria-component__form-wrapper', props.className)}
		/>
	);
};

FormWrapper.displayName = 'FormWrapper';

FormWrapper.propTypes = {
	children: PropTypes.node,
	border: PropTypes.bool,
	divider: PropTypes.bool,

	className: PropTypes.string,
};

export default Wrapper;
export { Header, Content, Footer, FooterSection, FormWrapper };
