import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SearchChip = React.memo((props) => {
	const {
		className,
		type,
		id,
		object,
		label,
		flag,
		colors,
		dismiss,
		onAction,
		size,
		active,
	} = props;

	const onClick = React.useCallback(() => {
		Analytics.event('search.chip.toggle', { type, id });

		let actionType = 'toggleFilter';

		if (type === 'tag') {
			actionType = 'toggleTag';
		}

		if (type === 'client') {
			actionType = 'toggleClient';
		}

		if (type === 'status') {
			actionType = 'toggleStatus';
		}

		if (type === 'currency') {
			actionType = 'toggleCurrency';
		}

		if (type === 'service') {
			actionType = 'toggleService';
		}

		return onAction?.(actionType, { type, item: object, id });
	}, [type, onAction, object, id]);

	return (
		<Chip
			className={cn('asteria-component__search-chip', className)}
			label={label}
			flag={flag}
			colors={colors}
			dismiss={dismiss}
			onClick={onClick}
			size={size}
			active={active}
		/>
	);
});

SearchChip.displayName = 'SearchChip';

SearchChip.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	id: PropTypes.string,
	object: PropTypes.object,
	label: PropTypes.string,
	flag: PropTypes.string,
	colors: PropTypes.string,
	dismiss: PropTypes.bool,
	onAction: PropTypes.func,
	size: PropTypes.string,
	active: PropTypes.bool,
};

export default SearchChip;
