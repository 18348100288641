import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';
import { positionClasses, stateClasses } from '@asteria/component-core/utils';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './label.scss';

const Label = (props) => {
	const {
		className,
		children,
		icon,
		tooltip: $tooltip,
		iconPosition,
		active,
		disabled,
		size,
	} = props;

	const tooltip = React.useMemo(() => {
		if (!$tooltip) {
			return null;
		}

		if (typeof $tooltip === 'string') {
			return TranslationService.get($tooltip, $tooltip);
		}

		return $tooltip;
	}, [$tooltip]);

	if (!children) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__form__label',
				className,
				stateClasses({ active: active, disabled: disabled }),
			)}
		>
			{icon ? (
				<Icon
					className={cn(
						'asteria-component__form__label__icon',
						positionClasses({ position: iconPosition }),
					)}
					size={size}
					icon={icon}
					tabIndex="0"
				/>
			) : null}

			{['string', 'number'].includes(typeof children) ? (
				<Text size={size}>{children}</Text>
			) : (
				children
			)}

			{tooltip ? (
				<TooltipWrapper tooltip={tooltip}>
					<Icon
						icon="help-filled"
						className="asteria-component__input__label__tooltip"
					/>
				</TooltipWrapper>
			) : null}
		</div>
	);
};

Label.displayName = 'Label';

Label.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node,
	]),
	className: PropTypes.string,
	size: SizeProp(),
	icon: PropTypes.string,
	iconPosition: PropTypes.string,
	tooltip: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default React.memo(Label);
