import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../../constants';

export * as selectors from './selectors';

const initialState = {
	isLoading: false,
	credit: 0,
	_credit: null,
	bankAccounts: [],
	accountBalance: 0,
};

export const accountsSlice = createSlice({
	name: 'accounts',
	initialState: initialState,
	reducers: {
		start: (state) => {
			state.isLoading = true;
		},
		stop: (state) => {
			state.isLoading = false;
		},
		setCredit: (state, action) => {
			state.credit = action.payload;
			state._credit = null;
		},
		setDynamicCredit: (state, action) => {
			state._credit = action.payload;
		},
		setAccounts: (state, action) => {
			state.bankAccounts = action.payload;
			state.accountBalance = (action.payload || [])
				.filter(({ active }) => active)
				.reduce(
					(acc, account) =>
						acc + (account?.sums?.display?.total || 0),
					0,
				);
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { start, stop, setCredit, setDynamicCredit, setAccounts } =
	accountsSlice.actions;

export default accountsSlice.reducer;
