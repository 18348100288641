import React from 'react';

const FlexItemContext = React.createContext({
	hide: () => null,
	show: () => null,
	displayModal: () => null,
	closeModal: () => null,
});

export default FlexItemContext;
