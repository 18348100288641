import React from 'react';

const Context = React.createContext({
	count: 0,
	step: 0,
	type: '',
	cancel: () => null,
	back: () => null,
	next: () => null,
	done: () => null,
	go: () => null,

	isBackVisible: false,
	isNextVisible: false,
	isNextDisabled: false,
});

export default Context;
