import React from 'react';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import animationListener from '@asteria/utils-funcs/animationListener';
import { cn } from '@asteria/utils-funcs/classes';
import stopPropogration from '@asteria/utils-funcs/stopPropogration';
import useWrapper from '@asteria/utils-hooks/useWrapper';

import Context from './context';

import './index.scss';

const Content = (props) => {
	const { className, placement, children } = props;

	return (
		<div
			className={cn(
				'asteria-component__dialog',
				`asteria-component__dialog--placement-${placement}`,
				className,
			)}
			onClick={stopPropogration}
		>
			{children}
		</div>
	);
};

Content.displayName = 'Content';

Content.propTypes = {
	open: PropTypes.bool,
	placement: PropTypes.oneOf([
		'top',
		'top-left',
		'top-right',
		'middle',
		'middle-left',
		'middle-right',
		'bottom',
		'bottom-left',
		'bottom-right',
	]),
	className: PropTypes.string,
	children: PropTypes.node,
	onClose: PropTypes.func,
};

const Dialog = (props) => {
	const { onClose, open } = props;

	const wrapper = useWrapper('asteria-dialog-container');

	const context = useMemo(() => {
		return {
			closeDialog: onClose,
		};
	}, [onClose]);

	const Component = (
		<Context.Provider value={context}>
			<CSSTransition
				in={open}
				appear
				unmountOnExit
				classNames="asteria-dialog"
				addEndListener={animationListener}
			>
				<Content {...props} />
			</CSSTransition>
		</Context.Provider>
	);

	return ReactDOM.createPortal(Component, wrapper);
};

Dialog.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	placement: PropTypes.oneOf([
		'top',
		'top-left',
		'top-right',
		'middle',
		'middle-left',
		'middle-right',
		'bottom',
		'bottom-left',
		'bottom-right',
	]),
	open: PropTypes.bool,
};

Dialog.defaultProps = {
	open: true,
	className: null,
	onClose: undefined,
};

Dialog.displayName = 'Dialog';

export { Content };
export default Dialog;
