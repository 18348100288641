import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { stateClasses } from '@asteria/component-core/utils';

import Checkbox from '@asteria/component-form/checkbox';
import { ListCell, ListFilter, ListItem } from '@asteria/component-list';

import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { Context } from '../../../context';

import './styles.scss';

const TransactionSettings = React.memo((props) => {
	const { columns, compact } = props;

	const dispatch = useDispatch();

	const handleOpenSettings = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.TransactionsSettings,
				data: { columns: columns, compact: compact },
			}),
		);
	}, [columns, compact, dispatch]);

	return (
		<Button
			size="sm"
			icon="more"
			onClick={handleOpenSettings}
			tooltip={{
				tooltip: TranslationService.get([
					'list.transactions.header.list.tooltip',
					'list.transactions.header.list.options.tooltip',
				]),
				variant: 'alt',
			}}
			analyticsKey="list.transactions.header.options"
		/>
	);
});

TransactionSettings.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object),
	compact: PropTypes.bool,
};

TransactionSettings.displayName = 'TransactionSettings';

const TransactionSettingsProvider = () => {
	const ctx = React.useContext(Context);

	const { columns, compact } = useDeepMemo(
		() => ({ columns: ctx?.columns, compact: ctx?.compact }),
		[ctx?.columns, ctx?.compact],
	);

	return <TransactionSettings columns={columns} compact={compact} />;
};

TransactionSettingsProvider.propTypes = {};

TransactionSettingsProvider.displayName = 'TransactionSettingsProvider';

const TransactionFiltersCell = React.memo((props) => {
	const { type, onAction, sorting } = props;

	const dispatch = useDispatch();

	const isActive = sorting?.field === type;

	const handleClick = React.useCallback(() => {
		onAction?.('transactions:sorting', type);
	}, [onAction, type]);

	const handleSelectAll = React.useCallback(
		({ checked }) => {
			if (checked) {
				return dispatch(TransactionStore.setSelected('ALL'));
			}

			return dispatch(TransactionStore.setSelected('NONE'));
		},
		[dispatch],
	);

	const tooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get(
				[
					`list.column.${type}`,
					'list.transactions.header.list',
					`list.transactions.header.list.${type}`,
					'list.transactions.header.list.tooltip',
					`list.transactions.header.list.${type}.tooltip`,
				],
				type,
				{ value: type },
			),
			variant: 'alt',
		}),
		[type],
	);

	return (
		<ListCell
			className={cn(
				'asteria-component__transactions__cell',
				{
					[`asteria-component__transactions__cell--${type}`]: type,
				},
				stateClasses({ active: sorting?.field === type }),
			)}
		>
			{type === 'options' ? (
				<TransactionSettingsProvider />
			) : type === 'selector' ? (
				<Checkbox uncontrolled onChange={handleSelectAll} />
			) : (
				<Button
					label={TranslationService.get(
						[
							`list.column.${type}`,
							'list.transactions.header.list',
							`list.transactions.header.list.${type}`,
						],
						type,
					)}
					className="asteria-component__transactions__filter"
					active={isActive}
					icon={
						isActive && sorting?.direction == 'ASC'
							? 'triangle-up'
							: 'triangle-down'
					}
					iconPosition="last"
					onClick={handleClick}
					// size="sm"
					iconSize="xs"
					variant="transaction-filter"
					tooltip={tooltip}
				/>
			)}
		</ListCell>
	);
});

TransactionFiltersCell.displayName = 'TransactionFiltersCell';

TransactionFiltersCell.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,

	sorting: PropTypes.shape({
		field: PropTypes.string,
		direction: PropTypes.string,
	}),
};

const TransactionFiltersCellProvider = (props) => {
	const ctx = React.useContext(Context);

	const { sorting } = useDeepMemo(
		() => ({ sorting: ctx?.sorting }),
		[ctx?.sorting],
	);

	return <TransactionFiltersCell {...props} sorting={sorting} />;
};

TransactionFiltersCellProvider.displayName = 'TransactionFiltersCellProvider';

TransactionFiltersCellProvider.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
};

const TransactionFilters = React.memo((props) => {
	const { className, onAction, columns: columns } = props;

	return (
		<ListFilter
			className={cn(
				'asteria-component__transactions__filters',
				className,
			)}
		>
			<ListItem>
				{columns.map((type) => (
					<TransactionFiltersCellProvider
						key={type}
						type={type}
						onAction={onAction}
					/>
				))}
			</ListItem>
		</ListFilter>
	);
});

TransactionFilters.displayName = 'TransactionFilters';

TransactionFilters.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	columns: PropTypes.arrayOf(PropTypes.string),
};

const TransactionFiltersProvider = (props) => {
	const ctx = React.useContext(Context);

	const { visible } = useDeepMemo(
		() => ({ visible: ctx?.columns?.visible }),
		[ctx?.columns?.visible],
	);

	return <TransactionFilters {...props} columns={visible} />;
};

TransactionFiltersProvider.displayName = 'TransactionFiltersProvider';

TransactionFiltersProvider.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default TransactionFiltersProvider;
