import { TranslationService } from '@asteria/language';

export function replace(value) {
	return value.replace(/\$/g, '');
}

export function getTagLabel(value) {
	const [category, tag] = value.split('-');

	return TranslationService.get([
		`tags.${replace(tag)}`,
		`tags.${replace(category)}-${replace(tag)}`,
	]);
}

export function shuffle(source) {
	const target = Array.from(source);

	for (let i = target.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[target[i], target[j]] = [target[j], target[i]];
	}

	return target;
}
