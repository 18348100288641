import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import List, { ListCell, ListHeader, ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SimulationListItem = React.memo((props) => {
	const { object, columns, type, onEdit, onView } = props;

	const handleEdit = React.useCallback(
		() => onEdit?.(object),
		[object, onEdit],
	);

	const handleView = React.useCallback(
		() => onView?.(object),
		[object, onView],
	);

	const ToggleProps = React.useMemo(() => ({ icon: 'more' }), []);

	return (
		<ListItem>
			{(columns ?? []).map(({ key, align }) => (
				<ListCell
					key={key}
					className={cn({
						[`asteria--align-${align}`]: align,
						[`asteria--variant-${key}`]: key,
					})}
				>
					{key === 'actions' ? (
						<Dropdown toggle={ToggleProps}>
							<DropdownItem onClick={handleEdit}>
								{TranslationService.get(
									[
										'financial.simulation.list.list.action.edit.label',
										`financial.simulation.list.${type}.list.action.edit.label`,
										`financial.simulation.list.list.action.edit.${key}.label`,
										`financial.simulation.list.${type}.list.action.edit.${key}.label`,
									],
									undefined,
									{ object: object },
								)}
							</DropdownItem>
							<DropdownItem onClick={handleView}>
								{TranslationService.get(
									[
										'financial.simulation.list.list.action.view.label',
										`financial.simulation.list.${type}.list.action.view.label`,
										`financial.simulation.list.list.action.view.${key}.label`,
										`financial.simulation.list.${type}.list.action.view.${key}.label`,
									],
									undefined,
									{ object: object },
								)}
							</DropdownItem>
						</Dropdown>
					) : (
						<Text>
							{TranslationService.get(
								[
									'financial.simulation.list.list.item.label',
									`financial.simulation.list.${type}.list.item.label`,
									`financial.simulation.list.list.item.${key}.label`,
									`financial.simulation.list.${type}.list.item.${key}.label`,
								],
								undefined,
								{ object: object },
							)}
						</Text>
					)}
				</ListCell>
			))}
		</ListItem>
	);
});

SimulationListItem.displayName = 'SimulationListItem';
SimulationListItem.propTypes = {
	type: PropTypes.string,
	columns: PropTypes.arrayOf(PropTypes.object),

	object: PropTypes.object,

	onEdit: PropTypes.func,
	onView: PropTypes.func,
};

const SimulationList = React.memo((props) => {
	const { className, onAction, type, columns, rows } = props;

	const style = React.useMemo(
		() => ({ '--size': columns?.length }),
		[columns?.length],
	);

	const onCreate = React.useCallback(
		() => onAction?.('simulation:create'),
		[onAction],
	);

	const onEdit = React.useCallback(
		(object) => onAction?.('simulation:edit', object),
		[onAction],
	);

	const onView = React.useCallback(
		(object) => onAction?.('simulation:view', object),
		[onAction],
	);

	return (
		<Wrapper
			className={cn(
				'asteria-component__financial-simulation-list',
				className,
			)}
			style={style}
		>
			<Header>
				<Title size="sm">
					{TranslationService.get([
						'financial.simulation.list.title',
						`financial.simulation.list.${type}.title`,
					])}
				</Title>
			</Header>
			<Content>
				<List>
					<ListHeader>
						{(columns ?? []).map(({ key, align }) => (
							<ListCell
								key={key}
								className={cn({
									[`asteria--align-${align}`]: align,
									[`asteria--variant-${key}`]: key,
								})}
							>
								<Text>
									{TranslationService.get([
										'financial.simulation.list.list.header.label',
										`financial.simulation.list.${type}.list.header.label`,
										`financial.simulation.list.list.header.${key}.label`,
										`financial.simulation.list.${type}.list.header.${key}.label`,
									])}
								</Text>
							</ListCell>
						))}
					</ListHeader>

					{(rows ?? []).map((object) => (
						<SimulationListItem
							key={object?._id ?? object?.id}
							object={object}
							columns={columns}
							type={type}
							onEdit={onEdit}
							onView={onView}
						/>
					))}
				</List>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						icon="add"
						label={TranslationService.get([
							'financial.simulation.list.action.create.label',
							`financial.simulation.list.${type}.action.create.label`,
						])}
						variant="tertiary"
						iconSize="md"
						onClick={onCreate}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

SimulationList.displayName = 'SimulationList';

SimulationList.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	onAction: PropTypes.func,

	columns: PropTypes.arrayOf(PropTypes.object),
	rows: PropTypes.arrayOf(PropTypes.object),
};

export default SimulationList;
