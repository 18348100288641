import React from 'react';

import PropTypes from 'prop-types';

import BaseQuestion from './Base';
import { useQuestion } from './hooks';

const QuestionContenterContent = (props) => {
	const { className, type, onAction, config } = props;

	return (
		<BaseQuestion
			className={className}
			variant="bars"
			type={type}
			title={config?.title}
			onAction={onAction}
		/>
	);
};

QuestionContenterContent.displayName = 'QuestionContenterContent';

QuestionContenterContent.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onAction: PropTypes.func,
	config: PropTypes.object,
};

const QuestionContenter = (props) => {
	const { type } = props;

	const config = useQuestion(type);

	if (!config) {
		return null;
	}

	return <QuestionContenterContent {...props} config={config} />;
};

QuestionContenter.displayName = 'QuestionContenter';

QuestionContenter.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onAction: PropTypes.func,
};

export default QuestionContenter;
