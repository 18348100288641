import { isAfter, isBefore, isSameDay } from 'date-fns';

import { parseDate } from '@asteria/utils-funcs/normalize';

export function getDateVariant({ startDate: $startDate, endDate: $endDate }) {
	if (!$startDate || !$endDate) {
		return null;
	}

	const startDate = parseDate($startDate);
	const endDate = parseDate($endDate);

	const now = new Date();

	if (
		isSameDay(now, startDate) ||
		isSameDay(now, endDate) ||
		(isAfter(now, startDate) && isBefore(now, endDate))
	) {
		return 'today';
	}

	if (isBefore(now, startDate)) {
		return 'future';
	}

	return 'past';
}

export function getDateProperties({ startDate, endDate }) {
	const variant = getDateVariant({ startDate, endDate });

	return {
		past: variant === 'past',
		today: variant === 'today',
		future: variant === 'future',
	};
}
