import React from 'react';

import { ViewportList } from 'react-viewport-list';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';
import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import {
	findParentByClassname,
	findScrollingParent,
} from '@asteria/utils-funcs/node';

import { useTransactions } from '../hooks';

import TransactionItem from './item';

const TransactionGroupContent = React.memo(
	React.forwardRef((props, ref) => {
		const { onAction, date, category, tag, types } = props;

		const [viewportRef, setViewportRef] = React.useState({ current: null });

		const transactions = useTransactions({
			types: types,
			date: date,
			category: category,
			tag: tag,
		});

		const exists = !!transactions.length;

		React.useLayoutEffect(() => {
			let node = findScrollingParent(ref?.current) ?? ref?.current;

			if (node && !node.isSameNode(ref?.current)) {
				const parent = findParentByClassname(
					ref?.current,
					'asteria-component__modal',
				);

				if (parent) {
					node = parent.querySelector(
						'.asteria-component__wrapper > .asteria-component__wrapper-content',
					);
				}
			}

			setViewportRef({ current: node });
		}, [ref?.current, exists]);

		if (!exists) {
			return (
				<div
					className={cn(
						'asteria-component__forecaster-transactions-list-item',
						'asteria--state-empty',
					)}
				>
					<Group horizontalAlign="center">
						<Title size="xs">
							{TranslationService.get(
								'forecaster.transactions.list.empty.title',
							)}
						</Title>
						<Text>
							{TranslationService.get(
								'forecaster.transactions.list.empty.text',
							)}
						</Text>
					</Group>
				</div>
			);
		}

		return (
			<ViewportList
				viewportRef={viewportRef}
				items={transactions}
				itemSize={40}
				getItemBoundingClientRect={getItemBoundingClientRect}
			>
				{(name) => (
					<TransactionItem
						key={name}
						name={name}
						types={types}
						onAction={onAction}
						showCategory={!category || category === '$type'}
					/>
				)}
			</ViewportList>
		);
	}),
);

TransactionGroupContent.displayName = 'TransactionGroupContent';

TransactionGroupContent.propTypes = {
	onAction: PropTypes.func,

	types: PropTypes.arrayOf(PropTypes.string),
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default TransactionGroupContent;
