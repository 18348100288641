import slice from './slice';

export * as selectors from './selectors';
export * as constants from './constants';
export { slice };

export const {
	setAccessToken,
	setLoginStatus,

	setSettings,
	setStatement,
	setStandalone,
	setMode,
	setFilters,
	resetFilterType,
	toggleFilter,
	addFilter,
	setConfig,
	setUser,
	setFeedback,
	setCompany,
	setCompanySettings,
	setCompanySettingsFlags,
	setPartner,
	setCurrencies,
	setTags,
	updateTags,
	pushTags,
	setClients,
	updateClients,
	addClient,
	setStatuses,
	setFullScreenState,
	setGraphLayout,
	setUserSettings,
	setUserSettingsPartial,
	setUserSettingsFlags,
	setUserFeedback,
	setListOpen,
	setTimesliceSize,
	setCurrentDate,
	setAdjustOpen,
	setSelectedDate,
	setSelectedType,

	register2FA,
	setUserCompanyId,
} = slice.actions;

export default slice.reducer;
