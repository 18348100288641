import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import * as utils from '../../../../utils';

import RowCategory from './category';
import RowEdit from './edit';
import RowInput from './input';
import ForecastTagRemoveModal from './remove-modal';
import RowSatisfaction from './satisfaction';
import RowTrends from './trends';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'onAction' | 'onSubmit' | 'translationOptions'> & Props> } */
const Row = React.memo(function Row(props) {
	const {
		type,
		category,
		tag,

		translationOptions,
		onAction,
		onSubmit,

		edit,
		// onRemove,
		onUpdate,
	} = props;

	const { getValues, setValue, reset } = useFormContext();

	const [removeModalOpen, removeModalActions] = useToggleState();

	const remove = React.useCallback(
		(event, { type, category, tag }) => {
			const changes = utils.form.tag.remove(
				{ type, category, tag, recalculate: true },
				{ getValues, setValue, reset },
			);

			utils.form.changes.apply(changes, { getValues, setValue, reset });

			// onRemove();

			return removeModalActions.close(event);
		},
		[getValues, removeModalActions, reset, setValue],
	);

	return (
		<>
			<div className={cn('asteria-component__card-section-row')}>
				<RowTrends
					type={type}
					category={category}
					tag={tag}
					translationOptions={translationOptions}
				/>

				<RowCategory
					type={type}
					category={category}
					tag={tag}
					edit={edit}
					onUpdate={onUpdate}
					onAction={onAction}
					onSubmit={onSubmit}
				/>

				<RowInput
					type={type}
					category={category}
					tag={tag}
					translationOptions={translationOptions}
				/>

				<div
					className={cn(
						'flex items-center gap-2',
						'asteria-component__card-section-actions',
					)}
				>
					<RowEdit active={edit} onUpdate={onUpdate} />
					<RowSatisfaction
						type={type}
						category={category}
						tag={tag}
					/>

					<Button
						icon="remove"
						size="sm"
						onClick={removeModalActions.open}
					/>
				</div>
			</div>

			<ForecastTagRemoveModal
				open={removeModalOpen}
				onClose={removeModalActions.close}
				type={type}
				category={category}
				tag={tag}
				onRemove={remove}
			/>
		</>
	);
});

Row.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,

	edit: PropTypes.bool,

	onRemove: PropTypes.func,
	onUpdate: PropTypes.func,

	id: PropTypes.string,
};

export default Row;
