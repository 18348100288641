import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import TagChip from '@asteria/component-tags/tag';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { replace } from '@asteria/utils-funcs/format';

import './styles.scss';

const TransactionTag = React.memo((props) => {
	const { status, type, _id, tag, onAction } = props;

	const hasClickFeature = useFeature('cashflow-transactions-chip-tags-click');

	const handleClick = React.useCallback(
		(tag) => {
			Analytics.event('transaction.chip', {
				_id: _id,
				tag: tag,
			});

			return onAction?.('transactions:filter', {
				type: 'tag',
				id: tag._id ?? tag.id,
				item: tag,
			});
		},
		[_id, onAction],
	);

	const tooltip = React.useMemo(() => {
		const { name, category } = tag || {};
		const { name: categoryName } = category || {};

		return {
			tooltip: TranslationService.get(
				[
					`list.tags.tooltip`,

					replace([categoryName]).join('.'),
					replace([name]).join('.'),
					replace([categoryName, name]).join('.'),
					replace([categoryName, name]).join('-'),
					replace([`tags`, categoryName]).join('.'),
					replace([`tags`, name]).join('.'),
					replace([`tags`, categoryName, name]).join('.'),
					replace([`tags`, [categoryName, name].join('-')]).join('.'),

					`list.transactions.transaction.chip.tooltip`,
					`list.transactions.transaction.tag.tooltip`,

					replace([
						`list.transactions.transaction.tag`,
						name,
						`tooltip`,
					]).join('.'),

					replace([
						`list.transactions.transaction.tag`,
						categoryName,
						name,
						`tooltip`,
					]).join('.'),
				],
				name,
			),
			variant: 'alt',
		};
	}, [tag]);

	return (
		<TagChip
			key={tag.id}
			tag={tag}
			status={status}
			type={type}
			size="sm"
			stopPropagation
			onClick={hasClickFeature ? handleClick : undefined}
			tooltip={hasClickFeature ? tooltip : null}
		/>
	);
});

TransactionTag.displayName = 'TransactionTag';

TransactionTag.propTypes = {
	status: PropTypes.string,
	type: PropTypes.string,
	_id: PropTypes.string,
	tag: PropTypes.object,
	onAction: PropTypes.func,
};

function useTags({ input }) {
	const filters = useSelector((store) =>
		AppStore.selectors.filters(store, { type: 'tag' }),
	);

	if (!filters.length) {
		return input.slice(0, 1);
	}

	const tags = input.filter((object) =>
		filters.some((filter) => (object?._id ?? object?.id) === filter?.id),
	);

	return tags.slice(0, 1);
}

const TagsCell = React.memo((props) => {
	const { className, data, onAction } = props;

	const tags = useTags({ input: data?.meta?.tags ?? [] });

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			{tags?.map((tag) => (
				<TransactionTag
					key={tag._id}
					status={data?.status}
					type={data?.type}
					_id={data?._id ?? data?.id}
					tag={tag}
					onAction={onAction}
				/>
			))}
		</div>
	);
});

TagsCell.displayName = 'TagsCell';

TagsCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	onAction: PropTypes.func,
};

export default TagsCell;
