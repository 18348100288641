import React from 'react';

import { useDispatch, useStore } from 'react-redux';
import { useBlocker, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { useModalActions } from '../../../components/modal-collection';
import { CreditLeaveModal } from '../../../modals';

const LeaveWrapper = React.memo((props) => {
	const { children } = props;

	const { id } = useParams();

	const { open } = useModalActions();

	const dispatch = useDispatch();
	const store = useStore();

	const shouldBlock = React.useCallback(
		({ currentLocation, nextLocation }) => {
			const hasChanges = AccountStore.selectors.hasCreditChange(
				store.getState(),
				{ simulationId: id },
			);

			const simulation = ScenarioStore.selectors.scenario(
				store.getState(),
				id,
			);

			return (
				currentLocation.pathname.includes('financial/credit') &&
				!nextLocation.pathname.includes('financial/credit') &&
				hasChanges &&
				(!id || simulation)
			);
		},
		[id, store],
	);

	const blocker = useBlocker(shouldBlock);

	React.useEffect(() => {
		if (blocker.state === 'blocked') {
			blocker.reset();

			const credit = AccountStore.selectors.dynamicCredit(
				store.getState(),
				{ simulationId: id },
			);

			open(<CreditLeaveModal />, {
				credit: credit,
				simulationId: id,
				next: blocker.location.pathname,
			});
		}
	}, [blocker.state, dispatch, id, open, store]);

	return <>{children}</>;
});

LeaveWrapper.displayName = 'LeaveWrapper';
LeaveWrapper.propTypes = { children: PropTypes.node };

export default LeaveWrapper;
