import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
} from '@asteria/component-core/pageHeader';

import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { cn } from '@asteria/utils-funcs/classes';

import ForecasterHeaderTextGroup from './ForecasterHeaderTextGroup';
import HeaderTitle from './title';

import './styles.scss';

const HeaderLinear = React.memo((props) => {
	const { onAction } = props;

	const fullScreen = useSelector(ForecasterStore.selectors.flags.fullScreen);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-header',
				'asteria--type-linear',
			)}
		>
			{!fullScreen ? (
				<FeatureFlag feature="forecaster-header-outside" invert>
					<PageHeader>
						<PageContent>
							<HeaderTitle />
						</PageContent>
						<PagePostfix>
							<ForecasterHeaderTextGroup />
							<FeatureFlag feature="widget-notifications">
								<FeatureFlag feature="forecaster-header-notifications">
									<Notifications onAction={onAction} />
								</FeatureFlag>
							</FeatureFlag>
							<FeatureFlag feature="page-header-tour">
								<TourButton />
							</FeatureFlag>
						</PagePostfix>
					</PageHeader>
				</FeatureFlag>
			) : null}
		</div>
	);
});

HeaderLinear.displayName = 'HeaderLinear';
HeaderLinear.propTypes = {
	onAction: PropTypes.func,
};

export default HeaderLinear;
