import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import SearchChip from './chip';
import { useSearchResults, useTabBehavior } from './utils/hooks';

import './styles.scss';

const SearchResults = React.memo((props) => {
	const { className, onAction } = props;

	const ref = React.useRef(null);

	const results = useSearchResults();

	const counter = useTabBehavior({ results, ref, onAction });

	return (
		<div
			className={cn(
				'asteria-component__search-floating',
				'asteria--variant-results',
				className,
			)}
			ref={ref}
		>
			{results.length ? (
				results.map((object, index) => (
					<SearchChip
						key={[object?.type, object?.id].join('-')}
						{...object}
						onAction={onAction}
						active={index === counter}
					/>
				))
			) : (
				<Text>
					{TranslationService.get([
						// eslint-disable-next-line spellcheck/spell-checker
						'search.nomatches',
						'search.empty',
					])}
				</Text>
			)}
		</div>
	);
});

SearchResults.displayName = 'SearchResults';
SearchResults.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default SearchResults;
