import React from 'react';

import PropTypes from 'prop-types';

import BaseTeaser from './base';

const CategoryTeaser = (props) => (
	<BaseTeaser {...props} path="pages.forecaster.teaser.category" />
);

CategoryTeaser.displayName = 'CategoryTeaser';

CategoryTeaser.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default CategoryTeaser;
