import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const Switcher = (props) => {
	const { type, onChange } = props;

	const handleClick = React.useCallback(
		(type) => (event) => {
			event.preventDefault();
			event.stopPropagation();

			return onChange?.(type);
		},
		[onChange],
	);

	return (
		<Group
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
			className="asteria-component__selector-switch"
		>
			<Chip
				label={TranslationService.get([
					'deposit',
					`tags.deposit`,
					`forecaster.tags.selector.deposit`,
				])}
				size="sm"
				onClick={handleClick('deposit')}
				active={type === 'deposit'}
			/>
			<Chip
				label={TranslationService.get([
					'withdraw',
					`tags.withdraw`,
					`forecaster.tags.selector.withdraw`,
				])}
				size="sm"
				onClick={handleClick('withdraw')}
				active={type === 'withdraw'}
			/>
		</Group>
	);
};

Switcher.displayName = 'Switcher';
Switcher.propTypes = {
	type: PropTypes.oneOf(['deposit', 'withdraw']),
	onChange: PropTypes.func,
};

export default Switcher;
