import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import PageContent from './PageContent';
import PageNavigation from './PageNavigation';
import PagePostfix from './PagePostfix';
import PagePrefix from './PagePrefix';
import PageSubtitle from './PageSubtitle';
import PageTitle from './PageTitle';

import './styles.scss';

const PageHeader = React.memo(
	React.forwardRef((props, ref) => {
		const { className, children } = props;

		let hasPrefix = false,
			hasContent = false,
			hasPostfix = false;

		for (const element of React.Children.toArray(children)) {
			if (
				Object.is(element?.type, PagePrefix) ||
				Object.is(element?.props?.children?.type, PagePrefix)
			) {
				hasPrefix = true;
			}

			if (
				Object.is(element?.type, PageContent) ||
				Object.is(element?.props?.children?.type, PageContent)
			) {
				hasContent = true;
			}

			if (
				Object.is(element?.type, PagePostfix) ||
				Object.is(element?.props?.children?.type, PagePostfix)
			) {
				hasPostfix = true;
			}
		}

		return (
			<div
				className={cn(
					'asteria-component__page-header',
					{
						'asteria-component__page-header--no-prefix': !hasPrefix,
						'asteria-component__page-header--no-content':
							!hasContent,
						'asteria-component__page-header--no-postfix':
							!hasPostfix,
					},
					className,
				)}
				ref={ref}
			>
				{children}
			</div>
		);
	}),
);

PageHeader.displayName = 'PageHeader';

PageHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default PageHeader;

export {
	PageContent,
	PageNavigation,
	PagePostfix,
	PagePrefix,
	PageSubtitle,
	PageTitle,
};
