import React from 'react';
import { createPortal } from 'react-dom';

import { createPopper } from '@popperjs/core';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

function generateGetBoundingClientRect(x = 0, y = 0) {
	return () => new DOMRectReadOnly(x, y);
}

const ElementAnchor = React.memo((props) => {
	const {
		children,
		className,
		minWidth,
		placement,
		offset: $offset,
		element = null,
		strict,
	} = props;

	const [position, setPosition] = React.useState(
		element?.current?.getBoundingClientRect?.() ?? {},
	);

	const ref = React.useRef();

	const updatePosition = React.useCallback((element) => {
		if (!element) {
			return;
		}

		const rect = element.getBoundingClientRect();
		setPosition(rect);
	}, []);

	React.useEffect(() => {
		updatePosition(element.current);

		const virtualElement = {
			getBoundingClientRect: generateGetBoundingClientRect(),
		};

		let offset = $offset ?? 10;

		if (typeof offset === 'string') {
			const value = getComputedStyle(ref.current).getPropertyValue(
				offset,
			);

			if (value) {
				offset = Number.parseFloat(value);
			}
		}

		if (placement === 'mouse') {
			const popper = createPopper(virtualElement, ref.current, {
				placement: 'top',
				modifiers: [
					{
						name: 'offset',
						options: { offset: [0, offset] },
					},
					{ name: 'arrow' },
				],
			});

			const update = ({ clientX: x, clientY: y }) => {
				virtualElement.getBoundingClientRect =
					generateGetBoundingClientRect(x, y);
				popper.update();
			};

			document.addEventListener('mousemove', update);

			return () => {
				document.removeEventListener('mousemove', update);
				popper.destroy();
			};
		}

		if (element?.current && ref?.current) {
			const popper = createPopper(element.current, ref.current, {
				placement: placement || 'bottom-start',
				modifiers: [
					{
						name: 'offset',
						options: { offset: [0, offset] },
					},
					{ name: 'arrow' },
				],
			});

			return () => {
				popper.destroy();
			};
		}
	}, [element, ref, $offset, placement, updatePosition]);

	const componentStyle = React.useMemo(() => {
		let width = position.width;

		if (!minWidth) {
			return {};
		}

		if (strict) {
			return { width: width };
		}

		return { minWidth: width };
	}, [position.width, minWidth, strict]);

	return (
		<>
			{createPortal(
				<div
					ref={ref}
					style={componentStyle}
					className={cn('asteria-component__level', className)}
				>
					{children}
				</div>,
				document.body,
			)}
		</>
	);
});

ElementAnchor.displayName = 'ElementAnchor';

ElementAnchor.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	minWidth: PropTypes.number,
	placement: PropTypes.string,
	offset: PropTypes.number,
	element: PropTypes.element,
	strict: PropTypes.bool,
};

export default ElementAnchor;
