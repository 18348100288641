import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

export * as companies from './companies';
export * as users from './users';
export * as adminUsers from './adminUsers';

export const details = createSelector(
	(store) => store?.admin?.details,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const partnerId = createSelector(details, (value) => value?.partnerId);
