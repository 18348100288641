import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import * as FormatUtils from '@asteria/utils-funcs/format';

import Trends from '../../trends';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions' | 'onAction'> & { type: 'deposit' | 'withdraw', category: string, tag: string }> } */
const Category = React.memo(function Category(props) {
	const { type, category, tag, translationOptions, onAction } = props;

	const onLabelClick = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: 'forecast-status',
				data: { type: type, category: category, tag: tag },
			}),
		[category, onAction, tag, type],
	);

	const source = translationOptions?.data?.source?.value;
	const target = translationOptions?.data?.target?.value;

	return (
		<div className="flex items-center gap-2 truncate">
			<Trends
				direction={target?.total > source?.total ? 'up' : 'down'}
				size="sm"
				translationOptions={translationOptions}
			/>
			<Button
				variant="link"
				size="sm"
				label={FormatUtils.formatTag({
					type: type,
					category: category,
					tag: tag,
				})}
				onClick={onLabelClick}
			/>
		</div>
	);
});

Category.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	onAction: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default Category;
