import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import PageNavigation from './PageNavigation';
import PageSubtitle from './PageSubtitle';
import PageTitle from './PageTitle';

import './styles.scss';

const PageContent = (props) => {
	const { className, children } = props;

	let hasTitle = false,
		hasSubtitle = false,
		hasNavigation = false;

	for (const element of React.Children.toArray(children)) {
		if (Object.is(element?.type, PageTitle)) {
			hasTitle = true;
		}

		if (Object.is(element?.type, PageSubtitle)) {
			hasSubtitle = true;
		}

		if (Object.is(element?.type, PageNavigation)) {
			hasNavigation = true;
		}
	}

	return (
		<div
			className={cn(
				'asteria-component__page-header__content',
				{
					'asteria-component__page-header__content--no-title':
						!hasTitle,
					'asteria-component__page-header__content--no-subtitle':
						!hasSubtitle,
					'asteria-component__page-header__content--no-navigation':
						!hasNavigation,
				},
				className,
			)}
		>
			{children}
		</div>
	);
};

PageContent.displayName = 'PageContent';

PageContent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default PageContent;
