import React from 'react';

import PropTypes from 'prop-types';

/**
 * @typedef Props
 * @property { boolean } show
 * @property { React.ReactNode } children
 */

/**
 * @type { React.FC<Props> }
 */
const Wrapper = React.memo(function Wrapper({ show, children }) {
	if (!show) {
		return null;
	}

	return children;
});

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = { show: PropTypes.bool, children: PropTypes.node };

export default Wrapper;
