import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';

import Card from '../../base';

/**
 * @typedef BtnProps
 * @property { 'primary' | 'secondary' | 'tertiary' | 'link' } variant
 * @property { string } label
 * @property { string } icon
 * @property { 'first' | 'last' } iconPosition
 * @property { unknown } postfix
 * @property { string } analyticsKey
 */

/**
 * @typedef Props
 * @property { boolean | Partial<BtnProps> } dismiss
 * @property { boolean | Partial<BtnProps> } connect
 * @property { unknown } data
 * @property { unknown } form
 * @property { <TResponse>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<Props> } */
const Footer = React.memo(function Footer(props) {
	const { dismiss = true, connect = false, data, onAction, form } = props;

	const onDismiss = useMutation({
		mutationFn: async () => onAction?.('card:dismiss', { form, data }),
	});

	const onConnect = useMutation({
		mutationFn: async () => onAction?.('card:connect', { form, data }),
	});

	if (!dismiss && !connect) {
		return null;
	}

	return (
		<Card.Footer>
			{dismiss ? (
				<FooterSection position="first">
					<Button
						variant={dismiss?.variant ?? 'secondary'}
						label={
							dismiss?.label ??
							TranslationService.getV2(['card.footer.action'], {
								postfix: {
									action: 'dismiss',
									...dismiss?.postfix,
								},
								data: data,
							})
						}
						icon={dismiss?.icon}
						iconPosition={dismiss?.iconPosition}
						onClick={onDismiss.mutate}
						analyticsKey={
							dismiss?.analyticsKey ??
							'card.footer.action.dismiss'
						}
						analytics={{ data, form }}
					/>
				</FooterSection>
			) : null}
			{connect ? (
				<FooterSection position="last">
					<Button
						variant={connect?.variant ?? 'primary'}
						label={
							connect?.label ??
							TranslationService.getV2(['card.footer.action'], {
								postfix: {
									action: 'connect',
									...connect?.postfix,
								},
								data: data,
							})
						}
						icon={connect?.icon}
						iconPosition={connect?.iconPosition}
						onClick={onConnect.mutate}
						analyticsKey={
							connect?.analyticsKey ??
							'card.footer.action.connect'
						}
						analytics={{ data, form }}
					/>
				</FooterSection>
			) : null}
		</Card.Footer>
	);
});

Footer.displayName = 'Footer';

Footer.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	connect: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			variant: PropTypes.string,
			label: PropTypes.string,
			icon: PropTypes.string,
			iconPosition: PropTypes.string,
			postfix: PropTypes.object,
		}),
	]),
	dismiss: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			variant: PropTypes.string,
			label: PropTypes.string,
			icon: PropTypes.string,
			iconPosition: PropTypes.string,
			postfix: PropTypes.object,
		}),
	]),
	data: PropTypes.object,
	form: PropTypes.object,
};

export default Footer;
