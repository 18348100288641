import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Title, { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const AboutScenarioModal = (props) => {
	const { open, onClose, onAction } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);

	const handleContinue = useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ScenarioCreate,
				data: { type: 'FORECAST' },
			}),
		);
	}, [dispatch]);

	const handleStartTour = useCallback(() => {
		onAction?.('scenario:tour:show');
	}, [onAction]);

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			size="sm"
			className={cn('asteria--type-scenario', 'asteria--action-about')}
		>
			<Wrapper>
				<Header onClose={handleClose}>
					{TranslationService.get('forecaster.modal.about.title')}
				</Header>
				<Content>
					<FormWrapper>
						<Content>
							<TextGroup>
								<Title type="title" size="xs">
									{TranslationService.get(
										'forecaster.modal.about.body.label',
									)}
								</Title>
							</TextGroup>

							<Text>
								{TranslationService.get(
									'forecaster.modal.about.body',
								)}
							</Text>
							<Button
								variant="link"
								label={TranslationService.get(
									'forecaster.modal.about.guide',
								)}
								onClick={handleStartTour}
								analyticsKey="forecaster.scenario.button.tourguide"
							/>
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection>
						{hasHistory ? (
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.back',
									'forecaster.modal.about.button.back',
								])}
								onClick={handleClose}
								analyticsKey="forecaster.modal.about.back"
							/>
						) : (
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.abort',
									'forecaster.modal.about.button.cancel',
								])}
								onClick={handleClose}
								analyticsKey="forecaster.modal.about.cancel"
							/>
						)}
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.continue',
								'forecaster.modal.about.button.continue',
							])}
							onClick={handleContinue}
							analyticsKey="forecaster.modal.about.continue"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

AboutScenarioModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
};

export default AboutScenarioModal;
