import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { sizeClasses } from '@asteria/component-core/utils';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';

import ListCell from './cell';
import ListFilter from './filter';
import ListGroup from './group';
import ListHeader from './header';
import ListItem from './item';

import './index.scss';

const List = React.forwardRef(function List(props, ref) {
	const { className, children, applyTypographySizes = true } = props;

	return (
		<div
			className={cn(
				'asteria-component__list',
				{ 'asteria--state-typography': applyTypographySizes },
				className,
				sizeClasses(props),
			)}
			ref={ref}
		>
			{children}
		</div>
	);
});

List.propTypes = {
	size: SizeProp(),
	children: PropTypes.node,
	className: PropTypes.string,
	applyTypographySizes: PropTypes.bool,
};

List.defaultProps = {
	className: null,
};

ComponentService.register('List', List, {
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	children: { type: 'node' },
	className: { type: 'string' },
	applyTypographySizes: { type: 'boolean' },
});

ComponentService.register('ListHeader', ListHeader, {
	className: { type: 'string' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	children: { type: 'node' },
});

ComponentService.register('ListGroup', ListGroup, {
	className: { type: 'string' },
	border: { type: 'boolean' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	children: { type: 'node' },
});

ComponentService.register('ListItem', ListItem, {
	children: { type: 'node' },
	className: { type: 'string' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
});

ComponentService.register('ListCell', ListCell, {
	children: { type: 'node' },
	className: { type: 'string' },
});

ComponentService.register('ListFilter', ListFilter, {
	className: { type: 'string' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	children: { type: 'node' },
});

export default React.memo(List);
export { ListHeader, ListGroup, ListItem, ListCell, ListFilter };
