import { LanguageService } from '@asteria/backend-utils-services';

import { TranslationService } from '@asteria/language';

export async function fetch({
	accessToken,
	language,
	partnerId,
	themeId,
	signal,

	skipUpdate,
}) {
	return LanguageService.translation
		.fetchOne(
			{
				code: language,
				partnerId: partnerId,
				themeId: themeId || partnerId,
				signal: signal,
				fields: `translations`,
			},
			{ token: accessToken },
		)
		.then(({ translations }) => {
			if (!skipUpdate) {
				TranslationService.code = language;
				TranslationService.setTranslations(translations);
			}

			return translations;
		})
		.catch(() => ({}));
}
