import {
	eachMonthOfInterval,
	eachWeekOfInterval,
	eachYearOfInterval,
	endOfISOWeek,
	endOfMonth,
	endOfYear,
	format,
	isFuture,
	isThisMonth,
	parseISO,
	startOfISOWeek,
	startOfMonth,
	startOfYear,
} from 'date-fns';

import { FORMAT } from '../constants';

import { applySorting } from './sorting';

const startOfTime = (date, size) => {
	let nextDate = date;

	if (size === 'week') {
		nextDate = startOfISOWeek(nextDate);
	}
	if (size === 'month') {
		nextDate = startOfMonth(nextDate);
	}
	if (size === 'year') {
		nextDate = startOfYear(nextDate);
	}

	return format(nextDate, 'yyyy-MM-dd');
};

const endOfTime = (date, size) => {
	let nextDate = date;

	if (size === 'week') {
		nextDate = endOfISOWeek(nextDate);
	}
	if (size === 'month') {
		nextDate = endOfMonth(nextDate);
	}
	if (size === 'year') {
		nextDate = endOfYear(nextDate);
	}

	return format(nextDate, 'yyyy-MM-dd');
};

const each = (size) => {
	if (size === 'week') {
		return eachWeekOfInterval;
	}

	if (size === 'month') {
		return eachMonthOfInterval;
	}

	return eachYearOfInterval;
};

export const mapDateToGroup = ($date, timesize) => {
	const date = typeof $date === 'string' ? parseISO($date) : $date;

	if (timesize === 'week') {
		return format(startOfISOWeek(date), FORMAT.week);
	}

	return format(date, FORMAT[timesize]);
};

/**
 * @typedef { { [key: string]: { id: string, startDate: Date, endDate: Date, items: object[], status: 'FORECAST' | 'HISTORY' } } } TransactionGroup
 */

/**
 * @param {{ size: string, startDate: string | Date, endDate: string | Date, initial?: TransactionGroup }} options
 * @returns { TransactionGroup }
 */
export function generateGroups(options = {}) {
	const { size, startDate, endDate, initial } = options;

	return each(size)({
		start: parseISO(
			startOfTime(
				typeof startDate === 'string' ? parseISO(startDate) : startDate,
				size,
			),
		),
		end: parseISO(
			endOfTime(
				typeof endDate === 'string' ? parseISO(endDate) : endDate,
				size,
			),
		),
	}).reduce((acc, date) => {
		const key = mapDateToGroup(date, size);

		if (acc[key] === undefined) {
			acc[key] = {
				id: key,
				startDate: startOfTime(date, size),
				endDate: endOfTime(date, size),
				items: [],
				status:
					isFuture(date) || isThisMonth(date)
						? 'FORECAST'
						: 'HISTORY',
			};
		}

		return acc;
	}, initial);
}

/**
 * @param { { items: { [id: string]: { paymentDate: string, [key: string]: unknown } }, size: string, initial?: TransactionGroup, sorting: { field: string, direction: string } } } options
 * @returns { TransactionGroup }
 */
export function generateGroupItems(options = {}) {
	const { items = {}, size, initial = {}, sorting = {} } = options;

	const transactions = Object.values(items)
		.filter(({ paymentDate }) => paymentDate)
		.map(({ paymentDate, ...object }) => ({
			...object,
			paymentDate: parseISO(paymentDate),
		}))
		.sort((a, b) => a.paymentDate - b.paymentDate);

	const grouped = transactions.reduce((acc, object) => {
		const { paymentDate } = object;
		const key = mapDateToGroup(paymentDate, size);

		if (acc[key] === undefined) {
			acc[key] = [];
		}

		acc[key].push(object.id ?? object._id);

		return acc;
	}, {});

	let groups = initial;

	if (transactions.length) {
		groups = generateGroups({
			startDate: transactions?.[0]?.paymentDate,
			endDate: transactions?.slice?.(-1)?.[0]?.paymentDate,
			size: size,
			initial: initial,
		});

		for (const key in grouped) {
			groups[key].items = grouped[key];
		}
	}

	return applySorting({ sorting: sorting, groups: groups, items: items });
}
