import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../../../PropTypes';
import Button from '../../../button';
import { useLoading } from '../../hooks';

import '../styles.scss';

/** @type { React.FC<{ loading: boolean, onBack: () => void, size: string }> } */
const BackButton = React.memo(function BackButton({
	onBack,
	loading: $loading,
	...args
}) {
	const loading = useLoading($loading);

	return (
		<Button
			icon="chevron-left"
			onClick={onBack}
			analyticsKey="wrapper.header.back"
			className={cn(
				'asteria-component__wrapper-header-action',
				'asteria--variant-back',
				{ 'asteria--state-loading': loading },
			)}
			{...args}
		/>
	);
});

BackButton.propTypes = {
	loading: PropTypes.bool,
	onBack: PropTypes.func,
	size: SizeProp(),
};

export default BackButton;
