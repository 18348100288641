import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import './styles.scss';

const TagSelectorItemPostfix = (props) => {
	const { onEdit } = props;

	return (
		<Group verticalAlign="center" horizontalAlign="center">
			<Button icon="check" className="asteria--action-check" size="sm" />
			<Button
				icon="edit"
				className="asteria--action-edit"
				stopPropagation
				variant="forecaster-edit"
				onClick={onEdit}
			/>
		</Group>
	);
};

TagSelectorItemPostfix.displayName = 'TagSelectorItemPostfix';
TagSelectorItemPostfix.propTypes = {
	object: PropTypes.object,
	onEdit: PropTypes.func,
};

export default TagSelectorItemPostfix;
