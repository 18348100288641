import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import {
	SatisfactionSelectorClosed,
	SatisfactionSelectorOpen,
} from '../../../../components/selector/satisfaction';
import { useProperties } from '../../../../hooks';
import * as AdjustCategory from '../../../../utils/adjustCategory';
import * as AdjustTag from '../../../../utils/adjustTag';
import * as AdjustType from '../../../../utils/adjustType';
import applyFormChanges from '../../../../utils/applyFormChanges';
import { useNavigation } from '../../hooks';

import './styles.scss';

const SatisfactionSection = (props) => {
	const { className, variant } = props;

	const { getValues, setValue, reset, unregister } = useFormContext();

	const [open, setOpen] = React.useState(true);

	const { type, category, tag, level } = useNavigation({ variant });
	const isZeroLevel = level === 0;

	const onOpen = React.useCallback(() => setOpen(true), []);

	const onClose = React.useCallback(() => setOpen(false), []);

	const onSelect = React.useCallback(
		(value) => {
			const form = getValues();

			let changes = [];

			if (category === '$type') {
				const types = isZeroLevel ? ['deposit', 'withdraw'] : [type];

				changes = changes.concat(
					types.flatMap((type) =>
						AdjustType.setProbability({
							type: type,
							value: value,
							form: form,
							strict: true,
						}),
					),
				);
			} else {
				changes = changes
					.concat(
						AdjustTag.setProbability({
							type: type,
							category: category,
							tag: tag,
							value: value,
							form: form,
							strict: true,
						}),
					)
					.concat(
						AdjustCategory.recalculate({
							type: type,
							category: category,
							form: form,
						}),
					);
			}

			applyFormChanges({
				changes: changes,
				setValue: setValue,
				getValues: getValues,
				unregister: unregister,
				reset: reset,
				event: 'bulk.probability',
			});

			onClose?.();
		},
		[
			category,
			getValues,
			isZeroLevel,
			onClose,
			reset,
			setValue,
			tag,
			type,
			unregister,
		],
	);

	const { label, probability } = useProperties({
		type: type,
		category: category,
		tag: tag,
		useForm: useFormValues,
		useStaticForm: getValues,
	});

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-satisfaction',
				{ 'asteria--state-open': open, 'asteria--state-closed': !open },
				className,
			)}
		>
			{open ? (
				<div className="asteria-component__forecaster-page-section-content">
					<Button
						size="sm"
						icon="close"
						variant="satisfaction-close"
						onClick={onClose}
					/>
					<TextGroup>
						<Title size="xs">
							{TranslationService.get(
								[
									`forecaster.page.category.section.probability.title`,
								],
								undefined,
								{ tag: label },
							)}
						</Title>
						<Text size="sm">
							{TranslationService.get(
								[
									`forecaster.page.category.section.probability.content`,
								],
								undefined,
								{ tag: label },
							)}
						</Text>
					</TextGroup>
					<SatisfactionSelectorOpen
						onSelect={onSelect}
						active={probability}
					/>
				</div>
			) : (
				<SatisfactionSelectorClosed
					onClick={onOpen}
					value={probability}
				/>
			)}
		</div>
	);
};

SatisfactionSection.displayName = 'SatisfactionSection';

SatisfactionSection.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
};

export default SatisfactionSection;
