import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
	addYears,
	eachYearOfInterval,
	format,
	isSameYear,
	isThisYear,
	subYears,
} from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import DatePickerContentCell from '../DatePickerContentCell';
import DatePickerSlider from '../DatePickerSlider';
import {
	cleanValue,
	getLocale,
	getNextYearDisabled,
	getPreviousYearDisabled,
	getSelectedCSSClasses,
} from '../utils';

import './index.scss';

const DatePickerContentYearCalendar = (props) => {
	const {
		onSelect,
		value: propValue,
		defaultValue,
		previousDate,
		updateCurrentDate,

		isPastDisabled,
		isFutureDisabled,

		locale,

		startDate,
		endDate,
		disabledDates,
	} = props;

	const currentLocale = getLocale(locale);
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const items = useMemo(() => {
		return eachYearOfInterval({
			start: subYears(value, 6),
			end: addYears(value, 5),
		});
	}, [value]);

	const { previousYear, nextYear } = useMemo(
		() => ({
			previousYear: subYears(value, 4),
			nextYear: addYears(value, 4),
		}),
		[value],
	);

	const isPreviousYearDisabled = useMemo(
		getPreviousYearDisabled({
			previousDate: previousDate,
			previousYear: previousYear,
			isPastDisabled: isPastDisabled,
			startDate: startDate,
		}),
		[previousDate, previousYear, isPastDisabled],
	);

	const isNextYearDisabled = useMemo(
		getNextYearDisabled({
			nextYear: nextYear,
			isFutureDisabled: isFutureDisabled,
			endDate: endDate,
		}),
		[nextYear, isFutureDisabled],
	);

	const onValueChange = useCallback(
		(value) => {
			setValue(value);
			updateCurrentDate(value);
		},
		[value],
	);

	return (
		<div className="asteria-component__datepicker__content__week">
			<div className="asteria-component__datepicker__content__slider__wrapper">
				<DatePickerSlider
					isPreviousDisabled={isPreviousYearDisabled}
					isNextDisabled={isNextYearDisabled}
					onPreviousClick={() => {
						onValueChange(previousYear);
					}}
					onNextClick={() => {
						onValueChange(nextYear);
					}}
					label={[items[0], items.slice(-1)[0]]
						.map((value) =>
							cleanValue(
								format(value, 'yyyy', {
									locale: currentLocale,
								}),
							),
						)
						.join(' - ')}
				/>
			</div>

			<div
				className={cn(
					'asteria-component__datepicker__content__week__calendar',
					'asteria-component__datepicker__content__calendar',
				)}
			>
				{items.map((date) => (
					<DatePickerContentCell
						key={['calendar', 'year', date].join('-')}
						date={date}
						onSelect={onSelect}
						value={propValue}
						isPastDisabled={isPastDisabled}
						isFutureDisabled={isFutureDisabled}
						isThisDate={isThisYear(date)}
						className={cn(
							'asteria-component__datepicker__content__year__calendar__item',
							getSelectedCSSClasses({
								value: propValue,
								date: date,
								validate: isSameYear,
							}),
						)}
						format="yyyy"
						startDate={startDate}
						endDate={endDate}
						disabledDates={disabledDates}
						locale={locale}
					/>
				))}
			</div>
		</div>
	);
};

DatePickerContentYearCalendar.propTypes = {
	defaultValue: PropTypes.instanceOf(Date),

	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	previousDate: PropTypes.instanceOf(Date),
	updateCurrentDate: PropTypes.func,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentYearCalendar.defaultProps = {};

export default DatePickerContentYearCalendar;
