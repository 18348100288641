import React from 'react';

import { useQueries, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { TranslationService } from '@asteria/language';

import { fetch } from '../api/translations';

export const Context = React.createContext({
	language: 'sv',
	onChange: () => null,
});

const TranslationsWrapper = React.memo((props) => {
	const { children, partnerId, enabled = true } = props;

	const [language, setLanguage] = React.useState(props.language);

	useQueries({
		queries: [
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				keepPreviousData: true,

				queryKey: ['widget', 'translations', partnerId ?? 'NONE', 'en'],
				queryFn: async ({ signal }) =>
					fetch({
						language: 'en',
						partnerId: partnerId,
						signal: signal,
						skipUpdate: true,
					}),
				enabled: enabled,
				placeholderData: null,
			},
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				keepPreviousData: true,

				queryKey: ['widget', 'translations', partnerId ?? 'NONE', 'sv'],
				queryFn: async ({ signal }) =>
					fetch({
						language: 'sv',
						partnerId: partnerId,
						signal: signal,
						skipUpdate: true,
					}),
				enabled: enabled,
				placeholderData: null,
			},
		],
	});

	const { data: translations } = useQuery({
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		queryKey: [
			'widget',
			'translations',
			partnerId ?? 'NONE',
			language ?? 'NONE',
		],
		queryFn: async ({ signal }) =>
			fetch({ language: language, partnerId: partnerId, signal: signal }),
		enabled: !!language && enabled,
		placeholderData: null,
	});

	const ctx = React.useMemo(
		() => ({
			language: language,
			onChange: setLanguage,
			translations: translations,
		}),
		[language, translations],
	);

	if (enabled && translations === null) {
		return null;
	}

	TranslationService.code = language;
	TranslationService.setTranslations(translations);

	return <Context.Provider value={ctx}>{children}</Context.Provider>;
});

TranslationsWrapper.displayName = 'TranslationsWrapper';
TranslationsWrapper.propTypes = {
	children: PropTypes.node,
	language: PropTypes.string,
	partnerId: PropTypes.string,
	enabled: PropTypes.bool,
};

export default TranslationsWrapper;
