import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import {
	endOfToday,
	formatISO,
	isThisMonth,
	parseISO,
	startOfMonth,
} from 'date-fns';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { pushTags } from '@asteria/datalayer/stores/app';

import Analytics from '@asteria/utils-analytics';

import addTag from '../../../../../utils/addTag';
import { useData } from '../hooks';

import TransactionGroupHeaderView from './view';

const selectors = {
	hasAdjustableTag: createSelector(
		(store) => store?.app?.tags ?? [],
		(tags) =>
			tags?.some?.(
				(object) =>
					object?.category?.name === '$forecaster' &&
					object?.name === '$adjusted',
			),
	),
};

const TransactionGroupHeaderProvider = React.memo((props) => {
	const { date, category, tag, types } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const { setValue } = useFormContext();

	const { total } = useData({
		types: types,
		date: date,
		category: category,
		tag: tag,
	});

	const handleCreate = React.useCallback(
		(type) => {
			const hasAdjustableTag = selectors.hasAdjustableTag(
				store.getState(),
			);

			let categoryName = category,
				tagName = tag;

			if (!categoryName || categoryName === '$type') {
				categoryName = '$forecaster';
				tagName = '$adjusted';
			}

			Analytics.event('forecaster.transactions.create', {
				type: type,
				category: categoryName,
				tag: tagName,
			});

			if (tagName === '$adjusted' && !hasAdjustableTag) {
				dispatch(
					pushTags({
						name: '$adjusted',
						category: { name: '$forecaster' },
					}),
				);
			}

			const id = uuid();

			const name = [
				type,
				'data',
				date,
				'FORECAST',
				'categories',
				categoryName,
				'tags',
				tagName,
				'transactions',
				id,
			].join('.');

			addTag({
				category: categoryName,
				tag: tagName,
				dispatch: dispatch,
			});

			const $date = date instanceof Date ? date : parseISO(date);

			setValue(
				name,
				{
					uuid: id,
					paymentDate: formatISO(
						isThisMonth($date) ? endOfToday() : startOfMonth($date),
						{ representation: 'date' },
					),
					// paymentDate: formatISO(
					// 	endOfMonth(
					// 		date instanceof Date ? date : parseISO(date),
					// 	),
					// 	{ representation: 'date' },
					// ),
					value: 0,
					probability: 1,
					currency:
						store.getState()?.app?.company?.settings?.currency ??
						'SEK',
					$category: [categoryName, tagName].join('-'),
				},
				{ shouldTouch: true, shouldDirty: true },
			);
		},
		[category, date, dispatch, setValue, store, tag],
	);

	const handleCreateDeposit = React.useCallback(() => {
		handleCreate?.('deposit');
	}, [handleCreate]);

	const handleCreateWithdraw = React.useCallback(() => {
		handleCreate?.('withdraw');
	}, [handleCreate]);

	const type = React.useMemo(() => {
		if (!types || types?.length > 1) {
			return 'all';
		}

		return types?.[0];
	}, [types]);

	return (
		<TransactionGroupHeaderView
			types={types}
			type={type}
			category={category}
			tag={tag}
			date={date}
			total={total}
			handleCreateDeposit={handleCreateDeposit}
			handleCreateWithdraw={handleCreateWithdraw}
		/>
	);
});

TransactionGroupHeaderProvider.displayName = 'TransactionGroupHeaderProvider';

TransactionGroupHeaderProvider.propTypes = {
	onAction: PropTypes.func,

	types: PropTypes.arrayOf(PropTypes.string),
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default TransactionGroupHeaderProvider;
