import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import { useChange } from './hooks';

const withRegister = function (Component) {
	const Uncontrolled = React.forwardRef((props, ref) => {
		const {
			debounce,
			onChange: onValueChange,
			isRawEvent,
			...args
		} = props;

		const onChange = useChange({
			debounce: debounce,
			onChange: onValueChange,
			isRawEvent: isRawEvent,
		});

		const handleChange = React.useCallback(
			(event) => {
				if (isRawEvent) {
					return onChange?.(event);
				}

				return onChange?.({
					name: event?.target?.name,
					value: event?.target?.value,
					checked: event?.target?.checked,
				});
			},
			[isRawEvent, onChange],
		);

		return <Component {...args} onChange={handleChange} ref={ref} />;
	});

	Uncontrolled.displayName = Component.displayName;
	Uncontrolled.propTypes = Component.propTypes;
	Uncontrolled.defaultProps = Component.defaultProps;

	const Controlled = React.forwardRef((props, ref) => {
		const {
			name,
			error: propError,
			onChange: onValueChange,
			onBlur: onValueBlur,
			minLength,
			maxLength,
			min,
			max,
			disabled,
			required,
			valueAsDate,
			valueAsNumber,
			shouldUnregister,

			debounce,
		} = props;

		const onChange = useChange({
			debounce: debounce,
			onChange: onValueChange,
		});

		const { register } = useFormContext();
		const { errors } = useFormState({ name: name });

		const { ref: onRefChange, ...field } = register(name, {
			onChange: (event) =>
				onChange?.({
					name: event?.target?.name,
					value: event?.target?.value,
					checked: event?.target?.checked,
				}),
			onBlur: onValueBlur,
			minLength: minLength,
			maxLength: maxLength,
			min: min,
			max: max,
			disabled: disabled,
			required: required,
			valueAsDate: valueAsDate,
			valueAsNumber: valueAsNumber,
			shouldUnregister: shouldUnregister,
		});

		const $ref = useCombinedRefs(ref, onRefChange);

		const error = propError ?? get(errors, name) ?? errors?.[name];

		return (
			<Uncontrolled
				{...props}
				{...field}
				error={error}
				required={false}
				isRawEvent
				ref={$ref}
			/>
		);
	});

	Controlled.displayName = Component.displayName;
	Controlled.propTypes = Component.propTypes;
	Controlled.defaultProps = Component.defaultProps;

	const Wrapper = React.forwardRef((props, ref) => {
		const { uncontrolled, name } = props;

		if (uncontrolled) {
			return <Uncontrolled key={name} {...props} ref={ref} />;
		}

		return <Controlled key={name} {...props} ref={ref} />;
	});

	Wrapper.displayName = Component.displayName;
	Wrapper.propTypes = {
		...Component.propTypes,
		uncontrolled: PropTypes.bool,
	};
	Wrapper.defaultProps = Component.defaultProps;

	return Wrapper;
};

export default withRegister;
