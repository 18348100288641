import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const Checkbox = (props) => {
	const { name, label, error, value, onChange } = props;

	return (
		<div
			className={cn('asteria-component__checkbox', 'asteria--size-md', {
				'asteria--state-error': error,
				'asteria--status-error': error,
			})}
		>
			<label className="asteria-component__label__wrapper">
				<div className="asteria-component__checkbox__inner">
					<div className="asteria-component__form__label">
						<p className="asteria-component__typography asteria-component__text asteria-component__text--size-md">
							{label}
						</p>
					</div>
					<input
						type="checkbox"
						name={name}
						checked={value}
						onChange={onChange}
					/>
					<span className="asteria-component__icon asteria-component__checkbox__icon asteria-utils-position-first asteria--size-md">
						<span className="asteria-component__icon-content asteria--icon-check"></span>
					</span>
				</div>
			</label>
			{error ? (
				<div>
					<div className="asteria-component__alert asteria-component__form-error asteria-component__alert--level-error asteria-component__alert--no-icon asteria-component__alert--no-title">
						<div className="asteria-component__wrapper asteria-component__wrapper--variant-vertical asteria-component__wrapper--no-header asteria-component__wrapper--no-footer asteria-component__alert__wrapper">
							<div className="asteria-component__wrapper-content asteria-component__alert__wrapper-content">
								<p className="asteria-component__typography asteria-component__text asteria-component__text--size-md">
									{error}
								</p>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.node,

	value: PropTypes.bool,
	onChange: PropTypes.func,
};

export default Checkbox;
