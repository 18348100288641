import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const ForecastCategoryClean = React.memo((props) => {
	const {
		onClose,
		onSubmit,
		type,
		category,
		tag,

		data: $data,
		useData,

		content: $content,
		useContent,
	} = props;

	const handleSubmit = React.useCallback(
		(event) => onSubmit?.(event, { type, category, tag }),
		[category, onSubmit, tag, type],
	);

	const data = useData?.({ type, category, tag }) ?? $data;
	const content = useContent?.({ type, category, tag }) ?? $content;

	return (
		<Wrapper>
			<Header onClose={onClose} verticalAlign="center">
				{TranslationService.get('tag.deselect.title', undefined, data)}
			</Header>
			<Content>
				{content ? (
					<Contenter content={content} data={data} />
				) : (
					<TextGroup>
						<Title type="title" size="sm">
							{TranslationService.get([
								'tag.deselect.content.info',
							])}
						</Title>
						<Text>
							{TranslationService.get(
								['tag.deselect.content.info.question'],
								undefined,
								data,
							)}
						</Text>
					</TextGroup>
				)}
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						onClick={onClose}
						label={TranslationService.get([
							'button.cancel',
							'action.cancel',
							'tags.deselect.button.cancel',
						])}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						onClick={handleSubmit}
						label={TranslationService.get([
							'button.remove',
							'action.remove',
							'tags.deselect.button.remove',
						])}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ForecastCategoryClean.displayName = 'ForecastCategoryClean';
ForecastCategoryClean.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	_id: PropTypes.string,
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

const ForecastCategoryCleanModal = React.memo((props) => {
	const { className, onClose, open } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__forecaster-modal',
				'asteria--variant-category',
				'asteria--type-clean',
				className,
			)}
			onClose={onClose}
			open={open}
			size="sm"
		>
			<ForecastCategoryClean {...props} />
		</Modal>
	);
});

ForecastCategoryCleanModal.displayName = 'ForecastCategoryRemoveModal';

ForecastCategoryCleanModal.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	...ForecastCategoryClean.propTypes,
};

export default ForecastCategoryCleanModal;
