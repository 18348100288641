import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { TextGroup, Title } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';
import LevelNavigation from '@asteria/component-level-navigation';
import Prefix from '@asteria/component-prefix';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import { useProperties, useTagContent } from '../../hooks';
import Context from '../../logic/context';

import Switcher from './Switcher';
import { useNavigation, useNavigationConfig } from './hooks';

import './styles.scss';

const StaticNavigation = React.memo((props) => {
	const { type, category, tag } = props;

	const { history } = React.useContext(Context);

	const { getValues } = useFormContext();

	const { label, colors } = useProperties({
		type: type,
		category: category,
		tag: tag,

		useForm: useFormValues,
		useStaticForm: getValues,
	});

	const content = useTagContent({
		type: type,
		category: category,
		tag: tag,

		useForm: useFormValues,
		useStaticForm: getValues,

		history: history,

		key: 'page.category.content',
		props: { size: 'sm' },
	});

	return (
		<TextGroup>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="left"
			>
				<Prefix colors={colors} />
				<Title size="sm">
					{TranslationService.get(
						[
							'forecaster.page.title',
							`forecaster.page.category.title`,
							`forecaster.page.category.${type}.title`,
							`forecaster.page.category.${type}.${category}.title`,
							`forecaster.page.category.${type}.${tag}.title`,
							`forecaster.page.category.${type}.${category}.${tag}.title`,
						],
						undefined,
						{ tag: label },
					)}
				</Title>
			</Group>

			{content}
		</TextGroup>
	);
});

StaticNavigation.displayName = 'StaticNavigation';
StaticNavigation.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

const DynamicNavigation = React.memo((props) => {
	const { parent, type, category, tag, path, onAction, level } = props;

	const navigate = useNavigate();

	const hasBackButtonLabel = useFeature(
		'forecaster-navigation-back-button-label',
	);

	const { getValues } = useFormContext();

	const dispatch = useDispatch();
	const store = useStore();

	const handleAction = React.useCallback(
		(action, data, options) => {
			if (action === 'navigation:navigate') {
				onAction?.('navigation:navigate', data);

				if (data === null) {
					return navigate(`/forecaster/`);
				}

				let next = (data ?? []).join('/');

				if (
					level === 2 &&
					['deposit', 'withdraw'].includes(next) &&
					!options?.toggle
				) {
					next = '';
				}

				return navigate(`/forecaster/category/` + next);
			}

			if (action === 'navigation:create') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.TagEdit,
					}),
				);
			}

			if (action === 'navigation:update') {
				const id = data?.[data?.length - 1];
				const tag = AppStore.selectors.tag(store.getState(), id);

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.TagEdit,
						data: { data: tag, form: cloneDeep(getValues()) },
					}),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, getValues, level, navigate, onAction, store],
	);

	const config = useNavigationConfig({ onAction: handleAction });

	const BackButtonProps = React.useMemo(() => {
		// const level = parent?.level ?? -1;

		return {
			icon: 'action-arrow-left',
			size: 'lg',
			iconSize: 'lg',
			variant: 'tertiary',
			label: hasBackButtonLabel
				? TranslationService.get(
						[
							'forecaster.navigation.back.label',
							`forecaster.navigation.back.level.${level}.label`,
							`forecaster.navigation.back.${type}.label`,
							`forecaster.navigation.back.level.${level}.${type}.label`,
						],
						undefined,
						{
							label: FormatUtils.formatTag({
								type: type,
								category: category,
								tag: tag,
							}),
							parent: FormatUtils.formatTag({
								type: parent?.type,
								category: parent?.category,
								tag: parent?.tag,
							}),
							type: FormatUtils.formatTag({
								type: type,
								category: `$type`,
								tag: `$${type}`,
							}),
						},
				  )
				: null,
		};
	}, [
		category,
		hasBackButtonLabel,
		level,
		parent?.category,
		parent?.tag,
		parent?.type,
		tag,
		type,
	]);

	return (
		<LevelNavigation
			config={config}
			path={path}
			onAction={handleAction}
			showBackAlways
			back={BackButtonProps}
			toggleable
		/>
	);
});

DynamicNavigation.displayName = 'DynamicNavigation';
DynamicNavigation.propTypes = {
	path: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,

	parent: PropTypes.shape({
		type: PropTypes.string,
		category: PropTypes.string,
		tag: PropTypes.string,
		level: PropTypes.number,
	}),
	level: PropTypes.number,

	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

const SectionHeader = React.memo((props) => {
	const { onAction, variant, view, onChangeView } = props;

	const hasBackButtonLabel = useFeature(
		'forecaster-navigation-back-button-label',
	);

	const { getValues } = useFormContext();

	const { type, category, tag, path, parent, level } = useNavigation({
		variant,
	});

	const onBack = React.useCallback(() => {
		const isDirty = !!getValues('$touched');

		if (isDirty) {
			return onAction?.('navigate', null);
		}

		return onAction?.('form:discard');
	}, [getValues, onAction]);

	return (
		<Group
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-header',
				{ [`asteria--variant-${variant}`]: variant },
				{
					'asteria--feature-forecaster-navigation-back-button-label':
						hasBackButtonLabel,
				},
			)}
			direction="horizontal"
			verticalAlign="top"
			horizontalAlign="left"
		>
			{variant === 'action' ? (
				<Button
					variant="link"
					icon="action-arrow-left"
					size="sm"
					iconSize="lg"
					onClick={onBack}
				/>
			) : null}

			<div className="asteria-component__forecaster-page-section-content">
				{variant === 'action' ? (
					<StaticNavigation
						onAction={onAction}
						type={type}
						category={category}
						tag={tag}
					/>
				) : null}

				{variant === 'category' ? (
					<DynamicNavigation
						onAction={onAction}
						type={type}
						category={category}
						tag={tag}
						path={path}
						parent={parent}
						level={level}
					/>
				) : null}
			</div>

			<Switcher active={view} setActive={onChangeView} />
		</Group>
	);
});

SectionHeader.displayName = 'SectionHeader';
SectionHeader.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	view: PropTypes.string,
	onChangeView: PropTypes.func,
};

export default SectionHeader;
