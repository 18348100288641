import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import { TranslationService } from '@asteria/language';

const Trends = React.memo(function Trends(props) {
	const { direction, size, translationOptions } = props;

	return (
		<TooltipWrapper
			tooltip={TranslationService.getV2(
				['card.trends.tooltip'],
				translationOptions,
			)}
		>
			<Icon
				icon={
					direction === 'up' ? 'arrow-up-right' : 'arrow-down-right'
				}
				size={size}
			/>
		</TooltipWrapper>
	);
});

Trends.displayName = 'Trends';

Trends.propTypes = {
	direction: PropTypes.oneOf(['up', 'down']),
	size: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default Trends;
