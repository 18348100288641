import React from 'react';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

// Need to make a easy grid tool inspiration https://tailwindcss.com/docs/grid-column

const GroupPropTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom', 'baseline']),
	horizontalAlign: PropTypes.oneOf([
		'left',
		'center',
		'right',
		'space-between',
	]),
	cols: PropTypes.number,
	rows: PropTypes.number,
	style: PropTypes.object,
	flex: PropTypes.bool,
};

/**
 * @type { React.ForwardRefExoticComponent<Partial<typeof GroupPropTypes> & { ref?: React.RefObject }> }
 */
const Group = React.forwardRef((props, ref) => {
	const {
		className,
		children,
		direction,
		verticalAlign,
		horizontalAlign,
		cols,
		rows,
		flex,
		...rest
	} = props;

	return (
		<div
			className={cn(
				'asteria-component__group',
				{
					[`asteria-component__group--direction-${direction}`]:
						direction,
					[`asteria-component__group--vertical-align-${verticalAlign}`]:
						verticalAlign,
					[`asteria-component__group--horizontal-align-${horizontalAlign}`]:
						horizontalAlign,
					[`asteria-component__group--cols-${cols}`]: cols,
					[`asteria-component__group--rows-${rows}`]: rows,
					'asteria--variant-flex': flex,
				},
				className,
			)}
			style={props.style}
			ref={ref}
			{...rest}
		>
			{children}
		</div>
	);
});

Group.propTypes = GroupPropTypes;

Group.defaultProps = {};

Group.displayName = 'Group';

ComponentService.register('Group', Group, {
	className: { type: 'string' },
	children: { type: 'node' },
	direction: { type: 'enum', options: ['vertical', 'horizontal'] },
	verticalAlign: {
		type: 'enum',
		options: ['top', 'center', 'bottom', 'baseline'],
	},
	horizontalAlign: {
		type: 'enum',
		options: ['left', 'center', 'right', 'space-between'],
	},
	cols: { type: 'number' },
	rows: { type: 'number' },
	style: { type: 'object' },
	flex: { type: 'boolean' },
});

export default React.memo(Group);
