import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

export const data = createSelector(
	(store) => store?.admin?.adminUsers?.data,
	(objects) => objects ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const loading = createSelector(
	(store) => store?.admin?.adminUsers?.loading,
	(value) => value ?? false,
);

export const details = createSelector(
	(store) => data(store),
	(_, ID) => ID,
	(objects, ID) =>
		objects.find((object) => (object?._id ?? object.id) === ID),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
