import React from 'react';

import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const BaseTeaser = (props) => {
	const { className, onAction, path } = props;

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<Teaser
			className={cn('asteria-component__forecaster-teaser', className)}
			path={path}
			actions={actions}
			onAction={onAction}
		/>
	);
};

BaseTeaser.displayName = 'BaseTeaser';

BaseTeaser.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	path: PropTypes.string,
};

export default BaseTeaser;
