import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import Modal from '@asteria/component-modal';
import Steps, { Step } from '@asteria/component-steps';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './index.scss';

const GuideStep = (props) => {
	const { step } = props;

	return (
		<Step>
			{step?.title && (
				<Title type="title" size="sm">
					{TranslationService.get(step?.title)}
				</Title>
			)}
			{step?.description && (
				<Text>{TranslationService.get(step?.description)}</Text>
			)}
			{step?.image && <img src={step?.image} />}
		</Step>
	);
};

GuideStep.propTypes = {
	step: PropTypes.object,
};

const Guide = (props) => {
	const guide = useConfig('app.guide');

	const { onEnd, className } = props;
	const { steps, title } = guide || {};

	return (
		<Steps
			className={cn('asteria-component__guide', className)}
			title={TranslationService.get(title)}
			onEnd={onEnd}
			counter
		>
			{steps?.map((step, index) => (
				<GuideStep key={index} step={step} />
			))}
		</Steps>
	);
};

Guide.propTypes = {
	className: PropTypes.string,
	onEnd: PropTypes.func,
};

const GuideModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose} size="sm">
			<Guide onEnd={onClose} />
		</Modal>
	);
};

export default Guide;
export { GuideModal };
