import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isAfter, parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import { TooltipContent } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import trigger from '@asteria/component-notifications/actions/trigger';
import Contenter from '@asteria/component-tools/contenter';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

import '../styles.scss';

const NotificationsSelector = createSelector(
	(state) => state?.notifications?.notifications ?? [],
	(state) => state?.app?.user?.settings?.flags ?? {},
	(_, { slug, validate }) => ({ slug, validate }),
	($notifications, flags, { slug, validate }) => {
		let notifications = [...$notifications].filter(
			({ createdAt }) => createdAt,
		);

		const timestamp = flags?.[['navigation', slug].join(':')];

		if (timestamp && validate) {
			notifications = notifications.filter(({ createdAt }) =>
				isAfter(parseISO(createdAt), parseISO(timestamp)),
			);
		}

		return notifications
			.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
			.filter((object) => object?.notificationKey?.startsWith?.(slug));
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

const selectors = {
	notifications: NotificationsSelector,

	notification: createSelector(
		NotificationsSelector,
		(notifications) => notifications[0],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const BadgeTooltip = (props) => {
	const { data, notificationKey } = props;

	const config = useConfig('navigation.badge.tooltip');

	const ContenterData = React.useMemo(
		() => ({ data: data, notificationKey: notificationKey }),
		[data, notificationKey],
	);

	const content = React.useMemo(() => {
		if (config?.[data?.body]) {
			if (Array.isArray(data?.body)) {
				const keys = [].concat(data?.body).reverse();

				for (const key in keys) {
					if (config?.[key]) {
						return config?.[key];
					}
				}
			} else {
				return config?.[data?.body];
			}
		}

		if (config?.[notificationKey]) {
			return config?.[notificationKey];
		}

		return config;
	}, [config, data?.body, notificationKey]);

	return (
		<TooltipContent className="asteria-component__navigation__badge-tooltip">
			{config?.length ? (
				<Contenter content={content} data={ContenterData} />
			) : data?.body ? (
				<Text size="sm">
					{TranslationService.get(
						[
							data?.body,
							['navigation', data?.body].join('.'),
						].flat(),
						data?.body,
						data,
					)}
				</Text>
			) : notificationKey ? (
				<Text size="sm">
					{TranslationService.get(
						[
							`navigation.notification.text`,
							`notification.${notificationKey}.text`,
							`navigation.notification.${notificationKey}.text`,
						],
						undefined,
						data,
					)}
				</Text>
			) : null}
		</TooltipContent>
	);
};

BadgeTooltip.propTypes = {
	data: PropTypes.object,
	notificationKey: PropTypes.string,
};

const NavigationBadgeWrapper = (props) => {
	const { onNavigate, onAction, route, children } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const hasBadgeRemoveFeature = useFeature('navigation-badge-remove');

	const notification = useSelector((store) =>
		selectors.notification(store, {
			slug: route?.slug,
			validate: hasBadgeRemoveFeature,
		}),
	);

	const handleAction = React.useCallback(
		(action, data) => {
			onAction?.(
				'updateTimestamp',
				['navigation', route?.slug].join(':'),
			);

			return onAction?.(action, data);
		},
		[onAction, route?.slug],
	);

	const handleNavigate = React.useCallback(
		(path) => {
			onAction?.(
				'updateTimestamp',
				['navigation', route?.slug].join(':'),
			);

			return onNavigate?.(path);
		},
		[route?.slug, onAction, onNavigate],
	);

	const badge = React.useMemo(() => {
		if (route?.badge) {
			return route?.badge;
		}

		if (notification) {
			return '!';
		}

		return null;
	}, [notification, route?.badge]);

	const BadgeProps = React.useMemo(
		() => ({
			badge: badge,
			size: 'sm',
			tooltip: notification
				? {
						tooltip: <BadgeTooltip {...notification} />,
						placement: 'right',
				  }
				: null,
			className: `asteria-component__navigation-badge--${route?.slug}`,
			onClick: () => {
				if (notification?.id) {
					handleAction?.(
						'markNotificationAsViewed',
						notification?.id,
					);
				}

				if (notification?.data?.action) {
					trigger(notification?.data?.action, {
						dispatch,
						store,
						onAction: handleAction,
					});

					if (notification?.data?.action?.action) {
						return handleAction?.(
							notification?.data?.action?.action,
							notification?.data?.action?.data,
						);
					}

					return;
				}

				if (route?.action) {
					return handleAction?.(...[].concat(route?.action));
				}

				return handleNavigate?.(route?.path);
			},
		}),
		[
			badge,
			notification,
			route?.slug,
			route?.action,
			route?.path,
			handleNavigate,
			handleAction,
			dispatch,
			store,
		],
	);

	if (route?.slug === 'notifications' || route?.slug === 'menu') {
		return null;
	}

	return (
		<BadgeWrapper forceOpen {...BadgeProps}>
			{children}
		</BadgeWrapper>
	);
};

NavigationBadgeWrapper.displayName = 'NavigationBadgeWrapper';

NavigationBadgeWrapper.propTypes = {
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	route: PropTypes.object,
	children: PropTypes.node,
};

export default NavigationBadgeWrapper;
