export function validateFormContext(ctx) {
	if (!ctx) {
		ctx = {};
	}

	ctx.form = ctx.form ?? ctx.getValues() ?? {};

	return ctx;
}

/**
 * @param {{ type: string, category?: string, tag?: string, id?: string, field?: string }} options
 * @returns { string[] }
 */
export function getPath({ type, category, tag, id, field }) {
	const path = [type];

	if (category) {
		path.push('categories', category);
	}

	if (tag) {
		path.push('tags', tag);
	}

	if (id) {
		path.push('transactions', id);
	}

	if (field) {
		path.push(field);
	}

	return path;
}
