import React from 'react';

import PropTypes from 'prop-types';

import Form from '@asteria/component-form';

import { decode } from '@asteria/utils-funcs/jwt';

import AuthProfileChangeContent from './content';

import './styles.scss';

const AuthProfileChange = React.memo((props) => {
	const { onClose, onSubmit, accessToken } = props;

	const [loading, setLoading] = React.useState(false);

	const companyId = decode(accessToken)?.companyId ?? null;

	const values = React.useMemo(() => ({ companyId: companyId }), [companyId]);

	const handleSubmit = React.useCallback(
		async (form) => {
			setLoading(true);

			try {
				await onSubmit?.('auth:profile:change', form);
			} catch (err) {
				//
			}

			setLoading(false);

			return onClose?.();
		},
		[onClose, onSubmit],
	);

	return (
		<Form values={values} onSubmit={handleSubmit}>
			<AuthProfileChangeContent {...props} loading={loading} />
		</Form>
	);
});

AuthProfileChange.displayName = 'AuthProfileChange';

AuthProfileChange.propTypes = {
	className: PropTypes.string,

	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	accessToken: PropTypes.string,
};

export default AuthProfileChange;
