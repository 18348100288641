import AsteriaCore from '@asteria/core';

import {
	InvoiceService,
	TransactionService,
} from '@asteria/backend-utils-services';

import { setGroup } from '@asteria/datalayer/stores/graph';

const QUERY = `
	query QuerySoldInvoices($transactionIds: [ObjectId!], $tags: [ObjectId]) {
		cashflow(
			filters: {
				search: { tags: $tags }
				preview: { factoring: { transactions: $transactionIds } }
			}
		) {
			sums {
				original {
					total
				}
			}
			max {
				original {
					total
				}
			}
			min {
				original {
					total
				}
			}
			probability
			type
			status
			count
			badge
			chip {
				id
				type
				config
			}
			info {
				id
				type
				data
			}
		}
	}
`;

TransactionService.transaction.extend({
	getQuery: () => QUERY,
	key: `sold`,
	loggerMethod: `services.transactionService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.transactionService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

const buildBar = (data, barType, types = []) => {
	const pieces =
		data?.filter(({ type }) => type === barType.toUpperCase()) || [];

	return {
		value: pieces
			.filter(({ badge }) => !badge)
			.reduce(
				(
					acc,
					{
						sums: {
							original: { total },
						},
					},
				) => acc + total,
				0,
			),
		types: [barType.toLowerCase(), ...types],
		badges: pieces
			.filter(({ badge }) => badge)
			.map(
				({
					status,
					type,
					count,
					probability,
					sums: {
						original: { total },
					},
					max,
					min,
					chip: {
						config: { name, tagType = name },
					},
					chip,
					info,
				}) => ({
					value: total,
					types: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
					],
					parts: [],
					chip,
					count,
					data: {
						type: type.toLowerCase(),
						status,
						probability: probability || 1,
						max: max?.original?.total,
						min: min?.original?.total,
					},
					info: info || [],
				}),
			),
		parts: pieces
			.filter(({ badge }) => !badge)
			.map(
				({
					status,
					type,
					probability,
					sums: {
						original: { total },
					},
					max,
					min,
					chip: {
						config: { name, tagType = name, category },
					},
					chip,
					info,
				}) => ({
					value: total,
					types: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
						...(category
							? [
									`${category.replace(
										'$',
										'',
									)}-${tagType.replace('$', '')}`,
							  ]
							: []),
					],
					parts: [],
					chip,
					data: {
						type: type.toLowerCase(),
						status,
						probability: probability || 1,
						max: max?.original?.total,
						min: min?.original?.total,
					},
					info: info || [],
				}),
			),
		data: {
			type: barType.toLowerCase(),
			probability: 1,
		},
	};
};

const queue = [];
const fetchSoldInvoice = AsteriaCore.utils.throttle(
	async ({ accessToken, dispatch }) => {
		const ids = [...new Set(queue.flatMap(({ startDate }) => startDate))];
		const first = queue?.[0] || {};

		try {
			const promise = TransactionService.transaction.extension.sold(
				{
					transactionIds: [
						...new Set(
							queue.flatMap(
								({ transactionIds }) => transactionIds,
							),
						),
					],
					tags: first?.tags,
				},
				{ token: accessToken },
			);

			queue.length = 0;
			let arr = [];
			const response = await promise;
			arr.push(response);

			arr?.forEach((data, index) => {
				const deposit = buildBar(
					data.filter(({ status }) => status !== 'BACKGROUND'),
					'deposit',
				);
				const withdraw = buildBar(
					data.filter(({ status }) => status !== 'BACKGROUND'),
					'withdraw',
				);

				dispatch(
					setGroup({
						id: ids[index],
						data: {
							id: ids[index],
							lines: data
								.filter(
									({ type }) =>
										type === 'ACCOUNT' || type === 'CREDIT',
								)
								.map(
									({
										sums: {
											original: { total },
										},
										max,
										min,
										status,
										probability,
										type,
										info,
									}) => ({
										value: total,
										max: max?.original?.total || total,
										min: min?.original?.total || total,
										probability,
										types: [
											type.toLowerCase(),
											status === 'FORECAST'
												? 'forecast'
												: 'history',
											...(type === 'CREDIT'
												? ['sharp']
												: []),
										],
										info: info || [],
									}),
								),
							bars: [
								buildBar(
									data.filter(
										({ status }) => status === 'BACKGROUND',
									),
									'deposit',
									['background'],
								),
								deposit,
								buildBar(
									data.filter(
										({ status }) => status === 'BACKGROUND',
									),
									'withdraw',
									['background'],
								),
								withdraw,
							],
						},
						// deletePrevious: true,
					}),
				);
			});
		} catch (e) {
			// Do nothing
		}
	},
	250,
);

export async function sold({
	accessToken,
	dispatch,
	startDate,
	tags,
	transactionIds,
	batch = true,
}) {
	if (batch) {
		queue.push({ transactionIds, tags, startDate });
		fetchSoldInvoice({ accessToken, dispatch });
	} else {
		return TransactionService.transaction.extension.sold(
			{
				transactionIds: transactionIds,
				tags: tags,
			},
			{ token: accessToken },
		);
	}
}

export async function custom({
	accessToken,
	query,
	dataloader,
	isBulk,
	signal,
	variables,
}) {
	return InvoiceService.invoice.sendRequest(
		{ query, dataloader, isBulk, signal, variables },
		{ token: accessToken },
	);
}
