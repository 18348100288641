import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

export const accounts = createSelector(
	(store) => store?.accounts?.bankAccounts,
	(_, options) => options,
	(value, options) => {
		let response = value ?? [];

		if (options?.currency) {
			response = response.filter(
				(account) =>
					account?.sums?.original?.currency === options?.currency,
			);
		}

		return response;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const account = createSelector(
	(store) => store?.accounts?.bankAccounts ?? [],
	(_, ID) => ID,
	(value, ID) =>
		value.find((account) => (account?._id ?? account?.id) === ID),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const currencies = createSelector(
	(store) => store?.accounts?.bankAccounts ?? [],
	(value) =>
		Array.from(
			new Set(value.map((account) => account?.sums?.original?.currency)),
		),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
