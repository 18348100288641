import { useCallback, useEffect, useState } from 'react';

import { createClient } from 'graphql-ws';

import AsteriaCore from '@asteria/core';

/**
 * @param { { token: string, query: string, variables?: object, onNext?: (data: any) => void, onError?: (error: any) => void, onComplete?: () => void, useReconnect?: boolean } } options
 */
export const useSubscription = (options) => {
	const {
		token: $token,
		query,
		variables,
		onNext,
		onError,
		onComplete,
	} = options;

	const uri =
		options.uri ||
		AsteriaCore.Configuration.getValueAsync({
			key: 'services.api-gateway.graphqlUri',
		});

	const [token, setToken] = useState(
		localStorage.getItem('wingsToken') || $token,
	);

	const handleNext = useCallback(
		(...args) => {
			const data = args[0];

			if (data?.errors?.length) {
				throw data?.errors?.[0];
			}

			return onNext?.(...args);
		},
		[onNext],
	);

	const handleError = useCallback((...args) => onError?.(...args), [onError]);

	const handleComplete = useCallback(
		(...args) => onComplete?.(...args),
		[onComplete],
	);

	useEffect(() => {
		const client = createClient({
			url: uri.replace('http://', 'ws://').replace('https://', 'wss://'),
			connectionParams: { Authorization: `Bearer ${token}` },
			webSocketImpl: window.WebSocket || window.MozWebSocket,
			shouldRetry: (err) => err?.extensions?.code === 'UNAUTHENTICATED',
			retryWait: async () => {
				await AsteriaCore.utils.sleep(5 * 1_000);

				setToken(
					(token) => localStorage.getItem('wingsToken') || token,
				);
			},
			lazy: false,
		});

		const unsubscribe = client.subscribe(
			{ query: query, variables: variables },
			{ next: handleNext, error: handleError, complete: handleComplete },
		);

		return unsubscribe;
	}, [handleComplete, handleError, handleNext, query, token, uri, variables]);
};
