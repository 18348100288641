import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import ActionBar, {
	ActionBarSectionActions,
	ActionBarSectionContent,
	ActionBarSectionExtra,
} from '@asteria/component-actionbar';
import Form, { Input } from '@asteria/component-form';

import * as AccountStore from '@asteria/datalayer/stores/accounts';

import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

import { CreditConfirmationModal } from '../../../modals';
import { useModalActions } from '../../modal-collection';

const CreditActionBar = React.memo((props) => {
	const { simulationId } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const step = useConfig(['credit.form.step']) ?? 10_000;
	const selectedCredit = useSelector(AccountStore.selectors.dynamicCredit);

	const { open } = useModalActions();

	const updateCredit = React.useCallback(
		(data) => {
			let val = parseFloat(data.value);

			if (Number.isNaN(val)) {
				val = data.value;
			}

			if (val >= 0) {
				dispatch(AccountStore.setDynamicCredit(val));
			}
		},
		[dispatch],
	);

	const handleContinue = React.useCallback(() => {
		const selectedCredit = AccountStore.selectors.dynamicCredit(
			store.getState(),
		);

		open(<CreditConfirmationModal />, {
			credit: selectedCredit,
			simulationId: simulationId,
		});
	}, [open, simulationId, store]);

	const actions = React.useMemo(
		() => [
			{
				variant: 'primary',
				label: TranslationService.get('action.continue'),
				onClick: handleContinue,
			},
		],
		[handleContinue],
	);

	const values = React.useMemo(
		() => ({ credit: selectedCredit }),
		[selectedCredit],
	);

	return (
		<ActionBar>
			<ActionBarSectionContent
				title={TranslationService.get([
					'services.credit.actionbar.title',
					'services.credit.title',
				])}
				content={TranslationService.get([
					'services.credit.actionbar.content',
				])}
				className="asteria--variant-full"
			/>
			<ActionBarSectionExtra>
				<Form values={values}>
					<Input
						name="credit"
						required
						type="number"
						direction="vertical"
						step={step}
						onChange={updateCredit}
						min={0}
						debounce={100}
						analyticsKey="credit.input"
					/>
				</Form>
			</ActionBarSectionExtra>
			<ActionBarSectionActions actions={actions} />
		</ActionBar>
	);
});

CreditActionBar.displayName = 'CreditActionBar';

CreditActionBar.propTypes = {
	simulationId: PropTypes.string,
};

CreditActionBar.defaultProps = {};

export default CreditActionBar;
