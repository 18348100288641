import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import * as Selectors from '../../selectors';

import BarGroup from './bargroup';

const BarGroupRange = React.memo((props) => {
	const {
		id,
		index,
		style,
		onClick,
		layout,
		beacon,
		first,
		last,
		mode,
		parts,
	} = props;

	const groupId = id || index;

	const active = useSelector(
		(store) => Selectors.graph.active(store, groupId),
		(a, b) => isEqual(a, b),
	);

	const hover = useSelector(
		(store) => Selectors.graph.hover(store, groupId),
		(a, b) => isEqual(a, b),
	);

	const types = useSelector(
		(store) =>
			Selectors.graph.range(store, {
				id: id,
				format: (response) => response?.[0]?.types ?? [],
			}),
		(a, b) => isEqual(a, b),
	);

	const groupStyle = React.useMemo(
		() => ({ gridColumn: index + 1 }),
		[index],
	);

	return (
		<div
			className={cn(
				'asteria-graph-bar-group-wrapper',
				types.map((type) => `asteria--type-${type}`),
				{
					'asteria--state-active': active,
					'asteria--state-hover': hover,
				},
			)}
			style={groupStyle}
		>
			<BarGroup
				style={style}
				groupId={groupId}
				onClick={onClick}
				layout={layout}
				first={first}
				last={last}
				beacon={beacon}
				parts={parts}
				mode={mode}
			/>
		</div>
	);
});

BarGroupRange.displayName = 'BarGroupRange';

BarGroupRange.propTypes = {
	id: PropTypes.string,
	range: PropTypes.object,
	index: PropTypes.number,
	style: PropTypes.object,
	group: PropTypes.object,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	onClick: PropTypes.func,
	layout: PropTypes.any,
	beacon: PropTypes.any,
	first: PropTypes.bool,
	last: PropTypes.bool,
};

const BarGraph = React.memo(({ parts = [], margin, onClick, layout, mode }) => {
	const range = useSelector(
		(store) =>
			Selectors.graph.range(store, { formatEach: (value) => value?.id }),
		(a, b) => isEqual(a, b),
	);

	const steps = useSelector(Selectors.graph.steps, (a, b) => isEqual(a, b));

	const onboardingStep = useMemo(() => ({ id: false, type: false }), []);

	const clickAction = useCallback(
		(...args) => {
			if (onClick) {
				onClick(...args);
			}
		},
		[onClick],
	);

	let center = 100;

	if (layout === 'stacked') {
		const size = steps.length - 1;
		const zeroIndex = steps.findIndex(({ value: v }) => v === 0);
		// const topZone = (size - zeroIndex) * (100 / size);
		const bottomZone = (size - (size - zeroIndex)) * (100 / size);

		center = bottomZone;
	}

	const style = useMemo(() => {
		if (layout !== 'stacked') {
			return { gridTemplateRows: '50% 50%', ...margin };
		}

		return {
			gridTemplateRows: `${center}% ${100 - center}%`,
			...margin,
		};
	}, [center, margin, layout]);

	return range.slice(1, -1).map((id, index) => (
		<BarGroupRange
			key={`bargroup-${id || index}`}
			id={id}
			index={index}
			style={style}
			// group={groups[id]}
			// minValue={minValue}
			// maxValue={maxValue}
			onClick={clickAction}
			layout={layout}
			beacon={id === onboardingStep.id ? onboardingStep.type : false}
			first={index === 0}
			last={index === range.length - 3}
			parts={parts}
			mode={mode}
		/>
	));
});

BarGraph.displayName = 'BarGraph';

export default BarGraph;
