import React from 'react';

import { useDispatch, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { CompleteScreen } from '@asteria/component-alert';
import Modal from '@asteria/component-modal';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { CreditSaveModal } from '..';
import {
	useModalActions,
	useModalHistory,
	useModalProps,
} from '../../components/modal-collection';
import { openCreditURI } from '../../utils/credit';

const CreditConfirmation = React.memo(() => {
	const { open, close, onAction, onSubmit } = useModalActions();
	const { credit, simulationId, next } = useModalProps();
	const { hasHistory } = useModalHistory();

	const store = useStore();
	const dispatch = useDispatch();

	const hasFinancialV1Feature = useFeature('financial-v1');

	const hasOverdraftRedirectFeature = useFeature(
		'financial-overdraft-redirect',
	);

	const [loading, setLoading] = React.useState(false);

	const handleClose = React.useCallback(
		(event) => close?.(event, { size: 2 }),
		[close],
	);

	const handleBack = React.useCallback((event) => close?.(event), [close]);

	const handleContinue = React.useCallback(
		(event) => {
			if (hasFinancialV1Feature) {
				const url = TranslationService.get(
					'overdraft.sent.redirect',
					// eslint-disable-next-line spellcheck/spell-checker
					'/app/ib/lana/foretagslan/ansokan?amount={{credit}}',
					{ credit: credit },
				);

				if (url) {
					window.history.pushState(
						{},
						TranslationService.get(
							'overdraft.sent.redirect.title',
							// eslint-disable-next-line spellcheck/spell-checker
							'Finansiera',
						),
						url,
					);
				}

				SnackbarStore.show(dispatch, {
					title: 'snackbar.financial.credit.approve.title',
					content: 'snackbar.financial.credit.approve.content',
					type: 'financial.credit.approve',
					variant: 'success',
					icon: 'check',
					data: { credit: credit },
					hideActions: true,
				});

				onAction?.('redirect', next ?? '/');

				return close?.(event);
			}

			open(<CreditSaveModal />, { credit: credit, next: next });
		},
		[hasFinancialV1Feature, open, credit, next, dispatch, onAction, close],
	);

	const handleSave = React.useCallback(
		async (event, options) => {
			const simulation = ScenarioStore.selectors.scenario(
				store.getState(),
				simulationId,
			);

			if (simulation) {
				setLoading(true);

				try {
					await onSubmit?.('scenario:update', {
						_id: simulationId,
						data: { value: credit },
					});
				} catch (err) {
					//
				}

				setLoading(false);
			}

			if (!options?.skipRedirect) {
				onAction?.('redirect', '/financial');
			}

			return handleClose(event);
		},
		[credit, handleClose, onAction, onSubmit, simulationId, store],
	);

	const handleSaveRedirect = React.useCallback(
		async (event) => {
			await handleSave(event);

			openCreditURI({ credit: credit });
		},
		[credit, handleSave],
	);

	return (
		<Wrapper>
			<Header onClose={handleClose}>
				{TranslationService.get([
					'services.credit.title',
					'financial.modal.credit.confirmation.title',
				])}
			</Header>
			<Content>
				<CompleteScreen
					level="success"
					icon="check"
					iconPosition="first"
					title={TranslationService.get(
						[
							'services.credit.sent.title',
							'financial.modal.credit.confirmation.alert.title',
						],
						undefined,
						{ credit },
					)}
				>
					<Title size="xs" align="center">
						{TranslationService.get(
							[
								'services.credit.sent.pretext',
								'financial.modal.credit.confirmation.alert.subtitle',
							],
							undefined,
							{ credit },
						)}
					</Title>
					<Text size="lg" weight="bold" align="center">
						{TranslationService.get(
							[
								'services.credit.sent.total',
								'financial.modal.credit.confirmation.alert.total',
							],
							'{{ credit | number }}',
							{ credit },
						)}
					</Text>
					<Text align="center">
						{TranslationService.get(
							[
								'services.credit.sent.description',
								'financial.modal.credit.confirmation.alert.content',
							],
							undefined,
							{ credit },
						)}
					</Text>
				</CompleteScreen>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'financial.modal.credit.confirmation.close',
						])}
						onClick={handleClose}
						analyticsKey="financial.modal.credit.confirmation.close"
					/>
				</FooterSection>
				<FooterSection position="last">
					{hasHistory ? (
						<Button
							variant="secondary"
							label={TranslationService.get([
								'button.back',
								'action.back',
								'financial.modal.credit.confirmation.back',
							])}
							onClick={handleBack}
							analyticsKey="financial.modal.credit.confirmation.back"
						/>
					) : null}
					{simulationId ? (
						<>
							<Button
								variant={
									hasOverdraftRedirectFeature
										? 'secondary'
										: 'primary'
								}
								label={TranslationService.get([
									'button.save',
									'action.save',
									'financial.modal.credit.confirmation.save',
								])}
								onClick={handleSave}
								analyticsKey="financial.modal.credit.confirmation.save"
								loading={loading}
								disabled={loading}
							/>
							{hasOverdraftRedirectFeature ? (
								<Button
									variant="primary"
									label={TranslationService.get([
										'button.save',
										'action.save',
										'financial.modal.credit.confirmation.save.redirect',
									])}
									onClick={handleSaveRedirect}
									analyticsKey="financial.modal.credit.confirmation.save.redirect"
									loading={loading}
									disabled={loading}
								/>
							) : null}
						</>
					) : (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.continue',
								'action.continue',
								'financial.modal.credit.confirmation.continue',
							])}
							onClick={handleContinue}
							analyticsKey="financial.modal.credit.confirmation.continue"
						/>
					)}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

CreditConfirmation.displayName = 'CreditConfirmation';
CreditConfirmation.propTypes = {};

const CreditConfirmationModal = React.memo((props) => {
	const { open, className } = props;

	const { close } = useModalActions();

	const handleClose = React.useCallback(
		(event) => close?.(event, { size: 2 }),
		[close],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			className={cn(
				'asteria-component__financial-modal',
				'asteria--variant-credit-confirmation',
				className,
			)}
			size="sm"
		>
			<CreditConfirmation {...props} />
		</Modal>
	);
});

CreditConfirmationModal.displayName = 'CreditConfirmationModal';

CreditConfirmationModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
};

export default CreditConfirmationModal;
