import React from 'react';

import PropTypes from 'prop-types';

import { TranslationService } from '@asteria/language';

import Select, { Option } from '../../../select';

import RepeatableModal from './Details';

import './styles.scss';

const initialState = { value: 'never', isModalOpen: false };

const DatePickerRepeatable = (props) => {
	const { onChange, locale } = props;

	const [{ value, isModalOpen, form }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'CHANGE': {
					let update = { ...state, value: action?.payload };

					if (action?.payload === 'other') {
						update.isModalOpen = true;
					} else {
						onChange?.({ value: action?.payload });
					}

					return update;
				}

				case 'CLOSE':
					return { ...state, ...initialState };

				case 'SUBMIT':
					onChange?.({
						value: state?.value,
						form: action?.payload,
					});

					return {
						...state,
						isModalOpen: false,
						form: action?.payload,
					};

				default:
					return state;
			}
		},
		initialState,
	);

	const handleClose = React.useCallback(
		() => dispatch({ type: 'CLOSE' }),
		[],
	);

	const handleChange = React.useCallback(({ value }) => {
		dispatch({ type: 'CHANGE', payload: value });
	}, []);

	const handleSubmit = React.useCallback((form) => {
		dispatch({ type: 'SUBMIT', payload: form });
	}, []);

	return [
		<RepeatableModal
			key="repeatable-modal"
			open={isModalOpen}
			onClose={handleClose}
			onSubmit={handleSubmit}
			defaultValues={form}
			locale={locale}
		/>,
		<div
			className="asteria-component__datepicker__content__repeatable"
			key="repeatable-section"
		>
			<Select
				uncontrolled
				label={TranslationService.get(['datepicker.repeatable.label'])}
				value={value}
				onChange={handleChange}
			>
				<Option value="never">
					{TranslationService.get([
						'datepicker.repeatable.value.never',
					])}
				</Option>
				<Option value="weekly">
					{TranslationService.get([
						'datepicker.repeatable.value.weekly',
					])}
				</Option>
				<Option value="monthly">
					{TranslationService.get([
						'datepicker.repeatable.value.monthly',
					])}
				</Option>
				<Option value="quarterly">
					{TranslationService.get([
						'datepicker.repeatable.value.quarterly',
					])}
				</Option>
				<Option value="other">
					{TranslationService.get([
						'datepicker.repeatable.value.other',
					])}
				</Option>
			</Select>
		</div>,
	];
};

DatePickerRepeatable.displayName = 'DatePickerRepeatable';

DatePickerRepeatable.propTypes = {
	onChange: PropTypes.func,
	locale: PropTypes.string,
};

export default DatePickerRepeatable;
