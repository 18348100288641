import React from 'react';

import { useDispatch, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Probability from '@asteria/component-core/probability';
import { Text, Title } from '@asteria/component-core/typography';
import { isPossibleToClick } from '@asteria/component-core/utils';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Prefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';

import trigger from '../../actions/trigger';

const getVolatilityText = (value, max = 1, steps = 5) => {
	const roundedValue = Math.min(1, Math.round(value * 100) / 100);
	const step = Math.floor(roundedValue / (max / steps));
	return TranslationService.get(`security.level.${step}`);
};

const NotificationAction = React.memo((props) => {
	const { onClick, type, data } = props;

	const volatilityText = React.useMemo(
		() => getVolatilityText(data?.probability ?? 0),
		[data?.probability],
	);

	const tooltip = React.useMemo(() => {
		if (data?.action?.text) {
			return TranslationService.get(
				data?.action?.text,
				data?.action?.text,
			);
		}

		return TranslationService.get(
			[
				`notification.review.tooltip`,
				`notification.action.tooltip`,
				`notification.${type?.toLowerCase?.()}.review.tooltip`,
				`notification.${type?.toLowerCase?.()}.action.tooltip`,
			],
			'Visa',
			{ data: data, volatilityText: volatilityText },
		);
	}, [data, type, volatilityText]);

	const label = React.useMemo(() => {
		if (data?.action?.text) {
			return TranslationService.get(
				data?.action?.text,
				data?.action?.text,
			);
		}

		return TranslationService.get(
			[
				`notification.review`,
				`notification.action`,
				`notification.${type?.toLowerCase?.()}.review`,
				`notification.${type?.toLowerCase?.()}.action`,
			],
			'Visa',
			{ data: data, volatilityText: volatilityText },
		);
	}, [data, type, volatilityText]);

	return (
		<Button
			variant="link"
			size="sm"
			type="notification-action"
			tooltip={label !== tooltip ? tooltip : null}
			label={label}
			onClick={onClick}
		/>
	);
});

NotificationAction.displayName = 'NotificationAction';

NotificationAction.propTypes = {
	type: PropTypes.string,
	data: PropTypes.shape({
		body: PropTypes.string,
		action: PropTypes.shape({
			text: PropTypes.string,
			action: PropTypes.string,
			data: PropTypes.string,
		}),
		tag: PropTypes.shape({
			status: PropTypes.string,
			category: PropTypes.shape({ name: PropTypes.string }),
			name: PropTypes.string,
		}),
		probability: PropTypes.number,
	}),
	onClick: PropTypes.func,
};

const NotificationText = React.memo((props) => {
	const { type, id, notificationKey, title, data, onAction, onClose } = props;

	const dispatch = useDispatch();
	const store = useStore();
	const ref = React.useRef(null);

	const handleClose = React.useCallback(
		(event) => {
			event.stopPropagation();
			onAction?.('markNotificationAsViewed', id);

			return onClose?.(id);
		},
		[id, onAction, onClose],
	);

	const handleAction = React.useCallback(() => {
		onAction?.('markNotificationAsViewed', id);

		trigger(data?.action, { dispatch, store, onAction: onAction });

		if (data?.action?.action) {
			onAction?.(data?.action?.action, data?.action?.data);
		}
	}, [data?.action, dispatch, id, onAction, store]);

	const PrefixColors = React.useMemo(
		() =>
			[
				data?.tag?.status,
				[data?.tag?.category?.name, data?.tag?.name]
					.filter(Boolean)
					.join('-')
					.replace(/\$/g, ''),
			]
				.filter(Boolean)
				.map((value) => value.toLowerCase()),
		[data?.tag?.category?.name, data?.tag?.name, data?.tag?.status],
	);

	const handleClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return handleAction(event);
		},
		[handleAction],
	);

	return (
		<Wrapper
			ref={ref}
			onClick={handleClick}
			className="asteria-component__notification"
		>
			{PrefixColors.length || data?.probability || title ? (
				<Header>
					{PrefixColors.length ? (
						<Prefix colors={PrefixColors} />
					) : null}

					{data?.probability ? (
						<Probability
							probability={data?.probability}
							values={[0.2, 0.4, 0.6, 0.8, 1]}
						/>
					) : null}

					<Title size="xxs">
						{TranslationService.get(title, title, props)}
					</Title>
				</Header>
			) : null}

			{data?.body || notificationKey ? (
				<Content>
					{data?.body ? (
						<Text size="sm">
							{TranslationService.get(
								data?.body,
								data?.body,
								data,
							)}
						</Text>
					) : notificationKey ? (
						<Text size="sm">
							{TranslationService.get(
								`notification.${notificationKey}.text`,
								`notification.${notificationKey}.text`,
								data,
							)}
						</Text>
					) : null}
				</Content>
			) : null}

			{data?.action ? (
				<Footer>
					<FooterSection>
						<NotificationAction
							type={type}
							data={data}
							onClick={handleAction}
						/>
					</FooterSection>
				</Footer>
			) : null}

			<Button
				variant="close"
				size="xs"
				icon="close"
				className="asteria-close"
				onClick={handleClose}
			/>
		</Wrapper>
	);
});

NotificationText.displayName = 'NotificationText';

NotificationText.propTypes = {
	id: PropTypes.string,
	notificationKey: PropTypes.string,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	type: PropTypes.string,
	data: PropTypes.shape({
		body: PropTypes.string,
		action: PropTypes.shape({
			text: PropTypes.string,
			action: PropTypes.string,
			data: PropTypes.string,
		}),
		tag: PropTypes.shape({
			status: PropTypes.string,
			category: PropTypes.shape({ name: PropTypes.string }),
			name: PropTypes.string,
		}),
		probability: PropTypes.number,
	}),
	onAction: PropTypes.func,
	onClose: PropTypes.func,
};

export default NotificationText;
