export function parsePath(path) {
	if (path.startsWith('/swedbank')) {
		return 'swedbank';
	}

	if (path.startsWith('/forecaster')) {
		return 'forecaster';
	}

	if (path.startsWith('/streamline')) {
		return 'streamline';
	}

	if (path.startsWith('/financial')) {
		return 'financial';
	}

	if (path.startsWith('/onboarding')) {
		return 'onboarding';
	}

	if (path.startsWith('/settings')) {
		return 'settings';
	}

	if (path.startsWith('/statement')) {
		return 'statement';
	}

	if (path.startsWith('/overview')) {
		return 'overview';
	}

	if (path.startsWith('/accounts')) {
		return 'accounts';
	}

	if (path.startsWith('/clients')) {
		return 'clients';
	}

	if (path.startsWith('/welcome')) {
		return 'welcome';
	}

	return 'cashflow';
}

export function parseView(view) {
	switch (view) {
		case 'forecaster':
			return '/forecaster';

		case 'financial':
			return '/financial';

		case 'onboarding':
			return '/onboarding';

		case 'settings':
			return '/settings';

		case 'statement':
			return '/statement';

		case 'overview':
			return '/overview';

		case 'accounts':
			return '/accounts';

		case 'clients':
			return '/clients';

		case 'welcome':
			return '/welcome';

		default:
			return '/';
	}
}
