import store from '@asteria/datalayer';

import Analytics from '@asteria/utils-analytics';

import * as UserAPI from '../api/user';

/**
 * @param { { dispatch: unknown, accessToken: string, event: { type?: 'settings:save'| 'forecaster:settings:save'| 'user:settings:save'| 'company:settings:save'| 'welcome:company:settings:save', data?: unknown } } } options
 */
export const onAction = (options) => {
	const { event, dispatch, accessToken } = options;

	if (
		event?.type === 'settings:save' ||
		event?.type === 'forecaster:settings:save'
	) {
		const user = store.getState()?.app?.user;
		const userId = user?.id ?? null;

		Analytics.event('settings.change', {
			user: user,
			userId: userId,
			data: event?.data,
		});

		return UserAPI.updateSettings({
			accessToken: accessToken,
			id: userId,
			settings: event?.data,
			dispatch: dispatch,
		});
	}

	if (event?.type === 'user:settings:save') {
		const user = store.getState()?.app?.user;
		const userId = user?.id ?? null;

		return UserAPI.update({
			accessToken: accessToken,
			id: userId,
			settings: event?.data,
			dispatch: dispatch,
		});
	}
};
