import React from 'react';

import { useStore } from 'react-redux';

import PropTypes from 'prop-types';

import {
	formatResponse,
	validateFormValues,
} from '@asteria/component-forecaster/utils';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import LoadingScreen from '@asteria/layout/loading';

import { GraphContext } from '../../context/loan';

const LoaderWrapper = React.memo((props) => {
	const { children, onSubmit } = props;

	const store = useStore();

	const [{ loading, /* error,  */ forecast }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'START':
					return { ...state, loading: true };

				case 'STOP':
					return { ...state, loading: false };

				case 'SUCCESS':
					return {
						...state,
						loading: false,
						forecast: action?.payload,
					};

				case 'ERROR':
					return {
						...state,
						loading: false,
						error: action?.payload,
					};

				default:
					return state;
			}
		},
		{ loading: true, forecast: null, error: null },
	);

	const fetchFuture = React.useCallback(async () => {
		const sourceScenario = ScenarioStore.selectors.sourceScenario(
			store.getState(),
		);

		const sourceScenarioData = ScenarioStore.selectors.sourceScenarioData(
			store.getState(),
		);

		return onSubmit?.('forecaster:changes', {
			scenarioName: sourceScenario,
		})
			.then((response) => formatResponse(response, { store }))
			.then((data) => ({ ...data, scenario: sourceScenario }))
			.then((data) => validateFormValues(data, sourceScenarioData));
	}, [onSubmit, store]);

	React.useEffect(() => {
		async function fetch() {
			try {
				const response = await fetchFuture();
				dispatch({ type: 'SUCCESS', payload: response });
			} catch (err) {
				dispatch({ type: 'ERROR', payload: err });
			}
		}

		fetch();
	}, [fetchFuture]);

	const ctx = React.useMemo(() => ({ forecast: forecast }), [forecast]);

	return (
		<>
			{loading ? <LoadingScreen blur type="loan.loading" /> : null}
			<GraphContext.Provider value={ctx}>
				{children}
			</GraphContext.Provider>
		</>
	);
});

LoaderWrapper.displayName = 'LoaderWrapper';
LoaderWrapper.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
};

export default LoaderWrapper;
