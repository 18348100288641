import React from 'react';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import { isPossibleToClick } from '../utils';

import Content from './content';
import WrapperContext from './context';
import Footer, { FooterSection } from './footer';
import Header, { CloseButton } from './header';

import './index.scss';

/**
 *
 * @param { React.ReactNode } child
 * @param { React.ElementType } Component
 * @returns { boolean }
 */
function isSameType(child, Component) {
	return (
		Object.is(child?.type, Component) ||
		Object.is(child?.props?.children?.type, Component) ||
		Object.is(child?.props?.originalType, Component) // From Storybook docs
	);
}

/**
 *
 * @param { 'header' | 'content' | 'footer' | 'close-button' } type
 * @param { React.ReactNode } node
 * @returns { boolean }
 */
function defaultCompare(type, node) {
	if (type === 'header') {
		return isSameType(node, Header);
	}

	if (type === 'content') {
		return isSameType(node, Content);
	}

	if (type === 'footer') {
		return isSameType(node, Footer);
	}

	if (type === 'close-button') {
		return Object.is(node?.type, CloseButton);
	}

	return false;
}

/** @type { React.FC<Partial<{ scroll: boolean, variant: 'vertical' | 'horizontal', className: string, children: React.ReactNode, border: boolean, collapse: boolean, open: boolean, onOpen: () => void, onClose: () => void, divider: boolean, compare: (type: 'header' | 'content' | 'footer' | 'close-button', node: React.ReactNode) => boolean, loading: boolean }>> } */
const Wrapper = React.forwardRef((props, ref) => {
	const {
		className,
		scroll,
		variant = 'vertical',
		border = false,
		divider = false,
		children: $children,
		collapse,
		open,
		onOpen,
		onClose,

		loading,

		compare = defaultCompare,

		...rest
	} = props;

	const [isOpen, setOpen] = React.useState(open);

	const headerRef = React.useRef(null);

	React.useEffect(() => {
		setOpen(open);
	}, [open]);

	const flags = []
		.concat($children)
		.flat()
		.filter(Boolean)
		.reduce(
			(acc, child) => {
				if (!acc.header && compare('header', child)) {
					acc.header = true;
				}

				if (!acc.content && compare('content', child)) {
					acc.content = true;
				}

				if (!acc.footer && compare('footer', child)) {
					acc.footer = true;
				}

				if (!acc.closeBtn && compare('close-button', child)) {
					acc.closeBtn = true;
				}

				return acc;
			},
			{ header: false, content: false, footer: false, closeBtn: false },
		);

	const handleCollapse = React.useCallback(
		(e) => {
			setOpen((isOpen) => {
				if (isOpen) {
					Analytics.event('wrapper.collapse', {
						className: className,
					});

					onClose?.(e);
				} else {
					Analytics.event('wrapper.expand', { className: className });

					onOpen?.(e);
				}

				return !isOpen;
			});
		},
		[className, onClose, onOpen],
	);

	const children = React.useMemo(
		() =>
			React.Children.toArray($children).map((child) => {
				if (compare('header', child) && collapse) {
					const onClick = child?.props?.onClick;

					return React.cloneElement(child, {
						ref: headerRef,
						isCollapsed: !isOpen,
						onClick: (e) => {
							const isClickable = isPossibleToClick(
								e,
								headerRef.current,
							);

							if (isClickable) {
								handleCollapse(e);
							}

							return onClick?.(e);
						},
						onCollapseClick: handleCollapse,
					});
				}

				return child;
			}),
		[$children, collapse, compare, handleCollapse, isOpen],
	);

	const ctx = React.useMemo(
		() => ({
			loading: loading,
			scrollable: scroll,
		}),
		[loading, scroll],
	);

	return (
		<WrapperContext.Provider value={ctx}>
			<div
				className={cn(
					'asteria-component__wrapper',
					{
						'asteria--state-loading': loading,
						'asteria--state-scroll': scroll,
					},
					{
						[`asteria-component__wrapper--variant-${variant}`]:
							variant,
						'asteria-component__wrapper--scroll': scroll,
						[`asteria-component__wrapper--border`]: border,
						[`asteria-component__wrapper--divider`]: divider,
						'asteria--state-closed': !isOpen && collapse,
						'asteria--state-open': isOpen && collapse,
						'asteria-component__wrapper--collapsible': collapse,
						'asteria-component__wrapper--no-header': !flags.header,
						'asteria-component__wrapper--no-content':
							!flags.content || (!isOpen && collapse),
						'asteria-component__wrapper--no-footer':
							!flags.footer || (!isOpen && collapse),
						'asteria-component__wrapper--close-button':
							flags.closeBtn,
					},
					className,
				)}
				{...rest}
				ref={ref}
			>
				{children}
			</div>
		</WrapperContext.Provider>
	);
});

Wrapper.propTypes = {
	scroll: PropTypes.bool,
	variant: PropTypes.oneOf(['vertical', 'horizontal']),
	className: PropTypes.string,
	children: PropTypes.node,
	border: PropTypes.bool,
	collapse: PropTypes.bool,
	open: PropTypes.bool,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	divider: PropTypes.bool,

	compare: PropTypes.func,
	loading: PropTypes.bool,
};

Wrapper.defaultProps = {};

Wrapper.displayName = 'Wrapper';

ComponentService.register('Wrapper', Wrapper, {
	scroll: { type: 'boolean' },
	variant: { type: 'enum', options: ['vertical', 'horizontal'] },
	className: { type: 'string' },
	children: { type: 'node' },
	border: { type: 'boolean' },
	collapse: { type: 'boolean' },
	open: { type: 'boolean' },
	onOpen: { type: 'function' },
	onClose: { type: 'function' },
	divider: { type: 'boolean' },

	compare: { type: 'function' },
	loading: { type: 'boolean' },
});

ComponentService.register('Header', Header, {
	className: { type: 'string' },
	onClose: { type: 'function' },
	onBack: { type: 'function' },
	children: { type: 'node' },
	tourKey: { type: 'array', of: { type: 'string' } },
	logo: { type: 'node' },
	onCollapseClick: { type: 'function' },
	isCollapsed: { type: 'boolean' },

	verticalAlign: { type: 'string' },

	postfix: {
		type: 'object',
		options: {
			position: { type: 'string' },
			size: { type: 'enum', options: ['lg', 'md', 'sm'] },
			icon: { type: 'string' },
			children: { type: 'node' },
			analyticsKey: { type: 'string' },
			loading: { type: 'boolean' },

			collapseBtn: { type: 'object' },
			closeBtn: { type: 'object' },
		},
	},
	prefix: {
		type: 'object',
		options: {
			position: { type: 'string' },
			children: { type: 'node' },
			size: { type: 'enum', options: ['lg', 'md', 'sm'] },
			loading: { type: 'boolean' },

			backBtn: { type: 'object' },
		},
	},

	loading: { type: 'boolean' },
});

ComponentService.register('Content', Content, {
	scroll: { type: 'boolean' },
	loading: { type: 'boolean' },

	className: { type: 'string' },
	children: { type: 'node' },
});

ComponentService.register('Footer', Footer, {
	className: { type: 'string' },
	children: { type: 'node' },
	loading: { type: 'boolean' },
});

ComponentService.register('FooterSection', FooterSection, {
	className: { type: 'string' },
	children: { type: 'node' },
	position: { type: 'enum', options: ['first', 'last'] },
	loading: { type: 'boolean' },
});

export default React.memo(Wrapper);
export { Header, Content, Footer, FooterSection, CloseButton };

export * as hooks from './hooks';
export { WrapperContext };
