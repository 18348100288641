import {
	compareAsc,
	eachMonthOfInterval,
	format,
	parseISO,
	subYears,
} from 'date-fns';
import { get } from 'lodash-es';

import * as FormatUtils from '@asteria/utils-funcs/format';

import { getPath } from '../../../utils/getFormPaths';

function getValueByMonth({
	dates,
	type,
	category,
	tag,
	field,
	getValues,
	history,
}) {
	for (const date of dates) {
		const fmt = format(date, 'yyyy-MM-01');

		const path = getPath({
			type: type,
			date: fmt,
			category: category,
			tag: tag,
			status: null,
		});

		const currentValue = get(getValues(), path);
		const historicalValue = get(history, path);

		for (const status of ['FORECAST', 'PAID']) {
			const value =
				currentValue?.[status]?.[field] ||
				historicalValue?.[status]?.[field];

			if (value) {
				return { date: fmt, value: value, status: status };
			}
		}
	}
}

export function getPreviousValue({
	type,
	category,
	tag,
	date: $date,
	history,
	getValues,

	field,
}) {
	const date = $date instanceof Date ? $date : parseISO($date);

	const historicalValue = get(
		history,
		getPath({
			type: type,
			date: format(subYears(date, 1), 'yyyy-MM-01'),
			status: 'PAID',
			category: category,
			tag: tag,
			field: field,
		}),
	);

	if (historicalValue) {
		return {
			date: format(subYears(date, 1), 'yyyy-MM-01'),
			value: historicalValue,
			status: 'PAID',
		};
	}

	const dates = []
		.concat(Object.keys(getValues()?.[type]?.data ?? {}))
		.concat(Object.keys(history?.[type]?.data ?? {}))
		.sort((a, b) => compareAsc(parseISO(a), parseISO(b)));

	if (!dates.length) {
		return null;
	}

	const startDate = parseISO(dates[0]);
	const endDate = parseISO(dates.slice(-1)[0]);

	let months = eachMonthOfInterval({ start: startDate, end: date })
		.slice(0, -1)
		.reverse();

	if (months.length) {
		const value = getValueByMonth({
			dates: months,
			type: type,
			category: category,
			tag: tag,
			field: field,
			getValues: getValues,
			history: history,
		});

		if (value) {
			return value;
		}
	}

	months = eachMonthOfInterval({ start: date, end: endDate }).slice(1);

	if (months.length) {
		const value = getValueByMonth({
			dates: months,
			type: type,
			category: category,
			tag: tag,
			field: field,
			getValues: getValues,
			history: history,
		});

		if (value) {
			return value;
		}
	}

	return null;
}

export function getVisibleState(object, { type, status, category, tag: $tag }) {
	if (!object) {
		return;
	}

	let tag = $tag;

	if (category === '$type') {
		tag = `$${type}`;
	}

	const types = object?.type ?? [];

	if (object?.id === 'forecast') {
		return status === 'FORECAST';
	}

	return types.includes(FormatUtils.replace([category, tag]).join('-'));
}
