import React from 'react';

import PropTypes from 'prop-types';

import NotificationEmpty from './empty';
import NotificationText from './text';

const Notification = React.memo((props) => {
	const { type } = props;

	switch (type?.toLowerCase?.()) {
		case 'empty':
			return <NotificationEmpty />;

		default:
			return <NotificationText {...props} />;
	}
});

Notification.displayName = 'Notification';

Notification.propTypes = { type: PropTypes.string };

export default Notification;
