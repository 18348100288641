import React from 'react';

import PropTypes from 'prop-types';

import PropTypeExtensions from '@asteria/component-storybook/PropTypes';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

export const Context = React.createContext({
	state: null,
	loading: false,
	compact: false,
	size: 'month',
	columns: {
		visible: [],
		available: [],
	},
	sorting: {
		field: 'paymentDate',
		direction: 'DESC',
	},
	reverse: false,

	useHeaderParts: () => [],
	useIsValid: () => true,
	useGroups: () => [],
});

export const Provider = (props) => {
	const {
		children,
		state,
		loading,
		compact,
		columns,
		sorting,
		size,

		useHeaderParts = () => [],
		useIsValid = () => true,
		useGroups = () => [],
	} = props;

	const ctx = useDeepMemo(
		() => ({
			state: state,
			loading: loading,
			compact: compact,
			size: size,
			columns: {
				visible: columns?.visible ?? [],
				available: columns?.available ?? [],
			},
			sorting: {
				field: sorting?.field,
				direction: sorting?.direction,
			},
			reverse:
				sorting?.field === 'paymentDate' &&
				sorting?.direction === 'ASC',

			useHeaderParts: useHeaderParts,
			useIsValid: useIsValid,
			useGroups: useGroups,
		}),
		[state, loading, compact, columns, sorting, size],
	);

	return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

Provider.displayName = 'SettingsContext';
Provider.propTypes = {
	children: PropTypes.node,
	state: PropTypeExtensions.oneOf(
		Object.values(TransactionStore.constants.STATES),
	),
	loading: PropTypes.bool,
	compact: PropTypes.bool,
	size: PropTypes.string,

	columns: PropTypes.shape({
		visible: PropTypes.arrayOf(PropTypes.string),
		available: PropTypes.arrayOf(PropTypes.string),
	}),

	sorting: PropTypes.shape({
		field: PropTypes.string,
		direction: PropTypes.string,
	}),

	useHeaderParts: PropTypes.func,
	useIsValid: PropTypes.func,
	useGroups: PropTypes.func,
};
