import { get } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import * as Changes from './changes';
import * as Tag from './tag';
import { getPath, validateFormContext } from './utils';

function shouldRecalculateParent(value) {
	return value === true || ['type', 'category', 'tag'].includes(value);
}

export function setProbability(
	{ type, category, tag, id, value, recalculate },
	ctx,
) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag, id });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: value,
			},
			context,
		),
	);

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Tag.recalculate({ type, category, tag, recalculate }, context),
		);
	}

	return changes;
}

export function setValue({ type, category, tag, id, value, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category, tag, id });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: value,
			},
			context,
		),
	);

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Tag.recalculate({ type, category, tag, recalculate }, context),
		);
	}

	return changes;
}

export function create({ type, category, tag, data, recalculate }, ctx) {
	const context = validateFormContext(ctx);

	const path = getPath({ type, category, tag, id: uuid() });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.join('.'),
				value: { total: 0, probability: 1, $new: true, ...data },
			},
			context,
		),
	);

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Tag.recalculate({ type, category, tag, recalculate }, context),
		);
	}

	return changes;
}

export function remove({ type, category, tag, id, recalculate }, ctx) {
	const context = validateFormContext(ctx);

	const path = getPath({ type, category, tag, id });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: 0,
			},
			context,
		),
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: 1,
			},
			context,
		),
		Changes.create(
			{
				type: 'set',
				name: path.concat('$deleted').join('.'),
				value: true,
			},
			context,
		),
	);

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Tag.recalculate({ type, category, tag, recalculate }, context),
		);
	}

	return changes;
}

export function move({ type, source, target, recalculate }, ctx) {
	const context = validateFormContext(ctx);

	const changes = [];

	const object = get(
		context.form,
		getPath({
			type,
			category: source?.category,
			tag: source?.tag,
			id: source?.id,
		}),
	);

	if (object) {
		changes.push(
			...create(
				{
					type,
					category: target?.category,
					tag: target?.tag,
					data: object,
				},
				context,
			),
			...remove(
				{
					type,
					category: source?.category,
					tag: source?.tag,
					id: source?.id,
				},
				context,
			),
		);
	}

	if (shouldRecalculateParent(recalculate)) {
		changes.push(
			...Tag.recalculate(
				{
					type,
					category: source?.category,
					tag: source?.tag,
					recalculate,
				},
				context,
			),
			...Tag.recalculate(
				{
					type,
					category: target?.category,
					tag: target?.tag,
					recalculate,
				},
				context,
			),
		);
	}

	return changes;
}
