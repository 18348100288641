import React from 'react';

import PropTypes from 'prop-types';

import { Actions as BaseActions } from '@asteria/component-actionbar';

import { cn } from '@asteria/utils-funcs/classes';

import ActionsSection from './section';

import './styles.scss';

const Actions = React.memo((props) => {
	const { className, onAction, actions } = props;

	const deposit = React.useMemo(
		() =>
			actions
				.filter(
					(object) =>
						object?.type === 'CATEGORY' &&
						object?.extra?.type === 'deposit',
				)
				.slice(0, 5),
		[actions],
	);

	const withdraw = React.useMemo(
		() =>
			actions
				.filter(
					(object) =>
						object?.type === 'CATEGORY' &&
						object?.extra?.type === 'withdraw',
				)
				.slice(0, 5),
		[actions],
	);

	return (
		<BaseActions
			className={cn('asteria-component__forecaster-actions', className)}
		>
			<ActionsSection
				type="deposit"
				actions={deposit}
				onAction={onAction}
			/>

			<ActionsSection
				type="withdraw"
				actions={withdraw}
				onAction={onAction}
			/>
		</BaseActions>
	);
});

Actions.displayName = 'Actions';

Actions.propTypes = {
	className: PropTypes.string,
	actions: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

export default Actions;
