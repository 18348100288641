import { createSelector } from '@reduxjs/toolkit';

import * as ScenarioStore from '../scenarios';

export const currentBalance = createSelector(
	(state) => state?.accounts?.accountBalance,
	(value) => value ?? 0,
);

export const credit = createSelector(
	(store) => store?.accounts?.credit,
	(credit) => credit ?? 0,
);

export const simulation = createSelector(
	credit,
	(store, options) =>
		ScenarioStore.selectors.scenario(store, options?.simulationId)?.data
			?.value,
	(credit, simulation) => simulation ?? credit ?? 0,
);

export const dynamicCredit = createSelector(
	(store) => store?.accounts?._credit,
	(store, options) => options?.defaultValue ?? simulation(store, options),
	(value, defaultValue) => value ?? defaultValue,
);

export const hasCreditChange = createSelector(
	(store, options) => simulation(store, options),
	(store, options) => dynamicCredit(store, options),
	(source, target) => source !== target,
);
