/**
 * @param { import('mustache') } Mustache
 * @param { * } Formatters
 * @returns { import('mustache') }
 */
const Formatter = (Mustache, Formatters) => {
	const originalLookup = Mustache.Context.prototype.lookup;

	const getParams = (param, lookup) => {
		const isString = /^['"](.*)['"]$/g;
		const isInteger = /^[+-]?\d+$/g;
		const isFloat = /^[+-]?\d*\.\d+$/g;

		if (isString.test(param)) {
			return param.replace(isString, '$1');
		}

		if (isInteger.test(param)) {
			return parseInt(param, 10);
		}

		if (isFloat.test(param)) {
			return parseFloat(param);
		}

		if (param === 'true') {
			return true;
		}

		if (param === 'false') {
			return false;
		}

		if (param === false) {
			return false;
		}

		return lookup(param);
	};

	const format = (expr, fltr, lookup, formatters = {}) => {
		const filterExp = /^\s*([^:]+)/g;
		const paramsExp = /:\s*(['][^']*[']|["][^"]*["]|[^:]+)\s*/g;
		let match = filterExp.exec(fltr);
		const filter = match[1].trim();
		const params = [expr];

		// eslint-disable-next-line no-cond-assign
		while ((match = paramsExp.exec(fltr))) {
			params.push(getParams(match[1].trim(), lookup));
		}

		const formatter = formatters[filter] || Formatters[filter] || null;
		if (formatter) {
			return formatter.apply(formatter, params);
		}

		return expr;
	};

	// eslint-disable-next-line no-param-reassign
	Mustache.Context.prototype.lookup = function parseExpression(name) {
		const formatters = name.split('|');
		let expression = formatters.shift().trim();
		// call original lookup method
		expression = originalLookup.call(this, expression);
		// Apply the formatters
		for (let i = 0; i < formatters.length; i += 1) {
			expression = format(
				expression,
				formatters[i],
				this.lookup.bind(this),
				this.view.formatters,
			);
		}
		return expression;
	};

	return Mustache;
};

export default Formatter;
