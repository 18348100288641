import React from 'react';

import PropTypes from 'prop-types';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

const FeatureWrapper = (props) => {
	const { feature, children } = props;

	if (feature) {
		return <FeatureFlag feature={feature}>{children}</FeatureFlag>;
	}

	return children;
};

FeatureWrapper.displayName = 'FeatureWrapper';

FeatureWrapper.propTypes = {
	feature: PropTypes.string,
	children: PropTypes.node,
};

export default FeatureWrapper;
