import { createSlice } from '@reduxjs/toolkit';
import { subYears } from 'date-fns';

import { ACTIONS } from '../../constants';
import { setCompany, setPartner } from '../app';

import { initialState } from './constants';
import * as reducers from './reducers';

export default createSlice({
	name: 'invoices',
	initialState: initialState,
	reducers: reducers,
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
		[setPartner]: (state, { payload }) => {
			state.serviceId = payload?._id ?? payload.id;
		},
		[setCompany]: (state, { payload }) => {
			const service = (payload?.services ?? []).find(
				(service) => service?.data?.version,
			);

			state.version = service?.data?.version ?? 1;

			if (!state?.reports?.timestamp) {
				const timestamp =
					payload?.settings?.flags?.['freja:reports:timestamp'];

				if (timestamp) {
					state.reports.timestamp = timestamp;
				} else {
					state.reports.timestamp = subYears(
						new Date(),
						1,
					).toISOString();
				}
			}
		},
	},
});
