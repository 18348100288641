import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import '../styles.scss';

/** @type { React.FC<{ from: string, step: number, card: unknown }> } */
const FeedbackSuccess = React.memo(function FeedbackSuccess({ from, card }) {
	return (
		<div className="flex flex-col gap-4">
			<div>
				<Icon icon="check" size="xl" />
			</div>
			<TextGroup className="flex flex-col gap-2">
				<Translation
					translationKey="card.content.title"
					translationOptions={{
						postfix: {
							variant: 'feedback',
							from: from,
							step: 'success',
						},
						data: { card: card },
					}}
					Component={Title}
				/>
				<Translation
					translationKey="card.content.text"
					translationOptions={{
						postfix: {
							variant: 'feedback',
							from: from,
							step: 'success',
						},
						data: { card: card },
					}}
					Component={Text}
				/>
			</TextGroup>
		</div>
	);
});

FeedbackSuccess.propTypes = {
	from: PropTypes.string,
	step: PropTypes.number,
	card: PropTypes.object,
};

export default FeedbackSuccess;
