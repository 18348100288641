import React, { useCallback } from 'react';

import { useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

// import ClientDetailsAlert from './ClientDetailsAlert';
import ClientDetailsInfo from './ClientDetailsInfo';
import ClientDetailsOverview from './ClientDetailsOverview';
import ClientDetailsStatistics from './ClientDetailsStatistics';

import './index.scss';

const selectors = {
	client: createSelector(
		(store) => store?.app?.clients ?? [],
		(_, ID) => ID,
		(clients, ID) =>
			clients.find((object) => (object?.id ?? object._id) === ID),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const ClientDetails = (props) => {
	const { className, _id, onAction } = props;

	const store = useStore();

	const client = useSelector(
		(store) => selectors.client(store, _id),
		(a, b) => isEqual(a, b),
	);

	// const [isAlertShown, setAlertState] = useState(true);

	// const onAlertClose = useCallback(() => {
	// 	setAlertState(false);
	// 	return onAction?.('clients:alert.close', client);
	// }, [client, onAction]);

	// const onAlertLinkClick = useCallback(
	// 	() => onAction?.('clients:alert.link', client),
	// 	[client, onAction],
	// );

	const onFilterLinkClick = useCallback(() => {
		const client = selectors.client(store.getState(), _id);

		return onAction?.('clients:filter', client);
	}, [_id, onAction, store]);

	return (
		<div
			className={cn(
				'asteria-component__client-overview__details',
				className,
			)}
		>
			<ClientDetailsOverview
				client={client}
				onFilterLinkClick={onFilterLinkClick}
			/>
			<div className="asteria-component__client-overview__details__wrapper">
				<ClientDetailsStatistics client={client} />
				{/* {isAlertShown ? (
					<ClientDetailsAlert
						onAlertClose={onAlertClose}
						onAlertLinkClick={onAlertLinkClick}
						client={client}
					/>
				) : null} */}
				<ClientDetailsInfo client={client} />
			</div>
		</div>
	);
};

ClientDetails.propTypes = {
	className: PropTypes.string,
	_id: PropTypes.string,

	onAction: PropTypes.func,
};

ClientDetails.defaultProps = {};

export default ClientDetails;
