import React from 'react';

import styled from 'styled-components';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';

const Navigation = styled(
	({ className, onNext = () => {}, onPrev = () => {} }) => (
		<div className={className} role="grid" tabIndex="-1">
			<Button
				className="asteria-button-left"
				size="sm"
				onClick={() => onPrev()}
				icon="chevron-left"
				tooltip={{
					tooltip: TranslationService.get([
						'graph.navigation.previous',
						'graph.navigation.tooltip',
						'graph.navigation.previous.tooltip',
					]),
					variant: 'alt',
				}}
				analyticsKey={'graph.navigation.previous'}
			/>
			<Button
				className="asteria-button-right"
				size="sm"
				onClick={() => onNext()}
				icon="chevron-right"
				tooltip={{
					tooltip: TranslationService.get([
						'graph.navigation.next',
						'graph.navigation.tooltip',
						'graph.navigation.next.tooltip',
					]),
					variant: 'alt',
				}}
				analyticsKey={'graph.navigation.next'}
			/>
		</div>
	),
)`
	// display: flex;
	// justify-content: space-between;
	// align-items: center;
	// position: absolute;
	// top: 0px;
	// right: 0;
	// left: 0;
	// height: 100%;
	// width: 100%;
	// pointer-events: none;

	// .asteria-component__button {
	// 	pointer-events: all;
	// }
`;
Navigation.displayName = 'Navigation';

export default Navigation;
