import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Group = React.memo(
	React.forwardRef((props, ref) => {
		const { className, children, border, ...rest } = props;
		return (
			<div
				className={cn(
					'asteria-component__list__group',
					[]
						.concat(border)
						.filter(Boolean)
						.map(
							(value) =>
								`asteria-component__list__group--border-${value}`,
						),
					className,
					sizeClasses(props),
				)}
				{...rest}
				ref={ref}
			>
				{children}
			</div>
		);
	}),
);

Group.displayName = 'ListGroup';

Group.propTypes = {
	className: PropTypes.string,
	border: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	size: SizeProp(),
	children: PropTypes.node,
};

Group.defaultProps = {};

export default React.memo(Group);
