import React from 'react';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';
import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import './styles.scss';

const Slider = React.forwardRef((props, ref) => {
	const { children, className, noScroll, updateBy = [] } = props;

	const [{ leftButtonPosition, rightButtonPosition }, dispatch] =
		React.useReducer(
			(state, action) => {
				switch (action?.type) {
					case 'UPDATE_LEFT':
						return {
							...state,
							leftButtonPosition: action?.payload,
						};

					case 'UPDATE_RIGHT':
						return {
							...state,
							rightButtonPosition: action?.payload,
						};

					case 'UPDATE':
						return {
							...state,
							leftButtonPosition: action?.payload?.left,
							rightButtonPosition: action?.payload?.right,
						};

					default:
						return state;
				}
			},
			{ leftButtonPosition: null, rightButtonPosition: null },
		);

	const sliderRef = React.useRef(null);
	const $ref = useCombinedRefs(ref, sliderRef);

	const length = React.Children.toArray(children).length;

	const handleScroll = React.useMemo(
		() =>
			AsteriaCore.utils.throttle((event) => {
				const element = event.target;

				const clientWidth = element.clientWidth;
				const scrollWidth = element.scrollWidth;
				const scrollLeft = element.scrollLeft;

				const position = scrollLeft;

				const leftPosition = position > 0 ? position : null;
				const rightPosition =
					scrollWidth - scrollLeft > clientWidth ? -position : null;

				dispatch({
					type: 'UPDATE',
					payload: { left: leftPosition, right: rightPosition },
				});
			}, 100),
		[],
	);

	React.useLayoutEffect(() => {
		if (!sliderRef.current) {
			return;
		}

		const element = sliderRef.current;

		const clientWidth = element.clientWidth;
		const scrollWidth = element.scrollWidth;

		if (scrollWidth > clientWidth) {
			dispatch({ type: 'UPDATE_RIGHT', payload: 0 });
		}

		element.addEventListener('scroll', handleScroll);

		return () => {
			element?.removeEventListener?.('scroll', handleScroll);
		};
	}, [...updateBy, handleScroll, length]);

	React.useLayoutEffect(() => {
		if (!sliderRef.current) {
			return;
		}

		const handler = AsteriaCore.utils.throttle((entries) => {
			for (const entry of entries) {
				handleScroll(entry);
			}
		}, 100);

		const node = sliderRef?.current;

		const observer = new ResizeObserver(handler);
		observer.observe(node);

		return () => {
			observer.unobserve(node);
		};
	}, [handleScroll]);

	const handleScrollRight = React.useCallback(() => {
		if (!sliderRef.current) {
			return;
		}

		sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });

		const clientWidth = sliderRef.current.clientWidth;
		const scrollWidth = sliderRef.current.scrollWidth;
		const scrollLeft = sliderRef.current.scrollLeft + 300;

		const position = Math.min(scrollLeft, scrollWidth - clientWidth);

		const leftPosition = position;
		const rightPosition =
			scrollWidth - scrollLeft > clientWidth ? -position : null;

		dispatch({
			type: 'UPDATE',
			payload: { left: leftPosition, right: rightPosition },
		});
	}, []);

	const handleScrollLeft = React.useCallback(() => {
		if (!sliderRef.current) {
			return;
		}

		sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });

		const scrollLeft = sliderRef.current.scrollLeft - 300;

		const position = Math.max(scrollLeft, 0);

		const leftPosition = -position;
		const rightPosition = scrollLeft > 0 ? position : null;

		dispatch({
			type: 'UPDATE',
			payload: { left: leftPosition, right: rightPosition },
		});
	}, []);

	const navigationStyles = React.useMemo(() => {
		const styles = {};

		if (leftButtonPosition !== null) {
			styles['--left'] = leftButtonPosition + 'px';
		}

		if (rightButtonPosition !== null) {
			styles['--right'] = rightButtonPosition + 'px';
		}

		return styles;
	}, [leftButtonPosition, rightButtonPosition]);

	return (
		<div
			className={cn(
				'asteria-component__slider',
				{ 'asteria-component__slider--no-scroll': noScroll },
				className,
			)}
			ref={$ref}
		>
			{leftButtonPosition !== null ? (
				<Button
					className={cn(
						'asteria-component__slider__navigation',
						'asteria-component__slider__navigation--action-previous',
					)}
					icon="chevron-left"
					size="sm"
					style={navigationStyles}
					onClick={handleScrollLeft}
				/>
			) : null}

			{children}

			{rightButtonPosition !== null ? (
				<Button
					className={cn(
						'asteria-component__slider__navigation',
						'asteria-component__slider__navigation--action-next',
					)}
					icon="chevron-right"
					size="sm"
					style={navigationStyles}
					onClick={handleScrollRight}
				/>
			) : null}
		</div>
	);
});

Slider.displayName = 'Slider';

Slider.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	noScroll: PropTypes.bool,
	updateBy: PropTypes.arrayOf(PropTypes.any),
};

Slider.defaultProps = {};

export default Slider;
