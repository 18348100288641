import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import Chip from '@asteria/component-chip/chip';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import * as FormatUtils from '@asteria/utils-funcs/format';

import { useMoveCategory } from '../../hooks';

const CategoryItem = (props) => {
	const { data, onAction } = props;

	const moveCategory = useMoveCategory({ onAction });

	const handleMoveCategory = React.useCallback(() => {
		moveCategory(data);
	}, [data, moveCategory]);

	return (
		<DropdownItem
			className="asteria-component__level-navigation-dropdown-adjust"
			onClick={handleMoveCategory}
		>
			<Chip
				variant="simple"
				colors={
					data?.color ?? [
						data?.type,
						FormatUtils.replace([
							data?.category?.name,
							data?.name,
						]).join('-'),
					]
				}
				label={FormatUtils.formatTag({
					type: data?.type,
					category: data?.category?.name,
					tag: data?.name,
				})}
			/>
		</DropdownItem>
	);
};

CategoryItem.displayName = 'CategoryItem';

CategoryItem.propTypes = {
	data: PropTypes.object,
	onAction: PropTypes.func,
};

const CategoryDropdown = (props) => {
	const { type, onAction } = props;

	const availableTags = useSelector((store) =>
		ForecasterStore.selectors.tags.available(store, { type: type }),
	).filter((item) => item?.name !== '$adjusted');

	const toggle = useMemo(
		() => ({
			icon: 'triangle-down',
			iconActive: 'triangle-up',
		}),
		[],
	);

	return (
		<Dropdown toggle={toggle} scroll>
			{availableTags.map((object) => (
				<CategoryItem
					key={object?.id}
					data={object}
					onAction={onAction}
				/>
			))}
		</Dropdown>
	);
};

CategoryDropdown.displayName = 'CategoryDropdown';

CategoryDropdown.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
};

export default CategoryDropdown;
