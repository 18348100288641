import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { stateClasses } from '@asteria/component-core/utils';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as GraphStore from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import LegendItem from './item';

import './index.scss';

const Legend = (props) => {
	const {
		data = [],
		direction = 'row',
		className,
		onClick,
		onHover,
		negative,
		clickable,
		hoverable,
		part,
	} = props;

	const dispatch = useDispatch();

	const selected = useSelector(
		(store) => GraphStore.selectors.legends.selected(store, { part: part }),
		(a, b) => isEqual(a, b),
	);

	const filters = useSelector(AppStore.selectors.filters, (a, b) =>
		isEqual(a, b),
	);

	const hasBelowZeroFeature = useFeature(`graph-below-zero`);
	const hasBelowZeroLegendFeature = useFeature(`graph-legend-below-zero`);

	const showLegendBelowZero =
		hasBelowZeroFeature && hasBelowZeroLegendFeature;

	React.useEffect(() => {
		dispatch(GraphStore.setLegendSelected(null));
	}, [dispatch, filters]);

	const handleClick = useCallback(
		(data) => {
			const key = (data?.type ?? []).join('-');

			Analytics.event('graph.legend.click.toggle', { data: key });

			dispatch(
				GraphStore.setLegendSelected({
					id: key,
					part: data?.part,
					title: data?.title,
					type: data?.type,
				}),
			);
		},
		[dispatch],
	);

	const handleHover = React.useCallback(
		(event, data) => {
			const key = (data?.type ?? []).join('-');

			dispatch(
				GraphStore.setLegendHighlight(
					data
						? {
								id: key,
								part: data?.part,
								title: data?.title,
								type: data?.type,
						  }
						: null,
				),
			);

			return onHover?.(event, data);
		},
		[dispatch, onHover],
	);

	return (
		<div className={cn('asteria-component__legends__wrapper', className)}>
			<div
				className={cn(
					'asteria-component__legends',
					{
						[`asteria-component__legends--direction-${direction}`]:
							direction,
						'asteria-component__legends--selectable':
							clickable || onClick,
					},
					stateClasses({ active: selected.length }),
				)}
			>
				{data.flatMap((object) => {
					const type = [].concat(object.type);

					const lastItem = type?.[type.length - 1];

					if (
						negative &&
						showLegendBelowZero &&
						(lastItem === 'paid' ||
							(object?.part !== 'cashflow' &&
								lastItem === 'forecast') ||
							lastItem === 'spread')
					) {
						return [
							<LegendItem
								key={['legend', ...type].join('-')}
								{...object}
								type={type}
								active={selected.some(
									(value) =>
										value?.id === [].concat(type).join('-'),
								)}
								onClick={clickable ? handleClick : onClick}
								onHover={hoverable ? handleHover : onHover}
							/>,
							<LegendItem
								key={['legend', ...type, 'negative'].join('-')}
								{...object}
								title={TranslationService.get(
									`graph.account.legend.${lastItem}.negative`,
								)}
								type={[...type, 'negative']}
								active={selected.some(
									(value) =>
										value?.id ===
										[]
											.concat(type)
											.concat('negative')
											.join('-'),
								)}
								onClick={clickable ? handleClick : onClick}
								onHover={hoverable ? handleHover : onHover}
							/>,
						];
					}

					return (
						<LegendItem
							key={['legend', ...type].join('-')}
							{...object}
							type={type}
							active={selected.some(
								(value) =>
									value?.id === [].concat(type).join('-'),
							)}
							onClick={clickable ? handleClick : onClick}
							onHover={hoverable ? handleHover : onHover}
						/>
					);
				})}
			</div>
		</div>
	);
};

Legend.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	direction: PropTypes.oneOf(['row', 'column']),
	className: PropTypes.string,
	onClick: PropTypes.func,
	onHover: PropTypes.func,

	clickable: PropTypes.bool,
	hoverable: PropTypes.bool,
	negative: PropTypes.bool,
};

export default Legend;
