import React from 'react';

import PropTypes from 'prop-types';

import Feedback from '@asteria/component-feedback';

import { TranslationService } from '@asteria/language';

import './styles.scss';

/** @type { React.FC<{ onAction: function }> } */
const StreamlinedViewFeedback = (props) => {
	const { onAction } = props;

	const onFeedbackAction = React.useCallback(
		(action, data) => {
			return onAction?.(action, data);
		},
		[onAction],
	);

	const onFeedbackSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	return (
		<Feedback
			description={TranslationService.getV2(['feedback.text'], {
				postfix: { feedback: 'page', view: 'streamlined' },
			})}
			feedbackKey="streamlined"
			minText={TranslationService.getV2(['feedback.rating'], {
				postfix: {
					feedback: 'page',
					view: 'streamlined',
					value: 'min',
				},
			})}
			maxText={TranslationService.getV2(['feedback.rating'], {
				postfix: {
					feedback: 'page',
					view: 'streamlined',
					value: 'max',
				},
			})}
			values={[1, 2, 3, 4, 5]}
			size="lg"
			onAction={onFeedbackAction}
			onSupportRequest={onFeedbackSupportRequest}
			validate
			type="streamlined"
		/>
	);
};

StreamlinedViewFeedback.propTypes = {
	onAction: PropTypes.func,
};

export default StreamlinedViewFeedback;
