import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { useFormValues } from '@asteria/component-form';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useProperties } from '../../hooks';

import { useNavigation } from './hooks';

import './styles.scss';

const SectionActions = React.memo((props) => {
	const { onAction, variant } = props;

	const { type, category, tag } = useNavigation({ variant });

	const { getValues, setValue } = useFormContext();

	const [isSaving, setSaving] = React.useState(false);

	const onBack = React.useCallback(() => {
		const isDirty = !!getValues('$touched');

		if (isDirty) {
			return onAction?.('navigate', null);
		}

		return onAction?.('form:discard');
	}, [getValues, onAction]);

	const onSubmit = React.useCallback(
		async (event) => {
			setSaving(true);

			try {
				await onAction?.('form:save', { form: cloneDeep(getValues()) });
				await onAction?.('action:remove');
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Category Page -> Save:', err);
			}

			setValue('$touched', false);

			setSaving(false);

			return onBack(event);
		},
		[getValues, onAction, onBack, setValue],
	);

	const isLoading = useSelector(ForecasterStore.selectors.flags.isLoading);

	const { label } = useProperties({
		type: type,
		category: category,
		tag: tag,
		useForm: useFormValues,
		useStaticForm: getValues,
	});

	return (
		<Group
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-actions',
			)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="center"
		>
			<Button
				variant="link"
				size="sm"
				label={TranslationService.get(
					[
						'button.back',
						'action.back',
						'forecaster.page.action.back',
						'forecaster.page.category.action.back',
					],
					undefined,
					{ tag: label },
				)}
				onClick={onBack}
			/>
			<Button
				variant="primary"
				size="sm"
				onClick={onSubmit}
				label={TranslationService.get(
					[
						'button.submit',
						'action.submit',
						'forecaster.page.action.submit',
						'forecaster.page.category.action.submit',
					],
					undefined,
					{ tag: label },
				)}
				disabled={isLoading || isSaving}
				loading={isSaving}
			/>
		</Group>
	);
});

SectionActions.displayName = 'SectionActions';
SectionActions.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default SectionActions;
