import React from 'react';

import { formatISO, isPast, isThisMonth } from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import TypedBarGroup from './typed-group';

import '../styles.scss';

const BarGraphColumn = React.memo((props) => {
	const {
		className,
		onAction,
		type,
		date,
		category,
		tag,
		types = [type],

		render,
		renderItem,
	} = props;

	let columnType = null;

	if (typeof date !== 'string') {
		if (isThisMonth(date)) {
			columnType = 'CURRENT';
		} else if (isPast(date)) {
			columnType = 'HISTORY';
		} else {
			columnType = 'FUTURE';
		}
	}

	return (
		<div
			className={cn(
				'asteria-component__forecaster-graph-column',
				{ 'asteria--variant-combined': types.length > 1 },
				className,
			)}
			data-date={
				typeof date !== 'string'
					? formatISO(date, { representation: 'date' })
					: date
			}
			data-type={columnType}
		>
			{render
				? render({ onAction, type, date, category, tag })
				: types.map((type) =>
						renderItem ? (
							renderItem({
								onAction: onAction,
								type: type,
								date: date,
								category: category,
								tag: tag,
							})
						) : (
							<TypedBarGroup
								key={type}
								onAction={onAction}
								type={type}
								date={date}
								category={category}
								tag={tag}
							/>
						),
				  )}
		</div>
	);
});

BarGraphColumn.displayName = 'BarGraphColumn';

BarGraphColumn.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,

	types: PropTypes.arrayOf(PropTypes.string),
	type: PropTypes.string,
	date: PropTypes.string,
	status: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	render: PropTypes.func,
	renderItem: PropTypes.func,
};

export default BarGraphColumn;
