import { addMonths, eachMonthOfInterval, format } from 'date-fns';
import { get } from 'lodash-es';

export default (form, path, includeFocus, length = 6) => {
	const dates = eachMonthOfInterval({
		start: new Date(),
		end: addMonths(new Date(), length - 1),
	}).map((date) => format(date, 'yyyy-MM-01'));

	const values = dates
		.filter((date) => {
			const val = get(form?.[date], `${path}.$state`, 'pending');

			if (val === 'pending') {
				return true;
			}

			if (val === 'focus' && includeFocus) {
				return true;
			}

			return false;
		})
		.map((date) => ({
			date,
			weight: get(form?.[date], `${path}.probability`),
		}));

	return values.sort((a, b) => a?.weight - b?.weight);
};
