import React from 'react';

import PropTypes from 'prop-types';

import { Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';
import Feedback from '@asteria/component-feedback';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const FeedbackDialogContent = (props) => {
	const { onClose, onAction, title, feedbackKey, ...rest } = props;

	const HeaderPostfix = React.useMemo(
		() => ({ position: 'absolute', size: 'xs' }),
		[],
	);

	return (
		<Wrapper>
			<Header
				onClose={() =>
					onAction?.('updateFeedback', {
						feedbackKey,
						dismissed: true,
					})
				}
				verticalAlign="top"
				postfix={HeaderPostfix}
			>
				<Title size="sm">{title}</Title>
			</Header>
			<Content>
				<Feedback
					title=""
					description={TranslationService.get('feedback.text')}
					maxText={TranslationService.get('feedback.rating.max')}
					minText={TranslationService.get('feedback.rating.min')}
					values={[1, 2, 3, 4, 5]}
					nodismiss
					onSupportRequest={() => onAction('go', '/support')}
					onEnd={onClose}
					onAction={onAction}
					feedbackKey={feedbackKey}
					validate={false}
					{...rest}
				/>
			</Content>
		</Wrapper>
	);
};

FeedbackDialogContent.displayName = 'FeedbackDialogContent';

FeedbackDialogContent.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	feedbackKey: PropTypes.string,
	title: PropTypes.string,
};

const FeedbackDialog = (props) => {
	const { className } = props;

	return (
		<Dialog
			className={cn('asteria-component__feedback__dialog', className)}
			placement="bottom-right"
		>
			<FeedbackDialogContent {...props} />
		</Dialog>
	);
};

FeedbackDialog.displayName = 'FeedbackDialog';

FeedbackDialog.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	feedbackKey: PropTypes.string,
	title: PropTypes.string,
};

export default FeedbackDialog;

export { FeedbackDialogContent };
