import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';

import { parseISO } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import { Input, useFormValues } from '@asteria/component-form';
import Select, { Option } from '@asteria/component-form/select';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useModalActions } from '../../../components/modal-collection';
import { LoanSaveModal } from '../../../modals';
import { getData } from '../../../utils/loan';

import LoanRange from './components/loan-range';

function useTotalValue() {
	const [type, amount, term, rate, timestamp] = useFormValues({
		name: ['type', 'amount', 'term', 'rate', 'timestamp'],
	});

	const data = getData({
		type,
		amount: Number.parseFloat(amount),
		term: Number.parseFloat(term),
		rate: Number.parseFloat(rate),
		timestamp: timestamp ? parseISO(timestamp) : new Date(),
	});

	return data.reduce((acc, object) => acc + (object?.interest || 0), 0);
}

function useAverageValue() {
	const [type, amount, term, rate, timestamp] = useFormValues({
		name: ['type', 'amount', 'term', 'rate', 'timestamp'],
	});

	const data = getData({
		type,
		amount: Number.parseFloat(amount),
		term: Number.parseFloat(term),
		rate: Number.parseFloat(rate),
		timestamp: timestamp ? parseISO(timestamp) : new Date(),
	});

	return (
		data.reduce((acc, object) => acc + (object?.total || 0), 0) /
		(term * 12)
	);
}

const SectionTotal = React.memo(() => {
	const value = useTotalValue();

	return (
		<TextGroup>
			<Text size="sm" weight="bold" className="asteria--variant-title">
				{TranslationService.get([
					'financial.page.loan.aside.summary.total.title',
				])}
			</Text>
			<Text size="xs" className="asteria--variant-value">
				{TranslationService.get(
					['financial.page.loan.aside.summary.total.value'],
					undefined,
					{ value: value },
				)}
			</Text>
		</TextGroup>
	);
});

SectionTotal.displayName = 'SectionTotal';

const SectionAverage = React.memo(() => {
	const value = useAverageValue();

	return (
		<TextGroup>
			<Text size="sm" weight="bold" className="asteria--variant-title">
				{TranslationService.get([
					'financial.page.loan.aside.summary.average.title',
				])}
			</Text>
			<Text size="xs" className="asteria--variant-value">
				{TranslationService.get(
					['financial.page.loan.aside.summary.average.value'],
					undefined,
					{ value: value },
				)}
			</Text>
		</TextGroup>
	);
});

SectionAverage.displayName = 'SectionAverage';

const AmountChip = React.memo((props) => {
	const { amount, currency, onClick } = props;

	const formAmount = useFormValues({ name: 'amount' });

	const handleClick = React.useCallback(
		() => onClick?.(amount),
		[amount, onClick],
	);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				[
					'financial.page.loan.aside.amount.example.label',
					`financial.page.loan.aside.amount.${currency}.example.label`,
				],
				'{{ amount | number:false:false:true }} {{ currency }}',
				{ currency: currency, amount: amount },
			)}
			active={Number.parseFloat(formAmount) === Number.parseFloat(amount)}
			onClick={handleClick}
		/>
	);
});

AmountChip.displayName = 'AmountChip';
AmountChip.propTypes = {
	amount: PropTypes.number,
	currency: PropTypes.string,
	onClick: PropTypes.func,
};

const SectionAside = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const store = useStore();

	const { id } = useParams();
	const { getValues, setValue } = useFormContext();
	const { open } = useModalActions();

	const { type } = useWatch();

	const currency = useSelector(AppStore.selectors.currency);

	const [loading, setLoading] = React.useState(false);

	const onAmountChipClick = React.useCallback(
		(amount) => {
			setValue('amount', amount, {
				shouldDirty: true,
				shouldTouch: true,
			});
		},
		[setValue],
	);

	const onContinue = React.useCallback(() => {
		open(<LoanSaveModal />, {
			form: cloneDeep(getValues()),
			simulationId: id,
			callback: onAction,
		});
	}, [getValues, id, onAction, open]);

	const onSave = React.useCallback(async () => {
		const simulation = ScenarioStore.selectors.scenario(
			store.getState(),
			id,
		);

		if (simulation) {
			setLoading(true);

			try {
				await onSubmit?.('scenario:update', {
					_id: id,
					data: cloneDeep(getValues()),
				});
			} catch (err) {
				//
			}

			setLoading(false);
		}

		onAction?.('loan:dirty:reset');
		onAction?.('redirect', '/financial');
	}, [getValues, id, onAction, onSubmit, store]);

	const inputNumberFormat = React.useMemo(
		() => ({ valueAsNumber: true }),
		[],
	);

	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-aside',
			)}
		>
			<Wrapper>
				<Content>
					<Text size="sm">
						{TranslationService.get([
							'financial.page.loan.aside.content',
						])}
					</Text>

					<Select
						className="asteria--type-loan"
						name="type"
						placeholder={TranslationService.get([
							'financial.page.loan.aside.type.placeholder',
						])}
						label={TranslationService.get([
							'financial.page.loan.aside.type.label',
						])}
					>
						<Option
							value="STRAIGHT"
							postfix={
								type === 'STRAIGHT' ? (
									<Group
										verticalAlign="center"
										horizontalAlign="center"
									>
										<Button icon="check" />
									</Group>
								) : null
							}
						>
							{TranslationService.get([
								'financial.page.loan.aside.type.value.label',
								'financial.page.loan.aside.type.value.STRAIGHT.label',
							])}
						</Option>
						<Option
							value="ANNUITY"
							postfix={
								type === 'ANNUITY' ? (
									<Group
										verticalAlign="center"
										horizontalAlign="center"
									>
										<Button icon="check" />
									</Group>
								) : null
							}
						>
							{TranslationService.get([
								'financial.page.loan.aside.type.value.label',
								'financial.page.loan.aside.type.value.ANNUITY.label',
							])}
						</Option>
					</Select>

					<Group flex>
						<Input
							name="amount"
							type="number"
							label={TranslationService.get([
								'financial.page.loan.aside.amount.label',
							])}
							min={0}
							postfix={
								<Text size="sm" weight="bold">
									{TranslationService.get(
										[
											'financial.page.loan.aside.amount.postfix',
											`financial.page.loan.aside.amount.${currency}.postfix`,
										],
										'{{ currency }}',
										{ currency: currency },
									)}
								</Text>
							}
							format={inputNumberFormat}
						/>

						<Group direction="horizontal" flex>
							{[1_000_000, 5_000_000].map((amount) => (
								<AmountChip
									key={amount}
									amount={amount}
									currency={currency}
									onClick={onAmountChipClick}
								/>
							))}
						</Group>
					</Group>

					<Group className="asteria--variant-range-controls">
						<LoanRange
							name="term"
							label={TranslationService.get([
								'financial.page.loan.aside.term.label',
							])}
							minLabel="financial.page.loan.aside.term.label.min"
							maxLabel="financial.page.loan.aside.term.label.max"
							min={1}
							max={5}
							format={inputNumberFormat}
						/>
						<LoanRange
							name="rate"
							label={TranslationService.get([
								'financial.page.loan.aside.rate.label',
							])}
							minLabel="financial.page.loan.aside.rate.label.min"
							maxLabel="financial.page.loan.aside.rate.label.max"
							postfix={
								<Text size="sm">
									{TranslationService.get(
										'financial.page.loan.aside.rate.postfix',
									)}
								</Text>
							}
							min={0}
							max={15}
							step={0.5}
							format={inputNumberFormat}
						/>
					</Group>

					<Group flex className="asteria--variant-summary">
						<SectionTotal />
						<SectionAverage />
					</Group>
				</Content>
				<Footer>
					<FooterSection position="last">
						{id ? (
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.save',
									'action.save',
									'financial.page.loan.aside.action.save.label',
								])}
								onClick={onSave}
								loading={loading}
								disabled={loading}
							/>
						) : (
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.continue',
									'action.continue',
									'financial.page.loan.aside.action.continue.label',
								])}
								onClick={onContinue}
							/>
						)}
					</FooterSection>
				</Footer>
			</Wrapper>
		</div>
	);
});

SectionAside.displayName = 'SectionAside';
SectionAside.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default SectionAside;
