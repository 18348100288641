import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useOnboardingState } from '../../hooks';

/**
 * @typedef { import('./types').RedirectButtonProps } RedirectButtonProps
 */

/** @type { React.FC<RedirectButtonProps> } */
const RedirectButton = React.memo(function RedirectButton({
	onAction,
	type,
	startDate,
	endDate,
	...args
}) {
	const onboarding = useOnboardingState();

	const onRedirect = React.useCallback(
		() =>
			onAction?.('card:redirect', {
				type,
				startDate,
				endDate,
				onboarding,
			}),
		[endDate, onAction, onboarding, startDate, type],
	);

	const analytics = React.useMemo(
		() => ({ startDate, endDate }),
		[endDate, startDate],
	);

	return (
		<Button
			size="sm"
			icon="chevron-right"
			onClick={onRedirect}
			analyticsKey={`card.${type}.redirect`}
			analytics={analytics}
			{...args}
		/>
	);
});

RedirectButton.propTypes = {
	onAction: PropTypes.func,
	type: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default RedirectButton;
