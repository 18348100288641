import React from 'react';
import ReactDOM from 'react-dom';

import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';
import { useFeature } from '@asteria/component-tools/featureflag';
import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import animationListener from '@asteria/utils-funcs/animationListener';
import { cn } from '@asteria/utils-funcs/classes';
import stopPropogration from '@asteria/utils-funcs/stopPropogration';
import useWrapper from '@asteria/utils-hooks/useWrapper';

import './index.scss';

const SidepanePortal = (props) => {
	const wrapper = useWrapper('asteria-sidepane-container');

	return ReactDOM.createPortal(<Sidepane {...props} />, wrapper);
};

SidepanePortal.displayName = 'SidepanePortal';

SidepanePortal.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.node,
	open: PropTypes.bool,
};

const Sidepane = ({ className, open, children }) => {
	return (
		<CSSTransition
			in={open}
			unmountOnExit
			classNames="asteria-sidepane"
			addEndListener={animationListener}
		>
			<div
				className={cn('asteria-component__sidepane', className)}
				onClick={stopPropogration}
			>
				{children}
			</div>
		</CSSTransition>
	);
};

Sidepane.displayName = 'Sidepane';

Sidepane.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.node,
	open: PropTypes.bool,
};

Sidepane.defaultProps = {
	open: true,
	className: null,
	onClose: undefined,
};

const SidepaneWrapper = React.memo((props) => {
	const isValidScreen = useMediaQuery('(max-width: 1280px)', true);
	const hasSidepaneAsModalFeature = useFeature('sidepane-as-modal');

	if (isValidScreen && !hasSidepaneAsModalFeature) {
		return <SidepanePortal {...props} />;
	}

	return <Modal {...props} />;
});

SidepaneWrapper.propTypes = {
	classNames: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.node,
	open: PropTypes.bool,
};

SidepaneWrapper.displayName = 'SidepaneWrapper';

export default SidepaneWrapper;

export { Sidepane };
