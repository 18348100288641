import React from 'react';

import PropTypes from 'prop-types';

import ProbabilityBar from '@asteria/component-core/probability';
import Tooltip from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const getToolTip = (item) => {
	const els = [];

	if (
		TranslationService.hasKey(`transaction.total.${item?.status}.tooltip`)
	) {
		if (item?.status !== 'FORECAST' || item?.oracle?.forecast?.risk !== 1) {
			els.push(
				<Text>
					{TranslationService.get(
						`transaction.total.${item?.status}.tooltip`,
						undefined,
						item,
					)}
				</Text>,
			);
		}
	}

	if (
		item?.oracle?.currency &&
		TranslationService.hasKey([
			`transaction.total.currency.risk.tooltip`,
			`transaction.total.${item?.status}.currency.risk.tooltip`,
		])
	) {
		els.push(
			<Text>
				{TranslationService.get(
					[
						`transaction.total.currency.risk.tooltip`,
						`transaction.total.${item?.status}.currency.risk.tooltip`,
					],
					undefined,
					item,
				)}
			</Text>,
		);
	}
	return els.length > 0 ? els : null;
};

const TotalCell = React.memo((props) => {
	const { className, data } = props;

	const tooltip = getToolTip(data);

	const isSecurityHidden = React.useMemo(
		() => !['FORECAST', 'UNPAID', 'SIGNED'].includes(data?.status),
		[data?.status],
	);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<Tooltip>
				<TextGroup>
					<Text>
						{TranslationService.get(
							[
								'transaction.total',
								`transaction.total.${data?.type}`,
							],
							'{{sums.display.total | number}} {{sums.display.currency}}',
							data,
						)}
					</Text>
					{data?.sums?.display?.currency !==
					data?.sums?.original?.currency ? (
						<Text size="sm">
							{TranslationService.get(
								[
									'transaction.total.foreign',
									`transaction.total.foreign.${data?.type}`,
								],
								'{{sums.original.total | number}} {{sums.original.currency}}',
								data,
							)}
						</Text>
					) : null}
				</TextGroup>
				{tooltip}
			</Tooltip>
			<ProbabilityBar
				probability={data?.oracle?.risk}
				tooltip
				hidden={isSecurityHidden}
			/>
		</div>
	);
});

TotalCell.displayName = 'TotalCell';

TotalCell.propTypes = { className: PropTypes.string, data: PropTypes.object };

export default TotalCell;
