import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableGroup from './TableGroup';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

import './styles.scss';

const Table = React.memo(
	React.forwardRef((props, ref) => {
		const { className, children, ...args } = props;

		return (
			<div
				className={cn('asteria-component__table', className)}
				ref={ref}
				{...args}
			>
				{children}
			</div>
		);
	}),
);

Table.displayName = 'Table';

Table.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Table;
export { TableCell, TableHeader, TableRow, TableFooter, TableGroup };
