import React from 'react';

import PropTypes from 'prop-types';

import { useDateVariant, useOnboardingState } from '../../hooks';

import Footer from './footer';

import './styles.scss';

/** @type { React.FC<Props> } */
const PromotionFooter = React.memo(function PromotionCard(props) {
	const {
		startDate,
		endDate,
		onAction,
		onSubmit,
		type,
		state,
		optional,
		required,
	} = props;

	const variant = useDateVariant({ startDate, endDate });

	const onboarding = useOnboardingState();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'card:back') {
				return onAction?.('card:promotion:back', data);
			}

			if (action === 'card:close') {
				return onAction?.('card:promotion:close', data);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	if (optional && required) {
		return null;
	}

	if (!state?.promotion?.visible?.footer) {
		return null;
	}

	return (
		<Footer
			dismiss={{
				postfix: {
					type: type,
					onboarding: onboarding,
					past: variant === 'past',
					today: variant === 'today',
					future: variant === 'future',
				},
				analyticsKey: `card.${type}.footer.action.dismiss`,
			}}
			connect={{
				postfix: {
					type: type,
					onboarding: onboarding,
					past: variant === 'past',
					today: variant === 'today',
					future: variant === 'future',
				},
				analyticsKey: `card.${type}.footer.action.connect`,
			}}
			onAction={handleAction}
			onSubmit={onSubmit}
			form={{
				type:
					onboarding === 'none'
						? null
						: onboarding === 'erp'
						? 'bank'
						: 'erp',
			}}
		/>
	);
});

PromotionFooter.displayName = 'PromotionFooter';

PromotionFooter.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	type: PropTypes.string,

	optional: PropTypes.bool,
	required: PropTypes.bool,

	state: PropTypes.shape({
		promotion: PropTypes.shape({
			visible: PropTypes.shape({
				footer: PropTypes.bool,
			}),
		}),
	}),
};

export default PromotionFooter;
