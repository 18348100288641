import React from 'react';

import PropTypes from 'prop-types';

import Step from '../step';

import './styles.scss';

const BasicStep = React.memo((props) => {
	const { onAction, onSubmit, data, step } = props;

	return (
		<Step
			onAction={onAction}
			onSubmit={onSubmit}
			data={data}
			path={`modals.auth.2fa.deactivate.step.${step}`}
		/>
	);
});

BasicStep.displayName = 'BasicStep';
BasicStep.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	data: PropTypes.object,
	step: PropTypes.number,
};

export default BasicStep;
