import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { TextGroup } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const IntegrationSplash = (props) => {
	const { className, direction = 'horizontal', type } = props;

	const config = useConfig('onboarding.layout')?.splash;

	const dispatch = useDispatch();

	const handleCreate = React.useCallback(
		() =>
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
					data: { type: 'erp' },
				}),
			),
		[dispatch],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'connect') {
				dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
						data: { type: data },
					}),
				);
			}
		},
		[dispatch],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	return (
		<div
			className={cn(
				'asteria-component__onboarding__landing-splash',
				{
					[`asteria-component__onboarding__landing-splash--direction-${direction}`]:
						direction,
					'asteria--state-custom': config?.[type]?.length,
					[`asteria--variant-${type}`]: type,
				},
				className,
			)}
		>
			{config?.[type]?.length ? (
				<>
					<div className="asteria-component__onboarding__landing-splash__content">
						<Contenter content={config?.[type]} actions={actions} />
					</div>
				</>
			) : (
				<>
					{config?.image ? (
						<Contenter
							content={[config?.image]}
							className="asteria-component__onboarding__landing-splash__image"
						/>
					) : null}

					<div className="asteria-component__onboarding__landing-splash__content">
						{config?.content?.length ? (
							<Contenter
								as={TextGroup}
								content={config?.content}
								actions={actions}
							/>
						) : null}

						<Button
							label={TranslationService.get([
								'onboarding.layout.action',
								'onboarding.layout.splash.action',
								`onboarding.layout.${type}.action`,
								`onboarding.layout.splash.${type}.action`,
							])}
							variant="secondary"
							onClick={handleCreate}
							analyticsKey="onboarding.layout.splash.action"
						/>
					</div>
				</>
			)}
		</div>
	);
};

IntegrationSplash.displayName = 'IntegrationSplash';

IntegrationSplash.propTypes = {
	className: PropTypes.string,
	direction: PropTypes.oneOf(['horizontal', 'vertical']),
	type: PropTypes.oneOf(['bank', 'erp']),
};

export default IntegrationSplash;
