import React from 'react';

import OverviewView from '@asteria/view-overview';

import { LayoutContext } from '../../context';

import './styles.scss';

const OverviewPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<OverviewView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

OverviewPage.displayName = 'OverviewPage';

OverviewPage.propTypes = {};

export default OverviewPage;
