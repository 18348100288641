import React from 'react';
import { useMemo } from 'react';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { FeatureFlag } from '../featureflag';

import ComponentService from './service';

// import { Provider } from './context';
import './index.scss';

const isAction = new RegExp('^on[A-Z]');

function formatChildren(value, options) {
	const { actions, data, translationData = {} } = options;

	if (Array.isArray(value) && value.some((object) => object.as)) {
		return value.map((object, index) =>
			formatChildren({ ...object, key: index }, options),
		);
	}

	if (value?.as) {
		return (
			<Component
				{...value}
				actions={actions}
				data={data}
				translationData={translationData}
			/>
		);
	}

	return TranslationService.getV2(value, {
		data: data,
		default: value,
		...translationData,
	});
}

function cleanValue($value, { key, actions, data, translationData }) {
	let value = $value;

	if (value?.__type === 'Contenter') {
		value = formatChildren(value, {
			actions: actions,
			data: data,
			translationData: translationData,
		});
	}

	if (value?.__type === 'Translation') {
		value = TranslationService.getV2(value?.value, {
			default: value?.default ?? value?.value,
			data: data,
			postfix: value?.postfix,
			prefix: value?.prefix,
			debug: value?.debug,
			...translationData,
		});
	}

	if (key === 'style') {
		return Object.fromEntries(
			Object.entries($value).map(([key, $value]) => [
				key,
				cleanValue($value, {
					key: key,
					actions: actions,
					data: data,
					translationData: translationData,
				}),
			]),
		);
	}

	return value;
}

const Component = React.memo(
	({ as = 'div', actions = {}, props = {}, data, translationData = {} }) => {
		const Comp = ComponentService.get(as) || as;

		const cleanProps = useMemo(() => {
			const clean = Object.fromEntries(
				Object.entries(props)
					.filter(([key]) => !key.startsWith('$'))
					.map(([key, $value]) => [
						key,
						cleanValue($value, {
							key: key,
							actions: actions,
							data: data,
							translationData: translationData,
						}),
					]),
			);

			if (as === 'Translation') {
				if (clean?.Component) {
					clean.Component =
						ComponentService.get(clean.Component) ||
						clean.Component;
				}
			}

			const componentActions = Object.keys(props).filter(
				(key) => isAction.test(key) && typeof clean[key] !== 'function',
			);

			for (const key of componentActions) {
				const available = []
					.concat(props?.[key])
					.filter(Boolean)
					.filter(({ action }) => !!actions?.[action]);

				if (available.length) {
					clean[key] = function (event) {
						for (const { action, args: $args } of available) {
							const trigger = actions[action];

							const args = []
								.concat($args)
								.filter(Boolean)
								.map((value) => {
									let clean = cleanValue(value, {
										actions: actions,
										data: data,
										translationData: translationData,
									});

									if (AsteriaCore.utils.isObject(clean)) {
										clean = Object.fromEntries(
											Object.entries(clean).map(
												([key, value]) => [
													key,
													value === '$$EVENT'
														? event
														: value,
												],
											),
										);
									}

									return clean;
								});

							trigger?.(...args);
						}
					};
				} else {
					delete clean[key];
				}
			}

			if (clean.label) {
				clean.label = TranslationService.getV2(clean.label, {
					data: data,
					default: clean.label,
					...translationData,
				});
			}

			if (clean.placeholder) {
				clean.placeholder = TranslationService.getV2(
					clean.placeholder,
					{
						data: data,
						default: clean.placeholder,
						...translationData,
					},
				);
			}

			if (clean.children) {
				clean.children = formatChildren(clean.children, {
					data: data,
					actions: actions,
					translationData: translationData,
				});
			}

			return clean;
		}, [props, as, actions, data, translationData]);

		if (
			props.$visible &&
			TranslationService.getV2(props.$visible, {
				data: data,
				default: '',
				...translationData,
			}).trim() !== 'true'
		) {
			return null;
		}

		if (props.$conditional) {
			for (const key in props.$conditional) {
				const value = props.$conditional[key];
				if (
					TranslationService.getV2(value, {
						data: data,
						default: '',
						...translationData,
					}).trim() === 'true'
				) {
					cleanProps[key] = true;
				}
			}
		}

		let result = (
			<Comp
				{...cleanProps}
				data={data}
				translationData={translationData}
			/>
		);

		if (props.$feature) {
			const features = [].concat(props.$feature);

			result = features.reduce(
				(acc, feature) => (
					<FeatureFlag
						feature={feature?.key ?? feature}
						invert={feature?.invert}
					>
						{acc}
					</FeatureFlag>
				),
				result,
			);
		}

		return result;
	},
);

Component.displayName = 'ContenterComponent';
Component.propTypes = {
	as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	actions: PropTypes.object,
	props: PropTypes.object,
	data: PropTypes.any,
	translationData: PropTypes.object,

	$visible: PropTypes.string,
	$conditional: PropTypes.object,
	$feature: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

const Wrapper = React.memo(function Wrapper({
	flat,
	children,
	as: As,
	className,
	extra,
}) {
	if (flat) {
		return children;
	}

	return (
		<As className={cn('asteria-component__contenter', className)}>
			<div className="asteria-component__contenter__inner">
				{children}
			</div>
			{extra}
		</As>
	);
});

Wrapper.propTypes = {
	flat: PropTypes.bool,
	children: PropTypes.node,
	as: PropTypes.string,
	className: PropTypes.string,
	extra: PropTypes.node,
};

const Contenter = (props) => {
	const {
		className,
		as,
		actions,
		data,
		translationData = {},
		content = [],
		children,
		flat,
	} = props;

	const contentActions = useMemo(() => {
		return actions || {};
	}, [actions]);

	if (!content?.length) {
		return null;
	}

	return (
		<Wrapper
			className={className}
			flat={flat}
			extra={children}
			as={as || 'div'}
		>
			{content?.map((comp, index) => (
				<Component
					key={comp.id ?? index}
					{...comp}
					actions={contentActions}
					data={data}
					translationData={translationData}
				/>
			))}
		</Wrapper>
	);
};

Contenter.propTypes = {
	className: PropTypes.string,
	as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	actions: PropTypes.object,
	data: PropTypes.any,
	translationData: PropTypes.object,
	content: PropTypes.arrayOf(
		PropTypes.shape({ as: PropTypes.string, props: PropTypes.object }),
	),
	children: PropTypes.node,
	flat: PropTypes.bool,
};

Contenter.defaultProps = {
	className: null,
};

Contenter.displayName = 'Contenter';

ComponentService.register('Contenter', Contenter, {
	className: { type: 'string' },
	as: { type: 'component' },
	actions: { type: 'object' },
	data: { type: 'object' },
	content: {
		type: 'array',
		of: {
			type: 'object',
			options: { as: { type: 'string' }, props: { type: 'object' } },
		},
	},
	children: { type: 'node' },
});

export default React.memo(Contenter);
