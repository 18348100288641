import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Title, { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

const ClearScenarioModal = (props) => {
	const { open, onClose, onAction, data } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);
	const isUpdating = useSelector(ScenarioStore.selectors.isUpdating);

	const handleSubmit = useCallback(() => {
		Analytics.event('forecaster.scenario.clear.submit', data);

		onAction?.('scenario:clear', data);
	}, [data, onAction]);

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			size="sm"
			className={cn('asteria--type-scenario', 'asteria--action-clear')}
		>
			<Wrapper>
				<Header onClose={handleClose}>
					{TranslationService.get('forecaster.modal.clear.title')}
				</Header>
				<Content>
					<FormWrapper>
						<Content>
							<TextGroup>
								<Title type="title" size="xs">
									{TranslationService.get(
										'forecaster.modal.clear.body.label',
									)}
								</Title>
								<Text>
									{TranslationService.get(
										'forecaster.modal.clear.body',
										undefined,
										data,
									)}
								</Text>
							</TextGroup>
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection>
						{hasHistory ? (
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.back',
									'forecaster.modal.clear.button.back',
								])}
								onClick={handleClose}
								analyticsKey="forecaster.modal.clear.back"
							/>
						) : (
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.cancel',
									'forecaster.modal.clear.button.cancel',
								])}
								onClick={handleClose}
								analyticsKey="forecaster.modal.clear.cancel"
							/>
						)}
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.clear',
								'forecaster.modal.clear.button.clear',
							])}
							onClick={handleSubmit}
							analyticsKey="forecaster.modal.clear.save"
							loading={isUpdating}
							disabled={isUpdating}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Modal>
	);
};

ClearScenarioModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
};

export default ClearScenarioModal;
