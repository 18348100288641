import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import Select, { Option } from '@asteria/component-form/select';

import { TranslationService } from '@asteria/language';

const selectors = {
	currencies: createSelector(
		(store) => store?.app?.settings?.currencies,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const CurrencySelect = (props) => {
	const { prefix } = props;
	const currencies = useSelector(selectors.currencies);

	const search = React.useMemo(
		() => ({
			toggle: true,
			path: 'value',
			placeholder: 'settings.currency.search.placeholder',
		}),
		[],
	);

	return (
		<Select
			name={[prefix, 'settings.currency'].filter(Boolean).join('.')}
			scroll
			search={search}
		>
			{currencies.map((code) => (
				<Option key={code} value={code}>
					<Chip flag={code} label={code} variant="simple" />
				</Option>
			))}
		</Select>
	);
};

CurrencySelect.displayName = 'CurrencySelect';

CurrencySelect.propTypes = { prefix: PropTypes.string };

const CurrencySelector = (props) => {
	const { prefix } = props;

	return (
		<Group className="asteria-component__settings-section__currency">
			{TranslationService.get(['settings.currency']) ? (
				<Title size="xs">
					{TranslationService.get(['settings.currency'])}
				</Title>
			) : null}

			<CurrencySelect prefix={prefix} />
		</Group>
	);
};

CurrencySelector.displayName = 'CurrencySelector';

CurrencySelector.propTypes = { prefix: PropTypes.string };

export default CurrencySelector;
