import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { cn } from '@asteria/utils-funcs/classes';

import SimulationList from '../../../../components/list';
import { useModalActions } from '../../../../components/modal-collection';
import { CreditSettingsModal } from '../../../../modals';

import '../../styles.scss';

const SectionOverdraft = React.memo((props) => {
	const { onAction } = props;

	const { open } = useModalActions();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'simulation:create') {
				return onAction?.('redirect', '/financial/credit');
			}

			if (action === 'simulation:edit') {
				const ID = data?._id ?? data?.id;

				open(<CreditSettingsModal />, { simulationId: ID });

				return;
			}

			if (action === 'simulation:view') {
				const ID = data?._id ?? data?.id;

				return onAction?.('redirect', `/financial/credit/${ID}`);
			}

			return onAction?.(action, data);
		},
		[onAction, open],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	const columns = React.useMemo(
		() => [
			{ key: 'name' },
			{ key: 'total', align: 'right' },
			{ key: 'actions', align: 'right' },
		],
		[],
	);

	const rows = useSelector((store) =>
		ScenarioStore.selectors.scenarios(store, { type: 'CREDIT' }),
	);

	if (rows.length) {
		return (
			<SimulationList
				onAction={handleAction}
				type="overdraft"
				columns={columns}
				rows={rows}
			/>
		);
	}

	return (
		<Teaser
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-overdraft',
			)}
			path="pages.financial.teaser.home.overdraft"
			actions={actions}
			onAction={handleAction}
		/>
	);
});

SectionOverdraft.displayName = 'SectionCredit';
SectionOverdraft.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionOverdraft;
