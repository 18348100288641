import React from 'react';

import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';
import useColors from '@asteria/utils-hooks/useColors';

import { useCategoryColors } from '../../../../../hooks';
import { Bar as BaseBar } from '../../../../bar';
import BarAction from '../bar-action';

import '../../styles.scss';

const View = React.memo((props) => {
	const {
		className,
		type,
		date,
		status,
		category,
		tag,

		tooltip,

		value,
		onChange,

		hidden,
		invisible,

		render,
	} = props;

	const fmtDate =
		typeof date !== 'string'
			? formatISO(date, { representation: 'date' })
			: date;

	const colors = useCategoryColors({ type, category, tag });
	const { typed, custom } = useColors(colors);

	if (render) {
		return render({
			type,
			date,
			status,
			category,
			tag,
			tooltip,
			value,
			onChange,
			hidden,
			invisible,
			colors,
		});
	}

	return (
		<BaseBar
			className={cn(
				[]
					.concat(status !== 'HISTORY' ? typed : [])
					.concat(`asteria-color__${status.toLowerCase()}`),
				{
					'asteria--state-hidden': hidden && (invisible ?? true),
					'asteria--state-invisible': invisible,
				},
				className,
			)}
			colors={status !== 'HISTORY' ? custom : []}
			value={value}
			editable={status === 'FORECAST' && value}
			onChange={onChange}
			data-date={fmtDate}
			data-status={status}
			tooltip={tooltip}
			type={type}
		>
			{status === 'FORECAST' && !value ? <BarAction type={type} /> : null}
		</BaseBar>
	);
});

View.displayName = 'Bar';
View.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,

	type: PropTypes.string,
	date: PropTypes.string,
	status: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	tooltip: PropTypes.object,

	value: PropTypes.number,
	onChange: PropTypes.func,

	hidden: PropTypes.bool,
	invisible: PropTypes.bool,

	render: PropTypes.func,
};

export default View;
