import React from 'react';

import { useFormContext } from 'react-hook-form';

import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Datepicker from '../../';
import Form, {
	Group as FormGroup,
	Wrapper as FormWrapper,
	useFormValues,
} from '../../../';
import Select, { Option } from '../../../select';
import { cleanValue, getLocale } from '../utils';

import './styles.scss';

const RepeatableModalFormDates = (props) => {
	const { locale } = props;

	const currentLocale = getLocale(locale);

	const days = useFormValues({ name: 'day' }) ?? [];

	const { getValues, setValue } = useFormContext();

	const handleChangeDay = React.useCallback(
		(value) => {
			let dates = getValues('day') ?? [];

			if (dates.includes(value)) {
				dates = dates.filter((date) => date !== value);
			} else {
				dates = dates.concat(value);
			}

			setValue('day', dates);
		},
		[getValues, setValue],
	);

	return (
		<FormGroup
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="space-between"
		>
			{eachDayOfInterval({
				start: startOfWeek(new Date(), { locale: currentLocale }),
				end: endOfWeek(new Date(), { locale: currentLocale }),
			}).map((date) => {
				const fmt = cleanValue(
					format(date, 'yyyy-MM-dd', {
						locale: currentLocale,
					}),
				);

				const value = cleanValue(
					// eslint-disable-next-line spellcheck/spell-checker
					format(date, 'eee'),
				);

				const label = cleanValue(
					// eslint-disable-next-line spellcheck/spell-checker
					format(date, 'eeeee', { locale: currentLocale }),
				);

				return (
					<div
						key={fmt}
						className={cn(
							'asteria-component__datepicker-repeatable__day',
							stateClasses({ active: days.includes(value) }),
						)}
						onClick={() => handleChangeDay(value)}
					>
						<Text>{label}</Text>
					</div>
				);
			})}
		</FormGroup>
	);
};

RepeatableModalFormDates.displayName = 'RepeatableModalFormDates';

RepeatableModalFormDates.propTypes = {
	locale: PropTypes.string,
};

const RepeatableModalForm = (props) => {
	const { locale } = props;

	return (
		<Content>
			<FormGroup>
				<Datepicker
					parent="modal"
					name="startDate"
					label={TranslationService.get([
						'datepicker.modal.repeatable.startDate.label',
					])}
					required
				/>
				<FormGroup direction="horizontal" verticalAlign="center">
					<Select
						name="every"
						label={TranslationService.get([
							'datepicker.modal.repeatable.every.label',
						])}
						scroll
					>
						{new Array(100).fill().map((_, index) => (
							<Option key={index} value={index + 1}>
								{TranslationService.get(
									['datepicker.modal.repeatable.every.value'],
									index + 1,
									{ value: index + 1 },
								)}
							</Option>
						))}
					</Select>
					<Select
						name="type"
						label={TranslationService.get([
							'datepicker.modal.repeatable.type.label',
						])}
						scroll
					>
						<Option value="never">
							{TranslationService.get([
								'datepicker.repeatable.value.never',
								'datepicker.modal.repeatable.type.value.never',
							])}
						</Option>
						<Option value="weekly">
							{TranslationService.get([
								'datepicker.repeatable.value.weekly',
								'datepicker.modal.repeatable.type.value.weekly',
							])}
						</Option>
						<Option value="monthly">
							{TranslationService.get([
								'datepicker.repeatable.value.monthly',
								'datepicker.modal.repeatable.type.value.monthly',
							])}
						</Option>
						<Option value="quarterly">
							{TranslationService.get([
								'datepicker.repeatable.value.quarterly',
								'datepicker.modal.repeatable.type.value.quarterly',
							])}
						</Option>
					</Select>
				</FormGroup>
				<RepeatableModalFormDates locale={locale} />
				<Datepicker
					parent="modal"
					name="endDate"
					label={TranslationService.get([
						'datepicker.modal.repeatable.endDate.label',
					])}
				/>
			</FormGroup>
		</Content>
	);
};

RepeatableModalForm.displayName = 'RepeatableModalForm';

RepeatableModalForm.propTypes = {
	locale: PropTypes.string,
};

const RepeatableModalContent = (props) => {
	const { onClose, onSubmit, defaultValues, locale } = props;

	return (
		<Form onSubmit={onSubmit} defaultValues={defaultValues}>
			<Wrapper scroll>
				<Header onClose={onClose} verticalAlign="center">
					{TranslationService.get([
						'datepicker.modal.repeatable.title',
					])}
				</Header>
				<Content scroll>
					<FormWrapper>
						<RepeatableModalForm locale={locale} />
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
								'datepicker.modal.repeatable.close',
							])}
							analyticsKey="datepicker.modal.repeatable.close"
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.submit',
								'action.submit',
								'datepicker.modal.repeatable.submit',
							])}
							analyticsKey="datepicker.modal.repeatable.submit"
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
};

RepeatableModalContent.displayName = 'RepeatableModalContent';

RepeatableModalContent.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	defaultValues: PropTypes.object,
	locale: PropTypes.string,
};

const RepeatableModal = (props) => {
	const { className, open, onClose, onSubmit, defaultValues, locale } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__datepicker-repeatable',
				className,
			)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<RepeatableModalContent
				onClose={onClose}
				onSubmit={onSubmit}
				defaultValues={defaultValues}
				locale={locale}
			/>
		</Modal>
	);
};

RepeatableModal.displayName = 'RepeatableModal';

RepeatableModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,

	defaultValues: PropTypes.object,
	locale: PropTypes.string,
};

export default RepeatableModal;
export { RepeatableModalContent };
