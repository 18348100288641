import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import { useDebounceFormValues } from '@asteria/component-form';

const AutoSave = React.memo((props) => {
	const { onAction } = props;

	const form = useDebounceFormValues({ delay: 1000 });

	const mutation = useMutation({
		mutationFn: async ({ form }) => {
			try {
				await onAction?.('form:save', { form: cloneDeep(form) });
			} catch (err) {
				// eslint-disable-next-line no-console
				console.warn('card:save', err);
			}
		},
	});

	React.useEffect(() => {
		mutation.mutate({ form });
	}, [form]);

	return null;
});

AutoSave.displayName = 'AutoSave';
AutoSave.propTypes = { onAction: PropTypes.func, variant: PropTypes.string };

export default AutoSave;
