import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import Legends from '@asteria/component-graph/legends';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import SettingsButton from '../../components/button/settings';
import LineGraph from '../../components/graph/lines';
import { useLegends } from '../../components/graph/lines/hooks';
import { useGraphRange } from '../../hooks';

import './styles.scss';

const SectionGraph = React.memo((props) => {
	const { onAction } = props;

	const dates = useGraphRange();

	const onGraphBack = React.useCallback(
		() => onAction?.('forecaster:graph:previous'),
		[onAction],
	);

	const onGraphNext = React.useCallback(
		() => onAction?.('forecaster:graph:next'),
		[onAction],
	);

	const legend = useLegends({ dates: dates });

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-graph',
			)}
		>
			<Group
				direction="horizontal"
				verticalAlign="top"
				horizontalAlign="space-between"
			>
				<div>
					<div className="forecaster__layout__header">
						<Title size="xs">
							{TranslationService.get(
								'forecaster.graph.line.title',
							)}
						</Title>
					</div>
					<Legends data={legend} />
				</div>
				<SettingsButton />
			</Group>

			<Group
				className="asteria-component__forecaster-page-section-content"
				verticalAlign="center"
				direction="horizontal"
				horizontalAlign="space-between"
			>
				<Button icon="chevron-left" size="sm" onClick={onGraphBack} />
				<LineGraph dates={dates} />
				<Button icon="chevron-right" size="sm" onClick={onGraphNext} />
			</Group>
		</div>
	);
});

SectionGraph.displayName = 'SectionGraph';
SectionGraph.propTypes = { onAction: PropTypes.func };

export default SectionGraph;
