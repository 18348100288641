import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

import * as common from './common';

/* START: Settings selectors */

export const settings = createSelector(
	(store) => store?.app?.user?.settings,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const layout = createSelector(settings, (value) => value?.layout ?? {}, {
	memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) },
});

export const graphLayout = createSelector(
	layout,
	(store, options) => options?.mode ?? common.mode(store),
	(layout, mode) => {
		if (mode === 'credit') {
			return 'grouped';
		}

		return layout?.graph?.layout;
	},
);

/* END: Settings selectors */
