import parseName from '../getFormPaths';

/**
 * @template TForm
 * @param {{ form: TForm, type: string, category: string, tag: string }} options
 * @returns {{ op: string, name: string, value: unknown }[]}
 */
function reset(options) {
	const { form, type, category, tag } = options;

	const changes = [...(form?.$changes ?? [])];

	const uuid = changes
		.filter(({ name, value, previousValue }) => {
			const parsed = parseName(name);

			return (
				(name.endsWith('.value') || name.endsWith('.total')) &&
				parsed.type === type &&
				parsed.category === category &&
				parsed.tag === tag &&
				(previousValue ?? 0) < value
			);
		})
		.slice(-1)?.[0]?.uuid;

	if (!uuid) {
		return changes.reverse();
	}

	const available = changes.slice(
		changes.findIndex((object) => object?.uuid === uuid),
	);

	const startIndex = available.findIndex(
		({ $event }) => $event === 'type:recalculate:value',
	);

	return available.slice(startIndex + 1).reverse();
}

export default reset;
