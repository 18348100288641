import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { isLoading as isIntegrationLoading } from '../../integrations/selectors';

export const isLoading = createSelector(
	isIntegrationLoading,
	(state) => state?.forecaster?.isLoading ?? true,
	(isIntegrationLoading, isForecasterLoading) =>
		isIntegrationLoading || isForecasterLoading,
);

export const isSaving = createSelector(
	(state) => state?.forecaster?.isSaving,
	(value) => value ?? false,
);

export const lastSaveAt = createSelector(
	(state) => state?.forecaster?.lastSaveAt,
	(value) => value ?? null,
);

export const shouldShowFeedback = createSelector(
	(state) => state?.forecaster?.isLoading ?? true,
	(state) => state?.forecaster?.navigation?.type ?? null,
	(isLoading, type) => !isLoading && type === 'overview',
);

export const shouldShowFooter = createSelector(
	isLoading,
	(state) => state?.forecaster?.navigation?.type ?? null,
	(isLoading, type) => !isLoading && type !== 'overview',
);

export const hasHistory = createSelector(
	(state) => state?.forecaster?.history ?? [],
	(history) => history.length > 0,
);

export const completedScenarioIsSaving = createSelector(
	(state) => state?.forecaster?.flags?.completedScenarioIsSaving,
	(value) => value ?? false,
);

export const action = createSelector(
	(state) => state?.forecaster?.action,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const fullScreen = createSelector(
	(state) => state?.forecaster?.flags?.fullScreen,
	(value) => value ?? false,
);

export const transactions = createSelector(
	(state) => state?.forecaster?.flags?.transactions,
	(value) => value ?? false,
);
