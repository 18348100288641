import { AuthService, SupportService } from '@asteria/backend-utils-services';

import * as UserAPI from './user';

const DEFAULT_MUTATION_AUTH_FIELDS = `
    ok
    error
    data {
        userId
        accessToken
        refreshToken
        accessTokenExpires
        refreshTokenExpires
        disabled
        revoked
				hasTwoFactor
    }
		companies {
			_id
			name
		}
`;

export async function login({
	username,
	password,
	partnerId,
	code,
	companyId,
}) {
	return AuthService.auth.login({
		useTokenFromContext: false,
		username: username,
		password: password,
		partnerId: partnerId,
		code: code,
		companyId: companyId,
		fields: DEFAULT_MUTATION_AUTH_FIELDS,
	});
}

export async function signup({ user, company, partnerId, signupKey }) {
	return AuthService.auth.signup({
		useTokenFromContext: false,
		user: user,
		company: company,
		partnerId: partnerId,
		signupKey: signupKey,
	});
}

export async function validateSignupKey({ key, partnerId }) {
	return AuthService.auth.validateSignupKey({
		useTokenFromContext: false,
		key: key,
		partnerId: partnerId,
	});
}

export async function forgotPassword({ username, partnerId, theme }) {
	return AuthService.auth.forgotPassword({
		useTokenFromContext: false,
		username: username,
		partnerId: partnerId,
		theme: theme,
	});
}

export async function resetPassword({ password, secret, partnerId }) {
	return AuthService.auth.resetPassword({
		useTokenFromContext: false,
		password: password,
		secret: secret,
		partnerId: partnerId,
		fields: `
		ok
		data {
			accessToken
			refreshToken
			hasTwoFactor
		}
		user {
			_id
			username
			rawCompanies
			companies {
				edges {
					node {
						_id
						name
						orgnumber
					}
				}
			}
		}
		`,
	});
}

export async function requestSignupKey({ input }) {
	return SupportService.issue.requestSignupKey({
		useTokenFromContext: false,
		input: input,
	});
}

export async function register2FA({ accessToken, dispatch }) {
	const response = await AuthService.auth.register2FA(
		{ fields: `url codes secret` },
		{ token: accessToken },
	);

	await UserAPI.me({ accessToken: accessToken, dispatch: dispatch });

	return response;
}

export async function validate2FA({ code, accessToken, dispatch }) {
	const response = await AuthService.auth.validate2FA(
		{ code: code },
		{ token: accessToken },
	);

	await UserAPI.me({ accessToken: accessToken, dispatch: dispatch });

	return response;
}

export async function refresh2FA({ accessToken }) {
	return AuthService.auth.refresh2FA(
		{ fields: `url codes secret` },
		{ token: accessToken },
	);
}

export async function fetch2FA({ accessToken }) {
	return AuthService.auth.fetch2FA(
		{ fields: `url codes secret` },
		{ token: accessToken },
	);
}

export async function deactivate2FA({ accessToken, dispatch }) {
	const response = await AuthService.auth.deactivate2FA(
		{},
		{ token: accessToken },
	);

	await UserAPI.me({ accessToken: accessToken, dispatch: dispatch });

	return response;
}

export async function changeProfile({ accessToken, dispatch, companyId }) {
	const response = await AuthService.auth.changeProfile(
		{ input: { companyId: companyId } },
		{ token: accessToken },
	);

	await UserAPI.me({
		accessToken: response?.data?.accessToken,
		dispatch: dispatch,
	});

	return response?.data;
}
