import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

const DEFAULT_ROLE = 'LIMITED_VIEW';

const selectors = {
	roles: createSelector(
		(store) => store?.app?.user?.roles,
		(value) => value?.[0] ?? DEFAULT_ROLE,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

/**
 * @returns { string }
 */
const useRole = () => {
	return useSelector(selectors.roles);
};

export default useRole;
