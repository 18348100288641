import React from 'react';

import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { BarGroup } from '../../../../bar';
import Bar from '../bar';

import '../../styles.scss';

const View = React.memo((props) => {
	const {
		onAction,
		type,
		date,
		category,
		tag,

		statuses,
		value,

		render,
		renderItem,
	} = props;

	const fmtDate =
		typeof date !== 'string'
			? formatISO(date, { representation: 'date' })
			: date;

	const style = React.useMemo(() => ({ '--overall': value }), [value]);

	return (
		<BarGroup
			style={style}
			data-date={fmtDate}
			className={cn({
				'asteria--type-deposit': type === 'deposit',
				'asteria--type-withdraw': type == 'withdraw',
			})}
		>
			{render
				? null
				: (statuses ?? []).map((status) =>
						renderItem ? (
							renderItem({
								onAction,
								type,
								date,
								status,
								category,
								tag,
							})
						) : (
							<Bar
								key={status}
								onAction={onAction}
								type={type}
								date={date}
								status={status}
								category={category}
								tag={tag}
							/>
						),
				  )}
		</BarGroup>
	);
});

View.displayName = 'TypedBarGroup';
View.propTypes = {
	onAction: PropTypes.func,
	type: PropTypes.string,
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	statuses: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.number,

	render: PropTypes.func,
	renderItem: PropTypes.func,
};

export default View;
