import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Feedback from '@asteria/component-feedback';
import Sidepane from '@asteria/component-sidepane';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TransactionDetails from '..';

const TransactionDetailsSidebarContent = React.memo((props) => {
	const { onAction, _id } = props;

	const shouldShowPrint = useFeature('transaction-details-sidebar-print');

	const object = useSelector((store) =>
		TransactionStore.selectors.item(store, _id),
	);

	const handlePrint = React.useCallback(
		() => onAction?.('transactions:print', _id),
		[_id, onAction],
	);

	const handleFeedbackAction = React.useCallback(
		(action, data) =>
			onAction?.('transactions:feedback:submit', {
				action: action,
				data: data,
			}),
		[onAction],
	);

	const handleFeedbackContact = React.useCallback(() => {
		onAction?.('transactions:feedback:support');
	}, [onAction]);

	return (
		<Content
			scroll
			className="asteria-component__transactions__details__sidebar__content"
		>
			<div className="asteria-component__transactions__details__sidebar__content__overview">
				<Text size="sm">
					{TranslationService.get(
						['transaction.details.sidebar.content.overview.label'],
						undefined,
						{ data: object },
					)}
				</Text>
				<Title size="base">
					{TranslationService.get(
						[
							'transaction.details.sidebar.content.overview.amount',
							`transaction.details.sidebar.content.overview.amount.${object?.type}`,
						],
						'{{ data.sums.display.total | number }} {{ data.sums.display.currency }}',
						{ data: object },
					)}
				</Title>
				<Text size="sm">
					{TranslationService.get(
						['transaction.details.sidebar.content.overview.date'],
						undefined,
						{ data: object },
					)}
				</Text>
			</div>
			{shouldShowPrint ? (
				<div className="asteria-component__transactions__details__sidebar__content__print">
					<Button
						icon="date"
						label={TranslationService.get(
							[
								'transaction.details.sidebar.content.print',
								`transaction.details.sidebar.content.print.${object?.type}`,
							],
							undefined,
							{ data: object },
						)}
						variant="link"
						onClick={handlePrint}
					/>
				</div>
			) : null}

			<TransactionDetails
				className="asteria-component__transactions__details__sidebar__content__info"
				_id={_id}
				onAction={onAction}
				direction="vertical"
			/>
			<div className="asteria-component__transaction-details__feedback-wrapper">
				<Feedback
					onAction={handleFeedbackAction}
					className="asteria-component__transactions__details__sidebar__content__feedback"
					onSupportRequest={handleFeedbackContact}
					feedbackKey="transaction-details-feedback"
					description={TranslationService.get(
						'transaction.details.sidebar.feedback.description',
					)}
					minText={TranslationService.get(
						'transaction.details.sidebar.feedback.min',
					)}
					maxText={TranslationService.get(
						'transaction.details.sidebar.feedback.max',
					)}
					values={[1, 2, 3, 4, 5]}
					validate
					type="transactions"
				/>
			</div>
		</Content>
	);
});

TransactionDetailsSidebarContent.displayName =
	'TransactionDetailsSidebarContent';

TransactionDetailsSidebarContent.propTypes = {
	onAction: PropTypes.func,
	_id: PropTypes.string,
};

const TransactionDetailsSidebar = (props) => {
	const { open, onClose, className, onAction } = props;

	const ID = useSelector(TransactionStore.selectors.active);

	if (!ID) {
		return null;
	}

	return (
		<Sidepane
			open={open}
			onClose={onClose}
			className={cn(
				'asteria-component__transaction-details__wrapper',
				className,
			)}
			scroll
			size="sm"
		>
			<Wrapper scroll>
				<Header onClose={onClose}>
					<Title type="title" size="sm">
						{TranslationService.get([
							'transaction.details.sidebar.header',
						])}
					</Title>
				</Header>
				<TransactionDetailsSidebarContent
					_id={ID}
					onAction={onAction}
				/>
			</Wrapper>
		</Sidepane>
	);
};

TransactionDetailsSidebar.displayName = 'TransactionDetailsSidebar';

TransactionDetailsSidebar.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default React.memo(TransactionDetailsSidebar);
