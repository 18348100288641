import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'>> } */
const Performance = React.memo(function Performance({ translationOptions }) {
	const diff = translationOptions?.data?.diff?.value;

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="card.content.extra.tooltip"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'value-type': 'performance',
						},
					}}
					Component={Text}
				/>
			}
		>
			<TextGroup>
				<Translation
					translationKey="card.content.extra.value"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'value-type': 'performance',
							value: 'amount',
						},
					}}
					Component={Text}
					align="right"
					size="sm"
				/>
				{!!diff?.percentage && Number.isFinite(diff?.percentage) ? (
					<Translation
						translationKey="card.content.extra.value"
						translationOptions={{
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								'value-type': 'performance',
								value: 'percentage',
							},
						}}
						Component={Text}
						align="right"
						size="xs"
					/>
				) : null}
			</TextGroup>
		</TooltipWrapper>
	);
});

Performance.propTypes = {
	translationOptions: PropTypes.object,
};

export default Performance;
