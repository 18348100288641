import React from 'react';

import { useDispatch } from 'react-redux';
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import Modal from '@asteria/component-modal';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import './styles.scss';

const EXCLUDE = ['selector', 'options'];

const Drag = React.memo(() => (
	<Button
		variant="column-drag"
		icon="drag"
		size="sm"
		className="asteria-component__transactions__columns__list-item__drag"
	/>
));

Drag.displayName = 'Drag';
Drag.propTypes = {};

const SortableDrag = SortableHandle(Drag);

const Column = React.memo((props) => {
	const { isDisabled, isActive, value, isDraggable, onClick } = props;

	const handleClick = React.useCallback(
		() => onClick?.(value),
		[onClick, value],
	);

	return (
		<div
			className={cn(
				'asteria-component__transactions__columns__list-item',
				stateClasses({ disabled: isDisabled }),
			)}
		>
			<Button
				icon={isActive ? 'remove' : 'add'}
				disabled={isDisabled}
				variant="link"
				className="asteria-component__transactions__columns__list-item__handler"
				onClick={handleClick}
			/>
			<Text size="sm">
				{TranslationService.get(`list.column.${value}`, value)}
			</Text>
			{isDraggable ? <SortableDrag /> : null}
		</div>
	);
});

Column.displayName = 'Column';

Column.propTypes = {
	isDisabled: PropTypes.bool,
	isActive: PropTypes.bool,
	isDraggable: PropTypes.bool,

	value: PropTypes.string,
	onClick: PropTypes.func,
};

const SortableColumn = SortableElement(Column);

const ColumnList = React.memo((props) => {
	const { children } = props;

	return (
		<div className="asteria-component__transactions__columns__list">
			{children}
		</div>
	);
});

ColumnList.displayName = 'ColumnList';
ColumnList.propTypes = { children: PropTypes.node };

const SortableColumnList = SortableContainer(ColumnList);

const TransactionSettings = React.memo((props) => {
	const { onClose, onAction, compact } = props;

	const { available, visible } = useDeepMemo(
		() => ({
			available: (props?.columns?.available ?? []).filter(
				(key) => !EXCLUDE.includes(key),
			),
			visible: (props?.columns?.visible ?? []).filter(
				(key) => !EXCLUDE.includes(key),
			),
		}),
		[props?.columns],
	);

	const dispatch = useDispatch();

	const [columns, setColumns] = React.useState(visible);

	React.useEffect(() => {
		setColumns(visible);
	}, [visible]);

	const handleSubmit = React.useCallback(
		({ compressList }) => {
			dispatch(
				AppStore.setUserSettingsPartial({
					listColumns: columns,
					compressList: compressList,
				}),
			);
			onAction?.('updateUserSettings', {
				listColumns: columns,
				compressList: compressList,
			});
			return onClose?.();
		},
		[columns, dispatch, onAction, onClose],
	);

	const handleAdd = React.useCallback((column) => {
		setColumns((columns) => columns.concat(column));
	}, []);

	const handleUpdate = React.useCallback(({ oldIndex, newIndex }) => {
		setColumns((columns) => {
			const items = [...columns];
			const [item] = items.splice(oldIndex, 1);
			items.splice(newIndex, 0, item);

			return items;
		});
	}, []);

	const handleRemove = React.useCallback((column) => {
		setColumns((columns) => columns.filter((value) => value !== column));
	}, []);

	const hidden = available.filter((column) => !columns.includes(column));

	const defaultValues = React.useMemo(
		() => ({ compressList: compact }),
		[compact],
	);

	const analytics = React.useMemo(
		() => ({ data: { listColumns: columns, compressList: compact } }),
		[columns, compact],
	);

	return (
		<Form onSubmit={handleSubmit} defaultValues={defaultValues}>
			<Wrapper className="asteria-component__transactions__columns">
				<Header onClose={onClose} verticalAlign="center">
					{TranslationService.get(
						['transaction.list.settings.title'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Inställningar för transaktionslistan',
					)}
				</Header>
				<Content scroll>
					<Group>
						<Title size="xxs">
							{TranslationService.get(
								['transaction.list.settings.content.title'],
								// eslint-disable-next-line spellcheck/spell-checker
								'Transaktionslista',
							)}
						</Title>
						<Checkbox
							label={TranslationService.get(
								['transaction.list.settings.compress.label'],
								// eslint-disable-next-line spellcheck/spell-checker
								'Visa en mer kompakt vy för att kunna se mer detaljer eller fler transaktioner åt gången',
							)}
							iconPosition="first"
							name="compressList"
						/>
					</Group>

					<Group>
						<Title size="xxs">
							{TranslationService.get('list.settings.title1')}
						</Title>
						<Text type="text" size="sm">
							{TranslationService.get('list.settings.text')}
						</Text>
					</Group>

					<Group>
						<Group
							direction="horizontal"
							verticalAlign="center"
							horizontalAlign="space-between"
						>
							<Title size="xxs">
								{TranslationService.get(
									'list.settings.title.active.columns',
									null,
									{ columns: columns.length },
								)}
							</Title>
							<Text size="sm">
								{TranslationService.get(
									'list.settings.text.active.columns',
									'',
									{ columns: columns.length },
								)}
							</Text>
						</Group>

						<SortableColumnList
							shouldCancelStart={() => {}}
							onSortEnd={handleUpdate}
							useDragHandle
							axis="y"
							lockAxis="y"
							helperClass="sortableHelper asteria-component__level"
							helperContainer={document.getElementById(
								'asteria-widget',
							)}
						>
							{columns.map((value, index) => (
								<SortableColumn
									key={['visible', value, index].join('-')}
									index={index}
									value={value}
									onClick={handleRemove}
									isActive
									isDraggable
									isDisabled={columns.length <= 3}
								/>
							))}
						</SortableColumnList>
					</Group>
					<Group>
						<Title size="xxs">
							{TranslationService.get(
								'list.settings.title.columns',
							)}
						</Title>
						<SortableColumnList
							shouldCancelStart={() => {}}
							onSortEnd={handleUpdate}
							useDragHandle
							axis="y"
							lockAxis="y"
							helperContainer={document.getElementById(
								'asteria-widget',
							)}
						>
							{hidden.length ? (
								hidden.map((value, index) => (
									<SortableColumn
										key={['hidden', value, index].join('-')}
										index={index}
										value={value}
										onClick={handleAdd}
									/>
								))
							) : (
								<Text size="sm">
									{TranslationService.get(
										'list.settings.not.available.columns',
									)}
								</Text>
							)}
						</SortableColumnList>
					</Group>
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get('action.abort')}
							onClick={onClose}
							analyticsKey="list.settings.cancel"
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get('action.save')}
							type="submit"
							analyticsKey="list.settings.submit"
							analytics={analytics}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
});

TransactionSettings.displayName = 'TransactionSettingsWrapper';

TransactionSettings.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,

	compact: PropTypes.bool,
	columns: PropTypes.shape({
		visible: PropTypes.arrayOf(PropTypes.string),
		available: PropTypes.arrayOf(PropTypes.string),
	}),
};

const TransactionSettingsModal = React.memo((props) => {
	const { className, open, onClose, onAction, columns, compact } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__transactions__modal',
				'asteria-component__transactions__modal--columns',
				className,
			)}
			open={open}
			size="sm"
			closeOnEscape
			onClose={onClose}
		>
			<TransactionSettings
				onClose={onClose}
				onAction={onAction}
				columns={columns}
				compact={compact}
			/>
		</Modal>
	);
});

TransactionSettingsModal.displayName = 'TransactionSettingsModal';

TransactionSettingsModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,

	compact: PropTypes.bool,
	columns: PropTypes.shape({
		visible: PropTypes.arrayOf(PropTypes.string),
		available: PropTypes.arrayOf(PropTypes.string),
	}),
};

export { TransactionSettingsModal };
export default TransactionSettings;
