import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Dropdown from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import Icon, { iconClasses } from '@asteria/component-core/icon';
import Slider from '@asteria/component-core/slider';
import Tooltip, { TooltipWrapper } from '@asteria/component-core/tooltip';
import {
	sizeClasses,
	stateClasses,
	statusClasses,
} from '@asteria/component-core/utils';

import Chip from '@asteria/component-chip';
import ComponentService from '@asteria/component-tools/contenter/service';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { deepEqual } from '@asteria/utils-funcs/compare';

import ControlledWrapper from '../base/controlled';
import Error from '../base/error';
import Label from '../base/label';

import Option from './Option';

import './styles.scss';

const Select = React.memo(
	React.forwardRef((props, ref) => {
		const {
			disabled,
			placeholder,
			name,
			label,
			icon = 'triangle-down',
			iconActive = 'triangle-up',
			iconPosition = 'last',
			onChange,
			size,
			className,
			children,
			variant = 'select',
			compare,
			fieldProps = {},
			open,
			scroll,
			error,
			floatingError,
			value,
			direction,
			search,
			stopPropagation,
			tooltip,
			indeterminate = false,
			analyticsKey = 'form.select',
			strict,
			multi,
		} = props;

		const innerRef = React.useRef(null);

		const handleClick = React.useCallback(
			($value) => {
				Analytics.event('form.select.change', {
					name: name,
					analyticsKey: analyticsKey || name,
					value: $value,
				});

				if (multi) {
					let next = []
						.concat(value)
						.filter((value) => (value ?? null) !== null);

					const index = next.findIndex((value) =>
						compare
							? compare(value, $value)
							: deepEqual(value, $value),
					);

					if (index === -1) {
						next.push($value);
					} else {
						next.splice(index, 1);
					}

					return onChange?.({ target: { name: name, value: next } });
				}

				return onChange?.({ target: { name: name, value: $value } });
			},
			[analyticsKey, compare, multi, name, onChange, value],
		);

		const isActiveByDefault =
			!value && !placeholder && !indeterminate && !multi;

		const Children = React.useMemo(
			() =>
				React.Children.map(children, (child, index) => {
					let childValue = child?.props?.value,
						childOnClick = child?.props?.onClick;

					if (child?.props?.as === 'SelectOption') {
						childValue = child?.props?.props?.value;
						childOnClick = child?.props?.props?.onClick;
					}

					const isActive = []
						.concat(value)
						.some((value) =>
							compare
								? compare(value, childValue)
								: deepEqual(value, childValue),
						);

					const props = {
						iconPosition: iconPosition,
						size: size,
						active: isActive || (isActiveByDefault && index === 0),
						onClick: childOnClick ?? handleClick,
						postfix:
							multi && isActive ? (
								<Group
									verticalAlign="center"
									horizontalAlign="center"
								>
									<Icon icon="check" />
								</Group>
							) : (
								child?.props?.postfix
							),
					};

					if (!child) {
						return null;
					}

					if (child?.props?.as === 'SelectOption') {
						return React.cloneElement(child, {
							props: { ...child?.props?.props, ...props },
						});
					}

					return React.cloneElement(child, props);
				}),
			[
				children,
				value,
				iconPosition,
				size,
				isActiveByDefault,
				handleClick,
				multi,
				compare,
			],
		);

		const dropdownLabel = React.useMemo(() => {
			function getValue(node) {
				if (node?.props?.as === 'SelectOption') {
					return node?.props?.props?.children;
				}

				if (typeof node?.props?.render === 'function') {
					return node?.props?.render?.(node?.props);
				}

				return node?.props?.children;
			}

			if (multi) {
				const active = Children.filter((node) => {
					if (node?.props?.as === 'SelectOption') {
						return node?.props?.props?.active;
					}

					return node?.props?.active;
				});

				if (active.length) {
					return active.map((node) => getValue(node));
				}

				return placeholder || [];
			}

			const active = Children.find((node) => {
				if (node?.props?.as === 'SelectOption') {
					return node?.props?.props?.active;
				}

				return node?.props?.active;
			});

			return getValue(active) || placeholder || getValue(Children?.[0]);
		}, [Children, multi, placeholder]);

		const toggleOptions = React.useMemo(() => {
			let children = null;

			if (typeof dropdownLabel !== 'string') {
				if (Array.isArray(dropdownLabel)) {
					children = (
						<Slider>
							{dropdownLabel.map((value) => (
								<Chip key={value} label={value} size="sm" />
							))}
						</Slider>
					);
				} else {
					children = dropdownLabel;
				}
			}

			return {
				children: children,
				label: typeof dropdownLabel === 'string' ? dropdownLabel : null,
				icon: icon,
				iconActive: iconActive,
				iconPosition: iconPosition,
				disabled: disabled,
				size: size,
			};
		}, [dropdownLabel, icon, iconActive, iconPosition, disabled, size]);

		const firstValue = Children?.[0]?.props?.value;

		React.useEffect(() => {
			if (isActiveByDefault) {
				handleClick(firstValue);
			}
		}, [firstValue, handleClick, isActiveByDefault]);

		return (
			<TooltipWrapper tooltip={tooltip}>
				<div
					className={cn(
						className,
						'asteria-component__select',
						{
							[`asteria-component__select--variant-${variant}`]:
								variant,
						},
						sizeClasses(props),
						iconClasses('asteria-component__select', props),
						stateClasses({ ...props, error: error }),
						{ 'asteria--state-filled': value !== null },
						statusClasses({
							info: error?.type === 'info',
							success: error?.type === 'success',
							error: error?.type === 'error',
							warning: error?.type === 'warning',
						}),
					)}
				>
					<label
						className={cn(
							className,
							'asteria-component__label__wrapper',
							{
								[`asteria-component__label__wrapper--direction-${direction}`]:
									direction,
							},
						)}
					>
						<Label size={size} tooltip={label?.tooltip}>
							{label?.value ?? label}
						</Label>
						<div
							className="asteria-component__select__inner"
							ref={innerRef}
						>
							<input
								type="hidden"
								name={name}
								{...fieldProps}
								ref={ref}
							/>
							<Dropdown
								variant={variant}
								placement="bottom-start"
								toggle={toggleOptions}
								size={size}
								open={open}
								scroll={scroll}
								search={
									search
										? { ...search, name: name }
										: undefined
								}
								stopPropagation={stopPropagation}
								className={cn(
									'asteria-component__select--dropdown',
									className,
									`${className}--dropdown`,
								)}
								title={
									placeholder ??
									TranslationService.get([
										'select.title',
										'select.dropdown.title',
									])
								}
								analyticsKey={analyticsKey}
								strict={strict}
								multi={multi}
								offset="--select-offset"
							>
								{Children}
							</Dropdown>
						</div>
					</label>
					{error && floatingError && (
						<Tooltip target={innerRef} open>
							<div className="asteria-component__select-error">
								{error && <Error error={error} />}
							</div>
						</Tooltip>
					)}
					{!floatingError && (
						<div className="asteria-component__select-error">
							{error && <Error error={error} />}
						</div>
					)}
				</div>
			</TooltipWrapper>
		);
	}),
);

Select.displayName = 'Select';

Select.propTypes = {
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({ value: PropTypes.string, tooltip: PropTypes.string }),
	]),
	icon: PropTypes.string,
	iconActive: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	onChange: PropTypes.func,
	size: SizeProp(),
	className: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.string,
	compare: PropTypes.func,
	fieldProps: PropTypes.object,
	open: PropTypes.bool,
	scroll: PropTypes.bool,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	floatingError: PropTypes.bool,
	search: PropTypes.shape({
		closeIcon: PropTypes.string,
		searchable: PropTypes.bool,
		toggleAsSearch: PropTypes.bool,
		name: PropTypes.string,
		path: PropTypes.string,
	}),
	value: PropTypes.any,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	stopPropagation: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	indeterminate: PropTypes.bool,

	analyticsKey: PropTypes.string,
	strict: PropTypes.bool,
	multi: PropTypes.bool,
};

const Component = ControlledWrapper(Select);

ComponentService.register('Select', Component, {
	disabled: { type: 'boolean' },
	placeholder: { type: 'string' },
	name: { type: 'string' },
	label: { type: 'string' },
	icon: { type: 'string' },
	iconActive: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },
	onChange: { type: 'function' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	className: { type: 'string' },
	children: { type: 'node' },
	variant: { type: 'string' },
	compare: { type: 'function' },
	fieldProps: { type: 'object' },
	open: { type: 'boolean' },
	scroll: { type: 'boolean' },
	error: {
		type: 'object',
		options: {
			type: { type: 'string' },
			message: { type: 'string' },
		},
	},
	floatingError: { type: 'boolean' },
	search: {
		type: 'object',
		options: {
			closeIcon: { type: 'string' },
			searchable: { type: 'boolean' },
			toggleAsSearch: { type: 'boolean' },
			name: { type: 'string' },
			path: { type: 'string' },
		},
	},
	value: { type: 'string' },
	direction: { type: 'enum', options: ['vertical', 'horizontal'] },
	stopPropagation: { type: 'boolean' },
	tooltip: { type: 'string' },
	indeterminate: { type: 'boolean' },

	analyticsKey: { type: 'string' },
	strict: { type: 'boolean' },
	multi: { type: 'boolean' },
});

ComponentService.register('SelectOption', Option, {
	hidden: { type: 'boolean' },
	value: { type: 'string' },
	children: { type: 'node' },
	onClick: { type: 'function' },
	className: { type: 'string' },
	postfix: { type: 'node' },
});

export { Option, Select };

export default Component;
