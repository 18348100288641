import React from 'react';

import PropTypes from 'prop-types';

import BaseTeaser from './base';

const HomeTeaser = (props) => (
	<BaseTeaser {...props} path="pages.forecaster.teaser.home" />
);

HomeTeaser.displayName = 'HomeTeaser';

HomeTeaser.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default HomeTeaser;
