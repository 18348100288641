import {
	isFuture,
	isSameMonth,
	isThisMonth,
	max,
	min,
	parseISO,
} from 'date-fns';
import { get } from 'lodash-es';

import { getPath } from './getFormPaths';
import getHistoryValue from './history';

function getValue(value, category, tag, status) {
	if (category && category !== '$type') {
		if (tag) {
			return value?.[status]?.categories?.[category]?.tags?.[tag];
		}

		return value?.[status]?.categories?.[category];
	}

	return value?.[status];
}

/**
 * @param {{ useForm: (options: { name: string | string }) => object, type: string, dates: string[], category?: string, tag?: string, history: object, useUnpaidValues?: boolean }} options
 */
function useTypeFormData(options) {
	const { useForm, dates, type, category, tag, history, useUnpaidValues } =
		options;

	const futureValues = useForm({
		name: dates.map((date) =>
			getPath({
				type: type,
				date: date,
				status: 'FORECAST',
				category: category,
				tag: tag,
				field: 'value',
			}),
		),
	}).reduce((acc, value, index) => ({ ...acc, [dates[index]]: value }), {});

	const unpaidValues = Object.fromEntries(
		dates.map((date) => [
			date,
			['UNPAID', 'SIGNED'].reduce(
				(acc, status) =>
					acc +
					(get(
						history,
						getPath({
							type: type,
							date: date,
							status: status,
							category: category,
							tag: tag,
							field: 'value',
						}),
					) || 0),
				0,
			),
		]),
	);

	return dates
		.filter(($date) => {
			const date = parseISO($date);

			return (
				(isThisMonth(date) || isFuture(date)) && futureValues?.[$date]
			);
		})
		.reduce(
			(acc, $date) => {
				function update(acc) {
					acc.count += 1;
					acc.average = acc.total / acc.count;

					if (acc.startDate === null) {
						acc.startDate = date;
					} else {
						acc.startDate = min([acc.startDate, date]);
					}

					if (acc.endDate === null) {
						acc.endDate = date;
					} else {
						acc.endDate = max([acc.endDate, date]);
					}

					return acc;
				}

				const date = parseISO($date);

				const future = futureValues?.[$date] || 0;
				const unpaid = unpaidValues?.[$date] || 0;

				let total = future;

				if (useUnpaidValues) {
					total += unpaid;
				}

				acc.total += total;

				acc.FORECAST.total += future;
				acc.UNPAID.total += unpaid;

				if (total) {
					Object.assign(acc, update(acc));
				}

				if (future) {
					Object.assign(acc.FORECAST, update(acc.FORECAST));
				}

				if (unpaid) {
					Object.assign(acc.UNPAID, update(acc.UNPAID));
				}

				return acc;
			},
			{
				total: 0,
				count: 0,
				average: 0,
				startDate: null,
				endDate: null,

				FORECAST: {
					total: 0,
					count: 0,
					average: 0,
					startDate: null,
					endDate: null,
				},

				UNPAID: {
					total: 0,
					count: 0,
					average: 0,
					startDate: null,
					endDate: null,
				},
			},
		);
}

/**
 * @param {{ currentBalance: number, useForm: (options: { name: string | string }) => object, useStaticForm: (name: string) => object, category?: string, tag?: string, history: object }} options
 */
export function useFormattedData(options) {
	const {
		currentBalance,
		useForm,
		useStaticForm,
		category,
		tag,
		history,

		useCurrentBalance,
		useUnpaidValues,
	} = options;

	const dates = Array.from(
		new Set(
			[]
				.concat(Object.keys(useStaticForm('deposit.data') ?? {}))
				.concat(Object.keys(useStaticForm('withdraw.data') ?? {})),
		),
	);

	const DEPOSIT = useTypeFormData({
		useForm,
		history,
		type: 'deposit',
		dates,
		category,
		tag,
		useUnpaidValues: useUnpaidValues,
	});

	const WITHDRAW = useTypeFormData({
		useForm,
		history,
		type: 'withdraw',
		dates,
		category,
		tag,
		useUnpaidValues: useUnpaidValues,
	});

	let startDate = null,
		endDate = null,
		average = 0;

	if (DEPOSIT?.startDate && WITHDRAW?.startDate) {
		startDate = min([DEPOSIT?.startDate, WITHDRAW?.startDate]);
	} else if (DEPOSIT?.startDate) {
		startDate = DEPOSIT?.startDate;
	} else if (WITHDRAW?.startDate) {
		startDate = WITHDRAW?.startDate;
	}

	if (DEPOSIT?.endDate && WITHDRAW?.endDate) {
		endDate = max([DEPOSIT?.endDate, WITHDRAW?.endDate]);
	} else if (DEPOSIT?.endDate) {
		endDate = DEPOSIT?.endDate;
	} else if (WITHDRAW?.endDate) {
		endDate = WITHDRAW?.endDate;
	}

	if (DEPOSIT?.average && WITHDRAW?.average) {
		average =
			(Math.abs(DEPOSIT?.average) - Math.abs(WITHDRAW?.average)) / 2;
	} else if (DEPOSIT?.average) {
		average = DEPOSIT?.average;
	} else if (WITHDRAW?.average) {
		average = WITHDRAW?.average;
	}

	let total = (DEPOSIT.total || 0) - (WITHDRAW.total || 0);

	if (useCurrentBalance) {
		total += currentBalance;
	}

	return {
		total: total,
		average: average,
		startDate: startDate,
		endDate: endDate,
		DEPOSIT: DEPOSIT,
		WITHDRAW: WITHDRAW,
	};
}

export default function formatFormData(
	currentBalance,
	data,
	$category,
	$tag,
	history,
) {
	const category = $category !== '$type' ? $category : null;
	const tag = $category !== '$type' ? $tag : null;

	const { DEPOSIT, WITHDRAW } = Object.fromEntries(
		Object.entries(data ?? {}).map(([type, value]) => [
			type.toUpperCase(),
			Object.entries(value?.data ?? {})
				.filter(([$date, value]) => {
					const date = new Date($date);

					return (
						(isSameMonth(date, new Date()) || isFuture(date)) &&
						(getValue(value, category, tag, 'FORECAST') ||
							getHistoryValue(history, {
								type: type,
								date: $date,
								category: category,
								tag: tag,
								status: ['FORECAST', 'UNPAID', 'SIGNED'],
							}))
					);
				})
				.reduce(
					(acc, [$date, $value]) => {
						const total =
							(getValue($value, category, tag, 'FORECAST')
								?.value ?? 0) +
							getHistoryValue(history, {
								type: type,
								date: $date,
								category: category,
								tag: tag,
								status: ['FORECAST', 'UNPAID', 'SIGNED'],
							});

						const date = new Date($date);

						acc.total += total;
						acc.count += 1;
						acc.average = acc.total / acc.count;

						if (acc.startDate === null) {
							acc.startDate = date;
						} else {
							acc.startDate = min([acc.startDate, date]);
						}

						if (acc.endDate === null) {
							acc.endDate = date;
						} else {
							acc.endDate = max([acc.endDate, date]);
						}

						return acc;
					},
					{
						total: 0,
						count: 0,
						average: null,
						startDate: null,
						endDate: null,
					},
				),
		]),
	);

	let startDate = null,
		endDate = null,
		average = 0;

	if (DEPOSIT?.startDate && WITHDRAW?.startDate) {
		startDate = min([DEPOSIT?.startDate, WITHDRAW?.startDate]);
	} else if (DEPOSIT?.startDate) {
		startDate = DEPOSIT?.startDate;
	} else if (WITHDRAW?.startDate) {
		startDate = WITHDRAW?.startDate;
	}

	if (DEPOSIT?.endDate && WITHDRAW?.endDate) {
		endDate = max([DEPOSIT?.endDate, WITHDRAW?.endDate]);
	} else if (DEPOSIT?.endDate) {
		endDate = DEPOSIT?.endDate;
	} else if (WITHDRAW?.endDate) {
		endDate = WITHDRAW?.endDate;
	}

	if (DEPOSIT?.average && WITHDRAW?.average) {
		average =
			(Math.abs(DEPOSIT?.average) - Math.abs(WITHDRAW?.average)) / 2;
	} else if (DEPOSIT?.average) {
		average = DEPOSIT?.average;
	} else if (WITHDRAW?.average) {
		average = WITHDRAW?.average;
	}

	const current = currentBalance || 0;

	return {
		total:
			current +
			Math.abs(DEPOSIT?.total ?? 0) -
			Math.abs(WITHDRAW?.total ?? 0),
		average: average,
		startDate: startDate,
		endDate: endDate,
		DEPOSIT: DEPOSIT,
		WITHDRAW: WITHDRAW,
	};
}
