import slice from './slice';
import { actions as thunkActions } from './thunk';

export * as selectors from './selectors';
export * as constants from './constants';

export { slice };

// Action creators are generated for each case reducer function
export const {
	start,
	stop,
	reset,
	restart,
	init,
	close,
	setGraphSettings,
	resetGraphSettings,
	startSaving,
	stopSaving,
	resetLastSaveAt,
} = slice.actions;

export const navigation = {
	go: slice.actions.navigationGo,
	setDate: slice.actions.navigationSetDate,
	next: slice.actions.navigationNext,
	back: slice.actions.navigationBack,
	skip: slice.actions.navigationSkip,
	...thunkActions.navigation,
};

export const action = {
	set: slice.actions.setAction,
	reset: slice.actions.resetAction,
};

export const flags = {
	set: slice.actions.setFlags,
	reset: slice.actions.resetFlags,
	toggle: slice.actions.toggleFlags,
};

export const tags = {
	select: slice.actions.tagsSelect,
	deselect: slice.actions.tagsDeselect,
	refresh: slice.actions.tagsRefresh,
	clearState: slice.actions.tagsClearState,
	markSkipped: slice.actions.markSkipped,
	toggle: {
		complete: slice.actions.tagsToggleComplete,
		remove: slice.actions.tagsToggleRemove,
		skip: slice.actions.tagsToggleSkipped,
	},
	...thunkActions.tags,
};

export const actions = {
	start: slice.actions.startActionsFetching,
	stop: slice.actions.stopActionsFetching,
	set: slice.actions.setActions,
	remove: slice.actions.removeActions,
};

export default slice.reducer;
