import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

/**
 * @param { string | { key: string, default?: string, data?: string } } label
 * @returns { string }
 */
export const formatLabel = (label) => {
	if (!label) {
		return null;
	}

	if (typeof label === 'object' && !Array.isArray(label)) {
		return TranslationService.get(
			label.key,
			label.default ?? label.key,
			label.data,
		);
	}

	return TranslationService.get(
		label,
		Array.isArray(label) ? label[0] : label,
	);
};

export const isActive = (path, current) => {
	if (path !== '/') {
		return current?.startsWith?.(path);
	}

	return path === current;
};

export const isSubActive = (route, current) => {
	return (route?.sub ?? []).some(
		(route) =>
			route?.path === current &&
			(!route?.feature || FeatureService.isActive(route?.feature)),
	);
};
