import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

export const scenarioId = createSelector(
	(store) => store?.app?.user?.settings?.flags?.scenarioId,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const filters = createSelector(
	(store) => store?.app?.filters,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const timesize = createSelector(
	(store) => store?.app?.timesize,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isWelcomeShown = createSelector(
	(store) => store?.app?.user?.settings?.flags?.welcome,
	(store) => store?.app?.company?.settings?.flags?.welcome,
	(user, company) => !!user || !!company,
);

export const isForecasterShown = createSelector(
	(store) => store?.app?.user?.settings?.flags?.tours ?? [],
	(value) => value.includes('forecaster'),
);

export const isForecastCompleted = createSelector(
	(store) => store?.app?.user?.settings?.flags?.isForecastCompleted ?? null,
	(value) => value ?? false,
);

export const activeBars = createSelector(
	(store) => store?.graph?.data?.activeBars ?? [],
	(_, ID) => ID,
	(value, ID) => {
		let response = value;

		if (ID) {
			response = response.filter((value) => value === ID);
		}

		return response;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const currentDate = createSelector(
	(store) => store?.app?.currentDate,
	(value) => value,
);

export const selectedDate = createSelector(
	(store) => store?.app?.selectedDate,
	(value) => value,
);

export const graphTypes = createSelector(
	(store) => store?.app?.selectedTypes,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const graphOptions = createSelector(
	(store) => store?.graph?.options,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const graphActiveGroups = createSelector(
	(store) => store?.graph?.data?.activeGroups ?? {},
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
