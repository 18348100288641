import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const BarGroup = React.memo((props) => {
	const { className, children, ...rest } = props;

	return (
		<div
			className={cn(
				'asteria-component__forecaster-graph-bar-group',
				className,
			)}
			{...rest}
		>
			{children}
		</div>
	);
});

BarGroup.displayName = 'BarGroup';

BarGroup.propTypes = { className: PropTypes.string, children: PropTypes.node };

export default BarGroup;
