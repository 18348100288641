import React from 'react';

import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { PageTitle } from '@asteria/component-core/pageHeader';

import { TranslationService } from '@asteria/language';

const HeaderTitle = React.memo(() => {
	const { pathname } = useLocation();

	const path = pathname.split('/').filter(Boolean);

	return (
		<PageTitle
			title={TranslationService.get(
				['forecaster.header.title', 'forecaster.page.title'].concat(
					path.map(
						(value, index, arr) =>
							`forecaster.page.${arr
								.slice(0, index + 1)
								.join('.')}.title`,
					),
				),
			)}
		/>
	);
});

HeaderTitle.displayName = 'HeaderTitle';

HeaderTitle.propTypes = { className: PropTypes.string };

export default HeaderTitle;
