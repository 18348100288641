import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const Line = React.memo((props) => {
	const { className, positive, zero, negative } = props;

	return (
		<div
			className={cn(
				'asteria-component__graph-line',
				{
					'asteria--variant-positive': positive,
					'asteria--variant-zero': zero,
					'asteria--variant-negative': negative,
				},
				className,
			)}
		/>
	);
});

Line.displayName = 'Line';

Line.propTypes = {
	className: PropTypes.string,

	positive: PropTypes.bool,
	zero: PropTypes.bool,
	negative: PropTypes.bool,
};

export default Line;
