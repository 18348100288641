import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import { MessageBox } from '@asteria/component-support';

import { TranslationService } from '@asteria/language';

const IntegrationMessage = (props) => {
	const { integration, onAction, onSubmit } = props;

	const handleClose = React.useCallback(
		() => onAction?.('integrations:action:delete'),
		[onAction],
	);

	const handleDelete = React.useCallback(() => {
		onAction?.('integrations:delete', integration);
		onSubmit?.('integrations:delete', integration);
	}, [onAction, integration, onSubmit]);

	return (
		<MessageBox
			variant="help"
			className="asteria-component__onboarding-list__integration-delete-message-box"
		>
			<Content>
				<Text>
					{TranslationService.get(
						[
							'integrations.list.item.delete',
							`integrations.list.item.${integration?.name}.delete`,
						],
						undefined,
						integration,
					)}
				</Text>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						size="sm"
						onClick={handleClose}
						label={TranslationService.get([
							'action.abort',
							'action.cancel',
						])}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						size="sm"
						onClick={handleDelete}
						label={TranslationService.get([
							'action.submit',
							'action.remove',
						])}
					/>
				</FooterSection>
			</Footer>
		</MessageBox>
	);
};

IntegrationMessage.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	integration: PropTypes.shape({
		_id: PropTypes.string,
		id: PropTypes.string,
		name: PropTypes.string,
		key: PropTypes.string,
		state: PropTypes.string,
		status: PropTypes.shape({
			state: PropTypes.string,
		}),
		lastSync: PropTypes.string,

		disabled: PropTypes.bool,

		config: PropTypes.shape({
			connected: PropTypes.bool,
		}),
	}),
};

export default IntegrationMessage;
