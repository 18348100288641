import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import Contenter from '@asteria/component-tools/contenter';
import {
	FeatureFlag,
	useBulkFeatures,
} from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Step, {
	Content,
	Footer,
	Header,
	StepInner,
} from '../../../components/Step';
import SkipButton from '../Skip';

const IntegrationCustom = (props) => {
	const {
		className,

		type,
		name,

		flow,
		loading,

		onClose,
		onBack,
		onAbort,

		header,
		footer,
		content,
	} = props;

	const featureKey =
		type === 'erp'
			? 'onboarding-single-erp-integration'
			: 'onboarding-single-bank-integration';

	const isBackHidden =
		useBulkFeatures([['onboarding-single-integration', featureKey]]) ||
		footer?.hideBack;

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const step = `${type}.configuration.custom`;

	// const content = useContenter(`integrations.${key}.custom`);

	const tourKey = React.useMemo(
		() => [
			'onboarding:configuration',
			`onboarding:configuration:${type}`,
			`onboarding:configuration:${key}`,
			`onboarding:configuration:${type}:${key}`,
		],
		[key, type],
	);

	const integrationName = TranslationService.get(
		[
			'integration.title',
			`integration.title.${key}`,
			`integration.title.${type}.${key}`,
			`integration.${key}.title`,
			`integration.${type}.${key}.title`,
		],
		flow?.name,
	);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-custom__info',
				{
					[`asteria-component__onboarding-custom--type-${type}`]:
						type,
				},
				className,
			)}
			step={step}
		>
			<Header
				onBack={!isBackHidden ? onBack : null}
				onClose={!header?.hideClose ? onClose : null}
				tourKey={tourKey}
			>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${type}.add.title`,
						`integrations.${key}.add.title`,
						`integrations.${type}.${key}.add.title`,
						`integrations.configuration.title`,
						`integrations.${type}.configuration.title`,
						`integrations.${key}.configuration.title`,
						`integrations.${type}.${key}.configuration.title`,
					],
					undefined,
					{ name: integrationName },
				)}
			</Header>
			<Content step={step} scroll>
				<StepInner>
					{content ? (
						content
					) : (
						<Contenter content={flow?.steps?.info?.content} />
					)}
				</StepInner>

				<FeatureFlag feature="integrations.custom.external" invert>
					<Button
						analyticsKey={`integrations.${key}.help`}
						className="asteria-component__onboarding-step__help"
						variant="assist"
						label={TranslationService.get(
							[
								`integrations.add.download`,
								`integrations.${type}.download`,
								`integrations.${type}.${key}.download`,
							],
							undefined,
							{ name: integrationName },
						)}
						href="https://dev-api.asteria.ai/assets/asteria/files/AsteriaIntegrations.exe"
						target="_blank"
					/>
					<Button
						analyticsKey={`integrations.${key}.help`}
						className="asteria-component__onboarding-step__help"
						variant="assist"
						label={TranslationService.get(
							[
								`integrations.add.download`,
								`integrations.${type}.plugin.download`,
								`integrations.${type}.${key}.plugin.download`,
							],
							undefined,
							{ name: integrationName },
						)}
						href="https://dev-api.asteria.ai/assets/asteria/files/AsteriaIntegrationsVisma.asar"
						target="_blank"
					/>
				</FeatureFlag>

				<FeatureFlag feature="integrations.custom.external">
					<Button
						analyticsKey={`integrations.${key}.help`}
						className="asteria-component__onboarding-step__help"
						variant="assist"
						label={TranslationService.get(
							[
								`integrations.add.external`,
								`integrations.${type}.add.external`,
								`integrations.${type}.${key}.add.external`,
							],
							undefined,
							{ name: integrationName },
						)}
						href="https://www.asteria.ai/en/integrations/accounting-software/visma-administration"
						target="_blank"
					/>
				</FeatureFlag>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${key}.abort`}
						variant="tertiary"
						label={TranslationService.get(
							[
								'action.abort',
								'button.abort',
								'onboarding.modal.action',
								'onboarding.modal.action.abort',
								'onboarding.modal.flow-configuration.action',
								'onboarding.modal.flow-configuration.action.abort',
								`onboarding.modal.${type}.action`,
								`onboarding.modal.${type}.action.abort`,
								`onboarding.modal.${type}.flow-configuration.action`,
								`onboarding.modal.${type}.flow-configuration.action.abort`,
								`onboarding.modal.${key}.action`,
								`onboarding.modal.${key}.action.abort`,
								`onboarding.modal.${key}.flow-configuration.action`,
								`onboarding.modal.${key}.flow-configuration.action.abort`,
								`onboarding.modal.${type}.${key}.action`,
								`onboarding.modal.${type}.${key}.action.abort`,
								`onboarding.modal.${type}.${key}.flow-configuration.action`,
								`onboarding.modal.${type}.${key}.flow-configuration.action.abort`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={onAbort}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${key}.continue`}
						variant="primary"
						label={TranslationService.get(
							[
								'action.continue',
								'button.continue',
								'integrations.action.next',
								`integrations.action.next.${key}`,
								'onboarding.modal.action',
								'onboarding.modal.action.continue',
								'onboarding.modal.flow-configuration.action',
								'onboarding.modal.flow-configuration.action.continue',
								`onboarding.modal.${type}.action`,
								`onboarding.modal.${type}.action.continue`,
								`onboarding.modal.${type}.flow-configuration.action`,
								`onboarding.modal.${type}.flow-configuration.action.continue`,
								`onboarding.modal.${key}.action`,
								`onboarding.modal.${key}.action.continue`,
								`onboarding.modal.${key}.flow-configuration.action`,
								`onboarding.modal.${key}.flow-configuration.action.continue`,
								`onboarding.modal.${type}.${key}.action`,
								`onboarding.modal.${type}.${key}.action.continue`,
								`onboarding.modal.${type}.${key}.flow-configuration.action`,
								`onboarding.modal.${type}.${key}.flow-configuration.action.continue`,
							],
							undefined,
							{ name: integrationName },
						)}
						type="submit"
						loading={loading}
						disabled={loading}
					/>
					<SkipButton type={type} name={name} />
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationCustom.displayName = 'IntegrationCustom';

IntegrationCustom.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	name: PropTypes.string,

	flow: PropTypes.object,
	loading: PropTypes.bool,

	onClose: PropTypes.func,
	onBack: PropTypes.func,
	onAbort: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	header: PropTypes.shape({ hideClose: PropTypes.bool }),
	footer: PropTypes.shape({ hideBack: PropTypes.bool }),
	content: PropTypes.node,
};

export default IntegrationCustom;
