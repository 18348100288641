import { PartnerService } from '@asteria/backend-utils-services';
import { AdminService } from '@asteria/backend-utils-services';

export async function fetch({ accessToken, themeId: $themeId, partnerId }) {
	let themeId = $themeId;

	if (!themeId) {
		themeId = await PartnerService.partner
			.fetchOne(
				{ id: partnerId, fields: 'name settings { themeId }' },
				{ token: accessToken },
			)
			.then((response) => response?.settings?.themeId);
	}

	if (themeId) {
		return AdminService.theme
			.fetchOne({ fields: 'styles', id: themeId }, { accessToken })
			.then((theme) => {
				return theme?.styles?.global?.css || '';
			})
			.catch(() => {
				return '';
			});
	}

	return '';
}
