import { useCallback, useMemo } from 'react';

import { useDispatch, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

const selectors = {
	userSettings: createSelector(
		(state) => state?.app?.user?.settings,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	companySettings: createSelector(
		(state) => state?.app?.company?.settings,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	feedback: createSelector(
		(state) => state?.app?.user?.feedback,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

function formatFlags(source) {
	return {
		...(source ?? {}),
		...Object.fromEntries(
			Object.entries(source ?? {})
				.filter(([key]) => !['scenarioId'].includes(key))
				.map(([key, value]) => {
					if (value && typeof value === 'object') {
						if (Array.isArray(value)) {
							return [key, []];
						}

						return [key, {}];
					}

					return [key, null];
				}),
		),
	};
}

export function useActions({ onAction, onClose, onSubmit }) {
	const store = useStore();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const onInvalidateQueries = useCallback(
		() => queryClient.invalidateQueries({ predicate: () => true }),
		[queryClient],
	);

	const onResetUserFlags = useCallback(
		async (event, options) => {
			const settings = selectors.userSettings(store.getState());

			const flags = formatFlags(settings?.flags);

			try {
				await onAction?.('settings:save', { flags: flags });
			} catch (err) {
				// Do nothing
			}

			dispatch(AppStore.setUserSettingsFlags(flags));

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[dispatch, onAction, onClose, onInvalidateQueries, store],
	);

	const onResetCompanyFlags = useCallback(
		async (event, options) => {
			const settings = selectors.companySettings(store.getState());

			const flags = formatFlags(settings?.flags);

			try {
				await onAction?.('company:settings:save', {
					settings: { flags: flags },
					questions: {},
				});
			} catch (err) {
				// Do nothing
			}

			dispatch(AppStore.setCompanySettingsFlags(flags));

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[dispatch, onAction, onClose, onInvalidateQueries, store],
	);

	const onResetFlags = useCallback(
		async (event, options) => {
			try {
				await onResetUserFlags(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			try {
				await onResetCompanyFlags(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onClose, onInvalidateQueries, onResetCompanyFlags, onResetUserFlags],
	);

	const onResetFeedback = useCallback(
		async (event, options) => {
			try {
				await onAction?.('feedback:reset');
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onAction, onClose, onInvalidateQueries],
	);

	const onResetScenario = useCallback(
		async (event, options) => {
			try {
				await onAction?.('scenario:current:reset');
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onAction, onClose, onInvalidateQueries],
	);

	const onClearScenario = useCallback(
		async (event, options) => {
			try {
				await onAction?.('scenario:current:clear');
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onAction, onClose, onInvalidateQueries],
	);

	const onRemoveDraftScenario = useCallback(
		async (event, options) => {
			try {
				await onAction?.('scenario:draft:clear');
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onAction, onClose, onInvalidateQueries],
	);

	const onDataClean = useCallback(
		async (event, options) => {
			try {
				await onSubmit?.('company:clean');
			} catch (err) {
				// Do nothing
			}

			try {
				await onSubmit?.('integrations:list');
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onClose, onInvalidateQueries, onSubmit],
	);

	const onDataFlagsClean = useCallback(
		async (event, options) => {
			try {
				await onDataClean(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			try {
				await onResetUserFlags(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			try {
				await onResetCompanyFlags(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[
			onClose,
			onDataClean,
			onInvalidateQueries,
			onResetCompanyFlags,
			onResetUserFlags,
		],
	);

	const onDataRefresh = useCallback(
		async (event, options) => {
			try {
				await onDataClean(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			const companyId = store.getState()?.app?.company?._id;
			const key = [companyId, new Date().toISOString()].join('::');

			const IntegrationConfig = {
				client: {
					authorizationCode: key,
					useCurrency: true,
				},
			};

			try {
				await onSubmit?.('integrations:create', [
					{
						key: 'asteriaBank',
						type: 'bank',
						config: IntegrationConfig,
					},
				]);
			} catch (err) {
				// Do nothing
			}

			try {
				await onSubmit?.('integrations:create', [
					{
						key: 'testIntegration',
						type: 'erp',
						config: IntegrationConfig,
					},
				]);
			} catch (err) {
				// Do nothing
			}

			try {
				await onSubmit?.('integrations:list');
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[onClose, onDataClean, onInvalidateQueries, onSubmit, store],
	);

	const onDataFlagsRefresh = useCallback(
		async (event, options) => {
			try {
				await onDataRefresh(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			try {
				await onResetUserFlags(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			try {
				await onResetCompanyFlags(event, { skipClose: true });
			} catch (err) {
				// Do nothing
			}

			onInvalidateQueries();

			if (!options?.skipClose) {
				return onClose?.(event);
			}
		},
		[
			onClose,
			onDataRefresh,
			onInvalidateQueries,
			onResetCompanyFlags,
			onResetUserFlags,
		],
	);

	const onForecastActionsRefresh = useCallback(
		async () => onSubmit?.('forecast:actions:fetch'),
		[onSubmit],
	);

	const onForecastActionsClean = useCallback(async () => {
		const objects = await onForecastActionsRefresh();

		const IDs = objects
			.map((object) => object?._id ?? object?.id)
			.filter(Boolean);

		if (IDs.length) {
			await onSubmit?.('forecast:actions:delete', IDs);
		}

		onInvalidateQueries();
	}, [onForecastActionsRefresh, onInvalidateQueries, onSubmit]);

	const onForecastActionsRecreate = useCallback(async () => {
		const input = ForecasterStore.selectors.tags
			.available(store.getState(), { type: 'all' })
			.map((object) => ({
				insert: true,
				type: 'CATEGORY',
				tagId: object?._id ?? object?.id,
				extra: { type: object?.type },
			}));

		await onForecastActionsClean();

		if (input.length) {
			await onSubmit?.('forecast:actions:update', input);
		}

		onInvalidateQueries();
	}, [onForecastActionsClean, onInvalidateQueries, onSubmit, store]);

	return useMemo(
		() => ({
			onResetUserFlags: onResetUserFlags,
			onResetCompanyFlags: onResetCompanyFlags,
			onResetFlags: onResetFlags,
			onResetFeedback: onResetFeedback,
			onResetScenario: onResetScenario,
			onClearScenario: onClearScenario,
			onRemoveDraftScenario: onRemoveDraftScenario,
			onDataClean: onDataClean,
			onDataFlagsClean: onDataFlagsClean,
			onDataRefresh: onDataRefresh,
			onDataFlagsRefresh: onDataFlagsRefresh,

			onForecastActionsRefresh: onForecastActionsRefresh,
			onForecastActionsRecreate: onForecastActionsRecreate,
			onForecastActionsClean: onForecastActionsClean,

			onInvalidateQueries: onInvalidateQueries,
		}),
		[
			onResetUserFlags,
			onResetCompanyFlags,
			onResetFlags,
			onResetFeedback,
			onResetScenario,
			onClearScenario,
			onRemoveDraftScenario,
			onDataClean,
			onDataFlagsClean,
			onDataRefresh,
			onDataFlagsRefresh,
			onForecastActionsRefresh,
			onForecastActionsRecreate,
			onForecastActionsClean,
			onInvalidateQueries,
		],
	);
}
