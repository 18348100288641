import React from 'react';

import PropTypes from 'prop-types';

import * as FormatUtils from '@asteria/utils-funcs/format';

import { useOverallValue } from '../hooks';

import View from './view';

const XAxisPrefixItem = React.memo((props) => {
	const { type, dates, category, tag } = props;

	const value = useOverallValue({
		type,
		dates,
		category,
		tag,
		includeFuture: true,
	});

	const label = FormatUtils.formatTag({ type, category, tag });

	return (
		<View
			type={type}
			dates={dates}
			category={category}
			tag={tag}
			value={value}
			label={label}
		/>
	);
});

XAxisPrefixItem.displayName = 'XAxisPrefixItemProvider';
XAxisPrefixItem.propTypes = {
	type: PropTypes.string,
	dates: PropTypes.arrayOf(PropTypes.string),
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default XAxisPrefixItem;
