import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import Auth2FASetupModal from './modal';

import './styles.scss';

const Setup2FA = React.memo(function Setup2FA(props) {
	const { onAction, onSubmit, storage = window.localStorage } = props;

	const dispatch = useDispatch();
	const [search] = useSearchParams();
	const navigate = useNavigate();

	const open = useSelector(AppStore.selectors.register2FA);

	const onClose = React.useCallback(() => {
		storage.removeItem('wingsToken');
		storage.removeItem('wingsRefreshToken');

		dispatch(AppStore.register2FA(false));
		dispatch(AppStore.setLoginStatus(false));
		dispatch(AppStore.setAccessToken(null));

		return navigate(['/', search.toString()].filter(Boolean).join('?'), {
			replace: true,
		});
	}, [dispatch, navigate, search, storage]);

	const onSuccess = React.useCallback(() => {
		dispatch(AppStore.register2FA(false));
	}, [dispatch]);

	return (
		<FeatureFlag feature="auth-2fa">
			<Auth2FASetupModal
				open={open}
				onAction={onAction}
				onSubmit={onSubmit}
				onClose={onClose}
				onSuccess={onSuccess}
			/>
		</FeatureFlag>
	);
});

Setup2FA.displayName = 'Setup2FA';

Setup2FA.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	storage: PropTypes.any,
};

export default Setup2FA;
