import React from 'react';

import {
	formatISO,
	isFuture,
	isPast,
	isSameMonth,
	isThisMonth,
	startOfYear,
} from 'date-fns';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import XAxisLabel from '@asteria/component-graph/graphs/xaxis/label';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import XAxisInput from './input';

import '../styles.scss';

const XAxisColumn = React.memo((props) => {
	const {
		type,
		date,
		category,
		tag,
		types = [type],
		onAction,

		render,
		renderItem,

		current,
		prefix,
		label,
	} = props;

	const hasVisibleYearFeature = useFeature('forecaster-xaxis-year-visible');

	let columnType = null;

	if (typeof date !== 'string') {
		if (isThisMonth(date)) {
			columnType = 'CURRENT';
		} else if (isPast(date)) {
			columnType = 'HISTORY';
		} else {
			columnType = 'FUTURE';
		}
	}

	return (
		<XAxisLabel
			className={cn({
				'asteria--variant-history': isPast(date) && !isThisMonth(date),
				'asteria--variant-current': current ?? isThisMonth(date),
				'asteria--variant-future': isFuture(date) && !isThisMonth(date),
			})}
			prefix={
				prefix !== undefined
					? prefix
					: isSameMonth(date, startOfYear(date)) ||
					  hasVisibleYearFeature
					? TranslationService.get(
							'xaxis.prefix',
							'{{ date|date:"yyyy"|cap }}',
							{ date: date },
					  )
					: null
			}
			label={
				label !== undefined
					? label
					: TranslationService.get(
							'xaxis.label',
							'{{ date|date:"MMMM"|cap }}',
							{ date: date },
					  )
			}
			data-date={
				typeof date !== 'string'
					? formatISO(date, { representation: 'date' })
					: date
			}
			data-type={columnType}
			size="sm"
		>
			<Group flex>
				{render
					? render({ types, date, category, tag, onAction })
					: types.map((type) =>
							renderItem ? (
								renderItem({
									type,
									date,
									category,
									tag,
									onAction,
								})
							) : (
								<XAxisInput
									key={type}
									type={type}
									date={date}
									category={category}
									tag={tag}
									onAction={onAction}
								/>
							),
					  )}
			</Group>
		</XAxisLabel>
	);
});

XAxisColumn.displayName = 'XAxisColumn';
XAxisColumn.propTypes = {
	type: PropTypes.string,
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	types: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,

	render: PropTypes.func,
	renderItem: PropTypes.func,

	current: PropTypes.bool,
	prefix: PropTypes.string,
	label: PropTypes.string,
};

export default XAxisColumn;
