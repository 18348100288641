import { get } from 'lodash-es';

import * as Category from './category';
import * as Tag from './tag';
import { getPath, validateFormContext } from './utils';

export function tags(input, ctx) {
	const context = validateFormContext(ctx);
	const changes = [];

	const grouped = input.reduce((acc, { category, tag }) => {
		if (!acc[category]) {
			acc[category] = {};
		}

		acc[category][tag] = true;

		return acc;
	}, {});

	for (const type of ['deposit', 'withdraw']) {
		const categories =
			get(context.form, getPath({ type, field: 'categories' })) ?? {};

		for (const category in categories) {
			if (!grouped[category]) {
				changes.push(
					...Category.remove(
						{ type, category, recalculate: true },
						context,
					),
				);

				continue;
			}

			const tags =
				get(context.form, getPath({ type, category, field: 'tags' })) ??
				{};

			for (const tag in tags) {
				if (!grouped[category][tag]) {
					changes.push(
						...Tag.remove(
							{ type, category, tag, recalculate: true },
							context,
						),
					);

					continue;
				}
			}
		}
	}

	return changes;
}
