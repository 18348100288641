import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { SkeletonLoader } from '@asteria/component-core/progress';
import Table, {
	TableCell,
	TableFooter,
	TableGroup,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import Tooltip from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const ICONS = {
	equal: 'arrow-straight',
	positive: 'arrow-up-right',
	negative: 'arrow-down-right',
};

const StatementDelta = (props) => {
	const { index, values, years } = props;

	const percentage = React.useMemo(() => {
		const value =
			Math.round((values?.[index] / values?.[index - 1]) * 100) - 100;

		return values?.[index] < values?.[index - 1]
			? Math.abs(value) * -1
			: Math.abs(value) || 0;
	}, [index, values]);

	const delta = React.useMemo(() => {
		if (percentage > 5) {
			return 'positive';
		}

		if (percentage < -5) {
			return 'negative';
		}

		return 'equal';
	}, [percentage]);

	const translationData = React.useMemo(
		() => ({
			percentage: percentage,
			year: years?.[index],
			prevYear: years?.[index - 1],
			value: values?.[index],
			absoluteDifference: values?.[index] - values?.[index - 1],
		}),
		[index, percentage, values, years],
	);

	if (index === 0) {
		return null;
	}

	if (!Math.round(values?.[index]) || !Math.round(values?.[index - 1])) {
		return null;
	}

	return (
		<Tooltip
			label={TranslationService.get(
				['statement.delta.tooltip', `statement.delta.${delta}.tooltip`],
				undefined,
				translationData,
			)}
		>
			<Group
				direction="horizontal"
				horizontalAlign="left"
				verticalAlign="center"
			>
				<Text size="xs">
					{TranslationService.get(
						'statement.delta.value',
						undefined,
						translationData,
					)}
				</Text>
				<Icon
					className={cn(
						'table-statement-delta-icon',
						`table-statement--delta-${delta}`,
					)}
					icon={ICONS[delta]}
					size="sm"
				/>
			</Group>
		</Tooltip>
	);
};

StatementDelta.displayName = 'StatementDelta';

StatementDelta.propTypes = {
	index: PropTypes.number,
	years: PropTypes.number,
	values: PropTypes.array,
};

const StatementRow = (props) => {
	const { group, row, years, onCreditActivate } = props;

	return (
		<TableRow>
			<TableCell>
				<Text size="sm">
					{TranslationService.get(row?.label, row?.label)}
				</Text>
			</TableCell>
			{years.map((year, index) => (
				<>
					<TableCell key={year} className="asteria--type-value">
						<Text size="sm">
							{TranslationService.get(
								'statement.row.value',
								'{{value | number : false}}',
								{
									value: row?.values?.[index],
								},
							)}
						</Text>

						{row?.badges?.[index] ? (
							<Button
								icon="alert"
								size="sm"
								onClick={onCreditActivate}
								tooltip={TranslationService.get(
									`${row.label}.badge`,
									// eslint-disable-next-line spellcheck/spell-checker
									'Du vet väl att du kan beräkna ditt finansieringsbehov i Företagskollen. Klicka här för att testa Finansieringsbehov',
									{ row: group, cell: row },
								)}
							/>
						) : null}
					</TableCell>
					<TableCell
						key={`${year}-delta`}
						className="asteria--type-delta"
					>
						<StatementDelta
							index={index}
							years={years}
							values={row?.values}
						/>
					</TableCell>
				</>
			))}
		</TableRow>
	);
};

StatementRow.displayName = 'StatementRow';

StatementRow.propTypes = {
	group: PropTypes.object,
	row: PropTypes.object,
	years: PropTypes.arrayOf(PropTypes.number),
	onCreditActivate: PropTypes.func,
};

const StatementGroup = (props) => {
	const { group, years, onCreditActivate } = props;

	return (
		<TableGroup
			className={cn(
				{ ['table-group-footer']: !group?.rows },
				'table-statement-group',
			)}
		>
			{group?.rows?.length ? (
				<>
					<TableHeader>
						<TableCell>
							<Text size="sm">
								{TranslationService.get(
									group.label,
									group.label,
								)}
							</Text>
						</TableCell>
					</TableHeader>

					{group?.rows.map((row) => (
						<StatementRow
							key={row.label}
							row={row}
							years={years}
							onCreditActivate={onCreditActivate}
							group={group}
						/>
					))}

					{group.rows
						.filter((cell) => cell?.rows)
						.map((group) => (
							<StatementGroup
								key={group.label}
								group={group}
								years={years}
								onCreditActivate={onCreditActivate}
							/>
						))}
				</>
			) : null}

			{group?.total ? (
				<TableFooter>
					<TableCell>
						<Text size="sm">
							{TranslationService.get(
								`${group.label}.total`,
								`${group.label}.total`,
							)}
						</Text>
					</TableCell>
					{years.map((year, index) => (
						<>
							<TableCell
								key={year}
								className="asteria--type-value"
							>
								<Text size="sm">
									{TranslationService.get(
										'statement.row.total',
										'{{value | number : false}}',
										{ value: group?.total?.[index] },
									)}
								</Text>
								{group?.badges?.[index] ? (
									<Button
										icon="alert"
										onClick={onCreditActivate}
										size="sm"
										tooltip={TranslationService.get(
											`${group.label}.badge`,
											// eslint-disable-next-line spellcheck/spell-checker
											'Du vet väl att du kan beräkna ditt finansieringsbehov i Företagskollen. Klicka här för att testa Finansieringsbehov',
										)}
									/>
								) : null}
							</TableCell>
							<TableCell
								key={`${year}-delta`}
								className="asteria--type-delta"
							>
								<StatementDelta
									index={index}
									years={years}
									values={group?.total}
								/>
							</TableCell>
						</>
					))}
				</TableFooter>
			) : null}
		</TableGroup>
	);
};

StatementGroup.displayName = 'StatementGroup';

StatementGroup.propTypes = {
	group: PropTypes.object,
	years: PropTypes.arrayOf(PropTypes.number),
	onCreditActivate: PropTypes.func,
};

const StatementTable = (props) => {
	const { className, isLoading, type, data, onCreditActivate } = props;

	if (isLoading) {
		return (
			<>
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
				<SkeletonLoader />
			</>
		);
	}

	return (
		<Table
			className={cn(
				'asteria-component__statement__table',
				{ [`asteria-component__statement__table--type-${type}`]: type },
				className,
			)}
		>
			<TableHeader>
				<TableCell />
				{data?.fiscalYears?.map((year, index) => (
					<TableCell key={year.start}>
						<Text size="sm">
							{TranslationService.get(
								[
									'statement.year',
									`statement.year.${index + 1}`,
								],
								'{{start | date:"MMM yy"}} - {{end | date:"MMM yy"}}',
								year,
							)}
						</Text>
					</TableCell>
				))}
			</TableHeader>
			{data?.rows?.map((group) => (
				<StatementGroup
					key={data.id}
					group={group}
					years={data.years}
					onCreditActivate={onCreditActivate}
				/>
			))}
			{data?.total ? (
				<TableGroup className="table-footer">
					<TableFooter>
						<TableCell>
							<Text size="sm">
								{TranslationService.get(
									'statement.result',
									// eslint-disable-next-line spellcheck/spell-checker
									'Resultat',
								)}
							</Text>
						</TableCell>
						{data?.years?.map((year, index) => (
							<>
								<TableCell
									key={year}
									className="asteria--type-value"
								>
									<Text size="sm">
										{TranslationService.get(
											'statement.total',
											'{{value | number : false}}',
											{ value: data?.total?.[index] },
										)}
									</Text>
								</TableCell>
								<TableCell
									key={`${year}-delta`}
									className="asteria--type-delta"
								>
									<StatementDelta
										index={index}
										years={data.years}
										values={data?.total}
									/>
								</TableCell>
							</>
						))}
					</TableFooter>
				</TableGroup>
			) : null}
		</Table>
	);
};

StatementTable.displayName = 'StatementTable';

StatementTable.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	onCreditActivate: PropTypes.func,
};

export default StatementTable;
