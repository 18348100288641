import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { sizeClasses } from '@asteria/component-core/utils';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';
import useColors from '@asteria/utils-hooks/useColors';

import './styles.scss';

const AccountBalancePrefix = (props) => {
	const { tooltip, className, onClick, size } = props;

	return (
		<TooltipWrapper tooltip={tooltip}>
			<span
				className={cn(
					'asteria-component__prefix',
					'asteria--type-account',
					sizeClasses({ size: size }),
					className,
				)}
				onClick={onClick}
			>
				<svg>
					<line x1="0" x2="100%" y1="50%" y2="50%" fill="red" />

					<defs>
						<circle
							id="account-dot-circle"
							cx="50%"
							cy="50%"
							r="6"
						/>

						<clipPath id="insideAccountDot">
							<use xlinkHref="#account-dot-circle" />
						</clipPath>
					</defs>

					<use
						id="account-dot"
						xlinkHref="#account-dot-circle"
						clipPath="url(#insideAccountDot)"
					/>
				</svg>
			</span>
		</TooltipWrapper>
	);
};

AccountBalancePrefix.displayName = 'AccountBalancePrefix';
AccountBalancePrefix.propTypes = {
	tooltip: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
	size: SizeProp(),
};

const Prefix = (props) => {
	const { colors = '', tooltip, className, onClick, size } = props;

	const { custom: customColors, typed: typedColors } = useColors(colors);

	const style = React.useMemo(() => {
		if (!customColors.length) {
			return {};
		}

		return { backgroundColor: customColors };
	}, [customColors]);

	return (
		<TooltipWrapper tooltip={tooltip}>
			<span
				className={cn(
					'asteria-component__prefix',
					sizeClasses({ size: size }),
					typedColors,
					className,
				)}
				style={style}
				onClick={onClick}
			/>
		</TooltipWrapper>
	);
};

Prefix.displayName = 'Prefix';

Prefix.propTypes = {
	className: PropTypes.string,
	colors: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	tooltip: PropTypes.node,
	onClick: PropTypes.func,
	size: SizeProp(),
};

ComponentService.register('Prefix', Prefix, {
	className: { type: 'string' },
	colors: { type: 'array', of: { type: 'string' } },
	tooltip: { type: 'node' },
	onClick: { type: 'function' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
});

export default Prefix;
export { AccountBalancePrefix };
