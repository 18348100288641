import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useStore } from 'react-redux';
import { useParams } from 'react-router-dom';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import { IntegrationsTeaser } from '@asteria/component-integrations-v2';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { cn } from '@asteria/utils-funcs/classes';

import SectionActions from './Actions';
import SectionGraph from './Graph';
import SectionHeader from './Header';
import SectionTransactions from './Transactions';
import AutoSave from './autosave';
import { useValueUpdate } from './hooks';
import SectionInformation from './information';
import SectionSaving from './saving';
import SectionSatisfaction from './section/satisfaction';
import SectionTeaser from './teaser';
import CleanModalWrapper from './wrapper/category-clean';
import FormWrapper from './wrapper/form';
import LeaveWrapper from './wrapper/leave';

import './styles.scss';

const CategoryPageContent = React.memo((props) => {
	const {
		onAction,
		onSubmit,
		view,
		onChangeView,
		variant,
		onReset,
		onGetInitialValues,
	} = props;

	const params = useParams();
	const store = useStore();

	const actionId = params?.actionId;

	const { getValues } = useFormContext();
	const onValueUpdate = useValueUpdate();

	const [cleanModalProps, setCleanModalProps] = React.useState(null);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'navigation:select') {
				return onAction?.(
					'navigate',
					`/forecaster/category/` + data.join('/'),
				);
			}

			if (action === 'form:save') {
				const form = data?.form ?? data;

				handleAction('form:reset', form);

				const sourceScenarioData =
					ScenarioStore.selectors.sourceScenarioData(
						store.getState(),
					);

				return onAction?.('forecaster:save', {
					scenario: {
						_id: sourceScenarioData?._id,
						name: sourceScenarioData?.name,
					},
					data: cloneDeep(form),
					noRedirect: true,
				});
			}

			if (action === 'form:reset') {
				onReset?.(cloneDeep(data));

				return;
			}

			if (action === 'form:discard') {
				handleAction('form:reset', {
					...onGetInitialValues?.(),
					$touched: undefined,
				});

				if (!data?.skipRedirect) {
					return onAction?.('navigate', null);
				}
			}

			if (action === 'action:remove') {
				if (actionId) {
					return onSubmit?.('forecast:actions:delete', actionId);
				}

				return;
			}

			if (action === 'value:update') {
				onValueUpdate({
					name: data?.name,
					value: data?.value,
					prev: data?.prev,
					skipApply: data?.skipApply,
				});

				handleAction('form:reset', {
					...getValues(),
					$initial: onGetInitialValues?.(),
				});

				return;
			}

			if (action === 'navigation:remove') {
				const [type, id] = data;
				const tag = AppStore.selectors.tag(store.getState(), id);

				setCleanModalProps({
					_id: id,
					type: type,
					category: tag?.category?.name,
					tag: tag?.name,
				});

				return;
			}

			return onAction?.(action, data);
		},
		[
			actionId,
			getValues,
			onAction,
			onGetInitialValues,
			onReset,
			onSubmit,
			onValueUpdate,
			store,
		],
	);

	const onCloseCleanModal = React.useCallback(() => {
		setCleanModalProps(null);
	}, []);

	return (
		<LeaveWrapper variant={variant} onAction={handleAction}>
			<CleanModalWrapper
				open={cleanModalProps !== null}
				onAction={handleAction}
				onClose={onCloseCleanModal}
				{...cleanModalProps}
			>
				{variant === 'category' ? (
					<AutoSave onAction={handleAction} variant={variant} />
				) : null}

				<div className="asteria-component__forecaster-page-content">
					<div className="asteria-component__forecaster-page-content-main">
						<SectionHeader
							onAction={handleAction}
							variant={variant}
							view={view}
							onChangeView={onChangeView}
						/>

						{view === 'graph' ? (
							<SectionGraph
								onAction={handleAction}
								variant={variant}
							/>
						) : null}

						{view === 'transactions' ? (
							<SectionTransactions
								onAction={handleAction}
								variant={variant}
							/>
						) : null}
					</div>

					{variant === 'category' ? (
						<Group className="asteria-component__forecaster-page-category-information">
							<SectionSaving
								onAction={handleAction}
								variant={variant}
							/>
							<SectionInformation
								onAction={handleAction}
								variant={variant}
							/>
						</Group>
					) : null}

					<IntegrationsTeaser
						onAction={onAction}
						onSubmit={onSubmit}
					/>

					{view === 'graph' ? (
						<SectionSatisfaction
							onAction={handleAction}
							variant={variant}
						/>
					) : null}

					{variant === 'action' ? (
						<SectionActions
							onAction={handleAction}
							variant={variant}
						/>
					) : null}
				</div>

				<SectionTeaser onAction={handleAction} />
			</CleanModalWrapper>
		</LeaveWrapper>
	);
});

CategoryPageContent.displayName = 'CategoryPageContent';
CategoryPageContent.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	view: PropTypes.string,
	onChangeView: PropTypes.func,
	variant: PropTypes.string,
	onReset: PropTypes.func,
	onGetInitialValues: PropTypes.func,
};

const CategoryPage = React.memo((props) => {
	const { className, onAction, onSubmit, variant } = props;

	const { reset, getValues } = useFormContext();

	const [view, setView] = React.useState('graph');

	const onChangeView = React.useCallback((view) => {
		return () => {
			setView(view);
		};
	}, []);

	const onGetInitialValues = React.useCallback(() => {
		const form = getValues();

		return form?.$initial ?? form;
	}, [getValues]);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page',
				'asteria--type-category',
				{ [`asteria--view-${view}`]: view },
				className,
			)}
		>
			<FormWrapper>
				<CategoryPageContent
					onAction={onAction}
					onSubmit={onSubmit}
					variant={variant}
					onChangeView={onChangeView}
					view={view}
					onReset={reset}
					onGetInitialValues={onGetInitialValues}
				/>
			</FormWrapper>
		</div>
	);
});

CategoryPage.displayName = 'CategoryPage';

CategoryPage.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	variant: PropTypes.string,
};

export default CategoryPage;
