const setFlags = (state, { payload }) => {
	for (const key in payload) {
		state.flags[key] = payload[key];
	}
};

const resetFlags = (state, { payload }) => {
	for (const key in payload) {
		state.flags[key] = false;
	}
};

const toggleFlags = (state, { payload }) => {
	for (const key in payload) {
		state.flags[key] = !state.flags[key];
	}
};

export { setFlags, toggleFlags, resetFlags };
