import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Title } from '@asteria/component-core/typography';
import { Header } from '@asteria/component-core/wrapper';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { TranslationService } from '@asteria/language';

import '../styles.scss';

const TransactionsHeader = React.memo((props) => {
	const {
		onAction,

		onToggleDirection,
		direction,
	} = props;

	const dispatch = useDispatch();

	const handleClose = React.useCallback(
		() => onAction?.('transactions:close'),
		[onAction],
	);

	const handleFullScreen = React.useCallback(() => {
		dispatch(ForecasterStore.flags.toggle({ fullScreen: true }));
	}, [dispatch]);

	const HeaderPostfix = React.useMemo(
		() => ({
			children: [
				<FeatureFlag
					key="fullscreen"
					feature="forecaster-transactions-fullscreen"
				>
					<Button
						icon="external"
						size="sm"
						onClick={handleFullScreen}
						analyticsKey="forecaster.transactions.fullscreen"
						tooltip={TranslationService.get([
							'forecaster.transactions.fullscreen',
							'forecaster.transactions.fullscreen.tooltip',
						])}
					/>
				</FeatureFlag>,
				<FeatureFlag key="flip" feature="forecaster-transactions-flip">
					<Button
						icon={
							direction === 'horizontal'
								? 'arrow-bottom-left'
								: 'arrow-top-right'
						}
						size="sm"
						onClick={onToggleDirection}
						analyticsKey="forecaster.transactions.flip"
						tooltip={TranslationService.get([
							'forecaster.transactions.flip',
							`forecaster.transactions.flip.${direction}`,
							'forecaster.transactions.flip.tooltip',
							`forecaster.transactions.flip.${direction}.tooltip`,
						])}
					/>
				</FeatureFlag>,
			],
		}),
		[direction, handleFullScreen, onToggleDirection],
	);

	return (
		<Header
			onClose={handleClose}
			verticalAlign="center"
			postfix={HeaderPostfix}
		>
			<Title size="xs">
				{TranslationService.get(
					'forecaster.transactions.title',
					// eslint-disable-next-line spellcheck/spell-checker
					'Transaktioner',
				)}
			</Title>
		</Header>
	);
});

TransactionsHeader.displayName = 'TransactionsHeader';

TransactionsHeader.propTypes = {
	onAction: PropTypes.func,

	onToggleDirection: PropTypes.func,
	direction: PropTypes.string,
};

export default TransactionsHeader;
