import React from 'react';

import { Text } from '@asteria/component-core/typography';
import Wrapper, { Content } from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const NotificationEmpty = React.memo(() => {
	return (
		<Wrapper
			className={cn(
				'asteria-component__notification',
				'asteria--type-empty',
			)}
		>
			<Content>
				<Text>
					{TranslationService.get(
						['notification.empty.text'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Bra jobbat! För tillfället har du inte några händelser att agera på.',
					)}
				</Text>
			</Content>
		</Wrapper>
	);
});

NotificationEmpty.displayName = 'NotificationEmpty';

NotificationEmpty.propTypes = {};

export default NotificationEmpty;
