import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';
import Tooltip, { TooltipContent } from '@asteria/component-core/tooltip';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import NotificationWrapper from '@asteria/component-notifications';
import {
	FeatureFlag,
	useBulkFeatures,
} from '@asteria/component-tools/featureflag';
import { useFeature } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import './overview.scss';

const selectors = {
	hasCurrencies: createSelector(
		(store) => store?.app?.currencies ?? [],
		(value) => value.length > 1,
	),

	company: createSelector(
		(store) => store?.app?.company,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	accounts: createSelector(
		(store) => store?.accounts?.bankAccounts,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	notifications: createSelector(
		(store) => store?.notifications?.notifications,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const BadgeTooltip = () => {
	return (
		<TooltipContent className="asteria-component__layout-overview__amount-tooltip">
			<Text size="sm">
				{TranslationService.get(
					'overview.amount.badge.tooltip',
					// eslint-disable-next-line spellcheck/spell-checker
					'För närvarande finns en fördröjning när reserverade belopp bokförs vilket påverkar vissa användares bokförda saldo i Företagskollen.',
				)}
			</Text>
			<Text size="sm">
				{TranslationService.get(
					'overview.amount.badge.tooltip',
					// eslint-disable-next-line spellcheck/spell-checker
					'Det senaste bokförda saldot hittar du under Konton i menyvalet till vänster.',
				)}
			</Text>
		</TooltipContent>
	);
};

const Overview = React.memo(
	React.forwardRef((props, ref) => {
		const {
			onAction,
			forceNotificationOpen,
			showRight = true,
			name,
		} = props;

		// const [showOverview, setShowOverview] = useState(false);

		const notifications = useSelector(selectors.notifications);
		const hasCurrencies = useSelector(selectors.hasCurrencies);
		const company = useSelector(selectors.company);
		const accounts = useSelector(selectors.accounts);

		const hasOverviewPage = useFeature('menu-item-overview');

		const toggleOverview = useCallback(() => {
			// setShowOverview(!showOverview);
			if (hasCurrencies && !hasOverviewPage) {
				return onAction?.('go', '/overview');
			}
		}, [hasCurrencies, hasOverviewPage, onAction]);

		const total = useMemo(
			() =>
				accounts
					.filter(({ active }) => active)
					.reduce(
						(acc, object) =>
							acc + (object?.sums?.display?.total ?? 0),
						0,
					),
			[accounts],
		);

		const title = useMemo(() => {
			if (name) {
				return TranslationService.get(name) || name;
			}

			if (company?.name) {
				return company?.name;
			}

			return TranslationService.get('header.title');
		}, [name, company?.name]);

		const hasNotificationFeature = useBulkFeatures([
			['widget-notifications', 'widget-notifications-cashflow'],
		]);

		const hasTourFeature = useFeature('page-header-tour');

		return (
			<PageHeader
				ref={ref}
				className="asteria-component__header-overview"
			>
				<PageContent>
					<PageTitle
						className="asteria-component__header-overview__title"
						onClick={hasCurrencies ? toggleOverview : undefined}
					>
						<Title size="page-title">{title}</Title>
					</PageTitle>
				</PageContent>

				{showRight ? (
					<PagePostfix
						className={cn({
							'asteria--state-has-notifications':
								hasNotificationFeature,
							'asteria--state-has-tour': hasTourFeature,
						})}
					>
						<div>
							<Tooltip variant="alt">
								<TextGroup className="asteria-component__text-amount">
									<Text>
										{TranslationService.get(
											'header.account.title',
										)}
									</Text>
									<Title size="xs" weight="bold">
										{formatNumber(total)}
										<FeatureFlag feature="overview-header-amount-badge">
											<Badge
												size="sm"
												tooltip={<BadgeTooltip />}
												icon="warning"
											/>
										</FeatureFlag>
									</Title>
								</TextGroup>
								<Text>
									{TranslationService.get(
										[
											'header.account.title',
											'header.account.title.tooltip',
										],
										undefined,
										{
											value: total,
											accounts: {
												enabled: accounts.filter(
													({ active }) => active,
												),
												disabled: accounts.filter(
													({ active }) => !active,
												),
											},
										},
									)}
								</Text>
							</Tooltip>
						</div>
						<FeatureFlag feature="widget-notifications">
							<FeatureFlag feature="widget-notifications-cashflow">
								<NotificationWrapper
									forceOpen={forceNotificationOpen}
									notifications={notifications}
									onAction={onAction}
								/>
							</FeatureFlag>
						</FeatureFlag>
						<FeatureFlag feature="page-header-tour">
							<TourButton />
						</FeatureFlag>
					</PagePostfix>
				) : null}
			</PageHeader>
		);
	}),
);

Overview.displayName = 'Overview';

Overview.propTypes = {
	className: PropTypes.string,
	company: PropTypes.any,
	onAction: PropTypes.func,
	accounts: PropTypes.arrayOf(PropTypes.object),
	name: PropTypes.string,

	forceNotificationOpen: PropTypes.bool,
	showRight: PropTypes.bool,
};

export default Overview;
