import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { ACTIONS } from '../constants';

function formatTransactionDetails(details = []) {
	if (!Array.isArray(details)) {
		return details;
	}

	return details.reduce(
		(acc, object) => ({ ...acc, [object.type]: object }),
		{},
	);
}

function formatCategoryDetails(details = []) {
	if (!Array.isArray(details)) {
		return details;
	}

	return details.reduce(
		(acc, object) => ({
			...acc,
			[object.categoryId]: {
				...object,
				details: object.details.reduce(
					(acc, object) => ({
						...acc,
						[object.tagId]: object,
					}),
					{},
				),
			},
		}),
		{},
	);
}

function formatScenario(object) {
	const transactions = object?.statistics?.transactions ?? {};
	const categories = object?.statistics?.categories ?? {};

	return {
		...object,
		statistics: {
			transactions: {
				...transactions,
				details: formatTransactionDetails(transactions?.details ?? []),
			},
			categories: {
				...categories,
				details: formatCategoryDetails(categories?.details ?? []),
			},
		},
	};
}

/**
 * @template T
 * @param { T[] } data
 * @returns { { data: T[], groupedData: { [id: string]: T }, draft: { data: T, isProcessed: boolean } } }
 */
export function applyScenarioChanges(data = []) {
	const state = {
		data: [],
		groupedData: {},
		draft: { data: null, isProcessed: false },
	};

	state.data = data
		.filter(
			({ name }) =>
				!['$draft', 'asteria default scenario'].includes(
					name.toLowerCase(),
				),
		)
		.map(formatScenario);

	state.groupedData = state.data.reduce(
		(acc, object) => ({ ...acc, [object._id]: object }),
		{},
	);

	state.draft.data =
		data.find(({ name }) => name.toLowerCase() === '$draft') ?? null;

	return state;
}

const initialState = {
	data: null,
	groupedData: null,
	draft: { data: null, isProcessed: false },
	isUpdating: false,
	isRefreshed: false,
};

export const scenariosSlice = createSlice({
	name: 'scenarios',
	initialState: initialState,
	reducers: {
		startRefreshing: (state) => {
			state.isRefreshed = true;
		},
		stopRefreshing: (state) => {
			state.isRefreshed = false;
		},
		startUpdating: (state) => {
			state.isUpdating = true;
		},
		stopUpdating: (state) => {
			state.isUpdating = false;
		},
		setScenarios: (state, action) => {
			const data = (action?.payload ?? []).filter(({ name }) => name);

			const changes = applyScenarioChanges(data);

			for (const key in changes) {
				state[key] = changes[key];
			}
		},
		processDraft: (state, action) => {
			if (action.payload?.type === 'clear') {
				state.draft.data = null;
			}

			if (action.payload?.type === 'continue') {
				state.draft.isProcessed = action.payload?.value ?? true;
			}
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const {
	processDraft,
	setScenarios,
	createScenario,
	updateScenario,
	deleteScenario,
	startUpdating,
	stopUpdating,
	startRefreshing,
	stopRefreshing,
} = scenariosSlice.actions;

export const selectors = {
	scenarios: createSelector(
		(store) => store?.scenarios?.data ?? [],
		(_, options) => options,
		($data, options) => {
			let result = $data;

			if (options?.type) {
				result = result.filter(
					(object) => object?.type === options?.type,
				);
			}

			return result;
		},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	scenario: createSelector(
		(store) => store?.scenarios?.data ?? [],
		(_, ID) => ID,
		(data, ID) => data.find((object) => (object?._id ?? object?.id) === ID),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	hasScenarios: createSelector(
		(state) => (state?.scenarios?.data ?? null) !== null,
		(value) => value,
	),

	sourceScenario: createSelector(
		(state) => state?.app?.user?.settings?.flags?.scenarioId ?? null,
		(state) => state?.scenarios?.groupedData ?? {},
		(scenarioId, scenarios) => scenarios?.[scenarioId]?.name,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	scenarioId: createSelector(
		(store) => store?.app?.user?.settings?.flags?.scenarioId,
		(value) => value ?? null,
	),

	sourceScenarioData: createSelector(
		(state) => state?.app?.user?.settings?.flags?.scenarioId ?? null,
		(state) => state?.scenarios?.groupedData ?? {},
		(scenarioId, scenarios) => scenarios?.[scenarioId],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	isUpdating: createSelector(
		(state) => state?.scenarios?.isUpdating,
		(value) => value ?? false,
	),

	isRefreshed: createSelector(
		(state) => state?.scenarios?.isRefreshed,
		(value) => value ?? false,
	),
};

export default scenariosSlice.reducer;
