import { TranslationService } from '@asteria/language';
import * as FormatUtils from '@asteria/utils-funcs/format';

export function formatCategory(object) {
	return {
		type: 'tag',
		id: object?._id ?? object?.id,

		label: FormatUtils.formatTag({
			category: object?.category?.name,
			tag: object?.name,
		}),

		colors:
			object?.color ??
			FormatUtils.replace([object?.category?.name, object?.name]).join(
				'-',
			),

		object: object,
	};
}

export function formatCurrency(object) {
	return {
		type: 'currency',
		id: object?._id ?? object?.id,
		label: TranslationService.get(
			`search.selector.currency.${object?.code}`,
			object?.code,
			{ currency: object },
		),
		flag: object?.code,

		object: object,
	};
}

export function formatClient(object) {
	return {
		type: 'client',
		id: object?._id ?? object?.id,
		label: object?.name,
		object: object,
	};
}

export function formatStatus(object) {
	const status = object?.id ?? object;

	const name = TranslationService.get([
		`status.${status}`,
		`selector.status.${status}`,
	]);

	return {
		type: 'status',
		id: status,
		label: name,
		object: { id: status, code: status, name: name },
	};
}
