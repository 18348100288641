import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import XAxisLabel from '@asteria/component-graph/graphs/xaxis/label';

import XAxisPrefixItem from './item';

import '../styles.scss';

const XAxisPrefix = React.memo((props) => {
	const { types, dates, category, tag, render, renderItem } = props;

	return (
		<XAxisLabel
			label="Overall"
			className="asteria-component__forecaster-axis-prefix"
			size="sm"
		>
			<Group flex>
				{render
					? render({ types, dates, category, tag })
					: types.map((type) =>
							renderItem ? (
								renderItem({ type, dates, category, tag })
							) : (
								<XAxisPrefixItem
									key={type}
									type={type}
									dates={dates}
									category={category}
									tag={tag}
								/>
							),
					  )}
			</Group>
		</XAxisLabel>
	);
});

XAxisPrefix.displayName = 'XAxisPrefix';
XAxisPrefix.propTypes = {
	types: PropTypes.arrayOf(PropTypes.string),
	type: PropTypes.string,
	dates: PropTypes.arrayOf(PropTypes.string),
	category: PropTypes.string,
	tag: PropTypes.string,

	render: PropTypes.func,
	renderItem: PropTypes.func,
};

export default XAxisPrefix;
