import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';

import { setTimesliceSize } from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const selectors = {
	size: createSelector(
		(store) => store?.app?.timesize,
		(value) => value ?? null,
	),
};

const TimeSelectorItem = React.memo((props) => {
	const { onClick, value, active, children } = props;

	const handleClick = React.useCallback(() => {
		onClick?.(value);
	}, [onClick, value]);

	const postfix = React.useMemo(
		() =>
			active ? (
				<Group verticalAlign="center" horizontalAlign="center">
					<Button size="sm" icon="check" />
				</Group>
			) : null,
		[active],
	);

	const analytics = React.useMemo(() => ({ value: value }), [value]);

	return (
		<DropdownItem
			onClick={handleClick}
			postfix={postfix}
			active={active}
			analyticsKey="graph.selector.time.item"
			analytics={analytics}
		>
			{children}
		</DropdownItem>
	);
});

TimeSelectorItem.displayName = 'TimeSelectorItem';

TimeSelectorItem.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.string,
	active: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const TimeSelector = React.memo((props) => {
	const { size, className } = props;

	const dispatch = useDispatch();
	const value = useSelector(selectors.size, (a, b) => isEqual(a, b));

	const ToggleProps = React.useMemo(
		() => ({
			icon: 'date',
			tooltip: {
				tooltip: TranslationService.get([
					'app.timeselector.title',
					'app.timeselector.dropdown.title',
					'app.timeselector.dropdown.tooltip',
				]),
				variant: 'alt',
			},
		}),
		[],
	);

	const handleChange = React.useCallback(
		(value) => {
			dispatch(setTimesliceSize(value));
		},
		[dispatch],
	);

	return (
		<Dropdown
			toggle={ToggleProps}
			size={size}
			className={cn('asteria-component__graph__time-selector', className)}
			title={TranslationService.get([
				'app.timeselector.title',
				'app.timeselector.dropdown.title',
			])}
			analyticsKey="graph.selector.time"
		>
			<TimeSelectorItem
				value="week"
				active={value === 'week'}
				onClick={handleChange}
			>
				{TranslationService.get('app.timeselector.weeks')}
			</TimeSelectorItem>
			<TimeSelectorItem
				value="month"
				onClick={handleChange}
				active={value === 'month'}
			>
				{TranslationService.get('app.timeselector.months')}
			</TimeSelectorItem>
			<TimeSelectorItem
				value="year"
				active={value === 'year'}
				onClick={handleChange}
			>
				{TranslationService.get('app.timeselector.years')}
			</TimeSelectorItem>
		</Dropdown>
	);
});

TimeSelector.displayName = 'TimeSelector';

TimeSelector.propTypes = {
	size: PropTypes.string,
	className: PropTypes.string,
};

export default TimeSelector;
