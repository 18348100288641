import { TransactionService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import { setUserSettingsFlags } from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import Analytics from '@asteria/utils-analytics';

import * as ScenarioAPI from '../api/scenario';
import * as UserAPI from '../api/user';

/**
 * @type { import('./scenarios').onAction }
 */
export const onAction = async (options) => {
	const { event, dispatch, accessToken } = options;

	if (event?.type?.startsWith?.('scenario:')) {
		Analytics.event('scenario.action', event);
		dispatch(ScenarioStore.startUpdating());

		if (event?.type === 'scenario:create') {
			await TransactionService.scenario
				.create(
					{
						input: {
							name: event?.data?.name,
							type: event?.data?.type,
							data: event?.data?.data,
						},
						fields: 'ok error',
					},
					{ token: accessToken },
				)
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			const state = store.getState();

			const history = state?.modals?.history ?? [];

			let size = 1;

			if (
				history.some(
					({ type }) =>
						type === ModalStore.MODAL_WINDOWS.ScenarioAbout,
				)
			) {
				size = 2;
			}

			dispatch(ModalStore.close({ size: size }));
		}

		if (event?.type === 'scenario:update') {
			await TransactionService.scenario
				.update(
					{
						id: event?.data?._id,
						input: {
							name: event?.data?.name,
							type: event?.data?.type,
							data: event?.data?.data,
						},
						fields: 'ok error',
					},
					{ token: accessToken },
				)
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			dispatch(ModalStore.close());
		}

		if (event?.type === 'scenario:delete') {
			await TransactionService.scenario
				.remove({ id: event?.data?._id }, { token: accessToken })
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			const state = store.getState();
			const scenarioId =
				state?.app?.user?.settings?.flags?.scenarioId ?? null;
			const scenarios = state?.scenarios?.groupedData ?? {};

			if (scenarioId && !scenarios[scenarioId]) {
				dispatch(setUserSettingsFlags({ scenarioId: null }));

				await UserAPI.refreshSettings({
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			const history = state?.modals?.history ?? [];

			let size = 1;

			if (
				history.some(
					({ type }) =>
						type === ModalStore.MODAL_WINDOWS.ScenarioEdit,
				)
			) {
				size = 2;
			}

			dispatch(ModalStore.close({ size: size }));
		}

		if (event?.type === 'scenario:draft:clear') {
			const scenarioId = store.getState()?.scenarios?.draft?.data?._id;

			await TransactionService.scenario
				.remove({ id: scenarioId }, { token: accessToken })
				.catch(() => null)
				.then(() =>
					dispatch(ScenarioStore.processDraft({ type: 'clear' })),
				);

			dispatch(ForecasterStore.reset());
			dispatch(ModalStore.close());
		}

		if (event?.type === 'scenario:draft:continue') {
			dispatch(
				ScenarioStore.processDraft({ type: 'continue', value: true }),
			);

			dispatch(ModalStore.close());
		}

		// eslint-disable-next-line no-empty
		if (event?.type === 'scenario:tourGuide') {
		}

		if (event?.type === 'scenario:clear') {
			await TransactionService.scenario
				.clean(
					{ input: { _id: event?.data?._id } },
					{ token: accessToken },
				)
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			dispatch(ScenarioStore.startRefreshing());
			dispatch(ModalStore.close());
		}

		if (event?.type === 'scenario:current:clear') {
			const scenarioId =
				store.getState()?.app?.user?.settings?.flags?.scenarioId;

			await TransactionService.scenario
				.clean({ input: { _id: scenarioId } }, { token: accessToken })
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			dispatch(ScenarioStore.startRefreshing());
			dispatch(ModalStore.close());
		}

		if (event?.type === 'scenario:reset') {
			await TransactionService.scenario
				.reset(
					{ input: { _id: event?.data?._id } },
					{ token: accessToken },
				)
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			dispatch(ScenarioStore.startRefreshing());
			dispatch(ModalStore.close());
		}

		if (event?.type === 'scenario:current:reset') {
			const scenarioId =
				store.getState()?.app?.user?.settings?.flags?.scenarioId;

			await TransactionService.scenario
				.reset({ input: { _id: scenarioId } }, { token: accessToken })
				.then(() =>
					ScenarioAPI.fetch({
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				)
				.catch(() => null);

			dispatch(ScenarioStore.startRefreshing());
			dispatch(ModalStore.close());
		}

		dispatch(ScenarioStore.stopUpdating());
	}
};
