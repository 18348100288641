import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Transactions from '../../components/transactions';
import { useTransactionAction } from '../../components/transactions/content/group/hooks';

import { useNavigation } from './hooks';

import './styles.scss';

const TYPES = ['deposit', 'withdraw'];

const SectionTransactions = React.memo((props) => {
	const { onAction, variant } = props;

	const { type, category, tag, level } = useNavigation({ variant });

	const handleTransactionAction = useTransactionAction(onAction);

	const handleAction = React.useCallback(
		(action, data) => {
			handleTransactionAction(action, data);

			return onAction?.(action, data);
		},
		[handleTransactionAction, onAction],
	);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-transactions',
			)}
		>
			<Transactions
				onAction={handleAction}
				category={category}
				tag={tag}
				types={level === 0 ? TYPES : [type]}
				hideHeader
			/>
		</div>
	);
});

SectionTransactions.displayName = 'SectionTransactions';

SectionTransactions.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default SectionTransactions;
