import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Select, { Option } from '@asteria/component-form/select';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const selectors = {
	currencies: createSelector(
		(store) => store?.app?.settings?.currencies,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const CurrencySelector = React.memo(
	React.forwardRef((props, ref) => {
		const { name } = props;

		const currencies = useSelector(selectors.currencies);

		const search = React.useMemo(
			() => ({
				path: 'value',
				toggle: true,
				placeholder: 'forecaster.currencies.search.placeholder',
				empty: 'forecaster.currencies.search.empty',
			}),
			[],
		);

		return (
			<Select
				name={name}
				className={cn(
					'forecaster-list-currency-select',
					'asteria-component__forecaster-selector',
				)}
				scroll
				search={search}
				ref={ref}
			>
				{currencies?.map((currency) => (
					<Option key={currency} value={currency}>
						<div className="forecaster-list-currency-option">
							<div
								className={`currency-flag currency-flag-${currency?.toLowerCase()}`}
							/>
							<Text size="sm">
								{TranslationService.get(
									`transaction.selector.currency.${currency}`,
									currency,
									{ currency: currency },
								)}
							</Text>
						</div>
					</Option>
				))}
			</Select>
		);
	}),
);

CurrencySelector.displayName = 'CurrencySelector';

CurrencySelector.propTypes = {
	name: PropTypes.string,
};

export default CurrencySelector;
