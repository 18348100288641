import { useStore } from 'react-redux';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
	formatResponse,
	validateFormValues,
} from '@asteria/component-forecaster/utils';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

export function useRequest({ onSubmit }) {
	return useQuery({
		queryKey: ['card', 'forecast-actions'],
		queryFn: async () => {
			return await onSubmit?.('card:fetch', {
				type: 'forecast-actions',
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,
	});
}

export function useRemoveMutation({ onSubmit }) {
	return useMutation({
		mutationFn: async (actionId) =>
			onSubmit?.('forecast:actions:delete', actionId),
	});
}

export function useForecasterFuture({ onSubmit }) {
	const store = useStore();

	const sourceScenario = ScenarioStore.selectors.sourceScenario(
		store.getState(),
	);

	const sourceScenarioData = ScenarioStore.selectors.sourceScenarioData(
		store.getState(),
	);

	return useQuery({
		queryKey: ['forecaster', 'future', { sourceScenario }],
		queryFn: async () =>
			onSubmit?.('forecaster:changes', {
				scenarioName: sourceScenario,
			})
				.then((response) => {
					return formatResponse(response, { store });
				})
				.then((data) => ({ ...data, scenario: sourceScenario }))
				.then((data) => validateFormValues(data, sourceScenarioData))
				.then((data) => {
					return data;
				}),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		placeholderData: null,
	});
}
