import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';
import { Option, Select } from '@asteria/component-form';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

// import { useCategories } from '../../../hooks';
import * as utils from '../../../../utils';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'onAction' | 'onSubmit'> & Pick<Props, 'type' | 'category' | 'tag' | 'custom' | 'onUpdate'>> } */
const RowCategory = React.memo(function RowCategory({
	type,
	category,
	tag,
	edit,
	onUpdate,
	onAction,
}) {
	const { getValues, setValue, reset } = useFormContext();

	// const categories = useCategories({ type });

	const onLabelClick = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: 'forecast',
				data: {
					section: 'details',
					type: type,
					category: category,
					tag: tag,
				},
			}),
		[category, onAction, tag, type],
	);

	// const current = useSelector(
	// 	(store) => {
	// 		const tags = store?.app?.tags ?? [];

	// 		const object = tags.find(
	// 			(object) =>
	// 				object?.category?.name === category && object?.name === tag,
	// 		);

	// 		return { color: object?.color, id: object?._id ?? object?.id };
	// 	},
	// 	(a, b) => isEqual(a, b),
	// );

	const available = useSelector(
		(store) =>
			ForecasterStore.selectors.tags
				.available(store, {
					type,
					// exclude: categories,
				})
				.map((object) => ({
					category: object?.category?.name,
					tag: object?.name,
					color: object?.color,
					id: object?._id ?? object?.id,
				})),
		(a, b) => isEqual(a, b),
	);

	const onChange = React.useCallback(
		({ value: target }) => {
			if (target === '$create') {
				return onAction?.('category:create');
			}

			const changes = utils.form.tag.move(
				{ type, source: { category, tag }, target, recalculate: true },
				{ getValues, setValue, reset },
			);

			utils.form.changes.apply(changes, { getValues, setValue, reset });

			onUpdate?.(target);
		},
		[category, getValues, onAction, onUpdate, reset, setValue, tag, type],
	);

	const updateCategory = React.useCallback(
		(id) => () => onAction?.('category:update', id),
		[onAction],
	);

	if (!edit) {
		return (
			<Button
				variant="link"
				size="sm"
				label={FormatUtils.formatTag({ type, category, tag })}
				onClick={onLabelClick}
			/>
		);
	}

	return (
		<Select
			uncontrolled
			value={{ category, tag }}
			size="sm"
			onChange={onChange}
			scroll
			className={cn(
				'asteria-component__selector',
				'asteria-component__card-selector',
				'asteria--type-category',
			)}
		>
			{[]
				// .concat({ category, tag, color: current.color, id: current.id })
				.concat(available)
				.map(({ category, tag, color, id }) => (
					<Option
						key={[category, tag].join('-')}
						value={{ category, tag }}
						render={({ value }) => {
							const { category, tag } = value;

							return FormatUtils.formatTag({
								type: type,
								category: category,
								tag: tag,
							});
						}}
						postfix={({ active, size }) =>
							active || category === '$custom' ? (
								<Group
									verticalAlign="center"
									horizontalAlign="center"
									className={cn({
										'asteria--state-active': active,
										'asteria--state-custom':
											category === '$custom',
									})}
								>
									{active ? (
										<Button
											className="asteria--type-check"
											size={size}
											icon="check"
										/>
									) : null}
									{category === '$custom' ? (
										<Button
											className="asteria--type-edit"
											size={size}
											icon="edit"
											onClick={updateCategory(id)}
										/>
									) : null}
								</Group>
							) : null
						}
					>
						<Chip
							colors={
								color ??
								FormatUtils.replace([category, tag]).join('-')
							}
							label={FormatUtils.formatTag({
								type: type,
								category: category,
								tag: tag,
							})}
							variant="simple"
							size="sm"
							className="pointer-events-none"
						/>
					</Option>
				))}
			<Option value="$create" className="asteria--variant-create">
				<Button
					label={TranslationService.get([
						'forecaster.tags.dropdown.create',
					])}
					size="sm"
					iconSize="sm"
					icon="add"
					variant="link"
				/>
			</Option>
		</Select>
	);
});

RowCategory.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	edit: PropTypes.bool,
	onUpdate: PropTypes.func,
};

export default RowCategory;
