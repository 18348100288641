import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Item = React.forwardRef((props, ref) => {
	const { className, children, ...rest } = props;

	return (
		<div
			className={cn(
				'asteria-component__list__item',
				className,
				sizeClasses(props),
			)}
			ref={ref}
			{...rest}
		>
			{children}
		</div>
	);
});

Item.displayName = 'Item';
Item.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	size: SizeProp(),
};

Item.defaultProps = {};

export default React.memo(Item);
