import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation } from '@asteria/language';

import Loader from '../Loader';

import './styles.scss';

const Loading = (props) => {
	const { className } = props;

	const hasDelayDoneFeature = useFeature('welcome-delay-done');

	const hasConnectedBanks = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				connected: true,
				status: 'IDLE',
			}).length,
	);

	const [hasDelayedBank, setDelayedBank] = React.useState(
		() => hasDelayDoneFeature && hasConnectedBanks,
	);

	const { hasERP, hasBank } = useSelector(
		(store) => {
			const integrations = IntegrationStore.selectors.integrations(
				store,
				{ status: 'INITIATING' },
			);

			const hasERP = integrations.some(({ type }) => type === 'erp');
			const hasBank =
				hasDelayedBank ||
				integrations.some(({ type }) => type === 'bank');

			return { hasERP: hasERP, hasBank: hasBank };
		},
		(a, b) => isEqual(a, b),
	);

	React.useEffect(() => {
		if (hasDelayDoneFeature && hasConnectedBanks) {
			setDelayedBank(true);
		}
	}, [hasConnectedBanks, hasDelayDoneFeature]);

	React.useEffect(() => {
		if (hasDelayedBank) {
			const timeout = setTimeout(() => {
				setDelayedBank(false);
			}, 10_000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [hasDelayedBank]);

	const keys = React.useMemo(
		() => [
			'welcome.placeholder.loading.text',
			hasERP ? 'welcome.placeholder.loading.erp.text' : null,
			hasBank ? 'welcome.placeholder.loading.bank.text' : null,
			hasBank && hasERP ? 'welcome.placeholder.loading.both.text' : null,
		],
		[hasBank, hasERP],
	);

	return (
		<Group
			className={className}
			verticalAlign="center"
			horizontalAlign="center"
		>
			<Loader variant="graph" />
			<Loader variant="category" />
			<Translation
				translationKey={keys}
				Component={Text}
				align="center"
			/>
		</Group>
	);
};

Loading.displayName = 'Loading';
Loading.propTypes = { className: PropTypes.string };

export default React.memo(Loading);
