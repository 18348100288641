import React from 'react';

import PropTypes from 'prop-types';

import '../styles.scss';

const Pattern = React.memo(({ variant = 'outer' }) => {
	return (
		<defs>
			<pattern
				id={['spread-normal-pattern', variant].join('-')}
				width="8"
				height="10"
				patternUnits="userSpaceOnUse"
				patternTransform="rotate(45)"
			>
				<rect
					height="10"
					width="8"
					className="asteria-color__spread-normal"
				/>
				<line
					y2="10"
					x1="1"
					x2="1"
					className="asteria-color__spread-normal-stripe"
				/>
				<line
					y2="10"
					x1="5"
					x2="5"
					className="asteria-color__spread-normal-stripe"
				/>
			</pattern>
			<pattern
				id="spread-negative-pattern"
				width="8"
				height="10"
				patternUnits="userSpaceOnUse"
				patternTransform="rotate(45)"
			>
				<rect
					height="10"
					width="8"
					className="asteria-color__spread-negative"
				/>
				<line
					y2="10"
					x1="1"
					x2="1"
					className="asteria-color__spread-negative-stripe"
				/>
				<line
					y2="10"
					x1="5"
					x2="5"
					className="asteria-color__spread-negative-stripe"
				/>
			</pattern>
		</defs>
	);
});

Pattern.displayName = 'Pattern';
Pattern.propTypes = { variant: PropTypes.oneOf(['outer', 'inner']) };

export default Pattern;
