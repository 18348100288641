import { PartnerService } from '@asteria/backend-utils-services';

import { setPartner } from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export async function fetch(options = {}) {
	const { accessToken, dispatch, partnerId } = options;

	const partner = await PartnerService.partner
		.fetchOne(
			{ isBulk: true, id: partnerId, fields: `id name` },
			{ token: accessToken },
		)
		.catch(() => {});

	TranslationService.updateGlobals({ partner });

	dispatch?.(setPartner(partner));

	return partner;
}
