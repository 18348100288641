export const findActive = (config, path, level = 0) => {
	if (!path.length) {
		return { config: config, level: level };
	}

	const nested = config.children.find((object) => object?.id === path[0]);

	if (nested?.children?.length) {
		return findActive(nested, path.slice(1), level + 1);
	}

	return { config: config, level: level };
};
