import React from 'react';

import BaseGroup from '@asteria/component-core/group';
import BaseWrapper from '@asteria/component-core/wrapper';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';

const Group = (props) => {
	const { className } = props;

	return (
		<BaseGroup
			{...props}
			className={cn('asteria-component__form-wrapper__group', className)}
		/>
	);
};

Group.propTypes = { ...BaseGroup.propTypes };

Group.displayName = 'FormGroup';

const Wrapper = (props) => {
	const { className } = props;

	return (
		<BaseWrapper
			{...props}
			className={cn('asteria-component__form-wrapper', className)}
		/>
	);
};

Wrapper.propTypes = { ...BaseWrapper.propTypes };

Wrapper.displayName = 'FormWrapper';

ComponentService.register('FormWrapper', Wrapper, {
	scroll: { type: 'boolean' },
	variant: { type: 'enum', options: ['vertical', 'horizontal'] },
	className: { type: 'string' },
	children: { type: 'node' },
	border: { type: 'boolean' },
	collapse: { type: 'boolean' },
	open: { type: 'boolean' },
	onOpen: { type: 'function' },
	onClose: { type: 'function' },
	divider: { type: 'boolean' },

	compare: { type: 'function' },
	loading: { type: 'boolean' },
});

ComponentService.register('FormGroup', Group, {
	className: { type: 'string' },
	children: { type: 'node' },
	direction: { type: 'enum', options: ['vertical', 'horizontal'] },
	verticalAlign: {
		type: 'enum',
		options: ['top', 'center', 'bottom', 'baseline'],
	},
	horizontalAlign: {
		type: 'enum',
		options: ['left', 'center', 'right', 'space-between'],
	},
	cols: { type: 'number' },
	rows: { type: 'number' },
	style: { type: 'object' },
	flex: { type: 'boolean' },
});

export default Wrapper;
export { Group };
