import { useContext, useMemo } from 'react';

import { format, parseISO } from 'date-fns';

import { useFormValues } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { getLabels } from '@asteria/utils-funcs/graph';

import LoanContext from '../../../../context/loan';
import { getData } from '../../../../utils/loan';

export function useGraphData() {
	const { dates, range } = useContext(LoanContext);

	const [type, amount, term, rate, timestamp] = useFormValues({
		name: ['type', 'amount', 'term', 'rate', 'timestamp'],
	});

	let fmt = 'yyyy-MM-dd';

	if (range === 'year') {
		fmt = 'yyyy';
	}

	const data = useMemo(
		() =>
			getData({
				type,
				amount: Number.parseFloat(amount),
				term: Number.parseFloat(term),
				rate: Number.parseFloat(rate),
				timestamp: timestamp ? parseISO(timestamp) : new Date(),
			}).reduce((acc, object) => {
				const date = format(object?.date, fmt);

				if (acc[date] === undefined) {
					acc[date] = [];
				}

				acc[date].push(object);

				return acc;
			}, {}),
		[amount, fmt, rate, term, timestamp, type],
	);

	return useMemo(
		() =>
			dates.slice(1, -1).map((date) => {
				const $date = format(date, fmt);

				const amortization = (data[$date] ?? []).reduce(
					(acc, object) => acc + (object?.amortization || 0),
					0,
				);
				const interest = (data[$date] ?? []).reduce(
					(acc, object) => acc + (object?.interest || 0),
					0,
				);

				return {
					date: date,
					data: {
						AMORTIZATION: { total: amortization },
						INTEREST: { total: interest },
					},
				};
			}),
		[data, dates, fmt],
	);
}

export function useYAxis({ data }) {
	const max = Math.max(
		...Object.values(
			data.reduce(
				(acc, object) => ({
					...acc,
					[object?.date]: Object.values(object?.data ?? {}).reduce(
						(acc, object) => acc + (object?.total || 0),
						0,
					),
				}),
				{},
			),
		),
	);

	const scale = useMemo(() => {
		if (!max) {
			return 0;
		}

		return Math.max(Math.floor(Math.floor(Math.log10(max)) / 3) - 1, 0);
	}, [max]);

	const labels = useMemo(() => {
		const labels = getLabels({
			max: max ? max : 5_000,
			min: 0,
			steps: 4,
			scale: scale,
			prefix: null,
		});

		// labels.reverse();

		return labels.map((object, index) => ({
			...object,
			size: index ? 'medium' : undefined,
			label: object.value ? object.label : '0',
		}));
	}, [max, scale]);

	const labelsMin = Math.min(...labels.map(({ value }) => value));
	const labelsMax = Math.max(...labels.map(({ value }) => value));

	return {
		labels,
		min: labelsMin,
		max: labelsMax,
		title: TranslationService.get(
			[
				'yaxis.label',
				`yaxis.label.SEK`,
				`yaxis.label.${scale}`,
				`yaxis.label.SEK.${scale}`,
			],
			'TKr',
		),
	};
}

export function useLegends() {
	return useMemo(
		() => [
			{
				type: ['amortization'],
				title: TranslationService.get('graph.legend.amortization'),
			},
			{
				type: ['interest'],
				title: TranslationService.get('graph.legend.interest'),
			},
			{
				type: ['forecast'],
				title: TranslationService.get([
					'tags.forecast',
					'graph.legend.forecast',
				]),
			},
		],
		[],
	);
}
