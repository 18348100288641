import React from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import useWrapper from '@asteria/utils-hooks/useWrapper';

import SnackbarWrapper from './SnackbarWrapper';

import './styles.scss';

const SnackbarWrapperPortal = React.memo((props) => {
	const { onAction } = props;

	const wrapper = useWrapper('asteria-snackbar-container');

	return ReactDOM.createPortal(
		<SnackbarWrapper onAction={onAction} />,
		wrapper,
	);
});

SnackbarWrapperPortal.displayName = 'SnackbarWrapperPortal';

SnackbarWrapperPortal.propTypes = {
	onAction: PropTypes.func,
};

SnackbarWrapperPortal.defaultProps = {};

export default SnackbarWrapperPortal;
