import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

function isSameTag(object, categoryName, tagName) {
	return object?.name === tagName && object?.category?.name === categoryName;
}

export const available = createSelector(
	(store) => store?.app?.tags ?? [],
	(store, options = {}) => ({
		...options,
		type: options?.type ?? store?.forecaster?.navigation?.type ?? null,
	}),
	(tags, { type, exclude = [], include = [] }) => {
		if (type === 'deposit') {
			return tags
				.filter(
					(object) =>
						!isSameTag(object, '$invoices', '$supplier') &&
						!isSameTag(object, '$withdraw', '$misc') &&
						!isSameTag(object, '$salaries', '$salary'),
				)
				.filter(
					(object) =>
						!exclude.some((value) =>
							isSameTag(object, value.category, value.tag),
						),
				)
				.concat(
					include
						.filter(({ type }) => type === 'deposit')
						.map((object) => ({
							name: object?.tag,
							category: { name: object?.category },
						})),
				)
				.map((object) => ({ ...object, type: 'deposit' }));
		}

		if (type === 'withdraw') {
			return tags
				.filter(
					(object) =>
						!isSameTag(object, '$invoices', '$customer') &&
						!isSameTag(object, '$deposit', '$misc'),
				)
				.filter(
					(object) =>
						!exclude.some((value) =>
							isSameTag(object, value.category, value.tag),
						),
				)
				.concat(
					include
						.filter(({ type }) => type === 'withdraw')
						.map((object) => ({
							name: object?.tag,
							category: { name: object?.category },
						})),
				)
				.map((object) => ({ ...object, type: 'withdraw' }));
		}

		return tags
			.filter(
				(object) =>
					!exclude.some((value) =>
						isSameTag(object, value.category, value.tag),
					),
			)
			.flatMap((object) => {
				if (
					isSameTag(object, '$invoices', '$customer') ||
					isSameTag(object, '$deposit', '$misc')
				) {
					return { ...object, type: 'deposit' };
				}

				if (
					isSameTag(object, '$invoices', '$supplier') ||
					isSameTag(object, '$withdraw', '$misc') ||
					isSameTag(object, '$salaries', '$salary')
				) {
					return { ...object, type: 'withdraw' };
				}

				return [
					{ ...object, type: 'deposit' },
					{ ...object, type: 'withdraw' },
				];
			});
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const active = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(store, options) => ({
		...options,
		type: options?.type ?? store?.forecaster?.navigation?.type,
	}),
	(tags, { type, exclude }) => {
		let available = [];

		if (type === 'warning') {
			available = tags.filter((object) => object?.alert);
		} else if (type === 'available') {
			available = tags.filter(
				(object) => object?.categoryName !== '$type',
			);
		} else {
			available = tags.filter((object) => object?.type === type);
		}

		if (exclude?.length) {
			available = available.filter((object) => {
				return !exclude.some((exclude) => {
					const isSameType = object?.type === exclude?.type;
					const isSameCategory =
						object?.categoryName === exclude?.category;
					const isSameTag = object?.tagName === exclude?.tag;

					if (exclude?.type && exclude?.category && exclude?.tag) {
						return isSameType && isSameCategory && isSameTag;
					}

					if (exclude?.category && exclude?.tag) {
						return isSameCategory && isSameTag;
					}

					if (exclude?.type) {
						return isSameType;
					}

					if (exclude?.category) {
						return isSameCategory;
					}

					if (exclude?.tag) {
						return isSameTag;
					}

					return false;
				});
			});
		}

		return available;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const grouped = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(tags) =>
		tags.reduce((acc, object) => {
			const type = object?.type;
			const category = object?.categoryName;
			const tag = object?.tag;

			if (acc?.[type] === undefined) {
				acc[type] = {};
			}

			if (acc[type][category] === undefined) {
				acc[type][category] = {};
			}

			acc[type][category][tag] = object;

			return acc;
		}, {}),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const deposit = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(tags) => tags.filter((object) => object?.type === 'deposit'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const withdraw = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(tags) => tags.filter((object) => object?.type === 'withdraw'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const color = createSelector(
	(store) => store?.app?.tags ?? [],
	(_, options) => options ?? {},
	(tags, { categoryName, tagName }) =>
		tags.find(
			(object) =>
				object?.name === tagName &&
				object?.category?.name === categoryName,
		)?.color ?? null,
);

export const object = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(_, options) => options,
	(tags, { type, categoryName, tagName }) =>
		tags?.find?.(
			(object) =>
				object?.type === type &&
				object?.categoryName === categoryName &&
				object?.tagName === tagName,
		) ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isTagCompleted = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(_, { type, category, tag }) => ({ type, category, tag }),
	(tags, { type, category, tag }) =>
		(tags ?? []).some(
			(object) =>
				object?.type === type &&
				object?.categoryName === category &&
				object?.tagName === tag &&
				object?.isCompleted,
		),
);

export const isTypeCompleted = createSelector(
	(store) => store?.forecaster?.tags ?? [],
	(_, { type, exclude }) => ({ type: type, exclude: exclude ?? [] }),
	(tags, { type, exclude }) => {
		let response = (tags ?? []).filter((object) => object?.type === type);

		if (exclude?.length) {
			response = response.filter(
				(object) =>
					!exclude.find((value) => {
						const type = value?.type;
						const category = value?.category;
						const tag = value?.tag;

						return (
							(!type || object?.type === type) &&
							(!category || object?.categoryName === category) &&
							(!tag || object?.tagName === tag)
						);
					}),
			);
		}

		return response.every((object) => object?.isCompleted);
	},
);

export const isCompleted = createSelector(
	(store) =>
		(store?.forecaster?.tags ?? []).reduce(
			(acc, { type, isCompleted, isRemoved }) => {
				if (acc[type] === undefined) {
					acc[type] = [];
				}

				acc[type].push(isCompleted || isRemoved);

				return acc;
			},
			{ deposit: [], withdraw: [] },
		),
	({ deposit, withdraw }) => ({
		deposit: deposit.length > 0 && deposit.every(Boolean),
		withdraw: withdraw.length > 0 && withdraw.every(Boolean),
	}),
);
