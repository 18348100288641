import React from 'react';

import PropTypes from 'prop-types';

import { View as LineGraphView } from '@asteria/component-forecaster/components/graph/lines';

import { cn } from '@asteria/utils-funcs/classes';

import LoanLineGraphColumn from './column';
import { useGraphData, useLegends, useYAxis } from './hooks';

import './styles.scss';

const LoanLineGraph = React.memo((props) => {
	const { className } = props;

	const data = useGraphData();
	const { labels, min, max, title } = useYAxis({ data });
	const legends = useLegends();

	return (
		<LineGraphView
			className={cn('asteria-component__financial-loan-graph', className)}
			labels={labels}
			min={min}
			max={max}
			title={title}
			showYAxis
			showLines
			legends={legends}
			showLegends
			data={data}
			renderItem={(props) => <LoanLineGraphColumn {...props} />}
		/>
	);
});

LoanLineGraph.displayName = 'LoanLineGraph';

LoanLineGraph.propTypes = { className: PropTypes.string };

export default LoanLineGraph;
