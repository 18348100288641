import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const Button = (props) => {
	const { children, loading, disabled } = props;

	return (
		<button
			className={cn(
				'asteria-component__button',
				'asteria--size-md',
				'asteria-component__button--variant-primary',
				{ 'asteria--state-disabled': disabled },
				{ 'asteria--state-loading': loading },
			)}
			disabled={disabled}
			type="submit"
		>
			<p
				className="asteria-component__typography asteria-component__text asteria-component__text--size-md"
				component="button-text"
			>
				{children}
			</p>
			{loading ? (
				<div className="asteria-component__progress asteria-component__progress--variant-circle asteria-component__progress--variant-circle-indeterminate asteria-component__spinner">
					<div className="asteria-component__progress__value"></div>
				</div>
			) : null}
		</button>
	);
};

Button.displayName = 'Button';

Button.propTypes = {
	children: PropTypes.node,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default Button;
