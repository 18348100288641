import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './error.scss';

const Error = React.memo((props) => {
	const {
		className,
		name,
		formNamePrefix,
		error = null,
		level,
		...alertProps
	} = props;

	if (!error) {
		return null;
	}

	return (
		<Alert
			className={cn(className, 'asteria-component__form-error')}
			level={level ?? error?.type ?? 'warning'}
			{...alertProps}
		>
			<Text>
				{TranslationService.get(
					[
						`form.error.label`,
						`form.error.label.${error.type}`,
						`form.${name}.error.label`,
						`form.${name}.error.label.${error.type}`,
						...(formNamePrefix
							? [
									`form.${formNamePrefix}.error.label`,
									`form.${formNamePrefix}.error.label.${error.type}`,
									`form.${formNamePrefix}.${name}.error.label`,
									`form.${formNamePrefix}.${name}.error.label.${error.type}`,
							  ]
							: []),
					],
					error.message || error.type,
					error,
				)}
			</Text>
		</Alert>
	);
});

Error.displayName = 'Error';

Error.propTypes = {
	className: PropTypes.string,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	icon: PropTypes.string,
	name: PropTypes.string,
	formNamePrefix: PropTypes.string,
	level: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	dismiss: PropTypes.bool,
	onDismiss: PropTypes.func,
};

Error.defaultProps = {
	className: null,
};

export default Error;
