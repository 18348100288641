export * from './sorting';
export * from './groups';

export function compareDates(reverse) {
	return function (a, b) {
		const source = Number.parseInt(a.replace(/-/g, ''));
		const target = Number.parseInt(b.replace(/-/g, ''));

		if (reverse) {
			return source - target;
		}

		return target - source;
	};
}
