import React from 'react';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { TooltipContent } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import { useFormValues, useManualFormValues } from '@asteria/component-form';
import Prefix from '@asteria/component-prefix';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useAccumulativeForm, useDate } from '../../../hooks';
import Context from '../../../logic/context';
import { getPath } from '../../../utils/getFormPaths';

import './styles.scss';

const AccountBalanceTooltipSection = (props) => {
	const { type, date, value /* min, max */ } = props;

	const { isCurrent, isPast, isFuture } = useDate(date);

	const colors = React.useMemo(() => {
		if (type === 'overview') {
			return 'account asteria-color__forecast';
		}

		return type;
	}, [type]);

	return (
		<div
			className={cn('asteria-component__forecaster-tooltip__section', {
				[`asteria-component__forecaster-tooltip__section--type-${type}`]:
					type,
			})}
		>
			<Group direction="horizontal" verticalAlign="center">
				<Prefix colors={colors} size="sm" />
				<Text size="sm">
					{TranslationService.get(
						[
							`forecaster.account.balance.label`,
							`forecaster.account.balance.${type}.label`,
						]
							.concat(
								isCurrent
									? [
											`forecaster.account.balance.current.label`,
											`forecaster.account.balance.current.${type}.label`,
									  ]
									: [],
							)
							.concat(
								isFuture
									? [
											`forecaster.account.balance.future.label`,
											`forecaster.account.balance.future.${type}.label`,
									  ]
									: [],
							)
							.concat(
								isPast
									? [
											`forecaster.account.balance.past.label`,
											`forecaster.account.balance.past.${type}.label`,
									  ]
									: [],
							),
					)}
				</Text>
				<Text align="right" size="sm">
					{TranslationService.get(
						[
							`forecaster.account.balance.total`,
							`forecaster.account.balance.${type}.total`,
						],
						'{{ total | number }}',
						{ total: value },
					)}
				</Text>
			</Group>
			{/* {['deposit', 'withdraw'].includes(type) &&
			(isCurrent || isFuture) &&
			min !== max ? (
				<Group
					direction="horizontal"
					verticalAlign="center"
					className="asteria-component__forecaster-tooltip__section--type-spread"
				>
					<span className="asteria-component__forecaster-tooltip__section__empty" />
					<Text size="xs">
						{TranslationService.get(
							[
								'forecaster.account.balance.spread',
								`forecaster.account.balance.${type}.spread`,
							],
							undefined,
							{ min: min, max: max },
						)}
					</Text>
				</Group>
			) : null} */}
		</div>
	);
};

AccountBalanceTooltipSection.displayName = 'AccountBalanceTooltipSection';

AccountBalanceTooltipSection.propTypes = {
	type: PropTypes.string,
	date: PropTypes.string,

	value: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
};

function useOverviewValues({ date }) {
	const { history: historyForm } = React.useContext(Context);

	const { isPast } = useDate(date);

	const { history, future } = useAccumulativeForm({
		useForm: useFormValues,
		history: historyForm,
	});

	let value = history?.[date] ?? future?.[date];

	if (!value) {
		if (isPast) {
			value = history?.$last;
		} else {
			value = future?.$last;
		}
	}

	return React.useMemo(
		() => ({ value: value?.value /* min: value?.min, max: value?.max */ }),
		[value?.value],
	);
}

function useTypedValues({ type, date }) {
	const { history: historyForm } = React.useContext(Context);

	/* const { object } = Object.entries(usePredictions()).reduce(
		(acc, [$date, object]) => {
			const diff = Math.abs(new Date(date) - new Date($date));

			if (diff < acc.diff) {
				acc.diff = diff;
				acc.object = object;
			}

			return acc;
		},
		{ diff: Number.MAX_VALUE, object: null },
	); */

	const future = useManualFormValues({
		name: getPath({
			type: type,
			date: date,
			status: 'FORECAST',
			field: 'value',
		}),
	});

	const history = get(
		historyForm,
		getPath({ type: type, date: date, status: 'PAID', field: 'value' }),
	);

	const value = (future || 0) + (history || 0);

	/* let min = value + (object?.min ?? 0),
		max = value + (object?.max ?? 0); */

	return React.useMemo(
		() => ({ value: value /* min: min, max: max */ }),
		[value],
	);
}

const AccountBalanceTooltip = (props) => {
	const { className, date } = props;

	const hasHorizontalFeature = useFeature(
		'forecaster-account-balance-horizontal-tooltip',
	);

	const direction = hasHorizontalFeature ? 'horizontal' : 'vertical';

	const headerTitle = TranslationService.get(
		['forecaster.account.balance.header'],
		// eslint-disable-next-line spellcheck/spell-checker
		'Kontobalans',
	);

	const overview = useOverviewValues({ date });
	const deposit = useTypedValues({ date, type: 'deposit' });
	const withdraw = useTypedValues({ date, type: 'withdraw' });

	return (
		<TooltipContent
			className={cn(
				'asteria-component__forecaster-tooltip',
				'asteria-component__forecaster-tooltip--type-account',
				{ [`asteria--direction-${direction}`]: direction },
				className,
			)}
		>
			<Wrapper>
				{headerTitle ? (
					<Header>
						<Text weight="medium" align="center" size="sm">
							{headerTitle}
						</Text>
					</Header>
				) : null}

				<Content>
					<Group direction="vertical">
						<AccountBalanceTooltipSection
							type="overview"
							date={date}
							value={overview?.value}
							// min={overview?.min}
							// max={overview?.max}
						/>
						<Group direction={direction}>
							<AccountBalanceTooltipSection
								type="deposit"
								date={date}
								value={deposit?.value}
								// min={deposit?.min}
								// max={deposit?.max}
							/>
							<AccountBalanceTooltipSection
								type="withdraw"
								date={date}
								value={withdraw?.value}
								// min={withdraw?.min}
								// max={withdraw?.max}
							/>
						</Group>
					</Group>
				</Content>
			</Wrapper>
		</TooltipContent>
	);
};

AccountBalanceTooltip.displayName = 'AccountBalanceTooltip';

AccountBalanceTooltip.propTypes = {
	className: PropTypes.string,
	date: PropTypes.string,
};

export default AccountBalanceTooltip;
