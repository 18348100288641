import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { useLineType } from '../../hooks';

import Pattern from './pattern';

import '../styles.scss';

const View = React.memo((props) => {
	const {
		line,
		previousLine = line,
		nextLine = line,
		max,
		min,
		height = 100,

		hiddenSpread,
		invisibleSpread,

		hiddenLineHistory,
		invisibleLineHistory,

		hiddenLineFuture,
		invisibleLineFuture,

		type,
	} = props;

	const { date, value, max: lineMax = value, min: lineMin = value } = line;

	const {
		value: previousValue,
		max: prevLineMax = previousValue,
		min: prevLineMin = previousValue,
	} = previousLine;

	const {
		value: nextValue,
		max: nextLineMax = nextValue,
		min: nextLineMin = nextValue,
	} = nextLine;

	const lineType = useLineType(date, type);

	const range = max - min;

	const y1 = height - ((previousValue - min) / range) * height;
	const yPrevMax = height - ((prevLineMax - min) / range) * height;
	const yPrevMin = height - ((prevLineMin - min) / range) * height;
	const yPrevInnerMax = yPrevMax + (yPrevMin - yPrevMax) * 0.25;
	const yPrevInnerMin = yPrevMin - (yPrevMin - yPrevMax) * 0.25;

	const y2 = height - ((value - min) / range) * height;
	const yMax = height - ((lineMax - min) / range) * height;
	const yMin = height - ((lineMin - min) / range) * height;
	const yInnerMax = yMax + (yMin - yMax) * 0.25;
	const yInnerMin = yMin - (yMin - yMax) * 0.25;

	const y3 = height - ((nextValue - min) / range) * height;
	const yNextMax = height - ((nextLineMax - min) / range) * height;
	const yNextMin = height - ((nextLineMin - min) / range) * height;
	const yNextInnerMax = yNextMax + (yNextMin - yNextMax) * 0.25;
	const yNextInnerMin = yNextMin - (yNextMin - yNextMax) * 0.25;

	const x1 = -50;
	const x2 = 50;
	const x3 = 150;

	const isCurrentOrHistory = ['history', 'current'].includes(lineType);
	const isCurrentOrFuture = ['current', 'future'].includes(lineType);

	return (
		<g>
			<g
				className={cn(
					'asteria-component__graph-spread',
					'asteria--variant-outer',
				)}
			>
				<Pattern variant="outer" />
				<polygon
					className={cn({
						'asteria--state-hidden':
							hiddenSpread && (invisibleSpread ?? true),
						'asteria--state-invisible': invisibleSpread,
					})}
					points={[
						[x1, yPrevMax].join(','),
						[x2, yMax].join(','),
						[x2, yMin].join(','),
						[x1, yPrevMin].join(','),
					].join(' ')}
					style={{
						fill: `url(#spread-normal-pattern-outer)`,
					}}
				/>
				<polygon
					className={cn({
						'asteria--state-hidden':
							hiddenSpread && (invisibleSpread ?? true),
						'asteria--state-invisible': invisibleSpread,
					})}
					points={[
						[x2, yMax].join(','),
						[x3, yNextMax].join(','),
						[x3, yNextMin].join(','),
						[x2, yMin].join(','),
					].join(' ')}
					style={{
						fill: `url(#spread-normal-pattern-outer)`,
					}}
				/>
			</g>

			<g
				className={cn(
					'asteria-component__graph-spread',
					'asteria--variant-inner',
				)}
			>
				<Pattern variant="inner" />
				<polygon
					className={cn({
						'asteria--state-hidden':
							hiddenSpread && (invisibleSpread ?? true),
						'asteria--state-invisible': invisibleSpread,
					})}
					points={[
						[x1, yPrevInnerMax].join(','),
						[x2, yInnerMax].join(','),
						[x2, yInnerMin].join(','),
						[x1, yPrevInnerMin].join(','),
					].join(' ')}
					style={{
						fill: `url(#spread-normal-pattern-inner)`,
					}}
				/>
				<polygon
					className={cn({
						'asteria--state-hidden':
							hiddenSpread && (invisibleSpread ?? true),
						'asteria--state-invisible': invisibleSpread,
					})}
					points={[
						[x2, yInnerMax].join(','),
						[x3, yNextInnerMax].join(','),
						[x3, yNextInnerMin].join(','),
						[x2, yInnerMin].join(','),
					].join(' ')}
					style={{
						fill: `url(#spread-normal-pattern-inner)`,
					}}
				/>
			</g>

			<line
				className={cn({
					'asteria--type-history': isCurrentOrHistory,
					'asteria--type-future': ['future'].includes(lineType),
					'asteria--state-hidden':
						(isCurrentOrHistory
							? hiddenLineHistory
							: hiddenLineFuture) &&
						((isCurrentOrHistory
							? invisibleLineHistory
							: invisibleLineFuture) ??
							true),
					'asteria--state-invisible': isCurrentOrHistory
						? invisibleLineHistory
						: invisibleLineFuture,
				})}
				x1={x1}
				y1={y1}
				x2={x2}
				y2={y2}
			/>
			<line
				className={cn({
					'asteria--type-history': ['history'].includes(lineType),
					'asteria--type-future': isCurrentOrFuture,
					'asteria--state-hidden':
						(isCurrentOrFuture
							? hiddenLineFuture
							: hiddenLineHistory) &&
						((isCurrentOrFuture
							? invisibleLineFuture
							: invisibleLineHistory) ??
							true),
					'asteria--state-invisible': isCurrentOrFuture
						? invisibleLineFuture
						: invisibleLineHistory,
				})}
				x1={x2}
				y1={y2}
				x2={x3}
				y2={y3}
			/>
		</g>
	);
});

View.displayName = 'Line';

View.propTypes = {
	line: PropTypes.any,
	previousLine: PropTypes.any,
	nextLine: PropTypes.any,
	max: PropTypes.number,
	min: PropTypes.number,

	height: PropTypes.number,

	hiddenSpread: PropTypes.bool,
	invisibleSpread: PropTypes.bool,

	hiddenLineHistory: PropTypes.bool,
	invisibleLineHistory: PropTypes.bool,

	hiddenLineFuture: PropTypes.bool,
	invisibleLineFuture: PropTypes.bool,
	type: PropTypes.oneOf(['month', 'year']),
};

export default View;
