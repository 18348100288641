import { useEffect } from 'react';

import arentia from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./arentia/main.scss';
import asteria from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./asteria/main.scss';
import lf from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./lf/main.scss';
import nbanks from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./nbanks/main.scss';
import payex from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./payex/main.scss';
import shb from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./shb/main.scss';
import skeleton from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./skeleton/main.scss';
import svea from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./svea/main.scss';
import swedbank from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./swedbank/main.scss';
import treyd from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./treyd/main.scss';
import wild from '!!style-loader?injectType=lazyStyleTag!css-loader!postcss-loader!sass-loader!./wild/main.scss';

const Themes = {
	skeleton,
	asteria,
	swedbank,
	svea,
	treyd,
	wild,
	arentia,
	nbanks,
	shb,
	lf,
	payex,
};

export const useTheme = (theme, callback) => {
	useEffect(() => {
		let themeStyles = Themes[theme];

		try {
			if (themeStyles) {
				themeStyles.use();
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn(e);
		}

		callback?.();

		return () => {
			try {
				if (themeStyles) {
					themeStyles.unuse();
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.warn(e);
			}
		};
	}, [callback, theme]);
};
