import AsteriaCore from '@asteria/core';

AsteriaCore.Cache.get = async (key) => sessionStorage.getItem(key);

AsteriaCore.Cache.set = async (options) => {
	const { key, data } = options;
	sessionStorage.setItem(key, JSON.stringify(data));
};

AsteriaCore.Cache.reset = async (key) => sessionStorage.removeItem(key);

AsteriaCore.Cache.cache = (options = {}) => {
	const { key = null, shouldUpdate = false } = options;

	return (fn) =>
		async (...args) => {
			if (!shouldUpdate) {
				const cache = await AsteriaCore.Cache.get(key).catch(
					() => null,
				);

				if (cache) {
					return JSON.parse(cache);
				}
			}

			const data = await fn(...args);

			await AsteriaCore.Cache.set({
				key: key,
				data: data,
			}).catch(() => null);

			return data;
		};
};

export default AsteriaCore.Cache;
