import React from 'react';

import {
	useController,
	/* useFormContext */
} from 'react-hook-form';

import PropTypes from 'prop-types';

import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import { useChange } from './hooks';
import withRegister from './withRegister';

/**
 * @template T
 * @param { T } Component
 * @returns { T }
 */
const ControlledWrapper = function (Component) {
	const Uncontrolled = React.forwardRef((props, ref) => {
		const {
			onChange: onValueChange,
			isRawEvent = false,

			debounce = null,
		} = props;

		const handleChange = useChange({
			debounce: debounce,
			onChange: onValueChange,
			isRawEvent: isRawEvent,
		});

		const onChange = React.useCallback(
			(event) => {
				if (isRawEvent) {
					return handleChange(event);
				}

				//TODO: Fix checked, as its "false" for text / etc inputs
				return handleChange({
					name: event.target.name,
					value:
						event.target.checked !== undefined &&
						event.target.type !== 'text' &&
						event.target.type !== 'number'
							? event.target.checked
							: event.target.value,
					extra: event?.extra,
				});
			},
			[isRawEvent, handleChange],
		);

		return <Component {...props} ref={ref} onChange={onChange} />;
	});

	Uncontrolled.displayName = Component.displayName;
	Uncontrolled.propTypes = Component.propTypes;
	Uncontrolled.defaultProps = Component.defaultProps;

	const Controlled = React.forwardRef((props, ref) => {
		const {
			name,
			onChange: onValueChange,
			onBlur: onValueBlur,
			shouldUnregister,
			value: propValue,
			error: propError,
			required,
			format,

			minLength,
			maxLength,
			validate,

			debounce = null,

			type,
		} = props;

		// const { watch } = useFormContext();

		const extraRef = React.useRef(null);

		const handleChange = useChange({
			debounce: debounce,
			onChange: onValueChange,
		});

		// const watchValue = watch(name);

		const {
			field: { value, ref: onRefChange, onChange, ...$fieldProps },
			fieldState: { error: fieldError },
		} = useController({
			name: name,
			defaultValue: propValue,
			shouldUnregister,
			rules: {
				minLength: minLength,
				maxLength: maxLength,
				required: required,
				onChange: (event) =>
					handleChange({
						name: event.target.name,
						value: event.target.value,
						prev: value,
						extra: extraRef.current,
					}),
				onBlur: onValueBlur,
				validate: validate,
			},
		});

		const $ref = useCombinedRefs(ref, onRefChange);

		const error = propError || fieldError;

		const formatOnChange = React.useCallback(
			(event) => {
				extraRef.current = event?.extra;

				if (type === 'range' || format?.valueAsNumber) {
					const val = Number.parseFloat(event.target.value);

					if (Number.isNaN(val)) {
						return onChange(event);
					}

					return onChange(val);
				}

				return onChange(event);
			},
			[type, format?.valueAsNumber, onChange],
		);

		const fieldProps = React.useMemo(() => {
			return {
				name: $fieldProps?.name,
				onBlur: $fieldProps?.onBlur,
				disabled: $fieldProps?.disabled,
			};
		}, [$fieldProps?.disabled, $fieldProps?.name, $fieldProps?.onBlur]);

		return (
			<Uncontrolled
				{...props}
				isRawEvent
				defaultValue={propValue}
				ref={$ref}
				onChange={formatOnChange}
				value={value}
				fieldProps={fieldProps}
				error={error}
			/>
		);
	});

	Controlled.displayName = Component.displayName;
	Controlled.propTypes = Component.propTypes;
	Controlled.defaultProps = Component.defaultProps;

	const Wrapper = React.forwardRef((props, ref) => {
		const { uncontrolled, name } = props;

		if (uncontrolled) {
			return <Uncontrolled key={name} {...props} ref={ref} />;
		}

		return <Controlled key={name} {...props} ref={ref} />;
	});

	Wrapper.displayName = Component.displayName;
	Wrapper.propTypes = {
		...Component.propTypes,
		uncontrolled: PropTypes.bool,
	};
	Wrapper.defaultProps = Component.defaultProps;

	return Wrapper;
};

export default ControlledWrapper;
export { withRegister };
