import { createSelector } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';

export const data = createSelector(
	(store) => store?.admin?.companies?.data ?? [],
	(_, options = {}) => options,
	($objects, options) => {
		let objects = Array.from($objects).sort((source, target) => {
			const sorting = [].concat(
				options?.sorting ?? {
					key: 'createdAt',
					type: 'DESC',
				},
			);

			for (const { key, type } of sorting) {
				let sourceValue = source?.[key];
				let targetValue = target?.[key];

				if (key === 'createdAt') {
					sourceValue = parseISO(sourceValue);
					targetValue = parseISO(targetValue);

					if (sourceValue - targetValue) {
						if (type === 'ASC') {
							return sourceValue - targetValue;
						}

						return targetValue - sourceValue;
					}
				}
			}

			return 0;
		});

		if (options?.filters) {
			const filters = options?.filters ?? [];

			objects = objects.filter((object) =>
				filters.some(({ type, value }) => {
					if (type === 'ID') {
						return (object?._id ?? object?.id) === value;
					}

					return false;
				}),
			);
		}

		return objects;
	},
);

export const loading = createSelector(
	(store) => store?.admin?.companies?.loading,
	(value) => value ?? false,
);

export const details = createSelector(
	(store) => data(store),
	(_, ID) => ID,
	(objects, ID) =>
		objects.find((object) => (object?._id ?? object.id) === ID),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const filters = createSelector(
	(store) => store?.admin?.companies?.filters ?? [],
	(_, options) => options,
	($value, options) => {
		let value = Array.from($value);

		if (options?.type) {
			const type = [].concat(options?.type ?? []);

			value = value.filter((object) => type.includes(object?.type));
		}

		return value;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const pagination = createSelector(
	(store) => store?.admin?.companies?.pagination,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
