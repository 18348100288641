export const promotionInitialState = {
	promotion: {
		feedback: { visible: false, type: null },
		dismiss: { visible: false, type: null },
		visible: { footer: true },
	},
};

export function promotionReducer(state, action) {
	switch (action?.type) {
		case 'SET_PROMOTION_DISMISS':
			return {
				...state,
				promotion: {
					...state?.promotion,
					dismiss: {
						visible: action?.payload?.visible ?? false,
						type: action?.payload?.type ?? null,
						...action?.payload,
					},
				},
			};

		case 'SET_PROMOTION_FEEDBACK':
			return {
				...state,
				promotion: {
					...state?.promotion,
					feedback: {
						visible: action?.payload?.visible ?? false,
						type: action?.payload?.type ?? null,
						...action?.payload,
					},
				},
			};

		case 'SET_PROMOTION_VISIBLE_FOOTER':
			return {
				...state,
				promotion: {
					...state?.promotion,
					visible: { ...state.visible, footer: action?.payload },
				},
			};

		default:
			return state;
	}
}

export function onPromotionAction(
	action,
	data,
	{ dispatch, required, onAction },
) {
	if (action === 'card:promotion:dismiss') {
		dispatch?.({
			type: 'SET_PROMOTION_DISMISS',
			payload: {
				visible: true,
				type: null,
				...data,
			},
		});

		return;
	}

	if (action === 'card:promotion:feedback') {
		dispatch?.({
			type: 'SET_PROMOTION_FEEDBACK',
			payload: {
				visible: true,
				type: null,
				...data,
			},
		});

		return;
	}

	if (action === 'card:promotion:back') {
		dispatch?.({
			type: 'SET_PROMOTION_DISMISS',
			payload: { visible: false, type: null },
		});
		dispatch?.({
			type: 'SET_PROMOTION_FEEDBACK',
			payload: { visible: false, type: null },
		});

		return;
	}

	if (action === 'card:promotion:close') {
		if (required && data?.data?.type !== 'forecast-action') {
			dispatch?.({
				type: 'SET_PROMOTION_VISIBLE_FOOTER',
				payload: false,
			});

			dispatch?.({
				type: 'SET_PROMOTION_DISMISS',
				payload: { visible: false, type: null },
			});

			dispatch?.({
				type: 'SET_PROMOTION_FEEDBACK',
				payload: { visible: false, type: null },
			});

			return;
		}

		dispatch?.({
			type: 'SET_PROMOTION_DISMISS',
			payload: { visible: false, type: null },
		});

		dispatch?.({
			type: 'SET_PROMOTION_FEEDBACK',
			payload: { visible: false, type: null },
		});

		return onAction?.('card:close', data);
	}

	return onAction?.(action, data);
}
