import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';

import { useFormValues } from '@asteria/component-form';
// import Checkbox from '@asteria/component-form/checkbox';
import Form from '@asteria/component-form/form';
import Radio from '@asteria/component-form/radio';
import Select, { Option } from '@asteria/component-form/select';
import Modal from '@asteria/component-modal';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { setGraphLayout } from '@asteria/datalayer/stores/app';
import { setGraphOptions } from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const selectors = {
	layout: createSelector(
		(store) => store?.app?.user?.settings?.layout?.graph,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	options: createSelector(
		(store) => store?.graph?.options,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const FormContent = () => {
	// const lineGraph = useFormValues({ name: 'graph.lineGraph' });
	// const barGraph = useFormValues({ name: 'graph.barGraph' });

	const layout = useFormValues({ name: 'layout' });

	return (
		<Content>
			<Group
				className={cn(
					'asteria-component__cashflow-graph-settings__section',
					'asteria--type-graph',
				)}
			>
				<TextGroup>
					<Title size="sm">
						{TranslationService.get(
							'cashflow.graph.settings.modal.section.graph.title',
						)}
					</Title>
				</TextGroup>
				<Group>
					<Select
						name="layout"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.layout.label',
						)}
					>
						<Option
							value="grouped"
							postfix={
								layout === 'grouped' ? (
									<Group
										verticalAlign="center"
										horizontalAlign="center"
									>
										<Button icon="check" />
									</Group>
								) : null
							}
						>
							{TranslationService.get(
								'cashflow.graph.settings.modal.layout.grouped.label',
							)}
						</Option>
						<Option
							value="stacked"
							postfix={
								layout === 'stacked' ? (
									<Group
										verticalAlign="center"
										horizontalAlign="center"
									>
										<Button icon="check" />
									</Group>
								) : null
							}
						>
							{TranslationService.get(
								'cashflow.graph.settings.modal.layout.stacked.label',
							)}
						</Option>
					</Select>
					{/* <Radio
						name="layout"
						value="stacked"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.layout.stacked.label',
						)}
					/>
					<Radio
						name="layout"
						value="grouped"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.layout.grouped.label',
						)}
					/> */}
				</Group>
				<Group>
					<Radio
						name="graph"
						value="all"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.graph.all.label',
						)}
					/>
					<Radio
						name="graph"
						value="lineGraph"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.graph.account.label',
						)}
					/>
					<Radio
						name="graph"
						value="barGraph"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.graph.bars.label',
						)}
					/>
					{/* <Checkbox
						name="graph.lineGraph"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.graph.account.label',
						)}
						checked={lineGraph}
						disabled={!barGraph && lineGraph}
					/>
					<Checkbox
						name="graph.barGraph"
						label={TranslationService.get(
							'cashflow.graph.settings.modal.graph.bars.label',
						)}
						checked={barGraph}
						disabled={!lineGraph && barGraph}
					/> */}
				</Group>
			</Group>
			<FeatureFlag feature="cashflow-graph-settings-scale">
				<Group
					className={cn(
						'asteria-component__cashflow-graph-settings__section',
						'asteria--type-scale',
					)}
				>
					<TextGroup>
						<Title size="sm">
							{TranslationService.get(
								'cashflow.graph.settings.modal.section.scale.title',
							)}
						</Title>
						<Text size="sm">
							{TranslationService.get(
								'cashflow.graph.settings.modal.section.scale.text',
							)}
						</Text>
					</TextGroup>
					<Group>
						<Radio
							name="scale"
							value="dynamic"
							label={TranslationService.get(
								'cashflow.graph.settings.modal.scale.dynamic.label',
							)}
						/>
						<Radio
							name="scale"
							value="static"
							label={TranslationService.get(
								'cashflow.graph.settings.modal.scale.static.label',
							)}
						/>
					</Group>
				</Group>
			</FeatureFlag>
		</Content>
	);
};

const CashflowGraphSettingsModal = (props) => {
	const { open, onClose, onAction, className } = props;

	const dispatch = useDispatch();

	const layout = useSelector(selectors.layout, (a, b) => isEqual(a, b));
	const options = useSelector(selectors.options, (a, b) => isEqual(a, b));

	const defaultValues = React.useMemo(() => {
		return {
			...layout,
			graph:
				options?.lineGraph && options?.barGraph
					? 'all'
					: options?.lineGraph
					? 'lineGraph'
					: 'barGraph',
		};
	}, [layout, options]);

	const handleSubmit = React.useCallback(
		(data) => {
			dispatch(setGraphLayout(data?.layout));

			onAction?.('updateUserSettings', {
				layout: {
					graph: {
						layout: data?.layout,
						scale: data?.scale,
					},
				},
			});

			let graphOptions;

			switch (data?.graph) {
				case 'lineGraph':
					graphOptions = { lineGraph: true, barGraph: false };
					break;

				case 'barGraph':
					graphOptions = { lineGraph: false, barGraph: true };
					break;

				default:
					graphOptions = { lineGraph: true, barGraph: true };
					break;
			}

			dispatch(setGraphOptions(graphOptions));

			return onClose?.();
		},
		[dispatch, onAction, onClose],
	);

	return (
		<Modal
			className={cn(
				'asteria-component__cashflow-graph-settings',
				className,
			)}
			open={open}
			onClose={onClose}
			size="sm"
			scroll
		>
			<Form onSubmit={handleSubmit} defaultValues={defaultValues}>
				<Wrapper>
					<Header onClose={onClose}>
						{TranslationService.get(
							'cashflow.graph.settings.modal.title',
						)}
					</Header>
					<FormContent />
					<Footer>
						<FooterSection position="first">
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'button.cancel',
									'cashflow.graph.settings.modal.button.cancel',
								])}
								onClick={onClose}
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								type="submit"
								variant="primary"
								label={TranslationService.get([
									'button.save',
									'cashflow.graph.settings.modal.button.save',
								])}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>
		</Modal>
	);
};

CashflowGraphSettingsModal.displayName = 'CashflowGraphSettingsModal';

CashflowGraphSettingsModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

export default CashflowGraphSettingsModal;
