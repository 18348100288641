import { get } from 'lodash-es';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import addTag from './addTag';
import {
	recalculate as recalculateCategory,
	setProbability as setCategoryProbability,
	setValue as setCategoryValue,
} from './adjustCategory';
import { setValue as setTagValue } from './adjustTag';
import { createChange, getPath } from './getFormPaths';

function getTransactionValue({ type, date, form }) {
	const path = getPath({ type, date });

	return Object.values(get(form, [path, 'categories'].join('.')) ?? {})
		.flatMap((object) =>
			Object.values(object?.tags ?? {}).flatMap((object) =>
				Object.values(object?.transactions ?? {}),
			),
		)
		.filter((object) => object && !object.$deleted)
		.reduce(
			(acc, { total = 0, probability = 1 }) => ({
				value: acc.value + (total || 0),
				min: acc.min + (total || 0) * (probability || 1),
			}),
			{ value: 0, min: 0 },
		);
}

const setProbability = ({ type, date, value, form, strict }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			setProbability({ type, date, value, form, strict }),
		);
	}

	const path = getPath({ type, date });

	const { categories = {}, probability: prev = 1 } = get(form, path) || {};

	let updates = [];

	if (value === prev) {
		return updates;
	}

	updates.push(
		createChange(
			{
				op: 'set',
				name: `${path}.probability`,
				value: value,
				previousValue: prev,
				event: 'type:probability:set',
			},
			form,
		),
	);

	const percentageChanged = value / prev;

	updates = updates.concat(
		Object.entries(categories).flatMap(([name, category]) =>
			setCategoryProbability({
				type,
				date,
				category: name,
				value: strict
					? value
					: category.probability * percentageChanged,
				prev: category.probability,
				form,
				strict: strict,
			}),
		),
	);

	return updates;
};

const setValue = ({ type, date, value, dispatch, form }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			setValue({ type, date, value, dispatch, form }),
		);
	}

	const path = getPath({ type, date });

	const { value: prev } = getTransactionValue({ type, date, form });

	let updates = [];

	if (value === prev) {
		return updates;
	}

	updates.push(
		createChange(
			{
				op: 'set',
				name: `${path}.value`,
				value: value,
				previousValue: prev,
				event: 'type:value:set',
			},
			form,
		),
	);

	const overflow =
		get(
			form,
			getPath({
				type,
				date,
				category: '$forecaster',
				tag: '$adjusted',
				field: 'value',
			}),
		) || 0;

	if (prev < value) {
		const diff = value - prev;

		addTag({ tag: '$adjusted', category: '$forecaster', dispatch });

		updates = updates.concat(
			setTagValue({
				type,
				date,
				category: '$forecaster',
				tag: '$adjusted',
				value: overflow + diff,
				form,
			}),
		);

		updates = updates.concat(
			recalculateCategory({
				type,
				date,
				category: '$forecaster',
				form,
			}),
		);
	} else {
		const diff = overflow + (value - prev);

		if (overflow > 0) {
			updates = updates.concat(
				setTagValue({
					type,
					date,
					category: '$forecaster',
					tag: '$adjusted',
					value: Math.max(diff, 0),
					form,
				}),
			);

			updates = updates.concat(
				recalculateCategory({
					type,
					date,
					category: '$forecaster',
					form,
					skipRecalculate: true,
				}),
			);
		}

		if (diff < 0) {
			const percentageChanged = value / (prev - overflow);

			const categories = get(form, [path, 'categories'].join('.')) || {};

			const tagsCount = Object.values(categories).reduce(
				(acc, { tags = {} }) => acc + Object.keys(tags).length,
				0,
			);

			const singleDiff = value / tagsCount;

			updates = updates.concat(
				Object.entries(categories).flatMap(
					([name, { value = 0, tags = {} }]) =>
						setCategoryValue({
							type,
							date,
							category: name,
							value: prev
								? value * percentageChanged
								: singleDiff * Object.keys(tags ?? {}).length,
							form,
						}),
				),
			);

			updates = updates.concat(recalculate({ type, date, form }));
		}
	}

	return updates;
};

const recalculate = ({ type, date, form }) => {
	if (!date) {
		// recalculate for all dates
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			recalculate({ type, date, form }),
		);
	}

	const path = getPath({ type, date });

	const { value: transactionValue, min: transactionMinValue } =
		getTransactionValue({ type, date, form });

	const { value, probability } = get(form, path) || {};

	const calculatedProbability =
		transactionValue !== 0 ? transactionMinValue / transactionValue : null;
	const normalizedProbability = normalizeProbability(calculatedProbability);

	const updates = [];

	if (transactionValue !== value) {
		updates.push(
			createChange(
				{
					op: 'set',
					name: `${path}.value`,
					value: transactionValue,
					event: 'type:recalculate:value',
					previousValue: value,
				},
				form,
			),
		);
	}

	if (normalizedProbability !== probability) {
		updates.push(
			createChange(
				{
					op: 'set',
					name: `${path}.probability`,
					value: normalizedProbability,
					event: 'type:recalculate:probability',
					previousValue: probability,
				},
				form,
			),
		);
	}

	return updates;
};

export { recalculate, setProbability, setValue };
