import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import Spinner from '@asteria/component-core/spinner';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import {
	ActionBarSectionActions,
	Action as BaseAction,
} from '@asteria/component-actionbar';
import { IntegrationErrorTooltip } from '@asteria/component-integrations-v2/components/Integration';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import * as WelcomeSelectors from '../../../selectors';

import './styles.scss';

function useVisibility({ sticky, status, name, onAction }) {
	const isVisible = useSelector(
		(store) => {
			const isVisible = WelcomeSelectors.isActionVisible(store, name);
			const integrations = IntegrationStore.selectors.integrations(
				store,
				{ connected: true },
			);

			if (name === 'connect:erp' && !isVisible) {
				return !integrations.some(({ type }) => type === 'erp');
			}

			if (name === 'connect:bank' && !isVisible) {
				return !integrations.some(({ type }) => type === 'bank');
			}

			return isVisible;
		},
		(a, b) => isEqual(a, b),
	);

	const [visible, setVisible] = React.useState(
		isVisible && (status !== 'connected' || sticky),
	);

	const onHide = React.useCallback(() => {
		setVisible(false);
		return onAction?.('welcome:action:hide', name);
	}, [name, onAction]);

	React.useEffect(() => {
		if (status === 'connected' && !sticky) {
			onHide();
		}
	}, [status, sticky, onHide]);

	return React.useMemo(
		() => ({ visible: visible, onHide: onHide }),
		[onHide, visible],
	);
}

function useIntegration({ type }) {
	return useSelector(
		(store) => {
			const integrations = IntegrationStore.selectors.integrations(
				store,
				{ type: type },
			);

			let integration = integrations.find(
				(object) =>
					object?.status?.state === 'ERROR' ||
					object?.config?.errors?.length,
			);

			if (integration) {
				return { status: 'error', integration: integration };
			}

			integration = integrations.find((object) =>
				['INITIATING'].includes(object?.status?.state),
			);

			if (integration) {
				return { status: 'importing', integration: integration };
			}

			integration = integrations.find(
				(object) =>
					['IDLE'].includes(object?.status?.state) &&
					object?.config?.connected,
			);

			if (integration) {
				return { status: 'connected', integration: integrations[0] };
			}

			return { status: 'create' };
		},
		(a, b) => isEqual(a, b),
	);
}

const IntegrationActions = (props) => {
	const { type, onAction, name, sticky } = props;

	const { status, integration } = useIntegration({ type });

	const dispatch = useDispatch();

	const { visible, onHide } = useVisibility({
		sticky: sticky,
		status: status,
		name: name,
		onAction: onAction,
	});

	const onOpen = React.useCallback(() => {
		if (status !== 'create') {
			return null;
		}

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
				data: { type: type },
			}),
		);
	}, [dispatch, status, type]);

	const onRemove = React.useCallback(
		() => onAction?.('welcome:integration:cancel', integration),
		[integration, onAction],
	);

	if (!visible) {
		return null;
	}

	return (
		<BaseAction
			className={cn('asteria-view__welcome__action', {
				'asteria--variant-importing': ['importing'].includes(status),
				'asteria--variant-create': ['create', 'nothing'].includes(
					status,
				),
				'asteria--variant-error': ['error'].includes(status),
			})}
			title={TranslationService.get([
				`welcome.action.integration.title`,
				`welcome.action.integration.${type}.title`,
			])}
			content={TranslationService.get([
				`welcome.action.integration.content`,
				`welcome.action.integration.${type}.content`,
			])}
			loading={status === 'importing'}
			variant="integration"
			onClick={status === 'create' ? onOpen : null}
			actions={
				<ActionBarSectionActions
					className={cn({
						[`asteria-integration--status-${status}`]: status,
					})}
				>
					<Group
						flex
						horizontalAlign="center"
						verticalAlign="center"
						direction="horizontal"
					>
						{status === 'create' || status === 'nothing' ? (
							<Button
								className="asteria-view__welcome__action__button--create"
								label={TranslationService.get([
									`welcome.action.integration.button.label`,
									`welcome.action.integration.${type}.button.label`,
								])}
								size="md"
								iconSize="xs"
								icon={[
									'plus',
									'welcome-action',
									'welcome-action-plus',
									'welcome-action-integration',
									'welcome-action-integration-plus',
								]}
								variant="link"
								onClick={onOpen}
							/>
						) : null}
						{status === 'error'
							? [
									<TooltipWrapper
										key="error-tooltip"
										tooltip={
											<IntegrationErrorTooltip
												integration={integration}
											/>
										}
									>
										<Icon
											icon={[
												'help',
												'welcome-action',
												'welcome-action-help',
												'welcome-action-integration',
												'welcome-action-integration-help',
											]}
											size="lg"
										/>
									</TooltipWrapper>,
									<Text key="error-label" size="md">
										{TranslationService.get([
											`welcome.action.integration.status`,
											`welcome.action.integration.status.error`,
											`welcome.action.integration.${type}.status`,
											`welcome.action.integration.${type}.status.error`,
										])}
									</Text>,
									<Button
										key="error-button"
										label={TranslationService.get([
											'button.cancel',
											'welcome.action.integration.cancel',
											`welcome.action.integration.${type}.cancel`,
										])}
										variant="href"
										size="sm"
										onClick={onRemove}
										analyticsKey={`welcome.action.integration.${type}.error.remove`}
									/>,
							  ]
							: null}
						{status === 'importing'
							? [
									<Spinner key="loading-spinner" />,
									<Text key="loading-label" size="md">
										{TranslationService.get([
											`welcome.action.integration.status`,
											`welcome.action.integration.status.loading`,
											`welcome.action.integration.${type}.status`,
											`welcome.action.integration.${type}.status.loading`,
										])}
									</Text>,
									<Button
										key="loading-button"
										label={TranslationService.get([
											'button.cancel',
											'welcome.action.integration.cancel',
											`welcome.action.integration.${type}.cancel`,
										])}
										variant="href"
										size="sm"
										onClick={onRemove}
										analyticsKey={`welcome.action.integration.${type}.loading.remove`}
									/>,
							  ]
							: null}
						{status === 'connected'
							? [
									<Button
										key="done-button"
										variant="default"
										icon={[
											'check',
											'welcome-action',
											'welcome-action-check',
											'welcome-action-integration',
											'welcome-action-integration-check',
										]}
										iconSize="xs"
										size="sm"
										onClick={onHide}
										analyticsKey={`welcome.action.integration.${type}.done`}
									/>,

									<Text key="done-label" size="md">
										{TranslationService.get([
											`welcome.action.integration.status`,
											`welcome.action.integration.status.done`,
											`welcome.action.integration.${type}.status`,
											`welcome.action.integration.${type}.status.done`,
										])}
									</Text>,
									<FeatureFlag
										key="done-button-cancel"
										feature={`welcome-integration-${type}-success-remove`}
									>
										<Button
											label={TranslationService.get([
												'button.cancel',
												'welcome.action.integration.cancel',
												`welcome.action.integration.${type}.cancel`,
											])}
											variant="href"
											size="sm"
											onClick={onRemove}
											analyticsKey={`welcome.action.integration.${type}.done.remove`}
										/>
									</FeatureFlag>,
							  ]
							: null}
					</Group>
				</ActionBarSectionActions>
			}
		/>
	);
};

export default IntegrationActions;
