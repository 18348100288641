const NORMALIZED = {
	'title-1': 'xl',
	'x-large': 'xl',
	xlarge: 'xl',
	'title-2': 'lg',
	large: 'lg',
	'title-3': 'md',
	p: 'md',
	base: 'md',
	default: 'md',
	body: 'md',
	label: 'md',
	medium: 'md',
	'title-4': 'sm',
	small: 'sm',
	'title-5': 'xs',
	xsmall: 'xs',
	'x-small': 'xs',
	'title-6': 'xxs',
	'xx-small': 'xxs',
	xxsmall: 'xxs',
};

const SIZES = ['xl', 'lg', 'md', 'sm', 'xs', 'xxs'];

/**
 * @param { string } $size
 * @param { number } step
 * @param { { min?: string, max?: string, default?: string } } options
 * @returns { string }
 */
export function increase($size, step = 1, options = {}) {
	const size = normalize($size);
	let result = SIZES[SIZES.indexOf(size) - step] ?? size;

	if (options?.min) {
		if (isSmaller(result, options?.min)) {
			result = options?.default ?? options?.min;
		}
	}

	if (options?.max) {
		if (isLarger(result, options?.max)) {
			result = options?.default ?? options?.max;
		}
	}

	return result;
}

/**
 * @param { string } $size
 * @param { number } step
 * @param { { min?: string, max?: string, default?: string } } options
 * @returns { string }
 */
export function decrease($size, step = 1, options = {}) {
	const size = normalize($size);
	let result = SIZES[SIZES.indexOf(size) + step] ?? size;

	if (options?.min) {
		if (isSmaller(result, options?.min)) {
			result = options?.default ?? options?.min;
		}
	}

	if (options?.max) {
		if (isLarger(result, options?.max)) {
			result = options?.default ?? options?.max;
		}
	}

	return result;
}

/**
 * @param { string } size
 * @returns { string }
 */
export function normalize(size) {
	return NORMALIZED?.[size] ?? size;
}

/**
 * @param { string } $source
 * @param { string } $target
 * @returns { number }
 */
export function compare($source, $target) {
	const source = normalize($source);
	const target = normalize($target);

	return SIZES.indexOf(source) - SIZES.indexOf(target);
}

/**
 * @param { string } source
 * @param { string } target
 * @returns { boolean }
 */
export function isLarger(source, target) {
	return compare(source, target) < 0;
}

/**
 * @param { string } source
 * @param { string } target
 * @returns { boolean }
 */
export function isSmaller(source, target) {
	return compare(source, target) > 0;
}

/**
 * @param { string } source
 * @param { string } target
 * @returns { boolean }
 */
export function isSame(source, target) {
	return compare(source, target) == 0;
}
