import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Switcher from '../../../components/loan/switcher';
import { useModalActions } from '../../../components/modal-collection';
import { LoanSettingsModal } from '../../../modals';
import { getCSVContent, getData } from '../../../utils/loan';

const SectionHeader = React.memo((props) => {
	const { onAction, view } = props;

	const dispatch = useDispatch();

	const { getValues } = useFormContext();

	const { id } = useParams();
	const { open } = useModalActions();

	const isSmallScreen = useMediaQuery('(max-width:1366px)');

	const onSettingsClick = React.useCallback(() => {
		open(<LoanSettingsModal />, {
			simulationId: id,
			redirect: true,
			callback: onAction,
		});
	}, [id, onAction, open]);

	const onBack = React.useCallback(
		() => onAction?.('redirect', '/financial'),
		[onAction],
	);

	const onExportClick = React.useCallback(() => {
		const [type, amount, term, rate, timestamp] = getValues([
			'type',
			'amount',
			'term',
			'rate',
			'timestamp',
		]);

		const data = getData({
			type,
			amount: Number.parseFloat(amount),
			term: Number.parseFloat(term),
			rate: Number.parseFloat(rate),
			timestamp: timestamp ? parseISO(timestamp) : new Date(),
		});

		const content = getCSVContent(data);

		const blob = new Blob([content], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('href', url);
		a.setAttribute('download', `loan.${timestamp}.csv`);

		a.click();

		SnackbarStore.show(dispatch, {
			title: 'financial.page.loan.export.title',
			content: 'financial.page.loan.export.content',
			type: 'financial.page.loan.export',
			variant: 'success',
			icon: 'check',
			hideActions: true,
		});
	}, [dispatch, getValues]);

	return (
		<Group
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-header',
			)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="space-between"
		>
			<div className="asteria-component__financial-page-navigation">
				<Group
					className="asteria-component__financial-page-navigation-back"
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="left"
				>
					<Button
						icon="action-arrow-left"
						iconSize="lg"
						variant="tertiary"
						size="lg"
						onClick={onBack}
					/>

					<Title size="sm">
						{TranslationService.get([
							'financial.navigation.back.level.-1.label',
							'financial.page.navigation.title',
							'financial.page.loan.navigation.title',
						])}
					</Title>
				</Group>
			</div>

			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="right"
			>
				<Button
					variant="link"
					icon="export"
					label={
						!isSmallScreen
							? TranslationService.get([
									'financial.page.loan.header.export.label',
							  ])
							: null
					}
					tooltip={
						isSmallScreen
							? {
									variant: 'alt',
									tooltip: TranslationService.get([
										'financial.page.loan.header.export.label',
									]),
							  }
							: null
					}
					onClick={onExportClick}
				/>
				<Switcher active={view} onAction={onAction} />
				{id ? <Button icon="cog" onClick={onSettingsClick} /> : null}
			</Group>
		</Group>
	);
});

SectionHeader.displayName = 'SectionHeader';
SectionHeader.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	view: PropTypes.string,
};

export default SectionHeader;
