import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { findNextNavigation, findPreviousNavigation } from '../utils';

export const date = createSelector(
	(state) => state?.forecaster?.navigation,
	(value) => value?.date ?? null,
);

export const section = createSelector(
	(state) => state?.forecaster?.navigation,
	(value) => value?.section ?? null,
);

export const type = createSelector(
	(state) => state?.forecaster?.navigation,
	(value) => value?.type ?? null,
);

export const category = createSelector(
	(state) => state?.forecaster?.navigation,
	(value) => value?.category ?? null,
);

export const tag = createSelector(
	(state) => state?.forecaster?.navigation,
	(value) => value?.tag ?? null,
);

function filterByNavigation(tags, navigation) {
	return (tags ?? []).filter(
		(object) =>
			object?.type === navigation?.type &&
			object?.categoryName === navigation?.category &&
			object?.tagName === navigation?.tag,
	);
}

export const isCompleted = createSelector(
	(state) => state?.forecaster?.navigation ?? {},
	(state) => state?.forecaster?.tags ?? [],
	(navigation, tags) =>
		filterByNavigation(tags, navigation).find(
			({ isCompleted }) => isCompleted,
		),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isSkipped = createSelector(
	(state) => state?.forecaster?.navigation ?? {},
	(state) => state?.forecaster?.tags ?? [],
	(navigation, tags) =>
		filterByNavigation(tags, navigation).find(({ isSkipped }) => isSkipped),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isRemoved = createSelector(
	(state) => state?.forecaster?.navigation ?? {},
	(state) => state?.forecaster?.tags ?? [],
	(navigation, tags) =>
		filterByNavigation(tags, navigation).find(({ isRemoved }) => isRemoved),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const history = createSelector(
	(state) => state?.forecaster?.history,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const next = createSelector(
	(store) => store?.forecaster,
	(state) => findNextNavigation(state),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const previous = createSelector(
	(store) => store?.forecaster,
	(state) => findPreviousNavigation(state),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
