import { createSelector } from '@reduxjs/toolkit';
import { compareAsc, parseISO } from 'date-fns';
import { isEqual, merge } from 'lodash-es';

import * as FormatUtils from '@asteria/utils-funcs/format';

export const auth = createSelector(
	(store) => store?.app?.auth,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const accessToken = createSelector(
	(store) => store?.app?.auth?.accessToken,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const partner = createSelector(
	(store) => store?.app?.partner,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const partnerId = createSelector(
	partner,
	(value) => value?._id ?? value?.id,
);

export const tags = createSelector(
	(state) => state?.app?.tags ?? [],
	(tags) =>
		tags.map((object) => ({
			...object,
			label: FormatUtils.formatTag({
				category: object?.category?.name,
				tag: object?.name,
			}),
		})),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const tag = createSelector(
	(state) => state?.app?.tags ?? [],
	(_, ID) => ID,
	(tags, ID) => tags.find((object) => (object?._id ?? object?.id) === ID),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const statement = createSelector(
	(state) => state?.app?.statement,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const company = createSelector(
	(state) => state?.app?.company,
	partnerId,
	(value) => {
		const company = value ?? null;

		if (!company) {
			return company;
		}

		return {
			...company,
			service: Array.from(company?.services ?? [])
				.sort(({ updatedAt: a }, { updatedAt: b }) =>
					compareAsc(
						a ? parseISO(a) : new Date(),
						b ? parseISO(b) : new Date(),
					),
				)
				// .filter(({ serviceId }) => serviceId === partnerId)
				.reduce(
					(acc, object) => ({
						...acc,
						...object,
						data: merge({}, acc?.data, object?.data),
					}),
					{},
				),
		};
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const companyVersion = createSelector(
	(store) => company(store)?.service?.data?.version,
	(version) => version ?? 1,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

function formatService(service, id) {
	if (!service) {
		return service;
	}

	const client = (service?.clients ?? []).find(
		({ clientId }) => clientId === id,
	)?.data;

	return { ...service, client: client };
}

function applyClientServiceChanges(object, partnerId) {
	const services = (object?.services ?? []).map((service) =>
		formatService(service, object?._id ?? object?.id),
	);

	const service = services.find(({ serviceId }) => serviceId === partnerId);

	return {
		...object,
		services,
		service,
		name: service?.client?.name ?? object?.name,
	};
}

export const clients = createSelector(
	(store) => store?.app?.clients ?? [],
	partnerId,
	(_, options = {}) => options,
	($objects, partnerId, options) => {
		let objects = $objects.map((node) =>
			applyClientServiceChanges(node, partnerId),
		);

		if (options?.id !== undefined) {
			const IDs = [].concat(options?.id);

			objects = objects.filter((object) =>
				IDs.includes(object?._id ?? object.id),
			);
		}

		if (options?.type !== undefined) {
			const type = [].concat(options?.type);

			objects = objects.filter((object) => type.includes(object?.type));
		}

		if (options?.filters?.length) {
			objects = objects.filter((object) => {
				return (options?.filters ?? []).some(({ type, value }) => {
					if (type === 'DELIVERY:MISSING') {
						const form = object?.service?.client;
						const method = form?.delivery?.method ?? null;

						return method === null;
					}

					if (type === 'NAME') {
						return object?.name?.includes?.(value);
					}
				});
			});
		}

		return objects;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const client = createSelector(
	(store) => store?.app?.clients ?? [],
	partnerId,
	(_, ID) => ID,
	($objects, partnerId, ID) => {
		const objects = $objects
			.filter((object) => (object?._id ?? object.id) === ID)
			.map((node) => applyClientServiceChanges(node, partnerId));

		return objects[0];
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const currencies = createSelector(
	(store) => store?.app?.currencies,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const register2FA = createSelector(
	(store) => store?.app?.register2FA,
	(value) => value ?? false,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const user = createSelector(
	(store) => store?.app?.user,
	(value) => {
		const response = value ?? null;

		if (!response) {
			return response;
		}

		const companies = response?.companies ?? [];
		const companyId = response?.companyId ?? null;
		const company =
			companies.find(
				(object) => (object?._id ?? object?.id) === companyId,
			) ?? companies?.[0];

		return { ...response, company: company };
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const statuses = createSelector(
	(store) => store?.app?.statuses,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const filters = createSelector(
	(store) => store?.app?.filters ?? [],
	(_, options) => options,
	(value, options) => {
		if (!options) {
			return value;
		}

		return value.filter((object) => {
			let valid = false;

			if (options?.type) {
				valid = object?.type === options?.type;
			}

			if (options?.id) {
				valid = object?.id === options?.id;
			}

			if (options?.invert) {
				valid = !valid;
			}

			return valid;
		});
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const currency = createSelector(
	(store) => store?.app?.company?.settings?.currency ?? 'SEK',
	(value) => value,
);
