import React from 'react';

import withForm from '../../../withForm';

import TagSelectorDropdown from './dropdown';
import TagSelectorSelect from './select';

import './styles.scss';

const TagSelectorProvider = (props) => {
	if (props.variant === 'select') {
		return <TagSelectorSelect {...props} />;
	}

	return <TagSelectorDropdown {...props} />;
};

TagSelectorProvider.displayName = 'TagSelector';

TagSelectorProvider.propTypes = {
	...TagSelectorSelect.propTypes,
	...TagSelectorDropdown.propTypes,
};

export default withForm(TagSelectorProvider);
