import { TransactionService } from '@asteria/backend-utils-services';

import { setCurrencies } from '@asteria/datalayer/stores/app';
import { removeItems, setItems } from '@asteria/datalayer/stores/transactions';

const FIELDS = `
_id
id
type
status
paymentDate

createdAt
updatedAt

sums {
		original {
				total
				currency
		}

		display {
				total
				currency
				rate
		}
}
meta {
		description
		categories {
				id
				name
		}
		tags {
				id
				_id
				name
				category {
						id
						_id
						name
				}
		}
}
description
message
oracle {
		risk
		max {
				original {
						total
						currency
				}
		}
		min {
				original {
						total
						currency
				}
		}
		forecast {
				risk
				max {
						display {
								total
								currency
						}
				}
				min {
						display {
								total
								currency
						}
				}
		}
		currency {
				risk
				max {
						display {
								total
								currency
						}
				}
				min {
						display {
								total
								currency
						}
				}
		}
}
manual
updatedAt
recurrentEvent {
		startDate
		endDate
		period
		parentId
}
links {
		id
		type
		invoiceDetails: details {
				... on Invoice {
						dates {
								invoiceSent
								invoiceDue
								invoicePaid
						}
						meta {
								message
								invoiceNumber
						}
						info {
								status
						}
						sums {
								original {
										total
										currency
								}
								display {
										rate
								}
						}
						client {
								id
								name
								type
						}
				}
		}
		accountDetails: details {
				... on BankAccountTransaction {
						bankAccountId
						identifiers {
								accountNumber
								number
						}
						dates {
								booked
						}
						sums {
								account {
										total
										currency
										rate
								}
						}
				}
		}
}`;

const PAGINATION_FIELDS = `
pageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
}
edges {
    node {
        ${FIELDS}
    }
}
`;

export async function currencies({ dispatch, accessToken }) {
	try {
		const currencies = await TransactionService.transaction
			.currencies(
				{
					isBulk: true,
					fields: `
					id: currency
					code: currency
					currency
					deposit {
						original {
						total
						currency
						}
						display {
						total
						currency
						}
						exposure {
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						tags {
						tag {
							id
							name
						}
						type
						parts {
							status
							count
							original {
							total
							currency
							}
							display {
							total
							currency
							}
						}
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						count
					}

					withdraw {
						original {
						total
						currency
						}
						display {
						total
						currency
						}
						exposure {
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						tags {
						tag {
							id
							name
						}
						type
						parts {
							status
							count
							original {
							total
							currency
							}
							display {
							total
							currency
							}
						}
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						}
						count
					}

					exposure {
						original {
						total
						currency
						}
						display {
						total
						currency
						}
					}

					risk {
						original {
							total
							currency
						}
						display {
							total
							currency
						}
						risk
						alert
					}
				`,
				},
				{ token: accessToken },
			)
			.catch(() => {
				return [];
			})
			.then((data) =>
				data?.sort((a, b) => a?.currency?.localeCompare(b?.currency)),
			);

		dispatch?.(setCurrencies(currencies));

		return currencies;
	} catch (e) {
		return null;
	}
}

export async function fetch({
	accessToken,
	endDate,
	startDate,
	search,
	fields = PAGINATION_FIELDS,
	types = ['DEPOSIT', 'WITHDRAW'],
	status = ['PAID', 'UNPAID', 'OVERDUE', 'FORECAST'],
	pageQuery,
	scenarioId,
	filters = {},
	isRaw = false,
}) {
	try {
		const data = await TransactionService.transaction.fetch(
			{
				isBulk: true,
				fields: fields,
				filters: {
					endDate: endDate,
					startDate: startDate,
					types: types,
					status: status,
					scenarioId: scenarioId,
					...filters,
				},
				search: search,
				pageFilters: {
					// last: 50,
					orderField: 'paymentDate',
					...pageQuery,
				},
			},
			{ token: accessToken },
		);

		if (isRaw) {
			return data;
		}

		const transactions = data?.edges?.map(({ node }) => node) || [];
		return { transactions, pageInfo: data?.pageInfo };
	} catch (e) {
		return {};
	}
}

export async function notifications({ accessToken, timestamp, scenarioId }) {
	return TransactionService.transaction.notifications(
		{
			input: { date: timestamp, scenarioId: scenarioId },
			fields: `type data`,
		},
		{ token: accessToken },
	);
}

export async function remove({ accessToken, items, dispatch }) {
	try {
		await TransactionService.transaction.remove(
			{ input: items.map((id) => ({ _id: id })) },
			{ token: accessToken },
		);

		dispatch(removeItems(items));
	} catch (e) {
		// Do nothing
	}
}

export async function update({
	accessToken,
	items,
	scenario,
	recurrent,
	dispatch,
}) {
	const transactions = Object.values(items)
		.map((item) => {
			try {
				const cleanObject = {
					id: item.id,
					data: {
						meta: {
							description: item.description,
						},
						sums: item.sums,
						paymentDate: item.paymentDate,
						status: 'FORECAST',
						manual: true,
					},
					scenario: { name: scenario?.name },
				};

				if (!cleanObject?.data?.sums) {
					return null;
				}

				if (!item.type) {
					cleanObject.data.type =
						cleanObject.data.sums.original.total > 0
							? 'DEPOSIT'
							: 'WITHDRAW';
				} else if (item.type === 'WITHDRAW') {
					cleanObject.data.sums.original.total = Math.abs(
						cleanObject.data.sums.original.total,
					);
				} else if (item.type === 'DEPOSIT') {
					cleanObject.data.sums.original.total = Math.abs(
						cleanObject.data.sums.original.total,
					);
				}

				cleanObject.data.sums.original.total = Math.abs(
					cleanObject.data.sums.original.total,
				);

				if (item.$tags) {
					cleanObject.data.meta = {
						...cleanObject.data.meta,
						tags: [item.$tags.id],
						categories: [item.$tags.category.id],
					};
				}

				if (item.$type === 'SUPPLIER' && item.$supplier) {
					cleanObject.data.links =
						item?.links
							?.filter(({ type }) => type !== 'CLIENT')
							?.map((link) => ({
								id: link.id,
								type: link.type,
								reference: link.reference,
								clientId: link.clientId,
							})) || [];

					cleanObject.data.links.push({
						type: 'CLIENT',
						id: item.$supplier.id,
					});

					cleanObject.data.type = 'WITHDRAW';
				}

				if (item.$type === 'CUSTOMER' && item.$customer) {
					cleanObject.data.links =
						item?.links
							?.filter(({ type }) => type !== 'CLIENT')
							?.map((link) => ({
								id: link.id,
								type: link.type,
								reference: link.reference,
								clientId: link.clientId,
							})) || [];

					cleanObject.data.links.push({
						type: 'CLIENT',
						id: item.$customer.id,
					});

					cleanObject.data.type = 'DEPOSIT';
				}

				if (item?.recurrentEvent?.period) {
					cleanObject.data.recurrentEvent = {
						period: item?.recurrentEvent?.period,
						startDate:
							item?.paymentDate?.startDate ||
							item.recurrentEvent?.startDate,
						endDate:
							item?.paymentDate?.endDate ||
							item?.recurrentEvent?.endDate,
					};

					cleanObject.data.paymentDate =
						item?.paymentDate?.startDate ||
						item.recurrentEvent?.startDate;
					cleanObject.recurrent = recurrent;
				}

				return cleanObject;
			} catch (e) {
				return null;
			}
		})
		.filter((item) => item);

	const transactionsToAdd = transactions.filter(({ id }) => !id);
	const transactionsToUpdate = transactions.filter(({ id }) => id);

	try {
		let updatedTransactions = [];
		if (transactionsToUpdate.length > 0) {
			const { data } = await TransactionService.transaction.update(
				{ fields: `data { ${FIELDS} }`, input: transactionsToUpdate },
				{ token: accessToken },
			);

			updatedTransactions = [...updatedTransactions, ...data];
		}

		if (transactionsToAdd.length > 0) {
			const data = await Promise.all(
				transactionsToAdd
					.map(({ data, scenario }) => ({
						...data,
						scenario: scenario,
					}))
					.map((data) =>
						TransactionService.transaction.create(
							{ fields: `data { ${FIELDS} }`, input: data },
							{ token: accessToken },
						),
					),
			).then((items) => items.map(({ data }) => data));

			updatedTransactions = [...updatedTransactions, ...data];
		}

		dispatch?.(setItems(updatedTransactions));

		return updatedTransactions;
	} catch (e) {
		// DO nothing
	}

	return [];
}
