import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useStore } from 'react-redux';

import PropTypes from 'prop-types';

import { View as TagCleanModal } from '@asteria/component-forecaster/modals/tag/clean';

import * as FormatUtils from '@asteria/utils-funcs/format';
import { selector as configSelector } from '@asteria/utils-hooks/useConfig';

import { getPath } from '../../../../utils/form/utils';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<{ open: boolean, onClose: () => void, type: 'deposit' | 'withdraw', category: string, tag: string, onRemove: (event: MouseEvent, context: { type: 'deposit' | 'withdraw', category: string, tag: string }) => void }> } */
const ForecastTagRemoveModal = React.memo(function ForecastTagRemoveModal({
	open,
	onClose,
	type,
	category,
	tag,
	onRemove,
}) {
	const store = useStore();

	const { getValues } = useFormContext();

	const useData = React.useCallback(
		({ type, category, tag }) => {
			const path = getPath({
				type,
				category,
				tag,
				field: 'transactions',
			}).join('.');

			const transactions = Object.values(getValues(path) ?? {}).filter(
				(object) => object?.total,
			);

			const total = transactions.reduce(
				(acc, { total }) => acc + total,
				0,
			);

			const count = transactions.length;

			return {
				label: FormatUtils.formatTag({ type, category, tag }),
				statistics: {
					current: {
						count: count,
						total: [{ currency: 'SEK', total: total }],
						DEPOSIT: {
							count: count,
							total: [{ currency: 'SEK', total: total }],
						},
						WITHDRAW: {
							count: count,
							total: [{ currency: 'SEK', total: total }],
						},
					},
				},
			};
		},
		[getValues],
	);

	const useContent = React.useCallback(
		({ type, category, tag }) => {
			const path = getPath({
				type,
				category,
				tag,
				field: 'transactions',
			}).join('.');

			const transactions = Object.values(getValues(path) ?? {}).filter(
				(object) => object?.total,
			);

			const total = transactions.reduce(
				(acc, { total }) => acc + total,
				0,
			);

			const config = configSelector(store.getState(), {
				deep: true,
				key: ['modals.tag.deselect'],
			});

			if (type === 'deposit' && !!total) {
				return config?.deposit;
			}

			if (type === 'withdraw' && !!total) {
				return config?.withdraw;
			}

			return config?.empty;
		},
		[getValues, store],
	);

	return (
		<TagCleanModal
			open={open}
			onClose={onClose}
			type={type}
			category={category}
			tag={tag}
			onSubmit={onRemove}
			useContent={useContent}
			useData={useData}
		/>
	);
});

ForecastTagRemoveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	onRemove: PropTypes.func,
};

export default ForecastTagRemoveModal;
