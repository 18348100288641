import React from 'react';

import PropTypes from 'prop-types';

const Text = (props) => {
	const { children } = props;

	return (
		<p className="asteria-component__typography asteria-component__text asteria-component__text--size-md">
			{children}
		</p>
	);
};

Text.displayName = 'Text';

Text.propTypes = { children: PropTypes.node };

export default Text;
