import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';

import * as selectors from './selectors';

export function useClients({ statistics }) {
	const type = useSelector(selectors.type, (a, b) => isEqual(a, b));

	const clients = useSelector((store) =>
		AppStore.selectors.clients(store, { type: type }),
	);

	return React.useMemo(() => {
		return clients
			.map((object) => ({
				...object,
				revenue: statistics?.[object?._id ?? object?.id] ?? 0,
			}))
			.sort(({ revenue: a }, { revenue: b }) => b - a);
	}, [clients, statistics]);
}

export function useStatistics({ onSubmit }) {
	const range = useSelector(selectors.sorting, (a, b) => isEqual(a, b));

	const [{ loading, data }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'START':
					return { ...state, loading: true };

				case 'STOP':
					return { ...state, loading: false };

				case 'SET':
					return { ...state, data: action?.payload };

				default:
					return state;
			}
		},
		{ loading: true, data: {} },
	);

	React.useEffect(() => {
		async function fetch() {
			dispatch({ type: 'START' });

			const response = await onSubmit?.('clients:statistics', {
				range: range,
			});

			dispatch({
				type: 'SET',
				payload: response.reduce(
					(acc, object) => ({
						...acc,
						[object?.id]: Math.abs(object?.revenue?.total ?? 0),
					}),
					{},
				),
			});
			dispatch({ type: 'STOP' });
		}

		fetch();
	}, [onSubmit, range]);

	return [loading, data];
}
