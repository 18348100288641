import { createSelector } from 'reselect';

// import { formatISO, startOfDay, subDays } from 'date-fns';
import { isEqual } from 'lodash-es';

import { getViewport } from '@asteria/component-core/utils';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import getLayoutSize from '@asteria/utils-funcs/getLayoutSize';

export const layoutSize = createSelector(
	(store) => store?.app?.user?.settings?.layout?.size,
	(layoutSize) => {
		const [, viewportHeight] = getViewport();

		let height = layoutSize?.[viewportHeight]?.height;

		if (Number.parseFloat(height)) {
			height = Number.parseFloat(height);
		} else {
			height = null;
		}

		return getLayoutSize(height);
	},
);

export const selectedDate = createSelector(
	(state) => state?.app?.selectedDate,
	(value) => value ?? null,
);

export const timesize = createSelector(
	(state) => state?.app?.timesize,
	(value) => value ?? 'month',
);

export const filters = createSelector(
	(store) => store?.app?.filters,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const types = createSelector(
	(store) => store?.app?.selectedTypes,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const scenarios = createSelector(
	(store) => store?.scenarios?.groupedData,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const tags = createSelector(
	(store) => store?.app?.tags ?? [],
	(data) => data.filter(({ name }) => name !== '$factoring'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const customers = createSelector(
	(store) => store?.app?.clients ?? [],
	(data) => data.filter(({ type }) => type === 'CUSTOMER'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const suppliers = createSelector(
	(store) => store?.app?.clients ?? [],
	(data) => data.filter(({ type }) => type === 'SUPPLIER'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isValid = createSelector(
	TransactionStore.selectors.item,
	(store) => store?.app?.selectedTypes ?? [],
	(store) => store?.app?.filters ?? [],
	(item, types, filters) => {
		if (!item) {
			return false;
		}

		if (types.length === 1) {
			const [type] = types;

			if (item.type !== type) {
				return false;
			}
		}

		if (filters.length) {
			const objectTags = (item?.meta?.tags ?? [])
				.map((object) => object._id ?? object.id)
				.filter(Boolean);

			const links = item?.links ?? [];

			const invoice = links.find(
				(object) => object?.type === 'INVOICE_PAYMENT',
			);

			const clientId =
				invoice?.clientId ??
				invoice?.invoiceDetails?.client?.id ??
				links.find((object) => object?.type === 'CLIENT')?.id;

			const { clients, currencies, statuses, tags } = filters.reduce(
				(acc, { type, id }) => {
					if (type === 'client') {
						acc.clients.push(id);
					}

					if (type === 'currency') {
						acc.currencies.push(id);
					}

					if (type === 'status') {
						acc.statuses.push(id);
					}

					if (type === 'tag') {
						acc.tags.push(id);
					}

					return acc;
				},
				{ clients: [], currencies: [], statuses: [], tags: [] },
			);

			if (clients.length && !clients.includes(clientId)) {
				return false;
			}

			if (
				currencies.length &&
				!currencies.includes(item?.sums?.original?.currency)
			) {
				return false;
			}

			if (statuses.length && !statuses.includes(item?.status)) {
				return false;
			}

			if (tags.length && !tags.find((id) => objectTags.includes(id))) {
				return false;
			}
		}

		return true;
	},
);
