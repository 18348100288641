import PropTypes from 'prop-types';

import { SizeProp } from '../PropTypes';

export const TypographyPropTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	type: PropTypes.oneOf(['text', 'title']),
	weight: PropTypes.oneOf([
		'thin',
		'extralight',
		'light',
		'normal',
		'regular',
		'medium',
		'semibold',
		'bold',
		'black',
	]),
	align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
	size: SizeProp('title'),
	separator: PropTypes.bool,
};

export const TitlePropTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
	size: SizeProp('title'),
};

export const TextPropTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	weight: PropTypes.oneOf([
		'thin',
		'extralight',
		'light',
		'normal',
		'regular',
		'medium',
		'semibold',
		'bold',
		'black',
	]),
	align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
	size: SizeProp('full'),
};
