import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { HomeTeaser } from '../../components/teaser';

import './styles.scss';

const SectionTeaser = React.memo((props) => {
	const { onAction } = props;

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-teaser',
			)}
		>
			<HomeTeaser onAction={onAction} />
		</div>
	);
});

SectionTeaser.displayName = 'SectionTeaser';
SectionTeaser.propTypes = { onAction: PropTypes.func };

export default SectionTeaser;
