import React from 'react';

import FinancialView from '@asteria/view-financial';

import { LayoutContext } from '../../context';

import './styles.scss';

const FinancialPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return <FinancialView onAction={onAction} onSubmit={onSubmit} />;
};

FinancialPage.displayName = 'FinancialPage';

FinancialPage.propTypes = {};

export default FinancialPage;
