import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

import SettingsButton from '../../components/button/settings';

import './styles.scss';

const Switcher = React.memo((props) => {
	const { active, setActive } = props;

	return (
		<Group
			className="asteria-component__forecaster-switcher"
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="center"
		>
			<Text size="sm">
				{TranslationService.get([
					`forecaster.page.category.switcher.label`,
				])}
			</Text>
			<Chip
				size="sm"
				label={TranslationService.get([
					`forecaster.page.category.switcher.chip.graph.label`,
				])}
				active={active === 'graph'}
				onClick={setActive?.('graph')}
			/>
			<Chip
				size="sm"
				label={TranslationService.get([
					`forecaster.page.category.switcher.chip.transactions.label`,
				])}
				active={active === 'transactions'}
				onClick={setActive?.('transactions')}
			/>
			<SettingsButton />
		</Group>
	);
});

Switcher.displayName = 'Switcher';
Switcher.propTypes = { active: PropTypes.string, setActive: PropTypes.func };

export default Switcher;
