import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Progress from '@asteria/component-core/progress';
import { Text } from '@asteria/component-core/typography';
import { isPossibleToClick } from '@asteria/component-core/utils';

import {
	ActionBarSectionActions,
	Action as BaseAction,
} from '@asteria/component-actionbar';

import { TranslationService } from '@asteria/language';

import WelcomeContext from '../../../context';
import * as WelcomeSelectors from '../../../selectors';

import './styles.scss';

function useVisibility({ sticky, name, onAction, isCompleted }) {
	const isVisible = useSelector((store) =>
		WelcomeSelectors.isActionVisible(store, name),
	);

	const [visible, setVisible] = React.useState(
		isVisible && (!isCompleted || sticky),
	);

	const onHide = React.useCallback(() => {
		setVisible(false);
		return onAction?.('welcome:action:hide', name);
	}, [name, onAction]);

	React.useEffect(() => {
		if (isCompleted && !sticky) {
			onHide();
		}
	}, [isCompleted, onHide, sticky]);

	return React.useMemo(
		() => ({ visible: visible, onHide: onHide }),
		[onHide, visible],
	);
}

function useProgress({ name, wizard }) {
	const { wizard: ctxWizard } = React.useContext(WelcomeContext);

	const config = ctxWizard?.[wizard];

	return useSelector(
		(store) => {
			const step = WelcomeSelectors.progress(store, name);
			const steps = (config?.steps ?? []).length + !!config?.done;
			const progress = !step ? 0 : Math.min((step / steps) * 100, 100);

			return { step, steps, progress };
		},
		(a, b) => isEqual(a, b),
	);
}

const WizardAction = (props) => {
	const { sticky, name, wizard, onAction } = props;

	const ref = React.useRef(null);

	const { step, steps, progress } = useProgress({
		name: name,
		wizard: wizard,
	});

	const isCompleted = progress === 100;

	const { visible, onHide } = useVisibility({
		sticky: sticky,
		name: name,
		onAction: onAction,
		isCompleted: isCompleted,
	});

	const onOpen = React.useCallback(
		(event) => {
			if (isPossibleToClick(event, ref.current)) {
				event.preventDefault();
				event.stopPropagation();

				return onAction?.('wizard:show', {
					name: name,
					wizard: wizard,
				});
			}
		},
		[name, onAction, wizard],
	);

	if (!visible) {
		return null;
	}

	return (
		<BaseAction
			className="asteria-view__welcome__action"
			title={TranslationService.get([
				'welcome.action.question.title',
				`welcome.action.question.${wizard}.title`,
				`welcome.action.question.${name}.title`,
			])}
			variant="wizard"
			onClick={!isCompleted ? onOpen : null}
			content={[
				progress ? (
					<div
						key="wizard-progress"
						className="asteria-view__welcome__action-progress"
					>
						<Group
							flex
							direction="horizontal"
							verticalAlign="center"
						>
							<Progress steps={steps} progress={progress} />
							{isCompleted ? (
								<Button
									size="sm"
									icon={[
										'edit',
										'welcome-action',
										'welcome-action-edit',
										'welcome-action-wizard',
										'welcome-action-wizard-edit',
									]}
									iconSize="md"
									onClick={onOpen}
									analyticsKey={`welcome.action.question.edit`}
								/>
							) : (
								<Text size="sm">
									{TranslationService.get(
										'welcome.action.question.progress',
										`{{step}} / {{steps}}`,
										{
											step: step,
											steps: steps,
											progress: progress,
										},
									)}
								</Text>
							)}
						</Group>
					</div>
				) : null,
				<Text key="wizard-content" size="md">
					{TranslationService.get([
						'welcome.action.question.content',
						`welcome.action.question.${wizard}.content`,
						`welcome.action.question.${name}.content`,
					])}
				</Text>,
			]}
			actions={
				<ActionBarSectionActions>
					<Group flex horizontalAlign="center" verticalAlign="center">
						{isCompleted ? (
							[
								<Button
									key="wizard-complete"
									variant="tertiary"
									icon={[
										'check',
										'welcome-action',
										'welcome-action-check',
										'welcome-action-wizard',
										'welcome-action-wizard-check',
									]}
									iconSize="md"
									size="md"
									onClick={onHide}
									analyticsKey={`welcome.action.question.done`}
								/>,
								<Text key="wizard-hide" size="xs">
									{TranslationService.get([
										`welcome.action.question.status`,
										`welcome.action.question.status.done`,
									])}
								</Text>,
							]
						) : (
							<Button
								label={TranslationService.get([
									'welcome.action.integration.button.label',
									'welcome.action.integration.question.button.label',
								])}
								variant="link"
								icon={[
									'triangle-right',
									'welcome-action',
									'welcome-action-triangle-right',
									'welcome-action-wizard',
									'welcome-action-wizard-triangle-right',
								]}
								size="md"
								onClick={onOpen}
								analyticsKey="welcome.action.question.wizard"
							/>
						)}
					</Group>
				</ActionBarSectionActions>
			}
		/>
	);
};

WizardAction.displayName = 'WizardAction';

WizardAction.propTypes = {
	className: PropTypes.string,
	sticky: PropTypes.bool,
	name: PropTypes.string,
	wizard: PropTypes.string,
	onAction: PropTypes.func,

	title: PropTypes.string,
	content: PropTypes.string,
};

export default WizardAction;
