function start(state) {
	state.actions.loading = true;
}

function stop(state) {
	state.actions.loading = false;
}

function set(state, { payload }) {
	state.actions.data = payload;
}

function remove(state, { payload }) {
	const IDs = [].concat(payload).filter(Boolean);

	state.actions.data = state.actions.data.filter(
		(object) => !IDs.includes(object?._id ?? object?.id) !== null,
	);
}

export {
	start as startActionsFetching,
	stop as stopActionsFetching,
	set as setActions,
	remove as removeActions,
};
