import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Tooltip from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const YAxisLabel = (props) => {
	const {
		className,
		label,
		value,
		prefix,
		size,
		tooltip,
		children,
		onHover,
		onClick,
	} = props;

	const handleClick = useCallback(() => {
		onClick?.({ label: label });
	}, [onClick, label]);

	const handleHover = useCallback(() => {
		onHover?.({ label: label });
	}, [onHover, label]);

	const handleLeave = useCallback(() => {
		onHover?.({ label: null });
	}, [onHover]);

	return (
		<div
			onMouseEnter={handleHover}
			onMouseLeave={handleLeave}
			onClick={handleClick}
			className={cn('asteria-graph-y-axis-label', className, {
				[`asteria-graph-y-axis-label-${size}`]: size,
				'asteria--variant-zero': value === 0,
				'asteria--variant-positive': value > 0,
				'asteria--variant-negative': value < 0,
			})}
		>
			<Tooltip label={tooltip}>
				<TextGroup>
					<Text size="xs">{prefix}</Text>
					<Text weight="light" size="xs">
						{label}
					</Text>
				</TextGroup>
				{children}
			</Tooltip>
		</div>
	);
};

YAxisLabel.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	prefix: PropTypes.string,
	size: SizeProp(),
	tooltip: PropTypes.string,
	children: PropTypes.node,
	onHover: PropTypes.func,
	onClick: PropTypes.func,

	value: PropTypes.bool,
};

export default YAxisLabel;
