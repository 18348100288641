import React from 'react';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';

import Notifications from '@asteria/component-notifications';
import Statement from '@asteria/component-statement';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import './styles.scss';

const StatementView = (props) => {
	const { onFetch, onAction } = props;

	useTourShowAction();

	return (
		<div className="asteria-view__statement">
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get(
							['statement.title', 'statement.modal.title'],
							// eslint-disable-next-line spellcheck/spell-checker
							'Årsrapport',
						)}
					/>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-statement">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<Statement
				onSubmit={onFetch}
				onAction={onAction}
				hideHeader
				hideFooter
			/>
		</div>
	);
};

StatementView.propTypes = {
	onFetch: PropTypes.func,
	onAction: PropTypes.func,
};

export default StatementView;
