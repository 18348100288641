import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Progress from '@asteria/component-core/progress';
import { Text, Title } from '@asteria/component-core/typography';

import { ListGroup, ListHeader, ListItem } from '@asteria/component-list';
import { useBulkFeatures } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

import Integration from '../Integration';

import './styles.scss';

const selectors = {
	integrations: createSelector(
		(store) => store?.integrations?.items ?? [],
		(_, type) => type,
		(objects, type) =>
			objects
				.filter((object) => object?.type === type)
				.map((object) => object?._id ?? object?.id),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const IntegrationGroup = (props) => {
	const { type, loading, onAction, onSubmit, className } = props;

	const dispatch = useDispatch();

	const featureKey =
		type === 'erp'
			? 'onboarding-single-erp-integration'
			: 'onboarding-single-bank-integration';

	const hasSingleFeature = useBulkFeatures([
		['onboarding-single-integration', featureKey],
	]);

	const integrations = useSelector((store) =>
		selectors.integrations(store, type),
	);

	const handleCreate = React.useCallback(
		() =>
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
					data: { type: type },
				}),
			),
		[dispatch, type],
	);

	return (
		<ListGroup className={className}>
			<ListHeader>
				<ListItem>
					<Title size="sm">
						{TranslationService.get([
							'integrations.list.title',
							`integrations.list.${type}.title`,
						])}
					</Title>
				</ListItem>
			</ListHeader>
			{loading ? (
				<Progress progress={-1} />
			) : !integrations.length ? (
				<ListItem>
					<Text size="sm">
						{TranslationService.get([
							'integrations.list.title.empty',
							`integrations.list.${type}.title.empty`,
						])}
					</Text>
				</ListItem>
			) : (
				integrations.map((id) => (
					<Integration
						key={id}
						id={id}
						onAction={onAction}
						onSubmit={onSubmit}
					/>
				))
			)}
			{!hasSingleFeature || !integrations.length ? (
				<ListItem className="asteria-component__list__item--create-integration">
					<Button
						analyticsKey="integrations.add.erp"
						variant="link"
						icon="add"
						iconPosition="first"
						label={TranslationService.get([
							`integrations.list.add`,
							`integrations.list.${type}.add`,
						])}
						iconSize="lg"
						onClick={handleCreate}
						tooltip={TranslationService.get([
							`integrations.list.add.tooltip`,
							`integrations.list.${type}.add.tooltip`,
						])}
					/>
				</ListItem>
			) : null}
		</ListGroup>
	);
};

IntegrationGroup.displayName = 'IntegrationGroup';

IntegrationGroup.propTypes = {
	type: PropTypes.string,
	loading: PropTypes.bool,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	className: PropTypes.string,
};

export default IntegrationGroup;
