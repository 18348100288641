import React from 'react';

export const AuthContext = React.createContext({
	/**
	 *
	 * @param { string } action
	 * @param { unknown } data
	 * @returns { void }
	 */
	// eslint-disable-next-line no-unused-vars
	onAction: (action, data) => {},
	/**
	 *
	 * @param { string } action
	 * @param { unknown } data
	 * @returns { Promise<unknown> }
	 */
	// eslint-disable-next-line no-unused-vars
	onSubmit: (action, data) => null,

	/** @type { string } */
	partnerId: null,

	/** @type { React.ReactNode } */
	logo: null,

	/** @type { boolean } */
	languages: true,

	/** @type { boolean } */
	signup: true,

	/** @type { boolean } */
	passwordReset: true,

	/** @type { boolean } */
	signupCode: true,

	/** @type { boolean } */
	homepage: false,

	/**
	 *
	 * @param { string } code
	 * @returns { void }
	 */
	// eslint-disable-next-line no-unused-vars
	onLanguageChange: (code) => null,
});
