import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const Input = (props) => {
	const {
		name,
		placeholder,
		type = 'text',
		onChange,
		value,
		required,
	} = props;

	const [active, setActive] = React.useState(false);

	const handleFocus = React.useCallback(() => {
		setActive(true);
	}, []);

	const handleBlur = React.useCallback(() => {
		setActive(false);
	}, []);

	return (
		<div
			className={cn(
				'asteria-component__input',
				'asteria--size-md',
				'asteria-component__input--type-text',
				'asteria-component__input--icon-position-first',
				{ 'asteria--state-active': active },
			)}
		>
			<label className="asteria-component__label__wrapper">
				<div className="asteria-component__input__inner">
					<div className="asteria-component__input__wrapper">
						<input
							name={name}
							placeholder={placeholder}
							autoComplete="off"
							type={type}
							onFocus={handleFocus}
							onBlur={handleBlur}
							value={value}
							onChange={onChange}
							required={required}
						/>
					</div>
				</div>
			</label>
		</div>
	);
};

Input.displayName = 'Input';

Input.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.string,

	type: PropTypes.string,
	required: PropTypes.bool,
};

export default Input;
