import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<{ className: string, count: boolean | number }> } */
const Skeleton = React.memo(function Skeleton(props) {
	const { className, count = 1 } = props;

	return (
		<span
			className={cn(
				'flex flex-col gap-2 w-full',
				'asteria-component__skeleton',
				className,
			)}
		>
			{Array.from({ length: +count }).map((value, index) => (
				<span
					key={index}
					className="bg-skeleton-normal-background h-2 w-full rounded-full animate-pulse"
				/>
			))}
		</span>
	);
});

Skeleton.displayName = 'Skeleton';

Skeleton.propTypes = {
	className: PropTypes.string,
	count: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default Skeleton;
