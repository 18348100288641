import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { addMonths, subMonths } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Forecaster from '@asteria/component-forecaster';
import {
	formatResponse,
	validateFormValues,
} from '@asteria/component-forecaster/utils';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { useTourShowAction } from '@asteria/utils-hooks/tour';

import { formatTags, getTags, groupCompleted } from './utils';

const ForecasterView = (props) => {
	const { onAction, onFetch } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const hasOverdueFeature = useFeature('forecaster-status-overdue');

	const sourceScenario = useSelector(
		ScenarioStore.selectors.sourceScenario,
		(a, b) => isEqual(a, b),
	);

	const sourceScenarioData = useSelector(
		ScenarioStore.selectors.sourceScenarioData,
		(a, b) => isEqual(a, b),
	);

	const response = useQueries({
		queries: [
			{
				queryKey: ['forecaster', 'history'],
				queryFn: async () => {
					let status = ['UNPAID', 'SIGNED'];

					if (hasOverdueFeature) {
						status.push('OVERDUE');
					}

					return onFetch?.('forecaster:history', {
						startDate: subMonths(new Date(), 12),
						endDate: addMonths(new Date(), 12),
						status: status,
					}).then((response) => formatResponse(response, { store }));
				},

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				placeholderData: null,
			},
			{
				queryKey: ['forecaster', 'future', { sourceScenario }],
				queryFn: async () =>
					onFetch?.('forecaster:changes', {
						scenarioName: sourceScenario,
					})
						.then((response) => formatResponse(response, { store }))
						.then((data) => ({ ...data, scenario: sourceScenario }))
						.then((data) =>
							validateFormValues(data, sourceScenarioData),
						)
						.then((data) => {
							const completed = groupCompleted(data);
							const tags = getTags(data, completed);

							const formData = validateFormValues(
								JSON.parse(JSON.stringify(data)),
								sourceScenarioData,
							);

							dispatch(
								ForecasterStore.init({
									data: formData,
									tags: formatTags(tags, completed),
								}),
							);

							return data;
						}),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				placeholderData: null,
			},
			{
				queryKey: ['forecaster', 'predictions'],
				queryFn: async () => onFetch?.('prediction:fetch'),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				placeholderData: null,
			},
			{
				queryKey: ['forecaster', 'actions'],
				queryFn: async () => onFetch?.('forecast:actions:fetch'),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				placeholderData: null,
			},
		],
	});

	const [history, future] = response;

	const loading = response.some((object) => object.isFetching);
	const ready = response.some((object) => object.isFetched);

	React.useEffect(() => {
		if (loading) {
			dispatch(ForecasterStore.start());
		}
	}, [dispatch, loading]);

	React.useEffect(() => {
		if (!loading && ready) {
			dispatch(ForecasterStore.stop());
		}
	}, [dispatch, ready, loading]);

	useTourShowAction(undefined, { skip: loading });

	return (
		<Forecaster
			forecast={loading ? null : future.data}
			history={loading ? null : history.data}
			loading={loading}
			scenario={sourceScenarioData}
			onAction={onAction}
			onSubmit={onFetch}
		/>
	);
};

ForecasterView.displayName = 'ForecasterView';

ForecasterView.propTypes = {
	integrations: PropTypes.shape({
		state: PropTypes.any,
		set: PropTypes.func,
		reset: PropTypes.func,
	}),
	onAction: PropTypes.func,
	onFetch: PropTypes.func,
};

export default React.memo(ForecasterView);
