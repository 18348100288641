import { get } from 'lodash-es';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import * as Changes from './changes';
import * as Tag from './tag';
import * as Type from './type';
import { getPath, validateFormContext } from './utils';

/**
 *
 * @param { { type: string, category: string } } options
 * @param { unknown } ctx
 * @returns { { value: number, min: number } }
 */
function getTransactionValue({ type, category }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category });

	const tags = get(context.form, path.concat('tags')) ?? {};

	return Object.values(tags)
		.flatMap((object) => Object.values(object?.transactions ?? {}))
		.reduce(
			(acc, { total, probability }) => ({
				value: acc.value + (total || 0),
				min: acc.min + (total || 0) * (probability || 1),
			}),
			{ value: 0, min: 0 },
		);
}

function shouldRecalculateParent(value) {
	return value === true || ['type'].includes(value);
}

export function setProbability({ type, category, value, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: value,
			},
			context,
		),
	);

	const tags = get(context.form, path.concat('tags')) ?? {};

	for (const tag in tags) {
		changes.push(
			...Tag.setProbability({ type, category, tag, value }, context),
		);
	}

	if (shouldRecalculateParent(recalculate)) {
		changes.push(...Type.recalculate({ type, recalculate }, context));
	}

	return changes;
}

export function setValue({ type, category, value, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category });

	const changes = [];

	const current = getTransactionValue({ type, category }, context);
	const diff = value / current.value || 0;

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: value,
			},
			context,
		),
	);

	const tags = get(context.form, path.concat('tags')) ?? {};

	for (const tag in tags) {
		const path = getPath({ type, category, tag });

		const previous = get(context.form, path.concat('total')) || 0;
		const next = previous * diff;

		changes.push(
			...Tag.setValue({ type, category, tag, value: next }, context),
		);
	}

	if (shouldRecalculateParent(recalculate)) {
		changes.push(...Type.recalculate({ type, recalculate }, context));
	}

	return changes;
}

export function recalculate({ type, category, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category });

	const changes = [];

	const current = getTransactionValue({ type, category }, context);

	const probability = normalizeProbability(
		current.value ? current.min / current.value : 1,
	);

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: current.value,
			},
			context,
		),
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: probability,
			},
			context,
		),
	);

	if (shouldRecalculateParent(recalculate)) {
		changes.push(...Type.recalculate({ type, recalculate }, context));
	}

	return changes;
}

export function remove({ type, category, recalculate: refresh }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type, category });

	const changes = [];

	const tags = get(context.form, path.concat('tags')) ?? {};

	for (const tag in tags) {
		changes.push(...Tag.remove({ type, category, tag }, context));
	}

	changes.push(...recalculate({ type, category }, context));

	if (shouldRecalculateParent(refresh)) {
		changes.push(
			...Tag.recalculate(
				{ type, category, recalculate: refresh },
				context,
			),
		);
	}

	changes.push(
		Changes.create(
			{
				type: 'REMOVE_CATEGORY',
				name: getPath({ type }).concat('tags').join('.'),
				value: { category },
			},
			context,
		),
	);

	return changes;
}
