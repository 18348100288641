import { set, unset } from 'lodash-es';

function getAvailableChanges(changes) {
	const grouped = changes.reduce(
		(acc, object) => ({ ...acc, [object.name]: 0 }),
		{},
	);

	let available = [];

	for (const change of changes.reverse()) {
		const { name } = change;

		if (grouped[name]) {
			continue;
		}

		available.push(change);
		grouped[name] += 1;
	}

	available = available.reverse();

	return available;
}

export default ({ changes, getValues, event, reset }) => {
	const form = getValues();

	const available = getAvailableChanges([...changes]);

	const removed = available.filter(({ op }) => op === 'unset');
	const sets = available.filter(({ op }) => op === 'set');

	sets.forEach(({ name, value }) => {
		set(form, name, value);
	});

	removed.forEach(({ name }) => {
		unset(form, name);
	});

	const formChanges = (form?.$changes ?? []).concat(
		changes.map((value) => ({ ...value, $event: value?.$event ?? event })),
	);

	set(form, '$touched', true);
	set(form, '$changes', formChanges);

	reset?.(form);
};

export const revertFormChanges = ({
	changes,
	getValues,
	setValue,
	unregister,
	shouldDirty = true,
}) => {
	getValues();

	const available = getAvailableChanges([...changes]);

	const removed = available.filter(
		({ previousValue }) => previousValue === undefined,
	);

	const sets = available.filter(
		({ previousValue }) => previousValue !== undefined,
	);

	sets.forEach(({ name, previousValue: value }) => {
		setValue(name, value, {
			shouldDirty: shouldDirty,
			shouldTouch: shouldDirty,
			shouldValidate: shouldDirty,
		});
	});

	getValues();

	removed.forEach(({ name }) => {
		setValue(name, undefined, {
			shouldDirty: shouldDirty,
			shouldTouch: shouldDirty,
			shouldValidate: shouldDirty,
		});

		unregister(name, {
			keepDefaultValue: false,
			keepValue: false,
		});
	});

	const groupedChanges = changes.reduce(
		(acc, object) => ({ ...acc, [object?.uuid]: object }),
		{},
	);

	const formChanges = (getValues()?.$changes ?? []).filter(
		(value) => !groupedChanges[value.uuid],
	);

	setValue('$changes', formChanges, {
		shouldDirty: shouldDirty,
		shouldTouch: shouldDirty,
		shouldValidate: shouldDirty,
	});
};
