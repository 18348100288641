import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import Select, { Option } from '@asteria/component-form/select';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import { FormContext } from '../../../withForm';

import TagSelectorItem from './item';
import { View as TagSelectorItemLabelView } from './item/label';
import Switcher from './switcher';
import { getInitialType } from './utils';

import './styles.scss';

const TagSelectorSelect = (props) => {
	const {
		name,
		onChange,

		include,
		exclude,
		type: $type,
		tooltip,
		size,

		selected = [],

		showProbability = false,
	} = props;

	const dispatch = useDispatch();

	const [type, setType] = React.useState(() => getInitialType($type));

	React.useEffect(() => {
		setType(getInitialType($type));
	}, [$type]);

	const tags = useSelector((store) =>
		ForecasterStore.selectors.tags.available(store, {
			type: type,
			exclude: exclude,
			include: include,
		}),
	);

	const onTagCreate = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.TagEdit,
			}),
		);
	}, [dispatch]);

	const { getStaticForm } = React.useContext(FormContext);

	const onTagEdit = React.useCallback(
		(object) => () => {
			const form = getStaticForm();

			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.TagEdit,
					data: { data: object, form: cloneDeep(form) },
				}),
			);
		},
		[dispatch, getStaticForm],
	);

	return (
		<Select
			className={cn(
				'asteria-component__selector',
				'asteria-component__forecaster-selector',
				'asteria--variant-select',
				'asteria--type-categories',
			)}
			scroll
			indeterminate
			name={name}
			size={size}
			onChange={onChange}
			tooltip={tooltip}
		>
			{$type && !['deposit', 'withdraw'].includes($type) ? (
				<Switcher type={type} onChange={setType} />
			) : null}

			{tags.map((object) => (
				<TagSelectorItem
					key={object?._id ?? object?._id}
					as={Option}
					object={object}
					value={[object?.category?.name, object?.name].join('-')}
					onEdit={onTagEdit(object)}
					selected={[]
						.concat(selected)
						.some(
							(value) =>
								value?.type === object?.type &&
								value?.category === object?.category?.name &&
								value?.tag === object?.name,
						)}
					showProbability={showProbability}
					render={({ object }) => (
						<TagSelectorItemLabelView
							label={FormatUtils.formatTag({
								type: object?.type,
								category: object?.category?.name,
								tag: object?.name,
							})}
							colors={[
								object?.color,
								FormatUtils.replace([
									object?.category?.name,
									object?.name,
								]).join('-'),
							]}
						/>
					)}
				/>
			))}

			<Option
				className={cn(
					'asteria-component__selector-item',
					'asteria-component__forecaster-selector-item',
					'asteria--variant-create',
				)}
				analyticsKey="forecaster.tag.select.create"
				onClick={onTagCreate}
				noColors
				value={null}
			>
				<Button
					label={TranslationService.get([
						'forecaster.tags.dropdown.create',
					])}
					size="md"
					iconSize="sm"
					icon="add"
					variant="tertiary"
				/>
			</Option>
		</Select>
	);
};

TagSelectorSelect.displayName = 'TagSelectorSelect';
TagSelectorSelect.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,

	onAction: PropTypes.func,

	variant: PropTypes.oneOf(['icon', 'select']),

	include: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),
	exclude: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),

	type: PropTypes.string,
	tooltip: PropTypes.string,
	size: PropTypes.string,

	selected: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),
	active: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),

	showProbability: PropTypes.bool,
};

export default TagSelectorSelect;
