import * as AppStore from '@asteria/datalayer/stores/app';
import * as GraphStore from '@asteria/datalayer/stores/graph';

export function applyCashflowSettings({ settings = {}, dispatch }) {
	if (!settings) {
		return;
	}

	if (settings?.resetFilterType) {
		dispatch(AppStore.resetFilterType());
	}

	if (settings?.filters) {
		dispatch(AppStore.setFilters(settings?.filters));
	}

	if (settings?.selectedDate) {
		dispatch(AppStore.setSelectedDate(settings?.selectedDate));
	}

	if (settings?.graphActiveGroups) {
		dispatch(GraphStore.setActiveGroups(settings?.graphActiveGroups));
	}

	if (settings?.currentDate) {
		dispatch(AppStore.setCurrentDate(settings?.currentDate));
	}

	if (settings?.graphOptions) {
		dispatch(GraphStore.setGraphOptions(settings?.graphOptions));
	}

	if (settings?.activeBars) {
		dispatch(GraphStore.setActiveBars(settings?.activeBars));
	}

	if (settings?.graphTypes) {
		dispatch(AppStore.setSelectedType(settings?.graphTypes));
	}

	return;
}
