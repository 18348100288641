import { parseISO } from 'date-fns';

export default () => {
	return 0;
};

/**
 * @param { string | Date } date
 * @returns { Date }
 */
export function parseDate(date) {
	if (typeof date === 'string') {
		return parseISO(date);
	}

	return date;
}
