import React from 'react';

import PropTypes from 'prop-types';

import Trends from '../../../trends';
import { useTranslationOptions } from '../hooks';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & Pick<Props, 'type' | 'category' | 'tag'>> } */
const RowTrends = React.memo(function RowTrends(props) {
	const {
		type,
		category,
		tag,
		translationOptions: $translationOptions,
	} = props;

	const {
		source,
		form,
		options: translationOptions,
	} = useTranslationOptions($translationOptions, { type, category, tag });

	if (!source?.total) {
		return <span />;
	}

	return (
		<Trends
			direction={form.total >= source?.total ? 'up' : 'down'}
			size="sm"
			translationOptions={translationOptions}
		/>
	);
});

RowTrends.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default RowTrends;
