import React from 'react';

import WelcomeView from '@asteria/view-welcome';

import { LayoutContext } from '../../context';

import './styles.scss';

const WelcomePage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<WelcomeView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

WelcomePage.displayName = 'WelcomePage';

WelcomePage.propTypes = {};

export default WelcomePage;
