import React from 'react';

import { useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-core/tabs';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';

import Modal from '@asteria/component-modal';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ApplyCredit from './ApplyCredit';
import NeedInput from './NeedInput';
import StatementTable from './Table';
import { generateCSV } from './utils/generateCSV';

import './styles.scss';

const selectors = {
	hasReadStatementInfo: createSelector(
		(state) => state?.app?.user?.settings?.flags?.hasReadStatementInfo,
		(value) => value ?? false,
	),
	erp: createSelector(
		(state) => state?.integrations?.items ?? [],
		(value) => value?.find?.(({ type }) => type === 'erp'),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	isLoading: createSelector(
		(state) => state?.integrations?.items ?? [],
		(value) =>
			(value?.find?.(({ type }) => type === 'erp')?.status?.state ||
				'IDLE') === 'INITIATING',
	),
};

const StatementContent = (props) => {
	const { onSubmit, onAction, onClose, hideDetailCard } = props;

	const statement = useSelector(AppStore.selectors.statement);
	const erp = useSelector(selectors.erp);
	const isLoading = useSelector(selectors.isLoading);

	React.useEffect(() => {
		if (!statement) {
			onSubmit?.('vouchers:statement');
		}
	}, [onSubmit, statement]);

	React.useEffect(() => {
		if (!isLoading) {
			onSubmit?.('vouchers:statement');
		}
	}, [onSubmit, isLoading]);

	const store = useStore();

	const hasCreditApplication = useFeature('credit-application');

	const setHasReadInfo = React.useCallback(() => {
		const hasReadStatementInfo = selectors.hasReadStatementInfo(
			store.getState(),
		);

		if (!hasReadStatementInfo) {
			onAction?.('updateUserSettings', {
				flags: { hasReadStatementInfo: true },
			});
		}
	}, [onAction, store]);

	const handleCreditActivate = React.useCallback(() => {
		if (hasCreditApplication) {
			setHasReadInfo();
			onAction?.('go', '/credit');
			onClose?.();
		}
	}, [hasCreditApplication, setHasReadInfo, onAction, onClose]);

	const onDownload = React.useCallback(() => {
		const csvData = generateCSV(statement);

		onSubmit?.('vouchers:statement:download', csvData);
	}, [onSubmit, statement]);

	if (!erp) {
		return <NeedInput onAction={onAction} />;
	}

	return [
		<Tabs key="tabs" active="result">
			<TabsNavigation>
				<Button
					variant="tab"
					tab="result"
					size="sm"
					label={TranslationService.get(
						'statement.tab.result',
						// eslint-disable-next-line spellcheck/spell-checker
						'Resultaträkning',
					)}
				/>
				<Button
					variant="tab"
					tab="balance"
					size="sm"
					label={TranslationService.get(
						'statement.tab.balance',
						// eslint-disable-next-line spellcheck/spell-checker
						'Balansrapport',
					)}
				/>
				<FeatureFlag feature="statement-tab-workingcapital">
					<Button
						variant="tab"
						tab="workingcapital"
						size="sm"
						label={TranslationService.get(
							'statement.tab.workingcapital',
							// eslint-disable-next-line spellcheck/spell-checker
							'Rörelsekapital',
						)}
					/>
				</FeatureFlag>

				<FeatureFlag feature="statement-tab-other">
					<Button
						variant="tab"
						tab="other"
						size="sm"
						label={TranslationService.get(
							'statement.tab.other',
							// eslint-disable-next-line spellcheck/spell-checker
							'Övrigt',
						)}
					/>
				</FeatureFlag>
			</TabsNavigation>
			<FeatureFlag feature="statement-download">
				<Button
					className="statement-download-button"
					label={TranslationService.get([
						'button.download',
						'action.download',
						'statement.action.download.label',
					])}
					icon="export"
					variant="link"
					onClick={onDownload}
				/>
			</FeatureFlag>
			<TabsContent>
				<div tab="result">
					<StatementTable
						data={statement?.accountingResults}
						type="result"
						onCreditActivate={handleCreditActivate}
						isLoading={!statement || isLoading}
					/>
				</div>
				<div tab="balance">
					<StatementTable
						data={statement?.accountingBalanceSheet}
						type="balance"
						onCreditActivate={handleCreditActivate}
						isLoading={!statement || isLoading}
					/>
				</div>
				<div tab="workingcapital">
					<StatementTable
						data={statement?.accountingWorkingCapital}
						type="workingcapital"
						onCreditActivate={handleCreditActivate}
						isLoading={!statement || isLoading}
					/>
				</div>
				<div tab="other">
					<StatementTable
						data={statement?.accountingMisc}
						type="other"
						onCreditActivate={handleCreditActivate}
						isLoading={!statement || isLoading}
					/>
				</div>
			</TabsContent>
		</Tabs>,
		!hideDetailCard && (
			<FeatureFlag key="credit" feature="credit-application">
				<FeatureFlag feature="credit-application-teaser">
					<ApplyCredit
						activateCredit={handleCreditActivate}
						onClose={setHasReadInfo}
					/>
				</FeatureFlag>
			</FeatureFlag>
		),
	];
};

StatementContent.displayName = 'StatementContent';

StatementContent.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,

	hideDetailCard: PropTypes.bool,
};

const Statement = (props) => {
	const {
		className,
		onClose,
		onSubmit,
		onAction,
		hideHeader,
		hideDetailCard,
		hideFooter,
	} = props;

	return (
		<Wrapper
			className={cn('asteria-component__statement', className)}
			scroll
		>
			{!hideHeader ? (
				<Header onClose={onClose}>
					{TranslationService.get(
						['statement.title', 'statement.modal.title'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Årsrapport',
					)}
				</Header>
			) : null}

			<Content scroll>
				<StatementContent
					onClose={onClose}
					onSubmit={onSubmit}
					onAction={onAction}
					hideDetailCard={hideDetailCard}
				/>
			</Content>
			{!hideFooter ? (
				<Footer>
					<FooterSection>
						<Button
							label={TranslationService.get([
								'button.close',
								'action.close',
								'statement.action.close.label',
							])}
							variant="tertiary"
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			) : null}
		</Wrapper>
	);
};

Statement.displayName = 'Statement';

Statement.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
	hideHeader: PropTypes.bool,
	hideDetailCard: PropTypes.bool,
	hideFooter: PropTypes.bool,
};

const StatementModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose} size="lg">
			<Statement {...props} />
		</Modal>
	);
};

StatementModal.displayName = 'StatementModal';

StatementModal.propTypes = {
	open: PropTypes.bool,

	className: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
	hideDetailCard: PropTypes.bool,
};

export default Statement;
export { StatementModal, NeedInput, ApplyCredit };
