import React, { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { cn } from '@asteria/utils-funcs/classes';

import Header from '../components/header';

import './layout.scss';

const Layout = (props) => {
	const { children, className, onAction } = props;

	const dispatch = useDispatch();
	const { getValues } = useFormContext();

	const handleAction = useCallback(
		(action, data) => {
			if (action === 'forecaster:settings:show') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.ForecasterSettings,
						data: {
							form: getValues(),
						},
					}),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, getValues, onAction],
	);

	return (
		<div className={cn('asteria-component__forecaster__view', className)}>
			<Header onAction={handleAction} />

			{children}
		</div>
	);
};

Layout.displayName = 'Layout';

Layout.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOf([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
	onAction: PropTypes.func,
};

export default React.memo(Layout);
