import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../../PropTypes';
import Group from '../../group';
import { Title } from '../../typography';
import { useLoading } from '../hooks';

import { BackButton, CloseButton, TourButton } from './actions';
import Postfix from './postfix';
import Prefix from './prefix';

import './styles.scss';

/**
 * @typedef Postfix
 * @property { boolean } loading
 * @property { string } position
 * @property { string } size
 * @property { string } icon
 * @property { string } analyticsKey
 * @property { unknown } collapseBtn
 * @property { unknown } closeBtn
 * @property { React.ReactNode | Partial<{ before: React.ReactNode, after: React.ReactNode }> } children
 */

/**
 * @typedef Prefix
 * @property { boolean } loading
 * @property { string } position
 * @property { string } icon
 * @property { unknown } backBtn
 * @property { React.ReactNode | Partial<{ before: React.ReactNode, after: React.ReactNode }> } children
 */

/**
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode } children
 * @property { string } verticalAlign
 * @property { boolean } isCollapsed
 * @property { Partial<Postfix> } postfix
 * @property { Partial<Prefix> } prefix
 * @property { string | string[] } tourKey
 * @property { React.ReactNode } logo
 * @property { boolean } loading
 * @property { (event: React.MouseEvent<HTMLDivElement>) => void } onClose
 * @property { (event: React.MouseEvent<HTMLDivElement>) => void } onBack
 * @property { (event: React.MouseEvent<HTMLDivElement>) => void } onCollapseClick
 */

/** @type { React.FC<Props> } */
const Header = React.forwardRef(function Header(props, ref) {
	const {
		className,
		onClose,
		onBack,
		children,
		verticalAlign = 'baseline',
		onCollapseClick,
		isCollapsed,
		postfix,
		prefix,

		loading: $loading,

		tourKey,
		logo,
		...rest
	} = props;

	const loading = useLoading($loading);

	return (
		<Group
			className={cn(
				'asteria-component__wrapper-header',
				{ 'asteria--state-loading': loading },
				className,
				{
					'asteria-component__wrapper-header--absolute-postfix':
						postfix?.position === 'absolute',
					'asteria-component__wrapper-header--absolute-prefix':
						prefix?.position === 'absolute',
				},
			)}
			direction="horizontal"
			verticalAlign={verticalAlign}
			ref={ref}
			{...rest}
		>
			{onBack || prefix?.children ? (
				<Prefix
					position={prefix?.position}
					onBack={onBack}
					{...prefix}
					size={prefix?.size ?? 'sm'}
					loading={prefix?.loading ?? loading}
				/>
			) : null}
			<div className="asteria-component__wrapper-header__content">
				{logo ? (
					<div className="asteria-component__wrapper-header__content__logo">
						{logo}
					</div>
				) : null}

				{typeof children === 'string' ? (
					<Title loading={loading}>{children}</Title>
				) : typeof children === 'function' ? (
					children({ loading })
				) : (
					children
				)}
			</div>

			{onCollapseClick || tourKey || onClose || postfix?.children ? (
				<Postfix
					onClose={onClose}
					tourKey={tourKey}
					isCollapsed={isCollapsed}
					onCollapseClick={onCollapseClick}
					loading={loading}
					{...postfix}
					size={postfix?.size ?? 'sm'}
					icon={postfix?.icon ?? 'close'}
				/>
			) : null}
		</Group>
	);
});

Header.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onBack: PropTypes.func,
	children: PropTypes.node,
	tourKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	logo: PropTypes.node,
	onCollapseClick: PropTypes.func,
	isCollapsed: PropTypes.bool,

	verticalAlign: PropTypes.string,

	postfix: PropTypes.shape({
		position: PropTypes.string,
		size: SizeProp(),
		icon: PropTypes.string,
		children: PropTypes.node,
		analyticsKey: PropTypes.string,
		loading: PropTypes.bool,

		collapseBtn: PropTypes.object,
		closeBtn: PropTypes.object,
	}),
	prefix: PropTypes.shape({
		position: PropTypes.string,
		children: PropTypes.node,
		size: SizeProp(),
		loading: PropTypes.bool,

		backBtn: PropTypes.object,
	}),

	loading: PropTypes.bool,
};

Header.defaultProps = {};

Header.displayName = 'WrapperHeader';

export default React.memo(Header);
export { BackButton, CloseButton, TourButton };
