import { decodeJwt } from 'jose';

export function decode(token) {
	if (!token) {
		return null;
	}

	try {
		return decodeJwt(token)?.data;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn('jwt:decode', err);
		return null;
	}
}
