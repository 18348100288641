import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import { MessageBoxToggle } from '@asteria/component-support/MessageBox';

import { TranslationService } from '@asteria/language';

const RemoveButton = React.memo((props) => {
	const { onSubmit, loading } = props;

	const toggle = React.useMemo(
		() => ({
			label: TranslationService.get([
				'financial.modal.loan.settings.remove.toggle.label',
			]),
			iconTooltip: TranslationService.get([
				'financial.modal.loan.settings.remove.toggle.tooltip',
			]),
			variant: 'link',
			icon: 'help',
			size: 'sm',
			iconPosition: 'last',
		}),
		[],
	);

	return (
		<MessageBoxToggle
			toggle={toggle}
			analyticsKey="financial.modal.loan.settings.remove.message-box"
			scrollIntoView
		>
			{({ onClose }) => [
				<Content key="content">
					<Text>
						{TranslationService.get([
							'financial.modal.loan.settings.remove.content',
						])}
					</Text>
				</Content>,
				<Footer key="footer">
					<FooterSection position="first">
						<Button
							variant="tertiary"
							size="sm"
							label={TranslationService.get([
								'action.abort',
								'action.cancel',
								'financial.modal.loan.settings.remove.abort',
								'financial.modal.loan.settings.remove.cancel',
							])}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							size="sm"
							label={TranslationService.get([
								'action.submit',
								'action.remove',
								'financial.modal.loan.settings.remove.submit',
								'financial.modal.loan.settings.remove.remove',
							])}
							onClick={onSubmit}
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>,
			]}
		</MessageBoxToggle>
	);
});

RemoveButton.displayName = 'RemoveButton';
RemoveButton.propTypes = { onSubmit: PropTypes.func, loading: PropTypes.bool };

export default RemoveButton;
