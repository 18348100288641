import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import FeedbackDialog from '@asteria/component-feedback/dialog';

const FeedbackLogic = (props) => {
	const { onClose, onAction } = props;

	const onFeedbackAction = useCallback(
		(action, data) => {
			if (data.dismissed) {
				onClose?.();
			}

			return onAction?.(action, data);
		},
		[onAction, onClose],
	);

	return <FeedbackDialog {...props} onAction={onFeedbackAction} />;
};

FeedbackLogic.propTypes = {
	onAction: PropTypes.func,
	onClose: PropTypes.func,
};

export default FeedbackLogic;
