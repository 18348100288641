import { AuthService, UserService } from '@asteria/backend-utils-services';

import { setUser } from '@asteria/datalayer/stores/app';

export const FIELDS = `
_id
id
username
firstName
lastName
settings {
	layout
	flags
	compressList
	listColumns
	hasReadAdjustable
}
companies {
	edges {
		node {
			_id
			name
		}
	}
}
feedback
roles
authRecord
`;

function formatUser(node) {
	return {
		...node,
		companies: (node?.companies?.edges ?? []).map(({ node }) => node),
	};
}

export async function me({ accessToken, dispatch, fields = FIELDS, raise }) {
	const response = await AuthService.auth
		.me({ isBulk: true, fields: fields }, { token: accessToken })
		.then(formatUser)
		.catch((err) => {
			if (raise) {
				throw err;
			}
		});

	//TODO: Clean this up in database ???
	if (response?.settings?.layout?.graph?.layout === 'flat') {
		response.settings.layout.graph.layout =
			response?.settings?.layout?.graph?.barLayout || 'grouped';
	}

	dispatch?.(setUser(response));

	return response;
}

export async function update({ accessToken, id, input }) {
	return UserService.user.update(
		{ id: id, input: input },
		{ token: accessToken },
	);
}
