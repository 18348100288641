import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import Spinner from '@asteria/component-core/spinner';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import Form, { Checkbox, Input } from '@asteria/component-form';
import Modal from '@asteria/component-modal';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { useActions } from './hooks';

import './styles.scss';

function formatFeatureKey(key, invert = false) {
	if (invert) {
		return key.replace(/\*\*/g, '.');
	}

	return key.replace(/\./g, '**');
}

const selectors = {
	features: createSelector(
		(store) => store?.features?.features ?? {},
		(value) =>
			Object.fromEntries(
				Object.keys(value).map((key) => [formatFeatureKey(key), true]),
			),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const TYPES = {
	FLAG: 'flag',
	SCENARIO: 'scenario',
	DATA: 'data',
	FORECAST_ACTIONS: 'forecast:actions',
};

function FeatureModalContent(props) {
	const { onClose, onSubmit } = props;

	const active = useSelector(selectors.features);

	const query = useQuery({
		queryKey: ['devtools', 'features'],
		queryFn: async () => {
			const response = await onSubmit?.('features:fetch');

			return Object.keys(response ?? {});
		},
		placeholderData: [],
	});

	const [search, setSearch] = React.useState('');

	const objects = (
		search
			? query.data.filter((key) =>
					key.toLowerCase().includes(search.toLowerCase()),
			  )
			: query.data
	).sort((a, b) => a.localeCompare(b));

	const handleSubmit = React.useCallback((form) => {
		const response = Object.entries(form).map(([key, value]) => ({
			key: formatFeatureKey(key, true),
			enabled: value,
		}));

		window.localStorage.setItem(
			'AsteriaFeatures',
			JSON.stringify(response),
		);

		window.location.reload();
	}, []);

	const handleReset = React.useCallback(() => {
		window.localStorage.removeItem('AsteriaFeatures');
		window.location.reload();
	}, []);

	return (
		<Form values={active} onSubmit={handleSubmit}>
			<Wrapper scroll>
				<Header onClose={onClose}>Feature flags</Header>
				<Content scroll>
					<Group>
						<Input
							name="search"
							uncontrolled
							icon="magnifier"
							value={search}
							onChange={({ value }) => setSearch(value)}
						/>
						{query.isFetching ? (
							<Spinner />
						) : (
							objects.map((key) => (
								<Checkbox
									key={key}
									name={formatFeatureKey(key)}
									label={key}
								/>
							))
						)}
					</Group>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label="Close"
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="secondary"
							label="Reset"
							onClick={handleReset}
						/>
						<Button variant="primary" label="Save" type="submit" />
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
}

FeatureModalContent.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

const FeatureModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal open={open} onClose={onClose}>
			<FeatureModalContent {...props} />
		</Modal>
	);
};

FeatureModal.displayName = 'FeatureModal';
FeatureModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

const DevToolsDropdown = (props) => {
	const { onAction, onSubmit } = props;

	const {
		onInvalidateQueries,

		onResetFlags,
		onResetUserFlags,
		onResetCompanyFlags,
		onResetFeedback,
		onResetScenario,
		onClearScenario,
		onRemoveDraftScenario,
		onDataClean,
		onDataFlagsClean,
		onDataRefresh,
		onDataFlagsRefresh,

		onForecastActionsRefresh,
		onForecastActionsRecreate,
		onForecastActionsClean,
	} = useActions({ onAction, onSubmit });

	const [isFeatureModalOpen, setFeatureModalOpen] = React.useState(false);

	const onFeatureModalOpen = React.useCallback(
		() => setFeatureModalOpen(true),
		[],
	);

	const onFeatureModalClose = React.useCallback(
		() => setFeatureModalOpen(false),
		[],
	);

	const ToggleProps = React.useMemo(() => ({ icon: 'cog' }), []);

	const [hasError, setError] = React.useState(false);

	const [active, setActive] = React.useState(TYPES.FLAG);

	const handleClick = React.useCallback((type) => {
		return (event) => {
			event.stopPropagation();

			setActive(type);
		};
	}, []);

	const onTriggerException = React.useCallback(() => {
		setError(true);
	}, []);

	if (hasError) {
		throw new Error('DevTools error');
	}

	return (
		<>
			<FeatureModal
				open={isFeatureModalOpen}
				onClose={onFeatureModalClose}
				onSubmit={onSubmit}
			/>
			<Dropdown
				className="asteria-component__devtools-button"
				toggle={ToggleProps}
			>
				{active === TYPES.FLAG
					? [
							<DropdownItem
								key="invalidate"
								onClick={onInvalidateQueries}
							>
								Invalidate queries
							</DropdownItem>,
							<DropdownItem key="flags" onClick={onResetFlags}>
								Reset all flags
							</DropdownItem>,
							<DropdownItem
								key="user-flags"
								onClick={onResetUserFlags}
							>
								Reset user flags
							</DropdownItem>,
							<DropdownItem
								key="company-flags"
								onClick={onResetCompanyFlags}
							>
								Reset company flags
							</DropdownItem>,
							<DropdownItem
								key="feedback"
								onClick={onResetFeedback}
							>
								Reset feedback
							</DropdownItem>,
							<DropdownItem
								key="exception"
								onClick={onTriggerException}
							>
								Trigger exception
							</DropdownItem>,
					  ]
					: null}
				{active === TYPES.SCENARIO
					? [
							<DropdownItem
								key="reset-scenario"
								onClick={onResetScenario}
							>
								Reset current scenario
							</DropdownItem>,
							<DropdownItem
								key="clear-scenario"
								onClick={onClearScenario}
							>
								Clear current scenario
							</DropdownItem>,
							<DropdownItem
								key="remove-draft"
								onClick={onRemoveDraftScenario}
							>
								Remove Draft scenario
							</DropdownItem>,
					  ]
					: null}
				{active === TYPES.DATA
					? [
							<DropdownItem
								key="clean-data"
								onClick={onDataClean}
							>
								Clean data
							</DropdownItem>,
							<DropdownItem
								key="clean-data-flags"
								onClick={onDataFlagsClean}
							>
								Clean data & Reset flags
							</DropdownItem>,
							<DropdownItem
								key="refresh-data"
								onClick={onDataRefresh}
							>
								Refresh data
							</DropdownItem>,
							<DropdownItem
								key="refresh-data-flags"
								onClick={onDataFlagsRefresh}
							>
								Refresh data & Reset flags
							</DropdownItem>,
					  ]
					: null}

				{active === TYPES.FORECAST_ACTIONS
					? [
							<DropdownItem
								key="refresh"
								onClick={onForecastActionsRefresh}
							>
								Refresh
							</DropdownItem>,
							<DropdownItem
								key="recreate"
								onClick={onForecastActionsRecreate}
							>
								Recreate
							</DropdownItem>,
							<DropdownItem
								key="clean"
								onClick={onForecastActionsClean}
							>
								Clean
							</DropdownItem>,
					  ]
					: null}

				<div className="asteria-component__devtools-button__chips">
					<Chip
						size="sm"
						label="Flags"
						active={active === TYPES.FLAG}
						onClick={handleClick(TYPES.FLAG)}
					/>
					<Chip
						size="sm"
						label="Scenario"
						active={active === TYPES.SCENARIO}
						onClick={handleClick(TYPES.SCENARIO)}
					/>
					<Chip
						size="sm"
						label="Data"
						active={active === TYPES.DATA}
						onClick={handleClick(TYPES.DATA)}
					/>
					<Chip
						size="sm"
						label="Features"
						onClick={(event) => {
							event.stopPropagation();

							return onFeatureModalOpen(event);
						}}
					/>
					<Chip
						size="sm"
						label="Forecast actions"
						active={active === TYPES.FORECAST_ACTIONS}
						onClick={handleClick(TYPES.FORECAST_ACTIONS)}
					/>
				</div>
			</Dropdown>
		</>
	);
};

DevToolsDropdown.displayName = 'DevToolsDropdown';
DevToolsDropdown.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const DevToolsButton = (props) => {
	const { onAction, onSubmit } = props;

	return (
		<FeatureFlag feature="asteria-devtools">
			<DevToolsDropdown onAction={onAction} onSubmit={onSubmit} />
		</FeatureFlag>
	);
};

DevToolsButton.displayName = 'DevToolsButton';
DevToolsButton.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default DevToolsButton;
