import React from 'react';

import { useStore } from 'react-redux';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { useSubscription } from '@asteria/utils-websocket/hooks';

const Updater = React.memo(({ onSubmit, accessToken }) => {
	const store = useStore();

	const onCashflowUpdate = React.useMemo(
		() =>
			AsteriaCore.utils.throttle(() => {
				const dates = (store.getState()?.graph?.data?.range ?? []).map(
					({ id }) => id,
				);

				for (const date of dates) {
					onSubmit?.('cashflow', { startDate: date });
				}

				onSubmit?.('cashflow:statistics');
			}, 2_000),
		[onSubmit, store],
	);

	const onNext = React.useCallback(
		(...args) => Promise.all([onCashflowUpdate(args)]),
		[onCashflowUpdate],
	);

	useSubscription({
		token: accessToken,
		query: `
			subscription TimesliceUpdated {
				transactionUpdated {
					_id
					paymentDate
				}
			}
		`,
		onNext: onNext,
	});

	useSubscription({
		token: accessToken,
		query: `
			subscription TimesliceCreated {
				transactionCreated {
					_id
					paymentDate
				}
			}
		`,
		onNext: onNext,
	});

	useSubscription({
		token: accessToken,
		query: `
			subscription TimesliceDeleted {
				transactionDeleted
			}
		`,
		onNext: onNext,
	});

	return null;
});

Updater.displayName = 'Updater';
Updater.propTypes = { onSubmit: PropTypes.func, accessToken: PropTypes.string };

export default Updater;
