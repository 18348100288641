import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';

import Chip from '@asteria/component-chip/chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const DropdownNavigationItem = React.memo((props) => {
	const {
		id,
		label,
		base,
		active,
		onAction,
		props: $props,
		editable,
		visible,
		useVisible = () => true,
		object,
	} = props;

	const key = [...base, id].join('-');

	const isActive = (active ?? []).join('-') === key;

	const defaultVisible = useVisible(object);
	const isVisible =
		isActive ||
		(visible?.[key] !== undefined ? visible?.[key] : defaultVisible);

	const handleSelect = React.useCallback(() => {
		if (isVisible) {
			return onAction?.('navigation:deselect', [...base, id], {
				toggle: true,
			});
		}

		return onAction?.('navigation:select', [...base, id]);
	}, [isVisible, onAction, base, id]);

	const handleEdit = React.useCallback(
		() => onAction?.('navigation:update', [...base, id]),
		[base, id, onAction],
	);

	const postfix = React.useMemo(() => {
		return (
			<Group verticalAlign="center" horizontalAlign="center">
				{editable ? (
					<Button variant="link" icon="edit" onClick={handleEdit} />
				) : null}
				<Icon icon="check" size="sm" />
			</Group>
		);
	}, [editable, handleEdit]);

	return (
		<DropdownItem
			className={cn(
				'asteria-component__level-navigation-selector-item',
				{ 'asteria--state-visible': isVisible },
				{ 'asteria--state-editable': editable },
				{
					[`asteria--type-tag-${object?.tag?.replace('$', '')}`]:
						object?.tag,
				},
			)}
			onClick={handleSelect}
			active={isVisible}
			postfix={postfix}
		>
			<Chip
				className="asteria--state-clickable"
				variant="simple"
				colors={$props?.colors}
				label={TranslationService.get([
					label,
					['level.navigation', ...base, id, 'label'].join('.'),
					['level.navigation', ...base, id, 'dropdown'].join('.'),
				])}
			/>
		</DropdownItem>
	);
});

DropdownNavigationItem.displayName = 'DropdownNavigationItem';

DropdownNavigationItem.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	base: PropTypes.arrayOf(PropTypes.string),
	props: PropTypes.object,
	object: PropTypes.object,
	editable: PropTypes.bool,
	visible: PropTypes.object,
	active: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,
	useVisible: PropTypes.func,
};

export default DropdownNavigationItem;
