import { TransactionService } from '@asteria/backend-utils-services';

import {
	formatRequest,
	formatState,
} from '@asteria/component-forecaster/utils';

import store from '@asteria/datalayer';
import { setUserSettingsFlags } from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import Analytics from '@asteria/utils-analytics';

import * as ForecastAPI from '../api/forecast';
import * as ScenarioAPI from '../api/scenario';
import * as UserAPI from '../api/user';

import { onAction as onScenarioAction } from './scenarios';

/**
 * @typedef { 'forecaster:save' | 'forecaster:abort' } EventType
 * @typedef { import('@tanstack/react-query').QueryClient } QueryClient
 */

/**
 * @param { { dispatch: unknown, accessToken: string, sourceScenario?: string, event: { type?: EventType, data?: unknown } } } options
 * @param {{ queryClient: QueryClient }} context
 */
export const onAction = async (options, context) => {
	const { event, dispatch, sourceScenario, accessToken } = options;

	let shouldUpdateSettings = false;

	if (event?.type === 'forecaster:reset') {
		await onScenarioAction?.({
			event: { type: 'scenario:current:clear' },
			dispatch: dispatch,
			accessToken: accessToken,
		});

		await context?.queryClient?.invalidateQueries?.({
			predicate: (query) =>
				['card', 'streamline', 'forecaster'].includes(
					query.queryKey[0],
				),
		});
	}

	if (event?.type === 'forecaster:abort') {
		Analytics.event('forecaster.abort', {
			sourceScenario: sourceScenario,
		});

		dispatch(ModalStore.close());

		await ScenarioAPI.fetch({ accessToken: accessToken }).then((data) =>
			dispatch(ScenarioStore.setScenarios(data)),
		);
	}

	if (event?.type === 'forecaster:save') {
		const noRedirect = event?.data?.noRedirect;
		const showSnackBar = event?.data?.showSnackBar;

		dispatch(ForecasterStore.startSaving());

		if (!noRedirect) {
			dispatch(ModalStore.close());
		}

		const targetScenario = event?.data?.scenario?.name;
		const input = formatRequest(event?.data?.data ?? {});

		Analytics.event('forecaster.save', {
			targetScenario: targetScenario,
			data: event?.data?.data ?? {},
			input: input,
		});

		await ForecastAPI.apply({
			input: input.map((value) => ({
				...value,
				targetScenarioName: targetScenario,
			})),
			accessToken: accessToken,
		}).catch(() => null);

		const scenarios = await ScenarioAPI.fetch({
			accessToken: accessToken,
		})
			.catch(() => [])
			.then((data) =>
				data.reduce(
					(acc, object) => ({ ...acc, [object?.name]: object }),
					{},
				),
			);

		let scenarioId = event?.data?.scenario?._id;

		if (event?.data?.scenario?.name === '$draft') {
			scenarioId = scenarios['$draft']?._id;
		}

		if (event?.data?.scenario?.name === '$custom') {
			scenarioId = scenarios['$custom']?._id;
		}

		// Update scenario state

		if (scenarioId) {
			const state = store.getState();
			const currentState = formatState(
				event?.data?.data ?? {},
				state?.app?.tags ?? [],
			);

			await TransactionService.scenario.update(
				{
					id: scenarioId,
					input: { state: currentState },
					fields: ` ok `,
				},
				{ token: accessToken },
			);
		}

		ScenarioAPI.fetch({ accessToken: accessToken }).then((data) =>
			dispatch(ScenarioStore.setScenarios(data)),
		);

		if (
			event?.data?.scenario?._id ||
			event?.data?.scenario?.name === '$custom'
		) {
			dispatch(
				setUserSettingsFlags({
					scenarioId: scenarioId,
				}),
			);

			shouldUpdateSettings = true;
		}

		if (showSnackBar) {
			SnackbarStore.show(dispatch, {
				title: 'forecaster.scenario.complete.success.title',
				content: 'forecaster.scenario.complete.success.content',
				variant: 'success',
				icon: 'check',
				hideActions: true,
			});
		}

		dispatch(ForecasterStore.stopSaving());

		if (!noRedirect) {
			dispatch(ForecasterStore.reset());
		}

		await context?.queryClient?.invalidateQueries?.({
			predicate: (query) =>
				['card', 'streamline'].includes(query.queryKey[0]),
		});
	}

	if (shouldUpdateSettings) {
		UserAPI.refreshSettings({
			accessToken: accessToken,
			dispatch: dispatch,
		});
	}
};
