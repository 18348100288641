import React from 'react';

import { cn } from '@asteria/utils-funcs/classes';

import './background.scss';

const Background = React.memo(({ className }) => (
	<div className={cn('asteria-graph-group-background', className)} />
));

Background.displayName = 'Background';

export default Background;
