import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { stateClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Option = (props) => {
	const {
		value,
		children,
		onClick,
		className,
		hidden,
		postfix,
		...itemProps
	} = props;

	const { active, size } = itemProps;

	const handleClick = useCallback(() => onClick?.(value), [value, onClick]);

	const analytics = React.useMemo(() => ({ value: value }), [value]);

	if (hidden) {
		return null;
	}

	return (
		<DropdownItem
			onClick={handleClick}
			className={cn(
				className,
				'asteria-component__select__option',
				stateClasses(props),
			)}
			postfix={
				postfix ? (
					typeof postfix === 'function' ? (
						postfix({ active, value, size })
					) : (
						postfix
					)
				) : active ? (
					<Group verticalAlign="center" horizontalAlign="center">
						<Button size={size} icon="check" />
					</Group>
				) : null
			}
			{...itemProps}
			analyticsKey="form.select.item"
			analytics={analytics}
		>
			{children}
		</DropdownItem>
	);
};

Option.propTypes = {
	hidden: PropTypes.bool,
	value: PropTypes.any,
	children: PropTypes.node,
	onClick: PropTypes.func,
	className: PropTypes.string,
	postfix: PropTypes.node,
};

export default Option;
