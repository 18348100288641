import formatNumber from './formatNumber';
import roundUp from './roundUp';

export const getLabels = ({ max, min, steps, scale, prefix = '±' } = {}) => {
	const rangeMin = roundUp(Math.min(0, min + min * 0.1));
	const rangeMax = roundUp(Math.max(10_000, max + max * 0.1));

	const range = Math.abs(rangeMax - rangeMin);
	const interval = roundUp(range / steps);

	return Array.from({ length: steps + 1 }).map((_, index) => {
		const next = rangeMin + interval * index;

		return {
			value: next,
			label: [
				prefix,
				formatNumber(next, false, 1000 ** scale, !!prefix, {
					thousand: false,
				}),
			]
				.filter(Boolean)
				.join(''),
		};
	});
};
