import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';
import Contenter from '@asteria/component-tools/contenter';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const ActionDialog = React.memo(function ActionDialog(props) {
	const { onClose, onAction, onSubmit, type, data } = props;

	const close = React.useCallback((event) => onClose?.(event), [onClose]);

	const trigger = React.useCallback(
		(event) => {
			close(event);

			if (data?.action) {
				if (data?.action?.action !== 'close') {
					onAction?.(data?.action?.action, data?.action?.data);
				}
			}
		},
		[close, data?.action, onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction, onSubmit, onClose }),
		[onAction, onClose, onSubmit],
	);

	const HeaderPostfix = React.useMemo(
		() => ({ position: 'absolute', size: 'xs' }),
		[],
	);

	return (
		<Dialog
			className={cn('asteria-component__action-dialog', data?.className)}
			onClose={close}
			placement={data?.placement ?? 'bottom'}
		>
			{data?.content ? (
				<Contenter content={data?.content} actions={actions} flat />
			) : (
				<Wrapper scroll>
					<Header onClose={close} postfix={HeaderPostfix}>
						<Translation
							translationKey={[
								type,
								`${type}.title`,
								'action.dialog.title',
								`action.dialog.${type}.title`,
							]}
							defaultText={type}
							data={{ data: data }}
							Component={Title}
							size="sm"
						/>
					</Header>
					<Content>
						<Translation
							translationKey={[
								`${type}.text`,
								'action.dialog.content.text',
								`action.dialog.${type}.content.text`,
							]}
							data={{ data: data }}
							Component={Text}
						/>

						<Button
							variant="primary"
							label={TranslationService.get([
								`${type}.button`,
								`action.dialog.action`,
								`action.dialog.${type}.action`,
							])}
							onClick={trigger}
						/>
					</Content>
				</Wrapper>
			)}
		</Dialog>
	);
});

ActionDialog.displayName = 'ActionDialog';

ActionDialog.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,

	type: PropTypes.string,

	data: PropTypes.shape({
		className: PropTypes.string,
		placement: PropTypes.string,
		content: PropTypes.arrayOf(PropTypes.object),

		action: PropTypes.shape({
			action: PropTypes.string,
			data: PropTypes.any,
		}),
	}),
};

export default ActionDialog;
