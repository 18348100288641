import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';

import Done from './Done';
import Empty from './Empty';
import Loading from './Loading';

import './styles.scss';

const VARIANTS = {
	empty: Empty,
	loading: Loading,
	done: Done,
};

function useLoading() {
	const [forceLoading, setForceLoading] = useState(false);

	const hasDelayDone = useFeature('welcome-delay-done');

	const { importing, hasERP, hasBank } = useSelector(
		(store) => {
			const importing = IntegrationStore.selectors.isLoading(store);

			const hasERP = !!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				connected: true,
			}).length;

			const hasBank = !!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				connected: true,
			}).length;

			return { importing, hasERP, hasBank };
		},
		(a, b) => isEqual(a, b),
	);

	useEffect(() => {
		if (!hasDelayDone) {
			return;
		}

		if (hasERP !== hasBank) {
			setForceLoading(true);
		}

		setTimeout(() => {
			setForceLoading(false);
		}, 10000);
	}, [hasBank, hasDelayDone, hasERP]);

	return React.useMemo(
		() => forceLoading || importing,
		[forceLoading, importing],
	);
}

const Placeholder = (props) => {
	const { className, onAction } = props;

	const loading = useLoading();

	const variant = useSelector((store) => {
		const exists = !!IntegrationStore.selectors.integrations(store, {
			connected: true,
		}).length;

		if (loading) {
			return 'loading';
		}

		if (exists) {
			return 'done';
		}

		return 'empty';
	});

	const Component = VARIANTS[variant] ?? Empty;

	return (
		<Component
			className={cn(
				'asteria-view__welcome__placeholder',
				{
					[`asteria-view__welcome__placeholder--variant-${variant}`]:
						variant,
				},
				className,
			)}
			onAction={onAction}
		/>
	);
};

Placeholder.displayName = 'Placeholder';

Placeholder.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default Placeholder;
