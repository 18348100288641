import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'>> } */
const Accuracy = React.memo(function Accuracy({ translationOptions }) {
	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="card.content.extra.tooltip"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'value-type': 'accuracy',
						},
					}}
					Component={Text}
				/>
			}
		>
			<Translation
				translationKey="card.content.extra.value"
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'value-type': 'accuracy',
					},
				}}
				Component={Text}
				size="sm"
			/>
		</TooltipWrapper>
	);
});

Accuracy.propTypes = {
	translationOptions: PropTypes.object,
};

export default Accuracy;
