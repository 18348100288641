import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { CreditConfirmationModal } from '..';
import {
	useModalActions,
	useModalProps,
} from '../../components/modal-collection';

const CreditLeave = React.memo(() => {
	const { close, open, onAction } = useModalActions();
	const { credit, simulationId, next } = useModalProps();

	const handleDiscard = React.useCallback(
		(event) => {
			onAction?.('redirect', {
				path: next ?? '/financial',
				resetCreditValue: true,
			});

			return close?.(event);
		},
		[next, onAction, close],
	);

	const handleSave = React.useCallback(() => {
		open(<CreditConfirmationModal />, {
			credit: credit,
			simulationId: simulationId,
			next: next,
		});
	}, [credit, next, open, simulationId]);

	return (
		<Wrapper>
			<Header onClose={close}>
				{TranslationService.get(['financial.modal.credit.leave.title'])}
			</Header>
			<Content>
				<Translation
					Component={Text}
					translationKey="financial.modal.credit.leave.content"
					size="sm"
				/>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'financial.modal.credit.leave.close',
						])}
						onClick={close}
						analyticsKey="financial.modal.credit.leave.close"
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="secondary"
						label={TranslationService.get([
							'button.discard',
							'action.discard',
							'financial.modal.credit.leave.discard',
						])}
						onClick={handleDiscard}
						analyticsKey="financial.modal.credit.leave.discard"
					/>
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.continue',
							'action.continue',
							'financial.modal.credit.leave.continue',
						])}
						analyticsKey="financial.modal.credit.leave.continue"
						onClick={handleSave}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

CreditLeave.displayName = 'CreditLeave';
CreditLeave.propTypes = {};

const CreditLeaveModal = React.memo((props) => {
	const { open, className } = props;

	const { close } = useModalActions();

	return (
		<Modal
			open={open}
			onClose={close}
			className={cn(
				'asteria-component__financial-modal',
				'asteria--variant-credit-leave',
				className,
			)}
			size="sm"
		>
			<CreditLeave {...props} />
		</Modal>
	);
});

CreditLeaveModal.displayName = 'CreditLeaveModal';

CreditLeaveModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
};

export default CreditLeaveModal;
