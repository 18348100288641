/* eslint-disable spellcheck/spell-checker */
// import React from 'react';
// import { useSelector } from 'react-redux';
// import { createSelector } from 'reselect';
// import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

// import ActionBar from '@asteria/component-actionbar/transactions';

// const selectors = {
// 	selectedTransactions: createSelector(
// 		(state) =>
// 			Object.entries(state?.transactions?.states || {})
// 				.filter(([, { selected }]) => selected)
// 				.map(([id]) => state?.transactions?.items?.[id]),
// 		(value) => value.filter((item) => item),
// 		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
// 	),
// };

const ActionbarLogic = () => {
	return null;

	// const { onAction } = props;

	// const selectedTransactions = useSelector(selectors.selectedTransactions);

	// return <ActionBar selected={selectedTransactions} onAction={onAction} />;
};

ActionbarLogic.propTypes = {
	onAction: PropTypes.func,
};

export default ActionbarLogic;
