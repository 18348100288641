import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { TooltipWrapper } from '../tooltip';

const TableCell = React.memo(
	React.forwardRef((props, ref) => {
		const { className, children, tooltip, ...rest } = props;

		return (
			<TooltipWrapper
				{...(!tooltip || React.isValidElement(tooltip)
					? { tooltip: tooltip }
					: tooltip)}
			>
				<div
					className={cn('asteria-component__table-cell', className)}
					ref={ref}
					{...rest}
				>
					{children}
				</div>
			</TooltipWrapper>
		);
	}),
);

TableCell.displayName = 'TableCell';

TableCell.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	tooltip: PropTypes.string,
};

export default TableCell;
