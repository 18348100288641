import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Prefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Dropdown, { DropdownItem } from '../dropdown';
import Icon from '../icon';

import './index.scss';

const ColorPickerItem = (props) => {
	const { onSelect, color, active } = props;

	const handleClick = useCallback(() => onSelect?.(color), [color, onSelect]);

	const analytics = React.useMemo(() => ({ value: color }), [color]);

	return (
		<DropdownItem
			className="asteria-component__color-picker__item"
			onClick={handleClick}
			analyticsKey="colorPicker.item"
			analytics={analytics}
		>
			{active === color ? (
				<Icon
					size="sm"
					icon="check"
					className="asteria-component__color-picker__item--active"
				/>
			) : null}
			<Prefix
				colors={color}
				size="lg"
				className={cn('asteria-component__color-picker__prefix', {
					'--color__brand-secondary': true,
				})}
			/>
		</DropdownItem>
	);
};

ColorPickerItem.propTypes = {
	onSelect: PropTypes.func,
	color: PropTypes.string,
	active: PropTypes.string,
};

const ColorPicker = (props) => {
	const {
		selectedColor,
		colors = ['#FFF'],
		onSelect,
		className,
		tooltip,
	} = props;

	const [color, setColor] = useState('#FFF');

	useEffect(() => {
		if (selectedColor) {
			setColor(selectedColor);
		} else {
			setColor(colors?.[0]);
		}
	}, [selectedColor]);

	const handleClick = useCallback(
		(color) => {
			setColor(color);
			onSelect(color);
		},
		[onSelect],
	);

	const toggle = useMemo(
		() => ({
			children: (
				<Prefix
					colors={color}
					className="asteria-component__color-picker__prefix"
				/>
			),
			icon: 'triangle-down',
			iconActive: 'triangle-up',
			iconPosition: 'last',
			tooltip: tooltip,
		}),
		[color, tooltip],
	);

	return (
		<Dropdown
			placement="bottom-end"
			direction="row"
			toggle={toggle}
			className={cn('asteria-component__color-picker', className)}
			title={TranslationService.get([
				'color.picker.title',
				'color.picker.dropdown.title',
			])}
			analyticsKey="colorPicker"
			scroll
		>
			{colors.map((color, index) => (
				<ColorPickerItem
					key={`tag-color-${index}`}
					onSelect={handleClick}
					active={selectedColor}
					color={color}
				/>
			))}
		</Dropdown>
	);
};

ColorPicker.propTypes = {
	selectedColor: PropTypes.string,
	className: PropTypes.string,
	colors: PropTypes.array,
	onSelect: PropTypes.func,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ColorPicker;
