import { FeatureService } from '@asteria/backend-utils-services';

import { setFeatures } from '@asteria/datalayer/stores/features';

/**
 * @param { { partnerId: string, dispatch?: unknown } } options
 */
export default async (options = {}) => {
	const { partnerId, dispatch } = options;

	const response = await FeatureService.feature
		.fetchByPartnerId({ id: partnerId })
		.catch(() => ({}));

	dispatch?.(setFeatures(response));

	return response;
};
