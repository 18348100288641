import { useContext, useMemo } from 'react';

import LoanContext from '../../../context/loan';

export function useGraphData() {
	const { dates } = useContext(LoanContext);

	return useMemo(
		() => dates.slice(1, -1).map((date) => ({ date: date })),
		[dates],
	);
}
