import React from 'react';

import { useSelector } from 'react-redux';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';
import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import * as selectors from './selectors';

/**
 * @param { { onFetch: function, state: string } } options
 * @returns { number }
 */
export function useModifiedCount(options) {
	const { onFetch } = options;

	const timestamp = useSelector(TransactionStore.selectors.timestamp);
	const scenarioId = useSelector(ScenarioStore.selectors.scenarioId);

	const [count, setCount] = React.useState(0);

	React.useEffect(() => {
		async function fetchCount() {
			if (timestamp) {
				const count = await onFetch?.('transactions:count', {
					timestamp: timestamp,
					scenarioId: scenarioId,
				});

				setCount(count);
			}
		}

		fetchCount();
	}, [onFetch, scenarioId, timestamp]);

	return count;
}

/**
 * @param { { onFetch: function, state: string } } options
 * @returns { object[] }
 */
export function useNotifications(options) {
	const { onFetch } = options;

	const timestamp = useSelector(TransactionStore.selectors.timestamp);
	const scenarioId = useSelector(ScenarioStore.selectors.scenarioId);

	const [notifications, setNotifications] = React.useState([]);

	React.useEffect(() => {
		async function fetchCount() {
			const notifications = await onFetch?.(
				'transactions:notifications',
				{
					timestamp: timestamp,
					scenarioId: scenarioId,
				},
			);

			setNotifications(notifications);
		}

		fetchCount();
	}, [onFetch, scenarioId, timestamp]);

	return notifications;
}

export function useIsValid(id) {
	return useSelector((store) => selectors.isValid(store, id));
}
