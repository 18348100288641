import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as GraphStore from '@asteria/datalayer/stores/graph';

export function useStateHidden({ layout }) {
	const hasBelowZeroFeature = useFeature(`graph-below-zero`);
	const hasBelowZeroLegendFeature = useFeature(`graph-legend-below-zero`);

	const showLegendBelowZero =
		hasBelowZeroFeature && hasBelowZeroLegendFeature;

	const selected = useSelector(
		(store) =>
			GraphStore.selectors.legends.selected(
				store,
				layout !== 'stacked' ? { part: 'account' } : null,
			),
		(a, b) => isEqual(a, b),
	);

	const exists = !!selected.length;

	const spread = {
		positive: selected.some((object) => object?.id === 'spread'),
		negative: selected.some((object) => object?.id === 'spread-negative'),
	};

	const line = {
		history: {
			positive: selected.some((object) => object?.id === 'account-paid'),
			negative: selected.some(
				(object) => object?.id === 'account-paid-negative',
			),
		},
		future: {
			positive: selected.some(
				(object) => object?.id === 'account-forecast',
			),
			negative: selected.some(
				(object) => object?.id === 'account-forecast-negative',
			),
		},
	};

	if (!showLegendBelowZero) {
		spread.negative = spread.positive;
		line.history.negative = line.history.positive;
		line.future.negative = line.future.positive;
	}

	const credit = selected.some((object) => object?.id === 'credit');

	return useMemo(() => {
		if (!exists) {
			return;
		}

		return {
			spread: {
				positive: !spread?.positive,
				negative: !spread?.negative,
			},
			credit: !credit,
			line: {
				history: {
					positive: !line?.history?.positive,
					negative: !line?.history?.negative,
				},
				future: {
					positive: !line?.future?.positive,
					negative: !line?.future?.negative,
				},
			},
		};
	}, [
		credit,
		exists,
		line?.future?.negative,
		line?.future?.positive,
		line?.history?.negative,
		line?.history?.positive,
		spread?.negative,
		spread?.positive,
	]);
}

export function useStateInvisible({ layout }) {
	const hasBelowZeroFeature = useFeature(`graph-below-zero`);
	const hasBelowZeroLegendFeature = useFeature(`graph-legend-below-zero`);

	const showLegendBelowZero =
		hasBelowZeroFeature && hasBelowZeroLegendFeature;

	const highlight = useSelector(
		GraphStore.selectors.legends.highlight,
		(a, b) => isEqual(a, b),
	);

	const exists = !!highlight;
	const valid = highlight?.part === 'account';

	const spread = {
		positive: highlight?.id === 'spread',
		negative: highlight?.id === 'spread-negative',
	};

	const line = {
		history: {
			positive: highlight?.id === 'account-paid',
			negative: highlight?.id === 'account-paid-negative',
		},
		future: {
			positive: highlight?.id === 'account-forecast',
			negative: highlight?.id === 'account-forecast-negative',
		},
	};

	if (!showLegendBelowZero) {
		spread.negative = spread.positive;
		line.history.negative = line.history.positive;
		line.future.negative = line.future.positive;
	}

	const credit = highlight?.id === 'credit';

	return useMemo(() => {
		if (!exists) {
			return;
		}

		if (!valid) {
			return layout !== 'stacked';
		}

		return {
			spread: {
				positive: !spread?.positive,
				negative: !spread?.negative,
			},
			credit: !credit,
			line: {
				history: {
					positive: !line?.history?.positive,
					negative: !line?.history?.negative,
				},
				future: {
					positive: !line?.future?.positive,
					negative: !line?.future?.negative,
				},
			},
		};
	}, [
		credit,
		exists,
		layout,
		line?.future?.negative,
		line?.future?.positive,
		line?.history?.negative,
		line?.history?.positive,
		spread?.negative,
		spread?.positive,
		valid,
	]);
}
