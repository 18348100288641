import React from 'react';

import { useQueries } from '@tanstack/react-query';
import { addMonths, formatISO, startOfMonth, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';
import { useRequestLoader } from '@asteria/utils-hooks/useDataLoader';

import Input from '../input';
import Month from '../month';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Wrapper = React.memo(function Wrapper(props) {
	const {
		className,

		date: initialDate = new Date().toISOString(),

		onAction,
		onSubmit,
	} = props;

	const [date, setDate] = React.useState(initialDate);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:select') {
				setDate(data?.date);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const dataloader = useRequestLoader({ onSubmit });

	const previousMonth = formatISO(
		subMonths(startOfMonth(parseDate(date?.visible ?? date)), 1),
		{ representation: 'date' },
	);

	const nextMonth = formatISO(
		addMonths(startOfMonth(parseDate(date?.visible ?? date)), 1),
		{ representation: 'date' },
	);

	const [pastMonthQuery, nextMonthQuery] = useQueries({
		queries: [
			{
				queryKey: ['time-selector', previousMonth, 'statistics'],
				queryFn: async ({ signal, meta }) => {
					return onSubmit?.('time:selector:statistics', {
						date: previousMonth,
						signal,
						dataloader: meta?.dataloader,
					});
				},

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				meta: { dataloader },
			},
			{
				queryKey: ['time-selector', nextMonth, 'statistics'],
				queryFn: async ({ signal, meta }) => {
					return onSubmit?.('time:selector:statistics', {
						date: nextMonth,
						signal,
						dataloader: meta?.dataloader,
					});
				},

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				meta: { dataloader },
			},
		],
	});

	const back = React.useCallback(() => {
		function change(date) {
			return formatISO(subMonths(parseDate(date), 1), {
				representation: 'date',
			});
		}

		let next = date;

		if (typeof date === 'string') {
			next = change(date);
		}

		if (date?.visible) {
			next = Object.fromEntries(
				Object.entries(date).map(([key, value]) => [
					key,
					value ? change(value) : value,
				]),
			);
		}

		handleAction('time:selector:select', { date: next });
	}, [date, handleAction]);

	const next = React.useCallback(() => {
		function change(date) {
			return formatISO(addMonths(parseDate(date), 1), {
				representation: 'date',
			});
		}

		let next = date;

		if (typeof date === 'string') {
			next = change(date);
		}

		if (date?.visible) {
			next = Object.fromEntries(
				Object.entries(date).map(([key, value]) => [
					key,
					value ? change(value) : value,
				]),
			);
		}

		handleAction('time:selector:select', { date: next });
	}, [date, handleAction]);

	return (
		<div
			className={cn(
				'flex items-center gap-4',
				'asteria-component__time-selector',
				className,
			)}
		>
			<Button icon="chevron-left" onClick={back} />
			<Month
				className="aspect-square h-full"
				flat
				date={previousMonth}
				onAction={handleAction}
				balance={pastMonthQuery?.data?.balance}
				health={pastMonthQuery?.data?.health}
				trends={pastMonthQuery?.data?.trends}
				loading={
					pastMonthQuery?.isFetching && !pastMonthQuery?.isRefetching
				}
			/>
			<Input
				className="flex-1"
				date={date}
				onAction={handleAction}
				onSubmit={onSubmit}
			/>
			<Month
				className="aspect-square h-full"
				flat
				date={nextMonth}
				onAction={handleAction}
				balance={nextMonthQuery?.data?.balance}
				health={nextMonthQuery?.data?.health}
				trends={nextMonthQuery?.data?.trends}
				loading={
					nextMonthQuery?.isFetching && !nextMonthQuery?.isRefetching
				}
			/>
			<Button icon="chevron-right" onClick={next} />
		</div>
	);
});

Wrapper.displayName = 'Wrapper';

Wrapper.propTypes = {
	className: PropTypes.string,
	date: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Wrapper;
