import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { Text } from '@asteria/component-core/typography';

import List from '@asteria/component-list';

import { TranslationService } from '@asteria/language';

import AccountsGroup from './group';
import * as selectors from './selectors';

const AccountsContent = React.memo(function AccountsContent() {
	const currencies = useSelector(selectors.currencies, (a, b) =>
		isEqual(a, b),
	);

	if (!currencies.length) {
		return (
			<Text size="sm">
				{TranslationService.get(['accounts.list.title.empty'])}
			</Text>
		);
	}

	return (
		<List size="lg" applyTypographySizes={false}>
			{currencies.map((currency) => (
				<AccountsGroup key={currency} currency={currency} />
			))}
		</List>
	);
});

export default AccountsContent;
