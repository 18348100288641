import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import GraphContext from '../../../../../logic/graphContext';
import { useLineType, useStateHidden, useStateInvisible } from '../../hooks';

import View from './view';

const Provider = React.memo((props) => {
	const { line, min, max, height = 100 } = props;

	const { TooltipComponent } = useContext(GraphContext);
	const { date } = line;

	const lineType = useLineType(date);

	const hiddenState = useStateHidden({ date: date });
	const invisibleState = useStateInvisible({ date: date });

	const invisible =
		lineType === 'current'
			? invisibleState?.line?.history && invisibleState?.line?.future
			: invisibleState?.line?.[lineType];

	const hidden =
		(lineType === 'current'
			? hiddenState?.line?.history && hiddenState?.line?.future
			: hiddenState?.line?.[lineType]) &&
		(invisible ?? true);

	return (
		<View
			line={line}
			min={min}
			max={max}
			height={height}
			tooltip={<TooltipComponent type="line" date={line?.date} />}
			hidden={hidden}
			invisible={invisible}
		/>
	);
});

Provider.displayName = 'DotProvider';
Provider.propTypes = {
	line: PropTypes.shape({ date: PropTypes.string, value: PropTypes.number }),
	min: PropTypes.number,
	max: PropTypes.number,
	hoverable: PropTypes.bool,
	showMonth: PropTypes.bool,

	height: PropTypes.number,
};

export default Provider;
