import React from 'react';
import { findDOMNode } from 'react-dom';

import { connect, useSelector } from 'react-redux';

import {
	addMonths,
	addWeeks,
	addYears,
	format,
	isFuture,
	isPast,
	isThisISOWeek,
	isThisMonth,
	isThisYear,
	isValid as isValidDate,
	parseISO,
	startOfISOWeek,
	startOfMonth,
	startOfYear,
} from 'date-fns';
import { isEqual } from 'lodash-es';
import { withTheme } from 'styled-components';

import AsteriaCore from '@asteria/core';

import ProbabilityBar from '@asteria/component-core/probability';
import { Text } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';
import {
	FeatureFlag,
	Service as FeatureService,
} from '@asteria/component-tools/featureflag';

import store from '@asteria/datalayer';
import {
	setAdjustOpen,
	setCurrentDate,
	setListOpen,
	setSelectedDate,
	setSelectedType,
	setTimesliceSize,
} from '@asteria/datalayer/stores/app';
import {
	setActive,
	setActiveBars,
	setActiveGroups,
	setHoverBars,
	setHoverGroups,
	setRange,
} from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';

import GraphLayout from '../layouts';
import * as Selectors from '../selectors';

import './graph.scss';

const getKeyPermutations = (key = '', delimiter = '.', initial = []) =>
	key
		.toString()
		.split('.')
		.reduce(
			(acc, item) => [
				...acc,
				acc.length > 0
					? [acc[acc.length - 1], item.toLowerCase()].join(delimiter)
					: item,
			],
			initial,
		);

// const mergeCssVars = (vars, suffix) => {
// 	const [color, ...rest] = vars;
// 	if (rest.length !== 0) {
// 		return `var(--${color}-${suffix}, ${mergeCssVars(rest, suffix)})`;
// 	}

// 	if (color.startsWith('system-')) {
// 		return `var(--${color}-${suffix})`;
// 	}

// 	return color;
// };

// const processColor = (color = '', types = [], theme, suffix = 'color') => {
// 	let fallback = 'rgba(0, 0, 0, 0)';
// 	if (types.includes('unpaid') || color.includes('.unpaid')) {
// 		fallback = `system-unpaid`;
// 	}

// 	if (types.includes('signed') || color.includes('.signed')) {
// 		fallback = `system-signed`;
// 	}

// 	if (types.includes('forecast') || color.includes('.forecast')) {
// 		fallback = `system-forecast`;
// 	}

// 	if (types.includes('overdue') || color.includes('.overdue')) {
// 		fallback = `system-overdue`;
// 	}

// 	if (color.startsWith('$')) {
// 		const colors = mergeCssVars(
// 			[
// 				...getKeyPermutations(color.replace('$', ''), '-', [
// 					'system',
// 				]).reverse(),
// 				fallback,
// 			],
// 			suffix,
// 		);

// 		return colors;
// 	}

// 	return color;
// };

// eslint-disable-next-line react/no-find-dom-node
const getElement = (el) => (React.isValidElement(el) ? el : findDOMNode(el));

function getSize(el) {
	if (!el) {
		return {
			width: 0,
			height: 0,
		};
	}

	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
	};
}

let processInfo = () => {};

const buildTooltipRow = (rowData, context = {}, As = 'li') => {
	const probabilityContext = {};
	const {
		id,
		data: { type, label, value, variants = [] } = {},
		data = {},
		colors,
	} = rowData;

	let tagName = (colors ?? [])
		?.find?.((name) => name?.includes?.('custom'))
		?.split?.('-')
		?.slice?.(1)
		?.join?.('-');

	if (type === 'probability') {
		const timesize = Selectors.common.currentTimesliceSize(
			store.getState(),
		);

		const date = context?.group?.id;

		probabilityContext.current = TimesizeUtils.isThis(
			date instanceof Date ? date : parseISO(date),
			timesize,
		);

		const parents = context?.parents ?? [];
		const items = parents.flatMap(({ items }) => items);

		const account = items.find(({ id }) => id === 'account.paid.current');
		const forecast = items.find(({ id }) => id === 'account.forecast');

		const accountTotal = account?.data?.value?.display?.total ?? 0;
		const forecastTotal = forecast?.data?.value?.display?.total ?? 0;

		const diff = Math.abs(forecastTotal - accountTotal);
		const diffRelative = Math.abs(diff / accountTotal);
		const diffRelativeNormalized = Math.round(diffRelative * 10) * 10;

		probabilityContext.diff = diff;
		probabilityContext.diffRelative = 1 - diffRelative;
		probabilityContext.diffRelativeNormalized = diffRelativeNormalized;
	}

	return (
		<As
			className={cn(
				'asteria-cashflow-tooltip-row',
				`asteria-cashflow-tooltip-row-${type}`,
				{
					'asteria-cashflow-tooltip-row-total':
						id?.startsWith?.('total'),
				},
				getKeyPermutations(id, '-', ['asteria-cashflow-tooltip-row']),
				variants.map(
					(variant) =>
						`asteria-cashflow-tooltip-row-${variant.toLowerCase()}`,
				),
				{
					'asteria-state-negative': value?.original?.total < 0,
				},
			)}
			key={`cashflow-tooltip-row-${id}`}
		>
			{type === 'currency' && (
				<div
					className={`currency-flag currency-flag-${value?.original?.currency?.toLowerCase()}`}
				/>
			)}
			{type === 'probability' ? (
				<FeatureFlag feature="graph-account-tooltip-forecast-diff">
					{probabilityContext.current && probabilityContext.diff ? (
						<Text className="asteria-cashflow-tooltip-forecast-diff">
							{TranslationService.get(
								[
									'cashflow.tooltip.account.forecast-diff.content',
									`cashflow.tooltip.account.forecast-diff.${probabilityContext.diffRelativeNormalized}.content`,
								],
								undefined,
								probabilityContext,
							)}
						</Text>
					) : null}
				</FeatureFlag>
			) : null}
			{type === 'probability' && (
				<FeatureFlag feature="graph-account-tooltip-probability">
					<ProbabilityBar
						probability={data?.value?.probability || 0}
						label
						labelPosition="first"
					/>
				</FeatureFlag>
			)}
			{type === 'tag' && (
				<Prefix colors={colors || id.toLowerCase().split('.')} />
			)}
			<Text
				className="asteria-cashflow-tooltip-label"
				size={type === 'subtext' || type === 'text' ? 'xs' : 'sm'}
			>
				{TranslationService.get(
					[
						...getKeyPermutations(id, '.', [
							'cashflow.tooltip',
						]).map((key) => `${key}.label`),
					],
					label ?? tagName,
					{ ...data, ...context },
				)}
			</Text>
			{type !== 'text' && type !== 'subtext' && type !== 'probability' && (
				<Text
					className={cn('asteria-cashflow-tooltip-total')}
					size="sm"
				>
					{TranslationService.get(
						[
							...getKeyPermutations(id, '.', [
								'cashflow.tooltip',
							]).map((key) => `${key}.value`),
						],
						undefined,
						{ ...data, ...context },
					)}
				</Text>
			)}
		</As>
	);
};

const TooltipGroupTitle = (props) => {
	const { id } = props;

	const translation = TranslationService.get(
		[
			id,
			...getKeyPermutations(id, '.', ['cashflow.tooltip']).map(
				(key) => `${key}.title`,
			),
		],
		'',
	);

	if (id && translation) {
		return (
			<Text size="sm" weight="medium" key="cashflow-tooltip-row-title">
				{translation}
			</Text>
		);
	}

	return null;
};

const buildTooltipGroup = (
	{
		id,
		data: { type, limit = false, items = [], variants = [] } = {},
		data = {},
	},
	context = {},
) => {
	const subItems = limit ? items.slice(0, limit) : items;
	const restSubItems = limit ? items.slice(limit) : [];

	return (
		<li
			key={`cashflow-tooltip-section-${id}`}
			className={cn(
				'asteria-cashflow-tooltip-section',
				getKeyPermutations(id, '-', [
					'asteria-cashflow-tooltip-section',
				]),
				variants.map(
					(variant) =>
						`asteria-cashflow-tooltip-section-${variant.toLowerCase()}`,
				),
			)}
		>
			<TooltipGroupTitle id={id} />
			{type
				? buildTooltipRow(
						{ id, data },
						{
							...context,
							parents: []
								.concat(context?.parents ?? [])
								.concat(data),
						},
						'div',
				  )
				: null}
			{items.length || context?.sub ? (
				<ul
					className={cn(
						getKeyPermutations(id, '-', [
							'asteria-cashflow-tooltip-section-inner',
						]),
					)}
				>
					{subItems.map((item) =>
						processInfo(item, {
							...context,
							parents: []
								.concat(context?.parents ?? [])
								.concat(data),
							sub: true,
						}),
					)}
					{restSubItems.length ? (
						<li className="asteria-cashflow-tooltip-section-inner-rest">
							<Text size="sm">
								{TranslationService.get(
									[
										...getKeyPermutations(id, '.', [
											'cashflow.tooltip',
										]).map((key) => `${key}.rest`),
									],
									'+{{rest}}',
									{
										...data,
										count: items.length,
										limit: limit,
										rest: restSubItems.length,
									},
								)}
							</Text>
						</li>
					) : null}
				</ul>
			) : (
				<Text size="sm" className="asteria--state-empty">
					{TranslationService.get([
						'graph.bar.tooltip.empty',
						`graph.bar.${id}.tooltip.empty`,
					])}
				</Text>
			)}
		</li>
	);
};

// eslint-disable-next-line react/display-name
processInfo = (item, context = {}) => {
	if (item.type === 'tooltip.group') {
		return buildTooltipGroup(item, context);
	}

	if (item.type === 'tooltip.row') {
		return buildTooltipRow(item, context);
	}

	return null;
};

const mergeGroups = (list) => {
	const result = list
		.filter(({ type }) => type === 'tooltip.group')
		.reduce((acc, item) => {
			const {
				id,
				data: { items },
				colors,
			} = item;

			const type = id?.split?.('.')?.[0];

			if (!acc[type]) {
				acc[type] = {
					...item,
					data: {
						...item.data,
						items: [],
					},
				};
			}

			const coloredItems = items.map((object) => ({
				...object,
				colors: colors,
			}));

			acc[type].data.items.push(...coloredItems);

			return acc;
		}, {});

	return Object.values(result);
};

const FakeScrollbar = React.memo(() => {
	const startDate = useSelector(
		(store) => store?.graph?.statistics?.transactions?.startDate,
		(a, b) => isEqual(a, b),
	);

	const endDate = useSelector(
		(store) => store?.graph?.statistics?.transactions?.endDate,
		(a, b) => isEqual(a, b),
	);

	const currentDate = useSelector(Selectors.common.currentDate, (a, b) =>
		isEqual(a, b),
	);

	const size = useSelector(Selectors.common.currentTimesliceSize, (a, b) =>
		isEqual(a, b),
	);

	const style = React.useMemo(() => {
		if (!startDate || !endDate || !currentDate) {
			return {};
		}

		const current = TimesizeUtils.addTimeslice(
			parseISO(currentDate),
			size,
			3,
		);

		const startOfRange = TimesizeUtils.addTimeslice(new Date(), size, -2);
		const endOfRange = TimesizeUtils.addTimeslice(new Date(), size, 2);

		let range = TimesizeUtils.each(
			{ startDate: parseISO(startDate), endDate: parseISO(endDate) },
			size,
		);

		if (range.length < 5) {
			range = TimesizeUtils.each(
				{ startDate: startOfRange, endDate: endOfRange },
				size,
			);
		}

		let index = range.findIndex((date) =>
			TimesizeUtils.isSame({ source: date, target: current }, size),
		);

		if (index == -1) {
			if (isPast(current)) {
				const diff = TimesizeUtils.diff(
					{ source: range[0], target: current },
					size,
				);

				index = range.length - 1 - (diff % range.length);
			}

			if (isFuture(current)) {
				const diff = TimesizeUtils.diff(
					{ source: current, target: range.slice(-1)[0] },
					size,
				);

				index = (diff - 1) % range.length;
			}
		}

		return { '--size': range.length - 1, '--position': index };
	}, [endDate, currentDate, startDate, size]);

	return (
		<span
			className={cn('asteria-component__graph-scrollbar', {
				'asteria--variant-default': style?.['--size'] === undefined,
			})}
			style={style}
		/>
	);
});

FakeScrollbar.displayName = 'FakeScrollbar';

class GraphData extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentDate: props.currentDate,
			currentTimesliceSize: 'month',
			isListOpen: false,
			userSettings: {},
			size: { width: 0, height: 0 },
		};

		this.subscriptions = [];
		this.ref = React.createRef();
		this.requestedGroups = 14;
		this.groupWidths = [];
		this.groupWidth = 0;

		this.getToolTip = this.getToolTip.bind(this);
		this.setDate = this.setDate.bind(this);
		this.setSize = this.setSize.bind(this);
		this.setTimesliceSize = this.setTimesliceSize.bind(this);
		this.updateRange = this.updateRange.bind(this);

		this.handleResize = AsteriaCore.utils.throttle(
			this.handleResize.bind(this),
			250,
		);
		this.next = this.next.bind(this);
		this.prev = this.prev.bind(this);
		this.updateSize = this.updateSize.bind(this);
		this.clickAction = this.clickAction.bind(this);
		this.mouseEnterAction = this.mouseEnterAction.bind(this);
		this.mouseLeaveAction = this.mouseLeaveAction.bind(this);

		this.init();
	}

	componentDidMount() {
		this.handleResize();

		window.addEventListener('resize', this.handleResize);
	}

	static getDerivedStateFromProps(props) {
		return { currentDate: props.currentDate };
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentDate !== prevProps.currentDate) {
			this.updateRange(this.props.currentDate, this.requestedGroups);
		} else if (
			this.props.currentTimesliceSize !== prevProps.currentTimesliceSize
		) {
			this.updateRange(
				this.props.currentDate,
				this.requestedGroups,
				this.props.currentTimesliceSize,
			);
		}
	}

	getToolTip({ bar, group, line, belowZero }) {
		if (bar?.value === 0) {
			return null;
		}

		const content = [];

		const bankAccounts = Selectors.common.accounts(store.getState());
		const layout = Selectors.settings.layout(store.getState())?.graph
			?.layout;

		let title = '';

		//(bar, group);

		// if (badge) {
		// 	const { data: { type = 'unknown' } = {} } = bar;
		// 	const { value: total } = badge;
		// eslint-disable-next-line spellcheck/spell-checker
		// 	const toolTipKey = `graph.bargraph.bar.${type.toLowerCase()}`;

		// 	const badgeType = badge.types.includes('overdue')
		// 		? 'overdue'
		// 		: 'unpaid';

		// 	title = TranslationService.get(
		// 		[
		// 			`${toolTipKey}.tooltip.title`,
		// 			`${toolTipKey}.badge.tooltip.title`,
		// 			`${toolTipKey}.${badgeType}.tooltip.title`,
		// 			`${toolTipKey}.badge.${badgeType}.tooltip.title`,
		// 		],
		// 		`${toolTipKey}.tooltip.title`,
		// 		{
		// 			bar,
		// 			group,
		// 			total,
		// 			badge,
		// 		},
		// 	);

		// 	const titleSubText = TranslationService.get(
		// 		[
		// 			`${toolTipKey}.tooltip.title.subtext`,
		// 			`${toolTipKey}.badge.tooltip.title.subtext`,
		// 			`${toolTipKey}.${badgeType}.tooltip.title.subtext`,
		// 			`${toolTipKey}.badge.${badgeType}.tooltip.title.subtext`,
		// 		],
		// 		'',
		// 		{
		// 			bar,
		// 			group,
		// 			total,
		// 			badge,
		// 		},
		// 	);

		// 	if (titleSubText) {
		// 		content.push(
		// 			<li
		// 				className="asteria-cashflow-tooltip-row asteria-cashflow-tooltip-row-title asteria-cashflow-tooltip-row-subtext"
		// 				key="cashflow-tooltip-row-badge-title-subtext"
		// 			>
		// 				<Text
		// 					className="asteria-cashflow-tooltip-subtext"
		// 					size="sm"
		// 				>
		// 					{titleSubText}
		// 				</Text>
		// 			</li>,
		// 		);
		// 	}

		// 	const typeLabel = TranslationService.get(
		// 		`${toolTipKey}.tooltip.label`,
		// 		`${toolTipKey}.tooltip.label`,
		// 		{
		// 			bar,
		// 			group,
		// 			total,
		// 			badge,
		// 		},
		// 	);

		// 	const typeTotal = TranslationService.get(
		// 		`${toolTipKey}.tooltip.total`,
		// 		`${toolTipKey}.tooltip.total`,
		// 		{
		// 			bar,
		// 			group,
		// 			total,
		// 			badge,
		// 		},
		// 	);

		// 	const typeSubtext = TranslationService.get(
		// 		`${toolTipKey}.badge.${badgeType}.tooltip.subtext`,
		// 		``,
		// 		{
		// 			bar,
		// 			group,
		// 			badge,
		// 			total,
		// 		},
		// 	);

		// 	const badgeLabel = TranslationService.get(
		// 		`${toolTipKey}.badge.${badgeType}.tooltip.label`,
		// 		`${toolTipKey}.badge.${badgeType}.tooltip.label`,
		// 		{
		// 			bar,
		// 			group,
		// 			badge,
		// 			total,
		// 		},
		// 	);

		// 	const badgeTotal = TranslationService.get(
		// 		`${toolTipKey}.badge.${badgeType}.tooltip.total`,
		// 		`${toolTipKey}.badge.${badgeType}.tooltip.total`,
		// 		{
		// 			bar,
		// 			group,
		// 			badge,
		// 			total,
		// 		},
		// 	);

		// 	content.push(
		// 		<li
		// 			key="cashflow-tooltip-row-badge-overdue"
		// 			className={cn(
		// 				'asteria-cashflow-tooltip-row',
		// 				'asteria-cashflow-tooltip-row-part',
		// 			)}
		// 		>
		// 			<span color={processColor(`$${badgeType}`, [], theme)} />
		// 			<Text className="asteria-cashflow-tooltip-label" size="sm">
		// 				{badgeLabel}
		// 			</Text>
		// 			<Text className="asteria-cashflow-tooltip-total" size="sm">
		// 				{badgeTotal}
		// 			</Text>
		// 		</li>,
		// 	);

		// 	if (typeSubtext) {
		// 		content.push(
		// 			<li
		// 				className="asteria-cashflow-tooltip-row asteria-cashflow-tooltip-row-subtext"
		// 				key="cashflow-tooltip-row-badge-subtext"
		// 			>
		// 				<Text
		// 					className="asteria-cashflow-tooltip-subtext"
		// 					size="sm"
		// 				>
		// 					{typeSubtext}
		// 				</Text>
		// 			</li>,
		// 		);
		// 	}

		// 	content.push(
		// 		<li
		// 			className="asteria-cashflow-tooltip-row asteria-cashflow-tooltip-row-total"
		// 			key="cashflow-tooltip-row-badge-total"
		// 		>
		// 			<Text className="asteria-cashflow-tooltip-label" size="sm">
		// 				{typeLabel}
		// 			</Text>
		// 			<Text className="asteria-cashflow-tooltip-total" size="sm">
		// 				{typeTotal}
		// 			</Text>
		// 		</li>,
		// 	);
		// }

		const { lines = [], bars = [] } = group;

		if (layout !== 'stacked2') {
			if (line && lines.some((l) => l?.info)) {
				const info = lines.map((l) => l?.info || []).flat();
				content.push(
					...info
						.filter(({ type }) => type === 'tooltip.group')
						.map(
							(item) =>
								processInfo(item, {
									bankAccounts,
									bar,
									group,
									line,
								}) || [],
						),
				);
			} else if (group && group?.bars) {
				let tooltipGroups = bars
					.filter(
						({ data: { type } = {} }) =>
							bar && type === bar.data.type,
					)
					.map(({ parts }) => {
						return parts || [];
					})
					.flat()
					.map(({ info, colors }) =>
						info.map((object) => ({ ...object, colors: colors })),
					)
					.flat(1)
					.filter(({ type }) => type === 'tooltip.group');

				if (!tooltipGroups.length) {
					tooltipGroups = [
						{
							id: bar?.data?.type?.toUpperCase?.(),
							type: 'tooltip.group',
							data: { items: [] },
						},
					];
				}

				content.push(
					...(mergeGroups(tooltipGroups).map(
						(item) =>
							processInfo(item, {
								bankAccounts,
								bar,
								group,
								line,
							}) || [],
					) || []),
				);
			} else if (bar?.info && bar?.info?.length) {
				const tooltipData = mergeGroups(
					bar?.info
						.flat(1)
						.filter(({ type }) => type === 'tooltip.group'),
				).map((object) => {
					const dataItems = object?.data?.items.filter(
						({ id }) =>
							id !== 'total.DEPOSIT' && id !== 'total.WITHDRAW',
					);

					return {
						...object,
						data: { ...object.data, items: dataItems },
					};
				});

				content.push(
					...(tooltipData.map(
						(item) =>
							processInfo(item, {
								bankAccounts,
								bar,
								group,
								line,
							}) || [],
					) || []),
				);
			}
		} else {
			if (line && lines.some((l) => l?.info)) {
				const info = lines.map((l) => l?.info || []).flat();
				content.push(
					...info
						.filter(({ type }) => type === 'tooltip.group')
						.map(
							(item) =>
								processInfo(item, {
									bankAccounts,
									bar,
									group,
									line,
								}) || [],
						),
				);
			}

			content.push(
				<div className="asteria-cashflow-tooltip-content">
					{mergeGroups(
						(bars || [])
							.filter(() => bar)
							.map(({ parts }) => parts || [])
							.flat()
							.map(({ info }) => info || [])
							.flat(1)
							.filter(({ type }) => type === 'tooltip.group'),
					).map(
						(item) =>
							processInfo(item, {
								bankAccounts,
								bar,
								group,
								line,
							}) || [],
					) || null}
				</div>,
			);
		}

		return {
			title,
			content: (
				<>
					<ul
						className={cn(
							'graph-tooltip',
							'graph-tooltip-content',
							{
								[`graph-tooltip__${layout}`]: layout,
								'asteria--state-below-zero': belowZero,
							},
						)}
					>
						{content}
					</ul>
					<FeatureFlag feature="graph-tooltip-bottom-action-label">
						<Text
							size="xs"
							align="center"
							className="graph-tooltip-bottom-action"
						>
							{TranslationService.get(
								[
									'graph.tooltip.bottom.action',
									line
										? 'graph.tooltip.account.bottom.action'
										: null,
									bar
										? 'graph.tooltip.bars.bottom.action'
										: null,
								],
								undefined,
								{ bar, group, line, belowZero },
							)}
						</Text>
					</FeatureFlag>
				</>
			),
		};
	}

	setDate(date) {
		const { dispatch } = this.props;
		const { currentDate } = this.state;

		if (date !== currentDate) {
			this.setState({ currentDate: date });
			this.updateRange(date, this.requestedGroups);
		}

		dispatch(setCurrentDate(date));
	}

	setSize(size, cb) {
		this.setState({ size }, cb);
	}

	setTimesliceSize(timeslice) {
		const { dispatch } = this.props;
		dispatch(setTimesliceSize(timeslice));
	}

	handleResize() {
		if (this.ref.current) {
			this.setSize(getSize(getElement(this.ref.current)), () => {
				const {
					size: { width = 0 },
				} = this.state;
				this.requestedGroups = Math.floor(width / this.groupWidth) + 4;
				this.updateRange(this.props.currentDate, this.requestedGroups);
			});
		}
	}

	init() {
		this.updateRange(this.props.currentDate, this.requestedGroups);
	}

	updateRange(requestedDate, size, newTimeslice, { force } = {}) {
		const { isListOpen } = this.state;
		const { dispatch } = this.props;

		const currentTimesliceSize = Selectors.common.currentTimesliceSize(
			store.getState(),
		);
		const dataRange = Selectors.graph.range(store.getState());
		const activeGroups = Selectors.graph.activeGroups(store.getState());
		const activeBars = Selectors.graph.activeBars(store.getState());

		let date = isValidDate(requestedDate)
			? requestedDate
			: parseISO(requestedDate);

		const newRange = [];
		const requestedSize =
			size && size !== Number.POSITIVE_INFINITY ? size : dataRange.length;
		const timeslice = newTimeslice || currentTimesliceSize;

		let prevDate = date;
		if (timeslice === 'month') {
			date = startOfMonth(date);
		} else if (timeslice === 'week') {
			date = startOfISOWeek(date);
			prevDate = addWeeks(date, -1);
		} else if (timeslice === 'year') {
			date = startOfYear(date);
		}

		for (let i = 0; i < requestedSize; i += 1) {
			let key = 'graph.xaxis';

			if (
				(timeslice === 'year' && isThisYear(date)) ||
				(timeslice === 'month' && isThisMonth(date)) ||
				(timeslice === 'week' && isThisISOWeek(date))
			) {
				key = `${key}.today`;
			} else if (isFuture(date)) {
				key = `${key}.future`;
			} else {
				key = `${key}.history`;
			}

			let prefixKey = key;

			if (timeslice === 'month' && date.getMonth() === 0) {
				prefixKey = `${prefixKey}.year`;
			}

			if (
				timeslice === 'week' &&
				date.getMonth() !== prevDate.getMonth()
			) {
				prefixKey = `${prefixKey}.month`;
			}

			const prefix = TranslationService.get(`${prefixKey}.prefix`, '', {
				date,
			});

			const label = TranslationService.get(
				`graph.xaxis.history.${timeslice}.label`,
				'',
				{ date },
			);
			let type = 'history';
			if (
				(timeslice === 'year' && isThisYear(date)) ||
				(timeslice === 'month' && isThisMonth(date)) ||
				(timeslice === 'week' && isThisISOWeek(date))
			) {
				type = 'today';
			} else if (isFuture(date)) {
				type = 'forecast';
			}

			newRange.push({
				id: `${format(date, 'yyyy-MM-dd', {
					locale: TranslationService.getLocale(),
				})}`,
				types: [type],
				prefix,
				label,
			});

			if (timeslice === 'week') {
				prevDate = date;
				date = addWeeks(date, 1);
			} else if (timeslice === 'month') {
				date = addMonths(date, 1);
			} else if (timeslice === 'year') {
				date = addYears(date, 1);
			}
		}

		if (timeslice !== currentTimesliceSize) {
			if (isListOpen) {
				if (activeGroups.length > 0) {
					const [groupId] = activeGroups;
					const [bar] = activeBars;
					const selectedDate = TimesizeUtils.startOfTime(
						groupId instanceof Date ? groupId : parseISO(groupId),
						timeslice,
						currentTimesliceSize,
					);

					dispatch(setSelectedDate(selectedDate));
					// dispatch(setCurrentDate(selectedDate));

					dispatch(
						setCurrentDate(
							TimesizeUtils.addTimeslice(
								selectedDate,
								timeslice,
								-3,
							),
							// TimesizeUtils.addTimeslice(selectedDate, timeslice, -4),
						),
					);

					dispatch(
						setSelectedType(bar ? [bar] : ['DEPOSIT', 'WITHDRAW']),
					);

					dispatch(
						setActiveGroups([
							`${format(selectedDate, 'yyyy-MM-dd')}`,
						]),
					);
				}
			} else {
				// dispatch(
				// 	setListDates(
				// 		TimesizeUtils.startOfTime(new Date(), timeslice),
				// 		TimesizeUtils.addTimeslice(
				// 			TimesizeUtils.startOfTime(new Date(), timeslice),
				// 			timeslice,
				// 			1,
				// 		),
				// 		['DEPOSIT', 'WITHDRAW'],
				// 		null,
				// 		timeslice,
				// 	),
				// );
			}
		}

		const newIds = newRange.map(({ id }) => id);
		const oldIds = dataRange.map(({ id }) => id);

		if (
			newIds.length !== oldIds.length ||
			!newIds.every((id) => oldIds.indexOf(id) !== -1) ||
			force
		) {
			if (currentTimesliceSize !== timeslice) {
				this.setTimesliceSize(timeslice);
			}

			dispatch(setRange(newRange));
		}
	}

	next(size = 1) {
		const { currentDate } = this.state;

		const currentTimesliceSize = Selectors.common.currentTimesliceSize(
			store.getState(),
		);

		const nextMonth = TimesizeUtils.addTimeslice(
			parseISO(currentDate),
			currentTimesliceSize,
			size,
		);

		this.updateRange(nextMonth, this.requestedGroups, undefined, {
			force: true,
		});
		this.setDate(format(nextMonth, 'yyyy-MM-dd'));
	}

	prev(size = 1) {
		const { currentDate } = this.state;

		const currentTimesliceSize = Selectors.common.currentTimesliceSize(
			store.getState(),
		);

		const prevMonth = TimesizeUtils.addTimeslice(
			parseISO(currentDate),
			currentTimesliceSize,
			-size,
		);

		this.updateRange(prevMonth, this.requestedGroups, undefined, {
			force: true,
		});
		this.setDate(format(prevMonth, 'yyyy-MM-dd'));
	}

	updateSize(size) {
		const {
			size: { width = 0 },
		} = this.state;

		const dataRange = Selectors.graph.range(store.getState());

		this.groupWidths.push(size);
		if (this.groupWidths.length >= dataRange.length - 2) {
			const newSize = Math.max(...this.groupWidths);

			this.groupWidths.length = 0;

			if (
				newSize !== 0 &&
				!Number.isNaN(newSize) &&
				this.groupWidth !== newSize
			) {
				this.groupWidth = newSize;
			}
		}

		if (
			width &&
			this.groupWidth &&
			this.requestedGroups !== Math.floor(width / this.groupWidth) + 4
		) {
			this.requestedGroups = Math.floor(width / this.groupWidth) + 4;
			this.updateRange(this.props.currentDate, this.requestedGroups);
			// dispatch(requestNewRange(Math.floor(width / this.groupWidth) + 2));
		}
	}

	clickAction({ bar, group, badge, source }) {
		// const { currentTimesliceSize } = this.state;
		const { dispatch, mode: propMode } = this.props;

		const $mode = Selectors.common.mode(store.getState());
		const mode = propMode ?? $mode;

		const currentTimesliceSize = Selectors.common.currentTimesliceSize(
			store.getState(),
		);

		if (mode === 'credit') {
			return;
		}

		if (source === 'edit') {
			this?.props?.onAction?.('graph:forecaster:show', {
				type: bar?.data?.type,
				date: group?.id,
			});
			return;
		}

		if (source === 'dot') {
			const type = bar?.data?.type;

			this?.props?.onAction?.('updateUserSettings', {
				flags: { [`bar:dot:${type}:isShown`]: true },
			});

			return;
		}

		if (badge) {
			// const date = new Date(group.id);
			dispatch(setActiveGroups([group.id]));
			dispatch(setListOpen(true, source));
			dispatch(
				setSelectedDate(
					group.id instanceof Date ? group.id : parseISO(group.id),
				),
			);
			dispatch(
				setCurrentDate(
					TimesizeUtils.addTimeslice(
						group.id instanceof Date
							? group.id
							: parseISO(group.id),
						currentTimesliceSize,
						-3,
					),
				),
			);
			// dispatch(
			// 	setCurrentDate(
			// 		TimesizeUtils.addTimeslice(
			// 			new Date(group.id),
			// 			currentTimesliceSize,
			// 			-4,
			// 		),
			// 	),
			// );
			dispatch(
				setSelectedType(
					badge.types.includes('deposit')
						? ['DEPOSIT']
						: ['WITHDRAW'],
				),
			);
			/*
			status: badge.types.includes('overdue') ? ['OVERDUE'] : [],
			*/
		} else {
			if (group) {
				// const date = new Date(group.id);
				dispatch(setActiveGroups([group.id]));
				dispatch(setListOpen(true, source));
				dispatch(setSelectedDate(group.id));

				if (FeatureService.isActive('graph-center-on-click')) {
					dispatch(
						setCurrentDate(
							format(
								TimesizeUtils.addTimeslice(
									group.id instanceof Date
										? group.id
										: parseISO(group.id),
									currentTimesliceSize,
									-3,
								),
								'yyyy-MM-dd',
							),
						),
					);
				}
				// dispatch(
				// 	setCurrentDate(
				// 		format(
				// 			TimesizeUtils.addTimeslice(
				// 				new Date(group.id),
				// 				currentTimesliceSize,
				// 				-4,
				// 			),
				// 			'yyyy-MM-dd',
				// 		),
				// 	),
				// );
				dispatch(
					setSelectedType(
						bar
							? [bar.data.type.toUpperCase()]
							: ['DEPOSIT', 'WITHDRAW'],
					),
				);

				if (source === 'edit') {
					dispatch(setAdjustOpen(true));
				} else {
					dispatch(setAdjustOpen(false));
				}
			} else {
				dispatch(setActiveGroups([]));
			}

			if (bar) {
				dispatch(setListOpen(true, source));
				dispatch(setActiveBars([bar.data.type]));
				if (source === 'edit') {
					dispatch(setAdjustOpen(true));
				} else {
					dispatch(setAdjustOpen(false));
				}
			} else {
				dispatch(setActiveBars([]));
			}

			if (group && !bar) {
				Analytics.event('button.click', {
					analyticsKey: 'graph.group',
					groupId: group.id,
					barType: null,
				});
			} else {
				Analytics.event('button.click', {
					analyticsKey: 'graph.group.bar',
					groupId: group.id,
					barType: bar.data.type,
				});
			}
		}

		dispatch(setActive(bar !== undefined ? 'barGraph' : 'lineGraph'));

		return this?.props?.onAction?.('graph:click', {
			type: bar?.data?.type,
			date: group?.id,
		});
	}

	mouseEnterAction({ bar, group }) {
		const { dispatch } = this.props;

		if (group) {
			dispatch(setHoverGroups([group.id]));
		}

		if (bar) {
			dispatch(setHoverBars([bar]));
		}
	}

	mouseLeaveAction({ bar }) {
		const { dispatch } = this.props;
		if (!bar) {
			dispatch(setHoverGroups([]));
		}

		if (bar) {
			dispatch(setHoverBars([]));
		}
	}

	render() {
		const {
			onResize,
			requestData,
			variant,
			mode,
			showFakeScroll,
			context,
		} = this.props;

		return (
			<>
				<GraphLayout
					updateSize={this.updateSize}
					next={this.next}
					prev={this.prev}
					ref={this.ref}
					getTooltip={this.getToolTip}
					onClick={this.clickAction}
					onMouseEnter={this.mouseEnterAction}
					onMouseLeave={this.mouseLeaveAction}
					onAction={this?.props?.onAction}
					onResize={onResize}
					requestData={requestData}
					onUpdateSettings={this.props.onUpdateSettings}
					// New
					variant={variant}
					mode={mode}
					context={context}
				/>
				{showFakeScroll ? (
					<FeatureFlag feature="graph-fake-scrollbar">
						<FakeScrollbar />
					</FeatureFlag>
				) : null}
			</>
		);
	}
}

GraphData.displayName = 'GraphData';

export { processInfo };
export default React.memo(
	withTheme(
		connect(
			(state) => {
				return {
					currentDate: Selectors.common.currentDate(state),
					currentTimesliceSize:
						Selectors.common.currentTimesliceSize(state),
				};
			},
			null,
			null,
			{
				areStatesEqual: (next, prev) => isEqual(prev, next),
				areOwnPropsEqual: (next, prev) => isEqual(prev, next),
				areStatePropsEqual: (next, prev) => isEqual(prev, next),
				areMergedPropsEqual: (next, prev) => isEqual(prev, next),
			},
		)(GraphData),
	),
);
