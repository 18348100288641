import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { format, parseISO, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { useFeature } from '@asteria/component-tools/featureflag';
import TransactionList, {
	Context,
	Hooks,
	TransactionShadow,
	TransactionWrapperListener,
} from '@asteria/component-transactions';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';
import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { useIsValid, useModifiedCount, useNotifications } from './hooks';
import * as selectors from './selectors';
import { addTimeslice, getRequest } from './utils';

const DEFAULT_SORTING = {
	field: 'paymentDate',
	direction: 'DESC',
};

async function fetchTransactions(extra = {}) {
	const { options, onFetch, store, skipDate } = extra;

	const request = getRequest({ store: store, options: options });

	if (skipDate) {
		delete request.startDate;
		delete request.endDate;
	}

	const response = await onFetch?.('transactions:list', request);

	return {
		transactions: response?.transactions ?? [],
		pageInfo: response?.pageInfo,
	};
}

const CashflowTransactions = (props) => {
	const { onAction, onFetch } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const ref = React.useRef(null);

	const scrollMargin = useConfig('transactions.pagination.margin') ?? 200;
	const hasGraphScrollingFeature = useFeature(
		'graph-transactions-paginate-scroll',
	);

	const [sorting, setSorting] = React.useState(DEFAULT_SORTING);

	const state = useSelector(TransactionStore.selectors.state);
	const visibleColumns = useSelector(
		TransactionStore.selectors.visibleColumns,
	);
	const compact = useSelector(TransactionStore.selectors.isCompact);

	const selectedDate = useSelector(selectors.selectedDate);
	const filters = useSelector(selectors.filters);
	const types = useSelector(selectors.types);
	const scenarioId = useSelector(ScenarioStore.selectors.scenarioId);
	const size = useSelector(selectors.timesize);
	const layoutSize = useSelector(selectors.layoutSize);

	const count = useModifiedCount({ onFetch: onFetch, state: state });
	const notifications = useNotifications({ onFetch: onFetch, state: state });

	const [{ loading, cursor }, dispatchFetching] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'LOADING':
					return { ...state, loading: true };

				case 'DONE':
					return { ...state, loading: false, cursor: action.payload };

				case 'RESET':
					return { ...state, loading: false, cursor: null };

				default:
					return state;
			}
		},
		{ loading: false, cursor: null },
	);

	const reverse =
		sorting?.field === 'paymentDate' && sorting?.direction === 'ASC';

	const columns = useDeepMemo(
		() => ({
			available: [
				'description',
				'paymentDate',
				'tags',
				'status',
				'total',
				'risk',
				'currency',
				'options',
			],
			visible: visibleColumns.concat('options'),
		}),
		[visibleColumns],
	);

	const isOpen = state !== null;

	React.useEffect(() => {
		if (isOpen) {
			const date = new Date().toISOString();

			dispatch(AppStore.setUserSettingsFlags({ transactions: date }));
			onAction?.('updateUserSettings', { flags: { transactions: date } });
		}
	}, [onAction, isOpen, dispatch]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action.startsWith('transactions:')) {
				if (action === 'transactions:show') {
					dispatch(TransactionStore.setState(data));
				}

				if (action === 'transactions:hide') {
					dispatch(TransactionStore.setActive(null));
					dispatch(TransactionStore.setState(null));
				}

				if (action === 'transactions:filter') {
					dispatch(AppStore.toggleFilter(data));
					dispatch(TransactionStore.setActive(null));
				}

				if (action === 'transactions:sorting') {
					setSorting((sorting) => {
						if (data === sorting.field) {
							if (sorting.direction === 'ASC') {
								return { ...sorting, direction: 'DESC' };
							}

							return { ...sorting, direction: 'ASC' };
						}

						return { field: data, direction: 'DESC' };
					});
				}

				return;
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);

	const fetch = React.useCallback(
		async (options = {}) => {
			if (loading) {
				return;
			}

			dispatchFetching({ type: 'LOADING' });

			let { transactions, pageInfo } = await fetchTransactions({
				store: store,
				options: options,
				onFetch: onFetch,
			});

			if (!transactions.length) {
				const response = await fetchTransactions({
					store: store,
					options: options,
					onFetch: onFetch,
					skipDate: true,
				});

				transactions = response?.transactions;
				pageInfo = response?.pageInfo;
			}

			const dates = Object.keys(
				(transactions ?? []).reduce((acc, object) => {
					if (object?.paymentDate) {
						const date = format(
							startOfMonth(parseISO(object?.paymentDate)),
							'yyyy-MM-dd',
						);

						acc[date] = true;
					}

					return acc;
				}, {}),
			);

			for (const date of dates) {
				if (!store.getState()?.graph?.data?.barGroups?.[date]) {
					onFetch?.('cashflow', { startDate: date });
				}
			}

			dispatch(TransactionStore.setItems(transactions));

			let cursor = null;

			if (reverse) {
				if (pageInfo?.hasNextPage) {
					cursor = pageInfo?.endCursor ?? null;
				}
			} else {
				if (pageInfo?.hasPreviousPage) {
					cursor = pageInfo?.startCursor ?? null;
				}
			}

			dispatchFetching({ type: 'DONE', payload: cursor });
		},
		[dispatch, loading, onFetch, reverse, store],
	);

	const fetchNext = React.useCallback(() => {
		if (!cursor) {
			return null;
		}

		const pageQuery = {};

		if (reverse) {
			pageQuery.after = cursor;
		} else {
			pageQuery.before = cursor;
		}

		return fetch({ pageQuery: pageQuery });
	}, [cursor, fetch, reverse]);

	React.useEffect(() => {
		if (state) {
			fetch().then(() => {
				ref?.current?.scrollTo?.({ top: 0, behavior: 'smooth' });
			});

			return () => {
				dispatch(TransactionStore.reset());
			};
		}
	}, [state, selectedDate, types, reverse, filters, scenarioId]);

	const handleScroll = React.useMemo(
		() =>
			AsteriaCore.utils.throttle((event) => {
				const clientHeight = event?.target?.clientHeight;
				const scrollHeight = event?.target?.scrollHeight;
				const scrollTop = event?.target?.scrollTop;

				if (scrollHeight - clientHeight - scrollMargin <= scrollTop) {
					fetchNext?.();
				}

				if (hasGraphScrollingFeature) {
					if (event.target && ref.current) {
						if (event.target.isSameNode(ref.current)) {
							let firstVisible = ref.current.querySelector(
								'.asteria--state-visible',
							);

							if (!firstVisible) {
								firstVisible = ref.current.querySelectorAll(
									'.asteria--state-scroll-passed',
								);

								firstVisible =
									firstVisible?.[firstVisible.length - 1];
							}

							if (firstVisible) {
								const selectedDate =
									firstVisible.getAttribute('data-date');
								const date = addTimeslice(
									parseISO(selectedDate),
									'month',
									-4,
								);

								dispatch(
									AppStore.setCurrentDate(
										format(date, 'yyyy-MM-dd'),
									),
								);
							}
						}
					}
				}
			}, 100),
		[dispatch, fetchNext, hasGraphScrollingFeature, scrollMargin],
	);

	React.useLayoutEffect(() => {
		if (isOpen) {
			let nodes = [
				document.querySelector(
					'.wings, .webapp, .asteria-component__wrapper-content',
				),
			];

			setTimeout(() => {
				const node = findScrollingParent(ref.current);

				if (node) {
					nodes.push(node);
				}

				nodes.forEach((node) => {
					if (node) {
						node.addEventListener('scroll', handleScroll);
					}
				});
			}, 500);

			return () => {
				nodes.forEach((node) => {
					if (node) {
						node.removeEventListener('scroll', handleScroll);
					}
				});
			};
		}
	}, [handleScroll, isOpen, layoutSize]);

	React.useEffect(() => {
		if (state) {
			return () => {
				dispatch(TransactionStore.reset());
				dispatchFetching({ type: 'RESET' });
				setSorting(DEFAULT_SORTING);
			};
		}
	}, [state]);

	const ctx = useDeepMemo(
		() => ({
			state: state,
			loading: loading,
			compact: compact,
			columns: columns,
			sorting: sorting,
			size: size,
		}),
		[columns, compact, loading, size, sorting, state],
	);

	return (
		<>
			<TransactionShadow />
			<TransactionWrapperListener onAction={handleAction} state={state} />
			<Context.Provider
				{...ctx}
				useHeaderParts={Hooks.useHeaderParts}
				useIsValid={useIsValid}
				useGroups={Hooks.useGroups}
			>
				<TransactionList
					count={count}
					loading={loading}
					notifications={notifications}
					onAction={handleAction}
					ref={ref}
				/>
			</Context.Provider>
		</>
	);
};

CashflowTransactions.displayName = 'CashflowTransactions';

CashflowTransactions.propTypes = {
	onAction: PropTypes.func,
	onFetch: PropTypes.func,
};

export default CashflowTransactions;
