import { TranslationService } from '@asteria/language';

export function formatError({ error, form }) {
	const key = error?.extensions?.key;
	const message =
		error?.response?.data?.errors?.[0]?.message ?? error?.message;

	return TranslationService.get([message, key], message, form);
}

export function formatAuthResponse(response) {
	const accessToken = response?.data?.accessToken ?? response?.accessToken;
	const refreshToken = response?.data?.refreshToken ?? response?.refreshToken;

	return { accessToken: accessToken, refreshToken: refreshToken };
}

export function defaultReducer(state, action) {
	switch (action?.type) {
		case 'START':
			return { ...state, loading: true, error: null };

		case 'STOP':
			return { ...state, loading: false };

		case 'FAILURE':
			return { ...state, loading: false, error: action?.payload };

		case 'NEXT':
			return {
				...state,
				loading: false,
				step: action?.payload?.step,
				data: action?.payload?.data,
			};

		default:
			return state;
	}
}
