import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';

import Notifications from '@asteria/component-notifications';
import Settings from '@asteria/component-settings';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import './styles.scss';

const SettingsView = (props) => {
	const { onAction } = props;

	const onClose = useCallback(() => {
		onAction?.('go', '/');
	}, [onAction]);

	useTourShowAction();

	return (
		<div className="asteria-view__settings">
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get('settings.title')}
					/>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-settings">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<Settings
				onAction={onAction}
				onClose={onClose}
				featurePrefix="view"
			/>
		</div>
	);
};

SettingsView.propTypes = {
	onAction: PropTypes.func,
};

export default SettingsView;
