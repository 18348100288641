import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import GraphLogic from '@asteria/component-graph/logic';

import { updateAxis } from '@asteria/datalayer/stores/graph';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

const selectors = {
	graphLayout: createSelector(
		(store) => store?.app?.user?.settings?.layout?.graph,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	graphStatistics: createSelector(
		(store) => store?.graph?.statistics,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	range: createSelector(
		(store) => store?.graph?.data?.range,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	barGroups: createSelector(
		(store) => store?.graph?.data?.barGroups,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Graph = (props) => {
	const { onAction, onResize, onFetch } = props;
	const dispatch = useDispatch();

	const requestData = useCallback(
		(startDate, { signal } = {}) => {
			dispatch(ScenarioStore.stopRefreshing());

			return onFetch?.('cashflow', {
				startDate: startDate,
				signal: signal,
			});
		},
		[dispatch, onFetch],
	);

	const graphLayout = useSelector(selectors.graphLayout);
	const graphStatistics = useSelector(selectors.graphStatistics);
	const range = useSelector(selectors.range);

	useEffect(() => {
		dispatch(
			updateAxis({
				barLayout: graphLayout?.layout,
				scale: graphLayout?.scale,
			}),
		);
	}, [
		range,
		dispatch,
		graphLayout?.layout,
		graphLayout?.scale,
		graphStatistics,
	]);

	const onUpdateSettings = useCallback(
		(form) => onAction?.('updateUserSettings', form),
		[onAction],
	);

	const ctx = React.useMemo(
		() => ({
			xaxis: { clickable: true, hoverable: true },
			legend: { clickable: true, hoverable: true },
		}),
		[],
	);

	return (
		<GraphLogic
			requestData={requestData}
			onResize={onResize}
			onUpdateSettings={onUpdateSettings}
			onAction={onAction}
			variant="cashflow"
			context={ctx}
		/>
	);
};

Graph.propTypes = {
	onResize: PropTypes.func,
	onAction: PropTypes.func,
	onFetch: PropTypes.func,
};

export default Graph;
