import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import { cn } from '@asteria/utils-funcs/classes';

import ForecasterSettingsForm from './form';

import './styles.scss';

const ForecasterSettings = (props) => {
	const { className, open, onClose } = props;

	return (
		<Modal
			className={cn('asteria-component__forecaster-settings', className)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<ForecasterSettingsForm {...props} />
		</Modal>
	);
};

ForecasterSettings.displayName = 'ForecasterSettings';

ForecasterSettings.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,

	form: PropTypes.object,
};

export default ForecasterSettings;
