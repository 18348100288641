import { ACTIONS, SECTIONS, initialState } from '../constants';

const init = (state, action) => {
	// state.isLoading = false;
	state.tags = action?.payload?.tags;

	state.navigation.section = state.navigation.section ?? SECTIONS.DEPOSIT;
	state.navigation.type = state.navigation.type ?? 'deposit';
	state.navigation.category = state.navigation.category ?? '$type';
	state.navigation.tag = state.navigation.tag ?? '$deposit';

	state.initialData = { tags: state.tags, navigation: state.navigation };
};

const start = (state, action) => {
	state.isLoading = true;

	if (action.payload) {
		state.navigation.type = action.payload;
	}
};

const stop = (state) => {
	state.isLoading = false;
};

const startSaving = (state) => {
	state.isSaving = true;
};

const stopSaving = (state) => {
	state.lastSaveAt = new Date().toISOString();

	state.isSaving = false;
};

const resetLastSaveAt = (state) => {
	state.lastSaveAt = null;
};

const reset = (state) => ({
	...initialState,
	isSaving: state?.isSaving ?? false,
});

const close = (state) => {
	state.action = { event: ACTIONS.Close };
};

const restart = (state) => {
	state.history = [];

	state.tags = state.initialData.tags;
	state.navigation = state.initialData.navigation;
};

export {
	close,
	init,
	reset,
	restart,
	start,
	startSaving,
	stop,
	stopSaving,
	resetLastSaveAt,
};
