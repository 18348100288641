import React from 'react';

import PropTypes from 'prop-types';

import { WizardStep } from '@asteria/component-wizard';

import { cn } from '@asteria/utils-funcs/classes';

import DoneStep from './Done';
import QuizStep from './Quiz';
import StartStep from './Start';

import './styles.scss';

const WelcomeWizardStep = (props) => {
	const { className, step, name, onAction } = props;

	return (
		<WizardStep
			className={cn(
				'asteria-view__welcome__wizard-step',
				{
					'asteria-view__welcome__wizard-step--variant-start':
						step === 'start',
					'asteria-view__welcome__wizard-step--variant-done':
						step === 'done',
					'asteria-view__welcome__wizard-step--variant-quiz':
						typeof step === 'number',
				},
				className,
			)}
		>
			{step === 'start' ? (
				<StartStep name={name} onAction={onAction} />
			) : null}
			{step === 'done' ? (
				<DoneStep name={name} onAction={onAction} />
			) : null}
			{typeof step === 'number' ? (
				<QuizStep step={step} name={name} onAction={onAction} />
			) : null}
		</WizardStep>
	);
};

WelcomeWizardStep.displayName = 'WizardStep';

WelcomeWizardStep.propTypes = {
	className: PropTypes.string,
	step: PropTypes.oneOfType([
		PropTypes.oneOf(['start', 'done']),
		PropTypes.number,
	]),
	onAction: PropTypes.func,
	name: PropTypes.string,
};

export default WelcomeWizardStep;
