import React from 'react';

import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import AccountsContent from './content';
import * as selectors from './selectors';

/** @type { React.FC<import('./types').Props> } */
const Accounts = React.memo(function Accounts(props) {
	const { className, onClose, onSubmit, header, footer } = props;

	const save = useMutation({
		mutationFn: async (form) =>
			onSubmit?.('accounts:save', {
				form: Object.entries(form?.bankAccount ?? {}).reduce(
					(acc, [id, account]) => ({ ...acc, [id]: account }),
					{},
				),
			}),
		onSuccess: () => onClose?.(),
	});

	const form = useSelector(
		(store) => ({
			bankAccount: selectors.accounts(store).reduce((acc, object) => {
				const ID = object?._id ?? object?.id;

				return { ...acc, [ID]: object };
			}, {}),
		}),
		(a, b) => isEqual(a, b),
	);

	return (
		<Form onSubmit={save.mutate} values={form}>
			<Wrapper className={cn('asteria-component__accounts', className)}>
				{header ? (
					<Header onClose={onClose}>
						{TranslationService.get('bankList.title')}
					</Header>
				) : null}
				<Content>
					<AccountsContent />
				</Content>
				<Footer>
					{footer?.back ? (
						<FooterSection position="first">
							<Button
								variant="tertiary"
								label={TranslationService.get([
									'action.abort',
									'button.abort',
									'accounts.action.abort',
								])}
								onClick={onClose}
							/>
						</FooterSection>
					) : null}
					<FooterSection position="last">
						<Button
							type="submit"
							variant="primary"
							label={TranslationService.get([
								'action.save',
								'button.save',
								'accounts.action.save',
							])}
							loading={save.isLoading}
							disabled={save.isLoading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
});

Accounts.displayName = 'Accounts';

Accounts.propTypes = {
	onClose: PropTypes.func,

	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	header: PropTypes.bool,
	footer: PropTypes.shape({ back: PropTypes.bool }),
};

export default Accounts;
