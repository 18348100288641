import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const Textarea = (props) => {
	const { name, placeholder, value, onChange, required } = props;

	const [active, setActive] = React.useState(false);

	const handleFocus = React.useCallback(() => {
		setActive(true);
	}, []);

	const handleBlur = React.useCallback(() => {
		setActive(false);
	}, []);

	return (
		<div
			className={cn(
				'asteria-component__input',
				'asteria--size-md',
				'asteria-component__input--type-textarea',
				'asteria-component__input--icon-position-first',
				{ 'asteria--state-active': active },
			)}
		>
			<label className="asteria-component__label__wrapper">
				<div className="asteria-component__input__inner">
					<div className="asteria-component__input__wrapper">
						<textarea
							name={name}
							placeholder={placeholder}
							autoComplete="off"
							onFocus={handleFocus}
							onBlur={handleBlur}
							value={value}
							onChange={onChange}
							required={required}
						/>
					</div>
				</div>
			</label>
		</div>
	);
};

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
};

export default Textarea;
