import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import '../../styles.scss';

const View = React.memo((props) => {
	const { type, value, label } = props;

	return (
		<div className="asteria-component__forecaster-axis-prefix-item">
			<Text className="asteria--variant-label">
				{TranslationService.get(
					[
						'forecaster.x-axis.prefix.label',
						`forecaster.x-axis.prefix.${type}.label`,
					],
					undefined,
					{ value, label },
				)}
			</Text>
			<Text className="asteria--variant-amount" size="xs">
				{TranslationService.get(
					[
						'forecaster.x-axis.prefix.amount',
						`forecaster.x-axis.prefix.${type}.amount`,
					],
					undefined,
					{ value, label },
				)}
			</Text>
		</div>
	);
});

View.displayName = 'XAxisPrefixItem';
View.propTypes = {
	type: PropTypes.string,
	dates: PropTypes.arrayOf(PropTypes.string),
	category: PropTypes.string,
	tag: PropTypes.string,

	value: PropTypes.number,
	label: PropTypes.string,
};

export default View;
