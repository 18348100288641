import store from '@asteria/datalayer';

import getProbability from './getProbability';

function formatLink(link) {
	delete link.invoiceDetails;

	return link;
}

function formatTransaction(object) {
	const defaultCurrency =
		store.getState()?.app?.company?.settings?.currency ?? 'SEK';

	let links;

	if (object?.descriptionClient) {
		links = Array.from(object?.links ?? []);

		const index = links.findIndex(({ type }) => type === 'CLIENT');

		if (index === -1) {
			links.push({ type: 'CLIENT', id: object?.descriptionClient });
		} else {
			links[index] = { type: 'CLIENT', id: object?.descriptionClient };
		}

		links = links.map((object) => formatLink(object));
	}

	return {
		_id: object?._id,
		description: object?.description,
		probability: object?.probability,
		total: object?.total,
		currency: object?.currency ?? defaultCurrency,
		paymentDate: object?.paymentDate,
		links: links,
	};
}

function formatChanges(changes) {
	const data = changes.reduce((acc, object) => {
		const type = object?.type;
		const category = object?.category;
		const tag = object?.tag;

		return {
			...acc,
			[type]: {
				...acc?.[type],
				[category]: {
					...acc?.[type]?.[category],
					[tag]: []
						.concat(acc?.[type]?.[category]?.[tag] ?? [])
						.concat(object),
				},
			},
		};
	}, {});

	return Object.entries(data).flatMap(([type, categories]) =>
		Object.entries(categories).flatMap(([category, tags]) =>
			Object.entries(tags).map(([tag, transactions]) => {
				return {
					type: type.toUpperCase(),
					categoryName: category,
					tagName: tag,
					total: transactions.reduce(
						(acc, { total }) => acc + total,
						0,
					),
					probability: getProbability(
						transactions.map(({ total, probability }) => ({
							value: total,
							probability: probability,
						})),
					),
					transactions: transactions.reduce(
						(acc, object) => {
							const fmt = formatTransaction(object);

							if (!object?.total || object?.$deleted) {
								acc.DELETE.push(fmt);
							} else if (object._id) {
								acc.UPDATE.push(fmt);
							} else {
								acc.CREATE.push(fmt);
							}

							return acc;
						},
						{ CREATE: [], UPDATE: [], DELETE: [] },
					),
				};
			}),
		),
	);
}

export default function formatRequest(form) {
	const data = Object.entries(form ?? {})
		.filter(([key]) => ['deposit', 'withdraw'].includes(key))
		.flatMap(([type, value]) =>
			Object.entries(value?.data ?? {})
				.filter(([, value]) => value?.FORECAST)
				.flatMap(([date, value]) =>
					Object.entries(value?.FORECAST?.categories ?? {}).flatMap(
						([category, value]) =>
							Object.entries(value?.tags ?? {}).flatMap(
								([tag, value]) =>
									Object.values(
										value?.transactions ?? {},
									).map((object) => ({
										...object,
										type: type,
										date: date,
										category: category,
										tag: tag,
									})),
							),
					),
				),
		)
		.reduce(
			(acc, object) => ({
				...acc,
				[object?.date]: []
					.concat(acc?.[object?.date] ?? [])
					.concat(object),
			}),
			{},
		);

	return Object.entries(data).map(([date, changes]) => ({
		date: date,
		changes: formatChanges(changes),
	}));
}
