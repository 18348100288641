import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { addMonths, formatISO, subMonths } from 'date-fns';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Line, LineChart, XAxis } from 'recharts';

import { parseDate } from '@asteria/utils-funcs/normalize';

import { useInitiatingIntegration, useRequiredIntegrations } from '../../hooks';
import BasicCard from '../basic';

import { ChartContainer, ChartTooltip, ChartTooltipContent } from './bars';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'up' | 'down' } direction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useRequest({ onSubmit, startDate, endDate }) {
	return useQuery({
		queryKey: ['card', 'cashflow', { startDate, endDate }],
		queryFn: async () => {
			const previous = await onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});

			const current = await onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});

			const next = await onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: formatISO(addMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(addMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});

			const incoming = await onSubmit?.('card:fetch', {
				type: 'incoming',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});

			const outgoing = await onSubmit?.('card:fetch', {
				type: 'outgoing',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});

			return {
				account: {
					previous,
					current,
					next,
				},
				incoming,
				outgoing,
			};
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,
	});
}

/** @type { React.FC<Props> } */
const CashflowCard = React.memo(function CashflowCard(props) {
	const { startDate, endDate, onSubmit } = props;

	const query = useRequest({
		onSubmit,
		startDate,
		endDate,
	});

	const { required, optional } = useRequiredIntegrations({
		required: 'bank',
		optional: 'erp',
	});

	const initiating = useInitiatingIntegration({ type: 'bank' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	const chartData = React.useMemo(
		() => [
			{
				month: 'January',
				deposit: query?.data?.incoming?.source?.PAID?.total ?? 0,
				withdraw: Math.abs(
					query?.data?.outgoing?.source?.PAID?.total ?? 0,
				),
			},
		],
		[query.data],
	);

	const chartConfig = React.useMemo(
		() => ({
			deposit: {
				label: 'Deposit',
				color: 'rgba(130, 208, 135, 1)',
			},
			withdraw: {
				label: 'Withdraw',
				color: 'rgba(206, 140, 130, 1)',
			},
		}),
		[],
	);

	const lineChartData = React.useMemo(
		() => [
			{
				month: 'December',
				actual:
					query?.data?.account?.previous?.source?.PAID?.total ?? 0,
				forecast:
					query?.data?.account?.previous?.source?.FORECAST?.total ??
					0,
			},
			{
				month: 'January',
				actual: query?.data?.account?.current?.source?.PAID?.total ?? 0,
				forecast:
					query?.data?.account?.current?.source?.FORECAST?.total ?? 0,
			},
			{
				month: 'February',
				actual: query?.data?.account?.next?.source?.PAID?.total ?? 0,
				forecast:
					query?.data?.account?.next?.source?.FORECAST?.total ?? 0,
			},
		],
		[query.data],
	);

	const lineChartConfig = React.useMemo(
		() => ({
			actual: {
				label: 'Actual',
				color: 'rgba(130, 208, 135, 1)',
			},
			forecast: {
				label: 'Forecast',
				color: 'rgba(206, 140, 130, 1)',
			},
		}),
		[],
	);

	return (
		<BasicCard
			{...props}
			type="cashflow"
			query={query}
			integrations={integrations}
			connect={{ type: 'bank', onboarding: 'erp' }}
		>
			<ChartContainer config={lineChartConfig}>
				<LineChart
					accessibilityLayer
					data={lineChartData}
					margin={{
						left: 12,
						right: 12,
					}}
				>
					<CartesianGrid vertical={false} />
					<XAxis
						dataKey="month"
						tickLine={false}
						axisLine={false}
						tickMargin={8}
						tickFormatter={(value) => value.slice(0, 3)}
					/>
					<ChartTooltip
						cursor={false}
						content={<ChartTooltipContent />}
					/>
					<Line
						dataKey="actual"
						type="monotone"
						stroke="rgba(130, 208, 135, 1)"
						strokeWidth={2}
						dot={false}
					/>
					<Line
						dataKey="forecast"
						type="monotone"
						stroke="rgba(206, 140, 130, 1)"
						strokeWidth={2}
						dot={false}
					/>
				</LineChart>
			</ChartContainer>
			<ChartContainer config={chartConfig}>
				<BarChart accessibilityLayer data={chartData}>
					<CartesianGrid vertical={false} />
					<XAxis
						dataKey="month"
						tickLine={false}
						tickMargin={10}
						axisLine={false}
						tickFormatter={(value) => value.slice(0, 3)}
					/>
					<ChartTooltip
						cursor={false}
						content={
							<ChartTooltipContent
								className="bg-white"
								indicator="dashed"
							/>
						}
					/>
					<Bar
						dataKey="deposit"
						fill="var(--color-deposit)"
						radius={4}
					/>
					<Bar
						dataKey="withdraw"
						fill="var(--color-withdraw)"
						radius={4}
					/>
				</BarChart>
			</ChartContainer>
		</BasicCard>
	);
});

CashflowCard.displayName = 'CashflowCard';

CashflowCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default CashflowCard;
