import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useBulkFeatures } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

const SettingsButton = () => {
	const dispatch = useDispatch();
	const { getValues } = useFormContext();

	const hasSettingsFeature = useBulkFeatures([
		'forecaster-scenarios',
		'forecaster-settings-reset',
		'forecaster-show-history-backdrop',
	]);

	const onSettingsShow = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ForecasterSettings,
				data: {
					form: getValues(),
				},
			}),
		);
	}, [dispatch, getValues]);

	if (!hasSettingsFeature) {
		return null;
	}

	return <Button icon="cog" onClick={onSettingsShow} />;
};

SettingsButton.displayName = 'SettingsButton';

SettingsButton.propTypes = { className: PropTypes.string };

export default SettingsButton;
