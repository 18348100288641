import React from 'react';

import IntegrationsView from '@asteria/view-onboarding';

import { LayoutContext } from '../../context';

import './styles.scss';

const OnboardingPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<IntegrationsView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

OnboardingPage.displayName = 'OnboardingPage';

OnboardingPage.propTypes = {};

export default OnboardingPage;
