import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import { Wrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import { MessageBoxToggle } from '@asteria/component-support/MessageBox';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

import CurrencySelector from './currency';
import ScenarioSection from './scenario';

const RemoveButton = React.memo((props) => {
	const { onAction } = props;

	const [loading, setLoading] = React.useState(false);

	const onRemoveSubmit = React.useCallback(async () => {
		setLoading(true);

		try {
			await onAction?.('company:remove');
		} catch (err) {
			//
		}

		setLoading(false);
	}, [onAction]);

	const toggle = React.useMemo(
		() => ({
			label: TranslationService.get(['settings.company.remove']),
			iconTooltip: TranslationService.get([
				'settings.company.remove.tooltip',
			]),
			variant: 'link',
			icon: 'help',
			iconPosition: 'last',
		}),
		[],
	);

	return (
		<MessageBoxToggle
			toggle={toggle}
			analyticsKey="settings.company.remove.message-box"
			scrollIntoView
		>
			{({ onClose }) => [
				<Content key="content">
					<Text>
						{TranslationService.get([
							'settings.company.remove.confirm',
						])}
					</Text>
				</Content>,
				<Footer key="footer">
					<FooterSection position="first">
						<Button
							variant="tertiary"
							size="sm"
							label={TranslationService.get([
								'action.abort',
								'action.cancel',
							])}
							onClick={onClose}
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							size="sm"
							label={TranslationService.get([
								'action.submit',
								'action.remove',
							])}
							onClick={onRemoveSubmit}
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>,
			]}
		</MessageBoxToggle>
	);
});

RemoveButton.displayName = 'RemoveButton';
RemoveButton.propTypes = { onAction: PropTypes.func };

const CompanySettings = (props) => {
	const { error, onAction, extra, active } = props;

	const dispatch = useDispatch();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'scenario:open') {
				return dispatch(
					ModalStore.open({
						type: data?.page,
						data: { data: data?.data },
					}),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);

	return (
		<Wrapper>
			<Content>
				{error && (
					<Alert level="error">
						<Text>{error}</Text>
					</Alert>
				)}

				<FeatureFlag feature="settings-company-currency">
					<CurrencySelector prefix="company" />
				</FeatureFlag>
				<FeatureFlag feature="settings-scenario-selection">
					<ScenarioSection onAction={handleAction} />
				</FeatureFlag>
				<Input
					type="text"
					name="company.name"
					label={TranslationService.get('signup.company.label')}
					placeholder={TranslationService.get(
						'signup.company.placeholder',
					)}
					// required
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.vat"
					label={TranslationService.get('signup.vatNumber.label')}
					placeholder={TranslationService.get(
						'signup.vatNumber.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.orgnumber"
					label={TranslationService.get(
						'signup.organizationNumber.label',
					)}
					placeholder={TranslationService.get(
						'signup.organizationNumber.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.contact.phone"
					label={TranslationService.get('signup.contact.phone.label')}
					placeholder={TranslationService.get(
						'signup.contact.phone.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.contact.zipCode"
					label={TranslationService.get(
						'signup.contact.zipCode.label',
					)}
					placeholder={TranslationService.get(
						'signup.contact.zipCode.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="email"
					name="company.contact.email"
					label={TranslationService.get('signup.contact.email.label')}
					placeholder={TranslationService.get(
						'signup.contact.email.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.contact.url"
					label={TranslationService.get('signup.contact.url.label')}
					placeholder={TranslationService.get(
						'signup.contact.url.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.contact.address"
					label={TranslationService.get(
						'signup.contact.address.label',
					)}
					placeholder={TranslationService.get(
						'signup.contact.address.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.contact.contactFirstName"
					label={TranslationService.get(
						'signup.contact.contactFirstName.label',
					)}
					placeholder={TranslationService.get(
						'signup.contact.contactFirstName.placeholder',
					)}
					direction="vertical"
				/>
				<Input
					type="text"
					name="company.contact.contactLastName"
					label={TranslationService.get(
						'signup.contact.contactLastName.label',
					)}
					placeholder={TranslationService.get(
						'signup.contact.contactLastName.placeholder',
					)}
					direction="vertical"
				/>
				{extra}
				<FeatureFlag feature="settings-company-remove">
					<RemoveButton key={active} onAction={onAction} />
				</FeatureFlag>
			</Content>
		</Wrapper>
	);
};

CompanySettings.propTypes = {
	error: PropTypes.string,
	active: PropTypes.string,
	onAction: PropTypes.func,
	extra: PropTypes.node,
};

export default CompanySettings;
