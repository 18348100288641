import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Floater = (props) => {
	const { className, position, horizontalAlign, verticalAlign, children } =
		props;

	return (
		<div
			className={cn('asteria-component__float', className, {
				[`asteria-component__float--position-${position}`]: position,
				[`asteria-component__float--vertical-align-${verticalAlign}`]:
					verticalAlign,
				[`asteria-component__float--horizontal-align-${horizontalAlign}`]:
					horizontalAlign,
			})}
		>
			{children}
		</div>
	);
};

Floater.propTypes = {
	className: PropTypes.string,
	position: PropTypes.oneOf(['fixed', 'absolute']),
	horizontalAlign: PropTypes.oneOf(['left', 'right', 'center']),
	verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
	children: PropTypes.node,
};

Floater.defaultProps = {
	horizontalAlign: 'right',
	verticalAlign: 'bottom',
	position: 'absolute',
};

export default Floater;
