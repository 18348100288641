import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon, { iconClasses } from '@asteria/component-core/icon';
import { Text, Title } from '@asteria/component-core/typography';
import { positionClasses, sizeClasses } from '@asteria/component-core/utils';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import ComponentService from '@asteria/component-tools/contenter/service';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import CompleteScreen from './CompleteScreen';

import './styles.scss';

const Alert = (props) => {
	const {
		className,
		level,
		title,
		children,
		icon,
		iconPosition = 'first',
		onDismiss,
		actions: $actions,
	} = props;

	const actions = ($actions ?? []).filter(Boolean);

	return (
		<div
			className={cn(
				'asteria-component__alert',
				className,
				iconClasses('asteria-component__alert', props),
				sizeClasses(props),
				{
					[`asteria-component__alert--level-${level}`]: level,
					'asteria-component__alert--no-icon': !icon,
					'asteria-component__alert--no-title': !title,
				},
			)}
		>
			<Wrapper
				border={false}
				className="asteria-component__alert__wrapper"
			>
				{title && (
					<Header
						className="asteria-component__alert__wrapper-header"
						onClose={onDismiss}
						postfix={{ size: 'xs' }}
					>
						{icon ? (
							<Icon
								icon={icon}
								size="sm"
								className={positionClasses({
									position: iconPosition,
								})}
							/>
						) : null}
						{typeof title === 'string' ? (
							<Title size="sm" weight="bold">
								{TranslationService.get(title, title)}
							</Title>
						) : (
							title
						)}
					</Header>
				)}
				<Content className="asteria-component__alert__wrapper-content">
					{icon && !title ? (
						<Icon
							icon={icon}
							size="sm"
							className={positionClasses({
								position: iconPosition,
							})}
						/>
					) : null}
					{typeof children === 'string' ? (
						<Text size="sm">{children}</Text>
					) : (
						children
					)}
				</Content>
				{actions?.length ? (
					<Footer className="asteria-component__alert__wrapper-footer">
						<FooterSection>
							{actions.map((props, index) => (
								<Button key={index} size="sm" {...props} />
							))}
						</FooterSection>
					</Footer>
				) : null}
			</Wrapper>
		</div>
	);
};

Alert.propTypes = {
	className: PropTypes.string,
	level: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
	title: PropTypes.string,
	children: PropTypes.node,
	icon: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	actions: PropTypes.arrayOf(PropTypes.object),

	onDismiss: PropTypes.func,
};

Alert.displayName = 'Alert';

ComponentService.register('Alert', Alert, {
	className: { type: 'string' },
	level: { type: 'enum', options: ['info', 'warning', 'error', 'success'] },
	title: { type: 'string' },
	children: { type: 'node' },
	icon: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },
	actions: { type: 'array', of: { type: 'object' } },
	onDismiss: { type: 'function' },
});

ComponentService.register('CompleteScreen', CompleteScreen, {
	className: { type: 'string' },
	level: { type: 'enum', options: ['info', 'warning', 'error', 'success'] },
	title: { type: 'string' },
	children: { type: 'node' },
	icon: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },
	actions: { type: 'array', of: { type: 'object' } },
});

export default React.memo(Alert);
export { CompleteScreen };
