import {
	endOfMonth,
	format,
	formatISO,
	parseISO,
	startOfMonth,
} from 'date-fns';

import store from '@asteria/datalayer';
import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { decode } from '@asteria/utils-funcs/jwt';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';

import * as AccountsAPI from './accounts';
import * as CashflowAPI from './cashflow';
import * as ClientsAPI from './clients';
import * as CompanyAPI from './company';
import * as CurrenciesAPI from './currencies';
import * as IntegrationsAPI from './integrations';
import * as ScenarioAPI from './scenario';
import * as TagsAPI from './tags';
import * as TransactionsAPI from './transactions';
import * as UserAPI from './user';

export async function fetch({ accessToken, dispatch }) {
	const options = { accessToken: accessToken, dispatch: dispatch };

	return Promise.all([
		UserAPI.me(options)
			.then((response) => {
				const companyId = decode(accessToken)?.companyId ?? null;

				dispatch(AppStore.setUserCompanyId(companyId));

				return response;
			})
			.then((data) => {
				const startDate = formatISO(startOfMonth(new Date()), {
					representation: 'date',
				});

				const dates = store.getState()?.graph?.data?.range ?? [];
				const timeSize = store.getState()?.app?.timesize ?? 'month';

				const endDate = dates?.length
					? format(
							TimesizeUtils.endOfTime(
								parseISO(startDate),
								timeSize,
							),
							'yyyy-MM-dd',
					  )
					: formatISO(endOfMonth(new Date()), {
							representation: 'date',
					  });

				return Promise.all([
					CashflowAPI.fetch({
						startDate: startDate,
						endDate: `${endDate}T23:59:59.999Z`,
						scenarioId: data?.settings?.flags?.scenarioId,
						accessToken: accessToken,
						dispatch: dispatch,
						skipLoading: true,
					}),
					CashflowAPI.statistics({
						scenarioId: data?.settings?.flags?.scenarioId,
						accessToken: accessToken,
						dispatch: dispatch,
					}),
				]);
			}),
		CompanyAPI.fetch(options).then((data) => {
			TranslationService.updateGlobals({ company: data });

			return data;
		}),
		AccountsAPI.fetch(options),
		IntegrationsAPI.fetch(options),
		ScenarioAPI.fetch(options),
		TagsAPI.fetch(options),
		ClientsAPI.fetch(options),
		TransactionsAPI.currencies(options),
		CurrenciesAPI.fetch(options),
	]);
}
