import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';
import { Content } from '@asteria/component-core/wrapper';

import List, { ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo, useMemoizeHook } from '@asteria/utils-hooks/useDeep';

import { Context } from '../../context';

import TransactionFilters from './Filters';
import TransactionGroup from './Group';

import './styles.scss';

const TransactionList = React.memo(
	React.forwardRef((props, ref) => {
		const {
			className,
			onScroll,
			onAction,
			groups,
			compact,
			style,
			loading,
			startsWith,
		} = props;

		return (
			<Content
				className={cn(
					'asteria-component__transactions__list',
					{
						'asteria--state-compact': compact,
						[`asteria--startsWith-${startsWith}`]: startsWith,
					},
					className,
				)}
				style={style}
				onScroll={onScroll}
				ref={ref}
			>
				<List size={compact ? 'sm' : undefined}>
					<TransactionFilters onAction={onAction} />
					{groups?.length ? (
						groups.map((group, index, array) => (
							<TransactionGroup
								key={group.id}
								loading={array.length - 1 === index && loading}
								onAction={onAction}
								group={group}
								ref={ref}
							/>
						))
					) : loading ? (
						<TransactionGroup
							loading={loading}
							onAction={onAction}
						/>
					) : (
						<ListItem className="asteria--state-empty">
							<Text>
								{TranslationService.get(
									'transactions.list.empty',
								)}
							</Text>
						</ListItem>
					)}
				</List>
			</Content>
		);
	}),
);

TransactionList.displayName = 'TransactionList';

TransactionList.propTypes = {
	className: PropTypes.string,

	onScroll: PropTypes.func,
	onAction: PropTypes.func,

	groups: PropTypes.arrayOf(PropTypes.object),
	style: PropTypes.object,
	compact: PropTypes.bool,
	loading: PropTypes.bool,
	startsWith: PropTypes.string,
};

const TransactionListProvider = React.forwardRef((props, ref) => {
	const ctx = React.useContext(Context);
	const { useGroups } = ctx;

	const { visible, compact, loading, size, sorting, reverse } = useDeepMemo(
		() => ({
			visible: ctx?.columns?.visible,
			compact: ctx?.compact,
			loading: ctx?.loading,
			size: ctx?.size,
			sorting: ctx?.sorting,
			reverse: ctx?.reverse,
		}),
		[
			ctx?.columns?.visible,
			ctx?.compact,
			ctx?.loading,
			ctx?.size,
			ctx?.sorting,
			ctx?.reverse,
		],
	);

	const groups = useMemoizeHook(useGroups, {
		size: size,
		sorting: sorting,
		reverse: reverse,
	});

	const style = useDeepMemo(() => {
		return {
			'--count': visible.length,
			'--columns': visible.includes('description')
				? visible
						.map((type) =>
							type === 'description'
								? `var(--column-description-size)`
								: `var(--column-size)`,
						)
						.join(' ')
				: visible
						.map((type) =>
							type === 'options' ? `var(--column-size)` : '1fr',
						)
						.join(' '),
		};
	}, [visible]);

	return (
		<TransactionList
			{...props}
			ref={ref}
			groups={groups}
			compact={compact}
			style={style}
			loading={loading}
			startsWith={visible?.[0]}
		/>
	);
});

TransactionListProvider.displayName = 'TransactionListProvider';

TransactionListProvider.propTypes = {
	className: PropTypes.string,

	onScroll: PropTypes.func,
	onAction: PropTypes.func,
};

export default TransactionListProvider;
