import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

/**
 * @typedef { import('./types').Props } Props
 */

const ICON = {
	up: 'arrow-up-right',
	down: 'arrow-down-right',
	equal: 'arrow-straight',
};

/** @type { React.FC<Props> } */
const Trends = React.memo(function Trends(props) {
	const { className, direction, size, extra } = props;

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="time.selector.trends.tooltip"
					translationOptions={extra}
					Component={Text}
				/>
			}
		>
			<Icon
				className={className}
				icon={ICON[direction] ?? 'arrow-straight'}
				size={size}
			/>
		</TooltipWrapper>
	);
});

Trends.displayName = 'Trends';

Trends.propTypes = {
	className: PropTypes.string,
	direction: PropTypes.oneOf(['up', 'down'], 'equal'),
	size: PropTypes.string,
	extra: PropTypes.object,
};

export default Trends;
