import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

import useTagColors from '../transactions/hooks/useTagColors';

const TagChip = React.memo((props) => {
	const {
		tag,
		type,
		status: propStatus,
		active,
		icon,
		variant,
		dismiss,
		size,
		onClick,
		stopPropagation,
		tooltip,
		dismissTooltip,
		analytics,
		analyticsKey,
	} = props;

	const { name, category } = tag || {};
	const { name: categoryName } = category || {};

	const label = useMemo(() => {
		if (categoryName === `$custom`) {
			return name;
		}

		return TranslationService.get([
			`tags.${name?.replace('$', '')}`,
			`tags.${categoryName?.replace('$', '')}.${name?.replace('$', '')}`,
		]);
	}, [categoryName, name]);

	const colors = useTagColors({ tag: tag, status: propStatus, type: type });

	const onChipClick = useCallback(
		(event) => {
			if (stopPropagation) {
				event?.stopPropagation();
			}
			onClick?.(tag);
		},
		[stopPropagation, onClick, tag],
	);

	return (
		<TooltipWrapper {...tooltip}>
			<Chip
				className="asteria-component__chip-tag"
				colors={colors}
				label={label}
				active={active}
				onClick={onClick ? onChipClick : null}
				icon={icon}
				variant={variant}
				size={size}
				dismiss={dismiss}
				dismissTooltip={dismissTooltip}
				analytics={analytics}
				analyticsKey={analyticsKey}
			/>
		</TooltipWrapper>
	);
});

TagChip.displayName = 'TagChip';

TagChip.propTypes = {
	tag: PropTypes.object,
	tooltip: PropTypes.object,
	colors: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	status: PropTypes.string,
	type: PropTypes.string,
	active: PropTypes.bool,
	onClick: PropTypes.func,

	icon: PropTypes.string,
	analytics: PropTypes.string,
	analyticsKey: PropTypes.string,
	variant: PropTypes.string,
	dismiss: PropTypes.bool,
	dismissTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	stopPropagation: PropTypes.bool,
	size: SizeProp(),
};

export default TagChip;
