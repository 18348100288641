import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
} from '@asteria/component-core/pageHeader';

import Notifications from '@asteria/component-notifications';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { cn } from '@asteria/utils-funcs/classes';

import ForecasterHeaderTextGroup from './ForecasterHeaderTextGroup';
import HeaderLinear from './HeaderLinear';
import HeaderTitle from './title';

import './styles.scss';

const HeaderSimple = React.memo((props) => {
	const { onAction } = props;

	return (
		<PageHeader>
			<PageContent>
				<HeaderTitle />
			</PageContent>
			<PagePostfix>
				<ForecasterHeaderTextGroup />
				<FeatureFlag feature="widget-notifications">
					<FeatureFlag feature="forecaster-header-notifications">
						<Notifications onAction={onAction} />
					</FeatureFlag>
				</FeatureFlag>
				<FeatureFlag feature="page-header-tour">
					<TourButton />
				</FeatureFlag>
			</PagePostfix>
		</PageHeader>
	);
});

HeaderSimple.displayName = 'HeaderSimple';
HeaderSimple.propTypes = { onAction: PropTypes.func };

const Header = (props) => {
	const { onAction } = props;

	const fullScreen = useSelector(ForecasterStore.selectors.flags.fullScreen);

	const hasHeaderOutsideFeature = useFeature('forecaster-header-outside');

	const hasNavigationSectionFeature = useFeature(
		'forecaster-navigation-sections',
	);

	return (
		<div
			className={cn('asteria-component__forecaster-header', {
				'asteria--feature-section-navigation':
					hasNavigationSectionFeature,
			})}
		>
			<PageHeader>
				<PageContent>
					{!hasHeaderOutsideFeature && !fullScreen ? (
						<HeaderTitle />
					) : null}
				</PageContent>

				{!hasHeaderOutsideFeature && !fullScreen ? (
					<PagePostfix>
						<ForecasterHeaderTextGroup />
						<FeatureFlag feature="widget-notifications">
							<FeatureFlag feature="forecaster-header-notifications">
								<Notifications onAction={onAction} />
							</FeatureFlag>
						</FeatureFlag>
						<FeatureFlag feature="page-header-tour">
							<TourButton />
						</FeatureFlag>
					</PagePostfix>
				) : null}
			</PageHeader>
		</div>
	);
};

Header.displayName = 'Header';

Header.propTypes = {
	onAction: PropTypes.func,
};

const HeaderWrapper = (props) => {
	return (
		<>
			<FeatureFlag feature="forecaster-header-linear">
				<HeaderLinear {...props} />
			</FeatureFlag>
			<FeatureFlag feature="forecaster-header-linear" invert>
				<Header {...props} />
			</FeatureFlag>
		</>
	);
};

HeaderWrapper.displayName = 'HeaderWrapper';

HeaderWrapper.propTypes = {
	onAction: PropTypes.func,
};

export default React.memo(HeaderWrapper);
export { HeaderSimple };
