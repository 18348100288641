import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { useLoading, useScrollable } from '../hooks';

import './styles.scss';

/**
 * @type { React.FC<Partial<{ className: string, scroll: boolean, loading: boolean, children: React.ReactNode }>> }
 */
const Content = React.forwardRef(function Content(props, ref) {
	const {
		className,
		scroll: $scroll,
		loading: $loading,
		children,
		...rest
	} = props;

	const scroll = useScrollable($scroll);
	const loading = useLoading($loading);

	return (
		<div
			className={cn(
				'asteria-component__wrapper-content',
				{
					'asteria--state-loading': loading,
					'asteria--state-scroll': scroll,
				},
				className,
				{
					'asteria-component__wrapper-content--scroll': scroll,
				},
			)}
			ref={ref}
			{...rest}
		>
			{typeof children === 'function'
				? children({ loading, scroll })
				: children}
		</div>
	);
});

Content.displayName = 'WrapperContent';

Content.propTypes = {
	scroll: PropTypes.bool,
	loading: PropTypes.bool,

	className: PropTypes.string,
	children: PropTypes.node,
};

export default React.memo(Content);
