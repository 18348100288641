import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const SkeletonLoader = ({ className }) => {
	return (
		<div
			className={cn(
				className,
				'asteria-transaction-group',
				'asteria-transaction-group__loading',
			)}
		>
			<div className="asteria-transaction-group__loading__row">
				<div className="asteria-transaction-group__loading__column">
					<span className="skeleton-box"></span>
				</div>
				<div className="asteria-transaction-group__loading__column">
					<span className="skeleton-box"></span>
					<span className="skeleton-box"></span>
				</div>
				<div className="asteria-transaction-group__loading__column">
					<span className="skeleton-box"></span>
					<span className="skeleton-box"></span>
					<span className="skeleton-box"></span>
				</div>
				<div className="asteria-transaction-group__loading__column">
					<span className="skeleton-box"></span>
				</div>
			</div>
		</div>
	);
};

SkeletonLoader.propTypes = {
	className: PropTypes.string,
	progress: PropTypes.number,
};

SkeletonLoader.displayName = 'SkeletonLoader';

export default SkeletonLoader;
