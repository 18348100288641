import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import Loader from '../../components/Loader';
import Wizard from '../../components/Wizard';

const QuizPage = (props) => {
	const { onAction, name, wizard } = props;
	const importing = useSelector(IntegrationStore.selectors.isLoading);

	return [
		importing ? <Loader key="loader" variant="category" /> : null,
		<Wizard key="wizard" name={name} wizard={wizard} onAction={onAction} />,
	];
};

QuizPage.displayName = 'QuizPage';

QuizPage.propTypes = {
	onAction: PropTypes.func,
	name: PropTypes.string,
	wizard: PropTypes.string,
};

export default QuizPage;
