import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Feedback from '@asteria/component-feedback';

import { setUserSettingsFlags } from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';

import WelcomeContext from '../../context';
import * as WelcomeSelectors from '../../selectors';

import './styles.scss';

const selectors = {
	showFeedback: createSelector(
		(store) => store?.app?.user?.settings?.flags?.showWelcomeFeedback,
		(value) => value ?? false,
	),
};

const WelcomeFeedback = (props) => {
	const { onAction } = props;

	const dispatch = useDispatch();

	const ctx = React.useContext(WelcomeContext);

	const isVisible = useSelector(
		(store) => WelcomeSelectors.isCompleted(store, { context: ctx }),
		(a, b) => isEqual(a, b),
	);

	const showFeedback = useSelector(selectors.showFeedback, (a, b) =>
		isEqual(a, b),
	);

	React.useEffect(() => {
		if (isVisible && !showFeedback) {
			dispatch(setUserSettingsFlags({ showWelcomeFeedback: true }));

			onAction?.('refreshUserSettings');
		}
	}, [dispatch, isVisible, onAction, showFeedback]);

	const handleSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	if (!showFeedback && !isVisible) {
		return null;
	}

	return (
		<Feedback
			className="asteria-view__welcome__feedback"
			onAction={onAction}
			onSupportRequest={handleSupportRequest}
			feedbackKey="welcome-feedback"
			description={TranslationService.get('welcome.feedback.description')}
			minText={TranslationService.get('welcome.feedback.min')}
			maxText={TranslationService.get('welcome.feedback.max')}
			values={[1, 2, 3, 4, 5]}
			size="lg"
			validate
			type="welcome"
		/>
	);
};

WelcomeFeedback.displayName = 'WelcomeFeedback';

WelcomeFeedback.propTypes = { onAction: PropTypes.func };

export default WelcomeFeedback;
