import React from 'react';

import PropTypes from 'prop-types';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useUnreadMessage } from '../hooks';

import './styles.scss';

const FloatButton = (props) => {
	const { className, onAction, onSubmit } = props;

	const unreadMessages = useUnreadMessage({
		onSubmit: onSubmit,
	});

	const handleClick = React.useCallback(() => {
		onAction?.('go', '/support');
	}, [onAction]);

	return (
		<div
			className={cn(
				'asteria-component__support-button-wrapper',
				className,
			)}
		>
			<Button
				icon="support"
				variant="secondary"
				size="lg"
				iconSize="md"
				onClick={handleClick}
				label={TranslationService.get('support.button.label')}
			>
				<Badge size="md">{unreadMessages}</Badge>
			</Button>
		</div>
	);
};

FloatButton.displayName = 'FloatButton';

FloatButton.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default FloatButton;
