import * as React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

const Navigation = (props) => {
	const { go, index, active } = props;

	const handleClick = React.useCallback(() => {
		Analytics.event('tour.navigation.go', {
			index: index + 1,
		});

		return go(index);
	}, [go, index]);

	return (
		<div
			className={cn('asteria-component__tour__navigation-dot', {
				'asteria-component__tour__navigation-dot--active': active,
			})}
			onClick={handleClick}
		/>
	);
};

Navigation.propTypes = {
	go: PropTypes.func,
	index: PropTypes.number,
	active: PropTypes.bool,
};

const Control = (props) => {
	const { action, onClick, onAction, ...rest } = props;

	const handleClick = React.useCallback(
		(event) => {
			if (onClick) {
				return onClick?.(event);
			}

			if (action) {
				return onAction?.(...[].concat(action));
			}
		},
		[action, onAction, onClick],
	);

	const label = React.useMemo(() => {
		if (props.label) {
			return TranslationService.get(props.label, props.label);
		}
	}, [props.label]);

	return <Button {...rest} label={label} onClick={handleClick} />;
};

Control.propTypes = {
	action: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	onClick: PropTypes.func,
	onAction: PropTypes.func,
	label: PropTypes.string,
};

function TourTooltip(props) {
	const {
		next,
		prev,
		close,
		goToStep,
		stepContent: { title, description, controls, disableNext, disablePrev },
		stepIndex,
		tooltipPosition,
		allSteps,

		onAction,
	} = props;

	const hasArrowFeature = useFeature('tour-show-arrow');

	const prevDisabled =
		disablePrev !== undefined ? disablePrev : stepIndex === 0;

	const nextDisabled =
		disableNext !== undefined
			? disableNext
			: stepIndex + 1 === allSteps.length;

	const handlePrevious = React.useCallback(() => prev?.(), [prev]);
	const handleNext = React.useCallback(() => next?.(), [next]);
	const handleClose = React.useCallback((event) => close?.(event), [close]);

	const HeaderPostfix = React.useMemo(
		() => ({ position: 'absolute', size: 'sm' }),
		[],
	);

	const analytics = React.useMemo(
		() => ({
			title: title,
			description: description,
			stepIndex: stepIndex + 1,
			steps: allSteps.length,
		}),
		[allSteps.length, description, stepIndex, title],
	);

	return (
		<Wrapper
			className={cn(
				'asteria-component__tour__tooltip',
				{
					'asteria-component__tour__tooltip--has-arrow':
						hasArrowFeature,
				},
				Array.from(
					new Set(
						[
							(tooltipPosition?.orientation ?? '').split('-')[0],
							tooltipPosition?.orientation,
						].filter(Boolean),
					),
				).map(
					(position) =>
						`asteria-component__tour__tooltip--position-${position}`,
				),
			)}
		>
			<Header onClose={handleClose} postfix={HeaderPostfix}>
				<Title size="sm">{TranslationService.get(title, title)}</Title>
			</Header>
			<Content>
				{typeof description === 'string' ? (
					<Translation
						Component={Text}
						translationKey={description}
						defaultText={description}
						size="sm"
					/>
				) : (
					description()
				)}
			</Content>
			{allSteps.length > 1 ? (
				<Footer>
					<FooterSection>
						<FeatureFlag feature="tour-navigation-link">
							<Button
								disabled={prevDisabled}
								onClick={handlePrevious}
								variant="link"
								label={TranslationService.get('tour.previous')}
								analyticsKey="tour.navigation.previous"
								analytics={analytics}
							/>
						</FeatureFlag>
						<FeatureFlag feature="tour-navigation-link" invert>
							<Button
								disabled={prevDisabled}
								onClick={handlePrevious}
								icon="chevron-left"
								analyticsKey="tour.navigation.previous"
								analytics={analytics}
							/>
						</FeatureFlag>
						<div className="asteria-component__tour__navigation">
							<FeatureFlag feature="tour-navigation-dots">
								<div className="asteria-component__tour__navigation-dots">
									{(allSteps ?? []).map((step, index) => (
										<Navigation
											key={index}
											go={goToStep}
											active={index === stepIndex}
											index={index}
										/>
									))}
								</div>
							</FeatureFlag>
							<FeatureFlag feature="tour-navigation-numbers">
								<Text size="sm">
									{stepIndex + 1} / {allSteps.length}
								</Text>
							</FeatureFlag>
						</div>
						<FeatureFlag feature="tour-navigation-link">
							<Button
								disabled={nextDisabled}
								onClick={handleNext}
								variant="link"
								label={TranslationService.get('tour.next')}
								analyticsKey="tour.navigation.next"
								analytics={analytics}
							/>
						</FeatureFlag>
						<FeatureFlag feature="tour-navigation-link" invert>
							<Button
								disabled={nextDisabled}
								onClick={handleNext}
								icon="chevron-right"
								analyticsKey="tour.navigation.next"
								analytics={analytics}
							/>
						</FeatureFlag>
						{controls?.length && (
							<div className="asteria-component__tour__actions">
								{controls.map((control, index) => (
									<Control
										key={index}
										{...control}
										onAction={onAction}
									/>
								))}
							</div>
						)}
					</FooterSection>
				</Footer>
			) : null}
		</Wrapper>
	);
}

TourTooltip.propTypes = {
	next: PropTypes.func,
	prev: PropTypes.func,
	close: PropTypes.func,
	goToStep: PropTypes.func,
	onAction: PropTypes.func,
	stepContent: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		controls: PropTypes.arrayOf(PropTypes.object),
		disableNext: PropTypes.bool,
		disablePrev: PropTypes.bool,
	}),
	stepIndex: PropTypes.number,
	allSteps: PropTypes.arrayOf(PropTypes.object),
	tooltipPosition: PropTypes.shape({
		orientation: PropTypes.oneOf([
			'east',
			'south',
			'west',
			'north',
			'center',
			'east-north',
			'east-south',
			'south-east',
			'south-west',
			'west-south',
			'west-north',
			'north-west',
			'north-east',
		]),
	}),
};

export { TourTooltip };
