import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const selectors = {
	hasReadStatementInfo: createSelector(
		(state) => state?.app?.user?.settings?.flags?.hasReadStatementInfo,
		(value) => value ?? false,
	),
};

const ApplyCredit = (props) => {
	const { className, activateCredit, onClose } = props;

	const hasReadStatementInfo = useSelector(selectors.hasReadStatementInfo);

	// const { dispatch } = useContext(DatalayerContext);
	// const [user = {}] = useStore('store-auth', ({ user: obj }) => obj);

	/*
	const onClose = useCallback(() => {
		dispatch(
			saveUser({
				...user,
				settings: {
					...(user?.settings || {}),
					flags: {
						...(user?.settings?.flags || {}),
						hasReadStatementInfo: true,
					},
				},
			}),
		);
	}, [dispatch, user]);
	*/

	const onAction = React.useCallback(
		(action) => {
			if (action === 'credit:apply') {
				return activateCredit?.();
			}

			if (action === 'credit:dismiss') {
				return onClose?.();
			}
		},
		[activateCredit, onClose],
	);

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	if (hasReadStatementInfo) {
		return null;
	}

	return (
		<Teaser
			className={cn(
				'asteria-component__statement-teaser',
				'asteria--variant-credit',
				className,
			)}
			path="pages.statement.teaser.credit"
			actions={actions}
			onAction={onAction}
		/>
	);
};

ApplyCredit.displayName = 'ApplyCredit';

ApplyCredit.propTypes = {
	className: PropTypes.string,
	activateCredit: PropTypes.func,
	onClose: PropTypes.func,
};

export default ApplyCredit;
