import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

const initialState = {
	translations: {},
	language: null,
	loaded: false,
};

export const languageSlice = createSlice({
	name: 'language',
	initialState: initialState,
	reducers: {
		setTranslations: (state, action) => {
			state.translations = action.payload;
		},
		setLanguage: (state, action) => {
			state.language = action.payload;
		},
		setTranslationsLoaded: (state, action) => {
			state.loaded = action.payload;
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { setTranslations, setLanguage, setTranslationsLoaded } =
	languageSlice.actions;

export default languageSlice.reducer;
