import React from 'react';

import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { Checkbox } from '@asteria/component-form';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SearchSelector = React.memo((props) => {
	const {
		className,
		label,
		toggle: $toggle,
		count,
		onAction,
		type,
		...$props
	} = props;

	const onReset = React.useCallback(
		(event) => {
			Analytics.event('search.selector.reset', { type: type });

			event.preventDefault();
			event.stopPropagation();

			return onAction?.('resetType', { type: type });
		},
		[onAction, type],
	);

	const toggle = React.useMemo(() => {
		const toggle = {
			...$toggle,
			className: cn(
				{ 'asteria--state-has-counter': count },
				$toggle?.className,
			),
			label: !count ? label : null,
			children: count ? (
				<Group
					verticalAlign="center"
					horizontalAlign="left"
					direction="horizontal"
				>
					<Text className="asteria-component__search-selector-count">
						{count > 9 ? '9+' : count}
					</Text>
					<Text>{label}</Text>
				</Group>
			) : null,
			icon: count ? 'search-clear' : 'triangle-down',
			iconActive: count ? 'search-clear' : 'triangle-up',
			onIconClick: count ? onReset : undefined,
			iconPosition: 'last',
			variant: 'search',
		};

		if (count) {
			toggle['active'] = true;
		}

		return toggle;
	}, [$toggle, count, label, onReset]);

	return (
		<Dropdown
			className={cn('asteria-component__search-selector', className)}
			scroll
			multi
			toggle={toggle}
			{...$props}
		/>
	);
});

SearchSelector.displayName = 'SearchSelector';
SearchSelector.propTypes = {
	className: PropTypes.string,

	label: PropTypes.string,
	toggle: PropTypes.object,
	count: PropTypes.func,
	onAction: PropTypes.func,
	type: PropTypes.string,
};

const SearchSelectorItem = React.memo((props) => {
	const { className, active, checked = active, ...$props } = props;

	return (
		<DropdownItem
			className={cn('asteria-component__search-selector-item', className)}
			prefix={
				<Group verticalAlign="center" horizontalAlign="center">
					<Checkbox uncontrolled checked={checked} />
				</Group>
			}
			active={active}
			{...$props}
		/>
	);
});

SearchSelectorItem.displayName = 'SearchSelectorItem';
SearchSelectorItem.propTypes = {
	className: PropTypes.string,
	active: PropTypes.bool,
	checked: PropTypes.bool,
};

export default SearchSelector;
export { SearchSelectorItem };
