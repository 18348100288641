import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const ActionBarSection = React.memo((props) => {
	const { className, children } = props;

	if (!children) {
		return null;
	}

	return (
		<div className={cn('asteria-component__actionbar__section', className)}>
			{children}
		</div>
	);
});

ActionBarSection.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ActionBarSection.displayName = 'ActionBarSection';

const ActionBarSectionPrefix = React.memo((props) => {
	const { className, children } = props;

	if (!children) {
		return null;
	}

	return (
		<ActionBarSection
			className={cn(
				'asteria-component__actionbar__section--prefix',
				className,
			)}
		>
			{typeof children === 'string' ? (
				<Text size="sm">{children}</Text>
			) : (
				children
			)}
		</ActionBarSection>
	);
});

ActionBarSectionPrefix.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ActionBarSectionPrefix.displayName = 'ActionBarSectionPrefix';

const ActionBarSectionContent = React.memo((props) => {
	const { className, title, content, extra } = props;

	if (!title && !content && !extra) {
		return null;
	}

	return (
		<ActionBarSection
			className={cn(
				'asteria-component__actionbar__section--content',
				className,
			)}
		>
			{title ? (
				<div className="asteria-component__actionbar__section__title">
					{typeof title === 'string' ? (
						<Title size="xs">{title}</Title>
					) : (
						title
					)}
				</div>
			) : null}

			{content ? (
				<div className="asteria-component__actionbar__section__content">
					{typeof content === 'string' ? (
						<Text size="sm">{content}</Text>
					) : (
						content
					)}
				</div>
			) : null}

			{extra ? extra : null}
		</ActionBarSection>
	);
});

ActionBarSectionContent.propTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	extra: PropTypes.node,
};

ActionBarSectionContent.displayName = 'ActionBarSectionContent';

const ActionBarSectionExtra = React.memo((props) => {
	const { className, children } = props;

	if (!children) {
		return null;
	}

	return (
		<ActionBarSection
			className={cn(
				'asteria-component__actionbar__section--extra',
				className,
			)}
		>
			{typeof children === 'string' ? (
				<Text size="sm">{children}</Text>
			) : (
				children
			)}
		</ActionBarSection>
	);
});

ActionBarSectionExtra.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ActionBarSectionExtra.displayName = 'ActionBarSectionExtra';

const ActionBarSectionActions = React.memo((props) => {
	const { className, actions: $actions, children } = props;

	const actions = ($actions ?? []).filter(Boolean);

	if (!actions?.length && !children) {
		return null;
	}

	return (
		<ActionBarSection
			className={cn(
				'asteria-component__actionbar__section--actions',
				className,
			)}
		>
			{actions.map((props, index) => (
				<Button key={index} {...props} />
			))}
			{children ? children : null}
		</ActionBarSection>
	);
});

ActionBarSectionActions.propTypes = {
	className: PropTypes.string,
	actions: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.node,
};

ActionBarSectionActions.displayName = 'ActionBarSectionActions';

export default ActionBarSection;

export {
	ActionBarSectionPrefix,
	ActionBarSectionContent,
	ActionBarSectionExtra,
	ActionBarSectionActions,
};
