import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import { Text, Title } from '@asteria/component-core/typography';

import { useMediaQuery } from '@asteria/component-tools/mediaquery';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Notification from './components/Notification';

import './styles.scss';

const selectors = {
	notifications: createSelector(
		(store) => store?.notifications?.notifications,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const NotificationTitle = React.memo(() => {
	const isMatched = useMediaQuery('(max-width: 1280px)', true);

	if (!isMatched) {
		return null;
	}

	return (
		<Title size="xs" className="asteria-component__notification__title">
			{TranslationService.get('notifications.title')}
		</Title>
	);
});

NotificationTitle.displayName = 'NotificationTitle';

const NotificationContent = React.memo((props) => {
	const { notifications, onAction } = props;

	return (
		<>
			<NotificationTitle />
			{notifications?.length ? (
				notifications.map((object) => {
					const analytics = {
						notificationKey: object?.notificationKey,
						title: object?.title,
						_id: object._id ?? object.id,
					};

					return (
						<DropdownItem
							noColors
							key={object._id ?? object.id}
							analyticsKey="notification.item"
							analytics={analytics}
						>
							<Notification {...object} onAction={onAction} />
						</DropdownItem>
					);
				})
			) : (
				<DropdownItem noColors analyticsKey="notification.item.empty">
					<Notification type="empty" onAction={onAction} />
				</DropdownItem>
			)}
		</>
	);
});

NotificationContent.displayName = 'NotificationContent';

NotificationContent.propTypes = {
	notifications: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

const NotificationWrapper = React.memo((props) => {
	const { label, forceOpen, className, onAction } = props;

	const notifications = useSelector(selectors.notifications);

	const NotificationToggle = React.useMemo(
		() => ({
			icon: 'notification',
			children: label ? <Text size="sm">{label}</Text> : null,
			tooltip: {
				tooltip: TranslationService.get([
					'notifications.title',
					'notifications.title.tooltip',
				]),
				variant: 'alt',
			},
		}),
		[label],
	);

	const available = React.useMemo(
		() =>
			(notifications ?? [])
				.filter((object) => object?.options?.badge)
				.map(({ id }) => id),
		[notifications],
	);

	const badge = React.useMemo(() => {
		if (available?.length) {
			if (available.length > 9) {
				return '9+';
			}

			return available.length;
		}

		return null;
	}, [available.length]);

	const handleOpen = React.useCallback(
		(isOpen) => {
			if (isOpen) {
				onAction?.('removeNotificationBadge', available);
			}
		},
		[available, onAction],
	);

	return (
		<BadgeWrapper
			badge={badge}
			size="sm"
			forceOpen
			className="asteria-component__notifications__wrapper"
		>
			<Dropdown
				toggle={NotificationToggle}
				className={cn('asteria-component__notifications', className)}
				placement="bottom-end"
				open={forceOpen}
				onOpen={handleOpen}
				title={TranslationService.get([
					'notifications.title',
					'notifications.dropdown.title',
				])}
				scroll
				multi
				analyticsKey="notifications"
			>
				<NotificationContent
					notifications={notifications}
					onAction={onAction}
				/>
			</Dropdown>
		</BadgeWrapper>
	);
});

NotificationWrapper.displayName = 'NotificationWrapper';

NotificationWrapper.propTypes = {
	forceOpen: PropTypes.bool,
	className: PropTypes.string,
	onAction: PropTypes.func,
	label: PropTypes.string,
};

export default NotificationWrapper;
export { Notification, NotificationContent };
