import { useContext, useMemo } from 'react';

function useContextSelector(Context, selector) {
	const ctx = useContext(Context);
	const value = selector?.(ctx);

	return useMemo(() => value, [value]);
}

export default useContextSelector;
