import React from 'react';

import PropTypes from 'prop-types';

import SnackbarList from './SnackbarList';

import './styles.scss';

const SnackbarWrapper = (props) => {
	const { onAction } = props;

	return (
		<div className="asteria-component__snackbar__wrapper">
			<SnackbarList onAction={onAction} position="top-start" />
			<SnackbarList onAction={onAction} position="top-center" />
			<SnackbarList onAction={onAction} position="top-end" />
			<SnackbarList onAction={onAction} position="middle-start" />
			<SnackbarList onAction={onAction} position="middle-center" />
			<SnackbarList onAction={onAction} position="middle-end" />
			<SnackbarList onAction={onAction} position="bottom-start" />
			<SnackbarList onAction={onAction} position="bottom-center" />
			<SnackbarList onAction={onAction} position="bottom-end" />
		</div>
	);
};

SnackbarWrapper.displayName = 'SnackbarWrapper';

SnackbarWrapper.propTypes = {
	onAction: PropTypes.func,
};

SnackbarWrapper.defaultProps = {};

export default SnackbarWrapper;
