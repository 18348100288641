import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { ListCell } from '@asteria/component-list';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { cn } from '@asteria/utils-funcs/classes';

import CurrencyCell from './CurrencyCell';
import DescriptionCell from './DescriptionCell';
import OptionsCell from './OptionsCell';
import PaymentDateCell from './PaymentDateCell';
import RiskCell from './RiskCell';
import SelectorCell from './SelectorCell';
import StatusCell from './StatusCell';
import TagsCell from './TagsCell';
import TotalCell from './TotalCell';

import './styles.scss';

const LoadingCell = () => {
	return (
		<div className="asteria-component__transactions__cell__content asteria--state-loading">
			<div className="asteria-component__transactions__skeleton" />
		</div>
	);
};

const Cell = {
	description: DescriptionCell,
	paymentDate: PaymentDateCell,
	tags: TagsCell,
	currency: CurrencyCell,
	status: StatusCell,
	risk: RiskCell,
	total: TotalCell,
	options: OptionsCell,
	selector: SelectorCell,
	loading: LoadingCell,
};

function formatType(type) {
	switch (type) {
		case 'description':
		case 'paymentDate':
		case 'status':
			return type;

		case 'currency':
			return 'sums.original.currency';

		case 'selector':
			return '_id';

		default:
			return null;
	}
}

const TransactionCell = React.memo((props) => {
	const { className, type, id, loading, onAction } = props;

	const data = useSelector((store) =>
		TransactionStore.selectors.field(store, {
			id: id,
			field: formatType(type),
		}),
	);

	const Content = loading ? Cell.loading : Cell[type] ?? null;

	if (!Content) {
		return null;
	}

	return (
		<ListCell
			className={cn(
				'asteria-component__transactions__cell',
				{
					[`asteria-component__transactions__cell--${type}`]: type,
				},
				className,
			)}
		>
			<Content data={data} onAction={onAction} />
		</ListCell>
	);
});

TransactionCell.displayName = 'TransactionCell';

TransactionCell.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
	loading: PropTypes.bool,

	onAction: PropTypes.func,
};

export default TransactionCell;
