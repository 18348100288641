import { AuthService, UserService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import { setUser } from '@asteria/datalayer/stores/app';

import Analytics from '@asteria/utils-analytics';

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export async function me(options = {}) {
	const { accessToken, dispatch } = options;

	const response = await AuthService.auth
		.me(
			{
				isBulk: true,
				fields: `
				_id
				id
				firstName
				lastName
				settings {
					layout
					flags
					compressList
					listColumns
					hasReadAdjustable
				}
				feedback
				roles
				authRecord

				companies {
					edges {
						node {
							_id
							name
						}
					}
				}
		`,
			},
			{ token: accessToken },
		)
		.then((node) => ({
			...node,
			companies: (node?.companies?.edges ?? []).map(({ node }) => node),
		}))
		.catch(() => {});

	//TODO: Clean this up in database ???
	if (response?.settings?.layout?.graph?.layout === 'flat') {
		response.settings.layout.graph.layout =
			response?.settings?.layout?.graph?.barLayout || 'grouped';
	}

	dispatch?.(setUser(response));

	return response;
}

export async function remove({ accessToken, id }) {
	await UserService.user.remove({ id: id }, { token: accessToken });

	localStorage.removeItem('wingsRefreshToken');
	localStorage.removeItem('wingsToken');

	const url = new URL('', window.location.origin);
	url.search = window.location.search;

	window.location.replace(url.toString());
}

export async function updateSettings({ accessToken, id, settings, dispatch }) {
	try {
		await UserService.user.update(
			{ fields: `ok error`, id: id, input: { settings: settings } },
			{ token: accessToken },
		);

		return me({ accessToken: accessToken, dispatch: dispatch });
	} catch (e) {
		// Do nothing
	}
}

export async function refreshSettings({ accessToken, dispatch }) {
	const user = store.getState()?.app?.user;

	const userId = user?.id ?? null;
	const settings = user?.settings ?? {};

	Analytics.event('settings.refresh', {
		user: user,
		userId: userId,
		data: settings,
	});

	return updateSettings({
		accessToken: accessToken,
		id: userId,
		settings: settings,
		dispatch: dispatch,
	});
}

export async function update({ accessToken, id, settings, dispatch }) {
	await UserService.user.update(
		{ fields: `ok error`, id: id, input: settings },
		{ token: accessToken },
	);

	return me({ accessToken: accessToken, dispatch: dispatch });
}
