import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text, TextGroup } from '@asteria/component-core/typography';

import { Checkbox } from '@asteria/component-form';
import { ListCell, ListItem } from '@asteria/component-list';

import { Translation } from '@asteria/language';

import * as selectors from './selectors';

/** @type { React.FC<{ id: string }> } */
const Account = React.memo(function Account({ id }) {
	const account = useSelector(
		(store) => selectors.account(store, id),
		(a, b) => isEqual(a, b),
	);

	return (
		<ListItem>
			<ListCell className="flex-1 truncate">
				<Checkbox
					key={`bankAccount.${id}.active`}
					name={`bankAccount.${id}.active`}
					className="truncate"
					label={
						<TextGroup>
							<Translation
								translationKey="accounts.details.title"
								defaultText="{{ name }}"
								data={account}
								Component={Text}
								size="sm"
								className="truncate"
							/>
							<Translation
								translationKey="accounts.details.content"
								defaultText="{{ identifiers.number }}"
								data={account}
								Component={Text}
								size="xs"
								className="truncate"
							/>
						</TextGroup>
					}
					iconPosition="first"
				/>
			</ListCell>
			<ListCell>
				<Translation
					translationKey="accounts.details.total"
					defaultText="{{ sums.original.total | number:false:false:true }}"
					data={account}
					Component={Text}
					size="sm"
				/>
			</ListCell>
		</ListItem>
	);
});

Account.propTypes = { id: PropTypes.string };

export default Account;
