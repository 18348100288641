import {
	formatTag,
	getCompletedTags,
} from '@asteria/component-forecaster/utils';

export function groupCompleted(data) {
	return (getCompletedTags(data) ?? []).reduce((acc, object) => {
		const type = object?.type;
		const categoryName = object?.categoryName;
		const tagName = object?.tagName;

		if (acc[type] === undefined) {
			acc[type] = {};
		}

		if (acc[type][categoryName] === undefined) {
			acc[type][categoryName] = {};
		}

		acc[type][categoryName][tagName] = true;

		return acc;
	}, {});
}

export function getTags(data, completed) {
	return Object.entries(data)
		.filter(([type]) => ['deposit', 'withdraw'].includes(type))
		.flatMap(([type, object]) =>
			Object.values(object?.tags ?? {}).map((value) => {
				const object = formatTag(value);

				return {
					type: type,
					object: {
						...object,
						isCompleted:
							completed?.[type]?.[object?.categoryName]?.[
								object?.tagName
							],
					},
				};
			}),
		)
		.sort((a, b) => {
			const weight = a?.object?.weight - b?.object?.weight;
			const type = a?.type?.localeCompare?.(b?.type ?? '');
			const label = a?.object?.label?.localeCompare?.(
				b?.object?.label ?? '',
			);

			if (type) {
				return type;
			}

			if (weight) {
				return weight;
			}

			return label;
		});
}

export function formatTags(tags, completed) {
	return tags
		.map(({ object, type }) => ({
			...object,
			type: type,
		}))
		.concat(
			{
				type: 'deposit',
				tagName: `$deposit`,
				categoryName: '$type',
				color: 'deposit',
				weight: Infinity,
				alert: false,
				isCompleted: completed?.['deposit']?.['$type']?.[`$deposit`],
			},
			{
				type: 'withdraw',
				tagName: `$withdraw`,
				categoryName: '$type',
				color: 'withdraw',
				weight: Infinity,
				alert: false,
				isCompleted: completed?.['withdraw']?.['$type']?.[`$withdraw`],
			},
		);
}
