import React from 'react';

import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import { MessageBoxToggle } from '@asteria/component-support/MessageBox';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useDynamicFormData } from '../../hooks';

import './styles.scss';

const ResetSection = React.memo((props) => {
	const { onAction, form } = props;

	const data = useDynamicFormData({
		history: null,

		useForm: (options) => {
			if (!options?.name) {
				return form;
			}

			return Array.isArray(options?.name)
				? options?.name.map((name) => get(form, name))
				: get(form, options?.name);
		},
		useStaticForm: (name) => {
			if (!name) {
				return form;
			}

			return Array.isArray(name)
				? name.map((name) => get(form, name))
				: get(form, name);
		},

		category: null,
		tag: null,

		useCurrentBalance: false,
		useUnpaidValues: false,
	});

	const mutation = useMutation({
		mutationFn: async () => onAction?.('forecaster:reset'),
	});

	const toggle = React.useMemo(
		() => ({
			size: 'sm',
			variant: 'link',
			label: TranslationService.get([
				'settings.forecast.reset.action',
				'settings.forecast.reset.action.label',
			]),
			analyticsKey: 'settings.forecast.reset.action',
		}),
		[],
	);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-settings-section',
				'asteria--variant-reset',
			)}
		>
			<Group>
				<Translation
					translationKey="settings.forecast.reset.title"
					Component={Title}
					size="xs"
				/>
				<Translation
					translationKey="settings.forecast.reset.description"
					Component={Text}
					size="sm"
				/>
			</Group>
			<MessageBoxToggle
				toggle={toggle}
				analyticsKey="settings.forecast.reset"
				scrollIntoView
			>
				{({ onClose }) => [
					<Content key="content">
						<Translation
							translationKey={[
								'settings.forecast.reset.confirmation.content',
								!data?.total
									? 'settings.forecast.reset.confirmation.empty.content'
									: null,
							]}
							Component={Text}
							data={data}
						/>
					</Content>,
					<Footer key="footer">
						<FooterSection position="first">
							<Button
								variant="tertiary"
								size="sm"
								label={TranslationService.get([
									'action.abort',
									'button.abort',
									'action.cancel',
									'button.cancel',
									'settings.forecast.reset.confirmation.action.cancel.label',
								])}
								onClick={onClose}
								loading={mutation.isLoading}
								disabled={mutation.isLoading}
								analyticsKey="settings.forecast.reset.confirmation.action.cancel"
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="primary"
								size="sm"
								label={TranslationService.get([
									'action.submit',
									'button.submit',
									'action.remove',
									'button.remove',
									'settings.forecast.reset.confirmation.action.submit.label',
								])}
								onClick={mutation.mutate}
								loading={mutation.isLoading}
								disabled={mutation.isLoading}
								analyticsKey="settings.forecast.reset.confirmation.action.submit"
							/>
						</FooterSection>
					</Footer>,
				]}
			</MessageBoxToggle>
		</div>
	);
});

ResetSection.displayName = 'ResetSection';
ResetSection.propTypes = {
	onAction: PropTypes.func,
	loading: PropTypes.bool,
	form: PropTypes.object,
};

export default ResetSection;
