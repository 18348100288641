import React from 'react';

import PropTypes from 'prop-types';

import { View as BarGraphGroupGroupView } from '@asteria/component-forecaster/components/graph/bars/column/typed-group';

import LoanBarGraphBar from './bar';
import { useGraphData } from './hooks';

const LoanBarGraphBarGroup = React.memo((props) => {
	const { date, type } = props;

	const data =
		useGraphData().find((object) => object?.date === date)?.data ?? {};

	const statuses = Object.keys(data);
	const value = Object.values(data).reduce(
		(acc, object) => acc + (object?.total || 0),
		0,
	);

	return (
		<BarGraphGroupGroupView
			type={type}
			date={date}
			statuses={statuses}
			value={value}
			renderItem={({ type, date, status }) => (
				<LoanBarGraphBar type={type} date={date} status={status} />
			)}
		/>
	);
});

LoanBarGraphBarGroup.displayName = 'LoanBarGraphBarGroup';
LoanBarGraphBarGroup.propTypes = {
	date: PropTypes.string,
	type: PropTypes.string,
};

export default LoanBarGraphBarGroup;
