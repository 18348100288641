import React from 'react';

import PropTypes from 'prop-types';

import { WelcomeContext } from '@asteria/view-welcome';

import useConfig from '@asteria/utils-hooks/useConfig';

const WelcomeConfigWrapper = ({ children }) => {
	const config = useConfig('widget.welcome');

	const ctx = React.useMemo(
		() => ({
			actions: config?.actions,
			wizard: config?.wizard,
			questions: config?.questions,
			placeholder: config?.placeholder,
		}),
		[
			config?.actions,
			config?.placeholder,
			config?.questions,
			config?.wizard,
		],
	);

	return (
		<WelcomeContext.Provider value={ctx}>
			{children}
		</WelcomeContext.Provider>
	);
};

WelcomeConfigWrapper.displayName = 'WelcomeConfigWrapper';
WelcomeConfigWrapper.propTypes = { children: PropTypes.node };

export default WelcomeConfigWrapper;
