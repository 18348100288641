import React from 'react';

import { useLocation } from 'react-router-dom';

import { LayoutContext } from '../context';

const LayoutHistoryCallback = React.memo(({ callback, navigate }) => {
	const location = useLocation();

	const { onAction } = React.useContext(LayoutContext);

	React.useEffect(() => {
		callback?.('create.history', {
			current: location?.pathname,
			navigate: navigate,
			open: (data) => onAction('go', data),
		});
	}, [callback, location?.pathname, navigate, onAction]);
});

LayoutHistoryCallback.displayName = 'LayoutHistoryCallback';

export default LayoutHistoryCallback;
