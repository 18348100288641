// import { setActiveFeatures } from '@asteria/services-datalayer/services/features/actions';

const Service = {
	features: {},
	defaults: {},
	toggled: {},
	detected: {},
	getEnabled: () => ({ ...Service.features, ...Service.toggled }),
	toggleFeature: (key, dispatch) => {
		if (Service.isActive(key)) {
			delete Service.features[key];
			delete Service.toggled[key];
		} else {
			Service.features[key] = { version: 1 };
			Service.toggled[key] = { version: 1 };
		}

		if (dispatch) {
			// dispatch(setActiveFeatures(Service.features));
		}
	},
	hasFeature: (feature, { minVersion = false, maxVersion = false } = {}) => {
		if (!feature) {
			return false;
		}

		if (minVersion !== false && feature.version < minVersion) {
			return false;
		}

		if (maxVersion !== false && feature.version > maxVersion) {
			return false;
		}

		return true;
	},
	isActive: (key) => {
		if (!Service.features || !Service.features[key]) {
			if (!Service.detected[key]) {
				Service.detected[key] = {
					key,
				};
			}
			return false;
		}

		return Service.hasFeature(Service.features[key]);
	},
	setFeatures: (features) => {
		Service.features = features;
	},
	setDefaultFeatures: (features) => {
		Service.defaults = features;
	},
};

if (window && !window.Asteria) {
	window.Asteria = {};
}

window.Asteria.FeatureService = Service;

export default Service;
