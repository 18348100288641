import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import WelcomeContext from '../../context';

import './styles.scss';

const Empty = (props) => {
	const { className } = props;

	const { placeholder: ctxPlaceholder } = React.useContext(WelcomeContext);
	const config = ctxPlaceholder?.empty ?? [];

	return <Contenter className={className} content={config} />;
};

Empty.displayName = 'Empty';
Empty.propTypes = { className: PropTypes.string };

export default React.memo(Empty);
