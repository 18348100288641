import React from 'react';

import { isEqual } from 'lodash-es';

/**
 * @typedef { Object } Size
 * @property { number } width
 * @property { number } height
 */

/**
 * @param { HTMLElement } el
 * @returns { Size }
 */
function getSize(el) {
	if (!el) {
		return { width: 0, height: 0 };
	}

	return { width: el.offsetWidth, height: el.offsetHeight };
}

/**
 * @param {{ ref: any, callback?: (options: Size) => void, initial?: Size, max?: Size }} options
 * @returns { Size }
 */
function useComponentSize(options) {
	const { ref, callback, initial, max } = options;

	const [size, setSize] = React.useReducer(
		(prev, next) => {
			if (isEqual(prev, next)) {
				return prev;
			}

			return next;
		},
		() => (initial ? initial : getSize(ref?.current)),
	);

	const handleResize = React.useCallback(
		(entries) => {
			for (const entry of entries) {
				const height = Math.round(
					entry?.contentBoxSize?.[0]?.blockSize ?? 0,
				);
				const width = Math.round(
					entry?.contentBoxSize?.[0]?.inlineSize ?? 0,
				);

				let size = { height: height, width: width };

				if (max?.height) {
					size.height = Math.min(size.height, max?.height);
				}

				if (max?.width) {
					size.width = Math.min(size.width, max?.width);
				}

				setSize(size);
				callback?.(size);
			}
		},
		[callback, max?.height, max?.width],
	);

	React.useLayoutEffect(() => {
		if (!ref?.current) {
			return;
		}

		const observer = new ResizeObserver(handleResize);

		const node = ref?.current;

		observer.observe(node);

		return () => {
			if (node) {
				observer.unobserve(node);
			}
		};
	}, [ref?.current, handleResize]);

	return React.useMemo(
		() => ({ width: size?.width, height: size?.height }),
		[size?.height, size?.width],
	);
}

export default useComponentSize;
