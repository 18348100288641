export const initialState = {
	data: null,
	isLoading: true,
	isSaving: false,

	lastSaveAt: null,

	tags: [],
	history: [],
	navigation: {
		section: null,
		date: null,
		type: 'deposit',
		category: null,
		tag: null,
	},
	action: null,
	initialData: null,
	flags: {
		fullScreen: false,
		transactions: false,
	},

	actions: {
		loading: true,
		data: [],
	},
};

export const ACTIONS = {
	ResetType: 'reset:type',
	ResetTag: 'reset:tag',
	ResetRemove: 'reset:remove',
	Reset: 'reset',
	Close: 'close',
	Abort: 'abort',
};

export const SECTIONS = {
	DEPOSIT: 'deposit',
	WITHDRAW: 'withdraw',
	OVERVIEW: 'overview',
	HARD_TO_FORECAST: 'hard-to-forecast',
	ALL_CATEGORIES: 'all-categories',
};
