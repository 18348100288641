import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual, merge } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-core/tabs';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';
import Footer, { FooterSection } from '@asteria/component-core/wrapper/footer';

import Form from '@asteria/component-form/form';
import Modal from '@asteria/component-modal';
import { useACL } from '@asteria/component-tools/acl';

import { setLanguage } from '@asteria/datalayer/stores/language';

import { TranslationService } from '@asteria/language';

import CompanySettings from './company';
import ScenarioSection from './scenario';
import UserSettings from './user';

import './index.scss';

const selectors = {
	user: createSelector(
		(state) => state?.app?.user,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	company: createSelector(
		(store) => store?.app?.company,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	settings: createSelector(
		(state) => state?.app?.user?.settings,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const useDefaultValues = (defaultValues) => {
	const user = useSelector(selectors.user);
	const company = useSelector(selectors.company);
	const settings = useSelector(selectors.settings);

	return React.useMemo(
		() =>
			merge({}, defaultValues ?? {}, {
				user: user,
				company: company,
				settings: settings,
				global: {
					language: localStorage.asteriaLanguage ?? 'sv',
				},
			}),
		[company, defaultValues, settings, user],
	);
};

const SettingsFooter = (props) => {
	const { loading, variant, onClose } = props;

	const {
		formState: { errors },
	} = useFormContext();

	return (
		<Footer>
			{variant === 'modal' ? (
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.abort',
							'action.abort',
							'settings.button.abort',
						])}
						analyticsKey="settings.button.abort"
						onClick={onClose}
					/>
				</FooterSection>
			) : null}

			<FooterSection position="last">
				<Button
					variant="primary"
					label={TranslationService.get([
						'button.save',
						'action.save',
						'settings.button.save',
					])}
					analyticsKey="settings.button.save"
					type="submit"
					loading={loading}
					disabled={loading || Object.keys(errors).length}
				/>
			</FooterSection>
		</Footer>
	);
};

SettingsFooter.propTypes = {
	loading: PropTypes.bool,
	variant: PropTypes.string,
	onClose: PropTypes.func,
};

const Settings = (props) => {
	const {
		onClose,
		onAction,
		onSubmit = onAction,
		featurePrefix,
		variant = 'default',
		extra,
		mode,
		defaultValues: $defaultValues,
	} = props;

	const current = React.useRef('user');

	const [{ loading, error, active }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'LOADING':
					return { ...state, loading: true };

				case 'DONE':
				case 'RESET':
					return {
						...state,
						loading: false,
						error: null,
						active: current?.current,
					};

				case 'ERROR':
					return { ...state, loading: false, error: action?.payload };

				default:
					return state;
			}
		},
		{ loading: false, error: null },
	);

	const hasViewRole = useACL('USER', 'VIEW');
	const hasCompanyAdminRole = useACL('USER', 'COMPANYADMIN');

	const tabs = React.useMemo(() => {
		const elements = [];
		if (mode) {
			return [];
		}

		if (hasViewRole) {
			elements.push(
				<Button
					variant="tab"
					size="sm"
					tab="user"
					label={TranslationService.get(
						[
							'settings.tab.user',
							`${featurePrefix}.settings.tab.user`,
						],
						'User',
					)}
				/>,
			);
		}

		if (hasCompanyAdminRole) {
			elements.push(
				<Button
					variant="tab"
					size="sm"
					tab="company"
					label={TranslationService.get(
						[
							'settings.tab.company',
							`${featurePrefix}.settings.tab.company`,
						],
						'Company',
					)}
				/>,
			);
		}

		return elements;
	}, [featurePrefix, hasCompanyAdminRole, hasViewRole, mode]);

	const content = React.useMemo(() => {
		const elements = [];

		if (hasViewRole && (!mode || mode === 'user')) {
			elements.push(
				<div tab="user">
					<UserSettings
						onAction={onAction}
						onSubmit={onSubmit}
						active={active}
						error={error?.user}
						extra={extra?.user}
					/>
				</div>,
			);
		}

		if (hasCompanyAdminRole && (!mode || mode === 'company')) {
			elements.push(
				<div tab="company">
					<CompanySettings
						onAction={onAction}
						onSubmit={onSubmit}
						active={active}
						error={error?.company}
						extra={extra?.company}
					/>
				</div>,
			);
		}

		return elements;
	}, [
		active,
		error?.company,
		error?.user,
		extra?.company,
		extra?.user,
		hasCompanyAdminRole,
		hasViewRole,
		mode,
		onAction,
		onSubmit,
	]);

	const defaultValues = useDefaultValues($defaultValues);

	const handleSubmit = React.useCallback(
		async (form) => {
			dispatch({ type: 'LOADING' });

			let error = null;

			try {
				await onAction?.('user:settings:save', {
					firstName: form?.user?.firstName,
					lastName: form?.user?.lastName,
					password: form?.user?.password,
					newpassword: form?.user?.newpassword,
					service: form?.user?.service,
				});
			} catch (err) {
				if (error === null) {
					error = {};
				}

				error.user = err.message;
			}

			try {
				await onAction?.('company:settings:save', {
					name: form?.company?.name,
					vat: form?.company?.vat,
					orgnumber: form?.company?.orgnumber,
					settings: form?.company?.settings,
					contact: form?.company?.contact,
					service: form?.company?.service,
				});
			} catch (err) {
				if (error === null) {
					error = {};
				}

				error.company = err.message;
			}

			try {
				await onAction?.('settings:save', {
					flags: form?.settings?.flags,
				});
			} catch (err) {
				if (error === null) {
					error = {};
				}

				error.settings = err.message;
			}

			if (error) {
				dispatch({ type: 'ERROR', payload: error });
			}

			if (!error) {
				dispatch({ type: 'DONE' });
			}

			if (form?.global?.language) {
				if (localStorage.asteriaLanguage !== form?.global?.language) {
					localStorage.setItem(
						'asteriaLanguage',
						form?.global?.language,
					);

					TranslationService.code = form?.global?.language;
					dispatch(setLanguage(form?.global?.language));

					window.location.reload();
				}
			}
		},
		[onAction],
	);

	const handleTabChange = React.useCallback(([value]) => {
		current.current = value;
		dispatch({ type: 'RESET' });
	}, []);

	return (
		<Form defaultValues={defaultValues} onSubmit={handleSubmit} update>
			<Wrapper
				className="asteria-component__settings"
				onClose={onClose}
				scroll
				border={false}
			>
				{variant === 'modal' ? (
					<Header onClose={onClose}>
						{TranslationService.get('settings.title')}
					</Header>
				) : null}
				<Content scroll>
					{tabs.length === 1 ? (
						content[0]
					) : (
						<Tabs active="user" onChange={handleTabChange}>
							<TabsNavigation
								key="navigation"
								analyticsKey="settings.tabs"
								variant="fluid"
							>
								{tabs}
							</TabsNavigation>
							<TabsContent key="content">{content}</TabsContent>
						</Tabs>
					)}
				</Content>
				<SettingsFooter
					loading={loading}
					variant={variant}
					onClose={onClose}
				/>
			</Wrapper>
		</Form>
	);
};

Settings.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	featurePrefix: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'modal']),
	mode: PropTypes.oneOf(['user', 'company']),
	extra: PropTypes.shape({ user: PropTypes.node, company: PropTypes.node }),
	defaultValues: PropTypes.object,
};

const SettingsModal = (props) => {
	const { open, onClose, onAction, onSubmit = onAction, extra } = props;

	return (
		<Modal open={open} onClose={onClose} size="sm" scroll>
			<Settings
				onClose={onClose}
				onAction={onAction}
				onSubmit={onSubmit}
				variant="modal"
				extra={extra}
			/>
		</Modal>
	);
};

SettingsModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	extra: PropTypes.shape({ user: PropTypes.node, company: PropTypes.node }),
	defaultValues: PropTypes.object,
};

export default Settings;
export { ScenarioSection, SettingsModal };
