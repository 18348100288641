export function getFaceIcon({ value }) {
	if (value <= 0.2) {
		return 'face-1';
	}

	if (value <= 0.4) {
		return 'face-2';
	}

	if (value <= 0.6) {
		return 'face-3';
	}

	if (value <= 0.8) {
		return 'face-4';
	}

	return 'face-5';
}
