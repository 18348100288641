import React from 'react';

import { Provider, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import store from '@asteria/datalayer';
import * as AppStore from '@asteria/datalayer/stores/app';

import * as hooks from './hooks';
import Datalayer from './wrappers/Datalayer';
import ErrorBoundary from './wrappers/Error';
import Features from './wrappers/Features';
import Routing from './wrappers/Routing';
import Theme from './wrappers/Theme';
import Translations, {
	Context as TranslationContext,
} from './wrappers/Translations';

const queryClient = new QueryClient();

/** @type { React.FC<{ otherwise?: React.ReactNode }> } */
const AuthValidate = React.memo(function AuthValidate({ children, otherwise }) {
	const [search] = useSearchParams();

	const valid = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
		(a, b) => isEqual(a, b),
	);

	if (valid === null) {
		return null;
	}

	if (valid) {
		return children;
	}

	return (
		otherwise ?? (
			<Navigate
				to={['/auth/login', search.toString()]
					.filter(Boolean)
					.join('?')}
			/>
		)
	);
});

AuthValidate.displayName = 'AuthValidate';
AuthValidate.propTypes = {
	children: PropTypes.node,
	otherwise: PropTypes.node,
};

/** @type { React.FC<{ to: string }> } */
const Redirect = React.memo(function Redirect({ to }) {
	const [search] = useSearchParams();

	return <Navigate to={[to, search].join('?')} replace />;
});

Redirect.displayName = 'Redirect';
Redirect.propTypes = { to: PropTypes.string };

const AsteriaWidgetContent = React.memo(function AsteriaWidgetContent(props) {
	const {
		accessToken,
		partnerId,
		languageCode,
		analytics,
		theme,

		routing,
		datalayer,
		translations = true,
		configuration = true,
	} = props;

	try {
		document.documentElement.style.setProperty(
			'--browser-zoom',
			window.devicePixelRatio,
		);
	} catch (err) {
		//
	}

	hooks.useConfiguration({ partnerId, enabled: configuration });
	hooks.usePartner({ partnerId });
	hooks.useAnalytics({ analytics });
	hooks.auth.useValidate({ accessToken });

	return (
		<Features partnerId={partnerId}>
			<Theme theme={theme} partnerId={partnerId}>
				<Translations
					language={languageCode}
					partnerId={partnerId}
					enabled={translations}
				>
					<Datalayer partnerId={partnerId} {...datalayer}>
						<Routing {...routing} />
					</Datalayer>
				</Translations>
			</Theme>
		</Features>
	);
});

AsteriaWidgetContent.displayName = 'AsteriaWidgetContent';
AsteriaWidgetContent.propTypes = {
	accessToken: PropTypes.string,
	partnerId: PropTypes.string,
	languageCode: PropTypes.string,
	analytics: PropTypes.bool,
	theme: PropTypes.string,
	callback: PropTypes.func,
	datalayer: PropTypes.shape({
		loader: PropTypes.bool,
		fetchExtra: PropTypes.func,
		onDataLoaded: PropTypes.func,
		loadingOnRefresh: PropTypes.bool,

		onUserLoad: PropTypes.func,
		onCompanyLoad: PropTypes.func,
		onIntegrationsLoad: PropTypes.func,
		onExtraLoad: PropTypes.func,

		skipMeRequest: PropTypes.bool,
		skipCompanyRequest: PropTypes.bool,
		skipIntegrationsRequest: PropTypes.bool,
		skipExtraRequest: PropTypes.bool,
	}),
	routing: PropTypes.shape({
		type: PropTypes.oneOf(['memory', 'browser', 'hash']),
		routes: PropTypes.node,
	}),
	translations: PropTypes.bool,
	configuration: PropTypes.bool,
};

function AsteriaWidget(props) {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<AsteriaWidgetContent {...props} />
			</Provider>
		</QueryClientProvider>
	);
}

AsteriaWidget.displayName = 'AsteriaWidget';
AsteriaWidget.propTypes = {
	...AsteriaWidgetContent.propTypes,
};

export default React.memo(AsteriaWidget);
export { AuthValidate, ErrorBoundary, Redirect, TranslationContext, hooks };
