import React from 'react';

import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';

import { pop } from '@asteria/datalayer/stores/snackbar';

import animationListener from '@asteria/utils-funcs/animationListener';

import Snackbar from '../SnackBar';

import './styles.scss';

const AnimatedSnackbar = (props) => {
	const { object, onAction } = props;

	const dispatch = useDispatch();

	const handleClose = React.useCallback(
		() => dispatch(pop(object._id)),
		[dispatch, object._id],
	);

	return (
		<CSSTransition
			in={true}
			appear
			unmountOnExit
			classNames="asteria-snackbar"
			addEndListener={animationListener}
		>
			<Snackbar {...object} onClose={handleClose} onAction={onAction} />
		</CSSTransition>
	);
};

AnimatedSnackbar.displayName = 'AnimatedSnackbar';

AnimatedSnackbar.propTypes = {
	object: PropTypes.object,
	onAction: PropTypes.func,
};

AnimatedSnackbar.defaultProps = {};

export default AnimatedSnackbar;
