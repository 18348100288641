import React from 'react';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Button from '@asteria/component-core/button';
import { TooltipWrapper } from '@asteria/component-core/tooltip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Context = React.createContext({
	onDragStart: () => null,
	editing: false,
});

function useEmptyState(ref, value) {
	const [state, setState] = React.useState(false);

	React.useLayoutEffect(() => {
		if (!ref?.current) {
			return;
		}

		const style = getComputedStyle(ref.current);
		const max = Number.parseFloat(style.getPropertyValue('--max'));

		setState(value / max < 0.05);
	}, [ref, value]);

	return React.useMemo(() => state, [state]);
}

const Bar = React.memo((props) => {
	const {
		className,
		children,
		value,
		editable,
		onChange,
		tooltip,
		type,
		loading,
		colors,
		...rest
	} = props;

	const [internalValue, setInternalValue] = React.useState(value);
	const [editing, setEditing] = React.useState(false);

	const ref = React.useRef(null);
	const lastDrag = React.useRef(null);

	const onThrottleChange = React.useMemo(
		() => AsteriaCore.utils.throttle(onChange, 200),
		[onChange],
	);

	React.useEffect(() => {
		setInternalValue(value);
	}, [value]);

	const onDrag = React.useCallback(
		(event) => {
			if (lastDrag.current === null) {
				return;
			}

			const { clientY } = event;
			const { y: lastY } = lastDrag.current;

			const deltaY = clientY - lastY;

			const style = getComputedStyle(ref.current);
			const parentStyle = getComputedStyle(ref.current.parentNode);

			const max = Number.parseFloat(style.getPropertyValue('--max'));
			const lastValue = Number.parseFloat(
				style.getPropertyValue('--defaultValue'),
			);

			const pixelToValue =
				max / parseInt(parentStyle.getPropertyValue('height'));

			const deltaValue = Math.round(
				(type === 'deposit' ? -deltaY : deltaY) * pixelToValue,
			);

			const value =
				lastValue + deltaValue > 0 ? lastValue + deltaValue : 0;

			ref.current.style.setProperty('--value', value);

			if (lastValue) {
				onThrottleChange?.({ value: value, immediate: true });
			}

			if (value >= max) {
				onThrottleChange?.({ value: value, expanding: true });
			}
		},
		[type, onThrottleChange],
	);

	const onDragEnd = React.useCallback(() => {
		const style = getComputedStyle(ref.current);
		const value = Number.parseFloat(style.getPropertyValue('--value'));

		setInternalValue(value);
		onChange?.({ value: value });

		setEditing(false);
		lastDrag.current = null;

		document.removeEventListener('mousemove', onDrag);
		document.removeEventListener('mouseup', onDragEnd);
	}, [onChange, onDrag]);

	const onDragStart = React.useCallback(
		(event) => {
			if (event?.button !== 0 && event?.type !== 'touchstart') {
				return;
			}

			setEditing(true);

			document.addEventListener('mousemove', onDrag);
			document.addEventListener('mouseup', onDragEnd);

			lastDrag.current = { y: event?.clientY };
		},
		[onDrag, onDragEnd],
	);

	const style = React.useMemo(() => {
		const style = {
			'--value': internalValue,
			'--defaultValue': internalValue,
		};

		if (colors?.length) {
			style['--color'] = colors;
		}

		return style;
	}, [colors, internalValue]);

	const TooltipProps = React.useMemo(() => {
		if (
			AsteriaCore.utils.isObject(tooltip) &&
			!React.isValidElement(tooltip)
		) {
			return tooltip;
		}

		return { placement: 'top', tooltip: tooltip };
	}, [tooltip]);

	const ButtonTooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get(
				['forecaster.bar.button.change.tooltip'],
				'Click and drag to adjust amount',
			),
			once: 'forecaster:bar:handle',
		}),
		[],
	);

	const ctx = React.useMemo(
		() => ({ onDragStart: onDragStart, editing: editing }),
		[editing, onDragStart],
	);

	const empty = useEmptyState(ref, internalValue);

	return (
		<TooltipWrapper
			{...(editing || !internalValue || !tooltip ? {} : TooltipProps)}
			targetRef={ref}
		>
			<div
				className={cn(
					'asteria-component__forecaster-graph-bar',
					{ [`asteria--type-${type}`]: type },
					{
						'asteria--state-loading': loading,
						'asteria--state-empty': empty,
					},
					className,
				)}
				style={style}
				{...rest}
				ref={ref}
			>
				{editable || editing ? (
					<Button
						className="asteria-component__forecaster-graph-bar-handle"
						icon="drag"
						size="xs"
						tabIndex="-1"
						analyticsKey="forecaster.bar.button.change"
						onMouseDown={onDragStart}
						tooltip={ButtonTooltip}
					/>
				) : null}
				<Context.Provider value={ctx}>{children}</Context.Provider>
			</div>
		</TooltipWrapper>
	);
});

Bar.displayName = 'Bar';

Bar.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	loading: PropTypes.bool,

	children: PropTypes.node,
	value: PropTypes.number,
	editable: PropTypes.bool,
	onChange: PropTypes.func,
	tooltip: PropTypes.object,

	colors: PropTypes.arrayOf(PropTypes.string),
};

export default Bar;
export { Context };
