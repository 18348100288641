import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const TableRow = React.memo(
	React.forwardRef((props, ref) => {
		const { className, children, border = true, ...rest } = props;

		return (
			<div
				className={cn(
					'asteria-component__table-row',
					{ 'asteria-component__table-row--no-border': !border },
					className,
				)}
				ref={ref}
				{...rest}
			>
				{children}
			</div>
		);
	}),
);

TableRow.displayName = 'TableRow';

TableRow.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	border: PropTypes.bool,
};

export default TableRow;
