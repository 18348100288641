import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import Accounts from './accounts';

/** @type { React.FC<import('./types').Props & { open: boolean }> } */
const AccountsListModal = React.memo(function AccountsListModal(props) {
	const { open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className="asteria--variant-accounts"
			size="sm"
		>
			<Accounts {...props} header footer={{ back: true }} />
		</Modal>
	);
});

AccountsListModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default AccountsListModal;
