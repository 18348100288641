import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { Context as BarContext } from '../../../bar';

import '../styles.scss';

const BarAction = React.memo((props) => {
	const { type } = props;

	const { onDragStart, editing } = React.useContext(BarContext);

	if (editing) {
		return null;
	}

	return (
		<Button
			icon={
				type === 'deposit'
					? ['add', 'forecaster-add']
					: ['remove', 'forecaster-remove']
			}
			variant="secondary"
			className={cn('asteria-component__forecaster-graph-button', {
				'asteria-color__deposit': type === 'deposit',
				'asteria-color__withdraw': type === 'withdraw',
			})}
			iconSize="lg"
			// onClick={onSetBaseValue}
			tooltip={TranslationService.get([
				'forecaster.add.new.period',
				type === 'deposit'
					? 'forecaster.add.positive.new.period'
					: 'forecaster.add.negative.new.period',
			])}
			onMouseDown={onDragStart}
		/>
	);
});

BarAction.displayName = 'BarAction';
BarAction.propTypes = { type: PropTypes.string };

export default BarAction;
