import React from 'react';

import { useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { LoanSaveModal } from '..';
import {
	useModalActions,
	useModalProps,
} from '../../components/modal-collection';

const LoanLeave = React.memo(() => {
	const store = useStore();

	const { open, close, onAction, onSubmit } = useModalActions();
	const { form, simulationId, next, callback } = useModalProps();

	const [loading, setLoading] = React.useState(false);

	const handleDiscard = React.useCallback(
		(event) => {
			callback?.('loan:dirty:reset');

			onAction?.('redirect', { path: next ?? '/financial' });

			return close?.(event);
		},
		[callback, onAction, next, close],
	);

	const handleContinue = React.useCallback(() => {
		open(<LoanSaveModal />, {
			form: form,
			simulationId: simulationId,
			next: next,
			callback: callback,
		});
	}, [callback, form, next, open, simulationId]);

	const handleSave = React.useCallback(
		async (event) => {
			const simulation = ScenarioStore.selectors.scenario(
				store.getState(),
				simulationId,
			);

			if (simulation) {
				setLoading(true);

				try {
					await onSubmit?.('scenario:update', {
						_id: simulationId,
						data: form,
					});
				} catch (err) {
					//
				}

				setLoading(false);
			}

			callback?.('loan:dirty:reset');
			onAction?.('redirect', '/financial');

			return close?.(event);
		},
		[callback, close, form, onAction, onSubmit, simulationId, store],
	);

	return (
		<Wrapper>
			<Header onClose={close}>
				{TranslationService.get(['financial.modal.loan.leave.title'])}
			</Header>
			<Content>
				<Translation
					Component={Text}
					translationKey="financial.modal.loan.leave.content"
					size="sm"
				/>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'financial.modal.loan.leave.close',
						])}
						onClick={close}
						analyticsKey="financial.modal.loan.leave.close"
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="secondary"
						label={TranslationService.get([
							'button.discard',
							'action.discard',
							'financial.modal.loan.leave.discard',
						])}
						onClick={handleDiscard}
						analyticsKey="financial.modal.loan.leave.discard"
						loading={loading}
						disabled={loading}
					/>
					{simulationId ? (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.save',
								'action.save',
								'financial.modal.loan.leave.save',
							])}
							analyticsKey="financial.modal.loan.leave.save"
							onClick={handleSave}
							loading={loading}
							disabled={loading}
						/>
					) : (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.continue',
								'action.continue',
								'financial.modal.loan.leave.continue',
							])}
							analyticsKey="financial.modal.loan.leave.continue"
							onClick={handleContinue}
							loading={loading}
							disabled={loading}
						/>
					)}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

LoanLeave.displayName = 'LoanLeave';
LoanLeave.propTypes = {};

const LoanLeaveModal = React.memo((props) => {
	const { open, className } = props;

	const { close } = useModalActions();

	return (
		<Modal
			open={open}
			onClose={close}
			className={cn(
				'asteria-component__financial-modal',
				'asteria--variant-loan-leave',
				className,
			)}
			size="sm"
		>
			<LoanLeave {...props} />
		</Modal>
	);
});

LoanLeaveModal.displayName = 'LoanLeaveModal';

LoanLeaveModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
};

export default LoanLeaveModal;
