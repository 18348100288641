import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Action as BaseAction } from '@asteria/component-actionbar';
import { useBulkFeatures } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const CompleteAction = (props) => {
	const hasWelcomeForecaster = useBulkFeatures([
		['forecaster', 'widget-welcome-page-forecaster-redirect'],
	]);

	return (
		<BaseAction
			className="asteria-view__welcome__action"
			{...props}
			variant="complete"
			// icon={[
			// 	'check',
			// 	'welcome-action',
			// 	'welcome-action-check',
			// 	'welcome-action-complete',
			// 	'welcome-action-complete-check',
			// ]}
			content={[
				<Text size="lg" key="complete-content">
					{TranslationService.get(
						[
							'welcome.action.complete.text',
							!hasWelcomeForecaster &&
								'welcome.action.complete.text.cashflow',
							hasWelcomeForecaster &&
								'welcome.action.complete.text.forecaster',
						].filter(Boolean),
					)}
				</Text>,
			]}
		/>
	);
};

CompleteAction.displayName = 'CompleteAction';

CompleteAction.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	showCloseButton: PropTypes.bool,
};

export default CompleteAction;
