import AsteriaCore from '@asteria/core';

import { ClientService, InvoiceService } from '@asteria/backend-utils-services';

import {
	addClient,
	setClients,
	updateClients,
} from '@asteria/datalayer/stores/app';

import { withPagination } from '@asteria/utils-funcs/request';

export const FIELDS = `
id
_id
name
createdAt
updatedAt
type
active
meta {
    source
    clientNumber
}

info {
    termsOfPayment
    vatNumber
    orgNumber
    creditLimit
}
contact {
    general {
        name
        email
        street
        city
        phone
    }
}

`;

export const DETAILS_FIELDS = `
	${FIELDS}
	statistics {
			invoices {
					count
					total
			}
			forecast: invoices(input: { status: FORECAST }) {
					count
			}
			unpaid: invoices(input: { status: UNPAID }) {
					count
			}
			paid: invoices(input: { status: PAID }) {
					count
					total
			}
			overdue: invoices(input: { status: OVERDUE }) {
					count
			}
	}
`;

export const PAGINATION_FIELDS = `
	pageInfo {
		count
		startCursor
		endCursor
		hasNextPage
	}
	edges {
		cursor
		node {
			${FIELDS}
		}
	}
`;

/**
 * @param { { accessToken: string, dataloader: DataLoader, dispatch?: unknown } } options
 */
export async function fetch(options) {
	const { accessToken, dataloader, dispatch } = options;

	const response = await withPagination(ClientService.client.fetch)(
		{
			isBulk: true,
			filters: {},
			pageFilters: { first: 200 },
			fields: PAGINATION_FIELDS,
			dataloader: dataloader,
		},
		{ token: accessToken },
	).catch(() => {
		return [];
	});

	dispatch?.(setClients(response));

	return response;
}

export async function details(options) {
	const { accessToken, dispatch, id } = options;

	const response = await ClientService.client.fetchOne(
		{ id: id, fields: DETAILS_FIELDS },
		{ token: accessToken },
	);

	dispatch?.(updateClients(response));

	return response;
}

export async function create({ client, dispatch, accessToken }) {
	try {
		const { data } = await ClientService.client.create(
			{
				fields: `ok data { ${FIELDS} }`,
				input: { name: client?.name, type: client?.type },
			},
			{ token: accessToken },
		);

		dispatch?.(addClient(data));

		return data;
	} catch (e) {
		return null;
	}
}

export async function statistics({ accessToken, filters }) {
	return InvoiceService.invoice
		.sendRequest(
			{
				query: `
					query Request(
						$filters: InvoiceStatisticsClientFilters = {}
					) {
						invoices {
							statistics {
								clientStatistics(filters: $filters) {
									id
									count
									revenue {
										currency
										total
									}
								}
							}
						}
					}
				`,
				variables: { filters: filters },
			},
			{ token: accessToken },
		)
		.then(
			(response) =>
				response?.invoices?.statistics?.clientStatistics ?? [],
		)
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.warn('client:statistics', err);

			return [];
		});
}

ClientService.client.extend({
	getQuery: () => `
		query ClientPerformance(
			$filters: ClientPerformanceFilters
			$companyId: ObjectId
		) {
			response: clientPerformance(
				filters: $filters
				companyId: $companyId
			) {
				trend
				accuracy
				invoices {
					count
					total {
						actual
						forecasted
					}
				}
				statistics {
					best {
						clientId
						client {
							name
						}
						count
						total {
							actual
							forecasted
						}
						trend
						impact
						accuracy
					}
					worst {
						clientId
						client {
							name
						}
						count
						total {
							actual
							forecasted
						}
						trend
						impact
						accuracy
					}
					details {
						clientId
						client {
							name
						}
						count
						total {
							actual
							forecasted
						}
						trend
						impact
						accuracy
					}
				}
			}
		}
	`,
	key: `clientPerformance`,
	loggerMethod: 'api.client.clientPerformance',
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.clientService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export async function performance({
	accessToken,
	filters,
	companyId,
	dataloader,
}) {
	return await ClientService.client.extension.clientPerformance(
		{ filters: filters, companyId: companyId, dataloader: dataloader },
		{ token: accessToken },
	);
}
