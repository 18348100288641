import React from 'react';

import { useDispatch, useStore } from 'react-redux';

import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { setCurrentDate } from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';

import './index.scss';

const GraphNavigation = React.memo((props) => {
	const { onNextClick, onPreviousClick } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const onNavigationNext = React.useCallback(() => {
		if (onNextClick) {
			onNextClick();
		} else {
			const currentDate = store.getState()?.app?.currentDate ?? null;
			const size = store.getState()?.app?.timesize ?? null;

			const nextInterval = TimesizeUtils.addTimeslice(
				currentDate instanceof Date
					? currentDate
					: parseISO(currentDate),
				size,
			);
			dispatch(setCurrentDate(format(nextInterval, 'yyyy-MM-dd')));
		}
	}, [dispatch, onNextClick, store]);

	const onNavigationPrev = React.useCallback(() => {
		if (onPreviousClick) {
			onPreviousClick();
		} else {
			const currentDate = store.getState()?.app?.currentDate ?? null;
			const size = store.getState()?.app?.timesize ?? null;

			const prevInterval = TimesizeUtils.addTimeslice(
				currentDate instanceof Date
					? currentDate
					: parseISO(currentDate),
				size,
				-1,
			);
			dispatch(setCurrentDate(format(prevInterval, 'yyyy-MM-dd')));
		}
	}, [dispatch, onPreviousClick, store]);

	const ButtonLeftTooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get([
				'graph.navigation.previous',
				'graph.navigation.tooltip',
				'graph.navigation.previous.tooltip',
			]),
			variant: 'alt',
		}),
		[],
	);

	const ButtonRightTooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get([
				'graph.navigation.next',
				'graph.navigation.tooltip',
				'graph.navigation.next.tooltip',
			]),
			variant: 'alt',
		}),
		[],
	);

	return (
		<div className="asteria-graph-navigation">
			<Button
				className="asteria-button-left"
				size="sm"
				onClick={onNavigationPrev}
				icon="chevron-left"
				tooltip={ButtonLeftTooltip}
				analyticsKey={'graph.navigation.previous'}
			/>
			<Button
				className="asteria-button-right"
				size="sm"
				onClick={onNavigationNext}
				icon="chevron-right"
				tooltip={ButtonRightTooltip}
				analyticsKey={'graph.navigation.next'}
			/>
		</div>
	);
});

GraphNavigation.displayName = 'GraphNavigation';

GraphNavigation.propTypes = {
	onNextClick: PropTypes.func,
	onPreviousClick: PropTypes.func,
};

export default GraphNavigation;
