/**
 *
 * @param { HTMLElement } element
 * @param { string } className
 * @returns { HTMLElement }
 */
export function findParentByClassname(element, className) {
	const classNames = [].concat(className);

	if (
		classNames.some((className) =>
			element?.classList?.contains?.(className),
		)
	) {
		return element;
	}

	if (element?.parentNode) {
		return findParentByClassname(element?.parentNode, className);
	}

	return false;
}

export function findScrollingParent(node) {
	if (!node) {
		return null;
	}

	if (node.scrollHeight > node.clientHeight) {
		return node;
	}

	return findScrollingParent(node.parentNode);
}
