class ComponentService {
	components = {};

	register(tag, component, properties) {
		this.components[tag] = { component, properties };
	}

	get(tag, property = 'component') {
		if (property === '*') {
			return this.components?.[tag] ?? null;
		}

		return this.components?.[tag]?.[property] ?? null;
	}

	has(tag) {
		return !!this.components?.[tag];
	}

	list() {
		return Object.keys(this.components);
	}
}

export default new ComponentService();
