import { createChange, getPath } from '../getFormPaths';
import validateState from '../validateState';

/**
 * @template T
 * @param {{ form: T }} options
 */
function reset(options) {
	const { form } = options;

	let changes = [];

	for (const type of ['deposit', 'withdraw']) {
		if (!form?.[type]) {
			continue;
		}

		for (const date in form?.[type]?.data ?? {}) {
			if (form?.[type]?.data?.[date]?.FORECAST) {
				changes.push(
					createChange(
						{
							op: 'set',
							name: [
								getPath({
									type: type,
									date: date,
								}),
								'$state',
							].join('.'),
							value: 'pending',
							event: 'reset:state:type',
						},
						form,
					),
				);
			}

			const categories = form?.[type]?.data?.[date]?.FORECAST?.categories;

			for (const categoryName in categories ?? {}) {
				changes.push(
					createChange(
						{
							op: 'set',
							name: [
								getPath({
									type: type,
									date: date,
									category: categoryName,
								}),
								'$state',
							].join('.'),
							value: 'pending',
							event: 'reset:state:category',
						},
						form,
					),
				);

				const tags = categories?.[categoryName]?.tags;

				for (const tagName in tags ?? {}) {
					changes.push(
						createChange(
							{
								op: 'set',
								name: [
									getPath({
										type: type,
										date: date,
										category: categoryName,
										tag: tagName,
									}),
									'$state',
								].join('.'),
								value: 'pending',
								event: 'reset:state:tag',
							},
							form,
						),
					);
				}
			}
		}
	}

	const tags = Object.entries(
		['deposit', 'withdraw']
			.flatMap((type) =>
				Object.values(form?.[type]?.data ?? {}).flatMap((value) =>
					Object.entries(value?.FORECAST?.categories ?? {}).flatMap(
						([category, value]) =>
							Object.keys(value?.tags ?? {}).flatMap((tag) => ({
								type: type,
								category: category,
								tag: tag,
							})),
					),
				),
			)
			.reduce(
				(acc, object) => ({
					...acc,
					[object?.type]: {
						...acc?.[object?.type],
						[object?.category]: {
							...acc?.[object?.type]?.[object?.category],
							[object?.tag]: true,
						},
					},
				}),
				{},
			),
	)
		.flatMap(([type, value]) =>
			Object.entries(value).flatMap(([category, value]) =>
				Object.keys(value).flatMap((tag) => ({
					type: type,
					category: category,
					tag: tag,
				})),
			),
		)
		.concat([{ type: 'deposit' }, { type: 'withdraw' }]);

	return changes.concat(
		tags.flatMap(({ type, category, tag }) =>
			validateState({
				form: form,
				type: type,
				category: category,
				tag: tag,
			}),
		),
	);
}

export default reset;
