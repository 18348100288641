import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const OptionsCell = React.memo((props) => {
	const { className, data } = props;

	const dispatch = useDispatch();

	const handleClick = React.useCallback(
		() => dispatch(TransactionStore.setActive(data?._id ?? data?.id)),
		[data?._id, data?.id, dispatch],
	);

	const analytics = React.useMemo(
		() => ({ _id: data?._id ?? data?.id }),
		[data?._id, data?.id],
	);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<Button
				icon="chevron-right"
				size="sm"
				onClick={handleClick}
				analyticsKey="transaction.options"
				analytics={analytics}
				tooltip={{
					tooltip: TranslationService.get(
						'list.transaction.item.more',
					),
					variant: 'alt',
				}}
			/>
		</div>
	);
});

OptionsCell.displayName = 'OptionsCell';

OptionsCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	onAction: PropTypes.func,
};

export default OptionsCell;
