import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text, TextGroup } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import Prefix from '@asteria/component-prefix';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { Notification } from '../../PropTypes';

import './styles.scss';

const TransactionHeaderNotification = React.memo((props) => {
	const { data } = props;

	if (data?.type === 'prefixes') {
		return (
			<div
				className={cn(
					'asteria-component__transactions__header__notification',
					'asteria-component__transactions__header__notification--prefix',
				)}
			>
				{data?.data.map(({ colors }, index) => (
					<Prefix key={index} colors={colors} />
				))}
			</div>
		);
	}

	if (data?.type === 'text') {
		return (
			<Text
				className={cn(
					'asteria-component__transactions__header__notification',
					'asteria-component__transactions__header__notification--text',
				)}
			>
				{TranslationService.getByObject(data?.data)}
			</Text>
		);
	}

	return null;
});

TransactionHeaderNotification.displayName = 'TransactionHeaderNotification';

TransactionHeaderNotification.propTypes = {
	data: Notification,
};

const TransactionHeaderNotifications = React.memo((props) => {
	const { notifications } = props;

	return (
		<div className="asteria-component__transactions__header__notifications">
			{notifications.map((object, index) => (
				<TransactionHeaderNotification key={index} data={object} />
			))}
		</div>
	);
});

TransactionHeaderNotifications.displayName = 'TransactionHeaderNotifications';

TransactionHeaderNotifications.propTypes = {
	notifications: PropTypes.arrayOf(Notification),
};

const TransactionHeaderContent = React.memo((props) => {
	const { count, notifications, onAction, state } = props;

	const handleClickAll = React.useCallback(
		() =>
			onAction?.(
				'transactions:show',
				TransactionStore.constants.STATES.All,
			),
		[onAction],
	);

	const handleClickModified = React.useCallback(
		() =>
			onAction?.(
				'transactions:show',
				TransactionStore.constants.STATES.ModifiedOnly,
			),
		[onAction],
	);

	if (state) {
		return [
			<Text key="label" size="lg">
				{TranslationService.get(
					['transactions.list.visible.wrapper.title'],
					// eslint-disable-next-line spellcheck/spell-checker
					'Transaktioner',
				)}
			</Text>,
			count ? (
				<div
					key="action"
					className="asteria-component__transactions__header__actions"
				>
					<Chip
						onClick={handleClickAll}
						active={state === TransactionStore.constants.STATES.All}
						label={TranslationService.get(
							['transactions.list.wrapper.actions.all'],
							// eslint-disable-next-line spellcheck/spell-checker
							'Alla',
							{ count: count },
						)}
					/>
					<Chip
						onClick={handleClickModified}
						active={
							state ===
							TransactionStore.constants.STATES.ModifiedOnly
						}
						label={TranslationService.get(
							['transactions.list.wrapper.actions.modified'],
							// eslint-disable-next-line spellcheck/spell-checker
							'Nya ({{ count }})',
							{ count: count },
						)}
					/>
				</div>
			) : null,
		];
	}

	if (notifications?.length) {
		return <TransactionHeaderNotifications notifications={notifications} />;
	}

	if (count) {
		return [
			<Icon key="icon" icon="document" />,
			<TextGroup key="label">
				<Text size="lg">
					{TranslationService.get(
						['transactions.list.wrapper.title.count'],
						'{{ count | number : false : false : true }}',
						{ count: count },
					)}
				</Text>
				<Text size="lg">
					{TranslationService.get(
						['transactions.list.wrapper.title.label'],
						// eslint-disable-next-line spellcheck/spell-checker
						'Nya transaktioner',
						{ count: count },
					)}
				</Text>
			</TextGroup>,
		];
	}

	return [
		<Icon key="icon" icon="document" />,
		<Text key="label" size="lg">
			{TranslationService.get(
				['transactions.list.empty.wrapper.title'],
				// eslint-disable-next-line spellcheck/spell-checker
				'Visa transaktioner',
			)}
		</Text>,
	];
});

TransactionHeaderContent.displayName = 'TransactionHeaderContent';

TransactionHeaderContent.propTypes = {
	count: PropTypes.number,
	onAction: PropTypes.func,
	notifications: PropTypes.arrayOf(Notification),

	state: PropTypes.string,
};

export default TransactionHeaderContent;
