import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';
import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useUnreadMessage } from './hooks';

const ContactUsDialog = React.memo(function ContactUsDialog(props) {
	const { className, onAction, onSubmit } = props;

	const content = useConfig(['pages.support.dialog'], { deep: true });

	const open = useSelector(
		(store) =>
			!store?.app?.user?.settings?.flags?.['dialog:close']?.['support'],
	);

	const unread = useUnreadMessage({
		onSubmit: onSubmit,
	});

	const onClose = React.useCallback(
		() => onAction?.('dialog:close', { type: 'support' }),
		[onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	const data = React.useMemo(() => ({ unread: unread }), [unread]);

	return (
		<Dialog
			className={cn('asteria-component__support-dialog', className)}
			placement="bottom-right"
			open={open}
		>
			<Wrapper>
				<Header
					onClose={onClose}
					postfix={{ size: 'xs', position: 'absolute' }}
				>
					{TranslationService.get(['support.dialog.title'])}
				</Header>
				<Content>
					<Contenter
						content={content}
						actions={actions}
						data={data}
					/>
				</Content>
			</Wrapper>
		</Dialog>
	);
});

ContactUsDialog.displayName = 'ContactUsDialog';

ContactUsDialog.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ContactUsDialog;
