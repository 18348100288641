import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Filter = React.forwardRef((props, ref) => {
	const { className, children, ...rest } = props;

	return (
		<div
			className={cn(
				'asteria-component__list__filter',
				className,
				sizeClasses(props),
			)}
			{...rest}
			ref={ref}
		>
			{children}
		</div>
	);
});

Filter.displayName = 'Filter';
Filter.propTypes = {
	className: PropTypes.string,
	size: SizeProp(),
	children: PropTypes.node,
};

Filter.defaultProps = {};

export default React.memo(Filter);
