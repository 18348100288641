import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';

import './index.scss';

const DatePickerHeader = (props) => {
	const { type = 'day', types, onSelect, locale } = props;

	const availableTypes = useMemo(
		() =>
			types.map((type) => ({
				label: TranslationService.get(`datepicker.${type}`),
				value: type,
			})),
		[locale, types],
	);

	return (
		<div className="asteria-component__datepicker__content__header">
			{availableTypes.map(({ label, value }) => (
				<div
					key={value}
					className={cn(
						'asteria-component__datepicker__content__header__item',
						`asteria-component__datepicker__content__header__item--type-${value}`,
						stateClasses({ active: type === value }),
					)}
					onClick={() => {
						onSelect(value);
					}}
				>
					<Text>{label}</Text>
				</div>
			))}
		</div>
	);
};

DatePickerHeader.propTypes = {
	locale: Constants.DatePickerLocalePropType,
	types: PropTypes.arrayOf(Constants.DatePickerTypesPropType),

	type: Constants.DatePickerTypesPropType,
	onSelect: PropTypes.func,
};

DatePickerHeader.defaultProps = {
	type: 'day',
};

export default DatePickerHeader;
