import React from 'react';

import PropTypes from 'prop-types';

import Wrapper, {
	Content,
	Footer,
	Header,
} from '@asteria/component-core/wrapper';

import { cn } from '@asteria/utils-funcs/classes';

import CardOnboardingFooter from './components/footer';

import './styles.scss';

/** @type { typeof Header } */
const CardHeader = React.memo(function CardHeader(props) {
	return (
		<Header
			{...props}
			className={cn('asteria-component__card-header', props.className)}
		/>
	);
});

CardHeader.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

/** @type { typeof Content } */
const CardContent = React.memo(function CardContent(props) {
	return (
		<Content
			{...props}
			className={cn('asteria-component__card-content', props.className)}
		/>
	);
});

CardContent.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

/** @type { typeof Footer } */
const CardFooter = React.memo(function CardFooter(props) {
	return (
		<Footer
			{...props}
			className={cn('asteria-component__card-footer', props.className)}
		/>
	);
});

CardFooter.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

function compare(type, node) {
	if (type === 'header') {
		return Object.is(node?.type, CardHeader);
	}

	if (type === 'content') {
		return Object.is(node?.type, CardContent);
	}

	if (type === 'footer') {
		return (
			Object.is(node?.type, CardFooter) ||
			Object.is(node?.type, CardOnboardingFooter)
		);
	}

	return false;
}

/** @type { typeof Wrapper & { Header: typeof CardHeader, Content: typeof CardContent, Footer: typeof CardFooter } } */
const Card = React.memo(function Card(props) {
	const { className, children, ...args } = props;

	return (
		<Wrapper
			className={cn('asteria-component__card', className)}
			compare={compare}
			{...args}
		>
			{children}
		</Wrapper>
	);
});

Card.displayName = 'Card';
Card.propTypes = { className: PropTypes.string, children: PropTypes.node };

Object.assign(Card, {
	Header: CardHeader,
	Content: CardContent,
	Footer: CardFooter,
});

export default Card;
