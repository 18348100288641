import { SECTIONS } from './constants';

/**
 * @template T
 * @param { T } state
 * @returns { T }
 */
export const validateNavigation = (state) => {
	const type = state?.navigation?.type;
	const category = state?.navigation?.category;
	const tag = state?.navigation?.tag;

	if (tag) {
		const isTagAvailable = state?.tags?.some?.(
			(object) =>
				object?.type === type &&
				object?.categoryName === category &&
				object?.tagName === tag,
		);

		if (!isTagAvailable) {
			state.navigation.category = null;
			state.navigation.tag = null;
		}
	}

	if (!state?.navigation?.tag) {
		let next = null;

		if (state.navigation.section === SECTIONS.HARD_TO_FORECAST) {
			next = (state.tags ?? []).find((object) => object?.alert);
		}

		if (!next || state.navigation.section === SECTIONS.ALL_CATEGORIES) {
			next = (state?.tags ?? [])
				.filter((object) => object?.type === type)
				.find((object) => object?.categoryName !== '$type');

			if (!next && type === 'deposit') {
				next = (state?.tags ?? [])
					.filter((object) => object?.type === 'withdraw')
					.find((object) => object?.categoryName !== '$type');
			}
		}

		if (next) {
			state.navigation.category = next?.categoryName;
			state.navigation.tag = next?.tagName;
			state.navigation.type = next?.type;
		} else {
			state.navigation.section = SECTIONS.DEPOSIT;
			state.navigation.type = 'deposit';
			state.navigation.category = '$type';
			state.navigation.tag = '$deposit';
		}
	}

	return state;
};

function formatNavigation(section, next) {
	return {
		section: section,
		type: next?.type,
		category: next?.categoryName,
		tag: next?.tagName,
	};
}

function findNavigation(tags, { type, category, tag }) {
	const index = tags.findIndex(
		(object) =>
			object?.type === type &&
			object?.categoryName === category &&
			object?.tagName === tag,
	);

	return { next: tags[index + 1], previous: tags[index - 1] };
}

export function findNextNavigation(state) {
	const section = state?.navigation?.section;
	const type = state?.navigation?.type;
	const category = state?.navigation?.category;
	const tag = state?.navigation?.tag;

	if (!section || section === SECTIONS.DEPOSIT) {
		return {
			section: SECTIONS.WITHDRAW,
			type: 'withdraw',
			category: '$type',
			tag: '$withdraw',
		};
	}

	if (section === SECTIONS.WITHDRAW) {
		const warning = (state?.tags ?? []).filter((object) => object?.alert);

		if (warning.length) {
			const next = warning[0];

			return formatNavigation(SECTIONS.HARD_TO_FORECAST, next);
		}

		const tags = (state.tags ?? []).filter(
			(object) => object?.categoryName !== '$type',
		);

		if (tags.length) {
			const next = tags[0];

			return formatNavigation(SECTIONS.ALL_CATEGORIES, next);
		}

		return { section: SECTIONS.OVERVIEW, type: 'overview' };
	}

	if (section === SECTIONS.HARD_TO_FORECAST) {
		const warning = findNavigation(
			(state?.tags ?? []).filter((object) => object?.alert),
			{ type: type, category: category, tag: tag },
		);

		if (warning.next) {
			return formatNavigation(SECTIONS.HARD_TO_FORECAST, warning.next);
		}

		const deposit = (state.tags ?? [])
			.filter((object) => object?.categoryName !== '$type')
			.filter((object) => object?.type === 'deposit');

		if (deposit.length) {
			const next = deposit[0];

			return formatNavigation(SECTIONS.ALL_CATEGORIES, next);
		}

		const withdraw = (state.tags ?? [])
			.filter((object) => object?.categoryName !== '$type')
			.filter((object) => object?.type === 'withdraw');

		if (withdraw.length) {
			const next = withdraw[0];

			return formatNavigation(SECTIONS.ALL_CATEGORIES, next);
		}

		return { section: SECTIONS.OVERVIEW, type: 'overview' };
	}

	if (section === SECTIONS.ALL_CATEGORIES) {
		const available = (state.tags ?? []).filter(
			(object) => object?.categoryName !== '$type',
		);

		let tags = findNavigation(
			available.filter((object) => object?.type === type),
			{ type: type, category: category, tag: tag },
		);

		if (!tags.next && type === 'deposit') {
			tags = findNavigation(
				available.filter((object) => object?.type === 'withdraw'),
				{ type: type, category: category, tag: tag },
			);
		}

		if (tags.next) {
			return formatNavigation(SECTIONS.ALL_CATEGORIES, tags.next);
		}

		return { section: SECTIONS.OVERVIEW, type: 'overview' };
	}
}

export function findPreviousNavigation(state) {
	const section = state?.navigation?.section;
	const type = state?.navigation?.type;
	const category = state?.navigation?.category;
	const tag = state?.navigation?.tag;

	if (section === SECTIONS.DEPOSIT) {
		return null;
	}

	if (section === SECTIONS.WITHDRAW) {
		return {
			section: SECTIONS.DEPOSIT,
			type: 'deposit',
			category: '$type',
			tag: '$deposit',
		};
	}

	if (section === SECTIONS.HARD_TO_FORECAST) {
		const warning = findNavigation(
			(state?.tags ?? []).filter((object) => object?.alert),
			{ type: type, category: category, tag: tag },
		);

		if (warning.previous) {
			return formatNavigation(
				SECTIONS.HARD_TO_FORECAST,
				warning.previous,
			);
		}

		return {
			section: SECTIONS.WITHDRAW,
			type: 'withdraw',
			category: '$type',
			tag: '$withdraw',
		};
	}

	if (section === SECTIONS.ALL_CATEGORIES) {
		const tags = findNavigation(
			(state.tags ?? []).filter(
				(object) => object?.categoryName !== '$type',
			),
			{ type: type, category: category, tag: tag },
		);

		if (tags.previous) {
			return formatNavigation(SECTIONS.ALL_CATEGORIES, tags.previous);
		}

		const warning = (state?.tags ?? []).filter((object) => object?.alert);

		if (warning.length) {
			const next = warning.slice(-1)[0];

			return formatNavigation(SECTIONS.HARD_TO_FORECAST, next);
		}

		return {
			section: SECTIONS.WITHDRAW,
			type: 'withdraw',
			category: '$type',
			tag: '$withdraw',
		};
	}

	if (section === SECTIONS.OVERVIEW) {
		const withdraw = (state.tags ?? [])
			.filter((object) => object?.categoryName !== '$type')
			.filter((object) => object?.type === 'withdraw');

		if (withdraw.length) {
			const next = withdraw.slice(-1)[0];

			return formatNavigation(SECTIONS.ALL_CATEGORIES, next);
		}

		const deposit = (state.tags ?? [])
			.filter((object) => object?.categoryName !== '$type')
			.filter((object) => object?.type === 'deposit');

		if (deposit.length) {
			const next = deposit.slice(-1)[0];

			return formatNavigation(SECTIONS.ALL_CATEGORIES, next);
		}

		return {
			section: SECTIONS.WITHDRAW,
			type: 'withdraw',
			category: '$type',
			tag: '$withdraw',
		};
	}
}
