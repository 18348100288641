import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Card from '../../base';
import Wrapper from '../../components/wrapper';
import {
	useCardPinning,
	useDateVariant,
	useOnboardingState,
	useTranslationData,
} from '../../hooks';
import FeedbackCard from '../feedback';

import Footer from './footer';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 * @property { string } type
 * @property { string | { type?: string, onboarding?: string } } connect
 * @property { unknown } card
 * @property { Partial<{ promotion: Partial<{ dismiss: boolean, feedback: boolean }> }> } [state]
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]

 */

/** @type { React.FC<Props> } */
const PromotionCard = React.memo(function PromotionCard(props) {
	const {
		className,
		startDate,
		endDate,
		onAction,
		onSubmit,
		type,
		state,
		postfix,
		show,
		connect,

		card,
	} = props;

	const WrapperComponent = props?.wrapper?.as ?? Wrapper;

	const variant = useDateVariant({ startDate, endDate });
	const pinned = useCardPinning({ type: type });

	const onboarding = useOnboardingState(connect);

	const { postfix: translationPostfix, data: translationData } =
		useTranslationData({
			type: type,

			past: variant === 'past',
			today: variant === 'today',
			future: variant === 'future',

			connect: connect,

			extra: { data: { card: card } },
		});

	const translationOptions = React.useMemo(
		() => ({
			data: translationData,
			postfix: translationPostfix,
		}),
		[translationData, translationPostfix],
	);

	const dismissData = React.useMemo(
		() => ({
			...state?.promotion?.dismiss,
			onboarding: onboarding,
		}),
		[onboarding, state?.promotion?.dismiss],
	);

	const feedbackData = React.useMemo(
		() => state?.promotion?.feedback,
		[state?.promotion?.feedback],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'card:back') {
				return onAction?.('card:promotion:back', data);
			}

			if (action === 'card:close') {
				if (data?.variant === 'card' && data?.step === 0) {
					return onAction?.('card:promotion:dismiss', data);
				}

				return onAction?.('card:promotion:close', data);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	if (state?.promotion?.dismiss?.visible) {
		return (
			<WrapperComponent {...props?.wrapper?.props} show={show}>
				<FeedbackCard
					from={type}
					onAction={handleAction}
					onSubmit={onSubmit}
					data={dismissData}
					card={card}
				/>
			</WrapperComponent>
		);
	}

	if (state?.promotion?.feedback?.visible) {
		return (
			<WrapperComponent {...props?.wrapper?.props} show={show}>
				<FeedbackCard
					variant="card"
					from={type}
					onAction={handleAction}
					onSubmit={onSubmit}
					data={feedbackData}
					card={card}
				/>
			</WrapperComponent>
		);
	}

	return (
		<WrapperComponent {...props?.wrapper?.props} show={show}>
			<Card
				className={cn(
					`asteria--variant-${type}`,
					{ 'asteria--state-pinned': pinned },
					className,
				)}
			>
				<Card.Header postfix={postfix}>
					<Group>
						<Translation
							translationKey={[
								'card.title',
								'card.title.promotion',
							]}
							translationOptions={translationOptions}
							Component={Title}
						/>
					</Group>
				</Card.Header>
				<Card.Content>
					<TextGroup className="flex flex-col gap-2">
						<Translation
							translationKey={[
								'card.content.title',
								'card.content.title.promotion',
							]}
							translationOptions={translationOptions}
							showWhenEmpty={false}
							Component={Title}
						/>
						<Translation
							translationKey={[
								'card.content.text',
								'card.content.text.promotion',
							]}
							translationOptions={translationOptions}
							showWhenEmpty={false}
							Component={Text}
						/>
					</TextGroup>
				</Card.Content>
				<Footer
					dismiss={{
						postfix: {
							type: type,
							onboarding: onboarding,
							past: variant === 'past',
							today: variant === 'today',
							future: variant === 'future',
						},
						analyticsKey: `card.${type}.footer.action.dismiss`,
					}}
					connect={{
						postfix: {
							type: type,
							onboarding: connect?.onboarding
								? connect?.onboarding
								: onboarding,
							past: variant === 'past',
							today: variant === 'today',
							future: variant === 'future',
						},
						analyticsKey: `card.${type}.footer.action.connect`,
					}}
					onAction={handleAction}
					onSubmit={onSubmit}
					form={{
						from: type,
						type:
							connect?.type ?? connect
								? connect?.type ?? connect
								: onboarding === 'none'
								? null
								: onboarding === 'erp'
								? 'bank'
								: 'erp',
					}}
				/>
			</Card>
		</WrapperComponent>
	);
});

PromotionCard.displayName = 'PromotionCard';

PromotionCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	type: PropTypes.string,

	postfix: PropTypes.object,
	state: PropTypes.shape({
		promotion: PropTypes.shape({
			dismiss: PropTypes.object,
			feedback: PropTypes.object,
		}),
	}),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
	show: PropTypes.bool,

	connect: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			type: PropTypes.string,
			onboarding: PropTypes.string,
		}),
	]),
	card: PropTypes.object,
};

export default PromotionCard;
