import axios from 'axios';

import AsteriaCore from '@asteria/core';

import { VoucherService } from '@asteria/backend-utils-services';

import { setStatement } from '@asteria/datalayer/stores/app';

const DEFAULT_FIELDS = `
accountingResults {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    total
    currency
    badges
}

accountingBalanceSheet {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    currency
    badges
}

accountingWorkingCapital {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    total
    currency
    badges
}

accountingMisc {
    years
    fiscalYears {
        start
        end
    }
    rows {
        label
        values
        rows {
            label
            values
            total
            badges
            rows {
                label
                values
                total
                badges
            }
        }
        total
        badges
    }
    total
    currency
    badges
}
`;

VoucherService.voucher.extend({
	getQuery: (fields = DEFAULT_FIELDS) => `
    query Statement {
        ${fields}
    }
  `,
	key: `results`,
	loggerMethod: `services.voucherService.results`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.voucherService.results`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

export async function statement(options) {
	const { accessToken, dispatch } = options;

	const response = await VoucherService.voucher.extension
		.results({}, { token: accessToken })
		.catch(() => {
			return {};
		});

	dispatch?.(setStatement(response));

	return response;
}

export async function download(options) {
	const { accessToken } = options;

	const baseURL = await AsteriaCore.Configuration.getValueAsync({
		key: 'services.api-gateway.api',
	});

	try {
		const response = await axios({
			baseURL: baseURL,
			url: '/services/voucher-service/expenses/download/',
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}).then(({ data }) => data);

		const blob = new Blob([response], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('href', url);
		a.setAttribute('download', 'statement.csv');
		a.click();

		return response;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.warn('statement.download', err);
	}
}
