import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Spinner from '@asteria/component-core/spinner';
import { Text } from '@asteria/component-core/typography';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SectionSaving = (props) => {
	const { className, variant, onAction } = props;

	const { getValues } = useFormContext();

	const saving = useSelector(ForecasterStore.selectors.flags.isSaving);
	const lastSaveAt = useSelector(ForecasterStore.selectors.flags.lastSaveAt);

	const tooltip = React.useMemo(
		() => ({
			variant: 'alt',
			tooltip: TranslationService.get(
				[
					'forecaster.page.category.saving.action.tooltip',
					lastSaveAt
						? 'forecaster.page.category.saving.saved.action.tooltip'
						: 'forecaster.page.category.saving.initial.action.tooltip',
				],
				undefined,
				{ date: lastSaveAt },
			),
		}),
		[lastSaveAt],
	);

	const onSave = React.useCallback(
		() => onAction?.('form:save', { form: getValues() }),
		[getValues, onAction],
	);

	if (variant !== 'category' || (!lastSaveAt && !saving)) {
		return null;
	}

	return (
		<Group
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-saving',
				className,
			)}
			verticalAlign="center"
			horizontalAlign="center"
		>
			{saving ? (
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="center"
				>
					<Spinner />
					<Text size="sm">
						{TranslationService.get([
							'forecaster.page.category.saving.label',
						])}
					</Text>
				</Group>
			) : (
				<Button
					variant="secondary"
					size="sm"
					icon={lastSaveAt ? 'check' : null}
					label={TranslationService.get(
						[
							'button.save',
							'action.save',
							'forecaster.page.category.saving.action.label',
						].concat(
							lastSaveAt
								? 'forecaster.page.category.saving.saved.action.label'
								: 'forecaster.page.category.saving.initial.action.label',
						),
					)}
					onClick={onSave}
					tooltip={lastSaveAt ? tooltip : null}
				/>
			)}
		</Group>
	);
};

SectionSaving.displayName = 'SectionSaving';

SectionSaving.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
	onAction: PropTypes.func,
};

export default SectionSaving;
