import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { cn } from '@asteria/utils-funcs/classes';

import '../styles.scss';

import './index.scss';

const DropdownClient = ({
	data,
	showEdit,
	showSelected,
	selectedIcon = 'check',
	handleClientSelect,
	active,
}) => {
	const dispatch = useDispatch();

	const handleEditTag = useCallback(
		(data) => {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.ClientEdit,
					data: { data: data },
				}),
			);
		},
		[dispatch],
	);

	const handleClick = useCallback(
		() => handleClientSelect(data),
		[data, handleClientSelect],
	);

	const handleEdit = useCallback(
		() => handleEditTag(data),
		[data, handleEditTag],
	);

	const postfix = React.useMemo(
		() => (
			<Group
				horizontalAlign="center"
				verticalAlign="center"
				className={cn(
					'asteria-component__forecaster-selector__helper',
					'asteria-component__forecaster-clients__helper',
				)}
			>
				{showSelected ? (
					<Button
						icon={selectedIcon}
						size="sm"
						className={cn(
							'asteria-component__forecaster-selector__helper__element',
							'asteria-component__forecaster-selector__helper__element--type-check',
						)}
						variant="icon-check"
					/>
				) : null}
				{showEdit ? (
					<Button
						className={cn(
							'asteria-component__forecaster-selector__helper__element',
							'asteria-component__forecaster-selector__helper__element--type-edit',
						)}
						icon="edit"
						stopPropagation
						onClick={handleEdit}
						variant="forecaster-edit"
					/>
				) : null}
			</Group>
		),
		[handleEdit, selectedIcon, showEdit, showSelected],
	);

	const analytics = React.useMemo(
		() => ({ name: data?.name, _id: data?._id }),
		[data?._id, data?.name],
	);

	return (
		<DropdownItem
			className={cn(
				'asteria-component__forecaster-selector__item',
				'asteria-component__forecaster-clients__item',
				{ 'asteria--state-hoverable': showSelected && showEdit },
			)}
			onClick={handleClick}
			postfix={postfix}
			analyticsKey="forecaster.client.item"
			analytics={analytics}
			active={active}
		>
			<Text size="sm">{data?.name}</Text>
		</DropdownItem>
	);
};

DropdownClient.propTypes = {
	data: PropTypes.object,
	colors: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	selectedIcon: PropTypes.string,
	showEdit: PropTypes.bool,
	showSelected: PropTypes.bool,
	handleClientSelect: PropTypes.func,

	active: PropTypes.bool,
};

DropdownClient.defaultProps = {};

export default React.memo(DropdownClient);
