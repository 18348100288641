/**
 * @template TFunc
 * @param { TFunc } func
 * @returns { TFunc }
 */
export const withLocalStorage = (func) => {
	return async (options) => {
		let { headers } = options;

		if (!headers) {
			headers = {};
		}

		const accessToken = getStorage().getItem('wingsToken');

		if ((headers?.Authorization || headers?.authorization) && accessToken) {
			delete headers?.Authorization;
			delete headers?.authorization;

			headers = { ...headers, Authorization: `Bearer ${accessToken}` };
		}

		return func({ ...options, headers: headers });
	};
};

/**
 * @returns { Storage }
 */
export function getStorage() {
	return localStorage.getItem('wingsRefreshToken') ||
		localStorage.getItem('wingsToken')
		? localStorage
		: sessionStorage;
}

/**
 * @param { Storage } storage
 */
export function getTokens(storage) {
	return {
		accessToken: storage.getItem('wingsToken'),
		refreshToken: storage.getItem('wingsRefreshToken'),
	};
}

/**
 * @param { Storage } storage
 * @param { { accessToken: string, refreshToken: string } } data
 */
export function setTokens(storage, data) {
	storage.setItem('wingsToken', data?.accessToken);
	storage.setItem('wingsRefreshToken', data?.refreshToken);
}

/**
 * @param { Storage } storage
 */
export function clearTokens(storage) {
	storage.removeItem('wingsToken');
	storage.removeItem('wingsRefreshToken');
}
