import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import List, { ListGroup, ListHeader, ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import Guide from '../../../components/Guide';

import './styles.scss';

const Action = (props) => {
	const { action, integration, onAction } = props;

	const { action: type, data, status } = action || {};
	const [showGuide, setShowGuide] = React.useState(false);

	const onClick = React.useCallback(
		() => onAction?.(action),
		[action, onAction],
	);

	const config = useConfig([
		`integration.actions.${type}`,
		`integration.actions.${integration?.key}.${type}`,
	]);

	const GuideProps = React.useMemo(
		() => ({ integration: { type: type, key: integration?.key } }),
		[integration?.key, type],
	);

	if (!action) {
		return null;
	}

	return (
		<ListItem
			className={cn(
				'asteria-component__onboarding-action',
				`asteria-component__onboarding-action--status-${status?.toLowerCase()}`,
			)}
		>
			{config?.guide ? (
				<Guide
					open={showGuide}
					steps={config?.guide?.steps}
					onClose={() => setShowGuide(false)}
					{...GuideProps}
				/>
			) : null}
			<TextGroup>
				<Title size="xxs">
					{TranslationService.get(
						[
							`integrations.actions.${type}`,
							`integrations.${integration.type}.actions.${type}`,
							`integrations.${integration.type}.${integration.key}.actions.${type}`,
						],
						undefined,
						{ integration: integration },
					)}
				</Title>
				<Text size="xs">
					{TranslationService.get(
						[
							`integrations.actions.${type}.message`,
							`integrations.${integration.type}.actions.${type}.message`,
							`integrations.${integration.type}.${integration.key}.actions.${type}.message`,
							data?.message,
						],
						data?.message,
						{ message: data?.message },
					)}
				</Text>
			</TextGroup>

			<div className="asteria-component__onboarding-action__actions">
				{config?.guide && status !== 'PROCESSED' ? (
					<Button
						//label={TranslationService.get('label.Guide')}
						onClick={() => setShowGuide(true)}
						icon="help"
					/>
				) : null}

				{status !== 'PROCESSED' ? (
					<>
						{status === 'PENDING' ? (
							<Button
								className="asteria-component__button__action"
								size="sm"
								variant="primary"
								label={TranslationService.get([
									'action.done',
									'integrations.actions.done',
								])}
								onClick={onClick}
							/>
						) : null}
						<Button
							//label={TranslationService.get('action.done')}
							disabled
							loading={status === 'PROGRESS'}
							icon={status !== 'PENDING' ? 'warning' : 'clock'}
							className={cn({
								'asteria-component__onboarding-actions__failed-icon':
									status !== 'PENDING',
								'asteria-component__onboarding-actions__pending-icon':
									status === 'PENDING',
							})}
						/>
					</>
				) : null}

				{status === 'PROCESSED' ? (
					<Button
						icon="check"
						disabled
						className="asteria-component__onboarding-actions__success-icon"
					/>
				) : null}
			</div>
		</ListItem>
	);
};

Action.propTypes = {
	action: PropTypes.any,
	integration: PropTypes.object,
	onAction: PropTypes.func,
};

const IntegrationConnectionActions = function GenericActions(props) {
	const { className, integration, actions, onAction } = props;

	if (!actions?.length) {
		return null;
	}

	return (
		<div className={cn('asteria-component__onboarding-actions', className)}>
			<List
				className="asteria-component__onboarding-list"
				size="lg"
				applyTypographySizes={false}
			>
				<ListGroup>
					<ListHeader>
						<ListItem>
							<Title size="xxs">
								{TranslationService.get([
									`integrations.actions`,
									`integrations.${integration.type}.actions`,
									`integrations.${integration.type}.${integration.key}.actions`,
								])}
							</Title>
						</ListItem>
					</ListHeader>
					{actions?.map((action) => (
						<Action
							key={action._id}
							action={action}
							integration={integration}
							onAction={onAction}
						/>
					))}
				</ListGroup>
			</List>
		</div>
	);
};

IntegrationConnectionActions.propTypes = {
	className: PropTypes.string,
	integration: PropTypes.object,
	onAction: PropTypes.func,
	actions: PropTypes.arrayOf(PropTypes.object),
};

IntegrationConnectionActions.defaultProps = {};

export default IntegrationConnectionActions;
