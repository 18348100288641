import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import ForecastCategoryCleanModal from '../../../modals/tag/clean';
import { recalculate as recalculateCategory } from '../../../utils/adjustCategory';
import {
	setProbability,
	setValue as setTagValue,
} from '../../../utils/adjustTag';
import applyFormChanges from '../../../utils/applyFormChanges';

const CleanModalWrapper = (props) => {
	const { children, onAction, onClose, open, ...rest } = props;

	const { getValues, setValue, unregister, reset } = useFormContext();

	const onSubmit = React.useCallback(
		(event, { type, category, tag }) => {
			const form = getValues();

			const changes = []
				.concat(setTagValue({ type, category, tag, value: 0, form }))
				.concat(setProbability({ type, category, tag, value: 1, form }))
				.concat(recalculateCategory({ type, category, form }));

			applyFormChanges({
				changes,
				getValues,
				setValue,
				unregister,
				reset,
				event: 'category:remove',
			});

			onAction?.('form:save', { form: getValues() });

			return onClose?.(event);
		},
		[getValues, onAction, onClose, reset, setValue, unregister],
	);

	return (
		<>
			<ForecastCategoryCleanModal
				open={open}
				onClose={onClose}
				onSubmit={onSubmit}
				{...rest}
			/>
			{children}
		</>
	);
};

CleanModalWrapper.displayName = 'CleanModalWrapper';
CleanModalWrapper.propTypes = {
	children: PropTypes.node,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

export default CleanModalWrapper;
