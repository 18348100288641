import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { useColor } from './utils';

import '../index.scss';

const IndeterminateCircle = React.memo((props) => {
	const { className, color } = props;

	const { style: colorStyle, className: colorClassName } = useColor(color);
	const style = { ...colorStyle };

	return (
		<div
			className={cn(
				'asteria-component__progress asteria-component__progress--variant-circle asteria-component__progress--variant-circle-indeterminate',
				className,
				colorClassName,
			)}
			style={style}
		>
			<div className="asteria-component__progress__value" />
		</div>
	);
});

IndeterminateCircle.propTypes = {
	className: PropTypes.string,
	color: PropTypes.shape({
		type: PropTypes.string,
		value: PropTypes.string,
	}),
};

IndeterminateCircle.displayName = 'IndeterminateCircle';

export default IndeterminateCircle;
