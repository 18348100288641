import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import TransactionGroupContent from './content';
import TransactionGroupHeader from './header';

import '../../styles.scss';

const TransactionGroup = React.memo(
	React.forwardRef((props, ref) => {
		const { onAction, date, category, tag, types } = props;

		return (
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-group',
				)}
			>
				<TransactionGroupHeader
					onAction={onAction}
					date={date}
					types={types}
					category={category}
					tag={tag}
				/>
				<TransactionGroupContent
					onAction={onAction}
					date={date}
					types={types}
					category={category}
					tag={tag}
					ref={ref}
				/>
			</div>
		);
	}),
);

TransactionGroup.displayName = 'TransactionGroup';

TransactionGroup.propTypes = {
	onAction: PropTypes.func,

	types: PropTypes.arrayOf(PropTypes.string),
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default TransactionGroup;
