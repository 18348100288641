import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import LoadingScreen from '@asteria/layout/loading';

const selectors = {
	isGraphLoading: createSelector(
		(store) => store?.graph?.loading,
		(value) => value ?? true,
	),
};

const Loader = React.memo(() => {
	const [isLoading, setLoading] = React.useState(true);
	const isGraphLoading = useSelector(selectors.isGraphLoading);
	const loadingRef = React.useRef(null);

	const integrationsIsLoading = useSelector(
		IntegrationStore.selectors.isLoading,
	);
	const integrationsIsFetching = useSelector(
		IntegrationStore.selectors.isFetching,
	);

	React.useEffect(() => {
		if (loadingRef.current) {
			setLoading(isGraphLoading);

			loadingRef.current = isGraphLoading;
		}

		if (loadingRef.current === null) {
			if (isGraphLoading) {
				loadingRef.current = true;
			}
		}
	}, [isGraphLoading]);

	if (isLoading || integrationsIsLoading || integrationsIsFetching) {
		return <LoadingScreen blur type="credit.loading" />;
	}

	return null;
});

Loader.displayName = 'Loader';

Loader.propTypes = { className: PropTypes.string };

export default Loader;
