import { format, parseISO } from 'date-fns';
import { get, set } from 'lodash-es';

import store from '@asteria/datalayer';

import * as AdjustCategory from './adjustCategory';
import * as AdjustType from './adjustType';
import { createChange, getPath } from './getFormPaths';
import getProbability from './getProbability';
import validateState from './validateState';

function clean(object) {
	return Object.fromEntries(Object.entries(object).filter(([key]) => key));
}

export function validateFormTags(options) {
	const { form, store } = options;

	const available = (store?.getState()?.app?.tags ?? []).reduce(
		(acc, object) => ({
			...acc,
			[object?.category?.name]: {
				...acc?.[object?.category?.name],
				[object?.name]: object,
			},
		}),
		{},
	);

	let changes = [];

	for (const type of ['deposit', 'withdraw']) {
		if (!form[type]) {
			continue;
		}

		for (const date in form?.[type]?.data ?? {}) {
			const data = form?.[type]?.data?.[date]?.FORECAST;

			if (!data) {
				continue;
			}

			const categories = clean(data?.categories ?? {});

			let hasTypeChanges = false;

			for (const category in categories) {
				if (!available?.[category]) {
					changes.push(
						createChange(
							{
								op: 'unset',
								name: getPath({
									type: type,
									date: date,
									category: category,
								}),
								previousValue: categories[category],
								event: 'validate:tags:category',
							},
							form,
						),
					);

					hasTypeChanges = true;

					continue;
				}

				const tags = clean(categories?.[category]?.tags ?? {});

				let hasCategoryChanges = false;

				for (const tag in tags) {
					if (!available?.[category]?.[tag]) {
						changes.push(
							createChange(
								{
									op: 'unset',
									name: getPath({
										type: type,
										date: date,
										category: category,
										tag: tag,
									}),
									previousValue: tags[tag],
									event: 'validate:tags:tag',
								},
								form,
							),
						);

						hasCategoryChanges = true;
						hasTypeChanges = true;

						continue;
					}
				}

				if (hasCategoryChanges) {
					changes = changes.concat(
						AdjustCategory.recalculate({
							type: type,
							category: category,
							form: form,
							date: date,
						}),
					);
				}
			}

			if (hasTypeChanges) {
				changes = changes.concat(
					AdjustType.recalculate({
						type: type,
						form: form,
						date: date,
					}),
				);
			}
		}
	}

	return changes;
}

export function validateFormClients(options) {
	const { form, store } = options;

	const clients = (store?.getState()?.app?.clients ?? []).reduce(
		(acc, object) => ({ ...acc, [object?._id ?? object.id]: object }),
		{},
	);

	let changes = [];

	for (const type of ['deposit', 'withdraw']) {
		if (!form[type]) {
			continue;
		}

		for (const date in form?.[type]?.data ?? {}) {
			const data = form?.[type]?.data?.[date]?.FORECAST;

			if (!data) {
				continue;
			}

			const categories = clean(data?.categories ?? {});

			for (const category in categories) {
				const tags = clean(categories?.[category]?.tags ?? {});

				for (const tag in tags) {
					const transactions = clean(tags?.[tag]?.transactions ?? {});

					for (const uuid in transactions) {
						const clientId = transactions[uuid]?.descriptionClient;

						if (clientId) {
							if (!clients[clientId]) {
								changes.push(
									createChange(
										{
											op: 'unset',
											name: getPath({
												type: type,
												date: date,
												category: category,
												tag: tag,
												uuid: uuid,
												field: 'descriptionClient',
											}),
											previousValue: clientId,
											event: 'validate:clients',
										},
										form,
									),
								);
							}
						}
					}
				}
			}
		}
	}

	return changes;
}

export default function validateFormValues(form, scenario) {
	for (const type of ['deposit', 'withdraw']) {
		if (!form[type]) {
			continue;
		}

		for (const date in form?.[type]?.data ?? {}) {
			const data = form?.[type]?.data?.[date]?.FORECAST;

			if (!data) {
				continue;
			}

			const path = getPath({ type: type, date: date });

			const categories = clean(data?.categories ?? {});

			for (const category in categories) {
				const tags = clean(categories?.[category]?.tags ?? {});

				const path = getPath({
					type: type,
					date: date,
					category: category,
				});

				for (const tag in tags) {
					const transactions = clean(tags?.[tag]?.transactions ?? {});

					const path = getPath({
						type: type,
						date: date,
						category: category,
						tag: tag,
					});

					const objects = Object.values(transactions ?? {});

					const value = objects.reduce(
						(acc, object) => acc + (object?.total ?? 0),
						0,
					);

					const probability = getProbability(
						objects.map((object) => ({
							value: object?.total ?? 0,
							probability: object?.probability ?? 1,
						})),
					);

					set(form, [path, 'probability'].join('.'), probability);
					set(form, [path, 'value'].join('.'), value);
					set(
						form,
						[path, '$state'].join('.'),
						probability >= 0.9 ? 'approved' : 'pending',
					);
				}

				const objects = Object.values(tags);

				const value = objects.reduce(
					(acc, object) => acc + (object?.value ?? 0),
					0,
				);

				const probability = getProbability(
					objects.map((object) => ({
						value: object?.value ?? 0,
						probability: object?.probability ?? 1,
					})),
				);

				set(form, [path, 'probability'].join('.'), probability);
				set(form, [path, 'value'].join('.'), value);
				set(
					form,
					[path, '$state'].join('.'),
					probability >= 0.9 ? 'approved' : 'pending',
				);
			}

			const objects = Object.values(categories);

			const value = objects.reduce(
				(acc, object) => acc + (object?.value ?? 0),
				0,
			);

			const probability = getProbability(
				objects.map((object) => ({
					value: object?.value ?? 0,
					probability: object?.probability ?? 1,
				})),
			);

			set(form, [path, 'probability'].join('.'), probability);
			set(form, [path, 'value'].join('.'), value);
			set(
				form,
				[path, '$state'].join('.'),
				probability >= 0.9 ? 'approved' : 'pending',
			);
		}
	}

	const tags = (store.getState()?.app?.tags ?? []).reduce(
		(acc, object) => ({ ...acc, [object._id ?? object.id]: object }),
		{},
	);

	const states = scenario?.state ?? [];

	for (const { type: $type, date, tag: $tag, state } of states) {
		const type = $type.toLowerCase();

		let path = null;

		if ($tag) {
			const tag = tags?.[$tag?._id];

			if (!tag) {
				continue;
			}

			path = getPath({
				type: type,
				date: format(parseISO(date), 'yyyy-MM-dd'),
				tag: tag?.name,
				category: tag?.category?.name,
			});

			const categoryName = tag?.category?.name;
			const tagName = tag?.name;
			const key = [categoryName, tagName].join('-');

			if (!form?.[type]?.tags?.[key]) {
				set(form, [type, 'tags', key].join('.'), {
					...tag,
					value: 0,
					probability: 1,
					transactions: {},
					category: {
						...tag.category,
						value: 0,
						probability: 1,
					},
					weight: 0,
					alert: false,
				});
			}
		} else {
			path = getPath({
				type: type,
				date: format(parseISO(date), 'yyyy-MM-dd'),
			});
		}

		const data = get(form, path);

		if (!data) {
			set(form, path, { value: 0, probability: 1 });
		}

		set(form, [path, '$state'].join('.'), state);
	}

	const data = Object.entries(form)
		.flatMap(([type, value]) =>
			Object.entries(value?.data ?? {}).flatMap(([date, value]) =>
				Object.entries(value?.FORECAST?.categories ?? {}).flatMap(
					([category, value]) =>
						Object.keys(value?.tags ?? {}).flatMap((tag) => ({
							type: type,
							date: date,
							category: category,
							tag: tag,
						})),
				),
			),
		)
		.reduce(
			(acc, { type, category, tag }) => ({
				...acc,
				[type]: {
					...acc?.[type],
					[category]: { ...acc?.[type]?.[category], [tag]: true },
				},
			}),
			{},
		);

	for (const type in data) {
		for (const category in data?.[type]) {
			for (const tag in data?.[type]?.[category]) {
				validateState({
					form: form,
					type: type,
					category: category,
					tag: tag,
				});
			}
		}

		validateState({
			form: form,
			type: type,
		});
	}

	return form;
}
