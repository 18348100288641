import React from 'react';

import PropTypes from 'prop-types';

import { Title } from '@asteria/component-core/typography';

import { ListGroup, ListHeader, ListItem } from '@asteria/component-list';

import './styles.scss';

const TransactionDetailsSection = (props) => {
	const { header, children } = props;

	return (
		<ListGroup className="asteria-component__transactions__details__section">
			<ListHeader>
				<ListItem className="asteria-component__transactions__details__section__header">
					<Title size="xxs">{header}</Title>
				</ListItem>
			</ListHeader>
			{children}
		</ListGroup>
	);
};

TransactionDetailsSection.displayName = 'TransactionDetailsSection';

TransactionDetailsSection.propTypes = {
	header: PropTypes.string,
	children: PropTypes.node,
};

export default TransactionDetailsSection;
