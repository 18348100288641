import { isEqual } from 'lodash-es';

import * as utils from './utils';

export function setUnpaid(state, { payload }) {
	state.unpaid = payload;
}

export function updateUnpaid(state, { payload }) {
	const objects = [].concat(payload).filter(Boolean);

	for (const object of objects) {
		const id = object?._id ?? object?.id;

		const index = state.unpaid.findIndex(
			(object) => (object?._id ?? object?.id) === id,
		);

		if (index !== -1) {
			state.unpaid[index] = object;
		} else {
			state.unpaid.push(object);
		}
	}

	return state;
}

export function setBatches(state, { payload }) {
	state.batches = payload;
}

export function setLayouts(state, { payload }) {
	state.layouts = payload;
}

export function search(state, { payload }) {
	state.search = payload;
}

export function setCountries(state, { payload }) {
	const countryCandidates = payload.reduce((acc, object) => {
		const candidates = object.candidates.reduce(
			(acc, item) => ({ ...acc, [item]: object.code }),
			{},
		);

		return { ...acc, ...candidates };
	}, {});

	const countryCodes = payload.reduce((acc, object) => {
		return { ...acc, [object.code]: object.candidates };
	}, {});

	state.country = { candidates: countryCandidates, codes: countryCodes };
}

export function filter(state, { payload: $payload }) {
	const payload = [].concat($payload).filter(Boolean);

	for (const object of payload) {
		const type = object?.type;
		const value = object?.value;

		if (value === null) {
			if (type === null) {
				state.filters = [];

				continue;
			}

			state.filters = state.filters.filter(
				(object) => object?.type !== type,
			);

			continue;
		}

		let filters = state.filters.reduce(
			(acc, object) => ({
				...acc,
				[object.type]: []
					.concat(acc?.[object.type])
					.concat(object.value)
					.filter(Boolean),
			}),
			{},
		);

		if (!filters[type]) {
			filters[type] = [];
		}

		const index = filters[type].findIndex((current) =>
			isEqual(current, value),
		);

		if (index !== -1) {
			filters[type].splice(index, 1);
		} else {
			filters[type] = filters[type].concat(value);
		}

		state.filters = Object.entries(filters).flatMap(([type, values]) =>
			values.map((value) => ({ type: type, value: value })),
		);
	}
}

export function select(state, action) {
	let payload = [].concat(action?.payload).filter(Boolean);

	if (payload.includes('RESET') || !payload.length) {
		state.selected = [];

		return state;
	}

	if (payload.includes('ALL')) {
		payload = [];
	}

	const { add, remove } = payload.reduce(
		(acc, target) => {
			if (
				state.selected.some(
					(source) => utils.getId(source) === utils.getId(target),
				)
			) {
				acc.remove.push(target);
			} else {
				acc.add.push(target);
			}

			return acc;
		},
		{ add: [], remove: [] },
	);

	if ([].concat(action?.payload).includes('ALL')) {
		if (add.length) {
			state.selected = state.selected.concat(add);

			return state;
		}

		state.selected = [];

		return state;
	}

	if (remove.length) {
		state.selected = state.selected.filter((source) => {
			return !remove.some((target) => {
				return utils.getId(target) === utils.getId(source);
			});
		});
	}

	if (add.length) {
		state.selected = state.selected.concat(add);
	}

	return state;
}
