import React from 'react';

import PropTypes from 'prop-types';

import * as Constants from '../../constants';

import DatePickerContentWeekDefault from './DatePickerContentWeekDefault';
import DatePickerContentWeekScroll from './DatePickerContentWeekScroll';

import './index.scss';

const DatePickerContentWeek = (props) => {
	const { behavior } = props;

	switch (behavior) {
		case 'scroll':
			return <DatePickerContentWeekScroll {...props} />;

		default:
			return <DatePickerContentWeekDefault {...props} />;
	}
};

DatePickerContentWeek.propTypes = {
	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentWeek.defaultProps = {};

export default DatePickerContentWeek;
