import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../../constants';

import { initialState } from './constants';
import * as reducers from './reducers';
import { reducers as thunkReducers } from './thunk';

export default createSlice({
	name: 'forecaster',
	initialState: initialState,
	reducers: reducers,
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
		...thunkReducers,
	},
});
