import React from 'react';

import PropTypes from 'prop-types';

const ModalContext = React.createContext({
	active: null,
	history: [],
	props: {},
	open: () => null,
	close: () => null,
	onAction: () => null,
	onSubmit: () => null,
});

function useModalActions() {
	const { open, close, onAction, onSubmit } = React.useContext(ModalContext);

	return React.useMemo(
		() => ({ open, close, onAction, onSubmit }),
		[close, onAction, onSubmit, open],
	);
}

function useModalProps() {
	const { props } = React.useContext(ModalContext);

	return React.useMemo(() => props, [props]);
}

function useModalHistory() {
	const { history } = React.useContext(ModalContext);

	return React.useMemo(
		() => ({ history: history, hasHistory: history.length > 0 }),
		[history],
	);
}

const ModalCollection = (props) => {
	const { children, onAction, onSubmit } = props;

	const [{ active, history, props: $props }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'OPEN': {
					let history = [].concat(state.history);

					if (state.active) {
						history.push({
							node: state.active,
							props: state.props,
						});
					}

					return {
						...state,
						active: action?.payload?.node,
						history: history,
						props: action?.payload?.props,
					};
				}

				case 'CLOSE': {
					const size = action?.payload?.size ?? 1;

					if (size > 1) {
						state.history.splice(-(size - 1));
					}

					const last = state.history.pop() ?? null;

					return {
						...state,
						active: last?.node ?? null,
						props: last?.props ?? {},
					};
				}

				default:
					return state;
			}
		},
		{ active: null, history: [], props: {} },
	);

	const open = React.useCallback((node, props) => {
		dispatch({ type: 'OPEN', payload: { node, props } });
	}, []);

	const close = React.useCallback((event, payload) => {
		dispatch({ type: 'CLOSE', payload: payload });
	}, []);

	const ctx = React.useMemo(
		() => ({
			active: active,
			history: history,
			props: $props,
			open: open,
			close: close,
			onAction: onAction,
			onSubmit: onSubmit,
		}),
		[$props, active, close, history, onAction, onSubmit, open],
	);

	return (
		<ModalContext.Provider value={ctx}>
			{active}
			{children}
		</ModalContext.Provider>
	);
};

ModalCollection.displayName = 'ModalCollection';

ModalCollection.propTypes = {
	children: PropTypes.node,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ModalCollection;
export { ModalContext, useModalActions, useModalHistory, useModalProps };
