/**
 * @template T
 * @param { T[] } data
 * @param { { [key: keyof T]: unknown }[] } filters
 * @returns { T[] }
 */
export const applyFilters = (data, filters = []) => {
	if (!filters.length) {
		return data;
	}

	return data.filter((object) => {
		for (const filter of filters) {
			for (const key in filter) {
				const source = object?.[key];
				const target = [].concat(filter?.[key]);

				if (!target.some((value) => value === source)) {
					return false;
				}
			}
		}

		return true;
	});
};

/**
 * @template T
 * @param { T[] } data
 * @param { { [key: keyof T]: 'ASC' | 'DESC' }[] } sorting
 * @returns { T[] }
 */
export const applySorting = (data, sorting = []) => {
	if (!sorting.length) {
		return data;
	}

	return data.sort((a, b) => {
		for (const sort of sorting) {
			for (const field in sort) {
				const source = a?.[field];
				const target = b?.[field];

				if (source === target) {
					continue;
				}

				if (sort[field] === 'DESC') {
					return target?.localeCompare(source);
				}

				return source?.localeCompare(target);
			}
		}
	});
};

/**
 * @template T
 * @param { T[] } data
 * @param { keyof T } key
 * @returns { { [key: keyof T]: T[] } }
 */
export function applyGrouping(data, key) {
	return (data ?? []).reduce((acc, object) => {
		const { [key]: $key } = object;

		if (acc[$key] === undefined) {
			acc[$key] = [];
		}

		acc[$key].push(object);

		return acc;
	}, {});
}
