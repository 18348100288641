import React, { useCallback, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useManualFormValues } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import * as FormatUtils from '@asteria/utils-funcs/format';

import { recalculate as recalculateCategory } from '../../utils/adjustCategory';
import { adjustTagValue, move as moveTag } from '../../utils/adjustTag';
import { setValue as setTypeValue } from '../../utils/adjustType';
import applyFormChanges from '../../utils/applyFormChanges';
import getFormPaths, { createChange, getPath } from '../../utils/getFormPaths';

import CategoryDropdown from './navigation/move-category';

export function useNavigation({ variant }) {
	const params = useParams();
	const location = useLocation();

	const path = useMemo(
		() => location.pathname.split('/').filter(Boolean).slice(2),
		[location.pathname],
	);

	const [type, tagId] = path;

	const action = useSelector((store) =>
		ForecasterStore.selectors.actions.value(store, params.actionId),
	);

	const tag = useSelector((store) => AppStore.selectors.tag(store, tagId));

	return useMemo(() => {
		function get({ variant, type, tagId, path }) {
			if (variant === 'action') {
				return {
					type: action?.extra?.type,
					category: action?.tag?.category?.name,
					tag: action?.tag?.name,
					level: action?.extra?.level,
					parent: null,
				};
			}

			if (!type) {
				return {
					type: 'deposit',
					category: '$type',
					tag: '$deposit',
					level: path.length,
					path: path,
					parent: null,
				};
			}

			if (!tagId) {
				return {
					type: type,
					category: '$type',
					tag: `$${type}`,
					level: path.length,
					path: path,
					parent: get({ type: null, path: path.slice(0, -1) }),
				};
			}

			if (tagId === '$adjusted') {
				return {
					type: type,
					category: '$forecaster',
					tag: '$adjusted',
					level: path.length,
					path: path,
					parent: get({ type: type, path: path.slice(0, -1) }),
				};
			}

			return {
				type: type,
				category: tag?.category?.name,
				tag: tag?.name,
				level: path.length,
				path: path,
				parent: get({
					type: null,
					tagId: null,
					path: path.slice(0, -1),
				}),
			};
		}

		return get({ variant, type, tagId, path });
	}, [
		action?.extra?.level,
		action?.extra?.type,
		action?.tag?.category?.name,
		action?.tag?.name,
		path,
		tag?.category?.name,
		tag?.name,
		tagId,
		type,
		variant,
	]);
}

function useVisible(object) {
	const { getValues } = useFormContext();

	const type = object?.type;
	const category = object?.category;
	const tag = object?.tag;

	const dates = Object.keys(getValues(getPath({ type })) ?? {});

	const value = useManualFormValues({
		name: dates.map((date) =>
			getPath({ type, date, tag, category, field: 'value' }),
		),
	}).reduce((acc, value) => acc + (value || 0), 0);

	const exists = ['$custom', '$type'].includes(category) || !!value;

	return useMemo(() => exists, [exists]);
}

export function useNavigationConfig({ onAction }) {
	const depositTags = useSelector((store) =>
		ForecasterStore.selectors.tags.available(store, { type: 'deposit' }),
	);

	const withdrawTags = useSelector((store) =>
		ForecasterStore.selectors.tags.available(store, { type: 'withdraw' }),
	);

	return useMemo(
		() => ({
			children: [
				{
					id: 'deposit',

					object: {
						type: 'deposit',
						category: '$type',
						tag: '$deposit',
					},

					label: FormatUtils.formatTag({
						type: 'deposit',
						category: '$type',
						tag: '$deposit',
					}),
					props: {
						// colors: 'deposit',
						size: 'sm',
					},
					extendable: { icon: 'folder' }, // DEFAULT ICON FOR DROPDOWN
					children: depositTags.map((object) => ({
						id: object?._id ? object?._id : object?.name,

						object: {
							type: 'deposit',
							category: object?.category?.name,
							tag: object?.name,
						},

						label: FormatUtils.formatTag({
							type: object?.type,
							category: object?.category?.name,
							tag: object?.name,
						}),
						useVisible: useVisible,
						editable: object?.category?.name === '$custom',
						props: {
							colors: object?.color ?? [
								object?.type,
								FormatUtils.replace([
									object?.category?.name,
									object?.name,
								]).join('-'),
							],
							dismiss: true,
							size: 'sm',
							children:
								object?.name === '$adjusted' ? (
									<CategoryDropdown
										type="deposit"
										onAction={onAction}
									/>
								) : null,
						},
					})),
				},
				{
					id: 'withdraw',

					object: {
						type: 'withdraw',
						category: '$type',
						tag: '$withdraw',
					},

					label: FormatUtils.formatTag({
						type: 'withdraw',
						category: '$type',
						tag: '$withdraw',
					}),
					props: {
						// colors: 'withdraw',
						size: 'sm',
					},
					extendable: { icon: 'folder' },
					children: withdrawTags.map((object) => ({
						id: object?._id ? object?._id : object?.name,

						object: {
							type: 'withdraw',
							category: object?.category?.name,
							tag: object?.name,
						},

						label: FormatUtils.formatTag({
							type: object?.type,
							category: object?.category?.name,
							tag: object?.name,
						}),

						editable: object?.category?.name === '$custom',
						useVisible: useVisible,
						props: {
							colors: object?.color ?? [
								object?.type,
								FormatUtils.replace([
									object?.category?.name,
									object?.name,
								]).join('-'),
							],
							dismiss: true,
							size: 'sm',
							children:
								object?.name === '$adjusted' ? (
									<CategoryDropdown
										type="withdraw"
										onAction={onAction}
									/>
								) : null,
						},
					})),
				},
			],
		}),
		[depositTags, withdrawTags, onAction],
	);
}

export function useValueUpdate() {
	const dispatch = useDispatch();
	const { reset, getValues, setValue, unregister } = useFormContext();

	return useCallback(
		({ name, value, prev, skipApply }) => {
			setValue(name, value, {
				shouldDirty: true,
				shouldTouch: false,
				shouldValidate: false,
			});

			if (!skipApply) {
				const { type, date, category } = getFormPaths(name);

				let changes = [];

				if (name.endsWith('.value')) {
					changes.push(
						createChange({
							op: 'unset',
							name: []
								.concat(name.split('.').slice(0, -1))
								.concat('$value')
								.join('.'),
							event: 'graph:clear',
						}),
					);

					if (category) {
						changes.push(
							createChange({
								op: 'unset',
								name: getPath({ type, date, field: '$value' }),
								event: 'graph:clear',
							}),
						);
					}
				}

				const form = getValues();

				if (name.includes('.tags.')) {
					// Adjust tag
					changes = changes.concat(
						adjustTagValue({
							name,
							value,
							prev,
							getValues,
							dispatch,
						}),
					);

					changes = changes.concat(
						recalculateCategory({
							type,
							date,
							category,
							form: getValues(),
						}),
					);
				} else if (!name.includes('.categories.')) {
					// Adjust type
					changes = changes.concat(
						setTypeValue({
							type,
							date,
							value,
							prev,
							form,
							dispatch,
						}),
					);
				}

				applyFormChanges({
					changes,
					setValue,
					getValues,
					unregister,
					reset,
					event: 'change:value',
				});
			}
		},
		[dispatch, getValues, reset, setValue, unregister],
	);
}

export function useMoveCategory({ onAction }) {
	const { reset, getValues, setValue, unregister } = useFormContext();

	return useCallback(
		(object) => {
			const form = getValues();

			const changes = moveTag?.({
				source: {
					type: object?.type,
					category: '$forecaster',
					tag: '$adjusted',
				},
				destination: {
					type: object?.type,
					category: object?.category?.name,
					tag: object?.name,
				},
				form: form,
			});

			applyFormChanges({
				changes,
				setValue,
				getValues,
				unregister,
				reset,
				event: 'category:move',
			});

			onAction?.(
				'navigate',
				`/forecaster/category/${object?.type}/${
					object?._id ?? object?.id
				}`,
			);
		},
		[getValues, onAction, reset, setValue, unregister],
	);
}
