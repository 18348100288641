import React from 'react';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { useAnalyticsData } from '@asteria/utils-funcs/analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Icon from '../icon';
import { Text } from '../typography';
import { stateClasses } from '../utils';

import './index.scss';

const Context = React.createContext({ onSelect: () => null });

const AccordionItem = React.memo((props) => {
	const { active, index, label, children, analyticsKey } = props;

	const { onSelect } = React.useContext(Context);

	const analyticsDataRef = useAnalyticsData({
		analyticsKey: analyticsKey,
		index: index,
		active: active,
	});

	const onClick = React.useCallback(() => {
		Analytics.event('accordion.click', analyticsDataRef.current);

		onSelect(index);
	}, [analyticsDataRef, index, onSelect]);

	return (
		<div
			className={cn(
				'asteria-component__accordion-item',
				stateClasses(props),
			)}
		>
			<div
				onClick={onClick}
				className="asteria-component__accordion-item__label"
			>
				<Icon
					icon={active ? 'chevron-down' : 'chevron-right'}
					size="sm"
				/>
				{typeof label === 'string' ? <Text>{label}</Text> : label}
			</div>
			{active ? (
				<div className="asteria-component__accordion-item__content">
					{children}
				</div>
			) : null}
		</div>
	);
});

AccordionItem.displayName = 'AccordionItem';
AccordionItem.propTypes = {
	active: PropTypes.bool,
	index: PropTypes.number,
	label: PropTypes.string,
	children: PropTypes.node,
	analyticsKey: PropTypes.string,
};

const Accordion = React.memo((props) => {
	const { className, children, multi } = props;

	const [selected, setSelected] = React.useState([]);

	const onSelect = React.useCallback(
		(index) => {
			setSelected((selected) => {
				const indexOf = selected.indexOf(index);

				if (multi) {
					if (indexOf === -1) {
						return [].concat(selected).concat(index);
					}

					selected.splice(indexOf, 1);

					return selected;
				}

				if (indexOf === -1) {
					return [index];
				}

				return [];
			});
		},
		[multi],
	);

	const ctx = React.useMemo(() => ({ onSelect: onSelect }), [onSelect]);

	return (
		<div className={cn('asteria-component__accordion', className)}>
			<Context.Provider value={ctx}>
				{React.Children.map(children, (child, index) =>
					React.cloneElement(child, {
						index: index,
						active: selected.includes(index),
					}),
				)}
			</Context.Provider>
		</div>
	);
});

Accordion.displayName = 'Accordion';

Accordion.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	multi: PropTypes.bool,
};

ComponentService.register('Accordion', Accordion, {
	className: { type: 'string' },
	children: { type: 'node' },
	multi: { type: 'boolean' },
});

ComponentService.register('AccordionItem', AccordionItem, {
	active: { type: 'boolean' },
	index: { type: 'number' },
	label: { type: 'string' },
	children: { type: 'node' },
	analyticsKey: { type: 'string' },
});

export default Accordion;
export { AccordionItem };
