import { normalize } from './size';

export const positionClasses = ({ position }) => ({
	[`asteria-utils-position-${position}`]: position,
});

export const sizeClasses = ({ size }) => ({
	[`asteria--size-${normalize(size)}`]: size,
});

export const statusClasses = ({ info, success, warning, error }) => ({
	'asteria--status-info': info,
	'asteria--status-success': success,
	'asteria--status-warning': warning,
	'asteria--status-error': error,
});

export const stateClasses = ({
	active,
	checked,
	selected,
	disabled,
	loading,
	error,
	hover,
	onClick,
}) => ({
	'asteria--state-disabled': disabled,
	'asteria--state-active': active,
	'asteria--state-checked': checked,
	'asteria--state-selected': selected,
	'asteria--state-loading': loading,
	'asteria--state-error': error,
	'asteria--state-hover': hover,
	'asteria--state-clickable': onClick,
});

export const animationListener = (node, done) => {
	// use the css transitionend event to mark the finish of a transition
	node.addEventListener('transitionend', done, false);
};

export const isPossibleToClick = (event, target) => {
	const node = event?.nativeEvent
		?.composedPath?.()
		?.find?.((node) =>
			node?.classList?.contains?.('asteria--state-clickable'),
		);

	if (target && node && !target.isSameNode(node)) {
		return false;
	}

	return true;
};

/**
 * @returns {[number, number]}
 */
export function getViewport() {
	let viewPortWidth;
	let viewPortHeight;

	// eslint-disable-next-line spellcheck/spell-checker
	// the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
	if (typeof window.innerWidth !== 'undefined') {
		viewPortWidth = window.innerWidth;
		viewPortHeight = window.innerHeight;
	}

	// eslint-disable-next-line spellcheck/spell-checker
	// IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
	else if (
		typeof document.documentElement !== 'undefined' &&
		typeof document.documentElement.clientWidth !== 'undefined' &&
		document.documentElement.clientWidth !== 0
	) {
		viewPortWidth = document.documentElement.clientWidth;
		viewPortHeight = document.documentElement.clientHeight;
	}

	// older versions of IE
	else {
		viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
		viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
	}
	return [viewPortWidth, viewPortHeight];
}
