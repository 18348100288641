/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

// import Accordion, { AccordionItem } from './components/Accordion';
import Alert from './components/Alert';
import Button from './components/Button';
import Checkbox from './components/Checkbox';
import Group from './components/Group';
import Input from './components/Input';
import Text from './components/Text';
import Textarea from './components/Textarea';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	FormWrapper,
	Header,
} from './components/Wrapper';

import './styles.scss';

/*
const faqItems = [
	{
		title: 'Hur kan jag få hjälp med mina problem med mitt bokföringssystem?',
		content:
			'aLorem ipsum dolor sit amet, quo no phaedrum nominati rationibus, vim tota dicit ea. Sea vidisse hendrerit contentiones eu, usu te vide constituam, tamquam ponderum at eos. df',
	},
	{
		title: 'Vilka webbläsare fungerar Företagskollen i?',
		content:
			'aLorem ipsum dolor sit amet, quo no phaedrum nominati rationibus, vim tota dicit ea. Sea vidisse hendrerit contentiones eu, usu te vide constituam, tamquam ponderum at eos. df',
	},
	{
		title: 'Hur kan jag rapportera fel med min data?',
		content:
			'aLorem ipsum dolor sit amet, quo no phaedrum nominati rationibus, vim tota dicit ea. Sea vidisse hendrerit contentiones eu, usu te vide constituam, tamquam ponderum at eos. df',
	},
];
 */

const Error = (props) => {
	const { className, onSubmit, message } = props;

	const [{ errors, form, sent, loading }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action.type) {
				case 'START':
					return { ...state, loading: true };
				case 'FINISH':
					return { ...state, loading: false, sent: true };
				case 'UPDATE':
					return {
						...state,
						errors: {
							...state.errors,
							[action.payload.name]: null,
						},
						form: {
							...state.form,
							[action.payload.name]: action.payload.value,
						},
					};

				case 'ERROR':
					return {
						...state,
						errors: {
							...state.errors,
							[action.payload.name]: action.payload.value,
						},
					};

				default:
					return state;
			}
		},
		{ errors: {}, form: {}, sent: false, loading: false },
	);

	const handleChange = React.useCallback((event) => {
		dispatch({
			type: 'UPDATE',
			payload: { name: event?.target?.name, value: event?.target?.value },
		});
	}, []);

	const handleCheckboxChange = React.useCallback((event) => {
		dispatch({
			type: 'UPDATE',
			payload: { name: event?.target?.name, value: event.target.checked },
		});
	}, []);

	const handleSubmit = React.useCallback(
		async (event) => {
			event.preventDefault();

			let hasError = false;

			if (!form.agree) {
				hasError = true;

				dispatch({
					type: 'ERROR',
					payload: { name: 'agree', value: 'Nödvändig' },
				});
			}

			if (hasError) {
				return;
			}

			const msg = [`Error: ${message}`, form?.message]
				.filter(Boolean)
				.join('\n\n');

			dispatch({ type: 'START' });

			delete form.agree;

			await onSubmit?.({ ...form, message: msg });

			dispatch({ type: 'FINISH' });
		},
		[form, message, onSubmit],
	);

	return (
		<div
			className={cn(
				'asteria-component__error',
				{ 'asteria--state-sent': sent },
				className,
			)}
		>
			<Alert>
				Ett oväntat fel har inträffat och därför kan inte tjänsten
				visas. Var vänlig försök igen eller kontakta support.
			</Alert>
			{!sent ? (
				<form onSubmit={handleSubmit}>
					<Wrapper
						className={cn(
							'asteria-component__error-section',
							'asteria-component__error-section--type-support',
						)}
						border
					>
						<Header>Kontakta support</Header>
						<Content>
							<Group>
								<Text>
									Vänligen kontakta supporten och beskriv
									problemet så kan vi hjälpa dig så fort som
									möjligt ...
								</Text>
								<FormWrapper>
									<Content>
										<Input
											name="subject"
											placeholder="Ange ämne"
											onChange={handleChange}
											value={form.subject}
											error={errors.subject}
											required
										/>
										<Input
											name="email"
											type="email"
											placeholder="Skriv din e-postadress"
											onChange={handleChange}
											value={form.email}
											error={errors.email}
											required
										/>
										<Textarea
											name="message"
											placeholder="Skriv ditt meddelande här"
											onChange={handleChange}
											value={form.message}
											error={errors.message}
											required
										/>
									</Content>
								</FormWrapper>
								<Checkbox
									name="agree"
									label="Jag godkänner att Asteria kontaktar mig för support av tjänsten pä ovan nämnda e-postaddress"
									onChange={handleCheckboxChange}
									value={form.agree}
									error={errors.agree}
								/>
							</Group>
						</Content>
						<Footer>
							<FooterSection position="last">
								<Button loading={loading} disabled={loading}>
									Skicka
								</Button>
							</FooterSection>
						</Footer>
					</Wrapper>
				</form>
			) : null}

			{/*
			<Wrapper
				className={cn(
					'asteria-component__error-section',
					'asteria-component__error-section--type-faq',
				)}
				divider
			>
				<Header>Vanliga frågor och svar</Header>
				<Content>
					<Accordion>
						{faqItems.map(({ title, content }, index) => (
							<AccordionItem key={index} label={title}>
								{content}
							</AccordionItem>
						))}
					</Accordion>
				</Content>
			</Wrapper>
			 */}
		</div>
	);
};

Error.displayName = 'Error';
Error.propTypes = {
	className: PropTypes.string,
	onSubmit: PropTypes.func,
	message: PropTypes.string,
};

class ErrorWrapper extends React.Component {
	state = { hasError: false };
	propTypes = { children: PropTypes.node, onSubmit: PropTypes.func };

	static getDerivedStateFromError(error) {
		// eslint-disable-next-line no-console
		console.log(error);

		// Update state so the next render will show the fallback UI.
		return { hasError: true, message: error.message };
	}

	render() {
		const { hasError = false, message = false } = this.state;
		const { children } = this.props;

		if (hasError) {
			return <Error onSubmit={this.props.onSubmit} message={message} />;
		}

		return children;
	}
}

export default Error;
export { ErrorWrapper };
