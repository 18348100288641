import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const PageNavigation = (props) => {
	const { className, children } = props;

	return (
		<div
			className={cn(
				'asteria-component__page-header__navigation',
				className,
			)}
		>
			{children}
		</div>
	);
};

PageNavigation.displayName = 'PageNavigation';

PageNavigation.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default PageNavigation;
