import React, { useEffect, useState } from 'react';

import { addYears, formatISO } from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import { useCurrentDate } from '../../hooks';
import { getCalendarSize } from '../utils';

import DatePickerContentYearCalendar from './DatePickerContentYearCalendar';

import './index.scss';

const DatePickerContentYear = (props) => {
	const { layout, value } = props;

	const now = useCurrentDate(value);

	const [dates, setDates] = useState(
		new Array(getCalendarSize(layout))
			.fill(null)
			.map((_, index) => addYears(now, index * 12)),
	);

	useEffect(() => {
		setDates(
			new Array(getCalendarSize(layout))
				.fill(null)
				.map((_, index) => addYears(now, index * 12)),
		);
	}, [layout]);

	return (
		<div
			className={cn(
				'asteria-component__datepicker__content--layout',
				`asteria-component__datepicker__content--layout-${layout}`,
			)}
		>
			{dates.map((date, index) => (
				<DatePickerContentYearCalendar
					key={formatISO(date, { representation: 'date' })}
					{...props}
					defaultValue={date}
					previousDate={index ? dates[index - 1] : null}
					updateCurrentDate={(date) => {
						setDates(
							dates.map((value, dateIndex) => {
								if (dateIndex < index) {
									return value;
								}

								if (dateIndex === index) {
									return date;
								}

								if (dateIndex > index) {
									return addYears(
										date,
										(dateIndex - index) * 12,
									);
								}
							}),
						);
					}}
				/>
			))}
		</div>
	);
};

DatePickerContentYear.propTypes = {
	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentYear.defaultProps = {};

export default DatePickerContentYear;
