import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { stateClasses } from '@asteria/component-core/utils';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

function getUpdateValue({ first, last, difference, min, max }) {
	let isUpdatePossible = true;

	if (isUpdatePossible && min?.first) {
		isUpdatePossible = first + difference >= min?.first;
	}

	if (isUpdatePossible && min?.last) {
		isUpdatePossible = last - difference >= min?.last;
	}

	if (isUpdatePossible && max?.first) {
		isUpdatePossible = first + difference <= max?.first;
	}

	if (isUpdatePossible && max?.last) {
		isUpdatePossible = last - difference <= max?.last;
	}

	if (!isUpdatePossible) {
		return null;
	}

	return [[first + difference, 'px'].join(''), 'auto', '1fr'].join(' ');
}

const Resizer = (props) => {
	const {
		className,
		children,
		active,
		direction = 'vertical',
		min,
		max,
		hidden,
		initial,
		analyticsKey,
		tooltip = TranslationService.get('layout.resizable.handle'),
	} = props;

	const [{ isDragging }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'START':
					return { ...state, isDragging: true };

				case 'END':
					return { ...state, isDragging: false };

				default:
					return state;
			}
		},
		{ isDragging: false },
	);

	const resizerRef = React.useRef(null);
	const firstRef = React.useRef(null);
	const lastRef = React.useRef(null);
	const handleRef = React.useRef(null);

	const lastDragRef = React.useRef(null);

	React.useEffect(() => {
		if (resizerRef.current) {
			resizerRef.current.style['grid-template-columns'] = null;
			resizerRef.current.style['grid-template-rows'] = null;

			if (active) {
				let value = null,
					key = null;

				if (initial?.first) {
					value = [
						[initial?.first, 'px'].join(''),
						'auto',
						'1fr',
					].join(' ');
				} else if (initial?.last) {
					value = [
						'1fr',
						'auto',
						[initial?.last, 'px'].join(''),
					].join(' ');
				}

				if (direction === 'horizontal') {
					key = 'grid-template-columns';
				}

				if (direction === 'vertical') {
					key = 'grid-template-rows';
				}

				if (key && value) {
					resizerRef.current.style[key] = value;
				}
			}
		}
	}, [active, direction]);

	const onDrag = React.useCallback((event) => {
		if (!lastDragRef.current) {
			return;
		}

		const { x, y, direction, first, last, min, max } = lastDragRef.current;

		if (direction === 'horizontal') {
			const difference = event.clientX - x;

			const value = getUpdateValue({
				first: first?.width,
				last: last?.width,
				difference: difference,
				min: min,
				max: max,
			});

			if (value !== null) {
				resizerRef.current.style['grid-template-columns'] = value;
			}
		}

		if (direction === 'vertical') {
			const difference = event.clientY - y;

			const value = getUpdateValue({
				first: first?.height,
				last: last?.height,
				difference: difference,
				min: min,
				max: max,
			});

			if (value !== null) {
				resizerRef.current.style['grid-template-rows'] = value;
			}
		}
	}, []);

	const onDragEnd = React.useCallback(() => {
		document.removeEventListener('mouseup', onDragEnd);
		document.removeEventListener('mousemove', onDrag);

		if (analyticsKey) {
			Analytics.event('resize.drag.end', {
				analyticsKey: analyticsKey,
				data: lastDragRef.current,
			});
		}

		lastDragRef.current = null;

		dispatch({ type: 'END' });
	}, [analyticsKey, onDrag]);

	const onDragStart = React.useCallback(
		(event) => {
			if (
				(event?.button !== 0 && event?.type !== 'touchstart') ||
				!handleRef.current
			) {
				return;
			}

			document.addEventListener('mousemove', onDrag);
			document.addEventListener('mouseup', onDragEnd);

			const { x, y } = handleRef.current.getBoundingClientRect();

			lastDragRef.current = {
				x: x,
				y: y,
				direction: direction,
				first: {
					height: firstRef.current.offsetHeight,
					width: firstRef.current.offsetWidth,
				},
				last: {
					height: lastRef.current.offsetHeight,
					width: lastRef.current.offsetWidth,
				},
				min: { first: min?.first, last: min?.last },
				max: { first: max?.first, last: max?.last },
			};

			dispatch({ type: 'START' });
		},
		[
			direction,
			max?.first,
			max?.last,
			min?.first,
			min?.last,
			onDrag,
			onDragEnd,
		],
	);

	if (hidden) {
		return children;
	}

	return (
		<div
			className={cn(
				'asteria-component__resizer',
				{
					[`asteria-component__resizer--direction-${direction}`]:
						direction,
				},
				stateClasses({ active: active }),
				className,
			)}
			ref={resizerRef}
		>
			<div
				className={cn(
					'asteria-component__resizer-element',
					'asteria--position-first',
				)}
				ref={firstRef}
			>
				{children?.[0]}
			</div>
			<div className="asteria-component__resizer-handle" ref={handleRef}>
				<Button
					tooltip={isDragging ? null : tooltip}
					variant="resizer-handle"
					onMouseDown={onDragStart}
				/>
			</div>
			<div
				className={cn(
					'asteria-component__resizer-element',
					'asteria--position-last',
				)}
				ref={lastRef}
			>
				{children?.[1]}
			</div>
		</div>
	);
};

Resizer.displayName = 'Resizer';

Resizer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	active: PropTypes.bool,
	hidden: PropTypes.bool,
	analyticsKey: PropTypes.string,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),

	min: PropTypes.shape({ first: PropTypes.number, last: PropTypes.number }),
	max: PropTypes.shape({ first: PropTypes.number, last: PropTypes.number }),
	initial: PropTypes.shape({
		first: PropTypes.number,
		last: PropTypes.number,
	}),

	tooltip: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.object,
	]),
};

export default Resizer;
