import React from 'react';

import PropTypes from 'prop-types';

import Alert from '@asteria/component-alert';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Snackbar = (props) => {
	const { className, onAction, onClose, icon, ...object } = props;

	const { variant, type, title, content, data, hideActions } = object;

	const handleClose = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);
	const handleAction = React.useCallback(
		(event, data) => onAction?.(event, data),
		[onAction],
	);

	const handleClick = React.useCallback(
		() => handleAction('snackbar.click', object),
		[handleAction, object],
	);

	const actions = React.useMemo(
		() =>
			hideActions
				? []
				: [
						{
							variant: 'href',
							label: TranslationService.get([
								'snackbar.action.label',
								`snackbar.${type}.action.label`,
							]),
							tooltip: TranslationService.get([
								'snackbar.action.tooltip',
								`snackbar.${type}.action.tooltip`,
							]),
							onClick: handleClick,
						},
				  ],
		[handleClick, hideActions, type],
	);

	return (
		<Alert
			title={TranslationService.get(title, undefined, {
				data: data,
			})}
			level={variant}
			className={cn('asteria-component__snackbar', className, {
				[`asteria-component__snackbar--variant-${variant}`]: variant,
				[`asteria-component__snackbar--type-${type}`]: type,
			})}
			icon={icon}
			actions={actions}
			onDismiss={handleClose}
		>
			{TranslationService.get(content, undefined, {
				data: data,
			})}
		</Alert>
	);
};

Snackbar.displayName = 'Snackbar';

Snackbar.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.oneOf([
		'default',
		'success',
		'error',
		'warning',
		'info',
	]),
	type: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string,
	hideActions: PropTypes.bool,

	data: PropTypes.object,

	icon: PropTypes.string,

	onAction: PropTypes.func,
	onClose: PropTypes.func,
};

Snackbar.defaultProps = {};

export default Snackbar;
