import React from 'react';

import PropTypes from 'prop-types';

import Dot from './dot';
import Line from './line';

import './styles.scss';

const LineGraphColumn = React.memo((props) => {
	const {
		index,
		first,
		last,
		previous,
		current,
		next,
		min,
		max,
		width,
		height,

		renderLine,
		renderDot,
	} = props;

	if (first || last) {
		return null;
	}

	return (
		<div className="asteria-component__forecaster-graph-column">
			<svg
				className="asteria-component__forecaster-graph-column-content"
				viewBox={`0 0 100 ${height ?? 100}`}
				preserveAspectRatio="none"
				width="100%"
				height="100%"
			>
				{renderLine ? (
					renderLine({
						current,
						previous,
						next,
						max,
						min,
						height,
						index,
					})
				) : (
					<Line
						line={current}
						previousLine={previous || current}
						nextLine={next || current}
						max={max}
						min={min}
						height={height}
					/>
				)}
			</svg>
			<div className="asteria-component__forecaster-graph-column-content">
				{renderDot ? (
					renderDot({
						current,
						previous,
						next,
						max,
						min,
						height,
						index,
					})
				) : (
					<Dot
						line={current}
						max={max}
						min={min}
						width={width}
						index={index}
						height={height}
					/>
				)}
			</div>
		</div>
	);
});

LineGraphColumn.displayName = 'LineGraphColumn';

LineGraphColumn.propTypes = {
	index: PropTypes.number,
	first: PropTypes.bool,
	last: PropTypes.bool,
	previous: PropTypes.object,
	current: PropTypes.object,
	next: PropTypes.object,
	min: PropTypes.number,
	max: PropTypes.number,
	width: PropTypes.number,

	height: PropTypes.number,

	renderLine: PropTypes.func,
	renderDot: PropTypes.func,
};

export default LineGraphColumn;
