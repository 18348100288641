import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import CategoryAction from './action/category';

import './styles.scss';

const ActionsSection = React.memo((props) => {
	const { onAction, type, actions } = props;

	const onNavigate = React.useCallback(
		() => onAction?.('navigate', `/forecaster/category/${type}`),
		[onAction, type],
	);

	return (
		<div className="asteria-component__forecaster-actions-section">
			<div className="asteria-component__forecaster-actions-section-title">
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="space-between"
				>
					<Title size="xs">
						{TranslationService.get([
							`forecaster.actions.section.title`,
							`forecaster.actions.section.${type}.title`,
						])}
					</Title>
					<Button
						label={TranslationService.get([
							`forecaster.actions.section.link`,
							`forecaster.actions.section.${type}.link`,
						])}
						icon="edit"
						variant="tertiary"
						onClick={onNavigate}
					/>
				</Group>
			</div>
			<div className="asteria-component__forecaster-actions-section-content">
				{actions.length ? (
					actions.map((object) => (
						<CategoryAction
							key={object?._id ?? object?.id}
							data={object?.data}
							onAction={onAction}
						/>
					))
				) : (
					<div className="asteria-component__forecaster-actions-section-content-placeholder">
						<Text size="lg">
							{TranslationService.get([
								`forecaster.actions.section.placeholder`,
								`forecaster.actions.section.${type}.placeholder`,
							])}
						</Text>
					</div>
				)}
			</div>
		</div>
	);
});

ActionsSection.displayName = 'ActionsSection';
ActionsSection.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	actions: PropTypes.arrayOf(PropTypes.object),
};

export default ActionsSection;
