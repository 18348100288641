import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import BasicStep from './step';

import './styles.scss';

const Auth2FAVerifyContent = React.memo((props) => {
	const {
		className,
		onClose,
		onAction,
		onSubmit,
		onSuccess,
		showHeaderClose,
	} = props;

	const dispatch = useDispatch();

	const { getValues, clearErrors, setError, trigger } = useFormContext();

	const [step, setStep] = React.useState(0);

	const [loading, setLoading] = React.useState(false);

	const [{ url, base64, codes, secret }, setData] = React.useState({
		url: null,
		base64: null,
		codes: [],
		secret: null,
	});

	const length = useConfig(['modals.auth.2fa.verify.step'], {
		deep: true,
		callback: (value) => value?.length ?? 0,
	});

	React.useEffect(() => {
		async function fetch() {
			const response = await onSubmit?.('auth:2fa:fetch');

			const url = response?.url;
			const secret = response?.secret;
			const codes = response?.codes;

			if (response?.url) {
				const base64 = await Promise.resolve(url).then((data) =>
					QRCode.toDataURL(data),
				);

				setData({
					url: url,
					secret: secret,
					codes: codes,
					base64: base64,
				});
			}
		}

		if (step === 1) {
			fetch();
		}
	}, [onSubmit, step]);

	const data = React.useMemo(
		() => ({ url: url, base64: base64, codes: codes, secret: secret }),
		[base64, codes, secret, url],
	);

	const onNext = React.useCallback(async () => {
		if (step === 0) {
			clearErrors('code');

			const isValid = await trigger('code');

			if (!isValid) {
				return;
			}

			const code = getValues('code');

			setLoading(true);
			const response = await onSubmit?.('auth:2fa:validate', code);
			setLoading(false);

			if (!response) {
				setError('code', {
					type: 'error',
					message: TranslationService.get(
						['auth.2fa.code.error.message'],
						undefined,
						{ code: code },
					),
				});

				return;
			}
		}

		setStep((step) => step + 1);
	}, [clearErrors, getValues, onSubmit, setError, step, trigger]);

	const handleAction = React.useCallback(
		async (action, data) => {
			if (action === 'codes:copy') {
				navigator.clipboard.writeText((codes ?? []).join('\n'));

				SnackbarStore.show(dispatch, {
					title: 'snackbar.auth.2fa.codes.copy.title',
					content: 'snackbar.auth.2fa.codes.copy.content',
					type: 'auth.2fa.codes.copy',
					variant: 'success',
					icon: 'clipboard',
					hideActions: true,
					data: { codes: codes },
				});

				return;
			}

			if (action === 'codes:refresh') {
				setLoading(true);
				const response = await onSubmit?.('auth:2fa:refresh');
				setLoading(false);

				setData((data) => ({ ...data, codes: response?.codes }));

				SnackbarStore.show(dispatch, {
					title: 'snackbar.auth.2fa.codes.refresh.title',
					content: 'snackbar.auth.2fa.codes.refresh.content',
					type: 'auth.2fa.codes.refresh',
					variant: 'success',
					icon: 'check',
					hideActions: true,
					data: { codes: codes },
				});

				return;
			}

			return onAction?.(action, data);
		},
		[codes, dispatch, onAction, onSubmit],
	);

	const Step = [BasicStep, BasicStep][step];

	return (
		<Wrapper
			className={cn(
				'asteria-component__auth-2fa',
				'asteria--variant-verify',
				className,
			)}
			scroll
		>
			<Header onClose={showHeaderClose && onClose}>
				{TranslationService.get([
					'auth.title',
					'auth.2fa.title',
					'auth.2fa.verify.title',
					`auth.2fa.step.${step}.title`,
					`auth.2fa.verify.step.${step}.title`,
					'auth.modal.title',
					'auth.modal.2fa.title',
					'auth.modal.2fa.verify.title',
					`auth.modal.2fa.step.${step}.title`,
					`auth.modal.2fa.verify.step.${step}.title`,
				])}
			</Header>
			<Content scroll>
				<Step
					onAction={handleAction}
					onSubmit={onSubmit}
					data={data}
					step={step}
				/>
			</Content>
			<Footer>
				{step + 1 < length ? (
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.abort',
								'action.abort',
								'auth.action.abort',
								'auth.2fa.action.abort',
								'auth.2fa.verify.action.abort',
								`auth.step.${step}.action.abort`,
								`auth.2fa.step.${step}.action.abort`,
								`auth.2fa.verify.step.${step}.action.abort`,
							])}
							onClick={onClose}
						/>
					</FooterSection>
				) : null}
				<FooterSection position="last">
					{step + 1 >= length ? (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.done',
								'action.done',
								'auth.action.done',
								'auth.2fa.action.done',
								'auth.2fa.verify.action.done',
								`auth.step.${step}.action.done`,
								`auth.2fa.step.${step}.action.done`,
								`auth.2fa.verify.step.${step}.action.done`,
							])}
							onClick={onSuccess}
							loading={loading}
							disabled={loading}
						/>
					) : (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.next',
								'action.next',
								'auth.action.next',
								'auth.2fa.action.next',
								'auth.2fa.verify.action.next',
								`auth.step.${step}.action.next`,
								`auth.2fa.step.${step}.action.next`,
								`auth.2fa.verify.step.${step}.action.next`,
							])}
							onClick={onNext}
							loading={loading}
							disabled={loading}
						/>
					)}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

Auth2FAVerifyContent.displayName = 'Auth2FAVerifyContent';

Auth2FAVerifyContent.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	showHeaderClose: PropTypes.bool,
};

export default Auth2FAVerifyContent;
