import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';

import * as ModalStore from '@asteria/datalayer/stores/modals';

export function useUnreadMessage({ onSubmit }) {
	const currentModal = useSelector(ModalStore.selectors.current);

	const { data: chatId } = useQuery({
		queryKey: ['conversation'],
		queryFn: () => {
			return onSubmit?.('conversation:list', {
				filters: { status: 'PENDING' },
			});
		},

		select: (response) => response?.edges?.[0]?.node?._id,

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	const { data: unreadMessages } = useQuery({
		queryKey: ['chat', chatId],
		queryFn: () => onSubmit?.('conversation:chat', { id: chatId }),

		select: (response) =>
			response.filter((message) => {
				return (
					message?.createdBy?.type !== 'UserToken' && !message?.read
				);
			}),

		placeholderData: [],
		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	if (!unreadMessages?.length || currentModal?.type === 'conversation') {
		return null;
	}

	return unreadMessages?.length > 9 ? '9+' : unreadMessages?.length;
}
