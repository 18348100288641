import {
	addDays,
	addMonths,
	addWeeks,
	addYears,
	endOfMonth,
	format,
	parseISO,
} from 'date-fns';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';
import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import * as selectors from './selectors';

/**
 *
 * @param { Date } date
 * @param { string } size
 * @param { number } count
 * @returns { Date }
 */
export function addTimeslice(date, size, count = 1) {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
}

/**
 *
 * @param { { store: { getState: () => object }, state: string, reverse: boolean, options: object } } param0
 * @returns { object }
 */
export function getRequest({ store, reverse, options }) {
	const filters = selectors.filters(store.getState());
	const types = selectors.types(store.getState());
	const scenarioId = ScenarioStore.selectors.scenarioId(store.getState());
	const selectedDate = selectors.selectedDate(store.getState());

	const state = TransactionStore.selectors.state(store.getState());

	const pageQuery = options?.pageQuery ?? {};

	if (reverse) {
		pageQuery.first = 50;
	} else {
		pageQuery.last = 50;
	}

	let endDate;
	let startDate;

	if (selectedDate) {
		if (reverse) {
			startDate = `${format(
				endOfMonth(parseISO(selectedDate)),
				'yyyy-MM-dd',
			)}T00:00:00.000Z`;
		} else {
			endDate = `${format(
				endOfMonth(parseISO(selectedDate)),
				'yyyy-MM-dd',
			)}T23:59:59.999Z`;
		}
	}

	const search = {};

	if (filters.some(({ type }) => type === 'tag')) {
		search['meta.tags'] = {
			$in: filters
				.filter(({ type }) => type === 'tag')
				.map(({ id }) => id),
		};
	}

	if (filters.some(({ type }) => type === 'currency')) {
		search['sums.original.currency'] = {
			$in: filters
				.filter(({ type }) => type === 'currency')
				.map(({ id }) => id),
		};
	}

	if (filters.some(({ type }) => type === 'client')) {
		search['links.clientId'] = {
			$in: filters
				.filter(({ type }) => type === 'client')
				.map(({ id }) => id),
		};
	}

	let status = ['PAID', 'UNPAID', 'SIGNED', 'OVERDUE', 'FORECAST'];

	if (filters.some(({ type }) => type === 'status')) {
		status = filters
			.filter(({ type }) => type === 'status')
			.map(({ id }) => id);
	}

	const request = {
		...options,
		startDate: startDate,
		endDate: endDate,
		search: search,
		types: types,
		status: status,
		scenarioId: scenarioId,
		pageQuery: pageQuery,
	};

	if (state === TransactionStore.constants.STATES.ModifiedOnly) {
		const timestamp = TransactionStore.selectors.timestamp(
			store.getState(),
		);

		request.filters = { updatedAt: { gt: timestamp } };

		delete request.endDate;
		delete request.startDate;
	}

	return request;
}
