import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import PropTypes from 'prop-types';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const FormPropTypes = {
	className: PropTypes.string,
	children: PropTypes.node,

	/**
	 * https://react-hook-form.com/docs/useform#mode
	 *
	 * Validation strategy before submitting behaviour.
	 */
	mode: PropTypes.oneOf([
		'onChange',
		'onBlur',
		'onSubmit',
		'onTouched',
		'all',
	]),

	/**
	 * https://react-hook-form.com/docs/useform#reValidateMode
	 *
	 * Validation strategy after submitting behaviour.
	 */
	reValidateMode: PropTypes.oneOf(['onChange', 'onBlur', 'onSubmit']),

	/**
	 * https://react-hook-form.com/docs/useform#defaultValues
	 *
	 * Default values for the form.
	 */
	defaultValues: PropTypes.object,

	/**
	 * https://react-hook-form.com/docs/useform#values
	 *
	 * Reactive values to update the form values.
	 */
	values: PropTypes.object,

	analyticsKey: PropTypes.string,
	onSubmit: PropTypes.func,
	editing: PropTypes.bool,

	resetOptions: PropTypes.object,
};

/**
 * @type { React.ForwardRefExoticComponent<Partial<FormPropTypes>> }
 */
const Form = React.forwardRef((props, ref) => {
	const {
		className,
		children,
		defaultValues,
		values,
		analyticsKey,
		onSubmit,
		editing = true,
		mode,
		reValidateMode,
		resetOptions,
		...formProps
	} = props;

	const methods = useForm({
		defaultValues: defaultValues,
		values: values,
		mode: mode,
		reValidateMode: reValidateMode,
		resetOptions: resetOptions,
	});

	const onAnalyticsSubmit = React.useCallback(
		(data) => {
			Analytics.event('form.submit', {
				form: data,
				className: className,
				analyticsKey: analyticsKey || className,
			});

			return onSubmit?.(data);
		},
		[onSubmit, className, analyticsKey],
	);

	const onAnalyticsError = React.useCallback(
		(errors) => {
			const hasError = Object.keys(errors ?? {}).length;

			if (!hasError) {
				return;
			}

			Analytics.event('form.error', {
				errors: errors,
				className: className,
				analyticsKey: analyticsKey || className,
			});
		},
		[analyticsKey, className],
	);

	const handleSubmit = React.useCallback(
		async (event) => {
			event.stopPropagation();

			const data = await methods.handleSubmit(
				onAnalyticsSubmit,
				onAnalyticsError,
			)(event);

			return data;
		},
		[methods, onAnalyticsError, onAnalyticsSubmit],
	);

	if (!editing) {
		return children;
	}

	return (
		<FormProvider {...methods}>
			<form
				className={cn('asteria-component__form', className)}
				onSubmit={handleSubmit}
				ref={ref}
				{...formProps}
			>
				{children}
			</form>
		</FormProvider>
	);
});

Form.displayName = 'Form';

Form.propTypes = FormPropTypes;

export default React.memo(Form);
