import React from 'react';

import StreamlineView from '@asteria/view-streamline';

import { LayoutContext } from '../context';

const StreamlinePage = React.memo(function StreamlinePage() {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return <StreamlineView onAction={onAction} onSubmit={onSubmit} />;
});

StreamlinePage.propTypes = {};

export default StreamlinePage;
