import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const BaseQuestion = (props) => {
	const {
		className,
		title,
		children,
		variant,
		type,
		extension,
		onAction,
		details,
		...args
	} = props;

	const onExtensionOpen = React.useCallback(
		() => onAction?.('welcome:extension:open', extension),
		[extension, onAction],
	);

	const onExtensionClose = React.useCallback(
		() => onAction?.('welcome:extension:close', extension),
		[extension, onAction],
	);

	return (
		<div
			className={cn(
				'asteria-view__welcome__question',
				{
					[`asteria-view__welcome__question--variant-${variant}`]:
						variant,
					[`asteria-view__welcome__question--type-${type}`]: variant,
				},
				className,
			)}
			{...args}
		>
			{title ? (
				<Text size="xl" align="center">
					{TranslationService.get(title, title)}
				</Text>
			) : null}
			<div className="asteria-view__welcome__question__content">
				{children}
			</div>
			{extension ? (
				details ? (
					<Button
						variant="link"
						label={TranslationService.get([
							'welcome.question.extension.link',
							`welcome.question.${type}.extension.link`,
							'welcome.question.extension.link.close',
							`welcome.question.${type}.extension.link.close`,
						])}
						tooltip={TranslationService.get([
							'welcome.question.extension.link.tooltip',
							`welcome.question.${type}.extension.link.tooltip`,
							'welcome.question.extension.link.close.tooltip',
							`welcome.question.${type}.extension.link.close.tooltip`,
						])}
						onClick={onExtensionClose}
						analyticsKey={`welcome.question.extension.close`}
					/>
				) : (
					<Button
						variant="link"
						label={TranslationService.get([
							'welcome.question.extension.link',
							`welcome.question.${type}.extension.link`,
							'welcome.question.extension.link.open',
							`welcome.question.${type}.extension.link.open`,
						])}
						tooltip={TranslationService.get([
							'welcome.question.extension.link.tooltip',
							`welcome.question.${type}.extension.link.tooltip`,
							'welcome.question.extension.link.open.tooltip',
							`welcome.question.${type}.extension.link.open.tooltip`,
						])}
						onClick={onExtensionOpen}
						analyticsKey={`welcome.question.extension.open`}
					/>
				)
			) : null}
		</div>
	);
};

BaseQuestion.displayName = 'BaseQuestion';

BaseQuestion.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.oneOf(['contenter', 'bars']),
	type: PropTypes.string,
	extension: PropTypes.any,
	details: PropTypes.bool,

	onAction: PropTypes.func,
};

export default BaseQuestion;
