import React from 'react';

/**
 * @param { string | string[] } colors
 * @param { string } [prefix]
 * @returns {{ custom: string[], typed: string[] }}
 */
const useColors = (colors = [], prefix = 'asteria-color__') =>
	React.useMemo(
		() =>
			[]
				.concat(colors)
				.filter(Boolean)
				.reduce(
					(acc, value) => {
						const isCustomColor =
							value.startsWith('#') ||
							value.startsWith('hsl') ||
							value.startsWith('rgb') ||
							value.startsWith('var(');

						if (isCustomColor) {
							acc.custom = acc.custom.concat(value);
						} else {
							acc.typed = acc.typed.concat(prefix + value);
						}

						return acc;
					},
					{ custom: [], typed: [] },
				),
		[colors, prefix],
	);

export default useColors;
