import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import useContenter from '@asteria/component-core/utils/useContenter';
import { FooterSection } from '@asteria/component-core/wrapper';

import { CompleteScreen } from '@asteria/component-alert';
import Feedback from '@asteria/component-feedback';
import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Step, { Content, Footer, Header } from '../../components/Step';
import { useFlow } from '../../hooks';

import './styles.scss';

const IntegrationSuccessFeedback = (props) => {
	const { type, name, onAction } = props;

	const [showFeedback, setShowFeedback] = React.useState(true);

	const onFeedbackAction = React.useCallback(
		(action, data) => {
			if (data.dismissed) {
				setShowFeedback(false);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const onFeedbackSupportRequest = React.useCallback(
		() => onAction?.('go', '/support'),
		[onAction],
	);

	return (
		<FeatureFlag feature="feedback">
			{showFeedback ? (
				<Feedback
					description={TranslationService.get([
						'feedback.text',
						'feedback.integration.text',
						`feedback.integration.${type}.text`,
						`feedback.integration.${type}.${name}.text`,
					])}
					feedbackKey={`add-${type}`}
					maxText={TranslationService.get([
						'feedback.rating.max',
						'feedback.integration.rating.max',
						`feedback.integration.${type}.rating.max`,
						`feedback.integration.${type}.${name}.rating.max`,
					])}
					minText={TranslationService.get([
						'feedback.rating.min',
						'feedback.integration.rating.min',
						`feedback.integration.${type}.rating.min`,
						`feedback.integration.${type}.${name}.rating.min`,
					])}
					values={[1, 2, 3, 4, 5]}
					size="lg"
					title=""
					onAction={onFeedbackAction}
					onSupportRequest={onFeedbackSupportRequest}
					onEnd={() => setShowFeedback(false)}
					validate
					type={type}
				/>
			) : null}
		</FeatureFlag>
	);
};

IntegrationSuccessFeedback.displayName = 'IntegrationSuccessFeedback';

IntegrationSuccessFeedback.propTypes = {
	type: PropTypes.string,
	onAction: PropTypes.func,
	name: PropTypes.string,
};

function useAnalytics(integration) {
	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.success', {
			integration: {
				_id: integration?._id,
				key: integration?.key,
				type: integration?.type,
				lastSync: integration?.lastSync,
				disabled: integration?.disabled,
				status: integration?.status,
				config: {
					connected: integration?.config?.connected,
					errors: integration?.config?.errors,
				},
				actions: integration?.actions,
			},
		});

		return () => {
			Analytics.endFlow('integrations.success');
		};
	}, []);

	return 'integrations.success';
}

const IntegrationSuccess = (props) => {
	const { className, onAction, onClose, type: $type, header } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, _id),
	);

	const type = $type ?? integration?.type;
	const key = integration?.key;

	const analyticsFlow = useAnalytics(integration);

	const content = useContenter(`integrations.${key}.success`);

	const step = `${type}.success`;

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, dispatch, onClose],
	);

	const done = React.useCallback(
		(event) => {
			const shouldOpenList = (
				store.getState()?.modals?.history ?? []
			).some(
				(object) =>
					object?.type ===
					ModalStore.MODAL_WINDOWS.IntegrationConnect,
			);

			close(event);

			if (shouldOpenList) {
				onAction?.('go', '/integrations');
			}
		},
		[close, onAction, store],
	);

	const flow = useFlow(integration?.type, integration?.key);

	const integrationName = TranslationService.get(
		[
			'integration.title',
			`integration.title.${integration?.key}`,
			`integration.title.${integration?.type}.${integration?.key}`,
			`integration.${integration?.key}.title`,
			`integration.${integration?.type}.${integration?.key}.title`,
		],
		flow?.name,
	);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-generic__success',
				{
					[`asteria-component__onboarding-generic__success--type-${type}`]:
						type,
				},
				className,
			)}
			step={step}
		>
			<Header onClose={!header?.hideClose ? close : null}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${type}.add.title`,
						`integrations.${key}.add.title`,
						`integrations.${type}.${key}.add.title`,
						`integrations.success.title`,
						`integrations.${type}.success.title`,
						`integrations.${key}.success.title`,
						`integrations.${type}.${key}.success.title`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Header>
			<Content step={step}>
				<CompleteScreen
					level="success"
					icon="check"
					iconPosition="first"
					title={TranslationService.get(
						[
							`integrations.success.title`,
							`integrations.${type}.success.title`,
							`integrations.${type}.${key}.success.title`,
						],
						undefined,
						{ integration: integration, name: integrationName },
					)}
				>
					<Title type="title" size="xs" align="center">
						{TranslationService.get(
							[
								`integrations.success.subtitle`,
								`integrations.${type}.success.subtitle`,
								`integrations.${type}.${key}.success.subtitle`,
							],
							undefined,
							{ integration: integration, name: integrationName },
						)}
					</Title>
					<Text
						weight="semibold"
						size="lg"
						className="asteria-component__alert--highlight"
						align="center"
					>
						{TranslationService.get(
							[
								`integrations.success.integration`,
								`integrations.${type}.success.integration`,
								`integrations.${type}.${key}.success.integration`,
							],
							'{{ name | capitalize }}',
							{ integration: integration, name: integrationName },
						)}
					</Text>
					<Text align="center" size="sm">
						{TranslationService.get(
							[
								`integrations.success.content`,
								`integrations.${type}.success.content`,
								`integrations.${type}.${key}.success.content`,
							],
							undefined,
							{ integration: integration, name: integrationName },
						)}
					</Text>
					<Contenter content={content} />
				</CompleteScreen>
				<IntegrationSuccessFeedback
					type={type}
					name={key}
					onAction={onAction}
				/>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${key}.done`}
						variant="primary"
						label={TranslationService.get(
							[
								'action.done',
								'button.done',
								'onboarding.modal.action',
								'onboarding.modal.action.done',
								'onboarding.modal.flow-success.action',
								'onboarding.modal.flow-success.action.done',
								`onboarding.modal.${integration?.type}.action`,
								`onboarding.modal.${integration?.type}.action.done`,
								`onboarding.modal.${integration?.type}.flow-success.action`,
								`onboarding.modal.${integration?.type}.flow-success.action.done`,
								`onboarding.modal.${integration?.key}.action`,
								`onboarding.modal.${integration?.key}.action.done`,
								`onboarding.modal.${integration?.key}.flow-success.action`,
								`onboarding.modal.${integration?.key}.flow-success.action.done`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action.done`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-success.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-success.action.done`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={done}
					/>
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationSuccess.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,

	header: PropTypes.shape({ hideClose: PropTypes.bool }),
};

IntegrationSuccess.defaultProps = {};

export default IntegrationSuccess;
export { IntegrationSuccessFeedback, useAnalytics };
