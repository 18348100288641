import React from 'react';

import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

function transform(value) {
	return format(parseISO(value || new Date().toISOString()), 'yyyy-MM-dd');
}

const PaymentDateCell = React.memo((props) => {
	const { className, data } = props;

	const content = transform(data);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<Text title={content}>{content}</Text>
		</div>
	);
});

PaymentDateCell.displayName = 'PaymentDateCell';

PaymentDateCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.string,
};

export default PaymentDateCell;
