import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Checkbox from '@asteria/component-form/checkbox';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SelectorCell = React.memo((props) => {
	const { className, data } = props;

	const dispatch = useDispatch();

	const isSelected = useSelector((store) =>
		TransactionStore.selectors.isSelected(store, data),
	);

	const handleChange = React.useCallback(
		() => dispatch(TransactionStore.setSelected(data)),
		[data, dispatch],
	);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<Checkbox uncontrolled onChange={handleChange} value={isSelected} />
		</div>
	);
});

SelectorCell.displayName = 'SelectorCell';

SelectorCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.string,
	onAction: PropTypes.func,
};

export default SelectorCell;
