export const initialState = {
	unpaid: [],
	selected: [],
	batches: [],
	layouts: [],
	search: '',
	filters: [],
	serviceId: null,
	version: 1,
	reports: { timestamp: null },
};
