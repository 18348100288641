import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import GraphLogic from '@asteria/component-graph/logic';

import { updateAxis } from '@asteria/datalayer/stores/graph';

const selectors = {
	graphLayout: createSelector(
		(store) => store?.app?.user?.settings?.layout?.graph,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	graphStatistics: createSelector(
		(store) => store?.graph?.statistics,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	dynamicCredit: createSelector(
		(store) => store?.accounts?._credit,
		(dynamicValue) => dynamicValue ?? 0,
	),
	range: createSelector(
		(store) => store?.graph?.data?.range,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	barGroups: createSelector(
		(store) => store?.graph?.data?.barGroups,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	selectedTransactions: createSelector(
		(store) => store?.transactions?.states ?? {},
		(store) => store?.transactions?.items ?? {},
		(states, items) =>
			Object.entries(states)
				.filter(([, { selected }]) => selected)
				.map(([key]) => items[key])
				.filter(Boolean),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Graph = (props) => {
	const { onAction, onResize, onSubmit, variant } = props;

	const dispatch = useDispatch();

	const graphLayout = useSelector(selectors.graphLayout);
	const graphStatistics = useSelector(selectors.graphStatistics);
	const range = useSelector(selectors.range);
	const barGroups = useSelector(selectors.barGroups);
	const dynamicCredit = useSelector(selectors.dynamicCredit);

	const requestData = useCallback(
		(startDate, { signal } = {}) =>
			onSubmit?.('cashflow', {
				startDate: startDate,
				signal: signal,
			}),
		[onSubmit],
	);

	useEffect(() => {
		if (dynamicCredit) {
			dispatch(
				updateAxis({
					barLayout: graphLayout?.layout,
					scale: graphLayout?.scale,
					mode: 'credit',
					dynamicCredit: dynamicCredit,
				}),
			);
		} else {
			dispatch(
				updateAxis({
					barLayout: graphLayout?.layout,
					scale: graphLayout?.scale,
					mode: 'credit',
				}),
			);
		}
	}, [
		range,
		barGroups,
		dispatch,
		dynamicCredit,
		graphLayout?.layout,
		graphLayout?.scale,
		graphStatistics,
	]);

	const onUpdateSettings = useCallback(
		(form) => onAction?.('updateUserSettings', form),
		[onAction],
	);

	return (
		<GraphLogic
			requestData={requestData}
			onResize={onResize}
			onUpdateSettings={onUpdateSettings}
			onAction={onAction}
			variant={variant}
			mode="credit"
		/>
	);
};

Graph.propTypes = {
	onResize: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	variant: PropTypes.string,
};

export default Graph;
