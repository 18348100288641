import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { CompleteScreen } from '@asteria/component-alert';
import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Select, { Option } from '@asteria/component-form/select';
import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { Content } from '../Step';

const SuccessScreen = (props) => {
	const { type, onClose, integration } = props;

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.missing.success', {
			integrationType: type,
			integration: integration,
		});

		return () => {
			Analytics.endFlow('integrations.missing.success');
		};
	}, []);

	const handleClose = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.missing.success');

			return onClose?.(event);
		},
		[onClose],
	);

	return (
		<Wrapper className={cn('asteria-component__onboarding-missing')}>
			<Header onClose={handleClose}>
				{TranslationService.get([
					'integrations.missing.success.header',
					`integrations.missing.${type}.success.header`,
				])}
			</Header>
			<Content scroll>
				<CompleteScreen
					level="success"
					icon="check"
					iconPosition="first"
					title={TranslationService.get(
						[
							'integrations.missing.success.title',
							`integrations.missing.${type}.success.title`,
							`integrations.missing.success.title.${integration}`,
							`integrations.missing.${type}.success.title.${integration}`,
						],
						undefined,
						{
							integration: integration,
							key: integration,
							name: integration,
						},
					)}
				>
					<Title type="title" size="xs" align="center">
						{TranslationService.get(
							[
								'integrations.missing.success.subtitle',
								`integrations.missing.${type}.success.subtitle`,
								`integrations.missing.success.subtitle.${integration}`,
								`integrations.missing.${type}.success.subtitle.${integration}`,
							],
							undefined,
							{
								integration: integration,
								key: integration,
								name: integration,
							},
						)}
					</Title>
					<Text
						type="text"
						size="lg"
						weight="semibold"
						align="center"
						className="asteria-component__alert--highlight"
					>
						{TranslationService.get(
							[
								'integrations.missing.success.integration',
								`integrations.missing.${type}.success.integrations`,
								`integrations.missing.success.integrations.${integration}`,
								`integrations.missing.${type}.success.integrations.${integration}`,
							],
							'{{ name | cap }}',
							{
								integration: integration,
								key: integration,
								name: integration,
							},
						)}
					</Text>
					<Text align="center" size="sm">
						{TranslationService.get(
							[
								'integrations.missing.success.content',
								`integrations.missing.${type}.success.content`,
								`integrations.missing.success.content.${integration}`,
								`integrations.missing.${type}.success.content.${integration}`,
							],
							undefined,
							{
								integration: integration,
								key: integration,
								name: integration,
							},
						)}
					</Text>
				</CompleteScreen>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get('action.done')}
						onClick={handleClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

SuccessScreen.propTypes = {
	type: PropTypes.string,
	onClose: PropTypes.func,
	integration: PropTypes.string,
};

const MissingFormContent = (props) => {
	const { type, onClose } = props;

	const form = useWatch();
	const { unregister } = useFormContext();

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.missing.form', {
			integrationType: type,
		});

		return () => {
			Analytics.endFlow('integrations.missing.form');
		};
	}, []);

	const handleClose = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.missing.form');

			return onClose?.(event);
		},
		[onClose],
	);

	const integrations = useConfig([
		'widget.integrations.missing',
		`widget.integrations.${type}.missing`,
	]) ?? ['bokio', 'dooer', 'speedledger'];

	React.useEffect(() => {
		if (form?.software !== 'custom') {
			unregister('customSoftware');
		}
	}, [form?.software, unregister]);

	return (
		<Wrapper className={cn('asteria-component__onboarding-missing')}>
			<Header onClose={handleClose}>
				{TranslationService.get([
					'integrations.missing.form.header',
					`integrations.missing.${type}.form.header`,
				])}
			</Header>
			<Content scroll>
				<Text size="sm">
					{TranslationService.get([
						'integrations.missing.form.content',
						`integrations.missing.${type}.form.content`,
					])}
				</Text>

				<FormWrapper>
					<Content>
						<Select
							placeholder={TranslationService.get([
								'integrations.missing.form.placeholder',
								`integrations.missing.${type}.form.placeholder`,
							])}
							name="software"
						>
							{integrations.map((value) => (
								<Option key={value} value={value}>
									{TranslationService.get(
										[
											'integrations.list.item',
											`integrations.missing.form.integrations.${value}`,
										],
										'{{ name | cap }}',
										{ key: value, name: value },
									)}
								</Option>
							))}
							<Option value="custom">
								{TranslationService.get([
									'integrations.missing.form.integrations.custom',
									`integrations.missing.${type}.form.integrations.custom`,
								])}
							</Option>
						</Select>
						{form?.software === 'custom' && (
							<Input
								direction="vertical"
								type="text"
								name="customSoftware"
								placeholder={TranslationService.get([
									'integrations.missing.form.integrations.custom.placeholder',
									`integrations.missing.${type}.form.integrations.custom.placeholder`,
								])}
							/>
						)}
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="secondary"
						label={TranslationService.get('action.close')}
						onClick={handleClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						type="submit"
						variant="primary"
						label={TranslationService.get('action.next')}
						disabled={
							!form?.software ||
							(form?.software === 'custom' &&
								!form?.customSoftware)
						}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

MissingFormContent.propTypes = {
	type: PropTypes.string,
	onClose: PropTypes.func,
};

MissingFormContent.displayName = 'MissingFormContent';

const FormScreen = (props) => {
	const { type, onClose, onNext } = props;

	const handleSubmit = React.useCallback((form) => onNext?.(form), [onNext]);

	return (
		<Form onSubmit={handleSubmit}>
			<MissingFormContent type={type} onClose={onClose} />
		</Form>
	);
};

FormScreen.propTypes = {
	type: PropTypes.string,
	onClose: PropTypes.func,
	onNext: PropTypes.func,
	setSelected: PropTypes.func,
	selected: PropTypes.string,
};

const MissingIntegration = (props) => {
	const { type, onClose, onSubmit } = props;
	const [integration, setIntegration] = React.useState(null);

	const handleNext = React.useCallback(
		(form) => {
			const integration = form?.customSoftware ?? form?.software;

			onSubmit?.('integrations:missing', { title: integration });
			setIntegration(integration);
		},
		[onSubmit],
	);

	if (!integration) {
		return <FormScreen type={type} onClose={onClose} onNext={handleNext} />;
	}

	return (
		<SuccessScreen
			type={type}
			onClose={onClose}
			integration={integration}
		/>
	);
};

MissingIntegration.propTypes = {
	type: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

const MissingIntegrationModal = (props) => {
	const { className, open } = props;

	return (
		<Modal open={open} className={className} size="sm">
			<MissingIntegration {...props} />
		</Modal>
	);
};

MissingIntegrationModal.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default MissingIntegrationModal;
export { MissingIntegration };
