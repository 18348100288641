import React from 'react';

import { useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import Form, {
	Group as FormGroup,
	Wrapper as FormWrapper,
} from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const HelpModalContentConfirmation = React.memo(() => {
	const email = useWatch({ name: 'email' });

	return (
		<Checkbox
			name="confirm"
			label={TranslationService.get(
				[
					'welcome.help.form.recommended.confirm',
					'welcome.help.form.recommended.confirm.email',
				],
				undefined,
				{ email: email },
			)}
			required
		/>
	);
});

HelpModalContentConfirmation.displayName = 'HelpModalContentConfirmation';

const HelpModalContent = (props) => {
	const { onClose, loading } = props;

	return (
		<Wrapper scroll className="asteria-view__welcome__help">
			<Header onClose={onClose}>
				{TranslationService.get(['welcome.help.form.title'])}
			</Header>
			<Content scroll>
				<FormWrapper>
					<Content>
						<FormGroup>
							<Input
								debounce={1000}
								type="email"
								name="email"
								required
								label={TranslationService.get([
									'welcome.help.form.email.label',
								])}
								placeholder={TranslationService.get([
									'welcome.help.form.email.placeholder',
								])}
							/>
							<FormGroup>
								<Text size="sm">
									{TranslationService.get([
										'welcome.help.form.select.title',
									])}
								</Text>
								<Checkbox
									name="flags.actionAddBank"
									label={TranslationService.get([
										'welcome.help.form.flags.actionAddBank',
									])}
								/>
								<Checkbox
									name="flags.actionAddERP"
									label={TranslationService.get([
										'welcome.help.form.flags.actionAddERP',
									])}
								/>
								<Text size="sm">
									{TranslationService.get([
										'welcome.help.form.recommended',
									])}
								</Text>
								<FormGroup>
									<Checkbox
										name="flags.actionForecast"
										label={TranslationService.get([
											'welcome.help.form.flags.actionForecast',
										])}
									/>
									<Checkbox
										name="flags.actionQuestions"
										label={TranslationService.get([
											'welcome.help.form.flags.actionQuestions',
										])}
									/>
								</FormGroup>
							</FormGroup>
							<Input
								debounce={1000}
								type="textarea"
								name="message"
								label={TranslationService.get([
									'welcome.help.form.message.label',
								])}
								placeholder={TranslationService.get([
									'welcome.help.form.message.placeholder',
								])}
							/>
							<HelpModalContentConfirmation />
						</FormGroup>
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						label={TranslationService.get([
							'button.cancel',
							'welcome.help.form.button.cancel',
						])}
						variant="tertiary"
						onClick={onClose}
						analyticsKey={`welcome.help.modal.cancel`}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						label={TranslationService.get([
							'button.submit',
							'welcome.help.form.button.submit',
						])}
						variant="primary"
						type="submit"
						loading={loading}
						analyticsKey={`welcome.help.modal.submit`}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

HelpModalContent.displayName = 'HelpModalContent';

HelpModalContent.propTypes = {
	onClose: PropTypes.func,
	loading: PropTypes.bool,
};

const HelpContentSuccess = (props) => {
	const { onClose } = props;

	return (
		<Wrapper>
			<Header onClose={onClose}>
				{TranslationService.get(['welcome.help.success.title'])}
			</Header>
			<Content>
				<Alert
					title={TranslationService.get([
						'welcome.help.success.alert.title',
					])}
					level="success"
					icon="check"
				>
					<Translation
						translationKey="welcome.help.success.alert.content"
						Component={Text}
					/>
				</Alert>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'welcome.help.success.button.close',
						])}
						onClick={onClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

HelpContentSuccess.displayName = 'HelpContentSuccess';
HelpContentSuccess.propTypes = { onClose: PropTypes.func };

const HelpContentWrapper = (props) => {
	const { onClose, onSubmit, defaultValues: $defaultValues } = props;

	const [isLoading, setLoading] = React.useState(false);

	const [step, setStep] = React.useState(0);

	const defaultValues = React.useMemo(
		() => ({
			...($defaultValues || {}),
		}),
		[$defaultValues],
	);

	const handleSubmit = React.useCallback(
		async (form) => {
			setLoading(true);

			await onSubmit?.('welcome:help', form);

			setLoading(false);

			setStep((step) => step + 1);
		},
		[onSubmit],
	);

	return (
		<Form onSubmit={handleSubmit} defaultValues={defaultValues}>
			{step === 0 ? (
				<HelpModalContent
					onClose={onClose}
					form={defaultValues}
					loading={isLoading}
				/>
			) : null}
			{step === 1 ? <HelpContentSuccess onClose={onClose} /> : null}
		</Form>
	);
};

HelpContentWrapper.displayName = 'HelpContentWrapper';

HelpContentWrapper.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	defaultValues: PropTypes.object,
};

const HelpModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			size="sm"
			className={cn(
				'asteria-view__welcome__modal',
				'asteria-view__welcome__modal--help',
			)}
		>
			<HelpContentWrapper {...props} />
		</Modal>
	);
};

HelpModal.displayName = 'HelpModal';

HelpModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	defaultValues: PropTypes.object,
};

export default HelpContentWrapper;
export { HelpModal };
