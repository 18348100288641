import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { getPath } from '../../../utils/getFormPaths';

import { useBarGraphData, useLegends } from './hooks';
import View from './view';

const BarGraphProvider = React.memo((props) => {
	const {
		className,

		onAction,

		type,
		category,
		tag,

		dates,
		types = [type],

		showYAxis,
		showLines,
		showLegends,

		render,
	} = props;

	const { getValues, setValue } = useFormContext();

	React.useEffect(() => {
		const form = getValues();

		const dates = Object.keys(form?.[type]?.data ?? {});

		for (const date of dates) {
			const path = getPath({
				type,
				date,
				status: 'FORECAST',
				category,
				tag,
			});

			const [value, probability] = getValues([
				[path, 'value'].join('.'),
				[path, 'probability'].join('.'),
			]);

			const options = {
				shouldDirty: false,
				shouldTouch: false,
				shouldValidate: false,
			};

			if (value === undefined) {
				setValue([path, 'value'].join('.'), 0, options);
			}

			if (probability === undefined) {
				setValue([path, 'probability'].join('.'), 1, options);
			}
		}
	}, [type, category, tag, getValues, setValue]);

	const { labels, title, max, min } = useBarGraphData({
		type,
		dates,
		category,
		tag,
		types,
	});

	const legends = useLegends({ type, category, tag, types, dates });

	return (
		<View
			className={className}
			onAction={onAction}
			types={types}
			type={type}
			category={category}
			tag={tag}
			dates={dates}
			labels={labels}
			title={title}
			max={max}
			min={min}
			showYAxis={showYAxis}
			showLines={showLines}
			showLegends={showLegends}
			legends={legends}
			render={render}
		/>
	);
});

BarGraphProvider.displayName = 'BarGraphProvider';

BarGraphProvider.propTypes = {
	className: PropTypes.string,

	dates: PropTypes.arrayOf(PropTypes.string),
	types: PropTypes.arrayOf(PropTypes.string),

	onAction: PropTypes.func,

	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	showYAxis: PropTypes.bool,
	showLines: PropTypes.bool,
	showLegends: PropTypes.bool,

	render: PropTypes.func,
};

export default BarGraphProvider;
