import React from 'react';
import { cloneElement } from 'react';

import PropTypes from 'prop-types';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import { stateClasses } from '../utils';

const TabsNavigation = (props) => {
	const {
		className,
		variant,
		direction,
		children,
		tab,
		setTab,
		analyticsKey,
	} = props;

	return (
		<div
			className={cn(
				'asteria-component__tabs__navigation__wrapper',
				{
					[`asteria-component__tabs__navigation__wrapper--variant-${variant}`]:
						variant,
					[`asteria-component__tabs__navigation__wrapper--direction-${direction}`]:
						direction,
				},
				className,
			)}
		>
			{React.Children.map(children, (child, index) => {
				const current = child?.props?.tab;
				const className = child?.props?.className;
				const onClick = child?.props?.onClick;

				const isActive = tab?.includes?.(current);

				return cloneElement(child, {
					className: cn(
						'asteria-component__tabs__navigation',
						`asteria-component__tabs__navigation--name-${current}`,
						stateClasses({ active: isActive }),
						className,
					),
					key: ['navigation', current, index]
						.filter(Boolean)
						.join('-'),
					onClick: (...args) => {
						Analytics.event('tab.change', {
							analyticsKey: analyticsKey,
							value: current,
						});

						setTab(current);

						return onClick?.(...args);
					},
				});
			})}
		</div>
	);
};

TabsNavigation.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	variant: PropTypes.oneOf(['horizontal', 'vertical']),
	direction: PropTypes.string,
	tab: PropTypes.arrayOf(PropTypes.string).isRequired,
	setTab: PropTypes.func,
	analyticsKey: PropTypes.string,
};

TabsNavigation.defaultProps = {
	variant: 'horizontal',
};

TabsNavigation.displayName = 'TabsNavigation';

export default TabsNavigation;
