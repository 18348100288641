import React, { useMemo } from 'react';

import Button from '@asteria/component-core/button';
import Tabs, {
	TabsContent,
	TabsNavigation,
} from '@asteria/component-core/tabs';
import { Title } from '@asteria/component-core/typography';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import Legends from '../components/legends';
import Menu from '../controllers/menu';
import Graph from '../graphs/graph';

const DefaultGraphs = {
	default: 'bars',
	bar: 'bars',
	account: 'account',
	badge: 'bars',
	'insight-unpaid': 'bars',
	'insight-overdue': 'bars',
};

const TabbedGraphs = (props) => {
	const {
		graph = {},
		updateSize,
		next,
		prev,
		onClick,
		onMouseEnter,
		onMouseLeave,
		getTooltip,
		mode = '',
		currentTimesliceSize,
		requestData,
		filters,
		hasUnpaid,
		hasOverdue,
		hasForecast,
		hasCredit,
		onUpdateSettings,
	} = props;

	const hasSpreadFeature = true;

	const listOpenSource = false;
	const availableCategories = useMemo(() => [], []);

	const defaultActiveTabs =
		DefaultGraphs[listOpenSource] || DefaultGraphs.default;

	const legends = [
		{
			key: 'history',
			label: TranslationService.get('graph.account.legend.history'),
		},
	];
	if (hasForecast) {
		legends.push({
			key: 'forecast',
			label: TranslationService.get('graph.account.legend.forecast'),
		});
	}

	if (hasSpreadFeature && hasForecast) {
		legends.push({
			key: 'spread',
			label: TranslationService.get('graph.account.legend.spread'),
		});
	}

	if (hasCredit) {
		legends.push({
			key: 'credit',
			label: TranslationService.get('graph.account.legend.credit'),
		});
	}

	return (
		<Tabs active={defaultActiveTabs} key="tabs">
			<TabsNavigation key="navigation">
				<FeatureFlag feature="tabsAsIcons" invert>
					<Button
						variant="primary"
						size="sm"
						label={TranslationService.get(
							'graph.tab.accountbalance',
							// eslint-disable-next-line spellcheck/spell-checker
							'Kontobalans',
						)}
						tab="account"
					/>
				</FeatureFlag>
				<FeatureFlag feature="tabsAsIcons">
					<Button
						variant="primary"
						size="sm"
						icon="accountBalance"
						// eslint-disable-next-line spellcheck/spell-checker
						data-tooltip="Visa kontobalans"
						tab="account"
					/>
				</FeatureFlag>
				<FeatureFlag feature="tabsAsIcons" invert>
					<Button
						variant="primary"
						size="sm"
						label={TranslationService.get(
							'graph.tab.revenue.expenses',
							'In-/utbetalningar',
						)}
						tab="bars"
					/>
				</FeatureFlag>
				<FeatureFlag feature="tabsAsIcons">
					<Button
						variant="primary"
						size="sm"
						icon="transactions"
						// eslint-disable-next-line spellcheck/spell-checker
						data-tooltip="Visa in-/utbetalningar"
						tab="bars"
					/>
				</FeatureFlag>
			</TabsNavigation>
			<TabsContent key="content">
				<div tab="account" className="asteria-tab-account">
					<Title
						size="title2"
						className="asteria-graph-line-graph-component"
					>
						{TranslationService.get('graph.account.title')}
					</Title>
					<Legends
						className="asteria-graph-line-graph-component asteria-graph-line-graph-legends"
						legends={legends}
					/>
					<Graph
						className="asteria-graph-line-graph-component"
						parts={['lines']}
						graph={graph}
						updateSize={updateSize}
						next={next}
						prev={prev}
						onClick={onClick}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						getTooltip={getTooltip}
						id={graph.id}
						filters={filters}
						availableCategories={availableCategories}
						size={currentTimesliceSize}
						barLayout="grouped"
						mode={mode}
						requestData={requestData}
						onUpdateSettings={onUpdateSettings}
					/>
				</div>
				<div tab="bars" className="asteria-tab-bars">
					<Title size="title2">
						{TranslationService.get('graph.cashflow.title')}
					</Title>
					<Menu
						id="cashflow-bar-graph"
						filters={filters}
						hasUnpaid={hasUnpaid}
						hasOverdue={hasOverdue}
						hasForecast={hasForecast}
					/>
					<Graph
						parts={['bars']}
						graph={graph}
						updateSize={updateSize}
						next={next}
						prev={prev}
						onClick={onClick}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
						getTooltip={getTooltip}
						id={graph.id}
						filters={filters}
						availableCategories={availableCategories}
						size={currentTimesliceSize}
						barLayout="grouped"
						mode={mode}
						requestData={requestData}
						onUpdateSettings={onUpdateSettings}
					/>
				</div>
			</TabsContent>
		</Tabs>
	);
};

export default TabbedGraphs;
