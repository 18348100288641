import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { useColor } from './utils';

import '../index.scss';

const IndeterminateProgress = React.memo((props) => {
	const { className, color } = props;

	const { style: colorStyle, className: colorClassName } = useColor(color);
	const style = { ...colorStyle };

	return (
		<div
			className={cn(
				'asteria-component__progress',
				'asteria-component__progress--variant-indeterminate',
				className,
			)}
		>
			<div
				className={cn(
					'asteria-component__progress__status',
					colorClassName,
				)}
				style={style}
			/>
		</div>
	);
});

IndeterminateProgress.displayName = 'IndeterminateProgress';

IndeterminateProgress.propTypes = {
	className: PropTypes.string,
	color: PropTypes.shape({ value: PropTypes.string, type: PropTypes.string }),
};

export default IndeterminateProgress;
