import React from 'react';

import PropTypes from 'prop-types';

const Header = (props) => {
	const { children } = props;

	return (
		<div className="asteria-component__group asteria-component__group--direction-horizontal asteria-component__group--vertical-align-baseline asteria-component__wrapper-header">
			<div className="asteria-component__wrapper-header__content">
				<p className="asteria-component__typography asteria-component__title asteria-component__title--size-md">
					{children}
				</p>
			</div>
		</div>
	);
};

Header.displayName = 'Header';

Header.propTypes = { children: PropTypes.node };

export default Header;
