function set(state, { payload }) {
	const edges = payload?.edges ?? [];
	const pageInfo = payload?.pageInfo ?? {};
	const $direction = payload?.$direction ?? 'ASC';

	const hasNextPage = pageInfo?.hasNextPage ?? false;
	const hasPreviousPage = pageInfo?.hasPreviousPage ?? false;
	const count = pageInfo?.count ?? 0;
	const currentCursor = pageInfo?.currentCursor ?? null;
	const startCursor = pageInfo?.startCursor ?? null;
	const endCursor = pageInfo?.endCursor ?? null;

	state.companies.data = edges;
	state.companies.count = count;

	state.companies.pagination.direction = $direction;

	const history = state.companies.pagination.history ?? [];

	if (history.includes(currentCursor)) {
		const index = history.indexOf(currentCursor);

		state.companies.pagination.history = history.slice(0, index + 1);
	} else {
		state.companies.pagination.history = history.concat(currentCursor);
	}

	state.companies.pagination.currentCursor = currentCursor;
	state.companies.pagination.startCursor = startCursor;
	state.companies.pagination.endCursor = endCursor;

	if ($direction === 'ASC') {
		state.companies.pagination.hasNextPage = hasNextPage;
		state.companies.pagination.hasPreviousPage = hasPreviousPage;
	} else {
		state.companies.pagination.hasNextPage = hasPreviousPage;
		state.companies.pagination.hasPreviousPage = hasNextPage;
	}
}

function update(state, { payload }) {
	const input = [].concat(payload);

	const objects = state.companies.data.reduce(
		(acc, object) => ({ ...acc, [object?._id ?? object.id]: object }),
		{},
	);

	for (const object of input) {
		const ID = object?._id ?? object.id;

		objects[ID] = object;
	}

	state.companies.data = Object.values(objects);
}

function remove(state, { payload }) {
	const input = [].concat(payload);

	const objects = state.companies.data.reduce(
		(acc, object) => ({ ...acc, [object?._id ?? object.id]: object }),
		{},
	);

	for (const ID of input) {
		delete objects[ID];
	}

	state.companies.data = Object.values(objects);
}

function reset(state) {
	state.companies.data = [];
}

function start(state) {
	state.companies.loading = true;
}

function stop(state) {
	state.companies.loading = false;
}

function setFilters(state, { payload }) {
	const filters = [].concat(payload);

	for (const { type, value, replace } of filters) {
		if (value === null) {
			state.companies.filters = state.companies.filters.filter(
				(object) => object?.type !== type,
			);
		} else {
			if (replace) {
				const index = state.companies.filters.findIndex(
					(object) => object?.type === type,
				);

				if (index !== -1) {
					state.companies.filters[index].value = value;
				} else {
					state.companies.filters = state.companies.filters.concat({
						type: type,
						value: value,
					});
				}
			} else {
				state.companies.filters = state.companies.filters.concat({
					type: type,
					value: value,
				});
			}
		}
	}
}

function toggleFilters(state, { payload }) {
	const filters = [].concat(payload);

	for (const { type, value } of filters) {
		const index = state.companies.filters.findIndex(
			(object) =>
				object?.type === type &&
				(value?.type ?? value) ===
					(object?.value?.type ?? object?.value),
		);

		if (index === -1) {
			state.companies.filters = state.companies.filters.concat({
				type: type,
				value: value,
			});
		} else {
			state.companies.filters.splice(index, 1);
		}
	}
}

export {
	set as setCompanies,
	update as updateCompanies,
	remove as removeCompanies,
	reset as resetCompanies,
	start as startCompaniesLoading,
	stop as stopCompaniesLoading,
	setFilters as setCompaniesFilters,
	toggleFilters as toggleCompaniesFilters,
};
