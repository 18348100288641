import React from 'react';

import LineGraphColumn from '@asteria/component-forecaster/components/graph/lines/column';

import LoanLineGraphDot from './dot';
import LoanLineGraphLine from './line';

const LoanLineGraphColumn = React.memo((props) => {
	return (
		<LineGraphColumn
			{...props}
			renderLine={(props) => <LoanLineGraphLine {...props} />}
			renderDot={(props) => <LoanLineGraphDot {...props} />}
		/>
	);
});

LoanLineGraphColumn.displayName = 'LoanLineGraphColumn';

LoanLineGraphColumn.propTypes = {};

export default LoanLineGraphColumn;
