import { TagService } from '@asteria/backend-utils-services';

import { setTags } from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import * as TagsAPI from '../api/tags';

/**
 * @param { { dispatch: unknown, accessToken: string, event: { type?: 'settings:save', data?: unknown } } } options
 */
export const onAction = (options) => {
	const { event, dispatch, accessToken } = options;

	if (event?.type === 'tag:update') {
		TagService.tag
			.update({ input: event?.data }, { token: accessToken })
			.then(() => TagsAPI.fetch({ accessToken: accessToken }))
			.then((data) => {
				dispatch(setTags(data));
				dispatch(ForecasterStore.tags.refresh(data));
			});

		dispatch(ModalStore.close());
	}

	if (event?.type === 'tag:remove') {
		TagService.tag
			.remove({ ids: event?.data?._id }, { token: accessToken })
			.then(() => TagsAPI.fetch({ accessToken: accessToken }))
			.then((data) => {
				dispatch(setTags(data));
				dispatch(ForecasterStore.tags.refresh(data));
			});

		dispatch(ModalStore.close());
	}
};
