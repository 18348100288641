/* eslint-disable spellcheck/spell-checker */
export const Accuracy = ({ variant }) => [
	{
		as: 'Text',
		props: {
			weight: 'bold',
			children: {
				__type: 'Translation',
				value: 'card.table.header',
				postfix: { accuracy: true, variant },
			},
		},
	},
	{
		as: 'table',
		props: {
			children: [
				{
					as: 'thead',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { accuracy: true, key: 'property', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { accuracy: true, key: 'value', variant },
														},
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
				{
					as: 'tbody',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { accuracy: true, key: 'state', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ accuracy.state }}`,
														title: ` accuracy.state `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { accuracy: true, key: 'multiplier', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ accuracy.multiplier }}`,
														title: ` accuracy.multiplier `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { accuracy: true, key: 'reached', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ accuracy.reached }}`,
														title: ` accuracy.reached `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { accuracy: true, key: 'diff-value', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ accuracy.diff.value }}`,
														title: ` accuracy.diff.value `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { accuracy: true, key: 'diff-percentage', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ accuracy.diff.percentage }}`,
														title: ` accuracy.diff.percentage `,
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
];

export const Credit = ({ variant }) => [
	{
		as: 'Text',
		props: {
			weight: 'bold',
			children: {
				__type: 'Translation',
				value: 'card.table.header',
				postfix: { credit: true, variant },
			},
		},
	},
	{
		as: 'table',
		props: {
			children: [
				{
					as: 'thead',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { accuracy: true, key: 'property', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { accuracy: true, key: 'value', variant },
														},
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
				{
					as: 'tbody',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { credit: true, key: 'approved', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ credit.approved }}`,
														title: ` credit.approved `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { credit: true, key: 'balance', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ credit.balance }}`,
														title: ` credit.balance `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { credit: true, key: 'utilized', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ credit.utilized }}`,
														title: ` credit.utilized `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { credit: true, key: 'remaining', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ credit.remaining }}`,
														title: ` credit.remaining `,
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
];

export const Diff = ({ variant }) => [
	{
		as: 'Text',
		props: {
			weight: 'bold',
			children: {
				__type: 'Translation',
				value: 'card.table.header',
				postfix: { diff: true, variant },
			},
		},
	},
	{
		as: 'table',
		props: {
			children: [
				{
					as: 'thead',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { diff: true, key: 'status', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { diff: true, key: 'count', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { diff: true, key: 'currency', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { diff: true, key: 'total', variant },
														},
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
				{
					as: 'tbody',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { diff: true, key: 'paid', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.PAID.count }}`,
														title: ` diff.PAID.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.PAID.currency }}`,
														title: ` diff.PAID.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.PAID.total }}`,
														title: ` diff.PAID.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { diff: true, key: 'unpaid', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.UNPAID.count }}`,
														title: ` diff.UNPAID.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.UNPAID.currency }}`,
														title: ` diff.UNPAID.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.UNPAID.total }}`,
														title: ` diff.UNPAID.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { diff: true, key: 'overdue', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.OVERDUE.count }}`,
														title: ` diff.OVERDUE.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.OVERDUE.currency }}`,
														title: ` diff.OVERDUE.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.OVERDUE.total }}`,
														title: ` diff.OVERDUE.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { diff: true, key: 'forecast', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.FORECAST.count }}`,
														title: ` diff.FORECAST.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.FORECAST.currency }}`,
														title: ` diff.FORECAST.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ diff.FORECAST.total }}`,
														title: ` diff.FORECAST.total `,
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
];

export const Source = ({ variant }) => [
	{
		as: 'Text',
		props: {
			weight: 'bold',
			children: {
				__type: 'Translation',
				value: 'card.table.header',
				postfix: { source: true, variant },
			},
		},
	},
	{
		as: 'table',
		props: {
			children: [
				{
					as: 'thead',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { source: true, key: 'status', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { source: true, key: 'count', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { source: true, key: 'currency', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { source: true, key: 'total', variant },
														},
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
				{
					as: 'tbody',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { source: true, key: 'paid', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.PAID.count }}`,
														title: ` source.PAID.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.PAID.currency }}`,
														title: ` source.PAID.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.PAID.total }}`,
														title: ` source.PAID.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { source: true, key: 'unpaid', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.UNPAID.count }}`,
														title: ` source.UNPAID.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.UNPAID.currency }}`,
														title: ` source.UNPAID.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.UNPAID.total }}`,
														title: ` source.UNPAID.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { source: true, key: 'overdue', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.OVERDUE.count }}`,
														title: ` source.OVERDUE.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.OVERDUE.currency }}`,
														title: ` source.OVERDUE.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.OVERDUE.total }}`,
														title: ` source.OVERDUE.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { source: true, key: 'forecast', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.FORECAST.count }}`,
														title: ` source.FORECAST.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.FORECAST.currency }}`,
														title: ` source.FORECAST.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ source.FORECAST.total }}`,
														title: ` source.FORECAST.total `,
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
	{
		as: 'Text',
		props: {
			children: {
				__type: 'Translation',
				value: 'card.table.footer',
				postfix: { source: true, variant },
			},
		},
	},
];

export const Target = ({ variant }) => [
	{
		as: 'Text',
		props: {
			weight: 'bold',
			children: {
				__type: 'Translation',
				value: 'card.table.header',
				postfix: { target: true, variant },
			},
		},
	},
	{
		as: 'table',
		props: {
			children: [
				{
					as: 'thead',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { target: true, key: 'status', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { target: true, key: 'count', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { target: true, key: 'currency', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.column',
															postfix: { target: true, key: 'total', variant },
														},
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
				{
					as: 'tbody',
					props: {
						children: [
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { target: true, key: 'paid', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.PAID.count }}`,
														title: ` target.PAID.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.PAID.currency }}`,
														title: ` target.PAID.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.PAID.total }}`,
														title: ` target.PAID.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { target: true, key: 'unpaid', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.UNPAID.count }}`,
														title: ` target.UNPAID.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.UNPAID.currency }}`,
														title: ` target.UNPAID.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.UNPAID.total }}`,
														title: ` target.UNPAID.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { target: true, key: 'overdue', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.OVERDUE.count }}`,
														title: ` target.OVERDUE.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.OVERDUE.currency }}`,
														title: ` target.OVERDUE.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.OVERDUE.total }}`,
														title: ` target.OVERDUE.total `,
													},
												},
											},
										},
									],
								},
							},
							{
								as: 'tr',
								props: {
									children: [
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: {
															__type: 'Translation',
															value: 'card.table.row',
															postfix: { target: true, key: 'forecast', variant },
														},
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.FORECAST.count }}`,
														title: ` target.FORECAST.count `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.FORECAST.currency }}`,
														title: ` target.FORECAST.currency `,
													},
												},
											},
										},
										{
											as: 'td',
											props: {
												children: {
													as: 'Text',
													props: {
														children: `{{ target.FORECAST.total }}`,
														title: ` target.FORECAST.total `,
													},
												},
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
	{
		as: 'Text',
		props: {
			children: {
				__type: 'Translation',
				value: 'card.table.footer',
				postfix: { target: true, variant },
			},
		},
	},
];
