import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import BaseQuestion from './Base';
import { useQuestion } from './hooks';

import './styles.scss';

const QuestionContenter = (props) => {
	const { className, type, onAction } = props;

	const { setValue, getValues } = useFormContext();
	const form = useWatch();

	const [isExtended, setExtended] = React.useState(false);

	const config = useQuestion(type);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'welcome:extension:open') {
				if (data?.type === 'inline') {
					setExtended(true);
				}
			}

			if (action === 'welcome:extension:close') {
				setExtended(false);
			}

			if (action === 'welcome:setValue') {
				const name = data?.name;
				const value = data?.value;

				if (getValues(name) === value) {
					setValue(name, undefined, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				} else {
					setValue(name, value, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				}
			}

			return onAction?.(action, data);
		},
		[getValues, onAction, setValue],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	const data = React.useMemo(() => ({ form: form }), [form]);

	if (!config) {
		return null;
	}

	const content = isExtended
		? []
				.concat(config?.content)
				.concat(config?.extension?.content)
				.filter(Boolean)
		: [].concat(config?.content).filter(Boolean);

	return (
		<BaseQuestion
			className={className}
			variant="contenter"
			type={type}
			title={config?.title}
			extension={config?.extension}
			onAction={handleAction}
			details={isExtended}
		>
			<Contenter content={content} actions={actions} data={data} />
		</BaseQuestion>
	);
};

QuestionContenter.displayName = 'QuestionContenter';

QuestionContenter.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onAction: PropTypes.func,
};

export default QuestionContenter;
