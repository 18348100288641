import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQueries, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import * as AppStore from '@asteria/datalayer/stores/app';

import Loading from '@asteria/layout/loading';
import { decode } from '@asteria/utils-funcs/jwt';

import { fetch as fetchCompany } from '../api/company';
import { fetch as fetchIntegrations } from '../api/integrations';
import { me as fetchMe } from '../api/user';

function useCombinedResponse(results) {
	const loading = results.some((object) => object.isFetching);
	const refetching = results.some((object) => object.isRefetching);

	return React.useMemo(
		() => ({
			loading: loading,
			refetching: refetching,
			done: !loading && !refetching,
		}),
		[loading, refetching],
	);
}

const DatalayerWrapper = React.memo((props) => {
	const {
		children,
		partnerId,
		fetchExtra,
		onDataLoaded,
		loader = true,
		loadingOnRefresh,

		onUserLoad,
		onCompanyLoad,
		onIntegrationsLoad,
		onExtraLoad,

		skipMeRequest,
		skipCompanyRequest,
		skipIntegrationsRequest,
		skipExtraRequest,
	} = props;

	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const responses = useQueries({
		queries: [
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				queryKey: ['widget', 'datalayer', partnerId, accessToken, 'me'],
				queryFn: async ({ signal }) => {
					const response = await fetchMe({
						partnerId: partnerId,
						accessToken: accessToken,
						dispatch: dispatch,
						signal: signal,
					});

					const companyId = decode(accessToken)?.companyId ?? null;
					dispatch(AppStore.setUserCompanyId(companyId));

					await onUserLoad?.(response);

					return response;
				},
				enabled: !!accessToken && !skipMeRequest,
			},
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				queryKey: [
					'widget',
					'datalayer',
					partnerId,
					accessToken,
					'company',
				],
				queryFn: async ({ signal }) => {
					const response = await fetchCompany({
						partnerId: partnerId,
						accessToken: accessToken,
						dispatch: dispatch,
						signal: signal,
					});

					await onCompanyLoad?.(response);

					return response;
				},
				enabled: !!accessToken && !skipCompanyRequest,
			},
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				queryKey: [
					'widget',
					'datalayer',
					partnerId,
					accessToken,
					'integrations',
				],
				queryFn: async ({ signal: signal }) => {
					const response = await fetchIntegrations({
						partnerId: partnerId,
						accessToken: accessToken,
						dispatch: dispatch,
						signal: signal,
					});

					await onIntegrationsLoad?.(response);

					return response;
				},
				enabled: !!accessToken && !skipIntegrationsRequest,
			},
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				queryKey: [
					'widget',
					'datalayer',
					partnerId,
					accessToken,
					'extra',
				],
				queryFn: async ({ signal }) => {
					const response = await fetchExtra?.({
						partnerId: partnerId,
						accessToken: accessToken,
						dispatch: dispatch,
						signal: signal,
					});

					await onExtraLoad?.(response);

					return response;
				},
				enabled: !!accessToken && !skipExtraRequest,
			},
		],
	});

	const { refetching, loading, done } = useCombinedResponse(responses);

	const data = React.useMemo(
		() => responses.map(({ data }) => data),
		[responses],
	);

	React.useEffect(() => {
		queryClient.invalidateQueries({
			predicate: (query) =>
				query.queryKey[0] === 'widget' &&
				query.queryKey[1] === 'datalayer' &&
				query.queryKey.slice(-1)[0] === 'extra',
		});
	}, [fetchExtra, queryClient]);

	React.useEffect(() => {
		if (done) {
			onDataLoaded?.(...data);
		}
	}, [data, done, onDataLoaded]);

	if (loading && (!refetching || loadingOnRefresh)) {
		if (!loader) {
			return null;
		}

		return <Loading />;
	}

	return children;
});

DatalayerWrapper.displayName = 'DatalayerWrapper';
DatalayerWrapper.propTypes = {
	children: PropTypes.node,
	loader: PropTypes.bool,
	partnerId: PropTypes.string,
	fetchExtra: PropTypes.func,
	onDataLoaded: PropTypes.func,
	loadingOnRefresh: PropTypes.bool,

	onUserLoad: PropTypes.func,
	onCompanyLoad: PropTypes.func,
	onIntegrationsLoad: PropTypes.func,
	onExtraLoad: PropTypes.func,

	skipMeRequest: PropTypes.bool,
	skipCompanyRequest: PropTypes.bool,
	skipIntegrationsRequest: PropTypes.bool,
	skipExtraRequest: PropTypes.bool,
};

export default DatalayerWrapper;
