import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { useFormValues } from '@asteria/component-form';

import * as utils from '../../../../utils';
import { getPath } from '../../../../utils/form/utils';
import Satisfaction from '../../../satisfaction';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Pick<Props, 'type' | 'category' | 'tag'>> } */
const RowSatisfaction = React.memo(function RowSatisfaction(props) {
	const { type, category, tag } = props;

	const { getValues, setValue, reset } = useFormContext();

	const path = getPath({ type, category, tag });

	const probability = useFormValues({
		name: path.concat('probability').join('.'),
	});

	const onProbabilityChange = React.useCallback(
		(value) => {
			const changes = utils.form.tag.setProbability(
				{ type, category, tag, value, recalculate: true },
				{ getValues, setValue, reset },
			);

			utils.form.changes.apply(changes, { getValues, setValue, reset });
		},
		[category, getValues, reset, setValue, tag, type],
	);

	return (
		<Satisfaction
			value={probability ?? 1}
			onChange={onProbabilityChange}
			size="sm"
		/>
	);
});

RowSatisfaction.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default RowSatisfaction;
