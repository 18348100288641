import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import useConfig from '@asteria/utils-hooks/useConfig';

const Step = React.memo((props) => {
	const { onAction, onSubmit, data, path } = props;

	const config = useConfig([path], { deep: true });

	const actions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<Contenter content={config?.content} actions={actions} data={data} />
	);
});

Step.displayName = 'Step';
Step.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	data: PropTypes.object,
	step: PropTypes.number,
	path: PropTypes.string,
};

export default Step;
