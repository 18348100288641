import { TranslationService } from '@asteria/language';

export const generateRows = (data) => {
	if (!data) {
		return '';
	}

	return data.reduce((acc, { label, values, rows, total }) => {
		const totalRow = total ? `;${total.join(';')}\n` : '';
		const valuesRow = values ? values.join(';') : '';

		const rowsData = rows?.reduce(
			(acc, { label, values }) =>
				[
					acc,
					`${TranslationService.get(label)};`,
					`${values.join(';')}\n`,
				].join(''),
			'',
		);

		return [
			acc,
			`${TranslationService.get(label) ?? ''};`,
			`${valuesRow}\n`,
			`${rowsData ?? ''}`,
			`${totalRow}\n`,
		].join('');
	}, '');
};

export const generatePeriod = (fiscalYears) => {
	if (!fiscalYears) {
		return '';
	}

	return fiscalYears
		.map((year, index) =>
			TranslationService.get(
				['statement.year', `statement.year.${index}`],
				`{{start | date:"MMM yy"}} - {{end | date:"MMM yy"}}`,
				year,
			),
		)
		.join(';');
};

export const generateCSV = (statement) => {
	const accountingBalance = statement.accountingBalanceSheet;
	const accountingMisc = statement.accountingMisc;
	const accountingResults = statement.accountingResults;
	const accountingWorkingCapital = statement.accountingWorkingCapital;

	const accountingBalanceTable = generateRows(accountingBalance.rows);
	const accountingMiscTable = generateRows(accountingMisc.rows);
	const accountingResultsTable = generateRows(accountingResults.rows);
	const accountingWorkingCapitalTable = generateRows(
		accountingWorkingCapital.rows,
	);

	return [
		';' + generatePeriod(accountingBalance.fiscalYears),
		accountingBalanceTable,
		';' + generatePeriod(accountingMisc.fiscalYears),
		accountingMiscTable,
		';' + generatePeriod(accountingWorkingCapital.fiscalYears),
		accountingWorkingCapitalTable,
		';' + generatePeriod(accountingResults.fiscalYears),
		accountingResultsTable,
	]
		.join('\n')
		.replace(/,/g, '');
};
