import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import './index.scss';

const DatePickerSlider = (props) => {
	const {
		label,
		onLabelClick,

		isPreviousDisabled,
		onPreviousClick,

		isNextDisabled,
		onNextClick,
	} = props;

	return (
		<div className="asteria-component__datepicker__content__slider">
			<Button
				icon="triangle-left"
				disabled={isPreviousDisabled}
				onClick={onPreviousClick}
			/>
			<span onClick={onLabelClick}>
				<Text>{label}</Text>
			</span>
			<Button
				icon="triangle-right"
				disabled={isNextDisabled}
				onClick={onNextClick}
			/>
		</div>
	);
};

DatePickerSlider.propTypes = {
	label: PropTypes.any.isRequired,
	onLabelClick: PropTypes.func,

	isPreviousDisabled: PropTypes.bool,
	isNextDisabled: PropTypes.bool,

	onPreviousClick: PropTypes.func,
	onNextClick: PropTypes.func,
};

DatePickerSlider.defaultProps = {};

export default DatePickerSlider;
