import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('./types').Props } Props
 */

function useHealthState(score) {
	let state = 'bad';

	if (score > 20) {
		state = 'acceptable';
	}

	if (score > 40) {
		state = 'good';
	}

	if (score > 60) {
		state = 'great';
	}

	if (score > 80) {
		state = 'perfect';
	}

	return state;
}

/** @type { React.FC<Props> } */
const Health = React.memo(function Health(props) {
	const { className, score, flat, extra, loading } = props;

	const state = useHealthState(score);

	const translationOptions = React.useMemo(
		() => ({
			postfix: { flat, health: state, ...extra?.postfix },
			data: { score, ...extra?.data },
		}),
		[extra?.data, extra?.postfix, flat, score, state],
	);

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="time.selector.health.tooltip"
					translationOptions={translationOptions}
					Component={Text}
				/>
			}
		>
			<div
				className={cn(
					'asteria-component__time-selector-health',
					{ [`asteria--variant-flat`]: flat },
					{ [`asteria--state-${state}`]: state },
					className,
				)}
			>
				<Translation
					translationKey="time.selector.health"
					translationOptions={translationOptions}
					Component={Text}
					loading={loading}
				/>
			</div>
		</TooltipWrapper>
	);
});

Health.displayName = 'Health';

Health.propTypes = {
	className: PropTypes.string,
	score: PropTypes.number,
	flat: PropTypes.bool,
	extra: PropTypes.shape({
		postfix: PropTypes.object,
		data: PropTypes.object,
	}),
	loading: PropTypes.bool,
};

export default Health;
export { useHealthState };
