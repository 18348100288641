import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { cn } from '@asteria/utils-funcs/classes';

import SectionActions from './actions';
import SectionGraph from './graph';
import SectionTeaser from './teaser';

import './styles.scss';

const HomePage = React.memo((props) => {
	const { className, onAction, onSubmit } = props;

	const saving = useSelector(ForecasterStore.selectors.flags.isSaving);

	const dispatch = useDispatch();

	React.useLayoutEffect(() => {
		dispatch(ForecasterStore.resetLastSaveAt());
	}, [dispatch, saving]);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page',
				'asteria--type-home',
				className,
			)}
		>
			<SectionGraph onAction={onAction} onSubmit={onSubmit} />
			<SectionActions onAction={onAction} onSubmit={onSubmit} />
			<SectionTeaser onAction={onAction} onSubmit={onSubmit} />
		</div>
	);
});

HomePage.displayName = 'HomePage';

HomePage.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default HomePage;
