import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import YAxisLabel from './label';

import './index.scss';

const YAxis = React.memo((props) => {
	const { labels = [], title, className, onHover, onClick } = props;

	return (
		<div
			className={cn('asteria-graph-y-axis', className, {
				'asteria-graph-y-axis--title': title,
			})}
		>
			{title ? (
				<YAxisLabel
					className="asteria-graph-y-axis-label--title"
					label={title}
					onHover={onHover}
					onClick={onClick}
				/>
			) : null}

			{labels.map((data, index) => (
				<YAxisLabel
					key={`y-axis-${index}`}
					{...data}
					onHover={onHover}
					onClick={onClick}
				/>
			))}
		</div>
	);
});

YAxis.displayName = 'YAxis';

YAxis.propTypes = {
	labels: PropTypes.array,
	className: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
	onHover: PropTypes.func,
};

export default YAxis;
