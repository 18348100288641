import React from 'react';

import { useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import {
	useModalActions,
	useModalProps,
} from '../../../components/modal-collection';

import RemoveButton from './remove';

const LoanSettings = React.memo(() => {
	const store = useStore();

	const { close, onAction, onSubmit } = useModalActions();
	const { simulationId, redirect, callback } = useModalProps();

	const [loading, setLoading] = React.useState(false);

	const simulation = useSelector((store) =>
		ScenarioStore.selectors.scenario(store, simulationId),
	);

	const defaultValues = React.useMemo(
		() => ({
			_id: simulation?._id ?? simulation?.id,
			type: simulation?.type,
			name: simulation?.name,
		}),
		[simulation?._id, simulation?.id, simulation?.name, simulation?.type],
	);

	const handleSubmit = React.useCallback(
		async (form) => {
			setLoading(true);

			try {
				await onSubmit?.('scenario:update', form);
			} catch (err) {
				//
			}

			setLoading(false);

			return close?.();
		},
		[close, onSubmit],
	);

	const onRemove = React.useCallback(async () => {
		setLoading(true);

		try {
			await onSubmit?.('scenario:delete', { _id: simulationId });
		} catch (err) {
			//
		}

		if (redirect) {
			callback?.('loan:dirty:reset');
			onAction?.('redirect', '/financial');
		}

		setLoading(false);

		return close?.();
	}, [redirect, close, onSubmit, simulationId, callback, onAction]);

	const validate = React.useMemo(
		() => ({
			exists: (value) => {
				const scenarios = ScenarioStore.selectors
					.scenarios(store.getState(), { type: 'LOAN' })
					.filter(
						(object) =>
							(object?._id ?? object?.id) !== simulationId,
					)
					.map(({ name }) => name);

				return !scenarios.includes(value);
			},
		}),
		[simulationId, store],
	);

	return (
		<Form defaultValues={defaultValues} onSubmit={handleSubmit}>
			<Wrapper>
				<Header onClose={close}>
					{TranslationService.get([
						'financial.modal.loan.settings.title',
					])}
				</Header>
				<Content>
					<FormWrapper>
						<Content>
							<Text>
								{TranslationService.get([
									'financial.modal.loan.settings.content',
								])}
							</Text>
							<Input
								name="name"
								label={TranslationService.get([
									'financial.modal.loan.settings.input.name.label',
								])}
								validate={validate}
								formNamePrefix="financial.loan.settings"
							/>
							<RemoveButton
								onSubmit={onRemove}
								loading={loading}
							/>
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
								'financial.modal.loan.settings.close',
							])}
							onClick={close}
							analyticsKey="financial.modal.loan.settings.close"
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.save',
								'action.save',
								'financial.modal.loan.settings.save',
							])}
							analyticsKey="financial.modal.loan.settings.save"
							loading={loading}
							disabled={loading}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
});

LoanSettings.displayName = 'LoanSettings';
LoanSettings.propTypes = {};

const LoanSettingsModal = React.memo((props) => {
	const { open, className } = props;

	const { close } = useModalActions();

	return (
		<Modal
			open={open}
			onClose={close}
			className={cn(
				'asteria-component__financial-modal',
				'asteria--variant-loan-settings',
				className,
			)}
			size="sm"
		>
			<LoanSettings {...props} />
		</Modal>
	);
});

LoanSettingsModal.displayName = 'LoanSettingsModal';

LoanSettingsModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
};

export default LoanSettingsModal;
