import React from 'react';

import PropTypes from 'prop-types';

import YAxis from '@asteria/component-graph/graphs/yaxis';
import Legend from '@asteria/component-graph/legends';

import { cn } from '@asteria/utils-funcs/classes';

import Lines from '../../lines';

import BarGraphColumn from './column';

import '../styles.scss';

import './styles.scss';

const BarGraph = React.memo((props) => {
	const {
		className,
		onAction,

		labels,
		title,

		type,
		category,
		tag,

		dates,
		types,

		min,
		max,

		showYAxis,
		showLines,
		showLegends,

		legends = [],

		size = (dates ?? []).length + 2,
		render,
		renderItem,
	} = props;

	const style = React.useMemo(
		() => ({ '--min': min, '--max': max }),
		[max, min],
	);

	const contentStyle = React.useMemo(() => ({ '--size': size }), [size]);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-graph',
				'asteria--variant-bars',
				{ 'asteria--state-has-y-axis': showYAxis },
				className,
			)}
			style={style}
		>
			{showYAxis ? <YAxis labels={labels} title={title} /> : null}

			<div
				className="asteria-component__forecaster-graph-content"
				style={contentStyle}
			>
				{showLines ? <Lines values={labels} /> : null}
				{showLegends && legends?.length ? (
					<Legend data={legends} clickable hoverable />
				) : null}
				{render
					? render({ onAction, type, category, tag, types })
					: dates.map((date) =>
							renderItem ? (
								renderItem({
									types: types,
									type: type,
									date: date,
									category: category,
									tag: tag,
									onAction: onAction,
								})
							) : (
								<BarGraphColumn
									key={[type, date, category, tag].join('-')}
									types={types}
									type={type}
									date={date}
									category={category}
									tag={tag}
									onAction={onAction}
								/>
							),
					  )}
			</div>
		</div>
	);
});

BarGraph.displayName = 'BarGraph';

BarGraph.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,

	labels: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string,

	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	dates: PropTypes.arrayOf(PropTypes.string),
	types: PropTypes.arrayOf(PropTypes.string),

	min: PropTypes.number,
	max: PropTypes.number,

	showYAxis: PropTypes.bool,
	showLines: PropTypes.bool,
	showLegends: PropTypes.bool,

	legends: PropTypes.arrayOf(PropTypes.object),

	size: PropTypes.number,

	render: PropTypes.func,
	renderItem: PropTypes.func,
};

export default BarGraph;
