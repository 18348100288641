import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import SearchChip from './chip';
import { useSearchExamples } from './utils/hooks';

import './styles.scss';

const SearchExamples = React.memo((props) => {
	const { className, onAction } = props;

	const examples = useSearchExamples();

	if (!examples.length) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__search-floating',
				'asteria--variant-examples',
				className,
			)}
		>
			<Text size="sm">
				{TranslationService.get('search.examples.description')}
			</Text>
			<div className="asteria-component__search-floating-content">
				{examples.map((object) => (
					<SearchChip
						key={[object?.type, object?.id].join('-')}
						{...object}
						onAction={onAction}
					/>
				))}
			</div>
		</div>
	);
});

SearchExamples.displayName = 'SearchExamples';
SearchExamples.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default SearchExamples;
