import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

/**
 * @typedef { import('./types').Props } Props
 */

/**
 * @param { Pick<Props, 'startDate' | 'endDate' | 'onSubmit'> } options
 */
export function useRequest({ onSubmit, startDate, endDate }) {
	const scenario = useSelector(
		ScenarioStore.selectors.sourceScenario,
		(a, b) => isEqual(a, b),
	);

	return useQuery({
		queryKey: ['card', 'forecast', { scenario }, { startDate, endDate }],
		queryFn: async () => {
			return await onSubmit?.('card:fetch', {
				type: 'forecast',
				source: {
					scenario: '$base',
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					scenario: scenario,
					startDate: startDate,
					endDate: endDate,
				},
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		placeholderData: { source: {}, target: {} },

		enabled: !!startDate && !!endDate,
	});
}

/**
 * @param {{ type: 'deposit' | 'withdraw' }} options
 */
export function useCategories({ type }) {
	const data = useFormValues({ name: type });

	return data?.tags ?? [];
}
