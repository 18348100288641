import React from 'react';

import { connect, useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Tooltip from '@asteria/component-core/tooltip';

import {
	FeatureFlag,
	Service as FeatureService,
	useFeature,
} from '@asteria/component-tools/featureflag';

import store from '@asteria/datalayer';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { findParentByClassname } from '@asteria/utils-funcs/node';

import ForecasterTeaser from '../ForecasterTeaser';
import TimeSelector from '../TimeSelector';
import { GraphActionsContext } from '../context';
import * as Selectors from '../selectors';
import XAxis from '../xaxis';
import YAxis from '../yaxis';

import GraphArea from './area';
import BarGraph from './bar/barGraph';
import LineGraph from './line/lineGraph';

import './graphs.scss';

// const processColor = (color = '', theme) => {
// 	if (color.startsWith('$')) {
// 		return preProcess(
// 			`var(--${color.replace('$', 'system-')}-color)`,
// 			theme,
// 		);
// 	}

// 	return color;
// };

const Updater = React.memo((props) => {
	const { size } = props;

	const { requestData } = React.useContext(GraphActionsContext);

	const currentDate = useSelector(Selectors.common.currentDate, (a, b) =>
		isEqual(a, b),
	);

	const filters = useSelector(Selectors.filters.filters, (a, b) =>
		isEqual(a, b),
	);

	const scenarioId = useSelector(Selectors.common.scenarioId, (a, b) =>
		isEqual(a, b),
	);

	const isScenarioRefreshed = useSelector(
		Selectors.common.isScenarioRefreshed,
		(a, b) => isEqual(a, b),
	);

	const range = useSelector(
		(store) =>
			Selectors.graph.range(store, {
				formatEach: (object) => object?.id,
			}),
		(a, b) => isEqual(a, b),
	);

	useQueries({
		queries: range.map((id) => ({
			queryKey: [
				'cashflow',
				'graph',
				id,
				{ currentDate },
				...filters.map((filter) => ({ filter: filter })),
				{ scenarioId },
				{ isScenarioRefreshed },
				{ size },
			],
			queryFn: async ({ signal }) => requestData?.(id, { signal }),
			refetchOnWindowFocus: false,
		})),
	});
});

Updater.displayName = 'Updater';
Updater.propTypes = { size: PropTypes.any };

const GraphAreaProvider = React.memo((props) => {
	const {
		onMouseDown,
		onMouseUp,
		onScroll,
		onUpdateSize,
		barLayout,
		parts,

		size,
		showXAxis,
		mode,

		isDragging,
	} = props;

	const hasDraggingFeature = useFeature('graph-behavior-drag');

	const range = useSelector(
		(store) =>
			Selectors.graph.range(store, { format: (range) => range.length }),
		(a, b) => isEqual(a, b),
	);
	const options = useSelector(Selectors.graph.options, (a, b) =>
		isEqual(a, b),
	);
	const graphGroupWidth = useSelector(
		Selectors.graph.graphGroupWidth,
		(a, b) => isEqual(a, b),
	);

	const hasSpreadDotsFeature = useFeature('graph-spread-info-dots');
	const hasDisableFutureFeature = useFeature('graph-disable-forecast-line');
	const shouldDisableFuture = hasDisableFutureFeature;

	const hasVisibleCategories = useSelector(
		Selectors.graph.hasVisibleCategories,
		(a, b) => isEqual(a, b),
	);

	const hasActive = useSelector(Selectors.graph.hasActive, (a, b) =>
		isEqual(a, b),
	);

	return (
		<>
			<GraphArea
				groups={range}
				className={cn(
					'asteria-graph-area',
					`asteria-graph-area-${barLayout}`,
					{
						'asteria-graph-area-has-filter': hasVisibleCategories,
						'asteria-graph-area-has-no-filter':
							!hasVisibleCategories,
						'asteria-graph-area-has-active': hasActive,
						'asteria-graph-area-dragging': isDragging,
						'asteria-graph-area-bars': parts.includes('bars'),
						'asteria-graph-area-lines': parts.includes('lines'),
						'asteria--feature-disable-forecast-line':
							shouldDisableFuture,
						'asteria--feature-graph-spread-info-dots':
							hasSpreadDotsFeature,
						'asteria--feature-dragging': hasDraggingFeature,
					},
				)}
				onMouseDown={onMouseDown}
				onTouchStart={onMouseDown}
				onMouseUp={onMouseUp}
				onTouchEnd={onMouseUp}
				onWheel={onScroll}
			>
				{parts.includes('bars') &&
				(mode === 'credit' || options?.barGraph) ? (
					<BarGraph
						layout={barLayout}
						size={size}
						parts={parts}
						mode={mode}
					/>
				) : null}
				{parts.includes('lines') &&
				(mode === 'credit' || options?.lineGraph) ? (
					<LineGraph
						layout={barLayout}
						parts={parts}
						size={size}
						width={
							Number.isNaN(graphGroupWidth)
								? 'auto'
								: graphGroupWidth
						}
						mode={mode}
					/>
				) : null}
				{showXAxis ? (
					<XAxis updateSize={onUpdateSize} mode={mode} />
				) : null}
			</GraphArea>
			<Updater size={size} />
		</>
	);
});

GraphAreaProvider.displayName = 'GraphAreaProvider';

GraphAreaProvider.propTypes = {
	onMouseDown: PropTypes.func,
	onMouseUp: PropTypes.func,
	onScroll: PropTypes.func,
	onUpdateSize: PropTypes.func,

	barLayout: PropTypes.string,
	parts: PropTypes.arrayOf(PropTypes.string),

	minValue: PropTypes.number,
	maxValue: PropTypes.number,

	size: PropTypes.any,
	mode: PropTypes.any,
	showXAxis: PropTypes.bool,
	isDragging: PropTypes.bool,
};

class Graph extends React.Component {
	constructor(props) {
		super();

		this.moved = 0;

		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleScroll = this.handleScroll.bind(this);

		this.updateSize = this.updateSize.bind(this);
		this.target = null;

		this.state = {
			topMargin: 0,
			bottomMargin: 0,
			tooltip: {},
			isDragging: false,
			actions: {
				clickAction: props.onClick,
				mouseEnterAction: this.handleMouseEnter,
				mouseLeaveAction: this.handleMouseLeave,
				onUpdateSettings: props.onUpdateSettings,
				setTarget: (el) => {
					this.target = el;
				},
				requestData: props.requestData,
			},
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (state.actions.clickAction !== props.onClick) {
			return {
				...state,
				actions: {
					...state.actions,
					clickAction: props.onClick,
				},
			};
		}

		return null;
	}

	handleScroll(e) {
		if (!FeatureService.isActive('graph-behavior-scroll')) {
			return;
		}

		const { next } = this.props;
		const {
			shiftKey = false,
			wheelDeltaX = 0,
			wheelDeltaY = 0,
			wheelDelta = 0,
		} = e.nativeEvent;

		if ((shiftKey && wheelDelta !== 0) || wheelDeltaX) {
			const value = wheelDeltaX * 10 || wheelDelta;

			if (wheelDeltaY !== 0) {
				return;
			}

			if (value >= 50) {
				Analytics.event('graph.scrolling.forward');

				e.preventDefault();
				this.setState((state) => {
					next && next(-1);
					return state;
				});
			} else if (value <= -50) {
				Analytics.event('graph.scrolling.backward');

				e.preventDefault();
				this.setState((state) => {
					next && next(1);
					return state;
				});
			}
		}
	}

	handleMouseDown(e) {
		const { button, type, changedTouches } = e;
		let { clientX, clientY } = e;

		this.setState({ tooltip: {} });

		if (button !== 0 && type !== 'touchstart') {
			return;
		}

		if (!FeatureService.isActive('graph-behavior-drag')) {
			return;
		}

		if (
			this.props.mode === 'credit' &&
			findParentByClassname(e.target, 'asteria-graph-line-button')
		) {
			return;
		}

		e.preventDefault();

		if (type === 'touchstart') {
			const [{ clientX: touchX, clientY: touchY }] = changedTouches;
			clientX = touchX;
			clientY = touchY;
		}

		if (type === 'touchstart') {
			window.addEventListener('touchmove', this.handleMouseMove);
			window.addEventListener('touchend', this.handleMouseUp);
		} else {
			window.addEventListener('mousemove', this.handleMouseMove);
			window.addEventListener('mouseup', this.handleMouseUp);
		}

		this.moved = 0;
		this.isDragging = true;
		this.setState({ isDragging: true });
		this.origin = { x: clientX, y: clientY };
		this.last = { x: clientX, y: clientY };

		Analytics.event('graph.dragging.start');
	}

	handleMouseUp() {
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);
		window.removeEventListener('touchmove', this.handleMouseMove);
		window.removeEventListener('touchend', this.handleMouseUp);
		this.isDragging = false;
		if (this.target) {
			this.target = null;
			this.targetSize = null;
		}
		this.setState({ isDragging: false });

		Analytics.event('graph.dragging.stop');
	}

	handleMouseMove(e) {
		if (
			this.props.mode === 'credit' &&
			findParentByClassname(e.target, 'asteria-graph-line-button')
		) {
			return;
		}

		const { changedTouches, type } = e;
		let { clientX, clientY } = e;

		const {
			origin: { x, y },
		} = this;

		const {
			last: { x: lastX, y: lastY },
		} = this;

		if (type === 'touchmove') {
			const [{ clientX: touchX, clientY: touchY }] = changedTouches;
			clientX = touchX;
			clientY = touchY;

			const diffX = clientX - lastX;
			const diffY = clientY - lastY;

			this.last.x = clientX;
			this.last.y = clientY;

			if (diffX === 0 || diffY !== 0) {
				return;
			}
		}

		e.preventDefault();

		const { next } = this.props;

		const translation = {
			x: clientX - x,
			y: clientY - y,
			deltaX: clientX - lastX,
			deltaY: clientY - lastY,
		};

		if (this.target) {
			this.target(translation);
		} else {
			if (this.moved - Math.floor(translation.x / 100) === 0) {
				return;
			}

			const diff = Math.floor(translation.x / 100) - this.moved;
			this.moved = Math.floor(translation.x / 100);
			this.setState((state) => {
				next && next(-diff);
				return state;
			});
		}
	}

	handleMouseEnter(data) {
		const { onMouseEnter } = this.props;

		if (this.isDragging) {
			return;
		}

		if (data.target) {
			this.setState({ tooltip: data });
		}

		onMouseEnter?.(data);
	}

	handleMouseLeave(data) {
		const { onMouseLeave } = this.props;

		if (this.isDragging) {
			return;
		}

		this.setState({ tooltip: {} });

		onMouseLeave?.(data);
	}

	updateSize(value, size) {
		const { parts = ['bars', 'lines'] } = this.props;

		const steps = Selectors.graph.steps(store.getState());
		const barsSteps = Selectors.graph.barsSteps(store.getState());
		const linesSteps = Selectors.graph.linesSteps(store.getState());

		let xAxis = steps;
		if (parts.length === 1) {
			xAxis = parts.includes('bars') ? barsSteps : linesSteps;
		}

		const { topMargin, bottomMargin, isDragging } = this.state;
		if (isDragging) {
			return;
		}
		if (value === xAxis[0].value && topMargin !== size) {
			this.setState((state) => ({
				bottomMargin: state.bottomMargin,
				topMargin: size,
			}));
		} else if (
			value === xAxis[xAxis.length - 1].value &&
			bottomMargin !== size
		) {
			this.setState((state) => ({
				topMargin: state.topMargin,
				bottomMargin: size,
			}));
		}
	}

	render() {
		const {
			className,
			parts = ['bars', 'lines'],
			showXAxis = true,
			options: { showYAxis = true } = {},
			updateSize = () => {},
			getTooltip = () => {},

			size,
			mode,
			barLayout: propBarLayout = 'grouped',

			id = 'base-graph',
			visibleCategories = [],
			hasTagFilters,
			hasActive,
			isActiveGroup,
		} = this.props;
		const { tooltip, actions } = this.state;

		const tooltipContent = tooltip?.target
			? getTooltip(tooltip)?.content
			: null;

		let barLayout = propBarLayout;

		if (mode === 'credit') {
			barLayout = 'grouped';
		}

		return (
			<GraphActionsContext.Provider value={actions}>
				<div
					className={cn(
						className,
						`asteria-graph`,
						`asteria-graph-${id}`,
						`asteria-graph-${barLayout}`,
						visibleCategories.map(
							(category) =>
								`asteria-graph-visible-category-${
									category.name.startsWith('$')
										? category.name.replace('$', '')
										: category._id
								}`,
						),
						{
							'asteria-graph-has-filter': hasTagFilters,
							'asteria-graph-has-no-filter': !hasTagFilters,
							'asteria-graph-has-active': hasActive,
							'asteria-graph-is-active-group': isActiveGroup,
							'asteria-graph-dragging': this.isDragging,
							'asteria-graph-bars': parts.includes('bars'),
							'asteria-graph-lines': parts.includes('lines'),
						},
					)}
				>
					{parts.includes('bars') ? (
						<ForecasterTeaser onAction={this?.props?.onAction} />
					) : null}
					<div className="asteria-graph-inner">
						{mode !== 'credit' ? (
							<FeatureFlag feature="cashflow-graph-timeselector">
								<TimeSelector />
							</FeatureFlag>
						) : null}

						{showYAxis ? (
							<YAxis parts={parts} updateSize={this.updateSize} />
						) : null}

						<GraphAreaProvider
							onMouseDown={this.handleMouseDown}
							onMouseUp={this.handleMouseUp}
							onScroll={this.handleScroll}
							onUpdateSize={updateSize}
							barLayout={barLayout}
							parts={parts}
							size={size}
							showXAxis={showXAxis}
							mode={mode}
							isDragging={this.isDragging}
						/>

						{tooltip.target &&
						!this.isDragging &&
						tooltipContent ? (
							<Tooltip
								open
								target={tooltip.target}
								className="asteria-graph-tooltip-wrapper"
								placement={
									barLayout === 'stacked' ? 'top' : 'top'
								}
								custom
							>
								{tooltipContent}
							</Tooltip>
						) : null}
					</div>
				</div>
			</GraphActionsContext.Provider>
		);
	}
}

Graph.displayName = 'Graph';

export default connect(
	(state) => {
		const activeBars = Selectors.graph.activeBars(state);
		const activeGroups = Selectors.graph.activeGroups(state);
		const id = Selectors.graph.id(state);
		const visibleCategories = Selectors.graph.visibleCategories(state);

		return {
			id: id,
			visibleCategories: visibleCategories,
			hasTagFilters: Selectors.filters.hasTagFilters(state),
			hasActive: activeGroups.length > 0 || activeBars.length > 0,
			isActiveGroup: activeGroups.length > 0 && activeBars.length === 0,
		};
	},
	null,
	null,
	{
		areStatesEqual: (next, prev) => isEqual(prev, next),
		areOwnPropsEqual: (next, prev) => isEqual(prev, next),
		areStatePropsEqual: (next, prev) => isEqual(prev, next),
		areMergedPropsEqual: (next, prev) => isEqual(prev, next),
	},
)(Graph);
