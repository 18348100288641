import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import LoenXAxis from '../../../components/loan/x-axis';

const SectionXAxis = React.memo(() => {
	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-axis-x',
			)}
		>
			<LoenXAxis />
		</div>
	);
});

SectionXAxis.displayName = 'SectionXAxis';
SectionXAxis.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionXAxis;
