import React from 'react';

import PropTypes from 'prop-types';

const Alert = (props) => {
	const { children } = props;

	return (
		<div className="asteria-component__alert asteria-component__alert--icon-warning asteria-component__alert--level-error asteria-component__alert--no-title">
			<div className="asteria-component__wrapper asteria-component__wrapper--variant-vertical asteria-component__wrapper--no-header asteria-component__wrapper--no-footer asteria-component__alert__wrapper">
				<div className="asteria-component__wrapper-content asteria-component__alert__wrapper-content">
					<span className="asteria-component__icon asteria--size-sm asteria-utils-position-first">
						<span className="asteria-component__icon-content asteria--icon-warning"></span>
					</span>

					<p className="asteria-component__typography asteria-component__text asteria-component__text--size-sm">
						{children}
					</p>
				</div>
			</div>
		</div>
	);
};

Alert.displayName = 'Alert';

Alert.propTypes = { children: PropTypes.node };

export default Alert;
