import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import SearchSelector, { SearchSelectorItem } from './base';

import './styles.scss';

const StatusSelectorItem = React.memo((props) => {
	const { onAction, object } = props;

	const ID = object?._id ?? object?.id;

	const onClick = React.useCallback(() => {
		Analytics.event('search.selector.toggle', { type: 'status', id: ID });

		return onAction?.('toggleStatus', {
			type: 'status',
			id: ID,
			item: object,
		});
	}, [ID, object, onAction]);

	const selected = useSelector(
		(store) => AppStore.selectors.filters(store, { id: ID }).length > 0,
	);

	return (
		<SearchSelectorItem active={selected} onClick={onClick}>
			<Chip label={object?.name} variant="simple" />
		</SearchSelectorItem>
	);
});

StatusSelectorItem.displayName = 'StatusSelectorItem';
StatusSelectorItem.propTypes = {
	onAction: PropTypes.func,
	object: PropTypes.string,
};

const StatusSelector = React.memo((props) => {
	const { className, onAction } = props;

	const hasSignedFeature = useFeature('cashflow-filter-signed');

	const count = useSelector(
		(store) => AppStore.selectors.filters(store, { type: 'status' }).length,
	);

	const objects = useSelector(AppStore.selectors.statuses)
		.filter((item) => item !== 'SIGNED' || hasSignedFeature)
		.map((status) => ({
			id: status,
			code: status,
			name: TranslationService.get([
				`status.${status}`,
				`selector.status.${status}`,
			]),
		}))
		.sort(({ name: a }, { name: b }) => a?.localeCompare?.(b));

	if (objects.length < 2) {
		return null;
	}

	return (
		<SearchSelector
			className={cn('asteria--variant-status', className)}
			label={TranslationService.get(`selector.status`)}
			onAction={onAction}
			type="status"
			count={count}
		>
			{objects.map((object) => (
				<StatusSelectorItem
					key={object?._id ?? object?.id}
					object={object}
					onAction={onAction}
				/>
			))}
		</SearchSelector>
	);
});

StatusSelector.displayName = 'StatusSelector';
StatusSelector.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default StatusSelector;
