import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Line from './line';

import './styles.scss';

const Lines = React.memo(
	React.forwardRef(({ values }, ref) => {
		if (!values?.length) {
			return null;
		}

		return (
			<div className={cn('asteria-component__graph-lines')} ref={ref}>
				{values.map(({ value }) => (
					<Line
						key={value}
						positive={value > 0}
						zero={value === 0}
						negative={value < 0}
					/>
				))}
			</div>
		);
	}),
);

Lines.propTypes = {
	bottom: PropTypes.bool,
	count: PropTypes.number,

	values: PropTypes.any,
};

Lines.displayName = 'Lines';

export default Lines;
export { Line };
