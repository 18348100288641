import React from 'react';

import PropTypes from 'prop-types';

import { DropdownItem } from '@asteria/component-core/dropdown';

import { GraphOptions, TimeSelector } from '@asteria/component-graph';
import GraphNavigation from '@asteria/component-graph/GraphNavigation';
import {
	FeatureFlag,
	useBulkFeatures,
} from '@asteria/component-tools/featureflag';

import './navigation.scss';

const NavigationItem = React.memo((props) => {
	const { onNavigate, route, onClick, ...itemProps } = props;

	const handleClick = React.useCallback(
		(event) => {
			if (onClick) {
				return onClick?.(event);
			}

			return onNavigate?.(route);
		},
		[onClick, onNavigate, route],
	);

	return <DropdownItem onClick={handleClick} {...itemProps} />;
});

NavigationItem.displayName = 'NavigationItem';

const Navigation = React.memo((props) => {
	const { onAction } = props;

	const hasNavigationFeature = useBulkFeatures([
		'graph-navigation-overview',
		'graph-navigation-overview-cashflow',
	]);

	return (
		<div className="asteria-component__layout-navigation">
			<FeatureFlag feature="cashflow-graph-timeselector" invert>
				<TimeSelector />
			</FeatureFlag>

			<FeatureFlag feature="cashflow-graph-options">
				<GraphOptions size="sm" onAction={onAction} />
			</FeatureFlag>

			{hasNavigationFeature ? <GraphNavigation /> : null}
		</div>
	);
});

Navigation.displayName = 'Navigation';

Navigation.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,

	forceCalendarOpen: PropTypes.bool,
	forceMenuOpen: PropTypes.bool,
};

export default Navigation;
