import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { stateClasses } from '@asteria/component-core/utils';
import Wrapper, { Header } from '@asteria/component-core/wrapper';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';
import useFilterClassNames from '@asteria/utils-hooks/useFilterClassname';

import { Notification } from './PropTypes';
import TransactionSidebar from './components/Details/Sidebar';
import TransactionHeaderContent from './components/Header';
import TransactionList from './components/List';
import { Context } from './context';

import './styles.scss';

const selectors = {
	filters: createSelector(
		(store) => store?.app?.filters,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const TransactionWrapperListener = (props) => {
	const { state, onAction } = props;

	const filters = useSelector(selectors.filters);

	React.useEffect(() => {
		if (filters?.length === 0) {
			if (!state) {
				onAction?.('transactions:hide');
			}
		} else {
			onAction?.(
				'transactions:show',
				TransactionStore.constants.STATES.All,
			);
		}
	}, [filters?.length]);

	return null;
};

TransactionWrapperListener.displayName = 'TransactionWrapperListener';

TransactionWrapperListener.propTypes = {
	state: PropTypes.string,
	onAction: PropTypes.func,
};

const TransactionWrapperListenerMemo = React.memo(TransactionWrapperListener);

const TransactionShadow = () => {
	const colors = useFilterClassNames({
		prefix: 'asteria-color',
		separator: '__',
		useClients: false,
		useCurrencies: false,
	});

	if (!colors.length) {
		return null;
	}

	return (
		<div
			className="asteria-component__transactions__shadow"
			style={{ '--size': colors.length }}
		>
			{colors.map((color) => (
				<div
					key={color}
					className={cn(
						'asteria-component__transactions__shadow__item',
						color,
					)}
				/>
			))}
		</div>
	);
};

TransactionShadow.displayName = 'TransactionShadow';

TransactionShadow.propTypes = {};

const TransactionShadowMemo = React.memo(TransactionShadow);

const TransactionWrapper = React.memo(
	React.forwardRef((props, ref) => {
		const {
			className,
			onAction,
			count,
			notifications,
			onClick,
			onScroll,
			state,
		} = props;

		const dispatch = useDispatch();

		const hasToggleFeature = useFeature('cashflow-transactions-toggle');

		const onSidebarClose = React.useCallback(
			() => dispatch(TransactionStore.setActive(null)),
			[dispatch],
		);

		const headerPostfix = React.useMemo(
			() => ({
				tooltip: {
					variant: 'alt',
					tooltip: state
						? TranslationService.get([
								'transactions.header.postfix.tooltip',
								'transactions.header.postfix.tooltip.hide',
						  ])
						: TranslationService.get([
								'transactions.header.postfix.tooltip',
								'transactions.header.postfix.tooltip.show',
						  ]),
				},
			}),
			[state],
		);

		const handleOpen = React.useCallback(
			(e) => {
				if (!hasToggleFeature) {
					e?.preventDefault?.();
					return;
				}

				const value = count
					? TransactionStore.constants.STATES.ModifiedOnly
					: TransactionStore.constants.STATES.All;

				Analytics.event('cashflow.transactions.show', {
					value: value,
				});
				onAction?.('transactions:show', value);
				onClick?.(e);
			},
			[count, hasToggleFeature, onAction, onClick],
		);

		const handleClose = React.useCallback(
			(e) => {
				if (!hasToggleFeature) {
					e?.preventDefault?.();
					return;
				}

				Analytics.event('cashflow.transactions.close');
				onAction?.('transactions:hide');
				onClick?.(e);
			},
			[hasToggleFeature, onAction, onClick],
		);

		return (
			<>
				<TransactionSidebar
					onAction={onAction}
					onClose={onSidebarClose}
					open
				/>
				<Wrapper
					className={cn(
						'asteria-component__transactions',
						className,
						stateClasses({ active: !!state }),
					)}
					open={state}
					collapse={hasToggleFeature}
					onClose={handleClose}
					onOpen={handleOpen}
				>
					<Header
						className={cn(
							'asteria-component__transactions__header',
							{
								'asteria--state-count':
									!notifications?.length && count,
							},
							className,
							stateClasses({ active: !!state }),
						)}
						postfix={headerPostfix}
					>
						<TransactionHeaderContent
							count={count}
							onAction={onAction}
							state={state}
							notifications={notifications}
						/>
					</Header>
					<TransactionList
						onScroll={onScroll}
						onAction={onAction}
						ref={ref}
					/>
				</Wrapper>
			</>
		);
	}),
);

TransactionWrapper.displayName = 'TransactionWrapper';

TransactionWrapper.propTypes = {
	count: PropTypes.number,
	notifications: PropTypes.arrayOf(Notification),
	className: PropTypes.string,

	onAction: PropTypes.func,
	onClick: PropTypes.func,
	onScroll: PropTypes.func,

	state: PropTypes.string,
};

const TransactionWrapperProvider = React.forwardRef((props, ref) => {
	const { onAction } = props;

	const ctx = React.useContext(Context);

	const { state } = useDeepMemo(() => ({ state: ctx?.state }), [ctx?.state]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'transactions:feedback:submit') {
				return onAction?.(...Object.values(data ?? {}));
			}

			if (action === 'transactions:feedback:support') {
				return onAction?.('go', '/support');
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	return (
		<TransactionWrapper
			{...props}
			ref={ref}
			state={state}
			onAction={handleAction}
		/>
	);
});

TransactionWrapperProvider.displayName = 'TransactionWrapperProvider';

TransactionWrapperProvider.propTypes = {
	count: PropTypes.number,
	notifications: PropTypes.arrayOf(Notification),
	className: PropTypes.string,

	onAction: PropTypes.func,
	onClick: PropTypes.func,
	onScroll: PropTypes.func,
};

export default TransactionWrapperProvider;

export * as Hooks from './hooks';

export * as Context from './context';

export {
	TransactionWrapperListenerMemo as TransactionWrapperListener,
	TransactionShadowMemo as TransactionShadow,
};
