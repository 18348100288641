import { addMonths, eachMonthOfInterval, format } from 'date-fns';
import { get } from 'lodash-es';

import store from '@asteria/datalayer';

import { selector } from '@asteria/utils-hooks/useConfig';

import { createChange, getPath } from './getFormPaths';

export function getState(value, category, tag) {
	if (!category || category === '$type') {
		return value?.FORECAST?.$state;
	}

	return value?.FORECAST?.categories?.[category]?.tags?.[tag]?.$state;
}

function validateState({ form, type, category, tag }) {
	const forecasterLength = selector(store.getState(), {
		key: 'forecaster.target.length',
		deep: true,
		defaultValue: 6,
	});

	const dates = eachMonthOfInterval({
		start: new Date(),
		end: addMonths(new Date(), forecasterLength - 1),
	}).map((date) => format(date, 'yyyy-MM-01'));

	const pendingDate = dates.find(
		(date) =>
			get(
				form,
				getPath({
					type: type,
					date: date,
					category: category,
					tag: tag,
					field: '$state',
				}),
			) === 'pending',
	);

	const hasFocus = dates.some(
		(date) =>
			get(
				form,
				getPath({
					type: type,
					date: date,
					category: category,
					tag: tag,
					field: '$state',
				}),
			) === 'focus',
	);

	if (pendingDate && !hasFocus) {
		const path = getPath({
			type: type,
			date: pendingDate,
			category: category,
			tag: tag,
			field: `$state`,
		});

		return [
			createChange(
				{
					op: 'set',
					name: path,
					value: 'focus',
					event: 'validate:state',
				},
				form,
			),
		];
	}

	return [];
}

export default validateState;
