import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import { parseDate } from '@asteria/utils-funcs/normalize';

import { useInitiatingIntegration, useRequiredIntegrations } from '../../hooks';
import BasicCard from '../basic';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'up' | 'down' } direction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useRequest({ onSubmit, startDate, endDate }) {
	return useQuery({
		queryKey: ['card', 'account', { startDate, endDate }],
		queryFn: async () => {
			return await onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,
	});
}

/** @type { React.FC<Props> } */
const AccountCard = React.memo(function AccountCard(props) {
	const { startDate, endDate, onSubmit } = props;

	const query = useRequest({
		onSubmit,
		startDate,
		endDate,
	});

	const { required, optional } = useRequiredIntegrations({
		required: 'bank',
		optional: 'erp',
	});

	const initiating = useInitiatingIntegration({ type: 'bank' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	return (
		<BasicCard
			{...props}
			type="account"
			query={query}
			integrations={integrations}
			connect={{ type: 'bank', onboarding: 'erp' }}
		/>
	);
});

AccountCard.displayName = 'AccountCard';

AccountCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default AccountCard;
