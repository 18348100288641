import React from 'react';

import PropTypes from 'prop-types';

import './styles.scss';

const Logo = React.memo((props) => {
	const { src, onAction } = props;

	const goOverview = React.useCallback(() => {
		onAction?.('go', '/');
	}, [onAction]);

	return (
		<div
			style={{ backgroundImage: `url(${src})` }}
			onClick={goOverview}
			className="asteria-component__navigation__logo"
		/>
	);
});

Logo.displayName = 'NavigationLogo';

Logo.propTypes = {
	src: PropTypes.string,
	onAction: PropTypes.func,
};

export default Logo;
