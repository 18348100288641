import React from 'react';

import PropTypes from 'prop-types';

import { useFeature } from '.';

const FeaturePropTypes = {
	feature: PropTypes.string,

	minVersion: PropTypes.number,
	maxVersion: PropTypes.number,
	invert: PropTypes.bool,
	children: PropTypes.node,
};

/**
 * @type { React.FC<typeof FeaturePropTypes> }
 */
const FeatureFlag = React.memo((props) => {
	const {
		feature,
		minVersion = false,
		maxVersion = false,
		invert = false,
		children,
	} = props;

	let hasFeature = useFeature(feature, minVersion, maxVersion);

	if (invert) {
		hasFeature = !hasFeature;
	}

	if (!children || !hasFeature) {
		return null;
	}

	return children;
});

FeatureFlag.displayName = 'FeatureFlag';

FeatureFlag.propTypes = FeaturePropTypes;

const FeatureWrapper = React.memo((props) => {
	const { feature, invert, children } = props;

	if (!feature) {
		return children;
	}

	return (
		<FeatureFlag feature={feature} invert={invert}>
			{children}
		</FeatureFlag>
	);
});

FeatureWrapper.displayName = 'FeatureWrapper';

FeatureWrapper.propTypes = {
	feature: PropTypes.string,
	invert: PropTypes.bool,
	children: PropTypes.node,
};

export default FeatureFlag;
export { FeatureWrapper };
