import { set, unset } from 'lodash-es';

import { validateFormContext } from './utils';

export function create({ type, name, value }, ctx) {
	const context = validateFormContext(ctx);

	if (type === 'set') {
		set(context.form, name, value);
	}

	if (type === 'unset') {
		unset(context.form, name);
	}

	return { type, data: { name, value } };
}

export function apply(changes, ctx) {
	const { setValue, getValues } = ctx;

	for (const change of changes) {
		if (change?.type === 'set') {
			const name = change?.data?.name;
			const value = change?.data?.value;

			setValue(name, value, {
				shouldDirty: false,
				shouldTouch: false,
				shouldValidate: false,
			});
		}

		if (change?.type === 'unset') {
			const name = change?.data?.name;

			setValue(name, undefined);
		}

		if (change?.type === 'ADD_TAG') {
			const name = change?.data?.name;
			const value = change?.data?.value;

			const current = getValues(name) ?? [];
			const next = [].concat(current).concat(value);

			setValue(name, next, {
				shouldDirty: false,
				shouldTouch: false,
				shouldValidate: false,
			});
		}

		if (change?.type === 'REMOVE_CATEGORY') {
			const name = change?.data?.name;
			const category = change?.data?.value?.category;

			const current = getValues(name) ?? [];
			const next = current.filter(
				(object) => !(object?.category === category),
			);

			setValue(name, next, {
				shouldDirty: false,
				shouldTouch: false,
				shouldValidate: false,
			});
		}

		if (change?.type === 'REMOVE_TAG') {
			const name = change?.data?.name;
			const category = change?.data?.value?.category;
			const tag = change?.data?.value?.tag;

			const current = getValues(name) ?? [];
			const next = current.filter(
				(object) =>
					!(object?.category === category && object?.tag === tag),
			);

			setValue(name, next, {
				shouldDirty: false,
				shouldTouch: false,
				shouldValidate: false,
			});
		}
	}
}
