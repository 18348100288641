import React from 'react';

import IntegrationAction from './Integration';
import WizardAction from './Wizard';

import './styles.scss';

const Action = (props) => {
	const { variant } = props;

	if (variant === 'integration') {
		return <IntegrationAction {...props} />;
	}

	if (variant === 'wizard') {
		return <WizardAction {...props} />;
	}

	return null;
};

Action.displayName = 'Action';

Action.propTypes = {
	...IntegrationAction.propTypes,
	...WizardAction.propTypes,
};

export default Action;
