import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';

const LevelNavigationItem = React.memo((props) => {
	const {
		id,
		label,
		path,
		active,
		visible,
		useVisible = () => true,
		props: $props,
		onAction,
		object,
		toggleable,
	} = props;

	const key = [...path, id].join('-');

	const isActive = (active ?? []).join('-') === key;

	const defaultVisible = useVisible(object);
	const isVisible =
		isActive ||
		(visible?.[key] !== undefined ? visible?.[key] : defaultVisible);

	const ref = React.useRef(null);

	const [removing, setRemoving] = React.useState(false);

	React.useLayoutEffect(() => {
		if (removing) {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		}
	}, [removing]);

	React.useLayoutEffect(() => {
		if (isActive) {
			ref?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		}
	}, [isActive]);

	const onAsk = React.useCallback(() => {
		setRemoving(true);
	}, []);

	const onRollback = React.useCallback(() => {
		setRemoving(false);
	}, []);

	const handleClick = React.useCallback(() => {
		let next = [...path, id];

		if (isActive && toggleable) {
			next.pop();
		}

		return onAction('navigation:navigate', next, {
			toggle: isActive && toggleable,
		});
	}, [id, isActive, onAction, path, toggleable]);

	const onRemove = React.useCallback(() => {
		const next = [...path, id];

		return Promise.allSettled([
			onAction('navigation:deselect', next, { toggle: true }),
			onAction('navigation:remove', next),
		]);
	}, [id, onAction, path]);

	const actions = React.useMemo(
		() => [
			{ icon: 'add', onClick: onRollback },
			{ icon: 'remove', onClick: onRemove },
		],
		[onRemove, onRollback],
	);

	if (!isVisible) {
		return null;
	}

	return (
		<Chip
			ref={ref}
			className="asteria-component__level-navigation-item"
			label={TranslationService.get([
				label,
				['level.navigation', ...path, id, 'label'].join('.'),
			])}
			active={isActive}
			{...$props}
			onClick={handleClick}
			onDismiss={onAsk}
			actions={removing ? actions : null}
			dismiss={$props?.dismiss && !removing}
		>
			{$props?.children}
		</Chip>
	);
});

LevelNavigationItem.displayName = 'LevelNavigationItem';

LevelNavigationItem.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	path: PropTypes.arrayOf(PropTypes.string),
	active: PropTypes.arrayOf(PropTypes.string),
	visible: PropTypes.object,
	useVisible: PropTypes.func,
	props: PropTypes.object,
	object: PropTypes.object,
	onAction: PropTypes.func,
	toggleable: PropTypes.bool,
};

export default LevelNavigationItem;
