import { useContext } from 'react';

import { useFormContext } from 'react-hook-form';

import { get } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

import Context from '../../../logic/context';
import { getPath } from '../../../utils/getFormPaths';

export function useOverallValue({
	type,
	/* dates, */
	category,
	tag,
	includeFuture,
	includeHistory,
}) {
	const { history } = useContext(Context);
	const { getValues } = useFormContext();

	const dates = Array.from(
		new Set(
			[]
				.concat(Object.keys(getValues()?.[type]?.data ?? {}))
				.concat(Object.keys(history?.[type]?.data ?? {})),
		),
	);

	const future = useFormValues({
		name: dates.map((date) =>
			getPath({
				type: type,
				date: date,
				status: 'FORECAST',
				category: category,
				tag: tag,
				field: 'value',
			}),
		),
	});

	const historical = dates
		.map((date) =>
			getPath({
				type: type,
				date: date,
				status: 'PAID',
				category: category,
				tag: tag,
				field: 'value',
			}),
		)
		.map((path) => get(history, path));

	return []
		.concat(includeFuture ? future : [])
		.concat(includeHistory ? historical : [])
		.reduce((acc, value) => acc + (value || 0), 0);
}
