import React from 'react';

import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import WelcomeContext from '../../../context';

export const useQuestion = (type) => {
	const { questions: ctxQuestions } = React.useContext(WelcomeContext);
	const config = ctxQuestions?.[type] ?? null;

	return useDeepMemo(() => config, [config]);
};
