import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import WelcomeContext from '../../../context';

import './styles.scss';

const DoneStep = (props) => {
	const { onAction, name } = props;

	const { wizard: ctxWizard } = React.useContext(WelcomeContext);
	const config = ctxWizard?.[name]?.done ?? null;

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	if (!config) {
		return null;
	}

	return <Contenter content={config} actions={actions} />;
};

DoneStep.displayName = 'DoneStep';

DoneStep.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	onAction: PropTypes.func,
};

export default DoneStep;
