import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

import useContextSelector from '@asteria/utils-hooks/useContextSelector';

import { GraphPropsContext } from './context';
import * as Selectors from './selectors';

const ctxSelectors = {
	mode: createSelector(
		(store) => store?.mode,
		(value) => value ?? null,
	),
	xaxis: {
		clickable: createSelector(
			(store) => store?.xaxis?.clickable,
			(value) => value ?? false,
		),
		hoverable: createSelector(
			(store) => store?.xaxis?.hoverable,
			(value) => value ?? false,
		),
	},
	legend: {
		clickable: createSelector(
			(store) => store?.legend?.clickable,
			(value) => value ?? false,
		),
		hoverable: createSelector(
			(store) => store?.legend?.hoverable,
			(value) => value ?? false,
		),
	},
};

/* Common */

export function useGraphMode() {
	const reduxMode = useSelector(Selectors.common.mode, (a, b) =>
		isEqual(a, b),
	);

	const ctxMode = useContextSelector(GraphPropsContext, ctxSelectors.mode);

	const mode = ctxMode ?? reduxMode;

	return useMemo(() => mode, [mode]);
}

/* X-Axis */

export function useXAxisClickable() {
	const value = useContextSelector(
		GraphPropsContext,
		ctxSelectors.xaxis.clickable,
	);

	return useMemo(() => value, [value]);
}

export function useXAxisHoverable() {
	const value = useContextSelector(
		GraphPropsContext,
		ctxSelectors.xaxis.hoverable,
	);

	return useMemo(() => value, [value]);
}

/* Legend */

export function useLegendClickable() {
	const value = useContextSelector(
		GraphPropsContext,
		ctxSelectors.legend.clickable,
	);

	return useMemo(() => value, [value]);
}

export function useLegendHoverable() {
	const value = useContextSelector(
		GraphPropsContext,
		ctxSelectors.legend.hoverable,
	);

	return useMemo(() => value, [value]);
}
