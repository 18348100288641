import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TextGroup } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';
import { FeatureWrapper } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

function slugify(slug) {
	if (!slug) {
		return slug;
	}

	return slug
		.split(' ')
		.map((value) => value.toLowerCase())
		.join('-');
}

const IntegrationPanel = (props) => {
	const { className, slug, iconPosition = 'last' } = props;

	const config = useConfig('onboarding.layout')?.panel?.[slug];

	const iconContent = useMemo(() => {
		if (config?.icon) {
			return {
				as: Icon,
				props: {
					icon: config?.icon?.icon,
					style: { '--fill': config?.icon?.fill },
				},
			};
		}

		if (config?.image) {
			return config?.image;
		}

		return null;
	}, [config?.icon, config?.image]);

	return (
		<FeatureWrapper feature={config?.feature}>
			<div
				className={cn(
					'asteria-component__onboarding__landing-panel',
					{ [`asteria--variant-${slugify(slug)}`]: slugify(slug) },
					className,
				)}
				style={{ '--color': config?.color }}
			>
				<div className="asteria-component__onboarding__landing-panel__content">
					{config?.content ? (
						<Contenter as={TextGroup} content={config?.content} />
					) : null}
				</div>
				{iconContent ? (
					<Contenter
						content={[iconContent]}
						className={cn(
							'asteria-component__onboarding__landing-panel__image',
							{
								[`asteria-component__onboarding__landing-panel__image--position-${iconPosition}`]:
									iconPosition,
							},
						)}
					/>
				) : (
					<div
						className={cn(
							'asteria-component__onboarding__landing-panel__image',
							{
								[`asteria-component__onboarding__landing-panel__image--position-${iconPosition}`]:
									iconPosition,
							},
						)}
					/>
				)}
			</div>
		</FeatureWrapper>
	);
};

IntegrationPanel.displayName = 'IntegrationPanel';

IntegrationPanel.propTypes = {
	className: PropTypes.string,
	slug: PropTypes.string,

	iconPosition: PropTypes.oneOf(['first', 'last']),
};

export default IntegrationPanel;
