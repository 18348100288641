import React from 'react';

import PropTypes from 'prop-types';

import { IntegrationDialog } from '@asteria/component-integrations-v2';

const IntegrationsLogic = (props) => {
	const { hidden } = props;

	if (hidden) {
		return null;
	}

	return <IntegrationDialog />;
};

IntegrationsLogic.displayName = 'IntegrationsLogic';

IntegrationsLogic.propTypes = {
	onFetch: PropTypes.func,
	hidden: PropTypes.bool,
};

export default React.memo(IntegrationsLogic);
