import parseName from '../getFormPaths';

/**
 * @template TForm
 * @param {{ form: TForm, type: string, category: string, tag: string }} options
 * @returns {{ op: string, name: string, value: unknown }[]}
 */
function reset(options) {
	const { form, type, category, tag } = options;

	return [...(form?.$changes ?? [])].reverse().filter(({ name }) => {
		const parsed = parseName(name);

		return (
			parsed.type === type &&
			parsed.category === category &&
			parsed.tag === tag
		);
	});
}

export default reset;
