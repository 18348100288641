import React from 'react';

import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useFeature } from '@asteria/component-tools/featureflag';

import Layout from './components/layout';
import CreditPage from './pages/credit';
import HomePage from './pages/home';
import LoanPage from './pages/loan';

const Wrapper = React.memo((props) => {
	const { children } = props;

	const [search] = useSearchParams();

	const hasFinancialV1Feature = useFeature('financial-v1');

	if (hasFinancialV1Feature) {
		return (
			<Navigate
				to={['/financial/credit', search.toString()]
					.filter(Boolean)
					.join('?')}
			/>
		);
	}

	return children;
});

Wrapper.displayName = 'Wrapper';
Wrapper.propTypes = { children: PropTypes.node };

const FinancialView = (props) => {
	const { onAction, onSubmit } = props;

	return (
		<Routes>
			<Route
				path="/"
				element={<Layout onAction={onAction} onSubmit={onSubmit} />}
			>
				<Route
					index
					element={
						<Wrapper>
							<HomePage onAction={onAction} onSubmit={onSubmit} />
						</Wrapper>
					}
				/>
				<Route
					path="/credit/:id?"
					element={
						<CreditPage onAction={onAction} onSubmit={onSubmit} />
					}
				/>
				<Route
					path="/loan/:id?"
					element={
						<Wrapper>
							<LoanPage onAction={onAction} onSubmit={onSubmit} />
						</Wrapper>
					}
				/>
			</Route>
		</Routes>
	);
};

FinancialView.displayName = 'FinancialView';
FinancialView.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default FinancialView;
