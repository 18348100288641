import { get } from 'lodash-es';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import { recalculate as recalculateCategory } from './adjustCategory';
import {
	add as addTransaction,
	clear as clearTransactions,
	move as moveTransaction,
	setProbability as setTransactionsProbability,
	setValue as setTransactionsValue,
} from './adjustTransactions';
import getFormPaths, { createChange, getPath } from './getFormPaths';

function getTransactionValue({ type, date, category, tag, form }) {
	const path = getPath({ type, date, category, tag });

	return Object.values(get(form, [path, 'transactions'].join('.')) ?? {})
		.filter((object) => object && !object.$deleted)
		.reduce(
			(acc, { total = 0, probability = 1 }) => ({
				value: acc.value + (total || 0),
				min: acc.min + (total || 0) * (probability || 1),
			}),
			{ value: 0, min: 0 },
		);
}

const setValue = ({ type, date, category, tag, value, form }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			setValue({ type, date, category, tag, value, form }),
		);
	}

	const path = getPath({ type, date, category, tag });

	const { value: prev } = getTransactionValue({
		type,
		date,
		category,
		tag,
		form,
	});

	const percentageChanged = value / prev; // percentageChanged, used to adjust values of all transactions

	let updates = [];

	if (value === prev) {
		return updates;
	}

	updates.push(
		createChange(
			{
				op: 'set',
				name: `${path}.value`,
				value: value,
				previousValue: prev,
				event: 'tag:value:set',
			},
			form,
		),
	);

	if (!prev) {
		updates = updates.concat(
			addTransaction({
				type,
				date,
				category,
				tag,
				value,
				probability: get(
					form,
					`${getPath({ type, date, category, tag })}.probability`,
					1,
				),
				form,
			}),
		);
	} else if (!value) {
		updates = updates.concat(
			clearTransactions({
				type,
				date,
				category,
				tag,
				form,
			}),
		);
	} else {
		updates = updates.concat(
			setTransactionsValue({
				type,
				date,
				category,
				tag,
				value: ({ total }) => total * percentageChanged,
				form,
			}),
		);
	}

	return updates;
};

const setState = () => [];

const recalculate = ({ type, date, category, tag, form }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			recalculate({ type, date, category, tag, form }),
		);
	}

	const path = getPath({ type, date, category, tag });

	const { value, probability } = get(form, path) || {};

	const { value: transactionValue, min: transactionMinValue } =
		getTransactionValue({ type, date, category, tag, form });

	const calculatedProbability =
		transactionValue !== 0 ? transactionMinValue / transactionValue : 1;
	const normalizedProbability = normalizeProbability(calculatedProbability);

	let updates = [];

	if (transactionValue !== value) {
		updates.push(
			createChange(
				{
					op: 'set',
					name: `${path}.value`,
					value: transactionValue,
					previousValue: value,
					event: 'tag:recalculate:value',
				},
				form,
			),
		);
	}

	if (normalizedProbability !== probability) {
		updates.push(
			createChange(
				{
					op: 'set',
					name: `${path}.probability`,
					value: normalizedProbability,
					previousValue: probability,
					event: 'tag:recalculate:probability',
				},
				form,
			),
		);
	}

	if (updates.length) {
		updates = updates.concat(
			recalculateCategory({ type, date, category, form }),
		);
	}

	return updates;
};

const setProbability = ({ type, date, category, tag, value, form, strict }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			setProbability({ type, date, category, tag, value, form, strict }),
		);
	}

	const path = getPath({ type, date, category, tag });
	const prev = get(form, [path, 'probability'].join('.')) ?? 1;

	let updates = [];

	if (value === prev) {
		return updates;
	}

	updates.push(
		createChange(
			{
				op: 'set',
				name: `${path}.probability`,
				value: value,
				previousValue: prev,
				event: 'tag:probability:set',
			},
			form,
		),
	);

	const percentageChanged = value / prev;

	updates = updates.concat(
		setTransactionsProbability({
			type,
			date,
			category,
			tag,
			probability: ({ probability = prev }) => {
				if (strict) {
					return value;
				}

				return probability * percentageChanged;
			},
			form,
		}),
	);

	return updates;
};

const move = ({ source, destination, form }) => {
	if (!source || !destination) {
		return [];
	}

	const date = source?.date || destination?.date;
	const type = source?.type || destination?.type;

	if (!date) {
		return Object.keys(get(form, getPath({ type: type }))).flatMap((date) =>
			move({
				source: { ...source, date: date, type: type },
				destination: { ...destination, date: date, type: type },
				form: form,
			}),
		);
	}

	const sourceTransactions = Object.values(
		get(
			form,
			getPath({
				type: source?.type,
				date: source?.date,
				category: source?.category,
				tag: source?.tag,
				field: 'transactions',
			}),
		) || {},
	);

	let changes = [];

	changes = changes.concat(
		sourceTransactions.flatMap(({ uuid }) =>
			moveTransaction({
				source: {
					type: source?.type,
					date: source?.date,
					category: source?.category,
					tag: source?.tag,
					uuid: uuid,
				},
				destination: {
					type: destination?.type,
					date: destination?.date,
					category: destination?.category,
					tag: destination?.tag,
				},
				form,
			}),
		),
	);

	changes = changes
		.concat(
			recalculate({
				type: source?.type,
				date: source?.date,
				category: source?.category,
				tag: source?.tag,
				form,
			}),
		)
		.concat(
			recalculate({
				type: destination?.type,
				date: destination?.date,
				category: destination?.category,
				tag: destination?.tag,
				form,
			}),
		);

	return changes;
};

const adjustTagValue = ({ name, value, getValues }) => {
	const { type, date, category, tag } = getFormPaths(name);

	const form = getValues();

	const { value: prev } = getTransactionValue({
		type,
		date,
		category,
		tag,
		form,
	});

	let updates = [].concat(
		setValue({ type, date, category, tag, value, form }),
	);

	const diff = value - prev;

	if (diff > 0 && category !== '$forecaster') {
		const overflow =
			get(
				form,
				getPath({
					type,
					date,
					category: '$forecaster',
					tag: '$adjusted',
					field: 'value',
				}),
			) || 0;

		if (overflow) {
			updates = updates.concat(
				setValue({
					type,
					date,
					category: '$forecaster',
					tag: '$adjusted',
					value: Math.max(overflow - diff, 0),
					form,
				}),
			);

			updates = updates.concat(
				recalculate({
					type,
					date,
					category: '$forecaster',
					tag: '$adjusted',
					form,
				}),
			);
		}
	}

	return updates;
};

export {
	adjustTagValue,
	move,
	recalculate,
	setProbability,
	setState,
	setValue,
};
