import React, { useMemo } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { isFuture, parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import {
	TooltipContent,
	TooltipWrapper,
} from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AccountStore from '@asteria/datalayer/stores/accounts';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import { useGraphMode } from '../../hooks';
import * as Selectors from '../../selectors';

import { useStateHidden, useStateInvisible } from './hooks';
import { findGroup } from './lineGraph';

const CreditReactTooltip = React.memo(() => {
	const credit = useSelector(
		(store) => AccountStore.selectors.dynamicCredit(store, {}),
		(a, b) => isEqual(a, b),
	);

	return (
		<TooltipContent className="asteria-component__overdraft-tooltip">
			<Group>
				<Title align="center">
					{TranslationService.get([
						'graph.credit.area.tooltip.title',
					])}
				</Title>
				<Group direction="horizontal" verticalAlign="center">
					<Text>
						{TranslationService.get([
							'graph.credit.area.tooltip.label',
						])}
					</Text>
					<Text>
						{TranslationService.get(
							['graph.credit.area.tooltip'],
							'{{ credit|number:false:false:true }}',
							{ credit: credit },
						)}
					</Text>
				</Group>
			</Group>
		</TooltipContent>
	);
});

CreditReactTooltip.displayName = 'CreditReactTooltip';

const CreditRect = React.memo((props) => {
	const { className, hidden, invisible, onMouseDown, y, fraction } = props;

	const hasOverdraftTooltipFeature = useFeature('credit-area-tooltip');

	return (
		<TooltipWrapper
			tooltip={hasOverdraftTooltipFeature ? <CreditReactTooltip /> : null}
			placement="mouse"
			offset={20}
		>
			<rect
				className={cn(className, 'asteria-overdraft-area', {
					'asteria--state-hidden': hidden,
					'asteria--state-invisible': invisible,
				})}
				style={{ stroke: 'none' }}
				x="0%"
				y={`${Math.min(y + fraction, y)}%`}
				height={`${Math.min(
					Math.abs(fraction),
					fraction > 0 ? 100 - y : y,
				)}%`}
				onMouseDown={onMouseDown}
				width="100%"
			/>
		</TooltipWrapper>
	);
});

CreditRect.displayName = 'CreditRect';
CreditRect.propTypes = {
	className: PropTypes.string,
	hidden: PropTypes.bool,
	invisible: PropTypes.bool,
	onMouseDown: PropTypes.func,
	y: PropTypes.number,
	fraction: PropTypes.number,
};

const LinePart = React.memo((props) => {
	const {
		className,
		id,
		line,
		prevLines,
		parts,
		layout,
		lines,
		center,
		intersectData,
		height,
		first,
		last,
		onDragStart,
		isFadingOut,
	} = props;

	let { value = 0 } = line;
	const { types: [type] = [] } = line;

	const hasBelowZeroLegendFeature = useFeature(`graph-legend-below-zero`);
	const hasCreditIndicator = useFeature('graph-credit-line-indicator');
	const hasBelowZeroFeature = useFeature(`graph-below-zero`);

	const mode = useGraphMode();

	const credit = useSelector(AccountStore.selectors.dynamicCredit, (a, b) =>
		isEqual(a, b),
	);

	const $minValue = useSelector(
		(store) => Selectors.graph.minValue(store, parts),
		(a, b) => isEqual(a, b),
	);

	const maxValue = useSelector(
		(store) => Selectors.graph.maxValue(store, parts),
		(a, b) => isEqual(a, b),
	);

	const hasCredit = useSelector(Selectors.graph.hasCredit, (a, b) =>
		isEqual(a, b),
	);

	const hiddenState = useStateHidden({ layout });
	const invisibleState = useStateInvisible({ layout });

	const minValue = layout === 'stacked' ? 0 : $minValue;

	const prevIndex = prevLines.findIndex(
		({ types: [prevType] = [] }) => prevType === type,
	);

	const isForecast = line.types.includes('forecast');

	const creditFraction =
		((credit - Math.abs(value)) / (maxValue - minValue)) * 100;

	let { value: prevValue = 0 } = prevLines[prevIndex] || {};

	const { types: prevTypes = [] } = prevLines[prevIndex] || {};

	if (layout !== 'stacked') {
		value = Math.max(value, minValue);
		prevValue = Math.max(prevValue, minValue);
	}

	let y1 = Math.max(
		100 - ((prevValue - minValue) / (maxValue - minValue)) * 100,
		0,
	);
	let y2 = Math.max(
		100 - ((value - minValue) / (maxValue - minValue)) * 100,
		0,
	);

	if (layout === 'stacked') {
		const $center = center ? center : 100;
		const divider = maxValue ? maxValue : Math.abs($minValue);

		y1 = Math.max(center - (prevValue / divider) * $center, 0);
		y2 = Math.max(center - (value / divider) * $center, 0);
	}

	const styles = React.useMemo(
		() => ({
			transform: `translateX(${
				layout === 'stacked'
					? 'var(--graph-stacked-credit-gap)'
					: 'var(--graph-default-credit-gap)'
			}) translateY(${height * (y2 / 100) - 5}px)`,
		}),
		[height, layout, y2],
	);

	const IconStyles = React.useMemo(
		() => ({
			cursor: 'row-resize',
		}),
		[],
	);

	const getCreditBoxPosition = React.useCallback(
		(y2) => {
			const translateX =
				first && isForecast ? 'calc(50% + 25px)' : '15px';
			const translateY = `${height * (y2 / 100) - 12}px`;
			return `translate(${translateX}, ${translateY})`;
		},
		[first, height, isForecast],
	);

	if (
		prevIndex === -1 ||
		(line.types.includes('account') &&
			line.types.includes('forecast') &&
			lines.find(({ types }) => types.includes('history')))
	) {
		return null;
	}

	const { intersectPoint, functionIsDecreasing, zeroLine } = intersectData;

	const originalY2 = y2;

	if (line.types.includes('sharp')) {
		if (creditFraction && isForecast && mode === 'credit') {
			y2 = Math.min(y2 + creditFraction, 100);
			y1 = prevTypes.includes('forecast')
				? Math.min(y1 + creditFraction, 100)
				: y1;
		}

		return (
			<React.Fragment>
				{y1 !== y2 && (
					<line
						className={cn(
							className,
							'asteria-graph-line-overdraft',
							'asteria-graph-line-vertical',
							{
								'asteria--feature-below-zero':
									hasBelowZeroFeature,
								'asteria--state-hidden':
									hiddenState?.credit &&
									(invisibleState?.credit ?? true),
								'asteria--state-invisible':
									invisibleState?.credit,
							},
							{
								'asteria-graph-line-below-zero': value < 0,
							},
						)}
						x1="0%"
						y1={`${height * (y1 / 100) + 2}`}
						x2="0%"
						y2={`${height * (y2 / 100) + 1}`}
					/>
				)}
				{hasCredit || mode === 'credit' ? (
					<line
						className={cn(
							className,
							'asteria-graph-line-overdraft',
							{
								'asteria--feature-below-zero':
									hasBelowZeroFeature,
								'asteria-graph-line-below-zero': value < 0,
								'asteria--state-hidden':
									hiddenState?.credit &&
									(invisibleState?.credit ?? true),
								'asteria--state-invisible':
									invisibleState?.credit,
							},
						)}
						x1="0%"
						y1={`${y2}%`}
						x2={last ? '150%' : '100%'}
						y2={`${y2}%`}
					/>
				) : null}
				{isForecast && mode === 'credit' && (
					<CreditRect
						className={className}
						hidden={
							hiddenState?.credit &&
							(invisibleState?.credit ?? true)
						}
						invisible={invisibleState?.credit}
						// onMouseDown={onDragStart}
						y={originalY2}
						fraction={creditFraction}
						mode={mode}
					/>
				)}
				{isForecast &&
				mode === 'credit' &&
				(!prevTypes.includes('forecast') || (first && isForecast)) ? (
					<g
						className={cn('asteria-graph-line-credit-box', {
							'asteria--state-hidden':
								hiddenState?.credit &&
								(invisibleState?.credit ?? true),
							'asteria--state-invisible': invisibleState?.credit,
						})}
						style={{
							transform: `${getCreditBoxPosition(y2)}`,
							['-moz-transform']: `${getCreditBoxPosition(y2)}`,
						}}
					>
						<TooltipWrapper
							tooltip={TranslationService.get(
								'graph.credit.handle.tooltip',
							)}
							once="graph:credit:handle"
						>
							<Icon
								icon="drag-circle"
								className="asteria-graph-line-button"
								svgOnly
								style={IconStyles}
								onMouseDown={onDragStart}
							/>
						</TooltipWrapper>
					</g>
				) : null}
				{first &&
				hasCreditIndicator &&
				(hasCredit || mode === 'credit') ? (
					<polygon
						className={cn(
							className,
							'asteria-overdraft-indicator',
							{
								'asteria--state-hidden':
									hiddenState?.credit &&
									(invisibleState?.credit ?? true),
								'asteria--state-invisible':
									invisibleState?.credit,
							},
						)}
						style={styles}
						points="0,0 10,5 0,10"
					/>
				) : null}
			</React.Fragment>
		);
	}

	const isFutureLine = id ? isFuture(parseISO(id)) : false;

	let negativePoints = {};
	let positivePoints = {};

	const points = {
		x1: '0%',
		y1: `${y1}%`,
		x2: `${last ? 150 : 100}%`,
		y2: `${y2}%`,
	};

	if (value < 0) {
		negativePoints = points;
	} else {
		positivePoints = points;
	}

	if (intersectPoint) {
		const firstPart = {
			...points,
			x1: `${intersectPoint}%`,
			y1: `${zeroLine}%`,
		};

		const secondPart = {
			...points,
			x2: `${intersectPoint}%`,
			y2: `${zeroLine}%`,
		};

		if (functionIsDecreasing) {
			positivePoints = firstPart;
			negativePoints = secondPart;
		} else {
			positivePoints = secondPart;
			negativePoints = firstPart;
		}
	}

	const isPositiveInvisible =
		(isForecast && invisibleState?.line?.future?.positive) ||
		(!isForecast && invisibleState?.line?.history?.positive) ||
		(invisibleState?.line?.history?.positive && isFadingOut);

	const isNegativeInvisible =
		(isForecast && invisibleState?.line?.future?.negative) ||
		(!isForecast && invisibleState?.line?.history?.negative) ||
		(invisibleState?.line?.history?.negative && isFadingOut);

	const isPositiveHidden =
		(isForecast && hiddenState?.line?.future?.positive) ||
		(!isForecast && hiddenState?.line?.history?.positive) ||
		(hiddenState?.line?.history?.positive && isFadingOut);

	const isNegativeHidden =
		(isForecast && hiddenState?.line?.future?.negative) ||
		(!isForecast && hiddenState?.line?.history?.negative) ||
		(hiddenState?.line?.history?.negative && isFadingOut);

	return (
		<g>
			<line
				className={cn(className, {
					'asteria--feature-below-zero': hasBelowZeroFeature,
					'asteria--state-hidden': isPositiveHidden,
					'asteria--state-invisible': isPositiveInvisible,
					'asteria-component__line--fade-out': isFadingOut,
					'asteria-component__line--fade-out-next':
						isFadingOut && intersectPoint < 50,
					'asteria--type-future': isFutureLine,
					'asteria--type-history': !isFutureLine,
				})}
				key={[type, 'positive'].join('-')}
				x1={0}
				y1={0}
				x2={0}
				y2={0}
				{...positivePoints}
				stroke={
					isFadingOut && intersectPoint >= 50
						? 'url(#gradient)'
						: undefined
				}
			/>
			<line
				className={cn(className, 'asteria-graph-line-below-zero', {
					'asteria--feature-below-zero': hasBelowZeroFeature,
					'asteria--state-hidden': hasBelowZeroLegendFeature
						? isNegativeHidden
						: isPositiveHidden,

					'asteria--state-invisible': hasBelowZeroLegendFeature
						? isNegativeInvisible
						: isPositiveInvisible,

					'asteria-component__line--fade-out':
						isFadingOut && intersectPoint < 50,
					'asteria--type-future': isFutureLine,
					'asteria--type-history': !isFutureLine,
				})}
				key={[type, 'negative'].join('-')}
				x1={0}
				y1={0}
				x2={0}
				y2={0}
				{...negativePoints}
				stroke={
					isFadingOut && intersectPoint < 50
						? 'url(#gradient)'
						: undefined
				}
			/>
		</g>
	);
});

LinePart.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	line: PropTypes.object,
	prevLines: PropTypes.arrayOf(PropTypes.object),
	parts: PropTypes.arrayOf(PropTypes.string),
	layout: PropTypes.string,
	lines: PropTypes.arrayOf(PropTypes.object),
	center: PropTypes.number,
	intersectData: PropTypes.object,
	height: PropTypes.number,
	first: PropTypes.bool,
	last: PropTypes.bool,
	onDragStart: PropTypes.func,
	isFadingOut: PropTypes.bool,
};

LinePart.displayName = 'LinePart';

const Line = React.memo(
	({
		id,
		prevId,
		index,
		layout,
		className,
		// group = {},
		last = false,
		first = false,
		// prevGroup,
		isFadingOut,
		height = 0,
		center,
		intersectData,
		parts,
	}) => {
		const range = useSelector(
			(store) =>
				Selectors.graph.range(store, {
					formatEach: (value) => ({ id: value?.id }),
				}),
			(a, b) => isEqual(a, b),
		);

		const group = useSelector(
			(store) =>
				Selectors.graph.barGroups(store, {
					format: (groups) =>
						findGroup({
							groups: groups,
							id: id,
							range: range,
							index: index,
						}),
				}),
			(a, b) => isEqual(a, b),
		);

		const prevGroup = useSelector(
			(store) =>
				Selectors.graph.barGroups(store, {
					format: (groups) =>
						findGroup({
							groups: groups,
							id: prevId,
							range: range,
							index: index,
						}),
				}),
			(a, b) => isEqual(a, b),
		);

		const mode = useGraphMode();

		const credit = useSelector(
			AccountStore.selectors.dynamicCredit,
			(a, b) => isEqual(a, b),
		);

		const $minValue = useSelector(
			(store) => Selectors.graph.minValue(store, parts),
			(a, b) => isEqual(a, b),
		);

		const maxValue = useSelector(
			(store) => Selectors.graph.maxValue(store, parts),
			(a, b) => isEqual(a, b),
		);

		const minValue = layout === 'stacked' ? 0 : $minValue;

		const store = useStore();

		const dispatch = useDispatch();
		// const { dispatch } = useContext(DatalayerContext);
		const { lines: groupLines = [] } = group;
		const { lines: prevGroupLines = [] } = prevGroup || group || {};

		const { lines, prevLines } = useMemo(() => {
			const hasCreditLines = groupLines.find(({ types = [] }) =>
				types?.includes('credit'),
			);

			if (mode === 'credit' && !hasCreditLines) {
				const creditLine = {
					value: 0,
					max: 0,
					min: 0,
					probability: null,
					types: [
						'credit',
						groupLines?.[0]?.types?.includes('forecast')
							? 'forecast'
							: 'history',
						'sharp',
					],
					info: [],
				};

				const prevCreditLine = {
					value: 0,
					max: 0,
					min: 0,
					probability: null,
					types: [
						'credit',
						prevGroupLines?.[0]?.types?.includes('forecast')
							? 'forecast'
							: 'history',
						'sharp',
					],
					info: [],
				};

				return {
					lines: [...groupLines, creditLine],
					prevLines: [...prevGroupLines, prevCreditLine],
				};
			}

			return {
				lines: groupLines,
				prevLines: prevGroupLines,
			};
		}, [groupLines, prevGroupLines, mode]);

		const lastDrag = React.useRef(null);

		const onDrag = React.useCallback(
			(event) => {
				if (!lastDrag.current) {
					return;
				}

				const { clientX, clientY } = event;

				const {
					x: lastX,
					y: lastY,
					credit,
					minValue,
					maxValue,
					height,
				} = lastDrag.current;

				const translation = {
					deltaX: clientX - lastX,
					deltaY: clientY - lastY,
				};

				const delta = (translation.deltaY / height) * 100;

				const value = Math.max(
					delta * ((maxValue - minValue) / 100) + credit,
					0,
				);

				dispatch(AccountStore.setDynamicCredit(Math.round(value)));
			},
			[dispatch],
		);

		const onDragEnd = React.useCallback(() => {
			const sourceCredit = lastDrag.current?.credit;
			const targetCredit = AccountStore.selectors.dynamicCredit(
				store.getState(),
			);

			Analytics.event('graph.credit.dragging', {
				from: sourceCredit,
				to: targetCredit,
			});

			document.removeEventListener('mousemove', onDrag);
			document.removeEventListener('mouseup', onDragEnd);

			lastDrag.current = null;
		}, [onDrag, store]);

		const onDragStart = React.useCallback(
			(event) => {
				if (event?.button !== 0 && event?.type !== 'touchstart') {
					return;
				}

				const { clientX, clientY } = event;

				document.addEventListener('mousemove', onDrag);
				document.addEventListener('mouseup', onDragEnd);

				lastDrag.current = {
					x: clientX,
					y: clientY,
					credit: credit,
					maxValue: maxValue,
					minValue: minValue,
					height: height,
				};
			},
			[credit, height, maxValue, minValue, onDrag, onDragEnd],
		);

		return lines.map((line, index) => {
			const { types: [type] = [] } = line;

			return (
				<LinePart
					key={[type, index].join('-')}
					className={className}
					id={id}
					line={line}
					prevLines={prevLines}
					parts={parts}
					layout={layout}
					lines={lines}
					center={center}
					intersectData={intersectData}
					height={height}
					first={first}
					last={last}
					onDragStart={onDragStart}
					isFadingOut={isFadingOut}
					mode={mode}
				/>
			);
		});
	},
);

Line.displayName = 'Line';
Line.Styler = {
	typePrefix: 'asteria-graph-line',
};

export default Line;
