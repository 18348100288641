import React from 'react';

import PropTypes from 'prop-types';

import { useOverallValue, useStatuses } from '../../hooks';

import View from './view';

const Provider = React.memo((props) => {
	const { onAction, type, date, category, tag, render, renderItem } = props;

	const value = useOverallValue({ type, date, category, tag });
	const statuses = useStatuses({ type, date, category, tag });

	return (
		<View
			onAction={onAction}
			type={type}
			date={date}
			category={category}
			tag={tag}
			statuses={statuses}
			value={value}
			render={render}
			renderItem={renderItem}
		/>
	);
});

Provider.displayName = 'TypedBarGroupProvider';
Provider.propTypes = {
	onAction: PropTypes.func,

	type: PropTypes.string,
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	render: PropTypes.func,
	renderItem: PropTypes.func,
};

export default Provider;
