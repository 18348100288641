import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import PropTypes from 'prop-types';

import {
	Service as FeatureService,
	useFeature,
} from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';
import useFilterClassname from '@asteria/utils-hooks/useFilterClassname';

import './styles.scss';

const selectors = {
	isTransactionListShown: createSelector(
		(store) => store?.transactions?.state ?? null,
		(value) => value !== null,
	),
	isFullScreen: createSelector(
		(store) => store?.app?.user?.settings?.layout?.fullscreen,
		(value) => value ?? null,
	),
	isEdit: createSelector(
		(state) => Object.values(state?.transactions?.states ?? {}),
		(value) => value.some(({ edit }) => edit),
	),
	hasCashflowTeaser: createSelector(
		(store) =>
			store?.app?.user?.settings?.flags?.['cashflow:forecast'] ?? false,
		(store) =>
			(store?.app?.filters ?? []).some(({ type }) => type === 'tag'),
		(isClosed, hasTags) =>
			!isClosed &&
			hasTags &&
			FeatureService.isActive('asteria-cashflow-teaser'),
	),
};

const ViewWrapper = React.forwardRef((props, ref) => {
	const { className, children, layoutSize, hasSidebar, view } = props;

	const hasGraphLargeFeature = useFeature('graph-large-bars');
	const hasGraphBorderedFeature = useFeature('graph-bordered');

	const hasTransactionInnerScrollFeature = useFeature(
		'cashflow-transactions-inner-scroll',
	);

	const hasTransactionAutoScrollFeature = useFeature(
		'cashflow-transactions-scroll-auto',
	);

	const isTransactionListShown = useSelector(
		selectors.isTransactionListShown,
	);
	const isFullScreen = useSelector(selectors.isFullScreen);
	const isEdit = useSelector(selectors.isEdit);
	const hasCashflowTeaser = useSelector(selectors.hasCashflowTeaser);

	const filterClassNames = useFilterClassname({
		prefix: 'asteria-wrapper--filters',
	});

	return (
		<div
			className={cn(
				'asteria-wrapper',
				className,
				{
					'asteria--feature-graph-large': hasGraphLargeFeature,
					[`asteria-fullscreen`]: isFullScreen,
					[`asteria-state--graph-bordered`]: hasGraphBorderedFeature,
					'asteria-transactions__editing': isEdit,
					'asteria--teaser-cashflow': hasCashflowTeaser,
					'asteria-wrapper--split': isTransactionListShown,
					[`asteria-wrapper--size-${layoutSize}`]: layoutSize,
					'asteria-wrapper--sidebar': hasSidebar,
					[`asteria-wrapper--view-${view}`]: view,
					'asteria--feature-transaction-scroll-inner':
						hasTransactionInnerScrollFeature,
					'asteria--feature-transaction-scroll-auto':
						hasTransactionAutoScrollFeature,
				},
				filterClassNames,
			)}
			ref={ref}
		>
			{children}
		</div>
	);
});

ViewWrapper.displayName = 'ViewWrapper';

ViewWrapper.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,

	layoutSize: PropTypes.string,
	hasSidebar: PropTypes.bool,
	view: PropTypes.string,
};

export default ViewWrapper;
