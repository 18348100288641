import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Form from '@asteria/component-form';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import Analytics from '@asteria/utils-analytics';

import ForecasterSettingsContent from './content';
import * as selectors from './selectors';

import './styles.scss';

const ForecasterSettingsForm = React.memo((props) => {
	const { onClose, onAction, form } = props;

	const dispatch = useDispatch();

	const settings = useSelector(selectors.settings);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'scenario:open') {
				return dispatch(
					ModalStore.open({
						type: data?.page,
						data: { data: data?.data },
					}),
				);
			}

			if (action === 'overview.reset') {
				return onClose?.();
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction, onClose],
	);

	const mutation = useMutation({
		mutationFn: async (form) => {
			Analytics.event('forecaster.scenario.save', form);
			return onAction?.('forecaster:settings:save', form);
		},

		onSuccess: () => onClose?.(),
	});

	return (
		<Form onSubmit={mutation.mutate} defaultValues={settings}>
			<ForecasterSettingsContent
				onClose={onClose}
				onAction={handleAction}
				loading={mutation.isLoading}
				form={form}
			/>
		</Form>
	);
});

ForecasterSettingsForm.displayName = 'ForecasterSettingsForm';

ForecasterSettingsForm.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,

	form: PropTypes.object,
};

export default ForecasterSettingsForm;
