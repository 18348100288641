import React from 'react';
import { cloneElement, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import TabsContent from './content';
import TabsNavigation from './navigation';

import './tabs.scss';

function getInitialValue(active) {
	if (Array.isArray(active)) {
		return active;
	}

	return active?.split?.(',') ?? [];
}

const Tabs = (props) => {
	const { className, active, direction, children, onChange } = props;

	const [tab, setTabs] = useState(getInitialValue(active));

	useEffect(() => {
		setTabs(getInitialValue(active));
	}, [active]);

	const setTab = useCallback(
		(next) => {
			const value = [].concat(next);

			setTabs(value);
			return onChange?.(value);
		},
		[onChange],
	);

	return (
		<div
			className={cn(
				'asteria-component__tabs',
				{
					[`asteria-component__tabs--direction-${direction}`]:
						direction,
				},
				className,
			)}
		>
			{React.Children.map(children, (child) =>
				cloneElement(child, { tab: tab, setTab: setTab }),
			)}
		</div>
	);
};

Tabs.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
	direction: PropTypes.string,
	active: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

Tabs.defaultProps = {};

Tabs.displayName = 'Tabs';

export default Tabs;
export { TabsContent, TabsNavigation };
