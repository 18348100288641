import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';
import Content from '@asteria/component-core/wrapper/content';

import { FeedbackRating } from '@asteria/component-feedback';
import {
	Wrapper as FormWrapper,
	Input,
	useFormValues,
} from '@asteria/component-form';
import Select, { Option } from '@asteria/component-form/select';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import '../styles.scss';

const FEEDBACK_THEMES = [
	'general',
	'navigation',
	'forecast',
	'support',
	'other',
];

/** @type { React.FC<{ from: string, step: number, rating: boolean, card: unknown }> } */
const CardFeedbackForm = function CardFeedbackForm({ from, rating, card }) {
	const { setValue } = useFormContext();

	const ratingValue = useFormValues({ name: 'rating' });

	const onRatingChange = React.useCallback(
		(value) => setValue('rating', value),
		[setValue],
	);

	const thumbValue = useFormValues({ name: 'thumb' });

	const thumbsUp = React.useCallback(() => setValue('thumb', 1), [setValue]);

	const thumbsDown = React.useCallback(
		() => setValue('thumb', 0),
		[setValue],
	);

	return (
		<FormWrapper>
			<Content>
				<Translation
					translationKey="card.content.text"
					translationOptions={{
						postfix: {
							variant: 'feedback',
							name: 'title',
							from: from,
							feedback: 'card',
						},
						data: { card: card },
					}}
					Component={Text}
				/>

				{rating ? (
					<FeedbackRating
						value={ratingValue}
						minText={TranslationService.getV2(['label'], {
							postfix: {
								variant: 'min',
								from: from,
								feedback: 'card',
							},
							data: { card: card },
						})}
						maxText={TranslationService.getV2(['label'], {
							postfix: {
								variant: 'max',
								from: from,
							},
							data: { card: card },
						})}
						onChange={onRatingChange}
					/>
				) : (
					<Group
						className={cn(
							'flex flex-row gap-2',
							'asteria-component__card-section',
							'asteria--variant-thumbs',
						)}
						direction="horizontal"
						horizontalAlign="center"
						verticalAlign="center"
					>
						<Button
							icon="thumbs-down"
							iconSize="lg"
							active={thumbValue === 0}
							onClick={thumbsDown}
						/>
						<Button
							icon="thumbs-up"
							iconSize="lg"
							active={thumbValue === 1}
							onClick={thumbsUp}
						/>
					</Group>
				)}
				<Select name="theme" placeholder="Select a feedback topic">
					{FEEDBACK_THEMES.map((theme) => (
						<Option key="theme" value={theme}>
							{TranslationService.getV2(['card.input.option'], {
								postfix: {
									variant: 'feedback',
									name: 'theme',
									from: from,
									theme: theme,
								},
								data: { card: card },
							})}
						</Option>
					))}
				</Select>
				<Input
					label={TranslationService.getV2(['card.input.label'], {
						postfix: {
							variant: 'feedback',
							name: 'description',
							from: from,
							feedback: 'card',
						},
						data: { card: card },
					})}
					name="description"
					type="textarea"
					placeholder={TranslationService.getV2(
						['card.input.placeholder'],
						{
							postfix: {
								variant: 'feedback',
								name: 'description',
								from: from,
								feedback: 'card',
							},
							data: { card: card },
						},
					)}
				/>
			</Content>
		</FormWrapper>
	);
};

CardFeedbackForm.propTypes = {
	from: PropTypes.string,
	step: PropTypes.number,
	rating: PropTypes.bool,
	card: PropTypes.object,
};

export default CardFeedbackForm;
