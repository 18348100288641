import React, { useCallback, useMemo } from 'react';

import {
	addMonths,
	format,
	formatISO,
	isAfter,
	isBefore,
	isFuture,
	isPast,
	isSameMonth,
	parseISO,
	subMonths,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import { cleanValue, getLocale } from '../utils';

const DatePickerContentCellComponent = (props) => {
	const {
		date,
		onSelect,

		isPastDisabled,
		isFutureDisabled,

		startDate,
		endDate,
		disabledDates,

		className,
		isThisDate,

		month,

		format: fmt,

		locale,
	} = props;

	const currentLocale = getLocale(locale);

	const isPastDate = !isThisDate && isPast(date);
	const isFutureDate = !isThisDate && isFuture(date);

	const isPastMonth = month && isSameMonth(month, subMonths(date, 1));
	const isFutureMonth = month && isSameMonth(month, addMonths(date, 1));

	const weekday = Number.parseInt(
		format(date, 'e', { locale: currentLocale }),
	);

	const isDisabledByPast = useMemo(
		() => isPastDisabled && isPastDate,
		[isPastDisabled, isPastDate],
	);

	const isDisabledByFuture = useMemo(
		() => isFutureDisabled && isFutureDate,
		[isFutureDisabled, isFutureDate],
	);

	const isDisabledByStartDate = useMemo(
		() => startDate && isBefore(date, parseISO(startDate)),
		[startDate, date],
	);

	const isDisabledByEndDate = useMemo(
		() => endDate && isAfter(date, parseISO(endDate)),
		[endDate, date],
	);

	const isDisableByDisabledDates = useMemo(() => {
		const formattedDate = formatISO(date, { representation: 'date' });

		if (!disabledDates) {
			return false;
		}

		if (typeof disabledDates === 'string') {
			return formattedDate === disabledDates;
		}

		if (Array.isArray(disabledDates)) {
			return disabledDates.some((date) => formattedDate === date);
		}

		return false;
	}, [date, disabledDates]);

	const isDisabled =
		isDisabledByPast ||
		isDisabledByFuture ||
		isDisabledByStartDate ||
		isDisabledByEndDate ||
		isDisableByDisabledDates;

	const onClick = useCallback(() => {
		if (isDisabled) {
			return;
		}

		onSelect(formatISO(date, { representation: 'date' }));
	}, [date, isDisabled, onSelect]);

	return (
		<div
			className={cn(
				className,
				'asteria-component__datepicker__content__calendar__item',
				`asteria--weekday-${weekday}`,
				{
					'asteria--date-past': isPastDate,
					'asteria--date-past-month': isPastMonth,
					'asteria--date-future': isFutureDate,
					'asteria--date-future-month': isFutureMonth,
					'asteria--date-current': isThisDate,
					'asteria--state-disabled': isDisabled,
				},
			)}
			onClick={onClick}
		>
			<span>
				<Text>
					{cleanValue(format(date, fmt, { locale: currentLocale }))}
				</Text>
			</span>
		</div>
	);
};

DatePickerContentCellComponent.propTypes = {
	className: PropTypes.string,
	isThisDate: PropTypes.bool,

	locale: Constants.DatePickerLocalePropType,

	date: PropTypes.instanceOf(Date),
	value: Constants.DatePickerValuePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
	format: PropTypes.string,
	month: PropTypes.instanceOf(Date),
};

const DatePickerContentCell = (props) => {
	const { date, className } = props;

	if (date === null) {
		return (
			<div
				className={cn(
					'asteria-component__datepicker__content__calendar__item',
					'asteria-component__datepicker__content__calendar__item--empty',
					className,
				)}
			/>
		);
	}

	return <DatePickerContentCellComponent {...props} />;
};

DatePickerContentCell.propTypes = {
	className: PropTypes.string,
	isThisDate: PropTypes.bool,

	locale: Constants.DatePickerLocalePropType,

	date: PropTypes.instanceOf(Date),
	value: Constants.DatePickerValuePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
	format: PropTypes.string,

	month: PropTypes.instanceOf(Date),
};

DatePickerContentCell.defaultProps = {};

export default DatePickerContentCell;
