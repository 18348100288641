import React from 'react';

import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';
import { MessageBoxToggle } from '@asteria/component-support';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getData } from '../../../utils/loan';

const TransactionHelpToggle = React.memo(() => {
	const toggle = React.useMemo(
		() => ({
			variant: 'tertiary',
			label: TranslationService.get([
				'financial.page.loan.section.transactions.help.toggle.label',
			]),
			icon: 'help',
			iconActive: 'close',
			iconPosition: 'last',
		}),
		[],
	);

	return (
		<MessageBoxToggle toggle={toggle}>
			<Text size="sm">
				{TranslationService.get([
					'financial.page.loan.section.transactions.help.toggle.content',
				])}
			</Text>
		</MessageBoxToggle>
	);
});

TransactionHelpToggle.displayName = 'TransactionHelpToggle';
TransactionHelpToggle.propTypes = {};

const TransactionMonthlyTable = React.memo(() => {
	const [type, amount, term, rate, timestamp] = useFormValues({
		name: ['type', 'amount', 'term', 'rate', 'timestamp'],
	});

	const data = getData({
		type,
		amount: Number.parseFloat(amount),
		term: Number.parseFloat(term),
		rate: Number.parseFloat(rate),
		timestamp: timestamp ? parseISO(timestamp) : new Date(),
	}).reduce((acc, object) => {
		const year = format(object.date, 'yyyy');

		if (!acc[year]) {
			acc[year] = [];
		}

		acc[year].push(object);

		return acc;
	}, {});

	return (
		<div
			className={cn(
				'asteria-component__financial-page-section-content-group',
				'asteria--variant-monthly',
			)}
		>
			<Title size="xs">
				{TranslationService.get([
					'financial.page.loan.section.transactions.table.monthly.title',
				])}
			</Title>
			{Object.entries(data).map(([year, rows]) => (
				<div
					key={year}
					className="asteria-component__financial-page-section-content-group-section"
					data-year={year}
				>
					<Text size="xs">
						{TranslationService.get(
							[
								'financial.page.loan.section.transactions.table.monthly.group.title',
							],
							'{{ year }}',
							{ year: year },
						)}
					</Text>
					<Table>
						<TableHeader>
							{[
								'date',
								'remaining',
								'amortization',
								'interest',
								'total',
								'end',
							].map((key) => (
								<TableCell
									key={key}
									className={cn({
										[`asteria--variant-${key}`]: key,
									})}
								>
									<TooltipWrapper
										tooltip={TranslationService.get([
											'financial.page.loan.section.transactions.table.monthly.header.cell',
											`financial.page.loan.section.transactions.table.monthly.header.cell.${key}`,
										])}
										variant="alt"
									>
										<Text weight="bold">
											{TranslationService.get([
												'financial.page.loan.section.transactions.table.monthly.header.cell',
												`financial.page.loan.section.transactions.table.monthly.header.cell.${key}`,
											])}
										</Text>
									</TooltipWrapper>
								</TableCell>
							))}
						</TableHeader>
						{rows.map((object) => {
							const key = format(object.date, 'yyyy-MM-dd');

							return (
								<TableRow key={key} data-date={key}>
									{[
										'date',
										'remaining',
										'amortization',
										'interest',
										'total',
										'end',
									].map((key) => (
										<TableCell
											key={key}
											className={cn({
												[`asteria--variant-${key}`]:
													key,
											})}
										>
											<TooltipWrapper
												tooltip={TranslationService.get(
													[
														'financial.page.loan.section.transactions.table.monthly.cell',
														`financial.page.loan.section.transactions.table.monthly.cell.${key}`,
													],
													undefined,
													object,
												)}
												variant="alt"
											>
												<Text size="sm">
													{TranslationService.get(
														[
															'financial.page.loan.section.transactions.table.monthly.cell',
															`financial.page.loan.section.transactions.table.monthly.cell.${key}`,
														],
														undefined,
														object,
													)}
												</Text>
											</TooltipWrapper>
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</Table>
				</div>
			))}
		</div>
	);
});

TransactionMonthlyTable.displayName = 'TransactionMonthlyTable';

const SectionTransactions = React.memo(() => {
	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-transactions',
			)}
		>
			<TransactionHelpToggle />
			<div className="asteria-component__financial-page-section-content">
				<TransactionMonthlyTable />
			</div>
		</div>
	);
});

SectionTransactions.displayName = 'SectionTransactions';
SectionTransactions.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionTransactions;
