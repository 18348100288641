import * as FormatUtils from '@asteria/utils-funcs/format';

export default function formatTag(object) {
	const tagName = object?.name ?? '';
	const categoryName = object?.category?.name ?? '';

	return {
		_id: object?._id,
		probability: object?.probability,
		tagName: tagName,
		categoryName: categoryName,
		weight: object?.weight || 0,
		alert: object?.alert || false,
		label: FormatUtils.formatTag({ category: categoryName, tag: tagName }),
	};
}
