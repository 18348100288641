import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

/* START: Filters selectors */

export const filters = createSelector(
	(store) => store?.app?.filters,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const typeFilters = createSelector(filters, (filters) =>
	filters.filter(({ type }) => type === 'type'),
);

export const tags = createSelector(
	filters,
	(filters) => filters.filter(({ type }) => type === 'tag'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const statuses = createSelector(
	filters,
	(filters) => filters.filter(({ type }) => type === 'status'),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const hasTagFilters = createSelector(
	tags,
	(filters) => filters.length > 0,
);

export const hasNotStatusFilters = createSelector(
	filters,
	(filters) => filters.filter(({ type }) => type !== 'status').length > 0,
);

/* END: Filters selectors */
