import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { isFuture, parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { getViewport } from '@asteria/component-core/utils';

import {
	useBulkFeatures,
	useFeature,
} from '@asteria/component-tools/featureflag';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import { setGraphOptions } from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';
import getLayoutSize from '@asteria/utils-funcs/getLayoutSize';
import useComponentSize from '@asteria/utils-hooks/useComponentSize';
import useHasScrolledPassed from '@asteria/utils-hooks/useHasScrolledPassed';

import Graph from '../graphs/graph';
import { useGraphMode, useLegendClickable, useLegendHoverable } from '../hooks';
import Legends from '../legends';
import Navigation from '../navigation';
import * as Selectors from '../selectors';

import TabbedGraphs from './tabbed';

const GroupedGraphs = React.memo((props) => {
	// Needs
	const { legends, onUpdateSettings, onAction, variant } = props;

	// Old
	const {
		// Old
		updateSize,
		next,
		prev,
		onClick,
		onMouseEnter,
		onMouseLeave,
		getTooltip,
		requestData,
	} = props;

	const mode = useGraphMode();

	const currentTimesliceSize = useSelector(
		Selectors.common.currentTimesliceSize,
		(a, b) => isEqual(a, b),
	);

	const lineGraphParts = useMemo(() => ['lines'], []);
	const barGraphParts = useMemo(() => ['bars'], []);

	let navigationFeature = null;

	if (variant === 'credit') {
		navigationFeature = 'graph-navigation-overview-credit';
	}

	if (variant === 'cashflow') {
		navigationFeature = 'graph-navigation-overview-cashflow';
	}

	const hasNavigationFeature = useBulkFeatures(
		['graph-navigation-overview', navigationFeature].filter(Boolean),
	);

	const hasSelectableFeature = useFeature('graph-legend-selectable');

	const legendClickable = useLegendClickable();
	const legendHoverable = useLegendHoverable();

	const { lineGraph, barGraph } = useSelector(
		Selectors.graph.options,
		(a, b) => isEqual(a, b),
	);

	const isCreditGraphActive = useSelector(
		Selectors.common.isCreditGraphActive,
		(a, b) => isEqual(a, b),
	);

	const accountLegends = useMemo(
		() => legends.filter(({ part }) => part === 'account'),
		[legends],
	);

	const cashflowLegends = useMemo(
		() => legends.filter(({ part }) => part === 'cashflow'),
		[legends],
	);

	return (
		<>
			{!hasNavigationFeature ? (
				<Navigation
					onNext={next}
					onPrev={prev}
					className="asteria-cashflow-graph-navigation"
				/>
			) : null}
			<Legends
				data={accountLegends}
				className="asteria-graph-line-graph-legends"
				clickable={legendClickable && hasSelectableFeature}
				hoverable={legendHoverable && hasSelectableFeature}
				negative
				part="account"
			/>
			<Graph
				key="cashflow-graph"
				className="asteria-graph-line-graph-component"
				parts={lineGraphParts}
				next={next}
				prev={prev}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				getTooltip={getTooltip}
				size={currentTimesliceSize}
				showXAxis={
					(mode === 'credit' && !isCreditGraphActive) ||
					(lineGraph && !barGraph)
				}
				barLayout="grouped"
				mode={mode}
				requestData={requestData}
				onUpdateSettings={onUpdateSettings}
				onAction={onAction}
			/>
			<Legends
				data={cashflowLegends}
				className="asteria-graph-revenue-graph-legends"
				clickable={legendClickable && hasSelectableFeature}
				hoverable={legendHoverable && hasSelectableFeature}
				negative
				part="cashflow"
				mode={mode}
			/>
			<Graph
				key="cashflow-graph-2"
				className="asteria-graph-revenue-graph"
				parts={barGraphParts}
				updateSize={updateSize}
				next={next}
				prev={prev}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				getTooltip={getTooltip}
				size={currentTimesliceSize}
				barLayout="grouped"
				mode={mode}
				requestData={requestData}
				onUpdateSettings={onUpdateSettings}
				onAction={onAction}
			/>
		</>
	);
});

GroupedGraphs.displayName = 'GroupedGraphs';

GroupedGraphs.propTypes = {
	legends: PropTypes.array,
	mode: PropTypes.string,
	shouldHideAccountBalance: PropTypes.bool,
};

function useLegends() {
	const mode = useGraphMode();

	const hasCreditArea = useSelector(
		AccountStore.selectors.hasCreditChange,
		(a, b) => isEqual(a, b),
	);

	const range = useSelector(Selectors.graph.range, (a, b) => isEqual(a, b));

	const hasTagFilters = useSelector(Selectors.filters.hasTagFilters, (a, b) =>
		isEqual(a, b),
	);

	const tags = useSelector(Selectors.filters.tags, (a, b) => isEqual(a, b));

	const statuses = useSelector(Selectors.filters.statuses, (a, b) =>
		isEqual(a, b),
	);

	const hasForecast = useSelector(Selectors.graph.hasForecast, (a, b) =>
		isEqual(a, b),
	);

	const hasCredit = useSelector(Selectors.graph.hasCredit, (a, b) =>
		isEqual(a, b),
	);

	const hasUnpaid = useSelector(Selectors.graph.hasUnpaid, (a, b) =>
		isEqual(a, b),
	);

	const hasOverdue = useSelector(Selectors.graph.hasOverdue, (a, b) =>
		isEqual(a, b),
	);

	// const hasSpreadDotsFeature = useFeature('graph-spread-info-dots');
	const hasDisableFutureFeature = useFeature('graph-disable-forecast-line');
	const shouldDisableFuture = hasDisableFutureFeature;

	const hasCategoryLegendFeature = useFeature('graph-legend-categories');

	const isFutureRange =
		range.length > 1 &&
		isFuture(
			range[1].id instanceof Date ? range[1].id : parseISO(range[1].id),
		) &&
		isFuture(
			range.slice(-1)[0].id instanceof Date
				? range.slice(-1)[0].id
				: parseISO(range.slice(-1)[0].id),
		);

	return useMemo(() => {
		let legends = [];

		if (!isFutureRange) {
			legends.push({
				part: 'account',
				type: ['account', 'paid'],
				title: TranslationService.get('graph.account.legend.history'),
			});
		}

		if (!hasTagFilters && !isFutureRange) {
			legends.push({
				part: 'cashflow',
				type: 'deposit',
				title: TranslationService.get([
					'tags.deposit',
					'graph.legend.deposit',
				]),
			});
		}

		if (!hasTagFilters && !isFutureRange) {
			legends.push({
				part: 'cashflow',
				type: 'withdraw',
				title: TranslationService.get([
					'tags.withdraw',
					'graph.legend.withdraw',
				]),
			});
		}

		if (hasCategoryLegendFeature) {
			legends = legends.concat(
				tags.map((object) => ({
					part: 'cashflow',
					color: object?.item?.color,
					type: [object?.item?.category?.name, object?.item?.name]
						.filter(Boolean)
						.map((value) => value.replace(/\$/g, ''))
						.join('-'),
					title: FormatUtils.formatTag({
						category: object?.item?.category?.name,
						tag: object?.item?.name,
					}),
				})),
			);
		}

		if (
			(!statuses.length || statuses.some(({ id }) => id === 'UNPAID')) &&
			hasUnpaid
		) {
			legends.push({
				part: 'cashflow',
				type: 'unpaid',
				title: TranslationService.get([
					'tags.unpaid',
					'graph.legend.unpaid',
				]),
			});
		}

		if (
			(!statuses.length || statuses.some(({ id }) => id === 'OVERDUE')) &&
			hasOverdue
		) {
			legends.push({
				part: 'cashflow',
				type: 'overdue',
				title: TranslationService.get([
					'tags.overdue',
					'graph.legend.overdue',
				]),
			});
		}

		if (
			(!statuses.length ||
				statuses.some(({ id }) => id === 'FORECAST')) &&
			hasForecast
		) {
			if (!shouldDisableFuture) {
				legends.push({
					part: 'account',
					type: ['account', 'forecast'],
					title: TranslationService.get(
						'graph.account.legend.forecast',
					),
				});
			}

			legends.push({
				part: 'account',
				type: 'spread',
				title: TranslationService.get('graph.account.legend.spread'),
			});

			legends.push({
				part: 'cashflow',
				type: 'forecast',
				title: TranslationService.get([
					'tags.forecast',
					'graph.legend.forecast',
				]),
			});
		}

		if (hasCredit || mode === 'credit') {
			legends.push({
				part: 'account',
				type: 'credit',
				title: TranslationService.get('graph.account.legend.credit'),
			});
		}

		if (hasCreditArea && mode === 'credit') {
			legends.push({
				part: 'account',
				type: 'credit-area',
				title: TranslationService.get(
					'graph.account.legend.credit.area',
				),
			});
		}

		return legends;
	}, [
		isFutureRange,
		hasTagFilters,
		hasCategoryLegendFeature,
		statuses,
		hasUnpaid,
		hasOverdue,
		hasForecast,
		hasCredit,
		mode,
		hasCreditArea,
		tags,
		shouldDisableFuture,
	]);
}

const GroupedLayout = React.memo(
	React.forwardRef((props, ref) => {
		const { className, onResize, onUpdateSettings } = props;

		const dispatch = useDispatch();
		const store = useStore();

		const mode = useGraphMode();

		const hasTagFilters = useSelector(
			Selectors.filters.hasTagFilters,
			(a, b) => isEqual(a, b),
		);

		const layout = useSelector(Selectors.settings.layout, (a, b) =>
			isEqual(a, b),
		);

		const isCreditGraphActive = useSelector(
			Selectors.common.isCreditGraphActive,
			(a, b) => isEqual(a, b),
		);

		const graphOptions = useSelector(Selectors.graph.options, (a, b) =>
			isEqual(a, b),
		);

		const showCreditSettings = useFeature('credit-settings');
		const showCreditSettingsGraph = useFeature('credit-settings-graph');

		const hasLargeBreakpoint = useFeature(
			'cashflow-graph-breakpoint-large',
		);
		const hasMediumBreakpoint = useFeature(
			'cashflow-graph-breakpoint-medium',
		);

		const showBarGraph = useMemo(() => {
			if (mode === 'credit') {
				if (showCreditSettings && showCreditSettingsGraph) {
					return isCreditGraphActive;
				}

				return false;
			}

			return graphOptions.barGraph;
		}, [
			graphOptions.barGraph,
			isCreditGraphActive,
			mode,
			showCreditSettings,
			showCreditSettingsGraph,
		]);

		const showLineGraph = useMemo(() => {
			if (mode === 'credit') {
				return true;
			}

			return graphOptions.lineGraph;
		}, [graphOptions.lineGraph, mode]);

		const hasPassed = useHasScrolledPassed(ref, -200);

		let { graph: { layout: graphLayout = 'grouped' } = {} } = layout || {};

		if (mode === 'credit') {
			graphLayout = 'grouped';
		}

		const legends = useLegends();

		const handleUpdateSize = React.useMemo(
			() =>
				AsteriaCore.utils.throttle((size) => {
					const [, height] = getViewport();
					const isTransactionListShown =
						Selectors.common.isTransactionListShown(
							store.getState(),
						);

					if (isTransactionListShown) {
						onUpdateSettings?.({
							layout: {
								size: {
									[height]: {
										width: size.width,
										height: size.height,
									},
								},
							},
						});
					}
				}, 1000),
			[onUpdateSettings, store],
		);

		const maxSizeValue = React.useMemo(() => {
			const [width, height] = getViewport();

			return { height: height, width: width };
		}, []);

		const { height } = useComponentSize({
			ref: ref,
			callback: handleUpdateSize,
			max: maxSizeValue,
		});

		const layoutSize = getLayoutSize(height);

		useEffect(() => {
			if (!hasLargeBreakpoint && !hasMediumBreakpoint) {
				const active = store.getState().graph?.active ?? 'barGraph';
				const graphOptions = Selectors.graph.options(store.getState());

				if (layoutSize === 'small') {
					if (graphOptions.lineGraph && graphOptions.barGraph) {
						dispatch(
							setGraphOptions({
								lineGraph: active !== 'barGraph',
								barGraph: active === 'barGraph',
							}),
						);
					}
				} else {
					dispatch(
						setGraphOptions({ lineGraph: true, barGraph: true }),
					);
				}
			}
		}, [
			dispatch,
			hasLargeBreakpoint,
			hasMediumBreakpoint,
			layoutSize,
			store,
		]);

		useEffect(() => {
			if (!hasLargeBreakpoint && !hasMediumBreakpoint) {
				onResize?.(layoutSize);
			}
		}, [hasLargeBreakpoint, hasMediumBreakpoint, layoutSize, onResize]);

		return (
			<div
				className={cn(
					className,
					'asteria-cashflow-graph',
					'asteria-cashflow-graph-grouped',
					`asteria-cashflow__mode-${mode}`,
					`asteria-cashflow-graph-layout-${graphLayout}`,
					{
						'asteria-has-scrolled-passed': hasPassed,
						'asteria-cashflow-graph-has-filter': hasTagFilters,
						'asteria-cashflow-graph-has-no-filter': hasTagFilters,
						'asteria-cashflow-graph-hide-account-balance':
							!showLineGraph,
						'asteria-cashflow-graph-hide-revenue': !showBarGraph,
					},
				)}
				ref={ref}
			>
				{graphLayout === 'grouped' && (
					<GroupedGraphs
						legends={legends}
						shouldHideAccountBalance={!showLineGraph}
						{...props}
						mode={mode}
					/>
				)}

				{graphLayout === 'tabs' && (
					<TabbedGraphs {...props} mode={mode} />
				)}
			</div>
		);
	}),
);

GroupedLayout.displayName = 'GroupedLayout';

GroupedLayout.propTypes = {
	className: PropTypes.string,
	hasUnpaid: PropTypes.bool,
	hasOverdue: PropTypes.bool,
	hasForecast: PropTypes.bool,
	hasCredit: PropTypes.bool,
	hasRisk: PropTypes.bool,
	settings: PropTypes.object,

	hasTagFilters: PropTypes.bool,
	mode: PropTypes.string,

	next: PropTypes.func,
	prev: PropTypes.func,
	onUpdateSettings: PropTypes.func,
	onResize: PropTypes.func,

	filters: PropTypes.arrayOf(PropTypes.object),
};

export default GroupedLayout;
