function set(state, { payload }) {
	state.users.data = payload;
}

function update(state, { payload }) {
	const input = [].concat(payload);

	const objects = state.users.data.reduce(
		(acc, object) => ({ ...acc, [object?._id ?? object.id]: object }),
		{},
	);

	for (const object of input) {
		const ID = object?._id ?? object.id;

		objects[ID] = object;
	}

	state.users.data = Object.values(objects);
}

function reset(state) {
	state.users.data = [];
}

function start(state) {
	state.users.loading = true;
}

function stop(state) {
	state.users.loading = false;
}

export {
	set as setUsers,
	update as updateUsers,
	reset as resetUsers,
	start as startUsersLoading,
	stop as stopUsersLoading,
};
