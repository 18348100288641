import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const Placeholder = (props) => {
	const { className, onAction } = props;

	const placeholderConfig = useConfig('pages.forecaster.placeholder', {
		deep: true,
	});

	const actions = React.useMemo(() => ({ onAction: onAction }), [onAction]);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-placeholder',
				className,
			)}
		>
			<Contenter content={placeholderConfig?.content} actions={actions} />
		</div>
	);
};

Placeholder.displayName = 'Placeholder';

Placeholder.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default Placeholder;
