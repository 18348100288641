import {
	addDays,
	addMonths,
	addWeeks,
	addYears,
	format,
	parseISO,
} from 'date-fns';

import * as AppStore from '@asteria/datalayer/stores/app';
import { setActiveGroups } from '@asteria/datalayer/stores/graph';

const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

const trigger = (action = {}, { dispatch, store, onAction }) => {
	const { uri, types, filters, listStartDate, graphStartDate } = action;
	const state = store?.getState?.();

	if (uri) {
		history?.push?.(uri);
	}

	if (filters?.length) {
		onAction?.('setFilters', filters);
	}

	if (types) {
		dispatch?.(AppStore.setSelectedType(types || ['WITHDRAW', 'DEPOSIT']));

		onAction?.('redirect', {
			view: 'cashflow',
			options: { skipFilters: true },
		});
	}

	if (listStartDate) {
		dispatch?.(setActiveGroups([listStartDate]));
		dispatch?.(AppStore.setSelectedDate(listStartDate));

		onAction?.('redirect', {
			view: 'cashflow',
			options: { skipFilters: true },
		});
	}

	if (graphStartDate) {
		dispatch?.(
			AppStore.setCurrentDate(
				format(
					addTimeslice(
						parseISO(graphStartDate),
						state?.app?.timesize,
						-1,
					),
					'yyyy-MM-dd',
				),
			),
		);

		onAction?.('redirect', {
			view: 'cashflow',
			options: { skipFilters: true },
		});
	}

	return true;
};

export default trigger;
