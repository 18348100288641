import PropTypes from 'prop-types';

export const DatePickerTypes = ['day', 'week', 'month', 'year'];
export const DatePickerTypesPropType = PropTypes.oneOf(DatePickerTypes);

export const DatePickerVariants = ['default', 'multiple', 'range'];
export const DatePickerVariantsPropType = PropTypes.oneOf(DatePickerVariants);

export const DatePickerBehavior = ['default', 'scroll'];
export const DatePickerBehaviorPropType = PropTypes.oneOf(DatePickerBehavior);

export const DatePickerLayout = ['default', 'double', 'triple'];
export const DatePickerLayoutPropType = PropTypes.oneOf(DatePickerLayout);

export const DatePickerLocale = [
	'sv',
	'en',
	'enGB',
	'enUS',
	'fr',
	'uk',
	'ru',
	'es',
	'pt',
];
export const DatePickerLocalePropType = PropTypes.oneOf(DatePickerLocale);

export const DatePickerValue = [
	PropTypes.string,
	PropTypes.arrayOf(PropTypes.string),
	PropTypes.shape({
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}),
];
export const DatePickerValuePropType = PropTypes.oneOfType(DatePickerValue);
