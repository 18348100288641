import { format } from 'date-fns';

import { SECTIONS } from '../constants';
import { findNextNavigation, findPreviousNavigation } from '../utils';

const go = (state, action) => {
	state.navigation.date = action?.payload?.date ?? null;

	if (action?.payload?.section) {
		state.navigation.section = action?.payload?.section ?? null;
	}

	state.navigation.type = action?.payload?.type ?? null;
	state.navigation.category = action?.payload?.category ?? null;
	state.navigation.tag = action?.payload?.tag ?? null;

	if (!action?.payload?.tag) {
		if (state.navigation.section === SECTIONS.DEPOSIT) {
			state.navigation.type = 'deposit';
			state.navigation.category = '$type';
			state.navigation.tag = '$deposit';
		}

		if (state.navigation.section === SECTIONS.WITHDRAW) {
			state.navigation.type = 'withdraw';
			state.navigation.category = '$type';
			state.navigation.tag = '$withdraw';
		}

		if (state.navigation.section === SECTIONS.HARD_TO_FORECAST) {
			const navigation = findNextNavigation(state);

			state.navigation.section = navigation?.section;
			state.navigation.type = navigation?.type;
			state.navigation.category = navigation?.category;
			state.navigation.tag = navigation?.tag;
		}

		if (state.navigation.section === SECTIONS.ALL_CATEGORIES) {
			const next = (state?.tags ?? [])
				.filter((object) => object?.categoryName !== '$type')
				.find((object) => object?.type === state.navigation.type);

			if (next) {
				state.navigation.category = next?.categoryName;
				state.navigation.tag = next?.tagName;
			} else {
				state.navigation.category = '$type';
				state.navigation.tag = `$${state.navigation.type}`;
			}
		}
	}
};

const setDate = (state, action) => {
	const isoDate =
		action.payload instanceof Date
			? format(action.payload, 'yyyy-MM-dd')
			: action.payload;
	state.navigation.date = isoDate;
};

const next = (state) => {
	const navigation = findNextNavigation(state);

	state.navigation.section = navigation?.section;
	state.navigation.type = navigation?.type;
	state.navigation.category = navigation?.category;
	state.navigation.tag = navigation?.tag;

	if (navigation?.type === 'overview') {
		state.flags.fullScreen = false;
		state.flags.transactions = false;
	}
};

const back = (state) => {
	const navigation = findPreviousNavigation(state);

	if (navigation) {
		state.navigation.section = navigation?.section;
		state.navigation.type = navigation?.type;
		state.navigation.category = navigation?.category;
		state.navigation.tag = navigation?.tag;

		const index = (state.tags ?? []).findIndex(
			(object) =>
				object?.type === state.navigation.type &&
				object.categoryName === state.navigation.category &&
				object.tagName === state.navigation.tag,
		);

		if (index !== -1) {
			state.tags[index].isCompleted = false;
			state.tags[index].isSkipped = false;
		}
	}
};

// eslint-disable-next-line no-unused-vars
const skip = (state) => {};

export {
	back as navigationBack,
	go as navigationGo,
	next as navigationNext,
	setDate as navigationSetDate,
	skip as navigationSkip,
};
