import React from 'react';

import PropTypes from 'prop-types';

import { useFormValues } from '@asteria/component-form';

import SearchExamples from './examples';
import SearchResults from './results';

import './styles.scss';

const SearchFloating = React.memo((props) => {
	const { onAction } = props;

	const handleAction = React.useCallback(
		(action, data) => {
			onAction(action, data, { skipBlur: true });
		},
		[onAction],
	);

	const value = useFormValues({ name: 'search-value' });

	if (value) {
		return <SearchResults onAction={handleAction} />;
	}

	return <SearchExamples onAction={handleAction} />;
});

SearchFloating.displayName = 'SearchFloating';
SearchFloating.propTypes = { onAction: PropTypes.func };

export default SearchFloating;
