import { useMemo } from 'react';

import { get } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

import * as FormatUtils from '@asteria/utils-funcs/format';

import { getPath } from '../../../utils/form/utils';

export function useTranslationOptions(base, { type, category, tag }) {
	const path = getPath({ type, category, tag });

	const source = get(base?.data?.source, path);

	const [total, probability] = useFormValues({
		name: [
			path.concat('total').join('.'),
			path.concat('probability').join('.'),
		],
	});

	const target = get(base?.data?.target, path);

	return useMemo(
		() => ({
			source: source,
			target: target,
			form: { total, probability },
			options: {
				...base,
				postfix: {
					...base?.postfix,
					section: 'details',
					'section-type': type,
					'section-category': category,
					'section-tag': tag,
				},
				data: {
					...base?.data,

					category:
						category && tag
							? FormatUtils.formatTag({ type, category, tag })
							: null,

					source: {
						...base?.data?.source,
						value: source,
					},

					target: {
						...base?.data?.target,
						value: target,
					},

					form: {
						...base?.data?.form,
						value: { total, probability },
					},
				},
			},
		}),
		[base, category, probability, source, tag, target, total, type],
	);
}
