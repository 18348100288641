import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { Circle } from '../progress';

import './index.scss';

const Spinner = ({ progress = -1, empty }) => {
	return (
		<Circle
			progress={progress}
			empty={empty}
			className={cn('asteria-component__spinner')}
		/>
	);
};

Spinner.propTypes = {
	className: PropTypes.string,
};

Spinner.defaultProps = {
	className: null,
};

Spinner.displayName = 'Spinner';

export default Spinner;
