import React from 'react';

import { useSelector } from 'react-redux';

import { useQueryClient } from '@tanstack/react-query';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useSubscription } from '@asteria/utils-websocket/hooks';

const ConversationUpdater = React.memo(function ConversationUpdater() {
	const accessToken = useSelector(AppStore.selectors.accessToken);
	const queryClient = useQueryClient();

	const refresh = React.useCallback(
		(response) => {
			queryClient.invalidateQueries({
				predicate: (query) =>
					query.queryKey[0] === 'chat' &&
					query.queryKey[1] ===
						response?.data?.chatMessageCreated?.chatId,
			});
		},
		[queryClient],
	);

	useSubscription({
		query: `
			subscription chatMessageCreated($filters: [ChatMessageFilters!]) {
				chatMessageCreated(filters: $filters) {
					_id
					chatId
				}
			}
		`,
		token: accessToken,
		onNext: refresh,
	});

	return null;
});

ConversationUpdater.displayName = 'ConversationUpdater';

ConversationUpdater.propTypes = {};

export default ConversationUpdater;
