import { createSlice, nanoid } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

const initialState = { data: [] };

export const slice = createSlice({
	name: 'snackbar',
	initialState: initialState,
	reducers: {
		push: {
			reducer: (state, { payload }) => {
				state.data.push(payload);
			},
			prepare: (payload) => {
				const _id = payload?._id || nanoid();
				const position = payload?.position ?? 'top-end';
				const data = payload?.data ?? {};

				return {
					payload: {
						...payload,
						_id: _id,
						position: position,
						data: data,
					},
				};
			},
		},
		pop: (state, action) => {
			if (!action?.payload) {
				return;
			}

			const index = state.data.findIndex(
				(object) => object._id === action?.payload,
			);

			if (index !== -1) {
				state.data.splice(index, 1);
			}
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function

export const { push, pop } = slice.actions;

export const show = (dispatch, payload) => {
	const _id = nanoid();
	const timeout = payload?.timeout || 5000;

	if (timeout !== -1) {
		setTimeout(() => dispatch(slice.actions.pop(_id)), timeout);
	}

	return dispatch(slice.actions.push({ ...payload, _id: _id }));
};

export default slice.reducer;
