export function getUrlParams(search) {
	return Object.fromEntries(new URLSearchParams(search).entries());
}

export function formatFavicon(node, name) {
	const [url, pathname] = node?.href?.split?.('/images/favicon/') ?? [
		'',
		'asteria/favicon.ico',
	];
	const [, ...path] = pathname.split('/');

	const href = []
		.concat(url)
		.concat(
			[]
				.concat(
					name === 'Asteria AB' ? 'asteria' : name?.toLowerCase?.(),
				)
				.concat(path)
				.filter(Boolean)
				.join('/'),
		)
		.join('/images/favicon/');

	return href;
}

export function getUrlLogin() {
	const { search } = location;

	const searchParams = getUrlParams(search);

	if (searchParams.token) {
		localStorage.setItem('wingsToken', searchParams.token);
		localStorage.setItem('asteriaSite', searchParams.site);

		const newParams = new URLSearchParams(search);
		newParams.delete('token');

		location.href = `/?${newParams.toString()}`;

		return true;
	}
}

export function getSite() {
	const { hostname, search } = location;

	const searchParams = getUrlParams(search);

	if (searchParams.site) {
		localStorage.setItem('asteriaSite', searchParams.site);

		return searchParams.site;
	}

	if (localStorage.asteriaSite) {
		return localStorage.asteriaSite;
	}

	if (location.href.includes('asteria.arentia.pt')) {
		return 'arentia';
	}

	if (location.href.includes('swedbank.')) {
		return 'payex';
	}

	if (location.href.includes('asteria.ai')) {
		const pathname = hostname
			.split('.')[0]
			.replace('dev-', '')
			.replace('stage-', '')
			.replace('prod-', '');

		return pathname;
	}

	return 'wings';
}

export function getWidget() {
	const { search, hostname } = location;
	const searchParams = getUrlParams(search);

	if (hostname.includes('payex.') || hostname.includes('swedbank.')) {
		sessionStorage.setItem('widget', 'freja');
		return 'freja';
	}

	if (searchParams.widget) {
		sessionStorage.setItem('widget', searchParams.widget);
		return searchParams.widget;
	}

	return sessionStorage.getItem('widget') ?? 'full';
}

export function getTheme(partner) {
	const { hostname } = location;

	if (hostname.includes('swedbank.')) {
		return 'swedbank';
	}

	return partner?.settings?.theme?.name;
}
