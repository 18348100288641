import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import { applyFilters, applySorting } from '@asteria/component-core/utils/data';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { getObject } from '@asteria/utils-funcs/data';

import DropdownClient from './Client';

import './index.scss';

const selectors = {
	clients: createSelector(
		(state) => state,
		(_, options) => options ?? {},
		(store, { path, filters, sorting }) => {
			let objects = getObject(store, path, []) ?? [];

			if (Object.keys(filters ?? {}).length) {
				objects = applyFilters(objects, [filters]);
			}

			if (Object.keys(sorting ?? {}).length) {
				objects = applySorting(objects, [
					{ [sorting?.field]: sorting?.order },
				]);
			}

			return objects;
		},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const ClientSelector = (props) => {
	const {
		onAction,
		onSelect,
		showEdit,
		showAdd,
		hideSelected,
		showSelectedIcon,
		selectedIcon,
		dataSource,
		label,
		filters,
		filters: { type } = {},
		name,
		multi,
		sorting,
		size,
	} = props;

	const dispatch = useDispatch();

	const clients = useSelector((store) =>
		selectors.clients(store, {
			path: dataSource,
			filters: filters,
			sorting: sorting,
		}),
	);

	const { setValue, getValues } = useFormContext();

	const [activeClients, setActiveClients] = useState(() => {
		const value = name ? getValues([name]) : [];
		return value || [];
	});

	const handleCreateClient = useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientEdit,
				data: { data: { type: type } },
			}),
		);
	}, [type, dispatch]);

	const handleClientSelect = useCallback(
		(data, isSelected) => {
			setActiveClients((activeClients) => {
				if (multi) {
					let value = [...activeClients, data._id];

					if (isSelected) {
						value = value.filter((item) => item !== data._id);
					}

					onSelect?.(value);

					if (name) {
						setValue(name, value);
					}

					return value;
				}

				onSelect?.(data._id);

				if (name) {
					setValue(name, data._id);
				}

				return [data._id];
			});

			return onAction?.('client:select', data);
		},
		[onAction, multi, name, onSelect, setValue],
	);

	const dropdownLabel = useMemo(() => {
		if (label) {
			return label;
		}

		const defaultLabel = TranslationService.get('forecaster.clients');
		if (!multi && activeClients && clients) {
			return (
				clients.find((object) => object._id === activeClients[0])
					?.name ?? defaultLabel
			);
		}

		return defaultLabel;
	}, [label, multi, activeClients, clients]);

	const value = getValues(name) ?? null;

	const dropdownToggle = useMemo(
		() => ({
			icon: 'triangle-down',
			iconActive: 'triangle-up',
			iconPosition: 'last',
			label: dropdownLabel,
			size: size,
			tooltip: TranslationService.get(
				[
					'forecaster.navigation.select.clients',
					`forecaster.navigation.select.clients.${type}`,
				],
				type === 'CUSTOMER'
					? // eslint-disable-next-line spellcheck/spell-checker
					  'Klicka här för att välja kund'
					: // eslint-disable-next-line spellcheck/spell-checker
					  'Klicka här för att välja leverantör',
			),
			className: cn({
				'asteria--state-filled': value,
			}),
		}),
		[dropdownLabel, size, type, value],
	);

	return (
		<Dropdown
			className={cn(
				'asteria-component__selector',
				'asteria-component__dropdown__clients',
				'asteria-component__forecaster-selector',
			)}
			size="sm"
			toggle={dropdownToggle}
			variant="select"
			scroll
			title={TranslationService.get([
				'client.select.title',
				'client.select.dropdown.title',
			])}
			analyticsKey="forecaster.client.select"
			offset="--select-offset"
		>
			{clients.map((object) => {
				const isSelected = activeClients?.includes(object._id);
				if (hideSelected && isSelected) {
					return null;
				}

				return (
					<DropdownClient
						showSelected={showSelectedIcon && isSelected}
						active={isSelected}
						key={object?._id}
						data={object}
						showEdit={showEdit}
						selectedIcon={selectedIcon}
						handleClientSelect={(data) =>
							handleClientSelect(data, isSelected)
						}
					/>
				);
			})}
			{showAdd ? (
				<DropdownItem
					className={cn(
						'asteria-component__forecaster-selector__item',
						'asteria-component__forecaster-clients__item',
						'asteria--variant-create',
					)}
					onClick={handleCreateClient}
					analyticsKey="forecaster.client.create"
				>
					<Button
						label={TranslationService.get(
							['forecaster.clients.dropdown.create'],
							'Create a client',
						)}
						size="md"
						iconSize="sm"
						icon="add"
						variant="tertiary"
					/>
				</DropdownItem>
			) : null}
		</Dropdown>
	);
};

ClientSelector.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	onAction: PropTypes.func,
	onSelect: PropTypes.func,

	showEdit: PropTypes.bool,
	showAdd: PropTypes.bool,
	multi: PropTypes.bool,

	hideSelected: PropTypes.bool,
	showSelectedIcon: PropTypes.bool,
	selectedIcon: PropTypes.string,

	dataSource: PropTypes.string,
	filters: PropTypes.object,
	sorting: PropTypes.object,

	size: PropTypes.string,
};

ClientSelector.defaultProps = {};

export default React.memo(ClientSelector);
