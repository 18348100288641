import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../../PropTypes';
import { useLoading } from '../hooks';

import { BackButton } from './actions';

import './styles.scss';

/** @type { React.FC<Partial<{ position: string, onBack: () => void, size: string, children: React.ReactNode | Partial<{ before: React.ReactNode, after: React.ReactNode }>, loading: boolean, backBtn: unknown }>> } */
const Prefix = React.memo((props) => {
	const {
		position,
		onBack,
		size,
		children,
		loading: $loading,
		backBtn = {},
	} = props;

	const before =
		children?.before || children?.after
			? children?.before ?? null
			: children;

	const after =
		children?.before || children?.after ? children?.after ?? null : null;

	const loading = useLoading($loading);

	return (
		<div
			className={cn(
				'asteria-component__wrapper-header__prefix',
				{ 'asteria--state-loading': loading },
				{
					[`asteria-component__wrapper-header__prefix--position-${position}`]:
						position,
				},
			)}
		>
			{typeof before === 'function' ? before({ loading }) : before}

			{onBack ? (
				<BackButton
					loading={loading}
					size={size}
					onBack={onBack}
					{...backBtn}
				/>
			) : null}

			{typeof after === 'function' ? after({ loading }) : after}
		</div>
	);
});

Prefix.displayName = 'HeaderPrefix';

Prefix.propTypes = {
	position: PropTypes.string,
	onBack: PropTypes.func,
	size: SizeProp(),

	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.shape({ before: PropTypes.node, after: PropTypes.node }),
	]),

	loading: PropTypes.bool,
	backBtn: PropTypes.object,
};

export default Prefix;
