import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const PagePostfix = React.memo((props) => {
	const { className, children } = props;

	return (
		<div
			className={cn('asteria-component__page-header__postfix', className)}
		>
			{children}
		</div>
	);
});

PagePostfix.displayName = 'PagePostfix';

PagePostfix.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default PagePostfix;
