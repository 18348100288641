import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Alert from '.';

import './styles.scss';

const CompleteScreen = (props) => {
	const { className } = props;

	return (
		<Alert
			{...props}
			className={cn('asteria-component__alert-screen', className)}
		/>
	);
};

CompleteScreen.propTypes = {
	className: PropTypes.string,
	level: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
	title: PropTypes.string,
	children: PropTypes.node,
	icon: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	actions: PropTypes.arrayOf(PropTypes.object),
};

CompleteScreen.displayName = 'CompleteScreen';

export default CompleteScreen;
