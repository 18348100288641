import React from 'react';

import { useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';
import Modal from '@asteria/component-modal';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import {
	useModalActions,
	useModalProps,
} from '../../components/modal-collection';

const LoanSave = React.memo(() => {
	const store = useStore();

	const { close, onAction, onSubmit } = useModalActions();
	const { form, next, callback } = useModalProps();

	const [loading, setLoading] = React.useState(false);

	const defaultValues = React.useMemo(() => {
		let name = TranslationService.get(
			['financial.modal.loan.save.input.name.default-value'],
			`Financial {{ now | date:"yyyy-MM-dd" }}`,
			{ now: new Date() },
		);

		const scenarios = ScenarioStore.selectors
			.scenarios(store.getState(), { type: 'LOAN' })
			.filter((object) => object?.name?.includes?.(name));

		if (scenarios.length) {
			name = [name, `(${scenarios.length + 1})`].join(' ');
		}

		return {
			name: name,
			type: 'LOAN',
			data: form,
		};
	}, [form, store]);

	const handleClose = React.useCallback(
		(event) => close?.(event, { size: 2 }),
		[close],
	);

	const handleBack = React.useCallback((event) => close?.(event), [close]);

	const handleSubmit = React.useCallback(
		async (form) => {
			setLoading(true);

			try {
				await onSubmit?.('scenario:create', form);
			} catch (err) {
				//
			}

			setLoading(false);

			callback?.('loan:dirty:reset');

			onAction?.('redirect', { path: next ?? '/financial' });

			return handleClose();
		},
		[callback, handleClose, next, onAction, onSubmit],
	);

	const validate = React.useMemo(
		() => ({
			exists: (value) => {
				const scenarios = ScenarioStore.selectors
					.scenarios(store.getState(), { type: 'LOAN' })
					.map(({ name }) => name);

				return !scenarios.includes(value);
			},
		}),
		[store],
	);

	return (
		<Form defaultValues={defaultValues} onSubmit={handleSubmit}>
			<Wrapper>
				<Header onClose={handleClose}>
					{TranslationService.get([
						'financial.modal.loan.save.title',
					])}
				</Header>
				<Content>
					<FormWrapper>
						<Content>
							<Text>
								{TranslationService.get([
									'financial.modal.loan.save.content',
								])}
							</Text>
							<Input
								name="name"
								label={TranslationService.get([
									'financial.modal.loan.save.input.name.label',
								])}
								validate={validate}
								formNamePrefix="financial.loan.save"
							/>
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.close',
								'action.close',
								'financial.modal.loan.save.close',
							])}
							onClick={handleClose}
							analyticsKey="financial.modal.loan.save.close"
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="secondary"
							label={TranslationService.get([
								'button.back',
								'action.back',
								'financial.modal.loan.save.back',
							])}
							onClick={handleBack}
							analyticsKey="financial.modal.loan.save.back"
						/>
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.submit',
								'action.submit',
								'financial.modal.loan.save.submit',
							])}
							analyticsKey="financial.modal.loan.save.submit"
							type="submit"
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
});

LoanSave.displayName = 'LoanSave';
LoanSave.propTypes = {};

const LoanSaveModal = React.memo((props) => {
	const { open, className } = props;

	const { close } = useModalActions();

	const handleClose = React.useCallback(
		(event) => close?.(event, { size: 2 }),
		[close],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			className={cn(
				'asteria-component__financial-modal',
				'asteria--variant-loan-save',
				className,
			)}
			size="sm"
		>
			<LoanSave {...props} />
		</Modal>
	);
});

LoanSaveModal.displayName = 'LoanSaveModal';

LoanSaveModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
};

export default LoanSaveModal;
