import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const FooterSection = (props) => {
	const { children, position } = props;

	return (
		<div
			className={cn('asteria-component__wrapper-footer__section', {
				[`asteria-component__wrapper-footer__section--position-${position}`]:
					position,
			})}
		>
			{children}
		</div>
	);
};

FooterSection.displayName = 'FooterSection';

FooterSection.propTypes = {
	children: PropTypes.node,
	position: PropTypes.string,
};

const Footer = (props) => {
	const { children } = props;

	return <div className="asteria-component__wrapper-footer">{children}</div>;
};

Footer.displayName = 'Footer';

Footer.propTypes = { children: PropTypes.node };

export default Footer;
export { FooterSection };
