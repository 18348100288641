import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const PageSubtitle = (props) => {
	const { className, children } = props;

	return (
		<div
			className={cn(
				'asteria-component__page-header__subtitle',
				className,
			)}
		>
			{children}
		</div>
	);
};

PageSubtitle.displayName = 'PageSubtitle';

PageSubtitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default PageSubtitle;
