import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { format } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Circle } from '@asteria/component-core/progress';
import Tooltip from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import List, {
	ListCell,
	ListGroup,
	ListHeader,
	ListItem,
} from '@asteria/component-list';
import Modal from '@asteria/component-modal';
import { MessageBoxToggle } from '@asteria/component-support/MessageBox';
import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { setFilters } from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';
import useConfig from '@asteria/utils-hooks/useConfig';

import './index.scss';

const selectors = {
	hasAccount: createSelector(
		(store) => store?.accounts?.bankAccounts ?? [],
		(_, currency) => currency,
		(objects, currency) =>
			objects.some((item) => item?.sums?.original?.currency === currency),
	),
	accounts: createSelector(
		(store) => store?.accounts?.bankAccounts ?? [],
		(_, currency) => currency,
		(objects, currency) =>
			objects.filter(
				(item) => item?.sums?.original?.currency === currency,
			),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	filters: createSelector(
		(store) => store?.app?.filters,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	companyCurrency: createSelector(
		(store) => store?.app?.company?.settings?.currency,
		(value) => value ?? 'SEK',
	),
	currencies: createSelector(
		(store) => store?.app?.currencies ?? [],
		(store) => store?.app?.company?.settings?.currency ?? 'SEK',
		(currencies, companyCurrency) =>
			Array.from(currencies).sort((a, b) => {
				if (a.code === companyCurrency) {
					return -1;
				}

				if (b.code === companyCurrency) {
					return 1;
				}

				const sourceTotal =
					(a?.deposit?.display?.total ?? 0) -
					(a?.withdraw?.display?.total ?? 0);

				const targetTotal =
					(b?.deposit?.display?.total ?? 0) -
					(b?.withdraw?.display?.total ?? 0);

				return targetTotal - sourceTotal;
			}),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const CurrencyExposure = (props) => {
	const { currency } = props;

	const hasAccount = useSelector((store) =>
		selectors.hasAccount(store, currency.currency),
	);

	const companyCurrency = useSelector(selectors.companyCurrency);

	if (companyCurrency === currency?.code) {
		return (
			<div className="asteria-currency-cell asteria-currency-cell-exposure" />
		);
	}

	if (hasAccount) {
		return (
			<div
				className={cn(
					'asteria-currency-cell asteria-currency-cell-exposure',
					{
						'asteria-state-negative':
							(currency?.exposure?.display?.total || 0) !== 0,
						'asteria-state-zero':
							(currency?.exposure?.display?.total || 0) === 0,
					},
				)}
			>
				<TextGroup>
					<Text className={cn('asteria-text-exposure')} size="sm">
						{formatNumber(
							currency?.exposure?.display?.total || 0,
							false,
							false,
							false,
						)}
						<span>
							{currency?.exposure?.display?.currency ||
								currency?.deposit?.display?.currency}
						</span>
					</Text>
					<Text
						className={cn(
							'asteria-text-exposure',
							'asteria-text-original',
						)}
						size="xs"
					>
						{formatNumber(
							currency?.exposure?.original?.total || 0,
							false,
							false,
							false,
						)}
						<span>
							{currency?.exposure?.original?.currency ||
								currency?.deposit?.original?.currency}
						</span>
					</Text>
				</TextGroup>
			</div>
		);
	}

	return (
		<div className="asteria-currency-cell asteria-currency-cell-exposure">
			<div className="asteria-currency-cell-exposure-deposit asteria-text-deposit">
				<Text
					className="asteria-text-exposure asteria-text-deposit"
					size="sm"
				>
					{formatNumber(
						currency?.deposit?.exposure?.display?.total || 0,
						false,
						false,
						false,
					)}
					<span>
						{currency?.deposit?.exposure?.display?.currency ||
							currency?.deposit?.display?.currency}
					</span>
				</Text>
				<Text
					className="asteria-text-exposure asteria-text-original asteria-text-deposit"
					size="xs"
				>
					{formatNumber(
						currency?.deposit?.exposure?.original?.total || 0,
						false,
						false,
						false,
					)}
					<span>
						{currency?.deposit?.exposure?.original?.currency ||
							currency?.deposit?.original?.currency}
					</span>
				</Text>
			</div>
			<div className="asteria-currency-cell-exposure-withdraw asteria-text-withdraw">
				<Text
					className="asteria-text-exposure asteria-text-withdraw"
					size="sm"
				>
					{formatNumber(
						currency?.withdraw?.exposure?.display?.total || 0,
						true,
						false,
						false,
					)}
					<span>
						{currency?.withdraw?.exposure?.display?.currency ||
							currency?.deposit?.display?.currency}
					</span>
				</Text>
				<Text
					className="asteria-text-exposure asteria-text-original asteria-text-withdraw"
					size="xs"
				>
					{formatNumber(
						currency?.withdraw?.exposure?.original?.total || 0,
						true,
						false,
						false,
					)}
					<span>
						{currency?.withdraw?.exposure?.original?.currency ||
							currency?.deposit?.original?.currency}
					</span>
				</Text>
			</div>
		</div>
	);
};

CurrencyExposure.propTypes = {
	currency: PropTypes.object,
};

const CurrencyAccount = (props) => {
	const { currency, onGetHelp } = props;

	const accounts = useSelector((store) =>
		selectors.accounts(store, currency.currency),
	);

	const { original, display } = useMemo(
		() =>
			accounts.reduce(
				(acc, item) => ({
					original: acc.original + item?.sums?.original?.total || 0,
					display: acc.display + item?.sums?.display?.total || 0,
				}),
				{ display: 0, original: 0 },
			),
		[accounts],
	);

	return (
		<ListCell className="asteria-currency-cell asteria-currency-cell-currency-total">
			{accounts?.length ? (
				<TextGroup>
					<Text className="asteria-text-amount" size="sm">
						{formatNumber(display || 0, false, false, false)}
					</Text>
					{currency?.exposure?.display?.currency !==
					currency?.exposure?.original?.currency ? (
						<Text
							className={cn('asteria-text-amount', {
								'asteria-text-original':
									currency?.exposure?.display?.currency !==
									currency?.exposure?.original?.currency,
							})}
							size="xs"
						>
							{formatNumber(original || 0, false, false, false)}
							<span>
								{currency?.exposure?.original?.currency ||
									currency?.deposit?.original?.currency}
							</span>
						</Text>
					) : null}
				</TextGroup>
			) : null}
			<FeatureFlag feature="financial-fx">
				{!accounts.length && (
					<Button
						label={TranslationService.get(
							'currencies.account.missing.helptext',
							// eslint-disable-next-line spellcheck/spell-checker
							'Du har inget valutakonto',
						)}
						size="sm"
						variant="link"
						href={TranslationService.get(
							'currencies.account.missing.helpurl',
						)}
						target="_blank"
						tooltip={TranslationService.get(
							'currencies.account.missing.tooltip',
							// eslint-disable-next-line spellcheck/spell-checker
							'Du har inget valutakonto i denna valuta. Klicka för att få hjälp med att få veta mer om valutakonton.',
						)}
						onClick={onGetHelp}
					/>
				)}
			</FeatureFlag>
		</ListCell>
	);
};

CurrencyAccount.propTypes = {
	currency: PropTypes.object,
	onGetHelp: PropTypes.func,
};

const CurrencyImpact = (props) => {
	const { currency, totalImpact = 0 } = props;

	const currencyTotal =
		(currency?.deposit?.display?.total || 0) -
		(currency?.withdraw?.display?.total || 0);

	const currencyImpact = Math.round(
		(Math.abs(currencyTotal) / totalImpact) * 100,
	);

	const displayDepositTotal = currency?.deposit?.display?.total;
	const displayWithdrawTotal = currency?.withdraw?.display?.total;

	return (
		<ListCell className="asteria-currency-cell asteria-currency-cell-currency-balance">
			<Tooltip className="asteria-tooltip-transaction-cell-status">
				<Circle
					progress={
						(Math.abs(
							(displayDepositTotal || 0) -
								(displayWithdrawTotal || 0),
						) /
							totalImpact) *
						100
					}
					empty
				/>
				<Text>
					{TranslationService.get(
						'currencies.currency.impact.tooltip',
						// eslint-disable-next-line spellcheck/spell-checker
						'Denna valuta har en potentiell {{currencyImpact}}% påverkan på ditt framtida kassaflöde',
						{ currencyImpact },
					)}
				</Text>
			</Tooltip>
			<Text className="asteria-text-amount" size="sm">
				{formatNumber(
					(currency?.deposit?.display?.total || 0) -
						(currency?.withdraw?.display?.total || 0),
				)}
			</Text>
		</ListCell>
	);
};

CurrencyImpact.propTypes = {
	currency: PropTypes.object,
	totalImpact: PropTypes.number,
};

const CurrencyView = (props) => {
	const {
		className,
		totalImpact = 0,
		currency,
		onClose,
		onGetHelp,
		onAction,
	} = props;

	const dispatch = useDispatch();

	const filters = useSelector(selectors.filters);
	const companyCurrency = useSelector(selectors.companyCurrency);

	const riskAction = useCallback(() => {
		onClose?.(false);
		onGetHelp?.();
	}, [onClose, onGetHelp]);

	const handleToggleCurrency = useCallback(
		(value) => {
			dispatch(
				setFilters([
					{
						id: value.currency,
						type: 'currency',
						item: currency,
					},
				]),
			);

			onAction?.('toggle:currency', value.currency);
		},
		[currency, dispatch, onAction],
	);

	const onFilter = useCallback(() => {
		onClose?.(false);
		handleToggleCurrency(currency);
	}, [currency, handleToggleCurrency, onClose]);

	const isActive = useMemo(
		() =>
			filters?.find(
				({ id, type }) =>
					type === 'currency' && id === currency?.currency,
			),
		[currency.currency, filters],
	);

	return (
		<ListItem
			className={cn(
				className,
				'asteria-list-item',
				'asteria-component__currency-view',
				`asteria-component__currency-view__currency-${currency?.currency?.toLowerCase()}`,
				{ 'asteria-state-active': isActive },
			)}
		>
			<ListCell className="asteria-currency-cell asteria-currency-cell-currency asteria-overview">
				<Tooltip
					label={TranslationService.get(
						'currencies.account.currency.chip.tooltip',
						undefined,
						{ currency: currency?.currency },
					)}
				>
					<Chip
						flag={currency?.currency}
						label={currency?.currency}
						onClick={onFilter}
						active={!!isActive}
						size="sm"
						analyticsKey="list.currency.filter"
					/>
				</Tooltip>
			</ListCell>
			<CurrencyExposure currency={currency} />
			<ListCell
				className={cn(
					'asteria-currency-cell',
					'asteria-currency-cell-currency-risk',
					{ 'asteria-state-risk': currency?.risk?.alert },
				)}
			>
				{currency?.risk?.display?.total !== 0 && (
					<>
						{currency?.currency !== companyCurrency &&
							currency?.risk?.alert && (
								<Tooltip>
									<Button
										icon="alert"
										onClick={riskAction}
										href={TranslationService.get(
											'currencies.account.missing.helpurl',
										)}
										target="_blank"
									/>
									<Text>
										{TranslationService.get(
											'currencies.risk.alert',
											// eslint-disable-next-line spellcheck/spell-checker
											'Ditt företag har en större valutarisk i {{currency.currency}}. Klicka för att få hjälp med hur du effektivt kan hantera din valutarisk',
											{ currency },
										)}
									</Text>
								</Tooltip>
							)}
						<Text className={cn('asteria-text-risk')} size="sm">
							{`±${formatNumber(
								currency?.risk?.display?.total || 0,
								false,
								false,
								true,
							)}`}
						</Text>
					</>
				)}
			</ListCell>
			<CurrencyImpact currency={currency} totalImpact={totalImpact} />
			<CurrencyAccount currency={currency} onGetHelp={onGetHelp} />
		</ListItem>
	);
};

CurrencyView.propTypes = {
	className: PropTypes.string,
	totalImpact: PropTypes.number,
	currency: PropTypes.object,
	onClose: PropTypes.func,
	onGetHelp: PropTypes.func,
	onAction: PropTypes.func,
};

const CurrenciesView = (props) => {
	const { className, onClose, onGetHelp, onAction } = props;

	const currencies = useSelector(selectors.currencies);
	const hasRiskAlert = useMemo(() => false); // TODO: Should be defined
	const start = useMemo(() => format(new Date(), 'yyyy-MM-dd'));

	const totalImpact = useMemo(
		() =>
			currencies?.reduce(
				(acc, item) =>
					acc +
					Math.abs(
						(item?.deposit?.display?.total || 0) -
							(item?.withdraw?.display?.total || 0),
					),
				0,
			) || 0,
		[currencies],
	);

	return (
		<List
			className={cn('asteria-component__currency-list', className)}
			size="lg"
			applyTypographySizes={false}
		>
			<ListHeader size="md">
				<ListItem>
					<ListCell className="asteria-currency-cell asteria-currency-cell-currency">
						<Text className="asteria-text" size="sm">
							{TranslationService.get(
								'currencies.currency',
								// eslint-disable-next-line spellcheck/spell-checker
								'Valuta',
							)}
						</Text>
					</ListCell>
					<ListCell className="asteria-currency-cell asteria-currency-cell-exposure">
						<Text className="asteria-text" size="sm">
							{TranslationService.get(
								'currencies.exposure',
								// eslint-disable-next-line spellcheck/spell-checker
								'Exponering',
							)}
						</Text>
					</ListCell>
					<ListCell className="asteria-currency-cell asteria-currency-cell-currency-risk">
						<Text className="asteria-text" size="sm">
							{TranslationService.get(
								'currencies.total.risk',
								// eslint-disable-next-line spellcheck/spell-checker
								'Valutarisk',
							)}
						</Text>
					</ListCell>
					<ListCell className="asteria-currency-cell asteria-currency-cell-currency-balance">
						<Text className="asteria-text" size="sm">
							{TranslationService.get(
								'currencies.currency.balance',
								// eslint-disable-next-line spellcheck/spell-checker
								'Valutanetto',
							)}
						</Text>
					</ListCell>
					<ListCell className="asteria-currency-cell asteria-currency-cell-currency-total">
						<Text className="asteria-text" size="sm">
							{TranslationService.get(
								'currencies.account',
								// eslint-disable-next-line spellcheck/spell-checker
								'Konto',
							)}
						</Text>
					</ListCell>
				</ListItem>
			</ListHeader>
			<ListGroup>
				{(currencies?.length ?? 0) > 1 ? (
					currencies?.map((currency, index) => (
						<CurrencyView
							key={currency?.currency}
							currency={currency}
							totalImpact={totalImpact}
							index={index}
							onClose={onClose}
							onGetHelp={onGetHelp}
							onAction={onAction}
						/>
					))
				) : (
					<ListItem className="asteria-component__list__item--empty">
						<Text className="asteria-list-empty" size="sm">
							{TranslationService.get(
								['drawer.currencies.empty'],
								// eslint-disable-next-line spellcheck/spell-checker
								'Du handlar inte i några andra valutor',
								{ start },
							)}
						</Text>
					</ListItem>
				)}

				{hasRiskAlert ? (
					<Text size="sm">
						{TranslationService.get('currencies.risk.alert.flat')}
					</Text>
				) : null}
			</ListGroup>
		</List>
	);
};

CurrenciesView.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onGetHelp: PropTypes.func,
	onAction: PropTypes.func,
};

const CurrencyList = function AccountsList(props) {
	const {
		onClose,
		onGetHelp = onClose,
		onAction,
		showHeader = false,
	} = props;

	const supportContent = useConfig('fx.support', { deep: true });

	const actions = React.useMemo(
		() => ({ onClose: onClose, onAction: onAction, onGetHelp: onGetHelp }),
		[onAction, onClose, onGetHelp],
	);

	const msgBoxToggle = React.useMemo(
		() => ({
			icon: 'help',
			iconActive: 'help-close',
			size: 'sm',
			analyticsKey: 'currencies.message.box.toggle.button',
		}),
		[],
	);

	return (
		<Wrapper className="asteria-component__currencies" scroll>
			{showHeader ? (
				<Header onClose={onClose}>
					{TranslationService.get('company.overview.title')}
				</Header>
			) : null}
			<Content scroll>
				<MessageBoxToggle
					toggle={msgBoxToggle}
					analyticsKey="currencies.message.box.toggle"
				>
					<Content>
						{supportContent?.length ? (
							<Contenter
								content={supportContent}
								actions={actions}
							/>
						) : (
							<Text>
								{TranslationService.get(
									'currencies.info',
									'Help text for currencies',
								)}
							</Text>
						)}
					</Content>
				</MessageBoxToggle>

				<CurrenciesView
					onClose={onClose}
					onGetHelp={onGetHelp}
					onAction={onAction}
				/>
			</Content>
			{onClose ? (
				<Footer>
					<FooterSection position="last">
						<Button
							variant="tertiary"
							label={TranslationService.get('button.close')}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			) : null}
		</Wrapper>
	);
};

CurrencyList.propTypes = {
	onClose: PropTypes.func,
	onGetHelp: PropTypes.func,
	onAction: PropTypes.func,
	showHeader: PropTypes.bool,
};

const CurrencyModal = (props) => {
	const { open, onClose, onAction } = props;

	return (
		<Modal open={open} onClose={onClose} size="lg">
			<CurrencyList onClose={onClose} onAction={onAction} showHeader />
		</Modal>
	);
};

CurrencyModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

export default CurrencyList;
export { CurrencyModal };
