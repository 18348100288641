import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { get, isEqual, merge } from 'lodash-es';

const local = JSON.parse(localStorage.getItem('asteriaConfig') ?? '{}');

const selectors = {
	config: createSelector(
		(store) => store?.app?.config ?? {},
		() => window?.__ASTERIA_CONFIG,
		(remote, fn = (config) => config) => fn(merge({}, remote, local)) ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

export const selector = createSelector(
	selectors.config,
	(_, options) => options ?? {},
	(config, options) => {
		const { key, defaultValue = null, deep } = options;

		if (!key) {
			return config ?? defaultValue;
		}

		const keys = [].concat(key);

		let value = null;

		for (const $key of keys.reverse()) {
			if (deep) {
				value = get(config, $key, null);
			}

			if (!value && config?.[$key]) {
				value = config[$key];
			}

			if (value) {
				break;
			}
		}

		return value ?? defaultValue;
	},
	{
		memoizeOptions: {
			equalityCheck: (a, b) => isEqual(a, b),
			resultEqualityCheck: (a, b) => isEqual(a, b),
		},
	},
);

/**
 * @template TResponse
 * @param { string | string[] } key
 * @param { { deep?: boolean, defaultValue?: unknown, callback?: <TValue = unknown>(value: TValue) => TValue } } [options]
 * @returns { TResponse }
 */
export default function useConfig(key, options = {}) {
	const { defaultValue, deep = false, callback } = options;

	return useSelector(
		(store) => {
			const value = selector(store, {
				deep: deep,
				key: key,
				defaultValue: defaultValue,
			});

			if (callback) {
				return callback?.(value);
			}

			return value;
		},
		(a, b) => isEqual(a, b),
	);
}
