import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import List, { ListGroup, ListItem } from '@asteria/component-list';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const ClientDetailsInfoItem = (props) => {
	const { className, label, value } = props;

	return (
		<ListItem
			className={cn(
				'asteria-component__client-overview__details__info__item',
				className,
			)}
		>
			<div className="asteria-component__client-overview__details__info__item__label">
				<Text size="sm">{label}</Text>
			</div>
			<div className="asteria-component__client-overview__details__info__item__value">
				{typeof value === 'string' ? (
					<Text size="sm">{value}</Text>
				) : (
					value
				)}
			</div>
		</ListItem>
	);
};

ClientDetailsInfoItem.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const ClientDetailsInfo = (props) => {
	const { className, client } = props;

	const values = [
		{
			label: TranslationService.get(
				[
					'client.overview.details.info.organization.number',
					`client.overview.details.${client?.type}.info.organization.number`,
				],
				undefined,
				{ client: client },
			),
			value: client?.info?.orgNumber,
		},
		{
			label: TranslationService.get(
				[
					'client.overview.details.info.client.number',
					`client.overview.details.${client?.type}.info.client.number`,
				],
				undefined,
				{ client: client },
			),
			value: client?.meta?.clientNumber,
		},
		{
			label: TranslationService.get(
				[
					'client.overview.details.info.city',
					`client.overview.details.${client?.type}.info.city`,
				],
				undefined,
				{ client: client },
			),
			value: client?.contact?.general?.city,
		},
		{
			label: TranslationService.get(
				[
					'client.overview.details.info.status',
					`client.overview.details.${client?.type}.info.status`,
				],
				undefined,
				{ client: client },
			),
			value: client?.info?.orgNumber ? (
				<Button
					size="sm"
					variant="link"
					href={`https://www.allabolag.se/${client?.info?.orgNumber?.replaceAll(
						'-',
						'',
					)}`}
					label={TranslationService.get(
						[
							'client.overview.details.info.status.value',
							client?.active
								? `client.overview.details.info.status.value.active`
								: `client.overview.details.info.status.value.inactive`,
							`client.overview.details.${client?.type}.info.status.value`,
							client?.active
								? `client.overview.details.${client?.type}.info.status.value.active`
								: `client.overview.details.${client?.type}.info.status.value.inactive`,
						],
						undefined,
						{ client: client },
					)}
					target="_blank"
				/>
			) : null,
		},
		{
			label: TranslationService.get(
				[
					'client.overview.details.info.address',
					`client.overview.details.${client?.type}.info.address`,
				],
				undefined,
				{ client: client },
			),
			value: client?.contact?.general?.street,
		},
		{
			label: TranslationService.get(
				[
					'client.overview.details.phone',
					`client.overview.details.${client?.type}.phone`,
				],
				undefined,
				{ client: client },
			),
			value: client?.contact?.general?.phone,
		},
	];
	return (
		<div
			className={cn(
				'asteria-component__client-overview__details__info',
				className,
			)}
		>
			<List size="lg">
				{values.map(({ label, value }) =>
					value ? (
						<ListGroup key={label}>
							<ClientDetailsInfoItem
								label={label}
								value={value}
							/>
						</ListGroup>
					) : null,
				)}
			</List>
		</div>
	);
};

ClientDetailsInfo.propTypes = {
	className: PropTypes.string,
	client: PropTypes.object,
};

export default ClientDetailsInfo;
