import AsteriaCore from '@asteria/core';

import handleBulk, { defaultCallback } from './handleBulk';
import instance from './instance';
import { withLocalStorage } from './utils';

AsteriaCore.HTTP.graphql = withLocalStorage(async (options) => {
	const { uri, query, variables, headers, dataloader, isBulk, signal } =
		options;

	if (dataloader) {
		const fn = dataloader?.fn ?? dataloader;
		const ctx = dataloader?.context;
		const onSuccess = dataloader?.onSuccess;

		return fn.execute(options, ctx).then(onSuccess);
	}

	if (isBulk) {
		return handleBulk(options);
	}

	return instance({
		url: uri,
		method: 'POST',
		data: { query: query, variables: variables },
		headers: headers,
		signal: signal,
	});
});

export default AsteriaCore.HTTP;
export { defaultCallback as defaultBulkCallback };
