import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
	Auth2FADeactivateModal,
	Auth2FASetupModal,
	Auth2FAVerifyModal,
} from '@asteria/view-auth';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import useModal from '@asteria/component-core/utils/useModal';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const User2FAContent = (props) => {
	const { onActivate, onDeactivate, onView } = props;

	const user = useSelector(AppStore.selectors.user);

	const active = user?.authRecord?.twoAuth?.active ?? null;

	if (!user?.authRecord) {
		return null;
	}

	if (!active) {
		return (
			<div
				className={cn(
					'asteria-component__section',
					'asteria--type-user-2fa',
				)}
			>
				<Group direction="horizontal" verticalAlign="top">
					<div className="asteria-component__section-content">
						<Icon icon="lock" />
					</div>
					<div className="asteria-component__section-content">
						<Title size="sm">
							{TranslationService.get([
								'settings.user.2fa.title',
								'settings.user.2fa.inactive.title',
							])}
						</Title>
						<Text>
							{TranslationService.get([
								'settings.user.2fa.content',
								'settings.user.2fa.inactive.content',
							])}
							.
						</Text>
						<Button
							variant="link"
							label={TranslationService.get([
								'settings.user.2fa.action.activate',
								'settings.user.2fa.inactive.action.activate',
							])}
							onClick={onActivate}
						/>
					</div>
				</Group>
			</div>
		);
	}

	return (
		<div
			className={cn(
				'asteria-component__section',
				'asteria--type-user-2fa',
			)}
		>
			<Group direction="horizontal" verticalAlign="top">
				<div className="asteria-component__section-content">
					<Icon icon="lock" />
				</div>
				<div className="asteria-component__section-content">
					<Title size="sm">
						{TranslationService.get([
							'settings.user.2fa.title',
							'settings.user.2fa.active.title',
						])}
					</Title>
					<TextGroup>
						<Text>
							{TranslationService.get([
								'settings.user.2fa.content',
								'settings.user.2fa.active.content',
							])}
						</Text>
						<Button
							variant="link"
							label={TranslationService.get([
								'settings.user.2fa.action.view',
								'settings.user.2fa.active.action.view',
							])}
							onClick={onView}
						/>
					</TextGroup>
					<Button
						variant="link"
						label={TranslationService.get([
							'settings.user.2fa.action.deactivate',
							'settings.user.2fa.active.action.deactivate',
						])}
						onClick={onDeactivate}
					/>
				</div>
			</Group>
		</div>
	);
};

User2FAContent.displayName = 'User2FAContent';

User2FAContent.propTypes = {
	onActivate: PropTypes.func,
	onView: PropTypes.func,
	onDeactivate: PropTypes.func,
};

const Setup2FA = React.memo((props) => {
	const { onAction, onSubmit, open, onClose } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	const handleSuccess = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	return (
		<Auth2FASetupModal
			open={open}
			onAction={onAction}
			onSubmit={onSubmit}
			onClose={handleClose}
			onSuccess={handleSuccess}
			showHeaderClose
		/>
	);
});

Setup2FA.displayName = 'UserSetup2FA';
Setup2FA.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

const Verify2FA = React.memo((props) => {
	const { onAction, onSubmit, open, onClose } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	const handleSuccess = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	return (
		<Auth2FAVerifyModal
			open={open}
			onAction={onAction}
			onSubmit={onSubmit}
			onClose={handleClose}
			onSuccess={handleSuccess}
			showHeaderClose
		/>
	);
});

Verify2FA.displayName = 'UserVerify2FA';
Verify2FA.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

const Deactivate2FA = React.memo((props) => {
	const { onAction, onSubmit, open, onClose } = props;

	const handleClose = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	const handleSuccess = React.useCallback(
		(event) => onClose?.(event),
		[onClose],
	);

	return (
		<Auth2FADeactivateModal
			open={open}
			onAction={onAction}
			onSubmit={onSubmit}
			onClose={handleClose}
			onSuccess={handleSuccess}
			showHeaderClose
		/>
	);
});

Deactivate2FA.displayName = 'UserDeactivate2FA';
Deactivate2FA.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

const User2FA = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const [isSetupOpen, { onOpen: onSetupOpen, onClose: onSetupClose }] =
		useModal();

	const [isVerifyOpen, { onOpen: onVerifyOpen, onClose: onVerifyClose }] =
		useModal();

	const [
		isDeactivateOpen,
		{ onOpen: onDeactivateOpen, onClose: onDeactivateClose },
	] = useModal();

	const onActivate = React.useCallback(
		(event) => onSetupOpen?.(event),
		[onSetupOpen],
	);

	const onDeactivate = React.useCallback(
		(event) => onDeactivateOpen?.(event),
		[onDeactivateOpen],
	);

	const onView = React.useCallback(
		(event) => onVerifyOpen?.(event),
		[onVerifyOpen],
	);

	const has2FAFeature = useFeature('auth-2fa');

	if (!has2FAFeature) {
		return null;
	}

	return (
		<>
			<Setup2FA
				onAction={onAction}
				onSubmit={onSubmit}
				open={isSetupOpen}
				onClose={onSetupClose}
			/>
			<Verify2FA
				onAction={onAction}
				onSubmit={onSubmit}
				open={isVerifyOpen}
				onClose={onVerifyClose}
			/>
			<Deactivate2FA
				onAction={onAction}
				onSubmit={onSubmit}
				open={isDeactivateOpen}
				onClose={onDeactivateClose}
			/>
			<User2FAContent
				onActivate={onActivate}
				onDeactivate={onDeactivate}
				onView={onView}
			/>
		</>
	);
});

User2FA.displayName = 'User2FA';
User2FA.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default User2FA;
