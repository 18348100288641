import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import * as utils from '../utils';

export * as reports from './reports';

export const countryCodes = createSelector(
	(store) => store?.invoices?.country?.codes ?? {},
	(value) => value,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const selected = createSelector(
	(store) => store?.invoices?.selected ?? [],
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const statistics = createSelector(
	selected,
	(selected) => utils.formatStatistics(selected),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isSelected = createSelector(
	(store, ID) =>
		(store?.invoices?.selected ?? []).some(
			(value) => utils.getId(value) === ID,
		),
	(singleSelected) => singleSelected,
);

export const filters = createSelector(
	(store) => store?.invoices?.filters ?? [],
	(_, options = {}) => options,
	(filters, { type, value }) => {
		let objects = filters;

		if (type) {
			objects = objects.filter((object) => object?.type === type);
		}

		if (value) {
			objects = objects.filter((object) => isEqual(object?.value, value));
		}

		return objects;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const batches = createSelector(
	(store) => store?.invoices?.batches ?? [],
	(_, options = {}) => options,
	(objects, options) => {
		let items = objects;

		if (options?.status) {
			items = items.filter(
				(object) => object?.status === options?.status,
			);
		}

		if (options?.id) {
			const id = [].concat(options?.id);

			items = items.filter((object) => id.includes(object?.id));
		}

		return items;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const layouts = createSelector(
	(store) => store?.invoices?.layouts ?? [],
	(_, options = {}) => options,
	(objects, options) => {
		let items = objects;

		if (options?.status) {
			const status = [].concat(options?.status);

			items = items.filter((object) => status.includes(object?.status));
		}

		if (options?.id) {
			const id = [].concat(options?.id);

			items = items.filter((object) => id.includes(utils.getId(object)));
		}

		return items;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const batch = createSelector(
	(store) => batches(store),
	(_, ID) => ID,
	(objects, ID) => objects.find((object) => object?.id === ID),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const search = createSelector(
	(store) => store?.invoices?.search ?? '',
	(value) => value,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
