import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import Group from '@asteria/component-core/group';
import ProbabilityBar from '@asteria/component-core/probability';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, Title } from '@asteria/component-core/typography';

import Contenter from '@asteria/component-tools/contenter';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { useAnalyticsData } from '@asteria/utils-funcs/analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useStateHidden, useStateInvisible } from './hooks';
import { findIntersect, toZero } from './utils';

import './line.scss';

const Spread = React.memo((props) => {
	const {
		className,
		id,
		yMax,
		yPrevMax,
		yMin,
		yPrevMin,
		yValue,
		yPrevValue,
		maxValue,
		minValue,
		value,
		tooltip = false,
		credit,

		zero,
		layout,
	} = props;

	const dispatch = useDispatch();

	const hasSpreadFeature = useFeature('graph-spread-info');
	const hasSpreadFactionFeature = useFeature('graph-spread-faction');

	const spreadTooltipConfig = useConfig('graph.spread.tooltip');

	const hiddenState = useStateHidden({ layout });
	const invisibleState = useStateInvisible({ layout });

	const [faction, setFaction] = useState(0);

	const analyticsDataRef = useAnalyticsData({
		date: id,
		minValue: minValue,
		maxValue: maxValue,
		value: value,
	});

	const handleClick = React.useCallback(() => {
		if (!hasSpreadFeature) {
			return;
		}

		Analytics.event('graph.spread.click', analyticsDataRef.current);

		dispatch(
			ModalStore.open({ type: ModalStore.MODAL_WINDOWS.GraphSpread }),
		);
	}, [analyticsDataRef, dispatch, hasSpreadFeature]);

	const setSpreadFraction = React.useCallback(
		(e) => {
			const box = e.target.getBoundingClientRect();

			const width = box.width;
			const height = box.height;

			const clientY = Math.min(Math.max(e.clientY - box.y, 0), height);
			const clientX = Math.min(Math.max(e.clientX - box.x, 0), width);

			const multiplierX = clientX / width;
			const multiplierY = clientY / height;

			const min = Math.min(yPrevMax, yPrevMin, yMax, yMin);
			const max = Math.max(yPrevMax, yPrevMin, yMax, yMin);

			const valueY = yPrevValue - (yPrevValue - yValue) * multiplierX;
			const topY = yPrevMax - (yPrevMax - yMax) * multiplierX;
			const bottomY = yPrevMin - (yPrevMin - yMin) * multiplierX;

			const cursorY = Math.min(
				Math.max(min + (max - min) * multiplierY, topY),
				bottomY,
			);

			const size = Math.abs(cursorY - valueY);

			let value = null;

			if (cursorY > valueY) {
				value = -size / Math.abs(bottomY - valueY);
			} else {
				value = size / Math.abs(topY - valueY);
			}

			if (Number.isNaN(value)) {
				value = null;
			}

			setFaction(value);
		},
		[yMax, yMin, yPrevMax, yPrevMin, yPrevValue, yValue],
	);

	const normalizedFaction = faction?.toFixed?.(1);
	const steppedFaction = Math.ceil((faction * 100) / 10) * 10;
	const absSteppedFaction = Math.abs(steppedFaction);

	let spreadValue = value + (maxValue - value) * (steppedFaction / 100);

	if (normalizedFaction < 0) {
		spreadValue = value - (value - minValue) * (absSteppedFaction / 100);
	}

	const ContenterData = React.useMemo(
		() => ({
			spread: spreadValue,
			value: value,
			minValue,
			maxValue,
			credit: credit,
			probability: 1 - Math.abs(normalizedFaction),
		}),
		[credit, maxValue, minValue, normalizedFaction, spreadValue, value],
	);

	const points = React.useMemo(() => {
		let normal = [],
			negative = [];

		const zeroPrevMax = toZero(yPrevMax, zero);
		const zeroPrevMin = toZero(yPrevMin, zero);
		const zeroMax = toZero(yMax, zero);
		const zeroMin = toZero(yMin, zero);

		if (
			zero >= yPrevMax &&
			zero >= yMax &&
			zero >= yMin &&
			zero >= yPrevMin
		) {
			normal = [
				`0,${yPrevMax}`,
				`100,${yMax}`,
				`100,${yMin}`,
				`0,${yPrevMin}`,
			];
		} else if (
			zero <= yPrevMax &&
			zero <= yMax &&
			zero <= yMin &&
			zero <= yPrevMin
		) {
			negative = [
				`0,${yPrevMax}`,
				`100,${yMax}`,
				`100,${yMin}`,
				`0,${yPrevMin}`,
			];
		} else if (
			zero >= yPrevMax &&
			zero >= yMax &&
			zero <= yMin &&
			zero <= yPrevMin
		) {
			normal = [
				`0,${yPrevMax}`,
				`100,${yMax}`,
				`100,${zero}`,
				`0,${zero}`,
			];

			negative = [
				`0,${zero}`,
				`100,${zero}`,
				`100,${yMin}`,
				`0,${yPrevMin}`,
			];
		} else if (zeroPrevMax * zeroMax < 0 && zeroPrevMin * zeroMin < 0) {
			const intersectMax = findIntersect(zeroPrevMax, zeroMax);
			const intersectMin = findIntersect(zeroPrevMin, zeroMin);

			if (zeroPrevMin < 0) {
				normal = [
					`100,${yMax}`,
					`${intersectMax},${zero}`,
					`${intersectMin},${zero}`,
					`100,${yMin}`,
				];

				negative = [
					`0,${yPrevMax}`,
					`${intersectMax},${zero}`,
					`${intersectMin},${zero}`,
					`0,${yPrevMin}`,
				];
			} else {
				normal = [
					`0,${yPrevMax}`,
					`${intersectMax},${zero}`,
					`${intersectMin},${zero}`,
					`0,${yPrevMin}`,
				];

				negative = [
					`100,${yMax}`,
					`${intersectMax},${zero}`,
					`${intersectMin},${zero}`,
					`100,${yMin}`,
				];
			}
		} else if (zeroPrevMax * zeroMax < 0) {
			const intersectMax = findIntersect(zeroPrevMax, zeroMax);

			if (zero > yPrevMax) {
				normal = [
					`0,${yPrevMax}`,
					`${intersectMax},${zero}`,
					`0,${zero}`,
				];

				negative = [
					`${intersectMax},${zero}`,
					`100,${yMax}`,
					`100,${yMin}`,
					`0,${yPrevMin}`,
					`0,${zero}`,
				];
			} else {
				normal = [
					`${intersectMax},${zero}`,
					`100,${yMax}`,
					`100,${zero}`,
				];
				negative = [
					`0,${yPrevMax}`,
					`${intersectMax},${zero}`,
					`100,${zero}`,
					`100,${yMin}`,
					`0,${yPrevMin}`,
				];
			}
		} else if (zeroPrevMin * zeroMin < 0) {
			const intersectMin = findIntersect(zeroPrevMin, zeroMin);

			if (zero > yPrevMin) {
				normal = [
					`0,${yPrevMax}`,
					`100,${yMax}`,
					`100,${zero}`,
					`${intersectMin},${zero}`,
					`0,${yPrevMin}`,
				];

				negative = [
					`${intersectMin},${zero}`,
					`100,${zero}`,
					`100,${yMin}`,
				];
			} else {
				normal = [
					`0,${yPrevMax}`,
					`100,${yMax}`,
					`100,${yMin}`,
					`${intersectMin},${zero}`,
					`0,${zero}`,
				];
				negative = [
					`0,${zero}`,
					`${intersectMin},${zero}`,
					`0,${yPrevMin}`,
				];
			}
		} else {
			normal = [
				`0,${yPrevMax}`,
				`100,${yMax}`,
				`100,${yMin}`,
				`0,${yPrevMin}`,
			];
		}

		return {
			normal: normal.filter(Boolean).join(' '),
			negative: negative.filter(Boolean).join(' '),
		};
	}, [yMax, yMin, yPrevMax, yPrevMin, zero]);

	const defaultStyles = React.useMemo(() => ({ cursor: 'pointer' }), []);

	if (yPrevMax === yPrevMin && yMax === yMin) {
		return null;
	}

	return (
		<svg
			pointerEvents="none"
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			shapeRendering="crispEdges"
			className={cn('asteria-graph-line__spread', className)}
		>
			<defs>
				<pattern
					id="spread-normal-pattern"
					width="8"
					height="10"
					patternUnits="userSpaceOnUse"
					patternTransform="rotate(45)"
				>
					<rect
						height="10"
						width="8"
						className="asteria-color__spread-normal"
					/>
					<line
						y2="10"
						x1="1"
						x2="1"
						className="asteria-color__spread-normal-stripe"
					/>
					<line
						y2="10"
						x1="5"
						x2="5"
						className="asteria-color__spread-normal-stripe"
					/>
				</pattern>
				<pattern
					id="spread-negative-pattern"
					width="8"
					height="10"
					patternUnits="userSpaceOnUse"
					patternTransform="rotate(45)"
				>
					<rect
						height="10"
						width="8"
						className="asteria-color__spread-negative"
					/>
					<line
						y2="10"
						x1="1"
						x2="1"
						className="asteria-color__spread-negative-stripe"
					/>
					<line
						y2="10"
						x1="5"
						x2="5"
						className="asteria-color__spread-negative-stripe"
					/>
				</pattern>
			</defs>
			<TooltipWrapper
				placement="mouse"
				tooltip={
					tooltip && hasSpreadFactionFeature && faction !== null ? (
						<Group
							direction="vertical"
							verticalAlign="center"
							className="asteria-graph-spread-tooltip-wrapper"
						>
							<div className="asteria-graph-spread-tooltip-header">
								<Title align="center">
									{TranslationService.get(
										'graph.spread.title',
										'Prognos bokfört saldo',
										{
											spread: spreadValue,
											value: value,
										},
									)}
								</Title>
								<Text
									className="asteria-graph-spread-tooltip-value"
									align="center"
								>
									{TranslationService.get(
										'graph.spread.value',
										'{{ value | number }}',
										{
											spread: spreadValue,
											value: value,
										},
									)}
								</Text>
							</div>
							<Contenter
								content={spreadTooltipConfig}
								data={ContenterData}
							/>
							<Group direction="vertical" verticalAlign="center">
								<div className="asteria-graph-spread-tooltip-line">
									<ProbabilityBar
										label
										type="graph.spread"
										labelPosition="first"
										className="asteria-component__probability__spread"
										probability={
											1 - Math.abs(normalizedFaction)
										}
									/>
									<Text className="asteria-graph-spread-tooltip-spread-line-text">
										{TranslationService.get(
											'graph.spread.value.value',
											'{{spread | number }}',
											{
												spread: spreadValue,
												value: value,
											},
										)}
									</Text>
								</div>
								{credit ? (
									<div
										className="asteria-graph-spread-tooltip-line"
										direction="horizontal"
									>
										<Text className="asteria-graph-spread-tooltip-line-title asteria-graph-spread-tooltip-credit-line-title">
											{TranslationService.get(
												'graph.spread.credit.title',
												'Beviljad kredit',
												{
													spread: spreadValue,
													value: value,
													credit: credit,
												},
											)}
										</Text>
										<Text className="asteria-graph-spread-tooltip-spread-line-text asteria-graph-spread-tooltip-credit-line-text">
											{TranslationService.get(
												'graph.spread.credit.value',
												'{{credit | number: false: false: true }}',
												{
													spread: spreadValue,
													value: value,
													credit: credit,
												},
											)}
										</Text>
									</div>
								) : null}
							</Group>
							<Text
								align="center"
								className="asteria-graph-spread-tooltip-info"
							>
								{TranslationService.get(
									'graph.spread.info',
									'Click to see more information about spread',
									{
										spread: spreadValue,
										value: value,
										credit: credit,
									},
								)}
							</Text>
						</Group>
					) : null
				}
			>
				<g>
					{points['normal'] ? (
						<polygon
							points={points['normal']}
							pointerEvents={tooltip ? 'all' : 'none'}
							onMouseMove={setSpreadFraction}
							style={defaultStyles}
							onClick={handleClick}
							fill="url(#spread-normal-pattern)"
							className={cn({
								'asteria--state-hidden':
									hiddenState?.spread?.positive &&
									(invisibleState?.spread?.positive ?? true),
								'asteria--state-invisible':
									invisibleState?.spread?.positive,
							})}
						/>
					) : null}

					{points['negative'] ? (
						<polygon
							points={points['negative']}
							pointerEvents={tooltip ? 'all' : 'none'}
							onMouseMove={setSpreadFraction}
							style={defaultStyles}
							onClick={handleClick}
							fill="url(#spread-negative-pattern)"
							className={cn({
								'asteria--state-hidden':
									hiddenState?.spread?.negative &&
									(invisibleState?.spread?.negative ?? true),
								'asteria--state-invisible':
									invisibleState?.spread?.negative,
							})}
						/>
					) : null}
				</g>
			</TooltipWrapper>
		</svg>
	);
});

Spread.displayName = 'Spread';

export default Spread;
