import React from 'react';

import { useSelector } from 'react-redux';

import {
	compareAsc,
	differenceInDays,
	isBefore,
	parseISO,
	subMonths,
} from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import './styles.scss';

``;

/**
 * @typedef ChildrenOptions
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { { postfix: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useIntegrationOutdated() {
	const available = useSelector(
		(store) => {
			const erp = IntegrationStore.selectors
				.integrations(store, {
					type: 'erp',
					filters: [{ status: 'IDLE', disabled: false }],
				})
				.filter((object) =>
					isBefore(
						parseISO(object.lastSync),
						subMonths(new Date(), 1),
					),
				);

			const bank = IntegrationStore.selectors
				.integrations(store, {
					type: 'bank',
					filters: [{ status: 'IDLE', disabled: false }],
				})
				.filter((object) =>
					isBefore(
						parseISO(object.lastSync),
						subMonths(new Date(), 1),
					),
				);

			return [].concat(erp).concat(bank);
		},
		(a, b) => isEqual(a, b),
	);

	return available;
}

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & { integrations: unknown[], type: 'erp' | 'bank' }> } */
const OnboardingOutdatedTitle = React.memo(function OnboardingOutdatedTitle({
	integrations: $integrations,
	type,
	translationOptions,
	onAction,
}) {
	const integrations = React.useMemo(
		() =>
			$integrations
				.sort((a, b) =>
					compareAsc(parseISO(a?.lastSync), parseISO(b?.lastSync)),
				)
				.map((object) => ({
					...object,
					days: differenceInDays(
						new Date(),
						parseISO(object.lastSync),
					),
				})),
		[$integrations],
	);

	const handleClick = React.useCallback(() => {
		onAction?.('card:action', {
			type: 'onboarding-outdated',
			integrations: integrations,
		});
	}, [integrations, onAction]);

	if (!integrations?.length) {
		return null;
	}

	if (integrations?.length === 1) {
		const integration = integrations?.[0];

		const integrationName = TranslationService.get([
			'integration.title',
			`integration.title.${integration?.key}`,
			`integration.title.${integration?.type}.${integration?.key}`,
			`integration.${integration?.key}.title`,
			`integration.${integration?.type}.${integration?.key}.title`,
		]);

		return (
			<Text className="asteria-component__onboarding-outdated-line">
				{TranslationService.getV2(
					[
						'card.content.title',
						'card.content.title.onboarding-outdated',
						'card.content.title.onboarding-outdated.single',
					],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-key': integration?.key,
							'integration-type': integration?.type,
						},
						data: {
							...translationOptions?.data,
							integration: integration,
							integrationName: integrationName,
						},
					},
				)}
				<Button
					onClick={handleClick}
					size="md"
					label={TranslationService.getV2(
						['card.content.onboarding-outdated.action'],
						{
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								'integration-key': integration?.key,
								'integration-type': integration?.type,
							},
							data: {
								...translationOptions?.data,
								integration: integration,
								integrationName: integrationName,
							},
						},
					)}
					variant="link"
				/>
				{TranslationService.getV2(
					[
						'card.content.title.last.onboarding-outdated',
						'card.content.title.last.onboarding-outdated.single',
					],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-key': integration?.key,
							'integration-type': integration?.type,
						},
						data: {
							...translationOptions?.data,
							integration: integration,
							integrationName: integrationName,
						},
					},
				)}
			</Text>
		);
	}

	return (
		<Text className="asteria-component__onboarding-outdated-line">
			{TranslationService.getV2(
				[
					'card.content.title',
					'card.content.title.onboarding-outdated',
					'card.content.title.onboarding-outdated.multiple',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integrations: {
							first: integrations?.[0],
							last: integrations?.[integrations?.length - 1],
						},
					},
				},
			)}
			<Button
				onClick={handleClick}
				size="md"
				label={TranslationService.getV2(
					['card.content.onboarding-outdated.action'],
					{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'integration-type': type,
						},
						data: {
							...translationOptions?.data,
							integrations: {
								first: integrations?.[0],
								last: integrations?.[integrations?.length - 1],
							},
						},
					},
				)}
				variant="link"
			/>
			{TranslationService.getV2(
				[
					'card.content.title.last.onboarding-outdated',
					'card.content.title.last.onboarding-outdated.multiple',
				],
				{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						'integration-type': type,
					},
					data: {
						...translationOptions?.data,
						integrations: {
							first: integrations?.[0],
							last: integrations?.[integrations?.length - 1],
						},
					},
				},
			)}
		</Text>
	);
});

OnboardingOutdatedTitle.propTypes = {
	translationOptions: PropTypes.object,
	type: PropTypes.oneOf(['erp', 'bank']),
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & { integrations: unknown[], visible: boolean }> } */
const OnboardingLoadingTitle = React.memo(function OnboardingLoadingTitle({
	visible = true,
	integrations,
	translationOptions,
	onAction,
}) {
	const erpOutdated = integrations.filter(({ type }) => type === 'erp');
	const bankOutdated = integrations.filter(({ type }) => type === 'bank');

	if (!visible) {
		return null;
	}

	return [
		<OnboardingOutdatedTitle
			key="onboarding-erp-outdated"
			type="erp"
			integrations={erpOutdated}
			translationOptions={translationOptions}
			onAction={onAction}
		/>,
		<OnboardingOutdatedTitle
			key="onboarding-bank-outdated"
			type="bank"
			integrations={bankOutdated}
			translationOptions={translationOptions}
			onAction={onAction}
		/>,
	];
});

OnboardingLoadingTitle.propTypes = {
	translationOptions: PropTypes.object,
	visible: PropTypes.bool,
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

export default OnboardingLoadingTitle;
export { useIntegrationOutdated };
