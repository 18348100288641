import React from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Button from '@asteria/component-core/button';
import Slider from '@asteria/component-core/slider';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Input from '../input';

import './index.scss';

const Search = (props) => {
	const {
		className,
		name,
		onChange,
		onClear,
		onFocus,
		placeholder,
		debounce,
		children,
		size = 'medium',
		hasFocus,

		value: defaultValue,
		analyticsKey,
	} = props;

	const { resetField, setValue } = useFormContext();

	const ref = React.useRef(null);

	React.useEffect(() => {
		if (hasFocus === false) {
			ref.current.blur();
		}
	}, [hasFocus]);

	const value = useWatch({ name: name });

	React.useEffect(() => {
		if (value !== defaultValue) {
			setValue(name, defaultValue);
		}
	}, [defaultValue]);

	const handleSearchClick = React.useCallback(() => {
		ref.current.focus();
	}, []);

	const handleClear = React.useCallback(
		(event) => {
			Analytics.event('form.search.clear', {
				name: name,
				analyticsKey: analyticsKey || name,
			});

			resetField(name);
			return onClear?.(event);
		},
		[analyticsKey, name, onClear, resetField],
	);

	const SliderUpdate = React.useMemo(() => [hasFocus], [hasFocus]);

	const Children = React.Children.toArray(children);

	return (
		<Input
			name={name}
			icon="magnifier"
			iconPosition="first"
			placeholder={placeholder}
			debounce={debounce}
			className={cn(
				'asteria-component__input__search',
				{ 'asteria--state-filled': Children?.length },
				className,
			)}
			size={size}
			onIconClick={handleSearchClick}
			onChange={onChange}
			iconTooltip={{
				tooltip: TranslationService.get('search.icon.tooltip'),
				variant: 'alt',
			}}
			onFocus={onFocus}
			ref={ref}
		>
			{Children?.length ? (
				<Slider noScroll updateBy={SliderUpdate}>
					{Children}
				</Slider>
			) : null}
			{Children?.length || value?.length ? (
				<Button
					className="asteria-component__input__search__clear"
					icon="close"
					size="xs"
					type="reset"
					tooltip={{
						tooltip: TranslationService.get(
							'search.form.clear.tooltip',
						),
						variant: 'alt',
					}}
					onClick={handleClear}
					analyticsKey="search.close"
				/>
			) : null}
		</Input>
	);
};

Search.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	size: SizeProp(),
	debounce: PropTypes.number,
	name: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	hasFocus: PropTypes.bool,
	onClear: PropTypes.func,
	children: PropTypes.node,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	analyticsKey: PropTypes.string,
};

Search.defaultProps = {};

export default Search;
