import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Icon from '@asteria/component-core/icon';
import Tooltip, { TooltipWrapper } from '@asteria/component-core/tooltip';
import {
	positionClasses,
	sizeClasses,
	stateClasses,
	statusClasses,
} from '@asteria/component-core/utils';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useCombinedRefs from '@asteria/utils-hooks/useCombinedRefs';

import { withRegister } from '../base/controlled';
import Error from '../base/error';
import Label from '../base/label';

import './styles.scss';

const Checkbox = React.memo(
	React.forwardRef((props, ref) => {
		const {
			active,
			disabled,
			className,
			size,
			icon = 'check',
			iconActive = icon,
			iconPosition = 'first',
			onChange,
			error,
			floatingError,
			analyticsKey,
			stopPropagation,
			direction,
			tooltip,
			label,

			...input
		} = props;

		const inputRef = React.useRef(null);
		const combinedRef = useCombinedRefs(ref, inputRef);

		const [internalValue, setInternalValue] = React.useState(
			input?.checked ?? active ?? false,
		);

		React.useLayoutEffect(() => {
			setInternalValue(inputRef?.current?.checked);
		}, []);

		React.useEffect(() => {
			if (active !== undefined) {
				setInternalValue(active);
			}
		}, [active]);

		React.useEffect(() => {
			if (input?.checked !== undefined) {
				setInternalValue(input?.checked);
			}
		}, [input?.checked]);

		const innerRef = React.useRef(null);

		const handleClick = React.useCallback(
			(event) => {
				if (stopPropagation) {
					event.stopPropagation();
				}
			},
			[stopPropagation],
		);

		const handleChange = React.useCallback(
			(event) => {
				Analytics.event('form.checkbox.change', {
					value: event.target.value,
					checked: event.target.checked,
					className: className,
					name: event.target.name,
					analyticsKey:
						analyticsKey || event.target.name || className,
				});

				setInternalValue(event.target.checked);

				return onChange?.(event);
			},
			[className, analyticsKey, onChange],
		);

		return (
			<div
				className={cn(
					'asteria-component__checkbox',
					className,
					sizeClasses(props),
					stateClasses({
						...props,
						disabled: disabled,
						active: internalValue,
						checked: internalValue,
						error: error,
					}),
					statusClasses({
						info: error?.type === 'info',
						success: error?.type === 'success',
						error:
							error?.type === 'required' ||
							error?.type === 'error',
						warning: error?.type === 'warning',
					}),
				)}
				onClick={handleClick}
			>
				<label
					className={cn(
						className,
						'asteria-component__label__wrapper',
						{
							[`asteria-component__label__wrapper--direction-${direction}`]:
								direction,
						},
					)}
				>
					<TooltipWrapper tooltip={tooltip}>
						<div
							className="asteria-component__checkbox__inner"
							ref={innerRef}
						>
							<Label
								size={size}
								disabled={disabled}
								active={active}
							>
								{label}
							</Label>

							<input
								type="checkbox"
								disabled={disabled}
								onChange={handleChange}
								ref={combinedRef}
								{...input}
								checked={internalValue}
							/>

							<Icon
								className={cn(
									'asteria-component__checkbox__icon',
									positionClasses({ position: iconPosition }),
								)}
								size={size}
								icon={internalValue ? iconActive : icon}
							/>
						</div>
					</TooltipWrapper>
				</label>
				{error && floatingError && (
					<Tooltip target={innerRef} open>
						<div className="asteria-component__checkbox-error">
							{error && <Error error={error} />}
						</div>
					</Tooltip>
				)}
				{!floatingError && (
					<div className="asteria-component__checkbox-error">
						{error && <Error error={error} />}
					</div>
				)}
			</div>
		);
	}),
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
	uncontrolled: PropTypes.bool,

	className: PropTypes.string,

	active: PropTypes.bool,
	disabled: PropTypes.bool,
	size: SizeProp(),
	icon: PropTypes.string,
	iconChecked: PropTypes.string,
	iconActive: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),
	name: PropTypes.string,
	onChange: PropTypes.func,
	fieldProps: PropTypes.object,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	floatingError: PropTypes.bool,
	value: PropTypes.any,
	checked: PropTypes.any,
	analyticsKey: PropTypes.string,
	stopPropagation: PropTypes.bool,
	direction: PropTypes.oneOf(['horizontal', 'vertical']),
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	label: PropTypes.string,
};

Checkbox.defaultProps = { icon: 'check', size: 'md' };

const Component = withRegister(Checkbox);

ComponentService.register('Checkbox', Component, {
	uncontrolled: { type: 'boolean' },

	className: { type: 'string' },

	active: { type: 'boolean' },
	disabled: { type: 'boolean' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	icon: { type: 'string' },
	iconChecked: { type: 'string' },
	iconActive: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },
	name: { type: 'string' },
	onChange: { type: 'function' },
	fieldProps: { type: 'object' },
	error: {
		type: 'object',
		options: {
			type: { type: 'string' },
			message: { type: 'string' },
		},
	},
	floatingError: { type: 'boolean' },
	value: { type: 'object' },
	checked: { type: 'boolean' },
	analyticsKey: { type: 'string' },
	stopPropagation: { type: 'boolean' },
	direction: { type: 'enum', options: ['horizontal', 'vertical'] },
	tooltip: { type: 'string' },
	label: { type: 'string' },
});

export default Component;
export { Checkbox };
