import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import { merge } from 'lodash-es';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

export const isActionVisible = createSelector(
	(store) => store?.app?.user?.settings?.flags?.['welcome:actions'],
	(store) => store?.app?.company?.settings?.flags?.['welcome:actions'],
	(_, name) => name,
	(user, company, name) => !user?.[name] && !company?.[name],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const userQuestions = createSelector(
	(store) => store?.app?.user?.settings?.flags?.['welcome:questions'],
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const companyQuestions = createSelector(
	(store) => store?.app?.company?.questions,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const progress = createSelector(
	(store) => store?.app?.user?.settings?.flags?.['welcome:questions'],
	(store) => store?.app?.company?.questions,
	(_, name) => name,
	(user, company, name) =>
		user?.[name]?.progress ?? company?.[name]?.progress ?? 0,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const data = createSelector(
	(store) => store?.app?.user?.settings?.flags?.['welcome:questions'],
	(store) => store?.app?.company?.questions,
	(_, name) => name,
	(user, company, name) => user?.[name]?.data ?? company?.[name]?.data ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const completedActions = createSelector(
	(store) => store?.app?.user?.settings?.flags?.['welcome:actions'],
	(store) => store?.app?.company?.settings?.flags?.['welcome:actions'],
	(user, company) => merge({}, user ?? {}, company ?? {}),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isCompleted = createSelector(
	(store) => store?.app?.user?.settings?.flags?.['welcome:questions'] ?? {},
	(store) => store?.app?.company?.questions ?? {},
	IntegrationStore.selectors.integrations,
	(_, { context }) => context,
	(user, company, integrations, context) => {
		const wizard = context?.wizard ?? {};

		const actions = (context?.actions ?? []).reduce((acc, object) => {
			const variant = object?.variant;
			const key = object?.key;
			const feature = object?.feature;
			const type = object?.props?.type;
			const wizardName = object?.props?.wizard;

			if (!acc[variant]) {
				acc[variant] = {};
			}

			if (!feature || FeatureService.isActive(feature)) {
				if (variant === 'integration') {
					const hasConnectedIntegrations = integrations.some(
						(object) =>
							object?.type === type && object?.config?.connected,
					);

					acc[variant][key] = hasConnectedIntegrations;
				}

				if (variant === 'wizard') {
					const progress =
						user?.[key]?.progress ?? company?.[key]?.progress ?? 0;
					const steps =
						(wizard?.[wizardName]?.steps?.length ?? 0) +
						!!wizard?.[wizardName]?.done;

					acc[variant][key] = progress === steps;
				}
			}

			return acc;
		}, {});

		const flags = Object.values(actions).flatMap((object) =>
			Object.values(object),
		);

		return flags.length && flags.every((value) => value);
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isWelcomeShown = createSelector(
	(store) => store?.app?.user?.settings?.flags?.welcome,
	(store) => store?.app?.company?.settings?.flags?.welcome,
	(user, company) => !!user || !!company,
);
