import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as AccountStore from '@asteria/datalayer/stores/accounts';

import { cn } from '@asteria/utils-funcs/classes';

import Loader from './loader';
import SectionFooter from './section/footer';
import SectionGraph from './section/graph';
import SectionHeader from './section/header';
import SectionTeaser from './section/teaser';
import Updater from './updater';
import LeaveWrapper from './wrapper/leave';

import './styles.scss';

const CreditPage = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const dispatch = useDispatch();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'credit:set') {
				dispatch(AccountStore.setDynamicCredit(data));

				return;
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);

	return (
		<div
			className={cn(
				'asteria-component__financial-page',
				'asteria--variant-credit',
			)}
		>
			<LeaveWrapper>
				<Updater />
				<Loader />
				<FeatureFlag feature="financial-v1" invert>
					<SectionHeader
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
				</FeatureFlag>
				<SectionGraph onAction={handleAction} onSubmit={onSubmit} />
				<SectionTeaser onAction={handleAction} onSubmit={onSubmit} />
				<SectionFooter onAction={handleAction} onSubmit={onSubmit} />
			</LeaveWrapper>
		</div>
	);
});

CreditPage.displayName = 'CreditPage';
CreditPage.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default CreditPage;
