import React from 'react';

import Button from '@asteria/component-core/button';
import Floater from '@asteria/component-core/float';

import { TranslationService } from '@asteria/language';

import FeedbackModal from './FormModal';
import FeedbackSuccessModal from './SuccessModal';

import './styles.scss';

export function useGenericProperties(options = {}) {
	const { onSubmit } = options;

	const [{ state, loading, value }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'OPEN':
					return {
						...state,
						state: 'OPEN',
						value: null,
						loading: false,
					};

				case 'SUBMIT':
					return { ...state, loading: true };

				case 'RATE':
					return { ...state, value: action?.payload };

				case 'DONE':
					return {
						...state,
						value: null,
						state: 'DONE',
						loading: false,
					};

				case 'CLOSE':
					return {
						...state,
						value: null,
						state: null,
						loading: false,
					};

				default:
					return state;
			}
		},
		{ state: null, loading: false, value: null },
	);

	const onFeedbackOpen = React.useCallback(() => {
		dispatch({ type: 'OPEN' });
	}, []);

	const onFeedbackClose = React.useCallback(() => {
		dispatch({ type: 'CLOSE' });
	}, []);

	const onFeedbackSubmit = React.useCallback(
		async (form) => {
			dispatch({ type: 'SUBMIT' });

			const key = [
				'generic',
				new Date().toISOString().split('.')[0],
			].join('::');

			await onSubmit?.({ feedbackKey: key, ...form });

			dispatch({ type: 'DONE' });
		},
		[onSubmit],
	);

	const FeedbackRating = React.useMemo(
		() => ({
			value: value,
			minText: TranslationService.get([
				'feedback.rating.min',
				'feedback.rating.generic.min',
			]),
			maxText: TranslationService.get([
				'feedback.rating.max',
				'feedback.rating.generic.max',
			]),
			onChange: (value) => {
				dispatch({ type: 'RATE', payload: value });
			},
		}),
		[value],
	);

	return React.useMemo(
		() => ({
			state: state,
			loading: loading,
			onOpen: onFeedbackOpen,
			onClose: onFeedbackClose,
			onSubmit: onFeedbackSubmit,
			rating: FeedbackRating,
		}),
		[
			FeedbackRating,
			loading,
			onFeedbackClose,
			onFeedbackOpen,
			onFeedbackSubmit,
			state,
		],
	);
}

const FloatingFeedbackButton = React.memo((props) => {
	const { onAction, icon = 'heart' } = props;

	const handleFeedbackSubmit = React.useCallback(
		(data) => onAction?.('updateFeedback', data),
		[onAction],
	);

	const { state, loading, onOpen, onClose, onSubmit, rating } =
		useGenericProperties({ onSubmit: handleFeedbackSubmit });

	return [
		<FeedbackModal
			key="feedback-modal"
			open={state === 'OPEN'}
			type="generic"
			onClose={onClose}
			onSubmit={onSubmit}
			hideContactUs
			loading={loading}
			rating={rating}
		/>,
		<FeedbackSuccessModal
			key="feedback-success-modal"
			open={state === 'DONE'}
			type="generic"
			onClose={onClose}
		/>,
		<Floater
			key="floating-button"
			horizontalAlign="right"
			verticalAlign="bottom"
			position="fixed"
		>
			<Button
				icon={icon}
				className="asteria-component__feedback-button"
				variant="primary"
				onClick={onOpen}
			/>
		</Floater>,
	];
});

FloatingFeedbackButton.propTypes = {};
FloatingFeedbackButton.displayName = 'FloatingFeedbackButton';

export default FloatingFeedbackButton;
