import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

export const TYPES = {
	Forecaster: 'forecaster',
	FinancialCredit: 'financial:credit',
	Cashflow: 'cashflow',
};

const initialState = { current: null, zIndex: null };

export const slice = createSlice({
	name: 'tour',
	initialState: initialState,
	reducers: {
		show: (state, action) => {
			state.current = action.payload?.type ?? null;
			state.zIndex = action.payload?.zIndex ?? null;
		},
		hide: (state) => {
			state.current = null;
			state.zIndex = null;
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { show, hide } = slice.actions;

export const selectors = {
	current: createSelector(
		(state) => state?.tour?.current,
		(value) => value ?? null,
	),
	zIndex: createSelector(
		(state) => state?.tour?.zIndex,
		(value) => value ?? null,
	),
};

export default slice.reducer;
