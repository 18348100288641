import React from 'react';

import { useFormContext } from 'react-hook-form';

import { addMonths, format, parseISO } from 'date-fns';

import {
	findParentByClassname,
	findScrollingParent,
} from '@asteria/utils-funcs/node';

function getInitialDates() {
	return [
		format(new Date(), 'yyyy-MM-01'),
		format(addMonths(new Date(), 1), 'yyyy-MM-01'),
	];
}

export function useScrollingDates({ ref, types, direction }) {
	const [activeDates, setActiveDates] = React.useState(getInitialDates);

	React.useEffect(() => {
		setActiveDates(getInitialDates);
	}, [direction]);

	const { getValues } = useFormContext();

	const increase = React.useCallback(
		(options) => {
			const force = options?.force;

			const formDates = Object.keys(
				getValues(types.map((type) => [type, 'data'].join('.'))).reduce(
					(acc, value) => ({ ...acc, ...value }),
					{},
				) ?? {},
			).reduce(
				(acc, key, index, array) => ({
					...acc,
					[key]: array[index + 1],
				}),
				{},
			);

			if (force) {
				setActiveDates((dates) =>
					dates.concat(
						format(
							addMonths(parseISO(dates.slice(-1)[0]), 1),
							'yyyy-MM-01',
						),
					),
				);
			} else {
				setActiveDates((dates) => {
					const lastDate = dates.slice(-1)[0];

					if (formDates[lastDate]) {
						return dates.concat(
							format(
								addMonths(parseISO(dates.slice(-1)[0]), 1),
								'yyyy-MM-01',
							),
						);
					}

					return dates;
				});
			}
		},
		[getValues, types],
	);

	React.useLayoutEffect(() => {
		function onUpdate(entries) {
			for (const entry of entries) {
				const node = entry.target;

				const clientHeight = node.clientHeight;
				const scrollHeight = node.scrollHeight;

				if (scrollHeight <= clientHeight) {
					increase();
				}
			}
		}

		if (!ref.current || direction === 'vertical') {
			return;
		}

		const node = ref.current;

		const observer = new ResizeObserver(onUpdate);
		observer.observe(node);

		return () => {
			observer.unobserve(node);
		};
	}, [direction, increase, ref]);

	const handleScroll = React.useCallback(
		(event) => {
			const node = event.target;

			const clientHeight = node.clientHeight;
			const scrollHeight = node.scrollHeight;
			const scrollTop = node.scrollTop;

			if (scrollHeight - clientHeight - 40 * 10 <= scrollTop) {
				increase({ force: true });
			}
		},
		[increase],
	);

	React.useLayoutEffect(() => {
		let node = findScrollingParent(ref?.current) ?? ref?.current;

		if (node && !node.isSameNode(ref?.current)) {
			const parent = findParentByClassname(
				ref?.current,
				'asteria-component__modal',
			);

			if (parent) {
				node = parent.querySelector(
					'.asteria-component__wrapper > .asteria-component__wrapper-content',
				);
			}
		}

		if (node) {
			node.addEventListener('scroll', handleScroll);

			return () => {
				node.removeEventListener('scroll', handleScroll);
			};
		}
	}, [ref, types, direction, handleScroll]);

	return React.useMemo(() => ({ dates: activeDates }), [activeDates]);
}
