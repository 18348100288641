import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import {
	useInitiatingIntegration,
	useOnboardingState,
	useOnboardingStatus,
	useRequiredIntegrations,
} from '../../hooks';
import BasicCard from '../basic';

import OnboardingErrorTitle, { useIntegrationError } from './error';
import OnboardingLoadingTitle, { useIntegrationLoading } from './loading';
import OnboardingOutdatedTitle, { useIntegrationOutdated } from './outdated';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { { postfix: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

/** @type { React.FC<ChildrenOptions> } */
const OnboardingTitle = React.memo(function OnboardingTitle(props) {
	const { translationOptions, loading, onAction } = props;

	const integrations = useIntegrationLoading();
	const integrationsError = useIntegrationError();
	const integrationsOutdated = useIntegrationOutdated();

	if (
		integrations.length ||
		integrationsError.length ||
		integrationsOutdated.length
	) {
		return [
			<OnboardingLoadingTitle
				key="onboarding-loading-title"
				integrations={integrations}
				translationOptions={translationOptions}
			/>,
			<OnboardingErrorTitle
				key="onboarding-error-title"
				integrations={integrationsError}
				translationOptions={translationOptions}
				onAction={onAction}
			/>,
			<OnboardingOutdatedTitle
				key="onboarding-outdated-title"
				integrations={integrationsOutdated}
				visible={!integrationsError.length}
				translationOptions={translationOptions}
				onAction={onAction}
			/>,
		];
	}

	return (
		<Translation
			translationKey="card.content.title"
			translationOptions={translationOptions}
			showWhenEmpty={false}
			Component={Title}
			loading={loading}
		/>
	);
});

OnboardingTitle.propTypes = {
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	onAction: PropTypes.func,
};

/** @type { React.FC<ChildrenOptions> } */
const OnboardingContent = React.memo(function OnboardingContent(props) {
	const { translationOptions, loading } = props;

	return (
		<Translation
			translationKey="card.content.text"
			translationOptions={translationOptions}
			showWhenEmpty={false}
			Component={Text}
			loading={loading}
		/>
	);
});

OnboardingContent.propTypes = {
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
};

const CONFIG = {
	title: (args) => <OnboardingTitle {...args} />,
	text: (args) => <OnboardingContent {...args} />,
};

/** @type { React.FC<Props> } */
const OnboardingCard = React.memo(function OnboardingCard(props) {
	const { required, optional } = useRequiredIntegrations({
		optional: 'both',
	});

	const initiating = useInitiatingIntegration({ type: 'bank' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	const onboarding = useOnboardingState();
	const status = useOnboardingStatus({ type: null });

	const promotion = status === 'none';
	const visible = !(onboarding === 'both' && status === 'connected');

	const config = React.useMemo(
		() => ({
			...CONFIG,
			trends: false,
			promotion: promotion,
			visible: visible,
		}),
		[promotion, visible],
	);

	return (
		<BasicCard
			{...props}
			type="onboarding"
			integrations={integrations}
			config={config}
		/>
	);
});

OnboardingCard.displayName = 'OnboardingCard';

OnboardingCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default OnboardingCard;
