import React from 'react';

import { useSelector } from 'react-redux';
import { useRouteError } from 'react-router-dom';

import Error from '@asteria/component-error';

import * as AppStore from '@asteria/datalayer/stores/app';

import { report } from '../api/support';

const ErrorBoundary = React.memo(() => {
	const accessToken = useSelector(AppStore.selectors.accessToken);

	const error = useRouteError();

	const onSubmit = React.useCallback(
		(input) => report({ accessToken: accessToken, input: input }),
		[accessToken],
	);

	return <Error onSubmit={onSubmit} message={error.message} />;
});

ErrorBoundary.displayName = 'ErrorBoundary';
ErrorBoundary.propTypes = {};

export default ErrorBoundary;
