import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Icon from '@asteria/component-core/icon';
import Tooltip, { TooltipWrapper } from '@asteria/component-core/tooltip';
import {
	positionClasses,
	sizeClasses,
	stateClasses,
	statusClasses,
} from '@asteria/component-core/utils';

import ComponentService from '@asteria/component-tools/contenter/service';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import ControlledWrapper from '../base/controlled';
import Error from '../base/error';
import Label from '../base/label';

import './styles.scss';

const Radio = React.memo(
	React.forwardRef((props, ref) => {
		const {
			active,
			disabled,
			className,
			size,
			icon,
			iconPosition = 'first',

			name,
			onChange,
			fieldProps = {},
			error,
			floatingError,
			value,
			defaultValue,

			analyticsKey,

			stopPropagation,

			direction,
			tooltip,
			label,
		} = props;

		const innerRef = React.useRef(null);

		const handleClick = React.useCallback(
			(event) => {
				if (stopPropagation) {
					event.stopPropagation();
				}
			},
			[stopPropagation],
		);

		const handleChange = React.useCallback(
			(event) => {
				Analytics.event('form.radio.change', {
					checked: event.target.checked,
					name: event.target.name,
					value: value,
					className: className,
					analyticsKey:
						analyticsKey || event.target.name || className,
				});

				onChange?.(event);
			},
			[value, className, analyticsKey, onChange],
		);

		return (
			<div
				className={cn(
					'asteria-component__radio',
					className,
					sizeClasses(props),
					stateClasses({ ...props, error: error }),
					statusClasses({
						info: error?.type === 'info',
						success: error?.type === 'success',
						error:
							error?.type === 'required' ||
							error?.type === 'error',
						warning: error?.type === 'warning',
					}),
				)}
				onClick={handleClick}
			>
				<label
					className={cn(
						className,
						'asteria-component__label__wrapper',
						{
							[`asteria-component__label__wrapper--direction-${direction}`]:
								direction,
						},
					)}
				>
					<TooltipWrapper tooltip={tooltip}>
						<div
							className="asteria-component__radio__inner"
							ref={innerRef}
						>
							<Label
								size={size}
								disabled={disabled}
								active={active}
							>
								{label}
							</Label>

							<input
								type="radio"
								value={defaultValue}
								disabled={disabled}
								checked={
									value?.toString?.() ===
									defaultValue?.toString?.()
								}
								name={name}
								onChange={handleChange}
								{...fieldProps}
								ref={ref}
							/>

							{icon ? (
								<Icon
									className={cn(
										'asteria-component__radio__icon',
										positionClasses({
											position: iconPosition,
										}),
									)}
									size={size}
									icon={icon}
								/>
							) : (
								<span
									className={cn(
										'asteria-component__radio__icon',
										positionClasses({
											position: iconPosition,
										}),
									)}
								/>
							)}
						</div>
					</TooltipWrapper>
				</label>
				{error && floatingError && (
					<Tooltip target={innerRef} open>
						<div className="asteria-component__radio-error">
							{error && <Error error={error} />}
						</div>
					</Tooltip>
				)}
				{!floatingError && (
					<div className="asteria-component__radio-error">
						{error && <Error error={error} />}
					</div>
				)}
			</div>
		);
	}),
);

Radio.displayName = 'Radio';

Radio.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	size: SizeProp(),
	icon: PropTypes.string,
	iconPosition: PropTypes.oneOf(['first', 'last']),

	name: PropTypes.string,
	onChange: PropTypes.func,
	fieldProps: PropTypes.object,
	error: PropTypes.shape({
		type: PropTypes.string,
		message: PropTypes.string,
	}),
	floatingError: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

	analyticsKey: PropTypes.string,

	stopPropagation: PropTypes.bool,

	direction: PropTypes.oneOf(['horizontal', 'vertical']),
	tooltip: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
	label: PropTypes.string,
};

Radio.defaultProps = {
	size: 'md',
};

const Component = ControlledWrapper(Radio);

ComponentService.register('Radio', Component, {
	active: { type: 'boolean' },
	disabled: { type: 'boolean' },
	className: { type: 'string' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	icon: { type: 'string' },
	iconPosition: { type: 'enum', options: ['first', 'last'] },

	name: { type: 'string' },
	onChange: { type: 'function' },
	fieldProps: { type: 'object' },
	error: {
		type: 'object',
		options: {
			type: { type: 'string' },
			message: { type: 'string' },
		},
	},
	floatingError: { type: 'boolean' },
	value: { type: 'string' },
	defaultValue: { type: 'string' },

	analyticsKey: { type: 'string' },

	stopPropagation: { type: 'boolean' },

	direction: { type: 'enum', options: ['horizontal', 'vertical'] },
	tooltip: { type: 'string' },
	label: { type: 'string' },
});

export default Component;
export { Radio };
