import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as TourStore from '@asteria/datalayer/stores/tour';

import { cn } from '@asteria/utils-funcs/classes';
import { useTourKey, useTourShowAction } from '@asteria/utils-hooks/tour';

import { SizeProp } from '../../../PropTypes';
import Button from '../../../button';
import { useLoading } from '../../hooks';

import '../styles.scss';

/** @type { React.FC<Partial<{ size: string, tourKey: string | string[], loading: boolean, forceShow: boolean }>> } */
const TourButton = React.memo(function TourButton(props) {
	const { size, tourKey, loading: $loading, forceShow } = props;

	const loading = useLoading($loading);

	const showTourImmediatelyFeature = useFeature(
		`modal-show-tour-immediately`,
	);

	const dispatch = useDispatch();

	const key = useTourKey(tourKey);

	useTourShowAction(tourKey, {
		skip: !showTourImmediatelyFeature,
		zIndex: 'calc(var(--z-index__modal) + 1)',
	});

	const handleClick = React.useCallback(() => {
		if (key) {
			dispatch(
				TourStore.show({
					type: key,
					zIndex: 'calc(var(--z-index__modal) + 1)',
				}),
			);
		}
	}, [dispatch, key]);

	if (!forceShow && !key) {
		return null;
	}

	return (
		<Button
			icon="help"
			variant="help"
			onClick={handleClick}
			size={size}
			analyticsKey="wrapper.header.help"
			className={cn(
				'asteria-component__wrapper-header-action',
				'asteria--variant-tour',
				{ 'asteria--state-loading': loading },
			)}
		/>
	);
});

TourButton.displayName = 'TourButton';

TourButton.propTypes = {
	size: SizeProp(),
	tourKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	loading: PropTypes.bool,
	forceShow: PropTypes.bool,
};

export default TourButton;
