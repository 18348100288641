import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { useFormValues } from '@asteria/component-form';

import { cn } from '@asteria/utils-funcs/classes';

import { useTagContent } from '../../hooks';
import Context from '../../logic/context';

import { useNavigation } from './hooks';

import './styles.scss';

const SectionInformationContent = React.memo((props) => {
	const { type, category, tag } = props;

	const { history } = React.useContext(Context);

	const { getValues } = useFormContext();

	const content = useTagContent({
		type: type,
		category: category,
		tag: tag,

		useForm: useFormValues,
		useStaticForm: getValues,

		history: history,

		key: 'page.category.information.content',
		props: { size: 'sm' },
	});

	return <>{content}</>;
});

SectionInformationContent.displayName = 'SectionInformationContent';
SectionInformationContent.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

const SectionInformation = React.memo((props) => {
	const { variant } = props;

	const { type, category, tag, level } = useNavigation({ variant });

	if (variant === 'action' || level === 0) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-information',
			)}
		>
			<SectionInformationContent
				type={type}
				category={category}
				tag={tag}
			/>
		</div>
	);
});

SectionInformation.displayName = 'SectionInformation';
SectionInformation.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
};

export default SectionInformation;
