import { createSlice } from '@reduxjs/toolkit';
import { subYears } from 'date-fns';

import { ACTIONS } from '../../constants';
import { setUser, setUserSettings, setUserSettingsFlags } from '../app';
import { setFeatures } from '../features';

import { STATES, initialState } from './constants';

export * as selectors from './selectors';
export * as constants from './constants';
export * as utils from './utils';

export const transactionsSlice = createSlice({
	name: 'transactions',
	initialState: initialState,
	reducers: {
		setActive: (state, { payload }) => {
			state.active = payload;
		},
		setSelected: (state, { payload }) => {
			if (payload === 'ALL') {
				state.selected = Object.keys(state.items).reduce(
					(acc, key) => ({ ...acc, [key]: true }),
					{},
				);
			} else if (payload === 'NONE') {
				state.selected = {};
			} else {
				state.selected[payload] = !state.selected[payload];
			}
		},
		setState: (state, action) => {
			if (
				action.payload === null ||
				Object.values(STATES).some((value) => value === action.payload)
			) {
				state.state = action.payload;
			}
		},
		setItems: (state, action) => {
			state.items = (action.payload ?? []).reduce((acc, object) => {
				const ID = object?._id ?? object?.id;

				if (acc[ID]) {
					if (acc[ID].updatedAt !== object.updatedAt) {
						acc[ID] = object;
					}
				} else {
					acc[ID] = object;
				}

				return acc;
			}, state.items);
		},
		removeItems: (state, action) => {
			for (const ID of action.payload) {
				delete state.items[ID];
			}
		},
		reset: (state, { payload }) => {
			state.items = {};

			if (payload?.full) {
				state.active = null;
				state.selected = {};
			}
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
		[setFeatures]: (state, { payload }) => {
			if (payload?.['cashflow-transactions-state-open']) {
				state.state = STATES.All;
			}
		},
		[setUser]: (state, { payload }) => {
			if (!state?.timestamp) {
				if (payload?.settings?.flags?.transactions) {
					state.timestamp = payload?.settings?.flags?.transactions;
				} else {
					state.timestamp = subYears(new Date(), 3).toISOString();
				}
			}
		},
		[setUserSettings]: (state, { payload }) => {
			if (!state?.timestamp) {
				if (payload?.flags?.transactions) {
					state.timestamp = payload?.flags?.transactions;
				} else {
					state.timestamp = subYears(new Date(), 3).toISOString();
				}
			}
		},
		[setUserSettingsFlags]: (state, { payload }) => {
			if (!state?.timestamp) {
				if (payload?.transactions) {
					state.timestamp = payload?.transactions;
				} else {
					state.timestamp = subYears(new Date(), 3).toISOString();
				}
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setActive,
	setSelected,
	reset,
	setState,
	setItems,
	removeItems,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
