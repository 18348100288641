import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import TableRow from './TableRow';

const TableHeader = React.memo(
	React.forwardRef((props, ref) => {
		const { className } = props;

		return (
			<TableRow
				{...props}
				className={cn('asteria-component__table-header', className)}
				ref={ref}
			/>
		);
	}),
);

TableHeader.displayName = 'TableHeader';

TableHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default TableHeader;
