import { useCallback, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore } from 'react-redux';

import { compareAsc, format, parseISO } from 'date-fns';

import { useManualFormValues } from '@asteria/component-form';

import addTag from '../../../../utils/addTag';
import { recalculate as recalculateTag } from '../../../../utils/adjustTag';
import {
	move as moveTransaction,
	remove as removeTransaction,
} from '../../../../utils/adjustTransactions';
import applyFormChanges from '../../../../utils/applyFormChanges';
import getFormPaths, { getPath } from '../../../../utils/getFormPaths';
import validateState from '../../../../utils/validateState';
import validateTag from '../../../../utils/validateTag';

export function useData(options) {
	const { type, types = [type], date, category, tag } = options;

	const total = useManualFormValues({
		name: types.map((type) =>
			getPath({
				type: type,
				date: date,
				category: category,
				tag: tag,
				field: 'value',
			}),
		),
	});

	return useMemo(() => {
		if (types?.length > 1) {
			return { total: total[0] - total[1] };
		}

		return { total: total[0] };
	}, [total, types?.length]);
}

export function useTransactions({ types, date, category, tag }) {
	const form = useManualFormValues({
		name: []
			.concat(types)
			.map((type) =>
				getPath({ type, date, status: 'FORECAST', category, tag }),
			),
	}).reduce((acc, object, index) => ({ ...acc, [types[index]]: object }), {});

	const transactions = Object.entries(form)
		.flatMap(([type, object]) => {
			if (object?.transactions) {
				return Object.entries(object?.transactions ?? {})
					.filter(([, value]) => !value?.$deleted)
					.map(([uuid, object]) => ({
						type: type,
						date: date,
						category: category,
						tag: tag,
						uuid: uuid,
						paymentDate: object?.paymentDate,
					}));
			}

			if (object?.tags) {
				return Object.entries(object?.tags).flatMap(([tag, object]) =>
					Object.entries(object?.transactions ?? {})
						.filter(([, value]) => !value?.$deleted)
						.map(([uuid, object]) => ({
							type: type,
							date: date,
							category: category,
							tag: tag,
							uuid: uuid,
							paymentDate: object?.paymentDate,
						})),
				);
			}

			if (object?.categories) {
				return Object.entries(object?.categories).flatMap(
					([category, object]) =>
						Object.entries(object?.tags ?? {}).flatMap(
							([tag, object]) =>
								Object.entries(object?.transactions ?? {})
									.filter(([, value]) => !value?.$deleted)
									.map(([uuid, object]) => ({
										type: type,
										date: date,
										category: category,
										tag: tag,
										uuid: uuid,
										paymentDate: object?.paymentDate,
									})),
						),
				);
			}

			return [];
		})
		.sort((a, b) => {
			const source = a?.paymentDate;
			const target = b?.paymentDate;

			if (!source && !target) {
				return 0;
			}

			if (!source) {
				return -1;
			}

			if (!target) {
				return 1;
			}

			return compareAsc(
				source instanceof Date ? source : parseISO(source),
				target instanceof Date ? target : parseISO(target),
			);
		});

	return transactions.map((object) =>
		getPath({
			type: object?.type,
			date: object?.date,
			category: object?.category,
			tag: object?.tag,
			uuid: object?.uuid,
		}),
	);
}

export function useTransactionAction(onAction) {
	const store = useStore();
	const dispatch = useDispatch();

	const { getValues, setValue, unregister, reset } = useFormContext();

	return useCallback(
		(action, data) => {
			if (action === 'transaction:update') {
				const { name, value, prev } = data;
				const { date, type, category, tag, uuid } = getFormPaths(name);

				onAction?.('value:update', {
					name,
					value,
					prev,
					skipApply: true,
				});

				const form = getValues();

				let changes = [];

				if (name.endsWith('.paymentDate')) {
					const sourceDate = format(parseISO(prev), 'yyyy-MM-01');
					const targetDate = format(parseISO(value), 'yyyy-MM-01');

					const options = {
						type: type,
						category: category,
						tag: tag,
					};

					changes = changes.concat(
						moveTransaction({
							source: {
								...options,
								date: sourceDate,
								uuid: uuid,
							},
							destination: { ...options, date: targetDate },
							form: form,
						}),
					);

					changes = changes
						.concat(
							recalculateTag({
								...options,
								date: sourceDate,
								form: form,
							}),
						)
						.concat(
							recalculateTag({
								...options,
								date: targetDate,
								form: form,
							}),
						);
				} else if (name.endsWith('.$category')) {
					const [targetCategory, ...$targetTag] = value.split('-');
					const targetTag = $targetTag.join('-');

					addTag({
						category: targetCategory,
						tag: targetTag,
						dispatch,
					});

					const options = { type: type, date: date };

					changes = changes.concat(
						moveTransaction({
							source: {
								...options,
								category: category,
								tag: tag,
								uuid: uuid,
							},
							destination: {
								...options,
								category: targetCategory,
								tag: targetTag,
							},
							form: form,
						}),
					);

					changes = changes.concat(
						recalculateTag({
							...options,
							category: category,
							tag: tag,
							form: form,
						}),
					);

					changes = changes.concat(
						recalculateTag({
							...options,
							category: targetCategory,
							tag: targetTag,
							form: form,
						}),
					);
				} else {
					changes = changes.concat(
						recalculateTag({
							type: type,
							date: date,
							category: category,
							tag: tag,
							form: form,
						}),
					);
				}

				changes = changes.concat(
					validateState({
						form: form,
						type: type,
						category: category,
						tag: tag,
					}),
				);

				applyFormChanges({
					changes,
					setValue,
					getValues,
					unregister,
					reset,
					event: 'transaction:update',
				});

				if (name.endsWith('.$category')) {
					const state = store.getState();

					// Move from one category to another
					const [targetCategory, ...$targetTag] = value.split('-');
					const targetTag = $targetTag.join('-');

					const isExistingTag = (state?.forecaster?.tags ?? []).some(
						(object) =>
							object?.type === type &&
							object?.categoryName === targetCategory &&
							object?.tagName === targetTag,
					);

					validateTag({
						type: type,
						category: category,
						tag: tag,
						dispatch: dispatch,
						getValues: getValues,
						event: {
							type: 'MOVE',
							data: {
								isExistingTag: isExistingTag,
								total: (changes ?? []).find(
									({ $event }) =>
										$event === 'transaction:move',
								)?.value?.total,
								source: {
									type,
									date,
									category,
									tag,
									uuid,
								},
								destination: {
									type,
									date,
									category: targetCategory,
									tag: targetTag,
								},
							},
						},
					});
				}

				return true;
			}

			if (action === 'transaction:remove') {
				const { name } = data;
				const { date, type, category, tag, uuid } = getFormPaths(name);

				const form = getValues();

				let changes = [];

				changes = changes.concat(
					removeTransaction({
						type,
						date,
						category,
						tag,
						uuid,
						form,
					}),
				);

				changes = changes.concat(
					recalculateTag({
						type,
						date,
						category,
						tag,
						form,
					}),
				);

				changes = changes.concat(
					validateState({
						form: form,
						type: type,
						category: category,
						tag: tag,
					}),
				);

				validateTag({
					type: type,
					category: category,
					tag: tag,
					dispatch: dispatch,
					getValues: getValues,
				});

				applyFormChanges({
					changes,
					setValue,
					getValues,
					unregister,
					reset,
					event: 'transaction:remove',
				});

				return true;
			}
		},
		[dispatch, getValues, onAction, reset, setValue, store, unregister],
	);
}
