import React from 'react';

import { useFeature } from '@asteria/component-tools/featureflag';

import { LayoutContext } from '../context';

const DemoNotifications = React.memo(() => {
	const hasDemoFeature = useFeature('notifications-demo');
	const hasCreditDemoFeature = useFeature('demo.notifications.credit');
	const hasOverdueDemoFeature = useFeature('demo.notifications.overdue');
	const hasCurrencyDemoFeature = useFeature('demo.notifications.currency');
	const hasDemoDialogsFeature = useFeature('action-dialog-test');
	const hasForecastDemoFeature = useFeature('demo.notifications.forecaster');

	const { onSubmit } = React.useContext(LayoutContext);

	React.useEffect(() => {
		onSubmit?.('notification:fetch');
	}, [
		hasDemoDialogsFeature,
		hasDemoFeature,
		hasCreditDemoFeature,
		hasOverdueDemoFeature,
		hasCurrencyDemoFeature,
		hasForecastDemoFeature,
		onSubmit,
	]);
});

DemoNotifications.displayName = 'DemoNotifications';

export default DemoNotifications;
