import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';

import {
	formatCategory,
	formatClient,
	formatCurrency,
	formatStatus,
} from './format';

export const clients = createSelector(
	AppStore.selectors.filters,
	AppStore.selectors.clients,
	(filters, value) =>
		value
			.filter(
				(object) =>
					!filters.some(
						(value) => value?.id === (object?._id ?? object?.id),
					),
			)
			.map((object) => formatClient(object)),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const tags = createSelector(
	AppStore.selectors.filters,
	AppStore.selectors.tags,
	(filters, value) =>
		value
			.filter(
				(object) =>
					!filters.some(
						(value) => value?.id === (object?._id ?? object?.id),
					),
			)
			.map((object) => formatCategory(object)),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const currencies = createSelector(
	AppStore.selectors.filters,
	AppStore.selectors.currencies,
	(filters, value) =>
		value
			.filter(
				(object) =>
					!filters.some(
						(value) => value?.id === (object?._id ?? object?.id),
					),
			)
			.map((object) => formatCurrency(object)),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const statuses = createSelector(
	AppStore.selectors.filters,
	AppStore.selectors.statuses,
	(filters, value) =>
		value
			.filter((status) => !filters.some((value) => value?.id === status))
			.map((status) => formatStatus(status)),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const active = createSelector(
	(store) =>
		AppStore.selectors.filters(
			store,
			FeatureService.isActive('graph-legend-categories')
				? { type: 'tag', invert: true }
				: null,
		),
	(value) =>
		value
			.map(({ type, item }) => {
				if (type === 'tag') {
					return formatCategory(item);
				}

				if (type === 'currency') {
					return formatCurrency(item);
				}

				if (type === 'client') {
					return formatClient(item);
				}

				if (type === 'status') {
					return formatStatus(item);
				}

				return null;
			})
			.filter(Boolean),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
