import { TransactionService } from '@asteria/backend-utils-services';

import { setScenarios } from '@asteria/datalayer/stores/scenarios';

import { withPagination } from '@asteria/utils-funcs/request';

/**
 * @typedef { import('@asteria/core').DataLoader } DataLoader
 */

export const FIELDS = `
_id
name
state {
	date
	type
	tag {
		_id
		name
		category {
			_id
			name
		}
	}
	state
}

type
parentId
data

statistics {
	transactions {
		count
		sums {
			total
			currency
		}
		details {
			type
			count
			sums {
				total
				currency
			}
		}
	}
	categories {
		count
		sums {
			total
			currency
		}
	}
}
createdAt
updatedAt

updatedByEntity {
	__typename

	... on User {
		id
		_id
		username
		firstName
		lastName
	}

	... on AdminUser {
		id
		_id
		username
		firstName
		lastName
	}

	... on Integration {
		id
		_id
		type
		key
	}
}
`;

export const DEFAULT_PAGINATION_FIELDS = `
	pageInfo {
		count
		startCursor
		endCursor
		hasNextPage
	}
	edges {
		cursor
		node {
			${FIELDS}
		}
	}
`;

/**
 * @param { { accessToken: string, dataloader: DataLoader, dispatch?: unknown } } options
 */
export async function fetch(options) {
	const { accessToken, dataloader, dispatch } = options;

	const response = await withPagination(TransactionService.scenario.fetch)(
		{
			dataloader: dataloader,
			isBulk: true,
			filters: { active: true },
			fields: DEFAULT_PAGINATION_FIELDS,
		},
		{ token: accessToken },
	);

	dispatch?.(setScenarios(response));

	return response;
}
