import React from 'react';

import { useDispatch, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Search from '@asteria/component-search';
import { useFeature } from '@asteria/component-tools/featureflag';

import {
	resetFilterType,
	setFilters,
	setMode,
	toggleFilter,
} from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const selectors = {
	mode: createSelector(
		(state) => state?.app?.mode,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const CashflowSearch = React.memo((props) => {
	const { onAction, onFocusChange } = props;

	const asteriaSearchSwedbank = useFeature('asteria-search-swedbank');

	const dispatch = useDispatch();
	const store = useStore();

	const handleAction = React.useCallback(
		(action, value) => {
			const state = store.getState();

			const mode = selectors.mode(state);

			if (action === 'toggleService') {
				dispatch(setMode(mode === value.code ? null : value.code));

				onAction?.('go', `/${value.code}`);
			}

			if (
				action === 'toggleTag' ||
				action === 'toggleCurrency' ||
				action === 'toggleClient' ||
				action === 'toggleStatus'
			) {
				dispatch(toggleFilter(value));
			}

			if (action === 'resetType') {
				dispatch(resetFilterType(value));
			}

			if (action === 'resetFilter') {
				dispatch(setFilters([]));
			}

			if (action === 'askForFeedback') {
				onAction?.('askForFeedback', {
					feedbackKey: 'search',
					size: 'small',
					maxText: TranslationService.get('feedback.rating.max'),
					minText: TranslationService.get('feedback.rating.min'),
					values: [1, 2, 3],
				});
			}
		},
		[store, dispatch, onAction],
	);

	return (
		<Search
			className={cn({
				['asteria-search-swedbank']: asteriaSearchSwedbank,
			})}
			onAction={handleAction}
			onFocusChange={onFocusChange}
		/>
	);
});

CashflowSearch.displayName = 'CashflowSearch';

CashflowSearch.propTypes = {
	onAction: PropTypes.func,
	onFocusChange: PropTypes.func,
};

export default CashflowSearch;
