import { useMemo } from 'react';

import {
	addMonths,
	addYears,
	eachMonthOfInterval,
	eachYearOfInterval,
	subMonths,
	subYears,
} from 'date-fns';

export function useDates({ range, startDate, endDate }) {
	return useMemo(() => {
		let dates = [];

		switch (range) {
			case 'month':
				dates = []
					.concat([subMonths(startDate, 1)])
					.concat(
						eachMonthOfInterval({ start: startDate, end: endDate }),
					)
					.concat([addMonths(endDate, 1)]);

				break;

			case 'year':
				dates = []
					.concat([subYears(startDate, 1)])
					.concat(
						eachYearOfInterval({ start: startDate, end: endDate }),
					)
					.concat([addYears(endDate, 1)]);
				break;

			default:
				dates = [];
		}

		return dates.slice(0, 12 + 2);
	}, [endDate, startDate, range]);
}
