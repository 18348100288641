import React from 'react';

import XAxisPrefix from '@asteria/component-forecaster/components/x-axis/prefix';

import LoanXAxisPrefixItem from './item';

const LoanXAxisPrefix = React.memo(() => {
	return <XAxisPrefix render={() => <LoanXAxisPrefixItem />} />;
});

LoanXAxisPrefix.displayName = 'LoanXAxisPrefix';

export default LoanXAxisPrefix;
