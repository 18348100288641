import React, { useCallback, useState } from 'react';

import 'currency-flags/dist/currency-flags.min.css';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Step = (props) => {
	return props.children;
};

const Steps = (props) => {
	const {
		className,
		title,
		onEnd,
		children,
		counter,
		onStepChange,
		nextBtnVariant = 'primary',
		nextBtnLabel = 'action.next',
		doneBtnLabel = 'action.done',
		backBtnLabel = 'action.close',
		hideBackBtnOnLast = false,
		dividerBeforeFooter = false,
	} = props;
	const [step, setStep] = useState(0);

	const numChildren = children?.length || 0;

	const next = useCallback(() => {
		if (step + 1 === numChildren) {
			if (onEnd) {
				onEnd();
			}
		} else {
			setStep(step + 1);
			onStepChange?.(step + 1);
		}
	}, [step, onEnd, numChildren]);

	const prev = useCallback(() => {
		if (step !== 0) {
			setStep(step - 1);
			onStepChange?.(step - 1);
		} else {
			if (onEnd) {
				onEnd();
			}
		}
	}, [step]);

	return (
		<Wrapper
			className={cn(
				'asteria-component__integration-guide',
				'asteria-component__steps',
				{
					'asteria-component__steps--step-first': step === 0,
					'asteria-component__steps--step-last':
						step === numChildren - 1,
				},
				className,
			)}
		>
			{title ? (
				<Header onClose={onEnd} onBack={step !== 0 ? prev : null}>
					{title}
				</Header>
			) : null}

			<Content scroll>
				{children?.[step] || null}
				{counter && (
					<Text className="asteria-component__steps__counter">{`${
						step + 1
					}/${numChildren}`}</Text>
				)}
			</Content>
			{dividerBeforeFooter && (
				<div className="asteria-component__divider"></div>
			)}
			<Footer className={hideBackBtnOnLast && 'hideBackBtnOnLast'}>
				<FooterSection>
					{!hideBackBtnOnLast && (
						<Button
							variant="tertiary"
							label={
								step === 0
									? TranslationService.get(backBtnLabel)
									: TranslationService.get('action.back')
							}
							onClick={prev}
						/>
					)}
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant={nextBtnVariant}
						label={
							step < numChildren - 1
								? TranslationService.get(nextBtnLabel)
								: TranslationService.get(doneBtnLabel)
						}
						onClick={next}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

Steps.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	nextBtnVariant: PropTypes.string,
	nextBtnLabel: PropTypes.string,
	doneBtnLabel: PropTypes.string,
	backBtnLabel: PropTypes.string,
	title: PropTypes.string,
	onEnd: PropTypes.func,
	onStepChange: PropTypes.func,
	counter: PropTypes.bool,
	hideBackBtnOnLast: PropTypes.bool,
	dividerBeforeFooter: PropTypes.bool,
};

Steps.defaultProps = {
	className: null,
	// nextBtnVariant: 'primary',
	// nextBtnLabel: 'action.next',
	// doneBtnLabel: 'action.done',
	// backBtnLabel: 'action.close',
	// hideBackBtnOnLast: false,
	// dividerBeforeFooter: false,
};

Steps.displayName = 'Steps';

export default Steps;
export { Step };
