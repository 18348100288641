import removeTag from './removeTag';

export const getAmount = ({ type, category, tag, getValues }) => {
	const form = getValues()?.[type]?.data ?? {};

	return Object.values(form)
		.filter(
			(value) =>
				value.FORECAST?.categories?.[category]?.tags?.[tag]?.value,
		)
		.map(
			(value) =>
				value.FORECAST?.categories?.[category]?.tags?.[tag]?.value,
		)
		.reduce((acc, value) => acc + value, 0);
};

export default ({ type, category, tag, dispatch, getValues, event }) => {
	const form = getValues()?.[type]?.data ?? {};

	for (const date in form) {
		if (form[date]?.FORECAST?.categories?.[category]?.tags?.[tag]?.value) {
			return;
		}
	}

	return removeTag({ category, tag, dispatch, event });
};
