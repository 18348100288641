import React from 'react';

import { ViewportList } from 'react-viewport-list';

import PropTypes from 'prop-types';

import { getItemBoundingClientRect } from '@asteria/component-core/utils/viewportList';

import { ListGroup } from '@asteria/component-list';
import { useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';

import TransactionGroupHeader from './Header';
import TransactionItem from './Item';

import './styles.scss';

const TransactionGroupWindowItems = React.forwardRef((props, ref) => {
	const { items, onAction } = props;

	const [viewportRef, setViewportRef] = React.useState({ current: null });

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref?.current) ?? ref?.current;

		setViewportRef({ current: node });
	}, [ref?.current, items?.length]);

	return (
		<ViewportList
			viewportRef={viewportRef}
			items={items ?? []}
			itemSize={40}
			scrollThreshold={40 * 20}
			renderSpacer={({ ref, style }) => (
				<div
					className="asteria-component__transactions-spacer"
					ref={ref}
					style={style}
				/>
			)}
			getItemBoundingClientRect={getItemBoundingClientRect}
		>
			{(id) => <TransactionItem key={id} id={id} onAction={onAction} />}
		</ViewportList>
	);
});

TransactionGroupWindowItems.displayName = 'TransactionGroupWindowItems';

TransactionGroupWindowItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

const TransactionGroupItems = React.forwardRef((props, ref) => {
	const { items, onAction } = props;

	const hasWindowFeature = useFeature('cashflow-transactions-window');

	if (hasWindowFeature) {
		return <TransactionGroupWindowItems {...props} ref={ref} />;
	}

	return (items ?? []).map((id) => (
		<TransactionItem key={id} id={id} onAction={onAction} />
	));
});

TransactionGroupItems.displayName = 'TransactionGroupItems';

TransactionGroupItems.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
};

const TransactionGroup = React.forwardRef((props, ref) => {
	const { className, loading, onAction, group } = props;

	return (
		<ListGroup
			className={cn('asteria-component__transactions__group', className)}
			data-date={group?.id}
		>
			{group?.id ? (
				<TransactionGroupHeader group={group} onAction={onAction} />
			) : null}
			<TransactionGroupItems
				items={group?.items}
				onAction={onAction}
				ref={ref}
			/>
			{loading ? (
				<>
					<TransactionItem loading />
					<TransactionItem loading />
					<TransactionItem loading />
					<TransactionItem loading />
					<TransactionItem loading />
					<TransactionItem loading />
					<TransactionItem loading />
				</>
			) : null}
		</ListGroup>
	);
});

TransactionGroup.displayName = 'TransactionGroup';

TransactionGroup.propTypes = {
	className: PropTypes.string,

	loading: PropTypes.bool,
	onAction: PropTypes.func,

	group: PropTypes.shape({
		id: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.string),
	}),
};

export default React.memo(TransactionGroup);
