import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

export const settings = createSelector(
	(store) => store?.app?.settings?.clients,
	(value) => value ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const sorting = createSelector(
	(store) => store?.app?.settings?.clients?.sorting,
	(value) => value ?? 'THIS_YEAR',
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const type = createSelector(
	(store) => store?.app?.settings?.clients?.type,
	(value) => value ?? 'CUSTOMER',
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
