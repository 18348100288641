import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const ConfirmationModalContent = React.memo((props) => {
	const { onClose, onAction } = props;

	const [saving, setSaving] = React.useState(false);

	const onDiscard = React.useCallback(
		() => onAction?.('discard'),
		[onAction],
	);

	const onSave = React.useCallback(async () => {
		setSaving(true);

		try {
			await onAction?.('save');
		} catch (err) {
			//
		}

		setSaving(false);
	}, [onAction]);

	return (
		<Wrapper>
			<Header onClose={onClose}>
				{TranslationService.get([
					'forecaster.modal.confirmation.title',
				])}
			</Header>
			<Content>
				<Translation
					translationKey="forecaster.modal.confirmation.content"
					Component={Text}
					size="sm"
				/>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.close',
							'action.close',
							'forecaster.modal.action.close.label',
						])}
						onClick={onClose}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="secondary"
						label={TranslationService.get([
							'button.discard',
							'action.discard',
							'forecaster.modal.action.discard.label',
						])}
						onClick={onDiscard}
						disabled={saving}
					/>
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.save',
							'action.save',
							'forecaster.modal.action.save.label',
						])}
						onClick={onSave}
						disabled={saving}
						loading={saving}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ConfirmationModalContent.displayName = 'ConfirmationModalContent';
ConfirmationModalContent.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

const ConfirmationModal = React.memo((props) => {
	const { open, onClose } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__forecaster-modal',
				'asteria--variant-confirmation',
			)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<ConfirmationModalContent {...props} />
		</Modal>
	);
});

ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

export default ConfirmationModal;
