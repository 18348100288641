import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Pick<Props, 'onUpdate'> & { active: boolean }> } */
const RowEdit = React.memo(function RowEdit({ active, onUpdate }) {
	const startEditing = React.useCallback(
		() => onUpdate({ $edit: true }),
		[onUpdate],
	);

	const stopEditing = React.useCallback(
		() => onUpdate({ $edit: false }),
		[onUpdate],
	);

	if (active) {
		return <Button icon="check" size="sm" onClick={stopEditing} />;
	}

	return <Button icon="edit" size="sm" onClick={startEditing} />;
});

RowEdit.propTypes = {
	active: PropTypes.bool,
	onUpdate: PropTypes.func,
};

export default RowEdit;
