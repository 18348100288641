import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Circle } from '@asteria/component-core/progress';
import { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, { Content, Header } from '@asteria/component-core/wrapper';

import Dialog from '@asteria/component-dialog';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const IntegrationDialog = (props) => {
	const { onClose, className } = props;

	const [isOpen, setOpen] = React.useState(true);

	const isLoading = useSelector(IntegrationStore.selectors.isLoading);
	const isFetching = useSelector(IntegrationStore.selectors.isFetching);

	const integrations = useSelector((store) =>
		IntegrationStore.selectors.integrations(store, {
			filters: [{ status: 'INITIATING' }, { status: 'IMPORTING' }],
		}),
	);

	const integration = integrations.reduce((acc, object) => {
		if (acc === null) {
			return object;
		}

		if (acc?.status?.progress > object?.status?.progress) {
			return object;
		}

		return acc;
	}, null);

	const dialogType = React.useMemo(
		() => (isLoading ? 'init' : 'fetch'),
		[isLoading],
	);

	React.useLayoutEffect(() => {
		if (isOpen && (isLoading || isFetching)) {
			const classNames = [
				`asteria--state-loading`,
				`asteria-loading--integration-${dialogType}`,
			];

			const node = document.querySelector('.asteria-wrapper');

			node?.classList?.add?.(...classNames);

			return () => {
				node?.classList?.remove?.(...classNames);
			};
		}
	}, [isFetching, isLoading, isOpen, dialogType]);

	const handleClose = React.useCallback(
		(event) => {
			setOpen(false);
			return onClose?.(event);
		},
		[onClose],
	);

	const HeaderPostfix = React.useMemo(
		() => ({ position: 'absolute', size: 'sm' }),
		[],
	);

	const integrationType = integration?.type;
	const integrationKey = integration?.key;
	const integrationProgress = integration?.status?.progress ?? -1;

	return (
		<Dialog
			className={cn(
				'asteria-component__integration-loading-dialog',
				'asteria-component__onboarding-loading-dialog',
				className,
			)}
			onClose={handleClose}
			placement="bottom"
			open={isOpen && (isLoading || isFetching)}
		>
			<Wrapper>
				<Header onClose={handleClose} postfix={HeaderPostfix}>
					<Text weight="bold">
						{TranslationService.getByObject({
							key: [
								`onboarding.importing.dialog.title`,
								`onboarding.importing.dialog.${integrationKey}.title`,
								`onboarding.importing.dialog.${integrationType}.${integrationKey}.title`,
								`onboarding.importing.dialog.${integrationProgress}.title`,
								`onboarding.importing.dialog.${integrationKey}.${integrationProgress}.title`,
								`onboarding.importing.dialog.${integrationType}.${integrationKey}.${integrationProgress}.title`,
								`onboarding.importing.dialog.${dialogType}.title`,
								`onboarding.importing.dialog.${dialogType}.${integrationKey}.title`,
								`onboarding.importing.dialog.${dialogType}.${integrationType}.${integrationKey}.title`,
								`onboarding.importing.dialog.${dialogType}.${integrationProgress}.title`,
								`onboarding.importing.dialog.${dialogType}.${integrationKey}.${integrationProgress}.title`,
								`onboarding.importing.dialog.${dialogType}.${integrationType}.${integrationKey}.${integrationProgress}.title`,
							],

							data: {
								integrations: integrations,
								integration: integration,
								progress: integrationProgress,
							},
							lookup: {
								integrationName: {
									key: [
										`integration.title.${integrationKey}`,
										`integration.title.${integrationType}.${integrationKey}`,
									],
									default: integrationKey,
								},
							},
						})}
					</Text>
				</Header>
				<Content>
					<Group direction="horizontal" verticalAlign="top" flex>
						<div
							className={cn(
								'asteria-component__onboarding-dialog__section',
								'asteria--type-progress',
							)}
						>
							<Circle progress={-1} empty />
						</div>
						<TextGroup
							className={cn(
								'asteria-component__onboarding-dialog__section',
								'asteria--type-content',
							)}
						>
							<Text size="sm" className="asteria--type-status">
								{TranslationService.getByObject({
									key: [
										`onboarding.importing.dialog.status`,
										`onboarding.importing.dialog.${integrationKey}.status`,
										`onboarding.importing.dialog.${integrationType}.${integrationKey}.status`,
										`onboarding.importing.dialog.${integrationProgress}.status`,
										`onboarding.importing.dialog.${integrationKey}.${integrationProgress}.status`,
										`onboarding.importing.dialog.${integrationType}.${integrationKey}.${integrationProgress}.status`,
										`onboarding.importing.dialog.${dialogType}.status`,
										`onboarding.importing.dialog.${dialogType}.${integrationKey}.status`,
										`onboarding.importing.dialog.${dialogType}.${integrationType}.${integrationKey}.status`,
										`onboarding.importing.dialog.${dialogType}.${integrationProgress}.status`,
										`onboarding.importing.dialog.${dialogType}.${integrationKey}.${integrationProgress}.status`,
										`onboarding.importing.dialog.${dialogType}.${integrationType}.${integrationKey}.${integrationProgress}.status`,
									],

									data: {
										integrations: integrations,
										integration: integration,
										progress: integrationProgress,
									},
									lookup: {
										integrationName: {
											key: [
												`integration.title.${integrationKey}`,
												`integration.title.${integrationType}.${integrationKey}`,
											],
											default: integrationKey,
										},
									},
								})}
							</Text>
							<Text
								size="xs"
								className="asteria--type-description"
							>
								{TranslationService.getByObject({
									key: [
										`onboarding.importing.dialog.content`,
										`onboarding.importing.dialog.${integrationKey}.content`,
										`onboarding.importing.dialog.${integrationType}.${integrationKey}.content`,
										`onboarding.importing.dialog.${integrationProgress}.content`,
										`onboarding.importing.dialog.${integrationKey}.${integrationProgress}.content`,
										`onboarding.importing.dialog.${integrationType}.${integrationKey}.${integrationProgress}.content`,
										`onboarding.importing.dialog.${dialogType}.content`,
										`onboarding.importing.dialog.${dialogType}.${integrationKey}.content`,
										`onboarding.importing.dialog.${dialogType}.${integrationType}.${integrationKey}.content`,
										`onboarding.importing.dialog.${dialogType}.${integrationProgress}.content`,
										`onboarding.importing.dialog.${dialogType}.${integrationKey}.${integrationProgress}.content`,
										`onboarding.importing.dialog.${dialogType}.${integrationType}.${integrationKey}.${integrationProgress}.content`,
									],

									data: {
										integrations: integrations,
										integration: integration,
										progress: integrationProgress,
									},
									lookup: {
										integrationName: {
											key: [
												`integration.title.${integrationKey}`,
												`integration.title.${integrationType}.${integrationKey}`,
											],
											default: integrationKey,
										},
									},
								})}
							</Text>
						</TextGroup>
					</Group>
				</Content>
			</Wrapper>
		</Dialog>
	);
};

IntegrationDialog.displayName = 'IntegrationDialog';

IntegrationDialog.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
};

export default IntegrationDialog;
