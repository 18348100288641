import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

const Mask = React.memo((props) => {
	const {
		targetInfo,
		disableMaskInteraction,
		padding,
		close,
		disableCloseOnClick,
		maskId,

		diffX,
		diffY,
	} = props;

	const [size, setSize] = useState({ width: 0, height: 0 });

	const hasTransparentFeature = useFeature('tour-transparent-background');

	const updateSize = useCallback(() => {
		setSize({ width: window.innerWidth, height: window.innerHeight });
	}, []);

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, [updateSize]);

	const containerHeight = size.height;
	const containerWidth = size.width;

	const pathId = `clip-path-${maskId}`;

	const getCutoutPoints = useCallback(
		(target, { diffX, diffY }) => {
			if (!target) {
				return '';
			}

			const { dims, coords } = target;

			const cutoutTop = coords.y - diffY - padding;
			const cutoutLeft = coords.x - diffX - padding;
			const cutoutRight = coords.x - diffX + dims.width + padding;
			const cutoutBottom = coords.y - diffY + dims.height + padding;

			return `0 0,
            0 ${containerHeight},
            ${cutoutLeft} ${containerHeight},
            ${cutoutLeft} ${cutoutTop},
            ${cutoutRight} ${cutoutTop},
            ${cutoutRight} ${cutoutBottom},
            ${cutoutLeft} ${cutoutBottom},
            ${cutoutLeft} ${containerHeight},
            ${containerWidth} ${containerHeight},
            ${containerWidth} 0`;
		},
		[containerHeight, containerWidth, padding],
	);
	const borderData = useMemo(() => {
		if (!targetInfo) {
			return {};
		}

		const { dims, coords } = targetInfo;

		const cutoutTop = coords.y - padding;
		const cutoutLeft = coords.x - padding;

		return {
			x: cutoutLeft - 1,
			y: cutoutTop - 1,
			width: dims.width + (padding + 1) * 2,
			height: dims.height + (padding + 1) * 2,
		};
	}, [padding, targetInfo]);

	const svgStyle = useMemo(
		() => ({
			height: containerHeight,
			width: containerWidth,
			pointerEvents: disableMaskInteraction ? 'auto' : 'none',
		}),
		[containerHeight, containerWidth, disableMaskInteraction],
	);

	return (
		<svg style={svgStyle}>
			{targetInfo && (
				<defs>
					<clipPath id={pathId}>
						<polygon
							points={getCutoutPoints(targetInfo, {
								diffX,
								diffY,
							})}
							strokeLinejoin="round"
							strokeWidth={4}
						/>
					</clipPath>
				</defs>
			)}
			<rect
				className={cn('asteria-component__tour__mask-background', {
					'asteria-component__tour__mask-background--transparent':
						hasTransparentFeature,
				})}
				onClick={disableCloseOnClick ? undefined : close}
				x={0}
				y={0}
				width={containerWidth}
				height={containerHeight}
				pointerEvents="auto"
				clipPath={targetInfo ? `url(#${pathId})` : undefined}
			/>
			{targetInfo && (
				<rect
					x={borderData?.x - diffX}
					y={borderData?.y - diffY}
					width={borderData?.width}
					height={borderData?.height}
					className="border"
					rx={4}
					ry={4}
				/>
			)}
		</svg>
	);
});

Mask.displayName = 'Mask';

Mask.propTypes = {
	targetInfo: PropTypes.object,
	padding: PropTypes.number,
	close: PropTypes.func,
	tourRoot: PropTypes.object,
	disableMaskInteraction: PropTypes.bool,
	disableCloseOnClick: PropTypes.bool,
	maskId: PropTypes.string,

	diffX: PropTypes.number,
	diffY: PropTypes.number,
};

export default Mask;
export { Mask };
