import React from 'react';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import '../styles.scss';

const View = (props) => {
	const { label, colors } = props;

	return <Chip label={label} colors={colors} variant="simple" />;
};

View.displayName = 'TagSelectorItemLabelView';
View.propTypes = {
	label: PropTypes.string,
	colors: PropTypes.arrayOf(PropTypes.string),
};

export default View;
