import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { replace } from '@asteria/utils-funcs/format';

const selectors = {
	color: createSelector(
		(store) => store?.app?.tags ?? [],
		(_, ID) => ID,
		(tags, ID) =>
			tags.find((object) => (object?._id ?? object?.id) === ID)?.color,
	),
};

export default function useTagColors({ tag, status, type }) {
	const color = useSelector((store) =>
		selectors.color(store, tag?._id ?? tag?.id),
	);

	const { name, category } = tag || {};
	const { name: categoryName } = category || {};

	const categoryColors = replace([categoryName, name]).join('-');
	const statusColors = status?.toLowerCase();
	const typeColors = type?.toLowerCase();

	return [
		[categoryColors, statusColors].join('-'),
		categoryColors,
		statusColors,
		typeColors,
		color,
	];
}
