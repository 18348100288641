import React from 'react';

import { useDispatch } from 'react-redux';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

export function useAction({ onAction }) {
	const dispatch = useDispatch();

	return React.useCallback(
		(action, data) => {
			if (data?.type === 'forecaster.tag.complete') {
				dispatch(
					ForecasterStore.tags.toggle.complete({
						type: data?.data?.type,
						categoryName: data?.data?.category,
						tagName: data?.data?.tag,
					}),
				);

				dispatch(
					ForecasterStore.action.set({
						event: ForecasterStore.constants.ACTIONS.ResetTag,
						type: data?.data?.type,
						category: data?.data?.category,
						tag: data?.data?.tag,
					}),
				);
			}

			if (data?.type === 'forecaster.tag.removed') {
				dispatch(
					ForecasterStore.action.set({
						event: ForecasterStore.constants.ACTIONS.ResetRemove,
						type: data?.data?.type,
						category: data?.data?.category,
						tag: data?.data?.tag,
					}),
				);
			}

			if (data?.type === 'forecaster.tag.skipped') {
				dispatch(
					ForecasterStore.tags.toggle.skip({
						type: data?.data?.type,
						categoryName: data?.data?.category,
						tagName: data?.data?.tag,
					}),
				);

				dispatch(
					ForecasterStore.action.set({
						event: ForecasterStore.constants.ACTIONS.ResetTag,
						type: data?.data?.type,
						category: data?.data?.category,
						tag: data?.data?.tag,
					}),
				);
			}

			if (data?.type === 'forecaster.type.complete') {
				const type = data?.data?.type;

				dispatch(ForecasterStore.tags.clearState(type));

				dispatch(
					ForecasterStore.action.set({
						event: ForecasterStore.constants.ACTIONS.ResetType,
						type: data?.data?.type,
						category: data?.data?.category,
						tag: data?.data?.tag,
					}),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction],
	);
}
