import { getViewport } from '@asteria/component-core/utils';

export default (height) => {
	if (!height) {
		return 'large';
	}

	let styles = null;

	const node = document.querySelector('.asteria-wrapper');

	if (node) {
		styles = getComputedStyle(node);
	}

	const small = Number.parseFloat(
		styles?.getPropertyValue?.('--breakpoint-small') || 250,
	);
	const large = Number.parseFloat(
		styles?.getPropertyValue?.('--breakpoint-large') || 510,
	);

	if (height < small) {
		return 'small';
	}

	const [, viewPortHeight] = getViewport();
	const outerHeight = viewPortHeight - height;

	if (outerHeight < large) {
		return 'large';
	}

	return 'medium';
};
