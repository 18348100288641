import ComponentService from '@asteria/component-tools/contenter/service';

import Circle from './Circle';
import Progress from './Progress';
import SkeletonLoader from './SkeletonLoader';

ComponentService.register('Progress', Progress, {
	className: { type: 'string' },
	progress: { type: 'number' },
	steps: { type: 'number' },
	color: {
		type: 'object',
		options: {
			type: { type: 'string' },
			value: { type: 'string' },
		},
	},
	tooltip: { type: 'string' },
});

ComponentService.register('ProgressCircle', Circle, {
	className: { type: 'string' },
	animateOnRender: { type: 'boolean' },
	animationSpeed: { type: 'number' },
	progress: { type: 'number' },
	tooltip: { type: 'node' },
	color: {
		type: 'object',
		options: {
			type: { type: 'string' },
			value: { type: 'string' },
		},
	},
	children: { type: 'node' },
	onClick: { type: 'function' },
	empty: { type: 'boolean' },
});

export default Progress;
export { Circle, SkeletonLoader };
