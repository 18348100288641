import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
	addMonths,
	formatISO,
	isFuture,
	isPast,
	isSameMonth,
	isThisMonth,
	isThisYear,
	parseISO,
	subMonths,
} from 'date-fns';

import { useFormValues } from '@asteria/component-form';

import * as GraphStore from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import { getLabels } from '@asteria/utils-funcs/graph';

import { useAccumulativeForm } from '../../../hooks';
import Context from '../../../logic/context';

export function useLineGraphData(options) {
	const { dates, hideSpread, absolute } = options;

	const { history: historicalValues } = useContext(Context);

	const { history, future } = useAccumulativeForm({
		useForm: useFormValues,
		history: historicalValues,
	});

	const lineValues = []
		.concat([subMonths(dates[0], 1)])
		.concat(dates)
		.concat(addMonths(dates.slice(-1)[0], 1))
		.map((date) => {
			const fmt = formatISO(date, { representation: 'date' });
			let value = history?.[fmt] ?? future?.[fmt];

			if (!value) {
				if (isPast(date)) {
					value = history?.$lastValue ?? history?.$last;
				} else {
					value = future?.$lastValue ?? future?.$last;
				}
			}

			if (hideSpread) {
				value.min = value?.value;
				value.max = value?.value;
			}

			return { date: fmt, ...value };
		});

	const max = Math.max(
		...lineValues.flatMap(({ value, min, max }) => [value, min, max]),
	);

	const min = Math.min(
		...lineValues.flatMap(({ value, min, max }) => [value, min, max]),
	);

	const scale = useMemo(() => {
		const absolute = Math.max(Math.abs(max), Math.abs(min));

		if (!absolute) {
			return 0;
		}

		return Math.floor(Math.floor(Math.log10(absolute)) / 3) - 1;
	}, [max, min]);

	const lineLabels = useMemo(() => {
		let $min = min,
			$max = max;

		if (absolute) {
			$max = Math.max(Math.abs(min), Math.abs(max));
			$min = -$max;
		}

		if (Math.abs($max) <= 1 && Math.abs($min) <= 1) {
			$max = 10_000;
			$min = -$max;
		}

		const labels = getLabels({
			max: $max,
			min: $min,
			scale: scale,
			steps: 4,
			prefix: false,
		});

		return labels.reverse().map((object, index) => ({
			...object,
			size: index ? 'medium' : undefined,
		}));
	}, [absolute, max, min, scale]);

	const lineLabelsMax = Math.max(
		1_000,
		...lineLabels.map(({ value }) => value),
	);

	const lineLabelsMin = Math.min(0, ...lineLabels.map(({ value }) => value));

	const response = {
		labels: lineLabels,
		max: lineLabelsMax,
		min: lineLabelsMin,
		data: lineValues,
		title: TranslationService.get(
			[
				'yaxis.label',
				`yaxis.label.SEK`,
				`yaxis.label.${scale}`,
				`yaxis.label.SEK.${scale}`,
			],
			'TKr',
		),
	};

	return response;
}

export function useLineType($date, type = 'month') {
	if (!$date) {
		return 'future';
	}

	const date = typeof $date === 'string' ? parseISO($date) : $date;

	if (type === 'month' && isThisMonth(date)) {
		return 'current';
	}

	if (type === 'year' && isThisYear(date)) {
		return 'current';
	}

	if (isPast(date)) {
		return 'history';
	}

	return 'future';
}

export function useLegends({ dates }) {
	const hasPast = dates.some((date) => isPast(date) && !isThisMonth(date));
	const hasFuture = dates.some((date) => isFuture(date) || isThisMonth(date));
	const hasSpread = dates.some((date) =>
		isSameMonth(date, subMonths(new Date(), 1)),
	);

	return useMemo(
		() =>
			[
				hasPast
					? {
							type: ['account', 'paid'],
							title: TranslationService.get(
								'graph.account.legend.history',
							),
					  }
					: null,
				hasFuture
					? {
							type: ['account', 'forecast'],
							title: TranslationService.get(
								'graph.account.legend.forecast',
							),
					  }
					: null,
				hasFuture || hasSpread
					? {
							type: ['spread'],
							title: TranslationService.get(
								'graph.account.legend.spread',
							),
					  }
					: null,
			].filter(Boolean),
		[hasFuture, hasPast, hasSpread],
	);
}

export function useStateHidden() {
	const selected = useSelector(GraphStore.selectors.legends.selected);
	const exists = !!selected.length;

	const spread = selected.some((object) => object?.id === 'spread');
	const lineHistory = selected.some(
		(object) => object?.id === 'account-paid',
	);
	const lineFuture = selected.some(
		(object) => object?.id === 'account-forecast',
	);

	return useMemo(() => {
		if (!exists) {
			return;
		}

		return {
			spread: !spread,
			line: { history: !lineHistory, future: !lineFuture },
		};
	}, [exists, lineFuture, lineHistory, spread]);
}

export function useStateInvisible() {
	const highlight = useSelector(GraphStore.selectors.legends.highlight);
	const exists = !!highlight;

	const spread = highlight?.id === 'spread';
	const lineHistory = highlight?.id === 'account-paid';
	const lineFuture = highlight?.id === 'account-forecast';

	return useMemo(() => {
		if (!exists) {
			return;
		}

		return {
			spread: !spread,
			line: { history: !lineHistory, future: !lineFuture },
		};
	}, [exists, lineFuture, lineHistory, spread]);
}
