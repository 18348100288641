import { configureStore } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';
import accounts from './stores/accounts';
import admin from './stores/admin';
import app from './stores/app';
import features from './stores/features';
import financial from './stores/financial';
import forecaster from './stores/forecaster';
import graph from './stores/graph';
import integrations from './stores/integrations';
import invoices from './stores/invoices';
import language from './stores/language';
import modals from './stores/modals';
import navigate from './stores/navigate';
import notifications from './stores/notifications';
import scenarios from './stores/scenarios';
import snackbar from './stores/snackbar';
import tour from './stores/tour';
import transactions from './stores/transactions';

const reducers = {
	accounts: accounts,
	admin: admin,
	app: app,
	features: features,
	financial: financial,
	forecaster: forecaster,
	graph: graph,
	integrations: integrations,
	invoices: invoices,
	language: language,
	modals: modals,
	navigate: navigate,
	notifications: notifications,
	scenarios: scenarios,
	snackbar: snackbar,
	tour: tour,
	transactions: transactions,
};

export const reset = (dispatch) => {
	dispatch({ type: ACTIONS.RESET });
};

export default configureStore({ reducer: reducers });

export function createStore(preloadedState) {
	return configureStore({
		reducer: reducers,
		preloadedState: preloadedState,
	});
}
