import React from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const FeedbackRating = (props) => {
	const {
		className,
		minText,
		maxText,
		value,
		values = [],
		icon,
		iconActive = icon,
		size,

		onChange,
	} = props;

	const valueIndex = values.indexOf(value);

	const [activeIndex, setActiveIndex] = React.useState(valueIndex);

	React.useEffect(() => {
		setActiveIndex(valueIndex);
	}, [valueIndex]);

	return (
		<Group
			direction="horizontal"
			horizontalAlign="center"
			verticalAlign="center"
			className={cn('asteria-component__feedback__rating', className)}
		>
			{minText ? (
				<Text size="sm">
					{TranslationService.get(minText, minText)}
				</Text>
			) : null}
			<Group
				direction="horizontal"
				horizontalAlign="center"
				verticalAlign="center"
				className={cn(
					'asteria-component__feedback__rating-values',
					className,
				)}
			>
				{values.map((_, index) => (
					<Button
						key={index}
						icon={icon}
						variant="feedback-rate"
						iconActive={iconActive}
						active={index <= activeIndex}
						onMouseEnter={() => setActiveIndex(index)}
						onMouseLeave={() => setActiveIndex(valueIndex)}
						onClick={() => onChange?.(values[index])}
						size={size}
					/>
				))}
			</Group>
			{maxText ? (
				<Text size="sm">
					{TranslationService.get(maxText, maxText)}
				</Text>
			) : null}
		</Group>
	);
};

FeedbackRating.displayName = 'FeedbackRating';

FeedbackRating.propTypes = {
	className: PropTypes.string,
	minText: PropTypes.string,
	maxText: PropTypes.string,
	values: PropTypes.arrayOf(PropTypes.number),
	icon: PropTypes.string,
	iconActive: PropTypes.string,
	value: PropTypes.number,
	size: SizeProp(),

	onChange: PropTypes.func,
};

FeedbackRating.defaultProps = {
	icon: 'star',
	iconActive: 'star-filled',
	values: [1, 2, 3, 4, 5],
	size: 'md',
};

export default FeedbackRating;
