import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Badge from '@asteria/component-core/badge';
import Tooltip from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const XAxisText = React.memo((props) => {
	const { label, prefix, size } = props;

	return (
		<TextGroup>
			<Text
				size={size?.prefix ?? size}
				className="asteria-graph-x-axis-label--prefix"
			>
				{prefix || <br />}
			</Text>
			<Text
				size={size?.label ?? size}
				className="asteria-graph-x-axis-label--label"
			>
				{label}
			</Text>
		</TextGroup>
	);
});

XAxisText.displayName = 'XAxisText';

XAxisText.propTypes = {
	label: PropTypes.string,
	prefix: PropTypes.string,
	size: PropTypes.oneOfType([
		PropTypes.shape({ prefix: SizeProp(), label: SizeProp() }),
		SizeProp(),
	]),
};

const XAxisLabel = React.memo((props) => {
	const {
		className,
		label,
		value,
		prefix,
		badge,
		tooltip,
		children,
		size,
		onClick,
		onHover,
		...rest
	} = props;

	const handleClick = useCallback(() => {
		onClick?.({ label: label, value: value });
	}, [onClick, label, value]);

	const handleHover = useCallback(() => {
		onHover?.({ label: label, value: value });
	}, [onHover, label, value]);

	const handleLeave = useCallback(() => {
		onHover?.({ label: null, value: null });
	}, [onHover]);

	return (
		<div
			onMouseEnter={handleHover}
			onMouseLeave={handleLeave}
			onClick={handleClick}
			className={cn('asteria-graph-x-axis-label', className)}
			{...rest}
		>
			<Tooltip label={tooltip}>
				<div>
					{badge ? (
						<Badge
							className="asteria-graph-x-badge"
							icon="alert"
							tooltip={badge}
						/>
					) : null}
					<XAxisText prefix={prefix} label={label} size={size} />
					{children}
				</div>
			</Tooltip>
		</div>
	);
});

XAxisLabel.displayName = 'XAxisLabel';

XAxisLabel.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	prefix: PropTypes.string,
	tooltip: PropTypes.string,
	badge: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
	onHover: PropTypes.func,
	size: PropTypes.oneOfType([
		PropTypes.shape({ prefix: PropTypes.string, label: PropTypes.string }),
		PropTypes.string,
	]),
};

export default XAxisLabel;
