import React from 'react';

import PropTypes from 'prop-types';

import { findActive } from './utils';
import LevelNavigationView from './view';

const LevelNavigationProvider = React.memo((props) => {
	const {
		className,
		config,
		path,
		onAction,
		showBackAlways,
		back,
		toggleable,
	} = props;

	const [active, setActive] = React.useState(path ?? []);
	const [visible, setVisible] = React.useState({});

	React.useEffect(() => {
		setActive(path ?? []);
	}, [path]);

	const handleAction = React.useCallback(
		(action, data, options) => {
			if (action === 'navigation:navigate') {
				setActive(data ?? []);
			}

			if (action === 'navigation:select') {
				setVisible((state) => ({ ...state, [data.join('-')]: true }));
			}

			if (action === 'navigation:deselect') {
				setVisible((state) => ({ ...state, [data.join('-')]: false }));
				setActive((path) => {
					if (data.slice(-1)[0] === path.slice(-1)[0]) {
						handleAction(
							'navigation:navigate',
							path.slice(0, -1),
							options,
						);
					}

					return path;
				});
			}

			return onAction?.(action, data, options);
		},
		[onAction],
	);

	const { config: activeConfig, level } = findActive(config, active);

	const onBack = React.useCallback(() => {
		const next = active.length ? active.slice(0, -1) : null;

		return handleAction('navigation:navigate', next);
	}, [active, handleAction]);

	const base = React.useMemo(() => active.slice(0, level), [active, level]);

	return (
		<LevelNavigationView
			className={className}
			level={level}
			config={activeConfig}
			active={active}
			visible={visible}
			base={base}
			onBack={onBack}
			onAction={handleAction}
			showBackAlways={showBackAlways}
			back={back}
			toggleable={toggleable}
		/>
	);
});

LevelNavigationProvider.displayName = 'LevelNavigationProvider';

LevelNavigationProvider.propTypes = {
	className: PropTypes.string,
	config: PropTypes.object,
	path: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,

	showBackAlways: PropTypes.bool,

	back: PropTypes.shape({
		size: PropTypes.string,
		icon: PropTypes.string,
		iconSize: PropTypes.string,
		label: PropTypes.string,
		variant: PropTypes.string,

		tooltip: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
			PropTypes.object,
		]),
	}),

	toggleable: PropTypes.bool,
};

export default LevelNavigationProvider;
