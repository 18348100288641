import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import { useGroupMessages } from '../hooks';

import Messages from './messages';

import '../index.scss';

const MessageGroup = (props) => {
	const { messages, type } = props;

	const groupedMessages = useGroupMessages(messages);

	return Object.keys(groupedMessages).map((date) => (
		<Group direction="vertical" key={date}>
			<Text size="xs" className="asteria-component__message-group-header">
				{TranslationService.get(
					[
						'conversation.modal.group.header',
						`conversation.modal.group.${type}.header`,
					],
					undefined,
					{
						date: date,
					},
				)}
			</Text>
			<Messages messages={groupedMessages[date]} type={type} />
		</Group>
	));
};

MessageGroup.displayName = 'MessageGroup';

MessageGroup.propTypes = {
	messages: PropTypes.array,
	type: PropTypes.string,
};

export default MessageGroup;
