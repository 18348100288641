import React, { useEffect, useMemo, useState } from 'react';

import Service from './service';

const Context = React.createContext({});

const Provider = ({ children, features: propFeatures }) => {
	const [features, setFeatures] = useState(propFeatures);

	useEffect(() => {
		setFeatures(propFeatures);
	}, [propFeatures]);

	const state = useMemo(() => {
		Service.setFeatures(features);
		return {
			features,
			setFeatures,
		};
	}, [features]);

	return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context;
export { Provider };
