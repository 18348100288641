import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import LoanBarGraph from '../../../components/loan/graph/bars';

const SectionBarGraph = React.memo(() => {
	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-bar-graph',
			)}
		>
			<LoanBarGraph />
		</div>
	);
});

SectionBarGraph.displayName = 'SectionBarGraph';
SectionBarGraph.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionBarGraph;
