import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import { useCategories } from '../../../hooks';
import { getPath } from '../../../utils/form/utils';

import { useTranslationOptions } from './hooks';
import ForecastDetailsSectionRow from './row';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'onAction' | 'onSubmit' | 'translationOptions'> & { type: 'deposit' | 'withdraw' }> } */
const ForecastDetailsSection = React.memo(function ForecastDetailsSection({
	type,
	translationOptions: $translationOptions,
	onAction,
	onSubmit,
}) {
	const dispatch = useDispatch();
	const store = useStore();

	const { getValues } = useFormContext();

	const path = getPath({ type });

	const { fields, append, remove, update } = useFieldArray({
		name: path.concat(['tags']).join('.'),
	});

	const categories = useCategories({ type });

	const next = useSelector(
		(store) =>
			ForecasterStore.selectors.tags.available(store, {
				type,
				exclude: categories,
			})?.[0],
		(a, b) => isEqual(a, b),
	);

	const { options: translationOptions } = useTranslationOptions(
		$translationOptions,
		{ type },
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'category:create') {
				return dispatch(
					ModalStore.open({ type: ModalStore.MODAL_WINDOWS.TagEdit }),
				);
			}

			if (action === 'category:update') {
				const object = AppStore.selectors.tag(store.getState(), data);
				const currency = AppStore.selectors.company(store.getState())
					?.settings?.currency;

				const category = object?.category?.name;
				const tag = object?.name;

				const deposit = Object.values(
					getValues(
						getPath({
							type: 'deposit',
							category,
							tag,
							field: 'transactions',
						}).join('.'),
					) ?? {},
				).filter(({ total }) => total);

				const withdraw = Object.values(
					getValues(
						getPath({
							type: 'withdraw',
							category,
							tag,
							field: 'transactions',
						}).join('.'),
					) ?? {},
				).filter(({ total }) => total);

				const count = deposit.length + withdraw.length;

				const depositTotal = deposit.reduce(
					(acc, { total }) => acc + total,
					0,
				);

				const withdrawTotal = withdraw.reduce(
					(acc, { total }) => acc + total,
					0,
				);

				const total = depositTotal - withdrawTotal;

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.TagEdit,
						data: {
							data: object,
							form: {},
							remove: {
								data: {
									label: FormatUtils.formatTag({
										type,
										category: category,
										tag: tag,
									}),
									statistics: {
										current: {
											count: count,
											total: [
												{
													currency: currency,
													total: total,
												},
											],

											DEPOSIT: {
												count: deposit.length,
												total: [
													{
														currency: currency,
														total: depositTotal,
													},
												],
											},

											WITHDRAW: {
												count: withdraw.length,
												total: [
													{
														currency: currency,
														total: withdrawTotal,
													},
												],
											},
										},
									},
								},
							},
						},
					}),
				);
			}

			return onAction?.(action, data);
		},
		[dispatch, getValues, onAction, store, type],
	);

	const create = React.useCallback(() => {
		if (!next?.name) {
			return handleAction?.('category:create');
		}

		return append({
			category: next?.category?.name,
			tag: next?.name,
			$edit: true,
		});
	}, [append, handleAction, next?.category?.name, next?.name]);

	const onRemove = React.useCallback(
		(index) => () => remove(index),
		[remove],
	);

	const onUpdate = React.useCallback(
		(source, index) => (target) => {
			const exists = fields.some(
				(object) =>
					object?.category === target?.category &&
					object?.tag === target?.tag,
			);

			if (exists) {
				return remove(index);
			}

			return update(index, { ...source, ...target });
		},
		[fields, remove, update],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-2',
				'asteria-component__card-section',
				'asteria--variant-details',
				{ [`asteria--type-${type}`]: type },
			)}
		>
			<Translation
				translationKey="card.extra.section.label"
				translationOptions={{
					...translationOptions,
					postfix: {
						...translationOptions?.postfix,
						section: 'details',
						'section-type': type,
					},
				}}
				Component={Text}
			/>
			{fields.map((object, index) => (
				<ForecastDetailsSectionRow
					key={object?.id}
					type={type}
					category={object?.category}
					tag={object?.tag}
					edit={object?.$edit}
					translationOptions={translationOptions}
					onAction={handleAction}
					onSubmit={onSubmit}
					id={object?.id}
					onRemove={onRemove(index)}
					onUpdate={onUpdate(object, index)}
				/>
			))}
			<Button icon="add" size="sm" onClick={create} />
		</div>
	);
});

ForecastDetailsSection.propTypes = {
	type: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default ForecastDetailsSection;
