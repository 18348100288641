import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import FeedbackRating from './Rating';

import './styles.scss';

const FeedbackModalContent = (props) => {
	const {
		onClose,
		onSubmit,
		loading,
		feedbackKey,
		type,
		onSupportRequest,
		rating,

		hideContactUs,
	} = props;

	const handleSubmit = React.useCallback(
		(form) => onSubmit?.({ ...form, value: rating?.value }),
		[onSubmit, rating?.value],
	);

	const prefix = ['feedback.form', feedbackKey, type]
		.filter(Boolean)
		.join('.');

	return (
		<Form onSubmit={handleSubmit}>
			<Wrapper
				className={cn(
					'asteria-component__feedback__modal',
					'asteria-component__feedback__modal--form',
				)}
			>
				<Header onClose={onClose} verticalAlign="center">
					{TranslationService.get([
						'feedback.form.header',
						`feedback.form.${feedbackKey}.header`,
						`feedback.form.${type}.header`,
						`feedback.form.${feedbackKey}.${type}.header`,
					])}
				</Header>
				<Content>
					<FormWrapper>
						<Content>
							<Text size="sm">
								{TranslationService.get([
									'feedback.form.description',
									`feedback.form.${feedbackKey}.description`,
									`feedback.form.${type}.description`,
									`feedback.form.${feedbackKey}.${type}.description`,
								])}
								{!hideContactUs ? (
									<>
										{' '}
										<Button
											className="asteria-component__feedback__contact"
											variant="link"
											label={TranslationService.get([
												'feedback.form.contact',
												`feedback.form.${feedbackKey}.contact`,
												`feedback.form.${type}.contact`,
												`feedback.form.${feedbackKey}.${type}.contact`,
											])}
											onClick={onSupportRequest}
											size="sm"
											analyticsKey={`${prefix}.contact`}
										/>
									</>
								) : null}
								.
							</Text>
							<Text size="sm">
								{TranslationService.get([
									'feedback.form.rate.label',
									`feedback.form.${feedbackKey}.rate.label`,
									`feedback.form.${type}.rate.label`,
									`feedback.form.${feedbackKey}.${type}.rate.label`,
								])}
							</Text>
							<FeedbackRating {...rating} size="lg" />
							<Input
								type="textarea"
								label={TranslationService.get([
									'feedback.form.comment.label',
									`feedback.form.${feedbackKey}.comment.label`,
									`feedback.form.${type}.comment.label`,
									`feedback.form.${feedbackKey}.${type}.comment.label`,
								])}
								placeholder={TranslationService.get([
									'feedback.form.comment.placeholder',
									`feedback.form.${feedbackKey}.comment.placeholder`,
									`feedback.form.${type}.comment.placeholder`,
									`feedback.form.${feedbackKey}.${type}.comment.placeholder`,
								])}
								name="message"
							/>
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'feedback.form.close.label',
								`feedback.form.${feedbackKey}.close.label`,
								`feedback.form.${type}.close.label`,
								`feedback.form.${feedbackKey}.${type}.close.label`,
							])}
							analyticsKey={`${prefix}.close.label`}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							type="submit"
							label={TranslationService.get([
								'feedback.form.submit.label',
								`feedback.form.${feedbackKey}.submit.label`,
								`feedback.form.${type}.submit.label`,
								`feedback.form.${feedbackKey}.${type}.submit.label`,
							])}
							loading={loading}
							analyticsKey={`${prefix}.submit.label`}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
};

FeedbackModalContent.displayName = 'FeedbackModalContent';

FeedbackModalContent.propTypes = {
	loading: PropTypes.bool,
	onClose: PropTypes.func,

	onSubmit: PropTypes.func,
	onSupportRequest: PropTypes.func,

	rating: PropTypes.object,

	feedbackKey: PropTypes.string,
	type: PropTypes.string,
	hideContactUs: PropTypes.bool,
};

const FeedbackModal = (props) => {
	const { open, onClose } = props;

	return (
		<Modal open={open} size="sm" onClose={onClose}>
			<FeedbackModalContent {...props} />
		</Modal>
	);
};

FeedbackModal.displayName = 'FeedbackModal';

FeedbackModal.propTypes = {
	open: PropTypes.bool,
	loading: PropTypes.bool,

	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onSupportRequest: PropTypes.func,

	rating: PropTypes.object,

	feedbackKey: PropTypes.string,
	type: PropTypes.string,
};

export default FeedbackModal;

export { FeedbackModalContent };
