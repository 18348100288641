import React from 'react';

import PropTypes from 'prop-types';

const Content = (props) => {
	const { children } = props;

	return <div className="asteria-component__wrapper-content">{children}</div>;
};

Content.displayName = 'Content';

Content.propTypes = { children: PropTypes.node };

export default Content;
