import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';

import {
	addMonths,
	addWeeks,
	eachWeekOfInterval,
	endOfMonth,
	format,
	isSameISOWeek,
	isThisISOWeek,
	parse,
	startOfMonth,
	subMonths,
	subWeeks,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import { useCurrentDate } from '../../hooks';
import DatePickerContentCell from '../DatePickerContentCell';
import { cleanValue, getLocale, getSelectedCSSClasses } from '../utils';

import './index.scss';

function getCSSClassName(year) {
	return `.asteria-component__datepicker__content__week__header--behavior-scroll--${year}`;
}

function getOffset(element, year) {
	return element?.querySelector(getCSSClassName(year))?.offsetTop;
}

const DEFAULT_LABEL_FMT = 'MM-yyyy';

const DatePickerContentWeekScroll = (props) => {
	const {
		onSelect,
		value: propValue,

		isPastDisabled,
		isFutureDisabled,

		locale,

		startDate,
		endDate,
		disabledDates,
	} = props;

	const currentLocale = getLocale(locale);

	const now = useCurrentDate(propValue);

	const [ref, setRef] = useState(null);
	const [value, setValue] = useState(now);

	const items = useMemo(() => {
		let values = eachWeekOfInterval({
			start: startOfMonth(subMonths(value, 6)),
			end: endOfMonth(addMonths(value, 6)),
		}).reduce((acc, value) => {
			const fmt = cleanValue(format(value, DEFAULT_LABEL_FMT));

			if (acc[fmt] === undefined) {
				acc[fmt] = [];
			}

			acc[fmt].push(value);

			return acc;
		}, {});

		values = Object.fromEntries(
			Object.entries(values).map(([label, dates]) => {
				const extraPreviousDates = 4;
				const totalDatesCount = 12;

				const startDate = dates[0];
				const endDate = dates.slice(-1)[0];

				return [
					label,
					[
						...new Array(extraPreviousDates)
							.fill(null)
							.map((_, index) =>
								subWeeks(startDate, extraPreviousDates - index),
							),
						...dates,
						...new Array(
							totalDatesCount -
								(dates.length + extraPreviousDates),
						)
							.fill(null)
							.map((_, index) => addWeeks(endDate, index + 1)),
					],
				];
			}),
		);

		return values;
	}, [value]);

	const { previousMonth, nextMonth } = useMemo(
		() => ({
			previousMonth: subMonths(value, 1),
			nextMonth: addMonths(value, 1),
		}),
		[value],
	);

	const [currentOffset, previousOffset, nextOffset] = useMemo(() => {
		return [
			getOffset(
				ref,
				cleanValue(
					format(value, DEFAULT_LABEL_FMT, {
						locale: currentLocale,
					}),
				),
			),
			getOffset(
				ref,
				cleanValue(
					format(previousMonth, DEFAULT_LABEL_FMT, {
						locale: currentLocale,
					}),
				),
			),
			getOffset(
				ref,
				cleanValue(
					format(nextMonth, DEFAULT_LABEL_FMT, {
						locale: currentLocale,
					}),
				),
			),
		];
	}, [ref, value]);

	const updateScrollPosition = useCallback(
		(event) => {
			if (event?.target?.scrollTop !== undefined) {
				const {
					target: { scrollTop },
				} = event;

				if (scrollTop < previousOffset) {
					setValue(subMonths(value, 1));
				}

				if (scrollTop > nextOffset) {
					setValue(addMonths(value, 1));
				}
			}
		},
		[value, currentOffset, previousOffset, nextOffset],
	);

	useEffect(() => {
		ref?.addEventListener('scroll', updateScrollPosition);

		return () => {
			ref?.removeEventListener('scroll', updateScrollPosition);
		};
	}, [ref, value]);

	useLayoutEffect(() => {
		ref?.querySelector(
			getCSSClassName(
				cleanValue(
					format(value, DEFAULT_LABEL_FMT, { locale: currentLocale }),
				),
			),
		)?.scrollIntoView();
	}, [ref]);

	return (
		<div className="asteria-component__datepicker__content__week">
			<div
				className={cn(
					'asteria-component__datepicker__content__week__calendar',
					'asteria-component__datepicker__content__week__calendar--behavior-scroll',
					'asteria-component__datepicker__content__calendar',
				)}
				ref={setRef}
			>
				{Object.entries(items).map(([label, dates]) => (
					<div key={label}>
						<div
							className={cn(
								'asteria-component__datepicker__content__week__header',
								'asteria-component__datepicker__content__week__header--behavior-scroll',
								`asteria-component__datepicker__content__week__header--behavior-scroll--${label}`,
							)}
						>
							<Text>
								{cleanValue(
									format(
										parse(
											label,
											DEFAULT_LABEL_FMT,
											new Date(),
										),
										'MMMM, yyyy',
									),
								)}
							</Text>
						</div>
						<div
							className={cn(
								'asteria-component__datepicker__content__week__calendar__values',
								'asteria-component__datepicker__content__week__calendar__values--behavior-scroll',
							)}
						>
							{dates.map((date) => (
								<DatePickerContentCell
									key={['calendar', 'week', date].join('-')}
									date={date}
									onSelect={onSelect}
									value={propValue}
									isPastDisabled={isPastDisabled}
									isFutureDisabled={isFutureDisabled}
									isThisDate={isThisISOWeek(date)}
									className={cn(
										'asteria-component__datepicker__content__week__calendar__item',
										getSelectedCSSClasses({
											value: propValue,
											date: date,
											validate: isSameISOWeek,
										}),
									)}
									format="II"
									startDate={startDate}
									endDate={endDate}
									disabledDates={disabledDates}
									locale={locale}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

DatePickerContentWeekScroll.propTypes = {
	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	locale: Constants.DatePickerLocalePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentWeekScroll.defaultProps = {};

export default DatePickerContentWeekScroll;
