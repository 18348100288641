import React from 'react';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import Accuracy from './accuracy';
import Category from './category';
import Performance from './performance';
import Status from './status';
import Total from './total';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions' | 'onAction' | 'onSubmit'> & { type: 'deposit' | 'withdraw', category: string, tag: string }> } */
const Row = React.memo(function Row(props) {
	const {
		type,
		category,
		tag,
		translationOptions: $translationOptions,
		onAction,

		index,
	} = props;

	const path = [type, 'categories', category, 'tags', tag];
	const source = get($translationOptions?.data?.source, path);
	const target = get($translationOptions?.data?.target, path);

	const translationOptions = React.useMemo(() => {
		const sourceTotal = source?.total ?? 0;
		const targetTotal = target?.total ?? 0;

		const diffValue = targetTotal - sourceTotal;
		const diffPercentage = (diffValue / sourceTotal) * 100;

		const accuracy = {
			diff: { value: null, percentage: null },
			multiplier: null,
			state: null,
			reached: null,
		};

		const performance = {
			value: null,
			percentage: null,
			state: ['WARNING', 'NEEDS_ATTENTION', 'NEUTRAL', 'GOOD'][index % 4],
		};

		return {
			...$translationOptions,
			postfix: {
				...$translationOptions?.postfix,

				section: 'details',
				'section-type': type,
				'section-category': category,
				'section-tag': tag,

				performance: performance.state,
			},
			data: {
				...$translationOptions?.data,

				category: FormatUtils.formatTag({ type, category, tag }),

				source: {
					...$translationOptions?.data?.source,
					value: source,
				},
				target: {
					...$translationOptions?.data?.target,
					value: target,
				},
				diff: {
					...$translationOptions?.data?.diff,
					value: {
						amount: diffValue,
						percentage: diffPercentage,
					},
				},
				accuracy: accuracy,
				performance: performance,
			},
		};
	}, [$translationOptions, category, index, source, tag, target, type]);

	return (
		<div
			className={cn('asteria-component__card-section-list-row', {
				[`asteria--type-${type}`]: type,
			})}
		>
			<Category
				type={type}
				category={category}
				tag={tag}
				translationOptions={translationOptions}
				onAction={onAction}
			/>
			<Performance translationOptions={translationOptions} />
			<Status translationOptions={translationOptions} />
			<Accuracy translationOptions={translationOptions} />
			<Total translationOptions={translationOptions} />
		</div>
	);
});

Row.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default Row;
