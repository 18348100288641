import React from 'react';

import PropTypes from 'prop-types';

import { View as XAxisView } from '@asteria/component-forecaster/components/x-axis';

import { cn } from '@asteria/utils-funcs/classes';

import LoanXAxisColumn from './column';
import { useGraphData } from './hooks';
import LoanXAxisPrefix from './prefix';

import './styles.scss';

const LoanXAxis = React.memo((props) => {
	const { className } = props;

	const data = useGraphData();
	const dates = data.map(({ date }) => date);

	return (
		<XAxisView
			className={cn('asteria-component__financial-loan-axis', className)}
			hasYAxis
			showPrefix
			dates={dates}
			renderItem={({ date }) => (
				<LoanXAxisColumn key={date} date={date} />
			)}
			renderPrefix={() => <LoanXAxisPrefix />}
		/>
	);
});

LoanXAxis.displayName = 'LoanXAxis';

LoanXAxis.propTypes = { className: PropTypes.string };

export default LoanXAxis;
