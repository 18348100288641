import React from 'react';

import PropTypes from 'prop-types';

import { useInitiatingIntegration, useRequiredIntegrations } from '../../hooks';
import BasicCard from '../basic';

import { useRequest } from './hooks';

import './styles.scss';

/**
 * @typedef { import('./types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

function usePromotionVisibility({
	default: defaultValue,
	query,
	loading,
	feedback,
	dismiss,
}) {
	if (feedback || dismiss) {
		return true;
	}

	if (loading) {
		return defaultValue;
	}

	return !Object.values(query?.data?.target ?? {}).some(({ total }) => total);
}

const CONFIG = { content: false, trends: false };

/** @type { React.FC<Props> } */
const ForecastStatusCard = React.memo(function ForecastStatusCard(props) {
	const { startDate, endDate, onSubmit } = props;

	const query = useRequest({
		onSubmit,
		startDate,
		endDate,
	});

	const { required, optional } = useRequiredIntegrations({
		required: ['erp', 'bank'],
		optional: 'both',
	});

	const initiating = useInitiatingIntegration({ type: 'both' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	return (
		<BasicCard
			{...props}
			type="forecast-status"
			query={query}
			integrations={integrations}
			config={CONFIG}
			usePromotionVisibility={usePromotionVisibility}
		/>
	);
});

ForecastStatusCard.displayName = 'ForecastStatusCard';

ForecastStatusCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default ForecastStatusCard;
export { default as ForecastStatusExtra } from './components/extra';
