import React from 'react';

import PropTypes from 'prop-types';

import WelcomeContext from '../../../context';

import QuestionBars from './Bars';
import QuestionContenter from './Contenter';

import './styles.scss';

const Variants = {
	contenter: QuestionContenter,
	bars: QuestionBars,
};

const useVariant = (type) => {
	const { questions: ctxQuestions } = React.useContext(WelcomeContext);
	const variant = ctxQuestions?.[type]?.$type ?? null;

	return React.useMemo(() => variant, [variant]);
};

const Question = (props) => {
	const { className, type, onAction } = props;

	const variant = useVariant(type);

	const Component = Variants?.[variant] ?? null;

	if (!Component) {
		return null;
	}

	return <Component className={className} type={type} onAction={onAction} />;
};

Question.displayName = 'Question';

Question.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onAction: PropTypes.func,
};

export default Question;
