import { endOfMonth, format, parseISO } from 'date-fns';
import { get } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import store from '@asteria/datalayer';

import { createChange, getPath } from './getFormPaths';

const add = ({
	type,
	date,
	category,
	tag,
	value = 0,
	probability = 1,
	form,
}) => {
	const path = getPath({ type, date, category, tag });
	const id = uuid();
	return [
		createChange(
			{
				op: 'set',
				name: `${path}.transactions.${id}`,
				value: {
					uuid: id,
					total: Math.round(value),
					paymentDate: format(
						endOfMonth(parseISO(date)),
						'yyyy-MM-dd',
					),
					description: '',
					currency:
						store.getState()?.app?.company?.settings?.currency ??
						'SEK',
					probability: probability,
					$category: `${category}-${tag}`,
				},
				event: 'transaction:add',
			},
			form,
		),
	];
};

const remove = ({ type, date, category, tag, form, uuid }) => {
	const path = getPath({ type, date, category, tag });
	const transaction = get(form, `${path}.transactions.${uuid}`, {});

	if (transaction._id) {
		return [
			createChange(
				{
					op: 'set',
					name: `${path}.transactions.${uuid}.$deleted`,
					value: true,
					event: 'transaction:remove:$deleted',
				},
				form,
			),
			createChange(
				{
					op: 'set',
					name: `${path}.transactions.${uuid}.total`,
					value: 0,
					event: 'transaction:remove:total',
				},
				form,
			),
		];
	} else {
		return [
			createChange(
				{
					op: 'unset',
					name: `${path}.transactions.${uuid}`,
					event: 'transaction:remove',
				},
				form,
			),
		];
	}
};

const setValue = ({ type, date, category, tag, value = () => 0, form }) => {
	const path = getPath({ type, date, category, tag });
	const transactions = Object.values(get(form, `${path}.transactions`, {}));

	return transactions
		.map((transaction) => {
			if (!transaction || transaction.$deleted) {
				return null;
			}

			const transactionValue = value?.(transaction) ?? transaction?.total;

			return createChange(
				{
					op: 'set',
					name: `${path}.transactions.${transaction.uuid}.total`,
					value: Math.round(transactionValue),
					event: 'transaction:value:set',
				},
				form,
			);
		})
		.filter((item) => item);
};

const setProbability = ({
	type,
	date,
	category,
	tag,
	probability = () => 0,
	form,
}) => {
	const path = getPath({ type, date, category, tag });
	const transactions = Object.values(get(form, `${path}.transactions`, {}));

	return transactions
		.map((transaction) => {
			if (!transaction || transaction.$deleted) {
				return null;
			}

			const transactionProbability =
				probability?.(transaction) ?? transaction?.probability;

			return createChange(
				{
					op: 'set',
					name: `${path}.transactions.${transaction.uuid}.probability`,
					value: transactionProbability,
					event: 'transaction:probability:set',
				},
				form,
			);
		})
		.filter((item) => item);
};

const clear = ({ type, date, category, tag, form }) => {
	const path = getPath({ type, date, category, tag });
	const transactions = Object.values(get(form, `${path}.transactions`, {}));

	return transactions.flatMap(({ uuid }) =>
		remove({ type, date, category, tag, form, uuid }),
	);
};

const move = ({ source, destination, form }) => {
	if (source?.uuid) {
		// move single transaction
		const sourcePath = getPath(source);
		const destinationPath = getPath(destination);
		const transaction = get(form, sourcePath, null);

		if (!transaction) {
			return [];
		}

		const newUUID = uuid();

		return [
			createChange(
				{
					op: 'set',
					name: `${destinationPath}.transactions.${newUUID}`,
					value: {
						...transaction,
						uuid: newUUID,
						_id: undefined,
						id: undefined,
						$category: [
							destination?.category,
							destination.tag,
						].join('-'),
					},
					event: 'transaction:move',
				},
				form,
			),
			...remove({ ...source, form }),
		];
	} else {
		// Move all transactions
	}

	return [];
};

export default ({
	path,
	field = 'total',
	transactions,
	faction,
	value,
	probability = () => 1,
	getValues,
}) => {
	if (transactions.length === 0 && value) {
		const date = path?.split?.('.')?.[2];

		return [
			createChange(
				{
					op: 'set',
					name: `${path}.transactions.${uuid()}`,
					root: `${path}.transactions.${uuid()}`,
					value: {
						total: Math.round(value),
						paymentDate: format(
							endOfMonth(parseISO(date)),
							'yyyy-MM-dd',
						),
						description: '',
						probability: probability?.({}) ?? 1,
					},
					event: 'transaction:adjust:create',
				},
				getValues(),
			),
		];
	}

	return transactions.flatMap((id) => {
		const item = getValues(`${path}.transactions.${id}`);

		if (!item) {
			return [];
		}

		const transValue = item?.[field];
		let newVal = transValue * faction;
		const newProbability = probability?.(item) ?? 1;

		if (value !== false) {
			newVal = value;
		}

		if (Number.isNaN(newVal)) {
			newVal = 0;
		}

		const ops = [];

		ops.push(
			createChange(
				{
					op: 'set',
					name: `${path}.transactions.${id}.probability`,
					value: newProbability,
					event: 'transaction:adjust:probability',
				},
				getValues(),
			),
		);

		if (newVal !== 0) {
			ops.push(
				createChange(
					{
						op: 'set',
						name: `${path}.transactions.${id}.${field}`,
						value: Math.round(newVal),
						event: 'transaction:adjust:value',
					},
					getValues(),
				),
			);
		}

		if (newVal !== 0 && item.$deleted) {
			ops.push(
				createChange(
					{
						op: 'set',
						name: `${path}.transactions.${id}.$deleted`,
						value: false,
						event: 'transaction:adjust:$deleted',
					},
					getValues(),
				),
			);
		} else if (newVal === 0) {
			ops.push(
				createChange(
					{
						op: item._id ? 'set' : 'unset',
						name: item._id
							? `${path}.transactions.${id}.$deleted`
							: `${path}.transactions.${id}`,
						value: true,
						event: 'transaction:adjust:delete',
					},
					getValues(),
				),
			);

			ops.push(
				createChange(
					{
						op: 'set',
						name: `${path}.transactions.${id}.total`,
						value: 0,
						event: 'transaction:adjust:total',
					},
					getValues(),
				),
			);
		}

		return ops;
	});
};

export { setValue, setProbability, add, remove, clear, move };
