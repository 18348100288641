import React, { useEffect, useMemo } from 'react';

import { useSelector, useStore } from 'react-redux';

import { isEqual } from 'lodash-es';

import AsteriaCore from '@asteria/core';

import { getViewport } from '@asteria/component-core/utils';

import {
	FeatureFlag,
	useBulkFeatures,
	useFeature,
} from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';
import getLayoutSize from '@asteria/utils-funcs/getLayoutSize';
import useComponentSize from '@asteria/utils-hooks/useComponentSize';
import useHasScrolledPassed from '@asteria/utils-hooks/useHasScrolledPassed';

import TimesliceSelector from '../components/time';
import Graph from '../graphs/graph';
import { useGraphMode, useLegendClickable, useLegendHoverable } from '../hooks';
import Legends from '../legends';
import Navigation from '../navigation';
import * as Selectors from '../selectors';

const StackedLayout = React.forwardRef(
	(
		{
			onAction,
			className,
			next,
			prev,
			onClick,
			onMouseEnter,
			onMouseLeave,
			getTooltip,
			updateSize,
			requestData,
			onUpdateSettings,
			onResize,
			variant,
		},
		ref,
	) => {
		// const { dispatch } = useContext(DatalayerContext);
		const store = useStore();

		const currentTimesliceSize = useSelector(
			Selectors.common.currentTimesliceSize,
			(a, b) => isEqual(a, b),
		);

		const mode = useGraphMode();

		const hasTagFilters = useSelector(
			Selectors.filters.hasTagFilters,
			(a, b) => isEqual(a, b),
		);

		const tags = useSelector(Selectors.filters.tags, (a, b) =>
			isEqual(a, b),
		);

		const statuses = useSelector(Selectors.filters.statuses, (a, b) =>
			isEqual(a, b),
		);

		const typeFilters = useSelector(Selectors.filters.typeFilters, (a, b) =>
			isEqual(a, b),
		);

		const hasUnpaid = useSelector(Selectors.graph.hasUnpaid, (a, b) =>
			isEqual(a, b),
		);

		const hasOverdue = useSelector(Selectors.graph.hasOverdue, (a, b) =>
			isEqual(a, b),
		);

		const hasForecast = useSelector(Selectors.graph.hasForecast, (a, b) =>
			isEqual(a, b),
		);

		const hasCredit = useSelector(Selectors.graph.hasCredit, (a, b) =>
			isEqual(a, b),
		);

		const graphOptions = useSelector(Selectors.graph.options, (a, b) =>
			isEqual(a, b),
		);

		const availableCategories = useMemo(() => [], []);

		const hideAccountBalanceAxis = useFeature(
			'graph-account-balance-without-xaxis',
		);

		const hasPassed = useHasScrolledPassed(ref, -200);

		let navigationFeature = null;

		if (variant === 'credit') {
			navigationFeature = 'graph-navigation-overview-credit';
		}

		if (variant === 'cashflow') {
			navigationFeature = 'graph-navigation-overview-cashflow';
		}

		const hasNavigationFeature = useBulkFeatures(
			['graph-navigation-overview', navigationFeature].filter(Boolean),
		);

		const hasLargeBreakpoint = useFeature(
			'cashflow-graph-breakpoint-large',
		);
		const hasMediumBreakpoint = useFeature(
			'cashflow-graph-breakpoint-medium',
		);
		const hasSelectableFeature = useFeature('graph-legend-selectable');

		const hasGraphOptionsFeature = useFeature('cashflow-graph-options');

		// const hasSpreadDotsFeature = useFeature('graph-spread-info-dots');
		const hasDisableFutureFeature = useFeature(
			'graph-disable-forecast-line',
		);
		const shouldDisableFuture = hasDisableFutureFeature;

		const hasCategoryLegendFeature = useFeature('graph-legend-categories');

		const filterDeposit = !(
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'DEPOSIT')
		);

		const filterWithdraw = !(
			typeFilters.length === 0 ||
			typeFilters.find(({ config: { type } }) => type === 'WITHDRAW')
		);

		const handleUpdateSize = React.useMemo(
			() =>
				AsteriaCore.utils.throttle((size) => {
					const [, height] = getViewport();
					const isTransactionListShown =
						Selectors.common.isTransactionListShown(
							store.getState(),
						);

					if (isTransactionListShown) {
						onUpdateSettings?.({
							layout: {
								size: {
									[height]: {
										width: size.width,
										height: size.height,
									},
								},
							},
						});
					}
				}, 1000),
			[onUpdateSettings, store],
		);

		const maxSizeValue = React.useMemo(() => {
			const [width, height] = getViewport();

			return { height: height, width: width };
		}, []);

		const { height } = useComponentSize({
			ref: ref,
			callback: handleUpdateSize,
			max: maxSizeValue,
		});

		const layoutSize = getLayoutSize(height);

		useEffect(() => {
			if (!hasLargeBreakpoint && !hasMediumBreakpoint) {
				onResize?.(layoutSize);
			}
		}, [hasLargeBreakpoint, hasMediumBreakpoint, layoutSize, onResize]);

		const shouldHideAccountBalance = useMemo(() => {
			return !hasGraphOptionsFeature;
		}, [hasGraphOptionsFeature]);

		const legends = useMemo(() => {
			let legends = [
				{
					part: 'account',
					type: ['account', 'paid'],
					title: TranslationService.get(
						'graph.account.legend.history',
					),
				},
			];

			if (!hasTagFilters) {
				legends.push({
					part: 'cashflow',
					type: 'deposit',
					title: TranslationService.get([
						'tags.deposit',
						'graph.legend.deposit',
					]),
				});
			}

			if (!hasTagFilters) {
				legends.push({
					part: 'cashflow',
					type: 'withdraw',
					title: TranslationService.get([
						'tags.withdraw',
						'graph.legend.withdraw',
					]),
				});
			}

			if (hasCategoryLegendFeature) {
				legends = legends.concat(
					tags.map((object) => ({
						part: 'cashflow',
						color: object?.item?.color,
						type: [object?.item?.category?.name, object?.item?.name]
							.filter(Boolean)
							.map((value) => value.replace(/\$/g, ''))
							.join('-'),
						title: FormatUtils.formatTag({
							category: object?.item?.category?.name,
							tag: object?.item?.name,
						}),
					})),
				);
			}

			if (
				(!statuses.length ||
					statuses.some(({ id }) => id === 'UNPAID')) &&
				hasUnpaid
			) {
				legends.push({
					part: 'cashflow',
					type: 'unpaid',
					title: TranslationService.get([
						'tags.unpaid',
						'graph.legend.unpaid',
					]),
				});
			}

			if (
				(!statuses.length ||
					statuses.some(({ id }) => id === 'OVERDUE')) &&
				hasOverdue
			) {
				legends.push({
					part: 'cashflow',
					type: 'overdue',
					title: TranslationService.get([
						'tags.overdue',
						'graph.legend.overdue',
					]),
				});
			}

			if (
				(!statuses.length ||
					statuses.some(({ id }) => id === 'FORECAST')) &&
				hasForecast
			) {
				if (!shouldDisableFuture) {
					legends.push({
						part: 'account',
						type: ['account', 'forecast'],
						title: TranslationService.get(
							'graph.account.legend.forecast',
						),
					});
				}

				legends.push({
					part: 'account',
					type: 'spread',
					title: TranslationService.get(
						'graph.account.legend.spread',
					),
				});

				legends.push({
					part: 'cashflow',
					type: 'forecast',
					title: TranslationService.get([
						'tags.forecast',
						'graph.legend.forecast',
					]),
				});
			}

			if (hasCredit) {
				legends.push({
					part: 'account',
					type: 'credit',
					title: TranslationService.get(
						'graph.account.legend.credit',
					),
				});
			}

			if (!graphOptions?.lineGraph) {
				return legends.filter(({ part }) => part === 'cashflow');
			}

			if (!graphOptions?.barGraph) {
				return legends.filter(({ part }) => part === 'account');
			}

			return legends;
		}, [
			hasTagFilters,
			hasCategoryLegendFeature,
			statuses,
			hasUnpaid,
			hasOverdue,
			hasForecast,
			hasCredit,
			graphOptions?.lineGraph,
			graphOptions?.barGraph,
			tags,
			shouldDisableFuture,
		]);

		const parts = React.useMemo(() => ['bars', 'lines'], []);

		const legendClickable = useLegendClickable();
		const legendHoverable = useLegendHoverable();

		return (
			<div
				className={cn(
					className,
					'asteria-cashflow-graph',
					'asteria-cashflow-graph-stacked',
					`asteria-cashflow__mode-${mode}`,
					`asteria-state-${layoutSize}`,
					{
						'asteria-has-scrolled-passed': hasPassed,
						'asteria-cashflow-graph-has-filter': hasTagFilters,
						'asteria-cashflow-graph-has-no-filter': !hasTagFilters,
						'asteria-cashflow-graph-hide-deposit': filterDeposit,
						'asteria-cashflow-graph-hide-withdraw': filterWithdraw,
						'asteria-cashflow-graph-hide-account-balance':
							shouldHideAccountBalance,
					},
				)}
				ref={ref}
			>
				{!hasNavigationFeature ? (
					<Navigation
						onNext={next}
						onPrev={prev}
						className="asteria-cashflow-graph-navigation"
					/>
				) : null}
				<Legends
					data={legends}
					className="asteria-graph-revenue-graph-legends"
					clickable={legendClickable && hasSelectableFeature}
					hoverable={legendHoverable && hasSelectableFeature}
					negative
				/>
				<FeatureFlag feature="timeSpanDropdown">
					<TimesliceSelector />
				</FeatureFlag>
				<Graph
					next={next}
					prev={prev}
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					getTooltip={getTooltip}
					size={currentTimesliceSize}
					updateSize={updateSize}
					availableCategories={availableCategories}
					showXAxis={!hideAccountBalanceAxis}
					barLayout="stacked"
					parts={parts}
					mode={mode}
					requestData={requestData}
					onUpdateSettings={onUpdateSettings}
					onAction={onAction}
				/>
			</div>
		);
	},
);
StackedLayout.displayName = 'StackedLayout';

export default StackedLayout;
