import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { isPossibleToClick, stateClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import ActionBar from './ActionBar';
import {
	ActionBarSectionActions,
	ActionBarSectionContent,
	ActionBarSectionPrefix,
} from './sections';

import './index.scss';

const Action = (props) => {
	const {
		className,
		onClick,
		icon,
		title,
		content,
		variant,
		loading,
		actions,
	} = props;

	const ref = React.useRef(null);

	const handleClick = React.useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			return onClick?.(event);
		},
		[onClick],
	);

	return (
		<ActionBar
			className={cn(
				'asteria-component__actions-action',
				{ [`asteria-action--variant-${variant}`]: variant },
				stateClasses({ onClick: onClick }),
				className,
			)}
			ref={ref}
			onClick={handleClick}
		>
			{icon ? (
				<ActionBarSectionPrefix>
					<Icon icon={icon} />
				</ActionBarSectionPrefix>
			) : null}
			<ActionBarSectionContent title={title} content={content} />
			{variant === 'link' ? (
				<ActionBarSectionActions>
					{loading ? (
						<Button loading />
					) : (
						<Button icon="action-arrow" />
					)}
				</ActionBarSectionActions>
			) : (
				actions
			)}
		</ActionBar>
	);
};

Action.displayName = 'Action';
Action.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	variant: PropTypes.oneOf(['link']),
	loading: PropTypes.bool,
	actions: PropTypes.node,
};

const Actions = (props) => {
	const { className, children, actions } = props;

	if (!children && !actions?.length) {
		return null;
	}

	return (
		<div className={cn('asteria-component__actions', className)}>
			{(actions ?? []).map((object, index) => (
				<Action key={index} {...object} />
			))}
			{children}
		</div>
	);
};

Actions.displayName = 'Actions';

Actions.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	actions: PropTypes.arrayOf(PropTypes.object),
};

export default Actions;
export { Action };
