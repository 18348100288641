import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

const selectors = {
	filters: createSelector(
		(store) => store?.app?.filters,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

function getTagClassName(value) {
	return [
		// [value.type, value?.item?.name].join('-').replace(/\$/g, ''),
		// [value.type, value?.item?.category?.name, value?.item?.name]
		// 	.join('-')
		// 	.replace(/\$/g, ''),
		// [value?.item?.name].join('-').replace(/\$/g, ''),
		[value?.item?.category?.name, value?.item?.name]
			.join('-')
			.replace(/\$/g, ''),
	];
}

function getClientClassName(value) {
	return [
		[value.type, value?.item?.type].join('-'),
		[
			value.type,
			value?.item?.type,
			(value?.item?.name ?? '').replace(/\s/g, '__'),
		].join('-'),
	];
}

function getCurrencyClassName(value) {
	return [[value.type, value.id].join('-')];
}

function getStatusClassName(value) {
	return [value.id];
	// return [[value.type, value.id].join('-'), value.id];
}

/**
 *
 * @param { { prefix?: string, separator?: string, useTags?: boolean, useClients?: boolean, useCurrencies?: boolean, useStatuses?: boolean } } options
 * @returns { string[] }
 */
const useFilterClassNames = (options = {}) => {
	const {
		prefix = '',
		separator = '-',
		useTags = true,
		useClients = true,
		useCurrencies = true,
		useStatuses = true,
	} = options;

	return useSelector(selectors.filters)
		.flatMap((value) => {
			switch (value.type) {
				case 'tag':
					return useTags ? getTagClassName(value) : null;

				case 'client':
					return useClients ? getClientClassName(value) : null;

				case 'currency':
					return useCurrencies ? getCurrencyClassName(value) : null;

				case 'status':
					return useStatuses ? getStatusClassName(value) : null;

				default:
					return null;
			}
		})
		.filter(Boolean)
		.map((value) => value.toLowerCase())
		.map((value) => [prefix, value].join(separator));
};

export default useFilterClassNames;
