import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

/**
 * @typedef { import('@tanstack/react-query').UseQueryResult } UseQueryResult
 */

/**
 * @typedef PropsPostfix
 * @property { boolean } future
 * @property { boolean } today
 * @property { boolean } past
 * @property { string } variant
 *
 * @typedef Props
 * @property { 'up' | 'down' } direction
 * @property { 'xs' | 'sm' | 'md' | 'lg' | 'xl' } size
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { PropsPostfix } postfix
 * @property { unknown } data
 */

/**
 * @typedef GetDiffOptions
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { string | string[] } status
 * @property { string | string[] } [sourceStatus]
 * @property { string | string[] } [targetStatus]
 *
 * @param { GetDiffOptions } options
 */
function getDiff({
	query,
	status,
	sourceStatus = status,
	targetStatus = status,
}) {
	const source = []
		.concat(sourceStatus)
		.reduce(
			(acc, status) => acc + (query?.data?.source?.[status]?.total ?? 0),
			0,
		);

	const target = []
		.concat(targetStatus)
		.reduce(
			(acc, status) => acc + (query?.data?.target?.[status]?.total ?? 0),
			0,
		);

	const value = source - target;

	return {
		percentage: Math.round((value / target) * 100),
		value: value,
	};
}

function useDiff({ query }) {
	return {
		PAID: getDiff({ query, status: 'PAID' }),
		OVERDUE: getDiff({ query, status: 'OVERDUE' }),
		UNPAID: getDiff({ query, status: 'UNPAID' }),
		FORECAST: getDiff({ query, status: 'FORECAST' }),
		CREDIT: getDiff({ query, status: 'CREDIT' }),

		PAST: getDiff({ query, status: 'PAID' }),
		TODAY: getDiff({
			query,
			status: ['FORECAST', 'PAID'],
			targetStatus: 'PAID',
		}),
		FUTURE: getDiff({
			query,
			status: 'FORECAST',
			targetStatus: ['FORECAST', 'PAID'],
		}),
	};
}

/** @type { React.FC<Props> } */
const TrendIcon = function TrendIcon({
	direction,
	query,
	size,
	postfix = {},
	data = {},
}) {
	const diff = useDiff({ query });

	const translationOptions = {
		data: {
			...data,
			data: query?.data?.source,
			source: query?.data?.source,
			target: query?.data?.target,
			diff: diff,
			direction: direction,
		},
		postfix: postfix,
	};

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="card.trends.tooltip"
					translationOptions={translationOptions}
					Component={Text}
				/>
			}
		>
			<Icon
				icon={
					direction === 'up' ? 'arrow-up-right' : 'arrow-down-right'
				}
				size={size}
			/>
		</TooltipWrapper>
	);
};

TrendIcon.propTypes = {
	direction: PropTypes.oneOf(['up', 'down']),

	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),

	query: PropTypes.object,

	postfix: PropTypes.shape({
		variant: PropTypes.string,
		future: PropTypes.bool,
		today: PropTypes.bool,
		past: PropTypes.bool,
	}),

	data: PropTypes.object,
};

export default TrendIcon;
