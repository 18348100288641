import React from 'react';

import { isSameMonth, parseISO, startOfYear } from 'date-fns';
import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import { useLineType } from '../../hooks';
import { getDotPosition } from '../utils';

import '../styles.scss';

const View = React.memo((props) => {
	const {
		line,
		min,
		max,
		height = 100,
		tooltip,
		hidden,
		invisible,
		label,

		hideLabel,
		type,
	} = props;

	const { date, value } = line;

	const { isStartOfYear } = React.useMemo(() => {
		if (!date) {
			return false;
		}

		const $date = parseISO(date);

		return { isStartOfYear: isSameMonth($date, startOfYear($date)) };
	}, [date]);

	const top = getDotPosition({
		max: max,
		min: min,
		value: value,
		height: height,
	});

	const style = React.useMemo(() => ({ '--top': `${top}px` }), [top]);

	const lineType = useLineType(date, type);

	return (
		<div
			className={cn('asteria-component__graph-dot', {
				'asteria--state-close-to-bottom': top >= height - 50,
				'asteria--state-current': lineType === 'current',
				'asteria--state-hidden': hidden,
				'asteria--state-invisible': invisible,
				'asteria--state-has-label': !hideLabel,
			})}
			style={style}
		>
			<TooltipWrapper tooltip={tooltip} custom>
				<div className="asteria-component__graph-dot-wrapper">
					<div className="asteria-component__graph-dot-shape" />
					{!hideLabel ? (
						<div className="asteria-component__graph-dot-label">
							<Text size="sm">{formatNumber(value, false)}</Text>
							{label !== undefined ? (
								label
							) : (
								<Text size="xs">
									{TranslationService.get(
										[
											'forecaster.linegraph.dot.date',
											isStartOfYear
												? 'forecaster.linegraph.dot.date.startOfYear'
												: null,
										],
										undefined,
										{ date: date },
									)}
								</Text>
							)}
						</div>
					) : null}
				</div>
			</TooltipWrapper>
		</div>
	);
});

View.displayName = 'Dot';
View.propTypes = {
	line: PropTypes.shape({ date: PropTypes.string, value: PropTypes.number }),
	min: PropTypes.number,
	max: PropTypes.number,
	hoverable: PropTypes.bool,
	showMonth: PropTypes.bool,

	height: PropTypes.number,
	tooltip: PropTypes.node,
	hidden: PropTypes.bool,
	invisible: PropTypes.bool,

	label: PropTypes.node,
	hideLabel: PropTypes.bool,
	type: PropTypes.oneOf(['month', 'year']),
};

export default View;
