import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import Help from './help';
import Overview from './overview';

/**
 * @typedef { import('../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'onAction' | 'onSubmit' | 'translationOptions'>> } */
const ForecastStatusExtraContent = React.memo(
	function ForecastStatusExtraContent({
		onAction,
		onSubmit,
		translationOptions,
	}) {
		return (
			<div className="flex flex-col gap-8">
				<Overview
					type="deposit"
					translationOptions={translationOptions}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<Overview
					type="withdraw"
					translationOptions={translationOptions}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<Help translationOptions={translationOptions} />
			</div>
		);
	},
);

ForecastStatusExtraContent.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

/** @type { React.FC<ChildrenOptions> } */
const ForecastStatusExtra = React.memo(function ForecastStatusExtra({
	onAction,
	onSubmit,
	translationOptions,
	loading,
}) {
	return (
		<>
			<ForecastStatusExtraContent
				onAction={onAction}
				onSubmit={onSubmit}
				translationOptions={translationOptions}
			/>
			<Translation
				translationKey="card.content.text"
				translationOptions={translationOptions}
				showWhenEmpty={false}
				Component={Text}
				loading={loading}
			/>
		</>
	);
});

ForecastStatusExtra.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	query: PropTypes.object,
	translationOptions: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default ForecastStatusExtra;
