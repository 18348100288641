import React from 'react';

import styled, { keyframes } from 'styled-components';

import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Wrapper = styled(({ className, children }) => {
	return <div className={className}>{children}</div>;
})`
	// display: flex;
`;

const pulseAnimation = keyframes`
	// 0% {
	// 	transform: scale(0.95) translate(-50%, -50%);
	// 	box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
	// }

	// 70% {
	// 	transform: scale(1) translate(-50%, -50%);
	// 	box-shadow: 0 0 0 6px rgba(255, 121, 63, 0);
	// }
	// 100% {
	// 	transform: scale(0.95) translate(-50%, -50%);
	// 	box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
	// }
}`;

const Prefix = styled.span`
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 5px;
`;
Prefix.displayName = 'Prefix';

const Part = styled(
	({ className, active, onClick = () => {}, part, children }) => (
		<div
			className={cn(className, `asteria-part-${part}`, {
				'asteria-state-active': active,
			})}
			onClick={() => onClick(part)}
			onKeyPress={() => onClick(part)}
			role="button"
			tabIndex="-1"
		>
			<Prefix className="asteria-component__prefix" />
			{children}
		</div>
	),
)`
	// display: flex;
	// margin-right: 5px;
	// align-items: center;

	// &.asteria-part-risk {
	// 	${Prefix} {
	// 		border: 2px solid #f35c1b;
	// 		animation: ${pulseAnimation} 2s infinite;
	// 		margin-top: 10px;
	// 	}
	// }
`;

Part.displayName = 'Part';

const Legends = styled(({ className, legends = [], scroll }) => (
	<Wrapper
		className={cn(className, 'asteria-component__legends')}
		horizontalScroll={scroll}
	>
		{legends.map(({ key, label }) => (
			<Part
				key={key}
				part={key}
				className="asteria-component__legends__legend"
			>
				<Text type="label">{label}</Text>
			</Part>
		))}
	</Wrapper>
))`
	display: flex;
	position: relative;
`;
Legends.displayName = 'Legends';

export default Legends;
