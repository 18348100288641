import React from 'react';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import CreditActionBar from '../../../../components/credit/actionbar';

import '../../styles.scss';

const SectionFooter = React.memo(() => {
	const { id } = useParams();

	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-footer',
			)}
		>
			<CreditActionBar simulationId={id} />
		</div>
	);
});

SectionFooter.displayName = 'SectionFooter';
SectionFooter.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionFooter;
