import React from 'react';

import PropTypes from 'prop-types';

import { useStateHidden, useStateInvisible } from '../../hooks';

import View from './view';

const Provider = React.memo((props) => {
	const {
		line,
		previousLine = line,
		nextLine = line,
		max,
		min,
		height = 100,
	} = props;

	const { date } = line;

	const hiddenState = useStateHidden({ date: date });
	const invisibleState = useStateInvisible({ date: date });

	return (
		<View
			line={line}
			previousLine={previousLine}
			nextLine={nextLine}
			max={max}
			min={min}
			height={height}
			hiddenSpread={hiddenState?.spread}
			invisibleSpread={invisibleState?.spread}
			hiddenLineHistory={hiddenState?.line?.history}
			invisibleLineHistory={invisibleState?.line?.history}
			hiddenLineFuture={hiddenState?.line?.future}
			invisibleLineFuture={invisibleState?.line?.future}
		/>
	);
});

Provider.displayName = 'LineProvider';

Provider.propTypes = {
	line: PropTypes.any,
	previousLine: PropTypes.any,
	nextLine: PropTypes.any,
	max: PropTypes.number,
	min: PropTypes.number,

	height: PropTypes.number,
};

export default Provider;
