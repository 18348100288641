import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { useDebounceFormValues } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import * as utils from '../utils';

/**
 * @typedef { import('../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'startDate' | 'endDate' | 'onAction' | 'onSubmit'>> } */
const AutoSave = React.memo(function AutoSave(props) {
	const { startDate, endDate, onSubmit } = props;

	const form = useDebounceFormValues({ delay: 1000 });

	const scenarioName = useSelector(
		ScenarioStore.selectors.sourceScenario,
		(a, b) => isEqual(a, b),
	);

	const mutation = useMutation({
		mutationFn: async ({ form, startDate, endDate, scenarioName }) => {
			if (!form?.$ready) {
				return;
			}

			try {
				await onSubmit('card:submit', {
					type: 'forecast',
					data: { form, startDate, endDate, scenarioName },
				});
			} catch (err) {
				// eslint-disable-next-line no-console
				console.warn('card:save', err);
			}
		},
	});

	React.useEffect(() => {
		mutation.mutate({ form, startDate, endDate, scenarioName });
	}, [endDate, form, scenarioName, startDate]);

	return null;
});

AutoSave.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<{}> } */
const TagValidation = React.memo(function TagValidation() {
	const { getValues, setValue, reset } = useFormContext();

	const tags = useSelector(
		(store) =>
			AppStore.selectors.tags(store).map((object) => ({
				category: object?.category?.name,
				tag: object?.name,
			})),
		(a, b) => isEqual(a, b),
	);

	React.useEffect(() => {
		const ctx = { getValues, setValue, reset };

		const changes = utils.form.validate.tags(tags, ctx);
		utils.form.changes.apply(changes, ctx);
	}, [getValues, reset, setValue, tags]);

	return null;
});

/** @type { React.FC<Pick<ChildrenOptions, 'startDate' | 'endDate' | 'onAction' | 'onSubmit'>> } */
const Updater = React.memo(function Updater(props) {
	return (
		<>
			<TagValidation />
			<AutoSave {...props} />
		</>
	);
});

Updater.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Updater;
