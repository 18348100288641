import React from 'react';

import PropTypes from 'prop-types';

import { View as LineGraphDotView } from '@asteria/component-forecaster/components/graph/lines/column/dot';

import LoanContext from '../../../../context/loan';

import LoanLineGraphTooltip from './tooltip';

const LoanLineGraphDot = React.memo((props) => {
	const { current: line, max, min, height = 100 } = props;

	const { range } = React.useContext(LoanContext);

	return (
		<LineGraphDotView
			line={line}
			min={min}
			max={max}
			height={height}
			hideLabel
			type={range}
			tooltip={<LoanLineGraphTooltip data={line} />}
			// tooltip={tooltip}
			// hidden={hidden}
			// invisible={invisible}
		/>
	);
});

LoanLineGraphDot.displayName = 'LoanLineGraphDot';
LoanLineGraphDot.propTypes = {
	current: PropTypes.object,
	previous: PropTypes.object,
	next: PropTypes.object,
	max: PropTypes.number,
	min: PropTypes.number,
	height: PropTypes.number,
};

export default LoanLineGraphDot;
