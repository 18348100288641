import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import { CATEGORIES } from '../../constants';
import { getTagLabel, replace, shuffle } from '../../utils';

import './styles.scss';

const Switcher = (props) => {
	const { items } = props;

	const [index, setIndex] = React.useState(0);

	React.useEffect(() => {
		const timeout = setInterval(() => {
			setIndex((value) => {
				if (value + 1 >= items.length) {
					return 0;
				}

				return value + 1;
			});
		}, 1000);

		return () => {
			clearInterval(timeout);
			setIndex(0);
		};
	}, [items?.length]);

	const item = items?.[index] ?? null;

	if (item === null) {
		return null;
	}

	const { type, value } = item;

	return (
		<Group
			className="asteria-view__welcome__loader__content"
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="center"
		>
			<Text>
				{TranslationService.get([
					'welcome.loading.category',
					`welcome.loading.${type}.category`,
				])}
			</Text>
			<Chip
				colors={replace(value)}
				label={getTagLabel(value)}
				variant="simple"
			/>
		</Group>
	);
};

Switcher.displayName = 'Switcher';
Switcher.propTypes = { items: PropTypes.arrayOf(PropTypes.string) };

const CategoryLoader = React.memo(() => {
	const hasDelayDone = useFeature('welcome-delay-done');

	const categories = useSelector(
		(store) => {
			const integrations = IntegrationStore.selectors.integrations(
				store,
				{ status: 'INITIATING' },
			);

			const hasERP = integrations.some(({ type }) => type === 'erp');
			const hasBank = integrations.some(({ type }) => type === 'bank');

			let categories = [];

			if (hasERP) {
				categories = categories.concat(
					CATEGORIES.ERP.map((value) => ({
						type: 'erp',
						value: value,
					})),
				);
			}

			if (hasBank || hasDelayDone) {
				categories = categories.concat(
					CATEGORIES.BANK.map((value) => ({
						type: 'bank',
						value: value,
					})),
				);
			}

			return categories;
		},
		(a, b) => isEqual(a, b),
	);

	if (!categories.length) {
		return null;
	}

	return <Switcher items={shuffle(categories)} />;
});

CategoryLoader.displayName = 'CategoryLoader';

export default React.memo(CategoryLoader);
