import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { Title } from '../typography';
import { stateClasses } from '../utils';
import Wrapper, { Content, Header } from '../wrapper';

import './styles.scss';

const Teaser = React.memo((props) => {
	const {
		className,
		path,
		translationData,
		actions,
		deep = true,
		children,
		onAction,
	} = props;

	const config = useConfig(path, { deep: deep });

	const onClick = React.useCallback(() => {
		if (!config?.action) {
			return;
		}

		return onAction?.(...[].concat(config?.action));
	}, [config?.action, onAction]);

	return (
		<Wrapper
			className={cn(
				'asteria-component__teaser',
				stateClasses({ onClick: !!config?.action }),
				className,
			)}
			onClick={onClick}
		>
			{config?.title ? (
				<Header className="asteria-component__teaser-header">
					<Title size={config?.title?.size ?? 'xs'}>
						{TranslationService.get(
							[].concat(config?.title?.value ?? config?.title),
							config?.title?.value ?? config?.title,
						)}
					</Title>
				</Header>
			) : null}
			<Contenter
				className="asteria-component__teaser-content"
				content={config?.content}
				actions={actions}
				translationData={translationData}
				as={Content}
			/>
			{children}
		</Wrapper>
	);
});

Teaser.displayName = 'Teaser';

Teaser.propTypes = {
	className: PropTypes.string,
	path: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	actions: PropTypes.object,
	translationData: PropTypes.object,
	deep: PropTypes.bool,
	children: PropTypes.node,
	onAction: PropTypes.func,
};

export default Teaser;
