import React from 'react';

import PropTypes from 'prop-types';

import { useManualFormValues } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { findParentByClassname } from '@asteria/utils-funcs/node';

import getFormPaths from '../../../../../../utils/getFormPaths';

import ItemView from './view';

const ForecasterTransactionConfig = {
	'$invoices-$customer': {
		type: 'select',
		name: 'descriptionClient',
		dataSource: 'app.clients',
		filters: { type: 'CUSTOMER' },
	},
	'$invoices-$supplier': {
		type: 'select',
		name: 'descriptionClient',
		dataSource: 'app.clients',
		filters: { type: 'SUPPLIER' },
	},
};

const TransactionItemProvider = React.memo((props) => {
	const { className, name, onAction, showCategory, types } = props;

	const { type } = getFormPaths(name);

	const currencySelectorRef = React.useRef(null);

	const [isMarkedAsRemoved, transactionCategory] = useManualFormValues({
		name: [[name, '$deleted'].join('.'), [name, '$category'].join('.')],
	});

	const [removed, setRemoved] = React.useState(false);

	const handleChange = React.useCallback(
		(args) => {
			Analytics.event('forecaster.transactions.update', args);

			onAction?.('transaction:update', args);
		},
		[onAction],
	);

	const handleRemove = React.useCallback(() => {
		setRemoved(true);

		onAction?.('transaction:remove', { name: `${name}.$delete` });
	}, [name, onAction]);

	const inputConfig = React.useMemo(() => {
		return ForecasterTransactionConfig[transactionCategory] || null;
	}, [transactionCategory]);

	const placeholder = React.useMemo(() => {
		if (inputConfig) {
			return null;
		}

		const path = name.split('.');
		const category = path?.[5]?.replace('$', '');
		const tag = path?.[7]?.replace('$', '');

		return TranslationService.get(
			'forecast.transactions.content.item.field.description',
			'forecast.transactions.content.item.field.description.default',
			{
				category: category,
				tag: tag,
			},
		);
	}, [inputConfig, name]);

	const InputFormat = React.useMemo(() => ({ valueAsNumber: true }), []);

	const analytics = React.useMemo(() => ({ name: name }), [name]);

	const [$style, setStyle] = React.useState({});

	React.useLayoutEffect(() => {
		const timeout = setTimeout(() => {
			if (currencySelectorRef.current) {
				const node = findParentByClassname(
					currencySelectorRef.current,
					'asteria-component__select',
				);

				const rect = node.getBoundingClientRect();

				setStyle({ '--default-currency-width': `${rect.width}px` });
			}
		}, 500);

		return () => {
			clearTimeout(timeout);
		};
	}, [name]);

	const style = React.useMemo(() => $style, [$style]);

	if (removed || isMarkedAsRemoved) {
		return null;
	}

	return (
		<ItemView
			className={className}
			name={name}
			style={style}
			types={types}
			type={type}
			inputConfig={inputConfig}
			placeholder={placeholder}
			showCategory={showCategory}
			handleChange={handleChange}
			InputFormat={InputFormat}
			handleRemove={handleRemove}
			currencySelectorRef={currencySelectorRef}
			analytics={analytics}
		/>
	);
});

TransactionItemProvider.displayName = 'TransactionItemProvider';

TransactionItemProvider.propTypes = {
	className: PropTypes.string,
	types: PropTypes.arrayOf(PropTypes.string),
	name: PropTypes.string,
	onAction: PropTypes.func,
	showCategory: PropTypes.bool,
};

export default TransactionItemProvider;
