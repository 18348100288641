import { useRole } from '.';
import { ADMIN_ROLES, USER_ROLES } from './constants';

const getRoleWeight = (type, role) => {
	if (type === 'ADMIN') {
		return -ADMIN_ROLES.indexOf(role);
	} else {
		return -USER_ROLES.indexOf(role);
	}
};
/**
 * @param {('ADMIN'|'USER')} type
 * @param {string|string[]} roles
 * @param {boolean} strict
 * @returns { boolean }
 */
const useACL = (type, roles = [], strict = false) => {
	const role = useRole();

	if (!Array.isArray(roles)) {
		roles = [roles];
	}

	const comparator = getRoleWeight.bind(null, type);

	if (strict) {
		return roles.includes(role);
	} else {
		return roles.some((r) => comparator(role) >= comparator(r));
	}
};

export default useACL;
