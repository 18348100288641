import React from 'react';

import LoanTimeSelector from '../../selector/time';

const LoanXAxisPrefixItem = React.memo(() => {
	return (
		<div className="asteria-component__forecaster-axis-prefix-item">
			<LoanTimeSelector />
		</div>
	);
});

LoanXAxisPrefixItem.displayName = 'LoanXAxisPrefixItem';

export default LoanXAxisPrefixItem;
