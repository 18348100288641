import React from 'react';

import PropTypes from 'prop-types';

import YAxis from '@asteria/component-graph/graphs/yaxis';
import Legend from '@asteria/component-graph/legends';

import { cn } from '@asteria/utils-funcs/classes';
import useComponentSize from '@asteria/utils-hooks/useComponentSize';

import Lines from '../../lines';

import LineGraphColumn from './column';

import '../styles.scss';

import './styles.scss';

const LineGraph = React.memo((props) => {
	const {
		className,
		data,
		labels,
		min,
		max,
		title,
		showYAxis,
		showLines,
		legends = [],
		showLegends,
		width = 100 / (((data ?? []).length || 3) - 2),
		size = (data ?? []).length,

		render,
		renderItem,
	} = props;

	const ref = React.useRef(null);

	const { height } = useComponentSize({ ref: ref });

	const style = React.useMemo(() => ({ '--size': size }), [size]);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-graph',
				'asteria--variant-lines',
				{ 'asteria--state-has-y-axis': showYAxis },
				className,
			)}
		>
			{showYAxis ? <YAxis labels={labels} title={title} /> : null}

			<div
				className="asteria-component__forecaster-graph-content"
				style={style}
				ref={ref}
			>
				{showLines ? <Lines values={labels} /> : null}

				{showLegends && legends?.length ? (
					<Legend data={legends} clickable hoverable />
				) : null}

				{render
					? render({ height })
					: data.map((value, index) =>
							renderItem ? (
								renderItem({
									index: index,
									first: index === 0,
									last: index === data.length - 1,
									previous: data[index - 1],
									current: data[index],
									next: data[index + 1],
									min: min,
									max: max,
									width: width,
									height: height,
								})
							) : (
								<LineGraphColumn
									key={index}
									index={index}
									first={index === 0}
									last={index === data.length - 1}
									previous={data[index - 1]}
									current={data[index]}
									next={data[index + 1]}
									min={min}
									max={max}
									width={width}
									height={height}
								/>
							),
					  )}
			</div>
		</div>
	);
});

LineGraph.displayName = 'LineGraph';

LineGraph.propTypes = {
	className: PropTypes.string,

	data: PropTypes.arrayOf(PropTypes.object),
	labels: PropTypes.arrayOf(PropTypes.object),
	min: PropTypes.number,
	max: PropTypes.number,
	title: PropTypes.string,

	showYAxis: PropTypes.bool,
	showLines: PropTypes.bool,
	showLegends: PropTypes.bool,

	legends: PropTypes.arrayOf(PropTypes.object),

	width: PropTypes.number,
	size: PropTypes.number,

	render: PropTypes.func,
	renderItem: PropTypes.func,
};

export default LineGraph;
