export const CATEGORIES = {
	ERP: [
		'$taxes-$tax',
		'$salaries-$salary',
		'$invoices-$supplier',
		'$invoices-$customer',
	],
	BANK: [
		'$transaction-$swish',
		'$transaction-$transfer',
		'$transaction-$cash',
		'$transaction-$card',
	],
};
