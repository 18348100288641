import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import './styles.scss';

function transform(value) {
	if (value?.startsWith('$')) {
		const [category, tag] = value.split('-');
		const clean = value.replace('$', '');

		const fmtCategory = FormatUtils.replace([category]);
		const fmtTag = FormatUtils.replace([tag]);

		if (
			TranslationService.hasKey([
				`list.transactions.transaction.description.${clean}`,
				`list.transactions.transaction.description.${fmtCategory}`,
				`list.transactions.transaction.description.${fmtTag}`,
				`list.transactions.transaction.description.${fmtCategory}.${fmtTag}`,
			])
		) {
			return TranslationService.get([
				`list.transactions.transaction.description.${clean}`,
				`list.transactions.transaction.description.${fmtCategory}`,
				`list.transactions.transaction.description.${fmtTag}`,
				`list.transactions.transaction.description.${fmtCategory}.${fmtTag}`,
			]);
		}

		return FormatUtils.formatTag({ category: category, tag: tag });
	}

	return TranslationService.get([value, 'transaction.unknown'], value);
}

const DescriptionCell = React.memo((props) => {
	const { className, data } = props;

	const content = transform(data);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<Text title={content}>{content}</Text>
		</div>
	);
});

DescriptionCell.displayName = 'DescriptionCell';

DescriptionCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.string,
};

export default DescriptionCell;
