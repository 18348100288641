import React from 'react';

import PropTypes from 'prop-types';

import { Content } from '@asteria/component-core/wrapper';

import TransactionGroup from './group';
import { useScrollingDates } from './hooks';

import '../styles.scss';

const TransactionsContent = React.memo((props) => {
	const {
		onAction,
		direction,

		types,

		category,
		tag,
	} = props;

	const ref = React.useRef(null);

	const { dates } = useScrollingDates({
		types: types,
		ref: ref,
		direction: direction,
	});

	const isTypedCategory = !category || category === '$type';
	const hasTypes = types?.length > 1;

	const style = React.useMemo(
		() => ({
			'--size': hasTypes ? 7 : isTypedCategory ? 6 : 5,
			'--columns': hasTypes
				? 'var(--forecaster-transactions-columns-7)'
				: isTypedCategory
				? 'var(--forecaster-transactions-columns-6)'
				: 'var(--forecaster-transactions-columns-5)',
		}),
		[hasTypes, isTypedCategory],
	);

	return (
		<Content style={style} ref={ref}>
			<div className="asteria-component__forecaster-transactions-list">
				{dates.map((date) => (
					<TransactionGroup
						key={[...types, date, category, tag].join('.')}
						date={date}
						category={category}
						tag={tag}
						onAction={onAction}
						ref={ref}
						types={types}
					/>
				))}
			</div>
		</Content>
	);
});

TransactionsContent.displayName = 'TransactionsContent';

TransactionsContent.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,

	category: PropTypes.string,
	tag: PropTypes.string,

	onToggleDirection: PropTypes.func,
	direction: PropTypes.string,

	types: PropTypes.arrayOf(PropTypes.string),
};

export default TransactionsContent;
