import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

const IntegrationsTeaser = React.memo(function IntegrationsTeaser({
	onAction,
	onSubmit,
}) {
	const hasBankFeature = useFeature('bank-integrations');
	const { state } = useLocation();

	const { erp, bank } = useSelector(
		(store) => {
			const erp = !!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				connected: true,
			}).length;

			const bank = !!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				connected: true,
			}).length;

			return { erp, bank: !hasBankFeature || bank };
		},
		(a, b) => isEqual(a, b),
	);

	const actions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	const translationData = React.useMemo(() => {
		if (state?.data?.type) {
			return {
				postfix: {
					type: state?.data?.type,
				},
			};
		}

		return {};
	}, [state?.data?.type]);

	if (erp && bank) {
		return null;
	}

	if (!erp && !bank) {
		return (
			<Teaser
				translationData={translationData}
				actions={actions}
				path="pages.onboarding.teaser.none"
				onAction={onAction}
			/>
		);
	}

	if (erp) {
		return (
			<Teaser
				translationData={translationData}
				actions={actions}
				path="pages.onboarding.teaser.erp"
				onAction={onAction}
			/>
		);
	}

	return (
		<Teaser
			translationData={translationData}
			actions={actions}
			path="pages.onboarding.teaser.bank"
			onAction={onAction}
		/>
	);
});

IntegrationsTeaser.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default IntegrationsTeaser;
