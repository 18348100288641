import React from 'react';

import PropTypes from 'prop-types';

import View from './view';

const Provider = React.memo((props) => {
	const {
		className,
		dates,
		hasYAxis,
		type,
		category,
		tag,
		types = [type],
		showPrefix,
		onAction,
	} = props;

	return (
		<View
			className={className}
			dates={dates}
			hasYAxis={hasYAxis}
			type={type}
			category={category}
			tag={tag}
			types={types}
			showPrefix={showPrefix}
			onAction={onAction}
			size={dates.length + 2}
		/>
	);
});

Provider.displayName = 'Provider';

Provider.propTypes = {
	className: PropTypes.string,
	dates: PropTypes.arrayOf(PropTypes.string),
	hasYAxis: PropTypes.bool,
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	types: PropTypes.arrayOf(PropTypes.string),
	showPrefix: PropTypes.bool,
	onAction: PropTypes.func,
};

export default Provider;
