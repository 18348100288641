import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { cn } from '@asteria/utils-funcs/classes';

import './../../styles.scss';

const SectionTeaser = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const hasIntegrations = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'erp',
				connected: true,
			}).length,
	);

	const actions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	if (hasIntegrations) {
		return null;
	}

	return (
		<Teaser
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-teaser',
			)}
			actions={actions}
			path="pages.financial.teaser.credit.erp"
			onAction={onAction}
		/>
	);
});

SectionTeaser.displayName = 'SectionTeaser';
SectionTeaser.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionTeaser;
