import React from 'react';

import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import CashflowView from '@asteria/view-cashflow';

import * as AppStore from '@asteria/datalayer/stores/app';

import { LayoutContext } from '../../context';

import './styles.scss';

const CashflowPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);
	const { wrapperRef, feedback } = useOutletContext();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	return (
		<CashflowView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
			feedback={feedback}
			wrapperRef={wrapperRef}
			accessToken={accessToken}
		/>
	);
};

CashflowPage.displayName = 'CashflowPage';

CashflowPage.propTypes = {};

export default CashflowPage;
