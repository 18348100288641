import { useLayoutEffect, useMemo } from 'react';

const useClickOutside = (ref, onClickOutside, parent) => {
	const node = useMemo(() => parent ?? document, [parent]);

	function onClick(event) {
		const refs = [].concat(ref);

		if (refs.every((ref) => ref?.current)) {
			const isPartOfRef = refs.some((ref) =>
				ref?.current?.contains?.(event?.target),
			);

			if (!isPartOfRef) {
				onClickOutside?.(event);
			}
		}
	}

	useLayoutEffect(() => {
		node.addEventListener('click', onClick);

		return () => {
			node.removeEventListener('click', onClick);
		};
	}, [node]);
};

export default useClickOutside;
