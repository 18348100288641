import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import List from '@asteria/component-list';
import TagChip from '@asteria/component-tags/tag';

import { toggleFilter } from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import TransactionDetailsRow from './TransactionDetailsRow';
import TransactionDetailsSection from './TransactionDetailsSection';

import './styles.scss';

const selectors = {
	client: createSelector(
		(store) => store?.app?.clients ?? [],
		(_, ID) => ID,
		(data, ID) =>
			ID
				? data.find(
						(object) => object.id === ID || object._id === ID,
				  ) ?? null
				: null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	bankAccount: createSelector(
		(store) => store?.accounts?.bankAccounts ?? [],
		(_, ID) => ID,
		(data, ID) =>
			ID
				? data.find(
						(object) => object.id === ID || object._id === ID,
				  ) ?? null
				: null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	tag: createSelector(
		(store) => store?.app?.tags ?? [],
		(_, ID) => ID,
		(tags, ID) =>
			tags.find((object) => object?.id === ID || object._id === ID),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

function useTransactionLinks(object) {
	const links = object?.links ?? [];

	const bankTransaction = links.find(
		(object) => object?.type === 'BANK_TRANSACTION',
	);

	const invoice = links.find((object) => object?.type === 'INVOICE_PAYMENT');

	const clientId =
		invoice?.clientId ??
		invoice?.invoiceDetails?.client?.id ??
		links.find((object) => object?.type === 'CLIENT')?.id;

	const client = useSelector((store) => selectors.client(store, clientId));

	const bankAccountId = bankTransaction?.accountDetails?.bankAccountId;

	const bankAccount = useSelector((store) =>
		selectors.bankAccount(store, bankAccountId),
	);

	return useDeepMemo(
		() => ({
			bankTransaction: bankTransaction,
			bankAccount: bankAccount,
			invoice: invoice,
			client: client,
		}),
		[bankAccount, bankTransaction, client, invoice],
	);
}

const TransactionDetailsTag = (props) => {
	const { status, type, id, _id } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const tag = useSelector((store) => selectors.tag(store, id ?? _id));

	const handleTagClick = React.useCallback(() => {
		const tag = selectors.tag(store.getState(), id ?? _id);

		dispatch(
			toggleFilter({
				type: 'tag',
				id: tag._id ?? tag.id,
				item: tag,
			}),
		);

		dispatch(TransactionStore.setActive(null));
	}, [_id, dispatch, id, store]);

	const analytics = React.useMemo(
		() => ({
			tag: tag,
		}),
		[tag],
	);

	return (
		<TagChip
			type={type}
			tag={tag}
			status={status}
			onClick={handleTagClick}
			size="sm"
			analyticsKey="transactions.details.tag"
			analytics={analytics}
		/>
	);
};

TransactionDetailsTag.propTypes = {
	status: PropTypes.string,
	type: PropTypes.string,
	id: PropTypes.string,
	_id: PropTypes.string,
	onAction: PropTypes.func,
};

function useOverviewFields(options) {
	const { object, bankTransaction, invoice, client, onAction } = options;

	const dispatch = useDispatch();

	const handleClientClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientOverview,
				data: { _id: client?._id ?? client?.id },
			}),
		);
	}, [client?._id, client?.id, dispatch]);

	return useDeepMemo(
		() => ({
			transactionDate: object?.paymentDate,
			'date.booked': bankTransaction?.accountDetails?.dates?.booked,
			client: client ? (
				<Button
					label={TranslationService.get(
						'transaction.details.client.action',
						'{{ client.name }}',
						{
							item: object,
							invoice: invoice?.invoiceDetails,
							client: client,
							bank: bankTransaction?.accountDetails,
						},
					)}
					variant="link"
					onClick={handleClientClick}
					size="sm"
					analytics={{ client: client }}
					analyticsKey="transaction.details.client.click"
				/>
			) : null,
			status: object?.status,
			'bank.reference':
				bankTransaction?.accountDetails?.identifiers?.number,
			reference: invoice?.invoiceDetails?.meta?.reference,
			message: invoice?.invoiceDetails?.meta?.message,
			tags: object?.meta?.tags ? (
				<div className="asteria-component__transactions__details__tags">
					{(object?.meta?.tags ?? []).map((tag) => (
						<TransactionDetailsTag
							key={tag.id}
							type={object?.type}
							status={object?.status}
							onAction={onAction}
							{...tag}
						/>
					))}
				</div>
			) : null,
		}),
		[
			bankTransaction?.accountDetails,
			client,
			handleClientClick,
			invoice?.invoiceDetails,
			object,
		],
	);
}

function useRiskFields(options) {
	const { object, invoice } = options;

	return useDeepMemo(() => {
		let response = {};

		if (
			object?.oracle?.currency?.risk &&
			object?.sums?.display?.currency !== object?.sums?.original?.currency
		) {
			response['currencyrisk.current'] = true;

			if (invoice) {
				response['currencyrisk.sent'] = true;
			}
		}

		return response;
	}, [invoice, object]);
}

function useBankAccountFields(options) {
	const { bankAccount } = options;

	return useDeepMemo(
		() => ({
			company: null,
			account: bankAccount?.identifiers?.number,
			'account.name': bankAccount?.name,
			'account.clearing': bankAccount?.identifiers?.clearing,
			'account.bic': bankAccount?.identifiers?.bic,
			'account.iban': bankAccount?.identifiers?.iban,
			'account.bank': null,
			currency: bankAccount?.sums?.original?.currency,
		}),
		[bankAccount],
	);
}

const TransactionDetails = (props) => {
	const { direction = 'horizontal', className, _id, onAction } = props;

	const object = useSelector((store) =>
		TransactionStore.selectors.item(store, _id),
	);
	const links = useTransactionLinks(object);

	const overview = useOverviewFields({
		object: object,
		onAction: onAction,
		...links,
	});
	const riskFields = useRiskFields({ object: object, ...links });
	const account = useBankAccountFields({ object: object, ...links });

	const fields = useDeepMemo(
		() => ({ overview: overview, risk: riskFields, account: account }),
		[account, overview, riskFields],
	);

	if (!object) {
		return null;
	}

	return (
		<List
			className={cn(
				'asteria-component__transactions__details',
				className,
				{
					[`asteria-component__transactions__details--direction-${direction}`]:
						direction,
				},
			)}
			size="lg"
			applyTypographySizes={false}
		>
			{Object.entries(fields).map(([key, $value]) => {
				const value = Object.entries($value).filter(
					([, value]) => value,
				);

				if (!value.length) {
					return null;
				}

				return (
					<TransactionDetailsSection
						key={key}
						header={TranslationService.get([
							'transaction.details.header',
							`transaction.details.header.${key}`,
						])}
					>
						{value.map(([key, value]) => (
							<TransactionDetailsRow
								key={key}
								label={TranslationService.get([
									'transaction.details',
									`transaction.details.${key}`,
									`transaction.details.${key}.${object?.status}`,
								])}
								slug={key}
							>
								{typeof value !== 'object'
									? TranslationService.get(
											[
												'transaction.details.value',
												`transaction.details.${key}.value`,
												`transaction.details.${key}.${object?.status}.value`,
												`transaction.details.${key}.${value}.value`,
												`transaction.details.${key}.${object?.status}.${value}.value`,
											],
											'{{ value }}',
											{
												value: value,
												item: object,
												...links,
												invoice:
													links?.invoice
														?.invoiceDetails,
												bank: links?.bankTransaction
													?.accountDetails,
											},
									  )
									: value}
							</TransactionDetailsRow>
						))}
					</TransactionDetailsSection>
				);
			})}
		</List>
	);
};

TransactionDetails.displayName = 'TransactionDetails';

TransactionDetails.propTypes = {
	direction: PropTypes.oneOf(['horizontal', 'vertical']),
	_id: PropTypes.string,
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default TransactionDetails;
