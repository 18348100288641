import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

const TableGroup = React.memo(
	React.forwardRef((props, ref) => {
		const { className, children } = props;

		return (
			<div
				className={cn('asteria-component__table-group', className)}
				ref={ref}
			>
				{children}
			</div>
		);
	}),
);

TableGroup.displayName = 'TableGroup';

TableGroup.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default TableGroup;
