import { useSelector } from 'react-redux';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

/**
 * @param { { size: string, sorting: { field: string, direction: string }, reverse: boolean } } options
 */
function useGroups(options = {}) {
	const { size = 'month', sorting = {}, reverse } = options;

	const transactions = useSelector(TransactionStore.selectors.transactions);

	const groups = Object.values(
		TransactionStore.utils.generateGroupItems({
			size: size,
			items: transactions,
			sorting: sorting,
		}),
	).sort(({ id: a }, { id: b }) =>
		TransactionStore.utils.compareDates(reverse)(a, b),
	);

	return groups;
}

export default useGroups;
