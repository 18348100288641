export const FORMAT = {
	week: 'yyyy-MM-dd',
	month: 'yyyy-MM',
	year: 'yyyy',
};

export const STATES = {
	All: 'ALL',
	ModifiedOnly: 'MODIFIED_ONLY',
};

export const initialState = {
	state: null,
	active: null,
	timestamp: null,
	selected: {},
	items: {},
};
