import React from 'react';

import PropTypes from 'prop-types';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../../PropTypes';
import { useLoading } from '../hooks';

import { CloseButton, CollapseButton, TourButton } from './actions';

import './styles.scss';

/** @type { React.FC<Partial<{ position: string, onClose: () => void, tourKey: string | string[], size: string, icon: string, isCollapsed: boolean, onCollapseClick: () => void, children: React.ReactNode | Partial<{ before: React.ReactNode, after: React.ReactNode }>, loading: boolean, collapseBtn: unknown, closeBtn: unknown }>> } */
const Postfix = React.memo((props) => {
	const {
		position,
		onClose,
		tourKey,
		size,
		icon,
		isCollapsed,
		onCollapseClick,
		children,

		collapseBtn = {},
		closeBtn = {},

		loading: $loading,

		...rest
	} = props;

	const loading = useLoading($loading);

	const before =
		children?.before || children?.after
			? children?.before ?? null
			: children;

	const after =
		children?.before || children?.after ? children?.after ?? null : null;

	return (
		<div
			className={cn(
				'asteria-component__wrapper-header__postfix',
				{ 'asteria--state-loading': loading },
				{
					[`asteria-component__wrapper-header__postfix--position-${position}`]:
						position,
				},
			)}
		>
			{typeof before === 'function' ? before({ loading }) : before}

			{tourKey ? (
				<FeatureFlag feature="modal-show-tour">
					<TourButton
						size={size}
						tourKey={tourKey}
						loading={loading}
						{...rest}
					/>
				</FeatureFlag>
			) : null}

			{onCollapseClick ? (
				<CollapseButton
					onCollapseClick={onCollapseClick}
					size={size}
					isCollapsed={isCollapsed}
					loading={loading}
					{...rest}
					{...collapseBtn}
				/>
			) : null}

			{onClose ? (
				<CloseButton
					icon={icon}
					onClose={onClose}
					size={size}
					loading={loading}
					{...rest}
					{...closeBtn}
				/>
			) : null}

			{typeof after === 'function' ? after({ loading }) : after}
		</div>
	);
});

Postfix.displayName = 'HeaderPostfix';

Postfix.propTypes = {
	position: PropTypes.string,
	onClose: PropTypes.func,
	tourKey: PropTypes.string,
	size: SizeProp(),
	icon: PropTypes.string,
	isCollapsed: PropTypes.bool,
	onCollapseClick: PropTypes.func,

	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.shape({ before: PropTypes.node, after: PropTypes.node }),
	]),

	loading: PropTypes.bool,

	collapseBtn: PropTypes.object,
	closeBtn: PropTypes.object,
};

export default Postfix;
