import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import {
	endOfMonth,
	format,
	formatISO,
	parseISO,
	startOfMonth,
	subMonths,
} from 'date-fns';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { Setup2FA } from '@asteria/view-auth';
import ViewWrapper from '@asteria/view-wrapper';

import { Text } from '@asteria/component-core/typography';
import { Content } from '@asteria/component-core/wrapper';

import Breadcrumbs from '@asteria/component-breadcrumbs';
import {
	request as CardRequest,
	response as CardResponse,
} from '@asteria/component-card';
import DevToolsButton from '@asteria/component-devtools';
import { FloatingFeedbackButton } from '@asteria/component-feedback';
import ModalCollection from '@asteria/component-modal-collection';
import { SnackbarWrapper } from '@asteria/component-snackbar';
import { SupportDialog, SupportFloatButton } from '@asteria/component-support';
import {
	FeatureFlag,
	Service as FeatureService,
	useFeature,
} from '@asteria/component-tools/featureflag';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as AppStore from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as GraphStore from '@asteria/datalayer/stores/graph';
import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';
import * as TourStore from '@asteria/datalayer/stores/tour';
import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { Translation, TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
// import { formatTag } from '@asteria/utils-funcs/format';
import * as NavigationUtils from '@asteria/utils-funcs/navigation';
import { parseDate } from '@asteria/utils-funcs/normalize';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';
import { defaultBulkCallback } from '@asteria/utils-graphql';
import { hooks as BaseHooks } from '@asteria/widget-base';

import { onAction as onClientAction } from '../actions/clients';
import { onAction as onFeedbackAction } from '../actions/feedback';
import { onAction as onForecasterAction } from '../actions/forecaster';
import { onAction as onScenarioAction } from '../actions/scenarios';
import { onAction as onSettingsAction } from '../actions/settings';
import { onAction as onTagAction } from '../actions/tags';
import * as AccountsAPI from '../api/accounts';
import * as CashflowAPI from '../api/cashflow';
import * as ClientsAPI from '../api/clients';
import * as CompanyAPI from '../api/company';
import * as FeatureAPI from '../api/features';
import * as FeedbackAPI from '../api/feedback';
import * as ForecastAPI from '../api/forecast';
import * as ForecastActionsAPI from '../api/forecastActions';
import * as IntegrationsAPI from '../api/integrations';
import * as InvoicesAPI from '../api/invoices';
import * as NotificationsAPI from '../api/notifications';
import * as PredictionAPI from '../api/predictions';
import * as SupportAPI from '../api/support';
import * as TagsAPI from '../api/tags';
import * as TransactionsAPI from '../api/transactions';
import * as UserAPI from '../api/user';
import * as VouchersAPI from '../api/vouchers';
import AnalyticsUpdater from '../components/analytics-updater';
import CashflowStatisticsUpdater from '../components/cashflow-statistics-updater';
import ConversationUpdater from '../components/conversation-updater';
import DemoNotifications from '../components/demo-notifications';
import IntegrationsDialog from '../components/integrations';
import LayoutHistoryCallback from '../components/layout-history-callback';
import NavigationLogic from '../components/navigation';
import Popups from '../components/popups';
import TourLogic from '../components/tour';
import WelcomeConfigWrapper from '../components/welcome-config-wrapper';
import { LayoutContext } from '../context';
import * as selectors from '../selectors';

import * as LayoutHooks from './hooks';
import { applyCashflowSettings } from './utils/settings';

function formatAccountCardResponse(response, credit) {
	return {
		PAID: {
			count: response?.PAID?.count ?? 0,
			total: response?.PAID?.sums?.original?.total ?? 0,
			currency: response?.PAID?.sums?.original?.currency ?? 'SEK',
		},
		FORECAST: {
			count: response?.FORECAST?.count ?? 0,
			total: response?.FORECAST?.sums?.original?.total ?? 0,
			currency: response?.FORECAST?.sums?.original?.currency ?? 'SEK',
			min: response?.FORECAST?.min?.original?.total,
			max: response?.FORECAST?.max?.original?.total,
		},
		UNPAID: {
			count: response?.UNPAID?.count ?? 0,
			total: response?.UNPAID?.sums?.original?.total ?? 0,
			currency: response?.UNPAID?.sums?.original?.currency ?? 'SEK',
		},
		OVERDUE: {
			count: response?.OVERDUE?.count ?? 0,
			total: response?.OVERDUE?.sums?.original?.total ?? 0,
			currency: response?.OVERDUE?.sums?.original?.currency ?? 'SEK',
		},
		CREDIT: {
			total: credit?.PAID?.sums?.original?.total ?? 0,
			currency: credit?.PAID?.sums?.original?.currency ?? 'SEK',
		},
	};
}

function formatInvoiceCardResponse(response) {
	function formatValue(data) {
		return {
			count: data?.count ?? 0,
			total: data?.total?.display?.[0]?.total ?? 0,
		};
	}

	return {
		health: response?.health?.[0],
		source: {
			summary: formatValue(response?.source?.statistics?.summary),
			paid: formatValue(response?.source?.statistics?.paid),
			unpaid: formatValue(response?.source?.statistics?.unpaid),
			overdue: formatValue(response?.source?.statistics?.overdue),
			forecast: formatValue(response?.source?.statistics?.forecast),
			credit: formatValue(response?.source_credit?.statistics?.summary),

			customers: {
				summary: formatValue(
					response?.source_customers?.statistics?.summary,
				),
				paid: formatValue(response?.source_customers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_customers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_customers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_customers?.statistics?.forecast,
				),
				credit: formatValue(
					response?.source_customers_credit?.statistics?.summary,
				),
			},
			suppliers: {
				summary: formatValue(
					response?.source_suppliers?.statistics?.summary,
				),
				paid: formatValue(response?.source_suppliers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_suppliers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_suppliers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_suppliers?.statistics?.forecast,
				),
				credit: formatValue(
					response?.source_suppliers_credit?.statistics?.summary,
				),
			},
		},
		target: {
			summary: formatValue(response?.target?.statistics?.summary),
			paid: formatValue(response?.target?.statistics?.paid),
			unpaid: formatValue(response?.target?.statistics?.unpaid),
			overdue: formatValue(response?.target?.statistics?.overdue),
			forecast: formatValue(response?.target?.statistics?.forecast),
			credit: formatValue(response?.target_credit?.statistics?.summary),

			customers: {
				summary: formatValue(
					response?.source_customers?.statistics?.summary,
				),
				paid: formatValue(response?.source_customers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_customers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_customers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_customers?.statistics?.forecast,
				),
				credit: formatValue(
					response?.source_customers_credit?.statistics?.summary,
				),
			},
			suppliers: {
				summary: formatValue(
					response?.source_suppliers?.statistics?.summary,
				),
				paid: formatValue(response?.source_suppliers?.statistics?.paid),
				unpaid: formatValue(
					response?.source_suppliers?.statistics?.unpaid,
				),
				overdue: formatValue(
					response?.source_suppliers?.statistics?.overdue,
				),
				forecast: formatValue(
					response?.source_suppliers?.statistics?.forecast,
				),
				credit: formatValue(
					response?.source_suppliers_credit?.statistics?.summary,
				),
			},
		},
	};
}

const Layout = ({ callback, debug, auth }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const prevState = React.useRef({});

	React.useEffect(() => {
		prevState.current = location;
	}, [location]);

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const onAuthAction = BaseHooks.auth.useAction();
	const onAuthSubmit = BaseHooks.auth.useSubmit();

	const queryClient = useQueryClient();

	const store = useStore();
	const dispatch = useDispatch();

	const hasNavigationContentFeature = useFeature('navigation-content');

	const skipOverviewFeature = useFeature('forecaster-skip-overview');
	const hasBreadcrumbsFeature = useFeature('breadcrumbs');

	const hasForecasterFeature = useFeature('forecaster');
	const hasWelcomeForecasterFeature = useFeature(
		'widget-welcome-page-forecaster-redirect',
	);
	// const hasTransactionEditFeature = useFeature(
	// 	'cashflow-graph-edit-with-list',
	// );

	const hasTranslationListOpenFeature = useFeature(
		'cashflow-transactions-state-open',
	);

	const onSubmitRef = React.useRef(null);
	const onActionRef = React.useRef(null);
	const forecasterPathRef = React.useRef(null);

	const [layoutSize, setLayoutSize] = React.useState('large');
	const [hasSidebar, setHasSidebar] = React.useState(false);
	const [wrapperRef, setWrapperRef] = React.useState(null);
	const [feedbackParams, setFeedbackParams] = React.useState(null);

	const view = NavigationUtils.parsePath(location.pathname);

	React.useEffect(() => {
		dispatch(AppStore.setStandalone(false));
	}, [dispatch]);

	const onNavigate = React.useCallback(
		($path, data) => {
			const path = $path?.path ?? $path;

			if (prevState?.current?.pathname === '/') {
				const { current } = prevState;

				applyCashflowSettings({
					settings: current?.state?.settings,
					dispatch: dispatch,
				});
			}

			if (path !== '/') {
				const isForecastCompleted = selectors.isForecastCompleted(
					store.getState(),
				);

				if (isForecastCompleted && skipOverviewFeature) {
					const node = document.querySelector(
						'.asteria-component__feedback.cashflow-forecast-feedback',
					);

					if (node) {
						onActionRef?.current?.('updateFeedback', {
							feedbackKey: 'forecaster-feedback',
							dismissed: true,
						});
					}
				}
			}

			if ($path?.resetCreditValue) {
				dispatch(AccountStore.setDynamicCredit(null));
			}

			if (!$path?.skipState) {
				dispatch(TransactionStore.reset({ full: true }));

				if (!hasTranslationListOpenFeature) {
					dispatch(TransactionStore.setState(null));
				}
			}

			return navigate(path, data);
		},
		[
			dispatch,
			hasTranslationListOpenFeature,
			navigate,
			skipOverviewFeature,
			store,
		],
	);

	const onForecasterOpen = React.useCallback(() => {
		Analytics.event('forecaster.open', {});

		return onNavigate?.('/forecaster');
	}, [onNavigate]);

	const onForecasterClose = React.useCallback(() => {
		Analytics.event('forecaster.close', {});

		dispatch(ModalStore.close());

		if (forecasterPathRef.current !== '/forecaster') {
			dispatch(ForecasterStore.reset());
		}

		const path = forecasterPathRef.current ?? '/';
		forecasterPathRef.current = null;

		return onNavigate?.(path);
	}, [dispatch, onNavigate]);

	const onForecasterValidate = React.useCallback(
		(path) => {
			forecasterPathRef.current = path;
			dispatch(ForecasterStore.close());
		},
		[dispatch],
	);

	const go = LayoutHooks.navigation.useNavigation({
		navigate: onNavigate,
		view: view,
		onForecasterValidate: onForecasterValidate,
	});

	const onSubmit = React.useCallback(
		async (action, data) => {
			const ctx = { queryClient };

			if (debug) {
				/* eslint-disable no-console */
				console.group('onSubmit');
				console.log('action', action);
				console.log('data', data);
				console.groupEnd();
				/* eslint-enable no-console */
			}

			/* Common API */

			if (action === 'search') {
				const options = { accessToken, dispatch };

				ClientsAPI.fetch(options, ctx);
				TagsAPI.fetch(options, ctx);
				TransactionsAPI.currencies(options, ctx);
			}

			/* Cashflow API */

			if (action === 'cashflow') {
				const {
					startDate,
					endDate: $endDate,
					useDispatch = true,
					signal,
				} = data;

				const state = store.getState();

				const filters = state?.app?.filters ?? [];
				const scenarioId =
					state?.app?.user?.settings?.flags?.scenarioId;

				const size = state?.app?.timesize;

				const endDate =
					$endDate ??
					format(
						TimesizeUtils.endOfTime(parseISO(startDate), size),
						'yyyy-MM-dd',
					);

				return CashflowAPI.fetch(
					{
						startDate: startDate,
						tags: (filters ?? [])
							.filter(({ type }) => type === 'tag')
							.map(({ id }) => id),
						clients: (filters ?? [])
							.filter(({ type }) => type === 'client')
							.map(({ id }) => id),
						status: (filters ?? [])
							.filter(({ type }) => type === 'status')
							.map(({ id }) => id),
						currencies: (filters ?? [])
							.filter(({ type }) => type === 'currency')
							.map(({ id }) => id),
						endDate: `${endDate}T23:59:59.999Z`,
						scenarioId: scenarioId,

						accessToken: accessToken,
						dispatch: useDispatch ? dispatch : undefined,

						signal: signal,
						// cancel: true,
					},
					ctx,
				);
			}

			if (action === 'cashflow:statistics') {
				const scenarioId = selectors.scenarioId(store.getState());
				const filters = selectors.filters(store.getState());
				const timesize = selectors.timesize(store.getState());

				const tags = (filters ?? [])
					.filter(({ type }) => type === 'tag')
					.map(({ id }) => id);

				const clients = (filters ?? [])
					.filter(({ type }) => type === 'client')
					.map(({ id }) => id);

				const status = (filters ?? [])
					.filter(({ type }) => type === 'status')
					.map(({ id }) => id);

				const currencies = (filters ?? [])
					.filter(({ type }) => type === 'currency')
					.map(({ id }) => id);

				return CashflowAPI.statistics(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						tags: tags.length ? tags : null,
						clients: clients.length ? clients : null,
						status: status.length ? status : null,
						currencies: currencies.length ? currencies : null,
						scenarioId: scenarioId,
						timeslice: timesize,
					},
					ctx,
				);
			}

			/* User API */

			if (action === 'user:remove') {
				return UserAPI.remove(
					{ accessToken: accessToken, id: data?.id },
					ctx,
				);
			}

			if (action === 'user:settings:refresh') {
				return UserAPI.refreshSettings({ accessToken, dispatch }, ctx);
			}

			if (action === 'user:settings:update') {
				const { id, ...settings } = data;

				return UserAPI.updateSettings(
					{
						accessToken,
						id: id,
						settings: settings,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			/* Company API */

			if (action === 'company:remove') {
				return CompanyAPI.remove(
					{ accessToken: accessToken, id: data?.id },
					ctx,
				);
			}

			if (action === 'company:settings:refresh') {
				return CompanyAPI.refreshSettingsFlags(
					{ accessToken, dispatch },
					ctx,
				);
			}

			if (action === 'company:update:self') {
				return CompanyAPI.updateSelf(
					{
						accessToken: accessToken,
						input: data,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'company:clean') {
				const companyId = store.getState()?.app?.company?._id;

				return CompanyAPI.clean(
					{ accessToken: accessToken, id: companyId },
					ctx,
				);
			}

			/* Notification API */

			if (action === 'notification:view') {
				const hasDemoFeature =
					FeatureService.isActive('notifications-demo');

				const hasDemoCreditFeature = FeatureService.isActive(
					'demo.notifications.credit',
				);
				const hasDemoOverdueFeature = FeatureService.isActive(
					'demo.notifications.overdue',
				);
				const hasDemoCurrencyFeature = FeatureService.isActive(
					'demo.notifications.currency',
				);
				const hasDemoDialogFeature =
					FeatureService.isActive('action-dialog-test');

				const hasDemoForecastFeature = FeatureService.isActive(
					'demo.notifications.forecaster',
				);

				return NotificationsAPI.markAsViewed(
					{
						accessToken: accessToken,
						id: data,
						dispatch: dispatch,
						flags: {
							testNotifications: hasDemoFeature,
							testCredit: hasDemoCreditFeature || hasDemoFeature,
							testOverdue:
								hasDemoOverdueFeature || hasDemoFeature,
							testCurrency:
								hasDemoCurrencyFeature || hasDemoFeature,
							testDialogs: hasDemoDialogFeature,
							testForecaster: hasDemoForecastFeature,
						},
					},
					ctx,
				);
			}

			if (action === 'notification:badge:remove') {
				const hasDemoFeature =
					FeatureService.isActive('notifications-demo');

				const hasDemoCreditFeature = FeatureService.isActive(
					'demo.notifications.credit',
				);
				const hasDemoOverdueFeature = FeatureService.isActive(
					'demo.notifications.overdue',
				);
				const hasDemoCurrencyFeature = FeatureService.isActive(
					'demo.notifications.currency',
				);
				const hasDemoDialogFeature =
					FeatureService.isActive('action-dialog-test');

				const hasDemoForecastFeature = FeatureService.isActive(
					'demo.notifications.forecaster',
				);

				return NotificationsAPI.removeNotificationBadge(
					{
						accessToken: accessToken,
						IDs: [].concat(data),
						dispatch: dispatch,
						flags: {
							testNotifications: hasDemoFeature,
							testCredit: hasDemoCreditFeature || hasDemoFeature,
							testOverdue:
								hasDemoOverdueFeature || hasDemoFeature,
							testCurrency:
								hasDemoCurrencyFeature || hasDemoFeature,
							testDialogs: hasDemoDialogFeature,
							testForecaster: hasDemoForecastFeature,
						},
					},
					ctx,
				);
			}

			if (action === 'notification:fetch') {
				const hasDemoFeature =
					FeatureService.isActive('notifications-demo');

				const hasDemoCreditFeature = FeatureService.isActive(
					'demo.notifications.credit',
				);
				const hasDemoOverdueFeature = FeatureService.isActive(
					'demo.notifications.overdue',
				);
				const hasDemoCurrencyFeature = FeatureService.isActive(
					'demo.notifications.currency',
				);
				const hasDemoDialogFeature =
					FeatureService.isActive('action-dialog-test');

				const hasDemoForecastFeature = FeatureService.isActive(
					'demo.notifications.forecaster',
				);

				return NotificationsAPI.fetch(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						flags: {
							testNotifications: hasDemoFeature,
							testCredit: hasDemoCreditFeature || hasDemoFeature,
							testOverdue:
								hasDemoOverdueFeature || hasDemoFeature,
							testCurrency:
								hasDemoCurrencyFeature || hasDemoFeature,
							testDialogs: hasDemoDialogFeature,
							testForecaster: hasDemoForecastFeature,
						},
					},
					ctx,
				);
			}

			/* Feedback API */

			if (action === 'feedback:create') {
				const { id, ...feedback } = data;

				return FeedbackAPI.create(
					{
						accessToken,
						id: id,
						feedback: feedback,
						dispatch,
					},
					ctx,
				);
			}

			/* Integration API */

			if (action === 'integrations:missing') {
				return IntegrationsAPI.addCustomERP(
					{
						accessToken: accessToken,
						input: data,
					},
					ctx,
				);
			}

			if (action === 'integrations:list') {
				return IntegrationsAPI.fetch(
					{
						accessToken: accessToken,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'integrations:delete') {
				return IntegrationsAPI.remove(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						_id: data.id,
					},
					ctx,
				);
			}

			if (action === 'integrations:enable') {
				return IntegrationsAPI.enable(
					{
						accessToken: accessToken,
						_id: data.id,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'integrations:disable') {
				return IntegrationsAPI.disable(
					{
						accessToken: accessToken,
						_id: data.id,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'integrations:import') {
				return IntegrationsAPI.reimport(
					{
						accessToken: accessToken,
						_id: data.id,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'integrations:create') {
				return IntegrationsAPI.create(
					{
						accessToken: accessToken,
						input: data,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'integrations:recreate') {
				return IntegrationsAPI.recreate(
					{
						accessToken: accessToken,
						input: data,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			/* Vouchers API */

			if (action === 'vouchers:statement') {
				return VouchersAPI.statement(
					{
						accessToken: accessToken,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'vouchers:statement:download') {
				const blob = new Blob([data], { type: 'text/csv' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.setAttribute('href', url);
				a.setAttribute('download', 'statement.csv');

				a.click();

				SnackbarStore.show(dispatch, {
					title: 'vouchers.statement.download.title',
					content: 'vouchers.statement.download.content',
					type: 'vouchers.statement.download',
					variant: 'success',
					icon: 'check',
					hideActions: true,
				});

				return;
			}

			/* Transactions API */

			if (action === 'transactions:count') {
				const { timestamp, scenarioId } = data;

				return TransactionsAPI.fetch(
					{
						accessToken: accessToken,
						isRaw: true,
						filters: { updatedAt: { gt: timestamp } },
						scenarioId: scenarioId,
						status: [
							'PAID',
							'UNPAID',
							'SIGNED',
							'OVERDUE',
							'FORECAST',
						],
						fields: `
						statistics {
							summary {
								count
							}
						}
					`,
					},
					ctx,
				)
					.then((data) => data?.statistics?.summary?.count ?? 0)
					.catch(() => 0);
			}

			if (action === 'transactions:list') {
				return TransactionsAPI.fetch(
					{ ...data, accessToken: accessToken },
					ctx,
				);
			}

			if (action === 'transactions:notifications') {
				return TransactionsAPI.notifications(
					{ ...data, accessToken: accessToken },
					ctx,
				);
			}

			if (action === 'transactions:update') {
				return TransactionsAPI.update(
					{
						...data,
						accessToken: accessToken,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'transactions:delete') {
				return TransactionsAPI.remove(
					{
						...data,
						accessToken: accessToken,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'currencies:fetch') {
				return TransactionsAPI.currencies(
					{
						token: accessToken,
						accessToken: accessToken,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			/* Clients API */

			if (action === 'clients:create') {
				return ClientsAPI.create(
					{
						client: data,
						accessToken: accessToken,
						dispatch: dispatch,
					},
					ctx,
				);
			}

			if (action === 'clients:statistics') {
				return ClientsAPI.statistics(
					{
						accessToken: accessToken,
						filters: data,
					},
					ctx,
				);
			}

			if (action === 'clients:details') {
				return ClientsAPI.details(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						id: data,
					},
					ctx,
				);
			}

			/* Forecast API */

			if (action === 'forecaster:history') {
				return ForecastAPI.fetchHistory(
					{
						accessToken: accessToken,
						scenarioName: data?.scenarioName,
						startDate: data?.startDate,
						endDate: data?.endDate,
						status: data?.status,
					},
					ctx,
				);
			}

			if (action === 'forecaster:changes') {
				return ForecastAPI.fetch(
					{
						accessToken: accessToken,
						scenarioName: data?.scenarioName,
						date: data?.date,
						dataloader: data?.dataloader,
					},
					ctx,
				);
			}

			if (action === 'forecast:actions:fetch') {
				return ForecastActionsAPI.fetch(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						dataloader: data?.dataloader,
					},
					ctx,
				);
			}

			if (action === 'forecast:actions:update') {
				return ForecastActionsAPI.update(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						input: data,
					},
					ctx,
				);
			}

			if (action === 'forecast:actions:delete') {
				return ForecastActionsAPI.remove(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						id: data,
					},
					ctx,
				);
			}

			/* Invoices API */

			if (action === 'factoring:sold') {
				return InvoicesAPI.sold(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						...data,
					},
					ctx,
				);
			}

			/* Features API */

			if (action === 'features:fetch') {
				return FeatureAPI.fetchAll({ accessToken: accessToken }, ctx);
			}

			/* Auth API */

			if (action?.startsWith?.('auth:')) {
				return onAuthSubmit(action, data, ctx);
			}

			/* Support API */

			if (action === 'support') {
				const reportId = await SupportAPI.report(
					{ accessToken: accessToken, input: data },
					ctx,
				);

				return reportId || TranslationService.get('support.missing.id');
			}

			if (action === 'welcome:help') {
				return SupportAPI.requestAssistance(
					{ accessToken: accessToken, input: data },
					ctx,
				);
			}

			if (action === 'conversation:list') {
				return SupportAPI.chats({
					accessToken: accessToken,
					filters: data?.filters,
				});
			}

			if (action === 'conversation:chat') {
				const companyId = store.getState()?.app?.company?._id;

				if (!data?.id) {
					return [];
				}

				const response = await SupportAPI.chat({
					accessToken: accessToken,
					companyId: companyId,
					id: data.id,
				});

				const messages = response?.messages?.edges?.map(
					({ node }) => node,
				);

				return messages;
			}

			if (action === 'conversation:create') {
				const companyId = store.getState()?.app?.company?._id;

				return await SupportAPI.createChats({
					accessToken: accessToken,
					input: data,
					companyId: companyId,
				});
			}

			if (action === 'message:update') {
				const companyId = store.getState()?.app?.company?._id;

				return await SupportAPI.updateChatMessages({
					companyId: companyId,
					input: data?.input?.map((object) => ({
						id: object?.messageId,
						read: object?.read,
					})),
					accessToken: accessToken,
				});
			}

			if (action === 'message:send') {
				const companyId = store.getState()?.app?.company?._id;

				return SupportAPI.createChatMessages({
					companyId: companyId,
					input: {
						chatId: data.id,
						content: data.message,
						read: data.read,
					},
					accessToken: accessToken,
				});
			}

			/* Tags API */

			if (action === 'tag:statistics') {
				const scenarioId =
					store.getState()?.app?.user?.settings?.flags?.scenarioId;

				return TagsAPI.statistics(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						_id: data,
						scenarioId: scenarioId,
					},
					ctx,
				);
			}

			/* Prediction API */

			if (action === 'prediction:fetch') {
				return PredictionAPI.fetch({ accessToken, dispatch }, ctx);
			}

			/* Scenario API */

			if (action?.startsWith?.('scenario')) {
				return onScenarioAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						event: { type: action, data: data },
					},
					ctx,
				);
			}

			if (action === 'accounts:save') {
				const input = Object.values(data?.form ?? {}).map(
					(account) => ({
						active: account?.active,
						identifiers: {
							number: account?.identifiers?.number,
						},
					}),
				);

				await AccountsAPI.update(
					{ accessToken: accessToken, input: input },
					ctx,
				);

				await AccountsAPI.fetch({ accessToken: accessToken }, ctx).then(
					(data) => dispatch(AccountStore.setAccounts(data)),
				);
			}

			/* Cards */

			if (action === 'card:fetch') {
				const dataloader = {
					fn: new AsteriaCore.DataLoader(defaultBulkCallback, {
						period: 0,
					}),
					context: { waiting: true },
					onSuccess: (data) => ({ response: data }),
				};

				if (
					data?.type === 'incoming' ||
					data?.type === 'outgoing' ||
					data?.type === 'account'
				) {
					const state = store.getState();

					const scenarioId =
						state?.app?.user?.settings?.flags?.scenarioId;

					const dates = [];

					if (data?.source) {
						dates.push({
							startDate: data?.source?.startDate,
							endDate: `${data?.source?.endDate}T23:59:59.999Z`,
						});
					}

					if (data?.target) {
						dates.push({
							startDate: data?.target?.startDate,
							endDate: `${data?.target?.endDate}T23:59:59.999Z`,
						});
					}

					const [source, target] = await CashflowAPI.card({
						dates: dates,
						scenarioId: scenarioId,
						accessToken: accessToken,
						dataloader: data?.dataloader,
					});

					let key = null;

					if (data?.type === 'incoming') {
						key = 'DEPOSIT';
					}

					if (data?.type === 'outgoing') {
						key = 'WITHDRAW';
					}

					if (data?.type === 'account') {
						key = 'ACCOUNT';
					}

					return {
						source: formatAccountCardResponse(
							source?.[key],
							source?.CREDIT,
						),
						target: formatAccountCardResponse(
							target?.[key],
							target?.CREDIT,
						),
					};
				}

				if (data?.type === 'profit') {
					const state = store.getState();

					const scenarioId =
						state?.app?.user?.settings?.flags?.scenarioId;

					const dates = [];

					if (data?.source) {
						dates.push({
							startDate: data?.source?.startDate,
							endDate: `${data?.source?.endDate}T23:59:59.999Z`,
						});
					}

					const [source] = await CashflowAPI.card({
						dates: dates,
						scenarioId: scenarioId,
						accessToken: accessToken,
					});

					const key = { incoming: 'DEPOSIT', outgoing: 'WITHDRAW' };

					return {
						source: formatAccountCardResponse(
							source?.[key[data?.source?.variant]],
							source?.CREDIT,
						),
						target: formatAccountCardResponse(
							source?.[key[data?.target?.variant]],
							source?.CREDIT,
						),
					};
				}

				if (data?.type === 'health') {
					const [source] = await CompanyAPI.health({
						accessToken: accessToken,
						filters: [
							{
								startDate: data?.source?.startDate,
								endDate: `${data?.source?.endDate}T23:59:59.999Z`,
							},
						],
						dataloader: data?.dataloader,
					});

					return { source };
				}

				if (data?.type === 'customer-performance') {
					const companyId = store.getState()?.app?.company?._id;

					const response = await ClientsAPI.performance({
						accessToken: accessToken,
						companyId: companyId,
						filters: {
							clientType: 'CUSTOMER',
							dates: {
								invoiceDue: {
									gte: data?.source?.startDate,
									lte: `${data?.source?.endDate}T23:59:59.999Z`,
								},
							},
						},
					});

					return { source: response };
				}

				if (data?.type === 'supplier-performance') {
					const companyId = store.getState()?.app?.company?._id;

					const response = await ClientsAPI.performance({
						accessToken: accessToken,
						companyId: companyId,
						filters: {
							clientType: 'SUPPLIER',
							dates: {
								invoiceDue: {
									gte: data?.source?.startDate,
									lte: `${data?.source?.endDate}T23:59:59.999Z`,
								},
							},
						},
					});

					return { source: response };
				}

				if (data?.type === 'forecast-actions') {
					const actions = await onSubmit?.('forecast:actions:fetch');

					return { actions: actions };
				}

				if (data?.type === 'invoices') {
					const response = await InvoicesAPI.custom({
						accessToken,
						query: `
							query Invoices(
								$source: InvoiceStatisticsInput
								$target: InvoiceStatisticsInput
								$health: [HealthFilters!]!
							) {
								health(filters: $health) {
									score
									trend
									actions {
										type
										postfix
										data
									}
								}
								source: invoices {
									statistics(input: $source) {
										...InvoiceStatisticsFragment
									}
								}
								source_credit: invoices(
									filters: { type: "credit" }
								) {
									statistics(input: $source) {
										...InvoiceStatisticsSummaryFragment
									}
								}
								source_customers: invoices(
									filters: { clientType: CUSTOMER }
								) {
									statistics(input: $source) {
										...InvoiceStatisticsFragment
									}
								}
								source_customers_credit: invoices(
									filters: {
										clientType: CUSTOMER
										type: "credit"
									}
								) {
									statistics(input: $source) {
										...InvoiceStatisticsSummaryFragment
									}
								}
								source_suppliers: invoices(
									filters: { clientType: SUPPLIER }
								) {
									statistics(input: $source) {
										...InvoiceStatisticsFragment
									}
								}
								source_suppliers_credit: invoices(
									filters: {
										clientType: SUPPLIER
										type: "credit"
									}
								) {
									statistics(input: $source) {
										...InvoiceStatisticsSummaryFragment
									}
								}
								target: invoices {
									statistics(input: $target) {
										...InvoiceStatisticsFragment
									}
								}
								target_credit: invoices(
									filters: { type: "credit" }
								) {
									statistics(input: $target) {
										...InvoiceStatisticsSummaryFragment
									}
								}
								target_customers: invoices(
									filters: { clientType: CUSTOMER }
								) {
									statistics(input: $target) {
										...InvoiceStatisticsFragment
									}
								}
								target_customers_credit: invoices(
									filters: {
										clientType: CUSTOMER
										type: "credit"
									}
								) {
									statistics(input: $target) {
										...InvoiceStatisticsSummaryFragment
									}
								}
								target_suppliers: invoices(
									filters: { clientType: SUPPLIER }
								) {
									statistics(input: $target) {
										...InvoiceStatisticsFragment
									}
								}
								target_suppliers_credit: invoices(
									filters: {
										clientType: SUPPLIER
										type: "credit"
									}
								) {
									statistics(input: $target) {
										...InvoiceStatisticsSummaryFragment
									}
								}
							}

							fragment InvoiceStatisticsFragment on InvoiceStatisticsType {
								summary {
									count
									total
								}
								paid: status(status: PAID) {
									count
									total
								}
								overdue: status(status: OVERDUE) {
									count
									total
								}
								unpaid: status(status: UNPAID) {
									count
									total
								}
								forecast: status(status: FORECAST) {
									count
									total
								}
							}

							fragment InvoiceStatisticsSummaryFragment on InvoiceStatisticsType {
								summary {
									count
									total
								}
							}
						`,
						variables: {
							source: {
								dates: {
									invoiceDue: {
										gte: data?.source?.startDate,
										lte: `${data?.source?.endDate}T23:59:59.999Z`,
									},
								},
							},
							target: {
								dates: {
									invoiceDue: {
										gte: data?.target?.startDate,
										lte: `${data?.target?.endDate}T23:59:59.999Z`,
									},
								},
							},
							health: {
								startDate: data?.target?.startDate,
								endDate: `${data?.target?.endDate}T23:59:59.999Z`,
								metrics: {
									include: [
										'OVERDUE_COUNT',
										'OVERDUE_IMPACT',
										'CREDIT_COUNT',
										'CREDIT_IMPACT',
									],
								},
							},
						},
					});

					return formatInvoiceCardResponse(response);
				}

				if (data?.type === 'forecast') {
					const [source, target] = await Promise.all([
						onSubmit?.('forecaster:changes', {
							scenarioName: data?.source?.scenario,
							date: data?.source?.startDate,
							dataloader: dataloader,
						}),
						onSubmit?.('forecaster:changes', {
							scenarioName: data?.target?.scenario,
							date: data?.target?.startDate,
							dataloader: dataloader,
						}),
					]);

					return {
						source: CardResponse.forecast(
							source?.entries?.[0]?.types ?? [],
						),
						target: CardResponse.forecast(
							target?.entries?.[0]?.types ?? [],
						),
					};
				}

				if (data?.type === 'forecast-status') {
					const scenario = ScenarioStore.selectors.sourceScenarioData(
						store.getState(),
					);

					const [source, target] = await Promise.all([
						onSubmit?.('forecaster:changes', {
							scenarioName: data?.source?.scenario,
							date: data?.source?.startDate,
							dataloader: dataloader,
						}),
						onSubmit?.('forecaster:changes', {
							scenarioName: data?.target?.scenario,
							date: data?.target?.startDate,
							dataloader: dataloader,
						}),
					]);

					return {
						source: CardResponse.forecast(
							source?.entries?.[0]?.types ?? [],
						),
						target: CardResponse.forecast(
							target?.entries?.[0]?.types ?? [],
						),
						scenario: scenario,
					};
				}

				return {};
			}

			if (action === 'card:submit') {
				if (data?.type === 'feedback') {
					if (data?.data?.type === 'forecast-action') {
						onSubmit?.('forecast:actions:delete', data?.data?.id);
					}

					const state = store.getState();

					const form = data?.form;
					const dismiss = form?.dismiss ?? false;

					if (data?.variant === 'card') {
						return onSubmit('feedback:create', {
							id: state?.app?.user?.id,
							feedbackKey: [
								'card',
								data?.from,
								data?.data?.onboarding,
							]
								.filter(Boolean)
								.join('-'),

							message: [form?.theme, form?.description]
								.filter(Boolean)
								.join(' \n\n '),
							extra: { thumb: form?.thumb },
						});
					}

					return onSubmit('feedback:create', {
						id: state?.app?.user?.id,
						feedbackKey: [
							'card',
							data?.from,
							data?.data?.onboarding,
						]
							.filter(Boolean)
							.join('-'),
						dismissed: dismiss,
						value: form?.rating,
						message: [form?.title, form?.description]
							.filter(Boolean)
							.join(' \n\n '),
					});
				}

				if (data?.type === 'forecast') {
					const form = data?.data?.form;
					const startDate = data?.data?.startDate;
					const endDate = data?.data?.endDate;
					const scenarioName = data?.data?.scenarioName;

					const ctx = { startDate, endDate, scenarioName };

					const request = CardRequest.forecast(form, ctx);

					const response = await ForecastAPI.apply({
						input: request,
						accessToken: accessToken,
					});

					await queryClient.invalidateQueries({
						predicate: (query) =>
							query.queryKey.includes('forecast-status'),
					});

					return response;
				}
			}

			if (action === 'time:selector:statistics') {
				const date = parseDate(data?.date);

				const [health, account] = await Promise.all([
					onSubmit('card:fetch', {
						type: 'health',
						source: {
							startDate: formatISO(startOfMonth(date), {
								representation: 'date',
							}),
							endDate: formatISO(endOfMonth(date), {
								representation: 'date',
							}),
						},
						dataloader: data?.dataloader,
					}),
					onSubmit('card:fetch', {
						type: 'account',
						source: {
							startDate: formatISO(startOfMonth(date), {
								representation: 'date',
							}),
							endDate: formatISO(endOfMonth(date), {
								representation: 'date',
							}),
						},
						target: {
							startDate: formatISO(
								startOfMonth(subMonths(date, 1)),
								{ representation: 'date' },
							),
							endDate: formatISO(endOfMonth(subMonths(date, 1)), {
								representation: 'date',
							}),
						},
						dataloader: data?.dataloader,
					}),
				]);

				const source = account?.source;
				const target = account?.target;

				const sourceBalance =
					(source?.PAID?.total ?? 0) + (source?.FORECAST?.total ?? 0);
				const targetBalance =
					(target?.PAID?.total ?? 0) + (target?.FORECAST?.total ?? 0);

				let direction = null;

				if (Math.abs(sourceBalance - targetBalance) < 1) {
					direction = 'equal';
				} else if (sourceBalance > targetBalance) {
					direction = 'up';
				} else {
					direction = 'down';
				}

				return {
					health: { score: health?.source?.score },
					trends: {
						direction: direction,
					},
					balance: { value: sourceBalance },
				};
			}

			if (action === 'data:loader') {
				return defaultBulkCallback(...data);
			}
		},
		[accessToken, debug, dispatch, onAuthSubmit, queryClient, store],
	);

	const onAction = React.useCallback(
		async (action, data) => {
			const ctx = { queryClient };

			if (debug) {
				/* eslint-disable no-console */
				console.group('onAction');
				console.log('action', action);
				console.log('data', data);
				console.groupEnd();
				/* eslint-enable no-console */
			}

			if (action === 'go') {
				return go(data?.path ?? data, data);
			}

			if (action === 'redirect') {
				return onNavigate(data);
			}

			if (action === 'toggle:currency') {
				return onAction('redirect', { path: '/', skipFilters: true });
			}

			if (action === 'clients:filter') {
				dispatch(
					AppStore.addFilter({
						type: 'client',
						id: data.id,
						item: { id: data.id, name: data.name },
					}),
				);

				dispatch(ModalStore.close());

				return onAction('redirect', { path: '/', skipFilters: true });
			}

			if (action === 'snackbar.click') {
				dispatch(SnackbarStore.pop(data?._id));
			}

			if (action === 'user:remove') {
				const user = store.getState()?.app?.user;
				const userId = user?.id ?? null;

				return onSubmit('user:remove', { id: userId });
			}

			if (action === 'company:remove') {
				const company = store.getState()?.app?.company;
				const companyId = company?.id ?? null;

				return onSubmit('company:remove', { id: companyId });
			}

			if (action === 'currencies:fetch') {
				return onSubmit('currencies:fetch');
			}

			if (action === 'updateTimestamp') {
				dispatch(
					AppStore.setUserSettingsFlags({
						[data]: new Date().toISOString(),
					}),
				);

				return onSubmit('user:settings:refresh');
			}

			if (action === 'tooltip:hide') {
				const tooltip =
					store.getState()?.app?.user?.settings?.flags?.tooltip ?? {};

				dispatch(
					AppStore.setUserSettingsFlags({
						tooltip: { ...tooltip, [data]: true },
					}),
				);

				return onSubmit('user:settings:refresh');
			}

			if (action === 'markNotificationAsViewed' && data) {
				return onSubmit('notification:view', data);
			}

			if (action === 'removeNotificationBadge' && data) {
				return onSubmit('notification:badge:remove', data);
			}

			if (action === 'forecaster:open') {
				return onForecasterOpen();
			}

			if (action === 'forecaster:close') {
				return onForecasterClose();
			}

			if (action === 'teaser:close') {
				dispatch(
					AppStore.setUserSettingsFlags(
						Object.fromEntries(
							[]
								.concat(data)
								.map((key) => [
									['teaser', key].join(':'),
									true,
								]),
						),
					),
				);

				return onSubmit('user:settings:refresh');
			}

			if (action === 'layout:resize') {
				setLayoutSize(data);
			}

			if (action === 'sidebar:visible') {
				setHasSidebar(data);
			}

			if (action === 'graph:click') {
				dispatch(
					TransactionStore.setState(
						TransactionStore.constants.STATES.All,
					),
				);

				dispatch(TransactionStore.setActive(null));
			}

			if (action === 'deleteTransaction') {
				return onSubmit('transactions:delete', {
					items: data.map(({ id }) => id),
				});
			}

			if (action === 'updateUserSettings') {
				const state = store.getState();

				return onSubmit('user:settings:update', {
					id: state?.app?.user?.id,
					...data,
				});
			}

			if (action === 'refreshUserSettings') {
				return onSubmit('user:settings:refresh');
			}

			if (action === 'refreshCompanySettingsFlags') {
				return onSubmit('company:settings:refresh');
			}

			if (action === 'setFilters') {
				const state = store.getState();

				const { tags, statuses, clients, currencies } = (
					data ?? []
				).reduce(
					(acc, object) => {
						const type = object?.type;

						if (type === 'tag') {
							const item = (state?.app?.tags ?? []).find(
								(tag) =>
									object?.tagName === tag?.name &&
									object?.categoryName ===
										tag?.category?.name,
							);

							if (item) {
								acc.tags.push({
									id: item?._id ?? item?.id,
									item: item,
									type: 'tag',
								});
							}
						}

						if (type === 'status') {
							const ID =
								object?._id ?? object?.id ?? object?.status;

							acc.statuses.push({
								type: 'status',
								id: ID,
								item: {
									id: ID,
									code: ID,
									name: TranslationService.get([
										`status.${ID}`,
										`selector.status.${ID}`,
									]),
								},
							});
						}

						if (type === 'client') {
							const ID = object?._id ?? object?.id;

							const item = AppStore.selectors.client(state, ID);

							if (item) {
								acc.clients.push({
									type: 'client',
									id: ID,
									item: item,
								});
							}
						}

						if (type === 'currency') {
							const ID = object?._id ?? object?.id;

							const item = AppStore.selectors
								.currencies(state)
								.find((object) => object?.code === ID);

							if (item) {
								acc.currencies.push({
									type: 'currency',
									id: ID,
									item: item,
								});
							}
						}

						return acc;
					},
					{ tags: [], statuses: [], clients: [], currencies: [] },
				);

				dispatch?.(
					AppStore.setFilters([
						...tags,
						...statuses,
						...clients,
						...currencies,
					]),
				);

				onAction?.('redirect', {
					view: 'cashflow',
					options: { skipFilters: true },
				});
			}

			if (action === 'updateFeedback') {
				const state = store.getState();

				return onSubmit('feedback:create', {
					id: state?.app?.user?.id,
					...data,
				});
			}

			if (action === 'askForFeedback') {
				setFeedbackParams(data);
			}

			if (action === 'graph:forecaster:show') {
				let path = ['forecaster', 'category'];

				if (data?.type) {
					path = path.concat([data?.type]);
				}

				return onAction?.('redirect', { path: '/' + path.join('/') });
			}

			if (action === 'tour:start') {
				dispatch(TourStore.show({ type: data }));
			}

			if (action === 'tour:end') {
				dispatch(TourStore.hide());
			}

			if (action === 'forecaster:validate') {
				return onForecasterValidate(data);
			}

			if (
				action === 'company:settings:save' ||
				action === 'welcome:company:settings:save'
			) {
				return onSubmit('company:update:self', data);
			}

			if (action === 'welcome:integration:cancel') {
				return onSubmit('integrations:delete', {
					id: data?._id ?? data?.id,
				});
			}

			if (action === 'welcome:close') {
				dispatch(AppStore.setUserSettingsFlags({ welcome: true }));
				dispatch(AppStore.setCompanySettingsFlags({ welcome: true }));

				onSubmit('company:settings:refresh');
				onSubmit('user:settings:refresh');

				if (hasWelcomeForecasterFeature && hasForecasterFeature) {
					return onAction('redirect', '/forecaster');
				}

				return onAction('redirect', '/');
			}

			if (
				action?.startsWith?.('settings') ||
				action === 'forecaster:settings:save' ||
				action === 'user:settings:save'
			) {
				return onSettingsAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						event: { type: action, data: data },
					},
					ctx,
				).then(() => {
					dispatch(ModalStore.close());
				});
			}

			if (action?.startsWith?.('forecaster')) {
				const sourceScenario = ScenarioStore.selectors.sourceScenario(
					store.getState(),
				);

				if (
					action === 'forecaster:abort' ||
					action === 'forecaster:close'
				) {
					onAction?.('forecaster:close');
				}

				if (action === 'forecaster:feedback:submit') {
					Analytics.event('forecaster.feedback.submit', {
						sourceScenario: sourceScenario,
					});

					onAction?.(...Object.values(data ?? {}));
				}

				if (action === 'forecaster:feedback:support') {
					Analytics.event('forecaster.feedback.support', {
						sourceScenario: sourceScenario,
					});

					onAction?.('go', '/support');
				}

				if (action === 'forecaster:tour:show') {
					dispatch(
						TourStore.show({ type: TourStore.TYPES.Forecaster }),
					);
				}

				return onForecasterAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						sourceScenario: sourceScenario,
						event: { type: action, data: data },
					},
					ctx,
				).then(() => {
					if (action === 'forecaster:save' && !data?.noRedirect) {
						onAction?.('forecaster:close');
					}
				});
			}

			if (action?.startsWith?.('tag')) {
				return onTagAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						event: { type: action, data: data },
					},
					ctx,
				);
			}

			if (action?.startsWith?.('scenario')) {
				return onScenarioAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						event: { type: action, data: data },
					},
					ctx,
				);
			}

			if (action?.startsWith?.('client')) {
				return onClientAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						event: { type: action, data: data },
					},
					ctx,
				);
			}

			if (action?.startsWith?.('feedback')) {
				await onFeedbackAction(
					{
						dispatch: dispatch,
						accessToken: accessToken,
						event: { type: action, data: data },
					},
					ctx,
				);

				dispatch(ModalStore.close());
			}

			if (action === 'auth:token' || action === 'auth:language') {
				return onAuthAction(action, data);
			}

			if (action?.startsWith?.('auth:')) {
				return onSubmit(action, data);
			}

			if (action === 'card:redirect') {
				if (
					data?.type === 'incoming' ||
					data?.type === 'outgoing' ||
					data?.type === 'account' ||
					data?.type === 'health' ||
					data?.type === 'profit'
				) {
					const userSettings = {
						activeBars: selectors.activeBars(store.getState()),
						currentDate: selectors.currentDate(store.getState()),
						selectedDate: selectors.selectedDate(store.getState()),
						graphTypes: selectors.graphTypes(store.getState()),
						graphOptions: selectors.graphOptions(store.getState()),
						graphActiveGroups: selectors.graphActiveGroups(
							store.getState(),
						),
					};

					const settings = {
						resetFilterType: true,
						filters: [],
						selectedDate: data?.startDate,
						currentDate: format(
							TimesizeUtils.addTimeslice(
								parseISO(data?.startDate),
								'month',
								-3,
							),
							'yyyy-MM-dd',
						),
						graphActiveGroups: [data?.startDate],
					};

					if (data?.type === 'account' || data?.type === 'profit') {
						settings.graphOptions = {
							lineGraph: true,
							barGraph: false,
						};
					}

					if (data?.type === 'incoming') {
						settings.activeBars = ['deposit'];
						settings.graphTypes = ['DEPOSIT'];
						settings.graphOptions = {
							lineGraph: false,
							barGraph: true,
						};

						applyCashflowSettings({
							settings: settings,
							dispatch: dispatch,
						});

						onAction?.('go', {
							path: '/',
							state: {
								settings: userSettings,
								data: { type: data?.type },
							},
						});

						dispatch(
							GraphStore.setLegendSelected({
								id: 'deposit',
								part: 'cashflow',
								title: 'Deposit',
								type: ['deposit'],
							}),
						);

						return;
					}

					if (data?.type === 'outgoing') {
						settings.activeBars = ['withdraw'];
						settings.graphTypes = ['WITHDRAW'];
						settings.graphOptions = {
							lineGraph: false,
							barGraph: true,
						};

						applyCashflowSettings({
							settings: settings,
							dispatch: dispatch,
						});

						onAction?.('go', {
							path: '/',
							state: {
								settings: userSettings,
								data: { type: data?.type },
							},
						});

						dispatch(
							GraphStore.setLegendSelected({
								id: 'withdraw',
								part: 'cashflow',
								title: 'Withdraw',
								type: ['withdraw'],
							}),
						);

						return;
					}

					applyCashflowSettings({
						settings: settings,
						dispatch: dispatch,
					});

					onAction?.('go', {
						path: '/',
						state: {
							settings: userSettings,
							data: { type: data?.type },
						},
					});

					return;
				}

				if (data?.type === 'onboarding') {
					return onAction?.('redirect', '/onboarding');
				}

				if (data?.type === 'forecast-actions') {
					return onAction?.('redirect', '/forecaster');
				}

				if (data?.type === 'customer-performance') {
					dispatch(
						AppStore.setSettings({
							clients: { type: 'CUSTOMER' },
						}),
					);

					return onAction?.('redirect', '/clients');
				}

				if (data?.type === 'supplier-performance') {
					dispatch(
						AppStore.setSettings({
							clients: { type: 'SUPPLIER' },
						}),
					);

					return onAction?.('redirect', '/clients');
				}

				if (data?.type === 'invoices') {
					dispatch(AppStore.setFilters([]));
					dispatch(AppStore.resetFilterType());

					dispatch(AppStore.setSelectedDate(data?.startDate));
					dispatch(GraphStore.setActiveGroups(data?.startDate));

					dispatch(
						AppStore.setCurrentDate(
							format(
								TimesizeUtils.addTimeslice(
									parseISO(data?.startDate),
									'month',
									-3,
								),
								'yyyy-MM-dd',
							),
						),
					);

					const filters = AppStore.selectors
						.tags(store.getState())
						.filter(
							(object) =>
								object?.category?.name === '$invoices' &&
								['$customer', '$supplier'].includes(
									object?.name,
								),
						)
						.map((item) => ({
							type: 'tag',
							id: item?._id ?? item?.id,
							item: item,
						}));

					dispatch(AppStore.setFilters(filters));

					return onAction?.('go', {
						path: '/',
						state: {
							data: { type: data?.type },
						},
					});
				}

				if (data?.type === 'forecast') {
					return onAction?.('redirect', '/forecaster');
				}

				if (data?.type === 'forecast-status') {
					return onAction?.('redirect', '/forecaster/category');
				}

				return;
			}

			if (action === 'card:connect') {
				const type = data?.form?.type;
				const from = data?.form?.from;

				if (from === 'forecast-status') {
					return onAction?.('go', '/forecaster');
				}

				if (!type) {
					return onAction?.('go', '/integrations/connect');
				}

				return onAction?.('go', `/integrations/add/${type}`);
			}

			if (action === 'card:close') {
				if (data?.state) {
					return;
				}

				if (data?.type === 'feedback') {
					const from = data?.from ?? 'unknown';
					const onboarding = data?.data?.onboarding ?? 'none';
					const flags = store.getState()?.app?.user?.settings?.flags;
					const available = flags?.['card:close'];

					if (data?.data?.type === 'forecast-action') {
						return;
					}

					dispatch(
						AppStore.setUserSettingsFlags({
							'card:close': {
								...available,
								[from]: {
									...available?.[from],
									[onboarding]: new Date().toISOString(),
								},
							},
						}),
					);

					return onSubmit('user:settings:refresh');
				}
			}

			if (action === 'card:pin') {
				const type = data?.type ?? 'unknown';
				const flags = store.getState()?.app?.user?.settings?.flags;
				const available = flags?.['card:pinned'];

				const value = available?.[type];

				dispatch(
					AppStore.setUserSettingsFlags({
						'card:pinned': {
							...available,
							[type]: !value ? new Date().toISOString() : null,
						},
					}),
				);

				return onSubmit('user:settings:refresh');
			}

			if (action === 'card:action') {
				if (data?.type === 'customer-details') {
					dispatch(
						AppStore.setSettings({
							clients: { type: 'CUSTOMER' },
						}),
					);

					return dispatch(
						ModalStore.open({
							type: ModalStore.MODAL_WINDOWS.ClientOverview,
							data: { _id: data?.client?.clientId },
						}),
					);
				}

				if (data?.type === 'supplier-details') {
					dispatch(
						AppStore.setSettings({
							clients: { type: 'SUPPLIER' },
						}),
					);

					return dispatch(
						ModalStore.open({
							type: ModalStore.MODAL_WINDOWS.ClientOverview,
							data: { _id: data?.client?.clientId },
						}),
					);
				}

				if (data?.type === 'invoices') {
					const filters = [];

					if (data?.status) {
						const statuses = AppStore.selectors.statuses(
							store.getState(),
						);

						const selectedStatus = statuses.find(
							(status) =>
								data?.status?.toLowerCase?.() ===
								status?.toLowerCase?.(),
						);

						if (selectedStatus) {
							filters.push({
								type: 'status',
								id: selectedStatus,
								item: {
									id: selectedStatus,
									code: selectedStatus,
									name: TranslationService.get([
										`status.${selectedStatus}`,
										`selector.status.${selectedStatus}`,
									]),
								},
							});
						}
					}

					if (data?.section) {
						const tags = AppStore.selectors.tags(store.getState());

						let tag;

						if (data?.section === 'customers') {
							tag = tags.find(
								(object) =>
									object?.category?.name === '$invoices' &&
									object?.name === '$customer',
							);
						}

						if (data?.section === 'suppliers') {
							tag = tags.find(
								(object) =>
									object?.category?.name === '$invoices' &&
									object?.name === '$supplier',
							);
						}

						if (tag) {
							filters.push({
								type: 'tag',
								id: tag?._id ?? tag?.id,
								item: tag,
							});
						}
					}

					dispatch(AppStore.setFilters(filters));

					return onAction?.('go', '/');
				}

				if (
					data?.type === 'onboarding-error' ||
					data?.type === 'onboarding-outdated'
				) {
					return onAction?.('go', '/onboarding');
				}

				if (data?.type === 'forecast') {
					const type = data?.data?.type;
					const category = data?.data?.category;
					const tag = data?.data?.tag;

					const path = [type];

					if (category && tag) {
						const object = AppStore.selectors
							.tags(store.getState())
							.find(
								(object) =>
									object?.category?.name === category &&
									object?.name === tag,
							);
						const id = object?._id ?? object?.id;

						if (id) {
							path.push(id);
						}
					}

					return onAction?.(
						'redirect',
						['/forecaster/category', ...path].join('/'),
					);
				}

				if (data?.type === 'forecast-status') {
					const type = data?.data?.type;
					const category = data?.data?.category;
					const tag = data?.data?.tag;

					const path = [type];

					if (category && tag) {
						const object = AppStore.selectors
							.tags(store.getState())
							.find(
								(object) =>
									object?.category?.name === category &&
									object?.name === tag,
							);
						const id = object?._id ?? object?.id;

						if (id) {
							path.push(id);
						}
					}

					return onAction?.(
						'redirect',
						['/forecaster/category', ...path].join('/'),
					);
				}

				if (data?.type === 'forecast-actions') {
					const type = data?.data?.type;
					const id = data?.data?.id;

					return onAction?.('redirect', `/forecaster/${type}/${id}`);
				}
			}

			if (action === 'support:chat:open') {
				const response = await onSubmit?.('conversation:list', {
					filters: { status: 'PENDING' },
				});

				let chatId = response?.edges?.[0]?.node?._id;

				if (!chatId) {
					const response = await onSubmit?.(
						'conversation:create',
						{},
					);

					chatId = response?.data?.[0]?._id;
				}

				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Conversation,
						data: {
							id: chatId,
							type: 'support',
							info: TranslationService.get(
								'support.conversation.info.text',
							),
						},
					}),
				);
			}

			if (action === 'dialog:close') {
				const type = data?.type;

				const flags = store.getState()?.app?.user?.settings?.flags;
				const available = flags?.['dialog:close'];

				dispatch(
					AppStore.setUserSettingsFlags({
						'dialog:close': {
							...available,
							[type]: new Date().toISOString(),
						},
					}),
				);

				return onSubmit('user:settings:refresh');
			}
		},
		[
			queryClient,
			debug,
			go,
			onNavigate,
			dispatch,
			store,
			onSubmit,
			onForecasterOpen,
			onForecasterClose,
			onForecasterValidate,
			hasWelcomeForecasterFeature,
			hasForecasterFeature,
			accessToken,
			onAuthAction,
		],
	);

	React.useEffect(() => {
		onActionRef.current = onAction;
	}, [onAction]);

	React.useEffect(() => {
		onSubmitRef.current = onSubmit;
	}, [onSubmit]);

	const onSnackbarAction = LayoutHooks.snackbar.useAction({
		onAction: onAction,
	});

	const onModalClose = LayoutHooks.modals.useClose();

	LayoutHooks.integrations.useSubscription({
		onAction: onAction,
		onSubmit: onSubmit,
	});

	const feedback = React.useMemo(
		() => ({
			state: feedbackParams,
			set: (data) => setFeedbackParams(data),
			reset: () => setFeedbackParams(null),
		}),
		[feedbackParams],
	);

	const ctx = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	const outletCtx = React.useMemo(
		() => ({ wrapperRef: wrapperRef, feedback: feedback }),
		[feedback, wrapperRef],
	);

	if (auth) {
		return (
			<LayoutContext.Provider value={ctx}>
				<Outlet />
			</LayoutContext.Provider>
		);
	}

	return (
		<LayoutContext.Provider value={ctx}>
			<DevToolsButton onAction={onAction} onSubmit={onSubmit} />
			<Setup2FA onAction={onAction} onSubmit={onSubmit} />
			<ConversationUpdater onSubmit={onSubmit} />
			<CashflowStatisticsUpdater />
			<LayoutHistoryCallback callback={callback} navigate={onNavigate} />
			<DemoNotifications />
			<AnalyticsUpdater view={view} />
			<WelcomeConfigWrapper>
				<ViewWrapper
					layoutSize={layoutSize}
					hasSidebar={hasSidebar}
					view={view}
					ref={setWrapperRef}
				>
					<div id="asteria-sidepane-container"></div>

					<FeatureFlag feature="responsive" invert>
						<div className="asteria-information">
							<Translation
								Component={Text}
								translationKey="asteria.information.mobile"
							/>
						</div>
					</FeatureFlag>

					<FeatureFlag feature="floating-feedback">
						<FloatingFeedbackButton onAction={onAction} />
					</FeatureFlag>

					<IntegrationsDialog
						onFetch={onSubmit}
						hidden={['welcome', 'forecaster', 'financial'].includes(
							view,
						)}
					/>

					<SnackbarWrapper onAction={onSnackbarAction} />
					<ModalCollection
						onAction={onAction}
						onClose={onModalClose}
						onSubmit={onSubmit}
					/>

					<TourLogic onAction={onAction} />
					<Popups onAction={onAction} onSubmit={onSubmit} />

					{!hasNavigationContentFeature ? (
						<NavigationLogic
							view={view}
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					) : null}
					<Content
						className={cn(
							'asteria-content-view',
							`asteria-content-view-${view}`,
							{
								'asteria--feature-navigation-content':
									hasNavigationContentFeature,
								'asteria--feature-breadcrumbs':
									hasBreadcrumbsFeature,
							},
							`asteria--location-${location.pathname
								.split('/')
								.filter(Boolean)
								.join('-')}`,
						)}
						scroll
						border={false}
					>
						{hasNavigationContentFeature ? (
							<NavigationLogic
								view={view}
								onAction={onAction}
								onSubmit={onSubmit}
							/>
						) : null}

						{hasBreadcrumbsFeature && view !== 'swedbank' ? (
							<Breadcrumbs
								onAction={onAction}
								onSubmit={onSubmit}
							/>
						) : null}

						<Outlet context={outletCtx} />
						<SupportFloatButton
							onAction={onAction}
							onSubmit={onSubmit}
						/>
						{view !== 'welcome' ? (
							<FeatureFlag feature="support-contact-dialog">
								<SupportDialog
									onAction={onAction}
									onSubmit={onSubmit}
								/>
							</FeatureFlag>
						) : null}
					</Content>
				</ViewWrapper>
			</WelcomeConfigWrapper>
		</LayoutContext.Provider>
	);
};

Layout.displayName = 'Layout';

Layout.propTypes = {
	callback: PropTypes.func,
	debug: PropTypes.bool,
	auth: PropTypes.bool,
};

export default Layout;
