import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import SatisfactionSelectorColumn from './column';

import '../styles.scss';

const SatisfactionSelector = React.memo((props) => {
	const { onSelect, active } = props;

	return (
		<div
			className={cn(
				'asteria-component__satisfaction-selector',
				'asteria--state-open',
			)}
		>
			<SatisfactionSelectorColumn
				value={0.2}
				active={active <= 0.2}
				onSelect={onSelect}
			/>
			<SatisfactionSelectorColumn
				value={0.4}
				active={active > 0.2 && active <= 0.4}
				onSelect={onSelect}
			/>
			<SatisfactionSelectorColumn
				value={0.6}
				active={active > 0.4 && active <= 0.6}
				onSelect={onSelect}
			/>
			<SatisfactionSelectorColumn
				value={0.8}
				active={active > 0.6 && active <= 0.8}
				onSelect={onSelect}
			/>
			<SatisfactionSelectorColumn
				value={1}
				active={active > 0.8 && active <= 1}
				onSelect={onSelect}
			/>
		</div>
	);
});

SatisfactionSelector.displayName = 'SatisfactionSelector';
SatisfactionSelector.propTypes = {
	onSelect: PropTypes.func,
	active: PropTypes.number,
};

export default SatisfactionSelector;
