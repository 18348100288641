import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { stateClasses } from '@asteria/component-core/utils';

import { ListItem } from '@asteria/component-list';

import * as TransactionStore from '@asteria/datalayer/stores/transactions';

import { cn } from '@asteria/utils-funcs/classes';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { Context } from '../../../../context';

import TransactionCell from './Cell';

import './styles.scss';

const TransactionItem = React.memo((props) => {
	const { className, id, loading, onAction, columns } = props;

	const isActive = useSelector((store) =>
		TransactionStore.selectors.isActive(store, id),
	);

	const isSelected = useSelector((store) =>
		TransactionStore.selectors.isSelected(store, id),
	);

	return (
		<ListItem
			className={cn(
				'asteria-component__transactions__item',
				className,
				stateClasses({
					active: isActive,
					selected: isSelected,
				}),
			)}
		>
			{columns.map((type) => (
				<TransactionCell
					key={type}
					type={type}
					id={id}
					loading={loading}
					onAction={onAction}
				/>
			))}
		</ListItem>
	);
});

TransactionItem.displayName = 'TransactionItem';

TransactionItem.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	loading: PropTypes.bool,
	onAction: PropTypes.func,

	columns: PropTypes.arrayOf(PropTypes.string),
};

const TransactionItemProvider = (props) => {
	const { id, loading } = props;

	const ctx = React.useContext(Context);
	const { useIsValid } = ctx;

	const { columns } = useDeepMemo(
		() => ({ columns: ctx?.columns?.visible }),
		[ctx?.active, ctx?.columns?.visible, ctx?.selected, id],
	);

	const isValid = useIsValid(id);

	if (!loading && !isValid) {
		return null;
	}

	return <TransactionItem {...props} columns={columns} />;
};

TransactionItemProvider.displayName = 'TransactionItemProvider';

TransactionItemProvider.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	loading: PropTypes.bool,
	onAction: PropTypes.func,
};

export default React.memo(TransactionItemProvider);
