import React from 'react';

import PropTypes from 'prop-types';

import Questions from '../../Questions';

import './styles.scss';

const QuizStep = (props) => {
	const { step, name, onAction } = props;

	return <Questions step={step} name={name} onAction={onAction} />;
};

QuizStep.displayName = 'QuizStep';

QuizStep.propTypes = {
	step: PropTypes.number,
	name: PropTypes.string,
	onAction: PropTypes.func,
};

export default QuizStep;
