import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useQueries } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Provider as FeatureProvider } from '@asteria/component-tools/featureflag/context';

import * as AppStore from '@asteria/datalayer/stores/app';

import { fetchActiveFeatures, fetchPartnerFeatures } from '../api/features';

const selectors = {
	features: createSelector(
		(store) => store?.features?.features,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const FeaturesWrapper = React.memo((props) => {
	const { children, partnerId } = props;

	const dispatch = useDispatch();

	const accessToken = useSelector(AppStore.selectors.accessToken);
	const features = useSelector(selectors.features, (a, b) => isEqual(a, b));

	useQueries({
		queries: [
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				queryKey: [
					'widget',
					'features',
					partnerId ?? 'NONE',
					'partner',
				],
				queryFn: async ({ signal }) =>
					fetchPartnerFeatures({
						partnerId: partnerId,
						dispatch: dispatch,
						signal: signal,
					}),
				enabled: !!partnerId,
			},
			{
				refetchOnMount: false,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				queryKey: [
					'widget',
					'features',
					partnerId ?? 'NONE',
					accessToken ?? 'NONE',
					'active',
				],
				queryFn: async ({ signal }) =>
					fetchActiveFeatures({
						accessToken: accessToken,
						partnerId: partnerId,
						dispatch: dispatch,
						signal: signal,
					}),
				enabled: !!accessToken,
			},
		],
	});

	return <FeatureProvider features={features}>{children}</FeatureProvider>;
});

FeaturesWrapper.displayName = 'FeaturesWrapper';
FeaturesWrapper.propTypes = {
	children: PropTypes.node,
	partnerId: PropTypes.string,
};

export default FeaturesWrapper;
