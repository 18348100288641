import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

const initialState = {
	notifications: [],
	modals: [],
	dialogs: [],
};

export const slice = createSlice({
	name: 'notifications',
	initialState: initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = (action.payload ?? []).filter(
				({ type }) => type !== 'modal' && type !== 'dialog',
			);

			state.modals = (action.payload ?? []).filter(
				({ type }) => type === 'modal',
			);

			state.dialogs = (action.payload ?? []).filter(
				({ type }) => type === 'dialog',
			);
		},
		removeNotification: (state, action) => {
			state.notifications = state.notifications.filter(
				({ id }) => id !== action.payload,
			);

			state.modals = state.modals.filter(
				({ id }) => id !== action.payload,
			);

			state.dialogs = state.dialogs.filter(
				({ id }) => id !== action.payload,
			);
		},
		setNotificationOptions: (state, { payload }) => {
			const IDs = payload?.IDs ?? [];
			const key = payload?.key ?? null;
			const value = payload?.value ?? null;

			state.notifications.forEach((object, index) => {
				if (IDs.includes(object.id)) {
					if (state.notifications[index].options === undefined) {
						state.notifications[index].options = {};
					}

					state.notifications[index].options[key] = value;
				}
			});
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { setNotifications, removeNotification, setNotificationOptions } =
	slice.actions;

export default slice.reducer;
