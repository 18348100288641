import React from 'react';

import PropTypes from 'prop-types';

import Wrapper, { Footer, Header } from '@asteria/component-core/wrapper';

import { cn } from '@asteria/utils-funcs/classes';

import Content from './content';

import './styles.scss';

const StepInner = function Step(props) {
	const { className, children } = props;

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step__content__inner',
				className,
			)}
		>
			{children}
		</Wrapper>
	);
};

StepInner.propTypes = {
	className: PropTypes.string,
	step: PropTypes.string,
	children: PropTypes.node,
};

StepInner.defaultProps = {
	className: null,
};

const Step = function Step(props) {
	const { className, step, children } = props;

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-step',
				`asteria-component__onboarding-step--step-${step}`,
				className,
			)}
		>
			{children}
		</Wrapper>
	);
};

Step.propTypes = {
	className: PropTypes.string,
	step: PropTypes.string,
	children: PropTypes.node,
};

Step.defaultProps = {
	className: null,
};

export default Step;
export { Content, Footer, Header, StepInner };
