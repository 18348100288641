import React from 'react';

const WelcomeContext = React.createContext({
	actions: [],
	wizard: {},
	questions: {},
	placeholder: {},
});

export default WelcomeContext;
