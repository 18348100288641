import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';

import Currencies from '@asteria/component-currency';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import './styles.scss';

const OverviewView = (props) => {
	const { onAction } = props;

	useEffect(() => {
		onAction?.('currencies:fetch');
	}, [onAction]);

	useTourShowAction();

	return (
		<div className="asteria-view__overview">
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get('company.overview.title')}
					/>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-overview">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<Currencies onAction={onAction} />
		</div>
	);
};

OverviewView.propTypes = {
	onAction: PropTypes.func,
};

export default OverviewView;
