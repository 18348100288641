function formatTransaction(data) {
	return {
		total: data?.total ?? 0,
		probability: data?.probability ?? 1,
		description: data?.description,
		links: data?.links,
		currency: data?.currency,
		paymentDate: data?.paymentDate,
	};
}

function formatTag(data) {
	const children = (data?.transactions ?? []).filter(
		(object) => object?.total,
	);

	return {
		total: data?.total ?? 0,
		probability: data?.probability ?? 1,
		transactions: Object.fromEntries(
			children.map((object) => [
				object?._id ?? object?.id,
				formatTransaction(object),
			]),
		),
	};
}

function formatCategory(data) {
	const children = (data?.tags ?? []).filter((object) => object?.total);

	return {
		total: data?.total ?? 0,
		probability: data?.probability ?? 1,
		tags: Object.fromEntries(
			children.map((object) => [
				object?.details?.name,
				formatTag(object),
			]),
		),
	};
}

function formatType(data) {
	const children = (data?.categories ?? []).filter((object) => object?.total);

	return {
		total: data?.total ?? 0,
		probability: data?.probability ?? 1,
		categories: Object.fromEntries(
			children.map((category) => [
				category?.details?.name,
				formatCategory(category),
			]),
		),
		tags: children.flatMap((category) =>
			(category?.tags ?? [])
				.filter((tag) => tag?.total)
				.map((tag) => {
					const id = tag?._id ?? tag?.id;

					return {
						id: id,
						_id: id,

						category: category?.details?.name,
						tag: tag?.details?.name,
					};
				}),
		),
	};
}

function format(data) {
	const children = data.filter((object) => object?.total);

	return Object.fromEntries(
		children.map((object) => [
			object?.type?.toLowerCase?.(),
			formatType(object),
		]),
	);
}

export default format;
