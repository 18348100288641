import { get } from 'lodash-es';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import * as Category from './category';
import * as Changes from './changes';
import * as Tag from './tag';
import { getPath, validateFormContext } from './utils';

/**
 *
 * @param { { type: string,  } } options
 * @param { unknown } ctx
 * @returns { { value: number, min: number } }
 */
function getTransactionValue({ type }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type });

	const categories = get(context.form, path.concat('categories')) ?? {};

	return Object.values(categories)
		.flatMap((object) => Object.values(object?.tags ?? {}))
		.flatMap((object) => Object.values(object?.transactions ?? {}))
		.reduce(
			(acc, { total, probability }) => ({
				value: acc.value + (total || 0),
				min: acc.min + (total || 0) * (probability || 1),
			}),
			{ value: 0, min: 0 },
		);
}

export function setProbability({ type, value }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type });

	const changes = [];

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: value,
			},
			context,
		),
	);

	const categories = get(context.form, path.concat('categories')) ?? {};

	for (const category in categories) {
		changes.push(
			...Category.setProbability({ type, category, value }, context),
		);
	}

	return changes;
}

export function setValue({ type, value, recalculate }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type });
	const adjustedPath = getPath({
		type,
		category: '$forecaster',
		tag: '$adjusted',
	});

	const changes = [];

	const current = getTransactionValue({ type }, context);

	const adjustedValue = get(context.form, adjustedPath.concat('total')) || 0;

	changes.push(
		Changes.create(
			{ type: 'set', name: path.concat('total').join('.'), value: value },
			context,
		),
	);

	if (value === current.value) {
		return changes;
	}

	if (value > current.value) {
		// Working with $adjusted tag

		changes.push(
			...Tag.setValue(
				{
					type,
					category: '$forecaster',
					tag: '$adjusted',
					value: value - current.value + adjustedValue,
					recalculate: 'category',
				},
				context,
			),
		);
	}

	if (value < current.value) {
		// Working with existing categories

		const diff = current.value - value;

		if (adjustedValue > diff) {
			changes.push(
				...Tag.setValue(
					{
						type,
						category: '$forecaster',
						tag: '$adjusted',
						value: adjustedValue - diff,
						recalculate: 'category',
					},
					context,
				),
			);
		} else {
			if (adjustedValue) {
				changes.push(
					...Tag.setValue(
						{
							type,
							category: '$forecaster',
							tag: '$adjusted',
							value: 0,
							recalculate: 'category',
						},
						context,
					),
				);
			}

			const diff = value / (current.value - adjustedValue);

			const categories =
				get(context.form, path.concat('categories')) ?? {};

			for (const category in categories) {
				const path = getPath({ type, category });

				const previous = get(context.form, path.concat('total')) || 0;
				const next = previous * diff;

				changes.push(
					...Category.setValue(
						{ type, category, value: next },
						context,
					),
				);
			}
		}
	}

	if (recalculate) {
		const categories = get(context.form, path.concat('categories')) ?? {};

		for (const category in categories) {
			const path = getPath({ type, category });
			const tags = get(context.form, path.concat('tags')) ?? {};

			for (const tag in tags) {
				changes.push(
					...Tag.recalculate(
						{ type, category, tag, recalculate: 'category' },
						context,
					),
				);
			}
		}
	}

	return changes;
}

export function recalculate({ type }, ctx) {
	const context = validateFormContext(ctx);
	const path = getPath({ type });

	const changes = [];

	const current = getTransactionValue({ type }, context);

	const probability = normalizeProbability(
		current.value ? current.min / current.value : 1,
	);

	changes.push(
		Changes.create(
			{
				type: 'set',
				name: path.concat('total').join('.'),
				value: current.value,
			},
			context,
		),
		Changes.create(
			{
				type: 'set',
				name: path.concat('probability').join('.'),
				value: probability,
			},
			context,
		),
	);

	return changes;
}
