import PropTypes from 'prop-types';

export const Size = {
	title: ['page-title', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs'],
	full: ['xl', 'lg', 'md', 'sm', 'xs', 'xxs'],
	modal: ['xxl', 'xl', 'lg', 'md', 'sm'],
	default: ['lg', 'md', 'sm'],
};

/**
 * @param { 'title' | 'full' | 'modal' } [type]
 */
export const SizeProp = (type) => {
	const values = Size[type] ?? Size.default;

	const propType = PropTypes.oneOf([...values]);
	propType.values = values;

	return propType;
};
