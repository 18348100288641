import { ClientService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import { setClients } from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import * as ClientsAPI from '../api/clients';

/**
 * @param { { dispatch: unknown, accessToken: string, event: { type?: 'settings:save', data?: unknown } } } options
 */
export const onAction = (options) => {
	const { event, dispatch, accessToken } = options;

	if (event?.type === 'client:create') {
		ClientService.client
			.create({ input: event?.data }, { token: accessToken })
			.then(() => ClientsAPI.fetch({ accessToken: accessToken }))
			.then((data) => {
				dispatch(setClients(data));
			});

		dispatch(ModalStore.close());
	}

	if (event?.type === 'client:update') {
		ClientService.client
			.update({ input: event?.data }, { token: accessToken })
			.then(() => ClientsAPI.fetch({ accessToken: accessToken }))
			.then((data) => {
				dispatch(setClients(data));
			});

		dispatch(ModalStore.close());
	}

	if (event?.type === 'client:remove') {
		const state = store.getState();

		ClientService.client
			.remove({ ids: event?.data?._id }, { token: accessToken })
			.then(() => ClientsAPI.fetch({ accessToken: accessToken }))
			.then((data) => {
				dispatch(setClients(data));
			});

		const history = state?.modals?.history ?? [];

		let size = 1;

		if (
			history.some(
				({ type }) => type === ModalStore.MODAL_WINDOWS.ClientEdit,
			)
		) {
			size = 2;
		}

		dispatch(ModalStore.close({ size: size }));
	}
};
