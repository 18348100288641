import React from 'react';

import { useFormContext } from 'react-hook-form';

import { formatISO } from 'date-fns';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import useConfig from '@asteria/utils-hooks/useConfig';

import Context from '../../../../../logic/context';
import getFormPaths, { getPath } from '../../../../../utils/getFormPaths';
import HistoryTooltip from '../../../../tooltip/history';
import { BASE_VALUE } from '../../../constants';
import { useStateHidden, useStateInvisible, useValue } from '../../hooks';
import { getPreviousValue } from '../../utils';

import View from './view';

import '../../styles.scss';

const Bar = React.memo((props) => {
	const { className, onAction, type, date, status, category, tag, render } =
		props;

	const { getValues, setValue, unregister } = useFormContext();

	const { history } = React.useContext(Context);

	const baseValue =
		useConfig(['forecaster.graph.base'], { deep: true }) ?? BASE_VALUE;

	const fmtDate = formatISO(date, { representation: 'date' });

	const name = getPath({
		type: type,
		date: fmtDate,
		status: status,
		category: category,
		tag: tag,
	});

	const value = useValue({ type, status, date, category, tag });

	const hidden = useStateHidden({ type, date, status, category, tag });
	const invisible = useStateInvisible({ type, date, status, category, tag });

	const TooltipProps = React.useMemo(
		() => ({
			tooltip: (
				<HistoryTooltip
					type={type}
					category={category}
					tag={tag}
					date={fmtDate}
				/>
			),
			placement: type === 'deposit' ? 'top' : 'bottom',
		}),
		[category, fmtDate, tag, type],
	);

	const onChange = React.useCallback(
		({ value, immediate }) => {
			const { type, date, status, category, tag } = getFormPaths(name);

			const path = getPath({
				type,
				date,
				status,
				category,
				tag,
				field: '$value',
			});

			if (immediate) {
				setValue(path, value);

				return;
			}

			unregister(path, {
				keepDefaultValue: false,
				keepDirty: false,
				keepDirtyValues: false,
				keepError: false,
				keepIsSubmitSuccessful: false,
				keepIsValid: false,
				keepTouched: false,
				keepValue: false,
			});

			const form = getValues();
			const prevValue = get(form, [name, 'value'].join('.'));

			if (!value && !prevValue) {
				const previous = getPreviousValue({
					type: type,
					category: category,
					tag: tag,
					date: date,
					history: history,
					getValues: getValues,
					field: 'value',
				});

				return onChange({ value: previous?.value ?? baseValue });
			}

			return onAction?.('value:update', {
				name: [name, 'value'].join('.'),
				value,
				prev: prevValue,
			});
		},
		[baseValue, getValues, history, name, onAction, setValue, unregister],
	);

	return (
		<View
			className={className}
			type={type}
			date={date}
			status={status}
			category={category}
			tag={tag}
			tooltip={TooltipProps}
			value={value}
			onChange={onChange}
			hidden={hidden}
			invisible={invisible}
			render={render}
		/>
	);
});

Bar.displayName = 'BarProvider';
Bar.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,

	type: PropTypes.string,
	date: PropTypes.string,
	status: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	render: PropTypes.func,
};

export default Bar;
