import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../../../PropTypes';
import Button from '../../../button';
import { useLoading } from '../../hooks';

import '../styles.scss';

/** @type { React.FC<{ onCollapseClick: () => void, isCollapsed: boolean, loading: boolean }> } */
const CollapseButton = React.memo(function CollapseButton({
	onCollapseClick,
	isCollapsed,
	loading: $loading,
	...rest
}) {
	const loading = useLoading($loading);

	return (
		<Button
			variant="collapse"
			onClick={onCollapseClick}
			icon={isCollapsed ? 'chevron-down' : 'chevron-up'}
			{...rest}
			className={cn(
				'asteria-component__wrapper-header-action',
				'asteria--variant-collapse',
				{ 'asteria--state-loading': loading },
				rest?.className,
			)}
		/>
	);
});

CollapseButton.propTypes = {
	onCollapseClick: PropTypes.func,
	isCollapsed: PropTypes.bool,
	loading: PropTypes.bool,
	size: SizeProp(),
};

export default CollapseButton;
