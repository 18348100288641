import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { ListItem } from '@asteria/component-list';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const TransactionDetailsRow = (props) => {
	const { label, children, slug } = props;

	return (
		<ListItem
			className={cn('asteria-component__transactions__details__row', {
				[`asteria-component__transactions__details__row--slug-${slug}`]:
					slug,
			})}
		>
			<Text size="xs">{label}</Text>
			{typeof children === 'object' ? (
				children
			) : (
				<Text size="xs">{children}</Text>
			)}
		</ListItem>
	);
};

TransactionDetailsRow.displayName = 'TransactionDetailsRow';

TransactionDetailsRow.propTypes = {
	label: PropTypes.string,
	slug: PropTypes.string,
	children: PropTypes.node,
};

export default TransactionDetailsRow;
