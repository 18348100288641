import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Accordion, { AccordionItem } from '@asteria/component-core/accordion';
import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useUnreadMessage } from '../hooks';

import './index.scss';

const DEFAULT_FAQ_QUESTIONS = 3;

const FAQContent = React.memo((props) => {
	const { onSubmit, onAction } = props;

	const faqContactConfig = useConfig('pages.support.contact', { deep: true });

	const unreadMessages = useUnreadMessage({
		onSubmit: onSubmit,
	});

	const contenterData = React.useMemo(
		() => ({ unread: unreadMessages }),
		[unreadMessages],
	);

	const actions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<Contenter
			content={faqContactConfig}
			actions={actions}
			data={contenterData}
		/>
	);
});

FAQContent.propTypes = {
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

FAQContent.displayName = 'FAQContent';

const FAQ = (props) => {
	const { setStep, onSubmit, onClose, onAction } = props;
	const [showAll, setShowAll] = useState(false);
	const faqConfig = useConfig('app.faq');

	const items = useMemo(() => {
		if (showAll) {
			return faqConfig?.items || [];
		}

		return faqConfig?.items?.slice(0, DEFAULT_FAQ_QUESTIONS) || [];
	}, [faqConfig, showAll]);

	const shouldShowMore = useMemo(() => {
		const length = faqConfig?.items?.length;

		return !showAll && length && length - DEFAULT_FAQ_QUESTIONS > 0;
	}, [faqConfig, showAll]);

	return (
		<Wrapper className="asteria-component__support">
			<Header onClose={onClose} scroll>
				{TranslationService.get('faq.title')}
			</Header>
			<Content scroll>
				<FormWrapper scroll>
					<Content>
						<FeatureFlag feature="support-contact">
							<FAQContent
								onSubmit={onSubmit}
								onAction={onAction}
							/>
						</FeatureFlag>
						<FeatureFlag feature="support-faq">
							<div className="asteria-component__faq__wrapper">
								<Title size="sm">
									{TranslationService.get(
										'faq.content.title',
									)}
								</Title>
								<Accordion>
									{items?.map((item, index) => (
										<AccordionItem
											analyticsKey={`faq.accordion.item.${index}.click`}
											key={index}
											label={TranslationService.get(
												item.label,
												item.label,
											)}
										>
											<Text>
												{TranslationService.get(
													item.description,
													item.description,
												)}
											</Text>
										</AccordionItem>
									))}
								</Accordion>
								{shouldShowMore ? (
									<Button
										className="asteria-component__load-more"
										variant="link"
										label={TranslationService.get(
											'faq.content.button.show.more',
										)}
										onClick={() => setShowAll(true)}
										analyticsKey="faq.content.button.show.more"
									/>
								) : null}
							</div>
						</FeatureFlag>
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.get('action.close')}
						onClick={onClose}
						analyticsKey="faq.content.button.close"
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="secondary"
						label={TranslationService.get(
							'faq.content.button.report',
						)}
						onClick={() => setStep('form')}
						analyticsKey="faq.content.button.report"
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

FAQ.propTypes = {
	setStep: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

const SupportForm = (props) => {
	const { setStep, onClose, onBack, setResponse, onSubmit } = props;
	const [sending, setSending] = useState();

	const handleSubmit = useCallback(
		async (form) => {
			if (onSubmit) {
				setSending(true);

				// eslint-disable-next-line no-unused-vars
				const { agree, ...submitForm } = form;

				try {
					const data = await onSubmit('support', submitForm);

					setResponse({ data: data });
				} catch (error) {
					setResponse({ error: error });
				}

				setSending(false);
				setStep('sent');
			}
		},
		[setResponse, onSubmit, setStep],
	);

	return (
		<Form onSubmit={handleSubmit}>
			<Wrapper className="asteria-component__support">
				<Header onClose={onClose} onBack={onBack}>
					{TranslationService.get('support.form.title')}
				</Header>
				<Content scroll>
					<FormWrapper>
						<Content>
							<Input
								type="text"
								placeholder={TranslationService.get(
									'support.form.placeholder.subject',
								)}
								name="subject"
								required
								formNamePrefix="support"
							/>
							<Input
								type="email"
								placeholder={TranslationService.get(
									'support.form.placeholder.email',
								)}
								name="email"
								required
								formNamePrefix="support"
							/>
							<Input
								type="textarea"
								placeholder={TranslationService.get(
									'support.form.placeholder.description',
								)}
								name="message"
								required
								formNamePrefix="support"
							/>
							<Checkbox
								name="agree"
								label={TranslationService.get(
									'support.form.label.agree',
								)}
								iconPosition="first"
								required
								formNamePrefix="support"
							/>
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get('action.back')}
							onClick={onBack}
							analyticsKey="faq.content.button.back"
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							type="submit"
							variant="primary"
							label={TranslationService.get('action.submit')}
							disabled={sending}
							loading={sending}
							analyticsKey="faq.content.button.submit"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
};

SupportForm.propTypes = {
	setStep: PropTypes.func,
	onClose: PropTypes.func,
	onBack: PropTypes.func,
	setResponse: PropTypes.func,
	onSubmit: PropTypes.func,
};

const Sent = (props) => {
	const { setStep, onClose, response } = props;

	return (
		<Wrapper>
			<Header onClose={onClose} onBack={() => setStep('faq')}>
				{TranslationService.get('support.success.title')}
			</Header>
			<Content>
				<FormWrapper>
					<Content>
						<div className="asteria-component__support__box">
							{response?.data ? (
								<Text>
									{TranslationService.get(
										'support.success.content',
										'',
										{ ticketNumber: response?.data },
									)}
								</Text>
							) : null}

							{response?.error ? (
								<Text>
									{TranslationService.get(
										'support.error.content',
										'',
										response?.error,
									)}
								</Text>
							) : null}
						</div>
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						variant="tertiary"
						label={TranslationService.get('action.close')}
						onClick={onClose}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

Sent.propTypes = {
	setStep: PropTypes.func,
	onClose: PropTypes.func,
	response: PropTypes.object,
};

const Guide = (props) => {
	const { onClose, onSubmit, onAction } = props;

	const [step, setStep] = useState('faq');
	const [response, setResponse] = useState(null);

	const handleClose = useCallback(() => {
		onClose?.();
		setStep('faq');
	}, [onClose]);

	const handleBack = useCallback(() => {
		setStep('faq');
	}, []);

	if (step === 'form') {
		return (
			<SupportForm
				setStep={setStep}
				onClose={handleClose}
				onBack={handleBack}
				onSubmit={onSubmit}
				setResponse={setResponse}
			/>
		);
	}

	if (step === 'sent') {
		return (
			<Sent setStep={setStep} onClose={handleClose} response={response} />
		);
	}

	return (
		<FAQ
			setStep={setStep}
			onClose={handleClose}
			onSubmit={onSubmit}
			onAction={onAction}
		/>
	);
};

Guide.propTypes = {
	className: PropTypes.string,
	onEnd: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

const GuideModal = (props) => {
	const { open, onClose, onSubmit, onAction } = props;

	return (
		<Modal open={open} onClose={onClose} size="sm">
			<Guide onClose={onClose} onSubmit={onSubmit} onAction={onAction} />
		</Modal>
	);
};

GuideModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,
};

export { FAQContent, GuideModal };

export default Guide;
