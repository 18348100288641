import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper from '@asteria/component-core/wrapper';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const MessageBox = (props) => {
	const { children, className, variant, scrollIntoView } = props;

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		if (scrollIntoView) {
			if (ref.current) {
				ref.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [scrollIntoView]);

	return (
		<Wrapper
			className={cn(
				'asteria-component__message-box',
				{
					[`asteria-component__message-box--variant-${variant}`]:
						variant,
				},
				className,
			)}
			ref={ref}
		>
			{children}
		</Wrapper>
	);
};

MessageBox.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	variant: PropTypes.string,

	scrollIntoView: PropTypes.bool,
};

MessageBox.defaultProps = {};

const MessageBoxToggle = (props) => {
	const { className, toggle, children, analyticsKey, ...rest } = props;

	const [open, setOpen] = React.useState(false);

	const handleOpen = React.useCallback(() => {
		Analytics.event('support.message-box.close', {
			analyticsKey: analyticsKey,
		});

		setOpen(true);
	}, [analyticsKey]);

	const handleClose = React.useCallback(() => {
		Analytics.event('support.message-box.open', {
			analyticsKey: analyticsKey,
		});

		setOpen(false);
	}, [analyticsKey]);

	const handleToggle = React.useCallback(() => {
		setOpen((value) => {
			if (value) {
				Analytics.event('support.message-box.close', {
					analyticsKey: analyticsKey,
				});
			} else {
				Analytics.event('support.message-box.open', {
					analyticsKey: analyticsKey,
				});
			}

			return !value;
		});
	}, [analyticsKey]);

	return (
		<div
			className={cn('asteria-component__message-box__wrapper', className)}
		>
			<Button {...toggle} onClick={handleToggle} active={open} />
			{open ? (
				<MessageBox className={className} {...rest}>
					{typeof children === 'function'
						? children({
								onOpen: handleOpen,
								onClose: handleClose,
								onToggle: handleToggle,
								open: open,
						  })
						: children}
				</MessageBox>
			) : null}
		</div>
	);
};

MessageBoxToggle.displayName = 'MessageBoxToggle';

MessageBoxToggle.propTypes = {
	className: PropTypes.string,
	toggle: PropTypes.object,
	children: PropTypes.node,
	analyticsKey: PropTypes.string,

	...MessageBox.propTypes,
};

export default MessageBox;
export { MessageBoxToggle };
