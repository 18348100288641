import React from 'react';

import posed from 'react-pose';

import { cn } from '@asteria/utils-funcs/classes';

import './area.scss';

const AnimatedGraph = posed.div({
	open: {
		opacity: 1,
		delayChildren: 2000,
		staggerChildren: 500,
		transition: { duration: 2000 },
	},
	closed: { opacity: 0, transition: { duration: 2000 } },
});
AnimatedGraph.displayName = 'AnimatedGraph';

const GraphArea = React.memo(({ className, ...props }) => {
	return (
		<AnimatedGraph
			className={cn(className, 'asteria-graph-area')}
			{...props}
		/>
	);
});

GraphArea.displayName = 'GraphArea';

export default GraphArea;
