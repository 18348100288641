import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

/**
 * @typedef { import('../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'>> } */
const Total = React.memo(function Total({ translationOptions }) {
	const source = translationOptions?.data?.source?.value;
	const target = translationOptions?.data?.target?.value;

	return (
		<TooltipWrapper
			tooltip={
				<Translation
					translationKey="card.content.extra.tooltip"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'value-type': 'total',
						},
					}}
					Component={Text}
				/>
			}
		>
			<TextGroup>
				<Translation
					translationKey="card.content.extra.value"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'value-type': 'total',
							value: 'current',
						},
					}}
					Component={Text}
					align="right"
				/>
				{!!source?.total && source?.total !== target?.total ? (
					<Translation
						translationKey="card.content.extra.value"
						translationOptions={{
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								'value-type': 'total',
								value: 'default',
							},
						}}
						Component={Text}
						align="right"
						size="xs"
					/>
				) : null}
			</TextGroup>
		</TooltipWrapper>
	);
});

Total.propTypes = {
	translationOptions: PropTypes.object,
};

export default Total;
