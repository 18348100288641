import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import { ScenarioSection } from '@asteria/component-settings';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import ResetSection from './reset';

import './styles.scss';

const ForecasterSettingsContent = React.memo((props) => {
	const { onClose, onAction, loading, form } = props;

	return (
		<Wrapper>
			<Header onClose={onClose} verticalAlign="center">
				{TranslationService.get('forecaster.modal.settings.title')}
			</Header>
			<Content>
				<FormWrapper>
					<Content>
						<FeatureFlag feature="forecaster-scenarios">
							<ScenarioSection onAction={onAction} prefix="" />
						</FeatureFlag>

						<FeatureFlag feature="forecaster-settings-reset">
							<ResetSection onAction={onAction} form={form} />
						</FeatureFlag>

						<FeatureFlag feature="forecaster-show-history-backdrop">
							<Checkbox
								name="flags.forecasterShowHistory"
								label={TranslationService.get(
									'forecaster.graph.option.show.history',
								)}
							/>
						</FeatureFlag>
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						label={TranslationService.get([
							'button.abort',
							'forecaster.modal.settings.button.abort',
						])}
						onClick={onClose}
						analyticsKey="forecaster.settings.cancel"
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.save',
							'forecaster.modal.settings.button.save',
						])}
						type="submit"
						analyticsKey="forecaster.settings.save"
						disabled={loading}
						loading={loading}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

ForecasterSettingsContent.displayName = 'ForecasterSettingsContent';

ForecasterSettingsContent.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	loading: PropTypes.bool,

	form: PropTypes.object,
};

export default ForecasterSettingsContent;
