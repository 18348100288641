import {
	isAfter,
	isBefore,
	isFuture,
	isPast,
	isSameMonth,
	isSameYear,
	parseISO,
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';
import sv from 'date-fns/locale/sv';

const LOCALES = {
	sv: sv,
	en: enUS,
	pt: pt,
};

export const validateSelectedDate = (options) => {
	const { value, date, validate } = options;

	if (!value) {
		return false;
	}

	if (Array.isArray(value)) {
		return value.some((e) => validate(parseISO(e), date));
	}

	if (typeof value === 'string') {
		return validate(parseISO(value), date);
	}

	const { startDate, endDate } = value;

	if (startDate && endDate) {
		return (
			validate(parseISO(startDate), date) ||
			validate(parseISO(endDate), date)
		);
	} else if (startDate) {
		return validate(parseISO(startDate), date);
	}

	return false;
};

export const getSelectedCSSClasses = (options) => {
	const { value, date, validate } = options;

	return {
		'asteria--state-focus': validateSelectedDate({
			value: value,
			date: date,
			validate: validate,
		}),
		'asteria--state-highlight':
			value &&
			value.startDate &&
			value.endDate &&
			isAfter(date, parseISO(value.startDate)) &&
			isBefore(date, parseISO(value.endDate)),
		'asteria-component__datepicker-state--range-start':
			value &&
			value.startDate &&
			value.endDate &&
			validate(date, parseISO(value.startDate)),
		'asteria-component__datepicker-state--range-end':
			value &&
			value.startDate &&
			value.endDate &&
			validate(date, parseISO(value.endDate)),
	};
};

export const getCalendarSize = (layout) => {
	switch (layout) {
		case 'double':
			return 2;

		case 'triple':
			return 3;

		default:
			return 1;
	}
};

/**
 * @param {{ isPastDisabled: boolean, previousYear: Date, previousDate?: Date, startDate?: string }} options
 */
export const getPreviousYearDisabled = (options) => () => {
	const { previousYear, previousDate, isPastDisabled, startDate } = options;

	let isDisabled = false;

	if (isPastDisabled) {
		const isSameDate = isSameYear(previousYear, new Date());
		const isPastDate = isPast(previousYear);

		if (isPastDate && !isSameDate) {
			isDisabled = true;
		}
	}

	if (previousDate !== null) {
		const isSameDate = isSameYear(previousYear, previousDate);
		const isBeforeDate = isBefore(previousYear, previousDate);

		if (isBeforeDate || isSameDate) {
			isDisabled = true;
		}
	}

	if (startDate) {
		const isSameDate = isSameYear(previousYear, parseISO(startDate));
		const isBeforeDate = isBefore(previousYear, parseISO(startDate));

		if (isBeforeDate && !isSameDate) {
			isDisabled = true;
		}
	}

	return isDisabled;
};

/**
 * @param {{ isPastDisabled: boolean, previousMonth: Date, previousDate?: Date, startDate?: string }} options
 */
export const getPreviousMonthDisabled = (options) => () => {
	const { previousMonth, previousDate, isPastDisabled, startDate } = options;

	let isDisabled = false;

	if (isPastDisabled) {
		const isSameDate = isSameMonth(previousMonth, new Date());
		const isPastDate = isPast(previousMonth);

		if (isPastDate && !isSameDate) {
			isDisabled = true;
		}
	}

	if (previousDate !== null) {
		const isSameDate = isSameMonth(previousMonth, previousDate);
		const isBeforeDate = isBefore(previousMonth, previousDate);

		if (isBeforeDate || isSameDate) {
			isDisabled = true;
		}
	}

	if (startDate) {
		const isSameDate = isSameMonth(previousMonth, parseISO(startDate));
		const isBeforeDate = isBefore(previousMonth, parseISO(startDate));

		if (isBeforeDate && !isSameDate) {
			isDisabled = true;
		}
	}

	return isDisabled;
};

/**
 * @param {{ isFutureDisabled: boolean, nextYear: Date, endDate?: string }} options
 */
export const getNextYearDisabled = (options) => () => {
	const { isFutureDisabled, nextYear, endDate } = options;

	let isDisabled = false;

	if (isFutureDisabled) {
		const isSameDate = isSameYear(nextYear, new Date());
		const isFutureDate = isFuture(nextYear);

		if (isFutureDate && !isSameDate) {
			isDisabled = true;
		}
	}

	if (endDate) {
		const isSameDate = isSameYear(nextYear, parseISO(endDate));
		const isAfterDate = isAfter(nextYear, parseISO(endDate));

		if (isAfterDate && !isSameDate) {
			isDisabled = true;
		}
	}

	return isDisabled;
};

/**
 * @param {{ isFutureDisabled: boolean, nextMonth: Date, endDate?: string }} options
 */
export const getNextMonthDisabled = (options) => () => {
	const { isFutureDisabled, nextMonth, endDate } = options;

	let isDisabled = false;

	if (isFutureDisabled) {
		const isSameDate = isSameMonth(nextMonth, new Date());
		const isFutureDate = isFuture(nextMonth);

		if (isFutureDate && !isSameDate) {
			isDisabled = true;
		}
	}

	if (endDate) {
		const isSameDate = isSameMonth(nextMonth, parseISO(endDate));
		const isAfterDate = isAfter(nextMonth, parseISO(endDate));

		if (isAfterDate && !isSameDate) {
			isDisabled = true;
		}
	}

	return isDisabled;
};

/**
 * @param { string } str
 * @returns { string }
 */
export const cleanValue = (str) => {
	const value = str.replace('.', '');

	const parts = value.split(' ');

	if (parts.length > 1) {
		return parts.map((value) => cleanValue(value)).join(' ');
	}

	return value[0].toUpperCase() + value.slice(1);
};

/**
 * @param { 'sv' | 'en' | 'enGB' | 'enUS' | 'fr' | 'uk' | 'ru' | 'es' } locale
 */
export const getLocale = (locale) => {
	return LOCALES[locale];
};
