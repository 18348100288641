import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const ClientDetailsStatisticsItem = (props) => {
	const { className, label, amount } = props;

	return (
		<div
			className={cn(
				'asteria-component__client-overview__details__statistics__item',
				className,
			)}
		>
			<Text size="sm">{label}</Text>
			<Text size="sm">{amount}</Text>
		</div>
	);
};

ClientDetailsStatisticsItem.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	amount: PropTypes.number,
};

const ClientDetailsStatistics = (props) => {
	const { className, client } = props;

	const { total, unpaid, overdue, forecast } = useMemo(
		() => ({
			total: client?.statistics?.invoices?.count ?? 0,
			unpaid: client?.statistics?.unpaid?.count ?? 0,
			paid: client?.statistics?.paid?.count ?? 0,
			overdue: client?.statistics?.overdue?.count ?? 0,
			forecast: client?.statistics?.forecast?.count ?? 0,
		}),
		[client?.statistics],
	);

	return (
		<div
			className={cn(
				'asteria-component__client-overview__details__statistics',
				className,
			)}
		>
			<ClientDetailsStatisticsItem
				label={TranslationService.get(
					[
						'client.overview.details.statistics.unpaid',
						`client.overview.details.${client?.type}.statistics.unpaid`,
					],
					undefined,
					{ client: client },
				)}
				amount={unpaid}
			/>
			<ClientDetailsStatisticsItem
				label={TranslationService.get(
					[
						'client.overview.details.statistics.overdue',
						`client.overview.details.${client?.type}.statistics.overdue`,
					],
					undefined,
					{ client: client },
				)}
				amount={overdue}
			/>
			<ClientDetailsStatisticsItem
				label={TranslationService.get(
					[
						'client.overview.details.statistics.total',
						`client.overview.details.${client?.type}.statistics.total`,
					],
					undefined,
					{ client: client },
				)}
				amount={total}
			/>
			<ClientDetailsStatisticsItem
				label={TranslationService.get(
					[
						'client.overview.details.statistics.forecast',
						`client.overview.details.${client?.type}.statistics.forecast`,
					],
					undefined,
					{ client: client },
				)}
				amount={forecast}
			/>
		</div>
	);
};

ClientDetailsStatistics.propTypes = {
	className: PropTypes.string,
	client: PropTypes.object,
};

export default ClientDetailsStatistics;
