import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

const initialState = {
	route: '/',
	sideDrawerOpen: false,
	invoicedIds: [],
};

export const navigateSlice = createSlice({
	name: 'navigate',
	initialState: initialState,
	reducers: {
		setNavigationRoute: (state, action) => {
			state.route = action.payload;
		},
		setSideDrawerOpen: (state, action) => {
			state.sideDrawerOpen = action.payload;
		},
		setInvociedIds: (state, action) => {
			state.invoicedIds = []
				.concat(state.invoicedIds)
				.concat(action.payload);
		},
		removeInvociedIds: (state, action) => {
			const ids = [].concat(action.payload);

			state.invoicedIds = state?.invoicedIds?.filter(
				(item) => !ids.includes(item),
			);
		},
		clearInvoices: (state) => {
			state.invoicedIds = [];
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const {
	setNavigationRoute,
	setSideDrawerOpen,
	setInvociedIds,
	removeInvociedIds,
	clearInvoices,
} = navigateSlice.actions;

export default navigateSlice.reducer;
