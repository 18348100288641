function set(state, { payload }) {
	state.adminUsers.data = payload;
}

function update(state, { payload }) {
	const input = [].concat(payload);

	const objects = state.adminUsers.data.reduce(
		(acc, object) => ({ ...acc, [object?._id ?? object.id]: object }),
		{},
	);

	for (const object of input) {
		const ID = object?._id ?? object.id;

		objects[ID] = object;
	}

	state.adminUsers.data = Object.values(objects);
}

function reset(state) {
	state.adminUsers.data = [];
}

function start(state) {
	state.adminUsers.loading = true;
}

function stop(state) {
	state.adminUsers.loading = false;
}

export {
	set as setAdminUsers,
	update as updateAdminUsers,
	reset as resetAdminUsers,
	start as startAdminUsersLoading,
	stop as stopAdminUsersLoading,
};
