import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CSSTransition } from 'react-transition-group';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';
import ComponentService from '@asteria/component-tools/contenter/service';
import MediaQuery from '@asteria/component-tools/mediaquery';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { useAnalyticsData } from '@asteria/utils-funcs/analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useClickOutside from '@asteria/utils-hooks/useClickOutside';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { SizeProp } from '../PropTypes';
import Button from '../button';
import { Text, Title } from '../typography';
import {
	animationListener,
	isPossibleToClick,
	sizeClasses,
	stateClasses,
} from '../utils';
import ElementAnchor from '../utils/elementAnchor';
import Wrapper, { Content, Header } from '../wrapper';

import './index.scss';

const DropdownContext = React.createContext({ close: () => null });

const DropdownItem = React.memo((props) => {
	const {
		children,
		className,
		prefix,
		postfix,
		analyticsKey,
		analytics: $analytics,
		onClick,
		noColors,
		size,
		level = 0,
	} = props;

	const analytics = useDeepMemo(() => $analytics, [$analytics]);

	const analyticsDataRef = useAnalyticsData({
		analyticsKey: analyticsKey
			? analyticsKey
			: typeof children === 'string'
			? children
			: null,
		children: typeof children === 'string' ? children : undefined,
		...analytics,
	});

	const ref = useRef(null);

	const handleClick = useCallback(
		(event) => {
			if (!isPossibleToClick(event, ref.current)) {
				return;
			}

			Analytics.event('dropdown.select', analyticsDataRef.current);

			return onClick?.(event);
		},
		[analyticsDataRef, onClick],
	);

	const style = React.useMemo(() => ({ '--level': level }), [level]);

	return (
		<li
			className={cn(
				'asteria-component__dropdown__item',
				className,
				{
					'asteria-component__dropdown__item--no-colors': noColors,
					'asteria-component__dropdown__item--has-prefix': prefix,
					'asteria-component__dropdown__item--has-postfix': postfix,
				},
				`asteria--level-${level}`,
				stateClasses(props),
				sizeClasses(props),
			)}
			ref={ref}
			onClick={handleClick}
			style={style}
		>
			{prefix ? (
				<div className="asteria-component__dropdown__item__prefix">
					{prefix}
				</div>
			) : null}

			<div className="asteria-component__dropdown__item__label">
				{typeof children == 'string' ? (
					<Text size={size}>{children}</Text>
				) : (
					children
				)}
			</div>

			{postfix ? (
				<div className="asteria-component__dropdown__item__postfix">
					{postfix}
				</div>
			) : null}
		</li>
	);
});

DropdownItem.displayName = 'DropdownItem';

DropdownItem.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	size: SizeProp(),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	prefix: PropTypes.node,
	postfix: PropTypes.node,
	onClick: PropTypes.func,
	noColors: PropTypes.bool,
	analyticsKey: PropTypes.string,
	analytics: PropTypes.object,
	level: PropTypes.number,
};

const DropdownList = React.memo(
	React.forwardRef((props, ref) => {
		const { direction, children, onClick, scroll, size } = props;

		const functions = []
			.concat(children)
			.filter((object) => typeof object === 'function');

		return (
			<ul
				className={cn(
					'asteria-component__dropdown__list',
					{ 'asteria-component__wrapper--scroll': scroll },
					{
						'asteria-component__wrapper--row': direction === 'row',
					},
					sizeClasses(props),
				)}
				onClick={onClick}
				ref={ref}
			>
				{React.Children.toArray(children).map((child) =>
					React.cloneElement(child, {
						size: child?.props?.size ?? size,
					}),
				)}
				{functions.map((child) => child({ size, ref }))}
			</ul>
		);
	}),
);

DropdownList.displayName = 'DropdownList';

DropdownList.propTypes = {
	direction: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
	scroll: PropTypes.bool,
	size: SizeProp(),
};

const Dropdown = React.memo((props) => {
	const {
		className,
		toggle,
		single,
		search,
		variant,
		placement,
		offset = 4,
		size,
		open: propsOpen,
		onOpen,
		stopPropagation,
		scroll,

		title,

		direction,
		analyticsKey,
		analytics: $analytics,
		children,
		multi,
		strict,

		searchOutside,
		onSearch,
	} = props;

	const analytics = useDeepMemo(() => $analytics, [$analytics]);
	const analyticsDataRef = useAnalyticsData({
		analyticsKey: analyticsKey,
		...analytics,
	});

	const ref = useRef();
	const nestedRef = useRef();
	const toggleRef = useRef();
	const [isOpen, setOpen] = useState(false);
	const [searchValue, setSearchValue] = React.useState('');

	const onSearchChange = React.useCallback(
		($value) => {
			let value = $value;

			onSearch?.(value);
			setSearchValue(value);
		},
		[onSearch],
	);

	const handleChange = React.useCallback(
		({ value }) => onSearchChange(value),
		[onSearchChange],
	);

	const handleClear = React.useCallback(() => {
		onSearchChange('');
	}, [onSearchChange]);

	useEffect(() => {
		if (!propsOpen === false) {
			onSearchChange('');
		}

		setOpen(propsOpen);
	}, [propsOpen]);

	const foundElements = React.useMemo(() => {
		const items = React.Children.toArray(children).filter((child) => {
			const value = get(child?.props, search?.path ?? 'children');

			if (typeof value === 'string') {
				return value.toLowerCase().includes(searchValue.toLowerCase());
			}

			return true;
		});

		if (items?.length) {
			return items;
		}

		return (
			<div
				className={cn(
					'asteria-component__dropdown__item',
					'asteria-component__dropdown__item--empty',
				)}
			>
				<Text>
					{TranslationService.get(
						[
							'dropdown.search.empty',
							`dropdown.search.${search?.name}.empty`,
							search?.empty,
						],
						search?.empty,
					)}
				</Text>
			</div>
		);
	}, [children, search?.empty, search?.name, search?.path, searchValue]);

	const handleOpenState = useCallback(
		(event, value) => {
			if (!propsOpen) {
				setOpen((prev) => {
					let next = value;

					if (typeof value === 'function') {
						next = value(prev);
					}

					if (next) {
						Analytics.event(
							'dropdown.open',
							analyticsDataRef.current,
						);
					} else {
						onSearchChange('');

						Analytics.event(
							'dropdown.close',
							analyticsDataRef.current,
						);
					}

					onOpen?.(next, event);

					return next;
				});
			}

			if (stopPropagation) {
				if (event && event.nativeEvent) {
					event.nativeEvent.stopImmediatePropagation();
				}

				event.stopPropagation();
			}
		},
		[propsOpen, stopPropagation, onOpen, analyticsDataRef, onSearchChange],
	);

	const handleClickOutside = React.useCallback(() => {
		handleOpenState(null, false);
	}, [handleOpenState]);

	useClickOutside([ref, nestedRef], handleClickOutside);

	const handleClose = useCallback(
		(event) => handleOpenState(event, false),
		[handleOpenState],
	);

	const handleToggle = useCallback(
		(event) => handleOpenState(event, (value) => !value),
		[handleOpenState],
	);

	const isSingle = Boolean(React.Children.count(children) === 1 && single);

	const handleClick = useCallback(
		(e) => {
			if (e?.isPropagationStopped?.()) {
				return;
			}

			if (isSingle) {
				const onClick =
					React.Children.toArray(children)?.[0]?.props?.onClick;

				return onClick?.(e);
			}

			return handleToggle(e);
		},
		[isSingle, handleToggle, children],
	);

	const AsComponent = (isSingle ? single?.as : null) ?? toggle?.as ?? Button;

	const ctx = React.useMemo(() => ({ close: handleClose }), [handleClose]);

	return (
		<DropdownContext.Provider value={ctx}>
			<div
				className={cn(
					className,
					'asteria-component__dropdown',
					{ 'asteria--state-single': isSingle },
					stateClasses({ ...props, active: isOpen }),
					sizeClasses(props),
				)}
				ref={ref}
			>
				{search && search?.toggle && isOpen ? (
					<div
						className={cn(
							'asteria-component__dropdown__toggle--input',
						)}
						ref={toggleRef}
					>
						<Input
							{...search}
							uncontrolled
							autoFocus
							active
							value={searchValue}
							onChange={handleChange}
							placeholder={TranslationService.get(
								[
									'dropdown.search.input.placeholder',
									`dropdown.search.input.${search?.name}.placeholder`,
									search?.placeholder,
								],
								search?.placeholder,
							)}
							icon={search?.iconClose ?? undefined}
							iconPosition="last"
							onIconClick={handleOpenState}
						/>
					</div>
				) : (
					<AsComponent
						active={isOpen}
						size={size}
						icon={(isSingle ? single?.icon : null) ?? toggle?.icon}
						iconActive={
							(isSingle
								? single?.iconActive ?? single?.icon
								: null) ??
							toggle?.iconActive ??
							toggle?.icon
						}
						onClick={handleClick}
						stopPropagation={stopPropagation}
						tabIndex="0"
						variant={variant}
						analyticsKey={analyticsKey}
						analytics={analytics}
						{...toggle}
						{...(isSingle ? single : {})}
						className={cn(
							'asteria-component__dropdown__toggle',
							toggle?.classNames,
							toggle?.className,
							...(isSingle
								? [single?.classNames, single?.className]
								: []),
						)}
						ref={toggleRef}
					/>
				)}
				<MediaQuery query="(max-width: 1280px)" invert>
					<CSSTransition
						in={isOpen}
						appear
						unmountOnExit
						classNames="my-node"
						addEndListener={animationListener}
					>
						<ElementAnchor
							element={toggleRef}
							placement={placement}
							offset={offset}
							className={cn(
								'asteria-component__dropdown-anchor',
								className,
							)}
							minWidth
							strict={strict}
						>
							<DropdownList
								scroll={scroll}
								direction={direction}
								onClick={!multi ? handleClose : undefined}
								ref={nestedRef}
								size={size}
							>
								{search && !search?.toggle ? (
									<div
										className={cn(
											'asteria-component__dropdown__item',
											'asteria-component__dropdown__item--input',
										)}
										onClick={(e) => e.stopPropagation()}
									>
										<Input
											{...search}
											autoFocus
											active
											uncontrolled
											value={searchValue}
											onChange={handleChange}
											placeholder={TranslationService.get(
												[
													'dropdown.search.input.placeholder',
													`dropdown.search.input.${search?.name}.placeholder`,
													search?.placeholder,
												],
												search?.placeholder,
											)}
											icon={
												search?.iconClear ?? undefined
											}
											iconPosition="last"
											onIconClick={handleClear}
										/>
									</div>
								) : null}
								{searchValue && !searchOutside
									? foundElements
									: children}
							</DropdownList>
						</ElementAnchor>
					</CSSTransition>
				</MediaQuery>
				<MediaQuery query="(max-width: 1280px)">
					<Modal
						open={isOpen}
						className={cn(
							'asteria-component__dropdown-modal',
							`${className}-mobile`,
						)}
					>
						<Wrapper>
							<Header onClose={handleClose} onBack={handleClose}>
								<Title type="title" size="sm">
									{title}
								</Title>
							</Header>
							<Content scroll={props?.scroll}>
								{search ? (
									<div
										className={cn(
											'asteria-component__dropdown__item',
											'asteria-component__dropdown__item--input',
										)}
									>
										<Input
											{...search}
											autoFocus
											active
											uncontrolled
											onChange={handleChange}
											value={searchValue}
											placeholder={TranslationService.get(
												[
													'dropdown.search.input.placeholder',
													`dropdown.search.input.${search?.name}.placeholder`,
												],
											)}
											icon={
												search?.iconClear ?? undefined
											}
											iconPosition="last"
											onIconClick={handleClear}
										/>
									</div>
								) : null}
								<DropdownList
									direction={direction}
									onClick={!multi ? handleClose : undefined}
								>
									{searchValue && !searchOutside
										? foundElements
										: children}
								</DropdownList>
							</Content>
						</Wrapper>
					</Modal>
				</MediaQuery>
			</div>
		</DropdownContext.Provider>
	);
});

Dropdown.propTypes = {
	className: PropTypes.string,
	toggle: PropTypes.shape(Button.propTypes),
	single: PropTypes.shape(Button.propTypes),
	placement: PropTypes.oneOf([
		'top',
		'top-start',
		'top-end',
		'bottom',
		'bottom-start',
		'bottom-end',
		'right',
		'right-start',
		'right-end',
		'left',
		'left-start',
		'left-end',
		'auto',
		'auto-start',
		'auto-end',
	]),
	size: SizeProp(),
	open: PropTypes.bool,
	onOpen: PropTypes.func,
	scroll: PropTypes.bool,
	analyticsKey: PropTypes.string,
	direction: PropTypes.string,
	stopPropagation: PropTypes.bool,
	search: PropTypes.shape({
		iconClose: PropTypes.string,
		iconClear: PropTypes.string,
		toggle: PropTypes.bool,
		name: PropTypes.string,
		path: PropTypes.string,
		placeholder: PropTypes.string,
		empty: PropTypes.string,
	}),
	offset: PropTypes.number,
	children: PropTypes.node,
	multi: PropTypes.bool,
	title: PropTypes.string,
	analytics: PropTypes.object,
	strict: PropTypes.bool,
};

Dropdown.defaultProps = {
	toggle: {
		icon: 'menu',
	},
	size: 'md',
	open: false,
	scroll: false,
};

Dropdown.displayName = 'Dropdown';

ComponentService.register('Dropdown', Dropdown, {
	className: { type: 'string' },
	toggle: { type: 'object' },
	single: { type: 'object' },
	placement: {
		type: 'enum',
		options: [
			'top',
			'top-start',
			'top-end',
			'bottom',
			'bottom-start',
			'bottom-end',
			'right',
			'right-start',
			'right-end',
			'left',
			'left-start',
			'left-end',
			'auto',
			'auto-start',
			'auto-end',
		],
	},
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	open: { type: 'boolean' },
	onOpen: { type: 'function' },
	scroll: { type: 'boolean' },
	analyticsKey: { type: 'string' },
	direction: { type: 'string' },
	stopPropagation: { type: 'boolean' },
	search: {
		type: 'object',
		options: {
			iconClose: { type: 'string' },
			iconClear: { type: 'string' },
			toggle: { type: 'boolean' },
			name: { type: 'string' },
			path: { type: 'string' },
			placeholder: { type: 'string' },
			empty: { type: 'string' },
		},
	},
	offset: { type: 'number' },
	children: { type: 'node' },
	multi: { type: 'boolean' },
	title: { type: 'string' },
	analytics: { type: 'object' },
	strict: { type: 'boolean' },
});

ComponentService.register('DropdownItem', DropdownItem, {
	active: { type: 'boolean' },
	disabled: { type: 'boolean' },
	className: { type: 'string' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	children: { type: 'node' },
	prefix: { type: 'node' },
	postfix: { type: 'node' },
	onClick: { type: 'function' },
	noColors: { type: 'boolean' },
	analyticsKey: { type: 'string' },
	analytics: { type: 'object' },
	level: { type: 'number' },
});

export default Dropdown;
export { DropdownItem, DropdownContext };
