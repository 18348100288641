import React from 'react';

const Context = React.createContext({});

const LayoutContext = React.createContext({
	onAction: () => null,
	onSubmit: () => null,
});

export default Context;
export { LayoutContext };
