import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

const ClientUpdateModal = (props) => {
	const { open, onClose, onAction, data = null, className } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);

	const handleDelete = useCallback(() => {
		Analytics.event('client.edit.remove', data);

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ClientRemove,
				data: { data: data },
			}),
		);
	}, [data, dispatch]);

	const handleSubmit = useCallback(
		(form) => {
			if (form?._id) {
				Analytics.event('forecaster.client.update', { form: form });

				return onAction?.('client:update', {
					_id: form?._id,
					name: form?.name,
					type: data?.type,
				});
			}

			Analytics.event('forecaster.client.create', { form: form });

			return onAction?.('client:create', {
				name: form?.name,
				meta: data?.meta,
				type: data?.type,
			});
		},
		[onAction, data],
	);

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	const maxLength =
		useConfig(
			[
				'input.maxLength',
				'forms.input.maxLength',
				'clients.input.maxLength',
				'forecaster.clients.input.maxLength',
			],
			{ deep: true },
		) ?? 25;

	const minLength =
		useConfig(
			[
				'input.minLength',
				'forms.input.minLength',
				'clients.input.minLength',
				'forecaster.clients.input.minLength',
			],
			{ deep: true },
		) ?? 2;

	return (
		<Modal
			className={cn(
				'asteria--type-clients',
				{
					'asteria--action-create': !data?._id,
					'asteria--action-update': data?._id,
				},
				className,
			)}
			size="sm"
			open={open}
			onClose={handleClose}
		>
			<Form defaultValues={data} onSubmit={handleSubmit}>
				<Wrapper scroll>
					<Header onClose={handleClose}>
						{TranslationService.get(
							[
								'clients.add.title',
								data?._id ? 'clients.update.title' : null,
							].filter(Boolean),
						)}
					</Header>
					<Content>
						<FormWrapper>
							<Content>
								<Input
									type="text"
									name="name"
									label={
										data?._id
											? TranslationService.get(
													'forecaster.modal.clients.label.update',
											  )
											: TranslationService.get(
													'forecaster.modal.clients.label.create',
											  )
									}
									minLength={minLength}
									maxLength={maxLength}
									direction="vertical"
									icon={data?._id ? 'remove' : null}
									iconPosition="last"
									iconTooltip={TranslationService.get(
										'forecaster.modal.client.remove.tooltip',
									)}
									helpText={(value) =>
										TranslationService.get(
											'forecaster.modal.client.input.help',
											// eslint-disable-next-line spellcheck/spell-checker
											'{{ count }} / {{ max }} tillgängliga tecken',
											{
												count: value.length,
												max: maxLength,
												available: Math.max(
													maxLength - value.length,
													0,
												),
											},
										)
									}
									onIconClick={handleDelete}
									required
								/>
							</Content>
						</FormWrapper>
					</Content>
					<Footer>
						<FooterSection>
							{hasHistory ? (
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.back',
										'forecaster.modal.client.button.back',
									])}
									onClick={handleClose}
									analyticsKey="forecaster.modal.client.back"
								/>
							) : (
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.cancel',
										'forecaster.modal.client.button.cancel',
									])}
									onClick={handleClose}
									analyticsKey="forecaster.modal.client.cancel"
								/>
							)}
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.save',
									'forecaster.modal.client.button.save',
								])}
								type="submit"
								analyticsKey="forecaster.modal.client.save"
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>
		</Modal>
	);
};

ClientUpdateModal.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
	open: PropTypes.bool,
};

export default ClientUpdateModal;
