import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import AsteriaCore from '@asteria/core';

import * as AppStore from '@asteria/datalayer/stores/app';

import { useSubscription as useBaseSubscription } from '@asteria/utils-websocket/hooks';

export function useSubscription({ onAction, onSubmit }) {
	const queryClient = useQueryClient();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const { refetch } = useQuery({
		queryKey: ['widget', 'integrations'],
		queryFn: async () => onSubmit?.('integrations:list'),

		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		refetchInterval: 30_000,
	});

	const invalidate = useMemo(
		() =>
			AsteriaCore.utils.throttle(
				() =>
					queryClient.invalidateQueries({
						predicate: (query) =>
							['card', 'streamline'].includes(query.queryKey[0]),
					}),
				5_000,
			),
		[queryClient],
	);

	return useBaseSubscription({
		token: accessToken,
		query: `
			subscription IntegrationUpdated {
				integrationUpdated {
					_id
				}
			}
		`,
		onNext: useCallback(
			(response) => {
				refetch();
				invalidate(response);

				const id = response?.data?.integrationUpdated?._id;

				if (id) {
					onAction?.('askForFeedback', {
						feedbackKey: `integration-updated-${id}`,
					});
				}
			},
			[invalidate, onAction, refetch],
		),
	});
}
