import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { cn } from '@asteria/utils-funcs/classes';

import WelcomeContext from '../../context';

import Question from './Question';

import './styles.scss';

const Questions = (props) => {
	const { className, step, name, onAction } = props;

	const [extension, setExtension] = React.useState(null);

	const { wizard: ctxWizard } = React.useContext(WelcomeContext);
	const config = ctxWizard?.[name]?.steps?.[step] ?? null;

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'welcome:extension:open') {
				if (data?.type === 'full') {
					setExtension(data?.link);
				}
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const handleClose = React.useCallback(() => {
		setExtension(null);
	}, []);

	if (!config) {
		return null;
	}

	const questions = [].concat(config);

	return (
		<div
			className={cn(
				'asteria-view__welcome__questions',
				{
					'asteria-view__welcome__questions--variant-complex':
						!extension && questions.length > 1,
					'asteria-view__welcome__questions--variant-extension':
						extension,
				},
				className,
			)}
		>
			{extension ? (
				<div className="asteria-view__welcome__questions__extension-wrapper">
					<Question type={extension} onAction={handleAction} />
					<Button
						icon="close"
						onClick={handleClose}
						analyticsKey={`welcome.questions.extension.close`}
					/>
				</div>
			) : (
				questions.map((type) => (
					<Question key={type} type={type} onAction={handleAction} />
				))
			)}
		</div>
	);
};

Questions.displayName = 'Questions';

Questions.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	step: PropTypes.number,
	onAction: PropTypes.func,
};

export default Questions;
