import React from 'react';

import PropTypes from 'prop-types';

import Wrapper from '@asteria/component-core/wrapper';

import { useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

import TransactionsContent from './content';
import TransactionsHeader from './header';

import './styles.scss';

const Transactions = React.memo((props) => {
	const {
		className,

		onAction,

		types,
		category,
		tag,

		onToggleDirection,
		direction,
		hideHeader,
	} = props;

	const hasViewSwitchFeature = useFeature('forecaster-view-switch');

	return (
		<Wrapper
			className={cn(
				'asteria-component__forecaster-transactions',
				{
					'asteria-component__forecaster-transactions-full':
						hasViewSwitchFeature,
				},
				className,
			)}
		>
			{!hasViewSwitchFeature && !hideHeader ? (
				<TransactionsHeader
					onAction={onAction}
					direction={direction}
					onToggleDirection={onToggleDirection}
				/>
			) : null}
			<TransactionsContent
				onAction={onAction}
				category={category}
				tag={tag}
				direction={direction}
				types={types}
			/>
		</Wrapper>
	);
});

Transactions.displayName = 'Transactions';

Transactions.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,

	category: PropTypes.string,
	tag: PropTypes.string,

	onToggleDirection: PropTypes.func,
	direction: PropTypes.string,

	types: PropTypes.arrayOf(PropTypes.string),
	hideHeader: PropTypes.bool,
};

export default Transactions;
