import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';
import { isPossibleToClick, stateClasses } from '@asteria/component-core/utils';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import NavigationContext from '../context';
import { formatLabel, isActive, isSubActive } from '../utils';

import NavigationBadgeWrapper from './BadgeWrapper';
import FeatureWrapper from './FeatureWrapper';

import './styles.scss';

const NavigationButtonIcon = (props) => {
	const { showLabel, route, direction, onNavigate, onAction } = props;

	const Tooltip = React.useMemo(
		() => ({
			tooltip: !showLabel ? formatLabel(route?.label) : null,
			variant: 'alt',
			placement: direction === 'horizontal' ? 'top' : 'right',
			size: 'sm',
		}),
		[direction, route?.label, showLabel],
	);

	if (!route?.icon) {
		return <span className="asteria-component__navigation__item__icon" />;
	}

	return (
		<NavigationBadgeWrapper
			onNavigate={onNavigate}
			onAction={onAction}
			route={route}
		>
			<TooltipWrapper {...Tooltip}>
				<Button
					icon={[].concat(
						route?.icon,
						['navigation', route?.icon].join('-'),
					)}
					className="asteria-component__navigation__item__icon"
					variant="navigation-icon"
				/>
			</TooltipWrapper>
		</NavigationBadgeWrapper>
	);
};

NavigationButtonIcon.displayName = 'NavigationButtonIcon';
NavigationButtonIcon.propTypes = {
	showLabel: PropTypes.bool,
	route: PropTypes.object,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
};

const NavigationButtonContent = (props) => {
	const {
		onNavigate,
		onAction,
		active,
		route,
		label,
		showLabel,
		direction,
		onCollapseClick,
	} = props;

	return [
		<NavigationButtonIcon
			showLabel={showLabel}
			route={route}
			direction={direction}
			onNavigate={onNavigate}
			onAction={onAction}
			key="navigation-icon"
		/>,
		label ? (
			<div
				className="asteria-component__navigation__item__label"
				key="navigation-label"
			>
				<Text size="sm">{label}</Text>
			</div>
		) : null,
		route?.sub?.length ? (
			<TooltipWrapper
				tooltip={TranslationService.get([
					`navigation.${route?.slug}.collapse.tooltip`,
				])}
				key="navigation-collapse"
			>
				<Button
					icon={active ? 'chevron-up' : 'chevron-down'}
					className="asteria-component__navigation__item__collapse"
					size="sm"
					onClick={onCollapseClick}
					variant="navigation-collapse"
				/>
			</TooltipWrapper>
		) : null,
	];
};

NavigationButtonContent.displayName = 'NavigationButtonContent';

NavigationButtonContent.propTypes = {
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	route: PropTypes.object,
	active: PropTypes.bool,
	label: PropTypes.string,
	showLabel: PropTypes.bool,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	onCollapseClick: PropTypes.func,
};

const NavigationButtonSub = (props) => {
	const { route, variant, onNavigate, onAction, showLabel } = props;

	const { current } = React.useContext(NavigationContext);

	return (
		<div className="asteria-component__navigation__item__sub">
			{(route?.sub ?? []).map((sub) => (
				<NavigationButton
					key={[route?.slug, sub?.slug].join('-')}
					variant={variant}
					onNavigate={onNavigate}
					onAction={onAction}
					route={sub}
					active={isActive(sub?.path, current)}
					showLabel={showLabel}
				/>
			))}
		</div>
	);
};

NavigationButtonSub.propTypes = {
	route: PropTypes.object,
	variant: PropTypes.string,
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	showLabel: PropTypes.bool,
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
};

NavigationButtonSub.displayName = 'NavigationButtonSub';

const NavigationButton = (props) => {
	const { variant, direction, onNavigate, onAction, route, showLabel } =
		props;

	const [isOpen, setOpen] = React.useState(false);
	const routeRef = React.useRef(null);

	const { current } = React.useContext(NavigationContext);

	const label = React.useMemo(
		() => (showLabel ? formatLabel(route?.label) : null),
		[route?.label, showLabel],
	);

	const handleToggle = React.useCallback(() => {
		setOpen((value) => !value);
	}, []);

	const handleClickAction = React.useCallback(
		(route) => {
			if (route?.action) {
				return onAction?.(...[].concat(route?.action));
			}

			return onNavigate?.(route?.path);
		},
		[onAction, onNavigate],
	);

	const handleClick = React.useCallback(
		(event) => {
			const isClickable = isPossibleToClick(event, routeRef.current);

			if (!isClickable) {
				return;
			}

			if (route?.sub?.length) {
				setOpen(true);

				if (!(route?.action || route?.path)) {
					return handleClickAction(route?.sub?.[0]);
				}
			}

			return handleClickAction(route);
		},
		[handleClickAction, route],
	);

	const isActiveRoute = isActive(route?.path, current);

	return (
		<FeatureWrapper feature={route?.feature}>
			<div
				className={cn(
					'asteria-component__navigation__item',
					{
						[`asteria-component__navigation--${route?.slug}`]:
							route?.slug,
						[`asteria-component__navigation__item--variant-${variant}`]:
							variant,
						'asteria-component__navigation__item--no-label': !label,
						'asteria-component__navigation__item--no-icon':
							!route?.icon,
						'asteria-component__navigation__item--no-sub':
							!route?.sub?.length,
						'asteria--state-sub-active': isSubActive(
							route,
							current,
						),
					},
					route?.className,
					stateClasses({
						active: isActiveRoute && !route?.sub?.length,
						onClick: onNavigate,
					}),
				)}
				onClick={handleClick}
				ref={routeRef}
			>
				<NavigationButtonContent
					onNavigate={onNavigate}
					onAction={onAction}
					active={isActiveRoute || isOpen}
					route={route}
					label={label}
					showLabel={showLabel}
					direction={direction}
					onCollapseClick={handleToggle}
				/>
			</div>
			{isOpen && route?.sub?.length ? (
				<NavigationButtonSub
					route={route}
					variant={variant}
					onNavigate={onNavigate}
					onAction={onAction}
					showLabel={showLabel}
					direction={direction}
				/>
			) : null}
		</FeatureWrapper>
	);
};

NavigationButton.displayName = 'NavigationButton';

NavigationButton.propTypes = {
	variant: PropTypes.oneOf(['default', 'stacked']),
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	route: PropTypes.object,
	active: PropTypes.bool,
	showLabel: PropTypes.bool,
};

export default NavigationButton;
export { NavigationButtonContent };
