import {
	addDays,
	addMonths,
	addWeeks,
	addYears,
	differenceInISOWeekYears,
	differenceInMonths,
	differenceInYears,
	eachMonthOfInterval,
	eachWeekOfInterval,
	eachYearOfInterval,
	endOfISOWeek,
	endOfMonth,
	endOfYear,
	isSameISOWeek,
	isSameMonth,
	isSameYear,
	isThisISOWeek,
	isThisMonth,
	isThisYear,
	startOfISOWeek,
	startOfMonth,
	startOfYear,
} from 'date-fns';

export const TimeSizeOrder = ['day', 'week', 'month', 'year'];

export const addTimeslice = (date, size, count = 1) => {
	if (size === 'day') {
		return addDays(date, count);
	}
	if (size === 'week') {
		return addWeeks(date, count);
	}
	if (size === 'month') {
		return addMonths(date, count);
	}
	if (size === 'year') {
		return addYears(date, count);
	}

	return date;
};

export const startOfTime = (date, size, prevSize) => {
	const modDate =
		TimeSizeOrder.indexOf(size) > TimeSizeOrder.indexOf(prevSize)
			? endOfTime(date, prevSize)
			: date;

	if (size === 'week') {
		return startOfISOWeek(modDate);
	}

	if (size === 'month') {
		return startOfMonth(modDate);
	}

	if (size === 'year') {
		return startOfYear(modDate);
	}

	return startOfMonth(modDate);
};

export const endOfTime = (date, size) => {
	if (size === 'week') {
		return endOfISOWeek(date);
	}

	if (size === 'month') {
		return endOfMonth(date);
	}

	if (size === 'year') {
		return endOfYear(date);
	}

	return endOfMonth(date);
};

export const isThis = (date, size) => {
	if (size === 'week') {
		return isThisISOWeek(date);
	}

	if (size === 'year') {
		return isThisYear(date);
	}

	return isThisMonth(date);
};

export const each = ({ startDate, endDate }, size) => {
	if (size === 'week') {
		return eachWeekOfInterval({ start: startDate, end: endDate });
	}

	if (size === 'year') {
		return eachYearOfInterval({ start: startDate, end: endDate });
	}

	return eachMonthOfInterval({ start: startDate, end: endDate });
};

export const isSame = ({ source, target }, size) => {
	if (size === 'week') {
		return isSameISOWeek(source, target);
	}

	if (size === 'year') {
		return isSameYear(source, target);
	}

	return isSameMonth(source, target);
};

export const diff = ({ source, target }, size) => {
	if (size === 'week') {
		return differenceInISOWeekYears(source, target);
	}

	if (size === 'year') {
		return differenceInYears(source, target);
	}

	return differenceInMonths(source, target);
};
