import { TransactionService } from '@asteria/backend-utils-services';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { withPagination } from '@asteria/utils-funcs/request';

const BASE_FIELDS = `
_id
type
tag {
  _id
  name
  category {
    _id
    name
  }
}
extra
`;

const BASE_PAGINATION_FIELDS = `
pageInfo {
  count
  hasNextPage
  hasPreviousPage
  currentCursor
  startCursor
  endCursor
}
edges {
  node {
    ${BASE_FIELDS}
  }
}
`;

TransactionService.transaction.extend({
	key: `fetchForecastActions`,
	getQuery: (fields = BASE_PAGINATION_FIELDS) => `
		query Request(
			$pageFilters: PageInput = {}
			$filters: ForecastActionFilters = {}
		) {
			forecastActions(pageFilters: $pageFilters, filters: $filters) {
				${fields}
			}
		}
	`,
	loggerMethod: `api.forecastActions.fetch`,
});

TransactionService.transaction.extend({
	key: `updateForecastActions`,
	getQuery: () => `
		mutation Request($input: [UpdateForecastActionInput!]!) {
			updateForecastActions(input: $input) {
				ok
				error
			}
		}
	`,
	loggerMethod: `api.forecastActions.update`,
});

TransactionService.transaction.extend({
	key: `removeForecastActions`,
	getQuery: () => `
		mutation Request($id: [ID!]!, $companyId: ObjectId) {
			deleteForecastActions(id: $id, companyId: $companyId) {
				ok
				error
			}
		}
	`,
	loggerMethod: `api.forecastActions.remove`,
});

export async function fetch({
	accessToken,
	dispatch,
	fields,
	skipLoading,
	dataloader,
}) {
	if (!skipLoading) {
		dispatch?.(ForecasterStore.actions.start());
	}

	const response = await withPagination(
		TransactionService.transaction.extension.fetchForecastActions,
	)({ fields: fields, dataloader: dataloader }, { token: accessToken });

	dispatch?.(ForecasterStore.actions.set(response));

	if (!skipLoading) {
		dispatch?.(ForecasterStore.actions.stop());
	}

	return response;
}

export async function update({ accessToken, dispatch, input }, ctx) {
	const response =
		await TransactionService.transaction.extension.updateForecastActions(
			{ input: input },
			{ token: accessToken },
		);

	await fetch({ accessToken, dispatch, skipLoading: true });

	ctx?.queryClient?.invalidateQueries?.({
		predicate: (query) =>
			['card', 'streamline'].includes(query.queryKey[0]),
	});

	return response;
}

export async function remove({ accessToken, dispatch, id }, ctx) {
	const response =
		await TransactionService.transaction.extension.removeForecastActions(
			{ id: id },
			{ token: accessToken },
		);

	await fetch({ accessToken, dispatch, skipLoading: true });

	ctx?.queryClient?.invalidateQueries?.({
		predicate: (query) =>
			['card', 'streamline'].includes(query.queryKey[0]),
	});

	return response;
}
