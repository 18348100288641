import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import Prefix, { AccountBalancePrefix } from '@asteria/component-prefix';
import { useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const LegendItem = (props) => {
	const { title, type, color, active, onClick, onHover, disabled } = props;

	const hasLargeAccountLegendFeature = useFeature(
		'graph-legend-account-balance-large',
	);

	const handleClick = useCallback(() => onClick?.(props), [props, onClick]);

	const handleMouseEnter = useCallback(
		(event) => onHover?.(event, props),
		[onHover, props],
	);

	const handleMouseLeave = useCallback(
		(event) => onHover?.(event, null),
		[onHover],
	);

	return (
		<div
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className={cn(
				'asteria-component__legend-item',
				stateClasses({
					active: active,
					disabled: disabled,
					onClick: onClick,
				}),
			)}
		>
			{(type ?? []).includes('account') &&
			hasLargeAccountLegendFeature ? (
				<AccountBalancePrefix
					className={cn({
						'asteria--variant-history': type.includes('paid'),
						'asteria--variant-future': type.includes('forecast'),
						'asteria--variant-negative': type.includes('negative'),
					})}
				/>
			) : (
				<Prefix colors={color ? color : type} />
			)}
			<Text size="sm">{title}</Text>
		</div>
	);
};

LegendItem.propTypes = {
	title: PropTypes.string,

	type: PropTypes.arrayOf(PropTypes.string),
	color: PropTypes.arrayOf(PropTypes.string),

	active: PropTypes.bool,
	disabled: PropTypes.bool,

	onClick: PropTypes.func,
	onHover: PropTypes.func,
};

export default LegendItem;
