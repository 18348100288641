/**
 * @param { string[] } data
 * @returns { string[] }
 */
export function variants(data) {
	let results = [...data];

	function combine(currentCombo, start) {
		for (let i = start; i < data.length; i++) {
			const newCombo = currentCombo.concat(data[i]);
			results.push(newCombo.join('.'));

			combine(newCombo, i + 1);

			// if (newCombo.length < end) {
			// 	combine(newCombo, i + 1, end);
			// }
		}
	}

	combine([], 0, 3);

	// Remove duplicates by converting to a set and back to an array
	results = Array.from(new Set(results));

	// Sort results to ensure correct order
	results.sort(
		(a, b) =>
			a.split('.').length - b.split('.').length || a.localeCompare(b),
	);

	return results;
}

/**
 * @template TValue
 * @param { TValue } source
 * @param { TValue } target
 * @param { () => string[] } getAvailable
 */
export function unifyOptions(source, target, getAvailable) {
	let response = Object.entries({
		...(source ?? {}),
		...(target ?? {}),
	})
		.filter(([, value]) => value)
		.flatMap(([key, value]) =>
			[]
				.concat(value)
				.map((value) =>
					value === true ? key : [key, value].join('-'),
				),
		);

	if (getAvailable && response.length) {
		const available = getAvailable();

		response = response.filter((key) =>
			(available ?? [])
				.map((value) => value.toLowerCase())
				.includes(key?.toLowerCase?.()),
		);
	}

	return response;
}

let availableOptionsCache = {};

/**
 * @param { Record<string, unknown> } translations
 * @param { string | string[] } $keys
 * @returns { { features: string[], prefix: string[], postfix: string[] } }
 */
export function getAvailableOptions(translations, $keys) {
	const keys = Array.isArray($keys) ? $keys : [$keys];

	for (const key of keys) {
		if (availableOptionsCache[key]) {
			continue;
		}

		const source = Object.keys(translations)
			.filter((source) => source.includes(key))
			.map((source) => {
				const [prefix, postfix] = source.split(key).map((key) => {
					if (key.startsWith('.')) {
						return key.slice(1);
					}

					if (key.endsWith('.')) {
						return key.slice(0, -1);
					}

					return key;
				});

				return { prefix, postfix };
			})
			.reduce(
				(acc, { prefix, postfix }) => {
					if (prefix) {
						for (const key of prefix.split('.')) {
							acc.prefix.add(key);
						}
						for (const key of prefix.split('.')) {
							acc.features.add(key);
						}
					}

					if (postfix) {
						for (const key of postfix.split('.')) {
							acc.postfix.add(key);
						}
					}

					return acc;
				},
				{
					features: new Set(),
					prefix: new Set(),
					postfix: new Set(),
				},
			);

		availableOptionsCache[key] = source;
	}

	const response = keys.reduce(
		(acc, key) => {
			const {
				features = [],
				prefix = [],
				postfix = [],
			} = availableOptionsCache[key] ?? {};

			acc.features.push(...Array.from(features));
			acc.prefix.push(...Array.from(prefix));
			acc.postfix.push(...Array.from(postfix));

			return acc;
		},
		{ features: [], prefix: [], postfix: [] },
	);

	return response;
}

export function getKeys(keys = [], options) {
	/**
	 * @param { string } key
	 * @param { boolean } [reverse]
	 */
	function format(key, reverse = false) {
		/**
		 * @param { string } value
		 */
		return (value) => (reverse ? [key, value] : [value, key]).join('.');
	}

	const features = unifyOptions(
		options?.default?.features,
		options?.features,
		options?.available?.features,
	);

	const postfix = unifyOptions(
		options?.default?.postfix,
		options?.postfix,
		options?.available?.postfix,
	);

	const prefix = unifyOptions(
		options?.default?.prefix,
		options?.prefix,
		options?.available?.prefix,
	);

	return []
		.concat(keys)
		.map((key) => key?.trim?.())
		.filter(Boolean)
		.flatMap((key) => [key, ...variants(features).map(format(key))])
		.flatMap((key) => [key, ...variants(postfix).map(format(key, true))])
		.flatMap((key) => [key, ...variants(prefix).map(format(key))]);
}
