import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import BasicStep from './step';

import './styles.scss';

const Auth2FADeactivateContent = React.memo((props) => {
	const {
		className,
		onClose,
		onAction,
		onSubmit,
		onSuccess,
		showHeaderClose,
	} = props;

	const { getValues, clearErrors, setError, trigger } = useFormContext();

	const [step, setStep] = React.useState(0);

	const [loading, setLoading] = React.useState(false);

	const length = useConfig(['modals.auth.2fa.deactivate.step'], {
		deep: true,
		callback: (value) => value?.length ?? 0,
	});

	const onNext = React.useCallback(async () => {
		if (step === 0) {
			clearErrors('code');

			const isValid = await trigger('code');

			if (!isValid) {
				return;
			}

			const code = getValues('code');

			setLoading(true);
			const response = await onSubmit?.('auth:2fa:validate', code);
			setLoading(false);

			if (!response) {
				setError('code', {
					type: 'error',
					message: TranslationService.get(
						['auth.2fa.code.error.message'],
						undefined,
						{ code: code },
					),
				});

				return;
			}

			await onSubmit?.('auth:2fa:deactivate');
		}

		setStep((step) => step + 1);
	}, [clearErrors, getValues, onSubmit, setError, step, trigger]);

	const Step = [BasicStep, BasicStep][step];

	return (
		<Wrapper
			className={cn(
				'asteria-component__auth-2fa',
				'asteria--variant-deactivate',
				className,
			)}
			scroll
		>
			<Header onClose={showHeaderClose && onClose}>
				{TranslationService.get([
					'auth.title',
					'auth.2fa.title',
					'auth.2fa.deactivate.title',
					`auth.2fa.step.${step}.title`,
					`auth.2fa.deactivate.step.${step}.title`,
					'auth.modal.title',
					'auth.modal.2fa.title',
					'auth.modal.2fa.deactivate.title',
					`auth.modal.2fa.step.${step}.title`,
					`auth.modal.2fa.deactivate.step.${step}.title`,
				])}
			</Header>
			<Content scroll>
				<Step onAction={onAction} onSubmit={onSubmit} step={step} />
			</Content>
			<Footer>
				{step + 1 < length ? (
					<FooterSection>
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.abort',
								'action.abort',
								'auth.action.abort',
								'auth.2fa.action.abort',
								'auth.2fa.deactivate.action.abort',
								`auth.step.${step}.action.abort`,
								`auth.2fa.step.${step}.action.abort`,
								`auth.2fa.deactivate.step.${step}.action.abort`,
							])}
							onClick={onClose}
						/>
					</FooterSection>
				) : null}

				<FooterSection position="last">
					{step + 1 >= length ? (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.done',
								'action.done',
								'auth.action.done',
								'auth.2fa.action.done',
								'auth.2fa.deactivate.action.done',
								`auth.step.${step}.action.done`,
								`auth.2fa.step.${step}.action.done`,
								`auth.2fa.deactivate.step.${step}.action.done`,
							])}
							onClick={onSuccess}
							loading={loading}
							disabled={loading}
						/>
					) : (
						<Button
							variant="primary"
							label={TranslationService.get([
								'button.next',
								'action.next',
								'auth.action.next',
								'auth.2fa.action.next',
								'auth.2fa.deactivate.action.next',
								`auth.step.${step}.action.next`,
								`auth.2fa.step.${step}.action.next`,
								`auth.2fa.deactivate.step.${step}.action.next`,
							])}
							onClick={onNext}
							loading={loading}
							disabled={loading}
						/>
					)}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

Auth2FADeactivateContent.displayName = 'Auth2FADeactivateContent';

Auth2FADeactivateContent.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	showHeaderClose: PropTypes.bool,
};

export default Auth2FADeactivateContent;
