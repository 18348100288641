import React from 'react';

import PropTypes from 'prop-types';

import Feedback from '@asteria/component-feedback';

import { TranslationService } from '@asteria/language';

const ForecasterFeedback = (props) => {
	const { onAction, className } = props;

	const [isOpen, setOpen] = React.useState(true);

	const handleAction = React.useCallback(
		(action, data) =>
			onAction?.('forecaster:feedback:submit', {
				action: action,
				data: data,
			}),
		[onAction],
	);

	const handleFeedbackContact = React.useCallback(
		() => onAction?.('forecaster:feedback:support'),
		[onAction],
	);

	const onFeedbackAction = React.useCallback(
		(action, data) => {
			if (data.dismissed) {
				setOpen(false);
			}

			onAction?.('updateUserSettings', {
				flags: { isForecastCompleted: false },
			});

			return handleAction?.(action, data);
		},
		[handleAction, onAction],
	);

	const handleClose = React.useCallback(() => {
		onAction?.('updateUserSettings', {
			flags: { isForecastCompleted: false },
		});

		setOpen(false);
	}, [onAction]);

	if (!isOpen) {
		return null;
	}

	return (
		<Feedback
			className={className}
			onAction={onFeedbackAction}
			onSupportRequest={handleFeedbackContact}
			feedbackKey="forecaster-feedback"
			description={TranslationService.get(
				'forecaster.feedback.description',
			)}
			minText={TranslationService.get('forecaster.feedback.min')}
			maxText={TranslationService.get('forecaster.feedback.max')}
			values={[1, 2, 3, 4, 5]}
			size="lg"
			validate
			type="forecaster"
			onEnd={handleClose}
		/>
	);
};

ForecasterFeedback.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default React.memo(ForecasterFeedback);
