import { createSelector } from '@reduxjs/toolkit';

export const current = createSelector(
	(state) => state?.modals?.current,
	(value) => value ?? null,
);

export const hasHistory = createSelector(
	(state) => state?.modals?.history ?? [],
	(value) => value.length > 0,
);
