import React from 'react';

import ForecasterView from '@asteria/view-forecaster';

import { LayoutContext } from '../../context';

import './styles.scss';

const ForecasterPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<ForecasterView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

ForecasterPage.displayName = 'ForecasterPage';

ForecasterPage.propTypes = {};

export default ForecasterPage;
