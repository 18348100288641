import React from 'react';

import PropTypes from 'prop-types';

import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getFaceIcon } from '../utils';

import '../styles.scss';

const SatisfactionSelectorColumn = React.memo((props) => {
	const { value, active, onSelect } = props;

	const icon = getFaceIcon({ value });

	const onClick = React.useCallback(
		() => onSelect?.(value),
		[onSelect, value],
	);

	return (
		<div
			className={cn(
				'asteria-component__satisfaction-selector-column',
				stateClasses({ active: active }),
			)}
			onClick={onClick}
		>
			<div className="asteria-component__satisfaction-selector-dot">
				<div className="asteria-component__satisfaction-selector-dot-content" />
			</div>
			<div className="asteria-component__satisfaction-selector-icon-wrapper">
				<Icon
					className={cn(
						'asteria-component__satisfaction-selector-icon',
						'asteria--variant-value',
					)}
					icon={icon}
					size="lg"
				/>
				<Text
					size="xs"
					className="asteria-component__satisfaction-selector-label"
				>
					{TranslationService.get([
						'forecaster.page.category.section.probability.selection.label',
						`forecaster.page.category.section.probability.selection.${value}.label`,
					])}
				</Text>
			</div>
		</div>
	);
});

SatisfactionSelectorColumn.displayName = 'SatisfactionSelectorColumn';
SatisfactionSelectorColumn.propTypes = {
	onSelect: PropTypes.func,
	active: PropTypes.bool,
	value: PropTypes.number,
};

export default SatisfactionSelectorColumn;
