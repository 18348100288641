import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';
import Content from '@asteria/component-core/wrapper/content';

import { FeedbackRating } from '@asteria/component-feedback';
import {
	Wrapper as FormWrapper,
	Input,
	useFormValues,
} from '@asteria/component-form';

import { Translation, TranslationService } from '@asteria/language';

import '../styles.scss';

/** @type { React.FC<{ from: string, step: number, card: unknown }> } */
const CardDismissForm = function CardDismissForm({ from, rating, card }) {
	const { setValue } = useFormContext();

	const ratingValue = useFormValues({ name: 'rating' });

	const onRatingChange = React.useCallback(
		(value) => setValue('rating', value),
		[setValue],
	);

	return (
		<FormWrapper>
			<Content>
				<Translation
					translationKey="card.content.text"
					translationOptions={{
						postfix: {
							variant: 'feedback',
							name: 'title',
							from: from,
							feedback: 'dismiss',
						},
						data: { card: card },
					}}
					Component={Text}
				/>
				<Input
					name="title"
					placeholder={TranslationService.getV2(
						['card.input.placeholder'],
						{
							postfix: {
								variant: 'feedback',
								name: 'title',
								from: from,
								feedback: 'dismiss',
							},
							data: { card: card },
						},
					)}
				/>
				<Input
					name="description"
					type="textarea"
					placeholder={TranslationService.getV2(
						['card.input.placeholder'],
						{
							postfix: {
								variant: 'feedback',
								name: 'description',
								from: from,
								feedback: 'dismiss',
							},
							data: { card: card },
						},
					)}
				/>
				{rating ? (
					<FeedbackRating
						value={ratingValue}
						minText={TranslationService.getV2(['label'], {
							postfix: {
								variant: 'min',
								from: from,
								step: 'feedback',
							},
							data: { card: card },
						})}
						maxText={TranslationService.getV2(['label'], {
							postfix: {
								variant: 'max',
								from: from,
								feedback: 'dismiss',
							},
							data: { card: card },
						})}
						onChange={onRatingChange}
					/>
				) : null}
			</Content>
		</FormWrapper>
	);
};

CardDismissForm.propTypes = {
	from: PropTypes.string,
	rating: PropTypes.bool,
	card: PropTypes.object,
};

export default CardDismissForm;
