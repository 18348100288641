import { createSelector } from '@reduxjs/toolkit';
import { get, isEqual } from 'lodash-es';

export const active = createSelector(
	(store) => store?.transactions?.active,
	(value) => value ?? null,
);

export const isActive = createSelector(
	(store) => store?.transactions?.active,
	(_, ID) => ID,
	(active, ID) => active === ID,
);

export const isSelected = createSelector(
	(store) => store?.transactions?.selected,
	(_, ID) => ID,
	(selected, ID) => selected?.[ID] ?? false,
);

export const transactions = createSelector(
	(store) => store?.transactions?.items,
	(items) => items ?? {},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const item = createSelector(
	(store) => store?.transactions?.items,
	(_, id) => id,
	(items, id) => items?.[id] ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const field = createSelector(
	(store) => store?.transactions?.items,
	(_, { id, field }) => ({ id, field }),
	(items, { id, field }) => get(items?.[id] ?? [], field, items?.[id] ?? {}),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const state = createSelector(
	(store) => store?.transactions?.state,
	(value) => value ?? null,
);

export const isCompact = createSelector(
	(store) => store?.app?.user?.settings?.compressList,
	(value) => value ?? false,
);

export const visibleColumns = createSelector(
	(store) => store?.app?.user?.settings?.listColumns,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const timestamp = createSelector(
	(store) => store?.transactions?.timestamp,
	(value) => value ?? null,
);
