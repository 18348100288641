import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';

import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import Step from '../step';

import './styles.scss';

const BasicStep = React.memo((props) => {
	const { onAction, onSubmit, data, step } = props;

	return (
		<Step
			onAction={onAction}
			onSubmit={onSubmit}
			data={data}
			path={`modals.auth.2fa.setup.step.${step}`}
		/>
	);
});

BasicStep.displayName = 'BasicStep';
BasicStep.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	data: PropTypes.object,
	step: PropTypes.number,
};

const Step3 = React.memo((props) => {
	const { onAction, onSubmit, data } = props;

	const dispatch = useDispatch();

	const [variant, setVariant] = React.useState('qr');

	const config = useConfig(['modals.auth.2fa.setup.step.2'], {
		deep: true,
	})?.[variant];

	const handleAction = React.useCallback(
		(action, $event) => {
			if (action === 'switch') {
				setVariant($event);

				return;
			}

			if (action === 'copy:secret') {
				navigator.clipboard.writeText(data?.secret);

				SnackbarStore.show(dispatch, {
					title: 'snackbar.auth.2fa.secret.copy.title',
					content: 'snackbar.auth.2fa.secret.copy.content',
					type: 'auth-2fa-secret-copy',
					variant: 'success',
					icon: 'clipboard',
					hideActions: true,
					data: data,
				});

				return;
			}

			return onAction?.(action, $event);
		},
		[data, dispatch, onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction, onSubmit: onSubmit }),
		[handleAction, onSubmit],
	);

	return (
		<Contenter
			className={cn(`asteria--variant-${variant}`)}
			content={config?.content}
			actions={actions}
			data={data}
		/>
	);
});

Step3.displayName = 'Step3';
Step3.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	data: PropTypes.object,
};

export { BasicStep, Step3 };
