import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const getRiskHigh = (item) => {
	if (item.status === 'PAID') {
		if (item?.oracle?.currency?.risk < 1) {
			return 0;
		}

		return item?.oracle?.currency?.max?.display?.total;
	}

	if (item.status === 'OVERDUE') {
		return 0;
	}

	return item?.oracle?.currency?.min?.display?.total;
};

const getRiskLow = (item) => {
	if (item.status === 'PAID') {
		if (item?.oracle?.currency?.risk >= 1) {
			return 0;
		}

		return item?.oracle?.currency?.max?.display?.total;
	}

	if (item.status === 'OVERDUE') {
		return 0;
	}

	return item?.oracle?.currency?.max?.display?.total;
};

const getInvoice = (item) => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'INVOICE_PAYMENT')) {
		const invoice = links.find(({ type }) => type === 'INVOICE_PAYMENT');
		return invoice?.invoiceDetails || false;
	}

	return false;
};

const getBank = (item) => {
	const links = item?.links || [];

	if (links.find(({ type }) => type === 'BANK_TRANSACTION')) {
		const transaction = links.find(
			({ type }) => type === 'BANK_TRANSACTION',
		);
		return transaction?.accountDetails || false;
	}

	return false;
};

const RiskCell = React.memo((props) => {
	const { className, data } = props;

	const invoice = getInvoice(data);
	const bank = getBank(data);

	let hasRisk =
		data?.status !== 'PAID' &&
		data?.oracle?.currency?.risk &&
		data?.sums?.display?.currency !== data?.sums?.original?.currency;

	const lowRisk = getRiskLow(data);
	const highRisk = getRiskHigh(data);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			{hasRisk && (highRisk !== 0 || lowRisk !== 0) ? (
				<Text>
					{TranslationService.get(
						['list.transactions.transaction.risk'],
						undefined,
						{
							item: data,
							invoice: invoice,
							bank: bank,
							lowRisk: lowRisk,
							highRisk: highRisk,
						},
					)}
				</Text>
			) : null}
		</div>
	);
});

RiskCell.displayName = 'RiskCell';

RiskCell.propTypes = { className: PropTypes.string, data: PropTypes.object };

export default RiskCell;
