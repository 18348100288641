import React, { useContext, useRef } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { Text } from '@asteria/component-core/typography';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';
import useComponentSize from '@asteria/utils-hooks/useComponentSize';

import { GraphActionsContext } from './context';
import { useXAxisClickable, useXAxisHoverable } from './hooks';
import * as Selectors from './selectors';

import './xaxis.scss';

const XAxisLabel = React.memo(
	({ rangeId, className, updateSize, children }) => {
		const ref = useRef(null);
		const { width } = useComponentSize({ ref: ref });
		const { clickAction, mouseEnterAction, mouseLeaveAction } =
			useContext(GraphActionsContext);

		const clickable = useXAxisClickable();
		const hoverable = useXAxisHoverable();

		React.useEffect(() => {
			updateSize?.(width);
		}, [updateSize, width]);

		const onClick = React.useCallback(
			() => clickAction({ group: { id: rangeId }, source: 'account' }),
			[clickAction, rangeId],
		);

		const onMouseEnter = React.useCallback(
			() => mouseEnterAction?.({ group: { id: rangeId } }),
			[mouseEnterAction, rangeId],
		);

		const onMouseLeave = React.useCallback(
			() => mouseLeaveAction?.({ group: { id: rangeId } }),
			[mouseLeaveAction, rangeId],
		);

		const onKeyPress = React.useCallback(
			() => clickAction?.({ group: { id: rangeId }, source: 'account' }),
			[clickAction, rangeId],
		);

		return (
			<div
				ref={ref}
				className={cn(
					{
						'asteria--state-clickable': clickable,
						'asteria--state-hoverable': hoverable,
					},
					className,
				)}
				onClick={clickable ? onClick : undefined}
				onMouseEnter={hoverable ? onMouseEnter : undefined}
				onMouseLeave={hoverable ? onMouseLeave : undefined}
				onKeyPress={onKeyPress}
				role="button"
				tabIndex="-1"
				title={TranslationService.get('graph.xaxis.button')}
			>
				<span className="asteria-indicator__wrapper">
					<span className="asteria-indicator asteria-time-indicator" />
				</span>
				{children}
			</div>
		);
	},
);

XAxisLabel.displayName = 'XAxisLabel';

const XAxisWrapper = React.memo((props) => {
	const { id, types, label, prefix, updateSize } = props;

	const active = useSelector(
		(store) => Selectors.graph.active(store, id),
		(a, b) => isEqual(a, b),
	);

	const noChildrenActive = useSelector(
		(store) => Selectors.graph.xAxis.noChildrenActive(store, id),
		(a, b) => isEqual(a, b),
	);

	const hover = useSelector(
		(store) => Selectors.graph.hover(store, id),
		(a, b) => isEqual(a, b),
	);

	const noChildrenHover = useSelector(
		(store) => Selectors.graph.xAxis.noChildrenHover(store, id),
		(a, b) => isEqual(a, b),
	);

	const value = useSelector(
		(store) => Selectors.graph.xAxis.value(store, id),
		(a, b) => isEqual(a, b),
	);

	return (
		<XAxisLabel
			className={cn(
				'asteria-graph-xaxis',
				{
					'asteria-state-active': active,
					'asteria-graph-xaxis-no-children-active': noChildrenActive,
					'asteria-state-hover': hover,
					'asteria-graph-xaxis-no-children-hover': noChildrenHover,
				},
				types?.map((t) => `asteria-graph-xaxis-${t}`),
			)}
			key={id}
			rangeId={id}
			updateSize={updateSize}
		>
			{prefix && (
				<Text size="xs" className="asteria-time-divider">
					{prefix}
				</Text>
			)}
			<Text size="sm" className="asteria-graph-x-axis-label-text">
				{label}
			</Text>
			<FeatureFlag feature="graph-account-balance-below-bar-graph">
				<Text
					size="xs"
					className={cn('asteria-graph-x-axis-label-total', {
						'asteria-graph-x-axis-label-total-negative':
							(value || 0) < 0,
					})}
				>
					{formatNumber(value || 0)}
				</Text>
			</FeatureFlag>
		</XAxisLabel>
	);
});

XAxisWrapper.displayName = 'XAxisWrapper';

const XAxis = React.memo(({ updateSize }) => {
	const labels = useSelector(Selectors.graph.range);

	return (
		<>
			{labels
				.slice(1, -1)
				.map(({ id, label, types = [], prefix = false }) => (
					<XAxisWrapper
						key={id}
						id={id}
						label={label}
						types={types}
						prefix={prefix}
						updateSize={updateSize}
					/>
				))}
		</>
	);
});

XAxis.displayName = 'XAxis';
export { XAxisLabel, XAxisWrapper };
export default XAxis;
