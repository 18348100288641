import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import Button from '@asteria/component-core/button';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

const selectors = {
	hasErp: createSelector(
		(store) => store?.integrations?.items ?? {},
		(value) => Object.values(value).some(({ type }) => type === 'erp'),
	),
};

const NavigationActions = () => {
	const dispatch = useDispatch();
	const hasErp = useSelector(selectors.hasErp);

	const handleERPClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
				data: { type: 'erp' },
			}),
		);
	}, [dispatch]);

	if (hasErp) {
		return null;
	}

	return (
		<FeatureFlag feature="header-action-erp">
			<Button
				variant="secondary"
				size="sm"
				className="asteria-component__button--add-erp"
				tooltipClassName="asteria-component__tooltip__add-erp"
				tooltip={TranslationService.get(
					'header.action.add.erp.tooltip',
					// eslint-disable-next-line spellcheck/spell-checker
					'Koppla på ditt bokföringssystem för att se dina fakturor, mer historik och på så sätt få en bättre överblick för framtiden med Företagskollen.',
				)}
				label={TranslationService.get(
					'header.action.add.erp',
					// eslint-disable-next-line spellcheck/spell-checker
					'Lägg till bokföringssystem',
				)}
				onClick={handleERPClick}
			/>
		</FeatureFlag>
	);
};

NavigationActions.propTypes = {};

export default NavigationActions;
