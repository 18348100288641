import { BankService } from '@asteria/backend-utils-services';

import { setAccounts, setCredit } from '@asteria/datalayer/stores/accounts';

import { withPagination } from '@asteria/utils-funcs/request';

export const FIELDS = `
_id
id
name
active
identifiers {
	clearing
	account
	number
	bic
	iban
}
sums {
	original {
		currency
		total
	}
	display {
		currency
		total
		credit
	}
}
`;

/**
 * @param { { accessToken: string, dispatch?: unknown, dataloader?: unknown } } options
 */
export async function fetch(options) {
	const { fields, accessToken, dataloader, dispatch } = options;

	const accountFields = fields || FIELDS;

	const response = await withPagination(BankService.bankAccount.fetch)(
		{
			dataloader: dataloader,
			fields: `
				pageInfo {
					count
					startCursor
					endCursor
					hasNextPage
				}
				edges {
					node {
						${accountFields}
					}
				}
			`,
			pageFilters: { orderField: 'name' },
		},
		{ token: accessToken },
	).catch(() => []);

	const credit = Math.round(
		response.reduce(
			(total, account) => total + (account?.sums?.display?.credit || 0),
			0,
		),
	);

	dispatch?.(setCredit(credit));
	dispatch?.(setAccounts(response));

	return response;
}

export async function update(options) {
	const { accessToken, input } = options;

	try {
		await BankService.bankAccount.update(
			{ input: input },
			{ token: accessToken },
		);
	} catch (e) {
		// Do nothing
	}

	return fetch(options);
}
