import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Title, { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';

import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

const ScenarioCreate = (props) => {
	const { onClose, onAction, type, data } = props;

	const [name, setName] = useState('');

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);
	const isUpdating = useSelector(ScenarioStore.selectors.isUpdating);

	const handleChange = useCallback(
		(event) => setName(event.value),
		[setName],
	);

	const handleSave = useCallback(() => {
		if (name.length) {
			Analytics.event('forecaster.scenario.create.save', { name: name });

			onAction?.('scenario:create', {
				name: name,
				type: type,
				data: data,
			});

			setName('');
		}
	}, [data, name, onAction, type]);

	return (
		<Wrapper>
			<Header onClose={onClose}>
				{TranslationService.get([
					'forecaster.modal.create.title',
					`forecaster.modal.create.${type}.title`,
				])}
			</Header>
			<Content>
				<FormWrapper>
					<Content>
						<TextGroup>
							<Title type="title" size="xs">
								{TranslationService.get([
									'forecaster.modal.create.body.label',
									`forecaster.modal.create.${type}.body.label`,
								])}
							</Title>
							<Text>
								{TranslationService.get([
									'forecaster.modal.create.body',
									`forecaster.modal.create.${type}.body`,
								])}
							</Text>
						</TextGroup>
						{TranslationService.get([
							'forecaster.modal.create.form.label',
							`forecaster.modal.create.${type}.form.label`,
						]) ? (
							<Title type="title" size="xs">
								{TranslationService.get([
									'forecaster.modal.create.form.label',
									`forecaster.modal.create.${type}.form.label`,
								])}
							</Title>
						) : null}
						<Input
							uncontrolled
							type="text"
							name="name"
							label={TranslationService.get([
								'forecaster.modal.create.label.name',
								`forecaster.modal.create.${type}.label.name`,
							])}
							direction="vertical"
							onChange={handleChange}
							value={name}
							required
						/>
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection>
					{hasHistory ? (
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.back',
								'forecaster.modal.create.button.back',
								`forecaster.modal.create.${type}.button.back`,
							])}
							onClick={onClose}
							analyticsKey="forecaster.modal.create.back"
						/>
					) : (
						<Button
							variant="tertiary"
							label={TranslationService.get([
								'button.cancel',
								'forecaster.modal.create.button.cancel',
								`forecaster.modal.create.${type}.button.cancel`,
							])}
							onClick={onClose}
							analyticsKey="forecaster.modal.create.cancel"
						/>
					)}
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.save',
							'forecaster.modal.create.button.save',
							`forecaster.modal.create.${type}.button.save`,
						])}
						onClick={handleSave}
						analyticsKey="forecaster.modal.create.save"
						loading={isUpdating}
						disabled={isUpdating}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
};

ScenarioCreate.displayName = 'ScenarioCreate';

ScenarioCreate.propTypes = {
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
	type: PropTypes.oneOf(['FORECAST', 'CREDIT', 'LOAN']),
};

const ScenarioCreateModal = (props) => {
	const { open, onClose } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			size="sm"
			className={cn('asteria--type-scenario', 'asteria--action-create')}
		>
			<ScenarioCreate {...props} onClose={handleClose} />
		</Modal>
	);
};

ScenarioCreateModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
	type: PropTypes.oneOf(['FORECAST', 'CREDIT', 'LOAN']),
};

export default ScenarioCreateModal;
