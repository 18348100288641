import { createAsyncThunk } from '@reduxjs/toolkit';

import FeatureService from '@asteria/component-tools/featureflag/service';

import * as FormatUtils from '@asteria/utils-funcs/format';

import * as ForecasterStore from '../../forecaster';
import * as SnackbarStore from '../../snackbar';
import slice from '../slice';

import { actions as tagActions } from './tags';

const next = createAsyncThunk(
	'forecaster/navigation/next',
	(data, { dispatch, getState }) => {
		const { focusPath } = data;

		const state = getState();

		const type = ForecasterStore.selectors.navigation.type(state);
		const categoryName =
			ForecasterStore.selectors.navigation.category(state) || '$type';
		const tagName =
			ForecasterStore.selectors.navigation.tag(state) || `$${type}`;
		const isCompleted =
			ForecasterStore.selectors.navigation.isCompleted(state);

		if (
			focusPath.length > 0 &&
			FeatureService.isActive('forecaster.skip.snackbar')
		) {
			dispatch(
				slice.actions.markSkipped({
					type: type,
					categoryName: categoryName,
					tagName: tagName,
					value: !isCompleted,
				}),
			);

			if (!isCompleted) {
				SnackbarStore.show(dispatch, {
					title: 'snackbar.forecaster.tag.skipped.title',
					content: 'snackbar.forecaster.tag.skipped.content',
					type: 'forecaster.tag.skipped',
					variant: 'warning',
					icon: 'warning',
					data: {
						type: type,
						category: categoryName,
						tag: tagName,
						data: data?.data,
						label: FormatUtils.formatTag({
							type: type,
							category: categoryName,
							tag: tagName,
						}),
					},
				});
			}
		} else {
			dispatch(
				tagActions.toggleCompleted({
					type,
					categoryName,
					tagName,
					value: true,
					data: data?.data,
				}),
			);
		}

		dispatch(slice.actions.navigationNext());
	},
);

export const actions = { next };
export const reducers = {
	[next.fulfilled]: () => {},
};
