import React from 'react';

const Context = React.createContext({
	dates: [],
	range: 'year',

	back: () => null,
	next: () => null,
	setRange: () => null,
});

const GraphContext = React.createContext({ forecast: [] });

export default Context;
export { GraphContext };
