import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import CategoryLoader from './CategoryLoader';
import GraphLoader from './GraphLoader';

import './styles.scss';

const Loader = (props) => {
	const { className, variant } = props;

	if (!variant) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-view__welcome__loader',
				{
					[`asteria-view__welcome__loader--variant-${variant}`]:
						variant,
				},
				className,
			)}
		>
			{variant === 'category' ? <CategoryLoader /> : null}
			{variant === 'graph' ? <GraphLoader /> : null}
		</div>
	);
};

Loader.displayName = 'Loader';

Loader.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.oneOf(['category', 'graph']),
};

export default Loader;
