import store from '@asteria/datalayer';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';

import * as FormatUtils from '@asteria/utils-funcs/format';

function showRemoveSnackbar({ type, category, tag, dispatch }) {
	SnackbarStore.show(dispatch, {
		title: 'snackbar.forecaster.tag.removed.title',
		content: 'snackbar.forecaster.tag.removed.content',
		type: 'forecaster.tag.removed',
		variant: 'success',
		icon: 'check',
		data: {
			type: type,
			category: category,
			tag: tag,
			label: FormatUtils.formatTag({
				type: type,
				category: category,
				tag: tag,
			}),
		},
	});
}

function showMoveSnackbar({ type, dispatch, extra = {} }) {
	SnackbarStore.show(dispatch, {
		title: extra?.isExistingTag
			? [
					'snackbar.forecaster.tag.moved.existing.title',
					`snackbar.forecaster.${type}.tag.moved.existing.title`,
			  ]
			: [
					'snackbar.forecaster.tag.moved.new.title',
					`snackbar.forecaster.${type}.tag.moved.new.title`,
			  ],
		content: extra?.isExistingTag
			? [
					'snackbar.forecaster.tag.moved.existing.content',
					`snackbar.forecaster.${type}.tag.moved.existing.content`,
			  ]
			: [
					'snackbar.forecaster.tag.moved.new.content',
					`snackbar.forecaster.${type}.tag.moved.new.content`,
			  ],
		type: 'forecaster.tag.moved',
		hideActions: true,
		variant: 'success',
		icon: 'check',
		data: {
			...extra,
			type: type,
			source: {
				...extra.source,
				label: FormatUtils.formatTag({ type: type, ...extra.source }),
			},
			destination: {
				...extra.destination,
				label: FormatUtils.formatTag({
					type: type,
					...extra.destination,
				}),
			},
		},
	});
}

export default async ({
	category,
	tag,
	dispatch,
	event = { type: 'REMOVE' },
}) => {
	const state = store.getState();

	const navigationType = ForecasterStore.selectors.navigation.type(state);
	const navigationCategory =
		ForecasterStore.selectors.navigation.category(state);
	const navigationTag = ForecasterStore.selectors.navigation.tag(state);

	if (
		category !== navigationCategory ||
		tag !== navigationTag ||
		event?.type === 'MOVE'
	) {
		dispatch(
			ForecasterStore.tags.deselect([
				{ type: navigationType, categoryName: category, tagName: tag },
			]),
		);
	}

	if (event?.type === 'REMOVE') {
		showRemoveSnackbar({
			type: navigationType,
			category: category,
			tag: tag,
			dispatch: dispatch,
			extra: event?.data,
		});
	}

	if (event?.type === 'MOVE') {
		showMoveSnackbar({
			type: navigationType,
			dispatch: dispatch,
			extra: event?.data,
		});
	}
};
