import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { Text } from '../../typography';

import { useColor } from './utils';

import '../index.scss';

const Stepper = React.memo((props) => {
	const { className, progress = -1, steps: $steps, color } = props;

	const steps = $steps || 1;

	const { style: colorStyle, className: colorClassName } = useColor(color);
	const style = { ...colorStyle };

	const progressStep = Math.floor(100 / steps) || 0;

	return (
		<div
			className={cn(
				'asteria-component__progress',
				'asteria-component__progress--variant-stepper',
				className,
			)}
		>
			<div className="asteria-component__progress__steps">
				{new Array(steps).fill(null).map((value, index) => {
					const prevValue = progressStep * index;
					const nextValue = progressStep * (index + 1);

					const isFilled =
						progress > prevValue && progress >= nextValue;

					const isCurrent =
						progress > prevValue && progress < nextValue;

					const isEmpty = progress <= prevValue;

					return (
						<div
							key={index}
							className={cn(
								'asteria-component__progress__status',
								{
									['asteria-component__progress__status--filled']:
										isFilled,
									['asteria-component__progress__status--empty']:
										isEmpty,
									['asteria-component__progress__status--current']:
										isCurrent,
								},
								colorClassName,
							)}
							style={{
								...style,
								'--state': isCurrent ? progress : undefined,
							}}
						/>
					);
				})}
			</div>

			<Text>{`${progress}%`}</Text>
		</div>
	);
});

Stepper.displayName = 'Stepper';

Stepper.propTypes = {
	className: PropTypes.string,
	progress: PropTypes.number,
	steps: PropTypes.number,
	color: PropTypes.shape({ value: PropTypes.string, type: PropTypes.string }),
};

export default Stepper;
