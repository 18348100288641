import React from 'react';

import StatementView from '@asteria/view-statement';

import { LayoutContext } from '../../context';

import './styles.scss';

const StatementPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<StatementView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

StatementPage.displayName = 'StatementPage';

StatementPage.propTypes = {};

export default StatementPage;
