import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import * as AppStore from '@asteria/datalayer/stores/app';

import { fetch as fetchStyles } from '../api/theme';

import { useTheme } from '@asteria/themes';

const ThemeGlobalStyle = createGlobalStyle`
	${({ style }) => style}
`;

const ThemeWrapper = React.memo((props) => {
	const { children, theme, partnerId } = props;

	const accessToken = useSelector(AppStore.selectors.accessToken);

	useTheme(theme);

	const { data } = useQuery({
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		queryKey: [
			'widget',
			'theme',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
		],
		queryFn: async ({ signal }) =>
			fetchStyles({
				accessToken: accessToken,
				partnerId: partnerId,
				signal: signal,
			}),
		enabled: !!partnerId && !!accessToken,
		placeholderData: {},
	});

	return (
		<>
			<ThemeGlobalStyle style={data} />
			{children}
		</>
	);
});

ThemeWrapper.displayName = 'ThemeWrapper';
ThemeWrapper.propTypes = {
	children: PropTypes.node,
	theme: PropTypes.string,
	partnerId: PropTypes.string,
};

export default ThemeWrapper;
