import React from 'react';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import {
	Text,
	TextGroup,
	UnorderedList,
} from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

import { useInitiatingIntegration, useRequiredIntegrations } from '../../hooks';
import BasicCard from '../basic';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'up' | 'down' } direction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useRequest({ onSubmit, startDate, endDate }) {
	return useQuery({
		queryKey: ['card', 'health', { startDate, endDate }],
		queryFn: async () => {
			const response = await onSubmit?.('card:fetch', {
				type: 'health',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
			});

			return response;
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,
	});
}

function useHealthState({ query }) {
	const score = query?.data?.source?.score ?? 0;

	let state = 'bad';

	if (score > 20) {
		state = 'acceptable';
	}

	if (score > 40) {
		state = 'good';
	}

	if (score > 60) {
		state = 'great';
	}

	if (score > 80) {
		state = 'perfect';
	}

	return state;
}

/**
 * @type { React.FC<ChildrenOptions> }
 */
const HealthExtra = React.memo(function HealthExtra(props) {
	const { onboarding, query, translationOptions } = props;

	const actions = query?.data?.source?.actions ?? [];

	if (onboarding === 'none') {
		return null;
	}

	return (
		<>
			<TextGroup>
				<Translation
					translationKey="card.content.extra.0"
					translationOptions={translationOptions}
					Component={Text}
				/>
				<Translation
					translationKey="card.content.extra.1"
					translationOptions={translationOptions}
					Component={Text}
				/>
			</TextGroup>
			{actions.length ? (
				<UnorderedList
					items={actions.map((action) => ({
						skipEmpty: true,

						value: 'card.content.bullet',
						translationOptions: {
							...translationOptions,
							postfix: {
								...translationOptions?.postfix,
								action: action?.type,
								...action?.postfix,
							},
							data: {
								...translationOptions?.data,
								action: action?.data,
								score: action?.score,
							},
						},
					}))}
				/>
			) : null}
		</>
	);
});

HealthExtra.propTypes = {
	loading: PropTypes.bool,
	variant: PropTypes.string,
	query: PropTypes.object,
	onboarding: PropTypes.string,
	direction: PropTypes.string,
	translationOptions: PropTypes.object,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const HealthCard = React.memo(function HealthCard(props) {
	const { startDate, endDate, onSubmit } = props;

	const query = useRequest({
		onSubmit,
		startDate,
		endDate,
	});

	const { required, optional } = useRequiredIntegrations({
		required: ['erp', 'bank'],
		optional: 'both',
	});

	const initiating = useInitiatingIntegration({ type: 'bank' });

	const integrations = React.useMemo(
		() => ({
			required: required,
			optional: optional,
			initiating: initiating,
		}),
		[optional, required, initiating],
	);

	const health = useHealthState({ query });

	const extra = React.useMemo(
		() => ({ postfix: { health: health } }),
		[health],
	);

	return (
		<BasicCard
			{...props}
			type="health"
			query={query}
			integrations={integrations}
			extra={extra}
		/>
	);
});

HealthCard.displayName = 'HealthCard';

HealthCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),
};

export default HealthCard;
export { HealthExtra };
