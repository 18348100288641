import React from 'react';

import PropTypes from 'prop-types';

import { View as LineGraphLineView } from '@asteria/component-forecaster/components/graph/lines/column/line';

import LoanContext from '../../../../context/loan';

const LoanLineGraphLine = React.memo((props) => {
	const {
		current: line,
		previous: previousLine = line,
		next: nextLine = line,
		max,
		min,
		height = 100,
	} = props;

	const { range } = React.useContext(LoanContext);

	return (
		<LineGraphLineView
			line={line}
			previousLine={previousLine}
			nextLine={nextLine}
			max={max}
			min={min}
			height={height}
			type={range}
			// hiddenSpread
			// invisibleSpread
			// hiddenLineHistory
			// invisibleLineHistory
			// hiddenLineFuture
			// invisibleLineFuture
		/>
	);
});

LoanLineGraphLine.displayName = 'LoanLineGraphLine';
LoanLineGraphLine.propTypes = {
	current: PropTypes.object,
	previous: PropTypes.object,
	next: PropTypes.object,
	max: PropTypes.number,
	min: PropTypes.number,
	height: PropTypes.number,
};

export default LoanLineGraphLine;
