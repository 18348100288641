import React from 'react';

import PropTypes from 'prop-types';

import PinButton from './pin';
import RedirectButton from './redirect';

/**
 * @typedef { import('./types').PostfixProps } PostfixProps
 */

/** @type { React.FC<PostfixProps> } */
const Postfix = React.memo(function Postfix(props) {
	const { type, onAction, startDate, endDate } = props;

	return (
		<div className="flex gap-1 items-center">
			<PinButton type={type} onAction={onAction} />
			<RedirectButton
				type={type}
				startDate={startDate}
				endDate={endDate}
				onAction={onAction}
			/>
		</div>
	);
});

Postfix.propTypes = {
	type: PropTypes.string,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	onAction: PropTypes.func,
};

export default Postfix;
