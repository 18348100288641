import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import SectionLoan from './section/loan';
import SectionOverdraft from './section/overdraft';
import SectionPlaceholder from './section/placeholder';

import './styles.scss';

const HomePage = React.memo((props) => {
	const { onAction, onSubmit } = props;

	return (
		<div
			className={cn(
				'asteria-component__financial-page',
				'asteria--variant-home',
			)}
		>
			<SectionPlaceholder onAction={onAction} onSubmit={onSubmit} />
			<SectionOverdraft onAction={onAction} onSubmit={onSubmit} />
			<SectionLoan onAction={onAction} onSubmit={onSubmit} />
		</div>
	);
});

HomePage.displayName = 'HomePage';
HomePage.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default HomePage;
