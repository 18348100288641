import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import List from '@asteria/component-list';
import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import IntegrationGroup from './Group';

import './styles.scss';

const IntegrationList = (props) => {
	const {
		loading,
		onClose,
		onAction,
		onSubmit,
		showHeader = false,
		showFooter = false,

		content,
	} = props;

	const hasBankFeature = useFeature('bank-integrations');

	const actions = React.useMemo(
		() => ({ onAction, onSubmit }),
		[onAction, onSubmit],
	);

	return (
		<Wrapper className="asteria-component__wrapper--onboarding-list" scroll>
			{showHeader ? (
				<Header onClose={onClose}>
					{TranslationService.get('integrations.list.header')}
				</Header>
			) : null}

			{content?.before?.length ? (
				<Content>
					<Contenter content={content?.before} actions={actions} />
				</Content>
			) : null}

			<Content>
				<List
					className="asteria-component__onboarding-list"
					size="lg"
					applyTypographySizes={false}
				>
					<IntegrationGroup
						className="asteria-component__list__group--erp"
						type="erp"
						loading={loading}
						onAction={onAction}
						onSubmit={onSubmit}
					/>
				</List>
			</Content>

			{content?.before?.length ? (
				<Content>
					<Contenter content={content?.between} actions={actions} />
				</Content>
			) : null}

			{hasBankFeature ? (
				<Content>
					<List
						className="asteria-component__onboarding-list"
						size="lg"
						applyTypographySizes={false}
					>
						<IntegrationGroup
							className="asteria-component__list__group--bank"
							type="bank"
							loading={loading}
							onAction={onAction}
							onSubmit={onSubmit}
						/>
					</List>
				</Content>
			) : null}

			{content?.after?.length ? (
				<Content>
					<Contenter content={content?.after} actions={actions} />
				</Content>
			) : null}
			{showFooter ? (
				<Footer>
					<FooterSection position="last">
						<Button
							variant="secondary"
							label={TranslationService.get([
								'action.close',
								'button.close',
								'onboarding.list.action',
								'onboarding.list.action.close',
							])}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			) : null}
		</Wrapper>
	);
};

IntegrationList.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	loading: PropTypes.bool,
	showFooter: PropTypes.bool,
	showHeader: PropTypes.bool,

	content: PropTypes.shape({
		before: PropTypes.arrayOf(PropTypes.object),
		between: PropTypes.arrayOf(PropTypes.object),
		after: PropTypes.arrayOf(PropTypes.object),
	}),
};

const IntegrationListModalContent = React.memo(
	function IntegrationListModalContent({
		onClose,
		onAction,
		onSubmit,
		content,
	}) {
		const hasBankFeature = useFeature('bank-integrations');

		const actions = React.useMemo(
			() => ({ onAction, onSubmit }),
			[onAction, onSubmit],
		);

		return (
			<Wrapper
				className="asteria-component__wrapper--onboarding-list"
				scroll
			>
				<Header onClose={onClose}>
					{TranslationService.get('integrations.list.header')}
				</Header>

				<Content>
					<div className="flex flex-col gap-8">
						<Contenter
							content={content?.before}
							actions={actions}
						/>

						<List
							className="asteria-component__onboarding-list"
							size="lg"
							applyTypographySizes={false}
						>
							<IntegrationGroup
								className="asteria-component__list__group--erp"
								type="erp"
								onAction={onAction}
								onSubmit={onSubmit}
							/>
						</List>

						<Contenter
							content={content?.between}
							actions={actions}
						/>

						{hasBankFeature ? (
							<List
								className="asteria-component__onboarding-list"
								size="lg"
								applyTypographySizes={false}
							>
								<IntegrationGroup
									className="asteria-component__list__group--bank"
									type="bank"
									onAction={onAction}
									onSubmit={onSubmit}
								/>
							</List>
						) : null}

						<Contenter content={content?.after} actions={actions} />
					</div>
				</Content>

				<Footer>
					<FooterSection position="last">
						<Button
							variant="secondary"
							label={TranslationService.get([
								'action.close',
								'button.close',
								'onboarding.list.action',
								'onboarding.list.action.close',
								'onboarding.modal.action',
								'onboarding.modal.action.close',
								'onboarding.modal.list.action',
								'onboarding.modal.list.action.close',
							])}
							onClick={onClose}
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		);
	},
);

IntegrationListModalContent.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	content: PropTypes.shape({
		before: PropTypes.arrayOf(PropTypes.object),
		between: PropTypes.arrayOf(PropTypes.object),
		after: PropTypes.arrayOf(PropTypes.object),
	}),
};

const IntegrationListModal = (props) => {
	const { open, onClose, onAction, onSubmit, content } = props;

	return (
		<Modal open={open} onClose={onClose} size="sm">
			<IntegrationListModalContent
				onClose={onClose}
				onAction={onAction}
				onSubmit={onSubmit}
				content={content}
			/>
		</Modal>
	);
};

IntegrationListModal.displayName = 'IntegrationListModal';

IntegrationListModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,

	content: PropTypes.shape({
		before: PropTypes.arrayOf(PropTypes.object),
		after: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default IntegrationList;

export { IntegrationListModal };
