export const normalizeProbability = (
	probability = 1,
	variants = [0.2, 0.4, 0.6, 0.8, 1],
) => {
	return [...variants]
		.reverse()
		.find((value) => value - Number.EPSILON - probability <= 0);
};

export const getProbabilityLevel = ({ value = null, variants = null }) => {
	if (value === null || variants === null) {
		return null;
	}

	return variants.filter((x) => x - Number.EPSILON <= value).length;
};
