import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

import * as utils from '../../../../utils';
import { getPath } from '../../../../utils/form/utils';
import { useTranslationOptions } from '../hooks';

/**
 * @typedef { import('../../../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('./types').Props } Props
 */

const INPUT_FORMAT = { valueAsNumber: true };

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'> & Pick<Props, 'type' | 'category' | 'tag'>> } */
const RowInput = React.memo(function RowInput(props) {
	const {
		type,
		category,
		tag,
		translationOptions: $translationOptions,
	} = props;

	const { getValues, setValue, reset } = useFormContext();

	const {
		source,
		form,
		options: translationOptions,
	} = useTranslationOptions($translationOptions, { type, category, tag });

	const onValueChange = React.useCallback(
		({ value }) => {
			const changes = utils.form.tag.setValue(
				{ type, category, tag, value, recalculate: true },
				{ getValues, setValue, reset },
			);

			utils.form.changes.apply(changes, { getValues, setValue, reset });
		},
		[category, getValues, reset, setValue, tag, type],
	);

	return (
		<Input
			name={getPath({ type, category, tag, field: 'total' }).join('.')}
			type="number"
			format={INPUT_FORMAT}
			size="sm"
			value={0}
			defaultValue={0}
			min={0}
			onChange={onValueChange}
			helpText={
				!!source?.total &&
				source?.total !== form?.total &&
				TranslationService.getV2(
					['card.extra.section.hint'],
					translationOptions,
				)
			}
		/>
	);
});

RowInput.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	translationOptions: PropTypes.object,
};

export default RowInput;
