import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual, merge, set } from 'lodash-es';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Wizard from '@asteria/component-wizard';

import { cn } from '@asteria/utils-funcs/classes';

import WelcomeContext from '../../context';
import * as WelcomeSelectors from '../../selectors';

import WizardStep from './Step';

import './styles.scss';

const WelcomeWizard = (props) => {
	const { className, onAction, startPage, name, wizard, ...args } = props;

	const formRef = React.useRef(null);

	const { wizard: ctxWizard } = React.useContext(WelcomeContext);
	const config = ctxWizard?.[wizard];
	const steps = (config?.steps ?? []).length;

	const step = useSelector((store) => WelcomeSelectors.progress(store, name));

	const defaultValues = useSelector(
		(store) => {
			const companyData = WelcomeSelectors.data(store, name);

			return Object.entries(
				merge(
					{},
					AsteriaCore.utils.flatObject(config?.defaultValues ?? {}),
					AsteriaCore.utils.flatObject(companyData ?? {}),
				),
			).reduce((acc, [key, value]) => set(acc, key, value), {});
		},
		(a, b) => isEqual(a, b),
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'wizard:next') {
				formRef.current = data?.form;
			}

			if (['wizard:next'].includes(action)) {
				const step = data?.step ?? null;

				if (step !== null) {
					onAction?.('welcome:step', { name: name, step: step + 1 });
				}

				return onAction?.(action, { name: name, data: data });
			}

			if (action === 'wizard:done') {
				onAction?.('welcome:step', { name: name, step: steps + 1 });

				return onAction?.(action, {
					name: name,
					data: data ?? formRef.current,
				});
			}

			return onAction?.(action, data);
		},
		[name, onAction, steps],
	);

	return (
		<Wizard
			className={cn('asteria-view__welcome__wizard', className)}
			progress
			arrows
			onAction={handleAction}
			defaultValues={defaultValues}
			initialStep={step}
			type="welcome"
			hasStartPage
			showCompleted
			hideDoneButton
			{...args}
		>
			{startPage ?? (
				<WizardStep
					name={wizard}
					step="start"
					onAction={handleAction}
				/>
			)}
			{[...new Array(steps).fill().map((_, index) => index), 'done'].map(
				(value) => (
					<WizardStep
						key={value}
						step={value}
						onAction={handleAction}
						name={wizard}
					/>
				),
			)}
		</Wizard>
	);
};

WelcomeWizard.displayName = 'Wizard';

WelcomeWizard.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	startPage: PropTypes.element,
	name: PropTypes.string,
	wizard: PropTypes.string,
};

export default WelcomeWizard;
