import React from 'react';

import PropTypes from 'prop-types';

import GraphContext from '../../../logic/graphContext';
import AccountBalanceTooltip from '../../tooltip/account-balance';

import { useLegends, useLineGraphData } from './hooks';
import LineGraph from './view';

const Provider = React.memo((props) => {
	const {
		className,
		dates,
		showYAxis,
		showLines,
		absolute,
		showLegends,
		render,
	} = props;

	const { labels, max, min, data, title } = useLineGraphData({
		dates: dates,
		absolute: absolute,
	});

	const legends = useLegends({ dates: dates });

	const ctx = React.useMemo(() => {
		return {
			TooltipComponent: AccountBalanceTooltip,
		};
	}, []);

	const width = 100 / ((data?.length || 3) - 2);

	return (
		<GraphContext.Provider value={ctx}>
			<LineGraph
				className={className}
				labels={labels}
				max={max}
				min={min}
				data={data}
				title={title}
				showYAxis={showYAxis}
				showLines={showLines}
				legends={legends}
				showLegends={showLegends}
				width={width}
				render={render}
			/>
		</GraphContext.Provider>
	);
});

Provider.displayName = 'LineGraphProvider';

Provider.propTypes = {
	className: PropTypes.string,

	dates: PropTypes.arrayOf(PropTypes.string),

	showYAxis: PropTypes.bool,
	showLines: PropTypes.bool,
	showLegends: PropTypes.bool,

	absolute: PropTypes.bool,

	render: PropTypes.func,
};

export default Provider;
