import { createSelector } from '@reduxjs/toolkit';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { selector as configSelector } from '@asteria/utils-hooks/useConfig';

export const key = createSelector(
	(store) => store?.integrations?.navigation?.key,
	(store) => configSelector(store, { key: 'integrations' }),
	(_, options) => options ?? {},
	(value, integrations, { type, key }) => {
		if (key) {
			return key;
		}

		if (type) {
			if (FeatureService.isActive('onboarding-single-integration')) {
				const isERPActive =
					type === 'erp' &&
					FeatureService.isActive(
						'onboarding-single-erp-integration',
					);

				const isBankActive =
					type === 'bank' &&
					FeatureService.isActive(
						'onboarding-single-bank-integration',
					);

				if (isERPActive || isBankActive) {
					const available = integrations.filter(
						(object) =>
							object?.type === type &&
							(!object?.feature ||
								FeatureService.isActive(object?.feature)),
					);

					if (available.length) {
						return available[0].key;
					}
				}
			}
		}

		return value ?? null;
	},
);

export const _id = createSelector(
	(store) => store?.integrations?.navigation?._id,
	(value) => value ?? null,
);
