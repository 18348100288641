import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import Progress from '@asteria/component-core/progress';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import WizardPagination from './Pagination';
import WizardStep from './Step';
import Context from './context';

import './styles.scss';

const WizardPropTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node,

	onAction: PropTypes.func,
	defaultValues: PropTypes.object,

	hideFooter: PropTypes.bool,
	arrows: PropTypes.bool,
	pagination: PropTypes.oneOf(['text', 'dot', 'full']),
	type: PropTypes.string,

	prefix: PropTypes.node,
	postfix: PropTypes.node,
	skipGo: PropTypes.bool,
	validateStep: PropTypes.bool,
	progress: PropTypes.bool,

	initialStep: PropTypes.number,
	showCompleted: PropTypes.bool,
	hideDoneButton: PropTypes.bool,
	hideCancelButton: PropTypes.bool,

	forceHideCancelButton: PropTypes.bool,
};

const WizardFooter = (props) => {
	const {
		hideFooter,
		hideDoneButton,
		hideCancelButton,
		forceHideCancelButton,
		type,
	} = props;

	const {
		back,
		cancel,
		next,
		done,
		isBackVisible,
		isNextDisabled,
		isNextVisible,
	} = React.useContext(Context);

	const analyticsPrefix = [`wizard`, type].filter(Boolean).join('.');

	if (hideFooter) {
		return null;
	}

	return (
		<Footer>
			<FooterSection position="first">
				{(!isBackVisible || !hideCancelButton) &&
				!forceHideCancelButton ? (
					<Button
						label={TranslationService.get([
							'button.cancel',
							'action.cancel',
							`wizard.cancel`,
							`wizard.${type}.cancel`,
						])}
						variant="link"
						onClick={cancel}
						analyticsKey={`${analyticsPrefix}.cancel`}
					/>
				) : null}

				{isBackVisible ? (
					<Button
						label={TranslationService.get([
							'button.back',
							'action.back',
							`wizard.back`,
							`wizard.${type}.back`,
						])}
						variant="tertiary"
						onClick={back}
						analyticsKey={`${analyticsPrefix}.back`}
					/>
				) : null}
			</FooterSection>
			<FooterSection position="last">
				{isNextVisible ? (
					<Button
						label={TranslationService.get([
							'button.continue',
							'action.continue',
							`wizard.continue`,
							`wizard.${type}.continue`,
						])}
						variant="secondary"
						disabled={isNextDisabled}
						onClick={next}
						analyticsKey={`${analyticsPrefix}.continue`}
					/>
				) : null}

				{!isNextVisible || !hideDoneButton ? (
					<Button
						label={TranslationService.get([
							'button.done',
							'action.done',
							`wizard.done`,
							`wizard.${type}.done`,
						])}
						variant="primary"
						onClick={done}
						analyticsKey={`${analyticsPrefix}.done`}
					/>
				) : null}
			</FooterSection>
		</Footer>
	);
};

WizardFooter.displayName = 'WizardFooter';
WizardFooter.propTypes = { ...WizardPropTypes };

const WizardProgress = (props) => {
	const { progress, arrows, hasStartPage, showCompleted, type } = props;

	const {
		back,
		next,
		isBackVisible,
		isNextDisabled,
		isNextVisible,

		step,
		count,
	} = React.useContext(Context);

	const analyticsPrefix = [`wizard`, type].filter(Boolean).join('.');

	const progressStep = step + (hasStartPage ? 0 : 1);
	const progressStepCount = count - (hasStartPage ? 1 : 0);

	if (!progress) {
		return null;
	}

	return (
		<Group
			className={cn('asteria-component__wizard-progress', {
				'asteria-component__wizard-progress--variant-arrows': arrows,
			})}
		>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="space-between"
			>
				{arrows ? (
					<Button
						icon="chevron-left"
						size="sm"
						onClick={back}
						disabled={!isBackVisible}
						analyticsKey={`${analyticsPrefix}.progress.arrow.left`}
					/>
				) : null}
				<Progress
					steps={progressStepCount}
					progress={(progressStep / progressStepCount) * 100}
				/>
				{arrows ? (
					showCompleted && step === count - 1 ? (
						<div className="asteria-component__wizard-progress__icon">
							<Icon icon="check" />
						</div>
					) : (
						<Button
							icon="chevron-right"
							size="sm"
							onClick={next}
							disabled={!isNextVisible || isNextDisabled}
							analyticsKey={`${analyticsPrefix}.progress.arrow.right`}
						/>
					)
				) : null}
			</Group>
			<Text size="xs">
				{TranslationService.get(
					['wizard.pagination'],
					'{{ step }} / {{ count }}',
					{
						step: progressStep,
						count: progressStepCount,
					},
				)}
			</Text>
		</Group>
	);
};

WizardProgress.displayName = 'WizardProgress';
WizardProgress.propTypes = { ...WizardPropTypes };

const WizardForm = (props) => {
	const {
		className,
		title,
		children,
		onAction,
		pagination,
		type,
		prefix,
		postfix,
		arrows,
		formRef,
		hasStartPage,
		skipGo,
		validateStep,

		initialStep,

		progress,
	} = props;

	const { isValid } = useFormState();
	const { getValues } = useFormContext();

	const count = React.Children.count(children);

	const [step, setStep] = React.useState(initialStep ?? 0);
	const lastStep = React.useRef(step);

	lastStep.current = Math.max(lastStep.current, step);

	const handleCancel = React.useCallback(
		() => onAction?.('wizard:cancel', { step: step, form: getValues() }),
		[getValues, onAction, step],
	);

	const handleBack = React.useCallback(() => {
		setStep((value) => value - 1);

		return onAction?.('wizard:back', { step: step, form: getValues() });
	}, [getValues, onAction, step]);

	const handleNext = React.useCallback(() => {
		setStep((value) => value + 1);

		return onAction?.('wizard:next', { step: step, form: getValues() });
	}, [getValues, onAction, step]);

	const handleDone = React.useCallback(() => {
		formRef?.current?.requestSubmit?.();
	}, [formRef]);

	const handleGo = React.useCallback(
		(value) => {
			if (validateStep) {
				if (value > lastStep.current) {
					return;
				}
			}

			setStep(value);
			return onAction?.('wizard:go', value);
		},
		[onAction, validateStep],
	);

	const isBackVisible = step !== 0 && !(hasStartPage && step === 1);
	const isNextVisible = step !== count - 1;

	const isNextDisabled = !isValid && lastStep.current === step;

	const ctx = React.useMemo(
		() => ({
			type: type,
			count: count,
			cancel: handleCancel,
			back: handleBack,
			next: handleNext,
			done: handleDone,
			go: skipGo ? null : handleGo,
			step: step,
			maxOpenStep: validateStep ? lastStep.current : count,

			isBackVisible: isBackVisible,
			isNextVisible: isNextVisible,
			isNextDisabled: isNextDisabled,
		}),
		[
			count,
			handleBack,
			handleCancel,
			handleDone,
			handleGo,
			handleNext,
			isBackVisible,
			isNextDisabled,
			isNextVisible,
			skipGo,
			step,
			type,
			validateStep,
		],
	);

	const child = React.Children.toArray(children)?.[step];

	const analyticsPrefix = [`wizard`, type].filter(Boolean).join('.');

	return (
		<Context.Provider value={ctx}>
			<div
				className={cn(
					'asteria-component__wizard__wrapper',
					{
						[`asteria-component__wizard__wrapper--type-${type}`]:
							type,
						'asteria-component__wizard__wrapper--is-start-page':
							hasStartPage && step === 0,
					},
					className,
				)}
			>
				{prefix}
				<Wrapper
					className={cn(
						'asteria-component__wizard',
						{
							[`asteria-component__wizard--type-${type}`]: type,
						},
						className,
					)}
				>
					{title ? (
						<Header>
							{AsteriaCore.utils.isObject(title) &&
							React.isValidElement(title)
								? title
								: TranslationService.get(title, title)}
						</Header>
					) : null}
					<Content
						className="asteria-component__wizard__content"
						scroll
					>
						{!progress && arrows ? (
							<Button
								icon="chevron-left"
								onClick={handleBack}
								disabled={!isBackVisible}
								analyticsKey={`${analyticsPrefix}.arrow.left`}
							/>
						) : null}

						<div className="asteria-component__wizard__inner">
							<WizardProgress {...props} />

							{child}
							<WizardPagination
								step={step}
								count={count}
								type={pagination}
							/>
						</div>

						{!progress && arrows ? (
							<Button
								icon="chevron-right"
								onClick={handleNext}
								disabled={!isNextVisible || isNextDisabled}
								analyticsKey={`${analyticsPrefix}.arrow.right`}
							/>
						) : null}
					</Content>
					<WizardFooter {...props} />
				</Wrapper>
				{postfix}
			</div>
		</Context.Provider>
	);
};

WizardForm.displayName = 'WizardForm';

WizardForm.propTypes = {
	...WizardPropTypes,
	formRef: PropTypes.any,
};

WizardForm.defaultProps = {
	hideFooter: false,
	navigation: 'full',
};

const Wizard = (props) => {
	const { onAction, defaultValues } = props;

	const ref = React.useRef(null);

	const handleSubmit = React.useCallback(
		(form) => onAction?.('wizard:done', form),
		[onAction],
	);

	return (
		<Form onSubmit={handleSubmit} defaultValues={defaultValues} ref={ref}>
			<WizardForm {...props} formRef={ref} />
		</Form>
	);
};

Wizard.displayName = 'Wizard';

Wizard.propTypes = WizardPropTypes;

Wizard.defaultProps = {
	hideFooter: false,
	navigation: 'full',
};

export default React.memo(Wizard);
export { WizardStep, WizardPagination, Context };
