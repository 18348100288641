import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import TagSelectorItemLabel from './label';
import TagSelectorItemPostfix from './postfix';

import './styles.scss';

const TagSelectorItem = (props) => {
	const {
		as: Component,

		object,
		active,
		selected,

		onClick,
		onEdit,

		value,

		showProbability = false,
	} = props;

	return (
		<Component
			className={cn(
				'asteria-component__selector-item',
				'asteria-component__forecaster-selector-item',
				'asteria--variant-category',
				{
					'asteria--state-check': selected,
					'asteria--state-probability': showProbability,
					'asteria--state-editable':
						object?.category?.name === '$custom',
				},
			)}
			active={active}
			onClick={onClick}
			value={value}
			postfix={<TagSelectorItemPostfix object={object} onEdit={onEdit} />}
		>
			<TagSelectorItemLabel object={object} />
		</Component>
	);
};

TagSelectorItem.displayName = 'TagSelectorItem';
TagSelectorItem.propTypes = {
	object: PropTypes.object,
	active: PropTypes.bool,
	selected: PropTypes.bool,

	onClick: PropTypes.func,
	onEdit: PropTypes.func,

	showProbability: PropTypes.bool,

	as: PropTypes.node,
	value: PropTypes.any,
};

export default TagSelectorItem;
export { TagSelectorItemLabel, TagSelectorItemPostfix };
