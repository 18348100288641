import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import { cn } from '@asteria/utils-funcs/classes';

import { useModalActions } from '../../modal-collection';

import TermsAndConditions from './content';

import './styles.scss';

const TermsAndConditionsModal = React.memo((props) => {
	const { close } = useModalActions();

	return (
		<Modal
			className={cn('asteria-view__auth-modal', 'asteria--variant-terms')}
			onClose={close}
			size="sm"
		>
			<TermsAndConditions {...props} />
		</Modal>
	);
});

TermsAndConditionsModal.displayName = 'TermsAndConditionsModal';

TermsAndConditionsModal.propTypes = {
	className: PropTypes.string,
};

export default TermsAndConditionsModal;
