import { useMemo } from 'react';

import { parseISO } from 'date-fns';

export function useCurrentDate(value) {
	return useMemo(() => {
		let date = null;

		if (value?.startDate) {
			date = parseISO(value?.startDate);
		}

		if (Array.isArray(value)) {
			date = parseISO(value[0]);
		}

		if (typeof value === 'string') {
			date = parseISO(value);
		}

		if (!date) {
			return new Date();
		}

		return date;
	}, [value]);
}
