import React from 'react';

import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Slider from '@asteria/component-core/slider';

import { cn } from '@asteria/utils-funcs/classes';

import SearchChip from '../chip';
import { useBackspaceBehavior } from '../utils/hooks';
import * as selectors from '../utils/selectors';

import '../styles.scss';

const SearchInputContent = React.memo((props) => {
	const { onAction } = props;

	const searchValue = useWatch({ name: 'search-value' });

	useBackspaceBehavior({ onAction: onAction });

	const filters = useSelector(selectors.active);

	const resetFilters = React.useCallback(() => {
		onAction?.('resetFilter');
	}, [onAction]);

	return (
		<>
			{filters.length ? (
				<Slider noScroll>
					{filters.map((object) => (
						<SearchChip
							key={[object?.type, object?.id].join('-')}
							{...object}
							onAction={onAction}
							size="sm"
							dismiss
						/>
					))}
				</Slider>
			) : null}
			<Button
				className={cn('asteria-component__search-reset', {
					'asteria--state-has-filters':
						filters?.length || searchValue,
				})}
				size="xs"
				icon="close"
				onClick={resetFilters}
			/>
		</>
	);
});

SearchInputContent.displayName = 'SearchInputContent';
SearchInputContent.propTypes = { onAction: PropTypes.func };

export default SearchInputContent;
