import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { IntegrationsTeaser } from '@asteria/component-integrations-v2';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Actions from '../../components/actions';
import Placeholder from '../../components/placeholder';

import './styles.scss';

const SectionActions = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const loading = useSelector(ForecasterStore.selectors.actions.loading);

	const onFreeNavigate = React.useCallback(
		() => onAction?.('navigate', '/forecaster/category'),
		[onAction],
	);

	const forecasterActions = useSelector((store) =>
		ForecasterStore.selectors.actions.data(store, { type: 'CATEGORY' }),
	);

	const actions = React.useMemo(
		() =>
			forecasterActions.map((object) => ({
				...object,
				data: {
					type: object?.extra?.type,
					category: object?.tag?.category?.name,
					tag: object?.tag?.name,
					id: object?._id ?? object?.id,
				},
			})),
		[forecasterActions],
	);

	if (loading) {
		return null;
	}

	if (!actions || !actions.length) {
		return (
			<div
				className={cn(
					'asteria-component__forecaster-page-section',
					'asteria--type-actions',
				)}
			>
				<TextGroup>
					<Title size="xs">
						{TranslationService.get([
							'forecaster.page.home.actions.title',
							'forecaster.page.home.actions.empty.title',
						])}
					</Title>
					<Text size="sm">
						{TranslationService.get([
							'forecaster.page.home.actions.text',
							'forecaster.page.home.actions.empty.text',
						])}
					</Text>
				</TextGroup>

				<IntegrationsTeaser onAction={onAction} onSubmit={onSubmit} />
				<Placeholder onAction={onAction} />
			</div>
		);
	}

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-actions',
			)}
		>
			<Group
				direction="horizontal"
				verticalAlign="top"
				horizontalAlign="space-between"
			>
				<TextGroup>
					<Title size="xs">
						{TranslationService.get([
							'forecaster.page.home.actions.title',
						])}
					</Title>
					<Text size="sm">
						{TranslationService.get([
							'forecaster.page.home.actions.text',
						])}
					</Text>
				</TextGroup>
				<FeatureFlag feature="forecaster-home-actions-link">
					<Button
						variant="secondary"
						label={TranslationService.get([
							'forecaster.page.home.actions.action.label',
						])}
						size="sm"
						onClick={onFreeNavigate}
					/>
				</FeatureFlag>
			</Group>
			<IntegrationsTeaser onAction={onAction} onSubmit={onSubmit} />

			<Actions onAction={onAction} actions={actions} />
		</div>
	);
});

SectionActions.displayName = 'SectionActions';
SectionActions.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionActions;
