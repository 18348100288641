import React from 'react';

import PropTypes from 'prop-types';

import NavigationButton from './NavigationButton';
import NavigationDropdown from './NavigationDropdown';

import './styles.scss';

const NavigationItem = (props) => {
	const { route } = props;

	if (route?.slug === 'notifications' || route?.slug === 'menu') {
		return null;
	}

	if (route?.type === 'dropdown') {
		return <NavigationDropdown {...props} />;
	}

	return <NavigationButton {...props} />;
};

NavigationItem.displayName = 'NavigationItem';

NavigationItem.propTypes = {
	variant: PropTypes.oneOf(['default', 'stacked']),
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	route: PropTypes.object,
	active: PropTypes.bool,
};

export default NavigationItem;
