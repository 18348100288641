import { useLayoutEffect } from 'react';

import { useDispatch, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';

import * as TourStore from '@asteria/datalayer/stores/tour';

import useConfig from './useConfig';

const selectors = {
	isTourShown: createSelector(
		(store) => store?.app?.user?.settings?.flags?.tours ?? [],
		(_, ID) => ID,
		(tours, ID) => tours.includes(ID),
	),
};

/**
 *
 * @param { string | string[] } [$parts]
 */
export function useTourKey($parts) {
	const { pathname } = useLocation();

	let parts = (
		$parts ? [].concat($parts) : pathname.split('/').filter(Boolean)
	).reverse();

	if (!parts.length) {
		parts = ['cashflow'];
	}

	const config = useConfig(['widget.tour']);

	for (let index = 0; index < parts.length; index += 1) {
		const path = parts.slice(index).reverse().join(':');

		if (config?.[path]) {
			if (!config?.[path]?.length) {
				return null;
			}

			return path;
		}
	}

	return null;
}

/**
 *
 * @param { string | string[] } [parts]
 * @param {{ skip?: boolean, zIndex?: number }} [options]
 */
export function useTourShowAction(parts, options = {}) {
	const dispatch = useDispatch();
	const store = useStore();

	const key = useTourKey(parts);

	useLayoutEffect(() => {
		if (key && !options?.skip) {
			const isTourShown = selectors.isTourShown(store.getState(), key);

			if (!isTourShown) {
				dispatch(
					TourStore.show({ type: key, zIndex: options?.zIndex }),
				);
			}
		}
	}, [dispatch, key, options?.skip, options?.zIndex, store]);
}
