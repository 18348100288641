import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import * as FormatUtils from '@asteria/utils-funcs/format';

import SearchSelector, { SearchSelectorItem } from './base';

import './styles.scss';

const CategorySelectorItem = React.memo((props) => {
	const { onAction, object } = props;

	const ID = object?._id ?? object?.id;

	const onClick = React.useCallback(() => {
		Analytics.event('search.selector.toggle', { type: 'tag', id: ID });

		return onAction?.('toggleTag', { type: 'tag', id: ID, item: object });
	}, [ID, object, onAction]);

	const selected = useSelector(
		(store) => AppStore.selectors.filters(store, { id: ID }).length > 0,
	);

	return (
		<SearchSelectorItem active={selected} onClick={onClick}>
			<Chip
				label={object?.label}
				variant="simple"
				colors={
					object?.color ??
					FormatUtils.replace([
						object?.category?.name,
						object?.name,
					]).join('-')
				}
			/>
		</SearchSelectorItem>
	);
});

CategorySelectorItem.displayName = 'CategorySelectorItem';
CategorySelectorItem.propTypes = {
	onAction: PropTypes.func,
	object: PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
		category: PropTypes.shape({ name: PropTypes.string }),
		color: PropTypes.string,
		id: PropTypes.string,
		_id: PropTypes.string,
	}),
};

const CategorySelector = React.memo((props) => {
	const { className, onAction } = props;

	const count = useSelector(
		(store) => AppStore.selectors.filters(store, { type: 'tag' }).length,
	);

	const objects = useSelector(AppStore.selectors.tags).sort(
		({ label: a }, { label: b }) => a?.localeCompare?.(b),
	);

	if (objects.length < 2) {
		return null;
	}

	return (
		<SearchSelector
			className={cn('asteria--variant-category', className)}
			label={TranslationService.get(`selector.categories`)}
			onAction={onAction}
			type="tag"
			count={count}
		>
			{objects.map((object) => (
				<CategorySelectorItem
					key={object?._id ?? object?.id}
					object={object}
					onAction={onAction}
				/>
			))}
		</SearchSelector>
	);
});

CategorySelector.displayName = 'CategorySelector';
CategorySelector.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default CategorySelector;
