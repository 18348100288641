import { useEffect, useRef } from 'react';

import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

/**
 * @template T
 * @param { T } data
 * @returns { import('react').RefObject<T> }
 */
export function useAnalyticsData($data) {
	const data = useDeepMemo(() => $data, [$data]);

	const ref = useRef(data);

	useEffect(() => {
		ref.current = data;
	}, [data]);

	return ref;
}
