import React from 'react';

import PropTypes from 'prop-types';

import Actions from '../../components/Actions';
import WelcomeFeedback from '../../components/Feedback';
import Placeholder from '../../components/Placeholder';

const OverviewPage = (props) => {
	const { onAction } = props;

	return [
		<WelcomeFeedback key="feedback" onAction={onAction} />,
		<Placeholder key="placeholder" onAction={onAction} />,
		<Actions key="actions" onAction={onAction} />,
	];
};

OverviewPage.displayName = 'OverviewPage';

OverviewPage.propTypes = { onAction: PropTypes.func };

export default OverviewPage;
