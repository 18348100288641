import slice from './slice';

export * as selectors from './selectors';
export { slice };

export const navigation = {
	go: slice.actions.navigationGo,
};

export const credit = {
	settings: {
		toggle: slice.actions.toggleCreditSettings,
		set: slice.actions.setCreditSettings,
	},
};

export default slice.reducer;
