import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { setGraphLayout } from '@asteria/datalayer/stores/app';
import { setGraphOptions } from '@asteria/datalayer/stores/graph';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const selectors = {
	layout: createSelector(
		(store) => store?.app?.user?.settings?.layout?.graph?.layout,
		(value) => value ?? 'grouped',
	),
	options: createSelector(
		(store) => store?.graph?.options,
		(value) => value ?? {},
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const GraphOptions = React.memo((props) => {
	const { size, className, onAction } = props;

	const dispatch = useDispatch();

	const hasCashflowSettingsModal = useFeature(
		'cashflow-graph-settings-modal',
	);

	const layout = useSelector(selectors.layout, (a, b) => isEqual(a, b));
	const options = useSelector(selectors.options, (a, b) => isEqual(a, b));

	const ToggleProps = React.useMemo(
		() => ({
			icon: 'cog',
			tooltip: {
				tooltip: TranslationService.get(
					'graph.options.dropdown.tooltip',
				),
				variant: 'alt',
			},
			size: 'md',
		}),
		[],
	);

	const showGroupedGraph = React.useCallback(() => {
		dispatch(setGraphLayout('grouped'));
		onAction?.('updateUserSettings', {
			layout: {
				graph: {
					layout: 'grouped',
				},
			},
		});
	}, [dispatch, onAction]);

	const showCombinedGraph = React.useCallback(() => {
		dispatch(setGraphLayout('stacked'));
		onAction?.('updateUserSettings', {
			layout: {
				graph: {
					layout: 'stacked',
				},
			},
		});
	}, [dispatch, onAction]);

	const showBarGraph = React.useCallback(() => {
		dispatch(setGraphOptions({ barGraph: true }));
	}, [dispatch]);

	const hideBarGraph = React.useCallback(() => {
		dispatch(setGraphOptions({ barGraph: false }));
	}, [dispatch]);

	const showLineGraph = React.useCallback(() => {
		dispatch(setGraphOptions({ lineGraph: true }));
	}, [dispatch]);

	const hideLineGraph = React.useCallback(() => {
		dispatch(setGraphOptions({ lineGraph: false }));
	}, [dispatch]);

	const openSettings = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.CashflowGraphSettings,
			}),
		);
	}, [dispatch]);

	if (hasCashflowSettingsModal) {
		return (
			<Button
				icon="cog"
				tooltip={{
					tooltip: TranslationService.get(
						'graph.options.dropdown.tooltip',
					),
					variant: 'alt',
				}}
				onClick={openSettings}
			/>
		);
	}

	return (
		<Dropdown
			toggle={ToggleProps}
			size={size}
			className={cn('asteria-component__graph__options', className)}
			title={TranslationService.get([
				'graph.options.dropdown',
				'graph.options.dropdown.title',
			])}
			analyticsKey="graph.options"
		>
			<FeatureFlag feature="cashflow-graph-options-combined">
				{layout === 'grouped' ? (
					<DropdownItem
						onClick={showCombinedGraph}
						analyticsKey="graph.options.item"
						size={size}
					>
						{TranslationService.get([
							'graph.options.combined.show',
						])}
					</DropdownItem>
				) : null}

				{layout === 'stacked' ? (
					<DropdownItem
						onClick={showGroupedGraph}
						analyticsKey="graph.options.item"
						size={size}
					>
						{TranslationService.get([
							'graph.options.combined.hide',
						])}
					</DropdownItem>
				) : null}
			</FeatureFlag>

			{options?.lineGraph && options?.barGraph ? (
				<DropdownItem
					onClick={hideLineGraph}
					analyticsKey="graph.options.item"
				>
					{TranslationService.get(['graph.options.line.hide'])}
				</DropdownItem>
			) : null}

			{!options?.lineGraph ? (
				<DropdownItem
					onClick={showLineGraph}
					analyticsKey="graph.options.item"
				>
					{TranslationService.get(['graph.options.line.show'])}
				</DropdownItem>
			) : null}

			{options?.barGraph && options?.lineGraph ? (
				<DropdownItem
					onClick={hideBarGraph}
					analyticsKey="graph.options.item"
				>
					{TranslationService.get(['graph.options.bars.hide'])}
				</DropdownItem>
			) : null}

			{!options?.barGraph ? (
				<DropdownItem
					onClick={showBarGraph}
					analyticsKey="graph.options.item"
				>
					{TranslationService.get(['graph.options.bars.show'])}
				</DropdownItem>
			) : null}
		</Dropdown>
	);
});

GraphOptions.displayName = 'GraphOptions';

GraphOptions.propTypes = {
	size: SizeProp(),
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default GraphOptions;
