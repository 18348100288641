import React from 'react';
import ReactDOM from 'react-dom';

import '@asteria/utils-cache';
import * as Configuration from '@asteria/utils-configuration';
import '@asteria/utils-graphql';

import App from './App';

import '@asteria/themes/base/main.scss';

import '@asteria/themes/asteria/main.scss';

import './index.scss';

Configuration.apply();

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
	require('./devconfig');
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);
