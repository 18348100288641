import React from 'react';

import PropTypes from 'prop-types';

import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';
import { WizardStep } from '@asteria/component-wizard';

import Actions from '../../components/Actions';
import WelcomeFeedback from '../../components/Feedback';
import Loader from '../../components/Loader';
import Placeholder from '../../components/Placeholder';
import Wizard from '../../components/Wizard';

const StartingPage = (props) => {
	const { onAction } = props;

	return (
		<WizardStep className="asteria-view__welcome__wizard__step--type-linear">
			<FeatureFlag feature="welcome-linear-wizard-placeholder">
				<Placeholder onAction={onAction} />
			</FeatureFlag>
			<Actions onAction={onAction} showTitle={false} />
		</WizardStep>
	);
};

StartingPage.displayName = 'LandingPage';
StartingPage.propTypes = { onAction: PropTypes.func };

const LinearPage = (props) => {
	const { onAction } = props;

	const hasLoaderFeature = useFeature('welcome-linear-loader');

	const [showLoader, setShowLoader] = React.useState(false);

	const handleAction = React.useCallback(
		(action, data) => {
			if (hasLoaderFeature) {
				if (action === 'wizard:next') {
					setShowLoader(true);
				}

				if (action === 'wizard:back' && data?.step === 1) {
					setShowLoader(false);
				}
			}

			return onAction?.(action, data);
		},
		[hasLoaderFeature, onAction],
	);

	return [
		<WelcomeFeedback key="feedback" onAction={onAction} />,
		<FeatureFlag key="placeholder" feature="welcome-linear-placeholder">
			<Placeholder onAction={handleAction} />
		</FeatureFlag>,
		hasLoaderFeature && showLoader ? (
			<Loader key="loader" variant="category" />
		) : null,
		<Wizard
			key="wizard"
			onAction={handleAction}
			startPage={<StartingPage onAction={handleAction} />}
			initialStep={0}
			forceHideCancelButton
		/>,
	];
};

LinearPage.displayName = 'LinearPage';

LinearPage.propTypes = { onAction: PropTypes.func };

export default LinearPage;
