import { useCallback } from 'react';

function useCombinedRefs(...refs) {
	return useCallback(
		(node) => {
			for (const ref of refs) {
				if (!ref) {
					continue;
				}

				if (typeof ref === 'function') {
					ref(node);
				} else {
					ref.current = node;
				}
			}
		},
		[refs],
	);
}

export default useCombinedRefs;
