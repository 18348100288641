import React, { useCallback, useContext, useRef } from 'react';

import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import { GraphActionsContext } from '../../context';

import { useStateHidden, useStateInvisible } from './hooks';

import './dot.scss';

const Dot = React.forwardRef(({ className, beacon }, ref) => (
	<div
		className={cn('asteria-graph-line-dot', className, {
			'asteria-graph-line-dot__beacon': beacon,
		})}
		ref={ref}
	/>
));

Dot.displayName = 'Dot';

const calcPosition = ({
	layout,
	maxValue = 0,
	minValue = 0,
	$minValue = 0,
	center = 50,
	group: { lines: [{ value = 0 } = {}] = [] } = {},
}) => {
	const range = Math.abs(maxValue - minValue);
	const relativeValue = value - minValue;

	let top = 100 - (relativeValue / range) * 100;

	if (layout === 'stacked') {
		const $center = center ? center : 100;
		const divider = maxValue ? maxValue : Math.abs($minValue);

		top = center - (value / divider) * $center;
	}

	return Math.max(Math.min(top, 100), 0);
};

const LineInfo = React.memo((props) => {
	const { group = {}, className, animated, beacon = false, layout } = props;
	const ref = useRef(null);
	const { mouseEnterAction, mouseLeaveAction, clickAction } =
		useContext(GraphActionsContext);

	const hasFxModule = useFeature('fx-module');
	const hasShowForecastAccountNumbers = useFeature(
		'show-forecast-account-numbers',
	);
	const hasBelowZeroFeature = useFeature(`graph-below-zero`);

	const { lines: [line = {}] = [] } = group;
	const isForecast = line?.types?.includes?.('forecast');

	const hiddenState = useStateHidden({ layout });
	const invisibleState = useStateInvisible({ layout });

	const clickHandler = useCallback(() => {
		Analytics.event(`graph.line-graph.dot.click`, { group });

		return clickAction?.({ group, source: 'account' });
	}, [clickAction, group]);

	const top = calcPosition(props);

	let invisible = null,
		hidden = null;

	if (isForecast) {
		if (line?.value >= 0 || !hasBelowZeroFeature) {
			invisible = invisibleState?.line?.future?.positive;
			hidden = hiddenState?.line?.future?.positive && (invisible ?? true);
		} else {
			invisible = invisibleState?.line?.future?.negative;
			hidden = hiddenState?.line?.future?.negative && (invisible ?? true);
		}
	} else {
		if (line?.value >= 0 || !hasBelowZeroFeature) {
			invisible = invisibleState?.line?.history?.positive;
			hidden =
				hiddenState?.line?.history?.positive && (invisible ?? true);
		} else {
			invisible = invisibleState?.line?.history?.negative;
			hidden =
				hiddenState?.line?.history?.negative && (invisible ?? true);
		}
	}

	return (
		<div
			role="button"
			tabIndex="-1"
			className={cn(
				'asteria-graph-line-info',
				className,
				{ 'asteria--feature-below-zero': hasBelowZeroFeature },
				{
					'asteria--state-hidden': hidden,
					'asteria--state-invisible': invisible,
				},
				{
					'asteria-graph-line-info-below-zero': line.value < 0,
					'asteria-graph-line-info-close-to-top': top < 15,
					'asteria-graph-line-info-close-to-bottom': top > 75,
					'asteria-graph-line-info-risk':
						hasFxModule &&
						animated &&
						line?.info?.some(({ type }) => type === 'risk'),
				},
			)}
			style={{ top: `${top}%` }}
			onClick={clickHandler}
			onKeyPress={clickHandler}
			onMouseEnter={() =>
				mouseEnterAction?.({
					line,
					group,
					target: ref,
					belowZero: hasBelowZeroFeature && line.value < 0,
				})
			}
			onMouseLeave={() =>
				mouseLeaveAction?.({
					line,
					group,
					target: ref,
					belowZero: hasBelowZeroFeature && line.value < 0,
				})
			}
		>
			<Dot
				className="asteria-graph-line-the-dot"
				beacon={beacon}
				ref={ref}
			/>

			{(hasShowForecastAccountNumbers || !isForecast) && (
				<Text size="sm">{formatNumber(line.value, false)}</Text>
			)}
		</div>
	);
});

LineInfo.displayName = 'Dot';

export default LineInfo;
export { Dot };
