import getFocusPath from './getFocusPath';

export default (data) => {
	const tags = ['deposit', 'withdraw'].flatMap((type) => {
		return Object.values(data?.[type]?.tags || {}).flatMap((tag) => {
			return {
				type,
				categoryName: tag?.category?.name,
				tagName: tag?.name,
			};
		});
	});

	const completed = tags.filter(({ type, categoryName, tagName }) => {
		const path = getFocusPath(
			data?.[type]?.data ?? {},
			`FORECAST.categories.${categoryName}.tags.${tagName}`,
		);
		return path.length === 0;
	});

	const depositPath = getFocusPath(data?.deposit?.data ?? {}, `FORECAST`);
	const withdrawPath = getFocusPath(data?.deposit?.data ?? {}, `FORECAST`);
	if (depositPath.length === 0) {
		completed.push({
			type: 'deposit',
			categoryName: '$type',
			tagName: '$deposit',
		});
	}

	if (withdrawPath.length === 0) {
		completed.push({
			type: 'withdraw',
			categoryName: '$type',
			tagName: '$withdraw',
		});
	}

	return completed;
};
