export const setCreditSettings = (state, { payload }) => {
	if (state?.credit === undefined) {
		state.credit = {};
	}

	if (state?.credit?.settings === undefined) {
		state.credit.settings = {};
	}

	for (const key in payload) {
		state.credit.settings[key] = payload[key];
	}
};

export const toggleCreditSettings = (state, { payload }) => {
	if (state?.credit === undefined) {
		state.credit = {};
	}

	if (state?.credit?.settings === undefined) {
		state.credit.settings = {};
	}

	const keys = [].concat(payload);

	for (const key of keys) {
		state.credit.settings[key] = !state.credit.settings[key];
	}
};
