import slice from './slice';

export * as selectors from './selectors';
export * as constants from './constants';
export { slice };

export const { setDetails } = slice.actions;

export const companies = {
	set: slice.actions.setCompanies,
	update: slice.actions.updateCompanies,
	remove: slice.actions.removeCompanies,
	reset: slice.actions.resetCompanies,
	start: slice.actions.startCompaniesLoading,
	stop: slice.actions.stopCompaniesLoading,

	setFilters: slice.actions.setCompaniesFilters,
	toggleFilters: slice.actions.toggleCompaniesFilters,
};

export const users = {
	set: slice.actions.setUsers,
	update: slice.actions.updateUsers,
	reset: slice.actions.resetUsers,
	start: slice.actions.startUsersLoading,
	stop: slice.actions.stopUsersLoading,
};

export const adminUsers = {
	set: slice.actions.setAdminUsers,
	update: slice.actions.updateAdminUsers,
	reset: slice.actions.resetAdminUsers,
	start: slice.actions.startAdminUsersLoading,
	stop: slice.actions.stopAdminUsersLoading,
};

export default slice.reducer;
