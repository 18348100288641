import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Icon from '@asteria/component-core/icon';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import DropdownNavigationItem from './item';

const LevelNavigationDropdown = React.memo((props) => {
	const { config, base, onAction, active, visible } = props;

	const handleCreate = React.useCallback(
		() => onAction?.('navigation:create', [...base]),
		[base, onAction],
	);

	const dropdownProps = React.useMemo(() => {
		const { extendable: { icon = 'folder', ...rest } = {} } = config;

		return {
			...rest,
			toggle: {
				children: <Icon icon={icon} />,
				icon: 'chevron-down',
				iconActive: 'chevron-up',
				iconPosition: 'last',
				...rest?.toggle,
			},
		};
	}, [config]);

	const translation = React.useMemo(
		() =>
			base
				.reduce((acc, item) => acc.concat([...acc, item].join('.')), [])
				.map((item) =>
					['level.navigation', item, 'create.label'].join('.'),
				),
		[base],
	);

	if (!config?.extendable) {
		return null;
	}

	return (
		<Dropdown
			{...dropdownProps}
			className="asteria-component__level-navigation-selector"
			scroll
		>
			{config?.children?.map((item) => (
				<DropdownNavigationItem
					key={item?.id}
					{...item}
					base={base}
					active={active}
					onAction={onAction}
					visible={visible}
				/>
			))}
			<DropdownItem
				className={cn(
					'asteria-component__level-navigation-selector-item',
					'asteria--variant-create',
				)}
				onClick={handleCreate}
			>
				<Button
					label={TranslationService.get([
						'level.navigation.create.label',
						...translation,
					])}
					size="md"
					iconSize="sm"
					icon="add"
					variant="tertiary"
				/>
			</DropdownItem>
		</Dropdown>
	);
});

LevelNavigationDropdown.displayName = 'LevelNavigationDropdown';

LevelNavigationDropdown.propTypes = {
	config: PropTypes.object,
	base: PropTypes.arrayOf(PropTypes.string),
	active: PropTypes.arrayOf(PropTypes.string),
	onAction: PropTypes.func,
	visible: PropTypes.object,
};

export default LevelNavigationDropdown;
