import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import useContenter, {
	Context as ContenterContext,
} from '@asteria/component-core/utils/useContenter';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const Section = (props) => {
	const { name, actions } = props;

	const content = useContenter(`spread.modal.${name}`);

	return (
		<Contenter
			content={content}
			className={cn(
				'asteria-component__section',
				`asteria-component__section--${name}`,
				'asteria-component__spread-section',
				`asteria-component__spread-section--${name}`,
			)}
		>
			{actions?.length
				? actions.map((props, index) => (
						<Button key={index} {...props} />
				  ))
				: null}
		</Contenter>
	);
};

const SpreadModalWrapper = (props) => {
	const { onClose, onAction } = props;

	const dispatch = useDispatch();

	const contenter = useConfig('spread.contenter');

	const hasIntegrations = useSelector(
		IntegrationStore.selectors.hasERP,
		(a, b) => isEqual(a, b),
	);

	const hasOnboardingFeature = useFeature('spread-modal-onboarding');
	const hasForecasterFeature = useFeature('spread-modal-forecaster');

	const hasHeaderForecasterFeature = useFeature('header-action-forecaster');
	const hasMenuForecasterFeature = useFeature('menu-item-forecaster');

	const OnboardingActions = React.useMemo(
		() => [
			{
				variant: 'link',
				label: TranslationService.get([
					'integrations.list.erp.add',
					`graph.spread.modal.section.onboarding.action`,
				]),
				onClick: () => {
					dispatch(
						ModalStore.open({
							type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
							data: { type: 'erp' },
						}),
					);
				},
			},
		],
		[dispatch],
	);

	const ForecasterActions = React.useMemo(
		() => [
			{
				variant: 'link',
				label: TranslationService.get(
					`graph.spread.modal.section.forecaster.action`,
				),
				analyticsKey: 'graph.spread.modal.forecaster.button',
				onClick: () => {
					dispatch(ModalStore.close());
					return onAction?.('go', '/forecaster');
				},
			},
		],
		[dispatch, onAction],
	);

	const SupportActions = React.useMemo(
		() => [
			{
				variant: 'link',
				label: TranslationService.get(
					`graph.spread.modal.section.support.action`,
				),
				analyticsKey: 'graph.spread.modal.support.button',
				onClick: () => {
					dispatch(ModalStore.close());
					return onAction?.('go', '/support');
				},
			},
		],
		[dispatch, onAction],
	);

	return (
		<ContenterContext.Provider value={contenter}>
			<Wrapper className="asteria-component__modal__graph-spread__wrapper">
				<Header onClose={onClose}>
					{TranslationService.get('graph.spread.modal.title')}
				</Header>
				<Content scroll>
					{hasOnboardingFeature && !hasIntegrations ? (
						<Section
							name="onboarding"
							actions={OnboardingActions}
						/>
					) : null}

					{hasForecasterFeature &&
					(hasHeaderForecasterFeature || hasMenuForecasterFeature) ? (
						<Section
							name="forecaster"
							actions={ForecasterActions}
						/>
					) : null}

					<Section name="support" actions={SupportActions} />
				</Content>
				<Footer>
					<FooterSection position="last">
						<Button
							variant="tertiary"
							label={TranslationService.get('action.close')}
							onClick={onClose}
							analyticsKey="spread.modal.close"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</ContenterContext.Provider>
	);
};

const SpreadModal = (props) => {
	const { open, onClose, className } = props;

	return (
		<Modal
			open={open}
			onClose={onClose}
			className={cn('asteria-component__modal__graph-spread', className)}
			size="sm"
		>
			<SpreadModalWrapper {...props} />
		</Modal>
	);
};

SpreadModal.displayName = 'SpreadModal';

SpreadModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default SpreadModal;
export { SpreadModalWrapper };
