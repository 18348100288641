import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import XAxisColumn from './column';
import XAxisPrefix from './prefix';

import './styles.scss';

const XAxis = React.memo((props) => {
	const {
		className,
		onAction,

		hasYAxis,
		showPrefix,

		type,
		dates,
		category,
		tag,
		types = [type],

		size = (dates ?? []).length + 2,

		render,
		renderItem,
		renderPrefix,
	} = props;

	const style = React.useMemo(() => ({ '--size': size }), [size]);

	return (
		<div
			className={cn(
				'asteria-component__forecaster-axis',
				'asteria--variant-x',
				{
					'asteria--state-has-y-axis': hasYAxis,
					'asteria--state-has-prefix': showPrefix,
				},
				className,
			)}
			style={style}
		>
			{showPrefix ? (
				renderPrefix ? (
					renderPrefix({
						type,
						dates,
						category,
						tag,
						types,
						onAction,
					})
				) : (
					<XAxisPrefix
						types={types}
						type={type}
						dates={dates}
						category={category}
						tag={tag}
					/>
				)
			) : null}

			{render
				? render({ type, dates, category, tag, types, onAction })
				: dates.map((date) =>
						renderItem ? (
							renderItem({
								type,
								date,
								category,
								tag,
								types,
								onAction,
							})
						) : (
							<XAxisColumn
								key={date}
								type={type}
								date={date}
								category={category}
								tag={tag}
								types={types}
								onAction={onAction}
							/>
						),
				  )}
		</div>
	);
});

XAxis.displayName = 'XAxis';

XAxis.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,

	hasYAxis: PropTypes.bool,
	showPrefix: PropTypes.bool,

	type: PropTypes.string,
	dates: PropTypes.arrayOf(PropTypes.string),
	category: PropTypes.string,
	tag: PropTypes.string,
	types: PropTypes.arrayOf(PropTypes.string),

	size: PropTypes.number,

	render: PropTypes.func,
	renderItem: PropTypes.func,
	renderPrefix: PropTypes.func,
};

export default XAxis;
