import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import Text from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const Slider = (props) => {
	const { className, variant, min, max, onChange } = props;

	const [minVal, setMinVal] = useState(min);
	const [maxVal] = useState(max);
	const minValRef = useRef(null);
	const range = useRef(null);

	// Convert to percentage
	const getPercent = useCallback(
		(value) => Math.round(((value - min) / (max - min)) * 100),
		[min, max],
	);

	// Set width of the range
	useEffect(() => {
		if (minValRef.current) {
			const minPercent = getPercent(minVal);
			// const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

			if (range.current) {
				range.current.style.left = `0%`;
				range.current.style.width = `${minPercent}%`;
			}
		}
	}, [minVal, getPercent]);

	// Get min and max values when their state changes
	useEffect(() => {
		onChange?.({});
	}, [minVal, maxVal, onChange]);

	return (
		<div
			className={cn('asteria-component__slider', className, {
				[`asteria-component__slider--variant-${variant}`]: variant,
			})}
		>
			<input
				type="range"
				min={min}
				max={max}
				value={minVal}
				ref={minValRef}
				onChange={(event) => {
					const value = Math.min(+event.target.value, maxVal - 1);
					setMinVal(value);
					event.target.value = value.toString();
					onChange(event.target.value);
				}}
				className={cn(
					'asteria-component__slider__thumb asteria-component__slider__thumb--zindex-3',
					{
						'asteria-component__slider__thumb--zindex-5':
							minVal > max - 100,
					},
				)}
			/>

			<div className="asteria-component__slider__overlay">
				<div className="asteria-component__slider__overlay__track" />
				<div
					ref={range}
					className="asteria-component__slider__overlay__range"
				/>
				<div className="asteria-component__slider__overlay__left-value">
					<Text size="sm">{minVal}</Text>
				</div>
				<div className="asteria-component__slider__overlay__right-value">
					<Text size="sm">{maxVal}</Text>
				</div>
			</div>
		</div>
	);
};

const MultiRangeSlider = (props) => {
	const { className, variant, min, max, onChange, value } = props;

	const [minVal, setMinVal] = useState(value?.[0] || min);
	const [maxVal, setMaxVal] = useState(value?.[1] || max);
	const minValRef = useRef(null);
	const maxValRef = useRef(null);
	const range = useRef(null);

	// Convert to percentage
	const getPercent = useCallback(
		(value) => Math.round(((value - min) / (max - min)) * 100),
		[min, max],
	);

	// Set width of the range to decrease from the left side
	useEffect(() => {
		if (maxValRef.current) {
			const minPercent = getPercent(minVal);
			const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

			if (range.current) {
				range.current.style.left = `${minPercent}%`;
				range.current.style.width = `${maxPercent - minPercent}%`;
			}
		}
	}, [minVal, maxVal, getPercent]);

	// Get min and max values when their state changes
	useEffect(() => {
		onChange?.({});
	}, [minVal, maxVal, onChange]);

	return (
		<div
			className={cn('asteria-component__slider', className, {
				[`asteria-component__slider--variant-${variant}`]: variant,
			})}
		>
			<input
				type="range"
				min={min}
				max={max}
				value={minVal}
				ref={minValRef}
				onChange={(event) => {
					const value = Math.min(+event.target.value, maxVal - 1);
					setMinVal(value);
					event.target.value = value.toString();
					onChange?.(event.target.value);
				}}
				className={cn(
					'asteria-component__slider__thumb asteria-component__slider__thumb--zindex-3',
					{
						'asteria-component__slider__thumb--zindex-5':
							minVal > max - 100,
					},
				)}
			/>
			<input
				type="range"
				min={min}
				max={max}
				value={maxVal}
				ref={maxValRef}
				onChange={(event) => {
					const value = Math.max(+event.target.value, minVal + 1);
					setMaxVal(value);
					event.target.value = value.toString();
				}}
				className="asteria-component__slider__thumb asteria-component__slider__thumb--zindex-4"
			/>

			<div className="asteria-component__slider__overlay">
				<div className="asteria-component__slider__overlay__track" />
				<div
					ref={range}
					className="asteria-component__slider__overlay__range"
				>
					<Text size="sm">{minVal}</Text>
					<Text size="sm">{maxVal}</Text>
				</div>
				<div className="asteria-component__slider__overlay__left-value">
					<Text size="sm">{min}</Text>
				</div>
				<div className="asteria-component__slider__overlay__right-value">
					<Text size="sm">{max}</Text>
				</div>
			</div>
		</div>
	);
};

Slider.propTypes = {
	variant: PropTypes.string,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

Slider.defaultProps = {
	variant: '',
};

Slider.displayName = 'Slider';

MultiRangeSlider.propTypes = {
	variant: PropTypes.string,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

MultiRangeSlider.defaultProps = {
	variant: 'multi-range',
};

MultiRangeSlider.displayName = 'MultiRangeSlider';

export default Slider;
export { MultiRangeSlider };
