import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Tooltip from '../../tooltip';
import { Text } from '../../typography';

import IndeterminateProgress from './IndeterminateProgress';
import Stepper from './Stepper';
import { useColor } from './utils';

import '../index.scss';

const ProgressComponent = React.memo((props) => {
	const { className, progress = -1, color, tooltip } = props;

	const { style: colorStyle, className: colorClassName } = useColor(color);
	const style = { ...colorStyle, width: `${progress}%` };

	const element = (
		<div
			className={cn(
				'asteria-component__progress',
				className,
				colorClassName,
			)}
		>
			<div
				className={cn(
					'asteria-component__progress__status',
					colorClassName,
				)}
				style={style}
			/>
			<Text>{`${progress}%`}</Text>
		</div>
	);

	if (tooltip) {
		return (
			<Tooltip>
				{element}
				{tooltip}
			</Tooltip>
		);
	}

	return element;
});

ProgressComponent.displayName = 'ProgressComponent';

ProgressComponent.propTypes = {
	className: PropTypes.string,
	progress: PropTypes.number,
	steps: PropTypes.number,
	color: PropTypes.shape({ value: PropTypes.string, type: PropTypes.string }),
	tooltip: PropTypes.string,
};

const Progress = React.memo((props) => {
	const { className, progress = -1, steps = null, color } = props;

	if (progress === -1) {
		return <IndeterminateProgress className={className} color={color} />;
	}

	if (steps) {
		return (
			<Stepper
				className={className}
				progress={progress}
				steps={steps}
				color={color}
			/>
		);
	}

	return <ProgressComponent {...props} />;
});

Progress.propTypes = {
	className: PropTypes.string,
	progress: PropTypes.number,
	steps: PropTypes.number,
	color: PropTypes.shape({ value: PropTypes.string, type: PropTypes.string }),
	tooltip: PropTypes.string,
};

Progress.defaultProps = {
	className: null,
	progress: null,
};

Progress.displayName = 'Progress';

export default Progress;
