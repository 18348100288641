import React from 'react';

import PropTypes from 'prop-types';

import { View as BarGraphView } from '@asteria/component-forecaster/components/graph/bars';

import { cn } from '@asteria/utils-funcs/classes';

import LoanBarGraphColumn from './column';
import { useGraphData, useLegends, useYAxis } from './hooks';

import './styles.scss';

const LoanBarGraph = React.memo((props) => {
	const { className } = props;

	const data = useGraphData();
	const { labels, max, min, title } = useYAxis({ data });
	const legends = useLegends();

	return (
		<BarGraphView
			className={cn('asteria-component__financial-loan-graph', className)}
			labels={labels}
			title={title}
			type="withdraw"
			dates={data.map(({ date }) => date)}
			min={min}
			max={max}
			showYAxis
			showLines
			showLegends
			legends={legends}
			renderItem={({ type, date }) => (
				<LoanBarGraphColumn type={type} date={date} />
			)}
		/>
	);
});

LoanBarGraph.displayName = 'LoanBarGraph';

LoanBarGraph.propTypes = { className: PropTypes.string };

export default LoanBarGraph;
