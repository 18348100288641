import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const WizardStep = (props) => {
	const { className, children } = props;

	return (
		<div className={cn('asteria-component__wizard__step', className)}>
			{children}
		</div>
	);
};

WizardStep.displayName = 'WizardStep';

WizardStep.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default React.memo(WizardStep);
