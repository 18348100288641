import AsteriaCore from '@asteria/core';

import { TransactionService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import {
	setMultipleGroups,
	setStatistics,
	startLoading,
	stopLoading,
	updateAxis,
} from '@asteria/datalayer/stores/graph';

export const FIELDS = `
sums {
	original {
		total
	}
}
max {
	original {
		total
	}
}
min {
	original {
		total
	}
}
probability
type
status
count
badge
chip {
	id
	type
	config
}
info {
	id
	type
	data
}
`;

TransactionService.transaction.extend({
	getQuery: (fields = FIELDS) => `
		query QueryCashflows(
			$dates: [CashflowQueryFilterInputType]!
			$tags: [ObjectId]
			$clients: [ObjectId]
			$status: [String]
			$currencies: [String]
			$preview: CashFlowQueryPreviewInputType
			$scenarioId: ObjectId
		) {
			cashflows(
				dates: $dates
				filters: {
					search: {
						tags: $tags
						clients: $clients
						status: $status
						currencies: $currencies
						scenarioId: $scenarioId
					}
					preview: $preview
				}
			) {
				${fields}
			}
		}
	`,
	key: `cashflow`,
	loggerMethod: `services.transactionService`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.transactionService`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

TransactionService.transaction.extend({
	key: `cashflowStatistics`,
	getQuery: () => `
		query CashflowStatistics(
			$timeslice: String
			$filters: CashflowQueryFilterInputType = {}
		) {
			cashflowStatistics(timeslice: $timeslice, filters: $filters) {
				timeslice
				account {
					min
					max
				}
				transactions {
					startDate
					endDate
					min
					max
					deposit {
						min
						max
					}
					withdraw {
						min
						max
					}
				}
			}
		}
	`,
	loggerMethod: 'api.cashflow.cashflowStatistics',
});

const buildBar = (data, barType, types = []) => {
	const tags = (store.getState()?.app?.tags ?? [])
		.filter((object) => object?.category?.name === '$custom')
		.reduce(
			(acc, object) => ({
				...acc,
				[object._id ?? object.id]: object.color,
			}),
			{},
		);

	const pieces =
		data?.filter(({ type }) => type === barType.toUpperCase()) || [];

	return {
		value: pieces
			.filter(({ badge }) => !badge)
			.reduce(
				(
					acc,
					{
						sums: {
							original: { total },
						},
					},
				) => acc + total,
				0,
			),
		types: [barType.toLowerCase(), ...types],
		badges: pieces
			.filter(({ badge }) => badge)
			.map(
				({
					status,
					type,
					count,
					probability,
					sums: {
						original: { total },
					},
					max,
					min,
					chip: {
						config: { name, tagType = name },
					},
					chip,
					info,
				}) => ({
					value: total,
					types: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
					],
					parts: [],
					chip,
					count,
					data: {
						type: type.toLowerCase(),
						status,
						probability: probability || 1,
						max: max?.original?.total,
						min: min?.original?.total,
					},
					info: info || [],
				}),
			),
		parts: pieces
			.filter(({ badge }) => !badge)
			.map(
				({
					status,
					type,
					probability,
					sums: {
						original: { total },
					},
					max,
					min,
					chip: {
						id,
						config: { name, tagType = name, category },
					},
					chip,
					info,
				}) => ({
					value: total,
					types: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
						...(category
							? [
									`${category.replace(
										'$',
										'',
									)}-${tagType.replace('$', '')}`,
							  ]
							: []),
					],
					colors: [
						type.toLowerCase(),
						tagType.replace('$', ''),
						status.toLowerCase(),
						...(category
							? [
									`${category.replace(
										'$',
										'',
									)}-${tagType.replace('$', '')}`,
									tags?.[id],
							  ].filter(Boolean)
							: []),
					],
					parts: [],
					chip,
					data: {
						type: type.toLowerCase(),
						status,
						probability: probability || 1,
						max: max?.original?.total,
						min: min?.original?.total,
					},
					info: info || [],
				}),
			),
		data: {
			type: barType.toLowerCase(),
			probability: 1,
		},
	};
};

const queue = [];
const controllers = [];

const fetchCashflows = AsteriaCore.utils.throttle(
	async ({ accessToken, dispatch, skipLoading }) => {
		controllers.forEach((value) => {
			value?.abort?.();
		});

		controllers.length = 0;

		if (!queue.length) {
			return;
		}

		const requests = queue.reduce(
			(acc, object) => ({
				...acc,
				[object?.startDate]: (acc?.[object?.startDate] ?? []).concat(
					object,
				),
			}),
			{},
		);

		const dates = Object.keys(requests);
		const values = Object.values(requests);

		const promises = values.map((values) => {
			const startDate = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.startDate), [])
					.filter(Boolean),
			)?.[0];

			const endDate = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.endDate), [])
					.filter(Boolean),
			)?.[0];

			const scenarioId = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.scenarioId), [])
					.filter(Boolean),
			)?.[0];

			const tags = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.tags), [])
					.filter(Boolean),
			);

			const clients = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.clients), [])
					.filter(Boolean),
			);

			const status = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.status), [])
					.filter(Boolean),
			);

			const currencies = AsteriaCore.utils.unique(
				values
					.reduce((acc, data) => acc.concat(data?.currencies), [])
					.filter(Boolean),
			);

			const controller = new AbortController();

			if (values.some((data) => data?.cancel)) {
				controllers.push(controller);
			}

			return TransactionService.transaction.extension
				.cashflow(
					{
						isBulk: true,
						dates: [{ startDate: startDate, endDate: endDate }],
						tags: tags,
						clients: clients,
						status: status,
						currencies: currencies,
						scenarioId: scenarioId,
						signal: controller.signal,
					},
					{ token: accessToken },
				)
				.catch((err) => {
					if (accessToken === '__OFFLINE__') {
						return [];
					}

					throw err;
				});
		});

		queue.length = 0;

		dispatch(startLoading());

		const response = await Promise.all(promises)
			.then((data) => data.map(([value]) => value).filter(Boolean))
			.catch(() => []);

		controllers.length = 0;

		const graphLayout =
			store.getState()?.app?.user?.settings?.layout?.graph;

		dispatch(
			setMultipleGroups(
				response.map((data, index) => {
					const available = data.filter(
						({ status }) => status !== 'BACKGROUND',
					);

					const background = data.filter(
						({ status }) => status === 'BACKGROUND',
					);

					const deposit = buildBar(available, 'deposit');
					const withdraw = buildBar(available, 'withdraw');

					return {
						id: dates[index],
						data: {
							id: dates[index],
							lines: data
								.filter(
									({ type }) =>
										type === 'ACCOUNT' || type === 'CREDIT',
								)
								.map(
									({
										sums: {
											original: { total },
										},
										max,
										min,
										status,
										probability,
										type,
										info,
									}) => ({
										value: total,
										max: max?.original?.total || total,
										min: min?.original?.total || total,
										probability,
										types: [
											type.toLowerCase(),
											status === 'FORECAST'
												? 'forecast'
												: 'history',
											...(type === 'CREDIT'
												? ['sharp']
												: []),
										],
										info: info || [],
									}),
								),
							bars: [
								buildBar(background, 'deposit', ['background']),
								deposit,
								buildBar(background, 'withdraw', [
									'background',
								]),
								withdraw,
							],
						},
					};
				}),
			),
		);

		dispatch(
			updateAxis({
				barLayout: graphLayout?.layout,
				scale: graphLayout?.scale,
			}),
		);

		if (!skipLoading) {
			dispatch(stopLoading());
		}
	},
	1000,
);

/**
 * @param { { accessToken: string, fields?: string, scenarioId?: string, dates?: { startDate: string, endDate: string }[], tags?: string[], clients?: string[], status?: string[], currencies?: string[], preview?: { factoring?: { clients?: string[], transactions?: string[] } } } } options
 */
export async function custom(options) {
	return TransactionService.transaction.extension.cashflow(
		{
			dates: options.dates,
			tags: options.tags,
			clients: options.clients,
			status: options.status,
			currencies: options.currencies,
			preview: options.preview,
			scenarioId: options.scenarioId,
			fields: options.fields,
			dataloader: options.dataloader,
		},
		{ token: options.accessToken },
	);
}

/**
 * @param {{ dates: { startDate: string, endDate: string }[], scenarioId: string, accessToken: string }} options
 */
export async function card({ dates, scenarioId, accessToken, dataloader }) {
	return custom({
		dates: dates,
		scenarioId: scenarioId,

		accessToken: accessToken,
		fields: ` sums { original { total currency } } max { original { total currency } } min { original { total currency } } type status count `,
		dataloader: dataloader,
	}).then((response) =>
		response.map((value) =>
			value.reduce((acc, object) => {
				const type = object?.type;
				const status = object?.status;

				if (!acc[type]) {
					acc[type] = {};
				}

				acc[type][status] = object;

				return acc;
			}, {}),
		),
	);
}

export async function fetch({
	accessToken,
	dispatch,
	tags,
	status,
	currencies,
	clients,
	startDate,
	endDate,
	scenarioId,
	batch = true,
	cancel,
	skipLoading,
}) {
	if (batch) {
		queue.push({
			startDate,
			endDate,
			tags,
			status,
			currencies,
			clients,
			scenarioId,
			cancel,
		});

		fetchCashflows({ accessToken, dispatch, skipLoading });

		return null;
	} else {
		return TransactionService.transaction.extension.cashflow(
			{
				dates: [
					{
						startDate,
						endDate,
					},
				],
				tags: tags,
				clients: clients,
				status: status,
				currencies: currencies,
				scenarioId: scenarioId,
			},
			{ token: accessToken },
		);
	}
}

export async function statistics({
	accessToken,
	dispatch,
	tags,
	status,
	currencies,
	clients,
	scenarioId,
	timeslice,
}) {
	const response =
		await TransactionService.transaction.extension.cashflowStatistics(
			{
				timeslice: timeslice,
				filters: {
					search: {
						tags: tags,
						clients: clients,
						status: status,
						currencies: currencies,
						scenarioId: scenarioId,
					},
				},
			},
			{ token: accessToken },
		);

	dispatch?.(setStatistics(response));

	return response;
}
