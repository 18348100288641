import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import './index.scss';

const ActionBar = React.forwardRef((props, ref) => {
	const { className, children, onClick } = props;

	if (!children) {
		return null;
	}

	return (
		<div
			className={cn('asteria-component__actionbar', className)}
			onClick={onClick}
			ref={ref}
		>
			{children}
		</div>
	);
});

ActionBar.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
};

ActionBar.displayName = 'ActionBar';

export default React.memo(ActionBar);
