import { useCallback, useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';

import * as AppStore from '@asteria/datalayer/stores/app';

import Analytics from '@asteria/utils-analytics';

import { TranslationContext } from '..';
import * as ConfigAPI from '../api/config';
import * as PartnerAPI from '../api/partner';

export * as auth from './auth';

export function useConfiguration({ partnerId, enabled }) {
	const dispatch = useDispatch();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	useQuery({
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		queryKey: [
			'widget',
			'configuration',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
		],
		queryFn: async ({ signal }) =>
			ConfigAPI.fetch({
				accessToken: accessToken,
				dispatch: dispatch,
				signal: signal,
			}),
		enabled: enabled && !!accessToken,
	});
}

export function usePartner({ partnerId }) {
	const dispatch = useDispatch();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	useQuery({
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		queryKey: [
			'widget',
			'partner',
			partnerId ?? 'NONE',
			accessToken ?? 'NONE',
		],
		queryFn: async ({ signal }) =>
			PartnerAPI.fetch({
				partnerId,
				accessToken: accessToken,
				dispatch: dispatch,
				signal: signal,
			}),
		enabled: !!accessToken && !!partnerId,
	});
}

export function useAnalytics({ analytics }) {
	const accessToken = useSelector(AppStore.selectors.accessToken);

	useEffect(() => {
		Analytics.accessToken = accessToken;
		Analytics.mode = analytics ? 'api' : 'console';
		Analytics.init();
		Analytics.event('load', {});
	}, [accessToken, analytics]);
}

export function useLanguageChangeCallback() {
	const { onChange } = useContext(TranslationContext);

	return useCallback((code) => onChange(code), [onChange]);
}
