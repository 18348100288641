import { useEffect, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { merge } from 'lodash-es';

/**
 * @template T
 * @param {{ name: string | string, exact?: boolean, disabled?: boolean }} [options]
 * @returns { T }
 */
export const useFormValues = (options = {}) => {
	const { name, exact, disabled } = options;

	const { getValues } = useFormContext();

	const dynamicValues = useWatch({
		name: name,
		exact: exact,
		disabled: disabled,
	});

	const staticValues = getValues(name);

	if (typeof dynamicValues === 'object' || staticValues === 'object') {
		return merge(dynamicValues, staticValues);
	}

	return dynamicValues || staticValues;
};

/**
 * @template T
 * @param {{ name: string | string, delay?: number }} [options]
 * @returns { T }
 */
export const useDebounceFormValues = (options = {}) => {
	const { delay = 1_000 } = options;

	const formValue = useFormValues(options);

	const [state, setState] = useState(formValue);

	useEffect(() => {
		const timer = setTimeout(() => {
			setState(formValue);
		}, delay);

		return () => {
			clearTimeout(timer);
		};
	}, [formValue, delay]);

	return state;
};

/**
 * @param {{ name: string[] }} options
 */
export function useManualFormValues({ name }) {
	const { watch } = useFormContext();

	return watch(name);
}
