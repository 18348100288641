import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const LoanBarGraphTooltip = React.memo((props) => {
	const { data } = props;

	return (
		<Group
			className={cn(
				'asteria-component__loan-tooltip',
				'asteria--type-bar',
			)}
			direction="vertical"
		>
			<Title size="xxs" className="asteria-component__loan-tooltip-title">
				{TranslationService.get('credit.loan.bar.tooltip.title')}
			</Title>
			<Group direction="horizontal" horizontalAlign="space-between">
				<Group direction="horizontal" verticalAlign="center">
					<Prefix colors={['amortization']} />
					<Text size="sm">
						{TranslationService.get(
							'credit.loan.bar.tooltip.amortization.title',
						)}
					</Text>
				</Group>
				<Text size="sm">
					{TranslationService.get(
						[
							'credit.loan.tooltip.value',
							'credit.loan.bar.tooltip.amortization.value',
						],
						undefined,
						{
							value: data?.AMORTIZATION?.total * -1 ?? 0,
						},
					)}
				</Text>
			</Group>
			<Group direction="horizontal" horizontalAlign="space-between">
				<Group direction="horizontal" verticalAlign="center">
					<Prefix colors={['interest']} />
					<Text size="sm">
						{TranslationService.get(
							'credit.loan.bar.tooltip.interest.title',
						)}
					</Text>
				</Group>
				<Text size="sm">
					{TranslationService.get(
						[
							'credit.loan.tooltip.value',
							'credit.loan.bar.tooltip.interest.value',
						],
						undefined,
						{
							value: data?.INTEREST?.total * -1 ?? 0,
						},
					)}
				</Text>
			</Group>
		</Group>
	);
});

LoanBarGraphTooltip.displayName = 'LoanBarGraphTooltip';
LoanBarGraphTooltip.propTypes = {
	data: PropTypes.object,
};

export default LoanBarGraphTooltip;
