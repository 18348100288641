import React, { useCallback, useContext, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { parseISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import * as TimesizeUtils from '@asteria/utils-funcs/timesize';
import useComponentSize from '@asteria/utils-hooks/useComponentSize';

import { GraphActionsContext } from '../../context';
import { useGraphMode } from '../../hooks';
import * as Selectors from '../../selectors';
import Background from '../background';

import LineDot, { Dot } from './dot';
import Line from './line';
import Spread from './spread';
import { findIntersect, toZero } from './utils';

import './line.scss';

const selectors = {
	showRisk: createSelector(
		(store) => store?.app?.user?.settings?.layout?.graph?.showRisk,
		(value) => value || false,
	),

	currency: createSelector(
		(store) => store?.app?.company?.settings?.currency,
		(value) => value ?? 'SEK',
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const LineGroupWrapper = React.memo(
	({ position, first, last, children, className }) => {
		const style = React.useMemo(() => ({ gridColumn: position }), []);

		return (
			<div
				className={cn(
					'asteria-graph-line-group-wrapper',
					{
						'asteria-graph-line-group-wrapper-first': first,
						'asteria-graph-line-group-wrapper-last': last,
					},
					className,
				)}
				style={style}
			>
				{children}
			</div>
		);
	},
);

LineGroupWrapper.propTypes = {
	position: PropTypes.number,
	first: PropTypes.bool,
	last: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
};

LineGroupWrapper.displayName = 'LineGroupWrapper';

const SpreadHelpButton = () => {
	const dispatch = useDispatch();

	const hasSpreadFeature = useFeature('graph-spread-info-button');

	const handleClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({ type: ModalStore.MODAL_WINDOWS.GraphSpread }),
		);
	}, [dispatch]);

	if (!hasSpreadFeature) {
		return null;
	}

	return (
		<Button
			icon="help-filled"
			variant="secondary"
			size="sm"
			className="asteria-graph-line-group-spread-info"
			onClick={handleClick}
		/>
	);
};

function getY({ min, max }, minValue, maxValue) {
	// console.groupCollapsed('getY');
	// console.log({ min, max, minValue, maxValue });
	// console.groupEnd();

	return {
		min: 100 - ((min - minValue) / (maxValue - minValue)) * 100,
		max: 100 - ((max - minValue) / (maxValue - minValue)) * 100,
	};
}

function findSpreadDots(dots) {
	if (dots?.[0]?.y !== undefined && dots?.[1]?.y !== undefined) {
		const diff = Math.abs(dots?.[0]?.y - dots?.[1]?.y);

		if (diff <= 8) {
			return findSpreadDots(
				[]
					.concat([
						{
							type: [dots?.[0]?.type, dots?.[1]?.type].join('-'),
							y: Math.min(dots?.[0]?.y, dots?.[1]?.y),
							intersection: []
								.concat(dots?.[0]?.intersection ?? dots?.[0])
								.concat(dots?.[1]?.intersection ?? dots?.[1]),
						},
					])
					.concat(dots.slice(2)),
			);
		}
	}

	if (dots?.[1]?.y !== undefined && dots?.[2]?.y !== undefined) {
		const diff = Math.abs(dots?.[1]?.y - dots?.[2]?.y);

		if (diff <= 8) {
			return findSpreadDots(
				[].concat(dots.slice(0, 1)).concat([
					{
						type: [dots?.[1]?.type, dots?.[2]?.type].join('-'),
						y: Math.max(dots?.[1]?.y, dots?.[2]?.y),
						intersection: []
							.concat(dots?.[1]?.intersection ?? dots?.[1])
							.concat(dots?.[2]?.intersection ?? dots?.[2]),
					},
				]),
			);
		}
	}

	return dots.map((object) => ({
		...object,
		intersection: object?.intersection ?? [object],
	}));
}

const SpreadInfo = React.memo(
	(props) => {
		const { id, prev, next } = props;

		const dispatch = useDispatch();

		const currency = useSelector(selectors.currency, (a, b) =>
			isEqual(a, b),
		);

		const credit = useSelector(
			AccountStore.selectors.dynamicCredit,
			(a, b) => isEqual(a, b),
		);

		const onDotClick = React.useCallback(() => {
			Analytics.event('graph.spread.dot.click', {
				id: id,
				prev: prev,
				next: next,
			});

			dispatch(
				ModalStore.open({ type: ModalStore.MODAL_WINDOWS.GraphSpread }),
			);
		}, [dispatch, id, next, prev]);

		const dots = findSpreadDots([
			{
				type: 'max',
				value: next?.max,
				diff: Math.abs(Math.abs(next?.max - next?.value) / next?.value),
				y: next?.yMax,
			},
			{
				type: 'center',
				value: next?.center,
				diff: Math.abs(
					Math.abs(next?.center - next?.value) / next?.value,
				),
				y: next?.yCenter,
			},
			{
				type: 'min',
				value: next?.min,
				diff: Math.abs(Math.abs(next?.min - next?.value) / next?.value),
				y: next?.yMin,
			},
		]);

		return (
			<div className="asteria-graph-spread-info">
				<svg
					height="100%"
					width="100%"
					className="asteria-graph-spread-info-lines"
				>
					<line x1="50%" x2="50%" y1="0" y2="100%" />
				</svg>
				<div className="asteria-graph-spread-info-dots">
					{dots.map((dot, index) => (
						<TooltipWrapper
							key={index}
							custom
							tooltip={
								<Group>
									{[].concat(dot?.intersection).map((dot) => (
										<Text
											key={dot?.type}
											size="sm"
											align="center"
										>
											{TranslationService.get(
												[
													`graph.spread.dot.tooltip.content.0`,
													`graph.spread.dot.tooltip.${dot?.type}.content.0`,
												],
												undefined,
												{
													...dot,
													currency: currency,
													credit: credit,
												},
											)}
										</Text>
									))}

									<Text size="xs" align="center">
										{TranslationService.get(
											[
												`graph.spread.dot.tooltip.content.1`,
												`graph.spread.dot.tooltip.${dot?.type}.content.1`,
											],
											undefined,
											{
												...dot,
												currency: currency,
												credit: credit,
											},
										)}
									</Text>
								</Group>
							}
							placement="right"
						>
							<div
								className="asteria-graph-dot-wrapper"
								style={{ '--y': `${dot.y}%` }}
								onClick={onDotClick}
							>
								<Dot />
							</div>
						</TooltipWrapper>
					))}
				</div>
			</div>
		);
	},
	(prev, next) =>
		Object.keys(prev).every((key) => isEqual(prev[key], next[key])),
);

SpreadInfo.displayName = 'SpreadInfo';
SpreadInfo.propTypes = {
	id: PropTypes.string,
	prev: PropTypes.object,
	next: PropTypes.object,
};

const LineColumn = React.memo(
	({
		className,
		id,
		prevId,
		index,
		layout,
		margin,
		first = false,
		last = false,
		onClick,
		center = 100,
		parts,
	}) => {
		const { setTarget } = useContext(GraphActionsContext);

		const mode = useGraphMode();

		const credit = useSelector(
			AccountStore.selectors.dynamicCredit,
			(a, b) => isEqual(a, b),
		);

		const timesize = useSelector(
			Selectors.common.currentTimesliceSize,
			(a, b) => isEqual(a, b),
		);

		const isCurrent = id
			? TimesizeUtils.isThis(parseISO(id), timesize)
			: false;

		const range = useSelector(
			(store) =>
				Selectors.graph.range(store, {
					formatEach: (value) => ({ id: value?.id }),
				}),
			(a, b) => isEqual(a, b),
		);

		const group = useSelector(
			(store) =>
				Selectors.graph.barGroups(store, {
					format: (groups) =>
						findGroup({
							groups: groups,
							id: id,
							range: range,
							index: index,
						}),
				}),
			(a, b) => isEqual(a, b),
		);

		const prevGroup = useSelector(
			(store) =>
				Selectors.graph.barGroups(store, {
					format: (groups) =>
						findGroup({
							groups: groups,
							id: prevId,
							range: range,
							index: index,
						}),
				}),
			(a, b) => isEqual(a, b),
		);

		const types = useSelector(
			(store) =>
				Selectors.graph.range(store, {
					id: id,
					format: (range) => range?.[0]?.types ?? [],
				}),
			(a, b) => isEqual(a, b),
		);

		const prevTypes = useSelector(
			(store) =>
				Selectors.graph.range(store, {
					id: prevId,
					format: (range) => range?.[0]?.types ?? [],
				}),
			(a, b) => isEqual(a, b),
		);

		const minValue = useSelector(
			(store) => Selectors.graph.minValue(store, parts),
			(a, b) => isEqual(a, b),
		);

		const maxValue = useSelector(
			(store) => Selectors.graph.maxValue(store, parts),
			(a, b) => isEqual(a, b),
		);

		const zero =
			layout === 'stacked'
				? center
				: (Math.abs(maxValue) /
						(Math.abs(maxValue) + Math.abs(minValue))) *
				  100;

		const ref = useRef(null);
		const { height } = useComponentSize({ ref: ref });
		const { lines: [{ max = 0, min = 0, value = 0 } = {}] = [] } = group;

		const active = useSelector(
			(store) => Selectors.graph.active(store, id),
			(a, b) => isEqual(a, b),
		);

		const hover = useSelector(
			(store) => Selectors.graph.hover(store, id),
			(a, b) => isEqual(a, b),
		);

		const hasBelowZeroFeature = useFeature(`graph-below-zero`);

		// const hasSpreadDotsFeature = useFeature('graph-spread-info-dots');
		const hasDisableFutureFeature = useFeature(
			'graph-disable-forecast-line',
		);
		const shouldDisableFuture = hasDisableFutureFeature;

		const {
			lines: [
				{
					max: prevMax = 0,
					min: prevMin = 0,
					value: prevValue = 0,
				} = {},
			] = [],
		} = prevGroup || group;

		const maxDiff = max - value;
		const prevMaxDiff = prevMax - prevValue;

		const minDiff = value - min;
		const prevMinDiff = prevValue - prevMin;

		const { min: yMin, max: yMax } = getY(
			{ min: min, max: max },
			minValue,
			maxValue,
		);

		const { min: yPrevMin, max: yPrevMax } = getY(
			{ min: prevMin, max: prevMax },
			minValue,
			maxValue,
		);

		const { min: yInnerMin, max: yInnerMax } = getY(
			{ min: min + minDiff / 2, max: max - maxDiff / 2 },
			minValue,
			maxValue,
		);

		const { min: yInnerPrevMin, max: yInnerPrevMax } = getY(
			{ min: prevMin + prevMinDiff / 2, max: prevMax - prevMaxDiff / 2 },
			minValue,
			maxValue,
		);

		const { min: yPrevValue, max: yValue } = getY(
			{ min: prevValue, max: value },
			minValue,
			maxValue,
		);

		const isFadingOut = prevTypes.includes('today') && shouldDisableFuture;

		const intersectData = React.useMemo(() => {
			const zeroPrevValue = toZero(yPrevValue, zero);
			const zeroValue = toZero(yValue, zero);

			if (zeroPrevValue * zeroValue < 0) {
				return {
					functionIsDecreasing: zeroPrevValue < 0,
					zeroLine: zero,
					intersectPoint: findIntersect(zeroPrevValue, zeroValue),
				};
			}

			return {};
		}, [yPrevValue, yValue, zero]);

		const forecastValue = useMemo(() => {
			if (
				group.lines?.[1]?.types &&
				!group.lines?.[1]?.types?.includes?.('credit')
			) {
				return group.lines?.[1]?.value;
			}

			return group.lines?.[0]?.value || 0;
		}, [group]);

		const LineGroupStyles = React.useMemo(
			() => ({
				// width,
				...margin,
			}),
			[margin],
		);

		const hasSpread = yPrevMax !== yPrevMin || yMax !== yMin;
		const hasInnerSpread =
			yInnerPrevMax !== yInnerPrevMin || yInnerMax !== yInnerMin;

		return (
			<div
				key={`linegroup_${id}`}
				ref={ref}
				style={LineGroupStyles}
				className={cn(
					className,
					'asteria-graph-line-group',
					'asteria-graph-line-group-lines',

					{
						'asteria--has-spread': hasSpread,
						'asteria--has-inner-spread': hasInnerSpread,
					},

					types.map((t) => `asteria-graph-line-group-${t}`),
					{
						'asteria-state-active': active,
						'asteria-state-hover': hover,
					},
				)}
			>
				{mode !== 'credit' && <SpreadHelpButton />}

				<Background className="asteria-graph-line-group-background" />
				<Spread
					className={cn(
						'asteria-component__graph-spread',
						'asteria--variant-outer',
					)}
					id={id}
					yPrevMax={yPrevMax}
					yPrevMin={yPrevMin}
					yMax={yMax}
					yMin={yMin}
					yValue={yValue}
					yPrevValue={yPrevValue}
					belowZero={value < 0}
					maxValue={max}
					minValue={min}
					value={forecastValue}
					credit={credit}
					tooltip
					zero={zero}
					layout={layout}
					mode={mode}
				/>

				<Spread
					className={cn(
						'asteria-component__graph-spread',
						'asteria--variant-inner',
					)}
					id={`inner-${id}`}
					yPrevMax={yInnerPrevMax}
					yPrevMin={yInnerPrevMin}
					yMax={yInnerMax}
					yMin={yInnerMin}
					yValue={0}
					yPrevValue={0}
					credit={credit}
					belowZero={forecastValue < 0}
					zero={zero}
					layout={layout}
					mode={mode}
				/>

				<svg
					height="100%"
					width="100%"
					className={cn(
						'asteria-graph-line',
						{ 'asteria--feature-below-zero': hasBelowZeroFeature },
						{
							'asteria-graph-line-below-zero': value < 0,
						},
					)}
					preserveAspectRatio="none"
				>
					<defs>
						<linearGradient id="fade-out">
							<stop
								offset="0%"
								stopOpacity={1}
								className={cn(
									'asteria-graph-line-gradient-start',
									{
										'asteria--feature-below-zero':
											hasBelowZeroFeature,
									},
									{
										'asteria-graph-line-below-zero':
											value < 0,
									},
								)}
							/>
							<stop
								offset="100%"
								stopOpacity={0}
								className={cn(
									'asteria-graph-line-gradient-end',
									{
										'asteria--feature-below-zero':
											hasBelowZeroFeature,
									},
									{
										'asteria-graph-line-below-zero':
											value < 0,
									},
								)}
							/>
						</linearGradient>
					</defs>
					{/* TODO: Update line intersect data */}
					<Line
						key={`line-${id}`}
						id={id}
						prevId={prevId}
						index={index}
						height={height}
						isFadingOut={isFadingOut}
						margin={margin}
						onClick={onClick}
						layout={layout}
						first={first}
						last={last}
						center={center}
						setTarget={setTarget}
						intersectData={intersectData}
						parts={parts}
						mode={mode}
					/>
				</svg>

				{hasSpread && mode !== 'credit' ? (
					<FeatureFlag feature="graph-spread-info-dots">
						{!isCurrent ? (
							<SpreadInfo
								id={id}
								prev={{
									yMin: yPrevMin,
									yMax: yPrevMax,
									yCenter: yPrevValue,

									max: prevMax,
									min: prevMin,
									center: prevValue,
									value: prevValue,
								}}
								next={{
									yMin: yMin,
									yMax: yMax,
									yCenter: yValue,

									max: max,
									min: min,
									center: value,
									value: value,
								}}
								mode={mode}
							/>
						) : null}
					</FeatureFlag>
				) : null}
			</div>
		);
	},
);

LineColumn.displayName = 'LineColumn';

const LineDotGroup = React.memo((props) => {
	const {
		id,
		index,
		onboardingStep,
		// width,
		margin,
		layout,
		center,
		parts,
	} = props;

	const { mouseEnterAction, mouseLeaveAction } =
		useContext(GraphActionsContext);

	const range = useSelector(
		(store) =>
			Selectors.graph.range(store, {
				formatEach: (value) => ({ id: value?.id }),
			}),
		(a, b) => isEqual(a, b),
	);

	const group = useSelector(
		(store) =>
			Selectors.graph.barGroups(store, {
				format: (groups) =>
					findGroup({
						groups: groups,
						id: id,
						range: range,
						index: index,
					}),
			}),
		(a, b) => isEqual(a, b),
	);

	const types = useSelector(
		(store) =>
			Selectors.graph.range(store, {
				id: id,
				format: (range) => range?.[0]?.types ?? [],
			}),
		(a, b) => isEqual(a, b),
	);

	const active = useSelector(
		(store) => Selectors.graph.active(store, id),
		(a, b) => isEqual(a, b),
	);

	const hover = useSelector(
		(store) => Selectors.graph.hover(store, id),
		(a, b) => isEqual(a, b),
	);

	const $minValue = useSelector(
		(store) => Selectors.graph.minValue(store, parts),
		(a, b) => isEqual(a, b),
	);

	const maxValue = useSelector(
		(store) => Selectors.graph.maxValue(store, parts),
		(a, b) => isEqual(a, b),
	);

	const minValue = layout === 'stacked' ? 0 : $minValue;

	const showRisk = useSelector(selectors.showRisk, (a, b) => isEqual(a, b));

	const mouseEnter = useCallback(
		() => mouseEnterAction({ group }),
		[group, mouseEnterAction],
	);

	const mouseLeave = useCallback(
		() => mouseLeaveAction({ group }),
		[group, mouseLeaveAction],
	);

	const DotStyles = React.useMemo(
		() => ({
			pointerEvents: 'none',
			position: 'relative',
			transform: 'translateX(-50%)',
			// width,
			zIndex: 2,
			...margin,
		}),
		[margin],
	);

	const DotInnerStyles = React.useMemo(
		() => ({
			// pointerEvents: 'auto',
			pointerEvents: 'none',
			height: '100%',
			width: '100%',
			transform: 'translateX(50%)',
		}),
		[],
	);

	return (
		<div
			key={`linegroup_dot_${id}`}
			className={cn(
				'asteria-graph-line-group',
				'asteria-graph-line-group-dot',
				types.map((t) => `asteria-graph-line-group-${t}`),
				{
					'asteria-state-active': active,
					'asteria-state-hover': hover,
					'asteria-graph-line-group-beacon':
						id === onboardingStep?.id,
				},
			)}
			style={DotStyles}
		>
			<div
				style={DotInnerStyles}
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
			/>
			<LineDot
				key={`dot-${id}`}
				group={group}
				maxValue={maxValue}
				minValue={minValue}
				$minValue={$minValue}
				layout={layout}
				center={center}
				animated={showRisk}
				becon={id === onboardingStep?.id}
			/>
		</div>
	);
});

LineDotGroup.displayName = 'LineDotGroup';

/**
 * @template TGroup
 * @template TRange
 * @param {{ groups: { [date: string]: TGroup }, id: string, range: TRange[], index: number }} options
 */
export function findGroup(options) {
	const { groups, id, range } = options;

	const group = groups[id];

	if (!group) {
		const rangeIndex = range.findIndex((value) => groups?.[value?.id]);

		if (rangeIndex !== -1) {
			const group = groups[range[rangeIndex].id];

			return {
				...group,
				lines: group.lines.map((value) => {
					if (value?.types?.includes?.('credit')) {
						return value;
					}

					return {
						...value,
						min: 0,
						max: 0,
						value: 0,
					};
				}),
			};
		}
	}

	return group || {};
}

const LineGraph = React.memo(
	({ layout, className, width = 0, margin, parts = [], onClick, mode }) => {
		const range = useSelector(
			(store) =>
				Selectors.graph.range(store, {
					formatEach: (value) => value?.id,
				}),
			(a, b) => isEqual(a, b),
		);

		const steps = useSelector(Selectors.graph.steps, (a, b) =>
			isEqual(a, b),
		);

		const onboardingStep = useMemo(() => ({ id: false, type: false }), []);

		let center = 100;

		if (layout === 'stacked') {
			const size = steps.length - 1;
			const zeroIndex = steps.findIndex(({ value: v }) => v === 0);
			// const topZone = (size - zeroIndex) * (100 / size);
			const bottomZone = (size - (size - zeroIndex)) * (100 / size);

			center = bottomZone;
		}

		return (
			<>
				{range.map((id, index) => {
					if (index === 0) {
						return null;
					}

					return (
						<LineGroupWrapper
							key={index}
							position={index}
							first={index === 1}
							last={index === range.length - 1}
							mode={mode}
						>
							<LineColumn
								id={id}
								prevId={range?.[index - 1]}
								index={index}
								className={className}
								layout={layout}
								width={width}
								margin={margin}
								first={index === 1}
								last={index === range.length - 1}
								onClick={onClick}
								center={center}
								parts={parts}
								mode={mode}
							/>
						</LineGroupWrapper>
					);
				})}
				{range.slice(1, -1).map((id, index) => {
					return (
						<LineGroupWrapper
							key={`linegroup_dot_${index}`}
							position={index + 1}
							className="asteria-graph-line-group-wrapper-info"
							mode={mode}
						>
							<LineDotGroup
								id={id}
								index={index}
								onboardingStep={onboardingStep}
								width={width}
								margin={margin}
								layout={layout}
								center={center}
								parts={parts}
								mode={mode}
							/>
						</LineGroupWrapper>
					);
				})}
			</>
		);
	},
);

LineGraph.displayName = 'LineGraph';

export default LineGraph;
export { LineColumn, LineDotGroup };
