import React from 'react';

import ClientsView from '@asteria/view-clients';

import { LayoutContext } from '../../context';

import './styles.scss';

const ClientsPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<ClientsView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

ClientsPage.displayName = 'ClientsPage';

ClientsPage.propTypes = {};

export default ClientsPage;
