import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { Translation, TranslationService } from '@asteria/language';

import { useModalActions } from '../../modal-collection';

import './styles.scss';

const TermsAndConditions = React.memo((props) => {
	const { className } = props;

	const { close } = useModalActions();

	return (
		<Wrapper className={className} scroll>
			<Header onClose={close}>
				{TranslationService.get('signup.terms.title')}
			</Header>
			<Content scroll>
				<Translation
					size="text"
					translationKey="signup.terms"
					Component={Text}
				/>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						variant="primary"
						label={TranslationService.get('action.close')}
						onClick={close}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

TermsAndConditions.displayName = 'TermsAndConditions';

TermsAndConditions.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default TermsAndConditions;
