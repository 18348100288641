import React, { useCallback } from 'react';

import styled from 'styled-components';

import Dropdown from '@asteria/component-core/dropdown';
import { Text } from '@asteria/component-core/typography';

// import DatalayerContext from '@asteria/services-datalayer/react/context';
import { FeatureFlag, useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const TimeOptions = [
	{
		label: 'app.timeselector.weeks',
		value: 'week',
	},
	{
		label: 'app.timeselector.months',
		value: 'month',
	},
	{
		label: 'app.timeselector.years',
		value: 'year',
	},
];

const Time = styled(({ className, key, active = false, onClick, children }) => (
	<div
		role="button"
		className={cn(className, 'asteria-timeslice-selector-time', {
			'asteria-state-active': active,
		})}
		onClick={() => onClick(key)}
		onKeyPress={() => onClick(key)}
		tabIndex="-1"
	>
		{children}
	</div>
))`
	cursor: pointer;
`;
Time.displayName = 'Time';

const Selector = styled(({ className }) => {
	// const { dispatch } = useContext(DatalayerContext);

	// const [size = false] = useStore(
	// 	'store-appstate',
	// 	({ timesliceSize }) => timesliceSize,
	// 	true,
	// );

	const size = 'month';

	const hasTimeDropdownFeature = useFeature('timeSpanDropdown');
	const hasImprovementsFeature = useFeature('asteria-improvements');

	const updateRange = useCallback(() => {
		// if (size === timesliceSize) {
		// 	return;
		// }
		// dispatch(setTimesliceSize(timesliceSize));
		// dispatch(
		// 	requestNewRange('cashflow-bar-graph', false, timesliceSize),
		// );
		// dispatch(
		// 	requestGraphData('cashflow-bar-graph', {
		// 		size: timesliceSize,
		// 	}),
		// );
		// dispatch(
		// 	setCurrentDate(addTimeslice(new Date(), timesliceSize, -4)),
		// );
		// dispatch(clearGraphData('cashflow-bar-graph'));
	}, [size]);

	return (
		<div className={cn(className, 'asteria-timeslice-selector-wrapper')}>
			<div className={cn(className, 'asteria-timeslice-selector')}>
				<FeatureFlag feature="asteria-improvements" invert>
					<FeatureFlag feature="timeSpanDropdown" invert>
						<Time
							key="week"
							active={size === 'week'}
							onClick={() => updateRange('week')}
						>
							<Text
								size="label"
								title={TranslationService.get(
									'app.timeselector.label',
								)}
							>
								{TranslationService.get(
									'app.timeselector.weeks',
								)}
							</Text>
						</Time>
						<Time
							key="month"
							active={size === 'month'}
							onClick={() => updateRange('month')}
						>
							<Text
								size="label"
								title={TranslationService.get(
									'app.timeselector.label',
								)}
							>
								{TranslationService.get(
									'app.timeselector.months',
								)}
							</Text>
						</Time>
						<Time
							key="year"
							active={size === 'year'}
							onClick={() => updateRange('year')}
						>
							<Text
								size="label"
								title={TranslationService.get(
									'app.timeselector.label',
								)}
							>
								{TranslationService.get(
									'app.timeselector.years',
								)}
							</Text>
						</Time>
					</FeatureFlag>
				</FeatureFlag>
			</div>
			<div
				className={cn(
					className,
					'asteria-timeslice-selector-as-dropdown',
				)}
			>
				{hasTimeDropdownFeature || hasImprovementsFeature ? (
					<Dropdown
						className={cn(
							className,
							'asteria-button-timespan-select',
						)}
						size="md"
						type="link"
						iconClosed="calendar"
						iconOpen="calendar"
						title={TranslationService.get([
							'app.timeselector.label',
							'app.timeselector.label.title',
							'app.timeselector.label.dropdown.title',
						])}
						alt={TranslationService.get('app.timeselector.label')}
						data-tooltip={TranslationService.get(
							'app.timeselector.label',
						)}
						direction={['down', 'left']}
						options={TimeOptions}
						selected={TimeOptions.filter(
							({ value }) => value === size,
						)}
						onChange={({ value }) => updateRange(value)}
					/>
				) : null}
			</div>
		</div>
	);
})`
	display: flex;
	position: relative;
	margin-top: 0;
`;
Selector.displayName = 'Selector';

export default Selector;
