import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Splash from '@asteria/component-splash';
import { useFeature } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const OnboardingSplash = (props) => {
	const { className } = props;

	const dispatch = useDispatch();

	const hasBankFeature = useFeature('bank-integrations');
	const hasTypedFeature = useFeature('onboarding-splash-types');

	const hasConnectedBanks = useSelector(
		(store) =>
			!!IntegrationStore.selectors.integrations(store, {
				type: 'bank',
				connected: true,
			}).length,
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'connect') {
				dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
						data: { type: data },
					}),
				);
			}
		},
		[dispatch],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	const path = React.useMemo(() => {
		const base = 'pages.onboarding.splash.generic';

		if (hasTypedFeature) {
			let type = 'erp';

			if (hasBankFeature && !hasConnectedBanks) {
				type = 'bank';
			}

			return [].concat(base).concat(`pages.onboarding.splash.${type}`);
		}

		return [].concat(base);
	}, [hasBankFeature, hasConnectedBanks, hasTypedFeature]);

	return (
		<Splash
			className={cn('asteria-component__onboarding__splash', className)}
			path={path}
			actions={actions}
		/>
	);
};

OnboardingSplash.displayName = 'OnboardingSplash';

OnboardingSplash.propTypes = {
	className: PropTypes.string,
};

export default OnboardingSplash;
