import { format, parseISO } from 'date-fns';

import { TranslationService } from '@asteria/language';

function transformDescription(value) {
	if (value?.startsWith('$')) {
		const clean = value.replace('$', '');

		return TranslationService.get(
			`list.transactions.transaction.description.${clean}`,
		);
	}

	return value || 'transaction.unknown';
}

function transformPaymentDate(value) {
	return format(parseISO(value || new Date().toISOString()), 'yyyy-MM-dd');
}

function transformStatus(value) {
	const status = value?.toLowerCase?.() ?? 'paid';

	return TranslationService.get(
		`list.transactions.transaction.status.${status}`,
		status,
	);
}

function transformTag(value) {
	const { name, category } = value || {};
	const { name: categoryName } = category || {};

	if (categoryName === `$custom`) {
		return name;
	}

	return TranslationService.get([
		`tags.${name?.replace('$', '')}`,
		`tags.${categoryName?.replace('$', '')}.${name?.replace('$', '')}`,
	]);
}

/**
 * @param { { field: string, direction: string } } sorting
 * @returns { number }
 */
function apply(sorting) {
	const { field, direction } = sorting;

	function getValues(a, b) {
		if (direction === 'ASC') {
			return [a, b];
		}

		return [b, a];
	}

	return function (a, b) {
		switch (field) {
			case 'description': {
				const [source, target] = getValues(
					transformDescription(a?.description),
					transformDescription(b?.description),
				);

				return source.localeCompare(target);
			}

			case 'tags': {
				const [source, target] = getValues(
					transformTag(a?.meta?.tags?.[0]),
					transformTag(b?.meta?.tags?.[0]),
				);

				return source.localeCompare(target);
			}

			case 'currency': {
				const [source, target] = getValues(
					a?.sums?.original?.currency ?? 'SEK',
					b?.sums?.original?.currency ?? 'SEK',
				);

				return source.localeCompare(target);
			}

			case 'status': {
				const [source, target] = getValues(
					transformStatus(a?.status),
					transformStatus(b?.status),
				);

				return source.localeCompare(target);
			}

			case 'risk': {
				return 0;
			}

			case 'total': {
				const [source, target] = getValues(
					a?.sums?.display?.total,
					b?.sums?.display?.total,
				);

				return source - target;
			}

			default: {
				const [source, target] = getValues(
					transformPaymentDate(a?.paymentDate),
					transformPaymentDate(b?.paymentDate),
				);

				return source.localeCompare(target);
			}
		}
	};
}

/**
 * @param { { sorting: { field: string, direction: string }, groups: object, items: object } } options
 */
export function applySorting(options = {}) {
	const { sorting, groups, items } = options;

	return Object.fromEntries(
		Object.entries(groups).map(([key, value]) => [
			key,
			{
				...value,
				items: Array.from(value.items)
					.map((id) => items[id])
					.sort(apply(sorting))
					.map((object) => object?._id ?? object.id),
			},
		]),
	);
}
