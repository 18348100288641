/**
 * @template T
 * @param { T }
 * @returns { T }
 */
export function withPagination(fn) {
	return async (options, context) => {
		const { pageFilters = {}, backward = false } = options;

		const size = pageFilters?.first ?? pageFilters?.last ?? 50;

		const response = await fn(
			{ ...options, pageFilters: pageFilters },
			context,
		).catch(() => ({ edges: [] }));

		let objects = (response?.edges ?? []).map(({ node }) => node);

		const count = response?.pageInfo?.count ?? 0;

		if (response?.pageInfo?.hasNextPage && count > size) {
			const key = backward ? 'before' : 'after';
			const cursor = backward
				? response?.pageInfo?.startCursor
				: response?.pageInfo?.endCursor;

			objects = objects.concat(
				await withPagination(fn)(
					{
						...options,
						pageFilters: { ...pageFilters, [key]: cursor },
					},
					context,
				),
			);
		}

		return objects;
	};
}
