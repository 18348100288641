import React from 'react';

import Dropdown from '@asteria/component-core/dropdown';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import LoanContext from '../../../../context/loan';

import LoanTimeSelectorItem from './item';

import './styles.scss';

const LoanTimeSelector = React.memo(() => {
	const { range } = React.useContext(LoanContext);

	const toggle = React.useMemo(
		() => ({
			icon: 'date',
			tooltip: {
				tooltip: TranslationService.get([
					'app.timeselector.title',
					'app.timeselector.dropdown.title',
					'app.timeselector.dropdown.tooltip',
					'financial.loan.timeselector.tooltip',
				]),
				variant: 'alt',
			},
		}),
		[],
	);

	return (
		<Dropdown
			toggle={toggle}
			className={cn(
				'asteria-component__financial-loan-selector',
				'asteria--variant-time',
			)}
		>
			<LoanTimeSelectorItem active={range === 'year'} type="year">
				{TranslationService.get(['app.timeselector.years'])}
			</LoanTimeSelectorItem>
			<LoanTimeSelectorItem active={range === 'month'} type="month">
				{TranslationService.get(['app.timeselector.months'])}
			</LoanTimeSelectorItem>
		</Dropdown>
	);
});

LoanTimeSelector.displayName = 'LoanTimeSelector';

export default LoanTimeSelector;
