import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip/chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { getFaceIcon } from './utils';

import './styles.scss';

const SatisfactionSelector = React.memo((props) => {
	const { onClick, value } = props;

	return (
		<Chip
			onClick={onClick}
			className={cn(
				'asteria-component__satisfaction-selector',
				'asteria--state-closed',
			)}
		>
			<Group
				direction="horizontal"
				verticalAlign="center"
				horizontalAlign="center"
			>
				<Icon
					className={cn(
						'asteria-component__satisfaction-selector-icon',
						'asteria--variant-value',
					)}
					icon={getFaceIcon({ value: value })}
				/>
				<Text className="asteria-component__satisfaction-selector-label">
					{TranslationService.get([
						'forecaster.page.category.section.probability.selection.label',
						`forecaster.page.category.section.probability.selection.${value}.label`,
					])}
				</Text>
				<Icon
					className={cn(
						'asteria-component__satisfaction-selector-icon',
						'asteria--variant-edit',
					)}
					icon="edit"
				/>
			</Group>
		</Chip>
	);
});

SatisfactionSelector.displayName = 'SatisfactionSelector';

SatisfactionSelector.propTypes = {
	onClick: PropTypes.func,
	value: PropTypes.number,
};

export default SatisfactionSelector;
