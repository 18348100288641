/**
 * @typedef { import('./types').Props } Props
 */

export function useDateRange(date) {
	if (typeof date === 'string') {
		return 'default';
	}

	if (date?.visible) {
		return 'custom';
	}

	return null;
}
