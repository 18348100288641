import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Teaser from '@asteria/component-core/teaser';

import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { cn } from '@asteria/utils-funcs/classes';

import SimulationList from '../../../../components/list';
import { useModalActions } from '../../../../components/modal-collection';
import { LoanSettingsModal } from '../../../../modals';

import '../../styles.scss';

const SectionLoan = React.memo((props) => {
	const { onAction } = props;

	const { open } = useModalActions();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'simulation:create') {
				return onAction?.('redirect', '/financial/loan');
			}

			if (action === 'simulation:edit') {
				const ID = data?._id ?? data?.id;

				open(<LoanSettingsModal />, {
					simulationId: ID,
					callback: onAction,
				});

				return;
			}

			if (action === 'simulation:view') {
				const ID = data?._id ?? data?.id;

				return onAction?.('redirect', `/financial/loan/${ID}`);
			}

			return onAction?.(action, data);
		},
		[onAction, open],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	const columns = React.useMemo(
		() => [
			{ key: 'name' },
			{ key: 'total', align: 'right' },
			{ key: 'actions', align: 'right' },
		],
		[],
	);

	const rows = useSelector((store) =>
		ScenarioStore.selectors.scenarios(store, { type: 'LOAN' }),
	);

	if (rows.length) {
		return (
			<SimulationList
				onAction={handleAction}
				type="loan"
				columns={columns}
				rows={rows}
			/>
		);
	}

	return (
		<Teaser
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-loan',
			)}
			path="pages.financial.teaser.home.loan"
			actions={actions}
			onAction={handleAction}
		/>
	);
});

SectionLoan.displayName = 'SectionLoan';
SectionLoan.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default SectionLoan;
