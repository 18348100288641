import React from 'react';

import PropTypes from 'prop-types';

import { Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import SearchInputContent from './content';

import '../styles.scss';

const SearchInput = React.memo((props) => {
	const { className, onAction, onFocus, onBlur } = props;

	const ref = React.useRef(null);

	const onSearchIconClick = React.useCallback(() => {
		Analytics.event('search.input.icon.click');

		ref.current.focus();
	}, []);

	const iconTooltip = React.useMemo(
		() => ({
			tooltip: TranslationService.get('search.icon.tooltip'),
			variant: 'alt',
		}),
		[],
	);

	return (
		<Input
			className={cn('asteria-component__search-input', className)}
			name="search-value"
			placeholder={TranslationService.get('search.placeholder')}
			icon="magnifier"
			iconPosition="first"
			iconTooltip={iconTooltip}
			onFocus={onFocus}
			onBlur={onBlur}
			iconSize="md"
			ref={ref}
			onIconClick={onSearchIconClick}
		>
			<SearchInputContent onAction={onAction} />
		</Input>
	);
});

SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

export default SearchInput;
