import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as AppStore from '@asteria/datalayer/stores/app';

import Analytics from '@asteria/utils-analytics';

const Updater = React.memo(() => {
	const dispatch = useDispatch();

	const { id } = useParams();

	const credit = useSelector((store) =>
		AccountStore.selectors.simulation(store, { simulationId: id }),
	);

	React.useLayoutEffect(() => {
		Analytics.startFlow('financial.credit');

		return () => {
			Analytics.endFlow('financial.credit');
		};
	}, []);

	React.useEffect(() => {
		dispatch(AppStore.setFilters([]));
		dispatch(AppStore.setMode('credit'));
		dispatch(AccountStore.setDynamicCredit(credit));

		return () => {
			dispatch(AppStore.setFilters([]));
			dispatch(AppStore.setMode(null));
			dispatch(AccountStore.setDynamicCredit(null));
		};
	}, [credit, dispatch]);

	return null;
});

Updater.displayName = 'Updater';
Updater.propTypes = {};

export default Updater;
