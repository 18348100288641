import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import '../styles.scss';

const ClientRemove = (props) => {
	const { onClose, onAction, data } = props;

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);

	const config = useConfig('modals.client.remove', { deep: true });

	const handleSubmit = useCallback(() => {
		Analytics.event('forecaster.clients.remove', data);
		return onAction?.('client:remove', data);
	}, [onAction, data]);

	const analytics = useDeepMemo(() => ({ data: data }), [data]);

	return (
		<Form defaultValues={data} onSubmit={handleSubmit}>
			<Wrapper scroll>
				<Header onClose={onClose}>
					{TranslationService.get(
						'clients.remove.title',
						undefined,
						data,
					)}
				</Header>
				<Content>
					<FormWrapper>
						<Content>
							{config?.contenter ? (
								<Contenter
									content={config?.contenter}
									data={data}
								/>
							) : (
								<TextGroup>
									<Title type="title" size="xs">
										{TranslationService.get(
											'clients.remove.body.label',
										)}
									</Title>
									<Text>
										{TranslationService.get(
											'clients.remove.body',
											undefined,
											data,
										)}
									</Text>
								</TextGroup>
							)}
						</Content>
					</FormWrapper>
				</Content>
				<Footer>
					<FooterSection>
						{hasHistory ? (
							<Button
								variant="tertiary"
								analyticsKey="clients.remove.button.back"
								analytics={analytics}
								label={TranslationService.get([
									'button.back',
									'action.back',
									'clients.remove.button.back',
								])}
								onClick={onClose}
							/>
						) : (
							<Button
								variant="tertiary"
								analyticsKey="clients.remove.button.cancel"
								analytics={analytics}
								label={TranslationService.get([
									'button.cancel',
									'action.cancel',
									'clients.remove.button.cancel',
								])}
								onClick={onClose}
							/>
						)}
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							analyticsKey="clients.remove.button.remove"
							analytics={analytics}
							label={TranslationService.get([
								'button.remove',
								'action.remove',
								'clients.remove.button.remove',
							])}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</Form>
	);
};

ClientRemove.displayName = 'ClientRemove';

ClientRemove.propTypes = {
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	data: PropTypes.object,
};

const ClientRemoveModal = (props) => {
	const { open, onClose, className } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	return (
		<Modal
			className={cn(
				'asteria--type-clients',
				'asteria--action-remove',
				className,
			)}
			size="sm"
			open={open}
			onClose={handleClose}
		>
			<ClientRemove {...props} onClose={handleClose} />
		</Modal>
	);
};

ClientRemoveModal.displayName = 'ClientRemoveModal';

ClientRemoveModal.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
	open: PropTypes.bool,
};

export default ClientRemoveModal;
export { ClientRemove };
