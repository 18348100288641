import { AuthService, SupportService } from '@asteria/backend-utils-services';

import { setFeedback } from '@asteria/datalayer/stores/app';

export async function reset({ accessToken, dispatch }) {
	try {
		await SupportService.feedback.reset({}, { token: accessToken });
		dispatch?.(setFeedback({}));
	} catch (e) {
		// Do nothing
	}
}

export async function create({ accessToken, feedback, dispatch }) {
	try {
		await SupportService.feedback.add(
			{ input: feedback },
			{ token: accessToken },
		);

		const response = await AuthService.auth
			.me({ fields: `feedback` }, { token: accessToken })
			.catch(() => {});

		dispatch?.(setFeedback(response.feedback));
	} catch (e) {
		// Do nothing
	}
}
