import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

function useHasScrolledPassed(
	ref,
	offset = 0,
	target = document.getElementsByClassName('asteria-wings-layout'),
) {
	const [passed, setPassed] = useState(false);

	const handleScroll = useCallback(() => {
		if (!ref || !ref.current) {
			return;
		}

		const node = target?.[0];

		if (!node) {
			return;
		}

		const box = ref.current.getBoundingClientRect();
		const targetBox = node.getBoundingClientRect();

		if (box.bottom + offset - targetBox.top < 0) {
			setPassed(true);
		} else {
			setPassed(false);
		}
	}, [offset, ref, target?.[0]]);

	useLayoutEffect(() => {
		if (!ref || !ref.current) {
			return () => {};
		}

		const node = target?.[0];

		if (!node) {
			return () => {};
		}

		node?.addEventListener?.('scroll', handleScroll);

		return () => {
			node?.removeEventListener?.('scroll', handleScroll);
		};
	}, [handleScroll, ref, target?.[0]]);

	return useMemo(() => passed, [passed]);
}

export default useHasScrolledPassed;
