import { get, set, unset } from 'lodash-es';
import { v4 as uuid } from 'uuid';

const getPath = ({
	type = null,
	date = null,
	category = null,
	tag = null,
	uuid = null,
	status = 'FORECAST',
	field = null,
}) => {
	if (uuid) {
		return [
			type,
			'data',
			date,
			status,
			'categories',
			category,
			'tags',
			tag,
			'transactions',
			uuid,
			field,
		]
			.filter(Boolean)
			.join('.');
	}

	if (tag && category !== '$type') {
		return [
			type,
			'data',
			date,
			status,
			'categories',
			category,
			'tags',
			tag,
			field,
		]
			.filter(Boolean)
			.join('.');
	}

	if (category && category !== '$type') {
		return [type, 'data', date, status, 'categories', category, field]
			.filter(Boolean)
			.join('.');
	}

	if (date) {
		return [type, 'data', date, status, field].filter(Boolean).join('.');
	}

	if (type) {
		return [type, 'data', field].filter(Boolean).join('.');
	}

	return '';
};

const createChange = ({ op, name, value, event, previousValue }, form) => {
	const $previousValue = previousValue ?? get(form, name);

	if (form) {
		if (op === 'set') {
			set(form, name, value);
		} else if (op === 'unset') {
			unset(form, name, value);
		}
	}

	return {
		uuid: uuid(),
		op,
		name,
		value,
		previousValue: $previousValue,
		$event: event,
	};
};

export default (name) => {
	const [type, , date, status, , category, , tag, , uuid] = name.split('.');

	return {
		type: type,
		date: date,
		status: status,
		category: category,
		tag: tag,
		uuid: uuid,

		statePath: getPath({
			type: type,
			date: date,
			status: status,
			category: category,
			tag: tag,
			field: '$state',
		}),
	};
};

export { getPath, createChange };
