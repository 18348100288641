import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../PropTypes';
import { Title } from '../typography';

import './styles.scss';

const PageTitle = React.memo((props) => {
	const { className, children, title, size = 'page-title' } = props;

	return (
		<div className={cn('asteria-component__page-header__title', className)}>
			{title ? <Title size={size}>{title}</Title> : null}
			{children}
		</div>
	);
});

PageTitle.displayName = 'PageTitle';

PageTitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	title: PropTypes.string,
	size: SizeProp(),
};

export default PageTitle;
