import React from 'react';

import PropTypes from 'prop-types';

import Form from '@asteria/component-form';

import Auth2FADeactivateContent from './content';

import './styles.scss';

const Auth2FADeactivate = React.memo((props) => {
	return (
		<Form>
			<Auth2FADeactivateContent {...props} />
		</Form>
	);
});

Auth2FADeactivate.displayName = 'Auth2FADeactivate';

Auth2FADeactivate.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	showHeaderClose: PropTypes.bool,
};

export default Auth2FADeactivate;
