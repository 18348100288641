/* eslint-disable spellcheck/spell-checker */
import { subSeconds } from 'date-fns';

import AsteriaCore from '@asteria/core';

import { NotificationService } from '@asteria/backend-utils-services';

import store from '@asteria/datalayer';
import {
	removeNotification,
	setNotificationOptions,
	setNotifications,
} from '@asteria/datalayer/stores/notifications';

NotificationService.notification.extend({
	getQuery: () => `
		mutation MarkAsViewed($id: ObjectId!) {
			updateNotificationSetViewed(input: { _id: $id, value: true }) {
				ok
			}
		}
	`,
	key: `markAsViewed`,
	loggerMethod: `services.notification-service.markAsViewed`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.notification-service.markAsViewed`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

NotificationService.notification.extend({
	getQuery: () => `
		mutation RemoveNotificationBadge(
			$input: [NotificationUpdateOptionInput!]!
		) {
			updateNotificationSetBadge(input: $input) {
				ok
			}
		}
	`,
	key: `removeNotificationBadge`,
	loggerMethod: `services.notification-service.removeNotificationBadge`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.notification-service.removeNotificationBadge`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

NotificationService.notification.extend({
	getQuery: () => `
		query GetNotifications {
			notifications(filters: { options: { viewed: false } }) {
				edges {
					node {
						id
						type
						data
						title
						notificationKey
						options {
							sticky
							badge
							viewed
						}
						createdAt
					}
				}
			}
		}
	`,
	key: `fetch`,
	loggerMethod: `services.notification-service.fetch`,
	onError: (err, { context }) => {
		if (!err?.__CANCEL__) {
			const { token = null, tokenData: { sessionId = null } = {} } =
				context;

			AsteriaCore.Logger.error(err, {
				method: `services.notification-service.fetch`,
				sessionId: sessionId,
				token: token,
			});
		}

		throw err;
	},
});

function unique(response) {
	return Object.values(
		response.reduce((acc, object) => ({ ...acc, [object.id]: object }), {}),
	);
}

function applyTestNotifications(response) {
	return [
		{
			id: 'b79f4afa-f179-4c40-b385-545cf1d4f2e3',
			options: { badge: false, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'text',
			notificationKey: 'forecaster.withdraw',
			title: 'Ea enim excepturi. Alias labore pariatur consequatur delectus soluta ut aspernatur et est. Vitae possimus distinctio magnam maiores enim aut. Iusto nulla placeat ut. Officiis ducimus asperiores.',
			data: {
				body: 'Non voluptas officiis minima alias et. Libero labore inventore. At exercitationem quo. Eum nam quas autem quis. Vitae deserunt repudiandae. Qui doloribus similique. Id omnis et iusto aut. Consequatur nobis illo reprehenderit harum cumque.',
				action: {
					text: 'forecaster.withdraw.action',
					action: 'go',
					data: '/forecaster/withdraw',
				},
			},
		},
		{
			id: '1fcd3a9c-9eca-4d02-aeb6-1c12b7c16d25',
			options: { badge: true, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'text',
			notificationKey: 'forecaster.withdraw',
			title: 'forecaster.withdraw.title',
			data: {
				body: 'forecaster.withdraw.content',
				action: {
					text: 'forecaster.withdraw.action',
					action: 'go',
					data: '/forecaster/withdraw',
				},
			},
		},
		...(store.getState()?.integrations?.items ?? []).map((object) => ({
			id: '1fcd3a9c-9eca-4d02-aeb6-1c12b7c16d26',
			options: { badge: true, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'text',
			notificationKey: 'onboarding.edit',
			title: 'onboarding.notification.edit.title',
			data: {
				body: [
					'onboarding.notification.edit.content',
					`onboarding.notification.${object?.type}.edit.content`,
					`onboarding.notification.${object?.key}.edit.content`,
					`onboarding.notification.${object?.type}.${object?.key}.edit.content`,
				],
				action: {
					text: 'onboarding.notification.edit.action',
					action: 'go',
					data: `/integrations/edit/${object?._id ?? object.id}`,
				},
			},
		})),
	].concat(response);
}

function applyTestCredit(response) {
	return [
		{
			id: '3f24e357-0d59-44cc-b7d9-05f55cac4f3a',
			options: { badge: true, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'text',
			title: 'notification.credit.title',
			data: {
				body: 'notification.credit.body',
				action: {
					text: ['action.view', 'notification.credit.action'],
					action: 'go',
					data: '/credit',
				},
			},
		},
	].concat(response);
}

function applyTestCurrency(response) {
	return [
		{
			id: '5cdf38ea-0e51-4d4b-8309-a63e8df54761',
			options: { badge: true, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'text',
			title: 'notification.currency.usd.title',
			data: {
				body: 'notification.currency.usd.text',
				action: {
					text: ['action.view', 'notification.currency.action'],
					action: 'setFilters',
					data: [{ type: 'currency', code: 'USD' }],
				},
			},
		},
	].concat(response);
}

function applyTestOverdue(response) {
	return [
		{
			id: '696932c4-9ce7-4316-9814-fcae8ccea866s',
			options: { badge: true, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'OVERDUE',
			notificationKey: 'cashflow.invoices-customer.overdue',
			title: 'tags.customer.overdue',
			data: {
				startDate: '2023-01-01T00:00:00.000Z',
				endDate: '2023-01-31T00:00:00.000Z',
				total: 192834.173,
				totalAvg: 182685.00600000002,
				totalAvgPrev: 39273.1677,
				tag: {
					name: '$customer',
					status: 'OVERDUE',
					category: { name: '$invoices' },
				},
				action: {
					graphStartDate: '2023-01-01T00:00:00.000Z',
					listStartDate: '2023-01-01T00:00:00.000Z',
					types: ['DEPOSIT'],
					filters: [
						{
							type: 'tag',
							categoryName: '$invoices',
							tagName: '$customer',
						},
						{ type: 'status', status: 'OVERDUE' },
					],
				},
				count: 12,
			},
		},
	].concat(response);
}

function applyTestDialogs(response) {
	return []
		.concat(
			[
				{ title: 'example' },
				{
					title: 'example-2',
					data: {
						action: { action: 'redirect', data: '/forecaster' },
					},
				},
				{
					title: 'example-3',
					data: {
						content: [
							{
								as: 'Wrapper',
								props: {
									children: [
										{
											as: 'Header',
											props: {
												children: 'Example title',
												onClose: { action: 'onClose' },
											},
										},
										{
											as: 'Content',
											props: {
												children: [
													{
														as: 'Text',
														props: {
															children: `Voluptatibus tenetur reiciendis et rerum alias quam.`,
														},
													},
													{
														as: 'Text',
														props: {
															children: `Voluptatibus tenetur reiciendis et rerum alias quam.`,
														},
													},
													{
														as: 'Text',
														props: {
															children: `Voluptatibus tenetur reiciendis et rerum alias quam.`,
														},
													},
												],
											},
										},
										{
											as: 'Footer',
											props: {
												children: [
													{
														as: 'FooterSection',
														props: {
															position: 'first',
															children: [
																{
																	as: 'Button',
																	props: {
																		label: 'Close',
																		variant:
																			'secondary',
																		onClick:
																			{
																				action: 'onClose',
																			},
																	},
																},
															],
														},
													},
													{
														as: 'FooterSection',
														props: {
															position: 'last',
															children: [
																{
																	as: 'Button',
																	props: {
																		label: 'Redirect',
																		variant:
																			'primary',
																		onClick:
																			[
																				{
																					action: 'onAction',
																					args: [
																						'redirect',
																						'/onboarding',
																					],
																				},
																				{
																					action: 'onClose',
																				},
																			],
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			].map((object, index) => ({
				...object,
				id: ['test-notification-modal', index].join('-'),
				options: { badge: true, viewed: false },
				createdAt: subSeconds(
					new Date(),
					Math.floor(Math.random() * 10),
				).toISOString(),
				type: 'modal',
			})),
		)
		.concat(
			[
				{ title: 'example' },
				{
					title: 'example-2',
					data: {
						action: { action: 'redirect', data: '/forecaster' },
					},
				},
				{
					title: 'example-3',
					data: {
						content: [
							{
								as: 'Wrapper',
								props: {
									children: [
										{
											as: 'Header',
											props: {
												children: {
													as: 'Title',
													props: {
														size: 'sm',
														children:
															'Custom wrapper',
													},
												},
												onClose: { action: 'onClose' },
												postfix: {
													position: 'absolute',
													size: 'xs',
												},
											},
										},
										{
											as: 'Content',
											props: {
												children: [
													{
														as: 'Text',
														props: {
															children: `Eum dolor nesciunt quia quae consequatur iste nam.`,
														},
													},
													{
														as: 'Button',
														props: {
															label: 'Connect ERP',
															variant: 'link',
															icon: 'add',
															onClick: {
																action: 'onAction',
																args: [
																	'go',
																	'/integrations/add/erp',
																],
															},
														},
													},
													{
														as: 'Text',
														props: {
															children: `Eum dolor nesciunt quia quae consequatur iste nam.`,
														},
													},
													{
														as: 'Button',
														props: {
															label: 'Connect Bank',
															variant: 'link',
															icon: 'add',
															onClick: {
																action: 'onAction',
																args: [
																	'go',
																	'/integrations/add/bank',
																],
															},
														},
													},
													{
														as: 'Text',
														props: {
															children: `Eum dolor nesciunt quia quae consequatur iste nam.`,
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			].map((object, index) => ({
				...object,
				id: ['test-notification-dialog', index].join('-'),
				options: { badge: true, viewed: false },
				createdAt: subSeconds(
					new Date(),
					Math.floor(Math.random() * 10),
				).toISOString(),
				type: 'dialog',
			})),
		)
		.concat(response);
}

function applyTestForecaster(response) {
	return [
		{
			id: 'c74373fc-43e4-23dd-99f7-5474b36f89a2',
			options: { badge: true, viewed: false },
			createdAt: subSeconds(
				new Date(),
				Math.floor(Math.random() * 10),
			).toISOString(),
			type: 'modal',
			title: 'splash.feature.forecaster',
			data: {
				action: { action: 'go', data: '/forecaster' },
			},
		},
	].concat(response);
}

/**
 * @param { { accessToken: string, dispatch: any, flags?: { testNotifications?: boolean, testDialogs?: boolean } } } options
 */
export const fetch = async (options) => {
	const { accessToken, dispatch, flags } = options;

	const response = await NotificationService.notification.extension
		.fetch({ isBulk: true }, { token: accessToken })
		.then((response) => (response?.edges ?? []).map(({ node }) => node))
		.catch(() => {
			return [];
		})
		.then((response) => {
			if (flags?.testNotifications) {
				return unique(applyTestNotifications(response));
			}

			return response;
		})
		.then((response) => {
			if (flags?.testDialogs) {
				return unique(applyTestDialogs(response));
			}

			return response;
		})
		.then((response) => {
			if (flags?.testCredit) {
				return unique(applyTestCredit(response));
			}

			return response;
		})
		.then((response) => {
			if (flags?.testCurrency) {
				return unique(applyTestCurrency(response));
			}

			return response;
		})
		.then((response) => {
			// eslint-disable-next-line no-constant-condition
			if (flags?.testForecaster) {
				return unique(applyTestForecaster(response));
			}

			return response;
		})
		.then((response) => {
			if (flags?.testOverdue) {
				return unique(applyTestOverdue(response));
			}

			return response;
		});

	dispatch?.(setNotifications(response));

	return response;
};

/**
 * @param { { accessToken: string, id: string, dispatch: any, flags?: { testNotifications?: boolean, testDialogs?: boolean } } } options
 */
export const markAsViewed = async (options) => {
	const { accessToken, id, dispatch, flags } = options;

	await NotificationService.notification.extension
		.markAsViewed({ id: id }, { token: accessToken })
		.catch(() => {});

	dispatch?.(removeNotification(id));

	if (Object.keys(flags || {}).length === 0) {
		return fetch({
			accessToken: accessToken,
			dispatch: dispatch,
			flags: flags,
		});
	}
};

/**
 * @param { { accessToken: string, IDs: string[], dispatch: any, flags?: { testNotifications?: boolean, testDialogs?: boolean } } } options
 */
export const removeNotificationBadge = async (options) => {
	const { accessToken, IDs, dispatch, flags } = options;

	if (!IDs.length) {
		return;
	}

	await NotificationService.notification.extension
		.removeNotificationBadge(
			{ input: IDs.map((_id) => ({ _id: _id, value: false })) },
			{ token: accessToken },
		)
		.catch(() => {});

	dispatch?.(
		setNotificationOptions({ IDs: IDs, key: 'badge', value: false }),
	);

	if (Object.keys(flags || {}).length === 0) {
		return fetch({
			accessToken: accessToken,
			dispatch: dispatch,
			flags: flags,
		});
	}
};
