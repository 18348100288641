import React from 'react';

import PropTypes from 'prop-types';

import Splash from '@asteria/component-splash';

import { cn } from '@asteria/utils-funcs/classes';

import '../../styles.scss';

const SectionPlaceholder = React.memo(() => {
	return (
		<Splash
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-placeholder',
			)}
			path="pages.financial.splash.home"
		/>
	);
});

SectionPlaceholder.displayName = 'SectionPlaceholder';
SectionPlaceholder.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionPlaceholder;
