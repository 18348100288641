import React from 'react';

import AsteriaCore from '@asteria/core';

export function useChange({ debounce, onChange, isRawEvent }) {
	const throttleOnChange = React.useMemo(() => {
		if (debounce && onChange) {
			return AsteriaCore.utils.throttle(onChange, debounce);
		}

		return null;
	}, [onChange, debounce]);

	return React.useCallback(
		(event) => {
			if (!onChange) {
				return;
			}

			if (!debounce || isRawEvent) {
				return onChange(event);
			}

			throttleOnChange(event);
		},
		[debounce, isRawEvent, onChange, throttleOnChange],
	);
}
