import { useCallback, useState } from 'react';

/**
 * @returns { [ boolean, { open: () => void, close: () => void, toggle: () => void } ] }
 */
export function useModalProperties() {
	const [state, setState] = useState(false);

	const open = useCallback(() => {
		setState(true);
	}, []);

	const close = useCallback(() => {
		setState(false);
	}, []);

	const toggle = useCallback(() => {
		setState((value) => !value);
	}, []);

	return [state, { open, close, toggle }];
}
