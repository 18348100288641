import React from 'react';

import PropTypes from 'prop-types';

import { View as BarGraphBarView } from '@asteria/component-forecaster/components/graph/bars/column/bar';

import { cn } from '@asteria/utils-funcs/classes';

import { useGraphData } from './hooks';
import LoanBarGraphTooltip from './tooltip';

const LoanBarGraphBar = React.memo((props) => {
	const { type, date, status } = props;

	const data =
		useGraphData().find((object) => object?.date === date)?.data ?? {};

	return (
		<BarGraphBarView
			className={cn({
				'asteria-color__forecast': status === 'AMORTIZATION',
			})}
			type={type}
			date={date}
			status={status}
			value={data?.[status]?.total ?? 0}
			tooltip={<LoanBarGraphTooltip data={data} />}
			// hidden
			// invisible
		/>
	);
});

LoanBarGraphBar.displayName = 'LoanBarGraphBar';
LoanBarGraphBar.propTypes = {
	type: PropTypes.string,
	date: PropTypes.string,
	status: PropTypes.string,
};

export default LoanBarGraphBar;
