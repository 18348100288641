import { CompanyService } from '@asteria/backend-utils-services';

import { setCompany } from '@asteria/datalayer/stores/app';

export const FIELDS = `
_id
id
name

settings {
	currency
	flags
}

services {
	id
	serviceId
	status
	data
	companies {
		companyId
		data
	}
	createdAt
	updatedAt
}

questions
state
name
vat
orgnumber
contact {
	phone
	zipCode
	email
	url
	address
	contactFirstName
	contactLastName
}
`;

/**
 * @param { { accessToken: string, dispatch?: unknown } } options
 */
export async function fetch(options = {}) {
	const { accessToken, dispatch } = options;

	const response = await CompanyService.company
		.fetchOne({ isBulk: true, fields: FIELDS }, { token: accessToken })
		.catch(() => {});

	dispatch?.(setCompany(response));

	return response;
}

export async function updateMyCompany({ accessToken, input }) {
	return CompanyService.company.updateMyCompany(
		{ input: input },
		{ token: accessToken },
	);
}
