import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';

import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import ModalCollection from '../modal-collection';

import './styles.scss';

const Layout = (props) => {
	const { onAction, onSubmit } = props;

	const { pathname } = useLocation();
	const path = pathname.split('/').filter(Boolean);

	return (
		<div
			className={cn(
				'asteria-component__financial-layout',
				'asteria-view__financial',
				{ [`asteria--page-${path.join('-')}`]: path.length },
			)}
		>
			<PageHeader className="asteria-component__financial-layout-header">
				<PageContent>
					<PageTitle
						title={TranslationService.get(
							['financial.title', 'financial.page.title'].concat(
								path.map(
									(value, index, arr) =>
										`financial.page.${arr
											.slice(0, index + 1)
											.join('.')}.title`,
								),
							),
						)}
					/>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-financial">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<div className="asteria-component__financial-layout-content">
				<ModalCollection onAction={onAction} onSubmit={onSubmit}>
					<Outlet />
				</ModalCollection>
			</div>
		</div>
	);
};

Layout.displayName = 'Layout';
Layout.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default Layout;
