import { createAsyncThunk } from '@reduxjs/toolkit';

import * as FormatUtils from '@asteria/utils-funcs/format';

import * as SnackbarStore from '../../snackbar';
import slice from '../slice';

const toggleCompleted = createAsyncThunk(
	'forecaster/setCompleted',
	(data, { dispatch, getState }) => {
		const { type, categoryName, tagName, value } = data;

		const state = getState();

		let isCompleted = false;

		const index =
			state.forecaster.tags?.findIndex?.(
				(object) =>
					object?.type === type &&
					object?.categoryName === categoryName &&
					object?.tagName === tagName,
			) ?? -1;

		if (index !== -1) {
			isCompleted = state.forecaster.tags[index].isCompleted;
		}

		if (!isCompleted) {
			let snackbarData = {
				title: [
					'snackbar.forecaster.type.complete.title',
					`snackbar.forecaster.type.${type}.complete.title`,
				],
				content: [
					'snackbar.forecaster.type.complete.content',
					`snackbar.forecaster.type.${type}.complete.content`,
				],
				type: 'forecaster.type.complete',
				variant: 'success',
				icon: 'check',
				data: { type: type, data: data?.data },
			};

			if (categoryName !== '$type') {
				snackbarData = {
					title: 'snackbar.forecaster.tag.complete.title',
					content: 'snackbar.forecaster.tag.complete.content',
					type: 'forecaster.tag.complete',
					variant: 'success',
					icon: 'check',
					data: {
						type: type,
						category: categoryName,
						tag: tagName,
						data: data?.data,
						label: FormatUtils.formatTag({
							type: type,
							category: categoryName,
							tag: tagName,
						}),
					},
				};
			}

			SnackbarStore.show(dispatch, snackbarData);
		}

		if (value === undefined || value !== isCompleted) {
			return dispatch(slice.actions.tagsToggleComplete(data));
		}
	},
);

export const actions = { toggleCompleted };
export const reducers = {
	[toggleCompleted.fulfilled]: () => {},
};
