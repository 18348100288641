import React from 'react';

import PropTypes from 'prop-types';

import Analytics from '@asteria/utils-analytics';

const AnalyticsUpdater = React.memo(({ view }) => {
	const previousView = React.useRef(null);

	React.useEffect(() => {
		if (view !== previousView.current) {
			if (previousView.current) {
				Analytics.endFlow();
			}

			Analytics.startFlow([`view`, view].join('.'));
		}

		previousView.current = view;
	}, [view]);
});

AnalyticsUpdater.displayName = 'AnalyticsUpdater';
AnalyticsUpdater.propTypes = { view: PropTypes.string };

export default AnalyticsUpdater;
