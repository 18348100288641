import React, { useCallback, useContext } from 'react';

import { connect, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { cn } from '@asteria/utils-funcs/classes';

import { GraphActionsContext } from '../../context';
import * as Selectors from '../../selectors';
import Background from '../background';

import { Bar } from './bar';

import './bargroup.scss';

const BarGroup = React.memo(
	({
		groupId,
		beacon = false,
		className,
		layout,
		style,
		active = false,
		hover = false,
		activeBars = [],
		onClick = () => {},
		index = 0,
		lines = 0,
		parts = [],
		mode,
	}) => {
		const minValue = useSelector(
			(store) => Selectors.graph.minValue(store, parts),
			(a, b) => isEqual(a, b),
		);

		const maxValue = useSelector(
			(store) => Selectors.graph.maxValue(store, parts),
			(a, b) => isEqual(a, b),
		);

		const rangeType = useSelector(
			(store) =>
				Selectors.graph.range(store, {
					id: groupId,
					format: (response) => response?.[0]?.types ?? [],
				}),
			(a, b) => isEqual(a, b),
		);

		const group = useSelector(
			(store) => Selectors.graph.barGroups(store, { id: groupId }),
			(a, b) => isEqual(a, b),
		);

		const { id, bars = [] } = group ?? {};
		const { mouseEnterAction, mouseLeaveAction } =
			useContext(GraphActionsContext);

		const max = {};
		for (let i = 0; i < bars.length; i += 1) {
			const { value = 0, data: { type = '' } = {} } = bars[i];

			if (!max[type]) {
				max[type] = bars[i];
			}

			if (Math.abs(value) > Math.abs(max[type].value)) {
				max[type] = bars[i];
			}
		}

		const mouseEnter = useCallback(
			() => mouseEnterAction && mouseEnterAction({ group }),
			[group, mouseEnterAction],
		);
		const mouseLeave = useCallback(
			() => mouseLeaveAction && mouseLeaveAction({ group }),
			[group, mouseLeaveAction],
		);

		return (
			<div
				className={cn(
					className,
					'asteria-graph-bar-group',
					rangeType.map((type) => `asteria-graph-bar-group-${type}`),
					`asteria-graph-bar-group__layout-${layout}`,
					{
						'asteria-state-active': active,
						'asteria-state-hover': hover,
						'asteria-graph-bar-group-beacon': beacon,
					},
				)}
				style={style}
				role="button"
				tabIndex="-1"
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
			>
				<Background className="asteria-graph-bar-group-background" />
				{lines > 0 && (
					<div className="asteria-graph-bar-group__lines">
						{Array(lines)
							.fill()
							.map((_, index) => (
								<div
									key={index}
									className="asteria-graph-bar-group__line"
								/>
							))}
					</div>
				)}
				{!id ? (
					<>
						<Bar
							id={id}
							bar={{ value: 1, types: ['deposit', 'loading'] }}
							group={group}
							height={0.5}
							key="loading-deposit"
							index={index}
							className={cn('asteria-loading')}
							mode={mode}
						/>
						<Bar
							id={id}
							bar={{ value: 1, types: ['withdraw', 'loading'] }}
							group={group}
							height={0.5}
							key="loading-withdraw"
							index={index}
							className={cn('asteria-loading')}
							mode={mode}
						/>
					</>
				) : null}
				{bars.map((bar) => {
					const { value, types, data: { type = '' } = {} } = bar;

					let magnitude = Math.abs(value / maxValue);
					if (minValue !== 0 && value < 0 && layout !== 'grouped') {
						magnitude = Math.abs(value / minValue);
					}

					return (
						<Bar
							beacon={
								beacon === bar.data.type &&
								types.includes('forecast')
							}
							pointer={max[type] === bar}
							id={id}
							active={
								active && activeBars.includes(bar.data.type)
							}
							bar={bar}
							group={group}
							height={magnitude}
							key={`${id}-${types.join('-')}`}
							rangeType={rangeType}
							index={index}
							onClick={onClick}
							className={cn({
								'asteria-after-beacon':
									beacon && beacon !== bar.data.type,
							})}
							mode={mode}
						/>
					);
				})}
			</div>
		);
	},
);

BarGroup.displayName = 'BarGroup';

export default connect(
	(state, ownProps) => {
		const { groupId: id } = ownProps;
		return {
			active: state?.graph?.data?.activeGroups?.includes(id),
			activeBars: state?.graph?.data?.activeBars || [],
			hover: state?.graph?.data?.hoverGroups.includes(id),
		};
	},
	null,
	null,
	{
		areStatesEqual: (next, prev) => isEqual(prev, next),
		areOwnPropsEqual: (next, prev) => isEqual(prev, next),
		areStatePropsEqual: (next, prev) => isEqual(prev, next),
		areMergedPropsEqual: (next, prev) => isEqual(prev, next),
	},
)(BarGroup);
