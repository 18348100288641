import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { GraphPropsContext } from '../context';
import * as Selectors from '../selectors';

import GroupedLayout from './grouped';
import StackedLayout from './stacked';

const GraphLayout = React.memo(
	React.forwardRef(function GraphLayout(props, ref) {
		const { mode, variant, context } = props;

		const graphLayout = useSelector(
			(store) => Selectors.settings.graphLayout(store, { mode }),
			(a, b) => isEqual(a, b),
		);

		const Component =
			graphLayout === 'stacked' ? StackedLayout : GroupedLayout;

		const ctx = React.useMemo(
			() => ({
				mode: mode,
				variant: variant,
				xaxis: {
					clickable: context?.xaxis?.clickable,
					hoverable: context?.xaxis?.hoverable,
				},
				legend: {
					clickable: context?.legend?.clickable,
					hoverable: context?.legend?.hoverable,
				},
			}),
			[
				context?.legend?.clickable,
				context?.legend?.hoverable,
				context?.xaxis?.clickable,
				context?.xaxis?.hoverable,
				mode,
				variant,
			],
		);

		return (
			<GraphPropsContext.Provider value={ctx}>
				<Component {...props} ref={ref} />
			</GraphPropsContext.Provider>
		);
	}),
);

GraphLayout.displayName = 'GraphLayout';

export default GraphLayout;
