import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions'>> } */
const Help = React.memo(function Help({
	translationOptions: $translationOptions,
}) {
	const translationOptions = React.useMemo(
		() => ({
			...$translationOptions,
			postfix: { ...$translationOptions?.postfix, section: 'help' },
		}),
		[$translationOptions],
	);

	const updatedBy = translationOptions?.data?.scenario?.updatedByEntity;

	return (
		<div
			className={cn(
				'flex items-center gap-4',
				'asteria-component__card-section',
				'asteria--variant-help',
			)}
		>
			<Button
				icon="help"
				tooltip={
					<Translation
						translationKey="card.content.extra.tooltip"
						translationOptions={translationOptions}
						Component={Text}
					/>
				}
			/>
			<TextGroup>
				<Translation
					translationKey="card.content.extra.content"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'section-type': 'created',
							entity: updatedBy?.__typename,
						},
						data: {
							...translationOptions?.data,
							integration: TranslationService.get([
								'integration.title',
								`integration.title.${updatedBy?.key}`,
								`integration.title.${updatedBy?.type}.${updatedBy?.key}`,
								`integration.${updatedBy?.key}.title`,
								`integration.${updatedBy?.type}.${updatedBy?.key}.title`,
							]),
						},
					}}
					Component={Text}
					size="sm"
				/>
				<Translation
					translationKey="card.content.extra.content"
					translationOptions={{
						...translationOptions,
						postfix: {
							...translationOptions?.postfix,
							'section-type': 'updated',
							entity: updatedBy?.__typename,
						},
						data: {
							...translationOptions?.data,
							integration: TranslationService.get([
								'integration.title',
								`integration.title.${updatedBy?.key}`,
								`integration.title.${updatedBy?.type}.${updatedBy?.key}`,
								`integration.${updatedBy?.key}.title`,
								`integration.${updatedBy?.type}.${updatedBy?.key}.title`,
							]),
						},
					}}
					Component={Text}
					size="sm"
				/>
			</TextGroup>
		</div>
	);
});

Help.propTypes = { translationOptions: PropTypes.object };

export default Help;
