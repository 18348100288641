import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import {
	Content,
	Footer,
	FooterSection,
} from '@asteria/component-core/wrapper';

import { MessageBoxToggle } from '@asteria/component-support/MessageBox';

import { TranslationService } from '@asteria/language';

const RemoveButton = React.memo((props) => {
	const { onAction } = props;

	const [loading, setLoading] = React.useState(false);

	const onRemoveSubmit = React.useCallback(async () => {
		setLoading(true);

		try {
			await onAction?.('user:remove');
		} catch (err) {
			//
		}

		setLoading(false);
	}, [onAction]);

	const toggle = React.useMemo(
		() => ({
			label: TranslationService.get(['settings.user.remove']),
			iconTooltip: TranslationService.get([
				'settings.user.remove.tooltip',
			]),
			variant: 'link',
			icon: 'help',
			iconPosition: 'last',
		}),
		[],
	);

	return (
		<MessageBoxToggle
			toggle={toggle}
			analyticsKey="settings.user.remove.message-box"
			scrollIntoView
		>
			{({ onClose }) => [
				<Content key="content">
					<Text>
						{TranslationService.get([
							'settings.user.remove.confirm',
						])}
					</Text>
				</Content>,
				<Footer key="footer">
					<FooterSection position="first">
						<Button
							variant="tertiary"
							size="sm"
							label={TranslationService.get([
								'action.abort',
								'action.cancel',
							])}
							onClick={onClose}
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							size="sm"
							label={TranslationService.get([
								'action.submit',
								'action.remove',
							])}
							onClick={onRemoveSubmit}
							loading={loading}
							disabled={loading}
						/>
					</FooterSection>
				</Footer>,
			]}
		</MessageBoxToggle>
	);
});

RemoveButton.displayName = 'RemoveButton';
RemoveButton.propTypes = { onAction: PropTypes.func };

export default RemoveButton;
