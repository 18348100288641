import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';

const IntegrationPrefix = (props) => {
	const { integration } = props;

	if (!integration?.config?.connected) {
		return (
			<Prefix
				colors="not-connected"
				className="not-connected"
				tooltip={
					<Text>
						{TranslationService.get(
							[
								'integrations.list.item.not.connected.tooltip',
								`integrations.list.item.${integration?.name}.not.connected.tooltip`,
							],
							undefined,
							integration,
						)}
					</Text>
				}
			/>
		);
	}

	if (integration?.disabled) {
		return (
			<Prefix
				colors="disabled"
				className="disabled"
				tooltip={
					<Text>
						{TranslationService.get(
							[
								'integrations.list.item.disabled.tooltip',
								`integrations.list.item.${integration?.name}.disabled.tooltip`,
							],
							undefined,
							integration,
						)}
					</Text>
				}
			/>
		);
	}

	return (
		<Prefix
			colors={`status-${integration?.state}`}
			className={`status-${integration?.state}`}
			tooltip={
				<Text>
					{TranslationService.get(
						[
							`integrations.list.item.tooltip`,
							`integrations.list.item.${integration?.name}.tooltip`,
							`integrations.list.item.${integration?.state}.tooltip`,
							`integrations.list.item.${integration?.state}.${integration?.name}.tooltip`,
						],
						undefined,
						integration,
					)}
				</Text>
			}
		/>
	);
};

IntegrationPrefix.displayName = 'IntegrationPrefix';

IntegrationPrefix.propTypes = {
	integration: PropTypes.shape({
		_id: PropTypes.string,
		id: PropTypes.string,
		name: PropTypes.string,
		key: PropTypes.string,
		state: PropTypes.string,
		status: PropTypes.shape({
			state: PropTypes.string,
		}),
		lastSync: PropTypes.string,

		disabled: PropTypes.bool,

		config: PropTypes.shape({
			connected: PropTypes.bool,
		}),
	}),
};

export default IntegrationPrefix;
