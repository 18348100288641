import React from 'react';

import PropTypes from 'prop-types';

import { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import Icon from '@asteria/component-core/icon';

import LoanContext from '../../../../context/loan';

const LoanTimeSelectorItem = React.memo((props) => {
	const { children, active, type } = props;

	const { setRange } = React.useContext(LoanContext);

	const onClick = React.useCallback(() => setRange?.(type), [setRange, type]);

	return (
		<DropdownItem
			postfix={
				<Group verticalAlign="center" horizontalAlign="center">
					<Icon icon="check" />
				</Group>
			}
			active={active}
			onClick={onClick}
		>
			{children}
		</DropdownItem>
	);
});

LoanTimeSelectorItem.displayName = 'LoanTimeSelectorItem';
LoanTimeSelectorItem.propTypes = {
	children: PropTypes.node,
	active: PropTypes.bool,
	type: PropTypes.string,
};

export default LoanTimeSelectorItem;
