import React from 'react';

import PropTypes from 'prop-types';

import Form, { useFormValues } from '@asteria/component-form';

import { useLoading } from '../../../hooks';

const FormWrapper = React.memo((props) => {
	const { children } = props;

	const loading = useLoading();
	const form = useFormValues();

	if (loading || form?.scenario === undefined) {
		return children;
	}

	return (
		<Form defaultValues={{ ...form, $touched: undefined }}>{children}</Form>
	);
});

FormWrapper.displayName = 'FormWrapper';
FormWrapper.propTypes = { children: PropTypes.node };

export default FormWrapper;
