import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

/* START: Common selectors */

export const currentDate = createSelector(
	(store) => store?.app?.currentDate,
	(value) => value,
);

export const selectedDate = createSelector(
	(store) => store?.app?.selectedDate,
	(value) => value,
);

export const mode = createSelector(
	(store) => store?.app?.mode,
	(value) => value ?? null,
);

export const currentTimesliceSize = createSelector(
	(store) => store?.app?.timesize,
	(value) => value ?? 'month',
);

export const accounts = createSelector(
	(store) => store?.accounts?.bankAccounts,
	(value) => value ?? [],
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isCreditGraphActive = createSelector(
	(store) => store?.financial?.credit?.settings?.graph,
	(value) => value ?? false,
);

export const isTransactionListShown = createSelector(
	(store) => store?.transactions?.state ?? null,
	(value) => value !== null,
);

export const scenarioId = createSelector(
	(store) => store?.app?.user?.settings?.flags?.scenarioId,
	(value) => value ?? null,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const isScenarioRefreshed = createSelector(
	(store) => store?.scenarios?.isRefreshed,
	(value) => value ?? false,
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

/* END: Common selectors */
