import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
	addYears,
	eachMonthOfInterval,
	format,
	formatISO,
	parseISO,
} from 'date-fns';

import { useFormValues } from '@asteria/component-form';

import * as AccountStore from '@asteria/datalayer/stores/accounts';

import { TranslationService } from '@asteria/language';
import { getLabels } from '@asteria/utils-funcs/graph';

import LoanContext, { GraphContext } from '../../../../context/loan';
import { getData } from '../../../../utils/loan';

export function useYAxis({ data }) {
	let min = Math.min(...data.map(({ value }) => value));
	let max = Math.max(...data.map(({ value }) => value));

	if (!min && !max) {
		max = 10_000;
		min = -10_000;
	}

	const scale = useMemo(() => {
		const absolute = Math.max(Math.abs(max), Math.abs(min));

		if (!absolute) {
			return 0;
		}

		return Math.floor(Math.floor(Math.log10(absolute)) / 3) - 1;
	}, [max, min]);

	const labels = useMemo(() => {
		const labels = getLabels({
			max: max,
			min: min,
			steps: 4,
			scale: scale,
			prefix: null,
		});

		labels.reverse();

		return labels.map((object, index) => ({
			...object,
			size: index ? 'medium' : undefined,
			label: object.value ? object.label : '0',
		}));
	}, [max, min, scale]);

	const labelsMin = Math.min(...labels.map(({ value }) => value));
	const labelsMax = Math.max(...labels.map(({ value }) => value));

	return {
		labels,
		min: labelsMin,
		max: labelsMax,
		title: TranslationService.get(
			[
				'yaxis.label',
				`yaxis.label.SEK`,
				`yaxis.label.${scale}`,
				`yaxis.label.SEK.${scale}`,
			],
			'TKr',
		),
	};
}

export function useGraphData() {
	const { dates, range } = useContext(LoanContext);
	const { forecast: $forecast } = useContext(GraphContext);

	const forecast = {};

	for (const type of ['deposit', 'withdraw']) {
		for (const date in $forecast?.[type]?.data ?? {}) {
			if (forecast[date] === undefined) {
				forecast[date] = 0;
			}

			const total = Object.values(
				$forecast?.[type]?.data?.[date] ?? {},
			).reduce((acc, { value }) => acc + (value || 0), 0);

			if (type === 'deposit') {
				forecast[date] += total;
			}

			if (type === 'withdraw') {
				forecast[date] -= total;
			}
		}
	}

	const currentBalance = useSelector(AccountStore.selectors.currentBalance);

	const [type, $amount, term, rate, $timestamp] = useFormValues({
		name: ['type', 'amount', 'term', 'rate', 'timestamp'],
	});

	const amount = Number.parseFloat($amount) || 0;

	const timestamp = $timestamp ? parseISO($timestamp) : new Date();

	const loanData = getData({
		type,
		amount,
		term,
		rate,
		timestamp,
	}).reduce((acc, object) => {
		const date = formatISO(object?.date, { representation: 'date' });
		return { ...acc, [date]: object };
	}, {});

	let fmt = 'yyyy-MM-dd';

	if (range === 'year') {
		fmt = 'yyyy';
	}

	const fullRange = eachMonthOfInterval({
		start: timestamp,
		end: addYears(timestamp, term),
	});

	const diff = fullRange.reduce((acc, $date) => {
		const date = formatISO($date, { representation: 'date' });

		const total = (forecast[date] || 0) - (loanData[date]?.total || 0);

		acc[date] = total;

		return acc;
	}, {});

	const { data } = fullRange.reduce(
		(acc, $date) => {
			const selector = formatISO($date, { representation: 'date' });
			const formattedDate = format($date, fmt);

			const last = acc.last;
			const total = last + (diff[selector] || 0);

			const loan =
				loanData[selector]?.total +
				(acc.data[formattedDate]?.loan || 0);

			const amortization =
				loanData[selector]?.amortization +
				(acc.data[formattedDate]?.amortization || 0);

			const interest =
				loanData[selector]?.interest +
				(acc.data[formattedDate]?.interest || 0);

			acc.data[formattedDate] = {
				total: total,
				amount: Number.parseFloat(amount),
				forecast: total - Number.parseFloat(amount),
				loan: loan,
				amortization: amortization,
				interest: interest,
			};

			acc.last = total;

			return acc;
		},
		{
			data: {},
			last: currentBalance + Number.parseFloat(amount),
		},
	);

	return useMemo(
		() =>
			dates.map((date) => {
				const formattedDate = format(date, fmt);

				return {
					date: date,
					value: data[formattedDate]?.total || 0,
					amount: data[formattedDate]?.amount || 0,
					forecast: data[formattedDate]?.forecast || 0,
					loan: data[formattedDate]?.loan || 0,
					amortization: data[formattedDate]?.amortization || 0,
					interest: data[formattedDate]?.interest || 0,
				};
			}),
		[data, dates, fmt],
	);
}

export function useLegends() {
	return useMemo(
		() => [
			{
				type: ['account', 'paid'],
				title: TranslationService.get('graph.account.legend.history'),
			},
			{
				type: ['account', 'forecast'],
				title: TranslationService.get('graph.account.legend.forecast'),
			},
			{
				type: ['spread'],
				title: TranslationService.get('graph.account.legend.spread'),
			},
		],
		[],
	);
}
