import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

export const loading = createSelector(
	(store) => store?.forecaster?.actions?.loading,
	(value) => value ?? false,
);

export const data = createSelector(
	(store) => store?.forecaster?.actions?.data ?? [],
	(_, options) => options,
	(objects, options) => {
		let result = objects;

		if (options?.type) {
			result = result.filter((object) => object?.type === options?.type);
		}

		return result;
	},
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);

export const value = createSelector(
	(store) => data(store),
	(_, ID) => ID,
	(objects, ID) =>
		objects.find((object) => ID === (object?._id ?? object?.id)),
	{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
);
