import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { cloneDeep } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Icon from '@asteria/component-core/icon';

import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { FormContext } from '../../../withForm';

import TagSelectorItem from './item';
import Switcher from './switcher';
import { getInitialType } from './utils';

import './styles.scss';

const TagSelectorDropdown = (props) => {
	const {
		onAction,

		variant,
		children,

		include,
		exclude,
		type: $type,
		tooltip,
		size,

		selected = [],
		active = [],

		showProbability = false,
	} = props;

	const dispatch = useDispatch();

	const [type, setType] = React.useState(() => getInitialType($type));

	const { getStaticForm } = React.useContext(FormContext);

	React.useEffect(() => {
		setType(getInitialType($type));
	}, [$type]);

	const tags = useSelector((store) =>
		ForecasterStore.selectors.tags.available(store, {
			type: type,
			exclude: exclude,
			include: include,
		}),
	);

	const onTagCreate = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.TagEdit,
			}),
		);
	}, [dispatch]);

	const onTagSelect = React.useCallback(
		(object) => () => onAction?.('tag:select', object),
		[onAction],
	);

	const onTagEdit = React.useCallback(
		(object) => () => {
			const form = getStaticForm();

			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.TagEdit,
					data: { data: object, form: cloneDeep(form) },
				}),
			);
		},
		[dispatch, getStaticForm],
	);

	const ToggleProps = React.useMemo(() => {
		let $children = <Icon icon="folder" />;

		if (variant === 'icon') {
			$children = null;
		}

		if (variant === 'custom') {
			$children = children;
		}

		return {
			icon: 'chevron-down',
			iconActive: 'chevron-up',
			iconPosition: 'last',
			children: $children,
			tooltip: tooltip,
			size: size,
		};
	}, [children, size, tooltip, variant]);

	return (
		<Dropdown
			className={cn(
				'asteria-component__selector',
				'asteria-component__forecaster-selector',
				'asteria--variant-dropdown',
				'asteria--type-categories',
			)}
			scroll
			toggle={ToggleProps}
			size={size}
		>
			{$type && !['deposit', 'withdraw'].includes($type) ? (
				<Switcher type={type} onChange={setType} />
			) : null}

			{tags.map((object) => (
				<TagSelectorItem
					key={object?._id ?? object?._id}
					as={DropdownItem}
					object={object}
					onClick={onTagSelect(object)}
					onEdit={onTagEdit(object)}
					selected={[]
						.concat(selected)
						.some(
							(value) =>
								value?.type === object?.type &&
								value?.category === object?.category?.name &&
								value?.tag === object?.name,
						)}
					active={[]
						.concat(active)
						.some(
							(value) =>
								value?.type === object?.type &&
								value?.category === object?.category?.name &&
								value?.tag === object?.name,
						)}
					showProbability={showProbability}
				/>
			))}

			<DropdownItem
				className={cn(
					'asteria-component__selector-item',
					'asteria-component__forecaster-selector-item',
					'asteria--variant-create',
				)}
				analyticsKey="forecaster.tag.select.create"
				onClick={onTagCreate}
				noColors
				size="lg"
			>
				<Button
					label={TranslationService.get([
						'forecaster.tags.dropdown.create',
					])}
					size="md"
					iconSize="sm"
					icon="add"
					variant="tertiary"
				/>
			</DropdownItem>
		</Dropdown>
	);
};

TagSelectorDropdown.displayName = 'TagSelectorDropdown';
TagSelectorDropdown.propTypes = {
	onAction: PropTypes.func,

	variant: PropTypes.oneOf(['icon', 'select']),
	children: PropTypes.node,

	include: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),
	exclude: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),

	type: PropTypes.string,
	tooltip: PropTypes.string,
	size: PropTypes.string,

	selected: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),
	active: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			category: PropTypes.string,
			tag: PropTypes.string,
		}),
	),

	showProbability: PropTypes.bool,
};

export default TagSelectorDropdown;
