import parseName from '../getFormPaths';

/**
 * @template TForm
 * @param {{ form: TForm, type: string }} options
 * @returns {{ op: string, name: string, value: unknown }[]}
 */
function reset(options) {
	const { form, type } = options;

	return [...(form?.$changes ?? [])]
		.reverse()
		.filter(({ name }) => parseName(name).type === type);
}

export default reset;
