import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import { useLoading } from '../hooks';

import './styles.scss';

/**
 * @type { React.FC<Partial<{ className: string, children: React.ReactNode, position: 'first' | 'last', loading: boolean }>> }
 */
const FooterSection = React.memo(function FooterSection(props) {
	const {
		children,
		className,
		loading: $loading,
		position = 'first',
	} = props;

	const loading = useLoading($loading);

	if (!children) {
		return null;
	}

	return (
		<div
			className={cn(
				'asteria-component__wrapper-footer__section',
				{ 'asteria--state-loading': loading },
				className,
				{
					[`asteria-component__wrapper-footer__section--position-${position}`]:
						position,
				},
			)}
		>
			{typeof children === 'function' ? children({ loading }) : children}
		</div>
	);
});

FooterSection.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	position: PropTypes.oneOf(['first', 'last']),
	loading: PropTypes.bool,
};

FooterSection.displayName = 'WrapperFooterSection';

/**
 * @type { React.FC<Partial<React.HTMLAttributes<HTMLDivElement> & { className: string, children: React.ReactNode, loading: boolean }>> }
 */
const Footer = React.forwardRef(function Footer(props, ref) {
	const { className, children, loading: $loading, ...rest } = props;

	const loading = useLoading($loading);

	if (!children) {
		return null;
	}

	const count = React.Children.toArray(children).length;

	return (
		<div
			className={cn(
				'asteria-component__wrapper-footer',
				{
					'asteria--state-loading': loading,
					[`asteria--size-${count}`]: count,
				},
				className,
			)}
			ref={ref}
			{...rest}
		>
			{children}
		</div>
	);
});

Footer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	loading: PropTypes.bool,
};

Footer.displayName = 'WrapperFooter';

export default React.memo(Footer);
export { FooterSection };
