import React from 'react';

import AccountsView from '@asteria/view-accounts';

import { LayoutContext } from '../../context';

import './styles.scss';

const AccountsPage = () => {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	return (
		<AccountsView
			onAction={onAction}
			onSubmit={onSubmit}
			onFetch={onSubmit}
		/>
	);
};

AccountsPage.displayName = 'AccountsPage';

AccountsPage.propTypes = {};

export default AccountsPage;
