import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';

import Button from '@asteria/component-core/button';
import Probability from '@asteria/component-core/probability';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

const selectors = {
	graph: createSelector(
		(state) => state?.graph?.data?.barGroups,
		(_, startDate) => startDate,
		(barGroups, startDate) => barGroups?.[startDate],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	size: createSelector(
		(state) => state?.app?.timesize,
		(size) => size ?? 'month',
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),

	selectedTypes: createSelector(
		(state) => state?.app?.selectedTypes,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const getToolTip = (item) => {
	const els = [];
	if (
		TranslationService.hasKey(
			`transaction.group.total.${item?.status}.tooltip`,
		)
	) {
		els.push(
			<Text>
				{TranslationService.get(
					`transaction.group.total.${item?.status}.tooltip`,
					null,
					item,
				)}
			</Text>,
		);
	}

	if (
		item?.oracle?.currency &&
		TranslationService.hasKey([
			`transaction.group.total.currency.risk.tooltip`,
			`transaction.group.total.${item?.status}.currency.risk.tooltip`,
		])
	) {
		els.push(
			TranslationService.get(
				[
					`transaction.group.total.currency.risk.tooltip`,
					`transaction.group.total.${item?.status}.currency.risk.tooltip`,
				],
				null,
				item,
			),
		);
	}
	return els.length > 0 ? els : null;
};

function useHeaderParts(options) {
	const { group, onAction = () => {} } = options;

	const graph = useSelector((state) =>
		selectors.graph(state, group?.startDate),
	);
	const selectedTypes = useSelector(selectors.selectedTypes);
	const size = useSelector(selectors.size);

	const tooltip = getToolTip(group);
	const showEditButton = useFeature('cashflow-transactions-list-header-edit');

	const total = useDeepMemo(() => {
		const [accountPaid, account = accountPaid] = graph?.lines || [];

		const [, deposit, , withdraw] = graph?.bars || [];

		const depositTotal = (deposit?.parts ?? [])
			.filter(({ types }) => !(types ?? []).includes('overdue'))
			.reduce((acc, { value }) => acc + (value ?? 0), 0);

		const withdrawTotal = (withdraw?.parts ?? [])
			.filter(({ types }) => !(types ?? []).includes('overdue'))
			.reduce((acc, { value }) => acc + (value ?? 0), 0);

		if (selectedTypes.length === 1) {
			if (selectedTypes.includes('DEPOSIT')) {
				return depositTotal;
			}

			if (selectedTypes.includes('WITHDRAW')) {
				return withdrawTotal;
			}
		}

		if (account?.types?.includes('credit')) {
			return accountPaid?.value || 0;
		}

		return depositTotal + withdrawTotal;
	}, [graph, selectedTypes]);

	const probability = useDeepMemo(() => {
		const line = graph?.lines?.find(
			({ types }) =>
				types.includes('account') && types.includes('forecast'),
		);

		if (line) {
			return line.probability;
		}

		return 1;
	}, [graph]);

	const headerKey = useDeepMemo(() => {
		if (!selectedTypes || selectedTypes.length > 1) {
			return 'all';
		}

		return selectedTypes[0].toLowerCase();
	}, [selectedTypes]);

	const onAdjustButtonClick = React.useCallback(
		() =>
			onAction?.('graph:forecaster:show', {
				type: headerKey === 'all' ? null : headerKey,
				date: group?.id,
			}),
		[group?.id, headerKey, onAction],
	);

	const adjustButtonTooltip = React.useMemo(
		() => ({
			variant: 'alt',
			tooltip: TranslationService.get([
				'transactions.group.action.adjust.tooltip',
				`transactions.group.${size}.action.adjust.tooltip`,
				`transactions.group.${group?.status}.action.adjust.tooltip`,
				`transactions.group.${group?.status}.${size}.action.adjust.tooltip`,
				`transactions.group.${headerKey}.action.adjust.tooltip`,
				`transactions.group.${headerKey}.${size}.action.adjust.tooltip`,
				`transactions.group.${headerKey}.${group?.status}.action.adjust.tooltip`,
				`transactions.group.${headerKey}.${group?.status}.${size}.action.adjust.tooltip`,
			]),
		}),
		[group?.status, headerKey, size],
	);

	return useDeepMemo(
		() => [
			<Text key="header-title">
				{TranslationService.get(
					[
						'transactions.group.title',
						`transactions.group.${size}.title`,
						`transactions.group.${group?.status}.title`,
						`transactions.group.${group?.status}.${size}.title`,
						`transactions.group.${headerKey}.title`,
						`transactions.group.${headerKey}.${size}.title`,
						`transactions.group.${headerKey}.${group?.status}.title`,
						`transactions.group.${headerKey}.${group?.status}.${size}.title`,
					],
					'{{startDate | date:"MMMM, yyyy")}}',
					group,
				)}
			</Text>,
			<TooltipWrapper tooltip={tooltip} key="header-total">
				<Text className="asteria--type-total">
					{TranslationService.get(
						'transactions.group.total',
						'{{total | number:false}}',
						{ ...group, total },
					)}
				</Text>
			</TooltipWrapper>,
			group?.status === 'FORECAST' ? (
				<Probability
					key="header-probability"
					probability={probability}
					tooltip
				/>
			) : null,
			showEditButton && group?.status === 'FORECAST' ? (
				<Button
					key="header-edit"
					size="sm"
					variant="transaction-icon"
					icon="pencil"
					onClick={onAdjustButtonClick}
					tooltip={adjustButtonTooltip}
				/>
			) : null,
		],
		[
			adjustButtonTooltip,
			group,
			headerKey,
			onAdjustButtonClick,
			probability,
			showEditButton,
			size,
			tooltip,
			total,
		],
	);
}

export default useHeaderParts;
