import { PredictionStorageService } from '@asteria/backend-utils-services';

import * as GraphStore from '@asteria/datalayer/stores/graph';

export async function fetch({ accessToken, dispatch }) {
	const response = await PredictionStorageService.prediction
		.sendRequest(
			{
				query: `
					query {
						predictionResults {
							edges {
								node {
									id
									prediction
								}
							}
						}
					}
				`,
			},
			{ token: accessToken },
		)
		.then((response) => response?.predictionResults)
		.then((response) => (response?.edges ?? []).map(({ node }) => node))
		.then(([response]) => response?.prediction ?? [])
		.then((response) =>
			response.map((object) => ({
				date: object?.paymentDate,
				revenue: object?.revenue,
			})),
		);

	dispatch?.(GraphStore.setPredictions(response));

	return response;
}
