import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Splash from '@asteria/component-splash';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const ClientSplash = (props) => {
	const { className, onAction } = props;

	const dispatch = useDispatch();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'erp:create') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
						data: { type: 'erp' },
					}),
				);
			}

			onAction(action, data);
		},
		[dispatch, onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	return (
		<Splash
			className={cn(
				'asteria-component__client-splash',
				'asteria--variant-client',
				className,
			)}
			path="pages.client.splash"
			actions={actions}
		/>
	);
};

ClientSplash.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

ClientSplash.defaultProps = {};

export default ClientSplash;
