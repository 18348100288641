import React from 'react';

import PropTypes from 'prop-types';

import { sizeClasses } from '@asteria/component-core/utils';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../constants';

import DatePickerContentDay from './DatePickerContentDay';
import DatePickerContentMonth from './DatePickerContentMonth';
import DatePickerContentWeek from './DatePickerContentWeek';
import DatePickerContentYear from './DatePickerContentYear';
import DatePickerHeader from './DatePickerHeader';
import DatePickerRepeatable from './DatePickerRepeatable';

import './index.scss';

const DatePickerContent = React.forwardRef((props, ref) => {
	const {
		type,
		types,
		onTypeSelect,
		onChange,
		onRepeatableChange,
		value,
		variant,
		behavior,
		layout,

		isPastDisabled,
		isFutureDisabled,

		locale,

		startDate,
		endDate,
		disabledDates,

		repeatable,
	} = props;

	let Content = null;

	switch (type) {
		case 'week':
			Content = DatePickerContentWeek;
			break;

		case 'month':
			Content = DatePickerContentMonth;
			break;

		case 'year':
			Content = DatePickerContentYear;
			break;

		default:
			Content = DatePickerContentDay;
			break;
	}

	return (
		<div
			className={cn(
				'asteria-component__datepicker__content',
				{
					[`asteria-component__datepicker__content--type-${type}`]:
						type,
				},
				sizeClasses(props),
				{
					'asteria-component__datepicker__content--multiple':
						layout && layout !== 'default' && behavior !== 'scroll',
					[`asteria-component__datepicker__content--multiple-${layout}`]:
						layout && layout !== 'default' && behavior !== 'scroll',
				},
			)}
			tabIndex="0"
			ref={ref}
		>
			{types?.length && types.length > 1 ? (
				<DatePickerHeader
					onSelect={onTypeSelect}
					type={type}
					locale={locale}
					types={types}
				/>
			) : null}
			<Content
				onSelect={onChange}
				value={value}
				variant={variant}
				behavior={behavior}
				layout={layout}
				isPastDisabled={isPastDisabled}
				isFutureDisabled={isFutureDisabled}
				startDate={startDate}
				endDate={endDate}
				disabledDates={disabledDates}
				locale={locale}
			/>
			{repeatable ? (
				<DatePickerRepeatable
					onChange={onRepeatableChange}
					locale={locale}
				/>
			) : null}
		</div>
	);
});

DatePickerContent.displayName = 'DatePickerContent';

DatePickerContent.propTypes = {
	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	type: Constants.DatePickerTypesPropType,
	types: PropTypes.arrayOf(Constants.DatePickerTypesPropType),
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onChange: PropTypes.func,
	onTypeSelect: PropTypes.func,
	onRepeatableChange: PropTypes.func,

	forwardRef: PropTypes.any,

	repeatable: PropTypes.bool,
};

DatePickerContent.defaultProps = {};

export default DatePickerContent;
