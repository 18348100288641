import React from 'react';

import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Trends from '../trends';

import List from './list';

/**
 * @typedef { import('../../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'translationOptions' | 'onAction' | 'onSubmit'> & { type: 'deposit' | 'withdraw' }> } */
const Overview = React.memo(function Overview(props) {
	const {
		type,
		translationOptions: $translationOptions,
		onAction,
		onSubmit,
	} = props;

	const onLabelClick = React.useCallback(
		() =>
			onAction?.('card:action', {
				type: 'forecast-status',
				data: { type: type },
			}),
		[onAction, type],
	);

	const path = [type];
	const source = get($translationOptions?.data?.source, path);
	const target = get($translationOptions?.data?.target, path);

	const translationOptions = React.useMemo(() => {
		const accuracy = {
			diff: { value: null, percentage: null },
			multiplier: null,
			state: null,
			reached: null,
		};

		const performance = {
			value: null,
			percentage: null,
			state: ['WARNING', 'NEEDS_ATTENTION', 'NEUTRAL', 'GOOD'][0],
		};

		return {
			...$translationOptions,
			postfix: {
				...$translationOptions?.postfix,

				section: 'overview',
				'section-type': type,

				performance: performance.state,
			},
			data: {
				...$translationOptions?.data,
				source: {
					...$translationOptions?.data?.source,
					value: source,
				},
				target: {
					...$translationOptions?.data?.target,
					value: target,
				},
				accuracy: accuracy,
				performance: performance,
			},
		};
	}, [$translationOptions, source, target, type]);

	return (
		<div
			className={cn(
				'flex flex-col gap-2',
				'asteria-component__card-section',
				'asteria--variant-overview',
				{ [`asteria--type-${type}`]: type },
			)}
		>
			<div className="flex flex-col gap-1">
				<Button
					variant="link"
					label={TranslationService.getV2(
						['card.content.extra.link'],
						translationOptions,
					)}
					onClick={onLabelClick}
				/>
				<Translation
					translationKey="card.content.extra.content"
					translationOptions={translationOptions}
					Component={Text}
				/>
			</div>
			<div className="flex gap-4 items-center">
				<TooltipWrapper
					tooltip={
						<Translation
							translationKey="card.content.extra.tooltip"
							translationOptions={translationOptions}
							Component={Text}
						/>
					}
				>
					<TextGroup>
						<Translation
							translationKey="card.content.extra.value"
							translationOptions={{
								...translationOptions,
								postfix: {
									...translationOptions?.postfix,
									value: 'current',
								},
							}}
							Component={Title}
						/>
						{!!source?.total && source?.total !== target?.total ? (
							<Translation
								translationKey="card.content.extra.value"
								translationOptions={{
									...translationOptions,
									postfix: {
										...translationOptions?.postfix,
										value: 'default',
									},
								}}
								Component={Text}
								size="sm"
							/>
						) : null}
					</TextGroup>
				</TooltipWrapper>
				<Trends
					direction={target?.total > source?.total ? 'up' : 'down'}
					translationOptions={translationOptions}
				/>
			</div>
			<List
				type={type}
				translationOptions={translationOptions}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
		</div>
	);
});

Overview.propTypes = {
	type: PropTypes.oneOf(['deposit', 'withdraw']),

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

export default Overview;
