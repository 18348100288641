import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import { Wrapper as FormWrapper } from '@asteria/component-form';
import Checkbox from '@asteria/component-form/checkbox';
import Input from '@asteria/component-form/input';
import Contenter from '@asteria/component-tools/contenter';
import { useBulkFeatures } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Guide from '../../../components/Guide';
import Step, {
	Content,
	Footer,
	Header,
	StepInner,
} from '../../../components/Step';
import SkipButton from '../Skip';

const IntegrationField = (props) => {
	const { field, integrationKey, type } = props;

	const label = React.useMemo(() => {
		if (!field?.label) {
			return null;
		}

		const label = field?.label?.value ?? field?.label;
		const translation = TranslationService.get(
			[
				label,
				`integrations.${label}`,
				`integrations.add.${label}`,
				[type, label].join('.'),
				`integrations.${type}.${label}`,
				`integrations.${type}.add.${label}`,
				[integrationKey, label].join('.'),
				`integrations.${integrationKey}.${label}`,
				`integrations.${integrationKey}.add.${label}`,
				[type, integrationKey, label].join('.'),
				`integrations.${type}.${integrationKey}.${label}`,
				`integrations.${type}.${integrationKey}.add.${label}`,
			],
			label,
			{ ...field, type: type, key: integrationKey },
		);

		if (typeof field?.label === 'object') {
			return { ...field?.label, value: translation };
		}

		return translation;
	}, [field, integrationKey, type]);

	const placeholder = React.useMemo(() => {
		if (!field?.placeholder) {
			return null;
		}

		return TranslationService.get(
			[
				field?.placeholder,
				`integrations.${field?.placeholder}`,
				`integrations.add.${field?.placeholder}`,
				[type, field?.placeholder].join('.'),
				`integrations.${type}.${field?.placeholder}`,
				`integrations.${type}.add.${field?.placeholder}`,
				[integrationKey, field?.placeholder].join('.'),
				`integrations.${integrationKey}.${field?.placeholder}`,
				`integrations.${integrationKey}.add.${field?.placeholder}`,
				[type, integrationKey, field?.placeholder].join('.'),
				`integrations.${type}.${integrationKey}.${field?.placeholder}`,
				`integrations.${type}.${integrationKey}.add.${field?.placeholder}`,
			],
			field?.placeholder,
			{ ...field, type: type, key: integrationKey },
		);
	}, [field, integrationKey, type]);

	const InputProps = React.useMemo(
		() => ({
			name: field?.key,
			label: label,
			placeholder: placeholder,
			required: field?.flags?.required,
			readonly: field?.flags?.readonly,
			disabled: field?.flags?.disabled,
		}),
		[
			field?.flags?.disabled,
			field?.flags?.readonly,
			field?.flags?.required,
			field?.key,
			label,
			placeholder,
		],
	);

	if (field?.type === 'checkbox') {
		return (
			<Checkbox
				{...InputProps}
				value={field?.value}
				iconPosition={field?.iconPosition ?? 'first'}
			/>
		);
	}

	return <Input {...InputProps} direction={field?.direction ?? 'vertical'} />;
};

IntegrationField.propTypes = {
	field: PropTypes.object.isRequired,
	integrationKey: PropTypes.string,
	type: PropTypes.string,
};

const IntegrationDynamic = (props) => {
	const {
		className,

		type,
		name,

		flow,
		loading,

		onClose,
		onBack,
		onAbort,

		header,
		footer,
		content: $content,
	} = props;

	const [showGuide, setShowGuide] = React.useState(false);

	const featureKey =
		type === 'erp'
			? 'onboarding-single-erp-integration'
			: 'onboarding-single-bank-integration';

	const isBackHidden =
		useBulkFeatures([['onboarding-single-integration', featureKey]]) ||
		footer?.hideBack;

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const step = `${type}.configuration.dynamic`;

	const fields = React.useMemo(() => flow?.fields ?? [], [flow]);

	const tourKey = React.useMemo(
		() => [
			'onboarding:configuration',
			`onboarding:configuration:${type}`,
			`onboarding:configuration:${key}`,
			`onboarding:configuration:${type}:${key}`,
		],
		[key, type],
	);

	const GuideProps = React.useMemo(
		() => ({ integration: { type: type, key: key } }),
		[key, type],
	);

	const integrationName = TranslationService.get(
		[
			'integration.title',
			`integration.title.${key}`,
			`integration.title.${type}.${key}`,
			`integration.${key}.title`,
			`integration.${type}.${key}.title`,
		],
		flow?.name,
	);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-configuration__info',
				{
					[`asteria-component__onboarding-configuration--type-${type}`]:
						type,
				},
				className,
			)}
			step={step}
		>
			{flow?.steps?.info?.guide ? (
				<Guide
					open={showGuide}
					steps={flow?.steps?.info?.guide?.steps}
					onClose={() => setShowGuide(false)}
					{...GuideProps}
				/>
			) : null}
			<Header
				onBack={!isBackHidden ? onBack : null}
				onClose={!header?.hideClose ? onClose : undefined}
				tourKey={tourKey}
			>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${type}.add.title`,
						`integrations.${key}.add.title`,
						`integrations.${type}.${key}.add.title`,
						`integrations.configuration.title`,
						`integrations.${type}.configuration.title`,
						`integrations.${key}.configuration.title`,
						`integrations.${type}.${key}.configuration.title`,
					],
					undefined,
					{ name: integrationName },
				)}
			</Header>
			<Content step={step} scroll>
				<StepInner>
					{$content ? (
						$content
					) : (
						<>
							<Contenter content={flow?.steps?.info?.content} />

							<FormWrapper>
								<Content>
									{fields.map((field) => (
										<IntegrationField
											key={field.key}
											field={field}
											type={type}
											integrationKey={key}
										/>
									))}
								</Content>
							</FormWrapper>
						</>
					)}
				</StepInner>

				{flow?.steps?.info?.guide ? (
					<Button
						className="asteria-component__onboarding-step__help"
						variant="assist"
						label={TranslationService.get(
							[
								`integrations.add.help`,
								`integrations.${type}.add.help`,
								`integrations.${type}.${key}.add.help`,
							],
							undefined,
							{ name: integrationName },
						)}
						// icon="triangle-right"
						// iconPosition="last"
						onClick={() => setShowGuide(true)}
					/>
				) : null}
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${key}.abort`}
						variant="tertiary"
						label={TranslationService.get(
							[
								'action.abort',
								'button.abort',
								'onboarding.modal.action',
								'onboarding.modal.action.abort',
								'onboarding.modal.flow-configuration.action',
								'onboarding.modal.flow-configuration.action.abort',
								`onboarding.modal.${type}.action`,
								`onboarding.modal.${type}.action.abort`,
								`onboarding.modal.${type}.flow-configuration.action`,
								`onboarding.modal.${type}.flow-configuration.action.abort`,
								`onboarding.modal.${key}.action`,
								`onboarding.modal.${key}.action.abort`,
								`onboarding.modal.${key}.flow-configuration.action`,
								`onboarding.modal.${key}.flow-configuration.action.abort`,
								`onboarding.modal.${type}.${key}.action`,
								`onboarding.modal.${type}.${key}.action.abort`,
								`onboarding.modal.${type}.${key}.flow-configuration.action`,
								`onboarding.modal.${type}.${key}.flow-configuration.action.abort`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={onAbort}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${key}.continue`}
						variant="primary"
						label={TranslationService.get(
							[
								'action.continue',
								'button.continue',
								'integrations.action.next',
								`integrations.action.next.${key}`,
								'onboarding.modal.action',
								'onboarding.modal.action.continue',
								'onboarding.modal.flow-configuration.action',
								'onboarding.modal.flow-configuration.action.continue',
								`onboarding.modal.${type}.action`,
								`onboarding.modal.${type}.action.continue`,
								`onboarding.modal.${type}.flow-configuration.action`,
								`onboarding.modal.${type}.flow-configuration.action.continue`,
								`onboarding.modal.${key}.action`,
								`onboarding.modal.${key}.action.continue`,
								`onboarding.modal.${key}.flow-configuration.action`,
								`onboarding.modal.${key}.flow-configuration.action.continue`,
								`onboarding.modal.${type}.${key}.action`,
								`onboarding.modal.${type}.${key}.action.continue`,
								`onboarding.modal.${type}.${key}.flow-configuration.action`,
								`onboarding.modal.${type}.${key}.flow-configuration.action.continue`,
							],
							undefined,
							{ name: integrationName },
						)}
						type="submit"
						loading={loading}
						disabled={loading}
					/>
					<SkipButton type={type} name={name} />
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationDynamic.displayName = 'IntegrationDynamic';

IntegrationDynamic.propTypes = {
	className: PropTypes.string,

	type: PropTypes.string,
	name: PropTypes.string,

	flow: PropTypes.object,
	loading: PropTypes.bool,

	onClose: PropTypes.func,
	onBack: PropTypes.func,
	onAbort: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	header: PropTypes.shape({ hideClose: PropTypes.bool }),
	footer: PropTypes.shape({ hideBack: PropTypes.bool }),
	content: PropTypes.node,
};

export default IntegrationDynamic;
