import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const selectors = {
	isShown: createSelector(
		(store) => store?.app?.user?.settings?.flags,
		(_, key) => key,
		(flags, key) => flags?.[['teaser', key].join(':')] ?? false,
	),
};

const ForecasterTeaser = React.memo((props) => {
	const { className, onAction } = props;

	const [isOpen, setOpen] = React.useState(true);

	const show = useSelector(
		(store) => selectors.isShown(store, 'graph:forecaster'),
		(a, b) => isEqual(a, b),
	);

	const isLoading = useSelector(
		IntegrationStore.selectors.isLoading,
		(a, b) => isEqual(a, b),
	);
	const isFetching = useSelector(
		IntegrationStore.selectors.isFetching,
		(a, b) => isEqual(a, b),
	);

	const handleDismiss = React.useCallback(() => {
		setOpen(false);
	}, []);

	const handleClose = React.useCallback(() => {
		onAction?.('teaser:close', 'graph:forecaster');
	}, [onAction]);

	const handleAction = React.useCallback(() => {
		onAction?.('go', '/forecaster');

		return handleClose();
	}, [handleClose, onAction]);

	const HeaderPostfix = React.useMemo(
		() => ({
			position: 'absolute',
			size: 'xs',
			analyticsKey: 'graph.forecaster.teaser.action.close',
		}),
		[],
	);

	if (show || !isOpen || isLoading || isFetching) {
		return null;
	}

	return (
		<FeatureFlag feature="graph-forecaster-teaser">
			<Wrapper
				className={cn(
					'asteria-component__graph__teaser--forecaster',
					className,
				)}
			>
				<Header onClose={handleDismiss} postfix={HeaderPostfix}>
					{TranslationService.get('graph.forecaster.teaser.title') ? (
						<Title size="xxs">
							{TranslationService.get(
								'graph.forecaster.teaser.title',
							)}
						</Title>
					) : null}
				</Header>
				<Content>
					{TranslationService.get(
						'graph.forecaster.teaser.content',
					) ? (
						<Text size="sm">
							{TranslationService.get(
								'graph.forecaster.teaser.content',
							)}
						</Text>
					) : null}
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							size="sm"
							label={TranslationService.get([
								'button.skip',
								'action.skip',
								'graph.forecaster.teaser.action.skip',
							])}
							variant="href"
							onClick={handleClose}
							analyticsKey="graph.forecaster.teaser.action.skip"
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							size="sm"
							label={TranslationService.get([
								'button.submit',
								'action.submit',
								'graph.forecaster.teaser.action.submit',
							])}
							variant="primary"
							onClick={handleAction}
							analyticsKey="graph.forecaster.teaser.action.submit"
						/>
					</FooterSection>
				</Footer>
			</Wrapper>
		</FeatureFlag>
	);
});

ForecasterTeaser.displayName = 'ForecasterTeaser';

ForecasterTeaser.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
};

export default ForecasterTeaser;
