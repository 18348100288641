import React from 'react';

import PropTypes from 'prop-types';

import { Text, TextGroup } from '@asteria/component-core/typography';
import { stateClasses } from '@asteria/component-core/utils';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Context from './context';

import './styles.scss';

const WizardPagination = (props) => {
	const { className, type } = props;

	const {
		count,
		type: wizardType,
		go,
		step,
		maxOpenStep,
	} = React.useContext(Context);

	return (
		<div
			className={cn(
				'asteria-component__wizard__pagination',
				{
					[`asteria-component__wizard__pagination--type-${type}`]:
						type,
				},
				className,
			)}
		>
			{type === 'dot' || type === 'full' ? (
				<div className="asteria-component__wizard__pagination__dots">
					{new Array(count).fill().map((_, index) => (
						<div
							key={index}
							className={cn(
								'asteria-component__wizard__pagination__dot',
								stateClasses({
									active: index === step,
									disabled: index > maxOpenStep,
									onClick: index <= maxOpenStep && go,
								}),
							)}
							onClick={() => go?.(index)}
						/>
					))}
				</div>
			) : null}

			{type === 'text' || type === 'full' ? (
				<Text size="sm">
					{TranslationService.get(
						['wizard.pagination'],
						'{{ step }} / {{ count }}',
						{ step: step + 1, count: count },
					)}
				</Text>
			) : null}

			{type === 'details' ? (
				<div className="asteria-component__wizard__pagination__inner">
					{new Array(count).fill().map((_, index) => (
						<div
							key={index}
							className={cn(
								'asteria-component__wizard__pagination__details',
								stateClasses({
									active: index === step,
									disabled: index > maxOpenStep,
									onClick: index <= maxOpenStep && go,
								}),
							)}
							onClick={() => go?.(index)}
						>
							<div className="asteria-component__wizard__pagination__details__index">
								<div className="asteria-component__wizard__pagination__details__index__content">
									<Text>{index + 1}</Text>
								</div>
							</div>
							<TextGroup>
								<Text>
									{TranslationService.get([
										`wizard.step.${index}.title`,
										`wizard.${wizardType}.step.${index}.title`,
									])}
								</Text>
								{TranslationService.hasKey([
									`wizard.step.${index}.subtitle`,
									`wizard.${wizardType}.step.${index}.subtitle`,
								]) ? (
									<Text size="sm">
										{TranslationService.get([
											`wizard.step.${index}.subtitle`,
											`wizard.${wizardType}.step.${index}.subtitle`,
										])}
									</Text>
								) : null}
							</TextGroup>
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

WizardPagination.displayName = 'WizardPagination';

WizardPagination.propTypes = {
	type: PropTypes.oneOf(['text', 'dot', 'full', 'details']),
	className: PropTypes.string,
};

export default React.memo(WizardPagination);
