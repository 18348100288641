import React from 'react';

import PropTypes from 'prop-types';

import { Checkbox, Datepicker } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

import './styles.scss';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Pick<Props, 'date' | 'onAction' | 'onSubmit'> & { extra: { postfix: unknown, data: unknown } }> } */
const CustomDateRange = React.memo(function CustomDateRange({
	date,
	onAction,
	extra,
}) {
	const checked = typeof date === 'object';

	const onToggle = React.useCallback(
		({ checked }) => onAction?.('time:selector:custom:toggle', checked),
		[onAction],
	);

	const onStartDateChange = React.useCallback(
		({ value }) =>
			onAction?.('time:selector:custom', { key: 'startDate', value }),
		[onAction],
	);

	const onEndDateChange = React.useCallback(
		({ value }) =>
			onAction?.('time:selector:custom', { key: 'endDate', value }),
		[onAction],
	);

	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-center justify-center">
				<Checkbox
					uncontrolled
					label={TranslationService.getV2(
						['time.selector.popup.custom.label'],
						extra,
					)}
					checked={checked}
					onChange={onToggle}
				/>
			</div>
			{checked ? (
				<div className="flex items-center justify-center gap-2">
					<Datepicker
						uncontrolled
						placeholder={TranslationService.getV2(
							[
								'time.selector.popup.custom.datepicker.placeholder',
							],
							{
								data: extra?.data,
								postfix: { ...extra?.data, type: 'start-date' },
							},
						)}
						value={date?.startDate}
						onChange={onStartDateChange}
						types={['day', 'week', 'month', 'year']}
						type="month"
					/>
					<Datepicker
						uncontrolled
						placeholder={TranslationService.getV2(
							[
								'time.selector.popup.custom.datepicker.placeholder',
							],
							{
								data: extra?.data,
								postfix: { ...extra?.data, type: 'end-date' },
							},
						)}
						value={date?.endDate}
						onChange={onEndDateChange}
						types={['day', 'week', 'month', 'year']}
						type="month"
					/>
				</div>
			) : null}
		</div>
	);
});

CustomDateRange.propTypes = {
	date: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	extra: PropTypes.object,
};

export default CustomDateRange;
