import { TranslationService } from '@asteria/language';

/**
 * @param { string[] } data
 * @returns { string[] }
 */
export function replace(data) {
	return data.filter(Boolean).map((value) => value.replace('$', ''));
}

/**
 * @param { { type?: string, category?: string, tag?: string, showTypedLabels?: boolean } } options
 * @returns { string }
 */
export const formatTag = (options = {}) => {
	const type = options?.type;
	const category = options?.category;
	const tag = options?.tag;

	if (category === '$type') {
		return TranslationService.get([
			replace([`navigation`, type]).join('.'),
			replace([`forecaster`, `navigation`, type]).join('.'),
			replace([`type`, type]).join('.'),
			replace([`tags`, `type`, type]).join('.'),
		]);
	}

	if (category === '$custom') {
		return tag;
	}

	return TranslationService.get(
		[
			replace([category]).join('.'),
			replace([tag]).join('.'),
			replace([category, tag]).join('.'),
			replace([category, tag]).join('-'),
			replace([`tags`, category]).join('.'),
			replace([`tags`, tag]).join('.'),
			replace([`tags`, category, tag]).join('.'),
			replace([`tags`, [category, tag].join('-')]).join('.'),
			/*
			...(FeatureService.isActive('typed-tags') && showTypedLabels
				? [
						replace([`tags`, type, category]).join('.'),
						replace([`tags`, type, tag]).join('.'),
						replace([`tags`, type, category, tag]).join('.'),
						replace([`tags`, type, [category, tag].join('-')]).join(
							'.',
						),
				  ]
				: []),
			 */
		].filter(Boolean),
		tag,
	);
};
