import React from 'react';

import { useQueries } from '@tanstack/react-query';
import {
	eachMonthOfInterval,
	endOfYear,
	formatISO,
	isAfter,
	isBefore,
	isSameMonth,
	startOfYear,
} from 'date-fns';
import PropTypes from 'prop-types';

import { parseDate } from '@asteria/utils-funcs/normalize';
import { useRequestLoader } from '@asteria/utils-hooks/useDataLoader';

import Month from '../month';

/**
 * @typedef { import('./types').Props } Props
 */

function isActive(current, compare) {
	if (typeof current === 'string') {
		return isSameMonth(parseDate(compare), parseDate(current));
	}

	if (current?.visible) {
		const startDate = current?.startDate;
		const endDate = current?.endDate;

		const byStartDate =
			startDate &&
			(isSameMonth(parseDate(compare), parseDate(startDate)) ||
				isAfter(parseDate(compare), parseDate(startDate)));

		const byEndDate =
			endDate &&
			(isSameMonth(parseDate(compare), parseDate(endDate)) ||
				isBefore(parseDate(compare), parseDate(endDate)));

		if (startDate && endDate) {
			return byStartDate && byEndDate;
		}
	}

	return false;
}

/** @type { React.FC<Pick<Props, 'date' | 'onAction' | 'onSubmit'>> } */
const PopupMonths = React.memo(function PopupMonths(props) {
	const { date, onSubmit, onAction } = props;

	const selectedDate = date?.visible ?? date;

	const months = eachMonthOfInterval({
		start: startOfYear(parseDate(selectedDate)),
		end: endOfYear(parseDate(selectedDate)),
	}).map((date) => formatISO(date, { representation: 'date' }));

	const dataloader = useRequestLoader({ onSubmit });

	const queries = useQueries({
		queries: months.map((date) => ({
			queryKey: ['time-selector', date, 'statistics'],
			queryFn: async ({ signal, meta }) => {
				return onSubmit?.('time:selector:statistics', {
					date,
					signal,
					dataloader: meta?.dataloader,
				});
			},

			refetchOnMount: true,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,

			meta: { dataloader },
		})),
	});

	return (
		<div className="grid grid-cols-4 gap-2">
			{months.map((month, index) => {
				const query = queries[index];

				return (
					<Month
						key={month}
						date={month}
						onAction={onAction}
						balance={query?.data?.balance}
						trends={query?.data?.trends}
						health={query?.data?.health}
						loading={query.isFetching && !query.isRefetching}
						active={isActive(date, month)}
					/>
				);
			})}
		</div>
	);
});

PopupMonths.propTypes = {
	date: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PopupMonths;
