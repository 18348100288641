import React from 'react';

import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import Graph from '../../../../components/credit/graph';

import './../../styles.scss';

const SectionGraph = React.memo((props) => {
	const { onAction, onSubmit } = props;

	return (
		<div
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-graph',
			)}
		>
			<div className="asteria-graphs">
				<div>
					<Graph onAction={onAction} onSubmit={onSubmit} />
				</div>
			</div>
		</div>
	);
});

SectionGraph.displayName = 'SectionGraph';
SectionGraph.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default SectionGraph;
