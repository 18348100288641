import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import ColorPicker from '@asteria/component-core/colorPicker';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';
import { MessageBoxToggle } from '@asteria/component-support/MessageBox';
import Contenter from '@asteria/component-tools/contenter';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { useAnalyticsData } from '@asteria/utils-funcs/analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import { DEFAULT_COLORS } from '../../../constants';
import { useTagDetails } from '../../../hooks';

const selectors = {
	tag: createSelector(
		(store) => store?.app?.tags,
		(_, _id) => _id,
		(tags, _id) => (tags ?? []).find((value) => value?._id === _id),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const RemoveTag = React.memo((props) => {
	const {
		onAction,
		onSubmit,
		_id,
		form,
		data: $data,
		content: $content,
	} = props;

	const store = useStore();

	const config = useConfig(['modals.tag.remove', 'modals.tag.edit.remove'], {
		deep: true,
	});

	const onRemove = React.useCallback(() => {
		const tag = selectors.tag(store.getState(), _id);

		Analytics.event('forecaster.tags.remove', tag);

		return onAction?.('tag:remove', tag);
	}, [store, _id, onAction]);

	const toggle = React.useMemo(
		() => ({
			variant: 'link',
			label: TranslationService.get([
				'forecaster.modal.tags.remove.label',
				'forecaster.modal.tags.edit.remove.label',
			]),
			size: 'sm',
			icon: 'help',
			iconActive: 'help-close',
			iconPosition: 'last',
			iconTooltip: TranslationService.get([
				'forecaster.modal.tags.remove.tooltip',
				'forecaster.modal.tags.edit.remove.tooltip',
			]),
		}),
		[],
	);

	const tagDetails = useTagDetails({
		_id: _id,
		form: form,
		onSubmit: onSubmit,
	});

	const data = $data ?? tagDetails;

	const dataContent = React.useMemo(() => {
		if (
			data?.statistics?.transactions?.count &&
			data?.statistics?.current?.count
		) {
			return config?.content;
		}

		if (data?.statistics?.transactions?.count) {
			return config?.history;
		}

		if (data?.statistics?.current?.count) {
			return config?.current;
		}

		return config?.empty;
	}, [config, data?.statistics]);

	const content = $content ?? dataContent;

	return (
		<MessageBoxToggle
			toggle={toggle}
			analyticsKey="tag.edit.remove.message-box"
		>
			{({ onClose }) => [
				<Content key="content">
					{content ? (
						<Contenter content={content} data={data} />
					) : (
						<TextGroup>
							<Title type="title" size="sm">
								{TranslationService.get([
									'tag.edit.remove.content.info',
								])}
							</Title>
							<Text>
								{TranslationService.get(
									['tag.edit.remove.content.info.question'],
									undefined,
									data,
								)}
							</Text>
						</TextGroup>
					)}
				</Content>,
				<Footer key="footer">
					<FooterSection position="first">
						<Button
							variant="tertiary"
							size="sm"
							label={TranslationService.get([
								'action.abort',
								'action.cancel',
							])}
							onClick={onClose}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							variant="primary"
							size="sm"
							label={TranslationService.get([
								'action.submit',
								'action.remove',
							])}
							onClick={onRemove}
						/>
					</FooterSection>
				</Footer>,
			]}
		</MessageBoxToggle>
	);
});

RemoveTag.displayName = 'RemoveTag';
RemoveTag.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	_id: PropTypes.string,
	form: PropTypes.object,

	data: PropTypes.object,
	content: PropTypes.object,
};

const EditTagModal = (props) => {
	const {
		open,
		onClose,
		onAction,
		onSubmit,
		data = null,
		form,
		remove,
	} = props;

	const colors =
		useConfig(['tags.colors', 'forecaster.tags.colors']) ?? DEFAULT_COLORS;

	const dispatch = useDispatch();
	const store = useStore();

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);

	const [selectedColor, setSelectedColor] = useState(null);

	useEffect(() => {
		if (data?.color) {
			setSelectedColor(data?.color);
		} else {
			setSelectedColor(colors?.[0]);
		}
	}, [data?.color, colors]);

	const submitAnalyticsDataRef = useAnalyticsData({
		previous: data,
		current: {
			categoryName: '$custom',
			color: selectedColor,
		},
	});

	const handleSubmit = useCallback(
		(form) => {
			Analytics.event('forecaster.header.tag.edit.update', {
				...submitAnalyticsDataRef.current,
				current: {
					...form,
					...submitAnalyticsDataRef.current.current,
				},
			});

			onAction?.('tag:update', {
				_id: form?._id || undefined,
				name: form?.name,
				categoryName: '$custom',
				color: selectedColor,
			});
		},
		[onAction, selectedColor, submitAnalyticsDataRef],
	);

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	const maxLength =
		useConfig(
			[
				'input.maxLength',
				'forms.input.maxLength',
				'tags.input.maxLength',
				'forecaster.tags.input.maxLength',
			],
			{ deep: true },
		) ?? 25;

	const minLength =
		useConfig(
			[
				'input.minLength',
				'forms.input.minLength',
				'tags.input.minLength',
				'forecaster.tags.input.minLength',
			],
			{ deep: true },
		) ?? 2;

	const onValidate = React.useCallback(
		(value) => {
			const tags = AppStore.selectors.tags(store.getState());

			return !tags.some(
				(object) =>
					data?._id !== (object?._id ?? object?.id) &&
					object?.name === value,
			);
		},
		[data?._id, store],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			size="sm"
			className={cn('asteria--type-tags', {
				'asteria--action-create': !data?._id,
				'asteria--action-update': data?._id,
			})}
		>
			<Form defaultValues={data} onSubmit={handleSubmit}>
				<Wrapper>
					<Header onClose={handleClose}>
						{data?._id
							? TranslationService.get(
									'forecaster.modal.tags.update.title',
							  )
							: TranslationService.get(
									'forecaster.modal.tags.create.title',
							  )}
					</Header>
					<Content>
						<FormWrapper>
							<Content>
								<Input
									type="text"
									name="name"
									label={
										data?._id
											? TranslationService.get(
													'forecaster.modal.tags.label.update',
											  )
											: TranslationService.get(
													'forecaster.modal.tags.label.create',
											  )
									}
									minLength={minLength}
									maxLength={maxLength}
									validate={onValidate}
									direction="vertical"
									formNamePrefix="forecaster.modals.tag.edit"
									helpText={(value) =>
										TranslationService.get(
											[
												'forecaster.modal.tags.input.help',
											],
											// eslint-disable-next-line spellcheck/spell-checker
											'{{ count }} / {{ max }} tillgängliga tecken',
											{
												count: value.length,
												max: maxLength,
												available: Math.max(
													maxLength - value.length,
													0,
												),
											},
										)
									}
									required
									postfix={
										<ColorPicker
											className="forecaster__modal__tags__dropdown__wrapper"
											colors={colors}
											selectedColor={selectedColor}
											onSelect={setSelectedColor}
											tooltip={TranslationService.get(
												[
													'forecaster.tags.colors.tooltip',
													'forecaster.modal.tags.color.pick',
												],
												// eslint-disable-next-line spellcheck/spell-checker
												'Klicka för att välja färg till kategori',
											)}
										/>
									}
								/>
								{data?._id ? (
									<RemoveTag
										onAction={onAction}
										onSubmit={onSubmit}
										_id={data?._id}
										form={form}
										data={remove?.data}
										content={remove?.content}
									/>
								) : null}
							</Content>
						</FormWrapper>
					</Content>
					<Footer>
						<FooterSection>
							{hasHistory ? (
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.back',
										'forecaster.modal.tags.button.back',
									])}
									onClick={handleClose}
									analyticsKey="forecaster.modal.tags.back"
								/>
							) : (
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.cancel',
										'forecaster.modal.tags.button.cancel',
									])}
									onClick={handleClose}
									analyticsKey="forecaster.modal.tags.cancel"
								/>
							)}
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.save',
									'forecaster.modal.tags.button.save',
								])}
								type="submit"
								analyticsKey="forecaster.modal.tags.save"
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>
		</Modal>
	);
};

EditTagModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	data: PropTypes.object,
	form: PropTypes.object,

	remove: PropTypes.shape({
		data: PropTypes.object,
		content: PropTypes.object,
	}),
};

export default React.memo(EditTagModal);
