/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const LANGUAGES = [
	{
		// eslint-disable-next-line spellcheck/spell-checker
		title: TranslationService.get('translation.swedish', 'Svenska'),
		code: 'sv',
	},
	{
		// eslint-disable-next-line spellcheck/spell-checker
		title: TranslationService.get('translation.english', 'Norwegian'),
		code: 'no',
	},
	{
		// eslint-disable-next-line spellcheck/spell-checker
		title: TranslationService.get('translation.english', 'Danish'),
		code: 'dk',
	},
	{
		// eslint-disable-next-line spellcheck/spell-checker
		title: TranslationService.get('translation.english', 'Finnish'),
		code: 'fi',
	},
	{
		// eslint-disable-next-line spellcheck/spell-checker
		title: TranslationService.get('translation.english', 'English'),
		code: 'en',
	},
	// {
	// 	title: TranslationService.get('translation.french', 'Français'),
	// 	code: 'fr',
	// },
	// {
	// 	title: TranslationService.get('translation.spanish', 'Español'),
	// 	code: 'es',
	// },
	// {
	// 	title: TranslationService.get('translation.portuguese', 'Português'),
	// 	code: 'pt',
	// },
];

const Languages = React.memo(({ onChange }) => {
	const [language, setLanguage] = React.useState(
		localStorage.getItem('asteriaLanguage') ?? 'sv',
	);

	const onLanguageChange = React.useMemo(
		() => (code) => () => {
			localStorage.setItem('asteriaLanguage', code);

			setLanguage(code);

			return onChange?.(code);
		},
		[onChange],
	);

	return (
		<Group
			className="asteria-view__auth-languages"
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="center"
		>
			{LANGUAGES.map((item) => (
				<Button
					key={item.code}
					label={item.title}
					onClick={onLanguageChange(item.code)}
					active={item.code === language}
					size="sm"
				/>
			))}
		</Group>
	);
});

Languages.displayName = 'Languages';

Languages.propTypes = { onChange: PropTypes.func };

export default Languages;
