import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import LevelNavigationDropdown from './dropdown';
import LevelNavigationSlider from './slider';

import './styles.scss';

const LevelNavigation = React.memo((props) => {
	const {
		className,
		level,
		onBack,
		active,
		visible,
		base,
		onAction,
		config,
		showBackAlways,

		back,
		toggleable,
	} = props;

	return (
		<Group
			className={cn('asteria-component__level-navigation', className)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
		>
			{level || showBackAlways ? (
				<Group
					className="asteria-component__level-navigation-back-wrapper"
					direction="horizontal"
					horizontalAlign="left"
					verticalAlign="center"
				>
					<Button
						className="asteria-component__level-navigation-back"
						icon={back?.icon ?? 'chevron-left'}
						size={back?.size}
						iconSize={back?.iconSize}
						onClick={onBack}
						tooltip={back?.tooltip}
						variant={back?.variant}
					/>
					<Title size="sm">{back?.label}</Title>
				</Group>
			) : null}
			<div
				className={cn(
					'asteria-component__level-navigation-content',
					`asteria--level-${level}`,
				)}
			>
				<LevelNavigationSlider
					config={config}
					active={active}
					visible={visible}
					base={base}
					onAction={onAction}
					toggleable={toggleable}
				/>
				<LevelNavigationDropdown
					config={config}
					active={active}
					visible={visible}
					base={base}
					onAction={onAction}
				/>
			</div>
		</Group>
	);
});

LevelNavigation.displayName = 'LevelNavigation';

LevelNavigation.propTypes = {
	className: PropTypes.string,
	level: PropTypes.number,
	config: PropTypes.arrayOf(PropTypes.object),
	active: PropTypes.arrayOf(PropTypes.string),
	base: PropTypes.arrayOf(PropTypes.string),
	path: PropTypes.arrayOf(PropTypes.string),
	visible: PropTypes.object,
	onAction: PropTypes.func,
	onBack: PropTypes.func,

	showBackAlways: PropTypes.bool,

	back: PropTypes.shape({
		size: PropTypes.string,
		icon: PropTypes.string,
		iconSize: PropTypes.string,
		label: PropTypes.string,
		variant: PropTypes.string,

		tooltip: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
			PropTypes.object,
		]),
	}),

	toggleable: PropTypes.bool,
};

export default LevelNavigation;
