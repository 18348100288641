import React, { useContext, useMemo } from 'react';

const Context = React.createContext({});

export default function useIsMounted(key) {
	const context = useContext(Context);
	return useMemo(() => context?.[key], [context, key]);
}

export { Context };
