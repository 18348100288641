import { IntegrationGateway } from '@asteria/backend-utils-services';

import { setIntegrations } from '@asteria/datalayer/stores/integrations';

export const FIELDS = `
    _id
    id
    key
    name: key
    type
    lastSync
    disabled
		lastSync
    status {
        state
    }
    config {
        connected
        server
        client
        errors {
            code
            message
        }
    }
    actions {
        _id
        action
        status
        data
    }
`;

/**
 * @param { { accessToken: string, variables?: object, fields?: string, dispatch?: unknown } } options
 */
export async function fetch(options) {
	const { accessToken, fields = FIELDS, variables = {}, dispatch } = options;

	const response = await IntegrationGateway.integration
		.fetch(
			{ fields: fields, isBulk: true, ...variables },
			{ token: accessToken },
		)
		.catch(() => []);

	dispatch?.(setIntegrations(response));

	return response;
}
