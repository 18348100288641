import React, { useContext } from 'react';

import { useFormContext } from 'react-hook-form';

import { TooltipWrapper } from '@asteria/component-core/tooltip';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

import { useDynamicFormData } from '../../hooks';
import Context from '../../logic/context';

import './styles.scss';

const ForecasterHeaderTextGroup = React.memo(() => {
	const { history } = useContext(Context);

	const { getValues } = useFormContext();

	const data = useDynamicFormData({
		useForm: useFormValues,
		useStaticForm: getValues,
		category: null,
		tag: null,
		history: history,

		useCurrentBalance: true,
		useUnpaidValues: true,
	});

	return (
		<TooltipWrapper
			tooltip={TranslationService.get(
				['forecaster.header.overview.tooltip'],
				undefined,
				data,
			)}
		>
			<TextGroup className="asteria-component__text-amount">
				<Text>
					{TranslationService.get(
						['forecaster.header.account'],
						undefined,
						data,
					)}
				</Text>
				<Title size="xs">
					{TranslationService.get(
						'forecaster.header.total',
						undefined,
						data,
					)}
				</Title>
			</TextGroup>
		</TooltipWrapper>
	);
});

ForecasterHeaderTextGroup.displayName = 'ForecasterHeaderTextGroup';

export default ForecasterHeaderTextGroup;
