import React from 'react';

import PropTypes from 'prop-types';

import * as Constants from '../../constants';

import DatePickerContentMonthDefault from './DatePickerContentMonthDefault';
import DatePickerContentMonthScroll from './DatePickerContentMonthScroll';

import './index.scss';

const DatePickerContentMonth = (props) => {
	const { behavior } = props;

	switch (behavior) {
		case 'scroll': {
			return <DatePickerContentMonthScroll {...props} />;
		}

		default:
			return <DatePickerContentMonthDefault {...props} />;
	}
};

DatePickerContentMonth.propTypes = {
	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentMonth.defaultProps = {};

export default DatePickerContentMonth;
