import React from 'react';

import { format, isThisYear } from 'date-fns';
import PropTypes from 'prop-types';

import XAxisColumn from '@asteria/component-forecaster/components/x-axis/column';

import { TranslationService } from '@asteria/language';

import LoanContext from '../../../context/loan';

const LoanXAxisColumn = React.memo((props) => {
	const { date } = props;

	const { range } = React.useContext(LoanContext);

	let label = TranslationService.get(
			['xaxis.label', 'financial.page.loan.xaxis.label'],
			'{{ date|date:"MMM"|cap }}',
			{ date: date },
		),
		prefix,
		current;

	if (range === 'year') {
		label = format(date, 'yyyy');
		prefix = null;
		current = isThisYear(date);
	}

	return (
		<XAxisColumn
			date={date}
			render={() => null}
			prefix={prefix}
			label={label}
			current={current}
		/>
	);
});

LoanXAxisColumn.displayName = 'LoanXAxisColumn';
LoanXAxisColumn.propTypes = { date: PropTypes.string };

export default LoanXAxisColumn;
