import React from 'react';

import PropTypes from 'prop-types';

import { ClientOverviewContent } from '@asteria/component-client-overview';

import { useTourShowAction } from '@asteria/utils-hooks/tour';

import './styles.scss';

const ClientsView = (props) => {
	const { onAction, onSubmit } = props;

	useTourShowAction();

	return (
		<div className="asteria-view__clients">
			<ClientOverviewContent
				onAction={onAction}
				onSubmit={onSubmit}
				headerType="page"
			/>
		</div>
	);
};

ClientsView.propTypes = {
	onFetch: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ClientsView;
