import { v4 as uuid } from 'uuid';

import { AnalyticsService } from '@asteria/backend-utils-services';

const EXECUTOR = {
	console: ({ items }) => {
		// eslint-disable-next-line no-console
		console.log('Analytics batch', items);
	},
	api: ({ items, accessToken }) =>
		AnalyticsService.analytics.create(
			{
				input: items.map(({ key, ...rest }) => ({
					type: key,
					data: rest,
				})),
			},
			{ token: accessToken },
		),
};

const Analytics = {
	queue: [],
	meta: {},
	mode: 'console',
	accessToken: false,
	executor: null,
	timeout: 10_000,
	group: null,
	groups: [],
	setMetaKey: (key, value) => {
		Analytics.meta[key] = value;
	},
	startFlow: (type, options = {}) => {
		const group = { ...options, type: type, id: uuid() };

		Analytics.group = group;
		Analytics.groups = Array.from(Analytics.groups).concat({ ...group });
	},
	endFlow: (type, all = false) => {
		if (type) {
			let index = Analytics.groups.findIndex(
				(group) => group?.type === type,
			);

			while (index !== -1) {
				const groups = Array.from(Analytics.groups);
				groups.splice(index, 1);
				Analytics.groups = groups;

				if (!all) {
					break;
				}

				index = Analytics.groups.findIndex(
					(group) => group?.type === type,
				);
			}
		} else {
			Analytics.groups.pop();
		}

		Analytics.group = Analytics.groups.slice(-1)[0] ?? null;
	},
	event: (eventKey, payload) => {
		Analytics.queue.push({
			key: eventKey,
			payload: payload,
			meta: Analytics.meta,
			date: new Date(),
			group: Analytics.group ? { ...Analytics.group } : null,
			groups: Array.from(Analytics.groups),
		});
	},
	init: () => {
		setTimeout(Analytics.dispatch, Analytics.timeout);
	},
	dispatch: async () => {
		try {
			if (
				!Analytics.queue ||
				Analytics.queue.length === 0 ||
				!Analytics.accessToken
			) {
				setTimeout(Analytics.dispatch, Analytics.timeout);
				return;
			}

			const items = [...Analytics.queue];
			Analytics.queue.length = 0;

			const executor =
				Analytics.executor ?? EXECUTOR[Analytics.mode] ?? EXECUTOR.api;

			await executor({
				items: items,
				accessToken: Analytics.accessToken,
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}

		setTimeout(Analytics.dispatch, Analytics.timeout);
	},
};

export default Analytics;
