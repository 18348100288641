import React from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import {
	ActionBarSectionActions,
	Action as BaseAction,
} from '@asteria/component-actionbar';
import { useFormValues } from '@asteria/component-form';
import Prefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useProperties, useTagContent } from '../../../hooks';
import Context from '../../../logic/context';

import './styles.scss';

const CategoryAction = React.memo((props) => {
	const { className, data, onAction } = props;
	const { type, category, tag } = data;

	const { history } = React.useContext(Context);

	const { getValues } = useFormContext();

	const { colors, label } = useProperties({
		type: type,
		category: category,
		tag: tag,

		useForm: useFormValues,
		useStaticForm: getValues,
	});

	const handleClick = React.useCallback(
		() => onAction?.('navigate', { ...data, event: 'action' }),
		[data, onAction],
	);

	const content = useTagContent({
		type: type,
		category: category,
		tag: tag,

		useForm: useFormValues,
		useStaticForm: getValues,

		history: history,

		key: 'action',
	});

	return (
		<BaseAction
			className={cn(
				'asteria-component__forecaster-action',
				'asteria--variant-category',
				className,
			)}
			title={
				<Group
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="left"
				>
					<Prefix colors={colors} size="lg" />
					<Title size="sm">
						{TranslationService.get(
							[
								'forecaster.action.title',
								`forecaster.action.${type}.title`,
								tag ? `forecaster.action.tag.title` : false,
								tag
									? `forecaster.action.tag.${type}.title`
									: false,
								tag
									? `forecaster.action.tag.${type}.${category}.title`
									: false,
								tag
									? `forecaster.action.tag.${type}.${category}.${tag}.title`
									: false,
							],
							undefined,
							{
								tag: label,
							},
						)}
					</Title>
				</Group>
			}
			content={content}
			variant="forecaster-actions"
			onClick={handleClick}
			actions={
				<ActionBarSectionActions>
					<Button
						icon="action-arrow-right"
						variant="tertiary"
						label={TranslationService.get(
							[
								'forecaster.action.button.label',
								`forecaster.action.${type}.button.label`,
								tag
									? `forecaster.action.tag.button.label`
									: false,
								tag
									? `forecaster.action.tag.${type}.button.label`
									: false,
								tag
									? `forecaster.action.tag.${type}.${category}.button.label`
									: false,
								tag
									? `forecaster.action.tag.${type}.${category}.${tag}.button.label`
									: false,
							],
							undefined,
							{
								label: label,
							},
						)}
						onClick={handleClick}
					/>
				</ActionBarSectionActions>
			}
		/>
	);
});

CategoryAction.displayName = 'Action';

CategoryAction.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	onAction: PropTypes.func,
};

export default CategoryAction;
