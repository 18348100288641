import React from 'react';

import { isEqual } from 'lodash-es';

/**
 * @type { React.useMemo }
 */
export function useDeepMemo(fn, key) {
	const ref = React.useRef(null);

	if (!ref?.current || !isEqual(key, ref.current.key)) {
		ref.current = { key: key, value: fn() };
	}

	return ref.current.value;
}

/**
 * @type { React.useCallback }
 */
export function useDeepCallback(fn, key) {
	const ref = React.useRef(null);

	if (!ref?.current || !isEqual(key, ref.current.key)) {
		ref.current = { key: key, value: fn };
	}

	return ref.current.value;
}

export function useMemoizeHook(useHook, ...args) {
	const value = useHook(...args);

	return useDeepMemo(() => value, [value]);
}

export function withDeepMemo(Component) {
	return React.memo(Component, (prev, next) => isEqual(prev, next));
}
