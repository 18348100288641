import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const ActionSplash = React.memo(function ActionSplash(props) {
	const { onClose, onAction, onSubmit, type, data } = props;

	const close = React.useCallback((event) => onClose?.(event), [onClose]);

	const trigger = React.useCallback(
		(event) => {
			close(event);

			if (data?.action) {
				if (data?.action?.action !== 'close') {
					onAction?.(data?.action?.action, data?.action?.data);
				}
			}
		},
		[close, data?.action, onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction, onSubmit, onClose }),
		[onAction, onClose, onSubmit],
	);

	return (
		<Modal
			className={cn('asteria-component__action-splash', data?.className)}
			onClose={close}
			size={data?.size ?? 'sm'}
		>
			{data?.content ? (
				<Contenter content={data?.content} actions={actions} flat />
			) : (
				<Wrapper scroll>
					<Header onClose={close}>
						{TranslationService.get(
							[
								type,
								`${type}.title`,
								'action.splash.title',
								`action.splash.${type}.title`,
							],
							type,
							{ data: data },
						)}
					</Header>
					<Content>
						{TranslationService.hasKey([
							`${type}.title2`,
							'action.splash.content.title',
							`action.splash.${type}.content.title`,
						]) ? (
							<Translation
								translationKey={[
									`${type}.title2`,
									'action.splash.content.title',
									`action.splash.${type}.content.title`,
								]}
								data={{ data: data }}
								Component={Title}
								size="sm"
							/>
						) : null}

						{TranslationService.hasKey([
							`${type}.text.intro`,
							'action.splash.content.intro',
							`action.splash.${type}.content.intro`,
						]) ? (
							<Translation
								translationKey={[
									`${type}.text.intro`,
									'action.splash.content.intro',
									`action.splash.${type}.content.intro`,
								]}
								data={{ data: data }}
								Component={Text}
							/>
						) : null}

						<Translation
							translationKey={[
								`${type}.text`,
								'action.splash.content.text',
								`action.splash.${type}.content.text`,
							]}
							data={{ data: data }}
							Component={Text}
						/>
					</Content>
					<Footer>
						<FooterSection position="last">
							<Button
								variant="secondary"
								label={TranslationService.get([
									`${type}.button`,
									`action.splash.action`,
									`action.splash.${type}.action`,
								])}
								onClick={trigger}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			)}
		</Modal>
	);
});

ActionSplash.displayName = 'ActionSplash';

ActionSplash.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onAction: PropTypes.func,

	type: PropTypes.string,

	data: PropTypes.shape({
		className: PropTypes.string,
		size: PropTypes.string,
		content: PropTypes.arrayOf(PropTypes.object),

		action: PropTypes.shape({
			action: PropTypes.string,
			data: PropTypes.any,
		}),
	}),
};

export default ActionSplash;
