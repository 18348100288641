import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';

import Prefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const LoanLineGraphTooltip = React.memo((props) => {
	const { data } = props;

	return (
		<Group
			className={cn(
				'asteria-component__loan-tooltip',
				'asteria--type-dot',
			)}
			direction="vertical"
		>
			<Title size="xxs" className="asteria-component__loan-tooltip-title">
				{TranslationService.get('credit.loan.dot.tooltip.title')}
			</Title>
			<Group direction="horizontal" horizontalAlign="space-between">
				<Group direction="horizontal" verticalAlign="center">
					<Prefix colors={['account', 'forecast']} />
					<Text size="sm">
						{TranslationService.get(
							'credit.loan.dot.tooltip.forecast.text',
						)}
					</Text>
				</Group>
				<Text size="sm">
					{TranslationService.get(
						[
							'credit.loan.tooltip.value',
							'credit.loan.dot.tooltip.total.value',
						],
						undefined,
						{
							value: data?.forecast,
						},
					)}
				</Text>
			</Group>
			<Group className="asteria-component__loan-tooltip-forecast">
				<Group direction="horizontal" horizontalAlign="space-between">
					<Group direction="horizontal" verticalAlign="center">
						<Prefix />
						<Text size="xs">
							{TranslationService.get(
								'credit.loan.dot.tooltip.amortization.text',
							)}
						</Text>
					</Group>
					<Text size="xs">
						{TranslationService.get(
							[
								'credit.loan.tooltip.value',
								'credit.loan.dot.tooltip.amortization.value',
							],
							undefined,
							{
								value: data?.interest * -1,
							},
						)}
					</Text>
				</Group>
				<Group direction="horizontal" horizontalAlign="space-between">
					<Group direction="horizontal" verticalAlign="center">
						<Prefix />
						<Text size="xs">
							{TranslationService.get(
								'credit.loan.dot.tooltip.interest.text',
							)}
						</Text>
					</Group>
					<Text size="xs">
						{TranslationService.get(
							[
								'credit.loan.tooltip.value',
								'credit.loan.dot.tooltip.interest.value',
							],
							undefined,
							{
								value: data?.amortization * -1,
							},
						)}
					</Text>
				</Group>
			</Group>
			<Group direction="horizontal" horizontalAlign="space-between">
				<Group direction="horizontal" verticalAlign="center">
					<Prefix />
					<Text size="sm" weight="bold">
						{TranslationService.get(
							'credit.loan.dot.tooltip.total.text',
						)}
					</Text>
				</Group>
				<Text size="sm" weight="bold">
					{TranslationService.get(
						[
							'credit.loan.tooltip.value',
							'credit.loan.dot.tooltip.total.value',
						],
						undefined,
						{
							value: data?.value,
						},
					)}
				</Text>
			</Group>
		</Group>
	);
});

LoanLineGraphTooltip.displayName = 'LoanLineGraphTooltip';
LoanLineGraphTooltip.propTypes = {
	data: PropTypes.object,
};

export default LoanLineGraphTooltip;
