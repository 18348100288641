import { createSlice } from '@reduxjs/toolkit';

import { ACTIONS } from '../constants';

const local = JSON.parse(
	window.localStorage.getItem('AsteriaFeatures') ?? '[]',
);

const initialState = {
	features: null,
};

export const featuresSlice = createSlice({
	name: 'features',
	initialState: initialState,
	reducers: {
		setFeatures: (state, action) => {
			state.features = action.payload;

			const external = []
				.concat(window.__ASTERIA_FEATURES)
				.concat(local)
				.filter(Boolean);

			external.forEach((feature) => {
				if (feature.enabled === false) {
					delete state.features[feature.key];
				} else {
					state.features[feature.key] = feature;
				}
			});
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { setFeatures } = featuresSlice.actions;

export default featuresSlice.reducer;
