const useWrapper = (id) => {
	const wrapper = document.getElementById(id);

	if (!wrapper) {
		const el = document.createElement('div');
		el.setAttribute('id', id);

		if (document.body.append) {
			document.body.append(el);
		} else {
			document.body.appendChild(el);
		}
	}

	return document.getElementById(id);
};

export default useWrapper;
