import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import AnimatedSnackbar from './AnimatedSnackbar';

import './styles.scss';

const selectors = {
	snackbars: createSelector(
		(store) => store?.snackbar?.data ?? [],
		(_, position) => position,
		(value, position) =>
			value
				.filter((object) => object?.position === position)
				.reverse()
				.slice(0, 1),
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const SnackbarList = (props) => {
	const { onAction, position } = props;

	const snackbars = useSelector((store) =>
		selectors.snackbars(store, position),
	);

	if (!snackbars.length) {
		return null;
	}

	return (
		<div
			className={cn('asteria-component__snackbar__wrapper__list', {
				[`asteria-position--${position}`]: position,
			})}
		>
			{snackbars.map((object) => (
				<AnimatedSnackbar
					key={object._id}
					object={object}
					onAction={onAction}
				/>
			))}
		</div>
	);
};

SnackbarList.displayName = 'SnackbarList';

SnackbarList.propTypes = {
	position: PropTypes.string,
	onAction: PropTypes.func,
};

SnackbarList.defaultProps = {};

export default SnackbarList;
