import React from 'react';

import PropTypes from 'prop-types';

import { BadgeWrapper } from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';
import { stateClasses } from '@asteria/component-core/utils';

import { Service as FeatureService } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useTourKey } from '@asteria/utils-hooks/tour';

import NavigationContext from '../context';
import { formatLabel, isActive } from '../utils';

import FeatureWrapper from './FeatureWrapper';
import { NavigationButtonContent } from './NavigationButton';

import './styles.scss';

const NavigationDropdownManualItem = React.memo((props) => {
	const { onClick, icon, iconPosition, slug, label, active, badge } = props;

	const { prefix, postfix } = React.useMemo(() => {
		const value = (
			<Group verticalAlign="center" horizontalAlign="center">
				<Button icon={icon} />
			</Group>
		);

		let prefix = null,
			postfix = null;

		if (icon) {
			if ((iconPosition ?? 'first') === 'first') {
				prefix = value;
			}

			if (iconPosition === 'last') {
				postfix = value;
			}
		}

		return { prefix, postfix };
	}, [icon, iconPosition]);

	return (
		<DropdownItem
			onClick={onClick}
			prefix={prefix}
			postfix={postfix}
			analyticsKey={`navigation.item.${slug}`}
			active={active}
			className={cn({
				[`asteria--type-${slug}`]: slug,
			})}
		>
			<BadgeWrapper size="sm" badge={badge}>
				{formatLabel(label)}
			</BadgeWrapper>
		</DropdownItem>
	);
});

NavigationDropdownManualItem.displayName = 'NavigationDropdownManualItem';
NavigationDropdownManualItem.propTypes = {
	onClick: PropTypes.func,
	icon: PropTypes.string,
	iconPosition: PropTypes.string,
	slug: PropTypes.string,
	label: PropTypes.string,
	badge: PropTypes.string,
	active: PropTypes.bool,
};

const NavigationDropdownManual = React.memo((props) => {
	const {
		variant,
		direction,
		onNavigate,
		onAction,
		active,
		route,
		showLabel,
	} = props;

	const { current } = React.useContext(NavigationContext);

	const label = React.useMemo(
		() => (showLabel ? formatLabel(route?.label) : null),
		[route?.label, showLabel],
	);

	const toggle = React.useMemo(
		() => ({
			as: 'div',
			className: cn(
				'asteria-component__navigation__item',
				{
					[`asteria-component__navigation__item--variant-${variant}`]:
						variant,
					'asteria-component__navigation__item--no-label': !label,
					'asteria-component__navigation__item--no-icon':
						!route?.icon,
					'asteria-component__navigation__item--no-sub':
						!route?.sub?.length,
				},
				route?.className,
				stateClasses({ active: active, onClick: onNavigate }),
			),
			children: (
				<NavigationButtonContent
					onNavigate={onNavigate}
					onAction={onAction}
					active={active}
					route={route}
					label={label}
					showLabel={showLabel}
					direction={direction}
				/>
			),
		}),
		[
			active,
			direction,
			label,
			onAction,
			onNavigate,
			route,
			showLabel,
			variant,
		],
	);

	const analytics = React.useMemo(
		() => ({ slug: route?.slug }),
		[route?.slug],
	);

	const tourKey = useTourKey();

	const routes = (route?.children?.data ?? [])
		.filter(
			(route) =>
				!route?.feature || FeatureService.isActive(route?.feature),
		)
		.filter((route) => route?.slug !== 'tour' || tourKey);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'navigation:tour') {
				return onAction?.('tour:start', tourKey);
			}

			return onAction?.(action, data);
		},
		[onAction, tourKey],
	);

	const onClick = (route) => () => {
		if (route?.action) {
			return handleAction?.(...[].concat(route?.action));
		}

		return onNavigate?.(route?.path);
	};

	return (
		<FeatureWrapper feature={route?.feature}>
			<div
				className={cn(
					'asteria-component__navigation__item',
					'asteria-component__navigation__item__dropdown',
					{
						[`asteria-component__navigation--${route?.slug}`]:
							route?.slug,
					},
					route?.className,
				)}
			>
				<Dropdown
					{...(route?.props ?? {})}
					single
					toggle={toggle}
					title={TranslationService.get([
						'navigation.dropdown.title',
						`navigation.${route?.slug}.dropdown.title`,
					])}
					analyticsKey="navigation.item"
					analytics={analytics}
					className="asteria-component__navigation-dropdown"
				>
					{routes.map((route) => (
						<NavigationDropdownManualItem
							key={route?.slug}
							onClick={onClick(route)}
							icon={route?.icon}
							iconPosition={route?.iconPosition}
							slug={route?.slug}
							badge={route?.badge}
							label={route?.label}
							active={isActive(route?.path, current)}
						/>
					))}
				</Dropdown>
			</div>
		</FeatureWrapper>
	);
});

NavigationDropdownManual.displayName = 'NavigationDropdownManual';
NavigationDropdownManual.propTypes = {
	variant: PropTypes.oneOf(['default', 'stacked']),
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	route: PropTypes.object,
	active: PropTypes.bool,
	showLabel: PropTypes.bool,
};

const NavigationDropdown = React.memo((props) => {
	const { route } = props;

	if (route?.children?.type === 'manual') {
		return <NavigationDropdownManual {...props} />;
	}

	return null;
});

NavigationDropdown.displayName = 'NavigationDropdown';

NavigationDropdown.propTypes = {
	variant: PropTypes.oneOf(['default', 'stacked']),
	direction: PropTypes.oneOf(['vertical', 'horizontal']),
	onNavigate: PropTypes.func,
	onAction: PropTypes.func,
	route: PropTypes.object,
	active: PropTypes.bool,
	showLabel: PropTypes.bool,
};

export default NavigationDropdown;
