import { FeatureService } from '@asteria/backend-utils-services';

import { setFeatures } from '@asteria/datalayer/stores/features';

/**
 * @param { { accessToken: string, partnerId: string, dispatch?: unknown } } options
 */
export async function fetch(options = {}) {
	const { accessToken, partnerId, dispatch } = options;

	const response = await FeatureService.feature
		.fetchActive(
			{ partnerId: partnerId, isBulk: true },
			{ token: accessToken },
		)
		.catch(() => {
			if (accessToken === '__OFFLINE__') {
				return JSON.parse(
					localStorage.getItem('asteriaFeatures') ?? '[]',
				).reduce(
					(acc, value) => ({ ...acc, [value]: { name: value } }),
					{},
				);
			}
		});

	dispatch?.(setFeatures(response));

	return response;
}

export async function fetchAll({ accessToken }) {
	return FeatureService.feature.fetch({}, { token: accessToken });
}
