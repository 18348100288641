import React from 'react';

import PropTypes from 'prop-types';

import { useProperties } from '../../../../../hooks';
import { FormContext } from '../../../../../withForm';

import View from './view';

const Provider = (props) => {
	const { object } = props;

	const { useForm, useStaticForm } = React.useContext(FormContext);

	const { colors, label } = useProperties({
		type: object?.type,
		category: object?.category?.name,
		tag: object?.name,

		useForm: useForm,
		useStaticForm: useStaticForm,
	});

	return <View label={label} colors={colors} />;
};

Provider.displayName = 'TagSelectorItemLabelProvider';
Provider.propTypes = {
	object: PropTypes.object,
};

export default Provider;
