import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';

import { useFeature } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import IntegrationPanel from './Panel';
import IntegrationSplash from './Splash';

import './styles.scss';

const OnboardingLanding = (props) => {
	const { className } = props;

	const hasBankFeature = useFeature('bank-integrations');

	const slugs = useConfig(['onboarding.layout'], {
		callback: (object) => Object.keys(object?.panel ?? {}),
	});

	const dispatch = useDispatch();

	const handleBankCreate = React.useCallback(
		() =>
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
					data: { type: 'bank' },
				}),
			),
		[dispatch],
	);

	const handleERPCreate = React.useCallback(
		() =>
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
					data: { type: 'erp' },
				}),
			),
		[dispatch],
	);

	return (
		<div
			className={cn('asteria-component__onboarding__landing', className)}
		>
			<div className="asteria-component__onboarding__landing__splash">
				<Group
					direction="horizontal"
					className={cn({
						'asteria--state-single': !hasBankFeature,
					})}
				>
					{hasBankFeature ? <IntegrationSplash type="bank" /> : null}
					<IntegrationSplash type="erp" />
				</Group>
				<Text>
					{TranslationService.get(
						'onboarding.landing.splash.footer',
						// eslint-disable-next-line spellcheck/spell-checker
						'* Företagskollen är för dig som kund, du väljer själv om du vill dela din data och prognos med banken/rådgivare eller bara vill kunna se det själv. Ditt val just nu är att du delar / delar inte din data med banken.',
					)}
				</Text>
			</div>

			{slugs.length ? (
				<>
					<div className="asteria-component__onboarding__landing__slugs">
						<Title size="base">
							{TranslationService.get(
								'onboarding.landing.panels.header',
								// eslint-disable-next-line spellcheck/spell-checker
								'Anslut ditt bokföringssystem för att också se',
							)}
						</Title>
						<div className="asteria-component__onboarding__landing__panel-wrapper">
							{slugs.map((slug) => (
								<IntegrationPanel key={slug} slug={slug} />
							))}
						</div>
					</div>
					<Group
						className="asteria-component__onboarding__landing__actions"
						direction="horizontal"
						verticalAlign="center"
						horizontalAlign="center"
					>
						{hasBankFeature ? (
							<Button
								label={TranslationService.get([
									'onboarding.layout.splash.action',
									'onboarding.layout.action',
									'onboarding.layout.splash.bank.action',
									'onboarding.layout.bank.action',
								])}
								variant="secondary"
								onClick={handleBankCreate}
								analyticsKey="onboarding.layout.action"
								analytics={{ type: 'bank' }}
							/>
						) : null}
						<Button
							label={TranslationService.get([
								'onboarding.layout.splash.action',
								'onboarding.layout.action',
								'onboarding.layout.splash.erp.action',
								'onboarding.layout.erp.action',
							])}
							variant="secondary"
							onClick={handleERPCreate}
							analyticsKey="onboarding.layout.action"
							analytics={{ type: 'erp' }}
						/>
					</Group>
				</>
			) : null}

			<div className="asteria-component__onboarding__landing__footer">
				<Text size="sm">
					{TranslationService.get(
						[
							'view.integrations.disclaimer',
							'onboarding.landing.footer',
						],
						// eslint-disable-next-line spellcheck/spell-checker
						'Företagskollen är en kostnadsfri tjänst som underlättar bokslutsarbetet och gör det enklare att planera för framtiden. Anslut ditt bokföringssystem för att få en djupare analys, fler insikter och en skarpare prognos av ditt kassaflöde. Resultatet visualiseras i enkla grafer och en prognos på företagets framtida kassaflöde presenteras. Tjänsten är framtagen tillsammans med vår samarbetspartner Asteria AB.',
					)}
				</Text>
			</div>
		</div>
	);
};

OnboardingLanding.displayName = 'OnboardingLanding';

OnboardingLanding.propTypes = {
	className: PropTypes.string,
};

export default OnboardingLanding;
