import { addYears, eachMonthOfInterval, formatISO, parseISO } from 'date-fns';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import getProbability from './getProbability';

function normalizeValue(options) {
	const { data, total, probability, extra = {} } = options;

	data.probability = getProbability([
		data,
		{ value: total, probability: probability },
	]);

	data.value = data.value + total;
	// data.value = Math.ceil((data.value + total) / 1000) * 1000;

	for (const key in extra) {
		if (extra[key] !== undefined) {
			data[key] = extra[key];
		}
	}

	return data;
}

/**
 * @param { { response: object, extra: object, object?: object } } options
 */
function formatTag(options = {}) {
	const { response, extra = {}, categoryName } = options;

	const object = normalizeValue({
		data: options?.object ?? {
			_id: response._id,
			name: response?.details?.name,
			value: 0,
			probability: 1,
			currency: response?.currency,
			color: response?.details?.color,
			transactions: Object.fromEntries(
				(response?.transactions ?? []).filter(Boolean).map((object) => {
					const links = object?.links ?? [];

					const hasInvoice = links.find(
						(object) => object?.type === 'INVOICE_PAYMENT',
					);

					const hasClient = links.find(
						(object) => object?.type === 'CLIENT',
					);

					if (hasInvoice) {
						object.descriptionClient =
							hasInvoice?.invoiceDetails?.clientId;
					}

					if (hasClient) {
						object.descriptionClient = hasClient.id;
					}

					return [
						object._id,
						{
							...object,
							total: object.total,
							// total: Math.ceil(object.total / 1000) * 1000,
							uuid: object._id,
							$category: [
								categoryName,
								response?.details?.name,
							].join('-'),
							probability: normalizeProbability(
								object.probability,
							),
						},
					];
				}),
			),
		},
		total: response.total,
		probability: response.probability,
		extra: extra,
	});

	// const transactions = response?.transactions ?? [];

	// for (const object of transactions) {
	// }

	return object;
}

/**
 * @param { { response: object, extra: object, object?: object } } options
 */
function formatCategory(options) {
	const { response, extra = {} } = options;

	const tags = response?.tags ?? [];
	const categoryName = response?.details?.name;

	const object = normalizeValue({
		data: options?.object ?? {
			_id: response._id,
			name: response?.details?.name,
			value: 0,
			probability: 1,
			currency: response?.currency,
			color: response?.details?.color,
			tags: {},
		},
		total: response.total,
		probability: response.probability,
		extra: extra,
	});

	for (const response of tags) {
		const tagName = response?.details?.name;

		const data = formatTag({
			response: response,
			object: object.tags[tagName],
			categoryName: categoryName,
			extra: {
				...extra,
				category: {
					_id: object._id,
					name: categoryName,
					value: object.value,
					probability: object.probability,
				},
			},
		});

		object.tags[tagName] = data;
	}

	return object;
}

/**
 * @param { { response: object, extra: object, object?: object } } options
 */
function formatType(options) {
	const { response, extra = {}, colors = {} } = options;

	const categories = response?.categories ?? [];

	const object = normalizeValue({
		data: options?.object ?? {
			value: 0,
			probability: 1,
			currency: response?.currency,
			categories: {},
		},
		total: response.total,
		probability: response.probability,
		extra: extra,
	});

	for (const response of categories) {
		const categoryName = response?.details?.name;

		const data = formatCategory({
			response: response,
			object: object.categories[categoryName],
			extra: extra,
			colors: colors,
		});

		object.categories[categoryName] = data;
	}

	return object;
}

/*
function validateTags(form, { type, store }) {
	const tags = ForecasterStore.selectors.tags.available(store?.getState(), {
		type: type,
	});

	for (const object of tags) {
		const tag = object?.name;
		const category = object?.category?.name;

		for (const date in form?.[type]?.data ?? {}) {
			for (const status in form?.[type]?.data?.[date] ?? {}) {
				const path = [
					type,
					'data',
					date,
					status,
					'categories',
					category,
					'tags',
					tag,
				];

				set(
					form,
					path.concat('value'),
					get(form, path.concat('value')) || 0,
				);

				set(
					form,
					path.concat('probability'),
					get(form, path.concat('probability')) || 1,
				);
			}
		}
	}

	return form;
}
 */

function prefill(form) {
	for (const type of ['deposit', 'withdraw']) {
		if (!form?.[type]) {
			form[type] = { data: {}, tags: {} };
		}

		for (const $date of eachMonthOfInterval({
			start: new Date(),
			end: addYears(new Date(), 1),
		})) {
			const date = formatISO($date, { representation: 'date' });

			if (!form?.[type]?.data?.[date]) {
				form[type].data[date] = {};
			}

			if (!form?.[type]?.data?.[date]?.FORECAST) {
				form[type].data[date].FORECAST = {
					total: 0,
					probability: 1,
					categories: {},
					currency: 'SEK',
				};
			}
		}
	}

	return form;
}

export default function formatResponse(data = {} /* options = {} */) {
	// const store = options?.store;

	const response = data?.entries?.reduce?.((acc, object) => {
		const { status, types = [] } = object;

		const date = formatISO(
			object.date instanceof Date ? object.date : parseISO(object.date),
			{ representation: 'date' },
		);

		for (const response of types) {
			const type = response.type.toLowerCase();

			if (acc[type] === undefined) {
				acc[type] = { tags: {}, data: {} };
			}

			if (acc[type].data[date] === undefined) {
				acc[type].data[date] = {};
			}

			if (acc[type].data[date] === undefined) {
				acc[type].data[date] = {};
			}

			acc[type].data[date][status] = formatType({
				response: response,
				object: acc[type].data[date][status],
			});

			if (status === 'FORECAST') {
				let typeTags = {};

				const categories = Object.fromEntries(
					Object.entries(
						acc[type].data[date][status].categories ?? {},
					).filter(([key]) => key),
				);

				for (const categoryName in categories) {
					const tags = Object.fromEntries(
						Object.entries(
							categories?.[categoryName]?.tags ?? {},
						).filter(([key]) => key),
					);

					for (const tagName in tags) {
						const key = [categoryName, tagName].join('-');

						if (typeTags[key] === undefined) {
							typeTags[key] = {
								...tags[tagName],
								value: 0,
								probability: 1,
							};
						}

						typeTags[key] = normalizeValue({
							data: typeTags[key],
							total: tags?.[tagName]?.value ?? 0,
							probability: tags?.[tagName]?.probability ?? 1,
						});
					}
				}

				if (!acc[type].tags) {
					acc[type].tags = {};
				}

				data?.tags?.[type]?.forEach((tag) => {
					const key = [tag?.tag?.category?.name, tag?.tag?.name].join(
						'-',
					);
					if (acc[type].tags[key]) {
						acc[type].tags[key].weight = tag.weight;
						acc[type].tags[key].alert = tag.alert;
					} else {
						acc[type].tags[key] = { ...typeTags[key] };
						acc[type].tags[key].weight = tag.weight;
						acc[type].tags[key].alert = tag.alert;
					}
				});
				/*
				Object.entries(typeTags).forEach(([key, tag]) => {
					if (acc[type].tags[key]) {
						acc[type].tags[key].value += tag.value;
						acc[type].tags[key].min += tag.value * tag.probability;
						acc[type].tags[key].probability =
							acc[type].tags[key].min / acc[type].tags[key].value;
					} else {
						acc[type].tags[key] = { ...tag };
						acc[type].tags[key].min = tag.value * tag.probability;
						acc[type].tags[key].probability =
							acc[type].tags[key].min / acc[type].tags[key].value;
					}
				});
				*/

				// acc[type].tags = { ...acc[type].tags, ...typeTags };
			}
		}

		return acc;
	}, {});

	// validateTags(response, { type: 'deposit', store: store });
	// validateTags(response, { type: 'withdraw', store: store });

	for (const type in response) {
		const data = response?.[type]?.data ?? {};

		for (const date in data) {
			for (const status in data?.[date] ?? {}) {
				if (data?.[date]?.[status]?.categories) {
					const categories = Object.fromEntries(
						Object.entries(
							data?.[date]?.[status]?.categories ?? {},
						).filter(([key]) => key),
					);

					response[type].data[date][status].categories = categories;

					for (const categoryName in categories) {
						if (categories?.[categoryName]?.tags) {
							const tags = Object.fromEntries(
								Object.entries(
									categories?.[categoryName]?.tags ?? {},
								).filter(([key]) => key),
							);

							response[type].data[date][status].categories[
								categoryName
							].tags = tags;
						}
					}
				}
			}
		}
	}

	prefill(response);

	return response;
}
