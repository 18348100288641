import React from 'react';

import PropTypes from 'prop-types';

import { useFormValues } from '@asteria/component-form';

import * as FormatUtils from '@asteria/utils-funcs/format';
import useConfig from '@asteria/utils-hooks/useConfig';

import { useTagDetails } from '../../../hooks';

import View from './view';

/** @type { React.FC<{ _id: string, type: string, category: string, tag: string }> } */
const ForecastCategoryCleanModalProvider = React.memo(
	function ForecastCategoryCleanModalProvider(props) {
		const { _id, type, category, tag } = props;

		const form = useFormValues();
		const data = useTagDetails({
			_id: _id,
			form: form,
			label: FormatUtils.formatTag({
				type,
				category,
				tag,
			}),
		});

		const hasDeposit = !!data?.statistics?.current?.DEPOSIT?.count;
		const hasWithdraw = !!data?.statistics?.current?.WITHDRAW?.count;

		const content = useConfig(['modals.tag.deselect'], {
			deep: true,
			callback: React.useCallback(
				(config) => {
					if (type === 'deposit' && hasDeposit) {
						return config?.deposit;
					}

					if (type === 'withdraw' && hasWithdraw) {
						return config?.withdraw;
					}

					return config?.empty;
				},
				[hasDeposit, hasWithdraw, type],
			),
		});

		return <View {...props} data={data} content={content} />;
	},
);

ForecastCategoryCleanModalProvider.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	_id: PropTypes.string,
};

export default ForecastCategoryCleanModalProvider;
