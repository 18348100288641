import { normalizeProbability } from '@asteria/component-core/utils/normalize';

/**
 * @param { { value: number, probability: number }[] } data
 */
export default function getProbability(data) {
	const { weight, total } = data.reduce(
		(acc, { value, probability }) => {
			if (value && probability) {
				return {
					...acc,
					weight: acc.weight + value * probability,
					total: acc.total + value,
				};
			} else {
				return acc;
			}
		},

		{ weight: 0, total: 0 },
	);

	if (total === 0) {
		return 1;
	}

	return normalizeProbability(weight / total);
}
