import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { Actions as BaseActions } from '@asteria/component-actionbar';
import {
	Service as FeatureService,
	useFeature,
} from '@asteria/component-tools/featureflag';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { Translation, TranslationService } from '@asteria/language';

import WelcomeContext from '../../context';
import * as WelcomeSelectors from '../../selectors';

import Action from './Action';
import Complete from './Action/Complete';

import './styles.scss';

const ActionsTitle = (props) => {
	const { show } = props;

	const hasBankFeature = useFeature('welcome-actions-action-bank');
	const hasERPFeature = useFeature('welcome-actions-action-erp');

	const isLoading = useSelector(
		(store) =>
			IntegrationStore.selectors.isLoading(store) &&
			(hasBankFeature || hasERPFeature),
		(a, b) => isEqual(a, b),
	);

	if (!show) {
		return null;
	}

	return (
		<div className="asteria-view__welcome__actions-title">
			<Title size="xs">
				{TranslationService.get('welcome.action.integration.title')}
			</Title>
			{isLoading ? (
				<Translation
					translationKey="welcome.action.integration.subtitle"
					Component={Text}
					size="sm"
				/>
			) : null}
		</div>
	);
};

ActionsTitle.displayName = 'ActionsTitle';

ActionsTitle.propTypes = { show: PropTypes.bool };

const Actions = (props) => {
	const { onAction, showTitle = true } = props;

	const store = useStore();
	const dispatch = useDispatch();

	const { actions: ctxActions } = React.useContext(WelcomeContext);

	const actions = useSelector(
		(store) => {
			const completed = WelcomeSelectors.completedActions(store);
			const integrations = IntegrationStore.selectors.integrations(
				store,
				{ connected: true },
			);

			if (
				completed?.['connect:erp'] &&
				!integrations.some(({ type }) => type === 'erp')
			) {
				delete completed['connect:erp'];
			}

			if (
				completed?.['connect:bank'] &&
				!integrations.some(({ type }) => type === 'bank')
			) {
				delete completed['connect:bank'];
			}

			return (ctxActions ?? []).filter(
				(object) =>
					!completed?.[object?.key] &&
					(!object?.feature ||
						FeatureService.isActive(object?.feature)),
			);
		},
		(a, b) => isEqual(a, b),
	);

	const handleAction = React.useCallback(
		(action, data) => {
			function updateActions(type, action) {
				const state = store.getState();
				const settings = state?.app?.[type]?.settings;
				const flags = settings?.flags?.['welcome:actions'] ?? {};

				dispatch(
					action({
						'welcome:actions': { ...flags, [data]: true },
					}),
				);
			}

			if (action === 'welcome:action:hide') {
				updateActions('user', AppStore.setUserSettingsFlags);
				updateActions('company', AppStore.setCompanySettingsFlags);

				onAction?.('refreshUserSettings');
				onAction?.('refreshCompanySettingsFlags');

				return;
			}

			return onAction?.(action, data);
		},
		[dispatch, onAction, store],
	);

	return (
		<BaseActions className="asteria-view__welcome__actions">
			<ActionsTitle show={showTitle} />
			{actions?.length ? (
				actions.map(({ variant, key, props }) => (
					<Action
						key={key}
						name={key}
						variant={variant}
						{...props}
						onAction={handleAction}
					/>
				))
			) : (
				<Complete onAction={handleAction} />
			)}
		</BaseActions>
	);
};

Actions.displayName = 'Actions';

Actions.propTypes = {
	showTitle: PropTypes.bool,
	onAction: PropTypes.func,
};

export default Actions;
