import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import { Input, Label } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';

const LoanRange = React.memo((props) => {
	const {
		name,
		label,
		minLabel,
		maxLabel,
		postfix,
		min,
		max,
		step = 1,
		format,
	} = props;

	const { setValue } = useFormContext();

	const value = useWatch({ name: name });

	useEffect(() => {
		if (value > max) {
			setValue(name, max);
		}
	}, [max, min, name, setValue, value]);

	return (
		<Group flex className={'asteria-component__loan-range'}>
			<Label>{label}</Label>
			<Group direction="horizontal" verticalAlign="top" flex>
				<Input
					name={name}
					type="number"
					min={min}
					max={max}
					step={step}
					size="sm"
					format={format}
					postfix={postfix}
				/>
				<Group direction="vertical" horizontalAlign="center" flex>
					<Input
						name={name}
						size="sm"
						type="range"
						min={min}
						max={max}
						step={step}
					/>
					{minLabel || maxLabel ? (
						<Group
							direction="horizontal"
							verticalAlign="center"
							horizontalAlign="space-between"
							flex
						>
							{minLabel ? (
								<Text size="xs">
									{TranslationService.get(
										minLabel,
										undefined,
										{
											value: min,
										},
									)}
								</Text>
							) : null}
							{maxLabel ? (
								<Text size="xs">
									{TranslationService.get(
										maxLabel,
										undefined,
										{
											value: max,
										},
									)}
								</Text>
							) : null}
						</Group>
					) : null}
				</Group>
			</Group>
		</Group>
	);
});

LoanRange.displayName = 'LoanRange';
LoanRange.propTypes = {
	name: PropTypes.string,

	label: PropTypes.string,
	minLabel: PropTypes.number,
	maxLabel: PropTypes.number,
	postfix: PropTypes.string,

	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	format: PropTypes.object,
};

export default LoanRange;
