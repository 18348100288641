import { useContext } from 'react';

import Context from './context';
import Service from './service';

/**
 * @param { string } key
 * @param { number } [minVersion]
 * @param { number } [maxVersion]
 * @returns { boolean }
 */
const useFeature = (key, minVersion = false, maxVersion = false) => {
	const { features } = useContext(Context);
	const feature = features?.[key];

	if (!feature && !Service.detected[key]) {
		Service.detected[key] = {
			key,
		};
	}

	if (Service.hasFeature(feature, { minVersion, maxVersion })) {
		return true;
	}

	return false;
};

/**
 * @param { string[][] } data
 * @example
 */
export const useBulkFeatures = (data) => {
	const { features } = useContext(Context);

	return data.some((keys) =>
		[].concat(keys).every((key) => {
			const feature = features?.[key];

			if (!feature && !Service.detected[key]) {
				Service.detected[key] = { key: key };
			}

			return Service.hasFeature(feature);
		}),
	);
};

export default useFeature;
