import store from '@asteria/datalayer';
import * as AppStore from '@asteria/datalayer/stores/app';
import * as ForecasterStore from '@asteria/datalayer/stores/forecaster';

export default async ({ type: $type, category, tag, dispatch }) => {
	const state = store.getState();

	const type = $type ?? ForecasterStore.selectors.navigation.type(state);

	// If tag already exists, select it
	dispatch(
		ForecasterStore.tags.select([
			{ type: type, categoryName: category, tagName: tag },
		]),
	);

	const tags = state?.app?.tags ?? [];

	if (
		!tags.some(
			(object) =>
				object?.name === tag && object?.category?.name === category,
		)
	) {
		dispatch(
			AppStore.pushTags({ name: tag, category: { name: category } }),
		);
	}
};
