import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { TranslationService } from '@asteria/language';
import * as FormatUtils from '@asteria/utils-funcs/format';

import '../../../../styles.scss';

const TransactionCreateButton = React.memo((props) => {
	const { type, category, tag, onClick } = props;

	const translationData = React.useMemo(
		() => ({
			category: FormatUtils.formatTag({
				type: type,
				category: category,
				tag: tag,
			})?.toLowerCase(),
		}),
		[category, tag, type],
	);

	return (
		<Button
			icon="add"
			variant="tertiary"
			label={TranslationService.get(
				[
					'forecaster.transactions.add',
					`forecaster.transactions.add.${type}`,
				],
				undefined,
				translationData,
			)}
			onClick={onClick}
		/>
	);
});

TransactionCreateButton.displayName = 'TransactionCreateButton';

TransactionCreateButton.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	onClick: PropTypes.func,
};

export default TransactionCreateButton;
