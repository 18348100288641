import React from 'react';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const Dot = (props) => {
	const { className, animate, tooltip, onClick, onMouseEnter, onMouseLeave } =
		props;

	const TooltipProps = React.useMemo(() => {
		if (
			AsteriaCore.utils.isObject(tooltip) &&
			!React.isValidElement(tooltip)
		) {
			return tooltip;
		}

		return {
			placement: 'top',
			tooltip: tooltip,
		};
	}, [tooltip]);

	return (
		<TooltipWrapper
			{...TooltipProps}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div
				className={cn('asteria-component__graph__dot', className, {
					'asteria-component__graph__dot--animated': animate,
				})}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>
		</TooltipWrapper>
	);
};

Dot.displayName = 'Dot';

Dot.propTypes = {
	className: PropTypes.string,
	animate: PropTypes.bool,
	tooltip: PropTypes.node,

	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};

Dot.defaultProps = {};

export default Dot;
