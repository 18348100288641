import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import Form from '@asteria/component-form';

import { Translation } from '@asteria/language';

import ForecastDetailsSection from './section/details';
import ForecastTotalSection from './section/total';
import Updater from './updater';

/**
 * @typedef { import('../types').ChildrenOptions } ChildrenOptions
 * @typedef { import('../types').Props } Props
 */

/** @type { React.FC<Pick<ChildrenOptions, 'onAction' | 'onSubmit' | 'translationOptions'>> } */
const ForecastExtraFormContent = function ForecastExtraFormContent({
	onAction,
	onSubmit,
	translationOptions,
}) {
	return (
		<>
			<div className="grid grid-cols-2 gap-4">
				<ForecastTotalSection
					type="deposit"
					onAction={onAction}
					onSubmit={onSubmit}
					translationOptions={translationOptions}
				/>
				<ForecastTotalSection
					type="withdraw"
					onAction={onAction}
					onSubmit={onSubmit}
					translationOptions={translationOptions}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<ForecastDetailsSection
					type="deposit"
					onAction={onAction}
					onSubmit={onSubmit}
					translationOptions={translationOptions}
				/>
				<ForecastDetailsSection
					type="withdraw"
					onAction={onAction}
					onSubmit={onSubmit}
					translationOptions={translationOptions}
				/>
			</div>
		</>
	);
};

ForecastExtraFormContent.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	translationOptions: PropTypes.object,
};

const FORM_RESET_OPTIONS = {
	keepDirtyValues: false,
	keepErrors: false,
	keepDirty: false,
	keepValues: false,
	keepDefaultValues: false,
	keepIsSubmitted: false,
	keepIsSubmitSuccessful: false,
	keepTouched: false,
	keepIsValid: false,
	keepSubmitCount: false,
};

/** @type { React.FC<ChildrenOptions> } */
const ForecastExtra = React.memo(function ForecastExtra({
	onAction,
	onSubmit,
	translationOptions,
	query,
	loading,
	startDate,
	endDate,
}) {
	const values = React.useMemo(
		() => (query.isFetching ? {} : { ...query.data?.target, $ready: true }),
		[query.data?.target, query.isFetching],
	);

	return (
		<>
			<Form
				key={[startDate, endDate].join('-')}
				className="flex flex-col gap-8"
				defaultValues={{}}
				values={values}
				resetOptions={FORM_RESET_OPTIONS}
			>
				<Updater
					startDate={startDate}
					endDate={endDate}
					onAction={onAction}
					onSubmit={onSubmit}
				/>
				<ForecastExtraFormContent
					onAction={onAction}
					onSubmit={onSubmit}
					translationOptions={translationOptions}
				/>
			</Form>
			<Translation
				translationKey="card.content.text"
				translationOptions={translationOptions}
				showWhenEmpty={false}
				Component={Text}
				loading={loading}
			/>
		</>
	);
});

ForecastExtra.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	loading: PropTypes.bool,
	query: PropTypes.object,
	translationOptions: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
};

export default ForecastExtra;
