export function useColor(color) {
	const style = {};

	if (color?.value) {
		style['--color'] = color?.value;
	}

	const className = []
		.concat(color?.type)
		.filter(Boolean)
		.map((type) => `asteria-color__${type}`);

	return { style: style, className: className };
}
