import React from 'react';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';

import { SizeProp } from '../PropTypes';
import Icon from '../icon';
import { TooltipWrapper } from '../tooltip';
import { sizeClasses, stateClasses } from '../utils';

import './index.scss';

const Badge = React.memo((props) => {
	const { children, className, tooltip, icon, size, onClick } = props;

	const TooltipProps = React.useMemo(() => {
		if (
			AsteriaCore.utils.isObject(tooltip) &&
			!React.isValidElement(tooltip)
		) {
			return tooltip;
		}

		return { tooltip: tooltip };
	}, [tooltip]);

	if (!children && !icon) {
		return null;
	}

	return (
		<TooltipWrapper {...TooltipProps}>
			<span
				className={cn(
					'asteria-component__badge',
					{ 'asteria-component__badge--clickable': onClick },
					sizeClasses(props),
					stateClasses(props),
					className,
				)}
				onClick={onClick}
			>
				{children}
				{icon ? <Icon size={size} icon={icon} /> : null}
			</span>
		</TooltipWrapper>
	);
});

Badge.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	icon: PropTypes.string,
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	size: SizeProp(),

	tooltip: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string,
		PropTypes.object,
	]),
	onClick: PropTypes.func,
};

Badge.displayName = 'Badge';

Badge.defaultProps = {};

const BadgeWrapper = React.memo((props) => {
	const { children, badge, icon, className, forceOpen, ...badgeProps } =
		props;

	if (badge || icon || forceOpen) {
		return (
			<div className={cn('asteria-component__badge__wrapper', className)}>
				{children}
				{badge || icon ? (
					<Badge {...badgeProps} icon={icon}>
						{badge}
					</Badge>
				) : null}
			</div>
		);
	}

	return children;
});

BadgeWrapper.displayName = 'BadgeWrapper';

BadgeWrapper.propTypes = {
	children: PropTypes.node,
	badge: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
	forceOpen: PropTypes.bool,
	...Badge.propTypes,
};

ComponentService.register('Badge', Badge, {
	className: { type: 'string' },
	children: { type: 'node' },
	icon: { type: 'string' },
	active: { type: 'boolean' },
	disabled: { type: 'boolean' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },

	tooltip: { type: 'node' },
	onClick: { type: 'function' },
});

ComponentService.register('BadgeWrapper', BadgeWrapper, {
	children: { type: 'node' },
	badge: { type: 'number' },
	forceOpen: { type: 'boolean' },
	className: { type: 'string' },
	icon: { type: 'string' },
	active: { type: 'boolean' },
	disabled: { type: 'boolean' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },

	tooltip: { type: 'node' },
	onClick: { type: 'function' },
});

export default Badge;
export { BadgeWrapper };
