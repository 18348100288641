import React from 'react';

import { addYears, formatISO, max, min, subYears } from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { parseDate } from '@asteria/utils-funcs/normalize';

import CustomDateRange from './custom';
import { useDateRange } from './hooks';
import PopupMonths from './months';

import './styles.scss';

/**
 * @typedef { import('./types').Props } Props
 */

/** @type { React.FC<Props> } */
const Popup = React.memo(function Popup(props) {
	const {
		className,

		date: initialDate = new Date().toISOString(),

		onAction,
		onSubmit,
	} = props;

	const [date, setDate] = React.useState(initialDate);

	React.useEffect(() => {
		setDate(initialDate);
	}, [initialDate]);

	const dateRange = useDateRange(date);

	const extra = React.useMemo(
		() => ({
			postfix: { 'date-range': dateRange },
			data: { date },
		}),
		[date, dateRange],
	);

	const back = React.useCallback(() => {
		function change(date) {
			return formatISO(subYears(parseDate(date), 1), {
				representation: 'date',
			});
		}

		setDate((date) => {
			let next = date;

			if (typeof next === 'string') {
				next = change(next);
			}

			if (next?.visible) {
				next.visible = change(next.visible);
			}

			onAction?.('time:selector:back', { date, next });

			return next;
		});
	}, [onAction]);

	const next = React.useCallback(() => {
		function change(date) {
			return formatISO(addYears(parseDate(date), 1), {
				representation: 'date',
			});
		}

		setDate((date) => {
			let next = date;

			if (typeof next === 'string') {
				next = change(next);
			}

			if (next?.visible) {
				next.visible = change(next.visible);
			}

			onAction?.('time:selector:next', { date, next });

			return next;
		});
	}, [onAction]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'time:selector:custom:toggle') {
				setDate((current) =>
					data
						? { visible: current, startDate: null, endDate: null }
						: current?.visible,
				);
			}

			if (action === 'time:selector:custom') {
				const key = data?.key;
				const value = data?.value;

				setDate((current) => {
					const next = { ...current, [key]: value };

					if (next?.startDate && next?.endDate) {
						const startDate = formatISO(
							min([
								parseDate(next?.startDate),
								parseDate(next?.endDate),
							]),
							{ representation: 'date' },
						);

						const endDate = formatISO(
							max([
								parseDate(next?.startDate),
								parseDate(next?.endDate),
							]),
							{ representation: 'date' },
						);

						next.startDate = startDate;
						next.endDate = endDate;
						next.visible = endDate;

						onAction?.('time:selector:select', { date: next });
					}

					return next;
				});
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-4 rounded border border-solid p-4',
				'bg-time-selector-popup-normal-background border-time-selector-popup-normal-border',
				'asteria-component__time-selector-popup',
				className,
			)}
		>
			<div className="flex items-center gap-2 justify-between">
				<Button icon="chevron-left" onClick={back} />
				<Translation
					translationKey="time.selector.popup.label"
					translationOptions={extra}
					Component={Text}
				/>
				<Button icon="chevron-right" onClick={next} />
			</div>
			<PopupMonths
				date={date}
				onAction={handleAction}
				onSubmit={onSubmit}
			/>
			<CustomDateRange
				date={date}
				onAction={handleAction}
				onSubmit={onSubmit}
				extra={extra}
			/>
		</div>
	);
});

Popup.displayName = 'Popup';

Popup.propTypes = {
	className: PropTypes.string,
	date: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Popup;
export { useDateRange };
