import { useContext } from 'react';

import { formatISO } from 'date-fns';
import { get } from 'lodash-es';

import { useManualFormValues } from '@asteria/component-form';

import Context from '../../../logic/context';
import { getPath } from '../../../utils/getFormPaths';

export function useVisibleValue({ type, date, category, tag }) {
	const { history } = useContext(Context);

	const historicalValue =
		get(
			history,
			getPath({
				type,
				date: formatISO(date, { representation: 'date' }),
				status: 'PAID',
				category,
				tag,
				field: 'value',
			}),
		) || 0;

	const [value, $value] = useManualFormValues({
		name: ['value', '$value'].map((field) =>
			getPath({
				type,
				date: formatISO(date, { representation: 'date' }),
				status: 'FORECAST',
				category,
				tag,
				field,
			}),
		),
	});

	if ($value !== undefined) {
		return historicalValue + ($value || 0);
	}

	return historicalValue + (value || 0);
}
