import React, { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useComponentSize from '@asteria/utils-hooks/useComponentSize';

import * as Selectors from './selectors';

import './yaxis.scss';

const YAxisLabelComponent = (props) => {
	const { className, updateSize = () => {}, value, label } = props;

	const ref = useRef(null);
	const { height } = useComponentSize({ ref: ref });

	useEffect(() => {
		updateSize(value, height);
	}, [value, height, updateSize]);

	return (
		<div
			className={cn(className, 'asteria-graph-yaxis-label', {
				'asteria-graph-yaxis-label-zero': value === 0,
			})}
		>
			<Text size="sm" ref={ref}>
				{label}
			</Text>
		</div>
	);
};

YAxisLabelComponent.displayName = 'YAxisLabel';

const YAxis = React.memo(({ parts = [], updateSize, style }) => {
	const steps = useSelector(
		(store) => Selectors.graph.yAxisSteps(store, parts),
		(a, b) => isEqual(a, b),
	);

	return (
		<>
			<div className={cn(`asteria-graph-yaxis-labels`)}>
				{steps.length > 1 &&
				Math.abs(steps[0].value - steps[1].value) > 1000 ? (
					<Text size="xs" className="asteria-prefix-y-axis">
						{TranslationService.get(
							['graph.yaxis.prefix', 'graph.yaxis.prefix.1000'],
							'Tkr',
						)}
					</Text>
				) : (
					<Text size="xs" className="asteria-prefix-y-axis">
						{TranslationService.get('graph.yaxis.prefix', 'Kr')}
					</Text>
				)}
				{steps.map(({ value = 0, label = '0' } = {}) => (
					<YAxisLabelComponent
						key={value}
						updateSize={updateSize}
						label={label}
						value={value}
					/>
				))}
			</div>
			<div className={cn(`asteria-graph-yaxis-lines`)} style={style}>
				{steps.map(({ value = 0 } = {}) => (
					<div
						key={value}
						className={cn('asteria-graph-yaxis-line', {
							'asteria-graph-yaxis-line-zero': value === 0,
						})}
					/>
				))}
			</div>
		</>
	);
});

YAxis.displayName = 'YAxis';
export default YAxis;
export { Text };
