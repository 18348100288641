import React, { useCallback } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Title, { Text, TextGroup } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Input from '@asteria/component-form/input';
import Modal from '@asteria/component-modal';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import { ASTERIA_CUSTOM_SCENARIO } from '../constants';

const EditScenarioModal = (props) => {
	const { open, onClose, onAction, data } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);

	const handleDelete = useCallback(() => {
		Analytics.event('forecaster.scenario.edit.remove', data);

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ScenarioRemove,
				data: { data: data },
			}),
		);
	}, [data, dispatch]);

	const handleReset = useCallback(() => {
		Analytics.event('forecaster.scenario.edit.reset', data);

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ScenarioReset,
				data: { data: data },
			}),
		);
	}, [data, dispatch]);

	const handleClear = useCallback(() => {
		Analytics.event('forecaster.scenario.edit.clear', data);

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.ScenarioClear,
				data: { data: data },
			}),
		);
	}, [data, dispatch]);

	const handleSubmit = useCallback(
		(form) => {
			Analytics.event('forecaster.scenario.edit.confirm', { form: form });

			return onAction?.('scenario:update', form);
		},
		[onAction],
	);

	const handleClose = useCallback(
		(event) => {
			const hasHistory = ModalStore.selectors.hasHistory(
				store.getState(),
			);

			if (hasHistory) {
				return dispatch(ModalStore.close());
			}

			return onClose?.(event);
		},
		[dispatch, onClose, store],
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			size="sm"
			className={cn('asteria--type-scenario', 'asteria--action-update')}
		>
			<Form defaultValues={data} update onSubmit={handleSubmit}>
				<Wrapper>
					<Header onClose={handleClose}>
						{TranslationService.get('forecaster.modal.edit.title')}
					</Header>
					<Content>
						<FormWrapper>
							<Content>
								<TextGroup>
									<Title type="title" size="xs">
										{TranslationService.get(
											'forecaster.modal.edit.body.label',
										)}
									</Title>
									<Text>
										{TranslationService.get(
											'forecaster.modal.edit.body',
											undefined,
											data,
										)}
									</Text>
								</TextGroup>

								{TranslationService.get(
									'forecaster.modal.edit.form.label',
								) ? (
									<Title type="title" size="xs">
										{TranslationService.get(
											'forecaster.modal.edit.form.label',
										)}
									</Title>
								) : null}
								<Input
									type="text"
									name="name"
									label={TranslationService.get(
										'forecaster.modal.edit.label.name',
									)}
									direction="vertical"
									icon={
										data?.name !== ASTERIA_CUSTOM_SCENARIO
											? 'remove'
											: null
									}
									iconPosition="last"
									iconTooltip={TranslationService.get(
										'forecaster.modal.edit.remove.tooltip',
									)}
									onIconClick={handleDelete}
									required
									disabled={
										data?.name === ASTERIA_CUSTOM_SCENARIO
									}
								/>
								<TextGroup>
									<Button
										size="sm"
										variant="link"
										label={TranslationService.get(
											['forecaster.modal.edit.reset'],
											'Reset scenario',
										)}
										tooltip={TranslationService.get([
											'forecaster.modal.edit.reset.tooltip',
										])}
										onClick={handleReset}
										analyticsKey="forecaster.modal.edit.reset"
									/>
									<Button
										size="sm"
										variant="link"
										label={TranslationService.get(
											['forecaster.modal.edit.clear'],
											'Clear scenario',
										)}
										tooltip={TranslationService.get([
											'forecaster.modal.edit.clear.tooltip',
										])}
										onClick={handleClear}
										analyticsKey="forecaster.modal.edit.clear"
									/>
								</TextGroup>
							</Content>
						</FormWrapper>
					</Content>
					<Footer>
						<FooterSection>
							{hasHistory ? (
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.back',
										'forecaster.modal.edit.button.back',
									])}
									onClick={handleClose}
									analyticsKey="forecaster.modal.edit.back"
								/>
							) : (
								<Button
									variant="tertiary"
									label={TranslationService.get([
										'button.cancel',
										'forecaster.modal.edit.button.cancel',
									])}
									onClick={handleClose}
									analyticsKey="forecaster.modal.edit.cancel"
								/>
							)}
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="primary"
								label={TranslationService.get([
									'button.save',
									'forecaster.modal.edit.button.save',
								])}
								type="submit"
								analyticsKey="forecaster.modal.edit.save"
							/>
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>
		</Modal>
	);
};

EditScenarioModal.propTypes = {
	open: PropTypes.bool,
	onAction: PropTypes.func,
	onClose: PropTypes.func,
	data: PropTypes.object,
};

export default EditScenarioModal;
