import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Tour from '@asteria/component-tour';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as TourStore from '@asteria/datalayer/stores/tour';

import useConfig from '@asteria/utils-hooks/useConfig';

import * as UserAPI from '../api/user';

const selectors = {
	userTours: createSelector(
		(store) => store?.app?.user?.settings?.flags?.tours,
		(value) => value ?? [],
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

function useTourSteps() {
	const tourConfig = useConfig('widget.tour');
	const activeTour = useSelector(TourStore.selectors.current);

	return React.useMemo(
		() => tourConfig?.[activeTour] ?? [],
		[activeTour, tourConfig],
	);
}

const TourLogic = (props) => {
	const { onAction } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const activeTour = useSelector(TourStore.selectors.current);
	const zIndex = useSelector(TourStore.selectors.zIndex);

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const handleTourAction = React.useCallback(
		(action, data) => {
			const userTours = selectors.userTours(store.getState());
			const activeTour = TourStore.selectors.current(store.getState());

			if (action === 'tour:end') {
				dispatch(
					AppStore.setUserSettingsFlags({
						tours: AsteriaCore.utils.unique([
							...userTours,
							activeTour,
						]),
					}),
				);

				UserAPI.refreshSettings({
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			return onAction?.(action, data);
		},
		[accessToken, dispatch, onAction, store],
	);

	const handleTourClose = React.useCallback(
		() => handleTourAction?.('tour:end'),
		[handleTourAction],
	);

	const tourSteps = useTourSteps();

	if (!activeTour) {
		return null;
	}

	return (
		<Tour
			isOpen={!!tourSteps?.length}
			onClose={handleTourClose}
			onAction={handleTourAction}
			steps={tourSteps}
			zIndex={zIndex}
			movingTarget
			rootSelector=".wings, .webapp, .asteria-component__wrapper-content"
		/>
	);
};

TourLogic.displayName = 'TourLogic';

TourLogic.propTypes = {
	onAction: PropTypes.func,
};

export default React.memo(TourLogic);
