import React from 'react';

import { formatISO, isPast, isThisMonth } from 'date-fns';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Input } from '@asteria/component-form';

import { cn } from '@asteria/utils-funcs/classes';
import formatNumber from '@asteria/utils-funcs/formatNumber';

import { getPath } from '../../../utils/getFormPaths';

import { useVisibleValue } from './hooks';

import '../styles.scss';

const XAxisInput = React.memo((props) => {
	const { type, date, category, tag, onAction } = props;

	const name = getPath({
		type,
		date: formatISO(date, { representation: 'date' }),
		status: 'FORECAST',
		category,
		tag,
		field: 'value',
	});

	const onChange = React.useCallback(
		({ name, value, prev }) =>
			onAction?.('value:update', { name, value, prev }),
		[onAction],
	);

	const visibleValue = useVisibleValue({ type, date, category, tag });
	const sign = type === 'deposit' ? '+' : '-';

	const inputFormat = React.useMemo(() => ({ valueAsNumber: true }), []);

	if (isPast(date) && !isThisMonth(date)) {
		return (
			<div
				className={cn(
					'asteria-component__forecaster-graph-input',
					'asteria--variant-history',
				)}
			>
				<Text align="center" size="sm">
					{visibleValue ? sign : null}
					{formatNumber(visibleValue, false, false, true)}
				</Text>
			</div>
		);
	}

	return (
		<Input
			className="asteria-component__forecaster-graph-input"
			name={name}
			visibleValue={[
				visibleValue ? sign : '',
				formatNumber(visibleValue, false, false, true),
			].join('')}
			hideVisibleValueOnFocus
			format={inputFormat}
			type="number"
			onChange={onChange}
			debounce={500}
			min={0}
			value={0}
		/>
	);
});

XAxisInput.displayName = 'XAxisInput';
XAxisInput.propTypes = {
	type: PropTypes.string,
	date: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,

	onAction: PropTypes.func,
};

export default XAxisInput;
