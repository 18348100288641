import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { LayoutContext } from '../context';
import * as selectors from '../selectors';

const CashflowStatisticsUpdater = React.memo(() => {
	const dispatch = useDispatch();

	const { onSubmit } = React.useContext(LayoutContext);

	const scenarioId = useSelector(selectors.scenarioId);
	const filters = useSelector(selectors.filters);
	const timesize = useSelector(selectors.timesize);

	React.useEffect(() => {
		onSubmit?.('cashflow:statistics');
	}, [dispatch, filters, onSubmit, scenarioId, timesize]);
});

CashflowStatisticsUpdater.displayName = 'CashflowStatisticsUpdater';

export default CashflowStatisticsUpdater;
