import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';

import {
	addYears,
	eachMonthOfInterval,
	endOfYear,
	format,
	isSameMonth,
	isThisMonth,
	startOfYear,
	subYears,
} from 'date-fns';
import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import { useCurrentDate } from '../../hooks';
import DatePickerContentCell from '../DatePickerContentCell';
import { cleanValue, getLocale, getSelectedCSSClasses } from '../utils';

import './index.scss';

function getCSSClassName(year) {
	return `.asteria-component__datepicker__content__month__header--behavior-scroll--${year}`;
}

function getOffset(element, year) {
	return element?.querySelector(getCSSClassName(year))?.offsetTop;
}

const DEFAULT_LABEL_FMT = 'yyyy';

const DatePickerContentMonthScroll = (props) => {
	const {
		onSelect,
		value: propValue,

		isPastDisabled,
		isFutureDisabled,

		locale,

		startDate,
		endDate,
		disabledDates,
	} = props;

	const currentLocale = getLocale(locale);

	const now = useCurrentDate(propValue);

	const [ref, setRef] = useState(null);
	const [value, setValue] = useState(now);

	const items = useMemo(() => {
		return eachMonthOfInterval({
			start: startOfYear(subYears(value, 4)),
			end: endOfYear(addYears(value, 4)),
		}).reduce((acc, value) => {
			const year = cleanValue(format(value, DEFAULT_LABEL_FMT));

			if (acc[year] === undefined) {
				acc[year] = [];
			}

			acc[year].push(value);

			return acc;
		}, {});
	}, [value]);

	const [currentOffset, previousOffset, nextOffset] = useMemo(() => {
		return [
			getOffset(
				ref,
				cleanValue(
					format(value, DEFAULT_LABEL_FMT, {
						locale: currentLocale,
					}),
				),
			),
			getOffset(
				ref,
				cleanValue(
					format(subYears(value, 1), DEFAULT_LABEL_FMT, {
						locale: currentLocale,
					}),
				),
			),
			getOffset(
				ref,
				cleanValue(
					format(addYears(value, 1), DEFAULT_LABEL_FMT, {
						locale: currentLocale,
					}),
				),
			),
		];
	}, [ref, value]);

	const updateScrollPosition = useCallback(
		(event) => {
			if (event?.target?.scrollTop !== undefined) {
				const {
					target: { scrollTop },
				} = event;

				if (scrollTop < previousOffset) {
					setValue(subYears(value, 1));
				}

				if (scrollTop > nextOffset) {
					setValue(addYears(value, 1));
				}
			}
		},
		[value, currentOffset, previousOffset, nextOffset],
	);

	useEffect(() => {
		ref?.addEventListener('scroll', updateScrollPosition);

		return () => {
			ref?.removeEventListener('scroll', updateScrollPosition);
		};
	}, [ref, value]);

	useLayoutEffect(() => {
		ref?.querySelector(
			getCSSClassName(
				cleanValue(
					format(value, DEFAULT_LABEL_FMT, { locale: currentLocale }),
				),
			),
		)?.scrollIntoView();
	}, [ref]);

	return (
		<div className="asteria-component__datepicker__content__month">
			<div
				className={cn(
					'asteria-component__datepicker__content__month__calendar',
					'asteria-component__datepicker__content__month__calendar--behavior-scroll',
					'asteria-component__datepicker__content__calendar',
				)}
				ref={setRef}
			>
				{Object.entries(items).map(([label, dates]) => (
					<div key={label}>
						<div
							className={cn(
								'asteria-component__datepicker__content__month__header',
								'asteria-component__datepicker__content__month__header--behavior-scroll',
								`asteria-component__datepicker__content__month__header--behavior-scroll--${label}`,
							)}
						>
							<Text>{label}</Text>
						</div>
						<div
							className={cn(
								'asteria-component__datepicker__content__month__calendar__values',
								'asteria-component__datepicker__content__month__calendar__values--behavior-scroll',
							)}
						>
							{dates.map((date) => (
								<DatePickerContentCell
									key={['calendar', 'month', date].join('-')}
									date={date}
									onSelect={onSelect}
									value={propValue}
									isPastDisabled={isPastDisabled}
									isFutureDisabled={isFutureDisabled}
									isThisDate={isThisMonth(date)}
									className={cn(
										'asteria-component__datepicker__content__month__calendar__item',
										getSelectedCSSClasses({
											value: propValue,
											date: date,
											validate: isSameMonth,
										}),
									)}
									format="MMM"
									startDate={startDate}
									endDate={endDate}
									disabledDates={disabledDates}
									locale={locale}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

DatePickerContentMonthScroll.propTypes = {
	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	locale: Constants.DatePickerLocalePropType,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentMonthScroll.defaultProps = {};

export default DatePickerContentMonthScroll;
