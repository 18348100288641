function formatStateValue(state) {
	if (state === 'focus') {
		return 'pending';
	}

	return state;
}

function formatState(data, tags) {
	return ['deposit', 'withdraw']
		.flatMap((type) =>
			Object.entries(data?.[type]?.data ?? {})
				.filter(([, value]) => value?.FORECAST)
				.flatMap(([date, value]) =>
					[]
						.concat({
							date: date,
							type: type.toUpperCase(),
							state: formatStateValue(value?.FORECAST?.$state),
						})
						.concat(
							Object.entries(
								value?.FORECAST?.categories ?? {},
							).flatMap(([category, value]) =>
								Object.entries(value?.tags ?? {})
									.filter(([, value]) => value?.value)
									.flatMap(([tag, value]) => {
										const available = (tags ?? []).find(
											(object) =>
												object?.name === tag &&
												object?.category?.name ===
													category,
										);

										if (!available) {
											return null;
										}

										return {
											date: date,
											type: type.toUpperCase(),
											state: formatStateValue(
												value?.$state,
											),

											categoryId:
												available?.category?._id,
											tagId: available._id,
										};
									})
									.filter(Boolean),
							),
						),
				),
		)
		.filter(({ state }) => state);
}

export default formatState;
