import React from 'react';

import { Route, Routes } from 'react-router-dom';

import PropTypes from 'prop-types';

import { AuthContext } from './context';
import ModalCollection from './modal-collection';
import LoginPage from './pages/login';
import RecoverPage from './pages/recover';
import ResetPage from './pages/reset';
import SignupPage from './pages/signup';
import WelcomePage from './pages/welcome';

import './styles.scss';

const AuthView = (props) => {
	const {
		onAction,
		onSubmit,
		partnerId,
		logo,
		languages = true,
		passwordReset = true,
		signup = true,
		signupCode = true,
		homepage = false,
	} = props;

	const ctx = React.useMemo(
		() => ({
			onAction,
			onSubmit,
			partnerId,

			logo,

			languages,
			signup,
			passwordReset,
			signupCode,
			homepage,

			onLanguageChange: (code) => onAction?.('auth:language', code),
		}),
		[
			homepage,
			languages,
			logo,
			onAction,
			onSubmit,
			partnerId,
			passwordReset,
			signup,
			signupCode,
		],
	);

	return (
		<AuthContext.Provider value={ctx}>
			<ModalCollection onAction={onAction} onSubmit={onSubmit}>
				<div className="asteria-view__auth">
					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/signup" element={<SignupPage />} />
						<Route path="/welcome" element={<WelcomePage />} />
						<Route path="/reset" element={<ResetPage />} />
						<Route path="/recover" element={<RecoverPage />} />
					</Routes>
				</div>
			</ModalCollection>
		</AuthContext.Provider>
	);
};

AuthView.displayName = 'AuthView';

AuthView.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	partnerId: PropTypes.string,
	logo: PropTypes.node,

	languages: PropTypes.bool,
	passwordReset: PropTypes.bool,
	signup: PropTypes.bool,
	signupCode: PropTypes.bool,
	homepage: PropTypes.bool,
};

export default AuthView;

export {
	Auth2FADeactivateModal,
	Auth2FASetupModal,
	Auth2FAVerifyModal,
	Setup2FA,
} from './modals/2fa';

export { default as AuthProfileChangeModal } from './modals/profile-change';
