import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Spinner from '@asteria/component-core/spinner';
import { TooltipContent } from '@asteria/component-core/tooltip';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { ListItem } from '@asteria/component-list';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

import IntegrationActions from './Actions';
import IntegrationMessage from './Message';
import IntegrationPrefix from './Prefix';

import './styles.scss';

const IntegrationErrorTooltip = (props) => {
	const { integration } = props;

	const title = React.useMemo(() => {
		const keys = [
			`integration.error.tooltip.title`,
			`integration.error.${integration?.type}.tooltip.title`,
			`integration.error.${integration?.key}.tooltip.title`,
			`integration.error.${integration?.type}.${integration?.key}.tooltip.title`,
		];

		if (!TranslationService.hasKey(keys)) {
			return null;
		}

		return (
			<Title>
				{TranslationService.get(keys, undefined, {
					integration: integration,
				})}
			</Title>
		);
	}, [integration]);

	const content = React.useMemo(() => {
		const keys = [
			`integration.error.tooltip.content`,
			`integration.error.${integration?.type}.tooltip.content`,
			`integration.error.${integration?.key}.tooltip.content`,
			`integration.error.${integration?.type}.${integration?.key}.tooltip.content`,
		];

		if (!TranslationService.hasKey(keys)) {
			return null;
		}

		return (
			<Text>
				{TranslationService.get(keys, undefined, {
					integration: integration,
				})}
			</Text>
		);
	}, [integration]);

	if (!integration?.config?.errors?.length) {
		return null;
	}

	return (
		<TooltipContent className="asteria-component__onboarding__error-tooltip">
			{title}
			{content}

			{(integration?.config?.errors ?? []).map((error) => {
				const { code, message } = error;

				return (
					<Group
						key={code}
						verticalAlign="top"
						horizontalAlign="center"
						direction="horizontal"
					>
						<Text weight="bold">
							{TranslationService.get(
								[
									`integration.error.code`,
									`integration.error.${code}.code`,
									`integration.error.${integration?.type}.code`,
									`integration.error.${code}.${integration?.type}.code`,
									`integration.error.${integration?.key}.code`,
									`integration.error.${code}.${integration?.key}.code`,
									`integration.error.${integration?.type}.${integration?.key}.code`,
									`integration.error.${code}.${integration?.type}.${integration?.key}.code`,
								],
								code,
								{ error: error },
							)}
						</Text>
						<Text>
							{TranslationService.get(
								[
									`integration.error.message`,
									`integration.error.${code}.message`,
									`integration.error.${integration?.type}.message`,
									`integration.error.${code}.${integration?.type}.message`,
									`integration.error.${integration?.key}.message`,
									`integration.error.${code}.${integration?.key}.message`,
									`integration.error.${integration?.type}.${integration?.key}.message`,
									`integration.error.${code}.${integration?.type}.${integration?.key}.message`,
									message,
								],
								message,
								{ error: error },
							)}
						</Text>
					</Group>
				);
			})}
		</TooltipContent>
	);
};

IntegrationErrorTooltip.displayName = 'IntegrationErrorTooltip';
IntegrationErrorTooltip.propTypes = { integration: PropTypes.object };

const IntegrationErrorButton = (props) => {
	const { integration, size } = props;

	if (!integration?.config?.errors?.length) {
		return null;
	}

	return (
		<Button
			icon="help"
			size={size}
			tooltip={<IntegrationErrorTooltip integration={integration} />}
		/>
	);
};

IntegrationErrorButton.displayName = 'IntegrationErrorButton';
IntegrationErrorButton.propTypes = {
	integration: PropTypes.object,
	size: PropTypes.string,
};

const Integration = (props) => {
	const { id, onAction, onSubmit } = props;

	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, id),
	);

	const [isDelete, setDeleteStatus] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);

	const handleAction = React.useCallback(
		(action, data) => {
			if (
				['integrations:action:delete', 'integrations:delete'].includes(
					action,
				)
			) {
				setDeleteStatus((isDelete) => !isDelete);

				if (action === 'integrations:action:delete') {
					return;
				}
			}

			if (action === 'integrations:delete') {
				setIsDeleting(true);
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const state = integration?.status?.state?.toLowerCase?.();

	const PropsWithState = React.useMemo(
		() => ({ ...integration, state: state }),
		[integration, state],
	);

	return (
		<>
			<ListItem>
				<IntegrationPrefix integration={PropsWithState} />

				<TextGroup className="asteria-component__onboarding-list__integration-description">
					<Text size="sm">
						{TranslationService.get(
							[
								'integrations.list.item',
								`integrations.list.item.${integration?.name}`,
							],
							undefined,
							PropsWithState,
						)}
					</Text>
					{integration?.lastSync ? (
						<Text size="xs">
							{TranslationService.get(
								[
									'integrations.list.item.lastSync',
									`integrations.list.item.${integration?.name}.lastSync`,
								],
								undefined,
								PropsWithState,
							)}
						</Text>
					) : null}
				</TextGroup>
				{state === 'importing' ||
				state === 'initiating' ||
				isDeleting ? (
					<Spinner />
				) : null}

				<IntegrationErrorButton integration={PropsWithState} />

				<IntegrationActions
					integration={PropsWithState}
					onAction={handleAction}
					onSubmit={onSubmit}
					disabled={isDeleting || isDelete}
				/>
			</ListItem>
			{isDelete ? (
				<IntegrationMessage
					integration={PropsWithState}
					onAction={handleAction}
					onSubmit={onSubmit}
				/>
			) : null}
		</>
	);
};

Integration.propTypes = {
	id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Integration;
export { IntegrationErrorTooltip, IntegrationErrorButton };
