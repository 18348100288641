import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import Button from '@asteria/component-core/button';

import * as TourStore from '@asteria/datalayer/stores/tour';

import Analytics from '@asteria/utils-analytics';
import { useTourKey } from '@asteria/utils-hooks/tour';

import { Mask } from './mask';
import { TourTooltip } from './tooltip';
import {
	centerViewportAroundElements,
	getElementDims,
	getIdString,
	getNearestScrollAncestor,
	getTargetInfo,
	getTargetPosition,
	getTooltipPosition,
	getValidPortalRoot,
	scrollToDestination,
	setFocusTrap,
	setNextOnTargetClick,
	setTargetWatcher,
	setTourUpdateListener,
	shouldScroll,
	shouldUpdate,
} from './utils';

import './index.scss';

const tourDefaultProps = {
	maskPadding: 5,
	tooltipSeparation: 10,
	transition: 'top 300ms ease, left 300ms ease',
	disableMaskInteraction: false,
	disableCloseOnClick: false,
	zIndex: 'var(--z-index__tour)',
	renderTolerance: 2,
	updateInterval: 500,
};
const basePortalString = 'asteria-tour-portal';
const baseMaskString = 'asteria-tour-mask';
const baseTooltipContainerString = 'asteria-tooltip-container';

const Tour = (props) => {
	const { steps: initialSteps, initialStepIndex, isOpen, onAction } = props;

	const steps = initialSteps.filter(
		(x) => x?.skipValidation || document.querySelector(x.selector),
	);
	const controlled = isOpen !== undefined;
	const [isOpenState, setIsOpenState] = React.useState(isOpen == undefined);
	const [target, setTarget] = React.useState(undefined);
	const [tooltipPosition, setTooltipPosition] = React.useState(undefined);

	const [currentStepIndex, setCurrentStepIndex] = React.useState(
		initialStepIndex || 0,
	);
	const [tourRoot, setTourRoot] = React.useState(undefined);
	const cleanupRefs = React.useRef([]);
	const tooltip = React.useRef(undefined);
	const portal = React.useRef(undefined);
	const targetPosition = React.useRef(undefined);
	const targetSize = React.useRef(undefined);
	const currentStepContent = steps[currentStepIndex];
	const tourOpen = controlled ? isOpen : isOpenState;
	const options = {
		...tourDefaultProps,
		...props,
		zIndex: props.zIndex ?? tourDefaultProps.zIndex,
		...currentStepContent,
	};
	const {
		selector,
		maskPadding,
		disableMaskInteraction,
		disableCloseOnClick,
		tooltipSeparation,
		transition,
		orientationPreferences,
		customTooltipRenderer,
		zIndex,
		rootSelector,
		rootRef,
		disableClose,
		disableNext,
		disablePrev,
		disableAutoScroll,
		identifier,
		getPositionFromCandidates,
		movingTarget,
		renderTolerance,
		updateInterval,
		disableMask,
		setUpdateListener,
		removeUpdateListener,
		// disableListeners,
		disableSmoothScroll,
		// debug,
		allowForeignTarget,
		nextOnTargetClick,
		validateNextOnTargetClick,
		renderMask,
		onClose,
	} = options;
	React.useEffect(() => {
		return cleanup;
	}, []); // set/reset the tour root

	React.useEffect(() => {
		let root;

		if (!root && rootRef?.current) {
			root = rootRef?.current;
		}

		if (!root && rootSelector) {
			root = document.querySelector(rootSelector);
		}

		if (!root) {
			root = getNearestScrollAncestor(portal.current);
		}

		if (tourOpen !== false && root !== tourRoot) {
			setTourRoot(root);
		}
	}, [rootSelector, tourOpen, rootRef, tourRoot]); // update tour when step changes

	React.useEffect(() => {
		if (tooltip.current && tourOpen) {
			setTimeout(() => {
				tooltip.current.focus();
			}, 500);

			updateTour();
		} else {
			cleanup();
		}
	}, [
		currentStepIndex,
		currentStepContent,
		tourOpen,
		tourRoot,
		tooltip.current,
	]); // update tooltip and target position in state

	const updateTour = () => {
		cleanup();
		const root = tourRoot;
		const tooltipContainer = tooltip.current;

		if (!root || !tooltipContainer) {
			setTarget(null);
			setTooltipPosition(null);
			targetPosition.current = null;
			targetSize.current = null;
			return;
		}

		const targetScope = allowForeignTarget ? document : root;

		const getTarget = () => targetScope.querySelector(selector);

		const currentTarget = getTarget();

		const currentTargetPosition = getTargetPosition(root, currentTarget);
		const currentTargetDims = getElementDims(currentTarget);
		const smartPadding = disableMask ? 0 : maskPadding;
		const tooltipPosition = getTooltipPosition({
			target: currentTarget,
			tooltip: tooltipContainer,
			padding: smartPadding,
			tooltipSeparation,
			orientationPreferences,
			root,
			getPositionFromCandidates,
			disableAutoScroll,
			allowForeignTarget,
			selector,
		});
		setTarget(currentTarget);
		setTooltipPosition(tooltipPosition);
		targetPosition.current = currentTargetPosition;
		targetSize.current = currentTargetDims; //focus trap subroutine

		const cleanupFocusTrap = setFocusTrap(
			tooltipContainer,
			currentTarget,
			disableMaskInteraction,
		);
		cleanupRefs.current.push(cleanupFocusTrap);

		if (
			shouldScroll({
				disableAutoScroll,
				allowForeignTarget,
				selector,
				root,
				target: currentTarget,
				tooltip: tooltipContainer,
				tooltipPosition: tooltipPosition.coords,
			})
		) {
			scrollToDestination(
				root,
				centerViewportAroundElements(
					root,
					tooltipContainer,
					currentTarget,
					tooltipPosition.coords,
					currentTargetPosition,
				),
				disableSmoothScroll,
			);
		}

		// if (!disableListeners) {
		const conditionalUpdate = () => {
			const availableTarget = getTarget();
			const availableSteps = initialSteps.filter((x) =>
				document.querySelector(x.selector),
			);

			if (
				availableSteps.length !== steps.length ||
				shouldUpdate({
					root,
					tooltipPosition: tooltipPosition.coords,
					tooltip: tooltipContainer,
					target: availableTarget,
					disableAutoScroll,
					rerenderTolerance: renderTolerance,
					targetCoords: targetPosition.current,
					targetDims: targetSize.current,
					allowForeignTarget,
					selector,
					getPositionFromCandidates,
					orientationPreferences,
					padding: smartPadding,
					tooltipSeparation,
				})
			) {
				updateTour();
			}
		};

		const cleanupUpdateListener = setTourUpdateListener({
			update: AsteriaCore.utils.throttle(conditionalUpdate, 300),
			customSetListener: setUpdateListener,
			customRemoveListener: removeUpdateListener,
		});
		cleanupRefs.current.push(cleanupUpdateListener); // if the user requests a watcher and there's supposed to be a target

		if (movingTarget && (currentTarget || selector)) {
			const cleanupWatcher = setTargetWatcher(
				conditionalUpdate,
				updateInterval,
			);
			cleanupRefs.current.push(cleanupWatcher);
		}

		if (nextOnTargetClick && currentTarget) {
			const cleanupTargetTether = setNextOnTargetClick(
				currentTarget,
				tourLogic.next,
				validateNextOnTargetClick,
			);
			cleanupRefs.current.push(cleanupTargetTether);
		}
		// }
	};

	const executeAction = (step, type) => {
		if (!step?.actions?.[type]) {
			return true;
		}

		const { click } = step?.actions?.[type] || {};
		if (click && document.querySelector(click)) {
			document.querySelector(click).click();
			return true;
		}

		return true;
	};

	React.useEffect(() => {
		if (currentStepContent) {
			if (!executeAction(currentStepContent, 'enter')) {
				return false;
			}
		}
	}, [currentStepContent]);

	const goToStep = (stepIndex) => {
		if (currentStepContent) {
			if (
				!executeAction(
					currentStepContent,
					stepIndex > currentStepIndex ? 'next' : 'back',
				)
			) {
				return false;
			}
		}

		if (stepIndex >= steps.length || stepIndex < 0) {
			return;
		}

		setCurrentStepIndex(stepIndex);
	};

	const cleanup = () => {
		cleanupRefs.current.forEach((f) => f());
		cleanupRefs.current = [];
	};

	const closeTour = (reset) => {
		reset && goToStep(0);
		!controlled && setIsOpenState(false);
		cleanup();
		target && target.focus(); // return focus to last target when closed
	};

	const baseLogic = {
		next: () => goToStep(currentStepIndex + 1),
		prev: () => goToStep(currentStepIndex - 1),
		close: (reset) => closeTour(reset),
		goToStep: goToStep,
		stepContent: { ...options },
		//pass options in as well to expose any defaults that aren't specified
		stepIndex: currentStepIndex,
		allSteps: steps,
		tooltipPosition,
		onAction: onAction,
	};
	const tourLogic = {
		...baseLogic,
		...(onClose && {
			close: () => onClose(baseLogic),
		}),
	};

	const keyPressHandler = (event) => {
		switch (event.key) {
			case 'Escape':
				event.preventDefault();

				Analytics.event('tour.legend.keydown', { key: 'Escape' });

				if (!disableClose) {
					tourLogic.close();
				}

				break;

			case 'ArrowRight':
				event.preventDefault();

				Analytics.event('tour.legend.keydown', { key: 'ArrowRight' });

				if (!disableNext) {
					tourLogic.next();
				}

				break;

			case 'ArrowLeft':
				event.preventDefault();

				Analytics.event('tour.legend.keydown', { key: 'ArrowLeft' });

				if (!disablePrev) {
					tourLogic.prev();
				}

				break;
		}
	}; //don't render if the tour is hidden or if there's no step data

	const [{ diffX, diffY }, setRootDiff] = React.useState({
		diffX: tourRoot?.scrollLeft ?? 0,
		diffY: tourRoot?.scrollTop ?? 0,
	});

	React.useLayoutEffect(() => {
		function callback(event) {
			const diffY = event?.target?.scrollTop ?? 0;
			const diffX = event?.target?.scrollLeft ?? 0;

			setRootDiff({ diffX, diffY });
		}

		if (tourRoot) {
			setRootDiff({
				diffX: tourRoot?.scrollLeft ?? 0,
				diffY: tourRoot?.scrollTop ?? 0,
			});

			tourRoot.addEventListener('scroll', callback);

			return () => {
				tourRoot.removeEventListener('scroll', callback);
			};
		}
	}, [tourRoot]);

	if (!tourOpen || !currentStepContent) {
		return null;
	}

	const portalStyle = {
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: zIndex,
		visibility: tooltipPosition ? 'visible' : 'hidden',
		pointerEvents: 'none',
		maxHeight: '100vh',
		overflow: 'hidden',
	};

	const tooltipContainerStyle = {
		position: 'absolute',
		top: (tooltipPosition?.coords?.y ?? 0) - diffY,
		left: (tooltipPosition?.coords?.x ?? 0) - diffX,
		transition: transition,
		pointerEvents: 'auto',
	};

	const MaskTag = renderMask ? renderMask : Mask; // render mask, tooltip, and their shared "portal" container

	const render = () => (
		<div
			ref={(ref) => (portal.current = ref)}
			id={getIdString(basePortalString, identifier)}
			style={portalStyle}
		>
			{tourRoot && (
				<>
					{!disableMask && (
						<MaskTag
							maskId={getIdString(baseMaskString, identifier)}
							targetInfo={getTargetInfo(tourRoot, target)}
							disableMaskInteraction={disableMaskInteraction}
							disableCloseOnClick={disableCloseOnClick}
							padding={maskPadding}
							close={tourLogic.close}
							diffX={diffX}
							diffY={diffY}
						/>
					)}

					<div
						ref={(ref) => (tooltip.current = ref)}
						id={getIdString(baseTooltipContainerString, identifier)}
						style={tooltipContainerStyle}
						onKeyDown={keyPressHandler}
						tabIndex={0}
					>
						{customTooltipRenderer ? (
							customTooltipRenderer(tourLogic)
						) : (
							<TourTooltip {...tourLogic} />
						)}
					</div>
				</>
			)}
		</div>
	);
	// after first render (once we've determined the tour root) spawn a portal there for rendering.

	if (tourRoot) {
		return ReactDOM.createPortal(render(), getValidPortalRoot(tourRoot));
	} else {
		return render();
	}
};

Tour.displayName = 'Tour';

Tour.propTypes = {
	steps: PropTypes.array.isRequired,
	initialStepIndex: PropTypes.number,
	zIndex: PropTypes.number,
	rootSelector: PropTypes.string,
	identifier: PropTypes.string,
	setUpdateListener: PropTypes.func,
	removeUpdateListener: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

const TourButton = (props) => {
	const { tourKey, zIndex } = props;

	const dispatch = useDispatch();

	const key = useTourKey(tourKey);

	const onClick = React.useCallback(() => {
		dispatch(TourStore.show({ type: key, zIndex: zIndex }));
	}, [dispatch, key, zIndex]);

	if (!key) {
		return null;
	}

	return <Button icon="help" onClick={onClick} />;
};

TourButton.displayName = 'TourButton';
TourButton.propTypes = {
	tourKey: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	zIndex: PropTypes.number,
};

export default Tour;
export { TourButton };
