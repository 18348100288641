import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import Tooltip from '@asteria/component-core/tooltip';
import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import TransactionCreateButton from './create-button';

import '../../../styles.scss';

const TransactionGroupHeaderView = React.memo((props) => {
	const {
		types,
		type,
		category,
		tag,
		date,
		total,
		handleCreateDeposit,
		handleCreateWithdraw,
	} = props;

	return (
		<div
			className={cn(
				'asteria-component__forecaster-transactions-list-header',
			)}
		>
			<div
				className={cn(
					'asteria-component__forecaster-transactions-list-item',
				)}
			>
				<div
					className={cn(
						'asteria-component__forecaster-transactions-list-cell',
						'asteria-component__forecaster-transactions-list-cell-title',
					)}
				>
					<Text weight="medium" size="sm">
						{TranslationService.get(
							[
								'forecaster.transactions.list.header',
								`forecaster.transactions.${type}.list.header`,
							],
							'{{ date | date:"MMMM, yyyy" | cap }}',
							{ date: date },
						)}
					</Text>
				</div>
				<div
					className={cn(
						'asteria-component__forecaster-transactions-list-cell',
						'asteria-component__forecaster-transactions-list-cell-actions',
					)}
				>
					<Group
						direction="horizontal"
						horizontalAlign="right"
						verticalAlign="center"
					>
						{types.includes('deposit') ? (
							<TransactionCreateButton
								type={'deposit'}
								category={category}
								tag={tag}
								onClick={handleCreateDeposit}
							/>
						) : null}
						{types.includes('withdraw') ? (
							<TransactionCreateButton
								type={'withdraw'}
								category={category}
								tag={tag}
								onClick={handleCreateWithdraw}
							/>
						) : null}
					</Group>
				</div>
				<div
					className={cn(
						'asteria-component__forecaster-transactions-list-cell',
						'asteria--type-total',
					)}
				>
					<Tooltip
						variant="alt"
						size="sm"
						label={
							Math.abs(total) > 999_999_999
								? TranslationService.get(
										[
											'forecaster.transactions.list.total',
											'forecaster.transactions.list.total.tooltip',
											`forecaster.transactions.${type}.list.total`,
											`forecaster.transactions.${type}.list.total.tooltip`,
										],
										'{{ total | number }}',
										{ total: total },
								  )
								: null
						}
					>
						<Text weight="medium" size="sm">
							{TranslationService.get(
								[
									'forecaster.transactions.list.total',
									`forecaster.transactions.${type}.list.total`,
								],
								'{{ total | number }}',
								{ total: total },
							)}
						</Text>
					</Tooltip>
				</div>
			</div>
		</div>
	);
});

TransactionGroupHeaderView.displayName = 'TransactionGroupHeaderView';

TransactionGroupHeaderView.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	types: PropTypes.arrayOf(PropTypes.string),
	date: PropTypes.string,
	total: PropTypes.number,

	handleCreateDeposit: PropTypes.func,
	handleCreateWithdraw: PropTypes.func,
};

export default TransactionGroupHeaderView;
