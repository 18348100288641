import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const CurrencyCell = React.memo((props) => {
	const { className, data, onAction } = props;

	const hasClickFeature = useFeature(
		'cashflow-transactions-chip-currency-click',
	);

	const handleClick = React.useCallback(() => {
		Analytics.event('transaction.chip.currency', {
			currency: data,
		});

		return onAction?.('transactions:filter', {
			type: 'currency',
			id: data,
			item: {
				id: data,
				code: data,
				currency: data,
			},
		});
	}, [data, onAction]);

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<TooltipWrapper
				tooltip={
					hasClickFeature
						? TranslationService.get([
								`list.transactions.transaction.chip.tooltip`,
								`list.transactions.transaction.currency.tooltip`,
								`list.transactions.transaction.currency.${data}.tooltip`,
						  ])
						: null
				}
				variant="alt"
			>
				<Chip
					flag={data}
					label={data}
					size="sm"
					onClick={hasClickFeature ? handleClick : undefined}
				/>
			</TooltipWrapper>
		</div>
	);
});

CurrencyCell.displayName = 'CurrencyCell';

CurrencyCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.string,
	onAction: PropTypes.func,
};

export default CurrencyCell;
