import React from 'react';

import { connect } from 'react-redux';

import { isEqual } from 'lodash-es';
import styled from 'styled-components';

import { Text } from '@asteria/component-core/typography';

import CorePrefix from '@asteria/component-prefix';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import Legends from '../components/legends';

const mapTagOrCategory = (
	{
		tag: { _id: tagId, name: tagName } = {},
		category: { _id: categoryId, name: categoryName } = {},
	} = {},
	prefix = '.',
) => {
	let key = tagName || categoryName;

	if (
		tagName === '$deposit' ||
		tagName === '$withdraw' ||
		tagName === '$forecast'
	) {
		if (categoryId === 'default') {
			return `default${prefix}${key.replace('$', '')}`;
		}

		return `default${prefix}${key.replace('$', '')}`;
	}

	if (categoryId) {
		key = categoryName.startsWith('$')
			? categoryName.replace('$', '')
			: categoryId;

		if (tagId) {
			key += `${prefix}${
				tagName.startsWith('$') ? tagName.replace('$', '') : tagId
			}`;
		}
	} else if (tagId) {
		key = `${tagName.startsWith('$') ? tagName.replace('$', '') : tagId}`;
	}

	return key;
};

/*
const Prefix = styled.span`
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	margin-right: 5px;
	background-color: ${({ theme, part: { name, color } = {} }) =>
		processColor(color || name, 'color', theme)};
	background-image: ${({ theme, part: { name, color } = {} }) =>
		processColor(color || name, 'image', theme)};
`;
Prefix.displayName = 'Prefix';
*/

const Prefix = (props) => {
	const { part } = props;
	return <CorePrefix colors={part?.name?.replace('$', '')} />;
};

const Part = styled(
	({ className, active, onClick = () => {}, part, children }) => (
		<div
			className={cn(className, {
				'asteria-state-active': active,
			})}
			onClick={() => onClick(part)}
			onKeyPress={() => onClick(part)}
			role="button"
			tabIndex="-1"
		>
			<Prefix part={part} />
			{children}
		</div>
	),
)`
	display: flex;
	margin-right: 5px;
`;
Part.displayName = 'Part';

const order = ['$taxes', '$salaries', '$transaction', '$invoices'];
const sort = ({ name: idA } = {}, { name: idB } = {}) => {
	const posA = order.indexOf(idA);
	const posB = order.indexOf(idB);

	return posA - posB;
};

const Categories = styled(
	({
		className,
		layout,
		categories = [],
		active = [],
		onClick = () => {},
	}) => (
		<div
			className={cn(className, 'asteria-graph-controls-menu-categories')}
		>
			<div>
				{categories.sort(sort).map((category) => {
					const label = TranslationService.get(
						[
							`graph.controllers.menu.category.${mapTagOrCategory(
								{
									category,
								},
							)}`,
							`graph.${layout}.controllers.menu.category.${mapTagOrCategory(
								{
									category,
								},
							)}`,
						],
						category.name,
					);

					if (label.startsWith('$')) {
						return null;
					}

					return (
						<Part
							key={category._id}
							part={category}
							active={active.includes(category)}
							onClick={onClick}
							className={`asteria-graph-controls-menu-category-${mapTagOrCategory(
								{
									category,
								},
								'-',
							)}`}
						>
							<Text size="label">{label}</Text>
						</Part>
					);
				})}
			</div>
		</div>
	),
)``;
Categories.displayName = 'Categories';

const Tags = styled(
	({
		className,
		tags = [],
		category,
		includeLegends,
		layout,
		filters,
		onClick = () => {},
	}) => {
		// const { dispatch } = useContext(DatalayerContext);

		const hasCredit = true;

		/*
		const [timesliceSize] = useStore(
			'store-appstate',
			({ timesliceSize: size }) => size,
		);

		const [hasCredit] = useStore(
			'store-graph',
			({ hasCredit: bool = false }) => bool,
		); */

		const legends =
			filters.filter(({ type }) => type !== 'type').length > 0
				? []
				: [
						{
							key: 'history',
							label: TranslationService.get(
								'graph.account.stacked.legend.history',
							),
						},
						{
							key: 'forecast',
							label: TranslationService.get(
								'graph.account.stacked.legend.forecast',
							),
						},
				  ];

		if (hasCredit) {
			legends.push({
				key: 'credit',
				label: TranslationService.get([
					'graph.account.legend.credit',
					'graph.account.stacked.legend.credit',
				]),
			});
		}

		legends.push({
			key: 'risk',
			label: TranslationService.get([
				'graph.account.legend.risk',
				'graph.account.stacked.legend.risk',
			]),
		});

		if (legends.length > 0) {
			legends.push({
				key: 'spread',
				label: TranslationService.get('graph.account.legend.spread'),
			});
		}

		return (
			<div className={cn(className, 'asteria-graph-controls-menu-tags')}>
				<div>
					{includeLegends ? (
						<Legends
							className="asteria-graph-line-graph-component"
							legends={legends}
						/>
					) : null}
					{tags.map((tag) => {
						const label = TranslationService.get(
							[
								`graph.controllers.menu.category.${mapTagOrCategory(
									{
										category,
										tag,
									},
								)}`,
								`graph.${layout}.controllers.menu.category.${mapTagOrCategory(
									{
										category,
										tag,
									},
								)}`,
							],
							tag.name,
						);

						// if (label.startsWith('$')) {
						// 	return null;
						// }

						return (
							<Part
								key={tag._id}
								part={tag}
								active={tags.includes(tag)}
								onClick={onClick}
								className={`asteria-graph-controls-menu-tag-${mapTagOrCategory(
									{
										tag,
									},
									'-',
								)}`}
							>
								<Text size="label">{label}</Text>
							</Part>
						);
					})}
				</div>
			</div>
		);
	},
)`
	${({ hasFilter }) =>
		hasFilter
			? `
		--system-deposit-color: rgb(214,214,214);
		--system-withdraw-color: rgb(183,183,183);
	`
			: ''};
`;
Tags.displayName = 'Tags';
//TODO: Connect has**** to backend
const Menu = React.memo(styled(
	({ className, layout, filters, hasUnpaid, hasOverdue, hasForecast }) => {
		const typeFilters = filters.filter(({ type }) => type === 'type');
		const statusFilters = filters.filter(({ type }) => type === 'status');
		const legends = [];

		if (
			filters.filter(({ type }) => type !== 'type' && type !== 'currency')
				.length === 0
		) {
			if (
				typeFilters.length === 0 ||
				typeFilters.find(({ item: { type } }) => type === 'DEPOSIT')
			) {
				legends.push({
					_id: '$deposit',
					name: '$deposit',
				});
			}

			if (
				typeFilters.length === 0 ||
				typeFilters.find(({ item: { type } }) => type === 'WITHDRAW')
			) {
				legends.push({
					_id: '$withdraw',
					name: '$withdraw',
				});
			}
		}

		if (
			(hasForecast && statusFilters.length === 0) ||
			statusFilters.find(({ item: { status } }) => status === 'FORECAST')
		) {
			legends.push({
				_id: '$forecast',
				name: '$forecast',
			});
		}

		if (
			(hasUnpaid && statusFilters.length === 0) ||
			statusFilters.find(({ item: { status } }) => status === 'UNPAID')
		) {
			legends.push({
				_id: '$unpaid',
				name: '$unpaid',
			});
		}

		if (
			(hasOverdue && statusFilters.length === 0) ||
			statusFilters.find(({ item: { status } }) => status === 'OVERDUE')
		) {
			legends.push({
				_id: '$overdue',
				name: '$overdue',
			});
		}

		return (
			<div className={cn(className, 'asteria-graph-controller-menu')}>
				<Tags
					includeLegends={layout === 'stacked'}
					hasOverdue={hasOverdue}
					hasUnpaid={hasUnpaid}
					layout={layout}
					filters={filters}
					hasFilter={
						filters.filter(({ type }) => type === 'tag').length > 0
					}
					tags={legends}
				/>
			</div>
		);
	},
)`
	position: relative;
`);
Menu.displayName = 'Menu';

const MenuWithData = connect(
	(state) => {
		return {
			filters: state?.app?.filters || [],
			hasUnpaid: state?.graph?.hasUnpaid,
			hasOverdue: state?.graph?.hasOverdue,
			hasForecast: state?.graph?.hasForecast,
		};
	},
	null,
	null,
	{
		areStatesEqual: (next, prev) => isEqual(prev, next),
		areOwnPropsEqual: (next, prev) => isEqual(prev, next),
		areStatePropsEqual: (next, prev) => isEqual(prev, next),
		areMergedPropsEqual: (next, prev) => isEqual(prev, next),
	},
)(Menu);

export default MenuWithData;
