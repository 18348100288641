import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useMutation } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { useFeature } from '@asteria/component-tools/featureflag';

import ActionDialog from './action-dialog';
import ActionSplash from './action-splash';

const selectors = {
	activeModal: createSelector(
		(state) => state?.notifications?.modals?.[0],
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
	activeDialog: createSelector(
		(state) => state?.notifications?.dialogs?.[0],
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Popups = (props) => {
	const { onAction, onSubmit } = props;

	const enabled = useFeature('action-dialogs');

	const modal = useSelector(selectors.activeModal, (a, b) => isEqual(a, b));
	const dialog = useSelector(selectors.activeDialog, (a, b) => isEqual(a, b));

	const id = modal?.id ?? dialog?.id;

	const close = useMutation({
		mutationFn: async () => onAction?.('markNotificationAsViewed', id),
	});

	if (!enabled) {
		return null;
	}

	if (modal) {
		return (
			<ActionSplash
				onAction={onAction}
				onSubmit={onSubmit}
				onClose={close.mutate}
				type={modal?.title}
				data={modal?.data}
			/>
		);
	}

	if (dialog) {
		return (
			<ActionDialog
				onAction={onAction}
				onSubmit={onSubmit}
				onClose={close.mutate}
				type={dialog?.title}
				data={dialog?.data}
			/>
		);
	}

	return null;
};

Popups.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Popups;
