import React from 'react';

import {
	RouterProvider,
	createBrowserRouter,
	createHashRouter,
	createMemoryRouter,
	createRoutesFromElements,
} from 'react-router-dom';

import PropTypes from 'prop-types';

const RoutingWrapper = React.memo((props) => {
	const { type = 'memory', routes } = props;

	let create;

	switch (type) {
		case 'browser':
			create = createBrowserRouter;
			break;

		case 'hash':
			create = createHashRouter;
			break;

		default:
			create = createMemoryRouter;
			break;
	}

	const router = create(createRoutesFromElements(routes));

	return <RouterProvider router={router} />;
});

RoutingWrapper.displayName = 'RoutingWrapper';
RoutingWrapper.propTypes = {
	routes: PropTypes.node,
	type: PropTypes.oneOf(['memory', 'browser', 'hash']),
};

export default RoutingWrapper;
