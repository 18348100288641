import React from 'react';

import useConfig from '@asteria/utils-hooks/useConfig';

export const useFlow = (type, key) => {
	const availableIntegrations = useConfig(`integrations`);

	return React.useMemo(
		() =>
			(availableIntegrations ?? []).find(
				(object) => object?.type === type && object?.key === key,
			),
		[availableIntegrations, key, type],
	);
};
