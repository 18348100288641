import { validateNavigation } from '../utils';

const refresh = (state, action) => {
	if (!action?.payload?.length) {
		return state;
	}

	const tags = action.payload.reduce((acc, object) => {
		const categoryName = object?.category?.name;
		const tagName = object?.name;

		if (acc[categoryName] === undefined) {
			acc[categoryName] = {};
		}

		acc[categoryName][tagName] = object;

		return acc;
	}, {});

	if (!state.tags) {
		state.tags = [];
	}

	state.tags = state.tags.filter(
		({ categoryName, tagName }) =>
			categoryName === '$type' ||
			categoryName === '$forecaster' ||
			tags?.[categoryName]?.[tagName],
	);

	validateNavigation(state);
};

const select = (state, action) => {
	const payload = [].concat(action?.payload);

	for (const { type, categoryName, tagName } of payload) {
		if (
			!state.tags.some(
				(object) =>
					object?.type === type &&
					object.categoryName === categoryName &&
					object.tagName === tagName,
			)
		) {
			state.tags.push({
				type: type,
				categoryName: categoryName,
				tagName: tagName,
			});
		}
	}

	if (!Array.isArray(action.payload)) {
		state.navigation.type = action?.payload?.type;
		state.navigation.category = action?.payload?.categoryName;
		state.navigation.tag = action?.payload?.tagName;
	}
};

const deselect = (state, action) => {
	const payload = [].concat(action?.payload);

	for (const { type, categoryName, tagName } of payload) {
		state.tags = state.tags.filter(
			(object) =>
				!(
					object?.type === type &&
					object.categoryName === categoryName &&
					object.tagName === tagName
				),
		);
	}

	validateNavigation(state);
};

const clearState = (state, action) => {
	const tags = state?.tags ?? [];

	for (let index = 0; index < tags.length; index += 1) {
		if (state?.tags?.[index]?.type === action?.payload) {
			state.tags[index].isRemoved = false;
			state.tags[index].isCompleted = false;
			state.tags[index].isSkipped = false;
		}
	}
};

function findTagIndex(state, { type, category, tag }) {
	return (
		state.tags?.findIndex?.(
			(object) =>
				object?.type === type &&
				object?.categoryName === category &&
				object?.tagName === tag,
		) ?? -1
	);
}

const toggleComplete = (state, action) => {
	const type = action?.payload?.type;
	const categoryName = action?.payload?.categoryName;
	const tagName = action?.payload?.tagName;

	const index = findTagIndex(state, {
		type: type,
		category: categoryName,
		tag: tagName,
	});

	if (index !== -1) {
		state.tags[index].isRemoved = false;
		state.tags[index].isSkipped = false;
		state.tags[index].isCompleted = !state.tags[index].isCompleted;
	}
};

const toggleRemove = (state, action) => {
	const type = action?.payload?.type;
	const categoryName = action?.payload?.categoryName;
	const tagName = action?.payload?.tagName;

	const index = findTagIndex(state, {
		type: type,
		category: categoryName,
		tag: tagName,
	});

	if (index !== -1) {
		state.tags[index].isCompleted = false;
		state.tags[index].isSkipped = false;
		state.tags[index].isRemoved = !state.tags[index].isRemoved;
	}
};

const toggleSkipped = (state, action) => {
	const type = action?.payload?.type;
	const categoryName = action?.payload?.categoryName;
	const tagName = action?.payload?.tagName;

	const index = findTagIndex(state, {
		type: type,
		category: categoryName,
		tag: tagName,
	});

	if (index !== -1) {
		state.tags[index].isCompleted = false;
		state.tags[index].isRemoved = false;
		state.tags[index].isSkipped = !state.tags[index].isSkipped;
	}
};

const markSkipped = (state, action) => {
	const type = action?.payload?.type;
	const categoryName = action?.payload?.categoryName;
	const tagName = action?.payload?.tagName;
	const value = action?.payload?.value ?? true;

	const index = findTagIndex(state, {
		type: type,
		category: categoryName,
		tag: tagName,
	});

	if (index !== -1) {
		state.tags[index].isSkipped = value;
	}
};

export {
	markSkipped as markSkipped,
	clearState as tagsClearState,
	deselect as tagsDeselect,
	refresh as tagsRefresh,
	select as tagsSelect,
	toggleComplete as tagsToggleComplete,
	toggleRemove as tagsToggleRemove,
	toggleSkipped as tagsToggleSkipped,
};
