import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';
import Group from '@asteria/component-core/group';

import Label from '@asteria/component-form/base/label';
import { useFeature } from '@asteria/component-tools/featureflag';

import { setUserSettingsFlags } from '@asteria/datalayer/stores/app';
import { MODAL_WINDOWS } from '@asteria/datalayer/stores/modals';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';

import './index.scss';

const ScenarioOption = (props) => {
	const { postfix, active, onClick, className, children, value } = props;

	const analytics = React.useMemo(() => ({ value: value }), [value]);

	return (
		<DropdownItem
			postfix={postfix}
			active={active}
			onClick={onClick}
			className={className}
			analyticsKey="settings.scenario.select.item"
			analytics={analytics}
		>
			{children}
		</DropdownItem>
	);
};

ScenarioOption.displayName = 'ScenarioOption';

ScenarioOption.propTypes = {
	postfix: PropTypes.node,
	active: PropTypes.bool,
	onClick: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.node,
	value: PropTypes.string,
};

const ScenarioSelect = (props) => {
	const { scenarioId, onChange } = props;

	const scenarios = useSelector((store) =>
		ScenarioStore.selectors.scenarios(store, { type: 'FORECAST' }),
	);

	const selected = useSelector((store) =>
		ScenarioStore.selectors.scenario(store, scenarioId),
	);

	const toggleOptions = useMemo(
		() => ({
			label: selected?.name
				? TranslationService.get(
						[
							selected?.name,
							`scenario.${selected?.name}`,
							`scenario.select.${selected?.name}`,
						],
						selected?.name,
						selected,
				  )
				: TranslationService.get(['settings.scenario.default']),
			icon: 'triangle-down',
			iconPosition: 'last',
		}),
		[selected],
	);

	const options = [
		// {
		// 	children: TranslationService.get('settings.scenario.default'),
		// 	value: null,
		// 	iconActive: 'check',
		// 	active: !selected,
		// 	postfix: !selected ? (
		// 		<Group verticalAlign="center" horizontalAlign="center">
		// 			<Button icon="check" />
		// 		</Group>
		// 	) : null,
		// 	onClick: () => onChange('$select', { _id: null }),
		// },
	].concat(
		scenarios.map((object) => {
			const active = selected?._id === object?._id;

			return {
				children: TranslationService.get(
					[
						object?.name,
						`scenario.${object?.name}`,
						`scenario.select.${object?.name}`,
					],
					object?.name,
					object,
				),
				value: object?._id,
				active: active,
				postfix: (
					<Group horizontalAlign="center" verticalAlign="center">
						{object?.name !== '$base' ? (
							<Button
								icon="edit"
								variant="scenario-edit"
								onClick={() => onChange('$update', object)}
								className="asteria-component__scenario__edit"
							/>
						) : null}

						{active ? (
							<Button
								icon="check"
								size="sm"
								variant="scenario-edit"
								onClick={() => onChange('$update', object)}
								className="asteria-component__scenario__check"
							/>
						) : null}
					</Group>
				),
				onClick: () => onChange('$select', object),
			};
		}),
		{
			children: (
				<Button
					label={TranslationService.get([
						'settings.scenario.create',
						'settings.forecast.scenario.create',
					])}
					icon="add"
					iconPosition="first"
					variant="scenario-add"
				/>
			),
			onClick: () => onChange('$create'),
			value: '$create',
			className: 'asteria-component__scenario__add',
		},
	);

	return (
		<Dropdown
			variant="select"
			placement="bottom"
			toggle={toggleOptions}
			className="asteria-component__scenario"
			title={TranslationService.get([
				'scenario.select.title',
				'scenario.select.dropdown.title',
			])}
			analyticsKey="settings.scenario.select"
		>
			{options.map((option) => {
				const { children, value } = option;

				return (
					<ScenarioOption key={`scenario-${value}`} {...option}>
						{children}
					</ScenarioOption>
				);
			})}
		</Dropdown>
	);
};

ScenarioSelect.propTypes = {
	onChange: PropTypes.func,
	scenarioId: PropTypes.string,
};

const ScenarioSection = (props) => {
	const { onAction, prefix = 'settings' } = props;
	const [scenarioId, setScenario] = useState(null);

	const dispatch = useDispatch();
	const { setValue } = useFormContext();

	const activeScenarioId = useSelector(ScenarioStore.selectors.scenarioId);

	const hasScenarioFeature = useFeature('forecaster-scenarios');

	useEffect(() => {
		setScenario(activeScenarioId);
	}, [activeScenarioId]);

	const handleChange = useCallback(
		(action, data) => {
			if (action === '$create') {
				Analytics.event('forecaster.scenario.settings.new', {});

				onAction?.('scenario:open', {
					page: MODAL_WINDOWS.ScenarioCreate,
				});
			}

			if (action === '$reset') {
				Analytics.event('forecaster.scenario.settings.reset', data);

				setValue(
					[prefix, 'flags.scenarioId'].filter(Boolean).join('.'),
					null,
				);
				dispatch(setUserSettingsFlags({ scenarioId: null }));
			}

			if (action === '$update') {
				Analytics.event('forecaster.scenario.settings.update', data);

				onAction?.('scenario:open', {
					page: MODAL_WINDOWS.ScenarioEdit,
					data: data,
				});
			}

			if (action === '$select') {
				Analytics.event('forecaster.scenario.settings.select', data);
				setValue(
					[prefix, 'flags.scenarioId'].filter(Boolean).join('.'),
					data?._id,
				);
				setScenario(data?._id);
				// dispatch(setUserSettingsFlags({ scenarioId: data?._id }));
			}
		},
		[dispatch, onAction, prefix, setValue],
	);

	if (!hasScenarioFeature) {
		return null;
	}

	return (
		<Group className="asteria-component__scenario-wrapper">
			{TranslationService.get([
				'settings.scenario',
				'settings.forecast.scenario',
			]) ? (
				<Label>
					{TranslationService.get([
						'settings.scenario',
						'settings.forecast.scenario',
					])}
				</Label>
			) : null}

			<ScenarioSelect
				// scenarios={scenarioSelectOptions}
				// selected={selected}
				onChange={handleChange}
				scenarioId={scenarioId}
			/>
		</Group>
	);
};

ScenarioSection.propTypes = {
	settings: PropTypes.object,
	onAction: PropTypes.func,
	prefix: PropTypes.string,
};

export default ScenarioSection;
