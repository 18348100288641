/* eslint-disable react/prop-types */

/* eslint-disable spellcheck/spell-checker */
import React from 'react';

import { endOfMonth, formatISO, startOfMonth } from 'date-fns';

import Badge from '@asteria/component-core/badge';
import Button from '@asteria/component-core/button';
import { Text, Title } from '@asteria/component-core/typography';

import {
	HealthCard,
	HealthExtra,
	InvoicesCard,
	InvoicesExtra,
} from '@asteria/component-card';

import { cn } from '@asteria/utils-funcs/classes';

import { LayoutContext } from '../context';

function Account({ title, content, total }) {
	return (
		<div className="p-2 flex items-center">
			<div className="flex-1 flex flex-col gap-1">
				<Text>{title}</Text>
				<Text size="xs">{content}</Text>
			</div>
			<Text>{total}</Text>
			<Button icon="triangle-right" size="sm" />
		</div>
	);
}

function Favorites({ className, style }) {
	return (
		<div className={cn(className)} style={style}>
			<div
				className={cn(
					'rounded border border-solid border-border-normal px-8 py-6 flex flex-col gap-2',
				)}
			>
				<div
					className="flex justify-between items-center"
					style={{
						'--color-title': 'var(--color-page-title)',
					}}
				>
					<Title size="sm">Favoritkonton</Title>
					<Button icon="more" iconSize="lg" />
				</div>
				<div className="divide-y border-y border-solid border-border-normal">
					<Account
						title="Företagskonto"
						content="2222-22-22222"
						total="140 632,79"
					/>
					<Account
						title="Valutakonto (EUR)"
						content="1111-1,222 222 222-6"
						total="18,00"
					/>
					<Account
						title="Sparkapitalkonto"
						content="1111-22-22223"
						total="0,00"
					/>
					<Account
						title="Sparkapitalkonto"
						content="2222-22-22222"
						total="15 050,00"
					/>
				</div>
				<div>
					<Button variant="link" label="Konton" size="sm" />
				</div>
			</div>
		</div>
	);
}

function ToDo({ className, style }) {
	return (
		<div className={cn(className)} style={style}>
			<div className="p-4 bg-splash-normal-background rounded px-8 py-6 flex flex-col gap-8">
				<Title
					size="sm"
					style={{
						'--color-title': 'var(--color-page-title)',
					}}
				>
					Att göra
				</Title>
				<div className="flex flex-col gap-4">
					<div className="divide-y border-b">
						<Text className="pb-4" weight="medium">
							Betalningar
						</Text>
						<div className="flex gap-2 items-center p-2">
							<Badge>
								<Text>8</Text>
							</Badge>
							<Text className="flex-1">Avvisade betalningar</Text>
							<Button icon="triangle-right" size="sm" />
						</div>
						<div className="flex gap-2 items-center p-2">
							<Badge>
								<Text>7</Text>
							</Badge>
							<Text className="flex-1">Filer att godkänna</Text>
							<Button icon="triangle-right" size="sm" />
						</div>
					</div>
					<div className="divide-y border-b">
						<Text className="pb-4" weight="medium">
							Signeringsärenden
						</Text>
						<div className="flex gap-2 items-center p-2">
							<Badge>
								<Text>1</Text>
							</Badge>
							<Text className="flex-1">Avtal att godkänna</Text>
							<Button icon="triangle-right" size="sm" />
						</div>
					</div>
					<div className="divide-y border-b">
						<Text className="pb-4" weight="medium">
							Dokument
						</Text>
						<div className="flex gap-2 items-center p-2">
							<Badge>
								<Text>6</Text>
							</Badge>
							<Text className="flex-1">Olästa dokument</Text>
							<Button icon="triangle-right" size="sm" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const SwedbankPage = React.memo(function SwedbankPage() {
	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const startDate = formatISO(startOfMonth(new Date()), {
		representation: 'date',
	});

	const endDate = formatISO(endOfMonth(new Date()), {
		representation: 'date',
	});

	return (
		<div className="max-w-5xl w-full mx-auto flex flex-col gap-8">
			<Title size="page-title">Välkommen!</Title>
			<div className="flex gap-8">
				<div className="flex-1 flex flex-col gap-8">
					<Favorites />
					<InvoicesCard
						startDate={startDate}
						endDate={endDate}
						onAction={onAction}
						onSubmit={onSubmit}
					>
						{(args) => <InvoicesExtra {...args} />}
					</InvoicesCard>
				</div>
				<div className="flex-1 flex flex-col gap-8">
					<HealthCard
						startDate={startDate}
						endDate={endDate}
						onAction={onAction}
						onSubmit={onSubmit}
					>
						{(args) => <HealthExtra {...args} />}
					</HealthCard>
					<ToDo />
				</div>
			</div>
		</div>
	);
});

SwedbankPage.displayName = 'SwedbankPage';

SwedbankPage.propTypes = {};

export default SwedbankPage;
