import React from 'react';

import PropTypes from 'prop-types';

import Contenter from '@asteria/component-tools/contenter';
import { Context } from '@asteria/component-wizard';

import WelcomeContext from '../../../context';

import './styles.scss';

const StartStep = (props) => {
	const { onAction, name } = props;

	const { wizard: ctxWizard } = React.useContext(WelcomeContext);
	const config = ctxWizard?.[name]?.start ?? null;

	const { next } = React.useContext(Context);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'wizard:start') {
				next?.();
			}

			return onAction?.(action, data);
		},
		[next, onAction],
	);

	const actions = React.useMemo(
		() => ({ onAction: handleAction }),
		[handleAction],
	);

	if (!config) {
		return null;
	}

	return <Contenter content={config} actions={actions} />;
};

StartStep.displayName = 'StartStep';

StartStep.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	onAction: PropTypes.func,
};

export default StartStep;
