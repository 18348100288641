import React from 'react';

export const GraphActionsContext = React.createContext({});

export const GraphPropsContext = React.createContext({
	mode: null,
	variant: null,
	xaxis: { clickable: false, hoverable: false },
	legend: { clickable: false, hoverable: false },
});
