import { cloneElement } from 'react';

import PropTypes from 'prop-types';

import { useACL } from '.';

const ACL = ({ type, roles, strict = false, children }) => {
	const hasAccess = useACL(type, roles, strict);

	if (!children) {
		return null;
	}

	if (hasAccess) {
		if (Array.isArray(children)) {
			return children
				.filter((child) => child)
				.map((child, index) =>
					cloneElement(child, { key: `child-${index}` }),
				);
		}

		return cloneElement(children);
	}

	return null;
};

ACL.propTypes = {
	type: PropTypes.oneOf(['ADMIN', 'USER']).isRequired,
	roles: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]).isRequired,
	strict: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

ACL.displayName = 'ACL';

export default ACL;
