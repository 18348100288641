import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import BarGraph from '../../components/graph/bars';
import LineGraph from '../../components/graph/lines';
import XAxis from '../../components/x-axis';
import { useGraphRange } from '../../hooks';

import { useNavigation } from './hooks';

import './styles.scss';

const TYPES = ['deposit', 'withdraw'];

const SectionGraph = React.memo((props) => {
	const { onAction, variant } = props;

	const { type, category, tag, level } = useNavigation({ variant });

	const [visible, setVisible] = React.useState('bars');

	const onToggle = React.useCallback(
		() => setVisible((value) => (value === 'bars' ? 'lines' : 'bars')),
		[],
	);

	const onGraphBack = React.useCallback(
		() => onAction?.('forecaster:graph:previous'),
		[onAction],
	);

	const onGraphNext = React.useCallback(
		() => onAction?.('forecaster:graph:next'),
		[onAction],
	);

	const dates = useGraphRange();

	return (
		<div
			className={cn(
				'asteria-component__forecaster-page-section',
				'asteria--type-graph',
			)}
		>
			<Button
				variant="link"
				label={TranslationService.get([
					'forecaster.graph.toggle',
					visible === 'bars'
						? 'forecaster.graph.account.toggle'
						: null,
					visible === 'lines' ? 'forecaster.graph.bars.toggle' : null,
				])}
				className="asteria-component__forecaster-toggle"
				onClick={onToggle}
				size="sm"
			/>
			<Group
				className="asteria-component__forecaster-page-section-content"
				verticalAlign="center"
				direction="horizontal"
				horizontalAlign="space-between"
			>
				<Button icon="chevron-left" size="sm" onClick={onGraphBack} />

				{visible === 'lines' ? (
					<LineGraph
						dates={dates}
						showLines
						showYAxis
						showLegends
						onAction={onAction}
					/>
				) : null}

				{visible === 'bars' ? (
					<BarGraph
						dates={dates}
						showLines
						showYAxis
						showLegends
						onAction={onAction}
						type={type}
						category={category}
						tag={tag}
						types={level === 0 ? TYPES : undefined}
					/>
				) : null}

				<Button icon="chevron-right" size="sm" onClick={onGraphNext} />
			</Group>

			<XAxis
				dates={dates}
				hasYAxis
				type={type}
				category={category}
				tag={tag}
				types={level === 0 ? TYPES : undefined}
				showPrefix
				onAction={onAction}
			/>
		</div>
	);
});

SectionGraph.displayName = 'SectionGraph';
SectionGraph.propTypes = {
	onAction: PropTypes.func,
	variant: PropTypes.string,
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
};

export default SectionGraph;
