import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import { Provider as FeatureProvider } from '@asteria/component-tools/featureflag/context';

const selectors = {
	features: createSelector(
		(store) => store?.features?.features,
		(value) => value ?? null,
		{ memoizeOptions: { resultEqualityCheck: (a, b) => isEqual(a, b) } },
	),
};

const Features = (props) => {
	const { children } = props;

	const features = useSelector(selectors.features);

	return <FeatureProvider features={features}>{children}</FeatureProvider>;
};

Features.propTypes = {
	children: PropTypes.node,
};

export default Features;
