import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { format, formatISO, parseISO, subYears } from 'date-fns';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import { TooltipContent } from '@asteria/component-core/tooltip';
import { Text, TextGroup } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';
import Prefix from '@asteria/component-prefix';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';

import { useProperties } from '../../../hooks';
import Context from '../../../logic/context';
import { getPath } from '../../../utils/getFormPaths';

import './styles.scss';

const selectors = {
	hasHistorySettings: createSelector(
		(store) => store?.app?.user?.settings?.flags?.forecasterShowHistory,
		(value) => value ?? false,
	),
};

function useHistoryData(options) {
	const { type, category, tag, date } = options;

	const { history } = React.useContext(Context);

	const hasUnpaidFeature = useFeature('forecaster-status-unpaid');

	const statuses = React.useMemo(() => {
		let statuses = ['PAID'];

		if (hasUnpaidFeature) {
			statuses = statuses.concat(['UNPAID', 'OVERDUE']);
		}

		return statuses;
	}, [hasUnpaidFeature]);

	return React.useMemo(
		() =>
			Object.fromEntries(
				statuses.map((key) => [
					key,
					get(
						history,
						getPath({
							type,
							date: formatISO(date, { representation: 'date' }),
							category,
							tag,
							status: key,
							field: 'value',
						}),
					) ?? 0,
				]),
			),
		[category, date, history, statuses, tag, type],
	);
}

function useForecastData(options) {
	const { type, category, tag, date } = options;

	let fmt = date;

	if (fmt instanceof Date) {
		fmt = format(fmt, 'yyyy-MM-dd');
	}

	return useFormValues({
		name: getPath({
			type: type,
			date: fmt,
			category: category,
			tag: tag,
			field: 'value',
		}),
	});
}

const HistoryTooltip = (props) => {
	const {
		type,
		category,
		tag,

		forecast,
		current,
		color,
		label,
		history,
	} = props;

	const hasHistorySettings = useSelector(selectors.hasHistorySettings);

	const items = React.useMemo(() => {
		const result = [];

		if (history?.PAID && hasHistorySettings) {
			result.push({
				key: ['history', type, category, tag].join('-'),
				color: 'total',
				label: TranslationService.get(
					['forecaster.history.tooltip.history.label'],
					undefined,
					{ label: label },
				),
				total: history?.PAID,
			});
		}

		if (current?.OVERDUE) {
			result.push({
				key: ['overdue', type, category, tag].join('-'),
				color: ['overdue'],
				label: TranslationService.get(
					['forecaster.history.tooltip.overdue.label'],
					'Overdue: {{{ label }}}',
					{ label: label },
				),
				total: current?.OVERDUE,
			});
		}

		if (forecast) {
			result.push({
				key: ['forecast', type, category, tag].join('-'),
				color: ['forecast'].concat(color),
				label: TranslationService.get(
					['forecaster.history.tooltip.forecast.label'],
					'Forecast: {{{ label }}}',
					{ label: label },
				),
				total: forecast,
			});
		}

		if (current?.UNPAID) {
			result.push({
				key: ['unpaid', type, category, tag].join('-'),
				color: ['unpaid'],
				label: TranslationService.get(
					['forecaster.history.tooltip.unpaid.label'],
					'Unpaid: {{{ label }}}',
					{ label: label },
				),
				total: current?.UNPAID,
			});
		}

		if (current?.PAID) {
			result.push({
				key: ['paid', type, category, tag].join('-'),
				color: color,
				label: TranslationService.get(
					['forecaster.history.tooltip.paid.label'],
					'Paid: {{{ label }}}',
					{ label: label },
				),
				total: current?.PAID,
			});
		}

		return result;
	}, [
		category,
		color,
		current?.OVERDUE,
		current?.PAID,
		current?.UNPAID,
		forecast,
		hasHistorySettings,
		history?.PAID,
		label,
		tag,
		type,
	]);

	const total =
		(current?.OVERDUE ?? 0) +
		(forecast ?? 0) +
		(current?.UNPAID ?? 0) +
		(current?.PAID ?? 0);

	return (
		<TooltipContent className="asteria-component__forecaster__tooltip--history">
			<div className="asteria-component__forecaster__tooltip--history__title">
				<Text weight="medium" size="sm">
					{TranslationService.get([
						`forecaster.navigation.${type}`,
						`forecaster.history.tooltip.title`,
						`forecaster.history.tooltip.title.${type}`,
					])}
				</Text>
			</div>
			{items.length ? (
				<div className="asteria-component__forecaster__tooltip--history__content">
					{items.map(({ key, color, label, total }) => (
						<div
							key={key}
							className="asteria-component__forecaster__tooltip--history__item"
						>
							<div className="asteria-component__forecaster__tooltip--history__item__details">
								<Prefix colors={color} />
								<Text size="sm">
									{TranslationService.get(
										[
											'forecaster.history.tooltip.item.label',
										],
										'{{{ label }}}',
										{ label: label, total: total },
									)}
								</Text>
							</div>
							<Text size="sm">
								{TranslationService.get(
									[
										'forecaster.history.tooltip.amount',
										`forecaster.history.tooltip.${type}.amount`,
									],
									'{{ total | number }}',
									{ label: label, total: total },
								)}
							</Text>
						</div>
					))}
				</div>
			) : null}
			{total ? (
				<TextGroup className="asteria-component__forecaster__tooltip--history__item asteria-component__forecaster__tooltip--history__item--total">
					<Text weight="medium" size="sm">
						{TranslationService.get(
							['forecaster.history.tooltip.total.label'],
							'Total',
						)}
					</Text>
					<Text weight="medium" size="sm">
						{TranslationService.get(
							[
								'forecaster.history.tooltip.total.amount',
								`forecaster.history.tooltip.total.${type}.amount`,
							],
							'{{ total | number }}',
							{ total: total },
						)}
					</Text>
				</TextGroup>
			) : null}
		</TooltipContent>
	);
};

HistoryTooltip.displayName = 'HistoryTooltip';

HistoryTooltip.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	date: PropTypes.string,

	forecast: PropTypes.number,
	current: PropTypes.object,
	history: PropTypes.object,
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	label: PropTypes.string,
};

const HistoryTooltipWrapper = (props) => {
	const { type, category, tag, date } = props;

	const { getValues } = useFormContext();

	const history = useHistoryData({
		type: type,
		category: category,
		tag: tag,
		date: subYears(date instanceof Date ? date : parseISO(date), 1),
	});

	const current = useHistoryData({
		type: type,
		category: category,
		tag: tag,
		date: date instanceof Date ? date : parseISO(date),
	});

	const forecast = useForecastData({
		type: type,
		category: category,
		tag: tag,
		date: date instanceof Date ? date : parseISO(date),
	});

	const { colors, label } = useProperties({
		type: type,
		category: category,
		tag: tag,
		useForm: useFormValues,
		useStaticForm: getValues,
	});

	return (
		<HistoryTooltip
			{...props}
			forecast={forecast}
			current={current}
			color={colors}
			label={label}
			history={history}
		/>
	);
};

HistoryTooltipWrapper.displayName = 'HistoryTooltipWrapper';

HistoryTooltipWrapper.propTypes = {
	type: PropTypes.string,
	category: PropTypes.string,
	tag: PropTypes.string,
	date: PropTypes.string,
};

export default React.memo(HistoryTooltipWrapper);
