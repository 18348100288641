import React from 'react';

import PropTypes from 'prop-types';

import { TooltipWrapper } from '@asteria/component-core/tooltip';

import Chip from '@asteria/component-chip';
import { useFeature } from '@asteria/component-tools/featureflag';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const StatusCell = React.memo((props) => {
	const { className, data, onAction } = props;

	const hasClickFeature = useFeature(
		'cashflow-transactions-chip-status-click',
	);

	const handleClick = React.useCallback(() => {
		Analytics.event('transaction.chip.status', {
			status: data,
		});

		return onAction?.('transactions:filter', {
			type: 'status',
			id: data,
			item: {
				code: data,
				name: data,
			},
		});
	}, [data, onAction]);

	const status = data?.toLowerCase?.() || 'paid';

	return (
		<div
			className={cn(
				'asteria-component__transactions__cell__content',
				className,
			)}
		>
			<TooltipWrapper
				tooltip={
					hasClickFeature
						? TranslationService.get([
								`list.transactions.transaction.chip.tooltip`,
								`list.transactions.transaction.status.tooltip`,
								`list.transactions.transaction.status.${status}.tooltip`,
						  ])
						: null
				}
				variant="alt"
			>
				<Chip
					colors={
						['unpaid', 'signed', 'overdue'].includes(status)
							? status
							: null
					}
					label={TranslationService.get(
						[
							`list.transactions.transaction.status`,
							`list.transactions.transaction.status.${status}`,
						],
						status,
					)}
					size="sm"
					onClick={hasClickFeature ? handleClick : undefined}
				/>
			</TooltipWrapper>
		</div>
	);
});

StatusCell.displayName = 'StatusCell';

StatusCell.propTypes = {
	className: PropTypes.string,
	data: PropTypes.string,
	onAction: PropTypes.func,
};

export default StatusCell;
