import React from 'react';

import PropTypes from 'prop-types';

import ComponentService from '@asteria/component-tools/contenter/service';

import { cn } from '@asteria/utils-funcs/classes';

import { sizeClasses, stateClasses } from '../utils';

import './styles.scss';

const iconClasses = (prefix, { icon, iconPosition }, generic = false) =>
	generic
		? {
				'asteria--icon-position-first': iconPosition === 'first',
				'asteria--icon-position-last': iconPosition === 'last',
				[`asteria--icon-${icon}`]: icon,
		  }
		: {
				...[].concat(icon).reduce(
					(acc, icon) => ({
						...acc,
						[`${prefix}--icon-${icon}`]: icon,
					}),
					{},
				),
				[`${prefix}--icon-position-${iconPosition}`]:
					!![].concat(icon).length && iconPosition,
		  };

function hasIcon(icon) {
	if (!icon) {
		return false;
	}

	const icons = [].concat(icon).reverse();

	const selectors = getComputedStyle(
		document.documentElement,
	).getPropertyValue('--asteria-selectors');

	const node = document.querySelector(selectors);

	if (!node) {
		return true;
	}

	for (const icon of icons) {
		const response = !!getComputedStyle(node).getPropertyValue(
			`--icon-${icon}`,
		);

		if (response) {
			return response;
		}
	}

	for (const icon of icons) {
		// eslint-disable-next-line no-console
		console.warn(`Missing icon: ${icon}`);
	}

	return false;
}

const SVGIcon = React.memo(
	React.forwardRef((props, ref) => {
		const { className, icon, ...rest } = props;

		const icons = React.useMemo(() => [].concat(icon).reverse(), [icon]);

		const html = React.useMemo(() => {
			const selectors = getComputedStyle(
				document.documentElement,
			).getPropertyValue('--asteria-selectors');

			const node = document.querySelector(selectors);

			if (!node) {
				return null;
			}

			let base64 = null;

			for (const icon of icons) {
				base64 = getComputedStyle(node).getPropertyValue(
					`--icon-${icon}`,
				);

				if (base64) {
					break;
				}
			}

			if (!base64) {
				return null;
			}

			const url = /\(["']?(?<svg>.+)["']?\)/g
				.exec(base64)
				?.groups?.svg?.replace?.(/["']/g, '');

			if (!url) {
				return null;
			}

			const base64Content = /base64,(?<content>.+)/g.exec(url)?.groups
				?.content;

			if (!base64Content) {
				return null;
			}

			let svg = null;

			try {
				svg = atob(base64Content);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.warn('Icon:SVGOnly', err);
			}

			if (!svg) {
				return null;
			}

			const svgContent = /<svg.+?>(?<content>.+?)<\/svg>/.exec(svg)
				?.groups?.content;

			if (!svgContent) {
				return null;
			}

			return { __html: svgContent };
		}, [icons]);

		if (!html) {
			return null;
		}

		return (
			<g
				className={cn(
					'asteria-component__icon',
					stateClasses(props),
					className,
				)}
				ref={ref}
			>
				<svg dangerouslySetInnerHTML={html} {...rest} />
			</g>
		);
	}),
);

SVGIcon.displayName = 'SVGIcon';
SVGIcon.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string,
	svgOnly: PropTypes.bool,
};

const Icon = React.memo(
	React.forwardRef((props, ref) => {
		const { className, contentClassName, icon, svgOnly, size, ...rest } =
			props;

		if (![].concat(icon).filter(Boolean).length) {
			return null;
		}

		if (svgOnly) {
			return <SVGIcon {...props} ref={ref} />;
		}

		return (
			<span
				className={cn(
					'asteria-component__icon',
					stateClasses(props),
					sizeClasses({ size: size }),
					className,
				)}
				ref={ref}
				{...rest}
			>
				<span
					className={cn(
						'asteria-component__icon-content',
						[].concat(icon).map((icon) => `asteria--icon-${icon}`),
						contentClassName,
					)}
				/>
			</span>
		);
	}),
);

Icon.displayName = 'Icon';

Icon.propTypes = {
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	icon: PropTypes.string,
	svgOnly: PropTypes.bool,
	size: PropTypes.string,
};

ComponentService.register('Icon', Icon, {
	className: { type: 'string' },
	contentClassName: { type: 'string' },
	icon: { type: 'string' },
	svgOnly: { type: 'boolean' },
	size: { type: 'string' },
});

export default Icon;
export { iconClasses, hasIcon };
