import { useMemo, useState } from 'react';

/**
 * @param { { defaultValue?: boolean, onOpen?: (event: MouseEvent) => void, onClose?: (event: MouseEvent) => void } } options
 * @returns { [boolean, { onOpen: (event: MouseEvent) => void, onClose: (event: MouseEvent) => void }] }
 */
function useModal(options) {
	const [open, setOpen] = useState(options?.defaultValue ?? false);

	const onOpen = options?.onOpen;
	const onClose = options?.onClose;

	const actions = useMemo(
		() => ({
			onOpen: (event) => {
				setOpen(true);
				return onOpen?.(event);
			},
			onClose: (event) => {
				setOpen(false);
				return onClose?.(event);
			},
		}),
		[onOpen, onClose],
	);

	return [open, actions];
}

export default useModal;
