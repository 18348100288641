import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
	addMonths,
	addWeeks,
	eachWeekOfInterval,
	endOfMonth,
	format,
	isSameISOWeek,
	isThisISOWeek,
	startOfMonth,
	subMonths,
	subWeeks,
} from 'date-fns';
import PropTypes from 'prop-types';

import { cn } from '@asteria/utils-funcs/classes';

import * as Constants from '../../constants';
import DatePickerContentCell from '../DatePickerContentCell';
import DatePickerSlider from '../DatePickerSlider';
import {
	cleanValue,
	getLocale,
	getNextMonthDisabled,
	getPreviousMonthDisabled,
	getSelectedCSSClasses,
} from '../utils';

import './index.scss';

const DatePickerContentWeekCalendar = (props) => {
	const {
		onSelect,
		value: propValue,
		defaultValue,
		previousDate,
		updateCurrentDate,

		isPastDisabled,
		isFutureDisabled,

		locale,

		startDate,
		endDate,
		disabledDates,
	} = props;

	const currentLocale = getLocale(locale);
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const items = useMemo(() => {
		const extraPreviousDates = 4;
		const totalDatesCount = 12;

		let values = eachWeekOfInterval({
			start: startOfMonth(value),
			end: endOfMonth(value),
		});

		const startDate = values[0];
		const endDate = values.splice(-1)[0];

		return []
			.concat(
				new Array(extraPreviousDates)
					.fill(null)
					.map((_, index) =>
						subWeeks(startDate, extraPreviousDates - index),
					),
			)
			.concat(values)
			.concat(
				new Array(
					totalDatesCount - (extraPreviousDates + values.length),
				)
					.fill(null)
					.map((_, index) => addWeeks(endDate, index + 1)),
			);
	}, [value]);

	const { previousMonth, nextMonth } = useMemo(
		() => ({
			previousMonth: subMonths(value, 1),
			nextMonth: addMonths(value, 1),
		}),
		[value],
	);

	const isPreviousMonthDisabled = useMemo(
		getPreviousMonthDisabled({
			previousDate: previousDate,
			previousMonth: previousMonth,
			isPastDisabled: isPastDisabled,
			startDate: startDate,
		}),
		[previousDate, previousMonth, isPastDisabled],
	);

	const isNextMonthDisabled = useMemo(
		getNextMonthDisabled({
			nextMonth: nextMonth,
			isFutureDisabled: isFutureDisabled,
			endDate: endDate,
		}),
		[nextMonth, isFutureDisabled],
	);

	const onValueChange = useCallback(
		(value) => {
			setValue(value);
			updateCurrentDate(value);
		},
		[value],
	);

	return (
		<div className="asteria-component__datepicker__content__week">
			<DatePickerSlider
				isPreviousDisabled={isPreviousMonthDisabled}
				isNextDisabled={isNextMonthDisabled}
				onPreviousClick={() => {
					onValueChange(previousMonth);
				}}
				onNextClick={() => {
					onValueChange(nextMonth);
				}}
				label={cleanValue(
					format(value, 'MMMM, yyyy', { locale: currentLocale }),
				)}
			/>
			<div
				className={cn(
					'asteria-component__datepicker__content__week__calendar',
					'asteria-component__datepicker__content__calendar',
				)}
			>
				{items.map((date) => (
					<DatePickerContentCell
						key={['calendar', 'week', date].join('-')}
						date={date}
						onSelect={onSelect}
						value={propValue}
						isPastDisabled={isPastDisabled}
						isFutureDisabled={isFutureDisabled}
						isThisDate={isThisISOWeek(date)}
						className={cn(
							'asteria-component__datepicker__content__week__calendar__item',
							getSelectedCSSClasses({
								value: propValue,
								date: date,
								validate: isSameISOWeek,
							}),
						)}
						format="II"
						startDate={startDate}
						endDate={endDate}
						disabledDates={disabledDates}
						locale={locale}
					/>
				))}
			</div>
		</div>
	);
};

DatePickerContentWeekCalendar.propTypes = {
	defaultValue: PropTypes.instanceOf(Date),

	value: Constants.DatePickerValuePropType,
	variant: Constants.DatePickerVariantsPropType,
	behavior: Constants.DatePickerBehaviorPropType,
	layout: Constants.DatePickerLayoutPropType,
	locale: Constants.DatePickerLocalePropType,

	previousDate: PropTypes.instanceOf(Date),
	updateCurrentDate: PropTypes.func,

	isPastDisabled: PropTypes.bool,
	isFutureDisabled: PropTypes.bool,

	startDate: PropTypes.string,
	endDate: PropTypes.string,
	disabledDates: Constants.DatePickerValuePropType,

	onSelect: PropTypes.func,
};

DatePickerContentWeekCalendar.defaultProps = {};

export default DatePickerContentWeekCalendar;
