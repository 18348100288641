import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { formatISO, isFuture, isThisMonth, subYears } from 'date-fns';
import { get } from 'lodash-es';

import { useFormValues, useManualFormValues } from '@asteria/component-form';

import * as GraphStore from '@asteria/datalayer/stores/graph';

import { TranslationService } from '@asteria/language';
import * as FormatUtils from '@asteria/utils-funcs/format';
import { getLabels } from '@asteria/utils-funcs/graph';
import { useDeepMemo } from '@asteria/utils-hooks/useDeep';

import { useCategoryColors } from '../../../hooks';
import Context from '../../../logic/context';
import { getPath } from '../../../utils/getFormPaths';
import { STATUS_PRIORITY } from '../constants';

import { getVisibleState } from './utils';

const selectors = {
	hasHistorySettings: createSelector(
		(store) => store?.app?.user?.settings?.flags?.forecasterShowHistory,
		(value) => value ?? false,
	),
};

export function useBarGraphData({
	type,
	dates,
	category,
	tag,
	types = [type],
}) {
	const hasHistorySettings = useSelector(selectors.hasHistorySettings);

	const { history } = useContext(Context);

	const absolute = types.length > 1;

	const future = useManualFormValues({
		name: dates.flatMap((date) =>
			types.map((type) =>
				getPath({
					type: type,
					date: formatISO(date, { representation: 'date' }),
					status: 'FORECAST',
					category: category,
					tag: tag,
					field: 'value',
				}),
			),
		),
	});

	const historical = dates.flatMap((date) =>
		types
			.map((type) =>
				getPath({
					type: type,
					date: formatISO(date, { representation: 'date' }),
					status: 'PAID',
					category: category,
					tag: tag,
					field: 'value',
				}),
			)
			.map((path) => get(history, path)),
	);

	const pastHistorical = hasHistorySettings
		? dates.flatMap((date) =>
				types
					.map((type) =>
						getPath({
							type: type,
							date: formatISO(subYears(date, 1), {
								representation: 'date',
							}),
							status: 'PAID',
							category: category,
							tag: tag,
							field: 'value',
						}),
					)
					.map((path) => get(history, path)),
		  )
		: [];

	const values = Array.from({ length: historical.length }).map(
		(value, index) => {
			const current = (future[index] || 0) + (historical[index] || 0);
			const past = pastHistorical[index] || 0;

			return Math.max(current, past);
		},
	);

	const max = Math.max(...values) || 10_000;
	const min = Math.min(...values) || 0;
	const absoluteValue = Math.max(
		Math.max(Math.abs(max), Math.abs(min)),
		3_000,
	);

	const scale = useMemo(() => {
		let value = max;

		if (absolute) {
			value = absoluteValue;
		}

		if (!value) {
			return 0;
		}

		return Math.max(Math.floor(Math.floor(Math.log10(value)) / 3) - 1, 0);
	}, [absolute, absoluteValue, max]);

	const labels = useMemo(() => {
		const labels = getLabels({
			max: absolute ? absoluteValue : max,
			min: absolute ? -absoluteValue : 0,
			steps: 4,
			scale: scale,
			prefix:
				types.length === 1 ? (type === 'deposit' ? '+' : '-') : null,
		});

		if ((types.length === 1 && type === 'deposit') || types.length !== 1) {
			labels.reverse();
		}

		return labels.map((object, index) => ({
			...object,
			size: index ? 'medium' : undefined,
			label: object.value ? object.label : '0',
		}));
	}, [absolute, absoluteValue, max, scale, type, types.length]);

	const labelsMin = Math.min(...labels.map(({ value }) => value));
	const labelsMax = Math.max(...labels.map(({ value }) => value));

	return useMemo(
		() => ({
			labels: labels,
			title: TranslationService.get(
				[
					'yaxis.label',
					`yaxis.label.SEK`,
					`yaxis.label.${scale}`,
					`yaxis.label.SEK.${scale}`,
				],
				'TKr',
			),
			max: labelsMax,
			min: labelsMin,
		}),
		[labels, labelsMax, labelsMin, scale],
	);
}

export function useStatuses({ type, date }) {
	const hasHistorySettings = useSelector(selectors.hasHistorySettings);

	const path = getPath({
		type: type,
		date: formatISO(date, { representation: 'date' }),
		status: null,
	});

	const form = useFormValues({ name: path });
	const { history } = useContext(Context);

	let statuses = []
		.concat(Object.keys(form ?? {}))
		.concat(Object.keys(get(history, path) ?? {}))
		.filter((status) => ['PAID'].includes(status));

	if (hasHistorySettings && (isThisMonth(date) || isFuture(date))) {
		statuses.push('HISTORY');
	}

	if (isFuture(date) || isThisMonth(date)) {
		if (!statuses.includes('FORECAST')) {
			statuses.push('FORECAST');
		}
	}

	statuses.sort((a, b) => STATUS_PRIORITY[a] - STATUS_PRIORITY[b]);

	return useDeepMemo(() => statuses, [statuses]);
}

export function useValue({ type, date: $date, status, category, tag }) {
	let date = $date;

	if (status === 'HISTORY') {
		date = subYears(date, 1);
	}

	const path = getPath({
		type: type,
		date: formatISO(date, { representation: 'date' }),
		status: status === 'HISTORY' ? 'PAID' : status,
		category: category,
		tag: tag,
		field: 'value',
	});

	const form = useManualFormValues({ name: path });
	const { history } = useContext(Context);

	return (form || 0) + (get(history, path) || 0);
}

export function useOverallValue(options) {
	const paid = useValue({ ...options, status: 'PAID' });
	const forecast = useValue({ ...options, status: 'FORECAST' });

	return paid + forecast;
}

export function useLegends({ type, dates, category, tag, types = [type] }) {
	const { history } = useContext(Context);

	const hasHistorySettings = useSelector(selectors.hasHistorySettings);

	const colors = useCategoryColors({ type, category, tag });

	const forecastValue = useManualFormValues({
		name: types.flatMap((type) =>
			dates.map((date) =>
				getPath({
					type: type,
					date: formatISO(date, { representation: 'date' }),
					category,
					tag,
					field: 'value',
					status: 'FORECAST',
				}),
			),
		),
	}).reduce((acc, value) => acc + (value || 0), 0);

	const historicalValue = types
		.flatMap((type) =>
			dates.map((date) =>
				getPath({
					type: type,
					date: formatISO(date, { representation: 'date' }),
					category,
					tag,
					field: 'value',
					status: 'PAID',
				}),
			),
		)
		.map((path) => get(history, path))
		.reduce((acc, value) => acc + (value || 0), 0);

	const pastHistorical = hasHistorySettings
		? types
				.flatMap((type) =>
					dates.map((date) =>
						getPath({
							type: type,
							date: formatISO(subYears(date, 1), {
								representation: 'date',
							}),
							category,
							tag,
							field: 'value',
							status: 'PAID',
						}),
					),
				)
				.map((path) => get(history, path))
				.reduce((acc, value) => acc + (value || 0), 0)
		: 0;

	const hasValue = !!(forecastValue + historicalValue);
	const hasForecastValue = !!forecastValue;
	const hasFuture = dates.some((date) => isFuture(date) || isThisMonth(date));
	const hasHistory = !!pastHistorical;

	return useMemo(
		() =>
			[]
				.concat(
					hasValue
						? types.map((type) => {
								let $tag = tag;

								if (category === '$type') {
									$tag = `$${type}`;
								}

								return {
									type: [
										type,
										FormatUtils.replace([
											category,
											$tag,
										]).join('-'),
									],
									title: FormatUtils.formatTag({
										type: type,
										category: category,
										tag: $tag,
									}),
									color:
										category === '$custom'
											? colors
											: undefined,
								};
						  })
						: [],
				)
				.concat(
					hasFuture && hasForecastValue
						? {
								type: 'forecast',
								title: TranslationService.get([
									'tags.forecast',
									'graph.legend.forecast',
								]),
						  }
						: [],
				)
				.concat(
					hasHistory
						? [
								{
									type: 'history',
									title: TranslationService.get([
										'graph.legend.history',
									]),
								},
						  ]
						: [],
				),
		[
			hasValue,
			types,
			hasFuture,
			hasForecastValue,
			hasHistory,
			tag,
			category,
			colors,
		],
	);
}

export function useStateHidden({ type, status, category, tag }) {
	const selected = useSelector(GraphStore.selectors.legends.selected);

	const exists = !!selected.length;
	const result = selected.some((object) =>
		getVisibleState(object, { type, status, category, tag }),
	);

	return useMemo(() => {
		if (!exists) {
			return;
		}

		return !result;
	}, [exists, result]);
}

export function useStateInvisible({ type, status, category, tag }) {
	const highlight = useSelector(GraphStore.selectors.legends.highlight);

	const exists = !!highlight;
	const result = getVisibleState(highlight, { type, status, category, tag });

	return useMemo(() => {
		if (!exists) {
			return;
		}

		return !result;
	}, [exists, result]);
}
