import React from 'react';

import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageTitle,
} from '@asteria/component-core/pageHeader';

import Accounts from '@asteria/component-accounts';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import { TranslationService } from '@asteria/language';
import { useTourShowAction } from '@asteria/utils-hooks/tour';

import './styles.scss';

const AccountsView = (props) => {
	const { onAction, onSubmit } = props;

	useTourShowAction();

	return (
		<div className="asteria-view__accounts">
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get('bankList.title')}
					/>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-accounts">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<Accounts onAction={onAction} onSubmit={onSubmit} />
		</div>
	);
};

AccountsView.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default AccountsView;
