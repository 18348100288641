import { get } from 'lodash-es';

import { normalizeProbability } from '@asteria/component-core/utils/normalize';

import {
	setProbability as setTagProbability,
	setValue as setTagValue,
} from './adjustTag';
import { recalculate as recalculateType } from './adjustType';
import { createChange, getPath } from './getFormPaths';

function getTransactionValue({ type, date, category, form }) {
	const path = getPath({ type, date, category });
	const tags = get(form, [path, 'tags'].join('.')) || {};

	return Object.values(tags)
		.flatMap((object) => Object.values(object?.transactions ?? {}))
		.filter((object) => object && !object.$deleted)
		.reduce(
			(acc, { total = 0, probability = 1 }) => ({
				value: acc.value + (total || 0),
				min: acc.min + (total || 0) * (probability || 1),
			}),
			{ value: 0, min: 0 },
		);
}

const setValue = ({ type, date, category, value, form }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			setValue({ type, date, category, value, form }),
		);
	}

	const path = getPath({ type, date, category });
	const tags = get(form, [path, 'tags'].join('.')) || {};

	const { value: prev } = getTransactionValue({ type, date, category, form });
	const percentageChanged = value / prev;

	let updates = [];

	if (value === prev) {
		return updates;
	}

	updates.push(
		createChange(
			{
				op: 'set',
				name: `${path}.value`,
				value: value,
				event: 'category:value:set',
			},
			form,
		),
	);

	const count = Object.keys(tags).length;

	updates = updates.concat(
		Object.entries(tags).flatMap(([tag, { value }]) =>
			setTagValue({
				type,
				date,
				category,
				tag,
				value: prev ? value * percentageChanged : value / count,
				form,
			}),
		),
	);

	return updates;
};

const setProbability = ({ type, date, category, value, form, strict }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			setProbability({ type, date, category, value, form, strict }),
		);
	}

	const path = getPath({ type, date, category });

	const { tags = {}, probability: prev = 1 } = get(form, path) || {};

	const percentageChanged = value / prev;

	let updates = [];

	if (value === prev) {
		return updates;
	}

	updates.push(
		createChange(
			{
				op: 'set',
				name: `${path}.probability`,
				value: value,
				event: 'category:probability:set',
			},
			form,
		),
	);

	updates = updates.concat(
		Object.entries(tags).flatMap(([name, tag]) =>
			setTagProbability({
				type,
				date,
				category: category,
				tag: name,
				value: strict ? value : tag.probability * percentageChanged,
				prev: tag.probability,
				form,
				strict: strict,
			}),
		),
	);

	return updates;
};

const recalculate = ({ type, date, category, form, skipRecalculate }) => {
	if (!date) {
		return Object.keys(get(form, getPath({ type }))).flatMap((date) =>
			recalculate({ type, date, category, form, skipRecalculate }),
		);
	}

	const path = getPath({ type, date, category });

	const { value, probability } = get(form, path) || {};

	const { value: transactionValue, min: transactionMinValue } =
		getTransactionValue({ type, date, category, form });

	const calculatedProbability =
		transactionValue !== 0 ? transactionMinValue / transactionValue : 1;
	const normalizedProbability = normalizeProbability(calculatedProbability);

	let updates = [];

	if (transactionValue !== value) {
		updates.push(
			createChange(
				{
					op: 'set',
					name: `${path}.value`,
					value: transactionValue,
					previousValue: value,
					event: 'category:recalculate:value',
				},
				form,
			),
		);
	}

	if (normalizedProbability !== probability) {
		updates.push(
			createChange(
				{
					op: 'set',
					name: `${path}.probability`,
					value: normalizedProbability,
					previousValue: probability,
					event: 'category:recalculate:probability',
				},
				form,
			),
		);
	}

	if (updates.length > 0 && !skipRecalculate) {
		updates = updates.concat(recalculateType({ type, date, form }));
	}

	return updates;
};

export { recalculate, setValue, setProbability };
