import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { parseISO } from 'date-fns';
import {
	addMonths,
	addYears,
	isAfter,
	isPast,
	startOfMonth,
	startOfYear,
	subMonths,
	subYears,
} from 'date-fns';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';

import { useFormValues } from '@asteria/component-form';
import Form from '@asteria/component-form/form';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as ScenarioStore from '@asteria/datalayer/stores/scenarios';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import LoanContext from '../../context/loan';

import { useDates } from './hooks';
import LoaderWrapper from './loader';
import SectionAside from './section/aside';
import SectionBarGraph from './section/bar-graph';
import SectionHeader from './section/header';
import SectionLineGraph from './section/line-graph';
import SectionTransactions from './section/transactions';
import SectionXAxis from './section/x-axis';
import LeaveWrapper from './wrapper/leave';

import './styles.scss';

const LoanPageContent = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const [view, setView] = React.useState('graph');

	const timestamp = useFormValues({ name: 'timestamp' });

	const $term = useFormValues({ name: 'term' });
	const term = $term ? $term : 3;

	const [{ range, date: currentDate }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'BACK': {
					const date = state.date;
					const range = state.range;

					let next = date;

					if (range === 'month') {
						next = subMonths(date, 1);
					}

					if (range === 'year') {
						next = subYears(date, 1);
					}

					return { ...state, date: next };
				}

				case 'NEXT': {
					const date = state.date;
					const range = state.range;

					let next = date;

					if (range === 'month') {
						next = addMonths(date, 1);
					}

					if (range === 'year') {
						next = addYears(date, 1);
					}

					return { ...state, date: next };
				}

				case 'SET_RANGE': {
					const range = action?.payload;

					let next = state?.date;

					if (range === 'month') {
						next = startOfMonth(
							timestamp ? parseISO(timestamp) : new Date(),
						);
					}

					if (range === 'year') {
						next = startOfYear(
							timestamp ? parseISO(timestamp) : new Date(),
						);
					}

					return { ...state, range: range, date: next };
				}

				case 'RESET':
					return {
						...state,
						range: action?.payload?.range ?? 'year',
						date:
							action?.payload?.date ??
							startOfYear(
								timestamp ? parseISO(timestamp) : new Date(),
							),
					};

				default:
					return state;
			}
		},
		{
			range: 'year',
			date: startOfYear(timestamp ? parseISO(timestamp) : new Date()),
		},
	);

	React.useEffect(() => {
		dispatch({
			type: 'RESET',
			payload: { date: startOfYear(parseISO(timestamp)) },
		});
	}, [timestamp]);

	const onBackClick = React.useCallback(() => {
		dispatch({ type: 'BACK' });
	}, []);

	const onNextClick = React.useCallback(() => {
		dispatch({ type: 'NEXT' });
	}, []);

	const onSetRange = React.useCallback((range) => {
		dispatch({ type: 'SET_RANGE', payload: range });
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'view:change') {
				setView(data);
				return;
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	const startDate = currentDate;
	const endDate =
		range === 'month'
			? addMonths(currentDate, 12)
			: addYears(currentDate, term - 1);

	const dates = useDates({
		range: range,
		startDate: currentDate,
		endDate: endDate,
	});

	const ctx = React.useMemo(
		() => ({
			dates: dates,
			range: range,
			back: onBackClick,
			next: onNextClick,
			setRange: onSetRange,
		}),
		[dates, onBackClick, onNextClick, onSetRange, range],
	);

	const isBackDisabled = isPast(startDate);
	const isNextDisabled =
		dates.length < 12 ||
		isAfter(
			endDate,
			addYears(startOfMonth(subMonths(new Date(), 1)), term),
		);

	return (
		<LoanContext.Provider value={ctx}>
			<div
				className={cn(
					'asteria-component__financial-page-section-wrapper',
					'asteria--variant-aside',
					{ [`asteria--view-${view}`]: view },
				)}
			>
				<SectionAside onAction={handleAction} onSubmit={onSubmit} />
			</div>
			<div
				className={cn(
					'asteria-component__financial-page-section-wrapper',
					'asteria--variant-content',
					{ [`asteria--view-${view}`]: view },
				)}
			>
				<SectionHeader
					onAction={handleAction}
					onSubmit={onSubmit}
					view={view}
				/>

				{view === 'graph' ? (
					<div className="asteria-component__financial-loan-graph-wrapper">
						<Button
							className="asteria-button-left"
							size="sm"
							onClick={onBackClick}
							icon="chevron-left"
							tooltip={{
								tooltip: TranslationService.get([
									'graph.navigation.previous',
									'graph.navigation.tooltip',
									'graph.navigation.previous.tooltip',
								]),
								variant: 'alt',
							}}
							analyticsKey={'graph.navigation.previous'}
							disabled={isBackDisabled}
						/>

						<div className="asteria-component__financial-loan-graph-content">
							<SectionLineGraph
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
							<SectionBarGraph
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
							<SectionXAxis
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
						</div>

						<Button
							className="asteria-button-right"
							size="sm"
							onClick={onNextClick}
							icon="chevron-right"
							tooltip={{
								tooltip: TranslationService.get([
									'graph.navigation.next',
									'graph.navigation.tooltip',
									'graph.navigation.next.tooltip',
								]),
								variant: 'alt',
							}}
							analyticsKey={'graph.navigation.next'}
							disabled={isNextDisabled}
						/>
					</div>
				) : (
					<SectionTransactions
						onAction={handleAction}
						onSubmit={onSubmit}
					/>
				)}
			</div>
		</LoanContext.Provider>
	);
});

LoanPageContent.displayName = 'LoanPageContent';
LoanPageContent.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const FormContent = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const { getValues, reset } = useFormContext();

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'loan:dirty:reset') {
				reset(getValues());
				return;
			}

			return onAction?.(action, data);
		},
		[getValues, onAction, reset],
	);

	return (
		<LeaveWrapper onAction={handleAction} onSubmit={onSubmit}>
			<LoanPageContent onAction={handleAction} onSubmit={onSubmit} />
		</LeaveWrapper>
	);
});

FormContent.displayName = 'FormContent';
FormContent.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

const LoanPage = React.memo((props) => {
	const { onAction, onSubmit } = props;

	const { id } = useParams();

	const simulation = useSelector((store) =>
		ScenarioStore.selectors.scenario(store, id),
	);

	const currency = useSelector(AppStore.selectors.currency);

	const values = React.useMemo(
		() => ({
			amount: 0,
			term: 3,
			rate: 10.5,
			currency: currency,
			type: 'STRAIGHT',
			timestamp: new Date().toISOString(),
			...simulation?.data,
		}),
		[currency, simulation?.data],
	);

	return (
		<div
			className={cn(
				'asteria-component__financial-page',
				'asteria--variant-loan',
			)}
		>
			<LoaderWrapper onSubmit={onSubmit} onAction={onAction}>
				<Form values={values}>
					<FormContent onAction={onAction} onSubmit={onSubmit} />
				</Form>
			</LoaderWrapper>
		</div>
	);
});

LoanPage.displayName = 'LoanPage';
LoanPage.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

export default LoanPage;
