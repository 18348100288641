import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import { cn } from '@asteria/utils-funcs/classes';

import AuthProfileChange from './form';

import './styles.scss';

const AuthProfileChangeModal = React.memo((props) => {
	const { open, onClose } = props;

	return (
		<Modal
			className={cn(
				'asteria-view__auth-modal',
				'asteria--variant-profile',
			)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<AuthProfileChange {...props} />
		</Modal>
	);
});

AuthProfileChangeModal.displayName = 'AuthProfileChangeModal';

AuthProfileChangeModal.propTypes = {
	className: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	accessToken: PropTypes.string,
};

export default AuthProfileChangeModal;
