import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector, useStore } from 'react-redux';

import { shuffle } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

import Analytics from '@asteria/utils-analytics';

import * as selectors from './selectors';

export function useSearchExamples() {
	const clients = useSelector(selectors.clients);
	const tags = useSelector(selectors.tags);
	const currencies = useSelector(selectors.currencies);

	return useMemo(() => {
		let selectTags = 5;
		let selectClients = 3;
		let selectCurrencies = 2;

		if (clients.length < selectClients) {
			selectCurrencies += selectClients - clients.length;
		}

		if (currencies.length < selectCurrencies) {
			selectTags += selectCurrencies - currencies.length;
		}

		return shuffle(
			[]
				.concat(shuffle(clients).slice(0, selectClients))
				.concat(shuffle(tags).slice(0, selectTags))
				.concat(shuffle(currencies).slice(0, selectCurrencies)),
		);
	}, [clients, currencies, tags]);
}

export function useSearchResults() {
	const value = useFormValues({ name: 'search-value' });

	const clients = useSelector(selectors.clients);
	const tags = useSelector(selectors.tags);
	const currencies = useSelector(selectors.currencies);
	const statuses = useSelector(selectors.statuses);

	return useMemo(() => {
		if (!value) {
			return [];
		}

		return []
			.concat(clients)
			.concat(tags)
			.concat(currencies)
			.concat(statuses)
			.filter(({ label }) => {
				const source = label?.toLowerCase?.();
				const target = value?.toLowerCase?.();

				return (source ?? '').includes(target);
			});
	}, [clients, currencies, statuses, tags, value]);
}

export function useBackspaceBehavior({ onAction }) {
	const store = useStore();
	const { getValues } = useFormContext();

	const handleKeyPress = useCallback(
		(e) => {
			const value = getValues('search-value');
			const filters = selectors.active(store.getState());

			if (e.keyCode === 8 && !value && filters?.length) {
				Analytics.event('search.behavior.backspace', {
					filters: filters,
				});

				e.preventDefault();

				const last = filters[filters.length - 1];

				onAction?.(
					'toggleTag',
					{
						type: last?.type,
						id: last?.id,
						item: last?.object,
					},
					{ skipBlur: true },
				);
			}
		},
		[getValues, onAction, store],
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);
}

export function useTabBehavior({ results, ref, onAction }) {
	const [counter, setCounter] = useState(0);

	useEffect(() => {
		setCounter(0);
	}, [results.length]);

	const handleKeyPress = useCallback(
		(e) => {
			if (results.length && e.keyCode === 9 && results.length !== 1) {
				e.preventDefault();
				setCounter((count) => {
					if (results.length <= count + 1) {
						Analytics.event('search.behavior.tab', {
							results: results,
							counter: 0,
						});

						ref?.current
							?.querySelector?.('.asteria-component__chip')
							?.scrollIntoView?.({
								behavior: 'smooth',
								block: 'center',
							});

						return 0;
					}

					Analytics.event('search.behavior.tab', {
						results: results,
						counter: count + 1,
					});

					ref?.current
						?.querySelector?.(
							'.asteria-component__chip.asteria--state-active',
						)
						?.scrollIntoView?.({
							behavior: 'smooth',
							block: 'center',
						});

					return count + 1;
				});
			}

			if (
				(e.keyCode === 13 && results.length) ||
				(e.keyCode === 9 && results.length === 1)
			) {
				e.preventDefault();

				const value = results[counter];

				Analytics.event('search.behavior.tab', {
					results: results,
					value: value,
					counter: counter,
				});

				onAction?.('toggleTag', {
					type: value?.type,
					id: value?.id,
					item: value?.object,
				});
			}
		},
		[counter, onAction, ref, results],
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	return counter;
}
