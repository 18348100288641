import { TranslationService } from '@asteria/language';

export function openCreditURI({ credit }) {
	const url = TranslationService.get(
		'overdraft.sent.redirect',
		// eslint-disable-next-line spellcheck/spell-checker
		'/app/ib/lana/foretagslan/ansokan?amount={{credit}}',
		{ credit: credit },
	);

	if (url) {
		window.history.pushState(
			{},
			TranslationService.get(
				'overdraft.sent.redirect.title',
				// eslint-disable-next-line spellcheck/spell-checker
				'Finansiera',
			),
			url,
		);
	}
}
