import React, { useCallback, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { SizeProp } from '@asteria/component-core/PropTypes';
import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import ProbabilityBar from '@asteria/component-core/probability';
import { Text } from '@asteria/component-core/typography';
import {
	isPossibleToClick,
	positionClasses,
	sizeClasses,
	stateClasses,
} from '@asteria/component-core/utils';
import { getProbabilityLevel } from '@asteria/component-core/utils/normalize';
import * as SizeUtils from '@asteria/component-core/utils/size';

import Prefix from '@asteria/component-prefix';

import Analytics from '@asteria/utils-analytics';
import { useAnalyticsData } from '@asteria/utils-funcs/analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useColors from '@asteria/utils-hooks/useColors';

const ChipFlag = React.memo((props) => {
	const { flag } = props;

	if (flag === 'Hög risk') {
		return null;
	}

	return (
		<div
			className={`asteria-component__chip__prefix currency-flag currency-flag-${flag?.toLowerCase()}`}
		/>
	);
});

ChipFlag.displayName = 'ChipFlag';

ChipFlag.propTypes = {
	flag: PropTypes.string,
};

const ChipLabel = React.memo((props) => {
	const { label, size = 'medium' } = props;

	if (typeof label === 'string') {
		const $size =
			SizeUtils.isSame(size, 'lg') || SizeUtils.isLarger(size, 'lg')
				? size
				: SizeUtils.decrease(size);

		return (
			<Text tabIndex="0" title={label} size={$size}>
				{label}
			</Text>
		);
	}

	return label;
});

ChipLabel.displayName = 'ChipLabel';

ChipLabel.propTypes = {
	label: PropTypes.string,
	size: SizeProp(),
};

const ChipIcon = React.memo((props) => {
	const { icon, iconPosition, size = 'medium' } = props;

	return (
		<Icon
			className={cn(
				'asteria-component__chip__prefix',
				positionClasses({ position: iconPosition }),
			)}
			size={size}
			icon={icon}
			tabIndex="0"
		/>
	);
});

ChipIcon.displayName = 'ChipIcon';

ChipIcon.propTypes = {
	icon: PropTypes.string,
	iconPosition: PropTypes.string,
	size: SizeProp(),
};

const ChipColors = React.memo((props) => {
	const { colors } = props;

	return (
		<Prefix
			className="asteria-component__chip__prefix"
			colors={colors}
			tabIndex="0"
		/>
	);
});

ChipColors.displayName = 'ChipColors';

ChipColors.propTypes = {
	colors: PropTypes.oneOf([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};

const ChipProbability = React.memo((props) => {
	const { probability, size = 'medium' } = props;

	return (
		<ProbabilityBar
			className="asteria-component__chip__probability"
			type="chip"
			tooltip={probability?.tooltip}
			probability={probability?.probability}
			values={probability?.values}
			size={size}
		/>
	);
});

ChipProbability.displayName = 'ChipProbability';

ChipProbability.propTypes = {
	probability: PropTypes.shape({
		tooltip: PropTypes.string,
		probability: PropTypes.number,
		values: PropTypes.arrayOf(PropTypes.number),
	}),
	size: SizeProp(),
};

const ChipDismiss = React.memo(
	React.forwardRef((props, ref) => {
		const { dismissIcon, onDismiss, size = 'medium', tooltip } = props;

		return (
			<Button
				className={cn(
					'asteria-component__chip__button',
					'asteria-component__chip__button--dismiss',
				)}
				icon={dismissIcon}
				type="button"
				variant="link"
				onClick={onDismiss}
				size={size}
				tooltip={tooltip}
				stopPropagation
				tabIndex="0"
				ref={ref}
			/>
		);
	}),
);

ChipDismiss.displayName = 'ChipDismiss';

ChipDismiss.propTypes = {
	dismissIcon: PropTypes.string,
	onDismiss: PropTypes.func,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

	size: SizeProp(),
};

const ChipAction = (props) => {
	const { className } = props;

	return (
		<Button
			{...props}
			className={cn(className, 'asteria-component__chip__action')}
		/>
	);
};

ChipAction.displayName = 'ChipAction';
ChipAction.propTypes = {
	className: PropTypes.string,
};

const ChipPropTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	colors: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
	icon: PropTypes.string,
	iconPosition: PropTypes.string,

	active: PropTypes.bool,
	size: SizeProp(),
	flag: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'flat', 'simple']),
	probability: PropTypes.shape({
		tooltip: PropTypes.string,
		probability: PropTypes.number,
		values: PropTypes.arrayOf(PropTypes.number),
	}),

	onClick: PropTypes.func,
	onDismiss: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,

	dismiss: PropTypes.bool,
	dismissIcon: PropTypes.string,
	dismissIconActive: PropTypes.string,
	dismissTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

	analytics: PropTypes.object,
	analyticsKey: PropTypes.string,

	actions: PropTypes.arrayOf(PropTypes.shape({ ...Button.propTypes })),

	children: PropTypes.node,
};

/**
 * @type { React.ForwardRefExoticComponent<Partial<ChipPropTypes & { ref: React.RefObject }>> }
 */
const Chip = React.forwardRef((props, ref) => {
	const {
		className,
		onClick,
		dismiss,
		onDismiss,
		label,
		colors,
		icon,
		iconPosition,
		flag,
		variant,
		probability,
		onMouseEnter,
		onMouseLeave,
		dismissIcon = 'close',
		dismissIconActive = dismissIcon,
		dismissTooltip,
		actions,
		analytics,
		analyticsKey,
		children,
		type,
		...restProps
	} = props;

	const [isActive, setActive] = useState(false);

	const targetRef = useRef(null);

	const probabilityLevel = useMemo(() => {
		return getProbabilityLevel({
			value: probability?.probability,
			variants: probability?.values,
		});
	}, [probability?.probability, probability?.values]);

	const { typed: typedColors } = useColors(colors);

	const handleMouseEnter = useCallback(
		(event) => {
			setActive(true);

			return onMouseEnter?.(event);
		},
		[onMouseEnter],
	);

	const handleMouseLeave = useCallback(
		(event) => {
			setActive(false);

			return onMouseLeave?.(event);
		},
		[onMouseLeave],
	);

	const analyticsDataRef = useAnalyticsData({
		analyticsKey: analyticsKey,
		label: label,
		flag: flag,
		dismiss: dismiss,
		probability: probability,
		...analytics,
	});

	const handleClick = useCallback(
		(event) => {
			if (!isPossibleToClick(event, (ref || targetRef)?.current)) {
				return;
			}

			if (analyticsKey) {
				Analytics.event('chip.click', analyticsDataRef.current);
			}

			return onClick?.(event);
		},
		[analyticsDataRef, analyticsKey, onClick, ref],
	);

	return (
		<button
			ref={ref || targetRef}
			className={cn(
				'asteria-component__chip',
				{ [`asteria-component__chip--type-${type}`]: type },
				typedColors,
				{
					[`asteria-component__chip--icon-${icon}`]: icon,
					[`asteria-component__chip--icon-${flag}`]: flag,
				},
				stateClasses(props),
				sizeClasses(props),
				{
					[`asteria-component__chip--variant-${variant}`]: variant,
					[`asteria-component__chip--probability-${probabilityLevel}`]:
						probabilityLevel,
				},
				className,
			)}
			onClick={handleClick}
			type="button"
			tabIndex="0"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...restProps}
		>
			{flag ? <ChipFlag flag={flag} /> : null}
			{colors ? <ChipColors colors={colors} /> : null}
			<ChipLabel label={label} size={props.size} />
			{icon ? (
				<ChipIcon
					icon={icon}
					iconPosition={iconPosition}
					size={props.size}
				/>
			) : null}
			{probability ? (
				<ChipProbability probability={probability} size={props.size} />
			) : null}
			{actions?.length
				? actions.map((action, index) => {
						const icon = action?.icon ?? action?.iconActive;
						const iconActive = action?.iconActive ?? action?.icon;

						return (
							<ChipAction
								key={index}
								size={props.size}
								{...action}
								icon={isActive ? iconActive : icon}
							/>
						);
				  })
				: null}
			{dismiss ? (
				<ChipDismiss
					dismissIcon={isActive ? dismissIconActive : dismissIcon}
					onDismiss={onDismiss}
					tooltip={dismissTooltip}
					size={props.size}
				/>
			) : null}
			{children}
		</button>
	);
});

Chip.propTypes = ChipPropTypes;

Chip.defaultProps = {};

Chip.displayName = 'Chip';

export default React.memo(Chip);
