import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

import * as AppStore from '@asteria/datalayer/stores/app';

import { decode } from '@asteria/utils-funcs/jwt';

import * as AuthAPI from '../api/auth';
import * as CompanyAPI from '../api/company';
import * as UserAPI from '../api/user';

import { useLanguageChangeCallback } from '.';

export function useValidate({ accessToken }) {
	const dispatch = useDispatch();

	const token = useSelector(
		(store) => AppStore.selectors.accessToken(store) ?? accessToken,
		(a, b) => isEqual(a, b),
	);

	const loggedIn = useSelector(
		(store) => AppStore.selectors.auth(store)?.loggedIn,
		(a, b) => isEqual(a, b),
	);

	useQuery({
		queryKey: ['auth', 'validate'],
		queryFn: async ({ signal }) => {
			if (!token) {
				dispatch(AppStore.setLoginStatus(false));

				return false;
			}

			let response;

			try {
				response = await UserAPI.me({
					accessToken: token,
					fields: `_id authRecord`,
					signal: signal,
					raise: true,
				});
			} catch (err) {
				dispatch(AppStore.setLoginStatus(false));

				return false;
			}

			if (response?.authRecord?.twoAuth?.active === false) {
				dispatch(AppStore.register2FA(true));
			}

			dispatch(AppStore.setLoginStatus(true));
			dispatch(AppStore.setAccessToken(token));

			return true;
		},
		enabled: !loggedIn,
		retry: false,
	});
}

export function useTokenChangeCallback(storage = window.localStorage) {
	const dispatch = useDispatch();

	return useCallback(
		({ accessToken, refreshToken, valid, wait }) => {
			if (accessToken) {
				storage.setItem('wingsToken', accessToken);
			}

			if (refreshToken) {
				storage.setItem('wingsRefreshToken', refreshToken);
			}

			if (!wait) {
				if (accessToken) {
					dispatch(AppStore.setAccessToken(accessToken));
				}
			}

			if (valid) {
				dispatch(AppStore.setLoginStatus(true));
			}
		},
		[dispatch, storage],
	);
}

export function useAction(storage = window.localStorage) {
	const onTokenChange = useTokenChangeCallback(storage);
	const onLanguageChange = useLanguageChangeCallback();

	return useCallback(
		(action, data) => {
			if (action === 'auth:token') {
				return onTokenChange(data);
			}

			if (action === 'auth:language') {
				return onLanguageChange(data);
			}
		},
		[onLanguageChange, onTokenChange],
	);
}

export function useSubmit(storage = window.localStorage) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [search] = useSearchParams();
	const location = useLocation();

	const isWelcome = location.pathname.startsWith('/welcome');
	const next = ['/', search.toString()].filter(Boolean).join('?');

	const accessToken = useSelector(
		(store) => AppStore.selectors.accessToken(store),
		(a, b) => isEqual(a, b),
	);

	const onAction = useAction(storage);

	return useCallback(
		async (action, data) => {
			if (action === 'auth:login') {
				const response = await AuthAPI.login({
					username: data?.username,
					password: data?.password,
					partnerId: data?.partnerId,
					companyId: data?.companyId,
					code: data?.code,
				});

				if (
					response?.hasTwoFactor === false ||
					response?.data?.hasTwoFactor === false
				) {
					dispatch(AppStore.register2FA(true));
				}

				return response;
			}

			if (action === 'auth:password-reset') {
				return AuthAPI.forgotPassword({
					username: data?.username,
					partnerId: data?.partnerId,
					theme: data?.theme,
				});
			}

			if (action === 'auth:password-recover') {
				const response = await AuthAPI.resetPassword({
					password: data?.password1,
					secret: data?.secret,
					partnerId: data?.partnerId,
				});

				if (
					response?.hasTwoFactor === false ||
					response?.data?.hasTwoFactor === false
				) {
					dispatch(AppStore.register2FA(true));
				}

				return response;
			}

			if (action === 'auth:code:validate') {
				const response = await AuthAPI.validateSignupKey({
					key: data?.key,
					partnerId: data?.partnerId,
				});

				return response?.ok;
			}

			if (action === 'auth:signup') {
				const response = await AuthAPI.signup({
					company: data?.company,
					user: data?.user,
					partnerId: data?.partnerId,
					signupKey: data?.signupKey,
				});

				if (
					response?.hasTwoFactor === false ||
					response?.data?.hasTwoFactor === false
				) {
					dispatch(AppStore.register2FA(true));
				}

				return response;
			}

			if (action === 'auth:welcome') {
				let result = {};

				if (data?.step == 0) {
					const response = await AuthAPI.resetPassword({
						password: data?.auth?.password1,
						secret: data?.auth?.secret,
						partnerId: data?.auth?.partnerId,
					});

					if (
						response?.hasTwoFactor === false ||
						response?.data?.hasTwoFactor === false
					) {
						dispatch(AppStore.register2FA(true));
					}

					const accessToken = response?.data?.accessToken;
					const refreshToken = response?.data?.refreshToken;

					result.accessToken = accessToken;
					result.refreshToken = refreshToken;

					const companyId = decode(accessToken)?.companyId ?? null;

					dispatch(AppStore.setUserCompanyId(companyId));

					const user = response?.user;
					const rawCompanies = user?.rawCompanies ?? [];
					const companies = (user?.companies?.edges ?? []).map(
						({ node }) => node,
					);

					const roles =
						rawCompanies.reduce(
							(acc, { companyId, roles }) => ({
								...acc,
								[companyId]: roles,
							}),
							{},
						)[companyId] ?? [];

					const isUser = roles.some((role) =>
						['COMPANYADMIN', 'USER'].includes(role),
					);

					if (isUser) {
						await UserAPI.update({
							accessToken: accessToken,
							id: user?._id,
							input: data?.user,
						});
					}

					const isCompanyAdmin = roles.some((role) =>
						['COMPANYADMIN'].includes(role),
					);

					if (isCompanyAdmin) {
						result.next = true;
						result.company = companies?.[0];
					}
				}

				if (data?.step == 1) {
					await CompanyAPI.updateMyCompany({
						accessToken: storage.getItem('wingsToken'),
						input: data?.company,
					});
				}

				return result;
			}

			if (action === 'auth:2fa:register') {
				return AuthAPI.register2FA({
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			if (action === 'auth:2fa:validate') {
				return AuthAPI.validate2FA({
					code: data,
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			if (action === 'auth:2fa:refresh') {
				return AuthAPI.refresh2FA({
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			if (action === 'auth:2fa:fetch') {
				return AuthAPI.fetch2FA({
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			if (action === 'auth:2fa:deactivate') {
				return AuthAPI.deactivate2FA({
					accessToken: accessToken,
					dispatch: dispatch,
				});
			}

			if (action === 'auth:profile:change') {
				const response = await AuthAPI.changeProfile({
					companyId: data?.companyId,
					accessToken: accessToken,
					dispatch: dispatch,
				});

				onAction?.('auth:token', {
					accessToken: response?.accessToken,
					refreshToken: response?.refreshToken,
					valid: true,
				});

				if (isWelcome) {
					navigate(next);
				}

				return response;
			}
		},
		[accessToken, dispatch, isWelcome, navigate, next, onAction, storage],
	);
}
