export default (inputNumber, space = 1) => {
	if (inputNumber === 0) {
		return 0;
	}

	const digits = Math.floor(Math.log10(Math.abs(inputNumber))) - 1;
	const base =
		(Math.ceil(Math.abs(inputNumber) / 10 ** digits) + space) *
		10 ** digits;
	return inputNumber < 0 ? -base : base;
};
