import React from 'react';

import { useFormContext } from 'react-hook-form';

import { get } from 'lodash-es';

import { useFormValues } from '@asteria/component-form';

export const FormContext = React.createContext({
	useForm: () => null,
	useStaticForm: () => null,
	getStaticForm: () => null,
});

/**
 * @template T
 * @param { T } Component
 * @returns { T }
 */
const withForm = (Component) => {
	/** @type { typeof Component } */
	const InnerForm = (props) => {
		const { getValues } = useFormContext();

		const ctx = React.useMemo(
			() => ({
				useForm: useFormValues,
				useStaticForm: getValues,
				getStaticForm: getValues,
			}),
			[getValues],
		);

		return (
			<FormContext.Provider value={ctx}>
				<Component {...props} />
			</FormContext.Provider>
		);
	};

	InnerForm.propTypes = Component.propTypes;
	InnerForm.defaultProps = Component.defaultProps;
	InnerForm.displayName = Component.displayName;

	/** @type { typeof Component } */
	const InnerStatic = (props) => {
		const { form } = props;

		const getStaticForm = React.useCallback(
			(name) => {
				if (!name) {
					return form;
				}

				if (Array.isArray(name)) {
					return name.map((name) => get(form, name));
				}

				return get(form, name);
			},
			[form],
		);

		const getForm = React.useCallback(
			({ name }) => getStaticForm(name),
			[getStaticForm],
		);

		const ctx = React.useMemo(
			() => ({
				useForm: getForm,
				useStaticForm: getStaticForm,
				getStaticForm: getStaticForm,
			}),
			[getForm, getStaticForm],
		);

		return (
			<FormContext.Provider value={ctx}>
				<Component {...props} />
			</FormContext.Provider>
		);
	};

	InnerStatic.propTypes = Component.propTypes;
	InnerStatic.defaultProps = Component.defaultProps;
	InnerStatic.displayName = Component.displayName;

	/** @type { typeof Component } */
	const Inner = (props) => {
		if (props.form) {
			return <InnerStatic {...props} />;
		}

		return <InnerForm {...props} />;
	};

	Inner.propTypes = Component.propTypes;
	Inner.defaultProps = Component.defaultProps;
	Inner.displayName = Component.displayName;

	return Inner;
};

export default withForm;
