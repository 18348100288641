import React from 'react';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import { cn } from '@asteria/utils-funcs/classes';

import Auth2FAVerify from './form';

import './styles.scss';

const Auth2FAVerifyModal = React.memo((props) => {
	const { open, onClose } = props;

	return (
		<Modal
			className={cn(
				'asteria-view__auth-modal',
				'asteria--variant-2fa-verify',
			)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<Auth2FAVerify {...props} />
		</Modal>
	);
});

Auth2FAVerifyModal.displayName = 'Auth2FAVerifyModal';

Auth2FAVerifyModal.propTypes = {
	open: PropTypes.bool,

	className: PropTypes.string,
	onClose: PropTypes.func,
	onSuccess: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	showHeaderClose: PropTypes.bool,
};

export default Auth2FAVerifyModal;
