import 'currency-flags/dist/currency-flags.min.css';

import ComponentService from '@asteria/component-tools/contenter/service';

import Chip from './chip';

import './index.scss';

ComponentService.register('Chip', Chip, {
	className: { type: 'string' },
	label: { type: 'string' },
	colors: { type: 'string' },
	icon: { type: 'string' },
	iconPosition: { type: 'string' },

	active: { type: 'boolean' },
	size: { type: 'enum', options: ['lg', 'md', 'sm'] },
	flag: { type: 'string' },
	variant: { type: 'enum', options: ['default', 'flat', 'simple'] },
	probability: {
		type: 'object',
		options: {
			tooltip: { type: 'string' },
			probability: { type: 'number' },
			values: { type: 'array', of: { type: 'number' } },
		},
	},

	onClick: { type: 'function' },
	onDismiss: { type: 'function' },
	onMouseEnter: { type: 'function' },
	onMouseLeave: { type: 'function' },

	dismiss: { type: 'boolean' },
	dismissIcon: { type: 'string' },
	dismissIconActive: { type: 'string' },
	dismissTooltip: { type: 'string' },

	analytics: { type: 'object' },
	analyticsKey: { type: 'string' },

	actions: { type: 'array', of: { type: 'object' } },

	children: { type: 'node' },
});

export default Chip;
